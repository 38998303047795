<div class="add-admin padding-left-right-15">
  <div class="padding-left-15">
      <h3 class="text-header-form-edit">
          <a class="no-focus" [routerLink]="['/admin', 'account']">
              <span class="fa fa-angle-left style-top-arrow"></span>
          </a>
          Add New Admin
      </h3>
  </div>
  <form method="post" #form="ngForm" (ngSubmit)="addAdminForm()" novalidate class="row padding-left-right-15">
    <div class="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-7 no-padding-left">
          <div class="card card-default">
              <div class="card-header">
                  <h3 class="card-title text-title-form-edit">Name</h3>
              </div>
              <div class="card-body row">
                  <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left inline-edit-provider">
                      <label for="first_name" class="input-label-required">First Name</label>
                      <input type="text" class="form-control" id="first_name" [(ngModel)]="newAdmin.first_name" name="first_name" [class.input-error]="error.first_name" (keyup)="onKeyUp('first_name')" maxlength="50">
                      <div class="message-error">{{ error.first_name }}</div>
                  </div>
                  <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left-right inline-edit-provider">
                      <label for="last_name" class="input-label-required">Last Name</label>
                      <input type="text" class="form-control" id="last_name" [(ngModel)]="newAdmin.last_name" name="last_name" [class.input-error]="error.last_name" (keyup)="onKeyUp('last_name')" maxlength="50">
                      <div class="message-error">{{ error.last_name }}</div>
                  </div>
              </div>
          </div>
          <div class="card card-default">
              <div class="card-header">
                  <h3 class="card-title text-title-form-edit">Mobile Phone Number</h3>
              </div>
              <div class="card-body">
                  <label for="phone_mobile" class="input-label-required">Phone Number</label>
                  <div>
                      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right">
                          <input type="text" class="form-control" [(ngModel)]="newAdmin.phone_mobile" name="phone_mobile" [class.input-error]="error.phone_mobile" (keyup)="onKeyUp('phone_mobile')" simplemask [format]="'(###) ###-####'" [target]="newAdmin" [field]="'phone_mobile'">
                      </div>
                      <div class="clear-both"></div>
                  </div>
                  <div class="message-error">{{ error.phone_mobile }}</div>
              </div>
          </div>
      </div>
      <div class="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-5 no-padding-right">
          <div class="card card-default">
              <div class="card-header">
                  <h3 class="card-title text-title-form-edit">Email</h3>
              </div>
              <div class="card-body">
                  <label for="email" class="input-label-required">Email</label>
                  <input type="text" class="form-control" id="email" [(ngModel)]="newAdmin.email" name="email" [class.input-error]="error.email" (keyup)="onKeyUp('email')" minlength="5" maxlength="254">
                  <div class="message-error">{{ error.email }}</div>
              </div>
          </div>
      </div>
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-bottom-20 no-padding-left">
          <button type="submit" class="container btn btn-rtn btn-90 text-uppercase">Create</button>
      </div>
  </form>
</div>
