<!--<rtn-appointment-header></rtn-appointment-header>-->
<div class="container-fluid provider-appointment px-0">
    <div class="row header-container">
        <div class="col-12 d-md-flex d-sm-none d-none pr-0 pl-0 justify-content-between title_apt">
            <h3>Appointments</h3>
            <span class="px-3" style="margin-left: 50px; font-size: 18px; padding-top: 5px;">Please make sure all patients are checked out when they complete appointment</span>
            <span class="float-right"><!--*ngIf="!_systemData.isOnline"-->
                <a class="btn btn-rtn mb-2" [class.disabled]="isLock" [attr.aria-disabled]="isLock"
                    (click)="appointmentBookDirective.bookAppointmentClicked(2)"><span class="label">Book
                        Appointment</span></a>
                <a class="btn btn-rtn mb-2" [class.disabled]="isLock" [attr.aria-disabled]="isLock"
                    (click)="availableSlotDirective.addNewAvailableSlot(2,null)"><span class="label">New Available
                        Slot</span></a>
            </span>
            <!--<span *ngIf="_systemData.isOnline" class="float-right">
                <a class="btn btn-rtn btn-disabled"><span class="label">Book Appointment</span></a>                
            </span>-->
        </div>
        <h3
            class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs text-center pr-0 pl-0">
            Appointments</h3>
        <div class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
            <span> <!-- *ngIf="!_systemData.isOnline"-->
                <a class="btn btn-rtn mr-2" [class.disabled]="isLock" [attr.aria-disabled]="isLock"
                    (click)="availableSlotDirective.addNewAvailableSlot(2,null)"><span class="label">New Available
                        Slot</span></a>
                <a class="btn btn-rtn float-left mr-2" [class.disabled]="isLock"
                    (click)="appointmentBookDirective.bookAppointmentClicked(2)"><span class="label">Book
                        Appointment</span></a>
            </span>
            <!--<span *ngIf="_systemData.isOnline">
                <a class="btn btn-rtn btn-disabled float-left"><span class="label">Book Appointment</span></a>
            </span>-->
        </div>
        <div *ngIf="module[2]" class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 time-info">
            <span class="float-left waiting-time"><span class="time-label">Waiting time:</span><span
                    class="time-value">{{waitTime}}</span></span>
            <span class="float-right current-time"><span class="time-label">Current time:</span><span
                    class="time-value">{{this.getCurrentTime()}}</span></span>
        </div>
    </div>
    <!--=============-->
    <!-- <div class="input-rtn display-flex">
    <input class="checkbox check-single" type="checkbox" id="searchOpenings-email">
    <label for="searchOpenings-email" class="selectorExtreme rtn-text-grey-85"></label>
  </div> -->
    <!--=============-->
    <div class="row timeline-container">
        <rtn-timeline></rtn-timeline>
    </div>
</div>
<rtn-modals-appointment-book (reload)="timelineDirective.refetchAppointment()"></rtn-modals-appointment-book>
<rtn-modals-available-slot (reload)="timelineDirective.refetchAppointment()"></rtn-modals-available-slot>
<!--<rtn-modals-patient-new (reload)="timelineDirective.refetchAppointment()"></rtn-modals-patient-new> -->