<modal #Reschedule_Modals class="reschedule-dialog">
  <div class="reschedule-header">
    <div class="btn-close-popup" (click)="close()">
      <i class="fa fa-times fa-lg"></i>
    </div>
  </div>
  <modal-body class="reschedule px-0">
    <div class="reschedule-content px-3">
      <div class="reschedule-header-text text-uppercase">Current appointment</div>
      <div>
        <table class="table table-hover">
          <tbody>
            <tr>
              <td>Provider</td>
              <td class="appointment-data">
                <span>{{ _utils.generateFullName(appointmentDetail.provider_first_name, appointmentDetail.provider_middle_name, appointmentDetail.provider_last_name) }}</span><span
                  *ngIf="appointmentDetail.credentials">,</span>
                <span>{{ appointmentDetail.credentials }}</span>
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td class="appointment-data">
                <div>{{ appointmentDetail.address }}</div>
                <div>{{ appointmentDetail.address2 }}</div>
                <div>
                  <span>{{ appointmentDetail.city }}</span><span *ngIf="appointmentDetail.state_code !== null">,</span>
                  <span>{{ appointmentDetail.state_code }}</span>
                  <span>{{ appointmentDetail.zip }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Date</td>
              <td class="appointment-data">{{ _utils.getDate(appointmentDetail.plan_check_in) }}</td>
            </tr>
            <tr>
              <td>Time</td>
              <td class="appointment-data">
                <span>{{ _utils.getTime(appointmentDetail.plan_check_in) }}</span>
                <span>-</span>
                <span>{{ _utils.getTime(appointmentDetail.plan_check_out) }}</span>
              </td>
            </tr>
            <tr>
              <td>Duration</td>
              <td class="appointment-data">{{ _utils.generateValueUnitDisplay(appointmentDetail.duration, 'min') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row opening-area px-0">
      <div class="reschedule-header-text text-uppercase px-3">Openings</div>
      <!--<div class="date-selector margin-bottom-5">
          <div class="date-selector-text">Date:</div>
          <rtn-calendar class="calender"
              #calendar
              [inline]="false"
              [autoclose]="true"
              [(value)]="selectedDate"
              [todayHighlight]="ngDatePicker.todayHighlight"
              [weekStart]="ngDatePicker.weekStart"
              [defaultViewDate]="ngDatePicker.defaultViewDate"
              [startDate]="ngDatePicker.startDate"
              [format]="ngDatePicker.format"
              (valueChange)="getAvailableSlots($event)">
              <input type="text" class="form-control calender-input bc-white" disabled
                  [(ngModel)]="selectedDate"
                  (input)="calendar.valueChange.next($event.target.value)">
                  <div class="input-group-addon">
                      <span aria-hidden="true" class="fa fa-calendar-o"></span>
                  </div>
          </rtn-calendar>-->
      <div class="date-selector margin-bottom-5 col-xs-12 col-sm-12 col-lg-12">
        <div class="date-selector-text">Date:</div>
        <div class="col-10 col-xs-10 col-sm-10 col-lg-10 no-padding-left date-select">
          <div class="input-group">
            <input type="text" #inputDateRescedule class="form-control calender-input bc-white"
              [(ngModel)]="selectedDate" disabled>
            <div class="input-group-append" #clickShowDateRescedule>
              <span aria-hidden="true" class="fa fa-calendar-o input-group-text"></span>
            </div>
          </div>

        </div>
      </div>
      <div class="clearfix w-100">
        <div class="col-12 row px-3">
          <div class="col-6 px-2 py-1" *ngFor="let slot of availableSlots">
            <div class="xmr-5 px-0 btn btn-default btn-slot w-100" [class.selected-bc]="slot.isSelected"
              (click)="getAppointmentClicked(slot)" title="{{slot.location_name}}">{{ getTime(slot.start_time.date) }}&nbsp;-&nbsp;{{ getTime(slot.end_time.date) }}</div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-rtn text-uppercase book-button" (click)="bookAppointmentClicked()"
          [disabled]="newAppointment === null">Book Appointment
        </button>
      </div>

    </div>
  </modal-body>
</modal>