import {Component, ViewChild, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {BillingInfo} from '@app/shared/models/billingInfo';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {AlertService} from '@app/shared/alert';
import {PracticeDetail} from '@app/shared/models/practiceDetail';
import {Select2InsuranceDirective} from '@app/shared/form/select2-insurance.directive';
import {Subscription} from 'rxjs';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {AcceptedInsuranceComponent} from '@app/shared/accepted-insurance/accepted-insurance';
import {InsuranceCompany} from '@app/shared/models/insuranceCompany';
import {Utils} from '@app/shared/utils';
import {RestService} from '@app/core/rest.service';
import {ProvidersService} from '@app/providers/providers.service';

@Component({
  moduleId: module.id,
  selector: 'app-practice-edit',
  templateUrl: './practice-edit.component.html',
  styleUrls: ['./practice-edit.component.scss'],
  providers: [RestService],
})
export class PracticeEditComponent implements OnInit, OnDestroy {
  @ViewChild(Select2InsuranceDirective) insurancesSelector: Select2InsuranceDirective;

  billing: BillingInfo = new BillingInfo();
  practiceDetail: PracticeDetail = new PracticeDetail();
  responseError: PracticeDetail = new PracticeDetail();
  listTime: any[] = new Array();
  settingFields: any = {};
  successMessage: string;

  updatePracticeSubscriber: Subscription;
  onGetPracticeSubscriber: Subscription;
  getInsurancesSubscriber: Subscription;

  constructor(
    private _router: Router,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    public _systemData: SystemData,
    // private _routeParams: RouteParams,
    private _alertService: AlertService,
    private _restService: ProvidersService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService
  ) {
  }

  ngOnInit() {
    var self = this;
    if (this._utils.accessEditPractice()) {
      this._loadingIndicatorService.start();
      this.onGetPracticeSubscriber = this._restService.getProfile().subscribe(
        (success: any) => {
          self._loadingIndicatorService.stop();
          self.practiceDetail = success.practice;
          if (success.practice.billing !== null) {
            self.billing = success.practice.billing;
          }
          // Set format for phone
          self.billing.phone_mobile = self.billing.phone_mobile.replace('+1 ', '');
          self.practiceDetail.state_code = self.practiceDetail.state_code === null ? '' : self.practiceDetail.state_code.toUpperCase();
          self.billing.state_code = self.billing.state_code === null ? '' : self.billing.state_code.toUpperCase();
          //this.openingHourDirective.injectData(this.practiceDetail.opening_hours);
          //Check if have case no insurance

          let index = self.practiceDetail.insurances.indexOf(null);
          if (index !== -1) {
            self.practiceDetail.insurances[index] = '-1';
          }
          let resultInsuranceList = self._systemService.getInsuranceList();
          if (resultInsuranceList != 'done') {
            self.getInsurancesSubscriber = self._systemEvent.insurances.subscribe((done: boolean) => {
              if (done) {
                setTimeout(() => {
                  self.insurancesSelector.updateData(self.practiceDetail.insurances);
                }, 500);
              }
            });
          } else {
            setTimeout(() => {
              self.insurancesSelector.updateData(self.practiceDetail.insurances);
            }, 500);
          }
        }, (error: any) => {
          this._loadingIndicatorService.stop();
        });
      this._systemService.getStates();
    }
    //   this._restService.getInsuranceList().subscribe(
    //     (success: any) => {
    //         console.log(success);
    //       const noInsuranceOption = {
    //         'id': '-1',
    //         'name': 'No Insurance/Self Pay'
    //       };
    //       this._systemData.insurances = success.insurances;
    //     },
    //     (error: any) => {
    //       console.log('error');
    //     },
    //   );
    this.insurancesSelector.getData();
  }

  ngOnDestroy() {
    if (this.onGetPracticeSubscriber) {
      this.onGetPracticeSubscriber.unsubscribe();
    }
    if (this.updatePracticeSubscriber) {
      this.updatePracticeSubscriber.unsubscribe();
    }
    if (this.getInsurancesSubscriber) {
      this.getInsurancesSubscriber.unsubscribe();
    }
  }

  updatePracticeForm(formVals: any) {
    console.log(formVals);
    const _self = this;
    jQuery(document).ready(function () {
      _self._loadingIndicatorService.start();
      _self.successMessage = null;

      let value = Object.assign({}, formVals);
      console.log(value);
      let billing_info = _self.formatDataBillingInfo(value);
      //value.opening_hours = this.openingHourDirective.getData();
      value.billing = billing_info;
      if (_self.insurancesSelector.getData() !== null) {
        value.insurances = _self.insurancesSelector.getData();
      } else {
        value.insurances = [];
      }

      // Get the checked box index before sending out save request
      //let currentCheckedBoxIndex = this.openingHourDirective.getCurrentCheckedBoxIndex();
      if (_self.updatePracticeSubscriber) {
        _self.updatePracticeSubscriber.unsubscribe();
      }
      _self.updatePracticeSubscriber = _self._restService.updatePractice(value).subscribe(
        (success: any) => {
          _self._loadingIndicatorService.stop();
          _self.successMessage = success.error_messages;
          _self.responseError = new PracticeDetail();
        },
        (error: any) => {
          _self._loadingIndicatorService.stop();
          let res = error.error;
          if (res.error_code === 'RC060') {
            _self.responseError = res.error_fields;
          } else if (res.error_code === 'RC070') {
            _self.showPopUpWarning(res.error_messages);
          } else {
            _self.responseError = res.error_fields;
          }
          /* let messagePos1 = '';
          let messagePos2 = '';
          let messagePos3 = '';
          if ((<any>this.responseError)['opening_hours.0.days'] && (<any>this.responseError)['opening_hours.0.days'].length !== 0) {
              messagePos1 = (<any>this.responseError)['opening_hours.0.days'];
          } else if ((<any>this.responseError)['opening_hours.0.end_time'] && (<any>this.responseError)['opening_hours.0.end_time'].length !== 0) {
              messagePos1 = (<any>this.responseError)['opening_hours.0.end_time'];
          }
          if ((<any>this.responseError)['opening_hours.1.days'] && (<any>this.responseError)['opening_hours.1.days'].length !== 0) {
              messagePos2 = (<any>this.responseError)['opening_hours.1.days'];
          } else if ((<any>this.responseError)['opening_hours.1.end_time'] && (<any>this.responseError)['opening_hours.1.end_time'].length !== 0) {
              messagePos2 = (<any>this.responseError)['opening_hours.1.end_time'];
          }
          if ((<any>this.responseError)['opening_hours.2.days'] && (<any>this.responseError)['opening_hours.2.days'].length !== 0) {
              messagePos3 = (<any>this.responseError)['opening_hours.2.days'];
          } else if ((<any>this.responseError)['opening_hours.2.end_time'] && (<any>this.responseError)['opening_hours.2.end_time'].length !== 0) {
              messagePos3 = (<any>this.responseError)['opening_hours.2.end_time'];
          }

          let showError = [{ index: 0, message: messagePos1 }, { index: 1, message: messagePos2 }, { index: 2, message: messagePos3}];
          this.openingHourDirective.injectError(currentCheckedBoxIndex, showError); */

          let data: any = [];
        }, () => {
          _self._loadingIndicatorService.stop();
        }
      );
    });
  }

  showPopUpWarning(titleWarning: string) {
    this._alertService.onClose = () => {
      location.reload();
    };
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = titleWarning;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  formatDataBillingInfo(data: any) {
    console.log(data);
    return {
      'first_name': data.billing_first_name,
      'last_name': data.billing_last_name,
      'email': data.billing_email,
      'phone_mobile': data.billing_phone_mobile,
      'address': data.billing_address,
      'address2': data.billing_address2,
      'city': data.billing_city,
      'state_code': data.billing_state_code,
      'zip': data.billing_zip
    };
  }

  onKeyUp(event: any, error: string) {
    this.successMessage = null;

    switch (error) {
      case 'name':
        this.responseError['name'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'insurances':
        this.responseError['insurances'] = null;
        break;
      case 'billing_first_name':
        (<any>this.responseError)['billing.first_name'] = null;
        break;
      case 'billing_last_name':
        (<any>this.responseError)['billing.last_name'] = null;
        break;
      case 'billing_email':
        (<any>this.responseError)['billing.email'] = null;
        break;
      case 'billing_phone_mobile':
        (<any>this.responseError)['billing.phone_mobile'] = null;
        break;
      case 'billing_address':
        (<any>this.responseError)['billing.address'] = null;
        break;
      case 'billing_address2':
        (<any>this.responseError)['billing.address2'] = null;
        break;
      case 'billing_city':
        (<any>this.responseError)['billing.city'] = null;
        break;
      case 'billing_state_code':
        (<any>this.responseError)['billing.state_code'] = null;
        break;
      case 'billing_zip':
        (<any>this.responseError)['billing.zip'] = null;
        break;
    }
  }

  preventWhiteSpaceInput(event: any) {
    if (event.target.value.match(/\s/g)) {
      event.target.value = event.target.value.replace(/\s/g, '');
    }
  }


}
