<div class="container admin-account no-padding">
  <h3 class="row d-none d-md-block font-size-24">Practice Management</h3>
  <h3 class="row d-block d-sm-none d-none d-sm-block d-md-none text-center font-size-24">Practice Management</h3>
  <rtn-table #table
      (onDataChange)="onDataChange($event)"
      [autoloading]="false"
      [data]="practiceList.data"
      [rowsOnPage]="practiceList.rowsOnPage"
      [numberOfPages]="practiceList.numberOfPages">
      <div class="row">
          <rtn-table-filter #filter class="width-100">
              <form #form="ngForm" (ngSubmit)="onSearchSubmited(table)" class="row">
                  <div class="col-sm-12 col-md-8 col-lg-7 no-padding-left-right">
                      <button type="button" [routerLink]="['/admin', 'add-practice']" class="btn btn-rtn">
                          <span class="label">Add Practice</span>
                      </button>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-5 pull-right no-padding-left-right row">
                    <div class="col-sm-9 col-md-9 col-lg-9 no-padding-left-right">
                        <input class="form-control margin-left-15 no-margin" type="text" placeholder="Enter Practice Name" name="searchText" [(ngModel)]="searchText">
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3 no-padding-left-right">
                        <button type="submit" class="btn btn-rtn btn-180 pull-right">
                            <i class="fas fa-search"></i>
                            <span class="label">Search</span>
                        </button>
                    </div>
                  </div>
              </form>
          </rtn-table-filter>

          <div class="col-md-12 no-padding-left-right search-box listview">
              <table class="table table-bordered">
                  <thead class="account-thead">
                      <tr class="row">
                          <th class="account-thead-text col-2 col-lg-2 col-md-2">Practice ID</th>
                          <th class="account-thead-text col-4 col-lg-4 col-md-4">Name</th>
                          <th class="account-thead-text col-6 col-lg-6 col-md-6">Address</th>
                      </tr>
                  </thead>

                  <tbody class="account-tbody">
                      <tr *ngFor="let item of table.data; let i = index" class="row">
                          <td class="col-2 col-lg-2 col-md-2">
                                <div class="account-id" [routerLink]="['/admin', 'edit-practice', item.id]">{{ item.id_show }}</div>
                          </td>
                          <td class="col-4 col-lg-4 col-md-4">{{ item.name }}</td>
                          <td class="col-6 col-lg-6 col-md-6">{{ item.fullAddress }}</td>
                      </tr>
                  </tbody>

                  <tfoot *ngIf="table.numberOfPages > 1">
                      <tr>
                          <td colspan="12" class="text-center table-responsive no-overflow-y">
                              <div class="page-footer">
                                  <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                              </div>
                          </td>
                      </tr>
                  </tfoot>
              </table>
          </div>
      </div>
  </rtn-table>
  <div class="text-center">{{ errorMessage }}</div>
</div>
