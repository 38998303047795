import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterSchedulesService } from '../master-schedules.service';
import { Utils } from '@app/shared/utils';
import { SystemData, SystemService } from '../../shared/system';
@Component({
  selector: 'app-arrival-confirmation',
  templateUrl: './arrival-confirmation.component.html',
  styleUrls: ['./arrival-confirmation.component.scss']
})
export class ArrivalConfirmationComponent implements OnInit, AfterViewInit {
  validateSubscriber: Subscription;
  updateArriveTimeSubcriber: Subscription;
  comfirmInfo = new comfirmInfo();
  clicked:any=false;
  showbackbutton:any=false;

  constructor(
    private _utils: Utils,
    private _restService: MasterSchedulesService,
    private loadingIndicatorService: LoadingIndicatorService,
    private _routeParams: ActivatedRoute,
    private router: Router,
    public _systemData: SystemData,
    public _systemService: SystemService
  ) { 
    jQuery('html, body').css('background-color', '#f3f3f3'); 
  }


  ngOnInit() {

    if (typeof (this._systemData.localStorage.user_token) !== 'undefined' && this._systemData.localStorage.user_token !== null) {
      this._systemService.setLoginedState(true);
      //console.log('arrival link : ', this._systemData);
    }

    //console.log(this._systemData.localStorage.getItem('user_id'));

    const currentUrl = this.router.url;
    const splitArr = currentUrl.split("/");
    const schedule_id = splitArr[splitArr.length - 2];
    const code = splitArr[splitArr.length - 1];
    this.loadingIndicatorService.start();
    this.validateSubscriber = this._restService.validateCode(code, schedule_id).subscribe(
      (row : any) => {
        this.loadingIndicatorService.stop();
        var userId = this._systemData.localStorage.getItem('user_id');

        if ( userId != undefined && userId != null && userId == row['userId']){
          this.showbackbutton = true;
        }
      },
      (e: any) => {
        const error = e.error;
        this.loadingIndicatorService.stop();
        
        var userId = this._systemData.localStorage.getItem('user_id');

        if ( userId != undefined && userId != null && error['error_fields'] != undefined && userId == error['error_fields']['userId']){
          this.showbackbutton = true;
        }

        if (error.error_code === 'RC002') {
          if(error.error_messages){
            this.comfirmInfo.result = Object.values(error.error_messages);
          }
        }else{
          if(error.error_messages){
            this.comfirmInfo.result = [error.error_messages];
          }
        }
      },
      // () => {
      //   this.loadingIndicatorService.stop();
      // }
    );
  }
  ngAfterViewInit(): void {
    
  }
  
  updateArrive() {
    const currentUrl = this.router.url;
    const splitArr = currentUrl.split("/");
    const schedule_id = splitArr[splitArr.length - 2];
    const code = splitArr[splitArr.length - 1];
    this.loadingIndicatorService.start();
    this.showbackbutton = false;

    this.updateArriveTimeSubcriber = this._restService.saveArrTime(code, schedule_id).subscribe(
      (row : any) => {
        this.loadingIndicatorService.stop();
        this.clicked = true;

        var userId = this._systemData.localStorage.getItem('user_id');

        if ( userId != undefined && userId != null && userId == row['userId']){
          this.showbackbutton = true;
        }

      },
      (e: any) => {
        const error = e.error;
        
        var userId = this._systemData.localStorage.getItem('user_id');

        if ( userId != undefined && userId != null && error['error_fields'] != undefined && userId == error['error_fields']['userId']){
          this.showbackbutton = true;
        }

        this.loadingIndicatorService.stop();
        if (error.error_code === 'RC002') {
          if(error.error_messages){
            this.comfirmInfo.result = Object.values(error.error_messages);
          }
        }else{
          if(error.error_messages){
            this.comfirmInfo.result = [error.error_messages];
          }
        }
      },
    
    );
  }
}

export class comfirmInfo {
  result: any = null;
}
