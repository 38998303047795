<!--<rtn-provider-header></rtn-provider-header>-->
<div class="container admin-account px-0">
    <h3 class="row d-block provider-appointment">List of all Patients</h3>

    <rtn-table #table (onDataChange)="onDataChange($event)" [autoloading]="false" [data]="accountList.data"
        [rowsOnPage]="accountList.rowsOnPage" [numberOfPages]="accountList.numberOfPages">
        <div class="row">
            <rtn-table-filter class="w-100" #filter class="col-lg-12 px-0">
                <form #form="ngForm" (ngSubmit)="onSearchSubmited(table)">
                    <div class="row col-12 pr-0 justify-content-end">
                        <div class="col-xl-3 col-8 col-md-4 pr-0">
                            <input class="form-control margin-left-15 height_34" type="text" placeholder="Enter Email or Name"
                                [(ngModel)]="searchText" name="searchText">
                        </div>
                        <div class="px-0 btn_90 ml_30">
                            <button type="submit" class="btn btn-rtn btn-180 float-right">
                                <i class="fas fa-search"></i>
                                <span class="label">Search</span>
                            </button>
                        </div>
                    </div>
                </form>
            </rtn-table-filter>

            <div class="col-sm-12 no-padding-left-right search-box listview">
                <table class="table table-bordered">
                    <thead class="account-thead">
                        <tr>
                            <th class="account-thead-text col-email">Email</th>
                            <th class="account-thead-text col-full-name">Full Name</th>
                            <th class="account-thead-text col-dob">Date of Birth</th>
                            <th class="account-thead-text col-phone">Phone Mobile</th>
                            <th class="account-thead-text col-address">Address</th>
                            <th class="account-thead-text col-status">Status</th>
                            <th *ngIf="isProvider" class="account-thead-text col-action">Action</th>
                        </tr>
                    </thead>

                    <tbody class="account-tbody">
                        <tr *ngFor="let item of table.data; let i = index">
                            <td class="account-email">{{ item.email }}</td>
                            <td class="account-full-name">{{ item.name_show }}</td>
                            <td class="account-dob">{{ item.dob }}</td>
                            <td class="account-phone">{{ item.phone_mobile }}</td>
                            <td class="account-address">{{ item.full_address }}</td>
                            <td class="account-status">{{ item.status_show }}</td>
                            <td *ngIf="isProvider" class="account-action"><a
                                    [routerLink]="['/provider', item.id, 'view-appointments']">View appointments</a>
                            </td>
                        </tr>
                    </tbody>

                    <tfoot *ngIf="table.numberOfPages > 1">
                        <tr>
                            <td colspan="12" class="text-center table-responsive no-overflow-y">
                                <div class="page-footer">
                                    <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </rtn-table>
    <div class="text-center">{{ errorMessage }}</div>
</div>