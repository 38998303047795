import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
// import { ROUTER_DIRECTIVES } from '@angular/router';
// import { AlertDownloadService } from './alert-download.service';
import {SystemData} from '@app/shared/system';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {Router} from '@angular/router';

@Component({
  selector: 'rtn-alert-download',
  moduleId: module.id,
  templateUrl: './alert-download.component.html',
  styleUrls: ['./alert-download.component.scss'],
  // directives: [ROUTER_DIRECTIVES]
})
export class AlertDownloadComponent implements OnInit, OnDestroy {

  isShow = false;
  downloadText: string;
  downloadLabel: string;
  downloadUrl: string;
  openUrl: string;

  getDownloadAlertSubscriber: Subscription;

  @Input()
  position = 'fixed';
  @Input()
  width = '100%';
  @Output()
  onClick: EventEmitter<any> = new EventEmitter();
  @Output()
  checkDevice: EventEmitter<any> = new EventEmitter();

  constructor(
    private _systemData: SystemData,
    private service: RestService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    if (this._router.url !== 'home/page-not-found') {
      return;
    }
    console.log('1');
    if (localStorage.getItem('app_download_ignore') !== '1') {
      console.log('2');
      // If the user already Close the previous prompt (1) OR never actually entered the site (null)
      if (this.getDownloadAlertSubscriber) {
        console.log('3');
        this.getDownloadAlertSubscriber.unsubscribe();
      }
      this.getDownloadAlertSubscriber = this.service.getDownloadAlert().subscribe(
        (success: any) => {
          console.log(success);
          if (success.error_code === 'RC000') {
            if (success.download) {
              // This is mobile platform, show the header bar
              this.downloadText = success.download.text;
              this.downloadLabel = success.download.label;
              this.downloadUrl = success.download.link;
              this.openUrl = success.download.app_link;
              this.isShow = true;
              this.checkDevice.emit(this.isShow);
              localStorage.setItem('app_download_ignore', '0');
            } else {
              // This is not mobile platform, hide the header bar
              localStorage.removeItem('app_download_ignore');
              this.isShow = false;
            }
          } else if (success.error_code === 'TL001') {
            this._router.navigate(['/home', 'page-not-found']);
          }

        }, (error: any) => {
          // Todo: Set Link valid = false => Hide change password form
          this.isShow = false;
          if (error.error_code === 'TL001') {
            this._router.navigate(['/home', 'page-not-found']);
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.getDownloadAlertSubscriber) {
      this.getDownloadAlertSubscriber.unsubscribe();
    }
  }

  close() {
    this.isShow = false;
    localStorage.setItem('app_download_ignore', '1');
    this.onClick.emit('');
    if (!this.isShow) {
      jQuery('.carousel.slider').css('margin-top', '52px');
    }
  }

}
