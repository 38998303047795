import {Directive, Input, Output, OnInit, AfterViewInit, OnDestroy, ElementRef, EventEmitter} from '@angular/core';
// import 'inputmask.dependencyLib'
// import 'inputmask.extensions'
// import 'inputmask.date.extensions'
// import 'inputmask.numeric.extensions'
// import 'inputmask.phone.extensions'
// import 'inputmask.regex.extensions'
// import 'jquery.inputmask';

declare let jQuery: any;
declare var $: any;

@Directive({
  selector: '[simplemask]'
})
export class SimpleMaskDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input() format: any;
  @Input() target: any;
  @Input() field: string;

  @Output() OnComplete: EventEmitter<any> = new EventEmitter();
  @Output() OnIncomplete: EventEmitter<any> = new EventEmitter();
  @Output() OnKeyDown: EventEmitter<any> = new EventEmitter();
  @Output() onBeforePaste: EventEmitter<any> = new EventEmitter();

  public element: any;

  constructor(
    public el: ElementRef
  ) {
    this.element = jQuery(this.el.nativeElement);
  }

  ngOnInit() {
    this.element.simpleMask({
      'mask': this.format
    });
    this.element.on('keyup', () => {
      setTimeout(() => {
        this.target[this.field] = this.element.val();
      }, 0);
    });
  }

  ngAfterViewInit() {
    this.element.bind('paste', (e: Event) => {
      this.target[this.field] = jQuery(e.target).val();
    });
  }

  ngOnDestroy() {
    // do nothing
  }

  invoke(...args: any[]) {
    this.element.select2.apply(this.element, args);
  }

  open() {
    this.invoke('open');
  }

  close() {
    this.invoke('close');
  }
}
