export class SearchOpenings {
  patient = '';
  patient_user_id = '';
  patient_name = '';
  family_id = 0;
  disease: any;
  specialty: any;
  provider = '';
  provider_name = '';
  providerName = '';
  insurance_company_id = '';
  insurance_plan_id = '';
  insurance_id = '';
  insurance_number = '';
  zip = '';
  distance = '100';
  show = '0';
  start_time = '';
  end_time = '';
  enable_email = 0;
  enable_sms = 0;
  email: string;
  mobile: string = '';
  first: string;
  middle: string;
  last: string;
  start_date = '';
  end_date = '';
  start_date_display = '';
  end_date_display = '';
  provider_selected: any = [];
  start_datetime = '';
  end_datetime = '';
  sort_order = 4;
  appointment_reason = '';
  apt_type : any = '';
  apt_reason : string ; 
  provider_office_id:any='';
  id:any=0;
  advance_notice_duration:any='';
  schedule_id=0;
}
