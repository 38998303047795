import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomepageService } from '@app/home/home.service';
import { Subscription } from 'rxjs';
import { AlertService } from '@app/shared/alert';
import { AlertDownloadComponent } from '@app/shared/alert-download/alert-download.component';
import { RestService } from '@app/core/rest.service';
import { SystemData } from '@app/shared/system';

// declare let jQuery: any;
import * as $ from 'jquery';
import * as jQuery from 'jquery';
import 'popper.js';
import 'bootstrap';

import {QuoteService} from './quote.service';

@Component({
  selector: 'rtn-home',
  moduleId: module.id,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: []
})
export class HomeComponent implements OnInit, AfterViewInit {

  positionOfSlide = 0;
  src = '../../../assets/img/homepage/';
  sliders: { id: number; src: string; title: string; class: string }[];
  features: { id: number; src: string, title: string, url: string }[];
  capabilities: { title: string; desc: string }[];
  desc = 'We’ve all experienced it. Waiting for hours on end in your doctor’s office, checking the clock ' +
    'wondering if your name will ever be called. Or worse yet, leaving in frustration without ever being seen. ' +
    'Although the circumstances may differ, it’s an all too common theme but one heard everyday around the world: ' +
    '“What about my time?” Solving the number - one complaint by patients - and medical providers alike - ' +
    'is the reason Real Time Neighborhood - RTN - was created.';

  @ViewChild(AlertDownloadComponent) _alertDownload: AlertDownloadComponent;

  constructor(
    private _router: Router,
    private service: RestService,
    public _systemData: SystemData,
  ) {
    this.sliders = [
      {
        id: 0,
        src: this.src + 'banner_01.png',
        title: 'Isn’t it time you stopped waiting?',
        class: 'active'
      },
      {
        id: 1,
        src: this.src + 'banner_02.png',
        title: 'Time is money. Make the most of yours.',
        class: ''
      },
      {
        id: 2,
        src: this.src + 'banner_03.png',
        title: 'We calculate the wait time so you can make the most out of your day.',
        class: ''
      },
      {
        id: 3,
        src: this.src + 'banner_04.png',
        title: 'Your time. Spend it wisely.',
        class: ''
      }
    ];

    this.features = [
      {
        id: 0,
        src: this.src + 'forpatient.png',
        title: 'for patients',
        url: '/Login'
      },
      {
        id: 1,
        src: this.src + 'fordoctor.png',
        title: 'for doctor',
        url: '/Login'
      },
      {
        id: 2,
        src: this.src + 'requestdemo.png',
        title: 'request demo',
        url: '/Login'
      },
      {
        id: 3,
        src: this.src + 'getApp.png',
        title: 'get app',
        url: '/Login'
      }
    ];

    this.capabilities = [
      {
        title: 'Time Management',
        desc: 'Avoid waiting in your doctor’s office and spend your time well.'
      },
      {
        title: 'Notifications',
        desc: 'Receive custom notifications sent directly to your phone or inbox with real time wait info.'
      },
      {
        title: 'Appointments',
        desc: 'Integrates directly with most EHR\'s and seamlessly with your current workflow.'
      },
      {
        title: 'Customer Service',
        desc: 'Improve relationships between doctors and patients'
      }
    ];

    if (jQuery(window).width() < 768) {
      setTimeout(() => {
        this.positionOfSlide = $('.navbar-static-top').height();
      }, 1000);
    }
  }

  ngOnInit(): void {

  }


  ngAfterViewInit() {
    // jQuery('.carousel').carousel();
  }

  navigate(id: number) {
    switch (id) {
      case 0:
        this._router.navigate(['/home', 'login']);
        break;

      case 1:
        this._router.navigate(['/home', 'login']);
        break;

      case 2:
        this._router.navigate(['/home', 'login']);
        break;

      case 3:
        this._router.navigate(['/home', 'login']);
        break;

      default:
        // do nothing
        break;
    }
  }

}
