<footer class="marketing-footer" style="margin-left: -15px; margin-right: -15px">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 company-info">
          <h4>INFO</h4>
          <ul class="list-unstyled">
            <li><a href="https://www.realtimeneighborhood.com/about-us" class="footer-hover">About RTN</a></li>
            <li><a href="mailto:Admin@realtimeneighborhood.com?subject=Request a Demo" target="_self" data-content="Admin@realtimeneighborhood.com" data-type="mail" role="button" class="footer-hover">Request A Demo</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 physic-directory">
          <h4>FEATURES PATIENTS</h4>
          <ul class="list-unstyled">
            <li><a href="https://www.realtimeneighborhood.com/patients" class="footer-hover">Scheduling</a></li>
            <li><a href="https://www.realtimeneighborhood.com/patients" class="footer-hover">Notifications</a></li>
            <li><a href="https://www.realtimeneighborhood.com/patients" class="footer-hover">Enhanced Search</a></li>
            <li><a href="https://www.realtimeneighborhood.com/patients" class="footer-hover">Family Members</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 company-info">
          <h4>FEATURES PROVIDERS</h4>
          <ul class="list-unstyled">
            <li><a href="https://www.realtimeneighborhood.com/providers" class="footer-hover">Productivity</a></li>
            <li><a href="https://www.realtimeneighborhood.com/providers" class="footer-hover">Communications</a></li>
            <li><a href="https://www.realtimeneighborhood.com/providers" class="footer-hover">Happier Patients </a></li>
            <li><a href="https://www.realtimeneighborhood.com/providers" class="footer-hover">EHR Integration </a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 physic-directory">
          <h4>CONTACT</h4>
          <ul class="list-unstyled">
            <li><a href="mailto:Admin@realtimeneighborhood.com?subject=Request a Demo" target="_self" data-content="Admin@realtimeneighborhood.com" data-type="mail" role="button" class="footer-hover">Request A Demo</a></li>
            <li><a href="mailto:Admin@realtimeneighborhood.com?subject=Contact Us" target="_self" data-content="Admin@realtimeneighborhood.com" data-type="mail" role="button" class="footer-hover">Send Us A Message</a></li>
            <li><a href="mailto:info@realtimeneighborhood.com" data-type="mail" data-auto-recognition="true" data-content="info@realtimeneighborhood.com" class="footer-hover">info@realtimeneighborhood.com</a></li>
            <li><a href="https://www.realtimeneighborhood.com/contact-us" class="footer-hover">Contact Support</a></li>
          </ul>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 company-info">
          <h4>CLIENTS</h4>
          <ul class="list-unstyled">
            <li><a href="https://www.realtimeneighborhood.com/patients" class="footer-hover">For Patients</a></li>
            <li><a href="https://www.realtimeneighborhood.com/providers" class="footer-hover">For Providers</a></li>
  
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 company-info">
          <h4>&nbsp;</h4>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 company-info">
          <h4>DIRECTORY</h4>
          <ul class="list-unstyled">
            <li><a href="/home/physician-directory" class="footer-hover">Provider Directory</a></li>
  
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 company-info">
          <h4>PRIVACY POLICY</h4>
          <ul class="list-unstyled">
            <li><a href="https://www.realtimeneighborhood.com/privacy-policy" class="footer-hover">Privacy policy</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copright-text text-center"><strong>Copyright &copy; 2017 <a href="#">Real Time Neighborhood</a>.</strong> All rights reserved.</div>
  </footer>
  