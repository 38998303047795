import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  getUnreadNotificationsUrl = environment.serverUrl + '/notifications/unread';
  getTopNotificationListUrl = environment.serverUrl + '/notifications/top';
  getNotificationGlobalSettingUrl = environment.serverUrl + '/patients/notification-settings';
  updateNotificationGlobalSettingUrl = environment.serverUrl + '/patients/notification-settings';
  readNotificationUrl = environment.serverUrl + '/notifications/';
  deleteNotificationsUrl = environment.serverUrl + '/notifications';
  getAllNotificationListUrl = environment.serverUrl + '/notifications';
  getReceiversUrl = environment.serverUrl + '/receivers';
  massNotificationUrl = environment.serverUrl + '/notifications/mass';
  massNotificationUrlToPatient = environment.serverUrl + '/notifications/mass-to-patient';
  searchPatientUrl = environment.serverUrl + '/patients';
  bookAppointmentUrl = environment.serverUrl + '/appointment/book';
  deleteSlotUrl = environment.serverUrl + '/available-slot/delete';
  fetchAvailableSlotByPeriodUrl = environment.serverUrl + '/appointment/available-slot';
  patientsUrl = environment.serverUrl + '/notifications/patients';

  constructor(private _http: HttpClient) {
  }
// Get all Patients of the system
  getPatients() {
    return this._http.get(this.patientsUrl);
  }
  getSearchPatients(searchTerm:any) {
    const params = new HttpParams().set('searchTerm', searchTerm);
    return this._http.get(this.patientsUrl, {params});
  }
  fetchAvailableSlotByPeriod(startTime: any, endTime: any) {
    const params = new HttpParams().set('start_time', startTime).set('end_time', endTime);
    return this._http.get(this.fetchAvailableSlotByPeriodUrl, {params});
  }

  deleteSlot(data: any) {
    return this._http.post(this.deleteSlotUrl, data);
  }

  bookAppointment(data: any) {
    return this._http.post(this.bookAppointmentUrl, data);
  }

  debugMode() {
    return !environment.production;
  }

  massNotification(data: any) {
    return this._http.post(this.massNotificationUrl, JSON.stringify(data));
  }
  massNotificationToPatient(data: any) {
    return this._http.post(this.massNotificationUrlToPatient, JSON.stringify(data));
  }

  getReceivers() {
    return this._http.get(this.getReceiversUrl);
  }

  getNotificationList(start_id: any, page: any, limit: any) {
    const params = new HttpParams().set('limit', limit).set('page', page);
    return this._http.get(this.getAllNotificationListUrl, {params});
  }

  deleteNotifications(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: JSON.stringify(data)
    };
    return this._http.delete(this.deleteNotificationsUrl, httpOptions);
  }

  readNotification(notificationId: any, dataRead: any) {
    return this._http.put(this.readNotificationUrl + notificationId, JSON.stringify(dataRead));
  }

  updateNotificationGlobalSetting(data: any) {
    return this._http.post(this.updateNotificationGlobalSettingUrl, JSON.stringify(data));
  }

  getNotificationGlobalSetting() {
    return this._http.get(this.getNotificationGlobalSettingUrl);
  }

  getUnreadNotifications() {
    return this._http.get(this.getUnreadNotificationsUrl);
  }

  getTopNotificationList(limit: any) {
    const params = new HttpParams().set('limit', limit);
    return this._http.get(this.getTopNotificationListUrl, {params});
  }

  searchPatient(key: any) {
    const params = new HttpParams().set('key', key);
    return this._http.get(this.searchPatientUrl, {params: params});
  }
}
