<div class="row">
  <div class="col-4 no-padding-left">
    <input type="text" class="input-phone form-control" [(ngModel)]="formInput.first" maxlength="5" datamax="3"
           mask="(000)" [dropSpecialCharacters]="false"
           [attr.disabled]="statusDisabled ? true : null" [class.input-error]="error" (keyup)="onKeyUp()" [disabled]="isDisabled">
  </div>
  <div class="col-4 no-padding-left"> 
    <input type="text" class="input-phone form-control" [(ngModel)]="formInput.middle" maxlength="3" datamax="3"
           mask="000" [dropSpecialCharacters]="false"
           [attr.disabled]="statusDisabled ? true : null" [class.input-error]="error" (keyup)="onKeyUp()" [disabled]="isDisabled">
  </div>
  <div class="col-4 no-padding">
    <input type="text" class="input-phone form-control" [(ngModel)]="formInput.last" maxlength="4" datamax="4"
           mask="0000" [dropSpecialCharacters]="false"
           [attr.disabled]="statusDisabled ? true : null" [class.input-error]="error" (keyup)="onKeyUp()" [disabled]="isDisabled">
  </div>
</div>
