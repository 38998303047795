import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';

// import {Body} from '@angular/http/src/body';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  getModeUrl = environment.serverUrl + '/mode';
  getStatesUrl = environment.serverUrl + '/states';
  getSpecialtiesUrl = environment.serverUrl + '/specialties';
  getProviderProfileUrl = environment.serverUrl + '/provider/profile';
  getPatientProfileUrl = environment.serverUrl + '/users/profile';
  getInsuranceListUrl = environment.serverUrl + '/insurance-list';
  getDownloadAlertUrl = environment.serverUrl + '/download/alert';
  getPhysicianDirectoryUrl = environment.serverUrl + '/physician-directory';
  addPracticeUrl = environment.serverUrl + '/practice/add';
  getProfileUrl = environment.serverUrl + '/practice/profile';
  getInsurancePlanUrl = environment.serverUrl + '/insurance-plans';
  getPracticeListUrl = environment.serverUrl + '/practices';
  fetchAllLocationUrl = environment.serverUrl + '/provider/fetchAllLocation';

  constructor(private _http: HttpClient) {
  }

  debugMode() {
    return !environment.production;
  }

  getMode() {
    return this._http.get(this.getModeUrl);
  }

  getStates() {
    return this._http.get(this.getStatesUrl);
  }

  getSpecialties() {
    return this._http.get(this.getSpecialtiesUrl);
  }

  getProviderLocations() {
    return this._http.get(this.fetchAllLocationUrl);
  }

  getInsuranceList() {
    return this._http.get(this.getInsuranceListUrl);
  }

  getDownloadAlert() {
    return this._http.get(this.getDownloadAlertUrl);
  }

  getPhysicianDirectory() {
    return this._http.get(this.getPhysicianDirectoryUrl);
  }

  getProviderProfile() {
    return this._http.get(this.getProviderProfileUrl);
  }

  getPatientProfile() {
    return this._http.get(this.getPatientProfileUrl);
  }

  getPracticeList() {
    return this._http.get(this.getPracticeListUrl);
  }

  getProfile() {
    return this._http.get(this.getProfileUrl);
  }

  getInsurancePlan(company: any) {
    const params = new HttpParams().set('insurance_company_id', company);
    return this._http.get(this.getInsurancePlanUrl, {params});
  }

  addPractice(data: any) {
    return this._http.post(this.addPracticeUrl, data);
  }

}
