<div class="book-appointment">
  <div class="book-appointment-page">
    <div class="content-page">
      <div class="text-header-title">APPOINTMENT</div>
      <div class="text-name">
        <span>{{ providerInfo.fullName }}</span><span *ngIf="providerInfo.credentials">,</span>
        <span>{{ providerInfo.credentials }}</span>
      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-sm-3 col-lg-2 col-xl-1 px-0 text-value">Specialty:</div>
          <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">{{ providerInfo.specialties }}</div>
        </div>

      </div>
      

      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-sm-3 col-lg-2 col-xl-1 px-0 text-value">Date:</div>
          <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">{{ _utils.getDateTimeFormat(_systemData.selectOpenSlotData.start_time) }}</div>
        </div>

      </div>


      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-sm-3 col-lg-2 col-xl-1 px-0 text-value">Address:</div>
          <ng-container *ngIf="_systemData.selectOpenSlotData.location_info != null">
           
              <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">
                {{ _systemData.selectOpenSlotData.location_info.address1 }},
                <ng-container *ngIf="_systemData.selectOpenSlotData.location_info.address2 !== ''">
                  {{ _systemData.selectOpenSlotData.location_info.address2 }},
                </ng-container>
                <ng-container *ngIf="_systemData.selectOpenSlotData.location_info.city !== ''">
                  {{ _systemData.selectOpenSlotData.location_info.city }},
                </ng-container>
                <ng-container *ngIf="_systemData.selectOpenSlotData.location_info.state !== ''">
                  {{ _systemData.selectOpenSlotData.location_info.state }},
                </ng-container>
                <ng-container *ngIf="_systemData.selectOpenSlotData.location_info.zipcode !== ''">
                  {{ _systemData.selectOpenSlotData.location_info.zipcode }}
                </ng-container>
              </div>
            
          </ng-container>
          <ng-container *ngIf="_systemData.selectOpenSlotData.location_info == null">
            <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">
              <!--<div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right" *ngIf="providerInfo.location_name != undefined && providerInfo.location_name !=''">
                {{ providerInfo.location_name }}
              </div>-->
              <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">
                {{ providerInfo.address }}
              </div>
              <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">
                {{ providerInfo.address2 }}
              </div>
              <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">
                <span>{{ providerInfo.city }}</span><span *ngIf="providerInfo.state_code !== null">,</span>
                <span>{{ providerInfo.state_code }}</span>
                <span>{{ providerInfo.zip }}</span>
              </div>
            </div>
          </ng-container>
          
        </div>

      </div>


      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-sm-3 col-lg-2 col-xl-1 px-0 text-value">Patient:</div>
          <div class="col-12 col-sm-9 col-lg-10 col-xl-11 px-0">{{ patientInfo.first_name + ' ' + patientInfo.last_name }}</div>
        </div>

      </div>


      <form method="post" #form="ngForm" (ngSubmit)="submitBookAppointment(bookAppointment)">
        <div class="text_info">
          <div class="row w-100">
            <div class="no-padding-left-right input-rtn pull-left margin-left-0">
              <input class="checkbox check-single" type="checkbox" name="settingReminder" id="settingReminder" [value]="bookAppointment.is_reminder"
                     [(ngModel)]="bookAppointment.is_reminder" #settingReminder>
              <label class="selectorExtreme margin-top-8" for="settingReminder"></label>
            </div>
            <div class="col-10 col-xl-10 col-sm-11 col-md-11 col-lg-11 no-padding-left-right padding-top-3">Yes, send me a reminder before my appointment (when enabled by Provider)</div>
          </div>

        </div>
        <div class="text_info">
        <div class="input-group select select-time-style">
          <select class="form-control" [value]="bookAppointment.reminder_day" [(ngModel)]="bookAppointment.reminder_day" [disabled]="!bookAppointment.is_reminder" #selectTime name="select-reminder">
            <option *ngFor="let time of timeSetting" [value]="time.value">{{time.name}}</option>
          </select>
          <div class="input-group-select-icon">
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div>
        <div class="text_info">
          <div class="no-padding-left-right input-rtn pull-left margin-left-0">
            <input class="checkbox check-single" type="checkbox" name="settingWaitTime" id="settingWaitTime" [value]="bookAppointment.add_followed"
                   [(ngModel)]="bookAppointment.add_followed">
            <label class="selectorExtreme margin-top-8" for="settingWaitTime"></label>
          </div>
          <div class="col-11 col-xl-11 col-sm-11 col-md-11 col-lg-11 no-padding-left-right padding-top-3 ">Add this Provider to my Followed Provider list</div>
        </div>

        <div class="text_info">          
          <div class="col-12 col-sm-4 col-md-6 col-xl-3  no-padding-left-right padding-top-3 ">
            <label class="input-label-required">Apt. Type</label>
            <div class="input-group select">              
              <select class="form-control textbox-edit-form width-full" [(ngModel)]="bookAppointment.event_reason_id" name="event_reason_id" ngControl="event_reason_id">
                <option [value]="''" selected>Select Type</option>
                <option *ngFor="let typeItem of appointmentTypeList" [value]="typeItem.id">{{typeItem.label}}</option>
              </select>
              <div class="input-group-select-icon cursorPointer select-state">
                  <i class="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="text_info">          
          <div class="col-12 col-sm-4 col-md-6 col-xl-4  no-padding-left-right padding-top-3 ">
            <label>Apt. Reason(Description)</label>
            <div class="">
              <textarea id="appointment_reason" name="appointment_reason" [(ngModel)]="bookAppointment.appointment_reason" class="form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="text_info">
          <rtn-patient-future-appointment [loadedpage]="loadedpage" [aptlists]="futureaptlists" [providerId]="0" [patientId]="0" (selecteRescheduleId)="selecteRescheduleId($event)" *ngIf="futureaptlists.length > 0"></rtn-patient-future-appointment>
        </div>

        <div class="row">
          <div class="alert alert-warning d-flex align-items-center m-0" role="alert">
            
            <div>
              <p class="m-0"><strong>Appointment Note:</strong> <span>If a referral is needed for your visit, please obtain it before arriving. Assistance? Contact our office.</span></p>
            </div>
          </div>
        </div>

        <div class="text_info">          
          <div class="col-12 no-padding-left-right padding-top-3 ">
            <button class="btn btn-rtn text-uppercase" type="submit">BOOk APPOINTMENT</button>            
          </div>
          <!-- <div class="col-8 col-xl-8 col-sm-8 col-md-8 col-lg-8 no-padding-left-right padding-top-3 ">
            <div class="apt-referal-notes">
              <p><strong>Appointment Note:</strong> <span>If a referral is needed for your visit, please obtain it before arriving. Assistance? Contact our office.</span></p>
            </div>
          </div> -->
        </div>
        
      </form>
    </div>
  </div>
</div>
