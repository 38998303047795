import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { SuperManagingUserDetail } from '../../shared/models/superManagingUserDetail';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';


@Component({
  selector: 'app-add-super-user',
  templateUrl: './add-super-user.component.html',
  styleUrls: ['../admin.component.scss','./add-super-user.component.scss'],
  moduleId: module.id,
})
export class AddSuperUserComponent implements OnInit {

  superUserDetail: SuperManagingUserDetail = new SuperManagingUserDetail();
    responseError: SuperManagingUserDetail = new SuperManagingUserDetail();
    addNewSuperUserSubscriber: Subscription;
    loadPracticeListSubscriber: Subscription;
    practiceList: any = [];
    practiceDefault: any;
    @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
    @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;
    constructor(
        public _systemData: SystemData,
        private _systemService: SystemService,
        private _alertService: AlertService,
        private _adminService: AdminRestService,
        private loadingIndicatorService: LoadingIndicatorService
    ) {}

    ngOnInit() {
        this._systemService.getStates();
        if (this.loadPracticeListSubscriber) {
            this.loadPracticeListSubscriber.unsubscribe();
        }
        this.loadPracticeListSubscriber = this._adminService.getPracticeList().subscribe(
            (success: any) => {
                this.practiceList = success.practices;
                this.practiceList.map((item: any) => {
                    if (item.name === 'RTN') {
                        //Set default value for office
                        this.superUserDetail.office_name = this.practiceDefault = item.id;
                    }
                });
            }
        );
    }

    ngOnDestroy() {
        if (this.loadPracticeListSubscriber) {
            this.loadPracticeListSubscriber.unsubscribe();
        }
        if (this.addNewSuperUserSubscriber) {
            this.addNewSuperUserSubscriber.unsubscribe();
        }
    }

    addSuperUserForm(formVals: any) {
        const self = this;
        this.loadingIndicatorService.start();
        let value = Object.assign({}, formVals);
        if (this.addNewSuperUserSubscriber) {
            this.addNewSuperUserSubscriber.unsubscribe();
        }
        value.type = 'SUPER_USER';
        this.addNewSuperUserSubscriber = this._adminService.addNewSuperUser(value).subscribe(
            (success: any) => {
                this.loadingIndicatorService.stop();
                this._alertService.onClose = () => {
                    this.superUserDetail = new SuperManagingUserDetail();
                    this.responseError = new SuperManagingUserDetail();
                    this.superUserDetail.office_name = this.practiceDefault;
                };
                this._alertService.showDismissButton = false;
                this._alertService.dismissButtonLabel = 'Close';
                this._alertService.showCloseButton = true;
                this._alertService.closeButtonLabel = 'OK';
                this._alertService.title = success.error_messages;
                this._alertService.message = '';
                this._alertService.emitter('open');
            },
            (error: any) => {
                this.loadingIndicatorService.stop();
                let res = error.error;
                this.responseError = res.error_fields;
            }
        );
    }

    onKeyUp(error: string) {
        switch (error) {
            case 'email':
                this.responseError['email'] = null;
                break;
            case 'phone_mobile':
                this.responseError['phone_mobile'] = null;
                break;
            case 'address':
                this.responseError['address'] = null;
                break;
            case 'address2':
                this.responseError['address2'] = null;
                break;
            case 'city':
                this.responseError['city'] = null;
                break;
            case 'state_code':
                this.responseError['state_code'] = null;
                break;
            case 'zip':
                this.responseError['zip'] = null;
                break;
            case 'first_name':
                this.responseError['first_name'] = null;
                break;
            case 'middle_name':
                this.responseError['middle_name'] = null;
                break;
            case 'last_name':
                this.responseError['last_name'] = null;
                break;
            case 'office_name':
                this.responseError['office_name'] = null;
                break;
        }
    }
}
