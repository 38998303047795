<div class="marketing-page container forgot-password">
  <div class="content-header row" [style.min-height.px]="minHeight">
    <div class="login-form col-12 col-md-9 col-lg-10 offset-lg-1 offset-sm-0 offset-0">
        <div class="login-form-body col-lg-6">
            <h1 [class.has-error]="error" [class.has-error]="success" class="text-uppercase">{{pageTitleText}}</h1>
            <form novalidate #form="ngForm" (ngSubmit)="reset()">
                <div *ngIf="error" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
                <div *ngIf="success" class="alert alert-success" role="alert">Email sent successfully!</div>
                <div class="form-group">
                    <label for="email">Enter your email address and we will send you an email to reset your password</label>
                    <input type="text" id="email" class="form-control" autoFocusCustom ngControl="email" name="email" maxlength="254" placeholder="example@example.com" required [(ngModel)]="email" (ngModelChange)="onChange()">
                </div>
                <div class="row form-submit">
                    <div class="col-12 col-md-12 col-lg-4 no-padding">
                        <button type="submit" [disabled]="isDisabled" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius">Send</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
  </div>
</div>