<div *ngIf="isShow" class="container-fluid alert-download navbar-fixed-top" [style.width]="width" [style.position]="position">
  <div class="alert-content">
    <div class="col-xs-4 col-sm-2 text-center">
      <span class="close-btn fa fa-close pull-left" (click)="close()"></span>
      <img class="app-icon" src="../../../assets/img/logo.png" alt="icon RTN">
    </div>
    <div class="col-xs-5 col-sm-8 app-text">
      <span class="text-content">{{downloadText}}</span>
    </div>
    <div class="col-xs-3 col-sm-2 app-download">
      <a href="{{downloadUrl}}" class="btn btn-sm download pull-right" role="button">{{downloadLabel}}</a>
    </div>
  </div>
</div>
