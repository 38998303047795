<div class="container-fluid p-0 w-100">
    <div class="row" *ngIf="!errorMessage">
        <header class="w-100">
            <rtn-alert-download [class.alert-download]="openAlertDownload" (checkDevice)="handleCheckDevice($event)"
                (click)="onClose($event)"></rtn-alert-download>
            <section class="navbar-aside-outer d-flex align-items-center flex-md-row flex-column">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6 col-sm-5 col-12 socialMedia d-flex align-items-center flex-row">
                            <!--<h6>Follow Us:</h6>
                            <ul class="list-group d-flex align-items-center flex-row">
                              <li><a href="#" target="_blank" title="Facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                              <li><a href="#" target="_blank" title="Twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                              <li><a href="#" target="_blank" title="Instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                              <li><a href="#" target="_blank" title="Linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                            </ul>-->
        
                        </div>
                        <div class="contact-info col-lg-6 col-sm-7 col-12 d-flex justify-content-end align-items-center">
                            <ul class="d-flex align-items-center flex-row">
                                <!--<li class="d-flex align-items-center">
                                    <span><img src="{{ src }}phone_icon.png"></span>
                                    <span><a href="tel:2122263126">(+212)-226-3126</a></span>
                                  </li>-->
                                <li class="d-flex align-items-center">
                                    <span><img src="assets/img/homepage/newDesign/envelope_icon.png"></span>
                                    <span><a
                                            href="mailto:info@realtimeneighborhood.com">info@realtimeneighborhood.com</a></span>
                                </li>
                            </ul>
        
                        </div>
                    </div>
                </div>
            </section>
            <section class="container-fluid position-relative">
                <nav class="d-flex justify-content-between navbar navbar-expand-lg col-sm-12 col-md-12 px-3 py-2"
                    [class.has-download]="_systemData.localStorage.getItem('app_download_ignore') === '0' || _systemData.localStorage.getItem('app_download_ignore') === null">
                    <div class="d-flex justify-content-between align-items-center navWrapper">
                        <div class="logoSection">
                            <a class="navbar-brand logo-position d-none d-sm-block no-focus m-auto"
                                href="https://www.realtimeneighborhood.com" target="_blank">
                                <img class="rtn-logo m-auto" src="assets/img/homepage/newDesign/logo.jpg"
                                    alt="Realtimeneighborhood Logo" />
                            </a>
                            <a class="navbar-brand d-block d-sm-none no-focus m-auto" [routerLink]="['/home']">
                                <img class="rtn-logo m-auto" src="assets/img/homepage/newDesign/logo.jpg"
                                    alt="Realtimeneighborhood Logo" />
                            </a>
        
                        </div>
                        <div class="mobileLogin">
                            <div class="login-info" *ngIf="!_systemData.isLogined">
                                <button type="button" class="navbar-toggler" (click)="showCollapse()">
                                    <i class="fa fa-bars" aria-hidden="true"></i>
                                </button>
                                <ul class="d-flex">
                                    <!--<li role="presentation">
                      <a class="d-flex justify-content-center align-items-center" [routerLink]="['/home', 'front-sign-up']">
                          <span>SignUp</span>
                          <span class="arrowIcon"><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
                      </a>
                  </li>-->
                                    <li role="presentation">
                                        <a class="d-flex justify-content-center align-items-center"
                                            [routerLink]="['/home', 'login']">
                                            <span>Log In</span>
                                            <span class="arrowIcon"><img src="assets/img/homepage/newDesign/RightArrow.svg"
                                                    alt="Arrow Icon" /></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="_systemData.isLogined">
                                <div class="btn-group userDropdown">
                                    <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                           <span class="profileName"> PO</span>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right" *ngIf="_systemData.isLogined">
                                      <button class="dropdown-item" type="button">{{_systemData.localStorage.getItem('userFullName')}}</button>
                                      <button class="dropdown-item" type="button" *ngIf="!isManagingMode"  (click)="logoutDirective.open()">Log Out</button>
                                      <button class="dropdown-item" type="button" *ngIf="isManagingMode" (click)="logoutManaging()">Log Out</button>
                                    </div>
                                  </div>
                                </div>
                        </div>
                    </div>
                    <div class="collapse navbar-collapse d-lg-flex justify-content-lg-end justify-content-sm-start align-items-center"
                        id="btn_menu_collspse">
                        <ul class="navbar-nav">
                            <li role="presentation" class="nav-item">
                                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/about-us"
                                    target="_blank">About Us </a>
                            </li>
                            <li role="presentation" class="nav-item">
                                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/features"
                                    target="_blank">Features</a>
                            </li>
                            <li role="presentation" class="nav-item">
                                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/providers"
                                    target="_blank">Providers</a>
                            </li>
                            <li role="presentation" class="nav-item">
                                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/patients"
                                    target="_blank">Patients</a>
                            </li>
                            <li role="presentation" class="nav-item">
                                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/contact-us"
                                    target="_blank">Contact Us</a>
                            </li>
                            <li role="presentation" class="nav-item"
                                *ngIf="_systemData.isLogined && _systemData.localStorage.getItem('user_role_id') == '4'">
                                <a class="no-focus nav-link" [routerLink]="['/patient', 'dashboard']"
                                    [class.active]="_systemData.isLogined && showHighlight">User Portal</a>
                            </li>
        
                        </ul>
                        <div *ngIf="_systemData.isLogined">
                        <div class="btn-group userDropdown">
                            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span>PO</span> 
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" *ngIf="_systemData.isLogined">
                              <button class="dropdown-item" type="button">{{_systemData.localStorage.getItem('userFullName')}}</button>
                              <button class="dropdown-item" type="button" *ngIf="!isManagingMode"  (click)="logoutDirective.open()">Log Out</button>
                              <button class="dropdown-item" type="button" *ngIf="isManagingMode" (click)="logoutManaging()">Log Out</button>
                            </div>
                          </div>
                        </div>
                        <div class="desktopLogin">
                            <div class="login-info" *ngIf="!_systemData.isLogined">
                                <ul class="d-flex">
                                    <!--<li role="presentation">
                              <a class="d-flex justify-content-center align-items-center" [routerLink]="['/home', 'front-sign-up']">
                                  <span>SignUp</span>
                                  <span class="arrowIcon"><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
                              </a>
                          </li>-->
                                    <li role="presentation">
                                        <a class="d-flex justify-content-center align-items-center"
                                            [routerLink]="['/home', 'login']">
                                            <span>Log In</span>
                                            <span class="arrowIcon"><img src="assets/img/homepage/newDesign/RightArrow.svg"
                                                    alt="Arrow Icon" /></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                           
                        </div>
                    </div>
        
                </nav>
            </section>
            <rtn-modals-general-logout></rtn-modals-general-logout>
        </header>
        <div class="col-md-12 Rtn-Meter p-0">
            <!-- <div class="waitmeter-top-part px-3 pb-0 pt-1 d-flex justify-content-center">
                <img class="logo" src="assets/rtnmeter-images/images/rtn-logo.png">
            </div> -->
            <div class="rtnmeter-main d-xl-flex justify-content-center align-items-center py-3">
                <div class="wait-meter container-md w-sm-100 mt-2 mb-2 p-0">
                    <div class="head-part d-flex justify-content-between align-items-center border-bottom">
                        <button class="btn undo mr-lg-5" type="button" (click)="reloadSlots()"><img class="rotate"
                                src="assets/rtnmeter-images/images/reload.png"></button>
                        <div class="wait-time  pl-lg-5 ml-lg-5">Available Slots</div>                        
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <label class="message-error pull-right hovernotes pt-1">Please hover over an appointment slot to see the provider's location for this appointment</label>
                    </div>
                   
                    <div class="col-md-12 form meter-main px-0 py-0 d-xl-flex">
                        <div class="wrapper-patient col-md-12" *ngIf="searchOpeningsResult.length > 0">
                            <div class="container availableSlots" *ngIf="searchOpeningsResult.length > 0">                                
                                <!-- Provider Box -->
                                <div class="border-bottom mb-4" *ngFor="let openings of searchOpeningsResult; let i = 'index'">  
                                    <div class="row py-2">
                                        <h6>{{ openings.fullProviderName }}</h6>
                                    </div>                                 
                                    <div class="row py-2">
                                        <!-- <div class="col-12 col-md-3">
                                            <h6>{{ openings.fullProviderName }}</h6>
                                            <span class=" mb-1 d-block" *ngIf="openings.credentials">{{ openings.credentials }},</span>
                                            <span class=" mb-1 d-block" *ngIf="openings.specialties">{{ _utils.getSpecialties(openings.specialties) }},</span>
                                            <span class=" mb-1 d-block">{{ openings.address }},</span>
                                            <span class=" mb-1 d-block" *ngIf="openings.address2">{{ openings.address2 }},</span>
                                            <span class=" mb-1 d-block" >
                                                <ng-container *ngIf="openings.city">{{ openings.city }},</ng-container>
                                                <ng-container *ngIf="openings.state_code != null">{{ openings.state_code }},</ng-container>
                                                <ng-container *ngIf="openings.zip">{{ openings.zip }},</ng-container>
                                            </span>                                            
                                            <span class=" mt-3 d-block font-weight-bold">Phone: {{ openings.phone_mobile }}</span>
                                            <span class=" mt-3 d-block font-weight-bold">
                                                <pre *ngIf="openings.note_for_provider" class="note-block-content" [innerHtml]="openings.note_for_provider"></pre>
                                            </span>
                                        </div> -->
                                        <div class="col-12 p-0 d-flex flex-wrap appointment_schedule">
                                            <!-- Date Box -->
                                            <div class="col-12 col-sm-6 col-md-4 col-xl-3 pr-0 mb-3" *ngFor="let appointment of openings.open_appointment; let i = index;">
                                                <div class="col-12 border p-0">
                                                    <div class="border-bottom py-2 text-center bg-light">
                                                        <h6 class="font-weight-normal m-0">{{ _utils.formatDate(appointment.date) }}</h6>
                                                    </div>
                                                    <div class="p-2 row">
                                                        <ng-container *ngFor="let slot of appointment.slots">
                                                            <div class="col-12">
                                                                <div class="search-slot text-center" *ngIf="slot.start_time !== ''" (click)="goToBookAppointment(openings.open_slot_id, openings.provider_id,  slot.slot_id,  openings.practice_open_notify_type)" title="{{slot.location_name}}">{{ slot.start_time }} - {{slot.end_time}}</div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- End Date Box -->                                            
                                        </div>
                                    </div>
                                </div>
                                <!-- Provider Box -->                                 
                                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row mt-5" *ngIf="errorMessage">
        <div class="col-md-12">
            <div class="errorsection">
                <p class="label-danger">{{ errorMessage }}</p>
            </div>
        </div>
    </div>
</div>
<rtn-embed-book-modal (callEventBookEmitter1)="handlecallEventBookEmitter1()"></rtn-embed-book-modal>
<rtn-common-footer *ngIf="!errorMessage"></rtn-common-footer>