import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QuickAptbookingComponent } from './quick-aptbooking.component'

const routes: Routes = [
  {
    path: '',
    component: QuickAptbookingComponent,
    children: [
      {
        path: 'smartbooking/:id', ///:schedule_id/:code
        component: QuickAptbookingComponent          
      }
    ],
}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuickAptbookingRoutingModule { }
