<modal #ProviderUnfollow_Modals class="provider-unfollow">
    <div *ngIf="minimumSelectedStatus">
        <modal-header>
            <h4 class="modal-title">{{messageConfirmUnfollow}}</h4>
        </modal-header>
        <div class="modal-footer">
            <button type="button" class="btn btn-rtn-inv btn-90" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-rtn btn-90" data-dismiss="modal" (click)="unfollowProvider($event)">OK</button>
        </div>
    </div>
    <div *ngIf="!minimumSelectedStatus">
        <modal-header>
            <h4 class="modal-title">{{messageMinimumSelect}}</h4>
        </modal-header>
        <div class="modal-footer">
            <button type="button" class="btn btn-rtn btn-90" data-dismiss="modal">OK</button>
        </div>
    </div>
</modal>