import {Component, EventEmitter, OnDestroy, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';
// import {AppointmentCheckOutService} from '@app/appointments/modals/appointment-check-out/appointment-check-out.service';
import {AlertService} from '@app/shared/alert';
import {Event} from '@app/appointments/timeline/timeline.component';
import {Subscription} from 'rxjs';

import * as moment from 'moment';
import {AppointmentsService} from '@app/appointments/appointments.service';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
  moduleId: module.id,
  selector: 'rtn-modals-appointment-check-out',
  templateUrl: './appointment-check-out.component.html',
  styleUrls: ['./appointment-check-out.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentCheckOutComponent implements OnDestroy {

  checkOutSubscriber: Subscription;

  @ViewChild('AppointmentCheckOut_Modals') modal: ModalComponent;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();

  eventEntry: Event;

  hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  errorMessage: string;
  requireMessage: string;
  currentTime: string;

  // ========== Specific Modal Method ========== //

  checkOutModel: CheckOutClass = new CheckOutClass();

  constructor(
    private Service: AppointmentsService,
    private _alertService: AlertService
  ) {
    // TODO
  }

  ngOnDestroy() {
    if (this.checkOutSubscriber) {
      this.checkOutSubscriber.unsubscribe();
    }
  }

  checkOutClicked() {
    this.checkTime();
    if (this.checkOutSubscriber) {
      this.checkOutSubscriber.unsubscribe();
    }

    this.checkOutSubscriber = this.Service.checkOut(this.eventEntry.data.id, {
      time: moment().hour(parseInt(this.checkOutModel.hour, 10))
        .minute(this.checkOutModel.minute).second(0).utc().format('YYYY-MM-DD HH:mm:ss')
    }).subscribe(
      (success: any) => {
        this.reload.emit(this.eventEntry);
        const checkInTime = this.eventEntry.data.events[1].startTimeFormatted24h;
        switch (success.error_code) {
          case 'RC000':
            this.close();
            break;
          case 'RC020':
            break;
          case 'RC055':
            this.requireMessage = success.error_messages + ' [' + checkInTime + ', ' + this.currentTime + ']';
            break;
          default:
            this.errorMessage = success.error_messages;
            this.close();
            this.openErrorMessage();
            break;
        }
        // if (success.error_code === 'RC000') {
        //   this.reload.emit(this.eventEntry);
        //   this.close();
        // }
      },
      (error: any) => {
        // this.reload.emit(this.eventEntry);
        // const checkInTime = this.eventEntry.data.events[1].startTimeFormatted24h;
        const e = error.error;
        // switch (e.error_code) {
        //   case 'RC020':
        //     break;
        //   case 'RC055':
        //     this.requireMessage = e.error_messages + ' [' + checkInTime + ', ' + this.currentTime + ']';
        //     break;
        //   default:
        //     this.errorMessage = e.error_messages;
        //     this.close();
        //     this.openErrorMessage();
        //     break;
        // }
      });

  }

  openErrorMessage() {
    this._alertService.onClose = () => {
      this.reload.emit(this.eventEntry);
    };
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = this.errorMessage;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  validCheckOutPopup() {
    this.checkOutModel.hour = moment().hour().toString();
    this.checkOutModel.hour.length === 1 ? this.checkOutModel.hour = '0' + this.checkOutModel.hour : this.checkOutModel.hour;
    // this.checkOutModel.minute = (moment().minutes() - moment().minutes() % 5).toString();
    // this.checkOutModel.minute.length === 1 ? this.checkOutModel.minute = '0' + this.checkOutModel.minute : this.checkOutModel.minute;
    this.checkOutModel.minute = moment((moment().minutes()).toString(), 'm').format('mm');
    this.requireMessage = null;
  }

  checkTime() {
    const currentMinute = moment().minutes();
    this.currentTime = moment().format('HH') + ':' + moment(currentMinute, 'm').format('mm');
  }

  onSelectClicked() {
    this.requireMessage = null;
  }

  // ========== General Modal Method ========== //

  open() {
    this.modal.open();
    this.validCheckOutPopup();
  }

  close() {
    this.modal.close();
  }
}

export class CheckOutClass {
  hour: string;
  minute: any;
}
