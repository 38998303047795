import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {environment} from '@env/environment';
import {CoreModule} from '@app/core';
import {SharedModule} from '@app/shared';
import {HomeModule} from './home/home.module';
import {ShellModule} from './shell/shell.module';
import {AboutModule} from './about/about.module';
import {AppComponent} from './app.component';
import {AdminModule} from './admin/admin.module';
import {AppRoutingModule} from './app-routing.module';
import {MasterSchedulesModule} from '@app/master-schedules/master-schedules.module';
import {DocumentsComponent} from './documents/documents.component';
import {DocumentDeleteComponent} from './documents/modals/document-delete/document-delete.component';
import {DocumentUploadComponent} from './documents/modals/document-upload/document-upload.component';
import {HttpModule} from '@angular/http';
import {TokenInterceptor} from '@app/auth/token.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RestService} from './core/rest.service';
import {Utils} from './shared/utils';
import {PatientsComponent} from '@app/patients/patients.component';

import {NgIdleKeepaliveModule} from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import {MomentModule} from 'angular2-moment';
// import { ListPatientsComponent } from './list-patients/list-patients.component'; // optional, provides moment-style pipes for date formatting
import {DashboardComponent} from './patients/dashboard/dashboard.component';
import {InsuranceComponent} from './patients/insurance/insurance.component';
import {FamilyMemberComponent} from './patients/family-member/family-member.component';
import {PatientAccountComponent} from './patients/account/account.component';
import {EditPhoneConfirmComponent} from './patients/modals/edit-phone-confirm/edit-phone-confirm.component';
import {AppointmentsModule} from '@app/appointments/appointments.module';
import {ProvidersModule} from '@app/providers/providers.module';
import {DocumentsModule} from '@app/documents/documents.module';
import {PatientsModule} from '@app/patients/patients.module';
import {WaitTimeModule} from '@app/wait-time/wait-time.module'; // optional, provides moment-style pipes for date formatting
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {DataLogsModule} from '@app/data-logs/data-logs.module';
import {NgxMaskModule} from 'ngx-mask';
import {WaittimeMeterModule} from './waittime-meter/waittime-meter.module';
import { ProviderDataLogsModule } from '@app/provider-data-logs/provider-data-logs.module';
import {QuickAptbookingModule} from '@app/quick-aptbooking/quick-aptbooking.module';
import {QuickDownloadModule} from '@app/quick-download/quick-download.module';
import {ActivatephoneModule} from '@app/activatephone/activatephone.module';

@NgModule({
    imports: [
        BrowserModule,
        MDBBootstrapModule.forRoot(),
        ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
        FormsModule,
        HttpClientModule,
        MomentModule,
        TranslateModule.forRoot(),
        NgbModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        CoreModule,
        SharedModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpModule,
        HomeModule,
        ShellModule,
        AboutModule,
        AdminModule,
        AppointmentsModule,
        ProvidersModule,
        DocumentsModule,
        PatientsModule,
        WaitTimeModule,
        DataLogsModule,
        MasterSchedulesModule,
        WaittimeMeterModule,
        ProviderDataLogsModule,
        QuickAptbookingModule,
        QuickDownloadModule,
        ActivatephoneModule,
        AppRoutingModule, // must be imported as the last module as it contains the fallback route,        
        NgxMaskModule.forRoot()
    ],
    declarations: [AppComponent],
    providers: [
        RestService,
        Utils,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
