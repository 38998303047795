<div class="row px-3">
    <div class="col-5 input-dob select no-padding-left">
      <select class="form-control" [(ngModel)]="formInput.month" [attr.disabled]="disabledForm" [class.input-error]="error" (change)="onSelect()" (ngModelChange)="callEventOnChangeEmit()">
          <option value="null" selected disabled hidden>Month</option>
          <option class="select-option" *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
      </select>
      <div class="cursorPointer selecter">
          <i class="fa fa-caret-down"></i>
      </div>
  </div>
  <div class="col-3 no-padding-left input-dob select">
      <select class="form-control" [(ngModel)]="formInput.day" [attr.disabled]="disabledForm" [class.input-error]="error" (change)="onSelect()" (ngModelChange)="callEventOnChangeEmit()">
          <option value="null" selected disabled hidden>DD</option>
          <option class="select-option" *ngFor="let day of days" [value]="day">{{day}}</option>
      </select>
      <div class="cursorPointer selecter pr-3">
          <i class="fa fa-caret-down"></i>
      </div>
  </div>
  <div class="col-4 no-padding input-dob select">
      <select class="form-control" [(ngModel)]="formInput.year" [attr.disabled]="disabledForm" [class.input-error]="error" (change)="onSelect()" (ngModelChange)="callEventOnChangeEmit()">
          <option value="null" selected disabled hidden>YYYY</option>
          <option class="select-option" *ngFor="let year of years" [value]="year">{{year}}</option>
      </select>
      <div class="cursorPointer selecter pr-0">
          <i class="fa fa-caret-down"></i>
      </div>
  </div>
</div>
