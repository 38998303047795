<!--<rtn-appointment-header></rtn-appointment-header>-->
<div class="row patient-preferences">
  <h2 class="patient-preferences-header col-12">Preferences</h2>

  <div class="patient-preferences-content mb-5">
    <!-- //Wait time trigger -->
    <form method="post" #preferences="ngForm" (ngSubmit)="submitSetting(preferences.value)">
      <div class="preferences-block" *ngIf="module[1]">
        <h4>Notifications</h4>
        <div class="panel panel-default">
          <!-- //Body -->
          <div class="panel-body">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 block">
              <div class="row">
                <!--<div class="full-width col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="is_setting_notification" id="notification-main" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="notification-main">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="notification-main">System wide patient alerts and notifications.</label>
                  </div>
                </div>-->
                <div class="full-width child-switch1 col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="alert_waittime" id="notification-first" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="notification-first">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="notification-first">Send patient alerts including wait time updates and when to return to the office.</label>
                  </div>
                </div>

                <div class="full-width child-switch1 col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="is_reminder" id="notification-second" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="notification-second">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="notification-second">Send appointment reminders to patients before their scheduled appointment.</label>
                  </div>
                </div>
                <div class="full-width child-switch1 col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="alert_appointment_confirmation" id="alert_appointment_confirmation" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="alert_appointment_confirmation">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="alert_appointment_confirmation">Send appointment Confirmation / Cancel / No-Show to patient.</label>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- //Wait Time Display -->
      <div class="preferences-block"  *ngIf="module[2]">
        <h4>Wait Time Display</h4>
        <div class="panel panel-default">
          <!--<form method="post" #formAppointmentNotifications="ngForm" (ngSubmit)="submitAppointmentNotifications(formAppointmentNotifications.value)">-->
          <!-- //Body -->
          <div class="panel-body">
            <div class="col-xs-12 block">
              <div class="row">

                <div class="full-width col-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="is_waittime_display" id="wait-time-display" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="wait-time-display">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="wait-time-display">Show wait time meter to patients (only available when Notifications setting is on).</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- //Available Appointment -->
      <div class="preferences-block"  *ngIf="module[5]">
        <h4>Available Appointments</h4>
        <div class="panel panel-default">
          <!--<form method="post" #formAppointmentNotifications="ngForm" (ngSubmit)="submitAppointmentNotifications(formAppointmentNotifications.value)">-->
          <!-- //Body -->
          <div class="panel-body">
            <div class="col-xs-12 block">
              <div class="row">

                <div class="full-width col-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="show_availble_appointment" id="available-appointment" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="available-appointment">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="available-appointment">Show physician openings to patient when they search for new appointments.</label>
                  </div>
                </div>
                
                <div class="full-width col-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="expire_open_slot" id="expire_open_slot" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="expire_open_slot">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="expire_open_slot">Remove Open Slot (Created via Cancel)</label>
                  </div>
                </div>

                <div class="full-width col-12  ml-5" *ngIf="expire_open_slot == true">
                  <div class="full-width col-12 row ml-3">
                    <div class="col-sm-4">
                      <label for="expire_open_slot_threshold_limit" class="">Remove Open Slot Threshold Limit</label>
                      <div class="input-group select">
                        <select class="form-control textbox-add-form" [(ngModel)]="expire_open_slot_threshold_limit" name="expire_open_slot_threshold_limit">
                          <option [value]="''" selected>Select Limit</option>
                          <option *ngFor="let limitItem of slotThresholdLimitOptions" [value]="limitItem.value">{{ limitItem.label }}</option>
                        </select>
                        <div class="input-group-select-icon cursorPointer select-state">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>                      
                      <!--<input type="number" class="form-control" id="expire_open_slot_threshold_limit" name="expire_open_slot_threshold_limit" [(ngModel)]="expire_open_slot_threshold_limit" />-->
                    </div>                    
                  </div>
                </div>

                <div class="full-width col-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="fill_slots_equal_or_smaller" id="fill_slots_equal_or_smaller" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="fill_slots_equal_or_smaller">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="fill_slots_equal_or_smaller">Fill slots Only that are equal or smaller than the available slot</label>
                  </div>
                </div>

                <div class="full-width col-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="disable_45_rule_to_office" id="disable_45_rule_to_office" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="disable_45_rule_to_office">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="disable_45_rule_to_office">Disable 45 mins rule to office</label>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- //Footer -->
          <!--<div class="panel-footer">
              <button type="submit" class="btn btn-rtn btn-90">Save</button>
              <span *ngIf="messageResponseAppointmentSuccess" class="message-success">{{messageResponseAppointmentSuccess}}</span>
          </div>-->
          <!--</form>-->
        </div>
      </div>
      <!-- Waitlist Settings -->

      <div class="preferences-block"  *ngIf="module[5]">
        <h4>Waitlist Notification Settings</h4>
        <div class="panel panel-default">
          
          <div class="panel-body">
            <div class="col-xs-12 block">
              <div class="row">

                <div class="full-width col-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="alert_waitlist_to_patient" id="alert_waitlist_to_patient" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="alert_waitlist_to_patient">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="alert_waitlist_to_patient">Send waitlist notification to patients (Only available when Notifications setting is on).</label>
                  </div>
                </div>

                <div class="full-width col-12">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" name="alert_waitlist_to_office" id="alert_waitlist_to_office" checked (change)="removeMessage()">
                    <label class="onoffswitch-label" for="alert_waitlist_to_office">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>

                  <div class="content-switch">
                    <label for="alert_waitlist_to_office">Send waitlist notification to office manager.</label>                    
                  </div>
                </div>
                <ng-container *ngIf="alert_waitlist_to_office">
                <div class="full-width col-12 ml-5">
                  <div class="full-width col-12 row ml-3">
                    <div class="col-sm-4">
                      <label for="waitlist_alert_office_email_id" class="">Office Manager Email IDs (Use comma to add more than one email id)</label>
                      <input type="email" class="form-control" id="waitlist_alert_office_email_id" name="waitlist_alert_office_email_id" [(ngModel)]="waitlist_alert_office_email_id" />
                    </div>
                    <div class="col-sm-4">
                      <label for="staticEmail" class="">Send at</label>
                      <div> 
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="send_waitlist_to_office_time1" name="send_waitlist_to_office_time1" [(ngModel)]="send_waitlist_to_office_time1" value="1">
                          <label class="form-check-label" for="send_waitlist_to_office_time1">6 AM</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="send_waitlist_to_office_time2" name="send_waitlist_to_office_time2" [(ngModel)]="send_waitlist_to_office_time2" value="1">
                          <label class="form-check-label" for="send_waitlist_to_office_time2">3 PM</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="send_waitlist_to_office_time3" name="send_waitlist_to_office_time3" [(ngModel)]="send_waitlist_to_office_time3" value="1">
                          <label class="form-check-label" for="send_waitlist_to_office_time3">7 PM</label>
                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
                </ng-container>
                <div class="full-width col-12  ml-5">
                  <div class="full-width col-12 row ml-3">
                    <div class="col-sm-4">
                      <label for="waitlist_alert_office_email_id" class="">Waitlist Threshold Limit (No. of days)</label>
                      <input type="number" class="form-control" id="waitlist_threshold_limit" name="waitlist_threshold_limit" [(ngModel)]="waitlist_threshold_limit" />
                    </div>                    
                  </div>
                </div>
                
              </div>
            </div>
          </div>          
        </div>
      </div>
      <button type="submit" class="btn btn-rtn btn-90 text-uppercase">SAVE</button>
      <span *ngIf="messageSuccess" class="message-success">{{ messageSuccess }}</span>
    </form>
  </div>
</div>
