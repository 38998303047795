import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { RestService } from '@app/core/rest.service';
import { Item } from '@app/shared/models/multipleSelect';
import { RtnDataTableCoreComponent } from '@app/shared/datatable';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Utils } from '@app/shared/utils';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Select2Provider3Directive } from '@app/shared/form/select2-provider3.directive';
import * as moment from 'moment';
import { AdminRestService } from '@app/admin/admin.service';
import { AlertService } from '@app/shared/alert';
import { Select2EventtypeDirective } from '@app/shared/form/select2-eventtype.directive';

import { Select2AptlocationDirective } from '@app/shared/form/select2-aptlocation.directive';

declare let jQuery: any;
@Component({
    selector: 'app-plan-actual-comparing',
    templateUrl: './plan-actual-comparing.component.html',
    styleUrls: ['./plan-actual-comparing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PlanActualComparingComponent implements OnInit, OnDestroy, AfterViewInit {
    currentDate: any = moment();
    comparingList: any = new Object();
    statuses: any = [];
    status: any = [];
    page = 1;
    rowOfPage = 10;
    errorMessage: string;
    userStatusSubscriber: Subscription;
    userInfoSubscriber: Subscription;
    runRequest = true;
    data_chart: any;
    practiceId: string = '0';
    providerId = '0';
    getListPracticeSubscriber: Subscription;
    getListProviderSubscriber: Subscription;
    currentPage: number = 1;
    dataSearchChart: dataStatistic = new dataStatistic();
    errorResponse: dataStatistic = new dataStatistic();
    dataNumber: dataNumber = new dataNumber();
    practiceTypeSubscriber: Subscription;
    providerTypeSubscriber: Subscription;
    public practicesList: any = [];
    public providersList: any = [];
    appointmentList: any = new Array();
    isEmpty: any;
    maxDate: any;
    selectedOption: any = '#typeChart option:selected';

    eventtypeList: any;
    geteventtypeSubscriber: Subscription;

    locationList:any;
    getOfficeLocationSubscriber: Subscription;

    @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
    @ViewChild('Select2Provider3Directive') ProviderSelector: Select2Provider3Directive;
    @ViewChild('inputDateFrom') inputPractice: ElementRef;
    @ViewChild('inputDateFrom') inputProvider: ElementRef;
    @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
    @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
    @ViewChild('inputDateTo') inputDateTo: ElementRef;
    @ViewChild('clickDateTo') clickDateTo: ElementRef;
    @ViewChild(RtnDataTableCoreComponent) rtnDataTableCore: RtnDataTableCoreComponent;

    @ViewChild('Select2EventtypeDirective') eventtypeSelector: Select2EventtypeDirective;
    @ViewChild('Select2AptlocationDirective') locationSelector: Select2AptlocationDirective;

    constructor(
        private _alertService: AlertService,
        private loadingIndicatorService: LoadingIndicatorService,
        private _router: Router,
        private _utils: Utils,
        private service: DataLogsService,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _datalogsService: DataLogsService,
        private _adminService: AdminRestService,
    ) {
        this.init();
        this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
    }
    ngAfterViewInit(): void {
        const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
        const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
        const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
        if (now >= zero_oclock && now < startCurrentTime) {
            this.maxDate = moment().subtract(2, 'days').format('LL');
            this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
            this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
        } else {
            this.maxDate = moment().subtract(1, 'days').format('LL');
            this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
            this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
        }
        const _self = this;
        jQuery(_self.inputDateFrom.nativeElement).datepicker({
            dateFormat: 'MM dd, yy',
            numberOfMonths: 2,
            showButtonPanel: true,
            maxDate: moment(this.maxDate).toDate(),
            changeMonth: true,
            changeYear: true,
            onSelect: (selected: any) => {
                _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
            }
        });
        jQuery(_self.inputDateFrom.nativeElement).datepicker("setDate", this.dataSearchChart.dateFrom);
        jQuery(_self.clickDateFrom.nativeElement).click(() => {
            jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
        });

        jQuery("#showDataChart").on("click", function () {
            // _self.resetData();
            _self.updateData();
        });
        /*jQuery('#exportDataChart').on('click', function () {
            _self.export();
        });*/
    }
    verifyDataSearch() {
        this.dataSearchChart.practice = this.PracticeSelector.getData();
        this.dataSearchChart.provider = this.ProviderSelector.getData();
        this.dataSearchChart.event_reason_id = this.eventtypeSelector.getData();
        this.dataSearchChart.location_id = this.locationSelector.getData();        
    }
    export() {
        const _self = this;
        /*this.verifyDataSearch();
        const dateFrom = this.dataSearchChart.dateFrom;
        const providerId = this.dataSearchChart.provider;
        const practiceId = this.dataSearchChart.practice;
        
        const event_reason_id = this.dataSearchChart.event_reason_id;

        const status = jQuery(this.selectedOption).val();*/

        const dateFrom = this.dataSearchChart.dateFrom;
        const providerId = this.ProviderSelector.getData();
        const practiceId = this.PracticeSelector.getData();
        const event_reason_id = this.eventtypeSelector.getData();
        const location_id = this.locationSelector.getData();
        const status = jQuery(this.selectedOption).val();
        
        if (this.validate()) {
            const token = localStorage.getItem('user_token');
            jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
                httpMethod: 'POST',
                data: {
                    'token': token,
                    'practice': practiceId,
                    'providerId': providerId,
                    'choosenDate': dateFrom,
                    'status': status,
                    'chartType': 7,
                    'event_reason_id' : event_reason_id,
                    'location_id' : location_id
                }
            }).done(function () {
                // TODO
            })
                .fail(function () {
                    _self._alertService.onClose = () => {
                        location.reload();
                    };
                    // _self._alertService.showDismissButton = false;
                    // _self._alertService.dismissButtonLabel = 'Close';
                    // _self._alertService.showCloseButton = true;
                    // _self._alertService.closeButtonLabel = 'Close';
                    // _self._alertService.title = 'This document is not available to download anymore.';
                    // _self._alertService.message = '';
                    // _self._alertService.emitter('open');
                });
        } else {
            // this.resetData();
            // update dataset
        }
    }

    validatePractice(message: any) {
        if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length == 0) {
            this.errorResponse.practice = message;
            this.PracticeSelector.inputError = true;
            this.PracticeSelector.changeBorderColorPractice();
            return false;
        } else {
            this.errorResponse.practice = null;
            this.PracticeSelector.inputError = false;
            this.PracticeSelector.changeBorderColorPractice();
            return true;
        }
    }

    validateProvider(message: any) {
        if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
            this.errorResponse.provider = message;
            this.ProviderSelector.inputError = true;
            this.ProviderSelector.changeBorderColorProvider();
            return false;
        } else {
            this.removeMsgErr();
            return true;
        }
    }
    validate() {
        const validatePractice = this.validatePractice('This field is required');
        const validateProvider = this.validateProvider('This field is required');
        if (validatePractice && validateProvider) {
            return true;
        }
        return false;
    }
    updateData() {
        this.dataNumber.actualAppts = 0;
        this.dataNumber.actualMinutes = 0;
        this.dataNumber.planAppts = 0;
        this.dataNumber.planMinutes = 0;
        const status = jQuery(this.selectedOption).val();
        const dateFrom = this.dataSearchChart.dateFrom;
        const practiceID = this.PracticeSelector.getData();
        const providerID = this.ProviderSelector.getData();
        const event_reason_id = this.eventtypeSelector.getData();
        const location_id = this.locationSelector.getData();
        
        if (this.validate()) {
            this.comparingList.data = [];
            this.appointmentList = [];
            this.comparingList.numberOfPages = 0;
            this.page = 1;
            this.rtnDataTableCore.setPage(this.page, this.rowOfPage);
            this.getCompareAppt(dateFrom, practiceID, providerID, status, event_reason_id, location_id);
        } else {
            //   this.resetData();
            return;
        }
    }
    getCompareAppt(dateFrom: any, practiceID: any, providerID: any, status: any, event_reason_id : any, location_id : any) {
        this._loadingIndicatorService.start();
        this._datalogsService.getCompareAppt(dateFrom, practiceID, providerID, this.page, this.rowOfPage, status, event_reason_id, location_id).subscribe(
            (success: any) => {
                this.comparingList.data = [];
                this.appointmentList = [];
                this._loadingIndicatorService.stop();
                this.comparingList.numberOfPages = success.last_page || 0;
                this.data_chart = success.data_chart.data;
                this.dataNumber.planAppts = success.data_number_appt.number_plan_appts;
                this.dataNumber.actualAppts = success.data_number_appt.number_actual_appts;
                this.dataNumber.planMinutes = success.data_number_minute.number_plan_minutes;
                this.dataNumber.actualMinutes = success.data_number_minute.number_actual_minutes;
                if (this.data_chart.length !== 0) {
                    this.isEmpty = true;
                    for (let index = 0; index < this.data_chart.length; index++) {
                        const entry = new Entry();
                        if (this.data_chart[index].is_changed != null) {
                            entry.plan = '';
                            entry.status = this.data_chart[index].status;
                            // if (this.data_chart[index].actual.actual_check_in == null) {
                            //     entry.actual = this.data_chart[index].planned.plan_check_in + ' - ' + this.data_chart[index].planned.plan_check_out + ' (Planned)';
                            // } else {
                            //     entry.actual = this.data_chart[index].actual.actual_check_in + ' - ' + this.data_chart[index].actual.actual_check_out;
                            // }
                            entry.actual = this.getActual(
                                this.data_chart[index].is_changed,
                                this.data_chart[index].actual.actual_check_in,
                                this.data_chart[index].actual.actual_check_out,
                                this.data_chart[index].planned.plan_check_in,
                                this.data_chart[index].planned.plan_check_out,
                                null,
                                null
                            );
                        } else {
                            entry.plan = this.data_chart[index].planned.plan_check_in + ' - ' + this.data_chart[index].planned.plan_check_out;
                            entry.status = this.data_chart[index].status;
                            // const tempCheckIn = (this.data_chart[index].actual.actual_check_in == null) ? ('') : (this.data_chart[index].actual.actual_check_in);
                            // const tempCheckOut = (this.data_chart[index].actual.actual_check_out == null) ? ('') : (this.data_chart[index].actual.actual_check_out);
                            const tempCheckIn = this.getCurrentCheckIn(this.data_chart[index].actual.actual_check_in);
                            const tempCheckOut = this.getCurrentCheckOut(this.data_chart[index].actual.actual_check_out);
                            // if (tempCheckIn == '' && tempCheckOut == '') {
                            //     entry.actual = '';
                            // } else {
                            //     entry.actual = this.data_chart[index].actual.actual_check_in + ' - ' + this.data_chart[index].actual.actual_check_out;
                            // }
                            entry.actual = this.getActual(
                                this.data_chart[index].is_changed,
                                this.data_chart[index].actual.actual_check_in,
                                this.data_chart[index].actual.actual_check_out,
                                this.data_chart[index].planned.plan_check_in,
                                this.data_chart[index].planned.plan_check_out,
                                tempCheckIn,
                                tempCheckOut
                            );
                        }
                        entry.event_name = this.data_chart[index].event_name;
                        entry.plan_duration = this.data_chart[index].planned.plan_duration;
                        entry.actual_duration = this.data_chart[index].actual.actual_duration;
                        entry.location_name = this.data_chart[index].location_name;
                        this.appointmentList.push(entry);
                    }
                    this.comparingList.data = this.appointmentList;
                } else {
                    this.isEmpty = false;
                    this.comparingList.data = [];
                }


            },
            (error: any) => {
                // todo
                this._loadingIndicatorService.stop();
            }
        );
    }
    getCurrentCheckIn(actual_check_in: any) {
        if (actual_check_in == null) {
            return '';
        } else {
            return actual_check_in;
        }
    }
    getCurrentCheckOut(actual_check_out: any) {
        if (actual_check_out == null) {
            return '';
        } else {
            return actual_check_out;
        }
    }

    getActual(is_changed: any, actual_check_in: any, actual_check_out: any, plan_check_in: any, plan_check_out: any, tempCheckIn: any = null, tempCheckOut: any = null) {
        if (is_changed != null) {
            if (actual_check_in == null) {
                return plan_check_in + ' - ' + plan_check_out + ' (Planned)';
            } else {
                return actual_check_in + ' - ' + actual_check_out;
            }
        } else {
            if (tempCheckIn == '' && tempCheckOut == '') {
                return '';
            } else {
                return actual_check_in + ' - ' + actual_check_out;
            }
        }

    }

    removeMsgErr() {
        this.errorResponse.provider = null;
        this.ProviderSelector.inputError = false;
        this.ProviderSelector.changeBorderColorProvider();
    }
    onChangePractice() {
        this.comparingList.data = [];
        this.appointmentList = [];
        this.comparingList.numberOfPages = 0;
        this.removeMsgErr();
        const practice = this.PracticeSelector.getData();
        if (practice.length !== 0) {
            this.getProviderByPractice(practice);
            this.ProviderSelector.clearData();
            this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
            if (this.providersList.length > 0) {
                this.ProviderSelector.updateData(this.providersList);
            }
        } else {
            this.ProviderSelector.clearData();
        }
    }
    onChangeProvider(){
        //alert('welcome');
        //console.log('here');
        const provider_id = this.ProviderSelector.getData();
        //console.log('provider_id : ' + provider_id);

        //console.log('provider_id : ' + provider_id.length);
        if (provider_id.length !== 0) {
            this.getProviderLocation(provider_id);
           // this.locationSelector.clearData();
           // this.locationSelector.resetSelect2(jQuery('#select2aptlocation'));            
        } else {
            this.locationSelector.clearData();
        }
    }
    getProviderByPractice(practiceId: any) {
        this._loadingIndicatorService.start();
        if (this.providerTypeSubscriber) {
            this.practiceTypeSubscriber.unsubscribe();
        }
        this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
            (success: any) => {
                if (success.error_code === 'RC000') {
                    this.providersList = [];
                    success.providers.forEach((item: any) => {
                        item.full_name
                            = item.first_name + ((item.middle_name == null)
                                ? (' ')
                                : (' ' + item.middle_name + ' ')) + item.last_name;
                        this.providersList.push(item);
                    });


                }
                this._loadingIndicatorService.stop();
            },
            (error: any) => {

                this._loadingIndicatorService.stop();
            }
        );
    }
    init() {
        this.dataNumber.actualAppts = 0;
        this.dataNumber.actualMinutes = 0;
        this.dataNumber.planAppts = 0;
        this.dataNumber.planMinutes = 0;
        this.comparingList.data = new Array();
        this.comparingList.numberOfPages = 0;
        this.comparingList.rowsOnPage = 10;
        this.errorMessage = null;
    }

    ngOnInit() {
        this.PracticeSelector.clearData();
        this.ProviderSelector.clearData();
        this.eventtypeSelector.clearData();
        this.locationSelector.clearData();

        this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
        this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
        this.eventtypeSelector.resetSelect2(jQuery('#select2Eventtype'));
        this.locationSelector.resetSelect2(jQuery('#select2aptlocation'));

        const _self = this;
        _self._loadingIndicatorService.start();
        _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
            (success: any) => {
                _self.practicesList = success.practices;
                const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
                _self.getProviderByPractice(firstPractice);
                _self.getAptTypeByPractice(firstPractice);
                this._loadingIndicatorService.stop();
            });

       /*_ _self._loadingIndicatorService.start();
        self.getOfficeLocationSubscriber = _self._datalogsService.getOfficeLocation().subscribe((success: any) => {
            _self.locationList = success.locations;
            this._loadingIndicatorService.stop();
            //console.log(_self.eventtypeList);
        });*/    
    }
    getAptTypeByPractice(practiceId: any) {
        const _self = this;
        _self._loadingIndicatorService.start();
        _self.geteventtypeSubscriber = _self._datalogsService.getEventtypes(practiceId).subscribe((success: any) => {
            _self.eventtypeList = success.eventtypes;
            _self._loadingIndicatorService.stop();
            //console.log(_self.eventtypeList);
        }, (error: any) => {
            this._loadingIndicatorService.stop();
        });
    }
    getProviderLocation(provider_id: any) {
        const _self = this;
        this.locationSelector.clearData();
        this.locationSelector.resetSelect2(jQuery('#select2aptlocation'));
        _self._loadingIndicatorService.start();
        _self.getOfficeLocationSubscriber = _self._datalogsService.getOfficeLocation(provider_id).subscribe((success: any) => {
            _self.locationList = success.locations;
            _self._loadingIndicatorService.stop();
            //console.log(_self.eventtypeList);
        }, (error: any) => {
            this._loadingIndicatorService.stop();
        });
    }

    ngOnDestroy() {
        if (this.userStatusSubscriber) {
            this.userStatusSubscriber.unsubscribe();
        }
        if (this.userInfoSubscriber) {
            this.userInfoSubscriber.unsubscribe();
        }

        if (this.geteventtypeSubscriber) {
            this.geteventtypeSubscriber.unsubscribe();
        }
        if (this.getOfficeLocationSubscriber) {
            this.getOfficeLocationSubscriber.unsubscribe();
        }        
    }

    onDataChange(e: any) {
        const status = jQuery(this.selectedOption).val();
        const dateFrom = this.dataSearchChart.dateFrom;
        const practiceID = this.PracticeSelector.getData();
        const providerID = this.ProviderSelector.getData();
        const event_reason_id = this.eventtypeSelector.getData();
        const location_id = this.locationSelector.getData();

        this.page = e['currentPage'];
        this.getCompareAppt(dateFrom, practiceID, providerID, status, event_reason_id, location_id);
    }
    onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
        const status = jQuery(this.selectedOption).val();
        const dateFrom = this.dataSearchChart.dateFrom;
        const practiceID = this.PracticeSelector.getData();
        const providerID = this.ProviderSelector.getData();
        const event_reason_id = this.eventtypeSelector.getData();
        const location_id = this.locationSelector.getData();

        if (this.runRequest) {
            if (this.page === 1) {
                this.getCompareAppt(dateFrom, practiceID, providerID, status, event_reason_id, location_id);
            } else {
                dataTable.setPage(1, 10);
            }
        }
    }
}
export class dataStatistic {
    practice: any = null;
    provider: any = null;
    dateFrom: any = null;
    dateTo: any = null;
    timezone: any = null;
    type: any = null;
    doneAppt: any = null;
    cancelAppt: any = null;
    noShowAppt: any = null;
    event_reason_id: any = null;
    location_id: any = null;
}
export class dataNumber {
    planAppts: any;
    planMinutes: any;
    actualAppts: any;
    actualMinutes: any;

}
export class Entry {
    plan: any;
    actual: any;
    status: any;
    event_name : any;
    plan_duration : any;
    actual_duration : any;
    location_name : any;
}