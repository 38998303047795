<!--<rtn-provider-header></rtn-provider-header>-->
<div class="container admin-account py-3 px-0">
    <h3 class="row d-none d-md-block header-text">List of Managing User</h3>
    <h3 class="row d-block d-sm-none d-none d-sm-block d-md-none text-center header-text">List of Managing Users</h3>

    <rtn-table #table
               (onDataChange)="onDataChange($event)"
               [autoloading]="false"
               [data]="accountList.data"
               [rowsOnPage]="accountList.rowsOnPage"
               [numberOfPages]="accountList.numberOfPages">
        <div class="row">
            <rtn-table-filter #filter class="width-100">
                <form #form="ngForm" (ngSubmit)="onSearchSubmited(table)">
                    <div class="col-md-7 col-lg-7 no-padding-left-right left">
                        <button type="button" [routerLink]="['/provider/user/managing', 'add']" class="btn btn-rtn">
                            <span class="label">Add Managing User</span>
                        </button>
                    </div>
                    <div class="col-md-5 col-lg-5 no-padding-left-right right">
                        <div class="col-sm-9 col-md-9 col-xs-9 no-padding-left-right left">
                            <input class="form-control margin-left-15" type="text" placeholder="Enter Email or Name" [(ngModel)]="searchText" name="searchText">
                        </div>
                        <div class="col-md-3 col-lg-3 col-xs-3 no-padding-right right">
                            <button type="submit" class="btn btn-rtn btn-180 pull-right">
                                <span class="fa fa-search" aria-hidden="true"></span>
                                <span class="label">Search</span>
                            </button>
                        </div>
                    </div>
                </form>
            </rtn-table-filter>

            <div class="col-sm-12 col-md-12 col-xs-12 no-padding-left-right search-box listview">
                <table class="table table-bordered">
                    <thead class="account-thead">
                    <tr>
                        <th class="account-thead-text col-email">Email</th>
                        <th class="account-thead-text col-full-name">Full Name</th>
                        <th class="account-thead-text col-phone">Phone Mobile</th>
                        <th class="account-thead-text col-address">Address</th>
                        <th class="account-thead-text col-status">Status</th>
                        <th class="account-thead-text col-action"></th>
                    </tr>
                    </thead>

                    <tbody class="account-tbody">
                    <tr *ngFor="let item of table.data; let i = index">
                        <td class="account-email">{{ item.email }}</td>
                        <td class="account-full-name">{{ item.name_show }}</td>
                        <td class="account-phone">{{ item.phone_mobile }}</td>
                        <td class="account-address">{{ item.full_address }}</td>
                        <td class="account-status">{{ item.status_show }}</td>
                        <td class="account-action text-center">
                            <a href="javascript:void(0);" class="btn btn-rtn btn-sm deactive" *ngIf="item.status_show === 'Full Active' || item.status_show === 'Partial Active'" (click)="deactiveAccount(item.id, table)">Deactivate</a>
                            <a href="javascript:void(0);" class="btn btn-rtn btn-sm active" *ngIf="item.status_show !== 'Full Active' && item.status_show !== 'Partial Active'" (click)="activeAccount(item.id, table)">Activate</a>
                            <a class="btn btn-rtn btn-sm" [routerLink]="['managing/edit', item.id]">Edit</a>
                            <a class="btn btn-rtn btn-sm" [routerLink]="['/provider/manage', item.id]">Providers</a>
                        </td>
                    </tr>
                    </tbody>

                    <tfoot *ngIf="table.numberOfPages > 1">
                    <tr>
                        <td colspan="12" class="text-center table-responsive no-overflow-y">
                            <div class="page-footer">
                                <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                            </div>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </rtn-table>
    <div class="text-center">{{ errorMessage }}</div>
</div>
