import { Component, OnDestroy, Input, Output, EventEmitter, ElementRef, HostBinding } from '@angular/core';
import { ModalInstance, ModalResult} from '@app/shared/modal/modal-instance';

@Component({
  selector: 'modal',
  template: `
        <div class="modal-dialog" [class.modal-sm]="isSmall" [class.modal-lg]="isLarge">
            <div class="modal-content">
                <ng-content></ng-content>
            </div>
        </div>
    `
})

export class ModalComponent implements OnDestroy {

  public instance: ModalInstance;
  public visible = false;

  @Input() animation = true;
  @Input() backdrop: string | boolean = false;
  @Input() keyboard = false;
  @Input() size: string;
  @Input() tabindex = '-1';

  @Output() onClose: EventEmitter<any> = new EventEmitter(false);
  @Output() onDismiss: EventEmitter<any> = new EventEmitter(false);
  @Output() onOpen: EventEmitter<any> = new EventEmitter(false);
  @Output() onFunction: EventEmitter<any> = new EventEmitter(false);

  @HostBinding('class.modal') get modalClass(): boolean { return true; }
  @HostBinding('class.fade') get fadeClass(): boolean { return this.animation; }
  @HostBinding('attr.data-keyboard') get dataKeyboardAttr(): boolean { return this.keyboard; }
  @HostBinding('attr.data-backdrop') get dataBackdropAttr(): string | boolean { return this.backdrop; }
  @HostBinding('attr.role') get roleAttr(): string { return 'dialog'; }
  @HostBinding('attr.tabindex') get tabIndexAttr(): string { return this.tabindex; }
  private overrideSize: string = null;

  constructor(private element: ElementRef) {
    this.instance = new ModalInstance(this.element);

    this.instance.hidden.subscribe((result) => {
      this.visible = this.instance.visible;
      if (result === ModalResult.Dismiss) {
        this.onDismiss.emit(undefined);
      }
    });

    this.instance.shown.subscribe(() => {
      this.onOpen.emit(undefined);
    });
  }

  public ngOnDestroy() {
    return this.instance && this.instance.destroy();
  }

  public routerCanDeactivate(): any {
    return this.ngOnDestroy();
  }

  public open(size?: string): Promise<void> {
    if (ModalSize.validSize(size)) { this.overrideSize = size; }
    return this.instance.open().then(() => {
      this.visible = this.instance.visible;
    });
  }

  public close(): Promise<void> {
    return this.instance.close().then(() => {
      this.onClose.emit(undefined);
    });
  }

  public function(): Promise<void> {
    return this.instance.function().then(() => {
      this.onFunction.emit(undefined);
    });
  }

  public dismiss(): Promise<void> {
    return this.instance.dismiss();
  }

  get isSmall() {
    return this.overrideSize !== ModalSize.Large
      && this.size === ModalSize.Small
      || this.overrideSize === ModalSize.Small;
  }

  get isLarge() {
    return this.overrideSize !== ModalSize.Small
      && this.size === ModalSize.Large
      || this.overrideSize === ModalSize.Large;
  }
}

export class ModalSize {
  static Small = 'sm';
  static Large = 'lg';

  static validSize(size: string) {
    return size && (size === ModalSize.Small || size === ModalSize.Large);
  }
}
