
    <form class="confirmation" [class.hidden]="!showConfirmation">
        <i [class.hidden]="!showIcon" class="fa fa-info fa-4x" aria-hidden="true"></i>
        <div class="text" style="margin-top: 30px; height: 38%;">
            <div *ngFor="let line of message">{{ line }}</div>
        </div>
        <div>
            <button type="submit" class="btn btn-rtn btn-90" (click)="handleOnYesClicked($event)">OK</button>
        </div>
    </form>
    