import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreModule} from '@app/core';
import {PatientsRoutingModule} from './patients-routing.module';
import {HeaderComponent} from './header/header.component';
import {PatientAccountComponent} from '@app/patients/account/account.component';
import {FamilyMemberComponent} from '@app/patients/family-member/family-member.component';
import {InsuranceComponent} from '@app/patients/insurance/insurance.component';
import {EditPhoneConfirmComponent} from '@app/patients/modals/edit-phone-confirm/edit-phone-confirm.component';
import {DashboardComponent} from '@app/patients/dashboard/dashboard.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@app/shared';
import {PatientsActivateComponent} from '@app/patients/active/activate.component';
import {DoctorDetailComponent} from '@app/patients/doctor-detail/doctor-detail.component';
import {DoctorFollowlComponent} from '@app/patients/doctor-follow/doctor-follow.component';
import {DoctorSearchlComponent} from '@app/patients/doctor-search/doctor-search.component';
import {PatientChangePasswordComponent} from '@app/patients/change-password/change-password.component';
import {RtnFeedbackComponent} from '@app/patients/feedback/feedback.component';
import {FeedbackRtn_ModalsComponent} from '@app/patients/patient/feedback-rtn';
import {ProviderUnfollow_ModalsComponent} from '@app/patients/patient/provider-unfollow';
import {FeedbackProvider_ModalsComponent} from '@app/patients/patient/feedback-provider';
import {AppointmentsModule} from '@app/appointments/appointments.module';
import {NotificationsModule} from '@app/notifications/notifications.module';
import {HomeModule} from '@app/home/home.module';
import {PatientsComponent} from '@app/patients/patients.component';
import {FindAppointmentsComponent} from '@app/patients/find-appointments/find-appointments.component';
import {ProvidersModule} from '@app/providers/providers.module';
import {WaitTimeModule} from '@app/wait-time/wait-time.module';
import {NgxMaskModule} from 'ngx-mask';
import {VerifyPhonenoComponent} from '@app/patients/modals/verify-phoneno/verify-phoneno.component';
import { AddPatientWaitlistComponent } from '@app/patients/find-appointments/add-patient-waitlist/add-patient-waitlist.component';

@NgModule({
  imports: [
    // CommonModule,
    // PatientsRoutingModule,
    // FormsModule,
    // ReactiveFormsModule,
    // SharedModule,
    // CoreModule,
    CommonModule,
    SharedModule,
    PatientsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppointmentsModule,
    NotificationsModule,
    HomeModule,
    ProvidersModule,
    WaitTimeModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    HeaderComponent,
    PatientAccountComponent,
    FamilyMemberComponent,
    InsuranceComponent,
    EditPhoneConfirmComponent,
    VerifyPhonenoComponent,
    DashboardComponent,
    PatientsActivateComponent,
    DoctorDetailComponent,
    DoctorFollowlComponent,
    DoctorSearchlComponent,
    PatientChangePasswordComponent,
    RtnFeedbackComponent,
    FeedbackProvider_ModalsComponent,
    ProviderUnfollow_ModalsComponent,
    FeedbackRtn_ModalsComponent,
    PatientsComponent,
    FindAppointmentsComponent,
    AddPatientWaitlistComponent,    
  ],
  exports: [
    HeaderComponent,
    PatientAccountComponent,
    FamilyMemberComponent,
    InsuranceComponent,
    EditPhoneConfirmComponent,
    DoctorDetailComponent,
    DoctorFollowlComponent,
    DoctorSearchlComponent,
    PatientChangePasswordComponent,
    RtnFeedbackComponent,
    FeedbackProvider_ModalsComponent,
    ProviderUnfollow_ModalsComponent,
    FeedbackRtn_ModalsComponent,
    FindAppointmentsComponent,
    VerifyPhonenoComponent
    
  ]
})
export class PatientsModule {
}
