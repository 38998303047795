import { Component, OnDestroy, HostListener } from '@angular/core';
import {SystemData, SystemEvent, SystemService} from '@app/shared/system';

@Component({
  selector: 'rtn-password-format-tooltip',
  templateUrl: './password-format.component.html',
  styleUrls: ['./password-format.component.scss'],
  moduleId: module.id,
})
export class PasswordFormatTooltipComponent implements OnDestroy {

    elementPopover: any;
    popoverIsOpened: boolean = false;
    constructor(
        private _systemService: SystemService
    ) {
        // do nothing
        console.log(111);
    }
    @HostListener('window:click', ['$event'])
    onClick() {
        if (this.popoverIsOpened) {
            this.popoverIsOpened = false;
            this.elementPopover.popover('dispose');
        }
    }

    toggle(e: any) {
        e.stopPropagation();
        if (this.popoverIsOpened) {
            this.popoverIsOpened = false;
            this.elementPopover.popover('dispose');
            return;
        }
        this.elementPopover = (<any>jQuery(e.target));
        this.elementPopover.popover({
            container: 'body',
            html: true,
            placement: 'auto',
            trigger: 'manual'
        });
        let popoverData = jQuery(this.elementPopover).data('bs.popover');
        jQuery(popoverData.getTipElement()).addClass('password-format-tooltip');
        popoverData.config.content = () => {
            return jQuery('#FormatPassword_Tooltip').html();
        };
        this.elementPopover.popover('show');
        this.popoverIsOpened = true;
    }

    ngOnDestroy() {
        if (this.elementPopover) {
            this.elementPopover.popover('dispose');
            this._systemService.removePopoverFromDOM(); // Please check this method in future updates
        }
    }

}
