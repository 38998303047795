<div class="marketing-page container pl-0 pr-0">
    <div class="content-header row" [style.min-height.px]="minHeight">
        <div class="login-form col-12 col-md-8 col-lg-10 offset-md-1 offset-sm-0 offset-0">
            <div class="login-form-body col-12 col-md-12 col-lg-6">
                <h1 [class.has-error]="error">LOG IN</h1>
                    <div *ngIf="error && !isNotActivate && !isDeactivate" class="alert alert-danger" role="alert">{{errorMessage}}</div>
                    <rtn-modals-resend-email></rtn-modals-resend-email>
                <form novalidate #customerForm="ngForm" (ngSubmit)="login()">
                    <div class="form-group">
                        <label for="email">Email Address</label>
                        <input type="text" tabindex="1" id="email" name="name" autoFocusCustom class="form-control" [(ngModel)]="username" placeholder="example@example.com" (ngModelChange)="enableSubmit()" maxlength="254" required>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" tabindex="2" id="password" name="password" class="form-control" [(ngModel)]="password" (ngModelChange)="enableSubmit()" maxlength="50" required>
                    </div>
                    <div class="row">
                        <div class="col-12 remember-password no-padding-left">
                            <div class="input-rtn">
                                <input tabindex="3" id="remember_me" name="remember_me" [(ngModel)]="remember_me" ngControl="remember_me" class="checkbox check-single" type="checkbox">
                                <label for="remember_me" class="selectorExtreme rtn-text-grey-85"></label>
                            </div>
                            <span class="rtn-text-grey-85">Remember Me</span>
                            <span class="pull-right"><a [routerLink]="['/home', 'forgot-password']"  class="link">Forgot password?</a></span>
                        </div>
                        <div class="col-12 col-md-4 col-lg-5 col-xl-4 no-padding">
                            <button tabindex="4" type="submit" [disabled]="isDisabled" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius">LOG IN</button>
                        </div>                        
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
