 <div class="container admin-account no-padding">
  <div class="simulator_wrapper">
    <h3 class="width-full font-size-24">Simulator</h3>
    <div class="simulator-info mt-4 ">
      <div class="col-12 px-0">
        <div class="row col-12 px-0">
          <div class="row col-12 border border-#ddd pt-3 no-print">
            <!-- <div class="col-2 col-xs-2 no-padding-right display-flex line-height-30"></div> -->
            <!-- Select pratice-->
            <div class="px-0 row col-lg-4 mb-2 pl_100">
              <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Practice:</div>
              <div
                class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0 width_70">
                Practice:</div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getPractice">
                <select #select2PracticeDirective2="select2-practice2" select2-practice2 class="form-control"
                  [value]="dataSearchChart.practice" [maximumSelectionLength]="0" [closeOnSelect]="true" [multiple]="false"
                  [tags]="false" id="select2Practice" (OnSelected)="onChangePractice()">
                  <option *ngFor="let practice of practicesList; let i = index" [id]="'practice' + practice.id"
                    [value]="practice.id">{{ practice.name }}</option>
                </select>
                <div class="message-error">{{ errorResponse.practice }}</div>
                <div class="red-alert d-none d-lg-block error_message"></div>
                <div
                  class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
                </div>
              </div>
            </div>
            <!-- Select provider-->
            <div class="px-0 row col-lg-4 mb-2 pl_100">
              <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Provider:</div>
              <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
                Provider:</div>
              <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
                <select
                #select2ProviderDirective="select2-provider"
                select2-provider 
                class="form-control"
                [value]="dataSearchChart.provider"
                [closeOnSelect]="true"
                [maximumSelectionLength]="0"
                [multiple]="false"
                id="select2Provider"
                placeholder="Select provider"
                [tags]="false"
                >
                <option value=""></option>
                <option *ngFor="let provider of providersList; let i = index" [id]="'provider' + provider.id" [value]="provider.id" >
                  {{ provider.full_name }}
                </option>
                </select>
                <div class="message-error">{{ errorResponse.provider }}</div>
                <div class="red-alert d-none d-lg-block error_message"></div>
                <div
                  class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
                </div>
              </div>
            
            </div>
          <!--date from-->
            <div class="px-0 row col-lg-4 mb-2 pl_100">
              <div class="pl-0 d-none d-lg-block find-appointment-line-title width_70 pt-1">Date:</div>
              <div
                class="width_70 text-left col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
                Date:</div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                <div class="input-group">
                  <input type="text" #inputDateFrom readonly [value]="dataSearchChart['dateFrom']"
                    class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateFrom']"
                    aria-describedby="basic-addon2">
                  <div class="input-group-append" #clickDateFrom>
                    <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                  </div>
                </div>
                <div class="message-error">{{ errorResponse.dateFrom }}</div>
              </div>
            </div>
            
            
            <div class="row col-lg-12 mb-2 float-custom">
              <div class="px-0 row col-lg-8 mb-2 pl_100">&nbsp;</div>
              <div class="px-0 row col-lg-4 mb-2 pl_100">
                <div class="ml-2">
                  <button class="btn btn-rtn load-schedule" type="button" (click)="updateData()">Load Schedule</button>
                </div>
                <div class="ml-2">
                  <button id='showDataChart' class="btn btn-rtn load-schedule px-1" type="button" (click)="resetData()">Reset</button>
                </div>
              </div>
                          
              
            </div>
          </div>
        </div>
        <div class="row col-12 mt-5"  *ngIf="appointmentData?.length > 0">                    
            <div class="col-12 col-xl-9 col-xl-9">
              <div class="checkOption">
                <div class="d-flex flex-wrap py-2">
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" value="" />
                      <span class="lable-name">AM Reminder</span>
                    </label>
                  </div>
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" value="" />
                      <span class="lable-name">Office Status</span>
                    </label>
                  </div>
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" value="" />
                      <span class="lable-name">Wait Time Changes</span>
                    </label>
                  </div>
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" value="" />
                      <span class="lable-name">RTO</span>
                    </label>
                  </div>
                  <div class="form-check mr-3">
                    <!--<label class="form-check-label">
                      <input type="checkbox" class="form-check-input" value="" />
                      <span class="lable-name">Open Slot</span>
                    </label>-->
                    <button class="btn btn-secondary dropdown-toggle btn-rtn" type="button" (click)="processSchedule()">
                      Process
                    </button>
                  </div>
                </div>
              </div>
              
              <div class="main-table table-responsive">
               
                <table class="table table-bordered" id="scheduleTable">
                  <thead>
                    <tr>
                      <th class="text-nowrap">S.No</th>
                      <th class="text-nowrap">Patent</th>
                      <th class="text-center text-nowrap">Plan-IN</th>
                      <th class="text-center text-nowrap">Plan-Out</th>
                      <th class="text-center text-nowrap">Arrived</th>
                      <th class="text-center text-nowrap">Actual-In</th>
                      <th class="text-center text-nowrap">Actual-Out</th>
                      <th class="text-center text-nowrap">Cancelled</th>
                      <th class="text-center text-nowrap">No-Show</th>
                    </tr>
                  </thead>
                  <tbody id="scheduleTable-row" >
                    <tr class="schedule-row" *ngFor="let apt of appointmentData, index as i">
                      <td class="text-nowrap">{{ i+1 }}</td>
                      <td class="text-nowrap">{{ apt.patient_name }}</td>
                      <td class="text-nowrap text-center active">{{ apt.plan_check_in }}</td>
                      <td class="text-nowrap text-center">{{ apt.plan_check_out }}</td>
                      <td class="text-nowrap text-center">{{ apt.arrived_time }}</td>
                      <td class="text-nowrap text-center">{{ apt.actual_check_in }}</td>
                      <td class="text-nowrap text-center">{{ apt.actual_check_out }}</td>
                      <td class="text-nowrap text-center">{{ apt.cancelled_at}}</td>
                      <td class="text-nowrap text-center">{{ apt.no_show}}</td>
                    </tr>
                    
                  </tbody>
                </table>
              
              </div>
            </div>
            <div class="col-12 col-xl-3 col-xl-3">
              <div class="d-flex justify-content-between mb-2">
                <ng-container *ngIf="processStatus">
                  <div class="dropdown playdrop">
                    <button class="btn btn-secondary dropdown-toggle btn-rtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Play
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <button (click)="autoPlayBtn()" class="dropdown-item" type="button">Auto Play</button>
                      <button (click)="manualPlayBtn()" class="dropdown-item" type="button">Manual Play</button>
                    </div>
                  </div>
                  <div class="d-flex auto-play" *ngIf="playDiv.autoPlay">
                    <button title="Play" (click)="playDiv.playBtn = false; playDiv.pauseBtn = true; triggerFunctionWithDelay()" class="btn btn-rtn playBtn" type="button" *ngIf="playDiv.playBtn"> 
                      <i class="fa fa-play" aria-hidden="true"></i>
                   </button>
                     <button title="Pause" (click)="playDiv.pauseBtn = false; playDiv.playBtn = true; pauseActivityBtn()" class="btn btn-rtn pauseBtn" type="button" *ngIf="playDiv.pauseBtn">
                      <i class="fa fa-pause" aria-hidden="true"></i>
                     </button>
                  </div>
                  <div class="d-flex manual-play" *ngIf="playDiv.manualPlay">
                     <button (click)="addPrevtActivityData()" [disabled]="currentIndex == 0" title="Previous" class="btn btn-rtn mr-1" type="button"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                     <button (click)="addNextActivityData()" [disabled]="currentIndex === activityData.length" title="Next" class="btn btn-rtn" type="button"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                  </div>
                </ng-container>                
              </div>
              <div class="activity-block">
                <div class="activity-head">
                  
                  <h2>Activity</h2>
                </div>
    
                <div class="list-group">
                  <ul>
                    <li class="list-group-item list-group-item-action" [ngClass]="{'activityOn' : (i == currentIndex-1) }"  *ngFor="let list of currentItem; index as i">
                      <div class="text-box">
                        <span>{{list.patentname}}</span> Send AM reminder at {{list.reminderTime}}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    

    <!--<div class="simulator-info mt-4 ">
      <div class="d-flex justify-content-between flex-wrap simulator-head mb-2">
        <h4>
          <span class="SimulatorTitle">Appointment</span>
        </h4>
        <div class="d-flex">          
          <button class="btn btn-rtn load-schedule" type="button" (click)="addAppointmentnData()">
            Load Schedule
          </button>
          <button class="btn btn-rtn mx-1" type="button">
            Add Schedule
          </button>
          <button class="btn btn-rtn px-4" type="button">Reset</button>
        </div>
      </div>
      <div class="row py-1">        
        
      </div>
      <div class="row mt-2">
        <div class="col-12 col-xl-9 col-xl-9">
          <div class="checkOption">
            <div class="d-flex flex-wrap py-2">
              <div class="form-check mr-3">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" value="" />
                  <span class="lable-name">AM Reminder</span>
                </label>
              </div>
              <div class="form-check mr-3">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" value="" />
                  <span class="lable-name">Office Status</span>
                </label>
              </div>
              <div class="form-check mr-3">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" value="" />
                  <span class="lable-name">Wait Time Changes</span>
                </label>
              </div>
              <div class="form-check mr-3">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" value="" />
                  <span class="lable-name">RTO</span>
                </label>
              </div>
              <div class="form-check mr-3">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" value="" />
                  <span class="lable-name">Open Slot</span>
                </label>
              </div>
            </div>
          </div>
          
          <div class="main-table table-responsive">
           
            <table class="table table-bordered" id="scheduleTable">
              <thead>
                <tr>
                  <th class="text-nowrap">Patent</th>
                  <th class="text-center text-nowrap">Plan-IN</th>
                  <th class="text-center text-nowrap">Plan-Out</th>
                  <th class="text-center text-nowrap">Arrived</th>
                  <th class="text-center text-nowrap">Actual-In</th>
                  <th class="text-center text-nowrap">Actual-Out</th>
                  <th class="text-center text-nowrap">Cancelled</th>
                  <th class="text-center text-nowrap">No-Show</th>
                </tr>
              </thead>
              <tbody id="scheduleTable-row" *ngIf="conversation?.length > 0" >
                <tr class="schedule-row" *ngFor="let item of conversation, index as i">
                  <td class="text-nowrap">{{ item.patentname }}</td>
                  <td class="text-nowrap text-center active">{{ item.PlanIN }}</td>
                  <td class="text-nowrap text-center">{{ item.PlanOUT }}</td>
                  <td class="text-nowrap text-center">{{ item.Arrived }}</td>
                  <td class="text-nowrap text-center">{{ item.ActualIn }}</td>
                  <td class="text-nowrap text-center">{{ item.ActualOut }}</td>
                  <td class="text-nowrap text-center">{{ item.Cancelled}}</td>
                  <td class="text-nowrap text-center">{{ item.NoShow}}</td>
                </tr>
                
              </tbody>
            </table>
          
          </div>
           <div
            class="d-flex none justify-content-between simulator-head mb-2 mt-4"
            style="display: none !important;"
          >
            <h4>
              <span class="SimulatorTitle">Wait List</span>
            </h4>
            <div class="d-flex">
              <button class="btn btn-rtn" type="button">
                Load Wait List
              </button>
              <button class="btn btn-rtn mx-1" type="button">
                Add Wait List
              </button>
            </div>
          </div> 

          <div class="main-table table-responsive" style="display: none;">
           
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-nowrap">Patent</th>
                  <th class="text-center text-nowrap">Date</th>
                  <th class="text-center text-nowrap">Time</th>
                  <th class="text-center text-nowrap">Email</th>
                  <th class="text-center text-nowrap">Phone</th>
                  <th class="text-center text-nowrap">Apt Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Patent-11</td>
                  <td class="text-nowrap text-center">06/01/2024-06/25/2025</td>
                  <td class="text-nowrap text-center">&nbsp;</td>
                  <td class="text-nowrap text-center">patent11@gmail.com</td>
                  <td class="text-nowrap text-center">000-0000-000</td>
                  <td class="text-nowrap text-center">New Visit</td>
                </tr>
                <tr>
                  <td>Patent-12</td>
                  <td class="text-nowrap text-center">06/01/2024-06/25/2025</td>
                  <td class="text-nowrap text-center">&nbsp;</td>
                  <td class="text-nowrap text-center">patent11@gmail.com</td>
                  <td class="text-nowrap text-center">000-0000-000</td>
                  <td class="text-nowrap text-center">New Visit</td>
                </tr>
                <tr>
                  <td>Patent-13</td>
                  <td class="text-nowrap text-center">06/01/2024-06/25/2025</td>
                  <td class="text-nowrap text-center">&nbsp;</td>
                  <td class="text-nowrap text-center">patent11@gmail.com</td>
                  <td class="text-nowrap text-center">000-0000-000</td>
                  <td class="text-nowrap text-center">New Visit</td>
                </tr>
              </tbody>
            </table>
         
          </div>
        </div>
        <div class="col-12 col-xl-3 col-xl-3">
          <div class="d-flex justify-content-between mb-2">
            <div class="dropdown playdrop">
              <button class="btn btn-secondary dropdown-toggle btn-rtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Play
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button (click)="autoPlayBtn()" class="dropdown-item" type="button">Auto Play</button>
                <button (click)="manualPlayBtn()" class="dropdown-item" type="button">Manual Play</button>
              </div>
            </div>
            <div class="d-flex auto-play" *ngIf="playDiv.autoPlay">
              <button title="Play" (click)="playDiv.playBtn = false; playDiv.pauseBtn = true; triggerFunctionWithDelay()" class="btn btn-rtn playBtn" type="button" *ngIf="playDiv.playBtn"> 
                <i class="fa fa-play" aria-hidden="true"></i>
             </button>
               <button title="Pause" (click)="playDiv.pauseBtn = false; playDiv.playBtn = true; pauseActivityBtn()" class="btn btn-rtn pauseBtn" type="button" *ngIf="playDiv.pauseBtn">
                <i class="fa fa-pause" aria-hidden="true"></i>
               </button>
            </div>
            <div class="d-flex manual-play" *ngIf="playDiv.manualPlay">
               <button (click)="addPrevtActivityData()" [disabled]="currentIndex == 0" title="Previous" class="btn btn-rtn mr-1" type="button"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
               <button (click)="addNextActivityData()" [disabled]="currentIndex === activityData.length" title="Next" class="btn btn-rtn" type="button"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
            </div>
          </div>
          <div class="activity-block">
            <div class="activity-head">
              
              <h2>Activity</h2>
            </div>

            <div class="list-group">
              <ul>
                <li class="list-group-item list-group-item-action" [ngClass]="{'activityOn' : (i == currentIndex-1) }"  *ngFor="let list of currentItem; index as i">
                  <div class="text-box">
                    <span>{{list.patentname}}</span> Send AM reminder at {{list.reminderTime}}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</div>