<div class="text-header-title padding-bottom-15 text-uppercase">Insurance</div>
<div class="col-12 col-lg-8 px-0 insurance-block">
    <div class="insurance-content">
        <div class="row">
            <div class="col-xs-12 col-md-12 col-lg-12 padding-bottom-15 panel-title">
                <p class="text-center text-header-title">Feature Coming Soon!</p>
                <p class="text-center">Stay tuned for exciting new capabilities</p>
            </div>
        </div>
    </div>
    
</div>
<!--<div class="col-12 col-lg-8 px-0 insurance-block">
    <div class="row insurance-header">Medical Insurance</div>
    <div class="insurance-content">
        <div class="row">
            <div class="col-xs-12 col-md-4 col-lg-4 input-label-required insurance-title">Insurance Plan</div>
            <div class="col-xs-12 col-md-8 col-lg-8 padding-bottom-15">
                <select class="form-control" id="select_company" [(ngModel)]="insuranceDetail.insurance_id" name="insurance_id"
                    (change)="changeInsurance($event.target.value)" [class.input-error]="errorMedicalInsurance.insurance_id">
                    <option value="" disabled>Select Insurance</option>
                    <option value="-1">No Insurance/Self Pay</option>
                    <option *ngFor="let plan of insuranceList" id="plan.id" [value]="plan.id">{{ plan.name }}</option>
                </select>
                <div class="cursorPointer selecter">
                    <i class="fa fa-caret-down"></i>
                </div>
                <div class="message-error" *ngIf="errorMedicalInsurance.insurance_id">{{ errorMedicalInsurance.insurance_id }}</div>
                <i class="font-text notice-text">* If you cannot find your plan in the list, please contact RTN admin</i>
            </div>
        </div>
        <div id="company_condition">
            <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-4 input-label-required insurance-title" id="id_label">ID#</div>
                <div class="col-xs-12 col-md-8 col-lg-8">
                    <input class="form-control" id="insurance_number" type="text" [(ngModel)]="insuranceDetail.insurance_number" name="insurance_number"
                    (keyup)="checkID()" maxlength="50" [class.input-error]="errorMedicalInsurance.insurance_number">
                    <div class="message-error">{{ errorMedicalInsurance.insurance_number }}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8 offset-lg-4 offset-md-4 pl-3">
                <button class="btn btn-rtn btn-90 text-uppercase" (click)="insuranceUpdateClicked()">UPDATE</button>
                <span class="message-success">{{ updateMedicalInsurance }}</span>
            </div>
        </div>
    </div>
</div>
<div class="col-12 col-lg-8 px-0 insurance-block mt-3 mb-3">
    <div class="insurance-header">
        <div class="row">Members</div>
        <div class=" row family-title-bottom">Add family/non-family member to account</div>
    </div>
    <rtn-family-member *ngFor="let member of familyMembers; let i = index" [member]="member"
        [insuranceList]="insuranceList" [patientEmailList]="patientEmailList" [index]="i">
    </rtn-family-member>
    <div class="ml-3 insurance-add padding-bottom-15" (click)="clickAddMoreFamilyMemberClicked()" *ngIf="showLinkAddFamilyMember">Add another family member</div>
</div>-->
