import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    EventEmitter,
    Output,
    ViewEncapsulation,
    AfterViewInit
} from '@angular/core';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {RestService} from '@app/core/rest.service';
import {AlertService} from '@app/shared/alert';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {NotificationsService} from '@app/notifications/notifications.service';
import {Select2PatientDirective} from '@app/shared/form/select2-patient.directive';
import {DataLogsService} from '@app/data-logs/data-logs.service';
import {Select2AjaxpatientDirective} from '@app/shared/form/select2-ajaxpatient.directive';
// declare let moment: any;

const _momentOptions = {
    week: {
        dow: 0, // Sunday is the first day of the week.
        doy: 6
    }
};

moment.updateLocale('en-US', _momentOptions);

@Component({
    moduleId: module.id,
    selector: 'app-notification-mass-patient',
    templateUrl: './notification-mass-patient.component.html',
    styleUrls: ['./notification-mass-patient.component.scss'],
    providers: [RestService],
    encapsulation: ViewEncapsulation.None
})
export class NotificationMassPatientComponent implements OnInit, OnDestroy, AfterViewInit {

    messageRequire: string; // Show message require
    patients: any = [];
    patientsId: any = [];
    patientsSelected: any = [];
    errorResponse: any;
    massNotificationSubscriber: Subscription;
    getReceiversSubscriber: Subscription;
    patientDefaultType = 'Add Patient(s)';
    @ViewChild('NotificationMass_Modals_Patient') modal: ModalComponent;
    @ViewChild('select2PatientDirective') patientsSelector: Select2PatientDirective;
    @ViewChild('Select2AjaxpatientDirective') ajaxpatientsSelector: Select2AjaxpatientDirective;
    @Output()
    onClick: EventEmitter<any> = new EventEmitter();
    patientTypeSubscriber: Subscription;


    // ========== Specific Modal Method ========== //

    massNotiModel: MassNotiClass = new MassNotiClass();

    // ========== Class Method ========== //
    public getSelect2Element: String = '.select2-container--default .select2-selection--multiple';
    patientLoadedStatus=false;

    constructor(
        private _dataLogService: DataLogsService,
        private _restService: NotificationsService,
        private _alertService: AlertService,
        private loadingIndicatorService: LoadingIndicatorService) {
    }

    validatePatient(message: any) {   
        if (this.ajaxpatientsSelector.getData() == null || this.ajaxpatientsSelector.getData().length == 0) {
            this.errorResponse = message;
            this.ajaxpatientsSelector.inputError = true;
            this.ajaxpatientsSelector.changeBorderColorPatient();
            return false;
        } else {
            this.errorResponse = null;
            this.ajaxpatientsSelector.inputError = false;
            this.ajaxpatientsSelector.changeBorderColorPatient();
            return true;
        }
    }

    validate() {
        const validatePatient = this.validatePatient('This field is required');
        if (validatePatient) {
            return true;
        }
        return false;
    }

    massNotiClicked() {
        if (this.validate()) {
            this.patientsSelected = this.ajaxpatientsSelector.getData();
            
            //console.log(this.patientsSelected);

            this.loadingIndicatorService.start();
            if (this.massNotificationSubscriber) {
                this.massNotificationSubscriber.unsubscribe();
            }
            this.massNotificationSubscriber = this._restService.massNotificationToPatient({
                patient_id_selected: this.patientsSelected,
                body: this.massNotiModel.content,
                time_offset: moment().utcOffset(),
                date: moment().format('YYYY-MM-DD')
            }).subscribe(
                (success: any) => {
                    this.loadingIndicatorService.stop();
                    if (success.error_code === 'RC000') {
                        this._alertService.onClose = () => {
                            this.dismiss();
                            this.ajaxpatientsSelector.clearData();

                        };
                        this._alertService.showDismissButton = false;
                        this._alertService.dismissButtonLabel = 'Close';
                        this._alertService.showCloseButton = true;
                        this._alertService.closeButtonLabel = 'Close';
                        this._alertService.title = success.error_messages;
                        this._alertService.message = '';
                        this._alertService.emitter('open');
                    }
                },
                (error: any) => {
                    this.loadingIndicatorService.stop();
                    const e = error.error;
                    if (e.error_code === 'RC050') {
                        this.messageRequire = e.error_fields.body;
                    } else if (e.error_code === 'RC049') {
                        this._alertService.onClose = () => {
                            this.dismiss();
                            this.ajaxpatientsSelector.clearData();
                        };
                        this._alertService.showDismissButton = false;
                        this._alertService.dismissButtonLabel = 'Close';
                        this._alertService.showCloseButton = true;
                        this._alertService.closeButtonLabel = 'Close';
                        this._alertService.title = e.error_messages;
                        this._alertService.message = '';
                        this._alertService.emitter('open');
                    }
                },
                () => {
                    this.loadingIndicatorService.stop();
                }
            );
        }
    }

    ngOnInit() {
        const self = this;
        jQuery('.notification-mass').parent().css('width', '600px');
        //self.getPatients();
        self.patientsSelected = this.patientDefaultType;
    }

    ngAfterViewInit(): void {
        const _self = this;
        jQuery(document).ready(function () {
            _self.ajaxpatientsSelector.element.on('change', function (e: any) {
                _self.errorResponse = null;
            });
            /*jQuery('input.select2-search__field').on('keyup', function() {
                let searchTerm = jQuery(this).val();
                //alert(jQuery(this).val());

                //console.log(typeof searchTerm);
                //console.log(searchTerm);

                if (searchTerm !='' && searchTerm != undefined && _self.patientLoadedStatus == false){
                    _self.getSearchPatients(searchTerm);
                }
            });*/
        });
    }
    getSearchPatients(searchTerm:any) {
        this.loadingIndicatorService.start();
        if (this.patientTypeSubscriber) {
            this.patientTypeSubscriber.unsubscribe();
        }
        this.patientLoadedStatus = true;
        this.patients = [];
        this.patientTypeSubscriber = this._restService.getSearchPatients(searchTerm).subscribe(
            (success: any) => {
                this.patientLoadedStatus = false;
                if (success.error_code === 'RC000') {
                    const now = moment(new Date()).seconds(0).milliseconds(0);
                    success.patients.forEach((item: any) => {
                        const dob = moment(item.dob).seconds(0).milliseconds(0);
                        const duration = moment.duration(now.diff(dob));
                        const years = duration.asYears();
                        if (years >= 18) {
                            item.full_name = item.first_name + ' ' + item.last_name + ' - ' + moment(item.dob).format('ll');
                            this.patients.push(item);
                        }

                    });
                }
                this.loadingIndicatorService.stop();
            },
            (error: any) => {
                this.patientLoadedStatus = false;
                this.loadingIndicatorService.stop();
            });
    }
    getPatients() {
        this.loadingIndicatorService.start();
        if (this.patientTypeSubscriber) {
            this.patientTypeSubscriber.unsubscribe();
        }
        this.patientTypeSubscriber = this._restService.getPatients().subscribe(
            (success: any) => {
                if (success.error_code === 'RC000') {
                    const now = moment(new Date()).seconds(0).milliseconds(0);
                    success.patients.forEach((item: any) => {
                        const dob = moment(item.dob).seconds(0).milliseconds(0);
                        const duration = moment.duration(now.diff(dob));
                        const years = duration.asYears();
                        if (years >= 18) {
                            item.full_name = item.first_name + ' ' + item.last_name + ' - ' + moment(item.dob).format('ll');
                            this.patients.push(item);
                        }

                    });
                }
                this.loadingIndicatorService.stop();
            },
            (error: any) => {
                this.loadingIndicatorService.stop();
            });
    }
    reloadPatientData(data:any){
        console.log(data);
    }

    ngOnDestroy() {
        if (this.massNotificationSubscriber) {
            this.massNotificationSubscriber.unsubscribe();
        }
    }

    // ========== General Modal Method ========== //

    dismiss() {
        this.modal.dismiss();
        this.messageRequire = null;
        this.massNotiModel.content = '';
        this.ajaxpatientsSelector.resetSelect2(jQuery('#select2Ajaxpatient'));
        this.ajaxpatientsSelector.clearData();
        this.patientsSelected = [];
        this.errorResponse = null;
        this.ajaxpatientsSelector.inputError = false;
        this.ajaxpatientsSelector.changeBorderColorPatient();
    }

    onKeyDown(event: any) {
        /*if (event.keyCode === 13 || (event.keyCode === 13 && event.shiftKey)) {        
            event.preventDefault();
        }*/
    }

    onKeyUp(e: any) {
        this.messageRequire = null;
    }

    open() {
        this.modal.open();
    }

    close() {
        this.modal.close();
    }
}

export class MassNotiClass {
    content = '';
}


