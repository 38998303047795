import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuickAptbookingRoutingModule } from './quick-aptbooking-routing.module';
import { QuickAptbookingComponent } from './quick-aptbooking.component';
//import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import {EmbedBookModalComponent} from '@app/quick-aptbooking/modals/embed-book-modal/embed-book-modal.component';
import {EmbedAppointmentBookComponent} from '@app/quick-aptbooking/modals/embed-appointment-book/embed-appointment-book.component';
import { SharedModule } from '@app/shared';
//import { PatientFutureAppointmentComponent } from '@app/appointments/modals/patient-future-appointment/patient-future-appointment.component';

@NgModule({
  imports: [
    CommonModule,
    QuickAptbookingRoutingModule,
    //NgbModule.forRoot(),
    FormsModule,
    SharedModule
  ],
  declarations: [
    QuickAptbookingComponent,
    EmbedBookModalComponent,
    EmbedAppointmentBookComponent,
   // PatientFutureAppointmentComponent   
  ],
  exports: [
    EmbedBookModalComponent,
    EmbedAppointmentBookComponent,
    //PatientFutureAppointmentComponent
  ]
})
export class QuickAptbookingModule { }
