import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {UtilsService} from '../core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AdminRestService {

  // API for Syystem Service
  getUserType = environment.serverUrl + '/admin/managed-roles';
  getUserStatus = environment.serverUrl + '/admin/users/statuses';
  getUserInfo = environment.serverUrl + '/admin/users';
  getActiveAccount = environment.serverUrl + '/admin/users/activate';
  getDeactiveAccount = environment.serverUrl + '/admin/users/deactivate';


  addAdminAccount = environment.serverUrl + '/admin/users/admins';
  updateAdminAccount: any = environment.serverUrl + '/admin/users/admins/{0}';

  // add-manager
  addNewManagingUserURL = environment.serverUrl + '/admin/users/super-managing-users';
  getPracticeListURL = environment.serverUrl + '/practices';
  addSuperProviderURL = environment.serverUrl + '/admin/users/super-providers';

  //
  addNewPatientURL = environment.serverUrl + '/users/sign-up';
  addNewSuperUserURL = environment.serverUrl + '/admin/users/super-managing-users';
  
  // edit manager user
  getUserURL: any = environment.serverUrl + '/admin/users/{0}';
  updateManagingUserFormURL: any = environment.serverUrl + '/admin/users/super-managing-users/{0}';

  // add provider
  addNewProviderURL = environment.serverUrl + '/admin/users/providers';
  getInsuranceCompanyURL = environment.serverUrl + '/insurance-companies';
  getInsurancePlanURL = environment.serverUrl + '/insurance-plans';

  //edit master
  getProviderProfileURL: any = environment.serverUrl + '/admin/users/super-providers/{0}';
  updateProfileURL: any = environment.serverUrl + '/admin/users/super-providers/{0}';

  //edit patiend
  updateProfilePatiendURL: any = environment.serverUrl + '/admin/users/patients/{0}';
  getUserPatiendURL: any = environment.serverUrl + '/admin/patient-info/{0}';

  updateSuperUserURL: any = environment.serverUrl + '/admin/users/super-managing-users/{0}';
  updatePasswordFromAdminURL: any = environment.serverUrl + '/admin/users/update-password-from-admin/{0}';

  //edit provider
  updateProfileProviderURL: any = environment.serverUrl + '/admin/users/providers/{0}';
  getUserProviderURL: any = environment.serverUrl + '/admin/users/{0}';

  //ehr
  switchModeURL = environment.serverUrl + '/admin/switch-mode';
  getModeURL = environment.serverUrl + '/mode';
  synchronizePokitdokURL = environment.serverUrl + '/admin/synchronize-pokitdok';
  synchronizeHL7URL = environment.serverUrl + '/hl7';

  //notification
  putNotificationListURL = environment.serverUrl + '/admin/notification-management';
  getNotificationListURL = environment.serverUrl + '/admin/notification-management';
  getVariablesListURL = environment.serverUrl + '/admin/notification-variables';

  //practise
  addPracticeURL = environment.serverUrl + '/admin/practice/add';
  updatePracticeURL: any = environment.serverUrl + '/admin/practice/update/{0}';

  getProfileURL: any = environment.serverUrl + '/admin/practice/profile/{0}';
  getListURL = environment.serverUrl + '/admin/practice/list';

  // API for ActiveModule
  loadAllModuleUrl: any = environment.serverUrl + '/admin/practice/active-module/list/{0}';
  changePasswordAdminURL = environment.serverUrl + '/admin/change-password';
  saveActiveModuleUrl = environment.serverUrl + '/admin/practice/active-module/list';
  
  synchronizeInsuranceURL = environment.serverUrl + '/admin/synchronize-insurance';

  //
  getUserDetailsUrl = environment.serverUrl + '/users/';

  fetchAllLocationUrl = environment.serverUrl + '/provider/fetchAllLocation';

  // Sim - tools
  loadProviderscheduleUrl: any = environment.serverUrl + '/simtools/loadProviderschedule?';
  getProviderscheduleUrl: any = environment.serverUrl + '/simtools/getProviderschedule?';
  processProviderScheduleUrl: any = environment.serverUrl + '/simtools/processProviderSchedule?';

  constructor(private _http: HttpClient) {
  }

  debugMode() {
    return !environment.production;
  }

  // admin/account
  userType() {
    return this._http.get(this.getUserType).pipe(
      map(results => results)
    );
  }

  userStatus() {
    return this._http.get(this.getUserStatus).pipe(
      map(results => results)
    );
  }

  userInfo(page: number, limit: number, roles: any, statuses: any, search_text: string) {
    let params = {
      page: page,
      limit: limit,
      roles: JSON.stringify(roles),
      statuses: JSON.stringify(statuses)
    };

    if (search_text && search_text.trim() != '') {
      params['search_text'] = encodeURIComponent(search_text);
    }
    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this._http.get(this.getUserInfo, {params: queryParams});
  }

  activeAccount(params: any) {
    return this._http.put(this.getActiveAccount, params).pipe(
      map(results => results)
    );
  }

  deactiveAccount(params: any) {
    return this._http.put(this.getDeactiveAccount, params).pipe(
      map(results => results)
    );
  }

  //add-admin
  addNewAdmin(params: any) {
    return this._http.post(this.addAdminAccount, params).pipe(
      map(results => results)
    );
  }
  updateAdminUser(id: number, params: any) {
    return this._http.put(this.updateAdminAccount.format(id), params).pipe(
      map(results => results)
    );
  }
  //add-manager
  addNewManagingUser(params: any) {
    return this._http.post(this.addNewManagingUserURL, params).pipe(
      map(results => results)
    );
  }

  getPracticeList() {
    return this._http.get(this.getPracticeListURL).pipe(
      map(results => results)
    );
  }

  addPractice(params: any) {
    return this._http.post(this.addPracticeURL, params).pipe(
      map(results => results)
    );
  }

  updatePractice(id: number | string, params: any) {
    return this._http.put(this.updatePracticeURL.format(id), params).pipe(
      map(results => results)
    );
  }

  //add-master
  addSuperProvider(params: any) {
    return this._http.post(this.addSuperProviderURL, params).pipe(
      map(results => results)
    );
  }

  //add-patient
  addNewPatient(params: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('api-version', '3.0');
    return this._http.post(this.addNewPatientURL, params, {
      headers: headers
    }).pipe(
      map(results => results)
    );
  }

  //add-provider
  addNewProvider(params: any) {
    return this._http.post(this.addNewProviderURL, params).pipe(
      map(results => results)
    );
  }

  getInsuranceCompany() {
    return this._http.get(this.getInsuranceCompanyURL).pipe(
      map(results => results)
    );
  }

  getInsurancePlan() {
    return this._http.get(this.getInsurancePlanURL).pipe(
      map(results => results)
    );
  }

  //add-super-user
  addNewSuperUser(params: any) {
    return this._http.post(this.addNewSuperUserURL, params).pipe(
      map(results => results)
    );
  }

  //edit-managing-user
  getUser(id: number | string) {
    return this._http.get(this.getUserURL.format(id)).pipe(
      map(results => results)
    );
  }

  updateManagingUserForm(id: number | string, params: any) {
    return this._http.put(this.updateManagingUserFormURL.format(id), params).pipe(
      map(results => results)
    );
  }

  // edit-master
  getProviderProfile(id: number | string) {
    return this._http.get(this.getProviderProfileURL.format(id)).pipe(
      map(results => results)
    );
  }

  updateProfile(id: number | string, params: any) {
    return this._http.put(this.updateProfileURL.format(id), params).pipe(
      map(results => results)
    );
  }

  //edit provider
  updateProfileProvider(id: number | string, params: any) {
    return this._http.put(this.updateProfileProviderURL.format(id), params).pipe(
      map(results => results)
    );
  }

  getUserProvider(id: number | string) {
    return this._http.get(this.getUserProviderURL.format(id)).pipe(
      map(results => results)
    );
  }


  // edit-patiend
  updateProfilePatiend(id: number | string, params: any) {
    return this._http.put(this.updateProfilePatiendURL.format(id), params).pipe(
      map(results => results)
    );
  }

  getUserPatiend(id: number | string) {
    return this._http.get(this.getUserPatiendURL.format(id)).pipe(
      map(results => results)
    );
  }

  //edit-support-user
  updateSuperUser(id: number | string, params: any) {
    return this._http.put(this.updateSuperUserURL.format(id), params).pipe(
      map(results => results)
    );
  }

  //ehr
  switchMode(is_online: any) {
    return this._http.put(this.switchModeURL, is_online).pipe(
      map(results => results)
    );
  }

  getMode() {
    return this._http.get(this.getModeURL).pipe(
      map(results => results)
    );
  }

  synchronizePokitdok() {
    return this._http.get(this.synchronizePokitdokURL).pipe(
      map(results => results)
    );
  }

  synchronizeHL7() {
    return this._http.get(this.synchronizeHL7URL).pipe(
      map(results => results)
    );
  }
  

  //notification

  putNotificationList(notifications: any) {
    return this._http.put(this.putNotificationListURL, notifications).pipe(
      map(results => results)
    );
  }

  getNotificationList() {
    return this._http.get(this.getNotificationListURL).pipe(
      map(results => results)
    );
  }

  getVariablesList() {
    return this._http.get(this.getVariablesListURL).pipe(
      map(results => results)
    );
  }

  getProfile(id: number | string) {
    return this._http.get(this.getProfileURL.format(id)).pipe(
      map(results => results)
    );
  }

  getList(page: number, limit: number, search_text: string) {
    let params = {
      page: page,
      limit: limit,
      search_text: search_text
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this._http.get(this.getListURL, {params: queryParams}).pipe(
      map(results => results)
    );
  }

  loadAllModule(practiceId: number) {
    return this._http.get(this.loadAllModuleUrl.format(practiceId)).pipe(
      map(results => results)
    );
  }

  saveActiveModule(Body: any) {
    return this._http.post(this.saveActiveModuleUrl, Body).pipe(
      map(results => results)
    );
  }

  changePasswordAdmin(params: any) {
    return this._http.put(this.changePasswordAdminURL, params);
  }
  updatePasswordFromAdmin(id: number | string, params: any) {
    return this._http.put(this.updatePasswordFromAdminURL.format(id), params).pipe(
      map(results => results)
    );
  }
  getUserDetail(id: any) {
    return this._http.get(this.getUserDetailsUrl + id);
  }
  synchronizeInsurance() {
    return this._http.get(this.synchronizeInsuranceURL).pipe(
      map(results => results)
    );
  }
  fetchAllLocation() {
    return this._http.get(this.fetchAllLocationUrl);
  } 
  loadProviderschedule(startTime: any, practice: any, providerId: any) {
    const params = new HttpParams()
        .set('startTime', startTime)        
        .set('practice', practice)
        .set('providerId', providerId);
                 
    return this._http.get(this.loadProviderscheduleUrl + params);
  }
  getProviderschedule(startTime: any, practice: any, providerId: any) {
    const params = new HttpParams()
        .set('startTime', startTime)        
        .set('practice', practice)
        .set('providerId', providerId);
                 
    return this._http.get(this.getProviderscheduleUrl + params);
  }
  processProviderSchedule(startTime: any, practice: any, providerId: any) {
    const params = new HttpParams()
        .set('startTime', startTime)        
        .set('practice', practice)
        .set('providerId', providerId);
                 
    return this._http.get(this.processProviderScheduleUrl + params);
  }
  
}
