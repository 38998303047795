import {Component, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
// import { BookService } from '../services/book.service';
import {AlertService} from '@app/shared/alert/alert.service';
import {SystemData, SystemEvent} from '@app/shared/system';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {Utils} from '@app/shared/utils';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {AppointmentsService} from '@app/appointments/appointments.service';
import {SimpleModalsService} from '@app/shared/modals/simple-modals/simple-modals.service';
import * as moment from 'moment';

declare let jQuery: any;
const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};

@Component({
  moduleId: module.id,
  selector: 'embed-modals-appointment-book',
  templateUrl: './embed-appointment-book.component.html',
  styleUrls: ['./embed-appointment-book.component.scss'],
})
export class EmbedAppointmentBookComponent implements OnInit, OnDestroy, AfterViewInit {
  timeSetting: any = [];
  bookAppointment: any = {};
  bookInfo: any = [];
  notificationSetting: any = [];

  providerInfo: any = [];
  patientInfo: any = [];

  bookListener: any;
  bookAppointmentSubscriber: Subscription;
  getBookAppointmentSubscriber: Subscription;
  getNotificationSettingsSubscriber: Subscription;

  @Output() callEventBookEmitter = new EventEmitter();
  @ViewChild('settingReminder') settingReminder: ElementRef;
  @ViewChild('selectTime') selectTime: ElementRef;

  constructor(
    private _router: Router,
    private Service: AppointmentsService,
    private _alertService: SimpleModalsService,
    public _utils: Utils,
    public _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _loadingIndicatorService: LoadingIndicatorService
  ) {
  }

  ngOnInit() {
    // Default value
    this.timeSetting = [
      {'name': 'One day', 'value': '1'},
      {'name': 'Two days', 'value': '2'},
      {'name': 'Three days', 'value': '3'},
      {'name': 'Five days', 'value': '5'},
      {'name': 'One week', 'value': '7'}
    ];
    this.initData();

    if (!this._systemData.selectOpenSlotData) {
      // If user do not have click slot
      this._router.navigate(['/patient', 'appointment', 'openings']);
    }
    this.getBookAppointment();
    this.bookListener = this._systemEvent.book.subscribe((action: string) => {
      switch (action) {
        case 'book_callGetBookAppointment':
          this.getBookAppointment();
          this.initData();
          break;
        default:
          break;
      }
    });
  }

  ngAfterViewInit() {
    const _self = this;
    jQuery(_self.settingReminder.nativeElement).change(function () {
      if (this.checked) {
        jQuery(_self.selectTime.nativeElement).prop('disabled', false);
      } else {
        jQuery(_self.selectTime.nativeElement).prop('disabled', true);
      }
    });
    if (jQuery(this.settingReminder.nativeElement).is(':checked')) {
      jQuery(this.selectTime.nativeElement).prop('disabled', false);
    } else {
      jQuery(this.selectTime.nativeElement).prop('disabled', true);
    }
  }

  ngOnDestroy() {
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }
    if (this.getBookAppointmentSubscriber) {
      this.getBookAppointmentSubscriber.unsubscribe();
    }
  }

  initData() {
    this.getNotificationSettings();
    this.bookAppointment.is_reminder = this.notificationSetting.is_reminder;
    this.bookAppointment.reminder_day = this.notificationSetting.reminder_day;
    this.bookAppointment.add_followed = 0;
  }

  getNotificationSettings() {
    if (this.getNotificationSettingsSubscriber) {
      this.getNotificationSettingsSubscriber.unsubscribe();
    }
    this.getNotificationSettingsSubscriber = this.Service.getNotificationGlobalSetting().subscribe(
      (success: any) => {
        this.notificationSetting = success.notification_settings;
        this.notificationSetting.reminder_day = (this.notificationSetting.reminder_day !== 0)
          ? this.notificationSetting.reminder_day : 1;
      }
    );
  }

  getBookAppointment() {
    if (this.getBookAppointmentSubscriber) {
      this.getBookAppointmentSubscriber.unsubscribe();
    }
    if (this._systemData.selectOpenSlotData.length === 0) {
      // this._systemData.selectOpenSlotData.provider_id = "";
      // this._systemData.selectOpenSlotData.patient_user_id = "";
      // this._systemData.selectOpenSlotData.family_id = "";
    }
    this.getBookAppointmentSubscriber = this.Service.getBookAppointment(
      this._systemData.selectOpenSlotData.provider_id,
      this._systemData.selectOpenSlotData.patient_user_id,
      this._systemData.selectOpenSlotData.family_id
    ).subscribe(
      (success: any) => {
        this.providerInfo = success.provider;
        this.providerInfo.fullName = this._utils.generateFullName(this.providerInfo.first_name,
          this.providerInfo.middle_name, this.providerInfo.last_name);
        this.providerInfo.specialties = this._utils.getSpecialties(this.providerInfo.specialties);
        this.patientInfo = success.patient;
      }
    );
  }

  submitBookAppointment(formVals: any) {
    const pathname = this._router.url.split('?')[0];
    let bookFromNotification = false;
    if (pathname === '/patient/notification') {
      bookFromNotification = true;
    }
    const _self = this;
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }
    const value = Object.assign({}, formVals);
    value.provider_id = this._systemData.selectOpenSlotData.provider_id;
    value.patient_user_id = this._systemData.selectOpenSlotData.patient_user_id;
    value.family_id = this._systemData.selectOpenSlotData.family_id;
    value.start_time = moment.utc(this._systemData.selectOpenSlotData.start_time).format();
    value.end_time = moment.utc(this._systemData.selectOpenSlotData.end_time).format();
    value.book_from_notification = bookFromNotification;
    value.slot_id =  this._systemData.selectOpenSlotData.slot_id;
    value.saved_id =  this._systemData.selectOpenSlotData.saved_id;

    this._loadingIndicatorService.start();
    this.bookAppointmentSubscriber = this.Service.bookAppointmentByPatient(value).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          _self._alertService.onClose = () => {
            this.callEventBookEmitter.emit('');
          };
          _self._alertService.dismissButtonLabel = 'No';
          _self._alertService.closeButtonLabel = 'OK';
          _self._alertService.showDismissButton = false;
          _self._alertService.showCloseButton = true;
          _self._alertService.title = success.error_messages;
          _self._alertService.message = '';
          _self._alertService.emitter('open');
          this._loadingIndicatorService.stop();
        } else if (success.error_code === 'RC056' || success.error_code === 'RC063' || success.error_code === 'RC086'
          || success.error_code === 'RC104' || success.error_code === 'RC108') {
          _self._alertService.onClose = () => {
            this.callEventBookEmitter.emit('');
          };
          _self._alertService.dismissButtonLabel = 'No';
          _self._alertService.closeButtonLabel = 'OK';
          _self._alertService.showDismissButton = false;
          _self._alertService.showCloseButton = true;
          _self._alertService.title = success.error_messages;
          _self._alertService.message = '';
          _self._alertService.emitter('open');
          this._loadingIndicatorService.stop();
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
        const res = error.error;
        if (res.error_code === 'RC056' || res.error_code === 'RC063' || res.error_code === 'RC086'
          || res.error_code === 'RC104' || res.error_code === 'RC108') {
          _self._alertService.onClose = () => {
            this.callEventBookEmitter.emit('');
          };
          _self._alertService.dismissButtonLabel = 'No';
          _self._alertService.closeButtonLabel = 'OK';
          _self._alertService.showDismissButton = false;
          _self._alertService.showCloseButton = true;
          _self._alertService.title = res.error_messages;
          _self._alertService.message = '';
          _self._alertService.emitter('open');
          this._loadingIndicatorService.stop();
        }
      },
      () => {
        this._loadingIndicatorService.stop();
      }
    );
  }
}
