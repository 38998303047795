<div class="row provider-detail">
    <div class="col-12 no-padding-left margin-bottom-15 back-to-followed-provider">
        <a [routerLink]="['/patient', 'follow-providers']">
            <i class="fa fa-share fa-flip-horizontal" aria-hidden="true"></i>
            Back to Followed Providers
        </a>
    </div>
    <!-- //Detailed Information -->
    <div class="col-12 detailed-information clear-both">
        <div class="card-head">
            <div class="user-icon text-center">
                <i class="fa fa-2x fa-user" aria-hidden="true"></i>
            </div>
            <div class="user-info">
                <div class="data-value name fullname">{{ _doctorDetail.fullName }}</div>
                <div class="data-value">Email: {{ _doctorDetail.email }}</div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 detailed-header">Details</div>
            </div>
            <div class="row info-block">
                <div class="col-12 col-sm-3 col-md-5 col-lg-3 data-head text-md-right">Office/Practice/Hospital</div>
                <div class="col-12 col-sm-9  col-md-7 col-lg-9 data-value">{{ _doctorDetail.office_name }}</div>
            </div>
            <div class="row info-block">
                <div class="col-12 col-sm-3 col-md-5 col-lg-3 data-head text-md-right">NPI</div>
                <div class="col-12 col-sm-9 col-md-7 col-lg-9 data-value">{{ _doctorDetail.npi }}</div>
            </div>
            <div class="row info-block">
                <div class="col-12 col-sm-3 col-md-5 col-lg-3 data-head text-md-right">Specialties</div>
                <div class="col-12 col-sm-9 col-md-7 col-lg-9 data-value">{{ _doctorDetail.specialties }}</div>
            </div>
            <div class="row info-block">
                <div class="col-12 col-sm-3 col-md-5 col-lg-3 data-head text-md-right">Address</div>
                <div class="col-12 col-sm-9 col-md-7 col-lg-9 data-value">
                    <div>{{ _doctorDetail.address }}</div>
                    <div>{{ _doctorDetail.address2 }}</div>
                    <div>
                        <span>{{ _doctorDetail.city }}</span><span *ngIf="_doctorDetail.state_code !== null">,</span>
                        <span>{{ _doctorDetail.state_code }}</span>
                        <span>{{ _doctorDetail.zip }}</span>
                    </div>
                </div>
            </div>
            <div class="row info-block">
                <div class="col-12 col-sm-3 col-md-5 col-lg-3 data-head text-md-right">Phone Number</div>
                <div class="col-12 col-sm-9 col-md-7 col-lg-9 data-value">{{ _doctorDetail.phone_mobile }}</div>
            </div>
            <div class="row info-block">
                <div class="col-12 col-sm-3 col-md-5 col-lg-3 data-head text-md-right">Website</div>
                <div class="col-12 col-sm-9 col-md-7 col-lg-9 data-value">
                    <a class="link" href="{{ _doctorDetail.website_link }}" target="_blank">{{ _doctorDetail.website_url }}</a>
                </div>
            </div>
            <div class="row info-block">
                <div class="col-12 col-sm-3 col-md-5 col-lg-3 data-head text-md-right">Hours of Operation</div>
                <div class="col-12 col-sm-9 col-md-7 col-lg-9 data-value text-nowrap">
                    <div *ngFor="let item of _doctorDetail.opening_hours">
                        <span>{{ item.time_slot }}</span>
                        <span class="padding-left-10">{{ item.time_days }}</span>
                    </div>
                </div>
            </div>
            <div class="horizontal-divider"></div>
            <div class="card-default card-accepted-insurance-up">
                <div class="card-heading accepted-insurance-header-up">
                    <h3 class="card-title text-title-form-signup mb-0">Accepted Insurance (from the Practice/Hospital)</h3>
                </div>
                <div class="card-body accepted-insurance">
                    <div *ngFor="let insurance of _doctorDetail.insurances; let i = index">
                        {{insurance === null ? 'No Insurance/Self Pay' : insurance}}
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-12 data-value no-padding-left-right padding-top-15" *ngIf="arrayModule[5]">
                <a class="link" (click)="openSearchOpeningPage()" target="_blank">Book Appointment</a>
            </div>
        </div>
        <div class="card-foot">
            <button type="button" class="btn btn-rtn btn-90 text-uppercase" (click)="feedbackProviderDirective.open()">Feedback</button>
        </div>
    </div>
    <!-- //Documents -->
    <div class="col-12 documents px-0" *ngIf="arrayModule[7]">
        <div class="doc-head">Documents</div>
        <rtn-table [autoloading]="autoloadDocumentList" (onDataChange)="handleFetchListDocuments($event)" [data]="_listDocuments.data" #table [rowsOnPage]="_listDocuments.rowsOnPage" [numberOfPages]="_listDocuments.numberOfPages">
            <div class="file-row" *ngFor="let item of table.data">
                <div class="file-name">
                    <i class="fa fa-2x fa-file-pdf-o" aria-hidden="true"></i>
                    <div>{{ item.name }}</div>
                </div>
                <div class="download-file">
                    <a aria-hidden="true" (click)="downloadFile(item.download_url)">
                        <i class="fa fa-2x fa-cloud-download" aria-hidden="true"></i>
                        <span>Download</span>
                    </a>
                </div>
            </div>
            <div class="navigator">
                <rtn-table-pager-bootstrap [enableExtreme]="false" class="row col-12"></rtn-table-pager-bootstrap>
            </div>
            <div class="no-document margin-bottom-20" *ngIf="table.numberOfPages < 1">There is no document</div>
        </rtn-table>
    </div>
</div>
<rtn-modals-feedback-provider [providerId]="_routeParams.snapshot.paramMap.get('id')"></rtn-modals-feedback-provider>
