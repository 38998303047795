import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WaittimeMeterRoutingModule } from './waittime-meter-routing.module';
import { WaittimeMeterComponent } from './waittime-meter.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    WaittimeMeterRoutingModule,
    NgbModule.forRoot(),
    FormsModule
  ],
  declarations: [WaittimeMeterComponent]
})
export class WaittimeMeterModule { }
