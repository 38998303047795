export class BillingInfo {
    first_name: string = '';
    last_name: string = '';
    email: string = '';
    phone_mobile: string = '';
    phone_mobile_tmp: string = '';
    address: string = '';
    address2: string = '';
    city: string = '';
    state_code: string = '';
    zip: string = '';
    user_id: string = '';
}
