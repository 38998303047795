import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {Event} from '@app/master-schedules/master-schedules.component';
import {Utils} from '@app/shared/utils';

declare let jQuery: any;

@Component({
    moduleId: module.id,
    selector: 'app-schedule-tooltip',
    templateUrl: './schedule-tooltip.component.html',
    styleUrls: ['./schedule-tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScheduleTooltipComponent {
    event: Event;
    name = '';

    constructor(
        private elementRef: ElementRef,
        private _utils: Utils
    ) {
        // TODO
    }

    updateEvent(event: Event) {
        this.event = event;
    }

    getHtml() {
        return jQuery(this.elementRef.nativeElement).html();
    }
}
