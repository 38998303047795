import {Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {PasswordFormatTooltipComponent} from '@app/shared/tooltip/password-format';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {AdminRestService} from '../admin.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {AlertService} from '../../shared/alert';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'rtn-admin-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../admin.component.scss', './change-password.component.scss'],
//   encapsulation: ViewEncapsulation.None,
})
export class AdminChangePasswordComponent implements OnInit {

  resetForm: FormGroup;
  linkIsValid: boolean = true;
  error: boolean = false;
  errorMessage: any;
  responseError: PasswordItem = new PasswordItem();
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
  minHeight: number;

  changePasswordSubscriber: Subscription;

  @ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _adminRestService: AdminRestService,
    private _router: Router,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.resetForm = this._formBuilder.group({
      old_password: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      password_confirmation: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    //TODO: Get token/ id from URL
    this.minHeight = window.innerHeight - 81;
  }

  reset() {
    var self = this;
    var resetData = {
      'old_password': this.resetForm.value.old_password,
      'password': this.resetForm.value.password,
      'password_confirmation': this.resetForm.value.password_confirmation,
    };
    this.responseError = new PasswordItem();
    // Call to service change password
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.changePasswordSubscriber = this._adminRestService.changePasswordAdmin(resetData).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          self.error = false;
          self.email = null;
          self.password = null;
          self.password_confirmation = null;
          self.old_password = null;
          self._alertService.onClose = () => {
            self._router.navigate(['/admin', 'account']);
          };
          self._alertService.showDismissButton = false;
          self._alertService.dismissButtonLabel = 'Close';
          self._alertService.showCloseButton = true;
          self._alertService.closeButtonLabel = 'OK';
          self._alertService.title = 'Your password is reset successfully!';
          self._alertService.message = '';
          self._alertService.emitter('open');
        } else {
          if (success.error_fields) {
            this.responseError = success.error_fields;
          } else {
            this.responseError = new PasswordItem();
          }
          self.errorMessage = success.error_messages;
          self.error = !!(self.errorMessage || self.errorMessage === '');
          this._loadingIndicatorService.stop();
        }
        // Todo
      }, (error: any) => {
        if (error.error.error_fields) {
          this.responseError = error.error.error_fields;
        } else {
          this.responseError = new PasswordItem();
        }
        self.errorMessage = error.error.error_messages;
        if (self.errorMessage || self.errorMessage == '') {
          self.error = true;
        } else {
          self.error = false;
        }
        this._loadingIndicatorService.stop();
      });
      this._loadingIndicatorService.stop();
  }

  ngOnDestroy() {
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
  }
}

export class PasswordItem {
  old_password: string = null;
  password: string = null;
  password_confirmation: string = null;
}
