import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalComponent} from '@app/shared/ng2-bs3-modal';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { HomepageService } from '@app/home/home.service';

@Component({
  moduleId: module.id,
  selector: 'rtn-modals-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.scss']
})
export class ResendEmailComponent implements OnDestroy {

  account: any;
    successResend: any;

    resendEmailSubscriber: Subscription;

    @ViewChild('ResendEmail_Modals') ResendEmail_Modals: ModalComponent;
    @ViewChild('Contact_Modals') Contact_Modals: ModalComponent;
    @ViewChild('SuccessResend_Modals') SuccessResend_Modals: ModalComponent;

    // ========== General Modal Method ========== //

    constructor (
        private _homepageService: HomepageService,
        private loadingIndicatorService: LoadingIndicatorService
    ) {
        // do nothing
    }

    ngOnDestroy() {
        if (this.resendEmailSubscriber) {
            this.resendEmailSubscriber.unsubscribe();
        }
    }

    open(acc: any) {
        this.account = acc;
        if (this.account.isNotActivate) {
            this.ResendEmail_Modals.open();
        } else if (this.account.isDeactivate) {
            this.Contact_Modals.open();
        }
    }

    resend() {
        this.loadingIndicatorService.start();
        if (this.resendEmailSubscriber) {
            this.resendEmailSubscriber.unsubscribe();
        }
        this.resendEmailSubscriber = this._homepageService.resendEmail(this.account).subscribe(
            (success: any) => {
                this.loadingIndicatorService.stop();
                // Save data to local storage
                if (success.error_code = 'RC000') {
                    this.ResendEmail_Modals.close();
                    this.successResend = success.error_messages;
                    this.SuccessResend_Modals.open();
                }
            }, (error: any) => {
                this.loadingIndicatorService.stop();
            });
    }

    close() {
        this.ResendEmail_Modals.close();
        this.Contact_Modals.close();
        this.SuccessResend_Modals.close();
    }

}
