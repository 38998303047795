import {Component, AfterViewInit, OnInit, ViewChild, OnDestroy, NgZone} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {AlertService} from '@app/shared/alert';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {Select2DiseaseDirective} from '@app/shared/form/select2-disease.directive';
import {DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent} from '@app/shared/datatable';
import * as moment from 'moment';
import {PatientsService} from '@app/patients/patients.service';

declare var jQuery: any;
const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
  selector: 'rtn-doctor-search',
  templateUrl: './doctor-search.component.html',
  styleUrls: ['../patients.component.scss', './doctor-search.component.scss'],
  moduleId: module.id,
})

export class DoctorSearchlComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RtnDataTableCoreComponent) rtnDataTableCore: RtnDataTableCoreComponent;
  @ViewChild(RtnDataTableFilterComponent) rtnDataTableFilter: RtnDataTableFilterComponent;
  @ViewChild(Select2DiseaseDirective) diseasesSelector: Select2DiseaseDirective;

  _searchArgs: InterfaceSearchProviderArgs = new SearchProviderArgs();
  listProviders: any = new Object();
  isMobile = false;

  searchForm: FormGroup;
  searchNoDataMessage = 'There is no provider.';
  searchNoCriteriaMessage: string;
  selectLastName = '';
  selectFirstName = '';
  selectCity = '';
  selectState = '';
  selectSpecialty = '';
  selectedDiseases: any = [];
  diseases: any;
  specialtyList: any;
  keyInForm = ['last_name', 'first_name', 'city', 'state', 'disease', 'specialty'];

  followProviderSubscriber: Subscription;
  searchProviderSubscriber: Subscription;
  getListSpecialtyByDiseaseSubscriber: Subscription;
  getListDiseaseSubscriber: Subscription;
  onGetSpecialtyListener: Subscription;

  constructor(
    private _restService: PatientsService,
    private _router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    public _systemData: SystemData,
    private _utils: Utils,
    private ngZone: NgZone,
    private location: Location
  ) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    window.onresize = (e: any) => {
      this.ngZone.run(() => {
        if (window.innerWidth < 768) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    };
    this.listProviders.rowsOnPage = this._searchArgs.limit;
    this.listProviders.numberOfPages = 0;
    this.listProviders.data = new Array();

    // Create search form
    this.searchForm = this.formBuilder.group({
      last_name: new FormControl(''),
      first_name: new FormControl(''),
      city: [],
      state: ['', Validators.compose([Validators.required])],
      disease: ['', Validators.compose([Validators.required])],
      specialty: ['', Validators.compose([Validators.required])],
    });

    this.onGetSpecialtyListener = this._systemEvent.specialty.subscribe((done: boolean) => {
      if (done) {
        // Set data default for specialty
        this.specialtyList = this._systemData.specialties;
      }
    });
  }

  ngOnInit() {
    if (this._utils.isPartialActiveStatus()) {
      this._router.navigate(['/patient', 'appointment', 'openings']);
    }
    const self = this;
    this._systemService.getStates();
    this._systemService.getSpecialties();
    /* var urlStr = this.getItemSearch();
    if (urlStr !== '') {
        // Set data search to input
        if (this.checkIssetParam(urlStr.last_name)) {
            this.selectLastName = urlStr.last_name;
        }
        if (this.checkIssetParam(urlStr.first_name)) {
            this.selectFirstName = urlStr.first_name;
        }
        if (this.checkIssetParam(urlStr.city)) {
            this.selectCity = urlStr.city;
        }
        if (this.checkIssetParam(urlStr.state)) {
            this.selectState = urlStr.state;
        }
        if (this.checkIssetParam(urlStr.disease)) {
            this.diseasesSelector && this.diseasesSelector.updateData(urlStr.disease);
            //this.selectedDiseases = urlStr.disease;
        }
        if (this.checkIssetParam(urlStr.specialty)) {
            this.selectSpecialty = urlStr.specialty;
        }
        // Search data
        this.handleFetchSearchProvider(urlStr);
    } else {
        this.clearDataSearch();
    } */

    // get Diseases list
    this.getListDiseaseSubscriber = this._restService.getDiseases().subscribe(
      (success: any) => {
        this.diseases = success.diseases;
        /* if (this.selectedDiseases) {
            this.diseasesSelector.updateData(this.selectedDiseases);
        } */
      }
    );
    this.location.replaceState(this.location.path().split('?')[0], '');
  }

  ngOnDestroy() {
    if (this.followProviderSubscriber) {
      this.followProviderSubscriber.unsubscribe();
    }
    if (this.getListSpecialtyByDiseaseSubscriber) {
      this.getListSpecialtyByDiseaseSubscriber.unsubscribe();
    }
    if (this.getListDiseaseSubscriber) {
      this.getListDiseaseSubscriber.unsubscribe();
    }
    if (this.searchProviderSubscriber) {
      this.searchProviderSubscriber.unsubscribe();
    }
    if (this.onGetSpecialtyListener) {
      this.onGetSpecialtyListener.unsubscribe();
    }
    const urlStrNew = window.location.origin + window.location.hash;
    window.history.pushState({}, null, urlStrNew); // Set new url
  }

  ngAfterViewInit() {
    this._systemEvent.menu.emit('find-providers');
    jQuery('.disease-style > span > span > span').attr('style', 'margin-top: -2px; padding-right: 25px');

  }

  /* checkIssetParam(param: any) {
      if (typeof param !== 'undefined' && param !== '') {
          return true;
      } else {
          return false;
      }
  } */

  onChangeDisease() {
    const diseases = this.diseasesSelector.getData();
    //reset value of specialty
    this.searchForm.patchValue({
      specialty: '', // or whatever fields you have
    });
    if (diseases.length > 0) {
      this.getListSpecialtyByDiseaseSubscriber = this._restService.getSpecialtyByDisease(diseases.toString()).subscribe(
        (success: any) => {
          this.specialtyList = success.specialties;
          //call again reset
          if (this.diseasesSelector.getData().length < 1) {
            this.resetSpecialty();
          }
          jQuery(document).ready(function () {
            jQuery('#searchbox-input-specialty').val('').addClass('ng-invalid');
          });
          // jQuery('#searchbox-input-specialty').val("");
          // this.selectSpecialty = '';
        }, error => {
        }, () => {
          //this.resetSpecialty();
        }
      );
    } else {
      this.resetSpecialty();
      // jQuery('#searchbox-input-specialty').val("");
      //  this.selectSpecialty = '';
    }
  }

  followProvider(provider: any | string, e: any, dataTable: any) {
    if (this.followProviderSubscriber) {
      this.followProviderSubscriber.unsubscribe();
    }
    this.followProviderSubscriber = this._restService.followProvider(provider.id).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          const el: HTMLElement = e.target.parentNode;
          el.classList.remove('action');
          provider.is_followed = true;
        } else {
          if (success.error_code === 'RC004' || success.error_code === 'RC025' || success.error_code === 'RC016'
            || success.error_code === 'RC017') {
            this.alertService.onClose = () => {
              dataTable.reload();
              this.location.replaceState(this.location.path().split('?')[0], '');
            };
          }
          this.alertService.showDismissButton = false;
          this.alertService.dismissButtonLabel = 'Close';
          this.alertService.showCloseButton = true;
          this.alertService.closeButtonLabel = 'Close';
          this.alertService.title = success.error_messages;
          this.alertService.message = '';
          this.alertService.emitter('open');
        }
      },
      (error: any) => {
        const errorJson = error.error;
        if (errorJson.error_code === 'RC004' || errorJson.error_code === 'RC025' || errorJson.error_code === 'RC016'
          || errorJson.error_code === 'RC017') {
          this.alertService.onClose = () => {
            dataTable.reload();
            this.location.replaceState(this.location.path().split('?')[0], '');
          };
        }
        this.alertService.showDismissButton = false;
        this.alertService.dismissButtonLabel = 'Close';
        this.alertService.showCloseButton = true;
        this.alertService.closeButtonLabel = 'Close';
        this.alertService.title = errorJson.error_messages;
        this.alertService.message = '';
        this.alertService.emitter('open');
      }
    );
  }

  // Reset filter
  handleResetSearchProvider(
    // form: ControlGroup,
    filter: RtnDataTableFilterComponent
  ) {
    // let controlGroup: any = form.controls;
    // for (name in controlGroup) {
    //     let control: Control = controlGroup[name];
    //     control.updateValue('');
    //     control.setErrors(null);
    // };
    // form.value.currentPage = '';
    // filter.query(form.value);
    this.listProviders.rowsOnPage = this._searchArgs.limit;
    this.listProviders.numberOfPages = 0;
    this.listProviders.data = new Array();
    this.location.replaceState(this.location.path().split('?')[0], '');
    delete this.searchNoCriteriaMessage;
    this.searchNoDataMessage = 'There is no provider.';
    this.searchForm.reset();
    this.clearDataSearch();
  }

  // Submit filter
  handleSubmitSearchProvider(filter: RtnDataTableFilterComponent) {
    this.location.replaceState(this.location.path().split('?')[0], '');
    var self = this;
    let tmpArr = new Array();
    this.searchForm.value.disease = this.diseasesSelector.getData();
    const formVals = this.searchForm.value;
    for (const key in formVals) {
      // only filter field in form
      if (this.keyInForm.includes(String(key))) {
        tmpArr[tmpArr.length] = jQuery.trim(formVals[key]);
      }
    }

    tmpArr = tmpArr.filter((item: any) => {
      return !(item === undefined || item === null || item.length === 0);
    });
    if (tmpArr.length > 0) {

      filter.dataTable.onDataChange.subscribe(
        (success: any) => {
          self.searchNoDataMessage = 'No results were found for the criteria you searched on. Please try again.';
        },
        (error: any) => {
          // TODO
        }
      );
      delete this.searchNoCriteriaMessage;
      filter.query(this.searchForm.value);
    } else {
      this.searchNoCriteriaMessage = 'You must search by at least one criteria.';
      this.searchNoDataMessage = 'There is no provider.';
      this.listProviders.data = [];
      this.listProviders.rowsOnPage = 10;
      this.listProviders.numberOfPages = 0;

      // Set new URL
      const urlStrNew = window.location.origin + window.location.hash;
      window.history.pushState({}, null, urlStrNew); // Set new url
    }
  }

  handleFetchSearchProvider(args: any) {
    this.location.replaceState(this.location.path().split('?')[0], '');
    if (this.selectLastName !== '') {
      args.last_name = this.selectLastName;
    }
    if (this.selectFirstName !== '') {
      args.first_name = this.selectFirstName;
    }
    if (this.selectCity !== '') {
      args.city = this.selectCity;
    }
    if (this.selectState !== '') {
      args.state = this.selectState;
    }
    if (this.selectedDiseases.length > 0) {
      args.disease = this.selectedDiseases;
    }
    if (this.selectSpecialty !== '') {
      args.specialty = this.selectSpecialty;
    }
    args.page = args['currentPage'] || 1;
    args.limit = args['rowsOnPage'] || 10;

    if (this.searchProviderSubscriber) {
      this.searchProviderSubscriber.unsubscribe();
    }
    this.searchProviderSubscriber = this.searchProvider(args).subscribe(
      (success: any) => {
        this.listProviders.rowsOnPage = success.limit || 10;
        this.listProviders.numberOfPages = success.total_pages || 0;
        const providers = success.providers || [];
        providers.map((item: any, i: any) => {

          const fullName = this._utils.generateFullName(item.first_name, item.middle_name, item.last_name);
          item.fullName = fullName;
          if (item.credentials !== '' && item.credentials !== null) {
            item.fullName += ', ' + item.credentials;
          }
          item.full_address = this._utils.generateFullAddress(item.address, item.address2,
            item.city, item.state_code, item.zip);
          const listSpecialtiesName = new Array();
          const specialties = item.specialties;
          for (let i = 0; i < specialties.length; i++) {
            listSpecialtiesName.push(specialties[i].name);
          }
          item.specialties = listSpecialtiesName.join(', ');
          if (item.phone_mobile) {
            if (item.phone_mobile.indexOf('+1 ') !== -1) {
              item.phone_mobile = item.phone_mobile.substr(item.phone_mobile.indexOf('+1 ') + 3);
            }
          }
        });

        this.listProviders.currentPage = parseInt(args.page);
        this.listProviders.data = providers;
      },
      (error: any) => {
        this.listProviders.rowsOnPage = 10;
        this.listProviders.numberOfPages = 0;
        this.listProviders.data = [];
        this.searchNoDataMessage = 'There is no provider.';
      },
      () => {
        // do nothing
      }
    );
  }

  /* private getItemSearch() {
      // This function is anonymous, is executed immediately and
      // the return value is assigned to QueryString!
      let query_string: any = {};
      if (window.location.search !== '') {
          var query = window.location.search.substring(1);
          var vars = query.split('&');
          for (var i = 0; i < vars.length; i++) {
              var pair = vars[i].split('=');
              // If first entry with this name
              if (typeof query_string[pair[0]] === 'undefined') {
                  query_string[pair[0]] = decodeURIComponent(pair[1]);
                  // If second entry with this name
              } else if (typeof query_string[pair[0]] === 'string') {
                  var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                  query_string[pair[0]] = arr;
                  // If third or later entry with this name
              } else {
                  query_string[pair[0]].push(decodeURIComponent(pair[1]));
              }
          }
          return query_string;
      } else {
          return '';
      }
  }; */

  // Clear data search
  clearDataSearch() {
    this.selectLastName = '';
    this.selectFirstName = '';
    this.selectCity = '';
    // this.selectState = '';
    this.selectedDiseases = [];
    this.diseasesSelector.updateData([]);
    this.resetSpecialty();
    jQuery('#searchbox-input-state').val('');
  }

  resetSpecialty() {
    this.specialtyList = this._systemData.specialties;
    jQuery(document).ready(function () {
      jQuery('#searchbox-input-specialty').val('').addClass('ng-invalid');
    });
  }

  // Search providers
  private searchProvider(xargs: any): any {
    if (xargs.first_name === '' && xargs.last_name === '' && xargs.city === ''
      && xargs.state === '' && xargs.specialty === '' && xargs.disease === '') {
      const urlStrNew = window.location.origin + window.location.hash;
      window.history.pushState({}, null, urlStrNew); // Set new url
    } else {
      // Create new url
      const urlStrSearch: any = [];
      for (const item in xargs) {
        if (item !== 'sortField'
          && item !== 'sortOrder' && xargs[item] !== ''
          && xargs[item] !== null && xargs[item] !== 'undefined') {
          urlStrSearch.push(item + '=' + this._utils.encodeParentheses(encodeURIComponent(xargs[item])));
        }
      }

      const urlStrNew = window.location.origin + window.location.hash + '?' + urlStrSearch.join('&');
      window.history.pushState(xargs, null, urlStrNew); // Set new url
    }

    return this._restService.searchProvider(
      xargs.limit, xargs.page,
      xargs.first_name, xargs.last_name,
      xargs.city, xargs.state, xargs.disease, xargs.specialty
    );
  }
}

interface InterfaceSearchProviderArgs {
  limit: number | string;
  page: number | string;
  first_name?: number | string;
  last_name?: number | string;
  city?: number | string;
  state?: number | string;
  disease?: number | string;
  specialty?: number | string;
}

class SearchProviderArgs implements InterfaceSearchProviderArgs {
  limit: number | string = 10;
  page: number | string = 1;
}
