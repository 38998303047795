import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  updateAccountUrl = environment.serverUrl + '/users/account';
  updatePasswordUrl = environment.serverUrl + '/users/password';
  updateAddressUrl = environment.serverUrl + '/users/address';
  changePasswordURL = environment.serverUrl + '/users/forgot/change-password';
  getNotificationGlobalSettingUrl = environment.serverUrl + '/patients/notification-settings';
  getNotifySavedOpenAppointmentSettingUrl = environment.serverUrl + '/patients/notify-open-settings';
  updateNotificationGlobalSettingUrl = environment.serverUrl + '/patients/notification-settings';
  deleteNotifySavedOpenAppointmentSettingUrl = environment.serverUrl + '/patients/delete-notify-open-setting';
  getTopNotificationListUrl = environment.serverUrl + '/notifications/top';
  getDoctorDetailURL: any = environment.serverUrl + '/providers/{0}';
  getPracticeListUrl = environment.serverUrl + '/practices';
  getListDocumentsURL: any = environment.serverUrl + '/providers/{0}/documents';
  followedProviderURL = environment.serverUrl + '/providers/followed';
  unfollowProviderURL = environment.serverUrl + '/providers/unfollow';
  getDiseasesUrl = environment.serverUrl + '/diseases';
  getDiseasesBySpecialtiesUrl = environment.serverUrl + '/diseases/specialties';
  followProviderURL: any = environment.serverUrl + '/providers/{0}/follow';
  searchProviderURL = environment.serverUrl + '/providers';
  resendEmailUrl = environment.serverUrl + '/familymembers/{id}/resend-email';
  addFamilyMembersUrl = environment.serverUrl + '/familymembers';
  deleteFamilyMembersUrl = environment.serverUrl + '/familymembers/';
  updateFamilyMembersUrl = environment.serverUrl + '/familymembers/';
  getInsuranceSettingsUrl = environment.serverUrl + '/patients/insurance';
  getInsuranceListUrl = environment.serverUrl + '/insurance-list';
  getListEmailPatientUrl = environment.serverUrl + '/patients/list-email-patients';
  getListFamilyMemberUrl = environment.serverUrl + '/familymembers';
  saveInsuranceSettingsUrl = environment.serverUrl + '/patients/insurance';
  getTotalFamilyMemberUrl = environment.serverUrl + '/familymembers/total';
  sendPhoneNumberCodeUrl = environment.serverUrl + '/phone-mobile-code';
  verifyPhoneNumberCodeUrl = environment.serverUrl + '/verify-phone-mobile';
  sendFeedbackToProviderURL: any = environment.serverUrl + '/providers/{0}/feedback';
  sendFeedbackToRTN_URL = environment.serverUrl + '/feedback';
  getNumOfUnreadNotificationUrl = environment.serverUrl + '/notifications/unread'; // namdv
  getListPatientFamilyMemberUrl = environment.serverUrl + '/patients/patient-family';
  getSavedSearchOpeningUrl = environment.serverUrl + '/appointment/openings/';
  getAppointmentDetailByIdUrl = environment.serverUrl + '/appointment/appt-detail';
  getListProviderUrl = environment.serverUrl + '/providers/list-providers';
  getSpecialtyByDiseaseUrl = environment.serverUrl + '/diseases/specialties';
  findAppointmentUrl = environment.serverUrl + '/appointment/list';
  updateHiddenStateUrl = environment.serverUrl + '/schedules/update-hidden-state';
  searchOpeningsUrl = environment.serverUrl + '/appointment/openings';
  saveSettingsUrl = environment.serverUrl + '/patients/notify-open-settings';
  blockNotifySavedOpenAppointmentSettingUrl = environment.serverUrl + '/patients/block-notify-open-setting';
  verifyphonenourl = environment.serverUrl+'/patients/verifyphoneno';
  isverfiedphonenostatusurl = environment.serverUrl+'/patients/checkisphonenoverified';
  getAppointmentTypeListurl = environment.serverUrl+'/apttypes/fetchtypes';
 /* isdownloadfileurl = environment.serverUrl+'/patients/download';*/
 movedNotifySavedOpenAppointmentSettingUrl = environment.serverUrl + '/patients/move-notify-open-setting/';
 getPracticeAppointmentTypeListurl = environment.serverUrl+'/practiceapttypes'; 

  constructor(private _http: HttpClient) {
  }

  saveSettings(data: any) {
    return this._http.post(this.saveSettingsUrl, JSON.stringify(data));
  }

  searchOpenings(
    limit: any,
    page: any,
    patient_name: string,
    family_id: any,
    disease: string,
    specialty: string,
    provider: string,
    insurance_id: string,
    insurance_number: string,
    zip: string,
    distance: string,
    show: string
  ) {
    var params = new HttpParams()
      .set('limit', limit)
      .set('page', page)
      .set('patient_name', patient_name)
      .set('family_id', family_id)
      .set('disease', disease)
      .set('specialty', specialty)
      .set('zip', zip)
      .set('distance', distance)
      .set('show', show);
    if (insurance_id) {
      params = params.set('insurance_id', insurance_id);
    }
    if (insurance_number) {
      params = params.set('insurance_number', insurance_number);
    }
    if(provider) {
      params = params.set('provider', provider);
    }
    else
    {
      params = params.set('provider', '');
    }

    return this._http.get(this.searchOpeningsUrl, {params: params});
  }

  updateHiddenState(data: any) {
    return this._http.post(this.updateHiddenStateUrl, JSON.stringify(data));
  }

  findAppointment(specialty: string, provider: string, date: any, show: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('api-version', '2.0');
    const params = new HttpParams()
      .set('specialty', specialty)
      .set('provider', provider)
      .set('date', date)
      .set('show', show);
    return this._http.get(this.findAppointmentUrl, {params: params, headers: headers});
  }

  getInsuranceSettings() {
    return this._http.get(this.getInsuranceSettingsUrl);
  }

  getSpecialtyByDisease(id: any) {
    const params = new HttpParams().set('disease_ids', id);
    return this._http.get(this.getSpecialtyByDiseaseUrl, {params: params});
  }

  getDiseases() {
    return this._http.get(this.getDiseasesUrl);
  }

  getListProvider() {
    return this._http.get(this.getListProviderUrl);
  }

  getAppointmentDetailById(id: any) {
    return this._http.get(this.getAppointmentDetailByIdUrl + id);
  }

  getSavedSearchOpening(id: any) {
    return this._http.get(this.getSavedSearchOpeningUrl + id);
  }

  getListPatientFamilyMember() {
    return this._http.get(this.getListPatientFamilyMemberUrl);
  }

  debugMode() {
    return !environment.production;
  }

  getNumOfUnreadNotification() {
    return this._http.get(this.getNumOfUnreadNotificationUrl);
  }

  sendFeedbackToRTN(params: any) {
    return this._http.put(this.sendFeedbackToRTN_URL, params).pipe(
      map(results => results)
    );
  }

  sendFeedbackToProvider(providerId: number | string, params: any) {
    return this._http.post(this.sendFeedbackToProviderURL.format(providerId), params).pipe(
      map(results => results)
    );
  }

  sendPhoneNumberCode(data: any) {
    return this._http.put(this.sendPhoneNumberCodeUrl, JSON.stringify(data));
  }

  verifyPhoneNumberCode(data: any) {
    return this._http.put(this.verifyPhoneNumberCodeUrl, JSON.stringify(data));
  }

  getTotalFamilyMember() {
    return this._http.get(this.getTotalFamilyMemberUrl);
  }

  saveInsuranceSettings(data: any) {
    return this._http.post(this.saveInsuranceSettingsUrl, JSON.stringify(data));
  }

  getListFamilyMember() {
    return this._http.get(this.getListFamilyMemberUrl);
  }

  getListEmailPatient() {
    return this._http.get(this.getListEmailPatientUrl);
  }

  getInsuranceList() {
    return this._http.get(this.getInsuranceListUrl);
  }

  updateFamilyMembers(data: any, id: any) {
    return this._http.put(this.updateFamilyMembersUrl + id, JSON.stringify(data));
  }

  addFamilyMembers(data: any) {
    return this._http.post(this.addFamilyMembersUrl, JSON.stringify(data));
  }

  deleteFamilyMembers(id: any) {
    return this._http.delete(this.deleteFamilyMembersUrl + id);
  }

  resendEmail(memberId: any) {
    return this._http.post(this.resendEmailUrl, {memberId: memberId});
  }

  searchProvider(
    limit: number | string,
    page: number | string,
    first_name: number | string,
    last_name: number | string,
    city: number | string,
    state: number | string,
    disease: any,
    specialty: number | string
  ) {
    const params = {
      limit,
      page,
      first_name,
      last_name,
      city,
      state,
      disease,
      specialty
    };
    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this._http.get(this.searchProviderURL, {params: queryParams});
  }

  followProvider(providerId: number | string) {
    return this._http.post(this.followProviderURL.format(providerId), {}).pipe(
      map(results => results)
    );
  }

  unfollowProvider(params: any) {
    return this._http.post(this.unfollowProviderURL, params).pipe(
      map(results => results)
    );
  }

  followedProvider(limit: number | string, page: number | string) {
    const params = {
      limit: limit,
      page: page
    };
    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this._http.get(this.followedProviderURL, {params: queryParams});
  }

  getListDocuments(providerId: number | string, limit: number | string, page: number | string) {
    const params = {
      limit: limit,
      page: page
    };
    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this._http.get(this.getListDocumentsURL.format(providerId), {params: queryParams});
  }

  getPracticeList() {
    return this._http.get(this.getPracticeListUrl);
  }

  getDoctorDetail(providerId: number | string) {
    return this._http.get(this.getDoctorDetailURL.format(providerId)).pipe(
      map(results => results)
    );
  }

  getTopNotificationList(limit: any) {
    const params = new HttpParams().set('limit', limit);
    return this._http.get(this.getTopNotificationListUrl, {params});
  }

  deleteNotifySavedOpenAppointmentSetting(data: any) {
    return this._http.delete(this.deleteNotifySavedOpenAppointmentSettingUrl, {params: data});
  }

  updateNotificationGlobalSetting(data: any) {
    return this._http.post(this.updateNotificationGlobalSettingUrl, JSON.stringify(data));
  }

  getNotifySavedOpenAppointmentSetting() {
    return this._http.get(this.getNotifySavedOpenAppointmentSettingUrl);
  }

  getNotificationGlobalSetting() {
    return this._http.get(this.getNotificationGlobalSettingUrl);
  }

  changePassword(params: any) {
    return this._http.put(this.changePasswordURL, params).pipe(
      map(results => results)
    );
  }

  updateAddress(data: any) {
    return this._http.put(this.updateAddressUrl, JSON.stringify(data));
  }

  updatePassword(data: any) {
    return this._http.put(this.updatePasswordUrl, JSON.stringify(data));
  }

  updateAccount(data: any) {
    return this._http.put(this.updateAccountUrl, JSON.stringify(data));
  }
  blockUserFromWaitList(data: any) {
    return this._http.post(this.blockNotifySavedOpenAppointmentSettingUrl, data);
  }
  verifyPhoneNo(data: any) { //console.log('ok');
    return this._http.post(this.verifyphonenourl, JSON.stringify(data));
  }

  isverifiedphoneno(type:any) { 
    const data = {
     type
    };
    const params =  UtilsService.buildQueryParams(type);//.set('type', type);
    return this._http.get(this.isverfiedphonenostatusurl,{params: params});
  }

  getAppointmentTypeList() {
    const data = {'is_limit':1}
    const params =  UtilsService.buildQueryParams(data);//.set('type', type);
    return this._http.get(this.getAppointmentTypeListurl,{params: params});
  }
  movedNotifySavedOpenAppointmentSetting(id:any) {
    return this._http.get(this.movedNotifySavedOpenAppointmentSettingUrl + id);
  }
  /* getPracticeAppointmentTypeList(slot_id:any) {
    const data = {'is_limit':1, 'slot_id' : slot_id};
    const params =  UtilsService.buildQueryParams(data);//.set('type', type);
    return this._http.get(this.getPracticeAppointmentTypeListurl,{params: params});
  }
 downloadfile(filename: any) {
    const data = {filename};
     const params =  UtilsService.buildQueryParams(filename);//.set('type', type);
     return this._http.get(this.isdownloadfileurl,{params: params}); 
  }*/
}
