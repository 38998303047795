import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MasterSchedulesRoutingModule} from './master-schedules-routing.module';
import {MasterSchedulesComponent} from './master-schedules.component';
import {ScheduleTooltipComponent} from './schedule-tooltip/schedule-tooltip.component';
import {ScheduleCheckInComponent} from './modals/schedule-check-in-component/schedule-check-in.component';
import {ScheduleCheckOutComponent} from './modals/schedule-check-out-component/schedule-check-out.component';
import {ScheduleEditComponent} from './modals/schedule-edit-component/schedule-edit.component';
import {ScheduleCancelComponent} from './modals/schedule-cancel-component/schedule-cancel.component';
import {ScheduleCancelCheckInComponent} from './modals/schedule-cancel-check-in-component/schedule-cancel-check-in.component';
import {HeaderComponent} from './header/header.component';
import {SharedModule} from '@app/shared';
import {FormsModule} from '@angular/forms';
import { ArrivalConfirmationComponent } from './arrival-confirmation/arrival-confirmation.component';
import { SchedulesMassPatientNotificationComponent } from './schedules-mass-patient-notification/schedules-mass-patient-notification.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        MasterSchedulesRoutingModule
    ],
    declarations: [
        MasterSchedulesComponent,
        ScheduleTooltipComponent,
        ScheduleCheckInComponent,
        ScheduleCheckOutComponent,
        ScheduleEditComponent,
        ScheduleCancelComponent,
        ScheduleCancelCheckInComponent,
        HeaderComponent,
        ArrivalConfirmationComponent,
        SchedulesMassPatientNotificationComponent
    ]
})
export class MasterSchedulesModule {
}
