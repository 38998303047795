import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, } from '@angular/core';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

import { DataLogsService } from '@app/data-logs/data-logs.service';
import * as moment from 'moment';
import { AlertService } from '@app/shared/alert';

import { Utils } from '@app/shared/utils';
import { ChartData } from '@app/shared/models/chartData';
declare let jQuery: any;

@Component({
  selector: 'app-provider-statistical-typeofappointment',
  templateUrl: './provider-statistical-typeofappointment.component.html',
  styleUrls: ['./provider-statistical-typeofappointment.component.scss']
})
export class ProviderStatisticalTypeofappointmentComponent implements OnInit, AfterViewInit {
  currentDate: any = moment();
  practiceId : number = 0;
  providerId : number = 0;
  dataSearchChart: dataStatistic = new dataStatistic();
  errorResponse: dataStatistic = new dataStatistic();
  data_chart: any;
  maxDate:any;

  public chartDataModel:any = [];
  public chartType: string = 'pie';
  public chartDatasets: Array<any> = [{ data: this.chartDataModel }];
  public chartLabels: Array<any> = [];

  public chartColors: Array<any> = [
      {
          backgroundColor: [],
          borderWidth: 2,
      }
  ];

  public chartOptions: any = {
      responsive: true,
      legend: {
          display: true,
          position: 'bottom'
      },
      tooltips: {
          callbacks: {
            
              label: (tooltipItem:any, data:any) => {
                  var labelText = this.data_chart[tooltipItem.index].label;
                  var labelValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                  return labelText + ' : ' + labelValue;
              }
          }
      }
  };

  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;
  constructor(
      private _alertService: AlertService,
      private _loadingIndicatorService: LoadingIndicatorService,
      private _datalogsService: DataLogsService,
      private _utils: Utils,
  ) {
      this.dataSearchChart.dateFrom = moment().subtract(1, "days").format('LL');
      this.dataSearchChart.dateTo = moment().subtract(1, "days").format('LL');
  }

ngOnInit() {

    this.practiceId = parseInt(localStorage.getItem('user_practice'));
    this.providerId = parseInt(localStorage.getItem('user_providerId'));
}

ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
        this.maxDate = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    }else{
        this.maxDate = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(_self.inputDateFrom.nativeElement).datepicker({
        dateFormat: 'MM dd, yy',
        numberOfMonths: 2,
        showButtonPanel: true,
        maxDate: moment(this.maxDate).toDate(),
        changeMonth: true,
        changeYear: true,
        onSelect: (selected: any) => {
            _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
        }
    });
    jQuery(_self.inputDateFrom.nativeElement).datepicker("setDate", this.dataSearchChart.dateFrom);
    jQuery(_self.clickDateFrom.nativeElement).click(() => {
        jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
    });

    jQuery(_self.inputDateTo.nativeElement).datepicker({
        dateFormat: 'MM dd, yy',
        numberOfMonths: 2,
        showButtonPanel: true,
        maxDate: moment(this.maxDate).toDate(),
        changeMonth: true,
        changeYear: true,
        onSelect: (selected: any) => {
            _self.dataSearchChart.dateTo = jQuery(_self.inputDateTo.nativeElement).val();
        }
    });
    jQuery(_self.inputDateTo.nativeElement).datepicker("setDate", this.dataSearchChart.dateTo);
    jQuery(this.clickDateTo.nativeElement).click(() => {
        jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });

    jQuery("#showDataChart").on("click", function () {
        // _self.resetData();
        _self.updateData();
    });
    /*jQuery("#exportDataChart").on("click", function () {
        _self.export();
    });*/
}

validateDateFromLessDateTo(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    if (date1 > date2) {
        this.errorResponse.dateFrom = message;
        return false;
    } else {
        this.errorResponse.dateFrom = null;
        return true;
    }
}

validateDateMoreThan31(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 31) {
        this.errorResponse.dateTo = message;
        return false;
    } else {
        this.errorResponse.dateTo = null;
        return true;
    }
}

validate() {
    const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
    const validateDateMoreThan31 = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
    if (validateDateFromLessDateTo && validateDateMoreThan31) {
        return true;
    }
    return false;
}

updateData() {
    const dateFrom = this.dataSearchChart.dateFrom;
    const dateTo = this.dataSearchChart.dateTo;

    if (this.validate()) {
        this.getStatisticalAppt(dateFrom, dateTo, this.practiceId, this.providerId);
    } else {
        this.resetData();
        return;
    }
}

export() {
    const _self = this;
    const dateFrom = this.dataSearchChart.dateFrom;
    const dateEnd = this.dataSearchChart.dateTo;
    
    if (this.validate()) {
        const token = localStorage.getItem('user_token');
        jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
            httpMethod: 'POST',
            data: {
                'token': token,
                'practice': this.practiceId,
                'providerId': this.providerId,
                'startTime': dateFrom,
                'endTime': dateEnd,
                'chartType': 9
            }
          }).done(function () {
            // TODO
          }).fail(function () {
              _self._alertService.onClose = () => {
                  location.reload();
              };
          });
    } else {
        this.resetData();
        this.chartDatasets = [{ data: [] }];
    }
}

getStatisticalAppt(dateFrom: any, dateEnd: any, practiceID: any, providerID: any) {
    this._loadingIndicatorService.start();
    this.chartDatasets = [new ChartData()];
    this.chartLabels = [];
    this._datalogsService.getStatiscalApptTypes(dateFrom, dateEnd, practiceID, providerID).subscribe(
        (success: any) => {
            this._loadingIndicatorService.stop();
            this.resetData();
            
            this.data_chart = success.statistic;
            let tempdata = [];

            if (this.data_chart !== undefined && this.data_chart.length > 0) {
                const length = this.data_chart.length;
                for (let index = 0; index < length; index++) {
                  tempdata.push(this.data_chart[index].noofapts);
                  this.chartLabels.push(this.data_chart[index].label + '(' + this.data_chart[index].noofapts + ')');
                  this.chartColors[0].backgroundColor.push(this.data_chart[index].color_code);
                }
            }
            this.chartDatasets = [{ data: tempdata }];

            setTimeout(function(){
                let minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
                console.log('Height:' + minHeight);
                jQuery('#datalogcontainer').css('height', 1024);
            }, 500);            

        },(error: any) => {
            this.chartDatasets = [{ data: [] }];
            this.chartLabels = [];
            this._loadingIndicatorService.stop();
        }
    );
}

    resetData() {
        this.chartDataModel.splice(0);
    }
    printDataChart(){
        window.print();
    }
}

export class dataStatistic {
    dateFrom: any = null;
    dateTo: any = null;
}
