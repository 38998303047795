<modal #Verifyphoneno_Modals class="appointment-new">
    <form #modalForm="ngForm" class="form">
      <div class="form-content">
        <div class="form-header">Verify Mobile Number</div>
        <div class="form-info" style="line-height:30px;">
          <p>
            You're automatically set up to receive our notifications. 
            If you haven't verified your phone number via our welcome text, kindly confirm your number <label *ngIf="patientDetails?.phone_mobile">({{patientDetails?.phone_mobile}}) </label><label *ngIf="patientDetails?.alt_phone_mobile"> ({{patientDetails?.alt_phone_mobile}}) </label>.
            For those unverified, fear not, essential notifications will be available via email and our patient portal.
          </p>
        </div>
        <div class="button">
          <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="dismiss()">Cancel</button>
          <button type="submit" class="btn btn-rtn btn-90 float-right" autofocus (click)="verifyPhoneno()">Verify</button>
        </div>
      </div>
    </form>
</modal>
  