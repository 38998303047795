import {Component, AfterViewInit, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {AlertService} from '@app/shared/alert';
import {Subscription} from 'rxjs';
import {FeedbackProvider_ModalsComponent} from '@app/patients/patient/feedback-provider';
import {RestService} from '@app/core/rest.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {SystemEvent, SystemInterval, SystemService, SystemData} from '../../shared/system';

import * as moment from 'moment';
import {PatientsService} from '@app/patients/patients.service';

declare var jQuery: any;
const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
  selector: 'rtn-doctor-detail',
  templateUrl: './doctor-detail.component.html',
  styleUrls: ['../patients.component.scss', './doctor-detail.component.scss'],
  moduleId: module.id,
})

export class DoctorDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  // declare arrayActiveModule
  arrayModule = new Array();
  _listDocuments: any;
  _doctorDetail: any;
  providerName = '';
  providerSpecialty: any;

  /*notificationMethods: any[];
  _weekStart: number = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)
  notificationSettingFields: any = {};
  notificationSettingValues: any = {};
  ngDatePicker: any = {};
  ngTimePicker: any = {};
  notificationSettingForm: ControlGroup;
  _messageResponseError: any;
  _messageResponseSuccess: any;
  _messageNotifyCancelDurationAgree: any;
  _messageNotifyIfBusinessFallsAgree: any;
  _messageNotifyBeforeNextMyAppointmentAgree: any;
  _messageNotifyMethod: any;

  startDateError: boolean = false;
  endDateError: boolean = false;
  startTimeError: boolean = false;
  endTimeError: boolean = false;
  beforeTimeError: boolean = false;
  businessFallError: boolean = false;
  beforeNextError: boolean = false;

  disabledNotifyField: boolean = true;
  disabledBeforeBusiness: boolean = true;
  disabledDayNextAppointment: boolean = true;
  disabled_notifications_cancellation: boolean = false;
  disabled_notifications_behind_schedule: boolean = false;
  disabled_notifications_the_next: boolean = false;

  listNumberNotification: any[] = new Array();
  listDayNotification: any[] = new Array();

  listTimeNotification: any[] = new Array();

  notificationMethodSMS: boolean = false;
  getProviderNotificationSubscriber: Subscription;
  updateProviderNotificationSettingSubscriber: Subscription;*/

  autoloadDocumentList = false;

  getDoctorDetailSubscriber: Subscription;
  getListDocumentsSubscriber: Subscription;
  loadPracticeListSubscriber: Subscription;
  @ViewChild(FeedbackProvider_ModalsComponent) feedbackProviderDirective: FeedbackProvider_ModalsComponent;

  constructor(
    private _restService: PatientsService,
    public _routeParams: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService,
    private _router: Router,
    private _utils: Utils,
    private _systemEvent: SystemEvent,
    public _systemService: SystemService,
  ) {
    /*this.notificationMethods = [{
        'label': 'RTN',
        'name': 'RTN',
        'bought': false,
        'disabled': false
    }, {
            'label': 'SMS',
            'name': 'SMS',
            'bought': false,
            'disabled': false
        }, {
            'label': 'Email',
            'name': 'EMAIL',
            'bought': false,
            'disabled': false
        }];

    // Get current date
    var _momentCurrentDateObj = moment().local();

    this.ngDatePicker['format'] = 'MM dd, yyyy';
    this.ngDatePicker['todayHighlight'] = true;
    this.ngDatePicker['weekStart'] = this._weekStart;
    this.ngDatePicker['defaultViewDate'] = {
        day: _momentCurrentDateObj.day(),
        month: _momentCurrentDateObj.month(),
        year: _momentCurrentDateObj.year()
    };*/

    this._listDocuments = new Object();
    this._listDocuments['data'] = new Array();
    this._listDocuments['numberOfPages'] = 0;
    this._listDocuments['rowsOnPage'] = 5;

    this._doctorDetail = new Object();

    // Notification settings
    /*var _nearest30MinutesInterval = moment(_momentCurrentDateObj).add((60 - _momentCurrentDateObj.minute()) % 30, 'minutes');
    this.ngTimePicker['startTime'] = _nearest30MinutesInterval.format('HH:mm');
    this.ngTimePicker['endTime'] = _nearest30MinutesInterval.format('HH:mm');

    this.notificationSettingFields = {
        notifyMethod: [new Array(), Validators.compose([Validators.required])],
        notifyCancelDurationAgree: [false, Validators.compose([Validators.required])],
        notifyCancelDurationStartDate: [_nearest30MinutesInterval.format('MMMM DD, YYYY'), Validators.compose([Validators.required])],
        notifyCancelDurationEndDate: [_nearest30MinutesInterval.format('MMMM DD, YYYY'), Validators.compose([Validators.required])],
        notifyCancelDurationStartTime: ['00:00', Validators.compose([Validators.required])],
        notifyCancelDurationEndTime: ['00:00', Validators.compose([Validators.required])],
        notifyIfBusinessFallsAgree: [false, Validators.compose([Validators.required])],
        notifyIfBusinessFallsBeforeTime: ['30', Validators.compose([Validators.required])],
        notifyIfBusinessFallsDurationTime: ['25', Validators.compose([Validators.required])],
        notifyBeforeNextMyAppointmentAgree: [false, Validators.compose([Validators.required])],
        notifyBeforeNextMyAppointmentDays: ['1', Validators.compose([Validators.required])]
    };

    this.notificationSettingForm = this.formBuilder.group(this.notificationSettingFields);

    // Get default values
    Object.keys(this.notificationSettingForm.controls).map((_k: any) => {
        this.notificationSettingValues[_k] = this.notificationSettingForm.controls[_k].value;
    });*/
  }

  ngOnInit() {
    // console.log(this._router.url.split('/')[2]);
    // get active module array
    this.arrayModule = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
    /*for (let i = 5; i <= 90; i += 5) {
        this.listNumberNotification.push(i);
    }
    for (let j = 1; j <= 5; j++) {
        this.listDayNotification.push(j);
    }
    var hour: string = '';
    var minutes: string = '';
    for (var h = 0; h <= 23; h++) {
        for (var m = 0; m <= 30; m += 30) {
            if (h < 10) {
                hour = '0' + h.toString();
            } else {
                hour = h.toString();
            }
            if (m < 10) {
                minutes = '0' + m.toString();
            } else {
                minutes = m.toString();
            }
            this.listTimeNotification.push(hour + ':' + minutes);
        }
    }*/

    if (this._utils.isPartialActiveStatus()) {
      this._router.navigate(['/patient', 'appointment', 'openings']);
    }

    // Force load document list
    this.autoloadDocumentList = true;

    // Get doctor detail
    if (this.getDoctorDetailSubscriber) {
      this.getDoctorDetailSubscriber.unsubscribe();
    }
    this.getDoctorDetailSubscriber = this._restService.getDoctorDetail(this._routeParams.snapshot.paramMap.get('id')).subscribe(
      (success: any) => {
        // Create fullname
        console.log(success);
        const fullName = this._utils.generateFullName(success.provider.first_name, success.provider.middle_name, success.provider.last_name);
        success.provider.fullName = fullName;
        if (success.provider.credentials !== '' && success.provider.credentials !== null) {
          // success.provider.fullName += ', ' + success.provider.credentials;
        }
        this.providerName = success.provider.fullName;

        const specialties = success.provider.specialties;
        const specialtiesForDisplay = new Array();
        const specialtiesId = new Array();
        for (let i = 0; i < specialties.length; i++) {
          specialtiesForDisplay.push(specialties[i].name);
          specialtiesId.push(specialties[i].id);
        }
        success.provider.specialties = specialtiesForDisplay.join(', ');
        this.providerSpecialty = specialtiesId;

        success.provider.opening_hours = this._utils.generateOpeningHours(success.provider.opening_hours, 3);
        this._doctorDetail = success.provider;
        if (this._doctorDetail.phone_mobile) {
          if (this._doctorDetail.phone_mobile.indexOf('+1 ') !== -1) {
            this._doctorDetail.phone_mobile = this._doctorDetail.phone_mobile.substr(this._doctorDetail.phone_mobile.indexOf('+1 ') + 3);
          }
        }
        this._doctorDetail.full_address = this._utils.generateFullAddress(success.provider.address, success.provider.address2,
          success.provider.city, success.provider.state_code, success.provider.zip);
        if (success.provider.website_url !== null) {
          if (success.provider.website_url.indexOf('http://') === -1 && success.provider.website_url.indexOf('https://') === -1) {
            this._doctorDetail.website_link = 'http://' + success.provider.website_url;
          } else {
            this._doctorDetail.website_link = success.provider.website_url;
          }
        }
        if (this.loadPracticeListSubscriber) {
          this.loadPracticeListSubscriber.unsubscribe();
        }
        this.loadPracticeListSubscriber = this._restService.getPracticeList().subscribe(
          (success: any) => {
            if (success.practices.length > 0) {
              success.practices.map((item: any) => {
                if (item.id === parseInt(this._doctorDetail.office_name)) {
                  this._doctorDetail.office_name = item.name;
                  this._doctorDetail.insurances = item.insurances;
                }
              });
            }
          }
        );
      },
      (error: any) => {
        const errorJson = error.json();
        if (['RC004', 'RC025', 'RC016', 'RC072'].indexOf(errorJson.error_code) > -1) {
          this._alertService.onClose = () => {
            this._router.navigate(['/patient', 'follow-providers']);
          };
        }
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Close';
        this._alertService.title = errorJson.error_messages;
        this._alertService.message = '';
        this._alertService.emitter('open');
      }
    );

    // Get provider notification setting
    /* if (this.getProviderNotificationSubscriber) {
        this.getProviderNotificationSubscriber.unsubscribe();
    }
    this.getProviderNotificationSubscriber = this._doctorService.getProviderNotification(this._routeParams.get('id')).subscribe(
        (success: any) => {
            if (success.error_code === 'RC000') {
                if (!success.phone_mobile_verified) {
                    this.notificationMethods[1].disabled = true;
                    this.notificationMethodSMS = true;
                }

                var _reponse = success.notification_settings;
                var _methodNotify = new Array();
                if (_reponse.methods.email) {
                    _methodNotify.push('EMAIL');
                }
                if (_reponse.methods.push_notification) {
                    _methodNotify.push('RTN');
                }
                if (_reponse.methods.sms) {
                    _methodNotify.push('SMS');
                }
                this.notificationSettingValues['notifyMethod'] = _methodNotify;

                this.notificationSettingValues.notifyCancelDurationAgree = _reponse.notify_cancels.enabled;
                if (this.notificationSettingValues.notifyCancelDurationAgree) {
                    this.disabledNotifyField = false;
                } else {
                    this.disabledNotifyField = true;
                }
                if (_reponse.notify_cancels.start !== null) {
                    this.notificationSettingValues.notifyCancelDurationStartDate = moment(_reponse.notify_cancels.start, 'YYYY-MM-DD').format('MMMM DD, YYYY');
                }
                if (_reponse.notify_cancels.end !== null) {
                    this.notificationSettingValues.notifyCancelDurationEndDate = moment(_reponse.notify_cancels.end, 'YYYY-MM-DD').format('MMMM DD, YYYY');
                }
                if (_reponse.notify_cancels.from !== null) {
                    this.notificationSettingValues.notifyCancelDurationStartTime = _reponse.notify_cancels.from;
                }
                if (_reponse.notify_cancels.to !== null) {
                    this.notificationSettingValues.notifyCancelDurationEndTime = _reponse.notify_cancels.to;
                }

                this.notificationSettingValues.notifyIfBusinessFallsAgree = _reponse.notify_falls.enabled;
                if (this.notificationSettingValues.notifyIfBusinessFallsAgree) {
                    this.disabledBeforeBusiness = false;
                } else {
                    this.disabledBeforeBusiness = true;
                }
                if (_reponse.notify_falls.before !== null) {
                    this.notificationSettingValues.notifyIfBusinessFallsBeforeTime = _reponse.notify_falls.before;
                }
                if (_reponse.notify_falls.time !== null) {
                    this.notificationSettingValues.notifyIfBusinessFallsDurationTime = _reponse.notify_falls.time;
                }

                this.notificationSettingValues.notifyBeforeNextMyAppointmentAgree = _reponse.notify_next_appts.enabled;
                if (this.notificationSettingValues.notifyBeforeNextMyAppointmentAgree) {
                    this.disabledDayNextAppointment = false;
                } else {
                    this.disabledDayNextAppointment = true;
                }
                if (_reponse.notify_next_appts.days !== null) {
                    this.notificationSettingValues.notifyBeforeNextMyAppointmentDays = _reponse.notify_next_appts.days;
                }
            } else if (success.error_code === 'RC004') {
                this._messageResponseError = success.error_messages;
            }
        },
        (error: any) => {
            var errorJson = error.json();
            if (['RC004', 'RC025', 'RC016', 'RC072'].indexOf(errorJson.error_code) > -1) {
                this._alertService.onClose = () => {
                    this._router.navigate(['/patient', 'follow-providers']);
                };
            } else {
                this._messageResponseError = error.error_messages;
            }
            this._alertService.showDismissButton = false;
            this._alertService.dismissButtonLabel = 'Close';
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'Close';
            this._alertService.title = errorJson.error_messages;
            this._alertService.emitter('open');
        }
    );*/
  }

  ngAfterViewInit() {
    this._systemEvent.menu.emit('find-providers');
  }

  ngOnDestroy() {
    if (this.getDoctorDetailSubscriber) {
      this.getDoctorDetailSubscriber.unsubscribe();
    }
    if (this.getListDocumentsSubscriber) {
      this.getListDocumentsSubscriber.unsubscribe();
    }
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
    /*if (this.getProviderNotificationSubscriber) {
        this.getProviderNotificationSubscriber.unsubscribe();
    }
    if (this.updateProviderNotificationSettingSubscriber) {
        this.updateProviderNotificationSettingSubscriber.unsubscribe();
    }*/
  }

  openSearchOpeningPage() {
    // Save data provider for redirect
    localStorage.setItem('providerName', this.providerName);
    localStorage.setItem('providerSpecialty', this.providerSpecialty);
    // localStorage.setItem('providerSpecialty', '1');
    this._router.navigate(['/patient/appointment/openings'], {queryParams: {'': 'provider'}});
  }

  downloadFile(_downloadUrl: string) {
    const token = localStorage.getItem('user_token');
    jQuery.fileDownload(_downloadUrl, {
      httpMethod: 'POST',
      data: 'token=' + token
    })
      .done(() => {
        // TODO
      })
      .fail(() => {
        this._alertService.onClose = () => {
          location.reload();
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Close';
        this._alertService.title = 'This document is not available to download anymore.';
        this._alertService.message = '';
        this._alertService.emitter('open');
      });
  }

  handleFetchListDocuments(e: any) {
    // Get list of documents
    if (this.getListDocumentsSubscriber) {
      this.getListDocumentsSubscriber.unsubscribe();
    }
    this.getListDocumentsSubscriber = this._restService.getListDocuments(
      this._routeParams.snapshot.paramMap.get('id'), e.rowsOnPage, e.currentPage
    ).subscribe(
      (success: any) => {
        this._listDocuments['data'] = success.documents.list;
        this._listDocuments['numberOfPages'] = success.documents.total_pages;
        this._listDocuments['rowsOnPage'] = success.documents.limit;
      },
      (error: any) => {
        const errorJson = error.json();
        this._alertService.onClose = () => {
          // do nothing
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Close';
        this._alertService.title = errorJson.error_messages;
        this._alertService.message = '';
        this._alertService.emitter('open');
      },
      () => {
        // do nothing
      }
    );
  }

  /*handleDatePickerOnShow(e: any) {
      var _self = this;
      jQuery(e.element.picker).addClass('provider-detail-notification-settings');
  }

  handleNotificationSettingFormSubmit(form: any, data: any) {
      let _self = this;
      this.setAllErrorIsFalse();
      var dataFormat = this.formatDataNotificationSetting(data);

      if (this.updateProviderNotificationSettingSubscriber) {
          this.updateProviderNotificationSettingSubscriber.unsubscribe();
      }
      this.updateProviderNotificationSettingSubscriber = this._doctorService.updateProviderNotification(
          this._routeParams.get('id'), dataFormat
      ).subscribe(
          (success: any) => {
              this.setAllErrorIsFalse();
              this._messageResponseSuccess = success.error_messages;
          },
          (error: any) => {
              var responseError = error.json();
              if (responseError.error_code === 'RC014') {
                  if (typeof responseError.error_fields.methods_sms !== 'undefined') {
                      this._messageNotifyMethod = responseError.error_fields.methods_sms;
                  } else if (typeof responseError.error_fields.methods_email !== 'undefined') {
                      this._messageNotifyMethod = responseError.error_fields.methods_email;
                  }
                  if (typeof responseError.error_fields.notify_cancels_enabled !== 'undefined') {
                      this._messageNotifyCancelDurationAgree = responseError.error_fields.notify_cancels_enabled;
                      this.startDateError = false;
                      this.endDateError = false;
                      this.startTimeError = false;
                      this.endTimeError = false;
                  } else if (typeof responseError.error_fields.notify_cancels_start !== 'undefined') {
                      this._messageNotifyCancelDurationAgree = responseError.error_fields.notify_cancels_start;
                      this.startDateError = true;
                      this.endDateError = false;
                      this.startTimeError = false;
                      this.endTimeError = false;
                  } else if (typeof responseError.error_fields.notify_cancels_end !== 'undefined') {
                      this._messageNotifyCancelDurationAgree = responseError.error_fields.notify_cancels_end;
                      this.startDateError = false;
                      this.endDateError = true;
                      this.startTimeError = false;
                      this.endTimeError = false;
                  } else if (typeof responseError.error_fields.notify_cancels_from !== 'undefined') {
                      this._messageNotifyCancelDurationAgree = responseError.error_fields.notify_cancels_from;
                      this.startDateError = false;
                      this.endDateError = false;
                      this.startTimeError = true;
                      this.endTimeError = false;
                  } else if (typeof responseError.error_fields.notify_cancels_to !== 'undefined') {
                      this._messageNotifyCancelDurationAgree = responseError.error_fields.notify_cancels_to;
                      this.startDateError = false;
                      this.endDateError = false;
                      this.startTimeError = false;
                      this.endTimeError = true;
                  }
                  if (typeof responseError.error_fields.notify_falls_enabled !== 'undefined') {
                      this._messageNotifyIfBusinessFallsAgree = responseError.error_fields.notify_falls_enabled;
                      this.beforeTimeError = false;
                      this.businessFallError = false;
                  } else if (typeof responseError.error_fields.notify_falls_before !== 'undefined') {
                      this._messageNotifyIfBusinessFallsAgree = responseError.error_fields.notify_falls_before;
                      this.beforeTimeError = true;
                      this.businessFallError = false;
                  } else if (typeof responseError.error_fields.notify_falls_time !== 'undefined') {
                      this._messageNotifyIfBusinessFallsAgree = responseError.error_fields.notify_falls_time;
                      this.beforeTimeError = false;
                      this.businessFallError = true;
                  }
                  if (typeof responseError.error_fields.notify_next_appts_enabled !== 'undefined') {
                      this._messageNotifyBeforeNextMyAppointmentAgree = responseError.error_fields.notify_next_appts_enabled;
                      this.beforeNextError = false;
                  } else if (typeof responseError.error_fields.notify_next_appts_days !== 'undefined') {
                      this._messageNotifyBeforeNextMyAppointmentAgree = responseError.error_fields.notify_next_appts_days;
                      this.beforeNextError = true;
                  }
              } else if (responseError.error_code === 'RC032' || responseError.error_code === 'RC080') {
                  _self._alertService.onClose = () => {
                      location.reload();
                  };
                  _self._alertService.showDismissButton = false;
                  _self._alertService.dismissButtonLabel = 'Close';
                  _self._alertService.showCloseButton = true;
                  _self._alertService.closeButtonLabel = 'OK';
                  _self._alertService.title = responseError.error_messages;;
                  _self._alertService.emitter('open');
              } else if (responseError.error_code === 'RC004' || responseError.error_code === 'RC025'
                  || responseError.error_code === 'RC016' || responseError.error_code === 'RC072') {
                  this._messageResponseError = responseError.error_messages;
              }
          }
          );
  }

  formatDataNotificationSetting(data: any) {
      var dataNotification: any = {};
      dataNotification.methods = {};
      dataNotification.notify_cancels = {};
      dataNotification.notify_falls = {};
      dataNotification.notify_next_appts = {};
      if (data.notifyMethod.indexOf('SMS') !== -1) {
          dataNotification.methods.sms = true;
      } else {
          dataNotification.methods.sms = false;
      }
      if (data.notifyMethod.indexOf('EMAIL') !== -1) {
          dataNotification.methods.email = true;
      } else {
          dataNotification.methods.email = false;
      }
      if (this.notificationMethods[1].disabled) {
          dataNotification.phone_mobile_verified = false;
      } else {
          dataNotification.phone_mobile_verified = true;
      }

      dataNotification.notify_cancels.enabled = data.notifyCancelDurationAgree;
      dataNotification.notify_cancels.start = moment(data.notifyCancelDurationStartDate, 'MMMM DD, YYYY').format('YYYY-MM-DD');
      dataNotification.notify_cancels.end = moment(data.notifyCancelDurationEndDate, 'MMMM DD, YYYY').format('YYYY-MM-DD');
      dataNotification.notify_cancels.from = data.notifyCancelDurationStartTime;
      dataNotification.notify_cancels.to = data.notifyCancelDurationEndTime;
      dataNotification.notify_falls.enabled = data.notifyIfBusinessFallsAgree;
      dataNotification.notify_falls.before = data.notifyIfBusinessFallsBeforeTime;
      dataNotification.notify_falls.time = data.notifyIfBusinessFallsDurationTime;
      dataNotification.notify_next_appts.enabled = data.notifyBeforeNextMyAppointmentAgree;
      dataNotification.notify_next_appts.days = data.notifyBeforeNextMyAppointmentDays;
      return dataNotification;
  }

  setAllErrorIsFalse() {
      this._messageResponseError = false;
      this._messageResponseSuccess = false;
      this._messageNotifyCancelDurationAgree = false;
      this._messageNotifyIfBusinessFallsAgree = false;
      this._messageNotifyBeforeNextMyAppointmentAgree = false;
      this._messageNotifyMethod = false;
      this.startDateError = false;
      this.endDateError = false;
      this.startTimeError = false;
      this.endTimeError = false;
      this.beforeTimeError = false;
      this.businessFallError = false;
      this.beforeNextError = false;
  }

  onCheckboxChange(name: any, event: any) {
      if (event.target.checked) {
          if (name === 'cbCancelBetween') {
              this.disabledNotifyField = false;
          } else if (name === 'cbBeforeBusiness') {
              this.disabledBeforeBusiness = false;
          } else if (name === 'cbDayNextAppointment') {
              this.disabledDayNextAppointment = false;
          }
      } else {
          if (name === 'cbCancelBetween') {
              this.disabledNotifyField = true;
          } else if (name === 'cbBeforeBusiness') {
              this.disabledBeforeBusiness = true;
          } else if (name === 'cbDayNextAppointment') {
              this.disabledDayNextAppointment = true;
          }
      }
  }*/

}
