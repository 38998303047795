import { Directive, Input, Output, OnInit, AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
// import { CORE_DIRECTIVES, ControlGroup, Control } from 'angular2/common';

declare let jQuery: any;
@Directive({
  selector: '[autocompletedata]',
  exportAs: 'autocompletedata'
})
export class AutoCompleteDirective implements OnInit, AfterViewInit {

  @Input() source: any;
  @Input() minLength = 1;
  @Input() target: any;
  @Input() field: string;

  @Output() OnChange: EventEmitter<any> = new EventEmitter();
  @Output() OnClose: EventEmitter<any> = new EventEmitter();
  @Output() OnFocus: EventEmitter<any> = new EventEmitter();

  public element: any;

  constructor(
    public el: ElementRef
  ) {
    this.element = jQuery(this.el.nativeElement);
  }

  ngOnInit() {
    this.element.autocomplete({
      source: this.source,
      minLength: this.minLength,
      select: (e: any) => {
        this.target[this.field] = e.target.value;
      },
      change: (e: any) => {
        this.target[this.field] = e.target.value;
        this.OnChange.emit(e);
      },
      close: (e: any) => {
        this.target[this.field] = e.target.value;
        this.OnClose.emit(e);
      },
      focus: (e: any) => {
        this.target[this.field] = e.target.value;
        this.OnFocus.emit(e);
      }
    });
  }

  ngAfterViewInit() {
    this.element.bind('paste', (e: any) => {
      this.target[this.field] = e.target.value;
    });
  }

  setSource(source: any) {
    this.element.autocomplete({
      source: source,
    });
  }
}
