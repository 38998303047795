import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, Injectable, ElementRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {WaitingTimeService} from '../number-waittime/services/waiting-time.service';
import {AppointmentDetail} from '../model/appointment-detail';
import {RtnScheduleComponent} from '@app/shared/schedule';
import {SystemInterval, SystemData, SystemEvent, SystemService} from '../../shared/system';
import {Utils} from '@app/shared/utils';
import {RestService} from '@app/core/rest.service';

import * as moment from 'moment';
import {WaitTimeService} from '@app/wait-time/wait-time.service';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

declare let jQuery: any;

@Injectable({
  providedIn: 'root'
})
@Component({
  moduleId: module.id,
  selector: 'app-speedometer-waittime',
  templateUrl: './speedometer-waittime.component.html',
  styleUrls: ['./speedometer-waittime.component.scss']
})
export class SpeedometerWaittimeComponent implements OnInit, OnDestroy {


  waitTimeData: any;
  waitTimeMeterStyle: any;
  appointmentId: number;
  appointmentDetail: AppointmentDetail = new AppointmentDetail();
  fetchWaitTimeSubscriber: Subscription;
  isFirefox = false;
  // waitTimeData: any;
  onNewWaitTimeData: EventEmitter<any> = new EventEmitter<any>();
  waitTimeInterval: any;

  @Input() enabledDetail = false;
  @Output() showDetailAppointmentPopup: EventEmitter<any> = new EventEmitter();

  waitTimeIntervalId: any;
  startTrigger = false;

  constructor(
    private Service: WaitTimeService,
    public _systemData: SystemData,
    private _systemInterval: SystemInterval,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    public _utils: Utils
  ) {
    var self = this;
    this.waitTimeMeterStyle = new Object();    
    this.onNewWaitTimeData.subscribe((newData: any) => {
      if (self.appointmentId) {
        self.waitTimeData = newData;
        self.displayWaitTime(self.appointmentId);
      }
    });
  }

  ngOnInit() {
    /*this.fetchWaitTime();
    this.waitTimeIntervalId = setInterval(() => {
            this.fetchWaitTime();
    }, this._systemInterval.appointment_fetchWaitTimePatient.duration);*/
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (is_firefox) {
      this.isFirefox = true;
    }
  }

  ngOnDestroy() {
    clearInterval(this.waitTimeIntervalId);
    if (this.fetchWaitTimeSubscriber) {
      this.fetchWaitTimeSubscriber.unsubscribe();
    }
  }

  // Calculate the deg of wait time meter
  calWaitTimeMeterDeg(xdeg: number) {
    let deg = xdeg ? xdeg : 0;
    if (deg < 0) {
      deg = 0;
    } else if (deg > 120) {
      deg = 123;
    }
    const _rotate = 'rotate(' + (deg * 1.5) + 'deg)';
    this.waitTimeMeterStyle = {
      '-ms-transform': _rotate,
      '-webkit-transform': _rotate,
      'transform': _rotate
    };
  }

  showWaitTime() {
    var self = this;
    this.Service.fetchWaitTimeByAppointment().subscribe((success: any) => {
      if (success.error_code === 'RC000') {
        self.waitTimeData = success.appointments;
        self.onNewWaitTimeData.emit(self.waitTimeData);
        self.displayWaitTime(self.appointmentId);
      }
    });
  }

  displayWaitTime(id: number) {
    // console.log(id);
    let dataNotFound = true;
    const now = new Date();
    let hour = now.getHours().toString();
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    let minute = now.getMinutes().toString();
    if (minute.length === 1) {
      minute = '0' + minute;
    }
    const currentTime = hour + ':' + minute;
    if (!id) {
      id = this.appointmentDetail.id;
    }
    this.appointmentDetail.currentTime = currentTime;
    // console.log(currentTime);
    if (this.waitTimeData.length > 0) {
      for (let i = 0; i < this.waitTimeData.length; i++) {
        const data = this.waitTimeData[i];
        if (data.id === id) {
          this.calWaitTimeMeterDeg(data.time);
          if (data.time > 999) {
            data.time = 999;
          }
          this.appointmentDetail.waitingTime = data.time;
          this.appointmentDetail.is_active = data.is_active;
          this.appointmentDetail.notice = data.message;
          if (data.message.match('opt in')) {
            this.appointmentDetail.hasLink = true;
          }
          dataNotFound = false;
          break;
        }
      }
      // console.log('waiting time: ' + this.appointmentDetail.waitingTime);
    } else {
      this.calWaitTimeMeterDeg(0);
      this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
      this.appointmentDetail.waitingTime = null;
    }
    // console.log(dataNotFound);
    if (dataNotFound) {
      this.calWaitTimeMeterDeg(0);
      this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
      this.appointmentDetail.waitingTime = null;
    }
  }

  showDetailAppointmentPopupClicked() {
    if (this.appointmentDetail.hasLink) {
      this.showDetailAppointmentPopup.emit(null);
    }
  }

  startWaitTimeInterval() {
    this.stopWaitTimeInterval();
    var self = this;
    const getWaitTime = () => {
      this.Service.fetchWaitTimeByAppointment().subscribe((success: any) => {
        if (success.error_code === 'RC000') {
          self.waitTimeData = success.appointments;
          self.onNewWaitTimeData.emit(self.waitTimeData);
          jQuery('.updateWaittime').click();
          console.log('startInterval');
          console.log(self.appointmentId);
        }
      });
    };

    this.waitTimeInterval = setInterval(getWaitTime, this._systemInterval.appointment_fetchWaitTimePatient.duration);
    getWaitTime();
  }

  stopWaitTimeInterval() {
    if (this.waitTimeInterval) {
      clearInterval(this.waitTimeInterval);
      this.waitTimeInterval = null;
    }
  }

}
