<div class="edit-patient">
    <div class="padding-left-15">
        <h3 class="text-header-form-edit">
            <a class="no-focus" [routerLink]="['/admin', 'account']">
                <span class="fa fa-angle-left style-top-arrow"></span>
            </a>
            Edit Patient
        </h3>
    </div>
        <div class="row">
            <div class="col-12 col-lg-7 no-padding">
                <form method="post" #form="ngForm" (ngSubmit)="editPatientForm()" novalidate class="row padding-left-right-15">
                    <div class="card card-default">
                        <div class="card-header">
                            <h3 class="card-title text-title-form-edit">Patient Information</h3>
                        </div>
                        <div class="card-body row">
                            <div class="col-6 no-padding-left pl-3">
                                <label for="first_name" class="input-label-required">First Name</label>
                                <input type="text" class="form-control" id="first_name" [(ngModel)]="editPatient.first_name" name="first_name" [class.input-error]="error.first_name" (keyup)="onKeyUp('first_name')" maxlength="50">
                                <div class="message-error">{{ error.first_name }}</div>
                            </div>                    
                            <div class="col-6 no-padding-right">
                                <label for="last_name" class="input-label-required">Last Name</label>
                                <input type="text" class="form-control" id="last_name" [(ngModel)]="editPatient.last_name" name="last_name" [class.input-error]="error.last_name" (keyup)="onKeyUp('last_name')" maxlength="50">
                                <div class="message-error">{{ error.last_name }}</div>
                            </div>
                            <div class="col-6 no-padding-left pl-3">
                                <label for="email">Email Address</label>
                                <input type="text" class="form-control" id="email" [(ngModel)]="editPatient.email" name="email">
                                <div class="message-error">{{ error.email }}</div>
                            </div>
                            <div class="col-6 no-padding-right">
                                <label for="phone_mobile">Mobile Phone Number</label>
                                <div>
                                    <div class="col-md-12 no-padding-left-right">
                                        <input type="text" class="form-control" [(ngModel)]="editPatient.phone_mobile" name="phone_mobile" [class.input-error]="error.phone_mobile" (keyup)="onKeyUp('phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                                    </div>
                                    <div class="clear-both"></div>
                                </div>
                                <div class="message-error">{{ error.phone_mobile }}</div>
                            </div>
                            <div class="col-6 no-padding-left">
                                <label for="dob" class="input-label-required pl-3">Date of Birth</label>
                                <div class="col-12 col-md-12 px-0">
                                    <rtn-dob [formInput]="editPatient" [error]="error.dob" (removeError)="onKeyUp($event)" id="dob"></rtn-dob>
                                    <div class="message-error">{{ error.dob }}</div>
                                </div>
                            </div>
                            <div class="col-6 no-padding-right">
                                <label for="zip" class="input-label-required">ZIP Code</label>
                                <input type="text" class="form-control" [(ngModel)]="editPatient.zip" name="zip" [class.input-error]="error.zip" (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'" [target]="editPatient" [field]="'zip'" maxlength="10">
                                <div class="message-error">{{ error.zip }}</div>
                            </div>   
                            
                            <div class="col-12 col-md-12 no-padding-left-right pl-3">
                                <label for="insurance_id" class="input-label-required">Insurance Plan</label>
                                <select class="form-control" id="insurance_id" [(ngModel)]="editPatient.insurance_id" name="insurance_id" (change)="changeInsurance($event.target.value)" [class.input-error]="error.insurance_id" [disabled]="editPatient.verified_insurance">
                                    <option value="">Select Insurance</option>
                                    <option *ngFor="let plan of _systemData.insurances" id="plan.id" [value]="plan.id">{{ plan.name }}</option>
                                </select>
                                <div class="cursorPointer selecter">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                                <div class="message-error" *ngIf="error.insurance_id">{{ error.insurance_id }}</div>
                            </div>
                            <div class="col-12 col-md-12 no-padding-left-right padding-bottom-15 pl-3">
                                <i class="font-text notice-text">* If you cannot find your plan in the list, please contact RTN admin</i>
                            </div>
                            <div class="col-12 col-md-12 no-padding-left-right pl-3" id="insurance-number">
                                <label for="insurance_number" class="input-label-required">Insurance ID</label>
                                <input class="form-control" id="insurance_number" type="text" [(ngModel)]="editPatient.insurance_number" name="insurance_number" (keyup)="onKeyUp('insurance_number')" maxlength="50" [class.input-error]="error.insurance_number" [disabled]="editPatient.verified_insurance">
                                <div class="message-error">{{ error.insurance_number }}</div>
                            </div> 
                            <ng-container *ngIf="!editPatient.verified_insurance && editPatient.insurance_id != '-1'">
                            <div class="col-12 col-md-12 no-padding-left-right pl-3" id="response-status">
                                <label for="response_status" class="input-label-required">Verify Insurance</label>
                                <select class="form-control" id="response_status" [(ngModel)]="editPatient.response_status" name="response_status" [class.input-error]="error.response_status" (keyup)="onKeyUp('response_status')">
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                <div class="cursorPointer selecter">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                                <div class="message-error" *ngIf="error.response_status">{{ error.response_status }}</div>
                            </div>
                            </ng-container>
                            
                            <div class="col-6 no-padding-left pl-3">
                                <label for="contact_email">Contact Email Address</label>
                                <input type="text" class="form-control" id="contact_email" [(ngModel)]="editPatient.contact_email" name="contact_email">
                                <div class="message-error">{{ error.contact_email }}</div>
                            </div>

                            <div class="col-6 no-padding-right">
                                
                            </div>

                            <div class="col-12 padding-bottom-20 no-padding-left pl-3 pt-2">
                                <button type="submit" class="container btn btn-rtn btn-90 text-uppercase">Save</button>
                                <span class="padding-left-15 message-success">{{ successMessage }}</span>
                            </div>               
                        </div>                        
                        
                    </div>
                    </form>
                </div>
                <div class="col-12 col-lg-5 no-padding-right">
                    <form method="post" #pwdform="ngForm" (ngSubmit)="updatePasswordFromAdmin(pwdform.value)" class="row padding-left-right-15">
                        <div class="card card-default">
                            <div class="card-header">
                                <h3 class="card-title text-title-form-edit">Change Password</h3>
                            </div>
                            <div class="card-body row">
                                <div class="col-md-12 row no-padding mb-3">
                                    <span class="text-label-style input-label-required">Password</span>
                                    <input class="form-control textbox-edit-form" type="password" [(ngModel)]="password" name="password" ngControl="password" [class.input-error]="responseError1['password']" maxlength="50" (keyup)="onKeyUp('password')">
                                    <span class="message-error" *ngIf="responseError1['password']">{{responseError1['password']}}</span>
                                </div>
                                <div class="col-md-12 row no-padding mb-3">
                                    <span class="text-label-style input-label-required">Confirm Password</span>
                                    <input class="form-control textbox-edit-form" type="password" [(ngModel)]="password_confirmation" name="password_confirmation" ngControl="password_confirmation" [class.input-error]="responseError1['password_confirmation']" maxlength="50" (keyup)="onKeyUp('password_confirmation')">
                                    <span class="message-error" *ngIf="responseError1['password_confirmation']">{{responseError1['password_confirmation']}}</span>
                                </div>
                                <div class="col-md-12 row no-padding mb-3">
                                    <span class="text-label-style">
                                    <input type="checkbox" [(ngModel)]="notify_to" name="notify_to" ngControl="ngControl" value="true"  /> Send Password to mail</span>
                                </div>
                                <div class="col-12 padding-bottom-20 no-padding-left">
                                    <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Update</button>
                                    <span class="padding-left-15 message-success">{{ successMessage1 }}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
        </div>
        
  </div>
