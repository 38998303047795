import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
// import { ManageUserService } from '../services/manage-user.service';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
// import { RouteParams, ROUTER_DIRECTIVES, Router } from 'angular2/router';
import {Subscription} from 'rxjs';
import {Utils} from '@app/shared/utils';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {Item} from '@app/shared/models/multipleSelect';
import {AlertService} from '@app/shared/alert/alert.service';
import {DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent} from '@app/shared/datatable';
import {RestService} from '@app/core/rest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProvidersService} from '@app/providers/providers.service';

@Component({
  moduleId: module.id,
  // selector: 'app-mapping-provider',
  templateUrl: './mapping-provider.component.html',
  styleUrls: ['./mapping-provider.component.scss']
})
export class MappingProviderComponent implements OnInit, OnDestroy {
  userStatusSubscriber: Subscription;
  userInfoSubscriber: Subscription;
  mappingProviderSubscriber: Subscription;
  successMessage: string;
  accountList: any = new Object();
  statuses: any = [];
  status: any = [];
  page = 1;
  rowOfPage = 10;
  errorMessage: string;
  searchText: string;
  countData = 0;
  isSuperUser = false;
  idSelected: any;

  constructor(
    private _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _service: ProvidersService,
    private _alertService: AlertService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
  ) {
  }

  init() {
    this.accountList.data = new Array();
    this.accountList.numberOfPages = 0;
    this.accountList.rowsOnPage = 10;
    this.errorMessage = null;
  }


  ngOnInit() {
    if (this._utils.accessAsSuperUser()) {
      // if (this._utils.isSuperUser()) {
      this.isSuperUser = true;
      if (this._activatedRoute.snapshot.paramMap.get('id')) {
        this.idSelected = parseInt(this._activatedRoute.snapshot.paramMap.get('id'), 10);
        this.init();
        this.getStatus();
        this._systemService.getStates();
      } else {
        this._router.navigate(['/Provider', 'ManageUser']);
      }
      // } else {
      //     this.idSelected = localStorage.getItem('managing_user_id');
      //     this.init();
      //     this.getStatus();
      //     this._systemService.getStates();
      // }
    }
  }

  ngOnDestroy() {
    if (this.userStatusSubscriber) {
      this.userStatusSubscriber.unsubscribe();
    }
    if (this.userInfoSubscriber) {
      this.userInfoSubscriber.unsubscribe();
    }
    if (this.mappingProviderSubscriber) {
      this.mappingProviderSubscriber.unsubscribe();
    }
  }

  getStatus() {
    if (this.userStatusSubscriber) {
      this.userStatusSubscriber.unsubscribe();
    }
    this.userStatusSubscriber = this._service.userStatus().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          success.statuses.forEach((status: any) => {
            const i = new Item();
            i.oj = status;
            this.statuses.push(i);
          });
          this.getInfo(this.page);
        }
      });
  }

  onDataChange(e: any) {
    this.page = e['currentPage'];
    this.getInfo(this.page, this.searchText);
  }

  getInfo(page: number, search_text: string = null) {
    this.errorMessage = null;
    this._loadingIndicatorService.start();
    if (this.userInfoSubscriber) {
      this.userInfoSubscriber.unsubscribe();
    }
    const mappingId = this.idSelected;
    const practiceId = localStorage.getItem('user_practice');
    this.userInfoSubscriber = this._service.getProviderUserInfo(page, this.rowOfPage, search_text, 'PROVIDER',
      practiceId, null, mappingId).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          this.accountList.numberOfPages = success.last_page || 0;
          const accounts = success.users || [];
          accounts.map((user: any, i: any) => {
            const listSpecialtiesName = new Array();
            const specialties = user.specialties;
            for (let j = 0; j < specialties.length; j++) {
              listSpecialtiesName.push(specialties[j].name);
            }
            user.specialties = listSpecialtiesName.join(', ');
            if (user.phone_mobile) {
              if (user.phone_mobile.indexOf('+1 ') !== -1) {
                user.phone_mobile = user.phone_mobile.substr(user.phone_mobile.indexOf('+1 ') + 3);
              }
            }

            for (let k = 0; k < this.statuses.length; k++) {
              if (user.status === this.statuses[k].oj.id) {
                user.status_show = this.statuses[k].oj.name;
              }
            }
          });
          this.accountList.data = accounts;
          this.countData = success.total;
        } else if (success.error_code === 'RC077') {
          this.init();
          this.errorMessage = success.error_messages;
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      });
  }

  onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
    if (this.page === 1) {
      this.getInfo(this.page, this.searchText);
    } else {
      dataTable.setPage(1, 10);
    }
  }

  mappingProvider(providerId: any, e: any, dataTable: any) {
    this._loadingIndicatorService.start();
    if (this.mappingProviderSubscriber) {
      this.mappingProviderSubscriber.unsubscribe();
    }
    const practiceId = parseInt(localStorage.getItem('user_practice'), 10);
    this.mappingProviderSubscriber = this._service.mappingProvider({
      'providerId': providerId,
      'managingId': this.idSelected,
      'practiceId': practiceId
    }).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          dataTable.reload();
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      },
      () => {
        this._loadingIndicatorService.stop();
      });
  }

  unMappingProvider(providerId: any, e: any, dataTable: any) {
    this._loadingIndicatorService.start();
    if (this.mappingProviderSubscriber) {
      this.mappingProviderSubscriber.unsubscribe();
    }
    const practiceId = parseInt(localStorage.getItem('user_practice'), 10);
    this.mappingProviderSubscriber = this._service.unMappingProvider({
      'providerId': providerId,
      'managingId': this.idSelected,
      'practiceId': practiceId
    }).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          dataTable.reload();
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      },
      () => {
        this._loadingIndicatorService.stop();
      });
  }

  openAlertMessage(msg: string) {
    this._alertService.onClose = () => {
      // location.reload();
    };
    this._alertService.showDismissButton = false;
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

}
