import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'rtn-home-front-sign-up',
  templateUrl: './front-sign-up.component.html',
  styleUrls: ['../home.component.scss', './front-sign-up.component.scss']
})
export class FrontSignUpComponent implements OnInit {

  minHeight: number;
  constructor(private _router: Router) {
    // do nothing
  }

  ngOnInit() {
      this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }
}
