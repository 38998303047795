import { Component, OnInit, ViewChild} from '@angular/core';
import { QuickDownloadService } from '@app/quick-download/quick-download.service';
import { Utils } from '@app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Subscription } from 'rxjs';
import {SystemInterval, SystemEvent, SystemData, SystemService} from '@app/shared/system';
import * as moment from 'moment';
declare let jQuery: any;

@Component({
  selector: 'app-quick-download',
  templateUrl: './quick-download.component.html',
  styleUrls: ['./quick-download.component.scss']
})
export class QuickDownloadComponent implements OnInit {
  isFirefox = false;
  errorMessage: string = '';
  openslotId: any=''
  //searchOpeningSubscriber: Subscription;
  //searchOpeningsResult: any = [];
  
  constructor(private quickBookingService: QuickDownloadService, 
    private _router: Router,
    public _utils: Utils,
    //private _loadingIndicatorService: LoadingIndicatorService,
    //private _systemEvent: SystemEvent,
    //private _systemData: SystemData,
    ) {
  }

  ngOnInit() {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (is_firefox) {
      this.isFirefox = true;
    }

    const currentUrl = this._router.url;
    const splitArr = currentUrl.split("/");

    const code = splitArr[splitArr.length - 1];

    if (code == null || code == undefined) {
      this.errorMessage = "Invalid request";
    } else {
      this.openslotId = code;
    }

    if (this.openslotId != '') {
      this.getOpenslotDetails();
    } 
  }
  ngOnDestroy() {
        
  }
  
  getOpenslotDetails() {
    let _self = this;
      jQuery.fileDownload(_self.quickBookingService.getDocumentURL+'/'+_self.openslotId, {
        httpMethod: 'POST',
        data: {
          'id': _self.openslotId
        }
      }).done(function (sucessTxt:any) {
        // TODO
       // console.log("sucessTxt:" + sucessTxt);
      }).fail(function (errorTxt:any) {
          //
          //console.log("errorTxt:" + errorTxt);
          _self.errorMessage = 'This document is not available to download anymore.';
      });    
  }   
}
