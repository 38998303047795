import { AfterViewInit, Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { AdminRestService } from '@app/admin/admin.service';

import { AlertService } from '@app/shared/alert';

import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { dataSearchChart } from '@app/shared/models/dataSearchChart';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Utils } from '@app/shared/utils';

import { SystemData } from '@app/shared/system';

//import { Select2EventtypeDirective } from '@app/shared/form/select2-eventtype.directive';

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";

declare let jQuery: any;

@Component({
  selector: 'app-appointment-by-labslot',
  templateUrl: './appointment-by-labslot.component.html',
  styleUrls: ['./appointment-by-labslot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentByLabslotComponent implements OnInit, AfterViewInit, OnDestroy {

  currentDate: any = moment();
  maxDate: any;
  heightCanvas: number;
  minHeight: number;
  data_chart: any;

  geByProviderSubscriber: Subscription;
    
  widthChart: number;
  practiceId = '0';
  providerId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();

  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;


  public providersList: any = [];
  public practicesList: any = [];
  public isDataAvailable = true;

  public message = '';
  labelWt: any = 'Wait Time';
  labelPdu: any = 'Plan Duration';
  labelAdu: any = 'Actual Duration';

  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
  @ViewChild('inputDateFrom') inputPractice: ElementRef;
  @ViewChild('inputDateFrom') inputProvider: ElementRef;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;

  private getIdDatePicker: String = '#inputDateFrom';
  private getIdPractice: String = '#getPractice option:selected';
  private getErrorMes: String = ' .error_message';

  private chartdata:any = [];

  private chartWidth: number;
  private chartHeight: number;
  private chartMargin = { top: 20, right: 40, bottom: 20, left: 80 };
  private chartX: any;
  private chartY: any;
  private chartSvg: any;
  private chartG: any;

  public curpage : number = 1;
  public noofdata : number = 0;
  public noofpage : number = 0;
  public isSubmitted : boolean = false;
  public isPrintview : boolean = false;
  public noofpageArr:any = [];
  public resultMode:string = 'fullview';

  //@ViewChild('Select2EventtypeDirective') eventtypeSelector: Select2EventtypeDirective;
  //eventtypeList: any;
  //geteventtypeSubscriber: Subscription;

  constructor(
    private _alertService: AlertService,
    //private _patientService: PatientsService,
    private _adminService: AdminRestService,
    private _datalogsService: DataLogsService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    public _systemData: SystemData,

  ) {
    this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.type = 1;

    let width = 1400; //1366
    width = (window.innerWidth > width) ? (window.innerWidth - 550) : (window.innerWidth - 400);
    this.chartWidth = width - this.chartMargin.left - this.chartMargin.right;
    this.chartHeight= 400 - this.chartMargin.top - this.chartMargin.bottom;

    //console.log('window.innerWidth : ' + window.innerWidth);
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.ProviderSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));

    //this.eventtypeSelector.clearData();
    //this.eventtypeSelector.resetSelect2(jQuery('#select2Eventtype'));
    
    const _self = this;
    _self._loadingIndicatorService.start();
    _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe((success: any) => {
        _self.practicesList = success.practices;
        const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
        _self.getProviderByPractice(firstPractice);
        this._loadingIndicatorService.stop();
      });
  }  
  getProviderByPractice(practiceId: any) {
    this._loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.providersList = [];
          success.providers.forEach((item: any) => {
            item.full_name
              = item.first_name + ((item.middle_name == null)
                ? (' ')
                : (' ' + item.middle_name + ' ')) + item.last_name;
            this.providersList.push(item);
          });
        }
        this._loadingIndicatorService.stop();
      },
      (error: any) => {

        this._loadingIndicatorService.stop();
      }
    );
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
      this.maxDate = moment().subtract(2, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    } else {
      this.maxDate = moment().subtract(1, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(this.inputDateTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        this.dataSearchChart.dateTo = jQuery(this.inputDateTo.nativeElement).val();
      }
    });
    jQuery(this.clickDateTo.nativeElement).click(() => {
      jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });
    jQuery(this.inputDateTo.nativeElement).datepicker('setDate', this.dataSearchChart.dateTo);

    /*jQuery('#exportDataChart').on('click', function () {
      _self.exportdata();
    });*/
    jQuery('#showDataChart').on('click', function () {
      //_self.resetData();
      _self.updateData();
    });
  }

  onChangePractice() {
    this.removeMsgErr();
    const practice = this.PracticeSelector.getData();
    if (practice.length !== 0) {
      this.getProviderByPractice(practice);
      this.ProviderSelector.clearData();
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      if (this.providersList.length > 0) {
        this.ProviderSelector.updateData(this.providersList);
      }
    } else {
      this.ProviderSelector.clearData();
    }
  }
  
  exportdata() {
    this.verifyDataSearch();
    const _self = this;
    const startTime = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;
    const providerID = this.dataSearchChart.provider;
    //const type = this.dataSearchChart.type;
    //const event_reason_id = this.eventtypeSelector.getData();

    //console.log(this.validate());

    if (this.validate()) {
      const token = localStorage.getItem('user_token');
      jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
        httpMethod: 'POST',
        data: {
          'token': token,
          'practice': practiceID,
          'providerId': providerID,
          'startTime': startTime,
          'chartType': 2
        }
      }).done(function () {
        // TODO
      })
        .fail(function () {
          _self._alertService.onClose = () => {
            location.reload();
          };
        });
    }
  }

  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.PracticeSelector.inputError = true;
      this.PracticeSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.PracticeSelector.inputError = false;
      this.PracticeSelector.changeBorderColorPractice();
      return true;
    }
  }

  validateProvider(message: any) {
    if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
      this.errorResponse.provider = message;
      this.ProviderSelector.inputError = true;
      this.ProviderSelector.changeBorderColorProvider();
      return false;
    } else {
      this.removeMsgErr();
      return true;
    }
  }

  removeMsgErr() {
    this.errorResponse.provider = null;
    this.ProviderSelector.inputError = false;
    this.ProviderSelector.changeBorderColorProvider();
  }

  validate() {
    const validatePractice = this.validatePractice('This field is required');
    const validateProvider = this.validateProvider('This field is required');
    if (validatePractice && validateProvider) {
      return true;
    }
    return false;
  }

  updateData() {
    this.verifyDataSearch();
    const date = this.dataSearchChart.dateTo;
    const providerId = this.dataSearchChart.provider;
    const practice = this.dataSearchChart.practice;
    //const type = this.dataSearchChart.type;
    //const event_reason_id = this.eventtypeSelector.getData();

    if (this.validate()) {
      this.getAppoinmentLogByDay(date, providerId, practice);
    }
  }

  verifyDataSearch() {
    this.dataSearchChart.practice = this.PracticeSelector.getData();
    this.dataSearchChart.provider = this.ProviderSelector.getData();
    //this.dataSearchChart.type = jQuery('#typeChart option:selected').val();
  }
  /*changeType(){
    this.dataSearchChart.type = jQuery('#typeChart option:selected').val();
    
    if (this.dataSearchChart.type == 2) {
      this.eventtypeSelector.clearData();
      this.eventtypeSelector.resetSelect2(jQuery('#select2Eventtype'));
    }    
  }*/
  getAppoinmentLogByDay(date: any, providerId: any, practice: any) {
    const self = this;
    this.isSubmitted = true;
    this._loadingIndicatorService.start();
    this.chartdata = [];
    this._datalogsService.getlabappointmentLog(date, providerId, practice).subscribe(
      (success: any) => {
        
        this._loadingIndicatorService.stop();

        let tempdata:any = success.data_chart;

        if (tempdata.data !== undefined && tempdata.data.length > 0) {
          const length = tempdata.data.length;
          for (let index = 0; index < length; index++) {

            let datainfo:any = {};            
            datainfo.State = index +'#' + tempdata.data[index].slotName;

            let tempvalues:any = [];  
            tempvalues.push({
              "type" : "Wait Time(Lab)", 
              "value" : tempdata.data[index].wt_lab, 
              "significant" : false,
              "plancheckin" : tempdata.data[index].Pi + ' - ' + tempdata.data[index].Po,
              "actualcheckin" : tempdata.data[index].Ai + ' - ' + tempdata.data[index].Ao,
              "planduration" : tempdata.data[index].pdu,
              "actualduration" : tempdata.data[index].adu,
              "waittime" : tempdata.data[index].wt,
              "status" : tempdata.data[index].status,
              "event_allowed" : tempdata.data[index].eventAllowed,
              "event_name" : tempdata.data[index].eventName,
              "plancheckin_lab" : tempdata.data[index].Pi_lab + ' - ' + tempdata.data[index].Po_lab,
              "actualcheckin_lab" : tempdata.data[index].Ai_lab + ' - ' + tempdata.data[index].Ao_lab,
              "planduration_lab" : tempdata.data[index].pdu_lab,
              "actualduration_lab" : tempdata.data[index].adu_lab,
              "waittime_lab" : tempdata.data[index].wt_lab
            });
            
            tempvalues.push({
              "type" : "Plan Duration(Lab)", 
              "value" : tempdata.data[index].pdu_lab, 
              "significant" : false,
              "plancheckin" : tempdata.data[index].Pi + ' - ' + tempdata.data[index].Po,
              "actualcheckin" : tempdata.data[index].Ai + ' - ' + tempdata.data[index].Ao,
              "planduration" : tempdata.data[index].pdu,
              "actualduration" : tempdata.data[index].adu,
              "waittime" : tempdata.data[index].wt,
              "status" : tempdata.data[index].status,
              "event_allowed" : tempdata.data[index].eventAllowed,
              "event_name" : tempdata.data[index].eventName,
              "plancheckin_lab" : tempdata.data[index].Pi_lab + ' - ' + tempdata.data[index].Po_lab,
              "actualcheckin_lab" : tempdata.data[index].Ai_lab + ' - ' + tempdata.data[index].Ao_lab,
              "planduration_lab" : tempdata.data[index].pdu_lab,
              "actualduration_lab" : tempdata.data[index].adu_lab,
              "waittime_lab" : tempdata.data[index].wt_lab
            });

            tempvalues.push({
              "type" : "Actual Duration(Lab)", 
              "value" : tempdata.data[index].adu_lab, 
              "significant" : false,
              "plancheckin" : tempdata.data[index].Pi + ' - ' + tempdata.data[index].Po,
              "actualcheckin" : tempdata.data[index].Ai + ' - ' + tempdata.data[index].Ao,
              "planduration" : tempdata.data[index].pdu,
              "actualduration" : tempdata.data[index].adu,
              "waittime" : tempdata.data[index].wt,
              "status" : tempdata.data[index].status,
              "event_allowed" : tempdata.data[index].eventAllowed,
              "event_name" : tempdata.data[index].eventName,
              "plancheckin_lab" : tempdata.data[index].Pi_lab + ' - ' + tempdata.data[index].Po_lab,
              "actualcheckin_lab" : tempdata.data[index].Ai_lab + ' - ' + tempdata.data[index].Ao_lab,
              "planduration_lab" : tempdata.data[index].pdu_lab,
              "actualduration_lab" : tempdata.data[index].adu_lab,
              "waittime_lab" : tempdata.data[index].wt_lab
            });

            tempvalues.push({
              "type" : "Wait Time", 
              "value" : tempdata.data[index].wt, 
              "significant" : false,
              "plancheckin" : tempdata.data[index].Pi + ' - ' + tempdata.data[index].Po,
              "actualcheckin" : tempdata.data[index].Ai + ' - ' + tempdata.data[index].Ao,
              "planduration" : tempdata.data[index].pdu,
              "actualduration" : tempdata.data[index].adu,
              "waittime" : tempdata.data[index].wt,
              "status" : tempdata.data[index].status,
              "event_allowed" : tempdata.data[index].eventAllowed,
              "event_name" : tempdata.data[index].eventName,
              "plancheckin_lab" : tempdata.data[index].Pi_lab + ' - ' + tempdata.data[index].Po_lab,
              "actualcheckin_lab" : tempdata.data[index].Ai_lab + ' - ' + tempdata.data[index].Ao_lab,
              "planduration_lab" : tempdata.data[index].pdu_lab,
              "actualduration_lab" : tempdata.data[index].adu_lab,
              "waittime_lab" : tempdata.data[index].wt_lab
            });
            
            tempvalues.push({
              "type" : "Plan Duration", 
              "value" : tempdata.data[index].pdu, 
              "significant" : false,
              "plancheckin" : tempdata.data[index].Pi + ' - ' + tempdata.data[index].Po,
              "actualcheckin" : tempdata.data[index].Ai + ' - ' + tempdata.data[index].Ao,
              "planduration" : tempdata.data[index].pdu,
              "actualduration" : tempdata.data[index].adu,
              "waittime" : tempdata.data[index].wt,
              "status" : tempdata.data[index].status,
              "event_allowed" : tempdata.data[index].eventAllowed,
              "event_name" : tempdata.data[index].eventName,
              "plancheckin_lab" : tempdata.data[index].Pi_lab + ' - ' + tempdata.data[index].Po_lab,
              "actualcheckin_lab" : tempdata.data[index].Ai_lab + ' - ' + tempdata.data[index].Ao_lab,
              "planduration_lab" : tempdata.data[index].pdu_lab,
              "actualduration_lab" : tempdata.data[index].adu_lab,
              "waittime_lab" : tempdata.data[index].wt_lab
            });

            tempvalues.push({
              "type" : "Actual Duration", 
              "value" : tempdata.data[index].adu, 
              "significant" : false,
              "plancheckin" : tempdata.data[index].Pi + ' - ' + tempdata.data[index].Po,
              "actualcheckin" : tempdata.data[index].Ai + ' - ' + tempdata.data[index].Ao,
              "planduration" : tempdata.data[index].pdu,
              "actualduration" : tempdata.data[index].adu,
              "waittime" : tempdata.data[index].wt,
              "status" : tempdata.data[index].status,
              "event_allowed" : tempdata.data[index].eventAllowed,
              "event_name" : tempdata.data[index].eventName,
              "plancheckin_lab" : tempdata.data[index].Pi_lab + ' - ' + tempdata.data[index].Po_lab,
              "actualcheckin_lab" : tempdata.data[index].Ai_lab + ' - ' + tempdata.data[index].Ao_lab,
              "planduration_lab" : tempdata.data[index].pdu_lab,
              "actualduration_lab" : tempdata.data[index].adu_lab,
              "waittime_lab" : tempdata.data[index].wt_lab
            });

            datainfo.values = tempvalues;

            //datainfo.plan_checkin   = tempdata.data[index].Pi;
            //datainfo.plan_checkout  = tempdata.data[index].Po;
            //datainfo.actual_checkin = tempdata.data[index].Ai;
            //datainfo.actual_checkout= tempdata.data[index].Ao;
            datainfo.waitime_lab          = tempdata.data[index].wt_lab;
            datainfo.plan_duration_lab    = tempdata.data[index].pdu_lab;
            datainfo.actual_duration_lab  = tempdata.data[index].adu_lab;
            datainfo.waitime          = tempdata.data[index].wt;
            datainfo.plan_duration    = tempdata.data[index].pdu;
            datainfo.actual_duration  = tempdata.data[index].adu;

            this.chartdata.push(datainfo);
          }
        }
        this.noofdata = this.chartdata.length;
        this.curpage = 1; 
        
        this.drawMultiBarChart();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }
  
  printChart(){    
    window.print();

  }
  drawMultiBarChart(){

    d3.selectAll("svg > *").remove();
    d3.selectAll('#myTooltip').remove();

    if (this.noofdata > 0) {
      let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.State.length}));

      //let barWidth:any = maxLength * 10;
      let barWidth:any = 30 * 10;

      let numBars:any = Math.round(this.chartWidth / barWidth);

      this.noofpage = Math.ceil(this.noofdata / numBars);
      this.noofpageArr = Array(this.noofpage).fill(0).map((x,i)=>i);

      let that = this;
      setTimeout(function(){
      for (var i=0; i < that.noofpage; i++) {
        var curpos = i + 1;
        that.printBarChart('groupchartbody_' + i, curpos, numBars);
      }
      }, 1000);
    }   

  }
  printBarChart(eleId:any, curpage:number, numBars:number){   
    
    if (document.getElementById(eleId) != undefined && document.getElementById(eleId)!= null) {
      document.getElementById(eleId).innerHTML = "";
    }

    if (this.noofdata == 0) {
      return false;
    }
    
    let chartSvgP = d3.select("#" + eleId).append('svg');
		chartSvgP.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
		chartSvgP.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
		
		let chartGp = chartSvgP.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    let startpos  = (curpage - 1) *  numBars;
    //this.noofpage = Math.ceil(this.noofdata / numBars);
    let endpos    = (curpage * numBars);
    endpos = (endpos > this.noofdata) ? this.noofdata : endpos;

    let tempdata:any = this.chartdata.slice(startpos, endpos);

		var x0 = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).paddingInner(0.2);

		var x1 = d3Scale.scaleBand().padding(0.1);

		var y = d3Scale.scaleLinear().rangeRound([(this.chartHeight / 1.75), 0]);
            

		var z = d3Scale.scaleOrdinal<any>().range(["#ff4d4d", "#80c5e5", "#21c47e", "#ff0000", "#37A3D6", "#1ca268"]);
      
    var categoriesNames = tempdata.map(function(d:any) { return d.State; });
    var rateNames = tempdata[0].values.map(function(d:any) { return d.type; }); 

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);
      
    //y.domain([0, 60]);

    let waitime_lab         = d3Array.max(tempdata, (d:any) => d.waitime_lab);
    let planduration_lab    = d3Array.max(tempdata, (d:any) => d.plan_duration_lab);
    let actualduration_lab  = d3Array.max(tempdata, (d:any) => d.actual_duration_lab);

    let waitime         = d3Array.max(tempdata, (d:any) => d.waitime);
    let planduration    = d3Array.max(tempdata, (d:any) => d.plan_duration);
    let actualduration  = d3Array.max(tempdata, (d:any) => d.actual_duration);

    var tooltip = d3.select("body")
      .append("div")
      .attr('id', 'myTooltip')
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("");

    let maxlimit:number = Math.max(parseInt(waitime_lab), parseInt(planduration_lab), parseInt(actualduration_lab), parseInt(waitime), parseInt(planduration), parseInt(actualduration));
    
    //console.log(maxlimit);
    y.domain([0, maxlimit]);

    var legend = chartGp.append("g").attr("class", "legend");
    var cWidth = this.chartWidth;
    var cHeight = this.chartHeight;

    var legenG = legend.selectAll("g")
      .data(tempdata[0].values.map(function(d:any) { return d.type; }))
      .enter()
      .append("g")
      .attr("transform", function(d:any, i:any) { 
        return "translate(" + i * (cWidth / (rateNames.length + 1)) + ", 0)"; 
    });

  legenG.append("rect")
        .attr("x", 0)
        .attr("y", cHeight - 40)
        .attr("width", 18)
        .attr("height", 18)
        .attr("fill", z);

  legenG.append("text")
        .attr("x", 24)
        .attr("y", cHeight - 30)
        .attr("dy", "0.32em")
        .text(function(d:any) { return d; }); 

  chartGp.append("g")			
        .attr("class", "grid")        
        .attr("transform", "translate(0," + (cHeight / 7) + ")")
        .call(d3Axis.axisLeft(y)
        .tickSize(-cWidth)          
        .ticks(6)
        .tickFormat(null));

    var barG = chartGp.append("g")
        .selectAll("g")
        .data(tempdata)
        .enter()
        .append("g")
        .attr("transform", function(d:any) { return "translate(" + x0(d.State) + ",0)"; });
    
      barG.selectAll(".bars-container-middle")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", 'bars-container-middle')	
        .attr("transform", "translate(0," + (cHeight / 7) + ")")      
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("y", function(d:any) { return 0; })
        .attr("width", x1.bandwidth())        
        .attr('height', function(d:any) { 
          return (d.value > 0) ? (cHeight / 1.75) : 0;        
        });

      let that = this;  
    
      barG.selectAll(".bars")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")        
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("width", x1.bandwidth())
        .attr("fill", function(d:any) { return z(d.type); })
        .attr("y", (cHeight / 2))
        .attr("y", function(d:any) { return y(d.value); })
        .attr('height', function(d:any) { 
          return (d.value > 0) ? ((cHeight / 1.75))  - y( d.value ) : 0;
        }).on("mouseover", function(d:any){
          var newHtml = '';

          if (d['status'] != 3 && d['status'] != 4 && d['eventAllowed'] != 0 && d['planduration_lab'] != 0) {
            newHtml += '<p><span class="lbltext">Lab</span></p>';
            newHtml += '<p><span class="lbltext">Plan </span><span>: ' + d['plancheckin_lab'] + '</span></p>';
            newHtml += '<p><span class="lbltext">Actual </span><span>: ' + d['actualcheckin_lab'] + '</span></p>';
            newHtml += '<p><span class="lbltext pdu">Plan Duration </span><span>: ' + d['planduration_lab'] + '</span></p>';
            newHtml += '<p><span class="lbltext adu">Actual Duration </span><span>: ' + d['actualduration_lab'] + '</span></p>';
            newHtml += '<p><span class="lbltext wt">Waittime </span><span>: ' + d['waittime_lab'] + '</span></p>';
          }

          newHtml += '<p><strong>' + d['event_name'] + '</strong></p>';
          newHtml += '<p><span class="lbltext">Plan </span><span>: ' + d['plancheckin'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Actual </span><span>: ' + d['actualcheckin'] + '</span></p>';
          newHtml += '<p><span class="lbltext pdu">Plan Duration </span><span>: ' + d['planduration'] + '</span></p>';
          newHtml += '<p><span class="lbltext adu">Actual Duration </span><span>: ' + d['actualduration'] + '</span></p>';
          newHtml += '<p><span class="lbltext wt">Waittime </span><span>: ' + d['waittime'] + '</span></p>';
          

          if (d['status'] == 3) {
            newHtml += '<p><span class="lbltext wt">Status </span><span>: Cancel</span></p>';
          }
          if (d['status'] == 4) {
            newHtml += '<p><span class="lbltext wt">Status </span><span>: No Show</span></p>';
          }


          tooltip.html(newHtml);
          return tooltip.style("visibility", "visible");
         })
         .on("mousemove", function(){
            var pageX = d3['event']['pageX'];
            var pageY = d3['event']['pageY'];

            if (cWidth - (pageX + 200) < 20){ 
              pageX = pageX - 200 - 20;
            }
            
            if (cHeight - (pageY + 200) < 20){ 
              pageY = pageY - 200 - 20;
            }

            return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
        })
        .on("mouseout", function(){           
          return tooltip.style("visibility", "hidden");
        });
    
        chartGp.append("g")
          .attr("class", "x-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 1.4) + ")")
          .call(d3Axis.axisBottom(x0))
          .selectAll("text")	
          .style("text-anchor", "middle")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr('y', '15')
          .text(function (d:any) {
            let xtext = d.split('#');
            return xtext[1];
      });
    
      chartGp.append("g")
          .attr("class", "y-axis axis")          
         .attr("transform", "translate(0," + (cHeight / 7) + ")")
          .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return d + " min"; }));

  }
}