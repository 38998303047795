<div class="book-appointment">
  <div class="book-appointment-page">
    <div class="content-page">
      <div class="text-header-title">APPOINTMENT</div>
      <div class="text-name">
        <span>{{ providerInfo.fullName }}</span><span *ngIf="providerInfo.credentials">,</span>
        <span>{{ providerInfo.credentials }}</span>
      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-3 col-md-3 col-sm-4 pl-0">Specialty</div>
          <div class="col-9 text-value col-md-9 col-sm-8 pl-0">{{ providerInfo.specialties }}</div>
        </div>

      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-3 col-md-3 col-sm-4 pl-0">Date</div>
          <div
            class="col-9 text-value col-md-9 col-sm-8 pl-0">{{ _utils.getDateTimeFormat(_systemData.selectOpenSlotData.start_time) }}</div>
        </div>

      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-3 col-md-3 col-sm-4 pl-0">Address</div>
          <div class="col-9 text-value col-md-9 col-sm-8 pl-0">
            <div class="col-md-12 col-sm-12 px-0">
              {{ providerInfo.address }}
            </div>
            <div class="col-md-12 col-sm-12 px-0">
              {{ providerInfo.address2 }}
            </div>
            <div class="col-md-12 col-sm-12 px-0">
              <span>{{ providerInfo.city }}</span><span *ngIf="providerInfo.state_code !== null">,</span>
              <span>{{ providerInfo.state_code }}</span>
              <span>{{ providerInfo.zip }}</span>
            </div>
          </div>
        </div>

      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-3 col-md-3 col-sm-4 pl-0">Patient</div>
          <div
            class="col-9 text-value col-md-9 col-sm-8 pl-0">{{ patientInfo.first_name + ' ' + patientInfo.last_name }}</div>
        </div>

      </div>
      <form method="post" #form="ngForm" (ngSubmit)="submitBookAppointment(bookAppointment)">
        <div class="text_info">
          <div class="row w-100">
            <div class="col-1 input-rtn pull-left mx-0">
              <input class="checkbox check-single" type="checkbox" name="settingReminder" id="settingReminder"
                     [value]="bookAppointment.is_reminder"
                     [(ngModel)]="bookAppointment.is_reminder" #settingReminder>
              <label class="selectorExtreme margin-top-8" for="settingReminder"></label>
            </div>
            <div class="col-11 padding-top-3 mr-0 px-0" style="margin-left: -20px;">
              Yes, send me a reminder before my appointment (when enabled by Provider)
            </div>
          </div>
        </div>
        <div class="input-group select select-time-style">
          <select class="form-control" [value]="bookAppointment.reminder_day" [(ngModel)]="bookAppointment.reminder_day"
                  [disabled]="bookAppointment.is_reminder" #selectTime name="select-reminder">
            <option *ngFor="let time of timeSetting" [value]="time.value">{{time.name}}</option>
          </select>
          <div class="input-group-select-icon">
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div class="text_info">
          <div class=" col-sm-2 col-md-1 col-lg-1 px-0 input-rtn pull-left margin-left-0">
            <input class="checkbox check-single" type="checkbox" name="settingWaitTime" id="settingWaitTime"
                   [value]="bookAppointment.add_followed"
                   [(ngModel)]="bookAppointment.add_followed">
            <label class="selectorExtreme margin-top-8" for="settingWaitTime"></label>
          </div>
          <div class="col-sm-9 col-md-10 col-lg-10 px-0 padding-top-3" style="margin-left: -20px;">Add this Provider to
            my Followed Provider list
          </div>
        </div>
        <button class="btn btn-rtn btn-180 text-uppercase margin-top-20" type="submit">BOOk APPOINTMENT</button>
      </form>
    </div>
  </div>
</div>
