import { AfterViewInit, OnInit, Component, OnDestroy, ViewChild, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
import { AppointmentDetail } from '@app/shared/models/appointment-detail';
import { Utils } from '@app/shared/utils';
import { RescheduleComponent } from '@app/appointments/modals/reschedule/reschedule.component';
import { Router } from '@angular/router';
import { SpeedometerWaittimeComponent } from '@app/wait-time/speedometer-waittime/speedometer-waittime.component';
import { SimpleModalsService } from '@app/shared/modals/simple-modals/simple-modals.service';
import { SystemData, SystemEvent, SystemInterval, SystemService } from '@app/shared/system';
import { LoadingIndicatorService } from '@app/shared/loading-indicator/loading-indicator.service';
import { MobileNumberComponent } from '@app/shared/mobile-number/mobile-number.component';
import { Subscription } from 'rxjs';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { WaitlistFromAppointmentComponent } from '@app/appointments/modals/waitlist-from-appointment/waitlist-from-appointment.component';

declare let jQuery: any;
declare let moment: any;

@Component({
    moduleId: module.id,
    selector: 'rtn-modals-appointment-detail',
    templateUrl: './appointment-detail.component.html',
    styleUrls: ['./appointment-detail.component.scss'],
    providers: [SpeedometerWaittimeComponent], encapsulation: ViewEncapsulation.None
})
export class AppointmentDetailComponent implements OnInit, AfterViewInit, OnDestroy {

    appointmentDetail: AppointmentDetail = new AppointmentDetail();
    // declare arrayActiveModule
    arrayModule = new Array();
    isAlert: boolean;
    isReminder: boolean;
    reminderDays: number;
    isEmail: boolean;
    emailAddress: string;
    isSMS: boolean;
    MobileNumber: string;
    mobile: any = {};
    errorMessage: string;
    errorEmailMessage: string;
    errorMobileMessage: string;
    messageResponseSuccess: string;
    isCancel = false;
    setWaitTimeInterval: any;
    isVerified = false;
    showVerified = false;
    classIcon = '.more-less.fas.';
    AltMobileNumber: string;
    altmobile: any = {};
    errorAltMobileMessage: string;
    showAltVerified = false;
    isAltVerified = false;

    getAppointmentNotificationSubscriber: Subscription;
    setAppointmentNotificationSubscriber: Subscription;
    cancelAppointmentSubscriber: Subscription;
    checkIsCancelSubscriber: Subscription;

    @ViewChild(RescheduleComponent) rescheduleDirective: RescheduleComponent;
    @ViewChild('AppointmentDetail_Modals') AppointmentDetail_Modals: ModalComponent;
    @ViewChild(SpeedometerWaittimeComponent) waitingTimeDirective: SpeedometerWaittimeComponent;
    @ViewChild(MobileNumberComponent) mobileNumberDirective: MobileNumberComponent;    
    @ViewChild(WaitlistFromAppointmentComponent) waitlistFromAppointmentComponent: WaitlistFromAppointmentComponent;    
    @Output() callEventWaitlistFrombookEmitter = new EventEmitter();

    errorCancelMessage: string;
    // ========== General Modal Method ========== //
    public tenantname:string = '';
    allow_to_addwaitlist:boolean = false;
    constructor(
        private _utils: Utils,
        private Service: AppointmentsService,
        public waitingTimeService: SpeedometerWaittimeComponent,
        private _cancelModals: SimpleModalsService,
        private _infoModals: SimpleModalsService,
        private _cancelledModals: SimpleModalsService,
        private _systemData: SystemData,
        private _systemInterval: SystemInterval,
        private router: Router,
        private loadingIndicatorService: LoadingIndicatorService,
        private _systemEvent: SystemEvent,
        private _systemServices: SystemService
    ) {
        // do nothing
        // get active module array


    }

    ngOnInit() {
        this.arrayModule = this._systemServices.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));

        if (!this.arrayModule[1] || !this.arrayModule[2]) {
            jQuery(".appointment-detail").find('.modal-content').css({ 'background': 'transparent' }).css({ 'box-shadow': 'none' });
        }

        this.getSubDomain();
    }
    ngAfterViewInit() {
        const _self = this;
        jQuery('#alertNotif').change(function () {
            if (this.checked) {
                _self.isAlert = true;
            } else {
                _self.isAlert = false;
            }
        });
        jQuery('#reminderNotif').change(function () {
            if (this.checked) {
                _self.isReminder = true;
            } else {
                _self.isReminder = false;
            }
            jQuery('#remind').prop('disabled', !_self.isReminder);
        });
        jQuery('#emailNotif').change(function () {
            if (this.checked) {
                _self.isEmail = true;
            } else {
                _self.isEmail = false;
            }
            jQuery('#email-notif').prop('disabled', !_self.isEmail);
        });
        jQuery('#SMSNotif').change(function () {
            if (this.checked) {
                _self.isSMS = true;
            } else {
                _self.isSMS = false;
            }
        });
        jQuery('#remind').prop('disabled', !_self.isReminder);
        jQuery('.email-notif').prop('disabled', !_self.isEmail);
    }

    ngOnDestroy() {
        if (this.getAppointmentNotificationSubscriber) {
            this.getAppointmentNotificationSubscriber.unsubscribe();
        }
        if (this.setAppointmentNotificationSubscriber) {
            this.setAppointmentNotificationSubscriber.unsubscribe();
        }
        if (this.cancelAppointmentSubscriber) {
            this.cancelAppointmentSubscriber.unsubscribe();
        }
        if (this.checkIsCancelSubscriber) {
            this.checkIsCancelSubscriber.unsubscribe();
        }
        clearInterval(this.setWaitTimeInterval);
    }

    setValue() {
        this.mobile = {};
        this.altmobile = {};

        if (this.getAppointmentNotificationSubscriber) {
            this.getAppointmentNotificationSubscriber.unsubscribe();
        }
        this.errorMobileMessage='';
        this.getAppointmentNotificationSubscriber = this.Service
            .getAppointmentNotification(this.waitingTimeDirective.appointmentDetail.id).subscribe(
                (success: any) => {
                    this.isAlert = (success.schedule.alert_waittime === 1);
                    this.isReminder = (success.schedule.is_reminder === 1);
                    this.isEmail = (success.schedule.is_email === 1);
                    this.isSMS = (success.schedule.is_sms === 1);
                    this.showVerified = !!(success.schedule.mobile_number);
                    this.isVerified = success.schedule.isVerified;
                    this.reminderDays = success.schedule.reminder_day === 0 ? 1 : success.schedule.reminder_day;
                    if (success.schedule.email !== '') {
                        this.emailAddress = success.schedule.email;
                    } else {
                        this.emailAddress = this._systemData.localStorage.loginEmail;
                    }
                    jQuery('#alertNotif').prop('checked', this.isAlert);
                    jQuery('#reminderNotif').prop('checked', this.isReminder);
                    jQuery('#emailNotif').prop('checked', this.isEmail);

                    jQuery('#remind').val(this.reminderDays);
                    // jQuery('#remind').prop('disabled', false);

                    // if (this.isEmail) {
                    //     jQuery('#email-notif').prop('disabled', false);
                    // }
                    jQuery('.email-notif').val(this.emailAddress);

                    if (success.schedule.mobile_number !== null) {

                        const num = success.schedule.mobile_number.replace(/[^0-9]/g, '');
                        const firstMobileNumber = num.substr(0, 3);
                        const secondMobileNumber = num.substr(3, 3);
                        const thirdMobileNumber = num.substr(6, 4);
                        this.mobile.first = '(' + firstMobileNumber + ')';
                        this.mobile.middle = secondMobileNumber;
                        this.mobile.last = thirdMobileNumber;
                    }

                    if (success.schedule.mobile_number === '' || success.schedule.mobile_number === null) {
                        success.schedule.is_sms = 0;
                        this.isSMS = false;
                    } else {
                        success.schedule.is_sms = 1;
                        this.isSMS = true;
                    }
                    //alt number
                    this.showAltVerified = !!(success.schedule.alt_mobile_number);
                    this.isAltVerified = success.schedule.isAltVerified;

                    if (success.schedule.alt_mobile_number !== null) {

                        const altnum = success.schedule.alt_mobile_number.replace(/[^0-9]/g, '');
                        const altfirstMobileNumber = altnum.substr(0, 3);
                        const altsecondMobileNumber = altnum.substr(3, 3);
                        const altthirdMobileNumber = altnum.substr(6, 4);
                        this.altmobile.first = '(' + altfirstMobileNumber + ')';
                        this.altmobile.middle = altsecondMobileNumber;
                        this.altmobile.last = altthirdMobileNumber;
                    }

                    if (success.schedule.alt_mobile_number === '' || success.schedule.alt_mobile_number === null) {
                    } else {
                        success.schedule.is_sms = 1;
                        this.isSMS = true;
                    }

                    jQuery('#SMSNotif').prop('checked', this.isSMS);

                    this._systemData.appointmentId = this.waitingTimeDirective.appointmentDetail.id;
                    this._systemEvent.book.emit('book_callGetWaitlistinAppointment');
                    this.allow_to_addwaitlist = success.schedule.allow_to_addwaitlist;
                }
            );
        this.checkIsCancel();
        // this.isAlert = jQuery('#alertNotif').is(':checked');
        // this.isReminder = jQuery('#reminderNotif').is(':checked');
        // this.isEmail = jQuery('#emailNotif').is(':checked');
        // this.isSMS = jQuery('#SMSNotif').is(':checked');
        // jQuery('#remind').prop('disabled', !this.isReminder);
        // jQuery('#email-notif').prop('disabled', !this.isEmail);
    }

    saveNotificationSetting() {
        this.loadingIndicatorService.start();
        this.reminderDays = parseInt(jQuery('#remind').val(), 10);
        this.emailAddress = jQuery('#email-notif-lg').val();
        if (this.mobile.first === '' && this.mobile.middle === '' && this.mobile.last === '') {
            this.MobileNumber = '';
        } else {
            this.MobileNumber = this.mobile.first + ' ' + this.mobile.middle + '-' + this.mobile.last;
            const numberOnly = this.MobileNumber.replace(/[^0-9]/g, '');
            this.MobileNumber = numberOnly ? this.MobileNumber : null;
        }
        this.isAlert = jQuery('#alertNotif').is(':checked');
        this.isReminder = jQuery('#reminderNotif').is(':checked');
        this.isEmail = jQuery('#emailNotif').is(':checked');
        this.isSMS = jQuery('#SMSNotif').is(':checked');
        if (this.setAppointmentNotificationSubscriber) {
            this.setAppointmentNotificationSubscriber.unsubscribe();
        }

        if (this.altmobile.first === '' && this.altmobile.middle === '' && this.altmobile.last === '') {
            this.AltMobileNumber = '';
        } else {
            this.AltMobileNumber = this.altmobile.first + ' ' + this.altmobile.middle + '-' + this.altmobile.last;
            const altnumberOnly = this.AltMobileNumber.replace(/[^0-9]/g, '');
            this.AltMobileNumber = altnumberOnly ? this.AltMobileNumber : null;
        }
        this.setAppointmentNotificationSubscriber = this.Service.setAppointmentNotification(
            this.waitingTimeDirective.appointmentDetail.id,
            {
                alert_waittime: this.isAlert ? 1 : 0,
                is_reminder: this.isReminder ? 1 : 0,
                reminder_day: this.reminderDays,
                is_email: this.isEmail ? 1 : 0,
                email: this.emailAddress,
                is_sms: this.isSMS ? 1 : 0,
                mobile_number: this.MobileNumber,
                alt_mobile_number: this.AltMobileNumber
            }).subscribe(
                (success: any) => {
                    this.loadingIndicatorService.stop();
                    this.messageResponseSuccess = 'Update successfully!';
                    this.errorMessage = '';
                    this.errorMobileMessage = '';
                    this.errorEmailMessage = '';
                    this.showVerified = !!(success.mobile_number);
                    this.isVerified = success.isVerified;

                    this.errorAltMobileMessage = '';
                    
                    this.showAltVerified = !!(success.alt_mobile_number);
                    this.isAltVerified = success.isAltVerified;
                },
                (error: any) => {
                    this.loadingIndicatorService.stop();
                    this.messageResponseSuccess = '';
                    const e = error.error;
                    if (e.error_fields) {
                        this.errorMobileMessage = e.error_fields.mobile_number;
                        if (e.error_fields.mobile_number) {
                            this.isVerified = false;
                        }
                        this.errorEmailMessage = e.error_fields.email;

                        this.errorAltMobileMessage = e.error_fields.alt_mobile_number;
                        if (e.error_fields.alt_mobile_number) {
                            this.isAltVerified = false;
                        }
                    }
                    this.errorMessage = e.error_messages;
                }
            );
    }

    cancelAppointment() {
        this.loadingIndicatorService.start();
        if (this.cancelAppointmentSubscriber) {
            this.cancelAppointmentSubscriber.unsubscribe();
        }
        this.cancelAppointmentSubscriber = this.Service
            .patientCancelAnAppointment(this.waitingTimeDirective.appointmentDetail.id).subscribe(
                (success: any) => {
                    //console.log(success);

                    if (success.error_code === 'RC000') {
                        this.loadingIndicatorService.stop();
                        
                        
                        this._infoModals.onClose = () => {
                            this.AppointmentDetail_Modals.close();
                            this._systemEvent.event.emit('event_callSearchAppointment');
                        };
                        this._infoModals.showDismissButton = false;
                        this._infoModals.showFnButton = false;
                        this._infoModals.showCloseButton = true;
                        this._infoModals.closeButtonLabel = 'OK';
                        this._infoModals.backdrop = 'false';
                        this._infoModals.title = 'Appointment is cancelled successfully!';
                        this._infoModals.emitter('open');
                    } else {
                        this.loadingIndicatorService.stop();

                        this._infoModals.onClose = () => {
                            this._infoModals.emitter('dismiss');
                        };

                        this._infoModals.showDismissButton = false;
                        this._infoModals.showFnButton = false;
                        this._infoModals.showCloseButton = true;
                        this._infoModals.closeButtonLabel = 'OK';
                        this._infoModals.backdrop = 'false';
                        this._infoModals.title = success.error_messages;
                        this._infoModals.emitter('open');
                        //this.checkIsCancel();
                    }
                },
                (error: any) => {
                    this.loadingIndicatorService.stop();
                    const e = error.error;
                    this._cancelledModals.onClose = () => {
                        this.AppointmentDetail_Modals.close();
                        this._systemEvent.event.emit('event_callSearchAppointment');
                    };
                    this._cancelledModals.showDismissButton = false;
                    this._cancelledModals.showFnButton = false;
                    this._cancelledModals.showCloseButton = true;
                    this._cancelledModals.closeButtonLabel = 'OK';
                    this._cancelledModals.backdrop = 'false';
                    this._cancelledModals.title = e.error_messages;
                    this._cancelledModals.emitter('open');
                }, () => {
                    // jQuery(document).ready(function () {
                    //   if (jQuery('.simple-modal').eq(1) !== undefined) {
                    //     jQuery('.simple-modal').eq(1).remove();
                    //   }
                    // });
                }
            );
    }

    open(appointment: any) {
        this.waitingTimeService.startWaitTimeInterval();
        this.messageResponseSuccess = '';
        this.errorMessage = '';
        this.errorCancelMessage = '';
        this.AppointmentDetail_Modals.open('lg');
        this.appointmentDetail = appointment;
        this.waitingTimeDirective.appointmentDetail = appointment;
        this.waitingTimeDirective.appointmentId = appointment.id;
        this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = true;
        this.setValue();
    }

    closeModals() {
        clearInterval(this.setWaitTimeInterval);
    }

    close() {
        clearInterval(this.setWaitTimeInterval);
        this.AppointmentDetail_Modals.close();
    }

    // alertToggle() {
    //   jQuery('#collapseAlert').collapse("toggle");
    //   jQuery('#collapseAlert').on('shown.bs.collapse', function () {
    //     jQuery('.more-less.fas.al').addClass('fa-minus');
    //     jQuery('.more-less.fas.al').removeClass('fa-plus');
    //   });
    //   jQuery('#collapseAlert').on('hidden.bs.collapse', function () {
    //     jQuery('.more-less.fas.al').addClass('fa-plus');
    //     jQuery('.more-less.fas.al').removeClass('fa-minus');
    //   });
    // }

    // reminderToggle() {
    //   jQuery('#collapseReminder').collapse("toggle");
    //   jQuery('#collapseReminder').on('shown.bs.collapse', function () {
    //     jQuery('.more-less.fas.reminder').addClass('fa-minus');
    //     jQuery('.more-less.fas.reminder').removeClass('fa-plus');
    //   });
    //   jQuery('#collapseReminder').on('hidden.bs.collapse', function () {
    //     jQuery('.more-less.fas.reminder').addClass('fa-plus');
    //     jQuery('.more-less.fas.reminder').removeClass('fa-minus');
    //   });
    // }

    toggleEvent(id: any) {
        const self = this;
        jQuery('#' + id).collapse("toggle");
        jQuery('#' + id).on('shown.bs.collapse', function () {
            jQuery(self.classIcon + id).addClass('fa-minus');
            jQuery(self.classIcon + id).removeClass('fa-plus');
        });
        jQuery('#' + id).on('hidden.bs.collapse', function () {
            jQuery(self.classIcon + id).addClass('fa-plus');
            jQuery(self.classIcon + id).removeClass('fa-minus');
        });
    }

    cancel() {
        /*this._cancelModals.onClose = () => {
            this.cancelAppointment();
        };
        this._cancelModals.onFunction = () => {
            this.rescheduleDirective.open(this.appointmentDetail);
            this.AppointmentDetail_Modals.close();
        };
        this._cancelModals.showDismissButton = true;
        this._cancelModals.showCloseButton = true;
        this._cancelModals.showFnButton = true;
        this._cancelModals.closeButtonLabel = 'Yes';
        this._cancelModals.dismissButtonLabel = 'No';
        this._cancelModals.fnButtonLabel = 'Reschedule';
        this._cancelModals.backdrop = 'false';
        this._cancelModals.title = 'Are you sure you want to cancel this appointment?';
        this._cancelModals.emitter('open');*/

        this.errorCancelMessage = '';


        if (this.checkIsCancelSubscriber) {
            this.checkIsCancelSubscriber.unsubscribe();
        }
        this.checkIsCancelSubscriber = this.Service.patientCheckIsCancel(this.waitingTimeDirective.appointmentDetail.id)
            .subscribe((success: any) => {
                this.isCancel = success.is_cancel;

                if (this.isCancel) {
                    this._cancelModals.onClose = () => {
                        this.cancelAppointment();
                    };
                    this._cancelModals.onFunction = () => {
                        this.rescheduleDirective.open(this.appointmentDetail);
                        this.AppointmentDetail_Modals.close();
                    };
                    this._cancelModals.showDismissButton = true;
                    this._cancelModals.showCloseButton = true;
                    this._cancelModals.showFnButton = true;
                    this._cancelModals.closeButtonLabel = 'Yes';
                    this._cancelModals.dismissButtonLabel = 'No';
                    this._cancelModals.fnButtonLabel = 'Reschedule';
                    this._cancelModals.backdrop = 'false';
                    this._cancelModals.title = 'Are you sure you want to cancel this appointment?';
                    this._cancelModals.emitter('open');
                } else {
                    /*this._infoModals.onClose = () => {
                        //this._infoModals.emitter('dismiss');
                        this.AppointmentDetail_Modals.close();
                    };

                    this._infoModals.showDismissButton = false;
                    this._infoModals.showFnButton = false;
                    this._infoModals.showCloseButton = true;
                    this._infoModals.closeButtonLabel = 'OK';
                    this._infoModals.backdrop = 'false';
                    //this._infoModals.title = "This appointment is not able to cancel anymore.";
                    this._infoModals.emitter('open');*/
                    this.errorCancelMessage = 'Sorry, we are unable to Cancel this appointment';
                }
            });

    }
    reschedule(appointmentDetailele : any) {
        this.errorCancelMessage = '';

        if (this.checkIsCancelSubscriber) {
            this.checkIsCancelSubscriber.unsubscribe();
        }
        this.checkIsCancelSubscriber = this.Service.patientCheckIsCancel(this.waitingTimeDirective.appointmentDetail.id)
            .subscribe((success: any) => {
                this.isCancel = success.is_cancel;

                if (this.isCancel) {
                    this.rescheduleDirective.open(appointmentDetailele); 
                    this.close();
                } else {
                    /*this._infoModals.onClose = () => {
                        //this._infoModals.emitter('dismiss');
                        //this.AppointmentDetail_Modals.close();
                    };

                    this._infoModals.showDismissButton = false;
                    this._infoModals.showFnButton = false;
                    this._infoModals.showCloseButton = true;
                    this._infoModals.closeButtonLabel = 'OK';
                    this._infoModals.backdrop = 'false';
                    this._infoModals.title = "This appointment is not able to reschedule anymore.";
                    this._infoModals.emitter('open');*/

                    this.errorCancelMessage = 'Sorry, we are unable to reschedule this appointment';
                }
            });
    }
    checkIsCancel() {
        if (this.checkIsCancelSubscriber) {
            this.checkIsCancelSubscriber.unsubscribe();
        }
        this.checkIsCancelSubscriber = this.Service.patientCheckIsCancel(this.waitingTimeDirective.appointmentDetail.id)
            .subscribe(
                (success: any) => {
                    this.isCancel = success.is_cancel;
                }
            );
    }

    reminderChange() {
        this.removeMessage();
        if (jQuery('#reminderNotif').is(":checked")) {
            this.isReminder = true;
            jQuery('#remind').attr('disabled', 'disabled');
        } else {
            this.isReminder = false;
            jQuery('#remind').removeAttr('disabled');
        }
    }

    alertNotifChange() {
        this.removeMessage();
        if (jQuery('#alertNotif').is(":checked")) {
            this.isAlert = true;
        } else {
            this.isAlert = false;
        }
    }

    emailNotifChange() {
        this.removeMessage();
        if (jQuery('#emailNotif').is(":checked")) {
            this.isEmail = true;
            jQuery('.email-notif').attr('disabled', 'disabled');
        } else {
            this.isEmail = false;
            jQuery('.email-notif').removeAttr('disabled');
        }
    }

    SMSNotifChange() {
        this.removeMessage();
        if (jQuery('#SMSNotif').is(":checked")) {
            this.isSMS = true;
            jQuery('#phone_mobile').attr('disabled', 'disabled');
        } else {
            this.isSMS = false;
            jQuery('#phone_mobile').removeAttr('disabled');
        }
    }

    removeMessage() {
        this.messageResponseSuccess = '';
        this.errorMessage = '';
        this.errorMobileMessage = '';
        this.errorEmailMessage = '';
        this.errorCancelMessage = '';
        this.errorAltMobileMessage = '';
    }
    getSubDomain() {
        const fullDomain = window.location.host;
        const path = fullDomain.split('.');
        if (path[1] == 'dev') {
            this.tenantname = path[0] + '.' + path[1];
        } else {
          this.tenantname = path[0];
        }
    }
    onKeyUp(event: any, error: string) {
        switch (error) {
            case 'alt_phonemobile':
              this.errorAltMobileMessage = null;
              this.showAltVerified = false;
              break;
          }
    }
    
    addwaitlist(id : any) {
        //this.waitlistFromAppointmentComponent.open(id); 
       // this.close();
       this.close();
       this._systemData.appointmentId = id;
       this.callEventWaitlistFrombookEmitter.emit('');
    }
}
