import { Component, EventEmitter, OnDestroy, OnInit, Output, AfterViewInit, ViewChild } from '@angular/core';
import { Subscription, fromEventPattern } from 'rxjs';
import { RtnFeedbackComponent } from '@app/patients/feedback/feedback.component';
import { RestService } from '@app/core/rest.service';
import { AlertService } from '@app/shared/alert';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';
import {PatientsService} from '@app/patients/patients.service';

@Component({
    moduleId: module.id,
    selector: 'rtn-modals-provider-unfollow',
    templateUrl: './provider-unfollow.component.html',
})

export class ProviderUnfollow_ModalsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('ProviderUnfollow_Modals') modal: ModalComponent;
    messageConfirmUnfollow = 'Are you sure you want to unfollow the selected provider(s)?';
    messageMinimumSelect = 'You need to select at least one provider.';
    minimumSelectedStatus = false;

    @Output()
    onClick: EventEmitter<any> = new EventEmitter();

    constructor(
        private _restService: PatientsService
    ) {
        // TODO
    }

    // ========== Specific Modal Method ========== //

    unfollowProvider(e: any) {
        this.onClick.emit(e);
    }

    // ========== Class Method ========== //

    ngOnInit() {
        // TODO
    }

    ngOnDestroy() {
        // TODO
    }

    ngAfterViewInit() {
        // TODO
    }


    // ========== General Modal Method ========== //

    open() {
        this.modal.open();
    }
}
