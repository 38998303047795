import { Component, ElementRef, AfterViewInit, OnDestroy, OnChanges, Input, Output, SimpleChange, EventEmitter } from '@angular/core';
import 'bootstrap-datepicker';

@Component({
  selector: 'rtn-calendar',
  template: `
        <div *ngIf="!inline" [attr.style]="styleDefinitions" [attr.class]="classDefinitions">
            <ng-content></ng-content>
        </div>
        <div *ngIf="inline"></div>
    `
})

export class RtnCalendarComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() value: any;
  @Input() inline = true;
  @Input() styleDefinitions: string;
  @Input() classDefinitions = 'input-group date';

  @Input() autoclose = true;
  @Input() beforeShowDay: any;
  @Input() beforeShowMonth: any;
  @Input() beforeShowYear: any;
  @Input() beforeShowDecade: any;
  @Input() beforeShowCentury: any;
  @Input() calendarWeeks = false;
  @Input() clearBtn = false;
  @Input() container = 'body';
  @Input() datesDisabled: any[] = new Array();
  @Input() daysOfWeekDisabled: any[] = new Array();
  @Input() daysOfWeekHighlighted: any[] = new Array();
  @Input() defaultViewDate: Object;
  @Input() disableTouchKeyboard = false;
  @Input() enableOnReadonly = true;
  @Input() endDate: Date;
  @Input() forceParse = false;
  @Input() assumeNearbyYear: boolean | number = false;
  @Input() format: any = 'mm/dd/yyyy';
  @Input() immediateUpdates = false;
  @Input() inputs: any;
  @Input() keyboardNavigation = true;
  @Input() language = 'en';
  @Input() maxViewMode: string | number = 4;
  @Input() minViewMode: string | number = 0;
  @Input() multidate: boolean | number = false;
  @Input() multidateSeparator = ',';
  @Input() orientation = 'auto';
  @Input() showOnFocus = false;
  @Input() startDate: Date;
  @Input() startView: string | number = 0;
  @Input() templates: any = {
    leftArrow: '<i class="fa fa-angle-left" style="font-size: 18px;"></i>',
    rightArrow: '<i class="fa fa-angle-right" style="font-size: 18px;"></i>'
  };
  @Input() title = '';
  @Input() todayBtn = false;
  @Input() todayHighlight = false;
  @Input() toggleActive = false;
  @Input() weekStart = 0;
  @Input() zIndexOffset = 10;

  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onClearDate: EventEmitter<any> = new EventEmitter();
  @Output() onChangeDate: EventEmitter<any> = new EventEmitter();
  @Output() onChangeYear: EventEmitter<any> = new EventEmitter();
  @Output() onChangeMonth: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  protected initialized: boolean;
  protected element: any;

  constructor(private el: ElementRef) {
    this.initialized = false;
  }

  ngAfterViewInit() {
    let options = new Object();
    options = {
      autoclose: this.autoclose,
      beforeShowDay: this.beforeShowDay,
      beforeShowMonth: this.beforeShowMonth,
      beforeShowYear: this.beforeShowYear,
      beforeShowDecade: this.beforeShowDecade,
      beforeShowCentury: this.beforeShowCentury,
      calendarWeeks: this.calendarWeeks,
      clearBtn: this.clearBtn,
      container: this.container,
      datesDisabled: this.datesDisabled,
      daysOfWeekDisabled: this.daysOfWeekDisabled,
      daysOfWeekHighlighted: this.daysOfWeekHighlighted,
      defaultViewDate: this.defaultViewDate,
      disableTouchKeyboard: this.disableTouchKeyboard,
      enableOnReadonly: this.enableOnReadonly,
      endDate: this.endDate,
      forceParse: this.forceParse,
      assumeNearbyYear: this.assumeNearbyYear,
      format: this.format,
      immediateUpdates: this.immediateUpdates,
      inputs: this.inputs,
      language: this.language,
      maxViewMode: this.maxViewMode,
      minViewMode: this.minViewMode,
      multidate: this.multidate,
      multidateSeparator: this.multidateSeparator,
      orientation: this.orientation,
      showOnFocus: this.showOnFocus,
      startDate: this.startDate,
      startView: this.startView,
      templates: this.templates,
      title: this.title,
      todayBtn: this.todayBtn,
      todayHighlight: this.todayHighlight,
      toggleActive: this.toggleActive,
      weekStart: this.weekStart,
      zIndexOffset: this.zIndexOffset
    };

    const _self = this;

    _self.element = jQuery(_self.el.nativeElement.children[0]);

    setTimeout(() => {
      jQuery(_self.element)
        .on('changeDate', function (event: any) {
          const instance = jQuery(this).data('datepicker');
          _self.onChangeDate.emit({
            'event': event,
            'view': jQuery(this),
            'element': instance
          });
          if (instance && instance.getFormattedDate) {
            _self.valueChange.next(instance.getFormattedDate());
          }
        }).on('changeMonth', function (event: any) {
        const instance = jQuery(this).data('datepicker');
        _self.onChangeMonth.emit({
          'event': event,
          'view': jQuery(this),
          'element': instance
        });
      }).on('changeYear', function (event: any) {
        const instance = jQuery(this).data('datepicker');
        _self.onChangeYear.emit({
          'event': event,
          'view': jQuery(this),
          'element': instance
        });
      }).on('clearDate', function (event: any) {
        const instance = jQuery(this).data('datepicker');
        _self.onClearDate.emit({
          'event': event,
          'view': jQuery(this),
          'element': instance,
        });
      }).on('hide', function (event: any) {
        const instance = jQuery(this).data('datepicker');
        _self.onHide.emit({
          'event': event,
          'view': jQuery(this),
          'element': instance
        });
      }).on('show', function (event: any) {
        jQuery('th.prev').html('<i class="fa fa-angle-left" style="font-size: 18px;"></i>');
        jQuery('th.next').html('<i class="fa fa-angle-right" style="font-size: 18px;"></i>');
        jQuery('table.table-condensed').css({'background': '#fff'});
        const instance = jQuery(this).data('datepicker');
        _self.onShow.emit({
          'event': event,
          'view': jQuery(this),
          'element': instance
        });
      }).datepicker(options);
      this.initialized = true;
    }, 10);
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.initialized) {
      for (const key in changes) {
        if (key === 'defaultViewDate' && jQuery.isPlainObject(changes[key].currentValue)) {
          const _defaultViewDate = changes[key].currentValue;
          jQuery(this.element).datepicker('update', new Date(_defaultViewDate['year'], _defaultViewDate['month'], _defaultViewDate['day']));
        }
      }
    }
  }

  ngOnDestroy() {
    jQuery(this.element).datepicker('destroy');
    this.initialized = false;
  }

  invoke(...xargs: any[]) {
    if (this.initialized) {
      const calendar = jQuery(this.element);
      const fnCallback = calendar.datepicker;
      return fnCallback.apply(calendar, arguments);
    }
  }

  updateDate(date: Date) {
    this.element.datepicker('update', date);
  }
}
