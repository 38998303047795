import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

import { DataLogsService } from '@app/data-logs/data-logs.service';
import * as moment from 'moment';
import { AlertService } from '@app/shared/alert';
import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { AdminRestService } from '@app/admin/admin.service';
import { Utils } from '@app/shared/utils';
declare let jQuery: any;

@Component({
  selector: 'app-simulatortool',
  templateUrl: './simulatortool.component.html',
  styleUrls: ['./simulatortool.component.scss']
})
export class SimulatortoolComponent implements OnInit, AfterViewInit {
  
  currentDate: any = moment();
  practiceId: string = '0';
  providerId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataStatistic = new dataStatistic();
  errorResponse: dataStatistic = new dataStatistic();
  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;
  public practicesList: any = [];
  public providersList: any = [];
  public appointmentData: any = [];

  playDiv = {
    autoPlay: false,
    manualPlay: false,
    playBtn: true,
    pauseBtn: false,
  };
  conversation: any[] = [];
  currentIndex: number = 0;
  currentItem: any = [];
  intervalId: any;

  activityData = [
    {
      patentname: 'Patent-1',
      reminderTime: '7.00'
    },
    {
      patentname: 'Patent-2',
      reminderTime: '8.00'
    },
    {
      patentname: 'Patent-3',
      reminderTime: '9.00'
    },
    {
      patentname: 'Patent-4',
      reminderTime: '10.00'
    },
    {
      patentname: 'Patent-5',
      reminderTime: '11.00'
    },
    {
      patentname: 'Patent-6',
      reminderTime: '12.00'
    }
  ]

  activityArray: any[] = [];

  maxDate:any;
    @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
    @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
    @ViewChild('inputDateFrom') inputPractice: ElementRef;
    @ViewChild('inputDateFrom') inputProvider: ElementRef;
    @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
    @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
    @ViewChild('inputDateTo') inputDateTo: ElementRef;
    @ViewChild('clickDateTo') clickDateTo: ElementRef;

    processStatus:boolean=false;

  constructor(
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _adminService: AdminRestService,
    private _datalogsService: DataLogsService,
    private _utils: Utils,
  ) {
    this.dataSearchChart.dateFrom = moment().subtract(1, "days").format('LL');
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.ProviderSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
    const _self = this;
    _self._loadingIndicatorService.start();
    _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
        (success: any) => {
            _self.practicesList = success.practices;
            const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
            _self.getProviderByPractice(firstPractice);
            this._loadingIndicatorService.stop();
        });
    
  }
  ngOnDestroy() {

  }
  removeMsgErr() {
    this.errorResponse.provider = null;
    this.ProviderSelector.inputError = false;
    this.ProviderSelector.changeBorderColorProvider();
  }

  onChangePractice() {
      this.ProviderSelector.clearData();
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      this.removeMsgErr();
      const practice = this.PracticeSelector.getData();
      if (practice.length !== 0) {
          this.getProviderByPractice(practice);
          if (this.providersList.length > 0) {
              this.ProviderSelector.updateData(this.providersList);

          }
      } else {
          this.ProviderSelector.clearData();
      }
  }
  getProviderByPractice(practiceId: any) {

    this._loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
        this.practiceTypeSubscriber.unsubscribe();
    }
    this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
        (success: any) => {
            if (success.error_code === 'RC000') {
                this.providersList = [];
                success.providers.forEach((item: any) => {
                    item.full_name
                        = item.first_name + ((item.middle_name == null)
                            ? (' ')
                            : (' ' + item.middle_name + ' ')) + item.last_name;
                    this.providersList.push(item);
                });


            }
            this._loadingIndicatorService.stop();
        },
        (error: any) => {

            this._loadingIndicatorService.stop();
        }
    );
  }
  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
        this.maxDate = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
        //this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    }else{
        this.maxDate = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
        //this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(_self.inputDateFrom.nativeElement).datepicker({
        dateFormat: 'MM dd, yy',
        numberOfMonths: 2,
        showButtonPanel: true,
        maxDate: moment(this.maxDate).toDate(),
        changeMonth: true,
        changeYear: true,
        onSelect: (selected: any) => {
            _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
        }
    });
    jQuery(_self.inputDateFrom.nativeElement).datepicker("setDate", this.dataSearchChart.dateFrom);
    jQuery(_self.clickDateFrom.nativeElement).click(() => {
        jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
    });    
  }
  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length == 0) {
        this.errorResponse.practice = message;
        this.PracticeSelector.inputError = true;
        this.PracticeSelector.changeBorderColorPractice();
        return false;
    } else {
        this.errorResponse.practice = null;
        this.PracticeSelector.inputError = false;
        this.PracticeSelector.changeBorderColorPractice();
        return true;
    }
  }

  validateProvider(message: any) {
      if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
          this.errorResponse.provider = message;
          this.ProviderSelector.inputError = true;
          this.ProviderSelector.changeBorderColorProvider();
          return false;
      } else {
          this.removeMsgErr();
          return true;
      }
  }

  validate() {
      const validatePractice = this.validatePractice('This field is required');
      const validateProvider = this.validateProvider('This field is required');
      const validateDateFrom = true;
      if (validatePractice && validateProvider && validateDateFrom) {
          return true;
      }
      return false;
  }
  updateData() {
    
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;    

    if (this.validate()) {        
        //this.loadSchedule(dateFrom, practiceID, providerID);
        this.getProviderschedule(dateFrom, practiceID, providerID);
    } else {        
        return;
    }

  }
  resetData(){

  }
  loadSchedule(dateFrom: any, practiceID: any, providerID: any){
    this._loadingIndicatorService.start();        
    this._adminService.loadProviderschedule(dateFrom, practiceID, providerID).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();                
                console.log(success);
                this.getProviderschedule(dateFrom, practiceID, providerID);
            },
            (error: any) => {
                // todo
                this._loadingIndicatorService.stop();
            }
        );
  }
  getProviderschedule(dateFrom: any, practiceID: any, providerID: any){
    this._loadingIndicatorService.start();        
    this._adminService.getProviderschedule(dateFrom, practiceID, providerID).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.appointmentData = success.data_schedules;
            },
            (error: any) => {
                // todo
                this._loadingIndicatorService.stop();
            }
        );
  }
  processSchedule(){
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;    

    if (this.validate()) {        
      this._loadingIndicatorService.start();        
      this._adminService.processProviderSchedule(dateFrom, practiceID, providerID).subscribe(
              (success: any) => {
                  this._loadingIndicatorService.stop();
                  this.processStatus = true;
                 // this.appointmentData = success.data_schedules;
              },
              (error: any) => {
                  // todo
                  this._loadingIndicatorService.stop();
              }
          );
    } else {        
        return;
    }
    
  }
  autoPlayBtn() {
    this.playDiv.autoPlay = true,
    this.playDiv.manualPlay = false
  }
  manualPlayBtn() {
    this.playDiv.autoPlay = false,
    this.playDiv.manualPlay = true
  }
  // For Next Button.
  addNextActivityData() {
    this.currentItem.push(this.activityData[this.currentIndex]);
    this.currentIndex++;
    // After end of data auto mode list will be stop.
    if (this.currentIndex >= this.activityData.length) {
      this.pauseActivityBtn();
      this.playDiv.playBtn = true,
      this.playDiv.pauseBtn = false
    }
  }
// For Previous Button.
  addPrevtActivityData() {
    this.currentItem.pop(this.currentIndex);
    this.currentIndex--;
  }
// For Auto Play.
  triggerFunctionWithDelay() {
    if (this.currentIndex >= this.activityData.length) {
      alert('No activity available');
      this.playDiv.playBtn = true,
      this.playDiv.pauseBtn = false
    }
    else {
      this.intervalId = setInterval(() => { this.addNextActivityData(); }, 2000);
    }
  }
// For Pause.
  pauseActivityBtn() {
    clearInterval(this.intervalId);
    this.intervalId = null
  }
}
export class dataStatistic {
  practice: any = null;
  provider: any = null;
  dateFrom: any = null;  
}
