import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { BillingInfo } from '../../shared/models/billingInfo';
import { PracticeDetail } from '../../shared/models/practiceDetail';
import { Select2InsuranceDirective } from '../../shared/form/select2-insurance.directive';
import { Utils } from '../../shared/utils';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';
import {Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-practice',
  templateUrl: './edit-practice.component.html',
  styleUrls: ['../admin.component.scss','./edit-practice.component.scss'],
  moduleId: module.id,
})
export class EditPracticeComponent implements OnInit {

  @ViewChild(Select2InsuranceDirective) insurancesSelector: Select2InsuranceDirective;
  @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
  @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;
    //@ViewChild(OpeningHourComponent) openingHourDirective: OpeningHourComponent;

    billing: BillingInfo = new BillingInfo();
    practiceDetail: PracticeDetail = new PracticeDetail();
    responseError: PracticeDetail = new PracticeDetail();
    listTime: any[] = new Array();
    settingFields: any = {};
    successMessage: string;
    idSelected: any;

    updatePracticeSubscriber: Subscription;
    onGetPracticeSubscriber: Subscription;
    getInsurancesSubscriber: Subscription;

    constructor(
        private _router: Router,
        private _systemEvent: SystemEvent,
        private _systemService: SystemService,
        public _systemData: SystemData,
        private _routeParams: ActivatedRoute,
        private _alertService: AlertService,
        private _adminService: AdminRestService,
        private _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {}

    ngOnInit() {
        var self = this;
        this._loadingIndicatorService.start();
        this.idSelected = parseInt(this._routeParams.snapshot.paramMap.get('id'));
        this.onGetPracticeSubscriber = this._adminService.getProfile(this.idSelected).subscribe(
            (success: any) => {
                self._loadingIndicatorService.stop();
                self.practiceDetail = success.practice;
                if (success.practice.billing !== null) {
                    self.billing = success.practice.billing;
                }
                // Set format for phone
                self.billing.phone_mobile = self.billing.phone_mobile.replace('+1 ', '');
                self.practiceDetail.state_code = self.practiceDetail.state_code === null ? '' : self.practiceDetail.state_code.toUpperCase();
                self.billing.state_code = self.billing.state_code === null ? '' : self.billing.state_code.toUpperCase();
                //this.openingHourDirective.injectData(this.practiceDetail.opening_hours);
                //Check if have case no insurance
                                let index = self.practiceDetail.insurances.indexOf(null);
                if (index !== -1) {
                    self.practiceDetail.insurances[index] = '-1';
                }

                let resultInsuranceList = self._systemService.getInsuranceList();
                if (self._systemData.insurances.length !== 0) {
                    if(resultInsuranceList != "done"){
                        self.getInsurancesSubscriber = self._systemEvent.insurances.subscribe(
                            (success: any) => {
                                if (success) {
                                    setTimeout(() => {
                                        self.insurancesSelector.updateData(self.practiceDetail.insurances);
                                    }, 500);
                                }
                            },(error: any) => {
                                console.log(error);
                            });
                    }else{
                        setTimeout(() => {
                            self.insurancesSelector.updateData(self.practiceDetail.insurances);
                        }, 500);
                    }
                }
            }, (error: any) => {
                self._loadingIndicatorService.stop();
                self._alertService.onClose = () => {
                    self._router.navigate(['/admin', 'practice']);
                };
                self._alertService.showDismissButton = false;
                self._alertService.dismissButtonLabel = 'Close';
                self._alertService.showCloseButton = true;
                self._alertService.closeButtonLabel = 'OK';
                self._alertService.title = error.error.error_messages;
                self._alertService.message = '';
                self._alertService.emitter('open');
            }
        );
        this._systemService.getStates();
    }

    ngOnDestroy() {
        if (this.onGetPracticeSubscriber) {
            this.onGetPracticeSubscriber.unsubscribe();
        }
        if (this.updatePracticeSubscriber) {
            this.updatePracticeSubscriber.unsubscribe();
        }
        if (this.getInsurancesSubscriber) {
            this.getInsurancesSubscriber.unsubscribe();
        }
    }

    removeMessage() {
        this.successMessage = null;
    }

    updatePracticeForm(formVals: any) {
        this._loadingIndicatorService.start();
        this.successMessage = null;

        let value = Object.assign({}, formVals);
        let billing_info = this.formatDataBillingInfo(value);
        //value.opening_hours = this.openingHourDirective.getData();
        value.billing = billing_info;
        if (this.insurancesSelector.getData() !== null) {
            value.insurances = this.insurancesSelector.getData();
        } else {
            value.insurances = [];
        }

        // Get the checked box index before sending out save request
        //let currentCheckedBoxIndex = this.openingHourDirective.getCurrentCheckedBoxIndex();
        if (this.updatePracticeSubscriber) {
            this.updatePracticeSubscriber.unsubscribe();
        }
        this.updatePracticeSubscriber = this._adminService.updatePractice(this.idSelected, value).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.successMessage = success.error_messages;
                this.responseError = new PracticeDetail();
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let res = error.error;
                if (res.error_code === 'RC060') {
                    this.responseError = res.error_fields;
                } else if (res.error_code === 'RC070') {
                    this.showPopUpWarning(res.error_messages);
                } else {
                    this.responseError = res.error_fields;
                }
                /* let messagePos1 = '';
                let messagePos2 = '';
                let messagePos3 = '';
                if ((<any>this.responseError)['opening_hours.0.days'] && (<any>this.responseError)['opening_hours.0.days'].length !== 0) {
                    messagePos1 = (<any>this.responseError)['opening_hours.0.days'];
                } else if ((<any>this.responseError)['opening_hours.0.end_time'] && (<any>this.responseError)['opening_hours.0.end_time'].length !== 0) {
                    messagePos1 = (<any>this.responseError)['opening_hours.0.end_time'];
                }
                if ((<any>this.responseError)['opening_hours.1.days'] && (<any>this.responseError)['opening_hours.1.days'].length !== 0) {
                    messagePos2 = (<any>this.responseError)['opening_hours.1.days'];
                } else if ((<any>this.responseError)['opening_hours.1.end_time'] && (<any>this.responseError)['opening_hours.1.end_time'].length !== 0) {
                    messagePos2 = (<any>this.responseError)['opening_hours.1.end_time'];
                }
                if ((<any>this.responseError)['opening_hours.2.days'] && (<any>this.responseError)['opening_hours.2.days'].length !== 0) {
                    messagePos3 = (<any>this.responseError)['opening_hours.2.days'];
                } else if ((<any>this.responseError)['opening_hours.2.end_time'] && (<any>this.responseError)['opening_hours.2.end_time'].length !== 0) {
                    messagePos3 = (<any>this.responseError)['opening_hours.2.end_time'];
                }

                let showError = [{ index: 0, message: messagePos1 }, { index: 1, message: messagePos2 }, { index: 2, message: messagePos3}];
                this.openingHourDirective.injectError(currentCheckedBoxIndex, showError); */

                let data: any = [];
            }, () => {
                this._loadingIndicatorService.stop();
            }
        );
    }

    showPopUpWarning(titleWarning: string) {
        this._alertService.onClose = () => {
            location.reload();
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = titleWarning;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    formatDataBillingInfo(data: any) {
        return {
            'first_name': data.billing_first_name,
            'last_name': data.billing_last_name,
            'email': data.billing_email,
            'phone_mobile': data.billing_phone_mobile,
            'address': data.billing_address,
            'address2': data.billing_address2,
            'city': data.billing_city,
            'state_code': data.billing_state_code,
            'zip': data.billing_zip
        };
    }

    onKeyUp(event: any, error: string) {
        this.successMessage = null;

        switch (error) {
            case 'name':
                this.responseError['name'] = null;
                break;
            case 'address':
                this.responseError['address'] = null;
                break;
            case 'address2':
                this.responseError['address2'] = null;
                break;
            case 'city':
                this.responseError['city'] = null;
                break;
            case 'state_code':
                this.responseError['state_code'] = null;
                break;
            case 'zip':
                this.responseError['zip'] = null;
                break;
            case 'insurances':
                this.responseError['insurances'] = null;
                break;
            case 'billing_first_name':
                (<any>this.responseError)['billing.first_name'] = null;
                break;
            case 'billing_last_name':
                (<any>this.responseError)['billing.last_name'] = null;
                break;
            case 'billing_email':
                (<any>this.responseError)['billing.email'] = null;
                break;
            case 'billing_phone_mobile':
                (<any>this.responseError)['billing.phone_mobile'] = null;
                break;
            case 'billing_address':
                (<any>this.responseError)['billing.address'] = null;
                break;
            case 'billing_address2':
                (<any>this.responseError)['billing.address2'] = null;
                break;
            case 'billing_city':
                (<any>this.responseError)['billing.city'] = null;
                break;
            case 'billing_state_code':
                (<any>this.responseError)['billing.state_code'] = null;
                break;
            case 'billing_zip':
                (<any>this.responseError)['billing.zip'] = null;
                break;
        }
    }

    preventWhiteSpaceInput(event: any) {
        if (event.target.value.match(/\s/g)) {
            event.target.value = event.target.value.replace(/\s/g, '');
        }
    }

}
