import {Component, OnInit} from '@angular/core';
import {Module} from '@app/core/module';
import {SystemService} from '@app/shared/system/system.service';
import {Router} from '@angular/router';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';

declare let jQuery: any;

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {
  module: any;
  minHeight: number;
  constructor(
    public _systemService: SystemService,
    public _router: Router,
    private loadingIndicatorService: LoadingIndicatorService,
  ) {
  }

  ngOnInit() {
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }

}
