import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
// import { ROUTER_DIRECTIVES, RouteConfig, Router } from '@angular/router';
import {Router} from '@angular/router';

// import { HeaderComponent } from '@app/shared/header/header.component';
// import { FooterComponent } from '../marketing/footer/footer.component';
import {SystemData, SystemService, SystemEvent, SystemInterval} from '@app/shared/system';
import {RestService as PatientService} from '@app/core/rest.service';
import {Subscription} from 'rxjs';
import {Utils} from '@app/shared/utils';
import {AlertService} from '@app/shared/alert';

// import { HomepageComponent } from '../../pages/patient/homepage/components/homepage.component';
// import { PhysicianDirectoryComponent } from '../../pages/patient/physician-directory/components/physician-directory.component';
// import { ActivatedComponent } from '../../pages/patient/activated/components/activated.component';
// import { AppointmentComponent } from '../../pages/patient/appointment/components/appointment.component';
// import { DoctorSearchComponent } from '../../pages/patient/doctor/components/search.component';
// import { DoctorFollowComponent } from '../../pages/patient/doctor/components/follow.component';
// import { ProfileComponent } from '../../pages/patient/profile/components/profile.component';
// import { EditComponent } from '../../pages/patient/edit/components/edit.component';
// import { NotificationComponent } from '../../pages/patient/notification/components/notification.component';
// import { InsuranceComponent } from './insurance/insurance.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PatientsService} from '@app/patients/patients.service';
import {NotificationsService} from '@app/notifications/notifications.service';
import {Module} from '@app/core/module';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import { VerifyPhonenoComponent } from '@app/patients/modals/verify-phoneno/verify-phoneno.component';
// import { DoctorDetailComponent } from '../../pages/patient/doctor/components/detail.component';
// import { AcceptedFamilyComponent } from '../../pages/patient/family-member/components/accepted-family-member.component';
// import { DeclinedFamilyComponent } from '../../pages/patient/family-member/components/declined-family-member.component';

declare let jQuery: any;

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit, OnDestroy {

  @ViewChild(VerifyPhonenoComponent) verifyPhonenoDirective: VerifyPhonenoComponent;
  // declare arrayActiveModule
  arrayModule = new Array();
  showMenu = false;
  minHeight: number;
  isActivatedScreen = false;
  notificationListener: any;
  menuListener: any;
  menuSelect = '';
  NumOfUnreadNotification: any = 0;
  deleteNotificationsSubscriber: Subscription;
  module: any;
  private getUnreadNotificationInterval: any;
  patientMobileMenu:boolean=false;

  constructor(
    private _restService: NotificationsService,
    private _patientService: PatientsService,
    public _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemInterval: SystemInterval,
    private _systemService: SystemService,
    private _router: Router,
    private _utils: Utils,
    private _alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
  }

  ngOnInit() {
    //this.openPhoneValidateModal();
    //this.verifyPhonenoDirective.open();
    // get active module array
    this.arrayModule = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!Module.PATIENT) {
      this.loadingIndicatorService.stop();
      this._router.navigate(['/home', 'page-not-found']);
    } else {
      // this moved to dashboard page
      //this.getUnreadNotifications();
      this.notificationListener = this._systemEvent.notification.subscribe((action: string) => {
        switch (action) {
          case 'notification_changeNumberOfUnreadNotifications':
            if (this._systemData.numberOfNewNotifications > 0) {
              this._systemData.numberOfNewNotifications--;
            }
            this._systemData.numberOfNewNotificationsDisplay = '' + this._systemData.numberOfNewNotifications;
            if (this._systemData.numberOfNewNotifications > 99) {
              this._systemData.numberOfNewNotificationsDisplay = '99+';
            }
            break;
          case 'notification_getNumberOfUnreadNotifications':
            this.getNumberOfUnreadNotification();
            break;
          default:
            break;
        }
      });
      this.menuListener = this._systemEvent.menu.subscribe((action: string) => {
        switch (action) {
          case 'dashboard':
            this.menuSelect = 'main';
            jQuery('#menu-device').blur();
            break;
          case 'account':
            this.menuSelect = 'account';
            jQuery('#menu-device').blur();
            break;
          case 'insurance':
            this.menuSelect = 'insurance';
            jQuery('#menu-device').blur();
            break;
          case 'appointments':
            this.menuSelect = 'appointments';
            jQuery('#menu-device').blur();
            break;
          case 'notifications':
            this.menuSelect = 'notifications';
            jQuery('#menu-device').blur();
            break;
          case 'find-providers':
            this.menuSelect = 'find-providers';
            jQuery('#menu-device').blur();
            break;
          default:
            break;
        }
        jQuery('#menu-device').blur();
      });
      // Get number of notification unread
      this.getNumberOfUnreadNotification();
      setTimeout(() => {
        const pathname = window.location.hash;
        if (
          pathname === '/patient/activated'
          || pathname === '/patient/homepage' 
          || pathname === '/patient/physician-directory' 
          || pathname.substr(0, 15) === '/patient/accept' 
          || pathname.substr(0, 16) === '/patient/decline') {
          this.showMenu = false;
          if (pathname === '/patient/activated') {
            this.isActivatedScreen = true;
          }
        } else {
          this.showMenu = true;
          this.isActivatedScreen = false;
        }
      }, 100);
      //this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight() - jQuery('nav.navbar-patient-static-top').outerHeight();
      this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight() - jQuery('header.patientheader').outerHeight();
      console.log(this.minHeight);
    }
    this.getverifiedphonenostatus();
    
  }

  openPatientMenufunction(){
     this.patientMobileMenu=true;
  }

 closePatientMenufunction(){
    this.patientMobileMenu=false;
 }

  ngOnDestroy() {
    jQuery(window).scrollLeft(0);
    if (this.notificationListener) {
      this.notificationListener.unsubscribe();
    }
    if (this.menuListener) {
      this.menuListener.unsubscribe();
    }
    this.stopGetUnreadNotificationInterval();
  }

  getNumberOfUnreadNotification() {
    this.stopGetUnreadNotificationInterval();

    const getUnRead = () => {
      this._patientService.getNumOfUnreadNotification().subscribe(
        (success: any) => {
          if (success.error_code === 'RC000') {
            this._systemData.numberOfNewNotifications = success.total;
            this._systemData.numberOfNewNotificationsDisplay = '' + this._systemData.numberOfNewNotifications;
            if (this._systemData.numberOfNewNotifications > 99) {
              this._systemData.numberOfNewNotificationsDisplay = '99+';
            }
          }
        }
      );
    };

    this.getUnreadNotificationInterval = setInterval(getUnRead, this._systemInterval.notification_fetchNotification.duration);
    getUnRead();
  }

  stopGetUnreadNotificationInterval() {
    if (this.getUnreadNotificationInterval) {
      clearInterval(this.getUnreadNotificationInterval);
      this.getUnreadNotificationInterval = null;
    }
  }

  onChangeMenu(value: any) {
    console.log(value);
    if (value !== 'insurance' && value !== 'appointments') {
      if (this._utils.isPartialActiveStatus()) {
        this._alertService.onClose = () => {
          const pathname = window.location.hash;
          if (pathname === '/patient/insurance') {
            this.menuSelect = 'insurance';
          } else if (pathname === '/patient/appointment/openings') {
            this.menuSelect = 'appointments';
          }
        };
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.title = 'Your account has not full access yet. Please update insurance or contact RTN admin to get full access account';
        this._alertService.message = '';
        this._alertService.emitter('open');
        return;
      }
    }
    switch (value) {
      case 'main':
        this._router.navigate(['/patient', 'dashboard']);
        break;
      case 'account':
        this._router.navigate(['/patient', 'edit']);
        break;
      case 'insurance':
        this._router.navigate(['/patient', 'insurance']);
        break;
      case 'appointments':
        this._router.navigate(['/patient', 'appointment', 'openings']).then(() => {
          window.location.reload();
        });
        break;
      case 'myappointments':
          this._router.navigate(['/patient', 'appointment', 'find']).then(() => {
            window.location.reload();
          });
          break;  
      case 'notifications':
        this._router.navigate(['/patient', 'notification']);
        break;
      case 'find-providers':
        this._router.navigate(['/patient', 'find-providers'] ).then(() => {
          window.location.reload();
        });
        break;
      default:
        break;
    }
    jQuery('#menu-device').blur();
  }

  getUnreadNotifications() {
    this.deleteNotificationsSubscriber = this._restService.getUnreadNotifications().subscribe(
      (success: any) => {
        this.NumOfUnreadNotification = success.total;
        if (this.NumOfUnreadNotification > 99) {
          this.NumOfUnreadNotification = '99+';
        }
      }
    );
  }

  openPhoneValidateModal() { 
   /*this._alertService.onClose = () => {
      // location.reload();
    };
    this._alertService.showDismissButton = false;
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'Close';
    this._alertService.title = 'Please verify your phone number';
    this._alertService.message = '';
    this._alertService.emitter('open');*/
  }

  getverifiedphonenostatus() {   
      
    const iscancel = localStorage.getItem('iscancel');

    if (iscancel == 'yes'){
      return;
    }           
      this._patientService.isverifiedphoneno({'type':'mobileno'}).subscribe((success: any) =>  {          
          if(success.isverified == 0 && success.phone_mobile !='' && success.phone_mobile != undefined){
            this.verifyPhonenoDirective.open(success); 
          }
      });      
  }
}
