import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
// import { ManageUserService } from '../services/manage-user.service';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {SuperManagingUserDetail} from '@app/shared/models/superManagingUserDetail';
// import { RouteParams, ROUTER_DIRECTIVES } from 'angular2/router';
import {Subscription} from 'rxjs';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {AlertService} from '@app/shared/alert/alert.service';
import {RestService} from '@app/core/rest.service';
import {ActivatedRoute} from '@angular/router';
import {AdminRestService} from '@app/admin/admin.service';

@Component({
  moduleId: module.id,
  templateUrl: './edit-managing-user.component.html',
  styleUrls: ['./edit-managing-user.component.scss']
})
export class EditManagingUserComponent implements OnInit, OnDestroy {
  managingUserDetail: SuperManagingUserDetail = new SuperManagingUserDetail();
  responseError: SuperManagingUserDetail = new SuperManagingUserDetail();
  updateNewManagingUserSubscriber: Subscription;
  onGetUserSubscriber: Subscription;
  loadPracticeListSubscriber: Subscription;
  practiceList: any = [];
  idSelected: number;
  successMessage: string;
  emailInDb = '';
  currentStatus: number;
  office_name:any;

  password: string;
  password_confirmation: string;
  notify_to : boolean = false;
  successMessage1 : string;
  updateManageUserPasswordSubscriber : Subscription;
  responseError1: ManageuserPasswordItem = new ManageuserPasswordItem();

  constructor(
    public _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _service: AdminRestService,
    private route: ActivatedRoute,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _alertService: AlertService
  ) {
    this._loadingIndicatorService.start();
    this.idSelected = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.onGetUserSubscriber = this._service.getUserDetail(this.idSelected).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.managingUserDetail = success.user;
        this.office_name = success.user.office_name;
        // Set format for phone
        this.managingUserDetail.phone_mobile = this.managingUserDetail.phone_mobile.replace('+1 ', '');
        this.managingUserDetail.state_code = this.managingUserDetail.state_code === null
          ? '' : this.managingUserDetail.state_code.toUpperCase();
        this.emailInDb = this.managingUserDetail.email;
        this.currentStatus = this.managingUserDetail.status;
      }, (error: any) => {
        this._loadingIndicatorService.stop();
      });
    this.loadPracticeListSubscriber = this._service.getPracticeList().subscribe(
      (success: any) => {
        this.practiceList = success.practices;
      }
    );
  }

  ngOnInit() {
    this._systemService.getStates();
  }

  ngOnDestroy() {
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
    if (this.updateNewManagingUserSubscriber) {
      this.updateNewManagingUserSubscriber.unsubscribe();
    }
    if (this.onGetUserSubscriber) {
      this.onGetUserSubscriber.unsubscribe();
    }
  }

  updateManagingUserForm(formVals: any) {
    const self = this;
    this.successMessage = null;
    const value = Object.assign({}, formVals);
    value.office_name = this.office_name;
    this._loadingIndicatorService.start();
    if (this.updateNewManagingUserSubscriber) {
      this.updateNewManagingUserSubscriber.unsubscribe();
    }
    this.updateNewManagingUserSubscriber = this._service.updateManagingUserForm(this.idSelected, value).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.successMessage = success.error_messages;
        this.responseError = new SuperManagingUserDetail();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
        const res = error.error;
        this.responseError = res.error_fields;
      }
    );
    
  }

  onKeyUp(error: string) {
    switch (error) {
      case 'email':
        this.responseError['email'] = null;
        break;
      case 'phone_mobile':
        this.responseError['phone_mobile'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'first_name':
        this.responseError['first_name'] = null;
        break;
      case 'middle_name':
        this.responseError['middle_name'] = null;
        break;
      case 'last_name':
        this.responseError['last_name'] = null;
        break;
      case 'office_name':
        this.responseError['office_name'] = null;
        break;
      case 'password':
          this.responseError1['password'] = null;
          break;
      case 'confirm_password':
          this.responseError1['confirm_password'] = null;
          break;   
    }
  }
  
  updatePasswordFromAdmin(formVals: any) {
    const self = this;
    this.successMessage = null;
    let value = Object.assign({}, formVals);
    //console.log(value);

    this._loadingIndicatorService.start();
        if (this.updateManageUserPasswordSubscriber) {
            this.updateManageUserPasswordSubscriber.unsubscribe();
        }
        this.updateManageUserPasswordSubscriber = this._service.updatePasswordFromAdmin(this.idSelected, value).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.successMessage1 = success.error_messages;
                this.responseError1 = new ManageuserPasswordItem();
                this.password = null;
                this.password_confirmation = null;
                this.notify_to = false;
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let res = error.error;
                this.responseError1 = res.error_fields;
                this.password = null;
                this.password_confirmation = null;
                this.notify_to = false;
            }
        );
    }
}
export class ManageuserPasswordItem {
  password: string = null;
  password_confirmation: string = null;
  notify_to : boolean = false;
}