import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import {RtnCalendarComponent} from '@app/shared/calendar/calendar.component';
import {RestService as EditService} from '@app/core/rest.service';
// import { ROUTER_DIRECTIVES, Router } from '@angular/router';
import {Router} from '@angular/router';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {RtnAuth} from '@app/shared/rtn-auth';
import {AlertService} from '@app/shared/alert';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {EditPhoneConfirmComponent} from '@app/patients/modals/edit-phone-confirm/edit-phone-confirm.component';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {Utils} from '@app/shared/utils';
// import { PasswordFormatTooltipComponent } from '@app/shared/tooltip/password-format';
import {PatientDetail, PatientAltphone} from '@app/shared/models/patientDetail';
import {Subscription} from 'rxjs';
import {DOBComponent} from '@app/shared/dob/dob';
import {MobileNumberComponent} from '@app/shared/mobile-number/mobile-number.component';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

import { VerifyPhonenoComponent } from '@app/patients/modals/verify-phoneno/verify-phoneno.component';


declare var jQuery: any;
// declare var moment: any;
import * as moment from 'moment';
import {PatientsService} from '@app/patients/patients.service';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6 // Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class PatientAccountComponent implements OnInit, AfterViewInit, OnDestroy {

  

  _patientDetail: any;
  statusMapping = false;
  ngDatePicker: any;
  _weekStart = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)
  error: PatientDetail = new PatientDetail();
  statusPhoneVerify = false;
  showStatusPhoneVerify = false;
  statusAltPhoneVerify= false;
  showStatusAltPhoneVerify = false;
  checkInvalidPhone = true;
  fullNameTemp: string;
  elementPopover: any;
  popoverIsOpened = false;
  successMessageAccount: string;
  successMessagePassword: string;
  successMessageAddress: string;
  firstEmail: string;
  onGetProfileListener: Subscription;
  updateProfileSubscriber: Subscription;
  updateAccountSubscriber: Subscription;
  updatePasswordSubscriber: Subscription;
  updateAddressSubscriber: Subscription;
  changeEmail: boolean = false;
  alt_phonemobile: any = {
    first : '',
    middle : '',
    last : ''
  };

  @ViewChild(DOBComponent) dobDirective: DOBComponent;
  @ViewChild(MobileNumberComponent) mobileNumberDirective: MobileNumberComponent;
  @ViewChild(EditPhoneConfirmComponent) editPhoneConfirmDirective: EditPhoneConfirmComponent;
  @ViewChild(VerifyPhonenoComponent) verifyPhonenoDirective: VerifyPhonenoComponent;

  // @ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;

  constructor(
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    public _systemData: SystemData,
    private _editService: PatientsService,
    private _router: Router,
    private _auth: RtnAuth,
    private _alertService: AlertService,
    private _utils: Utils,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    this._patientDetail = new Object();
    this.ngDatePicker = new Object();
    this.ngDatePicker['todayHighlight'] = true;
    this.ngDatePicker['weekStart'] = this._weekStart;
    this.ngDatePicker['format'] = 'MM dd, yyyy';
    this.onGetProfileListener = this._systemEvent.profile.subscribe((done: boolean) => {
      if (done) {
        const tempPatientDetail = this._systemData.profile;
        const _dob = moment(tempPatientDetail.dob, 'YYYY-MM-DD');
        tempPatientDetail.dob_display = _dob.format('MMMM DD, YYYY');
        this.ngDatePicker['defaultViewDate'] = {
          day: _dob.day(),
          month: _dob.month(),
          year: _dob.year()
        };
        if (tempPatientDetail.is_mapped) {
          this.statusMapping = true;
        }

        //tempPatientDetail.alt_phonemobile = new PatientAltphone();

        // Set temp variables for phone
        tempPatientDetail.phone_mobile = tempPatientDetail.phone_mobile.replace('+1 ', '');
        tempPatientDetail.phone_mobile_tmp = tempPatientDetail.phone_mobile;
        tempPatientDetail.alt_phone_mobile = tempPatientDetail.alt_phone_mobile.replace('+1 ', '');

        // Check phone number verify or not
        this.statusPhoneVerify = tempPatientDetail.phone_mobile_verified === 1;
        tempPatientDetail.state_code = tempPatientDetail.state_code === null
          ? '' : tempPatientDetail.state_code.toUpperCase();
        this._patientDetail = tempPatientDetail;

        const dobArr = this._patientDetail.dob.split('-');
        this._patientDetail.year = dobArr[0];
        this._patientDetail.month = dobArr[1];
        this._patientDetail.day = dobArr[2];

        if (this._patientDetail.phone_mobile) {
          const num = this._patientDetail.phone_mobile.replace(/[^0-9]/g, '');
          this._patientDetail.first = num.substr(0, 3) ? '(' + num.substr(0, 3) + ')' : '';
          this._patientDetail.middle = num.substr(3, 3) ? num.substr(3, 3) : '';
          this._patientDetail.last = num.substr(6, 4) ? num.substr(6, 4) : '';
          this.showStatusPhoneVerify = true;
        } else {
          this._patientDetail.first = '';
          this._patientDetail.middle = '';
          this._patientDetail.last = '';
        }

        this.statusAltPhoneVerify = tempPatientDetail.alt_phone_mobile_verified === 1;

        if (this._patientDetail.alt_phone_mobile) {
          const altnum = this._patientDetail.alt_phone_mobile.replace(/[^0-9]/g, '');
          this.alt_phonemobile.first = altnum.substr(0, 3) ? '(' + altnum.substr(0, 3) + ')' : '';
          this.alt_phonemobile.middle = altnum.substr(3, 3) ? altnum.substr(3, 3) : '';
          this.alt_phonemobile.last = altnum.substr(6, 4) ? altnum.substr(6, 4) : '';
          this.showStatusAltPhoneVerify = true;
        } else {
          this.alt_phonemobile.first = '';
          this.alt_phonemobile.middle = '';
          this.alt_phonemobile.last = '';
        }        
        //console.log(this.alt_phonemobile);
      }
    });
  }

  ngOnInit() {
    if (this._utils.isPartialActiveStatus()) {
      this._router.navigate(['/patient', 'appointment', 'openings']);
    }
    this._systemData.profile = false;
    this._systemService.getProfile(null);
    this._systemService.getStates();
  }

  ngAfterViewInit() {
    const _self = this;
    this._systemEvent.menu.emit('account');
    jQuery('#first_name').bind({
      paste: function () {
        _self.onKeyUp(null, 'first_name');
      }
    });
    jQuery('#last_name').bind({
      paste: function () {
        _self.onKeyUp(null, 'last_name');
      }
    });
    jQuery('#email').bind({
      paste: function () {
        _self.onKeyUp(null, 'email');
      }
    });
    jQuery('#email_confirmation').bind({
      paste: function () {
        _self.onKeyUp(null, 'email_confirmation');
      }
    });
    jQuery('#phone_mobile').bind({
      paste: function () {
        _self.onKeyUp(null, 'phone_mobile');
      }
    });
    jQuery('#password_current').bind({
      paste: function () {
        _self.onKeyUp(null, 'password_current');
      }
    });
    jQuery('#password').bind({
      paste: function () {
        _self.onKeyUp(null, 'password');
      }
    });
    jQuery('#password_confirmation').bind({
      paste: function () {
        _self.onKeyUp(null, 'password_confirmation');
      }
    });
    jQuery('#zip').bind({
      paste: function () {
        _self.onKeyUp(null, 'zip');
      }
    });
  }

  ngOnDestroy() {
    if (this.onGetProfileListener) {
      this.onGetProfileListener.unsubscribe();
    }
    if (this.updateAccountSubscriber) {
      this.updateAccountSubscriber.unsubscribe();
    }
    if (this.updatePasswordSubscriber) {
      this.updatePasswordSubscriber.unsubscribe();
    }
    if (this.updateAddressSubscriber) {
      this.updateAddressSubscriber.unsubscribe();
    }
  }

  // Show or disabled button verify phone when input new phone number
  checkVerifyPhoneOnKey() { console.log(this._patientDetail)
    if (this._patientDetail.phone_mobile_verified === 1) {
      if (this._patientDetail.phone_mobile_tmp !== this._patientDetail.phone_mobile) {
        this.statusPhoneVerify = false;
      } else {
        this.statusPhoneVerify = true;
      }
    }
    if (this._patientDetail.phone_mobile_tmp.replace(/[^0-9]/g, '').length === 10) {
      this.checkInvalidPhone = true;
      this.error.phone_mobile = null;
    } else {
      this.checkInvalidPhone = false;
    }
  }

  updateAccount(formVals: any) {
      //console.log(formVals);
    this.error.email_confirmation = '';
    this.successMessageAccount = null;
    const value = Object.assign({}, formVals);
    //console.log('TEST!@# ', this._patientDetail);
    //
    value.first_name = this._patientDetail.first_name;
    value.last_name = this._patientDetail.last_name;

    // console.log("TEST!@3123 ", this._patientDetail.email, value.email);
    // PENDING check lại email confirm: nếu edit email thì sẽ ko nhảy vào if() dưới -> ko xuất hiện field email_confirmation trong request ????
    if (this._patientDetail.email === value.email && !this.changeEmail) {
        value.email_confirmation = this._patientDetail.email;
    }
    const ignoreArray = [NaN, null, undefined, ''];
    if (ignoreArray.indexOf(this._patientDetail.month) > -1
      && ignoreArray.indexOf(this._patientDetail.day) > -1
      && ignoreArray.indexOf(this._patientDetail.year) > -1) {
      value.dob = '';
    } else {
      value.dob = this._patientDetail.year + '-' + this._patientDetail.month + '-' + this._patientDetail.day;
    }

    if (this._patientDetail.first || this._patientDetail || this._patientDetail) {
      const first = this._patientDetail.first
        ? '(' + this._patientDetail.first.replace(/\(|\)/g, '') + ') ' : '';
      const phone_mobile = first + this._patientDetail.middle + '-' + this._patientDetail.last;
      if (phone_mobile === '-') {
        value.phone_mobile = '';
      } else {
        value.phone_mobile = phone_mobile;
      }
    }

    if (this.alt_phonemobile.first || this.alt_phonemobile.middle || this.alt_phonemobile.last) {
      const first = this.alt_phonemobile.first
        ? '(' + this.alt_phonemobile.first.replace(/\(|\)/g, '') + ') ' : '';
      const alt_phone_mobile = first + this.alt_phonemobile.middle + '-' + this.alt_phonemobile.last;
      if (alt_phone_mobile === '-') {
        value.alt_phone_mobile = '';
      } else {
        value.alt_phone_mobile = alt_phone_mobile;
      }
    }

    if (this.updateAccountSubscriber) {
      this.updateAccountSubscriber.unsubscribe();
    }
    this.loadingIndicatorService.start();
    this.updateAccountSubscriber = this._editService.updateAccount(value).subscribe(
      (success: any) => {
        this.loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          localStorage.setItem('userFullName', this._utils.generateFullName(value.first_name, '', value.last_name));
          this.successMessageAccount = success.error_messages;
          this.statusPhoneVerify = success.phone_verified;
          this.statusAltPhoneVerify = success.alt_phone_verified;
          //mobile
          if (typeof value.phone_mobile === 'undefined' || value.phone_mobile === '') {
            this.showStatusPhoneVerify = false;
          } else {
            this.showStatusPhoneVerify = true;
          }
          //alt mobile
          if (typeof value.alt_phone_mobile === 'undefined' || value.alt_phone_mobile === '') {
            this.showStatusAltPhoneVerify = false;
          } else {
            this.showStatusAltPhoneVerify = true;
          }

        }
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
        const response = error.error;
        if (response.error_code === 'RC020') {
          this.error = response.error_fields;
        } else if (response.error_code === 'RC107') {
          this.openErrorMessage(response.error_messages);
        }
      },
      () => {
        this.loadingIndicatorService.stop();
      }
    );
  }

  updatePassword(formVals: any) {
    this.error.password = '';
    this.error.password_confirmation = '';
    this.error.password_current = '';
    this.successMessagePassword = null;
    const value = Object.assign({}, formVals);
    value.password_current = value.password_current === '' ? null : value.password_current;
    value.password = value.password === '' ? null : value.password;
    value.password_confirmation = value.password_confirmation === '' ? null : value.password_confirmation;
    if (value.password_current !== null || value.password !== null || value.password_confirmation !== null) {
      this.loadingIndicatorService.start();
      if (this.updatePasswordSubscriber) {
        this.updatePasswordSubscriber.unsubscribe();
      }
      this.updatePasswordSubscriber = this._editService.updatePassword(value).subscribe(
        (success: any) => {
          this.loadingIndicatorService.stop();
          if (success.error_code === 'RC000') {
            jQuery('#password').val('');
            jQuery('#password_confirmation').val('');
            jQuery('#password_current').val('');


            this.successMessagePassword = success.error_messages;
          }
        },
        (error: any) => {
          this.loadingIndicatorService.stop();
          const response = error.error;
          if (response.error_code === 'RC020' || response.error_code === 'RC066' || response.error_code === 'RC010'  ) {
            this.error = response.error_fields;
          }
        },
        () => {
          this.loadingIndicatorService.stop();
        }
      );
    }
  }

  updateAddress(formVals: any) {
    this.loadingIndicatorService.start();
    this.successMessageAddress = null;
    const value = Object.assign({}, formVals);
    value.state_code = jQuery('#state').val();
    if (this.updateAddressSubscriber) {
      this.updateAddressSubscriber.unsubscribe();
    }
    this.updateAddressSubscriber = this._editService.updateAddress(value).subscribe(
      (success: any) => {
        this.loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          this.successMessageAddress = success.error_messages;
        }
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
        const response = error.error;
        if (response.error_code === 'RC020') {
          this.error = response.error_fields;
        }
      },
      () => {
        this.loadingIndicatorService.stop();
      }
    );
  }

  openErrorMessage(msg: string) {
    this._alertService.onClose = () => {
      // location.reload();
    };
    this._alertService.showDismissButton = false;
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  onChangeEmail() {
    jQuery('#confirm_email').removeClass('hidden');
    this.changeEmail = true;
    this.error.email = null;
  }

  onChangeContactEmail() {
    this.error.contact_email = null;
  }

  clearError() {
    this.error = new PatientDetail();
  }

  showEditPhoneConfirmDirective() {
    this.editPhoneConfirmDirective.resetVerifyStep();
    this.editPhoneConfirmDirective.phone_mobile_tmp = this._patientDetail.phone_mobile_tmp;
    this.editPhoneConfirmDirective.open();
  }

  onChangeStatusPhoneVerify(e: any) {
    this.statusPhoneVerify = e;
  }

  onChangePhoneNumber(e: any) {
    this._patientDetail.phone_mobile = e;
  }

  onChangePhoneMobileVerified(e: any) {
    this._patientDetail.phone_mobile_verified = e;
  }

  clearErrorDob() {
    this.error.dob = null;
  }

  onKeyUp(event: any, error: string) {
    this.successMessageAccount = null;
    this.successMessagePassword = null;
    this.successMessageAddress = null;

    switch (error) {
      case 'first_name':
        this.error.first_name = null;
        break;

      case 'last_name':
        this.error.last_name = null;
        break;

      case 'phone_mobile':
        this.error.phone_mobile = null;
        this.showStatusPhoneVerify = false;
        break;

      case 'password':
        this.error.password = null;
        break;

      case 'password_confirmation':
        this.error.password_confirmation = null;
        break;

      case 'zip':
        this.error.zip = null;
        break;

      case 'ssn':
        this.error.ssn = null;
        break;

      case 'password_current':
        this.error.password_current = null;
        break;

      case 'email':
        this.error.email = null;
        break;

      case 'email_confirmation':
        this.error.email_confirmation = null;
        break;
      case 'address':
          this.error.address = null;
          break;  
      case 'city':
          this.error.city = null;
          break;
      case 'state_code':
        this.error.state_code = null;
        break;  
      case 'alt_phonemobile':
        this.error.alt_phonemobile = null;
        this.showStatusAltPhoneVerify = false;
        break;    
      case 'contact_email':
        this.error.contact_email = null;
        break;  
    }
  }

  Openverifyphonenomodal(type: any) { 
        
    this._editService.isverifiedphoneno({'type':type}).subscribe((success: any) =>  {     
        if(success.isverified == 0 && success.phone_mobile !='' && success.phone_mobile != undefined){ console.log(success);
          this.verifyPhonenoDirective.open(success); 
        }else if(success.isaltverified == 0 && success.alt_phone_mobile !='' && success.alt_phone_mobile != undefined){ console.log(success);
          this.verifyPhonenoDirective.open(success); 
        }
    }); 
  }

}
