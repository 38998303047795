import { Component, OnInit, OnDestroy } from '@angular/core';
import { WaittimeMeterService } from '@app/waittime-meter/waittime-meter.service';
import { AppointmentDetail } from '../wait-time/model/appointment-detail';
import { SystemInterval, SystemData, SystemEvent, SystemService } from '../shared/system';
import { Utils } from '@app/shared/utils';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
//import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AlertService} from './../shared/alert';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

declare let jQuery: any;

@Component({
  selector: 'app-waittime-meter',
  templateUrl: './waittime-meter.component.html',
  styleUrls: ['./waittime-meter.component.scss'],
  //providers: [NgbRatingConfig]
})
export class WaittimeMeterComponent implements OnInit, OnDestroy {

  waitTimeData: any;
  waitTimeMeterStyle: any;
  appointmentId: any = '';
  appointmentDetail: AppointmentDetail = new AppointmentDetail();
  fetchWaitTimeSubscriber: Subscription;
  isFirefox = false;
  waitTimeInterval: any;
  waitTimeIntervalId: any;
  startTrigger = false;
  errorMessage: string = '';

  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  currentRate = 0;
  review_text:any = '';
  responseError: any = { 
    review : null
  };
  reviewDetails : any={
    rating : 0,
    review : ''
  };
  iswmlgid : any = '';

  constructor(
    private Service: WaittimeMeterService,
    public _systemData: SystemData,
    private _systemInterval: SystemInterval,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    public _utils: Utils,
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    //private config: NgbRatingConfig,
    private _alertService : AlertService

  ) {

    this.waitTimeMeterStyle = new Object();

    //this.config.max = 5;
  }
  
  countStar(star : number, popover : NgbPopover) {
    this.selectedValue = star;
    popover.isOpen() ? popover.close() : popover.open();
  }

  addClass(star : number) {
    //console.log("star", star); 
    //console.log("selectedvalue", this.selectedValue);
    let ab = "";
    for (let i = 0; i < star; i++) {
      console.log("star i", star);
      ab = "starId" + i;
      document.getElementById(ab).classList.add("selected");
    }
  }
  removeClass(star : number) {
    //console.log("removestar", star);
    let ab = "";
    for (let i = star-1; i >= this.selectedValue; i--) {
      //console.log("star i", star);
      ab = "starId" + i;
      document.getElementById(ab).classList.remove("selected");
    }
  }

  ngOnInit() {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (is_firefox) {
      this.isFirefox = true;
    }

    const currentUrl = this._router.url;
   // const splitArr = currentUrl.split("/");
    const splitArrindex = currentUrl.split("/waittime");
     const splitArr = splitArrindex[1].split('/');
//console.log(sparr);
   // const code = splitArr[splitArr.length - 1];
    const code = splitArr[1];
    const wmlogin = splitArr[2];

    if (code == null || code == undefined) {
      this.errorMessage = "Invalid request";
    } else {
      this.appointmentId = code;
    }
//for waiting meter login parameter
    if (wmlogin != null || wmlogin != undefined) {
      //this.errorMessage = "Invalid request";
      this.iswmlgid = wmlogin;
    } 

    if (this.appointmentId != '') {
      this.fetchAppointmentDetails();
    }
  }
  ngOnDestroy() {
    clearInterval(this.waitTimeIntervalId);
    if (this.fetchWaitTimeSubscriber) {
      this.fetchWaitTimeSubscriber.unsubscribe();
    }
  }
  calWaitTimeMeterDeg(xdeg: number) {
    let deg = xdeg ? xdeg : 0;
    if (deg < 0) {
      deg = 0;
    } else if (deg > 120) {
      deg = 123;
    }
    const _rotate = 'rotate(' + (deg * 1.5) + 'deg)';

    this.waitTimeMeterStyle = {
      '-ms-transform': _rotate,
      '-webkit-transform': _rotate,
      'transform': _rotate
    };
  }
  fetchAppointmentDetails() {
    var self = this; 
    this.Service.fetchAppointmentDetails(self.appointmentId,self.iswmlgid).subscribe((success: any) => {
      if (success.error_code === 'RC000') {
        self.appointmentDetail = success.appointments;
        if (success.reviewdetails != null) {
          self.reviewDetails = {
            rating : success.reviewdetails.rating,
            review : success.reviewdetails.review
          };
        }
        
        if (success.appointments != null) {
          self.startWaitTimeInterval();
        } else {
          this.errorMessage = "Invalid request";
        }
      }
    });
  }
  showWaitTime() {
    var self = this;
    this.Service.fetchWaitTimeByAppointment(self.appointmentId).subscribe((success: any) => {
      if (success.error_code === 'RC000') {
        self.waitTimeData = success.appointments;
        self.displayWaitTime(self.appointmentId);
      }
    });
  }

  displayWaitTime(id: number) {
    
    let dataNotFound = true;
    const now = new Date();
    let hour = now.getHours().toString();
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    let minute = now.getMinutes().toString();
    if (minute.length === 1) {
      minute = '0' + minute;
    }
    const currentTime = hour + ':' + minute;
    /*if (!id) {
      id = this.appointmentDetail.id;
    }*/
    this.appointmentDetail.currentTime = currentTime;
    this.appointmentDetail.waitingTime = null;

    // console.log(currentTime);
    if (this.waitTimeData.length > 0) {
      for (let i = 0; i < this.waitTimeData.length; i++) {
          const data = this.waitTimeData[i];
          if (data.id === id) {
            this.calWaitTimeMeterDeg(data.time);
            if (data.time > 999) {
              data.time = 999;
            }
            this.appointmentDetail.waitingTime = data.time;
            this.appointmentDetail.is_active = data.is_active;
            if (this.appointmentDetail.waitingTime == null) {
              this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
            } else {
              this.appointmentDetail.notice = data.message;
            }

            if (data.message.match('opt in')) {
              this.appointmentDetail.hasLink = true;
            }
            dataNotFound = false;    
            break;
          }       
      }

    } else {
      this.calWaitTimeMeterDeg(0);
      this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
      this.appointmentDetail.waitingTime = null;
    }
    // console.log(dataNotFound);
    if (dataNotFound) {
      this.calWaitTimeMeterDeg(0);
      this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
      this.appointmentDetail.waitingTime = null;
    }
    //console.log(this.appointmentDetail);
  }
  startWaitTimeInterval() {
    this.stopWaitTimeInterval();
    var self = this;
    const getWaitTime = () => {
      this.Service.fetchWaitTimeByAppointment(this.appointmentId).subscribe((success: any) => {
        if (success.error_code === 'RC000') {
          self.waitTimeData = success.appointments;
          self.displayWaitTime(self.appointmentId);
        }
      });
    };

    this.waitTimeInterval = setInterval(getWaitTime, this._systemInterval.appointment_fetchWaitTimePatient.duration);
    getWaitTime();
  }

  stopWaitTimeInterval() {
    if (this.waitTimeInterval) {
      clearInterval(this.waitTimeInterval);
      this.waitTimeInterval = null;
    }
  }
  savescheduleratings() {
    var self = this;

    if (self.review_text == '') {
      self.responseError['review'] = 'Enter your comment';
      return false;
    }

    let inputdata = {
      'schedule_id' : self.appointmentId,
      'rating' : self.selectedValue, 
      'review' : self.review_text
    };

    self.Service.savescheduleratings(inputdata).subscribe((response: any) => {
      if (response.error_code === 'RC000') {

        self.reviewDetails = {
          rating : self.selectedValue,
          review : self.review_text
        };

        self.selectedValue = 0;
        self.review_text = '';

        self._alertService.onClose = () => {
          // TODO
        };
        self._alertService.showDismissButton = false;
        self._alertService.dismissButtonLabel = 'No';
        self._alertService.showCloseButton = true;
        self._alertService.closeButtonLabel = 'OK';
        self._alertService.title = response.error_messages;
        self._alertService.message = '';
        self._alertService.emitter('open');

      } else {
        self.selectedValue = 0;
        self.review_text = '';

        self._alertService.onClose = () => {
          // TODO
        };
        self._alertService.showDismissButton = false;
        self._alertService.dismissButtonLabel = 'No';
        self._alertService.showCloseButton = true;
        self._alertService.closeButtonLabel = 'OK';
        self._alertService.title = response.error_messages;
        self._alertService.message = '';
        self._alertService.emitter('open');
      }
    });
  }
  onKeyUp(error: string) {
    switch (error) {
        case 'review':
            this.responseError['review'] = null;
            break;            
    }
  }
}