import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MODAL_DIRECTIVES, ModalComponent } from '@app/shared/ng2-bs3-modal';
import { RtnCalendarComponent } from '@app/shared/calendar/calendar.component';
import { AlertService } from '@app/shared/alert/alert.service';
// import { ModalsService } from '../service/modals.service';
import { Subscription } from 'rxjs';
import { SystemEvent } from '@app/shared/system';
import { AppointmentDetail } from '@app/shared/models/appointment-detail';
import { Utils } from '@app/shared/utils';
import { RestService } from '@app/core/rest.service';

import * as moment from 'moment';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'rtn-patient-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RescheduleComponent implements OnDestroy, AfterViewInit {
  ngDatePicker: any;
  _weekStart = 0;
  _startDate: any = moment().format('MM-DD-YYYY');

  availableSlots: any;
  newAppointment: any = null;
  selectedDate: string = moment().add(1, 'day').format('MMMM DD, YYYY');
  errorMessage: string;
  dateSelected: any;

  getAvailableSlotsSubscriber: Subscription;
  bookAppointmentSubscriber: Subscription;

  appointmentDetail: AppointmentDetail = new AppointmentDetail();
  @ViewChild('Reschedule_Modals') modal: ModalComponent;
  @ViewChild(RtnCalendarComponent) RtnCalendarDirective: RtnCalendarComponent;
  @ViewChild('inputDateRescedule') inputDateRescedule: ElementRef;
  @ViewChild('clickShowDateRescedule') clickShowDateRescedule: ElementRef;

  constructor(
    public _utils: Utils,
    private Service: AppointmentsService,
    private _alertService: AlertService,
    private _elementRef: ElementRef,
    private _systemEvent: SystemEvent,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.ngDatePicker = new Object();
    this.ngDatePicker['todayHighlight'] = true;
    this.ngDatePicker['weekStart'] = this._weekStart;
    this.ngDatePicker['startDate'] = this._startDate;
    this.ngDatePicker['format'] = 'MM dd, yyyy';
  }

  ngAfterViewInit() {
    jQuery(this.inputDateRescedule.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: jQuery(window).width() < 768 ? 1 : 2,
      minDate: 0,
      showButtonPanel: true,
      onSelect: () => {
        this.newAppointment = null;
        this.getAvailableSlots(jQuery(this.inputDateRescedule.nativeElement).val());
      }
    });
    jQuery(this.clickShowDateRescedule.nativeElement).click(() => {
      jQuery(this.inputDateRescedule.nativeElement).datepicker('show');
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
    if (this.getAvailableSlotsSubscriber) {
      this.getAvailableSlotsSubscriber.unsubscribe();
    }
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }
  }

  open(appointmentDetail: any) {
    this.modal.open();
    this.appointmentDetail = appointmentDetail;
    this.getAvailableSlots(this.selectedDate);
  }

  close() {
    this.newAppointment = null;
    this.modal.close();
    jQuery(this.inputDateRescedule.nativeElement).datepicker('setDate', '+1');
  }

  getTime(time: any) {
    return moment(time).format('hh:mm A');
  }

  // getTime(time: any) {
  //     let formatDate = parseInt(moment(time).format('x'));
  //     return formatDate;
  // }

  /*changeDate(date: string) {
      console.log(date);
  }*/

  getAvailableSlots(date: string) {
    let location_id = this.appointmentDetail.location_id;

    this.getAvailableSlotsSubscriber = this.Service.patientGetAvailableSlots(
      this.appointmentDetail.id,
      moment(date, 'MMMM DD, YYYY').format('YYYY-MM-DD'),
      location_id
    ).subscribe(
      (success: any) => {
        this.availableSlots = success.slots;
      }, (error: any) => {
        const e = error.error;
        this.openAlert('close', e.error_messages);
      });
  }

  getAppointmentClicked(slot: any) {
    this.newAppointment = slot;
    for (let i = 0; i < this.availableSlots.length; i++) {
      const s = this.availableSlots[i];
      s.isSelected = s === slot ? true : false;
    }
  }

  bookAppointmentClicked() {
    const newAppointment = {
      slot_id: this.newAppointment.id,
      schedule_id: this.appointmentDetail.id,
      start_time: moment(this.newAppointment.start_time.date).utc().format(),
      end_time: moment(this.newAppointment.end_time.date).utc().format()
    };
    if (newAppointment.start_time) {
      this.unsubscribe();
      this._loadingIndicatorService.start();
      this.bookAppointmentSubscriber = this.Service.patientUpdateSchedule(newAppointment).subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          if (success.error_code === 'RC000') {
            this.openAlert('close', success.error_messages);
          } else {
            this.openAlert(null, success.error_messages);
          }
        }, (error: any) => {
          const e = error.error;
          // this.openAlert(null, e.error_messages);
        });
      this.newAppointment = null;
    }
  }

  openAlert(key: string, msg: string) {
    this._alertService.onClose = () => {
      if (key === 'close') {
        this.close();
        this._systemEvent.event.emit('event_callSearchAppointment');
      }
    };
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
}
