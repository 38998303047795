import {  
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
// import {AppointmentBookService} from '@app/appointments/modals/appointment-book/appointment-book.service';
import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
import { Event } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { from, Subscription } from 'rxjs';

import { ProvidersService } from '@app/providers/providers.service';

@Component({
  moduleId: module.id,
  selector: 'rtn-modals-app-add-apttypes',
  templateUrl: './add-apttypes.component.html',
  styleUrls: ['./add-apttypes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddApttypesComponent implements OnDestroy {
  fetchAptTypeSubscriber: Subscription;
  
  @ViewChild('Add_Appttype_Modals') modal: ModalComponent;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();

  successMessage: string;
  errorMessage: string;
  aptTypeObject: AptTypeClass = new AptTypeClass();
  errorResponse: ErrorAptTypeClass = new ErrorAptTypeClass();
  isSaved: boolean = false;

  constructor(
    private providerService: ProvidersService,
    private _alertService: AlertService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    
    if (this.fetchAptTypeSubscriber) {
        this.fetchAptTypeSubscriber.unsubscribe();
    }    
  }
  open(items:any) {

    if (items == 0) {
      this.modal.open();
      this.setInitial();
    } else {
      this.modal.open();
      this.setInitial();
      this.aptTypeObject.id = items.id;
      this.aptTypeObject.practice_id = items.practice_id;
      this.aptTypeObject.code = items.code;
      this.aptTypeObject.label = items.label;
      this.aptTypeObject.is_allowed = (items.is_allowed == '1') ? false : true; 
    }    
  }  
  close() {    
    this.setInitial();
    this.modal.close();
    this.successMessage = null;
    this.errorMessage = null;
    if (this.isSaved) {
      window.location.reload();
    }
  }
  setInitial(){
    this.aptTypeObject = new AptTypeClass();
    this.clearMsgError();
    this.aptTypeObject.id = null;
    this.aptTypeObject.code = '';
    this.aptTypeObject.label = '';
    this.aptTypeObject.is_allowed = false; 
  }
  clearMsgError() {
    this.errorResponse.code = '';
    this.errorResponse.label = '';
    this.errorResponse.is_allowed = '';    
  }  
  onChange(event: any) {
    switch (event) {
        case 'code':
            this.errorResponse.code = '';
            break;
        case 'label':
            this.errorResponse.label = '';
            break;
    }
}
  processSave() {
    this.clearMsgError();
    this._loadingIndicatorService.start();
    let formobj = this.aptTypeObject;

    let practiceId = localStorage.getItem('user_practice');
    formobj.practice_id = practiceId;

    this.fetchAptTypeSubscriber = this.providerService.addAptType(formobj).subscribe(
      (success: any) => {    
        this.isSaved = true;       
        this._loadingIndicatorService.stop();
        const res = success;
        if (res.error_code === 'RC000') {
            this.successMessage = res.error_messages;   
            let items = res.updateddata;
            this.setInitial(); // Reset the value    
            
            this.aptTypeObject.id = items.id;
            this.aptTypeObject.code = items.code;
            this.aptTypeObject.label = items.label;
            this.aptTypeObject.is_allowed = (items.is_allowed == '1') ? false : true; 
            this.aptTypeObject.practice_id = items.practice_id;
        }
      }, (error: any) => {
            this._loadingIndicatorService.stop();
            const response = error.error;
            if (response.error_code === 'RC020') {
              this.showMsgError(response.error_fields);
            } else {
              this.errorMessage = response.error_messages;
            }
        }, () => {
            // this.callDataSearhOpeningBack();
        });  

  }
  showMsgError(response:any){
    if (response.code != null) {
        this.errorResponse.code = response.code;
    }
    if (response.label != null) {
      this.errorResponse.code = response.label;
    }    
  }
}

export class AptTypeClass {
  id: number;
  practice_id: any;
  code: string;
  label: string;
  is_allowed: boolean;  
}

export class ErrorAptTypeClass {  
  code: string;
  label: string;
  is_allowed: string;  
}
