<div class="container active-module">
  <h3 class="row d-none d-md-block">Practice Modules</h3>
  <h3 class="row d-block d-sm-none d-none d-sm-block d-md-none text-center">Practice Modules</h3>
  <rtn-table #table
             (onDataChange)="onDataChange($event)"
             [autoloading]="false"
             [data]="_systemData.moduleList.data"
             [rowsOnPage]="_systemData.moduleList.rowsOnPage"
             [numberOfPages]="_systemData.moduleList.numberOfPages">
    <div class="col-md-12 no-padding-left-right search-box listview">
      <table class="table table-bordered">
        <thead class="account-setting-thead">
        <tr>
          <!-- <th class="account-setting-thead-text col-active">NO</th> -->
          <th class="account-setting-thead-text col-system-modules">Practice Modules</th>
          <th class="account-setting-thead-text col-active">Active</th>
        </tr>
        </thead>
        <tbody class="account-setting-tbody">
          <tr *ngFor="let module of table.data; let i = index">
            <!-- <td class="account-module">{{i+1}}</td> -->
            <td class="account-module">{{ module.name }}</td>
            <td>
              <label class="switch">
                <input type="checkbox" class="form-check-input Checkbox {{module.id}}" *ngIf="module.isActivated" checked (change)="onCheckbox(module.name,module.id,false)">
                <input type="checkbox" class="form-check-input Checkbox {{module.id}}" *ngIf="!module.isActivated" (change)="onCheckbox(module.name,module.id,true)">
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </rtn-table>
</div>
<app-active-module-modal (reload)="activeModuleDirective.reload"></app-active-module-modal>
