import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { SystemService } from '../../system';
import { SimpleModalsService } from '../..//modals/simple-modals/simple-modals.service';
import { SimpleModalsComponent } from '../..//modals/simple-modals/simple-modals.component';


@Component({
    moduleId: module.id,
    template: `<rtn-simple-modals #simpleModals class="logout-modal"></rtn-simple-modals>`,
    selector: 'rtn-modals-general-logout',
})

export class LogoutModalsComponent {
    @Output()
    reload: EventEmitter<any> = new EventEmitter();

    @ViewChild('simpleModals') modal: SimpleModalsComponent;
    errorMessage: string;

    constructor(
        private _systemService: SystemService
        , private _simpleModals: SimpleModalsService
    ) {
        //TODO
    }

    // ========== Specific Modal Method ========== //

    open() {
        this._simpleModals.onClose = () => {
            this._systemService.logOut();
        };
        this._simpleModals.showDismissButton = true;
        this._simpleModals.showCloseButton = true;
        this._simpleModals.showFnButton = false;
        this._simpleModals.closeButtonLabel = 'Yes';
        this._simpleModals.dismissButtonLabel = 'No';
        this._simpleModals.backdrop = 'false';
        this._simpleModals.title = 'Are you sure you want to log out?';
        this._simpleModals.emitter('open');
    }
}
