import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivatephoneService {

  postVerifyPhoneURL = environment.serverUrl + '/patients/verifyphonefromlink';
  
  constructor(private _http: HttpClient) { }  
  verifyPhoneFromURL(data: any) {
    return this._http.post(this.postVerifyPhoneURL, JSON.stringify(data));
  }
}