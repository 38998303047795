import {Component, OnInit, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {RtnConfig} from '@app/shared/rtnconfig';
import {RestService} from '@app/core/rest.service';
import {RtnAuth} from '@app/shared/rtn-auth';
import {SystemData, SystemService, SystemInterval} from '@app/shared/system';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
// import { NotificationLog_ModalsComponent } from '@app/shared/modals/provider/notification-log';
// import { NotificationMass_ModalsComponent } from '@app/shared/modals/provider/notification-mass';
// import { PatientNewSignUp_ModalsComponent } from '@app/shared/modals/provider/patient-new-sign-up';
// import { Logout_ModalsComponent } from '@app/shared/modals/general/logout';
import {Utils} from '@app/shared/utils';
import {AlertService} from '@app/shared/alert';
import {NotificationMassComponent} from '@app/notifications/notification-mass/notification-mass.component';
import {PatientNewSignUpComponent} from '@app/providers/patient-new-sign-up/patient-new-sign-up.component';
import {Logout_ModalsComponent} from '@app/shared/general/logout';
import {DocumentsService} from '@app/documents/documents.service';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { Subscription } from 'rxjs';
import { NotificationMassPatientComponent } from '@app/notifications/notification-mass-patient/notification-mass-patient.component';

@Component({
  selector: 'rtn-documents-header',
  moduleId: module.id,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(PatientNewSignUpComponent) patientNewSignUpDirective: PatientNewSignUpComponent;
  mode: boolean;
  change = 0;
  isProvider = true;
  isSuperUser = true;
  fullName = '';
  isManagingMode = false;
  extractData: any;
  module: any;
  getIsLockSubscriber: Subscription;
  isLock:any;
  // @ViewChild(NotificationLog_ModalsComponent) notificationLogDirective: NotificationLog_ModalsComponent;
  @ViewChild(NotificationMassComponent) notificationMassDirective: NotificationMassComponent;
  @ViewChild(NotificationMassPatientComponent) notificationMassPatientDirective: NotificationMassPatientComponent;
  // @ViewChild(PatientNewSignUp_ModalsComponent) patientNewSignUpDirective: PatientNewSignUp_ModalsComponent;
  @ViewChild(Logout_ModalsComponent) logoutDirective: Logout_ModalsComponent;

  constructor(
    private Service: AppointmentsService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _restService: DocumentsService,
    public _systemService: SystemService,
    public _systemData: SystemData,
    private _systemInterval: SystemInterval,
    public _router: Router,
    private _auth: RtnAuth,
    private _utils: Utils,
    private _alertService: AlertService
  ) {
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    this._systemService.checkOnlineStatus();
    this._systemInterval.header_fetchOnlineMode.interval = setInterval(() => {
      if (this.change === 0) {
        this.mode = this._systemData.isOnline;
        this.change++;
      }
      this._systemService.checkOnlineStatus();
      setTimeout(() => {
        this._systemService.checkOnlineStatus();
        if (this.mode !== this._systemData.isOnline) {
          this._alertService.onClose = () => {
            location.reload();
          };
          this._alertService.showDismissButton = false;
          this._alertService.dismissButtonLabel = 'Close';
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'OK';
          if (this._systemData.isOnline) {
            this._alertService.message = '';
            this._alertService.title = 'RTN system changes to work in Online mode';
          } else {
            this._alertService.message = '';
            this._alertService.title = 'RTN system changes to work in Offline mode';
          }
          this._alertService.emitter('open');
          this.mode = !this.mode;
        }
      }, 100);
    }, this._systemInterval.header_fetchOnlineMode.duration);

    this.isProvider = this._utils.isProvider() ? true : false;
    this.isSuperUser = this._utils.isSuperUser() ? true : false;
    const storeData = localStorage.getItem('storeData');
    if (this.isProvider && storeData) {
      this.extractData = JSON.parse(localStorage.getItem('storeData'));
      this.isManagingMode = true;
    }
    this.getLockProvider();
  }
  getLockProvider(){
    this._loadingIndicatorService.start();
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this.isLock = success.providers.is_lock;
        this._loadingIndicatorService.stop();

      }
    );
  }
  ngAfterViewInit() {
    // TODO
  }

  ngOnDestroy() {
    this._systemInterval.clearSystemInterval(this._systemInterval.header_fetchOnlineMode);
  }

  logoutManaging() {
    this._alertService.onClose = () => {
      const storeData = localStorage.getItem('storeData');
      if (this._utils.isProvider() && storeData) {
        const extractData = JSON.parse(localStorage.getItem('storeData'));
        localStorage.setItem('user_token', extractData.user_token);
        localStorage.setItem('id_token', extractData.id_token);
        localStorage.setItem('last_active_token', extractData.last_active_token);
        localStorage.setItem('user_id', extractData.user_id);
        localStorage.setItem('user_role_id', extractData.user_role_id);
        localStorage.setItem('account_name', extractData.account_name);
        localStorage.setItem('user_status', extractData.user_status);
        localStorage.setItem('user_practice', extractData.user_practice);
        localStorage.setItem('loginEmail', extractData.loginEmail);
        localStorage.setItem('userFullName', extractData.userFullName);
        localStorage.removeItem('storeData');
        if (parseInt(extractData.user_role_id, 10) === 5) {
          this._router.navigate(['/provider', 'practice']);
        } else if (parseInt(extractData.user_role_id, 10) === 6) {
          this._router.navigate(['/provider', 'list']);
        }
      }
    };
    this._alertService.showDismissButton = true;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'Yes';
    this._alertService.backdrop = 'false';
    this._alertService.title = 'Are you sure you want to log out?';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  notificationMass() {
    this.notificationMassDirective.open();
  }
  notificationMassPatient() {
    this.notificationMassPatientDirective.open();
  }
  changePassword(){
    this._router.navigate(['provider/apt/', 'change-password']);
  }
  onReload(value: any){
    switch (value) {
      case 'Appointment':
        this._router.navigate(['/provider', 'appointment']).then(() => {
          window.location.reload();
        });
        break;
      case 'Document':
        this._router.navigate(['/provider', 'document']).then(() => {
          window.location.reload();
        });
        break;
      case 'Patient':
        this._router.navigate(['/provider', 'patient']).then(() => {
          window.location.reload();
        });
        break;
      case 'wait-list':
        this._router.navigate(['/provider', 'wait-list']).then(() => {
          window.location.reload();
        });
        break;
      case 'statistical-appointment-by-types':
        this._router.navigate(['/provider', 'statistical-appointment-by-types']).then(() => {
          window.location.reload();
        });
        break;  
      default:
        break;
    }
  }
}
