import { Component, OnInit, ViewChild, AfterViewInit, ElementRef,ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

import { DataLogsService } from '@app/data-logs/data-logs.service';
import * as moment from 'moment';
import { AlertService } from '@app/shared/alert';
import { Select2Provider3Directive } from '@app/shared/form/select2-provider3.directive';
import { AdminRestService } from '@app/admin/admin.service';
import { Utils } from '@app/shared/utils';
//import { ChartData } from '@app/shared/models/chartData';
declare let jQuery: any;

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";

import { ActivatedRoute } from '@angular/router';
import { Select2AptlocationDirective } from '@app/shared/form/select2-aptlocation.directive';

@Component({
  selector: 'app-schedule-activity',
  templateUrl: './schedule-activity.component.html',
  styleUrls: ['./schedule-activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleActivityComponent implements OnInit, AfterViewInit {

    currentDate: any = moment();
    practiceId: string = '0';
    providerId = '0';
    getListPracticeSubscriber: Subscription;
    getListProviderSubscriber: Subscription;
    getOfficeLocationSubscriber: Subscription;
    dataSearchChart: dataStatistic = new dataStatistic();
    errorResponse: dataStatistic = new dataStatistic();
    practiceTypeSubscriber: Subscription;
    providerTypeSubscriber: Subscription;
    public practicesList: any = [];
    public providersList: any = [];
    data_chart: any;
    maxDate:any;

    public chartdata:any = [];
    private chartWidth: number;
    private chartHeight: number;
    private chartMargin = { top: 20, right: 40, bottom: 20, left: 60 };
    private chartX: any;
    private chartY: any;
    private chartSvg: any;
    private chartG: any;

    public curpage : number = 1;
    public noofdata : number = 0;
    public noofpage : number = 0;
    public noofpageArr:any = [];
    public isDataAvailable = false;

    @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
    //@ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
    @ViewChild('Select2Provider3Directive') ProviderSelector: Select2Provider3Directive;
    @ViewChild('inputDateFrom') inputPractice: ElementRef;
    @ViewChild('inputDateFrom') inputProvider: ElementRef;
    @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
    @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
    @ViewChild('inputDateTo') inputDateTo: ElementRef;
    @ViewChild('clickDateTo') clickDateTo: ElementRef;
    @ViewChild('Select2AptlocationDirective') locationSelector: Select2AptlocationDirective;

    firstTabs = false;
    
    private chartdata1:any = [];
    public curpage1 : number = 1;
    public noofdata1 : number = 0;
    public noofpage1 : number = 0;
    public noofpageArr1:any = [];
    locationList:any;

    constructor(
        private _alertService: AlertService,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _adminService: AdminRestService,
        private _datalogsService: DataLogsService,
        private _utils: Utils,
        private activeRoute: ActivatedRoute
    ) {
        this.dataSearchChart.dateFrom = moment().subtract(1, "days").format('LL');
        this.dataSearchChart.dateTo = moment().subtract(1, "days").format('LL');

        let width = 1400;
        width = (window.innerWidth > width) ? (window.innerWidth - 600) : (window.innerWidth - 400);
        this.chartWidth = width - this.chartMargin.left - this.chartMargin.right;
        this.chartHeight= 400 - this.chartMargin.top - this.chartMargin.bottom;
    }

    removeMsgErr() {
        this.errorResponse.provider = null;
        this.ProviderSelector.inputError = false;
        this.ProviderSelector.changeBorderColorProvider();
    }

    onChangePractice() {
        this.ProviderSelector.clearData();
        this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
        this.removeMsgErr();
        const practice = this.PracticeSelector.getData();
        if (practice.length !== 0) {
            this.getProviderByPractice(practice);
            if (this.providersList.length > 0) {
                this.ProviderSelector.updateData(this.providersList);

            }
        } else {
            this.ProviderSelector.clearData();
        }
    }

    ngOnInit() {
        this.PracticeSelector.clearData();
        this.ProviderSelector.clearData();
        this.locationSelector.clearData();

        this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
        this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
        this.locationSelector.resetSelect2(jQuery('#select2aptlocation'));

        const _self = this;
        _self._loadingIndicatorService.start();
        _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
            (success: any) => {
                _self.practicesList = success.practices;
                const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
                _self.getProviderByPractice(firstPractice);
                this._loadingIndicatorService.stop();
            });
    }

    ngAfterViewInit(): void {
        const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
        const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
        const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
        if (now >= zero_oclock && now < startCurrentTime) {
            this.maxDate = moment().subtract(2, 'days').format('LL');
            this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
            this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
        }else{
            this.maxDate = moment().subtract(1, 'days').format('LL');
            this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
            this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
        }
        const _self = this;
        jQuery(_self.inputDateFrom.nativeElement).datepicker({
            dateFormat: 'MM dd, yy',
            numberOfMonths: 2,
            showButtonPanel: true,
            maxDate: moment(this.maxDate).toDate(),
            changeMonth: true,
            changeYear: true,
            onSelect: (selected: any) => {
                _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
            }
        });
        jQuery(_self.inputDateFrom.nativeElement).datepicker("setDate", this.dataSearchChart.dateFrom);
        jQuery(_self.clickDateFrom.nativeElement).click(() => {
            jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
        });

        jQuery(_self.inputDateTo.nativeElement).datepicker({
            dateFormat: 'MM dd, yy',
            numberOfMonths: 2,
            showButtonPanel: true,
            maxDate: moment(this.maxDate).toDate(),
            changeMonth: true,
            changeYear: true,
            onSelect: (selected: any) => {
                _self.dataSearchChart.dateTo = jQuery(_self.inputDateTo.nativeElement).val();
            }
        });
        jQuery(_self.inputDateTo.nativeElement).datepicker("setDate", this.dataSearchChart.dateTo);
        jQuery(this.clickDateTo.nativeElement).click(() => {
            jQuery(this.inputDateTo.nativeElement).datepicker('show');
        });

        jQuery("#showDataChart").on("click", function () {
            // _self.resetData();
            _self.updateData();
        });
        /*jQuery("#exportDataChart").on("click", function () {
            _self.export();
        });*/
    }

    validateDateFromLessDateTo(message: any) {
        const date1 = new Date(this.dataSearchChart.dateFrom);
        const date2 = new Date(this.dataSearchChart.dateTo);
        if (date1 > date2) {
            this.errorResponse.dateFrom = message;
            return false;
        } else {
            this.errorResponse.dateFrom = null;
            return true;
        }
    }

    validateDateMoreThan31(message: any) {
        const date1 = new Date(this.dataSearchChart.dateFrom);
        const date2 = new Date(this.dataSearchChart.dateTo);
        const Difference_In_Time = date2.getTime() - date1.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        if (Difference_In_Days > 31) {
            this.errorResponse.dateTo = message;
            return false;
        } else {
            this.errorResponse.dateTo = null;
            return true;
        }
    }

    validatePractice(message: any) {
        if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length == 0) {
            this.errorResponse.practice = message;
            this.PracticeSelector.inputError = true;
            this.PracticeSelector.changeBorderColorPractice();
            return false;
        } else {
            this.errorResponse.practice = null;
            this.PracticeSelector.inputError = false;
            this.PracticeSelector.changeBorderColorPractice();
            return true;
        }
    }

    validateProvider(message: any) {
        if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
            this.errorResponse.provider = message;
            this.ProviderSelector.inputError = true;
            this.ProviderSelector.changeBorderColorProvider();
            return false;
        } else {
            this.removeMsgErr();
            return true;
        }
    }

    validate() {
        const validatePractice = this.validatePractice('This field is required');
        const validateProvider = this.validateProvider('This field is required');
        const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
        const validateDateMoreThan31
            = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
        if (validatePractice && validateProvider && validateDateFromLessDateTo && validateDateMoreThan31) {
            return true;
        }
        return false;
    }

    updateData() {
        const dateFrom = this.dataSearchChart.dateFrom;
        const dateTo = this.dataSearchChart.dateTo;
        const practiceID = this.PracticeSelector.getData();
        const providerID = this.ProviderSelector.getData();
        const locationID = this.locationSelector.getData();

        if (this.validate()) {
            //this.chartDataModel = [0, 0, 0, 0, 0, 0];
            //this.chartDatasets = [{ data: this.chartDataModel }];
            this.getScheduleActivity(dateFrom, dateTo, practiceID, providerID, locationID);
        } else {
            this.resetData();
            //this.chartDataModel = [0, 0, 0, 0, 0, 0];
            //this.chartDatasets = [{ data: this.chartDataModel }];
            return;
        }
    }
    onChangeProvider(){
      //alert('welcome');
      //console.log('here');
      const provider_id = this.ProviderSelector.getData();
      //console.log('provider_id : ' + provider_id);

      //console.log('provider_id : ' + provider_id.length);
      if (provider_id.length !== 0) {
          this.getProviderLocation(provider_id);
         // this.locationSelector.clearData();
         // this.locationSelector.resetSelect2(jQuery('#select2aptlocation'));            
      } else {
          this.locationSelector.clearData();
      }
    }
    getProviderLocation(provider_id: any) {
      const _self = this;
      this.locationSelector.clearData();
      this.locationSelector.resetSelect2(jQuery('#select2aptlocation'));
      _self._loadingIndicatorService.start();
      _self.getOfficeLocationSubscriber = _self._datalogsService.getOfficeLocation(provider_id).subscribe((success: any) => {
          _self.locationList = success.locations;
          _self._loadingIndicatorService.stop();
          //console.log(_self.eventtypeList);
      }, (error: any) => {
          this._loadingIndicatorService.stop();
      });
  }
    getScheduleActivity(dateFrom: any, dateEnd: any, practiceID: any, providerID: any, locationID: any) {
        this.isDataAvailable = true;
        this._loadingIndicatorService.start();
        this.chartdata = [];
        this.chartdata1 = [];
        //this.chartDatasets = [new ChartData()];
        this._datalogsService.getScheduleActivity(dateFrom, dateEnd, practiceID, providerID, locationID).subscribe(
            (row: any) => {
                this._loadingIndicatorService.stop();
                this.resetData();
                let datachart:any = row.data_chart;
                if (datachart !== undefined && datachart.length > 0) {
                  for (let index = 0; index < datachart.length; index++) {
                    let datainfo:any = {};
                    datainfo.State = index +'#' + datachart[index].log_date;
                    let tempvalues:any = [];
                    tempvalues.push({
                      "type" : "Plan", 
                      "value" : datachart[index].no_of_plan,                      
                      "date" : datachart[index].log_date,                      
                      "no_of_plan"   : datachart[index].no_of_plan,
                      "no_of_actual" : datachart[index].no_of_actual,
                      "no_of_cancel" : datachart[index].no_of_cancel,
                      "no_of_reschedule_cancel" : datachart[index].no_of_reschedule_cancel,
                      "no_of_noshow" : datachart[index].no_of_noshow,
                      "no_of_schedule" : datachart[index].no_of_schedule,
                      "schedule_duration": datachart[index].schedule_duration,
                      "plan_duration" : datachart[index].plan_duration,
                      "actual_duration" : datachart[index].actual_duration,
                      "no_of_skipped" : datachart[index].no_of_skipped,
                      "skipped_duration" : datachart[index].skipped_duration,
                      "no_of_notcheckin" : datachart[index].no_of_notcheckin
                    });                 
                   
                    tempvalues.push({
                      "type" : "Actual", 
                      "value" : datachart[index].no_of_actual,                      
                      "date" : datachart[index].log_date,
                      "no_of_plan"   : datachart[index].no_of_plan,
                      "no_of_actual" : datachart[index].no_of_actual,
                      "no_of_cancel" : datachart[index].no_of_cancel,
                      "no_of_reschedule_cancel" : datachart[index].no_of_reschedule_cancel,
                      "no_of_noshow" : datachart[index].no_of_noshow,
                      "no_of_schedule" : datachart[index].no_of_schedule,
                      "schedule_duration": datachart[index].schedule_duration,
                      "plan_duration" : datachart[index].plan_duration,
                      "actual_duration" : datachart[index].actual_duration,
                      "no_of_skipped" : datachart[index].no_of_skipped,
                      "skipped_duration" : datachart[index].skipped_duration,
                      "no_of_notcheckin" : datachart[index].no_of_notcheckin
                    });
                    tempvalues.push({
                      "type" : "Cancel", 
                      "value" : datachart[index].no_of_cancel,                      
                      "date" : datachart[index].log_date,
                      "no_of_plan"   : datachart[index].no_of_plan,
                      "no_of_actual" : datachart[index].no_of_actual,
                      "no_of_cancel" : datachart[index].no_of_cancel,
                      "no_of_reschedule_cancel" : datachart[index].no_of_reschedule_cancel,
                      "no_of_noshow" : datachart[index].no_of_noshow,
                      "no_of_schedule" : datachart[index].no_of_schedule,
                      "schedule_duration": datachart[index].schedule_duration,
                      "plan_duration" : datachart[index].plan_duration,
                      "actual_duration" : datachart[index].actual_duration,
                      "no_of_skipped" : datachart[index].no_of_skipped,
                      "skipped_duration" : datachart[index].skipped_duration,
                      "no_of_notcheckin" : datachart[index].no_of_notcheckin
                    });
                    tempvalues.push({
                      "type" : "Reschedule cancel", 
                      "value" : datachart[index].no_of_reschedule_cancel,                      
                      "date" : datachart[index].log_date,
                      "no_of_plan"   : datachart[index].no_of_plan,
                      "no_of_actual" : datachart[index].no_of_actual,
                      "no_of_cancel" : datachart[index].no_of_cancel,
                      "no_of_reschedule_cancel" : datachart[index].no_of_reschedule_cancel,
                      "no_of_noshow" : datachart[index].no_of_noshow,
                      "no_of_schedule" : datachart[index].no_of_schedule,
                      "schedule_duration": datachart[index].schedule_duration,
                      "plan_duration" : datachart[index].plan_duration,
                      "actual_duration" : datachart[index].actual_duration,
                      "no_of_skipped" : datachart[index].no_of_skipped,
                      "skipped_duration" : datachart[index].skipped_duration,
                      "no_of_notcheckin" : datachart[index].no_of_notcheckin
                    });
                    tempvalues.push({
                      "type" : "No-Show", 
                      "value" : datachart[index].no_of_noshow,                      
                      "date" : datachart[index].log_date,
                      "no_of_plan"   : datachart[index].no_of_plan,
                      "no_of_actual" : datachart[index].no_of_actual,
                      "no_of_cancel" : datachart[index].no_of_cancel,
                      "no_of_reschedule_cancel" : datachart[index].no_of_reschedule_cancel,
                      "no_of_noshow" : datachart[index].no_of_noshow,
                      "no_of_schedule" : datachart[index].no_of_schedule,
                      "schedule_duration": datachart[index].schedule_duration,
                      "plan_duration" : datachart[index].plan_duration,
                      "actual_duration" : datachart[index].actual_duration,
                      "no_of_skipped" : datachart[index].no_of_skipped,
                      "skipped_duration" : datachart[index].skipped_duration,
                      "no_of_notcheckin" : datachart[index].no_of_notcheckin
                    });

                    datainfo.values = tempvalues;

                    datainfo.no_of_plan   = datachart[index].no_of_plan;
                    datainfo.no_of_actual = datachart[index].no_of_actual;
                    datainfo.no_of_cancel = datachart[index].no_of_cancel;                    
                    datainfo.no_of_noshow = datachart[index].no_of_noshow;
                    datainfo.no_of_schedule = datachart[index].no_of_schedule;
                    datainfo.no_of_reschedule_cancel = datachart[index].no_of_reschedule_cancel;

                    this.chartdata.push(datainfo);

                    /* Plan vs actual */
                    let datainfo1:any = {};
                    datainfo1.State = index +'#' + datachart[index].log_date;
                    let tempvalues1:any = [];
                    tempvalues1.push({
                      "type" : "Plan Duration", 
                      "value" : datachart[index].plan_duration,                      
                      "date" : datachart[index].log_date,                      
                      "no_of_plan"   : datachart[index].no_of_plan,
                      "no_of_actual" : datachart[index].no_of_actual,                                            
                      "plan_duration" : datachart[index].plan_duration,
                      "actual_duration" : datachart[index].actual_duration
                    });                 
                   
                    tempvalues1.push({
                      "type" : "Actual Duration", 
                      "value" : datachart[index].actual_duration,                      
                      "date" : datachart[index].log_date,
                      "no_of_plan"   : datachart[index].no_of_plan,
                      "no_of_actual" : datachart[index].no_of_actual,                      
                      "plan_duration" : datachart[index].plan_duration,
                      "actual_duration" : datachart[index].actual_duration
                    });

                    datainfo1.values = tempvalues1;
                    datainfo1.plan_duration   = datachart[index].plan_duration;
                    datainfo1.actual_duration = datachart[index].actual_duration;

                    this.chartdata1.push(datainfo1);

                  }
                }   
                this.noofdata = this.chartdata.length; 
                this.curpage = 1;
                
                this.noofdata1 = this.chartdata1.length; 
                this.curpage1 = 1;
                if (!this.firstTabs)
                  this.drawMultiBarChart();
                else
                  this.drawMultiBarChart1();  

            },
            (error: any) => {
                // todo
                this._loadingIndicatorService.stop();
            }
        );
    }

    resetData() {
        //this.chartDataModel.splice(0);
    }
    toggleContent(pos:any){
      this.firstTabs = (pos == 0) ? false : true; 

      if (pos == 0) {
        jQuery('.nav-tabs li:nth-child(1)').addClass('active');
        jQuery('.nav-tabs li:nth-child(2)').removeClass('active');
        this.firstTabs = false;
        this.drawMultiBarChart();
      } else {
        jQuery('.nav-tabs li:nth-child(2)').addClass('active');
        jQuery('.nav-tabs li:nth-child(1)').removeClass('active');

        this.firstTabs = true;
        this.drawMultiBarChart1();
      }
    }
   
    getProviderByPractice(practiceId: any) {

        this._loadingIndicatorService.start();
        if (this.providerTypeSubscriber) {
            this.practiceTypeSubscriber.unsubscribe();
        }
        this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
            (success: any) => {
                if (success.error_code === 'RC000') {
                    this.providersList = [];
                    success.providers.forEach((item: any) => {
                        item.full_name
                            = item.first_name + ((item.middle_name == null)
                                ? (' ')
                                : (' ' + item.middle_name + ' ')) + item.last_name;
                        this.providersList.push(item);
                    });


                }
                this._loadingIndicatorService.stop();
            },
            (error: any) => {

                this._loadingIndicatorService.stop();
            }
        );
    }
    printDataChart(){
      window.print();
    }
    drawMultiBarChart(){
      const elements = document.getElementsByClassName("myTooltip");
    while (elements.length > 0) elements[0].remove();

    let barWidth:any = 30 * 10;

    let numBars:any = Math.round(this.chartWidth / barWidth);

    this.noofpage = Math.ceil(this.noofdata / numBars);
    this.noofpageArr = Array(this.noofpage).fill(0).map((x,i)=>i);
    
    let that = this;
    setTimeout(function(){
      for (var i=0; i < that.noofpage; i++) {
        var curpos = i + 1;
        that.printBarChart('groupchartbody_' + i, curpos, numBars);
      }
    }, 500);

  }
  drawMultiBarChart1(){
    
    const elements = document.getElementsByClassName("myTooltip");
  while (elements.length > 0) elements[0].remove();

  let barWidth:any = 20 * 10;

  let numBars:any = Math.round(this.chartWidth / barWidth);

  this.noofpage1 = Math.ceil(this.noofdata1 / numBars);
  this.noofpageArr1 = Array(this.noofpage1).fill(0).map((x,i)=>i);
  
  let that = this;
  setTimeout(function(){
    for (var i=0; i < that.noofpage1; i++) {
      var curpos = i + 1;
      that.printBarChart1('groupchartbodyplanned_' + i, curpos, numBars);
    }
  }, 500);

}
  printBarChart(eleId:any, curpage:number, numBars:number){   

    if (document.getElementById(eleId) != undefined && document.getElementById(eleId) != null) {
      document.getElementById(eleId).innerHTML = "";
    }

    if (this.noofdata == 0) {
      return false;
    }

    let chartSvgp = d3.select("#" + eleId).append('svg');

		chartSvgp.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
		chartSvgp.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
		
		let chartGp = chartSvgp.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    let startpos  = (curpage - 1) *  numBars;    
    let endpos    = (curpage * numBars);
    endpos        = (endpos > this.noofdata) ? this.noofdata : endpos;

    let tempdata:any = this.chartdata.slice(startpos, endpos);

    var x0 = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).paddingInner(0.2);
    var x1 = d3Scale.scaleBand().padding(0.1);

    var y = d3Scale.scaleLinear().rangeRound([(this.chartHeight / 1.75), 0]);

    var z = d3Scale.scaleOrdinal<any>().range(["#37A3D6", "#1ca268",  "#ff0000","#e26b01", "#ffce1a"]);

    var categoriesNames = tempdata.map(function(d:any) { return d.State; });
    var rateNames = tempdata[0].values.map(function(d:any) { return d.type; }); 

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);

    let no_of_schedule  = d3Array.max(tempdata, (d:any) => d.no_of_schedule);
    let no_of_plan      = d3Array.max(tempdata, (d:any) => d.no_of_plan);
    let no_of_actual    = d3Array.max(tempdata, (d:any) => d.no_of_actual);
    let no_of_cancel    = d3Array.max(tempdata, (d:any) => d.no_of_cancel);
    let no_of_noshow    = d3Array.max(tempdata, (d:any) => d.no_of_noshow);
    let no_of_reschedule_cancel   = d3Array.max(tempdata, (d:any) => d.no_of_reschedule_cancel);

    let maxlimit:number = Math.max(parseInt(no_of_reschedule_cancel), parseInt(no_of_plan), parseInt(no_of_actual), parseInt(no_of_cancel), parseInt(no_of_noshow));
    
    y.domain([0, maxlimit]);

    var tooltip = d3.select("body")
      .append("div")
      .attr('class', 'myTooltip')
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("");

      var legend = chartGp.append("g").attr("class", "legend");
      var cWidth = this.chartWidth;
      var cHeight = this.chartHeight;
  
      var legenG = legend.selectAll("g")
        .data(tempdata[0].values.map(function(d:any) { return d.type; }))
        .enter()
        .append("g")
        .attr("transform", function(d:any, i:any) { 
          return "translate(" + i * (cWidth / 5) + ", 0)";
      });
  
      legenG.append("rect")
        .attr("x", 0)
        .attr("y", cHeight - 40)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", z);
  
      legenG.append("text")
        .attr("x", 25)        
        .attr("y", cHeight - 30)
        .attr("dy", "0.32em")
        .text(function(d:any) { return d; });
  
      chartGp.append("g")			
        .attr("class", "grid")        
        .attr("transform", "translate(0," + (cHeight / 7) + ")")
        .call(d3Axis.axisLeft(y)
        .tickSize(-cWidth)        
        .tickFormat(null));  

      var barG = chartGp.append("g")
        .selectAll("g")
        .data(tempdata)
        .enter()
        .append("g")
        .attr("transform", function(d:any) { return "translate(" + x0(d.State) + ",0)"; });
    
      barG.selectAll(".bars-container-middle")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", 'bars-container-middle')	
        .attr("transform", "translate(0," + (cHeight / 7) + ")")      
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("y", function(d:any) { return 0; })
        .attr("width", x1.bandwidth())        
        .attr('height', function(d:any) { 
          return (d.value > 0) ? (cHeight / 1.75) : 0;        
      })
    
      barG.selectAll(".bars")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")        
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("width", x1.bandwidth())
        .attr("fill", function(d:any) { return z(d.type); })
        .attr("y", (cHeight / 2))
        .attr("y", function(d:any) { return y(d.value); })
        .attr('height', function(d:any) { 
          return (d.value > 0) ? ((cHeight / 1.75))  - y( d.value ) : 0;
        })
        .on("mouseover", function(d:any){
          
          var newHtml = '';

          newHtml += '<p><span class="lbltext">Date </span><span>: ' + d['date'] + '</span></p>';          
          newHtml += '<p><span class="lbltext">No of Plan</span><span>: ' + d['no_of_plan'] + '</span></p>';
          newHtml += '<p><span class="lbltext">No of Actual</span><span>: ' + d['no_of_actual'] + '</span></p>';

          /*if (d['no_of_notcheckin'] > 0){
            newHtml += '<p><span class="lbltext">Not check-in</span><span>: ' + d['no_of_notcheckin'] + '</span></p>';
          }*/
          
          newHtml += '<p><span class="lbltext">No of Cancel</span><span>: ' + d['no_of_cancel'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Reschedule Cancel</span><span>: ' + d['no_of_reschedule_cancel'] + '</span></p>';
          newHtml += '<p><span class="lbltext">No of no-show</span><span>: ' + d['no_of_noshow'] + '</span></p>';
          //newHtml += '<p><span class="lbltext">No of Schedule</span><span>: ' + d['no_of_schedule'] + '</span></p>';
          //newHtml += '<p><span class="lbltext">Schedule Duration</span><span>: ' + d['schedule_duration'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Plan Duration</span><span>: ' + d['plan_duration'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Actual Duration</span><span>: ' + d['actual_duration'] + '</span></p>';
          newHtml += '<p><span class="lbltext">No of Skipped</span><span>: ' + d['no_of_skipped'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Skipped Duration</span><span>: ' + d['skipped_duration'] + '</span></p>';
          //newHtml += '<p><span class="lbltext">No of Schedule</span><span>: ' + d['no_of_schedule'] + '</span></p>';
          //newHtml += '<p><span class="lbltext">Schedule Duration</span><span>: ' + d['schedule_duration'] + '</span></p>';

          tooltip.html(newHtml);
          return tooltip.style("visibility", "visible");
         })
         .on("mousemove", function(){
            var pageX = d3['event']['pageX'];
            var pageY = d3['event']['pageY'];

            if (cWidth - (pageX + 200) < 20){ 
              pageX = pageX - 200 - 20;
            }
            
            if (cHeight - (pageY + 200) < 20){ 
              pageY = pageY - 200 - 20;
            }

            return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
        })
        .on("mouseout", function(){           
          return tooltip.style("visibility", "hidden");
        });
    
      chartGp.append("g")
          .attr("class", "x-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 1.4) + ")")
          .call(d3Axis.axisBottom(x0))
          .selectAll("text")	
          .style("text-anchor", "middle")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr('y', '15')
          .text(function (d:any) {
            let xtext = d.split('#');
            return xtext[1];
          });
    
      chartGp.append("g")
          .attr("class", "y-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 7) + ")")                    
          .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return d; }).ticks(10).tickValues([0, 5, 10, 20, 40, 80, 100, 120, 150, 180, 200]));  
    }
  printBarChart1(eleId:any, curpage:number, numBars:number){   

    if (document.getElementById(eleId) != undefined && document.getElementById(eleId) != null) {
      document.getElementById(eleId).innerHTML = "";
    }

    if (this.noofdata1 == 0) {
      return false;
    }

    
    let chartSvgp = d3.select("#" + eleId).append('svg');

		chartSvgp.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
		chartSvgp.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
		
		let chartGp = chartSvgp.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    let startpos  = (curpage - 1) *  numBars;    
    let endpos    = (curpage * numBars);
    endpos        = (endpos > this.noofdata1) ? this.noofdata1 : endpos;

    let tempdata:any = this.chartdata1.slice(startpos, endpos);

    //console.log(tempdata);

    var x0 = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).paddingInner(0.2);
    var x1 = d3Scale.scaleBand().padding(0.1);

    var y = d3Scale.scaleLinear().rangeRound([(this.chartHeight / 1.75), 0]);

    var z = d3Scale.scaleOrdinal<any>().range(["#37A3D6", "#1ca268"]);

    var categoriesNames = tempdata.map(function(d:any) { return d.State; });
    var rateNames = tempdata[0].values.map(function(d:any) { return d.type; }); 

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);

    //let no_of_schedule  = d3Array.max(tempdata, (d:any) => d.no_of_schedule);
    let plan_duration      = d3Array.max(tempdata, (d:any) => d.plan_duration);
    let actual_duration   = d3Array.max(tempdata, (d:any) => d.actual_duration);    

    let maxlimit:number = Math.max(parseInt(plan_duration), parseInt(actual_duration));
    
    //console.log(maxlimit);

    y.domain([0, maxlimit]);

    var tooltip = d3.select("body")
      .append("div")
      .attr('class', 'myTooltip')
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("");

      var legend = chartGp.append("g").attr("class", "legend");
      var cWidth = this.chartWidth;
      var cHeight = this.chartHeight;
  
      var legenG = legend.selectAll("g")
        .data(tempdata[0].values.map(function(d:any) { return d.type; }))
        .enter()
        .append("g")
        .attr("transform", function(d:any, i:any) { 
          return "translate(" + i * (cWidth / 5) + ", 0)";
      });
  
      legenG.append("rect")
        .attr("x", 0)
        .attr("y", cHeight - 40)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", z);
  
      legenG.append("text")
        .attr("x", 25)        
        .attr("y", cHeight - 30)
        .attr("dy", "0.32em")
        .text(function(d:any) { return d; });
  
      chartGp.append("g")			
        .attr("class", "grid")        
        .attr("transform", "translate(0," + (cHeight / 7) + ")")
        .call(d3Axis.axisLeft(y)
        .tickSize(-cWidth)        
        .tickFormat(null));  

      var barG = chartGp.append("g")
        .selectAll("g")
        .data(tempdata)
        .enter()
        .append("g")
        .attr("transform", function(d:any) { return "translate(" + x0(d.State) + ",0)"; });
    
      barG.selectAll(".bars-container-middle")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", 'bars-container-middle')	
        .attr("transform", "translate(0," + (cHeight / 7) + ")")      
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("y", function(d:any) { return 0; })
        .attr("width", x1.bandwidth())        
        .attr('height', function(d:any) { 
          return (d.value > 0) ? (cHeight / 1.75) : 0;        
      })
    
      barG.selectAll(".bars")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")        
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("width", x1.bandwidth())
        .attr("fill", function(d:any) { return z(d.type); })
        .attr("y", (cHeight / 2))
        .attr("y", function(d:any) { return y(d.value); })
        .attr('height', function(d:any) { 
          return (d.value > 0) ? ((cHeight / 1.75))  - y( d.value ) : 0;
        })
        .on("mouseover", function(d:any){
          
          var newHtml = '';

          newHtml += '<p><span class="lbltext">Date </span><span>: ' + d['date'] + '</span></p>';
          
          newHtml += '<p><span class="lbltext">Plan Duration</span><span>: ' + d['plan_duration'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Actual Duration</span><span>: ' + d['actual_duration'] + '</span></p>';
          newHtml += '<p><span class="lbltext">No of Plan</span><span>: ' + d['no_of_plan'] + '</span></p>';
          newHtml += '<p><span class="lbltext">No of Actual</span><span>: ' + d['no_of_actual'] + '</span></p>';

          tooltip.html(newHtml);
          return tooltip.style("visibility", "visible");
         })
         .on("mousemove", function(){
            var pageX = d3['event']['pageX'];
            var pageY = d3['event']['pageY'];

            if (cWidth - (pageX + 200) < 20){ 
              pageX = pageX - 200 - 20;
            }
            
            if (cHeight - (pageY + 200) < 20){ 
              pageY = pageY - 200 - 20;
            }

            return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
        })
        .on("mouseout", function(){           
          return tooltip.style("visibility", "hidden");
        });
    
      chartGp.append("g")
          .attr("class", "x-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 1.4) + ")")
          .call(d3Axis.axisBottom(x0))
          .selectAll("text")	
          .style("text-anchor", "middle")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr('y', '15')
          .text(function (d:any) {
            let xtext = d.split('#');
            return xtext[1];
          });
    
      chartGp.append("g")
          .attr("class", "y-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 7) + ")")
          .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return d + ' min'; }));  
    }
}

export class dataStatistic {
    practice: any = null;
    provider: any = null;
    dateFrom: any = null;
    dateTo: any = null;
    location_id:any='';    
}