import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemData {
  isLogined = false;
  isOnline = false; // Set default to Online
  localStorage: any = localStorage;
  currentTime: any = new Object();
  currentTimeDisplay24hFormat = '00:00';
  numberOfNewNotifications = 0;
  numberOfNewNotificationsDisplay = '0';
  states: any = [];
  specialties: any = [];
  insurances: any = [];
  profile: any;
  selectOpenSlotData: any = [];
  savedSearchOpeningSelect: any;
  checkList: any [] = [];
  listOfActiveModule: any [] = [];
  moduleList: any = new Object();
  providerLocationLists: any = [];
  arrayTimes: any = [
    {text: '00:00 AM', value: '00:00:00'}, {text: '00:30 AM', value: '00:30:00'},
    {text: '01:00 AM', value: '01:00:00'}, {text: '01:30 AM', value: '01:30:00'},
    {text: '02:00 AM', value: '02:00:00'}, {text: '02:30 AM', value: '02:30:00'},
    {text: '03:00 AM', value: '03:00:00'}, {text: '03:30 AM', value: '03:30:00'},
    {text: '04:00 AM', value: '04:00:00'}, {text: '04:30 AM', value: '04:30:00'},
    {text: '05:00 AM', value: '05:00:00'}, {text: '05:30 AM', value: '05:30:00'},
    {text: '06:00 AM', value: '06:00:00'}, {text: '06:30 AM', value: '06:30:00'},
    {text: '07:00 AM', value: '07:00:00'}, {text: '07:30 AM', value: '07:30:00'},
    {text: '08:00 AM', value: '08:00:00'}, {text: '08:30 AM', value: '08:30:00'},
    {text: '09:00 AM', value: '09:00:00'}, {text: '09:30 AM', value: '09:30:00'},
    {text: '10:00 AM', value: '10:00:00'}, {text: '10:30 AM', value: '10:30:00'},
    {text: '11:00 AM', value: '11:00:00'}, {text: '11:30 AM', value: '11:30:00'},
    {text: '12:00 PM', value: '12:00:00'}, {text: '12:30 PM', value: '12:30:00'},
    {text: '01:00 PM', value: '13:00:00'}, {text: '01:30 PM', value: '13:30:00'},
    {text: '02:00 PM', value: '14:00:00'}, {text: '02:30 PM', value: '14:30:00'},
    {text: '03:00 PM', value: '15:00:00'}, {text: '03:30 PM', value: '15:30:00'},
    {text: '04:00 PM', value: '16:00:00'}, {text: '04:30 PM', value: '16:30:00'},
    {text: '05:00 PM', value: '17:00:00'}, {text: '05:30 PM', value: '17:30:00'},
    {text: '06:00 PM', value: '18:00:00'}, {text: '06:30 PM', value: '18:30:00'},
    {text: '07:00 PM', value: '19:00:00'}, {text: '07:30 PM', value: '19:30:00'},
    {text: '08:00 PM', value: '20:00:00'}, {text: '08:30 PM', value: '20:30:00'},
    {text: '09:00 PM', value: '21:00:00'}, {text: '09:30 PM', value: '21:30:00'},
    {text: '10:00 PM', value: '22:00:00'}, {text: '10:30 PM', value: '22:30:00'},
    {text: '11:00 PM', value: '23:00:00'}, {text: '11:30 PM', value: '23:30:00'}
  ];

  appointmentDateDate = [
    {
      text: 'Today',
      value: 1
    }, {
      text: 'Tomorrow',
      value: 2
    }, {
      text: 'Within one week',
      value: 3
    }, {
      text: 'All',
      value: 0
    }
  ];

  searchOpeningsDistance = [
    {
      text: 'Within 100 miles',
      value: 100
    }, {
      text: 'Within 50 miles',
      value: 50
    }, {
      text: 'Within 25 miles',
      value: 25
    }, {
      text: 'Within 20 miles',
      value: 20
    }, {
      text: 'Within 15 miles',
      value: 15
    }, {
      text: 'Within 10 miles',
      value: 10
    }, {
      text: 'Within 5 miles',
      value: 5
    }, {
      text: 'Within 1 mile',
      value: 1
    }, {
      text: 'Within 0.5 mile',
      value: 0.5
    }
  ];

  modulesActivatedState = [
    {text: 'Notification Module', state: false},
    {text: 'Wait-time Module', state: false},
    {text: 'Provider Module', state: false},
    {text: 'Patients Module', state: false},
    {text: 'Scheduling Module', state: false},
    {text: 'Datalog Module', state: false},
    {text: 'Document Module', state: false}
  ];

  //footNote = "Represents appointment variations (time slots on graphs , total appointments in hover window) encountered when searching by \"RTN specific wait time calculation\", as RTN’s wait time calculation algorithms may merge certain slots as needed for wait time accuracy for same time periods searched compared with “standard wait time” calculation. The actual appointment numbers and completed time slots are always shown in the standard search, so please refer back as needed. Further details are provided in the data log manual for clarity, please see page...";
  footNote = "Represents appointment variations (time slots on graphs , total appointments in hover window) encountered when searching by \"RTN specific wait time calculation\"  as RTN's wait time calculations utilize various algorithms which may lead to differences in results when using standard calculations. The actual appointment numbers and completed time slots are always shown in the standard search, so please refer back as needed. Further details are provided in the data log manual for clarity, please see page…";
  appointmentId = 0;
}
