import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { Utils } from '../../shared/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { SystemData, SystemEvent, SystemService } from '@app/shared/system';
import { ResendEmail_ModalComponent } from '@app/shared/modals/marketing/resendEmail';
import { SimpleModalsService } from '@app/shared/modals/simple-modals/simple-modals.service';
import { SimpleModalsComponent } from '@app/shared/modals/simple-modals/simple-modals.component';
import { AlertService } from '../../shared/alert';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../home.component.scss', './login.component.scss'],
  providers: [SimpleModalsService],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  // declare arrayActiveModule
  arrayModule = new Array();
  loginForm: FormGroup;
  error: boolean = false;
  isNotActivate: boolean = false;
  isDeactivate: boolean = false;
  errorMessage: any;
  password: string = '';
  username: string = '';
  remember_me: any;
  minHeight: number;
  isDisabled: boolean;
  account: any;
  verifiCodeCheck: boolean = false;
  userId: any;
  returnURL : string = '';
  @ViewChild(ResendEmail_ModalComponent) resendEmailDirective: ResendEmail_ModalComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _utils: Utils,
    private _router: Router,
    private _systemServices: SystemService,
    private _alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService,
    private _route : ActivatedRoute
  ) {

    this.account = new Object();
    this.loginForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember_me: ['']
    });


    if (this.username && this.password) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  ngOnInit() {
    //TODO
    if (localStorage.getItem('remember_me') === '1') {
      this.username = localStorage.getItem('user_email');
      this.remember_me = localStorage.getItem('remember_me');
    }
    this.clearLocalStorage();
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();

    this._route.queryParams.subscribe(params => {
      if (params['returnUrl'] != null && params['returnUrl'] != undefined) {
        this.returnURL = params['returnUrl'];
      }
     //console.log('returnURL : ', this.returnURL);
    });
  }

  ngAfterViewInit() {
    // jQuery('#email').focus();
  }

  verifiOtpCode() {
    const self = this;
    self._alertService.onClose = () => {
      const otp_code = self._alertService.inputValueInputHtml;
      self._alertService.showCustomInputHtml = false;
      self._alertService.inputValueInputHtml = '';
      self.verifiOtpCodeService(otp_code);
    };
    self._alertService.onDismiss = () => {
      window.location.reload();
    };
    self._alertService.inputTypeHtml = 'text';
    self._alertService.showDismissButton = true;
    self._alertService.showCloseButton = true;
    self._alertService.closeButtonLabel = 'Submit';
    self._alertService.title = 'Verification code';
    self._alertService.showCustomInputHtml = true;
    self._alertService.labelCustomInputHtml = 'Your Code';        
    self._alertService.message = 'Enter your verification code';
    self._alertService.emitter('open');
  }

  verifiOtpCodeService(otp_code: any) {
    if(otp_code == null || otp_code.length != 6) {
      this.error = true;
      this.errorMessage = "Your verification code is not valid!";
      this.removeFailLogin();
    }else{
      const self = this;
      this.loadingIndicatorService.start();   
      this._authService.verifiOtpCode(this.userId, otp_code).subscribe(
        (success: any) => {
         // console.log(success);
          self.loadingIndicatorService.stop();
          if (success.error_code === 'RC000') {
            self.processLogin(success);            
            self.error = false;
          } else {
            this.removeFailLogin();
            self.error = true;
            self.errorMessage = success.error_messages;
          }
          // Todo
        }, (error: any) => {
          this.removeFailLogin();
          self.loadingIndicatorService.stop();
          // Todo
          self.error = true;
          self.errorMessage = error.error.error_messages;
        });
      this.verifiCodeCheck = true;
      this.error = false;
      this.errorMessage = "";
    }
  }

  login() {
    const self = this;
    this.isDeactivate = false;
    this.isNotActivate = false;
    this._authService.userLogin(this.username, this.password).subscribe(
      (success: any) => {
        if(success.type == '2fa'){
          this.userId = success.user_id;
          this.verifiOtpCode();          
        }else{
          this.processLogin(success);
        }
      },
      (error: any) => {
        //TODO
        let e = error.error;
        if (e.error_code === 'RC090') {
          this.isNotActivate = true;
          this.openResend();
        } else if (e.error_code === 'RC011') {
          this.isDeactivate = true;
          this.openResend();
        } else if (e.error_code === 'TL001') {
          this._router.navigate(['/home', 'page-not-found']);
        } else {
          this.error = true;
          this.errorMessage = error.error.error_messages;
        }
        this.password = null;
      },
      () => {
        // this.navCtrl.navigateRoot('/dashboard/home');
      }
    );
  }

  processLogin(success: any) {
    const self = this;
    // Save data to local storage
    localStorage.setItem('user_role_id', success.user.role_id);
    localStorage.setItem('user_id', success.user.id);
    localStorage.setItem('account_name', success.user.account_name);
    localStorage.setItem('user_status', success.user.status);
    localStorage.setItem('user_practice', success.user.practice);
    localStorage.setItem('module_enc', success.modules);
    localStorage.setItem('user_providerId', success.user.provider_id);
    
    if (this.remember_me) {
      //Todo save if remember to local storage
      localStorage.setItem('user_email', self.username);
      localStorage.setItem('remember_me', '1');
    } else {
      localStorage.removeItem('user_email');
      localStorage.removeItem('remember_me');
    }
    localStorage.setItem('loginEmail', self.username);
    localStorage.setItem('userFullName', this._utils.generateFullName(success.user_name.first_name, success.user_name.middle_name, success.user_name.last_name));
    
    localStorage.setItem('userFirstName', success.user_name.first_name);
    localStorage.setItem('userLastName', success.user_name.last_name);

    // get active module array
    this.arrayModule = this._systemServices.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
    // force redirect to login for first time
    if (success.user.force_pwd_page === 1) {
      if (success.user.role_id === 1) {
        this._router.navigate(['/admin', 'change-password']);
      } else if (success.user.role_id === 2) {
        if (this.arrayModule[3]) {
          this._router.navigate(['/provider', 'change-password']);
        } else {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        }
      }
      else if (success.user.role_id === 3) {
        if (!this.arrayModule[3]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return
        }
        else if (this.arrayModule[5]) {
          this._router.navigate(['/provider', 'change-password']);
        } else {
          this._router.navigate(['/provider', 'change-password']);
        }
      }
      else if (success.user.role_id === 4) {
        if (!this.arrayModule[4]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        }
        else if (success.user.status === 4) { //4: Partial Active status
          this._router.navigate(['/patient', 'appointment', 'openings']);
        } else {
          //this.returnURL
          if (this.returnURL) {
            this._router.navigate([this.returnURL]);
          } else {
            this._router.navigate(['/patient', 'edit']);
          }
        }
      }
      else if (success.user.role_id === 5) {
        if (!this.arrayModule[3]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        }
        else if (success.user.practice !== null) { //If have 1 practice
          this._router.navigate(['/provider', 'change-password']);
        } else { //If have no practice
          this._router.navigate(['/provider', 'change-password']);
        }
      }
      else if (success.user.role_id === 6) {
        if (!this.arrayModule[3]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        } else {
          localStorage.setItem('managing_user_id', success.user.id);
          this._router.navigate(['/provider', 'change-password']);
        }
      }
    } else {
      // Redirect to Appoinment page
      if (success.user.role_id === 1) {
        this._router.navigate(['/admin', 'account']);
      } else if (success.user.role_id === 2) {
        if (this.arrayModule[3]) {
          // this._router.navigate(['/superProvider', 'account']);
          this._router.navigate(['/provider/practice', 'add']);
        } else {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        }
      } else if (success.user.role_id === 3) {
        if (!this.arrayModule[3]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return
        }
        else if (this.arrayModule[5]) {
          this._router.navigate(['/provider', 'appointment']);
        } else {
          this._router.navigate(['/provider', 'profile']);
        }
      } else if (success.user.role_id === 4) {
        if (!this.arrayModule[4]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        }
        else if (success.user.status === 4) { //4: Partial Active status
          this._router.navigate(['/patient', 'appointment', 'openings']);
        } else {
          //this.returnURL
          if (this.returnURL) {
            this._router.navigate([this.returnURL]);
          } else {
            this._router.navigate(['/patient', 'dashboard']);
          }
        }
      } else if (success.user.role_id === 5) {
        if (!this.arrayModule[3]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        }
        else if (success.user.practice !== null) { //If have 1 practice
          this._router.navigate(['/provider', 'practice']);
        } else { //If have no practice
          this._router.navigate(['/provider', 'practice', 'add']);
        }
      } else if (success.user.role_id === 6) {
        if (!this.arrayModule[3]) {
          this.removeFailLogin();
          this._router.navigate(['/home', 'page-not-found']);
          return;
        } else {
          localStorage.setItem('managing_user_id', success.user.id);
          this._router.navigate(['/provider', 'manage']);
        }
      }
    } 
    
    this._systemServices.setLoginedState(true);
  }

  enableSubmit() {
    if (this.username && this.password) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  openResend() {
    this.account.email = this.username;
    this.account.isNotActivate = this.isNotActivate;
    this.account.isDeactivate = this.isDeactivate;
    this.resendEmailDirective.open(this.account);
  }

  clearLocalStorage() {
    // Remove localStorage role id when logout
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role_id');
    localStorage.removeItem('user_status');
    localStorage.removeItem('user_email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('user_practice');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('managing_user_id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('user_token');
    localStorage.removeItem('last_active_token');
    localStorage.removeItem('storeData');
    localStorage.removeItem('module_enc');
    localStorage.removeItem('user_providerId');
  }

  removeFailLogin(){
     this.clearLocalStorage();
     this.username = '';
     this.password = '';
  }
}
