import {Component, ViewChild, OnInit, AfterViewInit, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {AlertService} from '@app/shared/alert';
import {Select2Directive} from '@app/shared/form/select2.directive';
import {OpeningHourComponent} from '@app/shared/opening-hour/opening-hour.component';
//import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
//import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
//import {PasswordFormatTooltipComponent} from '@app/shared/tooltip/password-format/password-format.component';
import {ProviderDetail} from '@app/shared/models/providerDetail';
import {Utils} from '@app/shared/utils';
import {Subscription} from 'rxjs';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {ProvidersService} from '@app/providers/providers.service';
import {AdminRestService} from '@app/admin/admin.service';
import {Select2LocationDirective} from '@app/shared/form/select2-location.directive';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  templateUrl: './edit-provider.component.html',
  styleUrls: ['./edit-provider.component.scss']
})
export class EditProviderComponent implements OnInit, AfterViewInit, OnDestroy {
  providerDetail: ProviderDetail = new ProviderDetail();  
  responseError: ProviderDetail = new ProviderDetail();  
  listTime: any[] = new Array();
  selectedSpecialties: any;
  
  is_mapped: boolean;
  fullNameTemp: string;
  successMessage: string;  
  practiceList: any;
  showPractice = false;
  practiceDefault: any;

  updateProfileSubscriber: Subscription;
  onGetProfileListener: Subscription;
  onGetSpecialtiesListener: Subscription;  
  loadPracticeListSubscriber: Subscription;
  fetchAllLocationSubscriber: Subscription;
  onGetLocationListener: Subscription;  
  selectedLocations: any;

  @ViewChild(Select2Directive) specialtiesSelector: Select2Directive;
  @ViewChild(Select2LocationDirective) locationSelector: Select2LocationDirective;
  
  //@ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;
  @ViewChild(OpeningHourComponent) openingHourDirective: OpeningHourComponent;
  idSelected: number;

  password: string;
  password_confirmation: string;
  notify_to : boolean = false;
  successMessage1 : string;
  updateManageUserPasswordSubscriber : Subscription;
  responseError1: ManageuserPasswordItem = new ManageuserPasswordItem();
  arrlocations:any=[];
  
  constructor(
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    public _systemData: SystemData,
    private _service: ProvidersService,
    private _alertService: AlertService,
    private _router: Router,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService, 
    private route: ActivatedRoute,
    private adminRestService : AdminRestService,   
  ) {
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
    this.loadPracticeListSubscriber = this._service.getPracticeList().subscribe(
      (success: any) => {
        this.practiceList = success.practices;
        this.practiceDefault = '';
        this.practiceList.map((item: any) => {
          if (item.name === 'RTN') {
            // Set default value for office
            this.practiceDefault = item.id;
          }
        });
      }
    );

    this.idSelected = parseInt(this.route.snapshot.paramMap.get('id'), 10);
  }

  ngOnInit() {
    this._systemService.getStates();
    this._systemService.getSpecialties();
    this._systemService.getLocationList();
    //this.fetchAllLocation();

    this.specialtiesSelector.clearData();
    this.locationSelector.clearData();

    this.specialtiesSelector.resetSelect2(jQuery('#select2Specialties'));
    this.locationSelector.resetSelect2(jQuery('#select2location'));

    this.onGetSpecialtiesListener = this._systemEvent.specialty.subscribe((done: boolean) => {
      if (done) {
        if (this.selectedSpecialties) {
          //console.log(this.selectedSpecialties);
          this.specialtiesSelector.updateData(this.selectedSpecialties);
        }
      }
    });
    this.onGetLocationListener = this._systemEvent.providerlocations.subscribe((done: boolean) => {
      if (done) {
        if (this.selectedLocations) {
          //console.log(this.selectedSpecialties);
          this.locationSelector.updateData(this.selectedLocations);
        }
      }
    }); 
    this.getProviderInfo();
  }
  getProviderInfo(){
    this.onGetProfileListener = this._service.getUserDetail(this.idSelected).subscribe((row: any) => {
      if (row) {

        this.providerDetail = row.user;        
        this.is_mapped = this.providerDetail.is_mapped;
        this.providerDetail.phone_mobile = this.providerDetail.phone_mobile.replace('+1 ', '');
        this.providerDetail.phone_mobile_tmp = this.providerDetail.phone_mobile;        
        this.selectedSpecialties = [];
        const specialtiesList = this.providerDetail.specialties;
        if (specialtiesList !== null) {
          for (let i = 0; i < specialtiesList.length; i++) {
            this.selectedSpecialties.push(specialtiesList[i].id);
          }  
          
          if (this._systemData.specialties.length !== 0) {
            this.specialtiesSelector.updateData(this.selectedSpecialties);
          }
          
        }
        this.openingHourDirective.injectData(this.providerDetail.opening_hours);

        // Set default value for office
        if (this.providerDetail.office_name === null || this.providerDetail.office_name === 'null' || this.providerDetail.office_name === '') {
            this.providerDetail.office_name = this.practiceDefault;
            this.showPractice = true;
        }  
        
        this.selectedLocations = [];

        if (this.providerDetail.location_id.length !== 0) {
          //this.locationSelector.clearData();     
          //let selectedLocation = []; 
          for (let i = 0; i < this.providerDetail.location_id.length; i++) {
            this.selectedLocations.push(this.providerDetail.location_id[i]);
          }   
          if (this._systemData.providerLocationLists.length !== 0) {
            this.locationSelector.updateData(this.selectedLocations);
          }
        }      
      }
    });
  }

  ngAfterViewInit() {
    // todo
  }

  ngOnDestroy() {
    if (this.updateProfileSubscriber) {
      this.updateProfileSubscriber.unsubscribe();
    }
    if (this.onGetProfileListener) {
      this.onGetProfileListener.unsubscribe();
    }
    if (this.onGetSpecialtiesListener) {
      this.onGetSpecialtiesListener.unsubscribe();
    }

    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
        
    if (this.onGetLocationListener) {
      this.onGetLocationListener.unsubscribe();
    }
       
  }

  updateProfileForm(formVals: any) {
    this._loadingIndicatorService.start();
    this.successMessage = null;
    this.clearError();
    const value = Object.assign({}, formVals);
    // let billing_info = this.formatDataBillingInfo(value);

    value.office_name = this.providerDetail.office_name;
    value.first_name = this.providerDetail.first_name;
    value.last_name = this.providerDetail.last_name;
    value.npi = this.providerDetail.npi;

    value.opening_hours = this.openingHourDirective.getData();
    // value.billing = billing_info;
    value.is_mapped = this.is_mapped;
    value.specialties = this.specialtiesSelector.getData();
    value.location_id = this.locationSelector.getData();
    
    // Get the checked box index before sending out save request
    const currentCheckedBoxIndex = this.openingHourDirective.getCurrentCheckedBoxIndex();
    if (this.updateProfileSubscriber) {
      this.updateProfileSubscriber.unsubscribe();
    }
    this.updateProfileSubscriber = this.adminRestService.updateProfileProvider(this.idSelected, value).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.successMessage = success.error_messages;        
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
        const res = error.error;
        if (res.error_code === 'RC060') {
          this.providerDetail.is_mapped = false;
          this.is_mapped = false;
          this.responseError = res.error_fields;
        } else if (res.error_code === 'RC070') {
          this._alertService.onClose = () => {
            location.reload();
          };
          this._alertService.showDismissButton = false;
          this._alertService.dismissButtonLabel = 'Close';
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'OK';
          this._alertService.title = res.error_messages;
          this._alertService.message = '';
          this._alertService.emitter('open');
        } else {
          this.responseError = res.error_fields;
          this.openingHourDirective.injectError(currentCheckedBoxIndex, [{
            index: 0,
            message: 'You are there'
          }, {index: 2, message: 'You are here'}]);
          if (typeof this.responseError['password'] !== 'undefined') {
            this.responseError['password_confirmation'] = '';
          } else {
            this.responseError['password_confirmation'] = res.error_fields.password_confirmation;
          }
        }
        let messagePos1 = '';
        let messagePos2 = '';
        let messagePos3 = '';
        let messagePos4 = '';
        let messagePos5 = '';
        let messagePos6 = '';
        let messagePos7 = '';

        if ((<any>this.responseError)['opening_hours.0.days']
          && (<any>this.responseError)['opening_hours.0.days'].length !== 0) {
          messagePos1 = (<any>this.responseError)['opening_hours.0.days'];
        } else if ((<any>this.responseError)['opening_hours.0.end_time']
          && (<any>this.responseError)['opening_hours.0.end_time'].length !== 0) {
          messagePos1 = (<any>this.responseError)['opening_hours.0.end_time'];
        }
        if ((<any>this.responseError)['opening_hours.1.days']
          && (<any>this.responseError)['opening_hours.1.days'].length !== 0) {
          messagePos2 = (<any>this.responseError)['opening_hours.1.days'];
        } else if ((<any>this.responseError)['opening_hours.1.end_time']
          && (<any>this.responseError)['opening_hours.1.end_time'].length !== 0) {
          messagePos2 = (<any>this.responseError)['opening_hours.1.end_time'];
        }
        if ((<any>this.responseError)['opening_hours.2.days']
          && (<any>this.responseError)['opening_hours.2.days'].length !== 0) {
          messagePos3 = (<any>this.responseError)['opening_hours.2.days'];
        } else if ((<any>this.responseError)['opening_hours.2.end_time']
          && (<any>this.responseError)['opening_hours.2.end_time'].length !== 0) {
          messagePos3 = (<any>this.responseError)['opening_hours.2.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.3.days']
          && (<any>this.responseError)['opening_hours.3.days'].length !== 0) {
          messagePos4 = (<any>this.responseError)['opening_hours.3.days'];
        } else if ((<any>this.responseError)['opening_hours.3.end_time']
          && (<any>this.responseError)['opening_hours.3.end_time'].length !== 0) {
          messagePos4 = (<any>this.responseError)['opening_hours.3.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.4.days']
          && (<any>this.responseError)['opening_hours.4.days'].length !== 0) {
          messagePos5 = (<any>this.responseError)['opening_hours.4.days'];
        } else if ((<any>this.responseError)['opening_hours.4.end_time']
          && (<any>this.responseError)['opening_hours.4.end_time'].length !== 0) {
          messagePos5 = (<any>this.responseError)['opening_hours.4.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.5.days']
          && (<any>this.responseError)['opening_hours.5.days'].length !== 0) {
          messagePos6 = (<any>this.responseError)['opening_hours.5.days'];
        } else if ((<any>this.responseError)['opening_hours.5.end_time']
          && (<any>this.responseError)['opening_hours.5.end_time'].length !== 0) {
          messagePos6 = (<any>this.responseError)['opening_hours.5.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.6.days']
          && (<any>this.responseError)['opening_hours.6.days'].length !== 0) {
          messagePos7 = (<any>this.responseError)['opening_hours.6.days'];
        } else if ((<any>this.responseError)['opening_hours.6.end_time']
          && (<any>this.responseError)['opening_hours.6.end_time'].length !== 0) {
          messagePos7 = (<any>this.responseError)['opening_hours.6.end_time'];
        }       

        const showError = [
          {index: 0, message: messagePos1},          
          {index: 1, message: messagePos2},
          {index: 2, message: messagePos3},
          {index: 3, message: messagePos4},
          {index: 4, message: messagePos5},
          {index: 5, message: messagePos6}
        ];
        this.openingHourDirective.injectError(currentCheckedBoxIndex, showError);
      }
    );
  }

  clearError() {
    this.responseError = new ProviderDetail();
  }

  preventWhiteSpaceInput(event: any) {
    if (event.target.value.match(/\s/g)) {
      event.target.value = event.target.value.replace(/\s/g, '');
    }
  }

  onKeyUp(event: any, error: string) {
    this.successMessage = null;

    switch (error) {
      case 'phone_mobile':
        this.responseError['phone_mobile'] = null;
        break;
      case 'password':
        this.responseError['password'] = null;
        break;
      case 'password_confirmation':
        this.responseError['password_confirmation'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'first_name':
        this.responseError['first_name'] = null;
        break;
      case 'last_name':
        this.responseError['last_name'] = null;
        break;
      case 'credentials':
        this.responseError['credentials'] = null;
        break;
      case 'npi':
        this.responseError['npi'] = null;
        break;
      case 'office_name':
        this.responseError['office_name'] = null;
        break;
      case 'specialties':
        this.responseError['specialties'] = null;
        break;
      case 'website_url':
        this.responseError['website_url'] = null;
        break;
      case 'password':
          this.responseError1['password'] = null;
          break;
      case 'confirm_password':
          this.responseError1['confirm_password'] = null;
          break;
      case 'office_address':
        this.responseError['office_address'] = null;
        break;
      case 'office_address2':
        this.responseError['office_address2'] = null;
        break;
      case 'office_city':
        this.responseError['office_city'] = null;
        break;
      case 'office_state_code':
        this.responseError['office_state_code'] = null;
        break;
      case 'office_zip':
        this.responseError['office_zip'] = null;
        break;
      case 'location_id':
        this.responseError['location_id'] = null;
        break;              
    }
  }
  removeMessage(event: any) {
    this.successMessage = null;
  }
  updatePasswordFromAdmin(formVals: any) {
    const self = this;
    this.successMessage = null;
    let value = Object.assign({}, formVals);
    
    this._loadingIndicatorService.start();
    if (this.updateManageUserPasswordSubscriber) {
        this.updateManageUserPasswordSubscriber.unsubscribe();
    }
    this.updateManageUserPasswordSubscriber = this.adminRestService.updatePasswordFromAdmin(this.idSelected, value).subscribe((success: any) => {
          this._loadingIndicatorService.stop();
          this.successMessage1 = success.error_messages;
          this.responseError1 = new ManageuserPasswordItem();
          this.password = null;
          this.password_confirmation = null;
          this.notify_to = false;
      }, (error: any) => {
          this._loadingIndicatorService.stop();
          let res = error.error;
          this.responseError1 = res.error_fields;
          this.password = null;
          this.password_confirmation = null;
          this.notify_to = false;
    });
  }
  fetchAllLocation(){
    const self = this;
        
    this._loadingIndicatorService.start();
    if (this.fetchAllLocationSubscriber) {
        this.fetchAllLocationSubscriber.unsubscribe();
    }
    this.fetchAllLocationSubscriber = this.adminRestService.fetchAllLocation().subscribe((success: any) => {
          this._loadingIndicatorService.stop();
          this.arrlocations = success.aptlocation;   
          //this.locationSelector.updateData(this.arrlocations);   
              
      }, (error: any) => {
          this._loadingIndicatorService.stop();         
    });
  }
}
export class ManageuserPasswordItem {
  password: string = null;
  password_confirmation: string = null;
  notify_to : boolean = false;
}