<nav class="navbar navbar-expand-lg navbar-expand-sm navbar-expand-md pl-0 pr-0">
  <div class="container provider-header px-0">
    <div class="navbar-header col-offset-2">
      <a class="navbar-brand" href="https://www.realtimeneighborhood.com/">
        <img src="/assets/img/logo.png" />
      </a>
    </div>

    <div class="provider-header-navigator navbar-collapse collapse">
      <ul class="navbar-nav title-menu provider-header-navigator-title">
        <li *ngIf="module[5]" class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'Appointment'], _router)">
          <a class="show-focus" [routerLink]="['/provider', 'appointment']" (click)="onReload('Appointment')">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">APPOINTMENTS</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                <span class="fa fa-calendar-check-o header-icon"></span>
            </span>
          </a>
        </li>
        <li *ngIf="module[7]" class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'Document'], _router)">
          <a class="show-focus" [routerLink]="['/provider', 'document']" (click)="onReload('Document')">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">DOCUMENTS</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                <span class="fa fa-files-o header-icon"></span>
            </span>
          </a>
        </li>
        <li *ngIf="module[4]" class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'Patient'], _router)">
          <a class="show-focus" [routerLink]="['/provider', 'patient']" (click)="onReload('Patient')">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">PATIENTS</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                <span class="fa fa-files-o header-icon"></span>
            </span>
          </a>
        </li>
        <li *ngIf="!isLock" class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'wait-list'], _router)" >
          <a class="show-focus" [routerLink]="['/provider', 'wait-list']" (click)="onReload('wait-list')">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">WAIT LIST</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                <span class="fa fa-files-o header-icon"></span>
            </span>
          </a>
        </li>
        <li *ngIf="!isLock" class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'dataLog'], _router)" >
          <a class="show-focus"  [routerLink]="['/provider', 'statistical-appointment-by-types']" (click)="onReload('statistical-appointment-by-types')">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">DATA LOG</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                <span class="fa fa-files-o header-icon"></span>
            </span>
          </a>
        </li>

      </ul>
      <ul class="nav navbar-nav navbar-right profile-menu provider-header-navigator-profile">

        <!-- Online/Offline status -->
        <li class="status">
          <a class="no-focus" aria-haspopup="true" aria-expanded="false">
            <div class="online cursorDefault" *ngIf="_systemData.isOnline">
              <span class="fr-font fr-connectivity header-icon" aria-hidden="true"></span>
              <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">Online</span>
            </div>
            <div class="offline cursorDefault" *ngIf="!_systemData.isOnline">
              <span class="fr-font fr-connectivity header-icon" aria-hidden="true"></span>
              <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">Offline</span>
            </div>
          </a>
        </li>

        <!-- User menu dropdown -->
        <li class="dropdown show">
          <a href="#" class="btn hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none dropdown-toggle hover-bottom-border no-focus" data-toggle="dropdown" role="button">
            <i class="pull-right fa fa-caret-down"></i>
            <i class="fa faalign-left fa-user menu-user"></i>
            <span class="pull-left text-overflow-ellipsis" *ngIf="isManagingMode">{{extractData.userFullName + ' : ' + _systemData.localStorage.getItem('userFullName')}}</span>
            <span class="pull-left text-overflow-ellipsis" *ngIf="!isManagingMode">{{_systemData.localStorage.getItem('userFullName')}}</span>
          </a>
          <a href="#" class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs dropdown-toggle hover-bottom-border no-focus" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            <span class="fa fa-bars header-icon"></span>
          </a>
          <ul class="dropdown-menu provider-header-navigator-profile-menu">
            <li class="">
              <a [routerLink]="['/provider', 'profile']" class="cursorPointer dropdown-item" style="height: 36px;">
                <i class="fa faalign-left fa-user"></i>My Profile
              </a>
            </li>
            <li *ngIf="isProvider">
              <a class="cursorPointer dropdown-item" (click)="patientNewSignUpDirective.open()">
                <i class="fa faalign-left fa-user-plus"></i>Sign Up New Patient
              </a>
            </li>
            <li *ngIf="isProvider">
              <a class="cursorPointer dropdown-item" [routerLink]="['/provider', 'preferences']">
                <i class="fa faalign-left fa-gear"></i>Patient Preferences
              </a>
            </li>
            <li *ngIf="isProvider">
              <a (click)="notificationMass()" class="cursorPointer dropdown-item">
                <i class="fa faalign-left fa-send"></i>Mass Notifications
              </a>
            </li>
            <li *ngIf="isProvider">
              <a (click)="notificationMassPatient()" class="cursorPointer dropdown-item">
                <i class="fa faalign-left fa-send"></i>Send to
              </a>
            </li>
            <li>
                <a (click)="changePassword()" class="cursorPointer dropdown-item">
                    <i class="fa faalign-left fa-key"></i>Change password
                </a>
              </li>
            <li>
              <a (click)="logoutDirective.open()" class="cursorPointer dropdown-item" *ngIf="!isManagingMode">
                <i class="fa faalign-left fa-sign-out"></i>Log Out
              </a>
              <a (click)="logoutManaging()" class="cursorPointer dropdown-item" *ngIf="isManagingMode">
                <i class="fa faalign-left fa-sign-out"></i>Log Out
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
<rtn-modals-general-logout></rtn-modals-general-logout>
<app-notification-mass></app-notification-mass>
<app-notification-mass-patient></app-notification-mass-patient>
<!-- <rtn-modals-notification-log></rtn-modals-notification-log>
<rtn-modals-notification-mass></rtn-modals-notification-mass>-->
<app-rtn-modals-patient-new-sign-up></app-rtn-modals-patient-new-sign-up>
