<div class="row px-0 col-xs-12 opening-hour-slot" [class.isTop]="index === 0">
  <div class="opening-hours-group pull-left">
      <div class="pull-left no-padding-left-right input-rtn">
          <input type="checkbox" [attr.checked]="checked" [attr.id]="'setting_opening_hours_' + index" (change)="onCheckboxChange($event); responseError = ''"
          />
          <label class="selectorExtreme margin-top-12px" [attr.for]="'setting_opening_hours_' + index"></label>
      </div>
  </div>
  <div class="opening-hours-group pull-left">
      <div class="pull-left padding-right-5 padding-left-5 display-inline-flex labelauty-container" [class.input-error]="responseError === 'Please select at least one day'">
          <input type="checkbox" data-labelauty="Su" [(ngModel)]="slot.days.day0" [disabled]="isDisabled || (checkStatus[0] !== -1 && checkStatus[0] !== index)"
              (change)="onDayCheckboxChange($event, 0); responseError = ''" />
          <input type="checkbox" data-labelauty="Mo" [(ngModel)]="slot.days.day1" [disabled]="isDisabled || (checkStatus[1] !== -1 && checkStatus[1] !== index)"
              (change)="onDayCheckboxChange($event, 1); responseError = ''" />
          <input type="checkbox" data-labelauty="Tu" [(ngModel)]="slot.days.day2" [disabled]="isDisabled || (checkStatus[2] !== -1 && checkStatus[2] !== index)"
              (change)="onDayCheckboxChange($event, 2); responseError = ''" />
          <input type="checkbox" data-labelauty="We" [(ngModel)]="slot.days.day3" [disabled]="isDisabled || (checkStatus[3] !== -1 && checkStatus[3] !== index)"
              (change)="onDayCheckboxChange($event, 3); responseError = ''" />
          <input type="checkbox" data-labelauty="Th" [(ngModel)]="slot.days.day4" [disabled]="isDisabled || (checkStatus[4] !== -1 && checkStatus[4] !== index)"
              (change)="onDayCheckboxChange($event, 4); responseError = ''" />
          <input type="checkbox" data-labelauty="Fr" [(ngModel)]="slot.days.day5" [disabled]="isDisabled || (checkStatus[5] !== -1 && checkStatus[5] !== index)"
              (change)="onDayCheckboxChange($event, 5); responseError = ''" />
          <input type="checkbox" data-labelauty="Sa" [(ngModel)]="slot.days.day6" [disabled]="isDisabled || (checkStatus[6] !== -1 && checkStatus[6] !== index)"
              (change)="onDayCheckboxChange($event, 6); responseError = ''" />
      </div>
  </div>
  <div class="opening-hours-group time-selector no-padding-right padding-left-10">
      <div class="time-from no-padding-right padding-left-10">
          <div class="select-time-label pull-left" [class.disabled-label]="isDisabled">From</div>
          <div class="input-group select select-time-style pull-left">
              <select class="form-control" [disabled]="isDisabled" [(ngModel)]="slot.start_time" (focus)="responseError = ''" (change)="clearErr()">
                  <option *ngFor="let i of listTime" [value]="i">{{i}}</option>
              </select>
              <div class="input-group-select-icon cursorPointer">
                  <i class="fa fa-caret-down"></i>
              </div>
          </div>
      </div>
      <div class="time-to no-padding-right pull-left padding-left-10">
          <div class="select-time-label pull-left" [class.disabled-label]="isDisabled">To</div>
          <div class="input-group select select-time-style pull-left" [class.input-error]="responseError === 'To time must be equal or greater than From time'">
              <select class="form-control" [disabled]="isDisabled" [(ngModel)]="slot.end_time" (focus)="responseError = ''" (change)="clearErr()">
                  <option *ngFor="let i of listTime" [value]="i">{{i}}</option>
              </select>
              <div class="input-group-select-icon cursorPointer">
                  <i class="fa fa-caret-down"></i>
              </div>
          </div>
      </div>
  </div>

</div>
<div class="row px-4 col-xs-12 message-error-container">
  <span class="message-error" *ngIf="responseError !== ''">{{responseError}}</span>
</div>
