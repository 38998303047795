import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {extract} from '@app/core';
import {HomeComponent} from '@app/home/home.component';
// import {Shell} from '@app/shell/shell.service';
// import {NotificationListComponent} from '@app/notifications/notification-list/notification-list.component';
// import {NotificationDashboardComponent} from '@app/notifications/notification-dashboard/notification-dashboard.component';
// import {NumberWaittimeComponent} from '@app/wait-time/number-waittime/number-waittime.component';
// import {NotificationMassComponent} from '@app/notifications/notification-mass/notification-mass.component';
import {LoginComponent} from '@app/home/login/login.component';
import {Role} from '@app/core/role';
import {AuthGuard} from '@app/core/auth.guard';
// import {SpeedometerWaittimeComponent} from '@app/wait-time/speedometer-waittime/speedometer-waittime.component';

// import {DocumentsComponent} from '@app/documents/documents.component';
// import {PatientsComponent} from '@app/patients/patients.component';
// import {PatientAccountComponent} from '@app/patients/account/account.component';
// import {InsuranceComponent} from '@app/patients/insurance/insurance.component';
// import {PracticeComponent} from '@app/providers/practice/practice.component';
// import {PracticeEditComponent} from '@app/providers/practice-edit/practice-edit.component';
import {ChangePasswordComponent} from '@app/home/change-password/change-password.component';
import {HomePageComponent} from '@app/home/home/home.component';
import {ChangePasswordExpiredComponent} from '@app/home/change-password-expired/change-password-expired.component';
import {ActivateComponent} from '@app/home/active/activate.component';
import {ForgotPasswordComponent} from '@app/home/forgot-password/forgot-password.component';
import {RegisterComponent} from '@app/home/register/register.component';
import {FrontSignUpComponent} from '@app/home/front-sign-up/front-sign-up.component';
// import {ListManagingUserComponent} from '@app/providers/list-managing-user/list-managing-user.component';
// import {AddManagingUserComponent} from '@app/providers/add-managing-user/add-managing-user.component';
// import {EditUserComponent} from '@app/providers/edit-user/edit-user.component';
// import {ListPatientsComponent} from '@app/providers/list-patients/list-patients.component';
// import {ProviderProfileComponent} from '@app/providers/provider-profile/provider-profile.component';
import {PhysicianDirectoryComponent} from '@app/home/physician-directory/physician-directory.component';
import {AcceptedFamilyComponent} from '@app/home/family-member/accepted-family.component';
import {DeclinedFamilyComponent} from '@app/home/family-member/declined-family.component';
import {ChartWaitTimeComponent} from '@app/data-logs/providers-waittime/chart-wait-time.component';
import {ChartTestComponent} from '@app/data-logs/slot-duration/slot-duration.component';
import {AppointmentByDayComponent} from '@app/data-logs/appointment-waittime/appointment-by-day.component';
import {NotFoundComponent} from '@app/shared/not-found/not-found.component';

import {PatientNotificationSettingComponent} from '@app/home/patient-notification-setting/patient-notification-setting.component';
import {PatientNotificationOptoutComponent} from '@app/home/patient-notification-optout/patient-notification-optout.component';

const routes: Routes = [{
  path: '',
  component: HomeComponent,
  children: [
    {
      path: 'home/homepage',
      component: HomePageComponent,
      data: {}
    },
    {
      path: '',
      redirectTo: 'home/homepage',
      pathMatch: 'full',
      data: {}
    },
    {
      path: 'home',
      redirectTo: 'home/homepage',
      pathMatch: 'full',
      data: {}
    },
    {
      path: 'home/login',
      component: LoginComponent,
      data: {}
    },
    {
      path: 'home/accept/:id/:active_token',
      component: AcceptedFamilyComponent,
      data: {}
    },
    {
      path: 'home/decline/:id/:active_token',
      component: DeclinedFamilyComponent,
      data: {}
    },
    {
      path: 'home/update-password-expired',
      component: ChangePasswordExpiredComponent,
      data: {}
    },
    {
      path: 'home/forgot-password/reset/:id/:token',
      component: ChangePasswordComponent,
      data: {}
    },
    {
      path: 'home/forgot-password/:genpass',
      component: ForgotPasswordComponent,
      data: {}
    },
    {
      path: 'home/forgot-password',
      component: ForgotPasswordComponent,
      data: {}
    },
    {
      path: 'home/front-sign-up',
      component: FrontSignUpComponent,
      data: {}
    },
    {
      path: 'home/sign-up',
      component: RegisterComponent,
      data: {}
    },
    {
      path: 'home/activate/:id/:token',
      component: ActivateComponent,
      data: {}
    },
    // {
    //   path: 'provider/mass',
    //   component: NotificationMassComponent,
    //   data: {title: extract('Notification Mass')}
    // },
    {
      path: 'physician-directory',
      component: PhysicianDirectoryComponent,
      data: {title: extract('RTN')},
      canActivate: [AuthGuard]
    },
    {
      path: 'home/page-not-found',
      redirectTo: '/page-not-found',
      //component: NotFoundComponent,
      data: {}
    },
    {
      path: 'home/patient-notification-setting/:id',
      component: PatientNotificationSettingComponent,
      data: {}
    },
    {
      path: 'home/patient-notification-optout/:id',
      component: PatientNotificationOptoutComponent,
      data: { 'optiontype': 'schedule_optout'}
    },
    {
      path: 'home/patient-notification-optin/:id',
      component: PatientNotificationOptoutComponent,
      data: { 'optiontype': 'schedule_optin'}
    },
    {
      path: 'home/patient-notification-altoptout/:id',
      component: PatientNotificationOptoutComponent,
      data: { 'optiontype': 'schedule_altoptout'}
    },
    {
      path: 'home/patient-all-notification-optout/:id',
      component: PatientNotificationOptoutComponent,
      data: { 'optiontype': 'patient_optout'}
    },
    {
      path: 'home/patient-all-notification-altoptout/:id',
      component: PatientNotificationOptoutComponent,
      data: { 'optiontype': 'patient_altoptout'}
    },  
    // {
    //   path: 'chart',
    //   component: ChartWaitTimeComponent,
    //   data: {},
    //   // canActivate: [AuthGuard]
    // },
    // {
    //   path: 'chart/test',
    //   component: ChartTestComponent,
    //   data: {},
    //   // canActivate: [AuthGuard]
    // },
    // {
    //   path: 'chart/appointment-by-day',
    //   component: AppointmentByDayComponent,
    //   data: {},
    //   // canActivate: [AuthGuard]
    // },
    // {
    //   path: 'chart/appointment-by-week',
    //   component: AppointmentByWeekComponent,
    //   data: {},
    //   // canActivate: [AuthGuard]
    // },
    // {
    //   path: 'chart',
    //   component: ChartWaitTimeComponent,
    //   data: {},
    //   //canActivate: [AuthGuard]
    // },
    // {
    //   path: 'chart/test',
    //   component: ChartTestComponent,
    //   data: {},
    //   // canActivate: [AuthGuard]
    // },
    // {
    //   path: 'chart/appointment-by-day',
    //   component: AppointmentByDayComponent,
    //   data: {},
    //   // canActivate: [AuthGuard]
    // },
    // {
    //   path: 'chart/appointment-by-week',
    //   component: AppointmentByWeekComponent,
    //   data: {},
    //   // canActivate: [AuthGuard]
    // }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {
}
