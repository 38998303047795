import { Component, Output, EventEmitter, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MODAL_DIRECTIVES, ModalComponent } from '@app/shared/ng2-bs3-modal';
import { AlertService } from '@app/shared/alert';
import { RestService as ModalsService} from '@app/core/rest.service';
import { Subscription } from 'rxjs';
import {PatientsService} from '@app/patients/patients.service';
@Component({
  selector: 'app-edit-phone-confirm',
  templateUrl: './edit-phone-confirm.component.html',
  styleUrls: ['./edit-phone-confirm.component.scss']
})
export class EditPhoneConfirmComponent implements OnInit, OnDestroy, AfterViewInit {

  phone_mobile_tmp: string;
  stepVerifyPhone = 0;
  messageConfirmCode: string;
  messageVerifyError = 'Mobile number verification failed. Please try again.';
  messageSuccess = 'Your mobile number has been verified. From now you can configure to receive notification via SMS in Notification Settings section of any Provider.';
  titleBtnSendCode = 'Send code';
  checkInvalidPhone = true;
  isInputFieldEmpty = false;

  sendPhoneNumberCodeSubscriber: Subscription;
  verifyPhoneNumberCodeSubscriber: Subscription;

  @ViewChild('EditPhoneConfirm_Modals') modal: ModalComponent;

  @Output()
  changeStatusPhoneVerify: EventEmitter<any> = new EventEmitter();
  @Output()
  changePhoneNumber: EventEmitter<any> = new EventEmitter();
  @Output()
  changePhoneMobileVerified: EventEmitter<any> = new EventEmitter();

  constructor(
    private _modalsService: PatientsService,
    private _alertService: AlertService
  ) { }

  showErrorMessage(message: string, buttonLabel: string) {
    this.dismiss();
    setTimeout(() => {
        this._alertService.onClose = () => {
            // TODO
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = buttonLabel;
        this._alertService.title = message;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }, 200);
  }

  reset() {
    this.isInputFieldEmpty = false;
    this.messageConfirmCode = '';
  }

  sendPhoneNumberCode(phone: string) {
    this.reset();
    let phoneData = { 'phone_mobile_draft': phone };

    if (this.sendPhoneNumberCodeSubscriber) {
        this.sendPhoneNumberCodeSubscriber.unsubscribe();
    }
    this.sendPhoneNumberCodeSubscriber = this._modalsService.sendPhoneNumberCode(phoneData).subscribe(
        (success: any) => {
            this.stepVerifyPhone = 1; // = 1 Confirm verify phone
        },
        (error: any) => {
            let response = error.json();
            if (response.error_code === 'RC030') { // Already verified
                this.messageVerifyError = response.error_messages;
                this.changePhoneNumber.emit(this.phone_mobile_tmp); // Set new phone after verified success
                this.changeStatusPhoneVerify.emit(true);
                this.changePhoneMobileVerified.emit(1); // Status phone verified
                this.showErrorMessage(this.messageVerifyError, 'OK');
            } else {
                this.resetVerifyStep();
            }
        }
    );
  }

  resetVerifyStep() {
    this.titleBtnSendCode = 'Send code';
    this.reset();
    this.stepVerifyPhone = 0; // = 0 Cancel Verify Phone
    this.isInputFieldEmpty = false;
  }

  backToReSendCode() {
    this.titleBtnSendCode = 'Resend code';
    this.reset();
    this.stepVerifyPhone = 0; // = 0 Cancel Verify Phone
  }

  verifyPhoneNumberCode(phone: string, confirmCode: string) {
    this.isInputFieldEmpty = false;
    let codeData = { 'phone_mobile_draft': phone, 'phone_mobile_verified_code': confirmCode };

    if (this.verifyPhoneNumberCodeSubscriber) {
        this.verifyPhoneNumberCodeSubscriber.unsubscribe();
    }
    this.verifyPhoneNumberCodeSubscriber = this._modalsService.verifyPhoneNumberCode(codeData).subscribe(
        (success: any) => {
            this.changeStatusPhoneVerify.emit(true);
            this.changePhoneMobileVerified.emit(1); // Status phone verified
            this.changePhoneNumber.emit(this.phone_mobile_tmp); // Set new phone after verified success
            this.showErrorMessage(this.messageSuccess, 'Close');
        },
        (error: any) => {
            let response = error.json();
            if (response.error_code === 'RC030') { // Already verified
                this.messageVerifyError = response.error_messages;
                this.changePhoneNumber.emit(this.phone_mobile_tmp); // Set new phone after verified success
                this.changeStatusPhoneVerify.emit(true);
                this.changePhoneMobileVerified.emit(1); // Status phone verified
                this.showErrorMessage(this.messageVerifyError, 'OK');
            } else if (response.error_code === 'RC027') { // Failed to verify
                this.messageVerifyError = response.error_messages;
                this.showErrorMessage(this.messageVerifyError, 'OK');
            } else if (response.error_code === 'RC029') { // Empty Input
                this.isInputFieldEmpty = true;
                this.messageConfirmCode = response.error_messages;
            }
        }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.sendPhoneNumberCodeSubscriber) {
        this.sendPhoneNumberCodeSubscriber.unsubscribe();
    }
    if (this.verifyPhoneNumberCodeSubscriber) {
        this.verifyPhoneNumberCodeSubscriber.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // TODO
  }

  open() {
    this.modal.open();
  }

  dismiss() {
    this.modal.dismiss();
    setTimeout(() => {
        this.resetVerifyStep();
    }, 200);
  }

}
