<modal #AppointmentBook_Modals class="appointment-new">
  <form #modalForm="ngForm" class="form">
    <div class="form-content">
      <div class="form-header">Send Message to patient</div><!--Send available slot to patient-->
      <div class="form-info">
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2 form-input-label">Patient</div>
          <div  class="col-lg-10 col-md-10 col-xs-10 search px-0">
            {{patient_name}}
          </div>

        </div>
        <!--<div class="row col-lg-12 col-md-12 col-xs-12" *ngIf="time">
          <div class="col-lg-2 col-md-2 col-xs-2 form-input-label">Time</div>
          <div  class="col-lg-10 col-md-10 col-xs-10 search px-0">
           {{time}}
          </div>
        </div>-->

        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2"></div>
          <div class="message-error">{{ requireMessage.patient_id }}</div>
        </div>
        <!--<div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2 form-input-label">Date</div>
          <div class="col-xs-10 col-sm-10 col-lg-10 col-md-10 no-padding-left date-select">
            <div class="input-group">
              <input type="text" #inputDateNewAppointment class="form-control calender-input bc-white"
                     [class.input-error]="requireMessage.date != null" [(ngModel)]="newAvailableSlotModel.date" disabled
                     [ngModelOptions]="{standalone: true}">
              <div class="input-group-append" #clickDateNewAppointment>
                <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2"></div>
          <div class="col-xs-10 col-sm-10 col-lg-10 col-md-10 message-error">{{ requireMessage.date }}</div>
        </div>

        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2 form-input-label">Slot</div>
          <div class="row col-xs-10 col-sm-10 col-lg-10 col-md-10 px-0">
            <div class="col-3 wrapper-slot" *ngFor="let slot of availableSlots">
              <div class="btn btn-default btn-slot">{{ getTime(slot.start_time) }}&nbsp;-&nbsp;{{ getTime(slot.end_time) }}</div>
            </div>
            <div *ngIf="availableSlots.length == 0">No open slot found for selected date.</div>
          </div>
        </div>-->

        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2 form-input-label">Email</div>
          <div class="row col-xs-10 col-sm-10 col-lg-10 col-md-10 px-0">
            <input type="email" name="email" id="email" class="form-control" [(ngModel)]="newAvailableSlotModel.email" />
            <div class="col-xs-10 col-sm-10 col-lg-10 col-md-10 message-error">{{ requireMessage.email }}</div>
          </div>
        </div>

        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2 form-input-label">Phone</div>
          <div class="row col-xs-10 col-sm-10 col-lg-10 col-md-10 px-0">
            <rtn-mobile-number class="font-text" id="phone_mobile" [formInput]="newAvailableSlotModel.phone"
                [error]="requireMessage.phone" 
            ></rtn-mobile-number>
          </div>
        </div>

        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-xs-2 form-input-label pt-4">Content</div>
          <div class="row col-xs-10 col-sm-10 col-lg-10 col-md-10 px-0">
            <i>[PLEASE_ALTER_THIS_MESSAGE_AT_YOUR_DISCRETION] - Please enter message you would like to send to Patient</i>
            <i>[EMAIL_MISMATCH_TXT] - Text will append -when login email & contact email is different.</i>
            <textarea name="content" [class.input-error]="requireMessage.content != ''" class="input form-control paddingInput" rows="8" [ngModel]="newAvailableSlotModel.content" (ngModelChange)="newAvailableSlotModel.content = $event" maxlength="1000"></textarea>
            <div>
              <div class="col-sm-9 message-error no-padding-left">{{ requireMessage.content }}</div>
              <div class="col-sm-3 text-left">{{ newAvailableSlotModel.content.length }}/1000</div>
          </div>
          </div>
        </div>

          <div class="row col-lg-12 col-md-12 col-xs-12">
            <div class="col-lg-2 col-md-2 col-xs-2 form-input-label">Send</div>
            <div class="row col-xs-10 col-sm-10 col-lg-10 col-md-10 px-0">
              <div class="col-md-3 input-rtn pull-left checkbox-style">
                <input class="checkbox check-single" type="checkbox" value="1" id="is_email"
                    name="is_email" [(ngModel)]="newAvailableSlotModel.is_email">
                <label class="selectorExtreme margin-top-12" for="is_email"></label> Email
              </div>
              <div class="col-md-3 input-rtn pull-left checkbox-style">
                <input class="checkbox check-single" type="checkbox" value="1" id="is_phone"
                    name="is_phone" [(ngModel)]="newAvailableSlotModel.is_phone">
                <label class="selectorExtreme margin-top-12" for="is_phone"></label> SMS
              </div>
            </div>
          </div>

        

      </div>
      <div class="row" *ngIf="errorMessageBook">
        <div class="col-xs-2"></div>
        <div class="col-xs-10 no-padding-left-right">
          <div class="message-error">{{ errorMessageBook }}</div>
        </div>
      </div>
    </div>
    <div class="button">
      <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="dismiss()">Cancel
      </button>

      <button type="submit" class="btn btn-rtn btn-90 float-right" (click)="checkLockedProvider(1)" autofocus
              [disabled]="!patient_id">Send
      </button>
    </div>
  </form>
</modal>
