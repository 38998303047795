<div class="container-fluid  w-100">
    <div class="row" style="border-bottom: 1px solid #e6e3e3;">
        <div class="col-md-12 Rtn-Meter">
            <div class="waitmeter-top-part px-3 pb-0 pt-1 d-flex justify-content-center">
                <img class="logo" src="assets/rtnmeter-images/images/rtn-logo.png">
            </div>            
        </div>

    </div>
    <div class="row mt-5" *ngIf="successMessage">
        <div class="col-md-12">
            <div class="errorsection">
                <p class="label-danger">{{successMessage}}</p>
            </div>
        </div>
    </div>
    <div class="row mt-5" *ngIf="errorMessage">
        <div class="col-md-12">
            <div class="errorsection">
                <p class="label-danger">{{ errorMessage }}</p>
            </div>
        </div>
    </div>
    
</div>