import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, } from '@angular/core';
import { Subscription } from 'rxjs';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

import { DataLogsService } from '@app/data-logs/data-logs.service';
import * as moment from 'moment';
import { AlertService } from '@app/shared/alert';
import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { AdminRestService } from '@app/admin/admin.service';
import { Utils } from '@app/shared/utils';
import { ChartData } from '@app/shared/models/chartData';
declare let jQuery: any;

@Component({
    selector: 'app-statistical-appointment',
    templateUrl: './statistical-appointment.component.html',
    styleUrls: ['./statistical-appointment.component.scss']
})
export class StatisticalAppointmentComponent implements OnInit, AfterViewInit {
    currentDate: any = moment();
    practiceId: string = '0';
    providerId = '0';
    getListPracticeSubscriber: Subscription;
    getListProviderSubscriber: Subscription;
    dataSearchChart: dataStatistic = new dataStatistic();
    errorResponse: dataStatistic = new dataStatistic();
    practiceTypeSubscriber: Subscription;
    providerTypeSubscriber: Subscription;
    public practicesList: any = [];
    public providersList: any = [];
    data_chart: any;
    maxDate:any;
    @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
    @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
    @ViewChild('inputDateFrom') inputPractice: ElementRef;
    @ViewChild('inputDateFrom') inputProvider: ElementRef;
    @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
    @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
    @ViewChild('inputDateTo') inputDateTo: ElementRef;
    @ViewChild('clickDateTo') clickDateTo: ElementRef;
    constructor(
        private _alertService: AlertService,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _adminService: AdminRestService,
        private _datalogsService: DataLogsService,
        private _utils: Utils,
    ) {
        this.dataSearchChart.dateFrom = moment().subtract(1, "days").format('LL');
        this.dataSearchChart.dateTo = moment().subtract(1, "days").format('LL');
    }

    removeMsgErr() {
        this.errorResponse.provider = null;
        this.ProviderSelector.inputError = false;
        this.ProviderSelector.changeBorderColorProvider();
    }

    onChangePractice() {
        this.ProviderSelector.clearData();
        this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
        this.removeMsgErr();
        const practice = this.PracticeSelector.getData();
        if (practice.length !== 0) {
            this.getProviderByPractice(practice);
            if (this.providersList.length > 0) {
                this.ProviderSelector.updateData(this.providersList);

            }
        } else {
            this.ProviderSelector.clearData();
        }
    }

    ngOnInit() {
        this.PracticeSelector.clearData();
        this.ProviderSelector.clearData();
        this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
        this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
        const _self = this;
        _self._loadingIndicatorService.start();
        _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
            (success: any) => {
                _self.practicesList = success.practices;
                const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
                _self.getProviderByPractice(firstPractice);
                this._loadingIndicatorService.stop();
            });
    }

    ngAfterViewInit(): void {
        const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
        const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
        const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
        if (now >= zero_oclock && now < startCurrentTime) {
            this.maxDate = moment().subtract(2, 'days').format('LL');
            this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
            this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
        }else{
            this.maxDate = moment().subtract(1, 'days').format('LL');
            this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
            this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
        }
        const _self = this;
        jQuery(_self.inputDateFrom.nativeElement).datepicker({
            dateFormat: 'MM dd, yy',
            numberOfMonths: 2,
            showButtonPanel: true,
            maxDate: moment(this.maxDate).toDate(),
            changeMonth: true,
            changeYear: true,
            onSelect: (selected: any) => {
                _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
            }
        });
        jQuery(_self.inputDateFrom.nativeElement).datepicker("setDate", this.dataSearchChart.dateFrom);
        jQuery(_self.clickDateFrom.nativeElement).click(() => {
            jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
        });

        jQuery(_self.inputDateTo.nativeElement).datepicker({
            dateFormat: 'MM dd, yy',
            numberOfMonths: 2,
            showButtonPanel: true,
            maxDate: moment(this.maxDate).toDate(),
            changeMonth: true,
            changeYear: true,
            onSelect: (selected: any) => {
                _self.dataSearchChart.dateTo = jQuery(_self.inputDateTo.nativeElement).val();
            }
        });
        jQuery(_self.inputDateTo.nativeElement).datepicker("setDate", this.dataSearchChart.dateTo);
        jQuery(this.clickDateTo.nativeElement).click(() => {
            jQuery(this.inputDateTo.nativeElement).datepicker('show');
        });

        jQuery("#showDataChart").on("click", function () {
            // _self.resetData();
            _self.updateData();
        });
        /*jQuery("#exportDataChart").on("click", function () {
            _self.export();
        });*/
    }

    validateDateFromLessDateTo(message: any) {
        const date1 = new Date(this.dataSearchChart.dateFrom);
        const date2 = new Date(this.dataSearchChart.dateTo);
        if (date1 > date2) {
            this.errorResponse.dateFrom = message;
            return false;
        } else {
            this.errorResponse.dateFrom = null;
            return true;
        }
    }

    validateDateMoreThan31(message: any) {
        const date1 = new Date(this.dataSearchChart.dateFrom);
        const date2 = new Date(this.dataSearchChart.dateTo);
        const Difference_In_Time = date2.getTime() - date1.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        if (Difference_In_Days > 31) {
            this.errorResponse.dateTo = message;
            return false;
        } else {
            this.errorResponse.dateTo = null;
            return true;
        }
    }

    validatePractice(message: any) {
        if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length == 0) {
            this.errorResponse.practice = message;
            this.PracticeSelector.inputError = true;
            this.PracticeSelector.changeBorderColorPractice();
            return false;
        } else {
            this.errorResponse.practice = null;
            this.PracticeSelector.inputError = false;
            this.PracticeSelector.changeBorderColorPractice();
            return true;
        }
    }

    validateProvider(message: any) {
        if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
            this.errorResponse.provider = message;
            this.ProviderSelector.inputError = true;
            this.ProviderSelector.changeBorderColorProvider();
            return false;
        } else {
            this.removeMsgErr();
            return true;
        }
    }

    validate() {
        const validatePractice = this.validatePractice('This field is required');
        const validateProvider = this.validateProvider('This field is required');
        const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
        const validateDateMoreThan31
            = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
        if (validatePractice && validateProvider && validateDateFromLessDateTo && validateDateMoreThan31) {
            return true;
        }
        return false;
    }

    updateData() {
        const dateFrom = this.dataSearchChart.dateFrom;
        const dateTo = this.dataSearchChart.dateTo;
        const practiceID = this.PracticeSelector.getData();
        const providerID = this.ProviderSelector.getData();
        if (this.validate()) {
            this.chartDataModel = [0, 0, 0, 0, 0, 0, 0];
            this.chartDatasets = [{ data: this.chartDataModel }];
            this.getStatisticalAppt(dateFrom, dateTo, practiceID, providerID);
        } else {
            this.resetData();
            this.chartDataModel = [0, 0, 0, 0, 0, 0, 0];
            this.chartDatasets = [{ data: this.chartDataModel }];
            return;
        }

    }

    export() {
        const _self = this;
        const dateFrom = this.dataSearchChart.dateFrom;
        const dateEnd = this.dataSearchChart.dateTo;
        const practiceId = this.PracticeSelector.getData();
        const providerId = this.ProviderSelector.getData();
        
        console.log(this.validate());

        if (this.validate()) {
            const token = localStorage.getItem('user_token');
            jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
                httpMethod: 'POST',
                data: {
                    'token': token,
                    'practice': practiceId,
                    'providerId': providerId,
                    'startTime': dateFrom,
                    'endTime': dateEnd,
                    'chartType': 5
                }
            }).done(function () {
                // TODO
            })
                .fail(function () {
                    _self._alertService.onClose = () => {
                        location.reload();
                    };
                    // _self._alertService.showDismissButton = false;
                    // _self._alertService.dismissButtonLabel = 'Close';
                    // _self._alertService.showCloseButton = true;
                    // _self._alertService.closeButtonLabel = 'Close';
                    // _self._alertService.title = 'This document is not available to download anymore.';
                    // _self._alertService.message = '';
                    // _self._alertService.emitter('open');
                });
        } else {
            this.resetData();
            this.chartDataModel = [0, 0, 0, 0, 0, 0, 0];
            this.chartDatasets = [{ data: this.chartDataModel }];

        }
    }

    getStatisticalAppt(dateFrom: any, dateEnd: any, practiceID: any, providerID: any) {
        this._loadingIndicatorService.start();
        this.chartDatasets = [new ChartData()];
        this._datalogsService.getStatiscalAppt(dateFrom, dateEnd, practiceID, providerID).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.resetData();
                this.data_chart = success.statistic;
                if (this.data_chart !== undefined && this.data_chart.length > 0) {
                    const length = this.data_chart.length;
                    for (let index = 0; index < length; index++) {
                        this.chartDataModel[index] = this.data_chart[index].stt.data;
                        this.chartLabels[index] = this.orgchartLabels[index] + '(' + this.chartDataModel[index] + ')'
                        // const dataLabel = {
                        //     'label': this.data_chart[index].stt.type,
                        //     'percentage': this.data_chart[index].stt.percent
                        // };
                    }
                }
                this.chartDatasets = [{ data: this.chartDataModel }];

                //console.log(this.chartDatasets);

            },
            (error: any) => {
                // todo
                this._loadingIndicatorService.stop();
            }
        );
    }

    resetData() {
        this.chartDataModel.splice(0);
    }

    getProviderByPractice(practiceId: any) {

        this._loadingIndicatorService.start();
        if (this.providerTypeSubscriber) {
            this.practiceTypeSubscriber.unsubscribe();
        }
        this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
            (success: any) => {
                if (success.error_code === 'RC000') {
                    this.providersList = [];
                    success.providers.forEach((item: any) => {
                        item.full_name
                            = item.first_name + ((item.middle_name == null)
                                ? (' ')
                                : (' ' + item.middle_name + ' ')) + item.last_name;
                        this.providersList.push(item);
                    });


                }
                this._loadingIndicatorService.stop();
            },
            (error: any) => {

                this._loadingIndicatorService.stop();
            }
        );
    }

    public chartDataModel = [0, 0, 0, 0, 0, 0, 0];

    public chartType: string = 'pie';

    public chartDatasets: Array<any> = [{ data: this.chartDataModel }]

    public orgchartLabels: Array<any> = ['Cancel', 'Finished', 'No-show', 'Not check-in', 'Cancel before 45 minutes', 'Cancel for Reschedule', 'Not check-in(Skipped Apt.)'];
    public chartLabels: Array<any> = ['Cancel', 'Finished', 'No-show', 'Not check-in', 'Cancel before 45 minutes', 'Cancel for Reschedule', 'Not check-in(Skipped Apt.)'];
    // public chartLabels: Array<any> = [];
    public chartColors: Array<any> = [
        {
            backgroundColor: ['#eb7578', '#63ba9b', '#FDB45C', '#949FB1', '#bc8ad4', '#6296c4', '#D6EE98'],
            hoverBackgroundColor: ['#eb7578', '#63ba9b', '#FDB45C', '#949FB1', '#bc8ad4', '#6296c4', '#D6EE98'],
            borderWidth: 2,
        }
    ];

    public chartOptions: any = {
        responsive: true,
        legend: {
            display: true,
            position: 'bottom'
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem:any, data:any) => {

                    var labelText = this.orgchartLabels[tooltipItem.index];
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = dataset.data.reduce(function (previousValue: any, currentValue: any, currentIndex: any, array: any) {
                        return previousValue + currentValue;
                    });
                    const currentValuePie = dataset.data[tooltipItem.index];
                    const percentage = ((currentValuePie / total) * 100).toFixed(2);
                    
                    return labelText + ' : ' + currentValuePie + ' of ' + total + ' (' + percentage + ' %) ';
                }
                /*beforeLabel: function (tooltipItem: any, data: any) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = dataset.data.reduce(function (previousValue: any, currentValue: any, currentIndex: any, array: any) {
                        return previousValue + currentValue;
                    });
                    const currentValuePie = dataset.data[tooltipItem.index];
                    const percentage = ((currentValuePie / total) * 100).toFixed(2);
                    return "Percentage: " + percentage + "%";
                },
                afterLabel: function (tooltipItem: any, data: any) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = dataset.data.reduce(function (previousValue: any, currentValue: any, currentIndex: any, array: any) {
                        return previousValue + currentValue;
                    });
                    const currentValuePie = dataset.data[tooltipItem.index];
                    const percentage = ((currentValuePie / total) * 100).toFixed(2);
                    return "Total Apt.:" + total;
                },*/
            }
        }
    };

    printDataChart(){
        window.print();
    }

}

export class dataStatistic {
    practice: any = null;
    provider: any = null;
    dateFrom: any = null;
    dateTo: any = null;
    timezone: any = null;
    type: any = null;
    doneAppt: any = null;
    cancelAppt: any = null;
    noShowAppt: any = null;
    skippedAppt: any = null;
}