import {Injectable, EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemEvent {
  system: EventEmitter<any> = new EventEmitter();
  notification: EventEmitter<any> = new EventEmitter();
  menu: EventEmitter<any> = new EventEmitter();
  specialty: EventEmitter<any> = new EventEmitter();
  insurances: EventEmitter<any> = new EventEmitter();
  profile: EventEmitter<any> = new EventEmitter();
  book: EventEmitter<any> = new EventEmitter();
  event: EventEmitter<any> = new EventEmitter();
  isOnline: EventEmitter<any> = new EventEmitter();
  providerlocations: EventEmitter<any> = new EventEmitter();
  constructor() {
    // TODO
  }
}
