import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ShellRoutingModule} from './shell-routing.module';
import { ShellComponent } from './shell.component';
import { FooterComponent } from './footer/footer.component';
import {SharedModule} from '@app/shared';
import {HeaderComponent} from '@app/shell/header/header.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    SharedModule,
    ShellRoutingModule
  ],
  exports: [
    FooterComponent
  ],
  declarations: [
    ShellComponent,
    FooterComponent,
    HeaderComponent
  ]
})
export class ShellModule {
}
