<div class="col-md-12 col-sm-12 col-xs-12 no-padding-left-right">
    <h4 class="title-block-style">DASHBOARD</h4>
    <div class="patient-appointment col-lg-12 col-md-12 col-12 no-padding-left-right" *ngIf="arrayModule[5]">
        <div class="text-header appoint-style d-flex justify-content-between col-12 flex-wrap"> 
            <span>Appointments</span>
            <span class="link-to-detail">
              <a class="link link-style" [routerLink]="['/patient', 'appointment', 'find']">Click here to view your appointments</a>
            </span>
        </div>
    </div>

    <app-notification-dashboard *ngIf="arrayModule[1]"></app-notification-dashboard>
    <h4 *ngIf="arrayModule[1]" class="title-block-style">SAVED SEARCHES FOR OPENINGS</h4>
    <div *ngIf="arrayModule[1]" class="saved-searchs-for-openings col-xs-12 col-sm-12 col-md-12 no-padding-left-right table-responsive">
        <div class="col-xs-12 col-sm-12 col-md-12 listview no-padding-left-right">
            <table class="table table-bordered table-ellipsis remove-style">
                <thead>
                    <tr>
                        <th class="col-date">Date</th>
                        <th class="col-time">Time</th>
                        <th class="col-provider">Provider</th>
                        <th class="col-zip">ZIP Code</th>
                        <th class="col-distance">Distance</th>
                        <!--<th class="col-insurance">Insurance</th>-->
                        <th class="col-delete"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of savedSearchForOpeningData; let isOdd=odd" id="saved-data-{{item.id}}" [class.change-background]="isOdd">
                        <td class="bg-white">{{item.date}}</td>
                        <td class="bg-white">{{item.time}}</td>
                        <td class="bg-white">{{item.provider_name}}</td>
                        <td class="bg-white">{{item.zip}}</td>
                        <td class="bg-white">{{item.distance}}</td>
                        <!--<td>{{item.insurance}}</td>-->
                        <td class="text-center bg-white">
                            <a class="icon-delete-style" (click)="showConfirmDeleteAlertService(item.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                        </td>
                    </tr>
                    <tr *ngIf="numberSavedSearchForOpening < 1" class="no-saved-search">
                        <td colspan="8" class="no-provider bg-white">There is no saved searches for openings</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <app-latest-notification-dashboard *ngIf="arrayModule[1]"></app-latest-notification-dashboard>
</div>
