import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import {RestService} from '@app/core/rest.service';
import { SystemEvent, SystemService, SystemData } from '@app/shared/system';
import { Router, ActivatedRoute  } from '@angular/router';
import { Subscription } from 'rxjs';
import { Utils } from '@app/shared/utils';
import { InputMaskDirective } from '@app/shared/form/input-mask.directive';
import { SimpleMaskDirective } from '@app/shared/form/simple-mask.directive';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Item } from '@app/shared/models/multipleSelect';
import { AlertService } from '@app/shared/alert';
import { DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent } from '@app/shared/datatable';
import {ProvidersService} from '@app/providers/providers.service';

@Component({
    moduleId: module.id,
    templateUrl: './manage-provider.component.html',
    styleUrls: ['./manage-provider.component.scss'],
    // directives: [ROUTER_DIRECTIVES, InputMaskDirective, SimpleMaskDirective, DataTableDirectives],
    // providers: [RestService],
})

export class ManageProviderComponent implements OnInit, OnDestroy {
    userStatusSubscriber: Subscription;
    userInfoSubscriber: Subscription;
    startManagingSubscriber: Subscription;
    onGetUserSubscriber: Subscription;
    idSelected: number;
    successMessage: string;
    accountList: any = new Object();
    statuses: any = [];
    status: any = [];
    page: number = 1;
    rowOfPage: number = 10;
    errorMessage: string;
    searchText: string = '';
    managingInfo: any;
    managingName: string;
    countData: number = 0;
    isSuperUser: boolean = false;
    runRequest: boolean = true;

    constructor(
        private _systemData: SystemData,
        private _systemEvent: SystemEvent,
        private _systemService: SystemService,
        private _reseService: ProvidersService,
        private _alertService: AlertService,
        // private _routeParams: RouteParams,
        private _router: Router,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _utils: Utils,
        private activeRoute: ActivatedRoute,
    ) {}

    init() {
        this.accountList.data = new Array();
        this.accountList.numberOfPages = 0;
        this.accountList.rowsOnPage = 10;
        this.errorMessage = null;
    }


    ngOnInit() {

        if (this._utils.accessAsSuperOrManagingUser()) {
            if (this._utils.isSuperUser()) {
                this.isSuperUser = true;
                if (this.activeRoute.snapshot.params.id) {
                    this.idSelected = parseInt(this.activeRoute.snapshot.params.id);
                    this.init();
                    this.getStatus();
                    this._systemService.getStates();
                } else {
                    this._router.navigate(['provider', 'manage']);
                }
            } else {
                this.idSelected = parseInt(localStorage.getItem('managing_user_id'));
                this.init();
                this.getStatus();
                this._systemService.getStates();
            }

            this.onGetUserSubscriber = this._reseService.getUserById(this.idSelected).subscribe(
                (success: any) => {
                    this.managingInfo = success.user;
                    this.managingName = this._utils.generateFullName(this.managingInfo.first_name, this.managingInfo.middle_name, this.managingInfo.last_name);
                }
            );
        }
    }

    ngOnDestroy() {
        if (this.userStatusSubscriber) {
            this.userStatusSubscriber.unsubscribe();
        }
        if (this.userInfoSubscriber) {
            this.userInfoSubscriber.unsubscribe();
        }
        if (this.startManagingSubscriber) {
            this.startManagingSubscriber.unsubscribe();
        }
        if (this.onGetUserSubscriber) {
            this.onGetUserSubscriber.unsubscribe();
        }
    }

    getStatus() {
        if (this.userStatusSubscriber) {
            this.userStatusSubscriber.unsubscribe();
        }
        this.userStatusSubscriber = this._reseService.userStatus().subscribe(
        (success: any) => {
            if (success.error_code === 'RC000') {
                success.statuses.forEach((status: any) => {
                    let i = new Item();
                    i.oj = status;
                    this.statuses.push(i);
                });
                this.getInfo(this.page);
            }
        });
    }

    onDataChange(e: any) {
        this.page = e['currentPage'];
        this.getInfo(this.page, this.searchText);
    }

    getInfo(page: number, search_text: string = null) {
        this.runRequest = false;
        this.errorMessage = null;
        this._loadingIndicatorService.start();
        if (this.userInfoSubscriber) {
            this.userInfoSubscriber.unsubscribe();
        }
        let parentId = this.idSelected;
        let practiceId = localStorage.getItem('user_practice');
        this.userInfoSubscriber = this._reseService.getProviderUserInfo(page, this.rowOfPage, search_text, 'PROVIDER', practiceId, parentId, 0).subscribe(
        (success: any) => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
            if (success.error_code === 'RC000') {
                this.accountList.numberOfPages = success.last_page || 0;
                let accounts = success.users || [];
                accounts.map((user: any, i: any) => {
                    let listSpecialtiesName = new Array();
                    var specialties = user.specialties;
                    for (let i = 0; i < specialties.length; i++) {
                        listSpecialtiesName.push(specialties[i].name);
                    }
                    user.specialties = listSpecialtiesName.join(', ');
                    if (user.phone_mobile) {
                        if (user.phone_mobile.indexOf('+1 ') !== -1) {
                            user.phone_mobile = user.phone_mobile.substr(user.phone_mobile.indexOf('+1 ')+3);
                        }
                    }

                    for (let i = 0; i < this.statuses.length; i++) {
                        if (user.status === this.statuses[i].oj.id) {
                            user.status_show = this.statuses[i].oj.name;
                        }
                    }

                    let time = 0;
                    if (user.waittime > 999) {
                        time = 999;
                    } else {
                        time = user.waittime;
                    }
                    user.waittime = this._utils.generateValueUnitDisplay(time, 'min');
                });
                this.accountList.data = accounts;
                this.countData = success.total;
            } else if (success.error_code === 'RC077') {
                this.init();
                this.errorMessage = success.error_messages;
            }
        },
        (error: any) => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
        },
        () => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
        });
    }

    onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
        if (this.runRequest) {
            if (this.page === 1) {
                this.getInfo(this.page, this.searchText.trim());
            } else {
                dataTable.setPage(1, 10);
            }
        }
    }

    startManaging(providerId: any) {
        this._loadingIndicatorService.start();
        if (this.startManagingSubscriber) {
            this.startManagingSubscriber.unsubscribe();
        }
        this.startManagingSubscriber = this._reseService.startManaging({'providerId': providerId}).subscribe(
        (success: any) => {
            this._loadingIndicatorService.stop();
            // Save old data
            let storeData = {
                id_token: localStorage.getItem('id_token'),
                last_active_token: localStorage.getItem('last_active_token'),
                user_id: localStorage.getItem('user_id'),
                user_token: localStorage.getItem('user_token'),
                user_role_id: localStorage.getItem('user_role_id'),
                account_name: localStorage.getItem('account_name'),
                user_status: localStorage.getItem('user_status'),
                user_practice: localStorage.getItem('user_practice'),
                loginEmail: localStorage.getItem('loginEmail'),
                userFullName: localStorage.getItem('userFullName')
            };
            localStorage.setItem('storeData', JSON.stringify(storeData));
            // Save data to local storage
            localStorage.setItem('id_token', success.token);
            localStorage.setItem('last_active_token', success.token);
            localStorage.setItem('user_id', success.user.id);
            localStorage.setItem('user_role_id', success.user.role_id);
            localStorage.setItem('account_name', success.user.account_name);
            localStorage.setItem('user_status', success.user.status);
            localStorage.setItem('user_practice', success.user.practice);
            localStorage.setItem('loginEmail', success.user.email);
            localStorage.setItem('user_token', success.token);
            localStorage.setItem('userFullName', this._utils.generateFullName(success.user_name.first_name, success.user_name.middle_name, success.user_name.last_name));
            this._alertService.onClose = () => {
                // Redirect to Appoinment page
                this._router.navigate(['/provider', 'profile']);
            };
            this._alertService.showDismissButton = false;
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'OK';
            this._alertService.title = success.message;
            this._alertService.message = '';
            this._alertService.emitter('open');
        },
        (error: any) => {
            this._loadingIndicatorService.stop();
            var response = error.json();
            if (response.error_code === 'RC004' || response.error_code === 'RC118') {
                this.openAlertMessage(response.error_messages);
            }
        },
        () => {
            this._loadingIndicatorService.stop();
        });
    }

    openAlertMessage(msg: string) {
        this._alertService.onClose = () => {
            //location.reload();
        };
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }
    reloadProviderList(){
        this.getInfo(this.page, this.searchText);
    }
}
