<!--<rtn-provider-header></rtn-provider-header>-->
  <div class="provider-profile pt-3">
    <div class="col-sm-10 offset-sm-1 col-lg-8 offset-md-2 no-padding-left-right">
        <h1 class="head-title">{{_providerDetail.fullName}}</h1>
        <div *ngIf="_providerDetail.specialties_display !== ''" class="head-content">{{_providerDetail.specialties_display}}</div>
        <div class="horizontal-divider"></div>
        <div class="general-info" *ngIf="!device">
            <div class="col-md-12 no-padding-left-right padding-side-v-5">
              <div class="row">
                <div class="px-0 col-4">
                  <div class="col-md-12 no-padding-left-right title">NPI</div>
                  <div class="col-md-12 no-padding-left-right content">{{_providerDetail.npi}}</div>
                </div>
                <div class="clear-side-v-5 d-md-none"></div>
                <div class="col-4">
                  <div class="col-md-12 no-padding-left-right title">Office/Practice/Hospital Name</div>
                  <div class="col-md-12 no-padding-left-right content">{{_providerDetail.office_name}}</div>
                </div>
                <div class="clear-side-v-5 d-md-none"></div>
                <div class="col-4">
                  <div class="col-md-12 no-padding-left-right title">Hours of Operation</div>
                  <div *ngFor="let item of _providerDetail.opening_hours_display" class="col-md-12 no-padding-left-right content">
                    <span>{{item.time_slot}}</span>
                    <span class="padding-left-15">{{item.time_days}}</span>
                  </div>
                </div>
                <div class="clear-side-v-5 d-md-none"></div>
              </div>

            </div>
            <div class="col-md-12 no-padding-left-right padding-side-v-5">
                <div class="col-md-4 no-padding-left-right">
                    <div class="title">Website:</div>
                    <div class="content text-ellipsis rtn-text-green-medium"><a href="{{_providerDetail.website_display}}" class="link cursorPointer" target="_blank">{{_providerDetail.website_url}}</a></div>
                </div>
            </div>
        </div>
        <div class="general-info" *ngIf="device">
            <div class="col-12 no-padding-left-right padding-side-v-5">
                <div class="col-7 no-padding-left-right">
                    <div class="col-12 no-padding-left-right title">NPI</div>
                    <div class="col-12 no-padding-left-right content">{{_providerDetail.npi}}</div>
                </div>
                <div class="col-5 no-padding-left-right">
                    <div class="col-12 no-padding-left-right title">Office/Practice/Hospital Name</div>
                    <div class="col-12 no-padding-left-right content">{{_providerDetail.office_name}}</div>
                </div>
            </div>
            <div class="col-12 no-padding-left-right padding-side-v-5">
                <div class="col-7 no-padding-left-right">
                    <div class="col-12 no-padding-left-right title">Hours of Operation</div>
                    <div *ngFor="let item of _providerDetail.opening_hours_display" class="col-12 no-padding-left-right content">
                        <span>{{item.time_slot}}</span>
                        <span class="padding-left-15">{{item.time_days}}</span>
                    </div>
                </div>
                <div class="col-5 no-padding-left-right">
                    <div class="title">Website:</div>
                    <div class="content text-ellipsis rtn-text-green-medium"><a href="{{_providerDetail.website_display}}" class="link cursorPointer" target="_blank">{{_providerDetail.website_url}}</a></div>
                </div>
            </div>
        </div>
        <div class="horizontal-divider"></div>
        <div class="contact-info">
            <div class="col-md-6 no-padding-left padding-right-15">
                <div class="col-12 no-padding-left-right contact-block-title">ACCOUNT INFO</div>
                <div class="col-12 no-padding-left-right padding-side-v-5 clearfix">
                    <div class="title pull-left">Phone Number</div>
                    <div class="content pull-right">{{_providerDetail.phone_mobile}}</div>
                </div>
                <div class="col-12 no-padding-left-right padding-side-v-5 clearfix">
                    <div class="title pull-left">Email Address</div>
                    <div class="content pull-right">{{_providerDetail.email}}</div>
                </div>
                <div class="col-12 no-padding-left-right padding-side-v-5 clearfix">
                    <div class="title pull-left">Address</div>
                    <div class="content pull-right">{{_providerDetail.address}}</div>
                    <div class="content pull-right">{{_providerDetail.address2}}</div>
                    <div class="content pull-right">{{_providerDetail.city}}<span *ngIf="_providerDetail.state_code !== null">,</span> {{ _providerDetail.state_code }} {{ _providerDetail.zip }}</div>
                </div>
                <ng-container *ngIf="_providerDetail.office_address || _providerDetail.office_city || _providerDetail.office_state_code || _providerDetail.office_zip">
                    <div class="col-12 no-padding-left-right padding-side-v-5 clearfix">
                        <div class="title pull-left">Office Address</div>
                        <div class="content pull-right">{{_providerDetail.office_address}}</div>
                        <div class="content pull-right">{{_providerDetail.office_address2}}</div>
                        <div class="content pull-right">{{_providerDetail.office_city}}<span *ngIf="_providerDetail.office_state_code !== null">,</span> {{ _providerDetail.office_state_code }} {{ _providerDetail.office_zip }}</div>
                    </div>
                </ng-container>
            </div>
            <div class="clear-side-v-5 d-md-none"></div>
            <!-- <div class="col-md-6 no-padding-right padding-left-15">
                <div class="col-12 no-padding-left-right contact-block-title">BILLING INFO</div>
                <div class="col-12 no-padding-left-right padding-side-v-5">
                    <div class="title pull-left">Full Name</div>
                    <div class="content pull-right">{{_billingInfo.name_display}}</div>
                </div>
                <div class="col-12 no-padding-left-right padding-side-v-5">
                    <div class="title pull-left">Phone Number</div>
                    <div class="content pull-right">{{_billingInfo.phone_mobile}}</div>
                </div>
                <div class="col-12 no-padding-left-right padding-side-v-5">
                    <div class="title pull-left">Email Address</div>
                    <div class="content pull-right">{{_billingInfo.email}}</div>
                </div>
                <div class="col-12 no-padding-left-right padding-side-v-5">
                    <div class="title pull-left">Address</div>
                    <div class="content pull-right" *ngIf="_billingInfo.address">{{_billingInfo.address}}</div>
                    <div class="content pull-right" *ngIf="_billingInfo.address2">{{_billingInfo.address2}}</div>
                    <div class="content pull-right">{{_billingInfo.city}}<span *ngIf="showComma">,</span> {{ _billingInfo.state_code }} {{ _billingInfo.zip }}</div>
                </div>
            </div> -->
        </div>
        <div class="clear-side-v-5 d-md-none"></div>
        <div class="horizontal-divider"></div>
        <!-- <div class="panel-default">
            <div class="panel-heading accepted-insurance-header">
                <h3 class="panel-title text-title-form-signup">Accepted Insurance</h3>
            </div>
            <div class="panel-body accepted-insurance">
                <div *ngFor="let plan of _providerDetail.insurances_name; let i = index">
                    {{plan}}
                </div>
            </div>
        </div> -->
        <div class="panel-default">
            <div class="panel-heading accepted-insurance-header">
                <h3 class="panel-title text-title-form-signup">Accepted Insurance (from the Practice/Hospital)</h3>
            </div>
            <div class="panel-body accepted-insurance">
                <div *ngFor="let insurance of _providerDetail.insurances; let i = index">
                    {{insurance === null ? 'No Insurance/Self Pay' : insurance}}
                </div>
            </div>
        </div>
        <div class="col-md-12 no-padding-left-right">
            <button type="button" class="btn btn-rtn btn-90 btn-bottom-page margin-top-40 text-uppercase" [routerLink]="['/provider', 'edit']">EDIT</button>
        </div>
    </div>
  </div>
