<div class="container admin-account no-padding">
    <h3 class="row d-none d-md-block font-size-24">Account Management</h3>
    <h3 class="row d-block d-sm-none d-none d-sm-block d-md-none text-center font-size-24">Account Management</h3>

  <rtn-table #table
      (onDataChange)="onDataChange($event)"
      [autoloading]="false"
      [data]="accountList.data"
      [rowsOnPage]="accountList.rowsOnPage"
      [numberOfPages]="accountList.numberOfPages">
      <div class="row">
          <rtn-table-filter #filter class="width-100">
              <form #form="ngForm" (ngSubmit)="onSearchSubmited(table)" class="row">
                <div class="col-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 no-padding-left-right">
                    <button type="button" class="btn btn-rtn btn-new-acc dropdown-toggle" data-toggle="dropdown">New Account</button>
                    <div class="dropdown-menu add-account">
                        <div [routerLink]="['/admin', 'add-admin']">Add New Admin Account</div>
                        <div [routerLink]="['/admin', 'add-patient']" *ngIf="arrayModule[4]">Add New Patient Account</div>
                        <div [routerLink]="['/admin', 'add-super-user']" *ngIf="arrayModule[3]" >Add New Super User</div>
                    </div>
                </div>
                  <div class="d-none d-lg-block col-md-2"></div>

                  <div class="col-3 col-xl-3 col-sm-3 col-md-3 col-lg-3 select-box no-padding-left">
                      <input type="text" class="form-control dropdown-toggle" data-toggle="dropdown" readonly name="rolesSelected" [(ngModel)]="rolesSelected">
                      <div class="select-multiple-menu dropdown-menu font-size-13">
                          <div *ngFor="let r of roles" class="select-item" (click)="selectRoleClicked($event, r, table)">
                              <span class="input-rtn">
                                  <input type="checkbox" class="checkbox check-single" [checked]="r.isCheck">
                                  <label class="selectorExtreme rtn-text-grey-85"></label>
                              </span>
                              <span class="select-text">{{ r.oj.name }}</span>
                          </div>
                      </div>
                      <div class="chevron-down-icon">
                          <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                  </div>

                  <div class="col-3 col-xl-3 col-sm-3 col-md-3 col-lg-3 select-box no-padding-left font-size-13">
                      <input type="text" class="form-control dropdown-toggle" data-toggle="dropdown" readonly name="statusesSelected" [(ngModel)]="statusesSelected">
                      <div class="select-multiple-menu dropdown-menu font-size-13">
                          <div *ngFor="let s of statuses" class="select-item" (click)="selectStatusClicked($event, s, table)">
                              <span class="input-rtn">
                                  <input type="checkbox" class="checkbox check-single" [checked]="s.isCheck">
                                  <label class="selectorExtreme rtn-text-grey-85"></label>
                              </span>
                              <span class="select-text">{{ s.oj.name }}</span>
                          </div>
                      </div>
                      <div class="chevron-down-icon">
                          <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                  </div>

                  <div class="col-2 col-xl-2 col-sm-2 col-md-2 col-lg-2 no-padding-left">
                      <input class="form-control" type="text" placeholder="Enter RTN ID, Email or Name" name="searchText" [(ngModel)]="searchText">
                  </div>

                  <button type="submit" class="btn btn-rtn col-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 no-padding">
                    <i class="fas fa-search"></i>
                    <span class="label">Search</span>
                  </button>
              </form>
          </rtn-table-filter>

          <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right search-box listview">
              <table class="table table-bordered">
                  <thead class="account-thead">
                      <tr>
                          <th class="account-thead-text col-rtn-id">RTN ID</th>
                          <th class="account-thead-text col-email">Email</th>
                          <th class="account-thead-text col-full-name">Full Name</th>
                          <th class="account-thead-text col-type">Type</th>
                          <th class="account-thead-text col-ehr-data">EHR Data</th>
                          <th class="account-thead-text col-status">Status</th>
                          <th class="account-thead-text col-event-click"></th>
                      </tr>
                  </thead>

                  <tbody class="account-tbody">
                      <tr *ngFor="let item of table.data; let i = index">
                          <!-- [class.account-id]="item.id != loginEmail || item.role_id === 4 && item.response_status == 1" [class.account-admin]="item.id == loginEmail || item.role_id === 4 && item.response_status == 0" -->
                          <td [class.account-id]="item.id != loginEmail" [class.account-admin]="item.id == loginEmail">
                              <div *ngIf="item.role_id === 6" [routerLink]="['/admin', 'edit-managing-user', item.id]">{{ item.id_show }}</div>
                              <div *ngIf="item.role_id === 5" [routerLink]="['/admin', 'edit-super-user', item.id ]">{{ item.id_show }}</div>
                              <div *ngIf="item.role_id === 4" [routerLink]="['/admin', 'edit-patient',item.id]">{{ item.id_show }}</div>
                              <!--<div *ngIf="item.role_id === 4 && item.response_status == 0">{{ item.id_show }}</div>-->
                              <div *ngIf="item.role_id === 3" [routerLink]="['/admin', 'edit-provider', item.id ]">{{ item.id_show }}</div>
                              <div *ngIf="item.role_id === 2" [routerLink]="['/admin', 'edit-super-provider', item.id]">{{ item.id_show }}</div>
                              <div *ngIf="item.id != loginEmail && item.role_id === 1" [routerLink]="['/admin', 'edit-admin', item.id]">{{ item.id_show }}</div>
                              <div *ngIf="item.id == loginEmail && item.role_id === 1">{{ item.id_show }}</div>
                          </td>
                          <td class="account-email">{{ item.email }}</td>
                          <td class="account-full-name">{{ item.name_show }}</td>
                          <td class="account-type">{{ item.role_id_show }}</td>
                          <td class="account-ehr-data">{{ item.is_mapped }}</td>
                          <td class="account-status">{{ item.status_show }}</td>
                          <td class="account-event-click text-center"> 
                                <ng-container *ngIf="item.role_id === 4">
                                    <ng-container *ngIf="item.response_status == 1">
                                        <button type="button" class="btn btn-rtn btn-90 deactive" *ngIf="item.status_show === 'Full Active' || item.status_show === 'Partial Active'" (click)="deactiveAccount(item.id, table)">Deactivate</button>
                                        <button type="button" class="btn btn-rtn btn-90 active" *ngIf="item.status_show !== 'Full Active' && item.status_show !== 'Partial Active'" (click)="activeAccount(item.id, table)">Activate</button>   
                                    </ng-container>
                                    <ng-container *ngIf="item.response_status == 0">
                                        <button type="button" class="btn btn-rtn btn-90 cursor-default deactive-user-login">Deactivate</button>
                                    </ng-container>
                                </ng-container>  
                                
                                <ng-container *ngIf="item.role_id != 4">
                                    <ng-container *ngIf="item.id != loginEmail && item.id != 1">
                                        <button type="button" class="btn btn-rtn btn-90 deactive" *ngIf="item.status_show === 'Full Active' || item.status_show === 'Partial Active'" (click)="deactiveAccount(item.id, table)">Deactivate</button>
                                        <button type="button" class="btn btn-rtn btn-90 active" *ngIf="item.status_show !== 'Full Active' && item.status_show !== 'Partial Active'" (click)="activeAccount(item.id, table)">Activate</button>
                                    </ng-container>
                                    <ng-container *ngIf="item.id == loginEmail || item.id == 1">
                                        <button type="button" class="btn btn-rtn btn-90 cursor-default deactive-user-login">Deactivate</button>
                                    </ng-container>
                                </ng-container>                           
                              
                          </td>
                      </tr>
                  </tbody>

                  <tfoot *ngIf="table.numberOfPages > 1">
                      <tr>
                          <td colspan="12" class="text-center table-responsive no-overflow-y table-responsive-cell">
                              <div class="page-footer">
                                  <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                              </div>
                          </td>
                      </tr>
                  </tfoot>
              </table>
          </div>
      </div>
  </rtn-table>
  <div class="text-center">{{ errorMessage }}</div>
</div>
