import { AfterViewInit, Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { chartDate } from '@app/shared/models/chartDate';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { AdminRestService } from '@app/admin/admin.service';
import { PatientsService } from '@app/patients/patients.service';
import { AlertService } from '@app/shared/alert';
import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { dataSearchChart } from '@app/shared/models/dataSearchChart';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Utils } from '@app/shared/utils';
import { Router } from '@angular/router';
import { SystemService, SystemData } from '@app/shared/system';

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";

declare let jQuery: any;

@Component({
  selector: 'app-duration-log-by-slot',
  templateUrl: './duration-log-by-slot.component.html',
  styleUrls: ['./duration-log-by-slot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DurationLogBySlotComponent implements OnInit, AfterViewInit, OnDestroy {
  
  currentDate: any = moment();
  data_chart: any;
  error: chartDate = new chartDate();
  getWaittimeByProviderSubscriber: Subscription;
  practiceId = '0';
  providerId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();

  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;

  public isDataAvailable = true;
  public providersList: any = [];
  public practicesList: any;
  public message = '';
  labelAdu: any = 'Average Duration';
  maxDate:any;
  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
  @ViewChild('inputDateFrom') inputPractice: ElementRef;
  @ViewChild('inputDateFrom') inputProvider: ElementRef;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;

  private getIdDatePickerFrom: String = '#inputDateFrom';
  private getIdDatePickerTo: String = '#inputDateFrom1';
  private getErrorMes: String = ' .error_message';
  private setBorderColor: String = 'border-color';
  private removeMessage: String = 'none !important';
  private getIdPractice: String = '#getPractice option:selected';

  private chartdata:any = [];


  public curpage : number = 1;
  public noofdata : number = 0;
  public noofpage : number = 0;
  public isSubmitted : boolean = false;
  constructor(
    private _alertService: AlertService,
    private _patientService: PatientsService,
    private _adminService: AdminRestService,
    private _datalogsService: DataLogsService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    public _systemData: SystemData,
  ) {

    this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.ProviderSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
    const _self = this;
    _self._loadingIndicatorService.start();
    _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
      (success: any) => {
        _self.practicesList = success.practices;
        const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
        _self.getProviderByPractice(firstPractice);
        this._loadingIndicatorService.stop();
      });
  }

  onChangePractice() {
    this.removeMsgErr();
    const practice = this.PracticeSelector.getData();
    if (practice.length !== 0) {
      this.getProviderByPractice(practice);
      this.ProviderSelector.clearData();
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      if (this.providersList.length > 0) {
        this.ProviderSelector.updateData(this.providersList);
      }
    } else {
      this.ProviderSelector.clearData();
    }
  }

  removeMsgErr() {
    this.errorResponse.provider = null;
    this.ProviderSelector.inputError = false;
    this.ProviderSelector.changeBorderColorProvider();
  }

  getProviderByPractice(practiceId: any) {
    this._loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.providersList = [];
          success.providers.forEach((item: any) => {
            item.full_name
              = item.first_name + ((item.middle_name == null)
              ? (' ')
              : (' ' + item.middle_name + ' ')) + item.last_name;
            this.providersList.push(item);
          });
        }
        this._loadingIndicatorService.stop();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
        this.maxDate = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    }else{
        this.maxDate = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(this.inputDateFrom.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        this.dataSearchChart.dateFrom = jQuery(this.inputDateFrom.nativeElement).val();
      }
    });
    jQuery(this.clickDateFrom.nativeElement).click(() => {
      jQuery(this.inputDateFrom.nativeElement).datepicker('show');
    });
    jQuery(this.inputDateFrom.nativeElement).datepicker('setDate', this.dataSearchChart.dateFrom);
    jQuery(this.inputDateTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        this.dataSearchChart.dateTo = jQuery(this.inputDateTo.nativeElement).val();
      }
    });
    jQuery(this.clickDateTo.nativeElement).click(() => {
      jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });
    jQuery(this.inputDateTo.nativeElement).datepicker('setDate', this.dataSearchChart.dateTo);
    jQuery('#getPractice').on('change', function () {
      _self.getListProviderSubscriber
        = _self._datalogsService.getListProviderByPractice(jQuery(_self.getIdPractice).val()).subscribe(
        (success: any) => {
          _self.providersList = success.providers;
        });
    });
    /*jQuery('#exportDataChart').on('click', function () {
      _self.exportData();
    });*/
    jQuery('#showDataChart').on('click', function () {
      //_self.resetData();
      _self.updateData();
    });
  }

  validateDateFromLessDateTo(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    if (date1 > date2) {
      this.errorResponse.dateFrom = message;
      return false;
    } else {
      this.errorResponse.dateFrom = null;
      return true;
    }
  }

  validateDateMoreThan31(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 31) {
      this.errorResponse.dateTo = message;
      return false;
    } else {
      this.errorResponse.dateTo = null;
      return true;
    }
  }

  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.PracticeSelector.inputError = true;
      this.PracticeSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.PracticeSelector.inputError = false;
      this.PracticeSelector.changeBorderColorPractice();
      return true;
    }
  }

  validateProvider(message: any) {
    if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
      this.errorResponse.provider = message;
      this.ProviderSelector.inputError = true;
      this.ProviderSelector.changeBorderColorProvider();
      return false;
    } else {
      this.errorResponse.provider = null;
      this.ProviderSelector.inputError = false;
      this.ProviderSelector.changeBorderColorProvider();
      return true;
    }
  }

  validate() {
    const validatePractice = this.validatePractice('This field is required');
    const validateProvider = this.validateProvider('This field is required');
    const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
    const validateDateMoreThan31
      = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
    return validatePractice && validateProvider && validateDateFromLessDateTo && validateDateMoreThan31;

  }

  verifyDataSearch() {
    this.dataSearchChart.practice = this.PracticeSelector.getData();
    this.dataSearchChart.provider = this.ProviderSelector.getData();
    this.dataSearchChart.type = jQuery('#typeChart option:selected').val();
  }

  updateData() {
    this.verifyDataSearch();
    const dateFrom = this.dataSearchChart.dateFrom;
    const dateTo = this.dataSearchChart.dateTo;
    const providerId = this.dataSearchChart.provider;
    const practiceID = this.dataSearchChart.practice;
    const type = this.dataSearchChart.type;
    if (this.validate()) {
      this.getDurationLog(dateFrom, dateTo, providerId, type, practiceID);
    }
  }

  exportData() {
    this.verifyDataSearch();
    const _self = this;

    const startTime = _self.dataSearchChart.dateFrom;
    const endTime = _self.dataSearchChart.dateTo;
    const practiceID = _self.dataSearchChart.practice;
    const providerID = _self.dataSearchChart.provider;
    if (_self.validate()) {
      const token = localStorage.getItem('user_token');
      jQuery.fileDownload(_self._datalogsService.scheduleExportUrl, {
        httpMethod: 'POST',
        data: {
          'token': token,
          'practice': practiceID,
          'providerId': providerID,
          'startTime': startTime,
          'endTime': endTime,
          'chartType': 4
        }
      }).done(function () {
        // TODO
      })
        .fail(function () {
          _self._alertService.onClose = () => {
            location.reload();
          };         
        });
    }
  }

  getDurationLog(dateFrom: any, dateEnd: any, providerId: any, type: any, practice: any) {
    const self = this;
    this._loadingIndicatorService.start();
    this.chartdata = [];

    this._datalogsService.getDurationLog(dateFrom, dateEnd, providerId, type, practice).subscribe(
      (success: any) => {
        
        this._loadingIndicatorService.stop();
        this.data_chart = success.data_chart;
        
        if (this.data_chart !== undefined && this.data_chart.length > 0) {
          for (let index = 0; index < this.data_chart.length; index++) {
            
            let datainfo:any = {};
            datainfo.label        = this.data_chart[index].plan_duration;
            datainfo.value        = this.data_chart[index].avg_actual_duration;
            datainfo.quantity     = this.data_chart[index].quatity;
            this.chartdata.push(datainfo);            
          }
        }
        this.noofdata = this.chartdata.length;
        //console.log(this.chartdata);
        this.curpage = 1;
        if (this.noofdata > 0) {
          this.chartdata.reverse();
        }
        //console.log(this.chartdata);
        this.initBarchart();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }
  initBarchart(){
    this.isSubmitted = true;
    d3.selectAll("svg > *").remove();
    d3.selectAll('#myTooltip').remove();
    
    if (document.getElementById("barchart") != undefined && document.getElementById("barchart")!= null) {
      document.getElementById("barchart").innerHTML = "";
    }

    if (this.noofdata == 0){
      return false;
    }   
    var margin = {
      top: 15,
      right: 25,
      bottom: 30,
      left: 80
  };
  
  var width = 960 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;
  
  var x = d3Scale.scaleLinear().rangeRound([0, width]);
  var y = d3Scale.scaleBand().rangeRound([height, 0]).padding(0.2);
      
      //Append the svg to body  
     
  var svg = d3.select("#barchart").append("svg")
            .attr('width', width + margin.left + margin.right )
            .attr('height', height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  let maxvalue = d3Array.max(this.chartdata, function(d:any) { return d.value; });
  let maxlabel = d3Array.max(this.chartdata, function(d:any) { return d.label; });
  //console.log(maxvalue);
  x.domain([0, parseInt(maxvalue)]);
  y.domain(this.chartdata.map(function(d:any) { return d.label; })); 

  var tooltip = d3.select("body")
    .append("div")
    .attr('id', 'myTooltip')
    .style("position", "absolute")
    .style("z-index", "10")
    .style("visibility", "hidden")
    .text("");

  svg.selectAll(".bar")
    .data(this.chartdata)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("x", 0)
    .attr("width", function(d:any) { return x(d.value);})
    .attr("y", function(d:any) { return y(d.label); })
    .attr("height", y.bandwidth())
    .on("mouseover", function(d:any){
      var newHtml = '';
      var newHtml = '';
      newHtml += '<p><span class="lbltext">Slot '+d['label']+'m</span></p>';
      newHtml += '<p><span class="lbltext">Quantity </span><span>: ' + d['quantity'] + '</span></p>';
      newHtml += '<p><span class="lbltext">Average Duration </span><span>: ' + d['value'] + '</span></p>';
    
      tooltip.html(newHtml);
      return tooltip.style("visibility", "visible");
      })
      .on("mousemove", function(){
        var pageX = d3['event']['pageX'];
        var pageY = d3['event']['pageY'];
        return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");    
    })
    .on("mouseout", function(){     
      return tooltip.style("visibility", "hidden");
    });

  svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3Axis.axisBottom(x).tickFormat(function(d:any) { return d + " min"; }));

  svg.append("g")
      .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return "Slot " + d + "m"; })); 
  }
  printChart(){    
    window.print();
  }
}