export class OpeningHourSlot {
    days: any = {
        day0: false,
        day1: false,
        day2: false,
        day3: false,
        day4: false,
        day5: false,
        day6: false,
    };
    start_time: string = '00:00';
    end_time: string = '00:00';
}
