import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { RtnConfig } from '@app/shared/rtnconfig';
import { map } from 'rxjs/operators';
import { UtilsService } from '@app/core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {
  getPracticeListUrl = environment.serverUrl + '/practices';
  addNewManagingUserUrl = environment.serverUrl + '/users/super-managing-users';
  addPracticeUrl = environment.serverUrl + '/practice/add';
  addNewProviderUrl = environment.serverUrl + '/provider/add';
  changePasswordProviderURL = environment.serverUrl + '/users/change-password';
  getProviderProfileUrl = environment.serverUrl + '/provider/profile';
  getUserDetailsUrl = environment.serverUrl + '/users/';
  updateManagingUserFormUrl = environment.serverUrl + '/users/super-managing-users/';
  getUserUrl = environment.serverUrl + '/users/info';
  updateUserUrl = environment.serverUrl + '/users/info';
  userStatusUrl = environment.serverUrl + '/users/statuses';
  getListManagingUserInfoUrl = environment.serverUrl + '/users/managing/list';
  userInfoUrl = environment.serverUrl + '/users/patient/list';
  getProviderUserInfoUrl = environment.serverUrl + '/users/get-list-by-parent';
  startMappingUrl = environment.serverUrl + '/provider/managing/start';
  getUserByIdUrl = environment.serverUrl + '/users/infoById/';
  mappingProviderUrl = environment.serverUrl + '/provider/mapping';
  unMappingProviderUrl = environment.serverUrl + '/provider/unmapping';
  signUpNewPatientUrl = environment.serverUrl + '/users/sign-up';
  getInsuranceListUrl = environment.serverUrl + '/insurance-list';
  getProfileUrl = environment.serverUrl + '/practice/profile';
  getNoteFromPracticeUrl = environment.serverUrl + '/practice/get-note';
  updatePracticeUrl = environment.serverUrl + '/practice/update';
  searchOpeningsUrl = environment.serverUrl + '/appointment/openings';
  updateIsLockProv = environment.serverUrl + '/provider/update-islock';
  viewApointmentUrl = environment.serverUrl + '/appointment/list/';

  getActiveAccount = environment.serverUrl + '/manage-users/activate';
  getDeactiveAccount = environment.serverUrl + '/manage-users/deactivate';

  updatePasswordFromSuperuserURL: any = environment.serverUrl + '/manage-users/update-password-from-superuser/{0}';
  listAptTypeUrl = environment.serverUrl + '/apttypes/list';
  addAptTypeUrl = environment.serverUrl + '/apttypes/save';

  fetchappointmentTypeUrl = environment.serverUrl + '/apttypes/fetchtypes';
  fetchProviderLocationUrl = environment.serverUrl + '/provider/fetchProviderLocation';

  constructor(private _http: HttpClient) {
  }
  debugMode() {
    return !environment.production;
  }
  searchOpenings(
    limit: any,
    page: any,
    patient_name: string,
    family_id: any,
    disease: string,
    specialty: string,
    provider: string,
    insurance_id: string,
    insurance_number: string,
    zip: string,
    distance: string,
    show: string
  ) {
    const params = new HttpParams()
      .set('limit', limit)
      .set('page', page)
      .set('patient_name', patient_name)
      .set('family_id', family_id)
      .set('disease', disease)
      .set('specialty', specialty)
      .set('provider', provider)
      .set('insurance_id', insurance_id)
      .set('insurance_number', insurance_number)
      .set('zip', zip)
      .set('distance', distance)
      .set('show', show);
    return this._http.get(this.searchOpeningsUrl, { params: params });
  }
  updateIsLockProvider(data: any) {
    return this._http.post(this.updateIsLockProv, data);
  }
  updatePractice(value: any) {
    return this._http.put(this.updatePracticeUrl, JSON.stringify(value));
  }

  getNoteFromPractice() {
    return this._http.get(this.getNoteFromPracticeUrl);
  }

  getProfile() {
    return this._http.get(this.getProfileUrl);
  }

  getInsuranceList() {
    return this._http.get(this.getInsuranceListUrl);
  }

  signUpNewPatient(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('api-version', '3.0');
    return this._http.post(this.signUpNewPatientUrl, data, {
      headers: headers
    }).pipe(
      map(results => results)
    );
    // return this._http.post(this.signUpNewPatientUrl, JSON.stringify(data));
  }

  unMappingProvider(data: any) {
    return this._http.post(this.unMappingProviderUrl, data);
  }

  mappingProvider(data: any) {
    return this._http.post(this.mappingProviderUrl, data);
  }

  getUserById(id: any) {
    return this._http.get(this.getUserByIdUrl + id);
  }

  startManaging(providerId: any) {
    const params = new HttpParams().set('providerId', providerId);
    return this._http.post(this.startMappingUrl, providerId);
  }

  getProviderUserInfo(
    page: any,
    limit: any,
    search_text: any,
    role: any,
    practiceId: any,
    parentId: any,
    mappingId: any
  ) {
    let params = new HttpParams()
      .set('limit', limit)
      .set('role', role)
      .set('page', page)
      .set('practiceId', practiceId);
    if (search_text != null) {
      params = params.set('search_text', encodeURIComponent(search_text));
    }
    if (parentId != null) {
      params = params.set('parentId', parentId);
    }
    if (mappingId != null && mappingId !== 0) {
      params = params.set('mappingId', mappingId);
    }
    return this._http.get(this.getProviderUserInfoUrl + '', { params });
  }

  userInfo(page: any, limit: any, searchText: any) {
    if (searchText == null) {
      searchText = '';
    }
    const params = new HttpParams().set('page', page).set('limit', limit).set('search_text', encodeURIComponent(searchText));
    return this._http.get(this.userInfoUrl, { params });
  }

  getListManagingUserInfo(page: any, limit: any, searchText: any) {
    let params = new HttpParams();
    if (searchText != null) {
      params = params.set('page', page).set('limit', limit).set('search_text', encodeURIComponent(searchText));
    } else {
      searchText = '';
      params = params.set('page', page).set('limit', limit).set('search_text', searchText);
    }
    return this._http.get(this.getListManagingUserInfoUrl, { params });
  }

  userStatus() {
    return this._http.get(this.userStatusUrl);
  }

  updateUser(value: any) {
    return this._http.put(this.updateUserUrl, JSON.stringify(value));
  }

  getUser() {
    return this._http.get(this.getUserUrl).pipe(
      map(results => results)
    );
  }

  updateManagingUserForm(id: any, data: any) {
    return this._http.put(this.updateManagingUserFormUrl + id, data);
  }

  getUserDetail(id: any) {
    return this._http.get(this.getUserDetailsUrl + id);
  }

  updateProviderProfile(data: any) {
    return this._http.put(this.getProviderProfileUrl, data);
  }

  changePasswordProvider(params: any) {
    return this._http.put(this.changePasswordProviderURL, params);
  }

  addNewProvider(value: any) {
    return this._http.post(this.addNewProviderUrl, JSON.stringify(value));
  }

  addPractice(data: any) {
    return this._http.post(this.addPracticeUrl, data);
  }

  addNewManagingUser(data: any) {
    return this._http.post(this.addNewManagingUserUrl, data);
  }

  getPracticeList() {
    return this._http.get(this.getPracticeListUrl);
  }

  getApointmentList(userId: any) {
    return this._http.get(this.viewApointmentUrl + userId);
  }
  activeAccount(params: any) {
    return this._http.put(this.getActiveAccount, params).pipe(
      map(results => results)
    );
  }

  deactiveAccount(params: any) {
    return this._http.put(this.getDeactiveAccount, params).pipe(
      map(results => results)
    );
  }
  
  updatePasswordFromSuperuser(id: number | string, params: any) {
    return this._http.put(this.updatePasswordFromSuperuserURL.format(id), params).pipe(
      map(results => results)
    );
  }
  /* Apt Type */
  getAptTypeList(
    page: any,
    limit: any,
    search_text: any,    
    practiceId: any
  ) {
    let params = new HttpParams()
      .set('limit', limit)      
      .set('page', page)
      .set('practiceId', practiceId);
    if (search_text != null) {
      params = params.set('search_text', encodeURIComponent(search_text));
    }
    
    return this._http.get(this.listAptTypeUrl + '', { params });
  }
  addAptType(data: any) {
    return this._http.post(this.addAptTypeUrl, data);
  }
  fetchAppointmentType(practiceId: any) {
    let params = new HttpParams().set('practiceId', practiceId); //.set('is_limit','1')
    return this._http.get(this.fetchappointmentTypeUrl + '', { params });
  }
  /*fetchProviderLocation() {    
    return this._http.get(this.fetchProviderLocationUrl);
  }*/
}
