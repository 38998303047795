import { Component, Output, EventEmitter, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MODAL_DIRECTIVES, ModalComponent } from '@app/shared/ng2-bs3-modal';
// import { ModalsService } from '../service/modals.service'; // Temporary
import {RestService} from '@app/core/rest.service';

@Component({
  selector: 'app-document-delete',
  templateUrl: './document-delete.component.html',
  styleUrls: ['./document-delete.component.scss']
})

export class DocumentDeleteComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('DocumentDelete_Modals') modal: ModalComponent;
  messageConfirmDelete: string = 'Are you sure you want to delete the selected document(s)?';
  messageMinimumSelect: string = 'You need to select at least one document.';
  minimumSelectedStatus: boolean = false;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  constructor(
      // private _modalsService: ModalsService,
      private Service: RestService,
  ) {
      //TODO
  }

  // ========== Specific Modal Method ========== //

  deleteDocuments(e: any) {
      this.onClick.emit(e);
  }

  // ========== Class Method ========== //

  ngOnInit() {
      //TODO
  }

  ngOnDestroy() {
      //TODO
  }

  ngAfterViewInit() {
      //TODO
  }


  // ========== General Modal Method ========== //

  open() {
      this.modal.open();
  }
}
