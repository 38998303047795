import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {Role} from '@app/core/role';
import {AuthGuard} from '@app/core/auth.guard';

import { ProviderDataLogsComponent } from './provider-data-logs.component';
import { ProviderStatisticalTypeofappointmentComponent } from './provider-statistical-typeofappointment/provider-statistical-typeofappointment.component';

const routes: Routes = [{
  path: '',
  component: ProviderDataLogsComponent,
  children: [
      {
          path: 'provider/statistical-appointment-by-types',
          component: ProviderStatisticalTypeofappointmentComponent,
          data: {roles: [Role.PROVIDER]}, 
          canActivate: [AuthGuard]
      }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderDataLogsRoutingModule { }
