import { AfterViewInit, Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { AdminRestService } from '@app/admin/admin.service';

import { AlertService } from '@app/shared/alert';

import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { dataSearchChart } from '@app/shared/models/dataSearchChart';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Utils } from '@app/shared/utils';

import { SystemData } from '@app/shared/system';

import { Select2EventtypeDirective } from '@app/shared/form/select2-eventtype.directive';

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";
import * as d3Shape from 'd3-shape';
import * as d3TimeFormat from 'd3-time-format';
import * as d3Collection from 'd3-collection';
//import * as d3Selection from 'd3-selection';


declare let jQuery: any;

@Component({
  selector: 'app-schedule-arrival-compare',
  templateUrl: './schedule-arrival-compare.component.html',
  styleUrls: ['./schedule-arrival-compare.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleArrivalCompareComponent implements OnInit, AfterViewInit, OnDestroy {

  currentDate: any = moment();
  maxDate: any;
  heightCanvas: number;
  minHeight: number;
  data_chart: any;
 
  geByProviderSubscriber: Subscription;
    
  //widthChart: number;
  practiceId = '0';
  providerId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();

  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;

  public providersList: any = [];
  public practicesList: any = [];  

  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef; 

  private chartdata:any = [];

  private chartWidth: number;
  private chartHeight: number;
  private chartMargin = { top: 40, right: 40, bottom: 100, left: 80 };
  
  public curpage : number = 1;
  public noofdata : number = 0;
  public noofpage : number = 0;
  public isSubmitted : boolean = false;
  public isPrintview : boolean = false;
  public noofpageArr:any = [];
  public resultMode:string = 'fullview';

  @ViewChild('Select2EventtypeDirective') eventtypeSelector: Select2EventtypeDirective;
  eventtypeList: any;
  geteventtypeSubscriber: Subscription;
  mindatetime:any = null;
  maxdatetime:any = null;

  constructor(
    private _alertService: AlertService,
    //private _patientService: PatientsService,
    private _adminService: AdminRestService,
    private _datalogsService: DataLogsService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    public _systemData: SystemData,

  ) {
    //this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    //this.dataSearchChart.type = 1;

    let width = 1400;
    width = (window.innerWidth > width) ? (window.innerWidth - 600) : (window.innerWidth - 400);
    this.chartWidth = width - this.chartMargin.left - this.chartMargin.right;
    this.chartHeight= 800 - this.chartMargin.top - this.chartMargin.bottom;

    //console.log('window.innerWidth : ' + window.innerWidth);
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.ProviderSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));

    //this.eventtypeSelector.clearData();
    //this.eventtypeSelector.resetSelect2(jQuery('#select2Eventtype'));
    
    const _self = this;
    _self._loadingIndicatorService.start();
    _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
      (success: any) => {
        _self.practicesList = success.practices;
        const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
        _self.getProviderByPractice(firstPractice);
        this._loadingIndicatorService.stop();
      });
  }  
  getProviderByPractice(practiceId: any) {
    this._loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.providersList = [];
          success.providers.forEach((item: any) => {
            item.full_name
              = item.first_name + ((item.middle_name == null)
                ? (' ')
                : (' ' + item.middle_name + ' ')) + item.last_name;
            this.providersList.push(item);
          });
        }
        this._loadingIndicatorService.stop();
      },
      (error: any) => {

        this._loadingIndicatorService.stop();
      }
    );
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
      this.maxDate = moment().subtract(2, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    } else {
      this.maxDate = moment().subtract(1, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(this.inputDateTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        this.dataSearchChart.dateTo = jQuery(this.inputDateTo.nativeElement).val();
      }
    });
    jQuery(this.clickDateTo.nativeElement).click(() => {
      jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });
    jQuery(this.inputDateTo.nativeElement).datepicker('setDate', this.dataSearchChart.dateTo);

    /*jQuery('#exportDataChart').on('click', function () {
      _self.exportdata();
    });*/
    jQuery('#showDataChart').on('click', function () {
      //_self.resetData();
      _self.updateData();
    });
  }

  onChangePractice() {
    this.removeMsgErr();
    const practice = this.PracticeSelector.getData();
    if (practice.length !== 0) {
      this.getProviderByPractice(practice);
      this.ProviderSelector.clearData();
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      if (this.providersList.length > 0) {
        this.ProviderSelector.updateData(this.providersList);
      }
    } else {
      this.ProviderSelector.clearData();
    }
  }
  
  exportdata() {
    this.verifyDataSearch();
    const _self = this;
    const startTime = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;
    const providerID = this.dataSearchChart.provider;
    //const type = this.dataSearchChart.type;
    //const event_reason_id = this.eventtypeSelector.getData();

    //console.log(this.validate());

    if (this.validate()) {
      const token = localStorage.getItem('user_token');
      jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
        httpMethod: 'POST',
        data: {
          'token': token,
          'practice': practiceID,
          'providerId': providerID,
          'startTime': startTime,
          'chartType': 2
        }
      }).done(function () {
        // TODO
      })
        .fail(function () {
          _self._alertService.onClose = () => {
            location.reload();
          };
        });
    }
  }

  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.PracticeSelector.inputError = true;
      this.PracticeSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.PracticeSelector.inputError = false;
      this.PracticeSelector.changeBorderColorPractice();
      return true;
    }
  }

  validateProvider(message: any) {
    if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
      this.errorResponse.provider = message;
      this.ProviderSelector.inputError = true;
      this.ProviderSelector.changeBorderColorProvider();
      return false;
    } else {
      this.removeMsgErr();
      return true;
    }
  }

  removeMsgErr() {
    this.errorResponse.provider = null;
    this.ProviderSelector.inputError = false;
    this.ProviderSelector.changeBorderColorProvider();
  }

  validate() {
    const validatePractice = this.validatePractice('This field is required');
    const validateProvider = this.validateProvider('This field is required');
    if (validatePractice && validateProvider) {
      return true;
    }
    return false;
  }

  updateData() {
    this.verifyDataSearch();
    const date = this.dataSearchChart.dateTo;
    const providerId = this.dataSearchChart.provider;
    const practice = this.dataSearchChart.practice;    

    if (this.validate()) {
      this.getScheduleArrivalCompare(date, providerId, practice);
    }
  }

  verifyDataSearch() {
    this.dataSearchChart.practice = this.PracticeSelector.getData();
    this.dataSearchChart.provider = this.ProviderSelector.getData();
    this.dataSearchChart.type = jQuery('#typeChart option:selected').val();
  }
  
  getScheduleArrivalCompare(date: any, providerId: any, practice: any) {
    const self = this;
    this.isSubmitted = true;
    this._loadingIndicatorService.start();
    this.chartdata = [];
    this.mindatetime = null;
    this.maxdatetime = null;

    this._datalogsService.getScheduleArrivalCompare(date, practice, providerId).subscribe(
      (success: any) => {
        
        this._loadingIndicatorService.stop();

        let tempdata:any = success.data_chart;
        

        if (tempdata !== undefined && tempdata.length > 0) {
          const length = tempdata.length;
          this.chartdata = tempdata;

          this.mindatetime = success.mindatetime;
          this.maxdatetime = success.maxdatetime;
        }

        this.noofdata = this.chartdata.length;
        this.curpage = 1; 
        
        this.drawMultiBarChart();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }
  
  printChart(){    
    window.print();

  }
  drawMultiBarChart(){

    d3.selectAll("svg > *").remove();
    d3.selectAll('myTooltip').remove();

    this.printBarChart('groupchartbody_0');
    
  }
  printBarChart(eleId:any){
    
    var trendsText = {'Planned': 'Planned', 'Arrived': 'Arrived', 'Actual': 'Actual'};

    if (document.getElementById(eleId) != undefined && document.getElementById(eleId)!= null) {
      document.getElementById(eleId).innerHTML = "";
    }

    if (this.noofdata == 0) {
      return false;
    }

    let tempdata:any = this.chartdata;

    let svg = d3.select("#" + eleId).append('svg');

		  svg.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
      svg.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
      
      var g = svg.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");

      var x = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).padding(1),
          y = d3Scale.scaleTime().rangeRound([this.chartHeight, 0]),        
          z = d3Scale.scaleOrdinal(['#036888','#0D833C','#D2392A']);

      // define the line
      var line = d3Shape.line()
        .defined(function(d:any) { return (d.total != 0 && d.total != null); })
        .x(function(d:any) { return x(d.timescale); })
        .y(function(d:any) { return y(d.total); });

      // scale the range of the data
      z.domain(d3Collection.keys(tempdata[0]).filter(function(key:any) {
        return (key !== "timescale" && key !== "comparedata");
      }));    

      var parseDate = d3TimeFormat.timeParse("%Y-%m-%d %H:%M:%S");
      var parseTime = d3TimeFormat.timeParse("%H:%M");

      var trends = z.domain().map(function(name:any) {
        return {
          name: name,
          values: tempdata.map(function(d:any) {
            return {
              timescale: d.timescale,            
              total: parseDate(d[name])
            };
          })
        };
      });

      var minTime = new Date(Date.parse(this.mindatetime));
      var maxTime = new Date(Date.parse(this.maxdatetime));

      x.domain(tempdata.map(function(d:any) { return d.timescale; }));
      y.domain([minTime, maxTime]);

      var cWidth = this.chartWidth;
      var cHeight = this.chartHeight;

      var legend = g.selectAll('g')
        .data(trends)
        .enter()
        .append('g')
        .attr('class', 'legend');
  
      legend.append('rect')
        .attr('x', 10)
        .attr('y', function(d:any, i:any) { return (i + 1) * 20; })
        .attr('width', 10)
        .attr('height', 10)
        .style('fill', function(d:any) { return z(d.name); });
  
      legend.append('text')
        .attr("x", 25)                
        .attr('y', function(d:any, i:any) { return (i + 1) * 20 + 10; })
        .text(function(d:any) { return trendsText[d.name]; });

      // Draw the line
      var trend = g.selectAll(".trend")
        .data(trends)
        .enter()
        .append("g")
        .attr("class", "trend")
        
  
      trend.append("path")
        .attr("class", "line")        
        .attr("d", function(d:any) { return line(d.values); })
        .style("stroke", function(d:any) { return z(d.name); });
        
      // Draw the empty value for every point
      var points = g.selectAll('.points')
        .data(trends)
        .enter()
        .append('g')
        .attr('class', 'points')
        .append('text');
  
      // Draw the circle
      trend
        .style("fill", "#FFF")
        .style("stroke", function(d:any) { return z(d.name); })
        .selectAll("circle.line")
        .data(function(d:any){ return d.values })
        .enter()
        .append("circle")
        .attr("r", 2)
        .style("stroke-width", 5)
        .attr("cx", function(d:any) { return x(d.timescale); })
        .attr("cy", function(d:any) { return y(d.total); });  

      // Draw the axis
      g.append("g")
        .attr("class", "axis axis-x")
        .attr("transform", "translate(0, " + cHeight + ")")
        .call(d3Axis.axisBottom(x))
        .selectAll("text")  
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");
  
      g.append("g")
        .attr("class", "axis axis-y")
        .call(d3Axis.axisLeft(y).ticks(28));

      var focus = g.append('g').attr('class', 'focus').style('display', 'none');
  
      focus.append('line')
      .attr('class', 'x-hover-line hover-line')
      .attr('y1' , 0)
      .attr('y2', cHeight);

      var timeScales = tempdata.map(function(name:any) { return x(name.timescale); });

      var div = d3.select("#groupchartbody_0").append("div").attr("class", "myTooltip").style("opacity", 0).style("position", "absolute").style("z-index", "10");

      svg.append('rect')
        .attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")")
        .attr("class", "overlay")
        .attr("width", cWidth)
        .attr("height", cHeight)
        .data(trends)
        .on("mouseover", (d:any) => {

          focus.style("display", null);
          d3.selectAll('.points text').style("display", null);

          var pageX = d3['event']['clientX'];
          var pageY = d3['event']['clientY'];

          var rectBoundry = document.getElementById(eleId).getBoundingClientRect();
          pageX = pageX - rectBoundry['x'] + 10
          pageY = pageY - rectBoundry['y'] + 10

          var i = d3Array.bisect(timeScales, pageX, 1);
          var di = tempdata[i-1];

         // console.log(di);

          var arrivedTxt = (di.Arrived != null) ? di.Arrived : '';
          var actualTxt = (di.Actual != null) ? di.Actual : '';

          var htmlStr = '';
          htmlStr += '<p><span class="lbltext">Slot </span><span>: ' + di.timescale + '</span></p>';
         // htmlStr += '<p><span class="lbltext">Arrival to apt time</span> : <span>' + di.comparedata.temp_arrived_to_pi + '</span></p>';
          //htmlStr += '<p><span class="lbltext">Arrival to checkin</span> : <span>' + di.comparedata.temp_arrived_to_ai + '</span></p>';
          //htmlStr += '<p><span class="lbltext">Checkin to apt time</span> : <span>' + di.comparedata.temp_ai_to_pi + '</span></p>';

          htmlStr += '<p><span class="lbltext">Planned </span><span>: ' + di.Planned + '</span></p>';
          htmlStr += '<p><span class="lbltext">Arrived</span> : <span>' + arrivedTxt + '</span></p>';
          htmlStr += '<p><span class="lbltext">Actual</span> : <span>' + actualTxt + '</span></p>';

          htmlStr += '<p><span class="lbltext">Arrival to apt time</span> : <span>' + di.comparedata.arrived_to_pi + '</span></p>';
          htmlStr += '<p><span class="lbltext">Arrival to checkin</span> : <span>' + di.comparedata.arrived_to_ai + '</span></p>';
          htmlStr += '<p><span class="lbltext">Checkin to apt time</span> : <span>' + di.comparedata.ai_to_pi + '</span></p>';

          div.style("opacity", 1);
          div.html(htmlStr).style("left", (pageX) + "px").style("top", (pageY - 28) + "px");

        })
        .on("mousemove", (d:any) => {
          
          var pageX = d3['event']['clientX'];
          var pageY = d3['event']['clientY'];

          var rectBoundry = document.getElementById(eleId).getBoundingClientRect();
          pageX = pageX - rectBoundry['x'] + 10;
          pageY = pageY - rectBoundry['y'] + 10;

          var i = d3Array.bisect(timeScales, pageX, 1);
          var di = tempdata[i-1];
          
          //console.log(di);

          var arrivedTxt = (di.Arrived != null) ? di.Arrived : '';
          var actualTxt = (di.Actual != null) ? di.Actual : '';
          
          var htmlStr = '';
          htmlStr += '<p><span class="lbltext">Slot </span><span>: ' + di.timescale + '</span></p>';
         // htmlStr += '<p><span class="lbltext">Arrival to apt time</span> : <span>' + di.comparedata.temp_arrived_to_pi + '</span></p>';
          //htmlStr += '<p><span class="lbltext">Arrival to checkin</span> : <span>' + di.comparedata.temp_arrived_to_ai + '</span></p>';
          //htmlStr += '<p><span class="lbltext">Checkin to apt time</span> : <span>' + di.comparedata.temp_ai_to_pi + '</span></p>';

          htmlStr += '<p><span class="lbltext">Planned </span><span>: ' + di.Planned + '</span></p>';
          htmlStr += '<p><span class="lbltext">Arrived</span> : <span>' + arrivedTxt + '</span></p>';
          htmlStr += '<p><span class="lbltext">Actual</span> : <span>' + actualTxt + '</span></p>';

          htmlStr += '<p><span class="lbltext">Arrival to apt time</span> : <span>' + di.comparedata.arrived_to_pi + '</span></p>';
          htmlStr += '<p><span class="lbltext">Arrival to checkin</span> : <span>' + di.comparedata.arrived_to_ai + '</span></p>';
          htmlStr += '<p><span class="lbltext">Checkin to apt time</span> : <span>' + di.comparedata.ai_to_pi + '</span></p>';

          div.style("opacity", 1);
          div.html(htmlStr).style("left", (pageX) + "px").style("top", (pageY - 28) + "px");
          
          focus.attr("transform", "translate(" + x(di.timescale) + ",0)");
          d3.selectAll('.points text')
          .attr('x', function(d:any) { return x(di.timescale) + 15; })
          .attr('y', function(d:any) { return y(d.values[i-1].total); })          
          .style('fill', function(d:any) { return z(d.name); });
        })
        .on("mouseout", (event:any) => {
          focus.style("display", "none");
          d3.selectAll('.points text').style("display", "none");        
          div.style("opacity", 0);
        });
  }    
}
