<div class="col-xs-12 no-padding-left-right text-header-title margin-bottom-15 text-uppercase">
    Future
</div>
<div class="col-xs-12 appointment-table margin-bottom-20">
    <rtn-table #tableFuture (onDataChange)="onDataChange($event)" [autoloading]="false" [data]="appointmentInFutureData">
        <div class="row">
            <div class="col-sm-12 no-padding-left-right listview">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="thead-text col-view">View</th>
                            <th class="thead-text col-date">Date Time</th>
                            <th class="thead-text col-patient">Patient</th>
                            <th class="thead-text col-provider">Provider</th>
                            <th class="thead-text col-specialty">Specialty</th>
                            <th class="thead-text col-address">Address</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of tableFuture.data; let i = index" (click)="showWaitingTimeClicked(item)"
                            [class.has-wait-time]="item.change_color" [class.selected-tr]="item.isSelected">
                            <td class="col-view"><a (click)="viewDetailAppt(item.plan_check_in)">Details</a></td>
                            <td class="col-date">
                                <div>{{ _utils.getDate(item.plan_check_in) }}</div>
                                <div>
                                    <span>{{ _utils.getTime(item.plan_check_in) }}</span>
                                    <span>-</span>
                                    <span>{{ _utils.getTime(item.plan_check_out) }}</span>
                                </div>
                            </td>
                            <td class="col-patient">
                                {{ _utils.generateFullName(item.patient_first_name, item.patient_middle_name, item.patient_last_name) }}
                            </td>
                            <td class="col-provider">
                                <span>{{ _utils.generateFullName(item.provider_first_name, item.provider_middle_name, item.provider_last_name) }}</span><span
                                    *ngIf="item.credentials">,</span>
                                <span>{{ item.credentials }}</span>
                            </td>
                            <td class="col-specialty">{{ _utils.getSpecialties(item.specialties) }}</td>
                            <td class="col-address">
                                <div>{{ item.address }}</div>
                                <div>{{ item.address2 }}</div>
                                <div>
                                    <span>{{ item.city }}</span><span *ngIf="item.state_code !== null">,</span>
                                    <span>{{ item.state_code }}</span>
                                    <span>{{ item.zip }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngIf="tableFuture.data.length==0" class="no-saved-search">
                            <td colspan="8">There is no results</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </rtn-table>
</div>
<div class="col-xs-12" [class.no-result]="findInFutureMsg !== null">{{ findInFutureMsg }}</div>
<div class="col-xs-12 no-padding-left-right text-header-title margin-bottom-15 text-uppercase">
    Past
</div>
<div class="col-xs-12 appointment-table margin-bottom-20">
    <rtn-table #table (onDataChange)="onDataChange($event)" [autoloading]="false" [data]="appointmentInPastData">
        <div class="row">
            <div class="col-sm-12 no-padding-left-right listview">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="thead-text col-view">View</th>
                            <th class="thead-text col-date">Date Time</th>
                            <th class="thead-text col-patient">Patient</th>
                            <th class="thead-text col-provider">Provider</th>
                            <th class="thead-text col-specialty">Specialty</th>
                            <th class="thead-text col-address">Address</th>
                            <!-- <th class="thead-text col-action">
                                views
                                 <div class="bulk btn-group navbar-right disable">
                                    <div class="dropdown">
                                        <a href="#" data-toggle="dropdown" class="btn">
                                            <i class="fa fa-th" aria-hidden="true">&nbsp;</i>
                                            Actions&nbsp;<span class="caret">&nbsp;</span>
                                        </a>
                                    </div>
                                </div> 
                                 <div class="bulk btn-group navbar-right enable">
                                    <div class="dropdown">
                                        <a href="#" data-toggle="dropdown" class="btn">
                                            <i class="fa fa-th" aria-hidden="true">&nbsp;</i>
                                            Actions&nbsp;<span class="caret">&nbsp;</span>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li class="pl-4"><a href="javascript:void(0);"
                                                    (click)="appointmentHidden()"><i class="fa fa-trash"
                                                        aria-hidden="true">&nbsp;</i>Delete
                                                    item(s) </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </th> -->
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of table.data; let i = index" 
                            [class.has-wait-time]="item.change_color" [class.selected-tr]="item.isSelected">
                            <td class="col-view"><a (click)="viewDetailAppt(item.plan_check_in)">Details</a></td>
                            <td class="col-date">
                                <div>{{ _utils.getDate(item.plan_check_in) }}</div>
                                <div>
                                    <span>{{ _utils.getTime(item.plan_check_in) }}</span>
                                    <span>-</span>
                                    <span>{{ _utils.getTime(item.plan_check_out) }}</span>
                                </div>
                            </td>
                            <td class="col-patient">
                                {{ _utils.generateFullName(item.patient_first_name, item.patient_middle_name, item.patient_last_name) }}
                            </td>
                            <td class="col-provider">
                                <span>{{ _utils.generateFullName(item.provider_first_name, item.provider_middle_name, item.provider_last_name) }}</span><span
                                    *ngIf="item.credentials">,</span>
                                <span>{{ item.credentials }}</span>
                            </td>
                            <td class="col-specialty">{{ _utils.getSpecialties(item.specialties) }}</td>
                            <td class="col-address">
                                <div>{{ item.address }}</div>
                                <div>{{ item.address2 }}</div>
                                <div>
                                    <span>{{ item.city }}</span><span *ngIf="item.state_code !== null">,</span>
                                    <span>{{ item.state_code }}</span>
                                    <span>{{ item.zip }}</span>
                                </div>
                            </td>
                            <!-- <td class="col-action">
                                <label class="chkbox">
                                    <input type="checkbox" [(ngModel)]="item.is_hidden"
                                        (change)="onSelectedItem(item.id, $event)" />
                                    <span class="checkmark"></span>
                                </label>
                            </td> -->
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngIf="table.data.length==0" class="no-saved-search">
                            <td colspan="8">There is no results</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </rtn-table>
</div>