<!-- <app-loading-indicator #indicator>
    <div class="screen-loading" [class.hidden]="!indicator.pleaseWait">
      <div class="screen-loading-spinner"></div>
    </div>
  </app-loading-indicator>
<rtn-alert #appAlert></rtn-alert> -->
<app-header-admin></app-header-admin>
<div class="container admin-portal" role="main" [style.min-height.px]="minHeight">
  <router-outlet></router-outlet>
</div>
<rtn-common-footer></rtn-common-footer>