<nav class="navbar navbar-expand-lg pl-0 pr-0 pt-0">
    <!--<a class="d-none d-lg-block navbar-header pt-4">
        <a class="navbar-brand" href="https://www.realtimeneighborhood.com/">
            <img src="/assets/img/logo.png" />
        </a>
    </a>
    <a class="d-none d-md-block d-lg-none navbar-header">
        <a class="navbar-brand" href="https://www.realtimeneighborhood.com/">
            <img src="/assets/img/logo.png" />
        </a>
    </a>
    <a class="d-block d-md-none navbar-header mx-auto" >
        <a class="navbar-brand" style="border-right: none" href="https://www.realtimeneighborhood.com/">
            <img src="/assets/img/logo.png" />
        </a>
    </a>-->
    <div class="container provider-header pl-0 pt-2 menu-flex-no-wrap">

    <a class="navbar-header pt-icon-2">
        <a class="navbar-brand" href="https://www.realtimeneighborhood.com/">
            <img src="/assets/img/logo.png"/>
        </a>
    </a>
    <div class="provider-header-navigator navbar_collapse collapse d-none d-md-block pt-2">
        <ul class="navbar-nav title-menu provider-header-navigator-title menu-flex-row">
            <li [class.active]="_systemService.navActivated(['/admin', 'account'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/admin', 'account']">
                    <span class="d-none d-lg-block">ACCOUNT MANAGEMENT</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-users header-icon blue"></span>
                    </span>
                </a>
            </li>
            <li [class.active]="_systemService.navActivated(['/admin', 'ehr'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/admin', 'ehr']">
                    <span class="d-none d-lg-block">EHR INTEGRATION</span>
                    <span class="d-block d-lg-none">
                        <span class="fr-font fr-connectivity header-icon blue"></span>
                    </span>
                </a>
            </li>
            <li [class.active]="_systemService.navActivated(['/admin', 'notification'], _router)"
                *ngIf="arrayModule[1]">
                <a class="show-focus nav-link" [routerLink]="['/admin', 'notification']">
                    <span class="d-none d-lg-block">NOTIFICATION MANAGEMENT</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-bell-o header-icon blue"></span>
                    </span>
                </a>
            </li>
            <li [class.active]="_systemService.navActivated(['/admin', 'practice'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/admin', 'practice']">
                    <span class="d-none d-lg-block">PRACTICE</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-hospital-o header-icon blue"></span>
                    </span>
                </a>
            </li>
            <li [class.active]="_systemService.navActivated(['/admin', 'dataLog'], _router)" *ngIf="arrayModule[6]">
                <a class="show-focus nav-link" [routerLink]="['/admin', 'waittimelog-by-provider']">
                    <span class="d-none d-lg-block">DATA LOG</span>
                    <span class="d-block d-lg-none">
                        <span class="fas fa-chart-bar header-icon blue"></span>
                    </span>
                </a>
            </li>
        </ul>
        <ul class="nav navbar-nav navbar-right profile-menu provider-header-navigator-profile">        
            <li class="dropdown demo1">
                <a href="#" class="btn d-none d-xl-block dropdown-toggle hover-bottom-border no-focus"
                    data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <i class="pull-right fa fa-caret-down"></i>
                    <i class="fa faalign-left fa-user menu-user"></i>
                    <span
                        class="pull-left text-overflow-ellipsis">{{_systemData.localStorage.getItem('userFullName')}}</span>
                </a>
                <a href="#" class="d-block d-xl-none dropdown-toggle hover-bottom-border no-focus padding_18"
                    data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <span class="fa fa-bars header-icon font_size_120 pl_6"></span>
                </a>
                <ul class="dropdown-menu provider-header-navigator-profile-menu"> <!--custom_menu-->
                    <!--<li class="d-block d-md-none height_36">
                        <a class="cursorPointer logout dropdown-item height_36" [routerLink]="['/admin', 'account']">
                            <i class="fa fa-users"></i>Account Management
                        </a>
                    </li>
                    <li class="d-block d-md-none height_36">
                        <a class="cursorPointer logout dropdown-item height_36" [routerLink]="['/admin', 'ehr']">
                            <i class="fa faalign-left fr-font fr-connectivity"></i>EHR Integration
                        </a>
                    </li>
                    <li class="d-block d-md-none height_36">
                        <a class="cursorPointer logout dropdown-item height_36" [routerLink]="['/admin', 'notification']">
                            <i class="fa fa-bell-o"></i>Notification Management
                        </a>
                    </li>
                    <li class="d-block d-md-none height_36">
                        <a class="cursorPointer logout dropdown-item height_36" [routerLink]="['/admin', 'practice']">
                            <i class="fa fa-hospital-o"></i>Pracitce
                        </a>
                    </li>
                    <li class="d-block d-md-none height_36">
                        <a class="cursorPointer logout dropdown-item height_36" [routerLink]="['/admin/chart', 'waittime']">
                            <i class="fa faalign-left fas fa-chart-bar"></i>Datalog
                        </a>
                    </li>-->
                    <li>                        
                        <a [routerLink]="['/admin', 'profile']" class="cursorPointer dropdown-item height_36">
                            <i class="fa faalign-left fa-user"></i>My Profile
                        </a>
                    </li>
                    <li>
                        <a (click)="changePassword()" class="cursorPointer logout dropdown-item">
                            <i class="fa faalign-left fa-key"></i>Change password
                        </a>
                    </li>
                    <li>
                        <a (click)="logoutDirective.open()" class="cursorPointer logout dropdown-item">
                            <i class="fa faalign-left fa-sign-out"></i>Log Out
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    
    </div>
</nav>
<rtn-modals-general-logout></rtn-modals-general-logout>