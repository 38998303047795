<modal #DeleteNotification_Modals>
    <div>
        <modal-header>
            <h4 class="modal-title">{{messageConfirmDelete}}</h4>
        </modal-header>
        <div class="modal-footer">
            <button type="button" class="btn btn-rtn-inv btn-90" data-dismiss="modal">No</button>
            <button type="button" class="btn btn-rtn btn-90" data-dismiss="modal" (click)="deleteNotification($event)">Yes</button>
        </div>
    </div>
</modal>