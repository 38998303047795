import {Component, OnInit, AfterViewInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {RestService as InsuranceService} from '@app/core/rest.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {SystemInterval, SystemEvent, SystemData} from '@app/shared/system';
import {AlertService} from '@app/shared/alert';
import {DOBComponent} from '@app/shared/dob/dob';
// import { FamilyMemberComponent } from '@app/shared/family-member/family-member';
import {InsuranceDetail} from '@app/shared/models/insuranceDetail';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {FamilyMember} from '@app/shared/models/familyMember';
import {Utils} from '@app/shared/utils';

import * as moment from 'moment';
import {PatientsService} from '@app/patients/patients.service';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6 // Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);
declare let jQuery: any;

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit, AfterViewInit, OnDestroy {

  familyMembersObject: FamilyMember = new FamilyMember();
  familyMembers: any = [];
  errorFamilyMembers: any = [];
  successMessage: string;
  insuranceDetail: InsuranceDetail = new InsuranceDetail();
  insuranceList: any;
  /*insuranceCompanies: any;
  insurancePlans: any;
  primary_account_holder_boolean: boolean = false;*/
  updateMedicalInsurance: any;
  errorMedicalInsurance: InsuranceDetail = new InsuranceDetail();
  totalMember = 0;
  usedGetTotal = false;
  showLinkAddFamilyMember = true;
  eventListener: any;
  patientEmailList: Array<any> = [];

  getInsuranceSettingsSubscriber: Subscription;
  getCompanyInsuranceSubscriber: Subscription;
  getCompanyPlanSubscriber: Subscription;
  updateCompanyPlanSubscriber: Subscription;
  getListFamilyMemberSubscriber: Subscription;
  addFamilyMembersSubscriber: Subscription;
  updateFamilyMembersSubscriber: Subscription;
  deleteFamilyMembersSubscriber: Subscription;
  getTotalFamilyMembersSubscriber: Subscription;
  getInsuranceListSubscriber: Subscription;
  getListPatientSubscriber: Subscription;

  @ViewChild(DOBComponent) dobDirective: DOBComponent;
  @ViewChild(InputMaskDirective) inputMaskDirective: InputMaskDirective;
  @ViewChild(SimpleMaskDirective) simpleMaskDirective: SimpleMaskDirective;

  // @ViewChild(FamilyMemberComponent) familyMemberComponent: FamilyMemberComponent;

  constructor(
    private _insuranceService: PatientsService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _alertService: AlertService,
    private _systemEvent: SystemEvent,
    private _utils: Utils
  ) {
  }

  ngOnInit() {
    if (this.getInsuranceSettingsSubscriber) {
      this.getInsuranceSettingsSubscriber.unsubscribe();
    }
    this.getInsuranceSettingsSubscriber = this._insuranceService.getInsuranceSettings().subscribe(
      (success: any) => {
        if (success.patientInsurance.insurance_id) {
          this.insuranceDetail.insurance_id = success.patientInsurance.insurance_id;
        } else {
          this.insuranceDetail.insurance_id = '';
        }
        if (this.insuranceDetail.insurance_id) {
          this.changeInsurance(this.insuranceDetail.insurance_id);
        }
        /*if (success.patientInsurance.insurance_plan_id) {
            this.insuranceDetail.insurance_plan_id = success.patientInsurance.insurance_plan_id;
        } else {
            this.insuranceDetail.insurance_plan_id = '';
        }*/
        this.insuranceDetail.insurance_number = success.patientInsurance.insurance_number;
        // this.primary_account_holder_boolean = (success.patientInsurance.primary_account_holder === 1)
        // ? true : false;
        if (success.patientInsurance.patient_user_id && (success.patientInsurance.insurance_id === null)) {
          this.insuranceDetail.insurance_id = '-1';
          jQuery('#company_condition').addClass('hidden');
        }
      }
    );
    /*if (this.getCompanyInsuranceSubscriber) {
        this.getCompanyInsuranceSubscriber.unsubscribe();
    }
    this.getCompanyInsuranceSubscriber = this._insuranceService.getInsuranceCompany().subscribe(
        (success: any) => {
            this.insuranceCompanies = success.insurance_companies;
        }
    );*/

    this.getInsuranceListSubscriber = this._insuranceService.getInsuranceList().subscribe(
      (success: any) => {
        this.insuranceList = success.insurances;
      }
    );


    // get patient list
    this.getListPatientSubscriber = this._insuranceService.getListEmailPatient().subscribe(
      (success: any) => {
        // this.patientEmailList = success.patients;
        if (success.patients.length > 0) {
          success.patients.forEach((item: any) => {
            this.patientEmailList.push(item.email);
          });
          this.getListFamilyMemberList();
        }
      }
    );
  }

  ngAfterViewInit() {
    const _self = this;
    this._systemEvent.menu.emit('insurance');
    /* jQuery('#insurance_number').bind('keypress', function (e: any) {
        return !(e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57));
    }); */

    this.eventListener = this._systemEvent.event.subscribe((action: string) => {
      switch (action) {
        case 'event_callShowLinkAddFamilyMember':
          this.actionAfterDeleteFamilyMember();
          this.showLinkAddFamilyMember = true;
          this.usedGetTotal = true;
          break;
        default:
          break;
      }
    });
    jQuery('#insurance_number').bind({
      paste: function () {
        _self.checkID();
      }
    });
  }

  ngOnDestroy() {
    if (this.getListPatientSubscriber) {
      this.getListPatientSubscriber.unsubscribe();
    }
    if (this.getInsuranceSettingsSubscriber) {
      this.getInsuranceSettingsSubscriber.unsubscribe();
    }
    /*if (this.getCompanyInsuranceSubscriber) {
        this.getCompanyInsuranceSubscriber.unsubscribe();
    }
    if (this.getCompanyPlanSubscriber) {
        this.getCompanyPlanSubscriber.unsubscribe();
    }*/
    if (this.getListFamilyMemberSubscriber) {
      this.getListFamilyMemberSubscriber.unsubscribe();
    }
    if (this.getTotalFamilyMembersSubscriber) {
      this.getTotalFamilyMembersSubscriber.unsubscribe();
    }
    if (this.getInsuranceListSubscriber) {
      this.getInsuranceListSubscriber.unsubscribe();
    }
  }

  getListFamilyMemberList() {
    this.familyMembers = [];
    // get family member list
    this.getListFamilyMemberSubscriber = this._insuranceService.getListFamilyMember().subscribe(
      (success: any) => {
        if (success.family_members.length > 0) {
          success.family_members.forEach((item: any) => {
            item.day = item.dob !== null ? moment(item.dob, 'YYYY-MM-DD').format('DD') : null;
            item.month = item.dob !== null ? moment(item.dob, 'YYYY-MM-DD').format('MM') : null;
            item.year = item.dob !== null ? moment(item.dob, 'YYYY-MM-DD').format('YYYY') : null;
            item.over18 = this.checkOver18Year(item.dob);
            item.show = true;
            if (item.is_same_insurance === 0 && item.insurance_id === null) {
              item.insurance_id = -1;
              // item.insurance_plan_id = '';
            }
            this.familyMembers.push(item);
          });
        } else {
          this.addMoreFamilyMemberClicked();
        }
      }
    );
  }

  checkOver18Year(dob: any) {
    if (dob) {
      const now = moment(new Date()); // todays date
      const compareDate = moment(dob); // compare date
      const duration = moment.duration(now.diff(compareDate));
      const years = duration.asYears();
      if (years >= 18) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  changeInsurance(plan: any) {
    if (plan === '-1') {
      this.insuranceDetail.insurance_number = '';
      jQuery('#company_condition').addClass('hidden');
    } else {
      jQuery('#company_condition').removeClass('hidden');
    }
    // this.insuranceDetail.insurance_plan_id = '';
    // this.errorMedicalInsurance.insurance_id = '';
    this.updateMedicalInsurance = '';
    /*if (this.getCompanyPlanSubscriber) {
        this.getCompanyPlanSubscriber.unsubscribe();
    }
    this.getCompanyPlanSubscriber = this._insuranceService.getInsurancePlan(company).subscribe(
        (success: any) => {
            this.insurancePlans = success.insurance_plans;
        }
    );*/
  }

  checkID() {
    this.errorMedicalInsurance.insurance_number = '';
    this.clearError();
  }

  clearError() {
    this.updateMedicalInsurance = '';
  }

  insuranceUpdateClicked() {
    this._loadingIndicatorService.start();
    this.clearError();
    // this.insuranceDetail.primary_account_holder = this.primary_account_holder_boolean ? 1 : 0;
    if (this.updateCompanyPlanSubscriber) {
      this.updateCompanyPlanSubscriber.unsubscribe();
    }
    this.insuranceDetail.insurance_number = this.insuranceDetail.insurance_number
      ? this.insuranceDetail.insurance_number.trim() : this.insuranceDetail.insurance_number;
    this.updateCompanyPlanSubscriber = this._insuranceService.saveInsuranceSettings(this.insuranceDetail).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        // do nothing
        this.updateMedicalInsurance = success.error_messages;
        if (success.currentStatus) {
          localStorage.setItem('user_status', success.currentStatus);
        }
        this.getListFamilyMemberList();
      }, (error: any) => {
        this._loadingIndicatorService.stop();
        const e = error.error;
        if (e.error_code === 'RC106' || e.error_code === 'RC107'
          || e.error_code === 'RC110' || e.error_code === 'RC111') {
          this.openAlert(e.error_messages);
        } else {
          this.errorMedicalInsurance = e.error_fields;
        }
      }, () => {
        this._loadingIndicatorService.stop();
      }
    );
  }

  actionAfterDeleteFamilyMember() {
    this.getTotalFamilyMembersSubscriber = this._insuranceService.getTotalFamilyMember().subscribe(
      (success: any) => {
        this.totalMember = success.total;
        if (this.totalMember === 0) {
          this.addMoreFamilyMemberClicked();
        }
      }
    );
  }

  clickAddMoreFamilyMemberClicked() {
    console.log('TEST add member', this._utils.isPartialActiveStatus());

    if (this._utils.isPartialActiveStatus()) {
      this.openAlert('Your account has not full access yet. ' +
        'Please update insurance or contact RTN admin to get full access account');
      return;
    } else {
      this.addMoreFamilyMemberClicked();
    }
  }

  addMoreFamilyMemberClicked() {
    console.log('TEST click add ', this.usedGetTotal);

    if (this.usedGetTotal) {
      this.familyMembers.length = this.totalMember;
      this.usedGetTotal = false;
    }
    if (this.familyMembers.length < 10) {
      this.familyMembers.push(Object.assign({}, this.familyMembersObject));
      this.errorFamilyMembers.push(Object.assign({}, this.familyMembersObject));
    } else {
      // this.showLinkAddFamilyMember = false;
      this.openAlert('You cannot add more than 10 members in family list');
    }
  }

  openAlert(msg: string) {
    this._alertService.onClose = () => {
      // do nothing
    };
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  onChange(e: any, i: number) {
    switch (e) {
      case 'first_name':
        this.errorFamilyMembers[i].first_name = null;
        break;

      case 'last_name':
        this.errorFamilyMembers[i].last_name = null;
        break;

      case 'dob':
        this.errorFamilyMembers[i].dob = null;
        break;

      case 'email':
        this.errorFamilyMembers[i].email = null;
        break;

      case 'zip_code':
        this.errorFamilyMembers[i].zip_code = null;
        break;

      case 'insurance_id':
        this.errorFamilyMembers[i].insurance_id = null;
        break;
    }
  }

}
