import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { LoadingIndicatorService } from '../../shared/loading-indicator';

@Component({
  selector: 'app-data-log',
  templateUrl: './data-log.component.html',
  styleUrls: ['./data-log.component.scss'],
  moduleId: module.id,
})
export class DataLogComponent implements AfterViewInit, OnDestroy, OnInit {

    constructor(
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        
    }

    ngOnInit() {
        
    }

    ngAfterViewInit() {
        //TODO
    }

    ngOnDestroy() {
        
    }
}
