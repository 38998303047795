import {Component, Input} from '@angular/core';
import {ModalComponent} from './modal.component';

@Component({
  selector: 'modal-footer',
  template: `
    <div class="modal-footer">
      <ng-content></ng-content>
      <button *ngIf="showDefaultButtons" type="button" class="btn btn-rtn btn-90" data-dismiss="modal"
              (click)="modal.dismiss()">Close
      </button>
      <button *ngIf="showDefaultButtons" type="button" class="btn btn-rtn btn-90" (click)="modal.close()">Save</button>
    </div>
  `
})
export class ModalFooterComponent {
  @Input() showDefaultButtons = false;

  constructor(private modal: ModalComponent) {
    // TODO
  }
}
