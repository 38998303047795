import { Component, EventEmitter, OnDestroy, OnInit, Output,Input, AfterViewInit, ViewChild } from '@angular/core';
import { Subscription, fromEventPattern } from 'rxjs';
import { RtnFeedbackComponent } from '@app/patients/feedback/feedback.component'
import { RestService } from '@app/core/rest.service'
import { AlertService } from '@app/shared/alert';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';
import {PatientsService} from '@app/patients/patients.service';

@Component({
    moduleId: module.id,
    selector: 'rtn-modals-feedback-provider',
    templateUrl: './feedback-provider.component.html',
})

export class FeedbackProvider_ModalsComponent implements OnInit, OnDestroy, AfterViewInit {
    sendFeedbackToProviderSubscriber: Subscription;

    @ViewChild(RtnFeedbackComponent) modal: RtnFeedbackComponent;

    @Input()
    providerId: number;

    @Output()
    onClick: EventEmitter<any> = new EventEmitter();

    constructor(
        private _restService: PatientsService,
        private _alertService: AlertService
    ) {
        //TODO
    }

    // ========== Class Method ========== //

    ngOnInit() {
        //TODO
    }

    ngOnDestroy() {
        if (this.sendFeedbackToProviderSubscriber) {
            this.sendFeedbackToProviderSubscriber.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.modal.sendFeedback = () => {
            if (this.sendFeedbackToProviderSubscriber) {
                this.sendFeedbackToProviderSubscriber.unsubscribe();
            }
            this.sendFeedbackToProviderSubscriber = this._restService.sendFeedbackToProvider(
                this.providerId, { subject: this.modal.model.subject, body: this.modal.model.content }
            ).subscribe(
                (success: any) => {
                    if (success.error_code === 'RC000') {
                        this._alertService.onClose = () => {
                            this.modal.dismiss();
                        };
                        this._alertService.showDismissButton = false;
                        this._alertService.dismissButtonLabel = 'Close';
                        this._alertService.showCloseButton = true;
                        this._alertService.closeButtonLabel = 'Close';
                        this._alertService.title = success.error_messages;
                        this._alertService.message = '';
                        this._alertService.emitter('open');
                    } else {
                      if (success.error_code === 'RC002') {
                        this.modal.messageErrorSubject = success.error_fields.subject;
                      } else if (success.error_code === 'RC004' || success.error_code === 'RC025' || success.error_code === 'RC016'
                        || success.error_code === 'RC072' || success.error_code === 'RC003') {
                        this._alertService.onClose = () => {
                          this.modal.dismiss();
                        };
                        this._alertService.showDismissButton = false;
                        this._alertService.dismissButtonLabel = 'Close';
                        this._alertService.showCloseButton = true;
                        this._alertService.closeButtonLabel = 'Close';
                        this._alertService.title = success.error_messages;
                        this._alertService.message = '';
                        this._alertService.emitter('open');
                      }
                    }
                },
                (error: any) => {
                    var response = error.error;
                    if (response.error_code === 'RC002') {
                        this.modal.messageErrorSubject = response.error_fields.subject;
                    } else if (response.error_code === 'RC004' || response.error_code === 'RC025' || response.error_code === 'RC016'
                        || response.error_code === 'RC072' || response.error_code === 'RC003') {
                        this._alertService.onClose = () => {
                            this.modal.dismiss();
                        };
                        this._alertService.showDismissButton = false;
                        this._alertService.dismissButtonLabel = 'Close';
                        this._alertService.showCloseButton = true;
                        this._alertService.closeButtonLabel = 'Close';
                        this._alertService.title = response.error_messages;
                        this._alertService.message = '';
                        this._alertService.emitter('open');
                    }
                });
        };
    }


    // ========== General Modal Method ========== //

    onKey() {
        //TODO
    }

    open() {
        this.modal.open();
    }
}
