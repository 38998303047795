import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {merge} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Logger, I18nService} from '@app/core';
import {AlertService} from './shared/alert';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {SimpleModalsService} from '@app/shared/modals/simple-modals/simple-modals.service';
import {AuthService} from './core/auth.service';
import {SystemService} from './shared/system';

const log = new Logger('App');
import * as $ from 'jquery';
import { AlertTimeOutService } from './shared/alertTimeOut/alertTimeOut.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // ide control variable
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private idle: Idle,
    private keepalive: Keepalive,
    private _authService: AuthService,
    private _router: Router,
    private _simpleModals: SimpleModalsService,
    private _systemService: SystemService,
    private _alertService: AlertTimeOutService
  ) {
    var self = this;
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(environment.timeoutActivity);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(1);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => { //
      if (self._router.url.startsWith('/home/') || self._router.url == '/home' || self._router.url.startsWith('/appointment/waittime/') || self._router.url.startsWith('/smartbooking/') || self._router.url.startsWith('/qdownload/')) {
        self.reset();
      } else {
        self.reset();
        self._systemService.logOutNotRouter();
        self._alertService.onClose = () => {
          self._router.navigate(['/home', 'login']);
        };

        self._alertService.onDismiss = () => {
          self._router.navigate(['/home', 'login']);
        };
        self._alertService.showDismissButton = false;
        self._alertService.showCloseButton = true;
        self._alertService.closeButtonLabel = 'OK';
        self._alertService.title = 'RTN system';
        self._alertService.message = 'You have logged off due to inactivity, please log back in to resume activity.';
        self._alertService.emitter('open');

        console.log('Timed out');
      }
    });
    idle.onIdleStart.subscribe(() => {
      self.idleState = 'You\'ve gone idle!';
      console.log('You\'ve gone idle!');
    });
    idle.onTimeoutWarning.subscribe((countdown: any) => {
      self.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log('You will time out in');
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }


  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }


    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this._router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this._router.navigated = false;
        // window.scrollTo(0, 0);
      }
    });
    /*var _self = this;
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('user_token');
        if(token == undefined || token == null) {
          console.log('token : ' + token);
          _self._router.navigate(['/home', 'login']);
        }
      }
    }, false);*/
  }
}
