import { AfterViewInit, Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { chartDate } from '@app/shared/models/chartDate';
import { Subscription } from 'rxjs';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { AdminRestService } from '@app/admin/admin.service';
import { PatientsService } from '@app/patients/patients.service';
import { AlertService } from '@app/shared/alert';
import { dataSearchChart } from '@app/shared/models/dataSearchChart';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { SystemEvent } from '@app/shared/system';
import * as moment from 'moment';
import { Utils } from '@app/shared/utils';
//import { ChartData } from '@app/shared/models/chartData';

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";

declare let jQuery: any;

@Component({
  selector: 'app-patient-waiting-roomtime',
  templateUrl: './patient-waiting-roomtime.component.html',
  styleUrls: ['./patient-waiting-roomtime.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientWaitingRoomtimeComponent  implements OnInit, AfterViewInit, OnDestroy {
  
  data_chart: any;
  error: chartDate = new chartDate();
  getWaittimeByProviderSubscriber: Subscription;
  
  practiceId = '0';
  providerId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;

  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();

  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;
  eventListener: any;
  maxDate: any;
  public providersList: any = [];
  public practicesList: any;
  public selectedPractice: any;
  
  public isSubmitted = false;
  
  currentDate: any = moment();

  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
  @ViewChild('inputDateFrom') inputPractice: ElementRef;
  @ViewChild('inputDateFrom') inputProvider: ElementRef;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;
  
  private chartdata:any = [];

  private chartWidth: number;
  private chartHeight: number;
  private chartMargin = { top: 20, right: 40, bottom: 20, left: 60 };
  private chartX: any;
  private chartY: any;
  private chartSvg: any;
  private chartG: any;

  public curpage : number = 1;
  public noofdata : number = 0;
  public noofpage : number = 0;
  public noofpageArr:any = [];
  
  public charttotaldata:any = [];

  constructor(
      private _utils: Utils,
      private _alertService: AlertService,
      private _patientService: PatientsService,
      private _adminService: AdminRestService,
      private _datalogsService: DataLogsService,
      private _loadingIndicatorService: LoadingIndicatorService,
      private _systemEvent: SystemEvent,
  ) {
      this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');

      let width = 1400; //1366
      width = (window.innerWidth > width) ? (window.innerWidth - 550) : (window.innerWidth - 400);
      this.chartWidth = width - this.chartMargin.left - this.chartMargin.right;
      this.chartHeight= 400 - this.chartMargin.top - this.chartMargin.bottom;
  }

  ngOnInit() {
      this.PracticeSelector.clearData();
      this.ProviderSelector.clearData();
      this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      const _self = this;
      _self._loadingIndicatorService.start();
      _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
          (success: any) => {
              _self.practicesList = success.practices;
              const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
              _self.getProviderByPractice(firstPractice);
              this._loadingIndicatorService.stop();
          });
  }

  ngOnDestroy(): void {


  }

  ngAfterViewInit(): void {
      const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
      const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
      const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
      if (now >= zero_oclock && now < startCurrentTime) {
          this.maxDate = moment().subtract(2, 'days').format('LL');
          this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
          this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
      } else {
          this.maxDate = moment().subtract(1, 'days').format('LL');
          this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
          this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
      }

      const _self = this;
      jQuery(_self.inputDateFrom.nativeElement).datepicker({
          dateFormat: 'MM dd, yy',
          numberOfMonths: 2,
          showButtonPanel: true,
          maxDate: moment(this.maxDate).toDate(),
          changeMonth: true,
          changeYear: true,
          onSelect: (selected: any) => {
              _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
          }
      });
      jQuery(_self.inputDateFrom.nativeElement).datepicker('setDate', this.dataSearchChart.dateFrom);
      jQuery(_self.clickDateFrom.nativeElement).click(() => {
          jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
      });
      
      jQuery('#showDataChart').on('click', function () {
          //_self.resetData();
          _self.updateData();
      });
      /*jQuery('#exportDataChart').on('click', function () {
          _self.exportdata();
      });*/
  }

  onChangePractice() {
      this.removeMsgErr();
      const practice = this.PracticeSelector.getData();
      if (practice.length !== 0) {
          this.getProviderByPractice(practice);
          this.ProviderSelector.clearData();
          this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
          if (this.providersList.length > 0) {
              this.ProviderSelector.updateData(this.providersList);
          }
      } else {
          this.ProviderSelector.clearData();
      }
  }

  removeMsgErr() {
      this.errorResponse.provider = null;
      this.ProviderSelector.inputError = false;
      this.ProviderSelector.changeBorderColorProvider();
  }

  getProviderByPractice(practiceId: any) {
      this._loadingIndicatorService.start();
      if (this.providerTypeSubscriber) {
          this.practiceTypeSubscriber.unsubscribe();
      }
      this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
          (success: any) => {
              if (success.error_code === 'RC000') {
                  this.providersList = [];
                  success.providers.forEach((item: any) => {
                      item.full_name
                          = item.first_name + ((item.middle_name == null)
                              ? (' ')
                              : (' ' + item.middle_name + ' ')) + item.last_name;
                      this.providersList.push(item);
                  });

              }
              this._loadingIndicatorService.stop();
          },
          (error: any) => {
              this._loadingIndicatorService.stop();
          }
      );
  }

  validatePractice(message: any) {
      if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
          this.errorResponse.practice = message;
          this.PracticeSelector.inputError = true;
          this.PracticeSelector.changeBorderColorPractice();
          return false;
      } else {
          this.errorResponse.practice = null;
          this.PracticeSelector.inputError = false;
          this.PracticeSelector.changeBorderColorPractice();
          return true;
      }
  }

  validateProvider(message: any) {
      if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
          this.errorResponse.provider = message;
          this.ProviderSelector.inputError = true;
          this.ProviderSelector.changeBorderColorProvider();
          return false;
      } else {
          this.errorResponse.provider = null;
          this.ProviderSelector.inputError = false;
          this.ProviderSelector.changeBorderColorProvider();
          return true;
      }
  }

  validate() {
      const validatePractice = this.validatePractice('This field is required');
      const validateProvider = this.validateProvider('This field is required');
      // const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
      // const validateDateMoreThan31
      //     = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
      return validatePractice && validateProvider;

  }

  verifyDataSearch() {
      this.dataSearchChart.practice = this.PracticeSelector.getData();
      this.dataSearchChart.provider = this.ProviderSelector.getData();
  }

  updateData() {
      this.verifyDataSearch();
      const dateFrom = this.dataSearchChart.dateFrom;
      // const dateEnd = this.dataSearchChart.dateTo;
      const providerId = this.dataSearchChart.provider;
      const practiceId = this.dataSearchChart.practice;
      if (this.validate()) {
          this.getAppoinmentByDay(dateFrom, practiceId, providerId);
      }
  }

  exportdata() {
      const _self = this;
      this.verifyDataSearch();
      const dateFrom = this.dataSearchChart.dateFrom;
      const providerId = this.dataSearchChart.provider;
      const practiceId = this.dataSearchChart.practice;
      if (this.validate()) {
          const token = localStorage.getItem('user_token');
          jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
              httpMethod: 'POST',
              data: {
                  'token': token,
                  'practice': practiceId,
                  'providerId': providerId,
                  'choosenDate': dateFrom,
                  'chartType': 6
              }
          }).done(function () {
              // TODO
          }).fail(function () {
              _self._alertService.onClose = () => {
                  location.reload();
              };
          });
      }
  }

  getAppoinmentByDay(dateFrom: any, practiceId: any, providerId: any) {
      this._loadingIndicatorService.start();
      //this.chartDatasets = [new ChartData()];
      this.isSubmitted = true;
      this.curpage = 1;
      this.chartdata = [];
      this.noofpageArr = [];
      this.noofpage = 0;
      
      this._datalogsService.getTimeFromArrivaltoCheckIn(dateFrom, providerId, practiceId).subscribe(
          (success: any) => {
              //this.resetData();
              this._loadingIndicatorService.stop();
              this.data_chart = success.data_chart;
              
              //chartdata
              
              if (this.data_chart !== undefined && this.data_chart.length > 0) {
                  for (let index = 0; index < this.data_chart.length; index++) {

                    let datainfo:any = {};
                    let label = this.data_chart[index].planned.plan_check_in + ' - ' + this.data_chart[index].planned.plan_check_out;

                    if (this.data_chart[index].timeGap > 20) {
                      datainfo.State = index +'#' + label +'(*)';
                    } else {
                      datainfo.State = index +'#' + label;
                    }

                   // console.log(this.data_chart[index].timeGap);

                    let tempvalues:any = [];

                    tempvalues.push({
                      "type" : "Patient Waiting Room Time",                       
                      "value" : this.data_chart[index].timeGap, 
                      "significant" : false,
                      "State" : label,
                      "waittime" : this.data_chart[index].waittime,
                      "arrived_time" : (this.data_chart[index].arrived_time == null) ? ("-"):this.data_chart[index].arrived_time,
                      "actual_check_in" : (this.data_chart[index].actual_check_in == null) ? ("-"):(this.data_chart[index].actual_check_in),
                      "actual_check_out" : (this.data_chart[index].actual_check_out == null) ? ("-"):(this.data_chart[index].actual_check_out),
                      "plannedDuration" : this.data_chart[index].planned_visit_time,
                      "patientwt" : this.data_chart[index].timeGap,
                      "actualDuration" : this.data_chart[index].time_at_hospital,
                      "actual_visit_time" : this.data_chart[index].actual_visit_time                              
                    });

                    tempvalues.push({
                      "type" : "Waittime",                       
                      "value" : this.data_chart[index].waittime, 
                      "significant" : false,
                      "State" : label,
                      "waittime" : this.data_chart[index].waittime,
                      "arrived_time" : (this.data_chart[index].arrived_time == null) ? ("-"):this.data_chart[index].arrived_time,
                      "actual_check_in" : (this.data_chart[index].actual_check_in == null) ? ("-"):(this.data_chart[index].actual_check_in),
                      "actual_check_out" : (this.data_chart[index].actual_check_out == null) ? ("-"):(this.data_chart[index].actual_check_out),
                      "plannedDuration" : this.data_chart[index].planned_visit_time,
                      "patientwt" : this.data_chart[index].timeGap,
                      "actualDuration" : this.data_chart[index].time_at_hospital,
                      "actual_visit_time" : this.data_chart[index].actual_visit_time                    
                    });

                    tempvalues.push({
                      "type" : "Total Visit Duration",                       
                      "value" : this.data_chart[index].time_at_hospital, 
                      "significant" : false,
                      "State" : label,
                      "waittime" : this.data_chart[index].waittime,
                      "arrived_time" : (this.data_chart[index].arrived_time == null) ? ("-"):this.data_chart[index].arrived_time,
                      "actual_check_in" : (this.data_chart[index].actual_check_in == null) ? ("-"):(this.data_chart[index].actual_check_in),
                      "actual_check_out" : (this.data_chart[index].actual_check_out == null) ? ("-"):(this.data_chart[index].actual_check_out),
                      "plannedDuration" : this.data_chart[index].planned_visit_time,
                      "patientwt" : this.data_chart[index].timeGap, // patient waiting time
                      "actualDuration" : this.data_chart[index].time_at_hospital,
                      "actual_visit_time" : this.data_chart[index].actual_visit_time                  
                    });
                    
                    datainfo.patientwaittime  = this.data_chart[index].time_at_hospital;
                    datainfo.waittime         = this.data_chart[index].waittime;
                    datainfo.actualDuration   = this.data_chart[index].timeGap;
                    
                    datainfo.values = tempvalues;
                    this.chartdata.push(datainfo);
                    //console.log(this.chartdata);
                  }
              }

              // total value
              var totalValue = [];
              totalValue = success.total_datachart;

              if (totalValue !== undefined) {
                let tempvalue1:any = [];

                    tempvalue1.push({
                      "type" : "Total Patient Waiting Room Time",                       
                      "value" : totalValue.patient_waitroomtime,                       
                      "State" : 'WT',
                      "patient_waitroomtime" : totalValue.patient_waitroomtime,
                      "planned_duration" : totalValue.planned_duration,
                      "actual_duration" : totalValue.actual_duration
                    });

                    tempvalue1.push({
                      "type" : "Total Planned Duration",                       
                      "value" : totalValue.planned_duration, 
                      "significant" : false,
                      "State" : 'PD',
                      "patient_waitroomtime" : totalValue.patient_waitroomtime,
                      "planned_duration" : totalValue.planned_duration,
                      "actual_duration" : totalValue.actual_duration                    
                    });

                    tempvalue1.push({
                      "type" : "Total Actual Duration",                       
                      "value" : totalValue.actual_duration, 
                      "significant" : false,
                      "State" : 'AD',
                      "patient_waitroomtime" : totalValue.patient_waitroomtime,
                      "planned_duration" : totalValue.planned_duration,
                      "actual_duration" : totalValue.actual_duration                    
                    });

                    let datainfo1:any = {};
                    
                    datainfo1.patientwaittime  = totalValue.patient_waitroomtime;
                    datainfo1.plannedduration  = totalValue.planned_duration;
                    datainfo1.actualduration   = totalValue.actual_duration;
                    
                    datainfo1.values = tempvalue1;
                    this.charttotaldata.push(datainfo1);
              }

              this.noofdata = this.chartdata.length;   
              this.curpage = 1;
              
              this.drawMultiBarChart();

              this.printTotalBarChart();
          },
          (error: any) => {
              this._loadingIndicatorService.stop();              
          }
      );
  }
  printDataChart(){
    window.print();
  }
  drawMultiBarChart(){
    
    const elements = document.getElementsByClassName("myTooltip");
    while (elements.length > 0) elements[0].remove();

    if (this.noofdata > 0) {
      let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.State.length}));

      let barWidth:any = maxLength * 10;

      let numBars:any = Math.round(this.chartWidth / barWidth);

      this.noofpage = Math.ceil(this.noofdata / numBars);

      this.noofpageArr = Array(this.noofpage).fill(0).map((x,i)=>i);

      let that = this;
      setTimeout(function(){
        for (var i=0; i < that.noofpage; i++) {
          var curpos = i + 1;
          that.printBarChart('groupchartbody_' + i, curpos, numBars);
        }
      }, 500);
    }
  }
  printBarChart(eleId:any, curpage:number, numBars:number){

    if (document.getElementById(eleId) != undefined && document.getElementById(eleId)!= null) {
      document.getElementById(eleId).innerHTML = "";
    }

    if (this.noofdata == 0) {
      return false;
    }

    let that1 = this;
    
    let chartSvgp = d3.select("#" + eleId).append('svg');
		chartSvgp.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
		chartSvgp.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
		
		let chartGp = chartSvgp.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    let startpos = (curpage - 1) *  numBars;
    let endpos = (curpage * numBars);
    endpos = (endpos > this.noofdata) ? this.noofdata : endpos;

    let tempdata:any = this.chartdata.slice(startpos, endpos);

    var x0 = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).paddingInner(0.2);
    var x1 = d3Scale.scaleBand().padding(0.1);

    var y = d3Scale.scaleLinear().rangeRound([(this.chartHeight / 1.75), 0]);

    var z = d3Scale.scaleOrdinal<any>()
      .range(["#52c5fe", "#c00000", "#0bbc6f"]);

    var categoriesNames = tempdata.map(function(d:any) { return d.State; });
    var rateNames = tempdata[0].values.map(function(d:any) { return d.type; }); 

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);

    /*let patientWaittime = d3Array.max(tempdata, (d:any) => d.patientwaittime);
    let waitTime        = d3Array.max(tempdata, (d:any) => d.waittime);
    let actualDuration  = d3Array.max(tempdata, (d:any) => d.actualDuration);*/

    let patientWaittime = d3Array.max(this.chartdata, (d:any) => d.patientwaittime);
    let waitTime        = d3Array.max(this.chartdata, (d:any) => d.waittime);
    let actualDuration  = d3Array.max(this.chartdata, (d:any) => d.actualDuration);
    
    let maxlimit:number = Math.max(parseInt(patientWaittime), parseInt(waitTime), parseInt(actualDuration));

    y.domain([0, maxlimit]);

    var tooltip = d3.select("body")
      .append("div")
      .attr('class', 'myTooltip')
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("");

      var legend = chartGp.append("g").attr("class", "legend");
      var cWidth = this.chartWidth;
      var cHeight = this.chartHeight;
  
      var legenG = legend.selectAll("g")
        .data(tempdata[0].values.map(function(d:any) { return d.type; }))
        .enter()
        .append("g")
        .attr("transform", function(d:any, i:any) { 
          return "translate(" + i * (cWidth / (rateNames.length + 1)) + ", 0)"; 
      });
  
      legenG.append("rect")
        .attr("x", 0)
        .attr("y", cHeight - 40)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", z);
  
      legenG.append("text")
        .attr("x", 25)
        //.attr("y", 9.5)
        .attr("y", cHeight - 30)
        .attr("dy", "0.32em")
        .text(function(d:any) { return d; });
  
      chartGp.append("g")			
        .attr("class", "grid")        
        .attr("transform", "translate(0," + (cHeight / 7) + ")")
        .call(d3Axis.axisLeft(y)
        .tickSize(-cWidth)          
        .ticks(6)
        .tickFormat(null));  

      var barG = chartGp.append("g")
        .selectAll("g")
        .data(tempdata)
        .enter()
        .append("g")
        .attr("transform", function(d:any) { return "translate(" + x0(d.State) + ",0)"; });
    
      barG.selectAll(".bars-container-middle")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", 'bars-container-middle')	
        .attr("transform", "translate(0," + (cHeight / 7) + ")")      
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("y", function(d:any) { return 0; })
        .attr("width", x1.bandwidth())        
        .attr('height', function(d:any) { 
          return (d.value > 0) ? (cHeight / 1.75) : 0;        
        });
    
      barG.selectAll(".bars")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")        
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("width", x1.bandwidth())
        .attr("fill", function(d:any) { return z(d.type); })
        .attr("y", (cHeight / 2))
        .attr("y", function(d:any) { return y(d.value); })
        .attr('height', function(d:any) { 
          return (d.value > 0) ? ((cHeight / 1.75))  - y( d.value ) : 0;
        })
        .on("mouseover", function(d:any){
          var newHtml = '';

          newHtml += '<p><span class="lbltext">Slot </span><span>: ' + d['State'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Arrived Time </span><span>: ' + d['arrived_time'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Actual Check-in </span><span>: ' + d['actual_check_in'] + '</span></p>';
          if (d['actual_check_out'] != undefined && d['actual_check_out'] != null) {
            newHtml += '<p><span class="lbltext">Actual Check-out </span><span>: ' + d['actual_check_out'] + '</span></p>';
          }
          
          newHtml += '<p><span class="lbltext">Total Planned Visit </span><span>: ' + d['plannedDuration'] + '</span></p>';
          if (d['actual_visit_time'] != undefined) {
            newHtml += '<p><span class="lbltext">Actual Apt Duration </span><span>: ' + d['actual_visit_time'] + '</span></p>';
          }
          newHtml += '<p><span class="lbltext">Patient Waiting Room Time </span><span>: ' + d['patientwt'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Waittime </span><span>: ' + d['waittime'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Total Visit Duration </span><span>: ' + d['actualDuration'] + '</span></p>';

          tooltip.html(newHtml);
          return tooltip.style("visibility", "visible");
         })
         .on("mousemove", function(){
            var pageX = d3['event']['pageX'];
            var pageY = d3['event']['pageY'];
                       
            if (cWidth - (pageX + 200) < 20){ 
              pageX = pageX - 200 - 20;
            }
            
            if (cHeight - (pageY + 200) < 20){ 
              pageY = pageY - 200 - 20;
            }

            return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
        })
        .on("mouseout", function(){           
          return tooltip.style("visibility", "hidden");
        });
    
      chartGp.append("g")
          .attr("class", "x-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 1.4) + ")")
          .call(d3Axis.axisBottom(x0))
          .selectAll("text")	
          .style("text-anchor", "middle")          
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr('y', '15')
          .style("fill", function(d:any) {             
            let xtext = d.split('#');
            //console.log(that1.chartdata[xtext[0]]);   
            let timeGp = parseInt(that1.chartdata[xtext[0]].actualDuration);
            return (timeGp > 20) ? 'red' : '#000000';
          })
          .text(function (d:any) {
            let xtext = d.split('#');
            return xtext[1];
          });
    
      chartGp.append("g")
          .attr("class", "y-axis axis")          
         .attr("transform", "translate(0," + (cHeight / 7) + ")")
          .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return d + " min"; }));   
  }   
  //
  printTotalBarChart(){
    var eleId = 'totalChartbar';
    if (document.getElementById(eleId) != undefined && document.getElementById(eleId)!= null) {
      document.getElementById(eleId).innerHTML = "";
    }

    let that1 = this;
    
    let chartSvgp = d3.select("#" + eleId).append('svg');
		chartSvgp.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
		chartSvgp.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
		
    let chartGp = chartSvgp.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
    
    let tempdata:any = this.charttotaldata;

    //console.log(tempdata);

    var x0 = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).paddingInner(0.2);
    var x1 = d3Scale.scaleBand().padding(0.1);

    var y = d3Scale.scaleLinear().rangeRound([(this.chartHeight / 1.75), 0]);

    var z = d3Scale.scaleOrdinal<any>().range(["#52c5fe", "#e26b01", "#0bbc6f"]);

    //console.log(tempdata);
    

    var categoriesNames = tempdata.map(function(d:any) { return d.State; });
    var rateNames = tempdata[0].values.map(function(d:any) { return d.type; }); 



    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);

    let patientWaittime = d3Array.max(tempdata, (d:any) => d.patientwaittime);
    let plannedDuration = d3Array.max(tempdata, (d:any) => d.plannedduration);
    let actualDuration  = d3Array.max(tempdata, (d:any) => d.actualduration);
    
    let maxlimit:number = Math.max(parseInt(patientWaittime), parseInt(plannedDuration), parseInt(actualDuration));

    y.domain([0, maxlimit]);

    var tooltip = d3.select("body")
      .append("div")
      .attr('class', 'myTooltip')
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("");

      var legend = chartGp.append("g").attr("class", "legend");
      var cWidth = this.chartWidth;
      var cHeight = this.chartHeight;
  
      var legenG = legend.selectAll("g")
        .data(tempdata[0].values.map(function(d:any) { return d.type; }))
        .enter()
        .append("g")
        .attr("transform", function(d:any, i:any) { 
          return "translate(" + i * (cWidth / (rateNames.length + 1)) + ", 0)"; 
      });
  
      legenG.append("rect")
        .attr("x", 0)
        .attr("y", cHeight - 40)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", z);
  
      legenG.append("text")
        .attr("x", 25)
        //.attr("y", 9.5)
        .attr("y", cHeight - 30)
        .attr("dy", "0.32em")
        .text(function(d:any) { return d; });
  
      chartGp.append("g")			
        .attr("class", "grid")        
        .attr("transform", "translate(0," + (cHeight / 7) + ")")
        .call(d3Axis.axisLeft(y)
        .tickSize(-cWidth)          
        .ticks(6)
        .tickFormat(null));  

      var barG = chartGp.append("g")
        .selectAll("g")
        .data(tempdata)
        .enter()
        .append("g")
        .attr("transform", function(d:any) { return "translate(" + x0(d.State) + ",0)"; });
    
      barG.selectAll(".bars-container-middle")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", 'bars-container-middle')	
        .attr("transform", "translate(0," + (cHeight / 7) + ")")      
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("y", function(d:any) { return 0; })
        .attr("width", x1.bandwidth())        
        .attr('height', function(d:any) { 
          return (d.value > 0) ? (cHeight / 1.75) : 0;        
        });
    
      barG.selectAll(".bars")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")        
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("width", x1.bandwidth())
        .attr("fill", function(d:any) { return z(d.type); })
        .attr("y", (cHeight / 2))
        .attr("y", function(d:any) { return y(d.value); })
        .attr('height', function(d:any) { 
          return (d.value > 0) ? ((cHeight / 1.75))  - y( d.value ) : 0;
        })
        .on("mouseover", function(d:any){
          /*
           "patient_waitroomtime" : totalValue.patient_waitroomtime,
            "planned_duration" : totalValue.planned_duration,
            "actual_duration" : totalValue.actual_duration
          */

          var newHtml = '';
          newHtml += '<p><span class="lbltext">Patient Waiting Room Time </span><span>: ' + d['patient_waitroomtime'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Total Planned Visit </span><span>: ' + d['planned_duration'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Total Actual Visit </span><span>: ' + d['actual_duration'] + '</span></p>';

          tooltip.html(newHtml);
          return tooltip.style("visibility", "visible");
         })
         .on("mousemove", function(){
            var pageX = d3['event']['pageX'];
            var pageY = d3['event']['pageY'];
                       
            if (cWidth - (pageX + 200) < 20){ 
              pageX = pageX - 200 - 20;
            }
            
            if (cHeight - (pageY + 200) < 20){ 
              pageY = pageY - 200 - 20;
            }

            return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
        })
        .on("mouseout", function(){           
          return tooltip.style("visibility", "hidden");
        });
    
      chartGp.append("g")
          .attr("class", "x-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 1.4) + ")")
          .call(d3Axis.axisBottom(x0))
          .selectAll("text")	
          .style("text-anchor", "middle")          
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr('y', '15');
    
      chartGp.append("g")
          .attr("class", "y-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 7) + ")")
          .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return d + " min"; }));   
  }
}