import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';

//import { ModalComponent } from '@app/shared/ng2-bs3-modal';

import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
//import { Event, TimelineComponent } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { from, Subscription, Subscriber } from 'rxjs';
//import { RestService } from '@app/core/rest.service';
import { AppointmentsService } from '@app/appointments/appointments.service';
//import { ProvidersService } from '@app/providers/providers.service';
import {SystemData, SystemEvent} from '@app/shared/system';

@Component({
  moduleId: module.id,
  selector: 'rtn-patient-future-appointment',
  templateUrl: './patient-future-appointment.component.html',
  styleUrls: ['./patient-future-appointment.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class PatientFutureAppointmentComponent implements OnDestroy, OnInit {
  
  fetchPatientUpcommingAptSubscriber: Subscription;
  upcommingappointments: any = [];
  selectedupcommingId: number = 0;

  @Input() providerId: number;
  @Input() aptlists: any;
  @Input() patientId: number;
  @Input() page: string;
  @Input() loadedpage: string;
  //@Input() currentId: string;

  @Output() selecteRescheduleId: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private Service: AppointmentsService,
    private _alertService: AlertService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,   
    private _systemEvent: SystemEvent, 
  ) {
   
  }
  
  showMessage(msg: any) {
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  ngOnInit() {
    this.initData();

    this._systemEvent.book.subscribe((action: string) => {
      switch (action) {
        case 'book_callFutureApt':     
        console.log("Listen - book_callFutureApt");     
        //console.log("currentId - book_callFutureApt" + this.currentId);     
          this.initData();
          break;
        default:
          break;
      }
    });
  }
  /*ngOnChanges(changes: SimpleChanges) {
    // set page when items array first set or changed
    if (changes.items.currentValue !== changes.items.previousValue) {
      this.initData();
    }
    console.log(changes.items.currentValue);
    console.log(changes.items.previousValue);
  }*/
  ngOnDestroy() {
    this.selectedupcommingId = 0;
    this.upcommingappointments = [];
  }

  initData() {
    console.log('this.loadedpage: ' + this.loadedpage);
    console.log('this.patientId: ' + this.patientId);
    if (this.loadedpage == 'patient_book_wl' || this.loadedpage == 'patient_schedule' || this.loadedpage == 'provider_book' || this.loadedpage == 'provider_book_wl' || this.loadedpage == 'provider_wl') {
      console.log(this.aptlists);
      this.upcommingappointments = this.aptlists; 
    } else {
      //this.fetchPatientUpcommingApt(this.patientId, this.providerId);
    }
  }

  /*fetchPatientUpcommingApt(patient_id: any, provider_id: any) {
    
    this.upcommingappointments = [];

    this._loadingIndicatorService.start();

    if (this.fetchPatientUpcommingAptSubscriber) {
      this.fetchPatientUpcommingAptSubscriber.unsubscribe();
    }
    this.fetchPatientUpcommingAptSubscriber = this.Service.fetchPatientUpcommingApt(patient_id, provider_id).subscribe((success: any) => {
      this._loadingIndicatorService.stop();
      if (success.aptlist !== undefined) {
        this.upcommingappointments = success.aptlist;  
        console.log(this.upcommingappointments);          
      }
    },(error: any) => {
          this._loadingIndicatorService.stop();
    });    
  }*/
  selectAppointment(id:any){
    this.selectedupcommingId = (this.selectedupcommingId == id) ? 0 : id;
    this.selecteRescheduleId.emit(this.selectedupcommingId);
  }
}