<div class="marketing-page container change-password">
  <div class="content-header row" [style.min-height.px]="minHeight">
      <div class="login-form col-12 col-md-12 col-lg-10 offset-md-1 offset-sm-0 offset-xs-0">
          <div class="login-form-body col-md-6">
              <h1 class="text-uppercase margin-bottom-0">Change password</h1>
              <label>To complete the change password expired, please enter the below information</label>
              <form novalidate [formGroup]="resetForm" (ngSubmit)="reset()">
                  <div *ngIf="error" class="alert alert-danger" role="alert">{{errorMessage}}</div>
                  <div *ngIf="linkIsValid" class="form-body">
                    <div class="form-group">
                        <label for="email">Email Address</label>
                        <input type="text" tabindex="1" id="email" name="email" autoFocusCustom class="form-control" formControlName="email" [ngModel]="email" placeholder="example@example.com" maxlength="254" required>
                    </div>
                    <div class="form-group">
                        <label for="old_password">Old Password</label>
                        <i class="fa fa-exclamation-circle icon-warning" (click)="passwordFormatToolipDirective.toggle($event)"></i>
                        <input tabindex="1" type="password" [ngModel]="old_password" name="old_password" formControlName="old_password" id="old_password" class="form-control" [class.input-error]="responseError['old_password']" ngControl="old_password" maxlength="50" required>
                        <span class="message-error" *ngIf="responseError['old_password']">{{responseError['old_password']}}</span>
                    </div>
                    <div class="form-group">
                        <label for="password">New Password</label>
                        <i class="fa fa-exclamation-circle icon-warning" (click)="passwordFormatToolipDirective.toggle($event)"></i>
                        <input tabindex="1" type="password" [ngModel]="password" name="password" formControlName="password" id="password" class="form-control" [class.input-error]="responseError['password']" ngControl="password" maxlength="50" required>
                        <span class="message-error" *ngIf="responseError['password']">{{responseError['password']}}</span>
                    </div>
                    <div class="form-group">
                        <label for="password_confirmation">Confirm New Password</label>
                        <input tabindex="2" type="password" [ngModel]="password_confirmation" name="password_confirmation" formControlName="password_confirmation" id="password_confirmation" class="form-control" [class.input-error]="responseError['password_confirmation']" ngControl="password_confirmation" maxlength="50" required>
                        <span class="message-error" *ngIf="responseError['password_confirmation']">{{responseError['password_confirmation']}}</span>
                    </div>
                    <div class="row form-submit">
                        <div class="col-12 col-md-12 col-lg-4 no-padding">
                            <button type="submit" [disabled]="!resetForm.valid" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius">Reset</button>
                        </div>
                    </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>
<rtn-password-format-tooltip></rtn-password-format-tooltip>