<div class="not-found">
    <div class="container py-5">
        <div class="row">
            <div class="col-xs-8 col-sm-10 offset-xs-2 offset-sm-1">
                <div class="col-xs-12">
                    <div class="moving margin-side-auto">
                        <i class="fa fa-truck fa-fw"></i>
                        <div class="title">404</div>
                        <div id="wheel1" class="wheel">
                            <i class="fa fa-circle wheel-base"></i>
                            <i class="fa fa-cog fa-inverse fa-spin fa-fw wheel-top"></i></div>
                        <div id="wheel2" class="wheel">
                            <i class="fa fa-circle wheel-base"></i>
                            <i class="fa fa-cog fa-inverse fa-spin fa-fw wheel-top"></i>
                        </div>
                        <i class="fa fa-hospital-o fa-fw"></i>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="content margin-side-auto">
                        <div class="head-title">Page Not Found</div>
                        <div class="text">Sorry, your requested page is being hospitalized for amnesia. It cannot remember who it is or where it is going.
                            Don’t worry, our 404 team is taking it to the hospital. Meanwhile, you can <a class="link" (click)="changeToHome($event)"> click here </a>
                            and we will bring you back home.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
