import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';

import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
import { Event } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Subscription } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { AppointmentsService } from '@app/appointments/appointments.service';

declare let jQuery: any;
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'app-notify-available-slot-waitlist',
  templateUrl: './notify-available-slot-waitlist.component.html',
  styleUrls: ['./notify-available-slot-waitlist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotifyAvailableSlotWaitlistComponent  implements AfterViewInit, OnDestroy {
  fetchAvailableSlotSubscriber: Subscription;
  fetchAvailableSlotByTimeSubscriber: Subscription;
  sendAvailableSlotSubscriber: Subscription;
  getIsLockSubscriber: Subscription;
  getIsDeleteSaveSearchSubscriber: Subscription;
  
  isLock: any;
  currentDate: any = moment();
  msg:string = 'Your account has been locked.';
  // get current date by default
  
  @ViewChild('AppointmentBook_Modals') modal: ModalComponent;
  @ViewChild('inputDateNewAppointment') inputDateNewAppointment: ElementRef;
  @ViewChild('clickDateNewAppointment') clickDateNewAppointment: ElementRef;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  eventEntry: Event;
  dataDateBook: DataDateBook = new DataDateBook;
  errorMessage: string;
  errorMessageBook: string;
  searchDisabled = true;
  patients: any = [];
  patient_id: number;
  patient_name: any;
  isFamily = false;
  availableSlots: any = [];
  selectedSlot = 0;
  public savedId: number;
  time: any;
  input_group: any = '.input-group';
  search_field: any = '#search-field-new-appointment';

  // ========== Specific Modal Method ========== //
  newAvailableSlotModel: NewAvailableSlotClass = new NewAvailableSlotClass();
  requireMessage: RequireMessage = new RequireMessage();
  notifySettings:any={};
  fetchNotifySettingSubscriber: Subscription;

  constructor(
    private Service: AppointmentsService,
    private _alertService: AlertService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    // TODO
  }

  selectSlot(slotId: any) {
    this.selectedSlot = slotId;
    for (let i = 0; i < this.availableSlots.length; i++) {
      const s = this.availableSlots[i];
      s.isSelected = s.id === slotId ? true : false;
    }
  }
  fetchAvailableSlotByTime(date: any) {
    let dateRequest = null;
    if (date != null && date != '') {
      dateRequest = this._utils.formatTimeForWebServiceCall(date.clone());     
    }
    this._loadingIndicatorService.start();
    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    this.fetchAvailableSlotSubscriber = this.Service.fetchAvailableSlotByTime(this.savedId, dateRequest)
      .subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          if (success.available_slots !== null) {
            this.availableSlots = success.available_slots;
            this.selectedSlot = 0;
            this.fetchWaitlistinfo();
          }
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        }
      );
  }
  fomartTimeShowInList(time: string) {
    let value = '';
    if (time) {
      if (time === '00:00:00') {
        value = '00:00 AM';
      } else if (time === '00:30:00') {
        value = '00:30 AM';
      } else {
        value = this._utils.formatTimeString(time, 'LT');
      }
    }
    if (value.substr(1, 1) === ':') {
      value = '0' + value;
    }
    return value;
  }
  getDataAvailableSlot(savedId: any, patient_id: any, patient_name: any, dateFrom: any, dateTo: any, startTime: any, endTime: any) {
    this.savedId = savedId;
    
    //this.dataDateBook.dateFrom = moment(moment(dateFrom).add(1, 'days').format('LL'));
    //this.dataDateBook.dateTo = moment(moment(dateTo).add(1, 'days').format('LL'));

    this.dataDateBook.dateFrom = moment(dateFrom).format('LL');
    this.dataDateBook.dateTo = moment(dateTo).format('LL');

    const today = moment(this.currentDate.format('LL'));

    if (startTime === '00:00:00' && endTime === '23:59:59') {
      this.time = '';
    } else {      
      this.time =  moment(dateFrom).format('hh:mm A') + ' - '
      + moment(dateTo).format('hh:mm A')
    }

    if (!this.isLock && !this.modal.visible) {
      this.patient_id = patient_id;
      this.patient_name = patient_name;
      if (this.dataDateBook.dateFrom !== '') {
        if (this.dataDateBook.dateFrom < today && this.dataDateBook.dateTo >= today) {
          this.dataDateBook.dateFrom = this.currentDate;
        }
        this.dataDateBook.selectedDate = this.dataDateBook.dateFrom;
        this.newAvailableSlotModel.date = this.dataDateBook.selectedDate.format('MMMM DD, YYYY');
      }

      //this.setDatePicker(this.dataDateBook.dateFrom, this.dataDateBook.dateTo);
      this.errorMessageBook = null;
      //this.fetchAvailableSlotByTime(moment(this.dataDateBook.selectedDate));
      this.fetchWaitlistinfo();
      this.modal.open();
    }    
  }
  showMessage(msg: any) {
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  checkLockedProvider(type: any, savedId: any = null, patient_id: any = null, patient_name: any = null, dateFrom: any = null, dateTo: any = null, startTime: any = null, endTime: any = null, is_family:any=null) {
    this._loadingIndicatorService.start();
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this.isLock = success.providers.is_lock;
        if (this.isLock) {
          if (type == 2) {
            this._loadingIndicatorService.stop();
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              window.location.reload();
            };
            this.showMessage(this.msg);
          } else if (type == 1) {
            this._loadingIndicatorService.stop();
            this.dismiss();
            this.close();
            this.showMessage(this.msg);
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              window.location.reload();
            };
          }
        } else {
          if (type == 1) {
            this.checkIsDeleteAndStatusSaveSearch();
            this._loadingIndicatorService.stop();
          } else if (type == 2) {
            this.savedId = savedId;
            this.patient_id = patient_id;
            this.isFamily = is_family;
            this.availableSlots = [];
            this.getDataAvailableSlot(savedId, patient_id, patient_name, dateFrom, dateTo, startTime, endTime);
            this._loadingIndicatorService.stop();
          }
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }

  checkIsDeleteAndStatusSaveSearch(){
    this._loadingIndicatorService.start();
    this.getIsDeleteSaveSearchSubscriber = this.Service.getIsDeleteAndStatusSaveSearch(this.savedId, this.patient_id).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        if(success.isDeletedAt.deleted_at!=null){
          this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
            this.dismiss();
            this.close();
            window.location.reload();

          };
          this.showMessage('This patient is no longer in the waitlist.');
        }else if(success.status==2){
          this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
            this.dismiss();
            this.close();
            window.location.reload();

          };
          this.showMessage('This patient is inactive.');
        }else if(success.is_family==false){
          this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
            this.dismiss();
            this.close();
            window.location.reload();

          };
          this.showMessage('This patient is no longer in the waitlist.');
        }
        else{
          this.sendAvailableSlot();
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      },
      () => {
        
      }
    );
  }

  setDatePicker(startDate: any, endDate: any) {
    startDate = moment(startDate).toDate();
    endDate = moment(endDate).toDate();
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker('destroy');
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker({
      format: 'MM dd, yy',
      altFormat: 'MM dd, yy',
      numberOfMonths: 2,
      minDate: startDate,
      maxDate: endDate,
      showButtonPanel: true,
      onSelect: (selected: any) => {
        this.availableSlots = [];
        this.dataDateBook.selectedDate = moment(selected);
        this.newAvailableSlotModel.date = this.dataDateBook.selectedDate.format('MMMM DD, YYYY');
        this.fetchAvailableSlotByTime(this.dataDateBook.selectedDate);
        this.errorMessageBook = null;
      }
    });
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker('setDate', startDate);
    jQuery(this.clickDateNewAppointment.nativeElement).click(() => {
      jQuery(this.inputDateNewAppointment.nativeElement).datepicker('show');
    });
  }
 
  sendAvailableSlot() {

    var emailid = null;
    var mobile_number = null;

    if (this.newAvailableSlotModel.is_email) {
      emailid = this.newAvailableSlotModel.email;
    }

    if (this.newAvailableSlotModel.is_phone) {
      mobile_number = this.newAvailableSlotModel.phone.first + ' ' + this.newAvailableSlotModel.phone.middle + '-' + this.newAvailableSlotModel.phone.last;
      const numberOnly = mobile_number.replace(/[^0-9]/g, '');
      mobile_number = numberOnly ? mobile_number : null;
    }
    

    this.errorMessageBook = null;
    this._loadingIndicatorService.start();
    if (this.sendAvailableSlotSubscriber) {
      this.sendAvailableSlotSubscriber.unsubscribe();
    }

    this.sendAvailableSlotSubscriber = this.Service.sendAvailableSlotFromWaitList({
      patient_id: this.patient_id,
      //slot_id: this.selectedSlot,
      is_family: this.isFamily,
      save_id: this.savedId,
      is_email : this.newAvailableSlotModel.is_email,
      is_phone : this.newAvailableSlotModel.is_phone,
      email_id : emailid,
      phone : mobile_number,
      content : this.newAvailableSlotModel.content
    })
      .subscribe(
        (res: any) => {
          this._loadingIndicatorService.stop();
          if (res.error_code === 'RC000') {
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              //window.location.reload();
            };
            this._alertService.showDismissButton = false;
            this._alertService.dismissButtonLabel = 'No';
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'OK';
            this._alertService.title = res.error_messages;
            this._alertService.message = '';
            this._alertService.emitter('open');
          }
          if (res.error_code === 'RC002' || res.error_code === 'RC061' || res.error_code === 'RC119' || res.error_code === 'RC086' || res.error_code === 'RC144') {
            this.errorMessageBook = res.error_messages;
          }
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
          const e = error.error;
          if (e.error_code === 'RC002' || e.error_code === 'RC061' || e.error_code === 'RC119') {
            this.errorMessageBook = e.error_messages;
          }
        },
        () => {
          this._loadingIndicatorService.stop();
        }
      );


  }

  getTime(time: any) {
    // return moment(time).format('hh:mm A');
    return moment(time).format('HH:mm');
  }
// ========== Class Method ========== //

  ngAfterViewInit() {
    

  }

  ngOnDestroy() {
    this.selectedSlot = 0;
    
    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    if (this.sendAvailableSlotSubscriber) {
      this.sendAvailableSlotSubscriber.unsubscribe();
    }    
  }

  // ========== General Modal Method ========== //

  dismiss() {
    this.newAvailableSlotModel.patient = this.patient_id = null;
    this.requireMessage = new RequireMessage();
    jQuery(this.search_field).parent().removeClass('open');
  }

  onKeyUp() {
    this.requireMessage.patient_id = null;
    if (jQuery(this.search_field).val() === '') {
      this.patient_id = null;
      this.searchDisabled = true;
      jQuery('.search').removeClass('open');
    } else {
      this.searchDisabled = false;
    }
  }

  close() {
    this.selectedSlot = 0;
    this.modal.close();
  }

  fetchWaitlistinfo() {
    
    this._loadingIndicatorService.start();
    if (this.fetchNotifySettingSubscriber) {
      this.fetchNotifySettingSubscriber.unsubscribe();
    }
    this.fetchNotifySettingSubscriber = this.Service.fetchWaitlistinfo(this.savedId).subscribe((res : any) => {
          this._loadingIndicatorService.stop();
          
          this.notifySettings = res.data;
          this.newAvailableSlotModel.email = this.notifySettings.email_id;

          var mobile_number = this.notifySettings.mobile;

          this.newAvailableSlotModel.phone.first = '';
          this.newAvailableSlotModel.phone.middle = '';
          this.newAvailableSlotModel.phone.last = '';

          if (mobile_number) {
            const num = mobile_number.replace(/[^0-9]/g, '');
            const firstMobileNumber = num.substr(0, 3);
            const secondMobileNumber = num.substr(3, 3);
            const thirdMobileNumber = num.substr(6, 4);

            this.newAvailableSlotModel.phone.first = '(' + firstMobileNumber + ')';
            this.newAvailableSlotModel.phone.middle = secondMobileNumber;
            this.newAvailableSlotModel.phone.last = thirdMobileNumber; 
          }
          this.newAvailableSlotModel.content = this.notifySettings.apt_opening;

          /*if (this.availableSlots.length > 0) {
            this.newAvailableSlotModel.content = this.notifySettings.apt_opening;
          } else {
            this.newAvailableSlotModel.content =  '';
          }*/       
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        }
      );
  }
}

export class NewAvailableSlotClass {
  patient = '';
  date: string;
  email: string = '';
  phone: any = {
    first : '',
    middle : '',
    last : ''
  };
  content: string = '';
  is_email: boolean= true;
  is_phone: boolean= false;
}

export class DataDateBook {
  selectedDate: any = moment();
  dateFrom: any = '';
  dateTo: any = '';
}

/*export class PatientInfoClass {
  id: number;
  first_name: string;
  last_name: string;
  dob: string;
  phone_mobile: number;
  is_family: boolean;
}*/

export class RequireMessage {
  patient_id: string;
  date: string;
  start_time: string;
  end_time: string;
  email: string;
  phone: string;
  content: string = '';
}
