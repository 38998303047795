import {Injectable} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import * as moment from 'moment';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Injectable({
  providedIn: 'root'
})
export class Utils {
  constructor(
    private _router: Router,
    private _routerActive: ActivatedRoute
  ) {
  }

  generateFullName(firstName: string, middleName: string, lastName: string) {
    let fullName = '';
    if (middleName !== null && middleName !== undefined) {
      if (middleName.length > 0 && middleName !== ' ') {
        fullName = firstName + ' ' + middleName + '. ' + lastName;
      } else {
        fullName = firstName + ' ' + lastName;
      }
    } else {
      fullName = firstName + ' ' + lastName;
    }
    return fullName;
  }

  generateFullAddress(address1: string, address2: string, city: string, state: string, zip: string) {
    const fullAddress: any = [];
    for (let i = 0; i < arguments.length - 1; i++) {
      if (arguments[i] !== null && arguments[i] !== '') {
        fullAddress.push(arguments[i]);
      }
    }
    let f: string = fullAddress.join(', ');
    if (zip !== null && zip !== '') {
      if (state !== null && state !== '') {
        f = f + ' ' + zip;
      } else {
        if (f !== '') {
          f = f + ', ' + zip;
        } else {
          f = f + zip;
        }
      }
      return f;
    } else {
      return f;
    }
  }

  generateOpeningHours(hours: any, mode: number) {
    const weekDayCode1 = ['U', 'M', 'T', 'W', 'R', 'F', 'S'];
    const weekDayCode2 = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const weekDayCode3 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekDayHybrid = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
    const weekDayFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let weekDay = weekDayCode3;
    const opening_hours: any = [];
    hours.forEach((timeSlot: any) => {
      // let final: string;
      const days: any = [];
      const seed: any = [];
      let range: any = [];
      const processed: any = [];
      for (let i = 0; i < weekDay.length; i++) {
        if (timeSlot.days['day' + i]) {
          seed.push(i);
        }
      }
      if (seed[0] !== null && seed[0] !== undefined) {
        range.push(seed[0]); // Sunday is the first day
        for (let i = 1; i < seed.length; i++) {
          if (seed[i] - seed[i - 1] !== 1) {
            processed.push(range);
            range = [];
          }
          range.push(seed[i]);
        }
        if (range.length !== 0) {
          processed.push(range);
        }
        // Decide which representation of weekday to use
        switch (mode) {
          case 1:
            weekDay = weekDayCode1;
            break;
          case 2:
            weekDay = weekDayCode2;
            break;
          case 3:
            weekDay = weekDayCode3;
            break;
          case 4:
            weekDay = weekDayFull;
            break;
          case 5:
            weekDay = weekDayHybrid;
            break;
          default:
            break;
        }
        processed.forEach((timeRange: any) => {
          if (timeRange.length === 1) {
            days.push(weekDay[timeRange[0]]);
          } else if (timeRange.length === 2) {
            days.push(weekDay[timeRange[0]]);
            days.push(weekDay[timeRange[1]]);
          } else if (timeRange.length < 7) {
            days.push(weekDay[timeRange[0]] + ' - ' + weekDay[timeRange[timeRange.length - 1]]);
          } else {
            days.push('Sun - Sat');
          }
        });
        opening_hours.push({
          time_slot: timeSlot.start_time + ' - ' + timeSlot.end_time,
          time_days: days.join(', ')
        });
      }
    });
    return opening_hours;
  }

  // generateTimeAgoAndCredentials(list: any) {
  //   for (const item of list) {
  //     console.log(item.mins);
  //     if (list[item].mins < 60) {
  //       list[item].timeAgo = list[item].mins + ' minutes ago';
  //     } else if (list[item].mins <= 1440) {
  //       list[item].timeAgo = Math.round(list[item].mins / 60) + ' hours ago';
  //     } else if (list[item].mins <= 43200) {
  //       list[item].timeAgo = Math.round(list[item].mins / 1440) + ' days ago';
  //     } else {
  //       const time = moment(list[item].time, moment.ISO_8601).local();
  //       const date = time.format('MMMM DD, YYYY');
  //       const hour = time.format('HH:mm');
  //       list[item].timeAgo = date + ' at ' + hour;
  //     }
  //     list[item].fullName = this.generateFullName(list[item].first_name, list[item].middle_name, list[item].last_name);
  //     if (list[item].credentials !== '' && list[item].credentials !== null) {
  //       list[item].fullName += ', ' + list[item].credentials;
  //     }
  //   }
  //   return list;
  // }

  generateTimeAgoAndCredentials(list: any) {
    for (const item of list) {
      if (item.mins < 60) {
        item.timeAgo = item.mins + ' minutes ago';
      } else if (item.mins <= 1440) {
        item.timeAgo = Math.round(item.mins / 60) + ' hours ago';
      } else if (item.mins <= 43200) {
        item.timeAgo = Math.round(item.mins / 1440) + ' days ago';
      } else {
        const time = moment(item.time, moment.ISO_8601).local();
        const date = time.format('MMMM DD, YYYY');
        const hour = time.format('HH:mm');
        item.timeAgo = date + ' at ' + hour;
      }
      item.fullName = this.generateFullName(item.first_name, item.middle_name, item.last_name);
      if (item.credentials !== '' && item.credentials !== null) {
        item.fullName += ', ' + item.credentials;
      }
    }
    return list;
  }

  generateTimeArray(startMinute: number, minuteStep: number, endMinute: number) {
    const result: any = new Array();
    for (let time = startMinute; time < endMinute; time += minuteStep) {
      const minute = time % 60;
      const hour = (time - minute) / 60;
      const minuteDisplay = minute < 10 ? '0' + minute : minute;
      const hourDisplay = hour < 10 ? '0' + hour : hour;
      result.push(hourDisplay + ':' + minuteDisplay);
    }
    return result;
  }

  generateValueUnitDisplay(value: number, unit: string) {
    if (Math.abs(value) < 2) {
      return value + ' ' + unit;
    } else {
      return value + ' ' + unit + 's';
    }
  }

  generateUnitDisplay(value: number, unit: string) {
    if (Math.abs(value) < 2) {
      return unit;
    } else {
      return unit + 'S';
    }
  }
  generateUnitWithValueDisplay(value: number, unit: string) {
    if (Math.abs(value) < 2) {
      return value + ' ' + unit;
    } else {
      return value + ' ' + unit + 'S';
    }
  }

  formatTime24h(t: number) {
    let hour: number;
    let minute: number;
    let hourS: string;
    let minuteS: string;
    minute = t % 60;
    hour = (t - (minute)) / 60;
    minuteS = (minute < 10) ? ('0' + minute) : '' + minute;
    hourS = (hour < 10) ? ('0' + hour) : '' + hour;
    return (hourS + ':' + minuteS);
  }

  parseTimeFromWebServiceCall(time: string) {
    return moment(time, moment.ISO_8601);
  }

  formatTimeForWebServiceCall(momentObj: any) {
    return momentObj.clone().utc().format();
  }

  calculateDuration(momentStart: any, momentEnd: any) {
    return moment.duration(momentEnd.diff(momentStart)).asMinutes();
  }

  calculateDurationIgnoringDST(momentStart: any, momentEnd: any) {
    return moment.duration(momentEnd.diff(momentStart)).asMinutes() + (momentEnd.utcOffset() - momentStart.utcOffset());
  }

  encodeParentheses(code: string) {
    return code.replace(/\(/g, '%28').replace(/\)/g, '%29');
  }

  // Follow Bootstrap v 4.0 standard
  // Extra Small: Portait Phones (<768px)
  // Small: Landscape Phones (<768px)
  // Medium: Tablets (≥768px)
  // Large: Desktops (≥992px)
  // Extra Large: Large Desktops (≥1200px)
  detectDeviceBasedOnWidth(device: string) {
    let max_bp = 2000;
    // let bp1 = 544;
    // let bp2 = 768;
    // let bp3 = 992;
    // let bp4 = 1200;

    switch (device) {
      case 'p-phone':
        // Portrait Phone
        max_bp = 544;
        break;
      case 'l-phone':
        // Landscpe Phone
        max_bp = 768;
        break;
      case 'tablet':
        // Tablet
        max_bp = 992;
        break;
      case 'desktop':
        // Dekstop
        max_bp = 1200;
        break;
      default:
        break;
    }
    if (max_bp === 2000) {
      return true;
    }
    // This is to ensure it calculates only the true visible widht of the viewport,
    // not including the vertical scrollbar which appears when the window is too short
    const viewPortWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (viewPortWidth < max_bp) {
      return true;
    } else {
      return false;
    }
  }

  //    decodeParentheses(code: string) {
  //        return code.replace(/%28/g, "(").replace(/%29/g, ")");
  //    }

  bytesToSize(bytes: any) {
    if (bytes < 0) {
      return 'Invalid size';
    }
    if (bytes === 0) {
      return '0 Byte';
    }
    const k = 1024;
    const decimals = 1;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  }

  /**
   * Format 13:34:56 to 01:34 PM
   * @param time
   */
  formatTimeString(time: any, formatType: any) {
    const fields = time.split(':');
    const hourData = fields[0];
    const minuteData = fields[1];
    const secondData = fields[2];
    return moment({hours: hourData, minutes: minuteData, seconds: secondData}).format(formatType);
  }

  getDate(date: string) {
    if (date) {
      return moment.utc(date).local().format('MMMM DD, YYYY');
    }
  }

  formatDate(date: string) {
    const format_style = 'MMMM DD, YYYY';
    if (date) {
      return moment(date).format(format_style);
    }
  }

  getTime(time: string) {
    if (time) {
      return moment.utc(time).local().format('hh:mm A');
    }
  }

  getDateTimeFormat(dateTime: string) { // Format like Tuesday, December 20, 2016 3:30 PM
    if (dateTime) {
      return moment.utc(dateTime).local().format('dddd, MMMM DD, YYYY hh:mm A');
    }
  }

  getSpecialties(specialties: any) {
    if (specialties) {
      const res: any = [];
      for (let i = 0; i < specialties.length; i++) {
        res.push(specialties[i].name);
      }
      return res.join(', ');
    }
  }

  explodePhoneMobile(obj: any) {
    if (obj.mobile) {
      let phone = obj.mobile.replace('+1', '').match(/\d/g);
      if (phone) {
        phone = phone.join('');
        obj.first = phone.substring(0, 3);
        obj.middle = phone.substring(3, 6);
        obj.last = phone.substring(6, 10);
      }
    }
    return obj;
  }

  implodePhoneMobile(first: any, middle: any, last: any) {
    if (first) {
      const firstNew = first.match(/\d/g).join('');
      return '(' + firstNew + ') ' + middle + '-' + last;
    }
    return '';
  }

  getUrlParameter(name: any) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(name);
    }
    return paramValue;
    // name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    // const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    // console.log(location.search);
    // const results = regex.exec(location.search);
    // return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  decodeBase64UrlParameter(name: string) {
    return window.atob(name);
  }

  // If status = 4 (Partial Active, only access to Update Insurance,
  // Search Openings, Book appointments: maximum 3 appointments only)
  isPartialActiveStatus() {
    if (localStorage.getItem('user_status') === '4') {
      return true;
    } else {
      return false;
    }
  }

  isPendingStatus() {
    if (localStorage.getItem('user_status') === '0') {
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    if (localStorage.getItem('user_role_id') === '1') {
      return true;
    } else {
      return false;
    }
  }

  isProvider() {
    if (localStorage.getItem('user_role_id') === '3') {
      return true;
    } else {
      return false;
    }
  }

  isManagingUser() {
    if (localStorage.getItem('user_role_id') === '6') {
      return true;
    } else {
      return false;
    }
  }

  isSuperUser() {
    if (localStorage.getItem('user_role_id') === '5') {
      return true;
    } else {
      return false;
    }
  }

  isPatients() {
    if (localStorage.getItem('user_role_id') === '4') {
      return true;
    } else {
      return false;
    }
  }

  havePractice() {
    if (localStorage.getItem('user_practice') === 'null') {
      return false;
    } else {
      return true;
    }
  }

  accessByProvider() {
    if (localStorage.getItem('user_role_id') === '3') {
      return true;
    } else {
      if (this.isSuperUser()) {
        if (this.havePractice()) {
          this._router.navigate(['/provider', 'practice']);
        } else {
          this._router.navigate(['/provider', 'practice', 'add']);
        }
      } else if (this.isManagingUser()) {
        if (this.havePractice()) {
          this._router.navigate(['/provider', 'manage']);
        } else {
          this.removeLocalStorage();
          this._router.navigate(['/home', 'login']);
        }
      }
      return false;
    }
  }

  accessPractice() {
    if (this.isSuperUser()) {
      if (this.havePractice()) {
        return true;
      } else {
        this._router.navigate(['/provider', 'practice', 'add']);
        return false;
      }
    } else if (this.isManagingUser()) {
      this._router.navigate(['/provider', 'manage']);
      return false;
    } else {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    }
  }

  accessAddPractice() {
    if (this.isSuperUser()) {
      if (this.havePractice()) {
        // this._router.navigate(['/provider', 'practice']);
        this._router.navigate(['/provider', 'practice','add']);
        return false;
      } else {
        return true;
      }
    } else if (this.isManagingUser()) {
      this._router.navigate(['/provider', 'manage']);
      return false;
    } else {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    }
  }

  accessEditPractice() {
    if (this.isSuperUser()) {
      if (this.havePractice()) {
        return true;
      } else {
        this._router.navigate(['/provider', 'practice', 'add']);
        return false;
      }
    } else if (this.isManagingUser()) {
      this._router.navigate(['/provider', 'manage']);
      return false;
    } else {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    }
  }

  accessManageUser() {
    if (this.isSuperUser()) {
      if (this.havePractice()) {
        return true;
      } else {
        this._router.navigate(['/provider', 'practice', 'add']);
        return false;
      }
    } else if (this.isManagingUser()) {
      this._router.navigate(['/provider', 'manage']);
      return false;
    } else {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    }
  }

  accessAsSuperUser() {
    if (this.isSuperUser()) {
      return true;
    } else if (this.isManagingUser()) {
      this._router.navigate(['/provider', 'manage']);
      return false;
    } else if (this.isProvider()) {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    } else {
      return false;
    }
  }

  accessAsSuperOrManagingUser() {
    if (this.isSuperUser() || this.isManagingUser()) {
      return true;
    } else if (this.isProvider()) {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    } else {
      return false;
    }
  }

  accessListProvider() {
    if (this.isSuperUser()) {
      return true;
    } else if (this.isManagingUser()) {
      this._router.navigate(['/provider', 'manage']);
      return false;
    } else {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    }
  }

  accessAddProvider() {
    if (this.isSuperUser()) {
      return true;
    } else if (this.isManagingUser()) {
      this._router.navigate(['/provider', 'manage']);
      return false;
    } else {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    }
  }

  accessEditUser() {
    if (this.accessAsSuperOrManagingUser()) {
      if (this.havePractice() || this.isManagingUser()) {
        return true;
      } else {
        if (this.isSuperUser()) {
          this._router.navigate(['/provider', 'practice', 'add']);
        } else {
          this.removeLocalStorage();
          this._router.navigate(['/home', 'login']);
        }
        return false;
      }
    } else {
      this._router.navigate(['/provider', 'appointment']);
      return false;
    }
  }

  removeLocalStorage() {
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role_id');
    localStorage.removeItem('user_status');
    localStorage.removeItem('user_email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('user_practice');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('managing_user_id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('last_active_token');
    localStorage.removeItem('storeData');
  }
}
