import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {PasswordFormatTooltipComponent} from '@app/shared/tooltip/password-format';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../shared/alert';
import {HomepageService} from '../home.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

@Component({
  selector: 'rtn-home-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../home.component.scss', './change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  resetForm: FormGroup;
  linkIsValid: boolean = true;
  error: boolean = false;
  errorMessage: any;
  responseError: PasswordItem = new PasswordItem();
  id: string;
  token: string;
  password: string;
  password_confirm: string;
  minHeight: number;

  validLinkSubscriber: Subscription;
  changePasswordSubscriber: Subscription;

  @ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _homePageService: HomepageService,
    private _params: ActivatedRoute,
    private _router: Router,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.resetForm = this._formBuilder.group({
      password: ['', Validators.compose([Validators.required])],
      password_confirm: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    //TODO: Get token/ id from URL
    this.id = this._params.snapshot.paramMap.get('id');
    this.token = this._params.snapshot.paramMap.get('token');
    // Validate id & token
    if (this.validLinkSubscriber) {
      this.validLinkSubscriber.unsubscribe();
    }
    this.validLinkSubscriber = this._homePageService.validLink(this.id, this.token).subscribe(
      (success: any) => {
        // Todo: Set Link valid = true => Show change password form
        this.linkIsValid = true;
      }, (error: any) => {
        // Todo: Set Link valid = false => Hide change password form
        this.linkIsValid = false;
        this.error = true;
        this.errorMessage = error.error.error_messages;
      });
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }

  reset() {
    var self = this;
    var resetData = {
      'password': this.resetForm.value.password,
      'password_confirmation': this.resetForm.value.password_confirm,
      'id': this.id,
      'token': this.token
    };
    // Call to service change password
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.changePasswordSubscriber = this._homePageService.changePassword(resetData).subscribe(
      (success: any) => {
        self._loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          // TODO
          self.error = false;
          self.password = null;
          self.password_confirm = null;
          self._alertService.onClose = () => {
            self._router.navigate(['/home', 'login']);
          };
          self._alertService.showDismissButton = false;
          self._alertService.dismissButtonLabel = 'Close';
          self._alertService.showCloseButton = true;
          self._alertService.closeButtonLabel = 'OK';
          self._alertService.title = 'Your password is reset successfully!';
          self._alertService.message = '';
          self._alertService.emitter('open');
        } else {
          self._loadingIndicatorService.stop();
          self.responseError = success.error_fields;
        }
      }, (error: any) => {
        self._loadingIndicatorService.stop();
        self.responseError = error.error.error_fields;
      });
  }

  ngOnDestroy() {
    if (this.validLinkSubscriber) {
      this.validLinkSubscriber.unsubscribe();
    }
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
  }
}

export class PasswordItem {
  password: string = '';
  password_confirm: string = '';
}
