import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output,
    OnInit,
    ViewChild,
    ViewEncapsulation
  } from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
import { Event } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { from, Subscription } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { PatientsService } from '@app/patients/patients.service';
//import { AppointmentsService } from '@app/appointments/appointments.service';
//import { ProvidersService } from '@app/providers/providers.service';

declare let jQuery: any;
import * as moment from 'moment';

@Component({
   
    selector: 'rtn-modals-verify-phoneno',
    templateUrl: './verify-phoneno.component.html',
    styleUrls: ['./verify-phoneno.component.scss'],
    //encapsulation: ViewEncapsulation.None
})

export class VerifyPhonenoComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('Verifyphoneno_Modals') modal: ModalComponent;

    @Output()
    reload: EventEmitter<any> = new EventEmitter();
    eventEntry: Event;
    patientDetails:any;
    constructor(
        //private Service: AppointmentsService,
        private _alertService: AlertService,
        private _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _service: PatientsService
        //private providersService: ProvidersService
    ) {
       
    }

    ngOnInit() {
    }
  

    ngAfterViewInit() {

    }

    setInitial() {
        
    }

    verifyPhonenoClicked() {

    }

    ngOnDestroy() {

    }

    open(patientInfo:any) {
      this.patientDetails = patientInfo;
      //console.log(this.patientDetails);
        this.modal.open();
        this.setInitial();
    }
    close() {
      
      this.modal.close();
    }
    dismiss() {
      //const iscancel = true;
      localStorage.setItem('iscancel', 'yes');
     // console.log(iscancel);
      this.modal.dismiss();
    }

    verifyPhoneno() { 
      //var response = this._service.verifyPhoneNo(1);
      //console.log(response);
      this._service.verifyPhoneNo({"ismanualverification":'yes'}).subscribe(
        (success: any) => {
          
          this._alertService.onClose = () => {
            // location.reload();
          };
          this._alertService.showDismissButton = false;
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'Close';
         // this._alertService.title = success.message;
          this._alertService.message = success.message;
          this._alertService.emitter('open');
          this.modal.dismiss();
        }
      );
      
    }
}