import {Component, AfterViewInit, OnInit, ViewChild, OnDestroy, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {AlertService} from '@app/shared/alert';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {SystemEvent} from '@app/shared/system';
import {DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent} from '@app/shared/datatable';
import {ProviderUnfollow_ModalsComponent} from '@app/patients/patient/provider-unfollow/provider-unfollow.component';
import * as moment from 'moment';
import {PatientsService} from '@app/patients/patients.service';

declare var jQuery: any;
const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
  selector: 'rtn-doctor-follow',
  templateUrl: './doctor-follow.component.html',
  styleUrls: ['../patients.component.scss', './doctor-follow.component.scss'],
  moduleId: module.id,
})

export class DoctorFollowlComponent implements OnInit, AfterViewInit, OnDestroy {

  followedProvider: any = new Object();
  numberOfRowDefault = 10;
  isMobile = false;

  followedProviderSubscriber: Subscription;
  unfollowProviderSubscriber: Subscription;

  @ViewChild(RtnDataTableCoreComponent) rtnDataTableCore: RtnDataTableCoreComponent;
  @ViewChild(RtnDataTableFilterComponent) rtnDataTableFilter: RtnDataTableFilterComponent;
  @ViewChild(ProviderUnfollow_ModalsComponent) providerUnfollowDirective: ProviderUnfollow_ModalsComponent;

  constructor(
    private _restService: PatientsService,
    private _router: Router,
    private _utils: Utils,
    private ngZone: NgZone,
    private _systemEvent: SystemEvent
  ) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    window.onresize = (e: any) => {
      this.ngZone.run(() => {
        if (window.innerWidth < 768) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    };
    this.followedProvider.rowsOnPage = this.numberOfRowDefault;
    this.followedProvider.numberOfPages = 0;
    this.followedProvider.numberOfRow = 0;
    this.followedProvider.data = new Array();
  }

  ngOnInit() {
    if (this._utils.isPartialActiveStatus()) {
      this._router.navigate(['/patient', 'appointment', 'openings']);
    }
  }

  ngAfterViewInit() {
    this._systemEvent.menu.emit('find-providers');
  }

  ngOnDestroy() {
    if (this.followedProviderSubscriber) {
      this.followedProviderSubscriber.unsubscribe();
    }
    if (this.unfollowProviderSubscriber) {
      this.unfollowProviderSubscriber.unsubscribe();
    }
  }

  handleFetchFollowedProvider(args: any) {
    args.page = args['currentPage'] || 1;
    args.limit = args['rowsOnPage'] || this.numberOfRowDefault;
    if (this.followedProviderSubscriber) {
      this.followedProviderSubscriber.unsubscribe();
    }
    this.followedProviderSubscriber = this._restService.followedProvider(args.limit, args.page).subscribe(
      (success: any) => {
        this.followedProvider.rowsOnPage = success.limit || this.numberOfRowDefault;
        this.followedProvider.numberOfPages = success.total_pages || 0;
        const providers = success.providers || [];
        this.followedProvider.numberOfRow = success.providers.length;
        providers.map((item: any, i: any) => {

          const fullName = this._utils.generateFullName(item.first_name, item.middle_name, item.last_name);
          item.fullName = fullName;
          if (item.credentials !== '' && item.credentials !== null) {
            item.fullName += ', ' + item.credentials;
          }
          item.full_address = this._utils.generateFullAddress(item.address, item.address2,
            item.city, item.state_code, item.zip);
          const listSpecialtiesName = new Array();
          const specialties = item.specialties;
          for (let i = 0; i < specialties.length; i++) {
            listSpecialtiesName.push(specialties[i].name);
          }
          item.specialties = listSpecialtiesName.join(', ');
          if (item.phone_mobile) {
            if (item.phone_mobile.indexOf('+1 ') !== -1) {
              item.phone_mobile = item.phone_mobile.substr(item.phone_mobile.indexOf('+1 ') + 3);
            }
          }
        });

        this.followedProvider.data = providers;
        setTimeout(this.checkSelectStatus, 10);
      },
      (error: any) => {
        // TODO
      }
    );
  }

  unfollowProvider(dataTable: RtnDataTableCoreComponent) {
    const self = this;
    const unfollowedProvider
      = jQuery('input[name="select-list[]"]:checked').map(function () {
      return jQuery(this).val();
    }).get();
    if (this.unfollowProviderSubscriber) {
      this.unfollowProviderSubscriber.unsubscribe();
    }
    this.unfollowProviderSubscriber = self._restService.unfollowProvider({
      provider_ids: unfollowedProvider
    }).subscribe(
      (success: any) => {
        const uniqueArray = unfollowedProvider.filter(function (item: any, pos: any) {
          return unfollowedProvider.indexOf(item) === pos;
        });
        if (uniqueArray.length === dataTable.data.length && dataTable.currentPage > 1) {
          dataTable.setPage(dataTable.currentPage - 1, dataTable.rowsOnPage);
        } else {
          dataTable.reload();
        }
      },
      (error: any) => {
        // TODO
      });
  }

  showProviderUnfollowDirective() {
    this.providerUnfollowDirective.minimumSelectedStatus = this.checkMinimumSelect();
    this.providerUnfollowDirective.open();
  }

  checkMinimumSelect() {
    return (jQuery('input[name="select-list[]"]:checked').length > 0);
  }

  checkSelectStatus() {
    jQuery('label[for="selectorAll"]').removeClass('selectorIntermediate').addClass('selectorExtreme');
    // jQuery('input[name="selectAll"]').prop("indeterminate", false);
    if (jQuery('input[name="select-list[]"]:checked').length === 0) {
      jQuery('input[name="selectAll"]').prop('checked', false);
    } else {
      if (jQuery('input[name="select-list[]"]:checked').length === jQuery('input[name="select-list[]"]').length) {
        jQuery('input[name="selectAll"]').prop('checked', true);
      } else {
        // jQuery('input[name="selectAll"]').prop("indeterminate", true);
        jQuery('label[for="selectorAll"]').addClass('selectorIntermediate').removeClass('selectorExtreme');
        jQuery('input[name="selectAll"]').prop('checked', true);
      }
    }
    return;
  }

  selectAll() {
    jQuery('label[for="selectorAll"]').removeClass('selectorIntermediate').addClass('selectorExtreme');
    if (jQuery('input[name="selectAll"]:checked').length > 0) {
      jQuery('input[name="select-list[]"]').prop('checked', true);
    } else {
      jQuery('input[name="select-list[]"]').prop('checked', false);
    }
    return;
  }

}
