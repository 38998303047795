<modal #AppointmentNew_Modals class="appointment-new">
  <form method="post" #form="ngForm" (ngSubmit)="addNewAvailableSlot(1,form.value)">
    <div class="form-content">
      <div class="form-header">New Available Slots</div>
      <div class="form-info">
        <div class="row provider-style" *ngIf="isManagingMode">
          <div class="col-2 form-input-label margin-top-15">Additional Provider(s)</div>
          <div class="col-10 padding-right-15">
            <select select2-provider class="form-control multichoice-edit-form" [tags]="false" (OnKeyUp)="onKeyUp('provider')" [maximumSelectionLength]="0">
              <option *ngFor="let item of providerList" data-id="item.id" [value]="item.id">{{item.full_name}}</option>
            </select>
            <span class="message-error" *ngIf="errorProvider">{{errorProvider}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-2 form-input-label input-label-required px-0">Location</div>
          <div class="col-10">
            <div class="input-group select width-full">        
                <select class="form-control textbox-add-form" [(ngModel)]="newAvailSlot.location_id" name="location_id">                  
                    <option [value]="''" selected>Select Location</option>
                    <ng-container *ngIf="practiceLocationGroups.length > 0">                      
                        <optgroup *ngFor="let providerLocgroups of practiceLocationGroups; let idx1 = index" [label]="providerLocgroups.group_name">
                          <option *ngFor="let providerLoc of providerLocgroups.locations" [value]="providerLoc.id">{{ providerLoc.location_name }}</option>
                        </optgroup>                      
                    </ng-container>
                    <ng-container *ngIf="practiceLocationGroups.length == 0">
                      <option *ngFor="let providerLoc of providerLocations" [value]="providerLoc.id">{{ providerLoc.location_name }}</option>
                    </ng-container>
                </select>
                <div class="input-group-select-icon cursorPointer select-state">
                    <i class="fa fa-caret-down"></i>
                </div>
            </div>
            <label class="message-error my-0">{{ requireMessage.location_id }}</label>
          </div>
        </div>
        
        <div class="row">
          <div class="col-2 form-input-label px-0">From</div>
          <div class="col-10 date-select">
            <div class="input-group">
              <input type="text" #inputDateNewAppointment class="form-control calender-input bc-white"
                     [class.input-error]="requireMessage.date != null" [(ngModel)]="newAvailSlot.date" disabled name="input-date">
              <div class="input-group-append" #clickDateNewAppointment>
                <span aria-hidden="true" class="input-group-text fas fa-calendar-alt"></span>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-2 form-input-label px-0">To</div>
          <div class="col-10 date-select" style="margin-top: 5px;">
            <div class="input-group">
              <input type="text" #inputEndDateNewAppointment class="form-control calender-input bc-white"
                     [class.input-error]="requireMessage.enddate != null" [(ngModel)]="newAvailSlot.enddate" disabled name="input-end-date">
              <div class="input-group-append" #clickEndDateNewAppointment>
                <span aria-hidden="true" class="input-group-text fas fa-calendar-alt"></span>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class= "col-10 offset-lg-2">
            <p><input type="checkbox" [(ngModel)]="newAvailSlot.includeSaturday" name="input-incl-Sat" /> Include Saturdays</p>
            <p><input type="checkbox" [(ngModel)]="newAvailSlot.includeSunday" name="input-incl-Sun" /> Include Sundays</p>
          </div>
        </div>
        <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
          <div class="message-error">{{ requireMessage.date }}</div>
          <div class="message-error">{{ requireMessage.enddate }}</div>
        </div>
        <div class="row" *ngFor="let slot of slotList; let i = index" [attr.id]="'slot-id-'+i">
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 form-input-label px-0">Slot {{i + 1}}</div>
          <div class="col-6 content pl-3">
            <div class="input-group select time-picker">
              <select class="form-control" [class.input-error]="requireMessage['slot.'+i+'.start_time'] != null" [(ngModel)]="slot.hourStart" name="slot_{{i}}_hourStart" (click)="onClicked('start_time')">
                <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.hourStart == h">{{ h }}</option>
              </select>
              <div class="input-group-select-icon">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div>:</div>
            <div class="input-group select time-picker" [ngClass]="{ 'disabled': true }">
              <select class="form-control" [class.input-error]="requireMessage['slot.'+i+'.start_time'] != null" [(ngModel)]="slot.minuteStart" name="slot_{{i}}_minuteStart" (click)="onClicked('start_time')">
                <option *ngFor="let m of minutes" [ngValue]="m" [selected]="slot.minuteStart == m">{{ m }}</option>
              </select>
              <div class="input-group-select-icon">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div>-</div>
            <div class="input-group select time-picker">
              <select class="form-control" [class.input-error]="requireMessage['slot.'+i+'.end_time'] != null" [(ngModel)]="slot.hourEnd" name="slot_{{i}}_hourEnd"  (click)="onClicked('end_time')">
                <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.hourEnd == h">{{ h }}</option>
              </select>
              <div class="input-group-select-icon">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div>:</div>
            <div class="input-group select time-picker">
              <select class="form-control" [class.input-error]="requireMessage['slot.'+i+'.end_time'] != null" [(ngModel)]="slot.minuteEnd" name="slot_{{i}}_minuteEnd" (click)="onClicked('end_time')">
                <option *ngFor="let m of minutes" [ngValue]="m" [selected]="slot.minuteEnd == m">{{ m }}</option>
              </select>
              <div class="input-group-select-icon">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
              <a *ngIf="(slotList.length - 1) == i" class="link1 btn btn-rtn btn-sm mt-2 float-right" href="javascript:void(0);" (click)="addMoreSlot()" title="Add"><i class="fa fa-plus"></i></a>
              <a *ngIf="(slotList.length - 1) != i" class="link1 btn btn-rtn-inv btn-sm mt-2 float-right" href="javascript:void(0);" (click)="removeSlot(i)" title="Remove"><i class="fa fa-minus"></i></a>
          </div>
          <div class="offset-lg-2 col-lg-10 pl-0 pr-0">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
            <div class="col-xs-10 col-sm-10 col-lg-10 col-md-10 no-padding-left-right message-error" [class.hidden]="requireMessage['slot.'+i+'.start_time'] == null">{{ requireMessage['slot.'+i+'.start_time'] }}</div>
            <div class="col-xs-10 col-sm-10 col-lg-10 col-md-10 no-padding-left-right message-error" [class.hidden]="requireMessage['slot.'+i+'.start_time'] != null">{{ requireMessage['slot.'+i+'.end_time'] }}</div>
          </div>
        </div>

        <!--<div class="row col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
          <a class="link" (click)="addMoreSlot()">Add more</a>
        </div>-->
        <div class="row" *ngIf="note">
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 form-input-label px-0">Note</div>
          <div class="col-10 pl-3 date-select" style="margin-top: 5px;">
            <pre class="note-block-content" [innerHtml]="note"></pre>
          </div>
        </div>
        <div class="row" *ngIf="errorMessageBook">
          <div class="col-xs-2"></div>
          <div class="col-xs-10 no-padding-left-right">
            <div class="message-error">{{ errorMessageBook }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="dismiss()">Cancel</button>
      <button type="submit" class="btn btn-rtn btn-90 float-right" autofocus>Save</button>
    </div>
  </form>
  <rtn-modals-alert [showIcon]="true" (OnYesClicked)="confirmationYesClicked()"></rtn-modals-alert>
</modal>
