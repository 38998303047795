import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { SuperProviderDetail } from '../../shared/models/superProviderDetail';
import { RegistrationLink } from '../../shared/models/registrationLink';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-master',
  templateUrl: './edit-master.component.html',
  styleUrls: ['../admin.component.scss','./edit-master.component.scss'],
  moduleId: module.id,
})
export class EditMasterComponent implements OnInit {

  superProviderDetail: SuperProviderDetail = new SuperProviderDetail();
    responseError: SuperProviderDetail = new SuperProviderDetail();
    addMoreDisabled: boolean = false;
    successMessage: string;

    onGetProfileListener: Subscription;
    editSuperProviderSubscriber: Subscription;
    @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
    @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;
    constructor(
        public _systemData: SystemData,
        private _systemService: SystemService,
        private _routeParams: ActivatedRoute,
        private _adminService: AdminRestService,
        private loadingIndicatorService: LoadingIndicatorService
    ) {
        // do nothing
    }

    ngOnInit() {
        this.loadingIndicatorService.start();
        this._systemService.getStates();

        this.onGetProfileListener = this._adminService.getProviderProfile(parseInt(this._routeParams.snapshot.paramMap.get('id'))).subscribe(
        (success: any) => {
            this.loadingIndicatorService.stop();
            this.superProviderDetail = success.user;
            this.superProviderDetail.phone_mobile = this.superProviderDetail.phone_mobile.replace('+1 ', '');
            if (success.user.billing !== null) {
                this.superProviderDetail.billing.state_code = this.superProviderDetail.billing.state_code === null ? '' : this.superProviderDetail.billing.state_code.toUpperCase();
            } else this.superProviderDetail.billing = new SuperProviderDetail().billing;
        }, (error: any) => {
            this.loadingIndicatorService.stop();
        });
    }

    ngOnDestroy() {
        if (this.onGetProfileListener) {
            this.onGetProfileListener.unsubscribe();
        }
        if (this.editSuperProviderSubscriber) {
            this.editSuperProviderSubscriber.unsubscribe();
        }
    }

    editSuperProviderClicked() {
        this.loadingIndicatorService.start();
        this.successMessage = null;

        if (this.editSuperProviderSubscriber) {
            this.editSuperProviderSubscriber.unsubscribe();
        }

        this.editSuperProviderSubscriber = this._adminService.updateProfile(parseInt(this._routeParams.snapshot.paramMap.get('id')), this.superProviderDetail).subscribe(
        (success: any) => {
            this.loadingIndicatorService.stop();
            if (success.error_code === 'RC000') {
                this.successMessage = success.error_messages;
                this.responseError = new SuperProviderDetail();
            }
        },
        (error: any) => {
            this.loadingIndicatorService.stop();
            let e = error.error;
            if (e.error_code === 'RC020') {
                this.responseError = e.error_fields;
            }
        });
    }

    onKeyUp(error: string) {
        this.successMessage = null;

        switch (error) {
            case 'email':
                this.responseError.email = null;
                break;

            case 'account_name':
                this.responseError.account_name = null;
                break;

            case 'office_name':
                this.responseError.office_name = null;
                break;

            case 'phone_mobile':
                this.responseError.phone_mobile = null;
                break;

            case 'billing.address':
                (<any>this.responseError)['billing.address'] = null;
                break;

            case 'billing.address2':
                (<any>this.responseError)['billing.address2'] = null;
                break;

            case 'billing.city':
                (<any>this.responseError)['billing.city'] = null;
                break;

            case 'billing.state_code':
                (<any>this.responseError)['billing.state_code'] = null;
                break;

            case 'billing.zip':
                (<any>this.responseError)['billing.zip'] = null;
                break;

            case 'billing.first_name':
                (<any>this.responseError)['billing.first_name'] = null;
                break;

            case 'billing.last_name':
                (<any>this.responseError)['billing.last_name'] = null;
                break;

            case 'billing.email':
                (<any>this.responseError)['billing.email'] = null;
                break;

            case 'billing.phone_mobile':
                (<any>this.responseError)['billing.phone_mobile'] = null;
                break;
        }
    }

}
