<div class="marketing-page container activate-user">
    <div class="content-header row" [style.min-height.px]="minHeight">
        <div class="activate-content col-12">
            <div *ngIf="!linkIsValid" class="alert alert-danger col-6 offset-xs-3">
                {{errorMessage}}
            </div>
            <div *ngIf="linkIsValid" class="active-body">
                <h1 class="active-headline text-center">Your account is now active !</h1>
                <div class="success-body text-center">
                    <img class="img-responsive" src="/assets/img/active_user.png" alt="active">
                    <p class="text-small success-text" *ngIf="showBtn">Your account has been activated successfully.</p>
                    <a [routerLink]="['/home', 'login']" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius" role="button" *ngIf="showBtn">LOGIN</a>
                </div>
            </div>
        </div>
    </div>
</div>