import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class WaittimeMeterService {

  fetchAppointmentDetailsUrl = environment.serverUrl + '/appointment/fetchaptdetailsforwaittime';
  fetchWaitTimeByAppointmentUrl = environment.serverUrl + '/appointment/fetchwaittime';

  saveScheduleratingUrl = environment.serverUrl + '/appointment/savescheduleratings';
  
  constructor(private _http: HttpClient) { }
  
  fetchAppointmentDetails(id:any,wmlogin: any) {
    if (wmlogin != '') {
      return this._http.get(this.fetchAppointmentDetailsUrl + '/' + id + '/' + wmlogin);
    } else {
      return this._http.get(this.fetchAppointmentDetailsUrl + '/' + id);
    }    
  }
  fetchWaitTimeByAppointment(id:any) {
    return this._http.get(this.fetchWaitTimeByAppointmentUrl + '/' + id);
  }
  savescheduleratings(data: any) {
    return this._http.post(this.saveScheduleratingUrl, data);
  }
}
