import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

// import { CORE_DIRECTIVES } from 'angular2/common';

@Component({
  selector: 'rtn-modals-alert',
  // directives: [CORE_DIRECTIVES, ModalComponent, MODAL_DIRECTIVES],
  template: `
    <form class="confirmation" [class.hidden]="!showConfirmation">
        <i [class.hidden]="!showIcon" class="fa fa-info fa-4x" aria-hidden="true"></i>
        <div class="text" style="margin-top: 30px; height: 38%;">
            <div *ngFor="let line of message">{{ line }}</div>
        </div>
        <div>
            <button type="submit" class="btn btn-rtn btn-90" (click)="handleOnYesClicked($event)">OK</button>
        </div>
    </form>
    `,
  styleUrls: ['./modal-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModalsAlertComponent {
  message: any;
  showConfirmation = false;
  @Input()
  showIcon = true;
  @Output()
  OnYesClicked: any = new EventEmitter();

  constructor() {
    // TODO
  }

  open() {
    this.showConfirmation = true;
    setTimeout(function() {
      jQuery('.btn-no').focus();
    }, 400);
  }

  close() {
    this.showConfirmation = false;
  }

  handleOnYesClicked(e: any) {
    this.OnYesClicked.emit(e);
  }
}
