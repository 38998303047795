<modal #feedbackModal class="feedback">
    <form>
        <div class="row noMargin">
            <div class="col-4 col-xs-4 noPaddingLeft bg">
                <div class="header">Feedback
                    <div class="arrow-left"></div>
                </div>
                <img src="/assets/img/mass-noti.png">
            </div>
            <div class="col-8 col-xs-8 inputForm">
                <div>Subject<input type="text" [class.input-error]="messageErrorSubject != null" class="form-control paddingInput" maxlength="255" [(ngModel)]="model.subject" ngControl="subject" name="subject" (keyup)="onKey()" autoFocusCustom/></div>
                <div class="message-error">{{messageErrorSubject}}</div>
                <div>Content<textarea class="form-control paddingInput" rows="8" [(ngModel)]="model.content" ngControl="content" name="content"></textarea></div>
                <div class="textRight">
                    <button type="button" class="btn btn-90 btn-rtn-inv" data-dismiss="modal" (click)="dismiss()"><span class="label">Cancel</span></button>
                    <button type="button" class="btn btn-rtn btn-90" (click)="sendFeedback()">Send</button>
                </div>
            </div>
        </div>
    </form>
</modal>
