import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

import {CoreModule} from '@app/core';
import {SharedModule} from '@app/shared';
import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {QuoteService} from './quote.service';
import {LoginComponent} from './login/login.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {HomePageComponent} from './home/home.component';
import {FrontSignUpComponent} from './front-sign-up/front-sign-up.component';
import {HeaderComponent} from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ActivateComponent } from './active/activate.component';
import {ChangePasswordExpiredComponent} from '@app/home/change-password-expired/change-password-expired.component';
import { PhysicianDirectoryComponent } from './physician-directory/physician-directory.component';
import { AcceptedFamilyComponent } from './family-member/accepted-family.component';
import { DeclinedFamilyComponent } from './family-member/declined-family.component';
import { FooterAppointmentComponent } from './footer-appointment/footer-appointment.component';
import {PatientNotificationSettingComponent} from '@app/home/patient-notification-setting/patient-notification-setting.component';
import {PatientNotificationOptoutComponent} from '@app/home/patient-notification-optout/patient-notification-optout.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    HomePageComponent,
    FrontSignUpComponent,
    ActivateComponent,
    HeaderComponent,
    FooterComponent,
    ChangePasswordExpiredComponent,
    PhysicianDirectoryComponent,
    AcceptedFamilyComponent,
    DeclinedFamilyComponent,
    FooterAppointmentComponent,
    PatientNotificationSettingComponent,
    PatientNotificationOptoutComponent
  ],
  providers: [
    QuoteService
  ],
  exports: [HeaderComponent, FooterComponent, FooterAppointmentComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class HomeModule {
}
