<div class="sidebarAndContent mt-3">
  <h3 class="title m_l_8">Notification Logs</h3>
  <div class="col-md-12 pr-0">
    <div class="row col-12 px-0">
        <rtn-table class="col-12 px-0">
            <div class="row">
                <rtn-table-filter #filter class="col-12 px-0">
                    <form #form="ngForm" class=" border border-#ddd pt-3 pl-3 pb-3 mb-3" >
                        <div class="row col-12 px-0">
                            <div class="row col-lg-6 mb-2 px-0">
                                <div class="d-none d-lg-block width_70 pt-1">Practice:</div>
                                <div
                                  class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                                  Practice:</div>

                                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getPractice">
                                    <select
                                        #select2PracticeDirective="select2-practice"
                                        select2-practice
                                        class="form-control"
                                        [value]="searchDataLog.practice"
                                        [maximumSelectionLength]="0"
                                        [multiple]="false"
                                        [tags]="false"
                                        id="select2Practice">
                                        <option *ngFor="let practice of practices; let i = index" [id]="'practice' + practice.id" [value]="practice.id" >{{ practice.name }}</option>
                                    </select>
                                    <div class="message-error">{{ errorResponse.practice }}</div>
                                </div>
                            </div>
                            <div class="row col-lg-6 mb-2 px-0">
                                <div class="d-none d-lg-block width_70 pt-1">Provider(s):</div>
                                <div
                                  class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                                  Provider(s):</div>

                                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
                                    <select #select2Provider2Directive="select2-provider2"
                                    select2-provider2
                                    class="form-control"
                                    [maximumSelectionLength]="0"
                                    [tags]="false" 
                                    id="select2Provider">
                                        <option *ngFor="let provider of providers" [id]="'provider' + provider.id" [value]="provider.id">{{ provider.full_name }}</option>
                                    </select>

                                </div>
                            </div>
                            <div class="row col-lg-6 mb-2 px-0 date-select">
                                <div class="d-none d-lg-block  width_70 pt-1">From:</div>
                                <div
                                  class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                                  From:</div>
                                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                                    <!-- <input type="text" #inputDatePlanCheckIn class="form-control calender-input bc-white" readonly >
                                    <div class="input-group-addon btn-select-date" #clickDatePlanCheckIn>
                                        <span aria-hidden="true" class="fa fa-calendar-o"></span>
                                    </div> -->
                                    <div class="input-group">
                                        <input type="text" #inputDatePlanCheckIn readonly class="form-control calender-input bc-white" aria-describedby="basic-addon2">
                                        <div class="input-group-append"  #clickDatePlanCheckIn>
                                          <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
                                            <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col-lg-6 mb-2 px-0 date-select">
                                <div class="d-none d-lg-block  width_70 pt-1">To:</div>
                                <div
                                  class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                                  To:</div>
                                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                                    <!-- <input type="text" #inputDatePlanCheckInTo class="form-control calender-input bc-white" readonly >
                                    <div class="input-group-addon btn-select-date" #clickDatePlanCheckInTo>
                                        <span aria-hidden="true" class="fa fa-calendar-o"></span>
                                    </div> -->
                                    <div class="input-group">
                                        <input type="text" [class.input-error]="errorDateTo"  #inputDatePlanCheckInTo readonly id="inputDatePlanCheckInTo" class="form-control calender-input bc-white" aria-describedby="basic-addon2">
                                        <div class="input-group-append"  #clickDatePlanCheckInTo>
                                          <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
                                            <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                                        </div>
                                    </div>
                                    <div class="message-error">{{errorDateTo}}</div>
                                </div>
                            </div>
                            <div class="row col-lg-6 mb-2 px-0">
                                <div class="d-none d-lg-block  width_70 pt-1">Patient(s):</div>
                                    <div
                                      class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                                      Patient(s):</div>
                                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                                    <div class="form-group" id="select2Ajaxpatientblock">
                                        
                                        <select #Select2AjaxpatientDirective="select2-ajaxpatient" id="select2Ajaxpatient" select2-ajaxpatient class="form-control"  [maximumSelectionLength]="0" [tags]="false" placeholder="Patients: All" >
                                            <option *ngFor="let patient of patients" [id]="'patientList' + patient.id" [value]="patient.id">{{ patient.full_name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-lg-6 mb-2 px-0">

                            </div>
                            </div>


                        <div class="col-sm-12 select-box no-padding-left">
                            <button type="submit" class="btn btn-rtn btn_90" (click)="clickBtnSearchDataLog($event)">
                                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                                <span class="label">Search</span>
                        </button>
                        </div>
                    </form>
                </rtn-table-filter>

                <div class="col-sm-12 no-padding-left-right search-box listview">
                    <div class="col-12" class="no-result" *ngIf="noResult">{{ noResult }}</div>
                    <div class="clear-both data-log-table margin-bottom-20" *ngIf="showDataLogTable">
                        <rtn-table #table
                              (onDataChange)="searchDataLogSlot($event)"
                              [autoloading]="false"
                              [data]="searchDataLogResult.data"
                              [rowsOnPage]="searchDataLogResult.rowsOnPage"
                              [numberOfPages]="searchDataLogResult.numberOfPages">

                        <div class="row">

                            <div class="col-sm-12 list-view px-0">
                                <div class="scrollbar-top">
                                    <div class="div-content">
                                    </div>
                                </div>
                                <table class="table table-bordered tbl-data-log table-striped">
                                    <thead class="account-thead">
                                        <tr>
                                            <th class="data-name">Full Name Provider</th>
                                            <th class="data-name">Full Name Patient</th>
                                            <th class="data-date">Scheduled Check In</th>
                                            <th class="data-date">Scheduled Check Out</th>
                                            <th class="data-date">Rescheduled Check In</th>
                                            <th class="data-date">Rescheduled Check Out</th>
                                            <th class="data-date">Actual Check In</th>
                                            <th class="data-date">Actual Check Out</th>
                                            <th class="data-noti">Notification 1</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 2</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 3</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 4</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 5</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 6</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 7</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 8</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 9</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 10</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 11</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 12</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 13</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 14</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                            <th class="data-noti">Notification 15</th>
                                            <th class="data-date">Sent At</th>
                                            <th class="data-is-sent">Is Sent</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody class="account-tbody">
                                        <tr *ngFor="let item of table.data; let i = 'index'">
                                            <td class="data-name">{{ item.full_name_provider }}</td>
                                            <td class="data-name">{{ item.full_name_patient }}</td>
                                            <td class="data-date">{{ item.initial_check_in }}</td>
                                            <td class="data-date">{{ item.initial_check_out }}</td>
                                            <td class="data-date">{{ item.rescheduled_check_in }}</td>
                                            <td class="data-date">{{ item.rescheduled_check_out }}</td>
                                            <td class="data-date">{{ item.actual_check_in }}</td>
                                            <td class="data-date">{{ item.actual_check_out }}</td>
                                            <!--<td class="data-noti">{{ item.notification_1 }}</td>-->
                                            <td class="data-noti"><div [innerHTML]="item.notification_1"></div></td>                                            
                                            <td class="data-date">{{ item.sent_1 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_1 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_2"></div></td>
                                            <td class="data-date">{{ item.sent_2 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_2 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_3"></div></td>
                                            <td class="data-date">{{ item.sent_3 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_3 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_4"></div></td>
                                            <td class="data-date">{{ item.sent_4 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_4 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_5"></div></td>
                                            <td class="data-date">{{ item.sent_5 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_5 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_6"></div></td>
                                            <td class="data-date">{{ item.sent_6 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_6 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_7"></div></td>
                                            <td class="data-date">{{ item.sent_7 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_7 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_8"></div></td>
                                            <td class="data-date">{{ item.sent_8 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_8 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_9"></div></td>
                                            <td class="data-date">{{ item.sent_9 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_9 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_10"></div></td>
                                            <td class="data-date">{{ item.sent_10 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_10 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_11"></div></td>
                                            <td class="data-date">{{ item.sent_11 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_11 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_12"></div></td>
                                            <td class="data-date">{{ item.sent_12 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_12 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_13"></div></td>
                                            <td class="data-date">{{ item.sent_13 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_13 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_14"></div></td>
                                            <td class="data-date">{{ item.sent_14 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_14 }}</td>
                                            <td class="data-noti"><div [innerHTML]="item.notification_15"></div></td>
                                            <td class="data-date">{{ item.sent_15 }}</td>
                                            <td class="data-is-sent">{{ item.is_sent_15 }}</td>
                                        </tr>
                                    </tbody>

                                    <tfoot *ngIf="table.numberOfPages > 1">
                                    <tr>
                                        <td colspan="12" class="text-center table-responsive no-overflow-y">
                                            <div class="page-footer">
                                                <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                            </div>
                                        </td>
                                    </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                    </rtn-table>
                    </div>
                </div>
            </div>
        </rtn-table>
    </div>
  </div>
</div>
