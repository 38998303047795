<!--<rtn-provider-header></rtn-provider-header>-->
<div class="container-fluid provider-edit px-0 py-3">
  <div class="row padding-left-15">
    <h3 class="col-12 text-header-form-edit">
      Add New Provider
    </h3>
  </div>
  <form method="post" #form="ngForm" (ngSubmit)="addProviderForm(form.value)" class="row col-lg-12 pr-0">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="panel-edit-profile panel-default">
        <div class="panel-heading">
          <h3 class="panel-title text-title-form-edit">Account Info</h3>
        </div>
        <div class="panel-body py-2">
          <div class="col-12 inline-edit-provider">
            <div class="row">
                <div class="pl-0 col-6 email-address">
                    <span class="text-label-style input-label-required">Email Address</span>
                    <input class="form-control textbox-edit-form" type="text" ngControl="email"
                           [class.input-error]="responseError['email']"
                           [(ngModel)]="providerDetail.email" maxlength="50" (keyup)="onKeyUp('email')" name="email">
                    <span class="message-error" *ngIf="responseError['email']">{{responseError['email']}}</span>
                  </div>
                  <!--                  <div class="row padding-left-15 padding-right-15">-->
                  <div class="col-6 px-0">
                    <div class="text-label-style input-label-required">Phone Number</div>
                    <div>
                      <div class="col-sm-12 col-md-12 no-padding-left-right">
                        <input class="form-control textbox-edit-form" ngControl="phone_mobile" type="text"
                               [class.input-error]="responseError['phone_mobile']"
                               [(ngModel)]="providerDetail.phone_mobile" (keyup)="onKeyUp('phone_mobile')"
                               mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false" name="phone_mobile">
                        <span class="message-error">{{ responseError.phone_mobile }}</span>
                      </div>
                    </div>
                  </div>
            </div>

            <!--                  </div>-->
          </div>
          <div class="col-md-12 inline-edit-provider">
            <span class="text-label-style input-label-required">Address Line 1</span>
            <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address']"
                   [(ngModel)]="providerDetail.address" ngControl="address" maxlength="50" (keyup)="onKeyUp('address')"
                   name="address">
            <span class="message-error" *ngIf="responseError['address']">{{responseError['address']}}</span>
          </div>
          <div class="col-12 inline-edit-provider">
            <span class="text-label-style">Address Line 2</span>
            <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address2']"
                   [(ngModel)]="providerDetail.address2" ngControl="address2" maxlength="50" name="address2">
            <span class="message-error" *ngIf="responseError['address2']">{{responseError['address2']}}</span>
          </div>
          <div class="col-12 inline-edit-provider">
              <div class="row">
                  <div class="px-0 col-5">
                    <span class="text-label-style input-label-required">City</span>
                    <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['city']"
                           [(ngModel)]="providerDetail.city" ngControl="city" maxlength="50" (keyup)="onKeyUp('city')"
                           name="city">
                    <span class="message-error" *ngIf="responseError['city']">{{responseError['city']}}</span>
                  </div>
                  <div class="col-4">
                    <span class="text-label-style input-label-required">State</span>
                    <div class="input-group select width-full">
                      <select class="col-12 form-control-sm textbox-edit-form" [(ngModel)]="providerDetail.state_code"
                              ngControl="state_code"
                              [class.input-error]="responseError['state_code']" (change)="onKeyUp('state_code')"
                              name="state_code">
                        <option [value]="''" selected>Select State</option>
                        <option *ngFor="let stateItem of _systemData.states"
                                [value]="stateItem.code">{{stateItem.name}}</option>
                      </select>
                      <div class="input-group-append cursorPointer select-state">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                    <span class="message-error" *ngIf="responseError['state_code']">{{responseError['state_code']}}</span>
                  </div>
                  <div class="pr-0 col-3">
                    <span class="text-label-style input-label-required">ZIP Code</span>
                    <input class="form-control textbox-edit-form" type="text" ngControl="zip"
                           [class.input-error]="responseError['zip']"
                           [(ngModel)]="providerDetail.zip" (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'"
                           [target]="providerDetail"
                           [field]="'zip'" maxlength="10" name="zip">
                    <span class="message-error" *ngIf="responseError['zip']">{{responseError['zip']}}</span>
                  </div>
                </div>
          </div>

        </div>
      </div>
      
      <div class="panel-edit-profile panel-default">
        <div class="panel-heading">
          <h3 class="panel-title text-title-form-edit">Physician Info</h3>
        </div>
        <div class="panel-body py-2">
            <div class="col-12 inline-edit-provider">
                <div class="row">
                    <div class="pl-0 col-5">
                      <span class="text-label-style input-label-required">First Name</span>
                      <input class="form-control textbox-edit-form" type="text" [(ngModel)]="providerDetail.first_name"
                             ngControl="first_name"
                             [class.input-error]="responseError['first_name']" maxlength="50" (keyup)="onKeyUp('first_name')"
                             name="first_name">
                      <span class="message-error" *ngIf="responseError['first_name']">{{responseError['first_name']}}</span>
                    </div>
                    <div class="px-0 col-2">
                                  <span for="middle_name">Middle
                                      <span class="hidden-xs">Initial</span>
                                  </span>
                      <input id="middle-name" class="form-control textbox-edit-form" type="text"
                             [class.input-error]="responseError['middle_name']"
                             [(ngModel)]="providerDetail.middle_name" ngControl="middle_name" maxlength="1" name="middle_name">
                      <span class="message-error" *ngIf="responseError['middle_name']">{{responseError['middle_name']}}</span>
                    </div>
                    <div class="pr-0 col-5">
                      <span class="text-label-style input-label-required">Last Name</span>
                      <input class="form-control textbox-edit-form" type="text" [(ngModel)]="providerDetail.last_name"
                             ngControl="last_name"
                             [class.input-error]="responseError['last_name']" maxlength="50" (keyup)="onKeyUp('last_name')"
                             name="last_name">
                      <span class="message-error" *ngIf="responseError['last_name']">{{responseError['last_name']}}</span>
                    </div>
                  </div>
            </div>
            <div class="col-12 inline-edit-provider">
                <div class="row">
                    <div class="pl-0 col-6">
                      <span class="text-label-style">Credentials</span>
                      <input class="form-control textbox-edit-form" type="text"
                             [class.input-error]="responseError['credentials']"
                             [(ngModel)]="providerDetail.credentials" ngControl="credentials" maxlength="50"
                             placeholder="i.e. MD, DO, RN, RPA..." name="credentials">
                      <span class="message-error" *ngIf="responseError['credentials']">{{responseError['credentials']}}</span>
                    </div>
                    <div class="px-0 col-6">
                      <span class="text-label-style input-label-required">NPI</span>
                      <input class="form-control textbox-edit-form" type="text" ngControl="npi"
                             [(ngModel)]="providerDetail.npi" [disabled]="providerDetail.is_mapped"
                             [class.input-error]="responseError['npi']"
                             (keyup)="onKeyUp('npi')" inputmask [format]="'##########'" name="npi"
                             [validator]="'[0-9]'" [target]="providerDetail" [field]="'npi'">
                      <span class="message-error" *ngIf="responseError['npi']">{{responseError['npi']}}</span>
                    </div>
                  </div>
            </div>

          <div class="col-12 inline-edit-provider">
<!--            <div class="input-group select width-full">-->
              <span class="text-label-style">Office/Practice/Hospital Name</span>
            <div class="input-group select width-full">
              <select class="col-md-12 form-control-sm textbox-edit-form" [(ngModel)]="providerDetail.office_name"
                      ngControl="office_name" disabled name="office_name">
                <option [value]="''" selected>Select Practice</option>
                <option *ngFor="let practice of practiceList" [value]="practice.id">{{practice.name}}</option>
              </select>
              <div class="input-group-append cursorPointer select-state">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>

<!--            </div>-->
          </div>
          <div class="col-12 inline-edit-provider">
            <span class="text-label-style">Specialties</span>
            <select select2 class="col-md-12 form-control-sm multichoice-edit-form" [(ngModel)]="providerDetail.specialties"
                    ngControl="specialties"
                    (OnKeyUp)="onKeyUp('specialties')" (change)="onKeyUp('specialties')" name="specialties">
              <option *ngFor="let specialtyItem of _systemData.specialties" data-id="specialtyItem.id"
                      [value]="specialtyItem.id">{{specialtyItem.name}}</option>
            </select>
            <span class="message-error" *ngIf="responseError['specialties']">{{responseError['specialties']}}</span>
          </div>
          <div class="col-12 inline-edit-provider">
            <span class="text-label-style">Website</span>
            <input class="col-md-12 form-control-sm textbox-edit-form" type="text" [class.input-error]="responseError['website_url']"
                   [(ngModel)]="providerDetail.website_url" ngControl="website_url" maxlength="255"
                   (input)="preventWhiteSpaceInput($event)" name="website_url">
            <span class="message-error" *ngIf="responseError['website_url']">{{responseError['website_url']}}</span>
          </div>
        </div>
      </div>

      
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Office Address</h3>
          </div>
          <div class="panel-body py-2">

            <div class="col-md-12 inline-edit-provider">
              <span class="text-label-style">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['office_address']"
                     [(ngModel)]="providerDetail.office_address" ngControl="office_address" maxlength="50" (keyup)="onKeyUp('office_address')"
                     name="office_address">
              <span class="message-error" *ngIf="responseError['office_address']">{{responseError['office_address']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['office_address2']"
                     [(ngModel)]="providerDetail.office_address2" ngControl="office_address2" maxlength="50" name="office_address2">
              <span class="message-error" *ngIf="responseError['office_address2']">{{responseError['office_address2']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
                <div class="row">
                    <div class="px-0 col-5">
                      <span class="text-label-style">City</span>
                      <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['office_city']"
                             [(ngModel)]="providerDetail.office_city" ngControl="office_city" maxlength="50" (keyup)="onKeyUp('office_city')"
                             name="office_city">
                      <span class="message-error" *ngIf="responseError['office_city']">{{responseError['office_city']}}</span>
                    </div>
                    <div class="col-4">
                      <span class="text-label-style">State</span>
                      <div class="input-group select width-full">
                        <select class="col-12 form-control-sm textbox-edit-form" [(ngModel)]="providerDetail.office_state_code"
                                ngControl="office_state_code"
                                [class.input-error]="responseError['office_state_code']" (change)="onKeyUp('office_state_code')"
                                name="office_state_code">
                          <option [value]="''" selected>Select State</option>
                          <option *ngFor="let stateItem of _systemData.states"
                                  [value]="stateItem.code">{{stateItem.name}}</option>
                        </select>
                        <div class="input-group-append cursorPointer select-state">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <span class="message-error" *ngIf="responseError['office_state_code']">{{responseError['office_state_code']}}</span>
                    </div>
                    <div class="pr-0 col-3">
                      <span class="text-label-style">ZIP Code</span>
                      <input class="form-control textbox-edit-form" type="text" ngControl="office_zip"
                             [class.input-error]="responseError['office_zip']"
                             [(ngModel)]="providerDetail.office_zip" (keyup)="onKeyUp('office_zip')" simplemask [format]="'#####-####'"
                             [target]="providerDetail"
                             [field]="'office_zip'" maxlength="10" name="office_zip">
                      <span class="message-error" *ngIf="responseError['office_zip']">{{responseError['office_zip']}}</span>
                    </div>
                  </div>
                  
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Location</span>
              <select select2location class="form-control multichoice-edit-form" (OnKeyUp)="onKeyUp('location_id')" id="select2location">
                <option *ngFor="let locitem of arrlocations; let i = index" data-id="locitem.id" [value]="locitem.id">{{locitem.location_name}} - {{locitem.city}}</option>
              </select>
              <span class="message-error" *ngIf="responseError['location_id']">{{responseError['location_id']}}</span>
            </div>
  
          </div>
        </div>

    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-0">
      <div class="panel-edit-profile panel-default">
        <div class="panel-heading">
          <h3 class="panel-title text-title-form-edit"><span class="input-label-required">Hours of Operation</span></h3>
        </div>
        <div class="panel-body py-2">
            <rtn-opening-hour [enableValidation]="true" [resetOnDisabled]="false" [addNew]="true"></rtn-opening-hour>
          <span class="message-error padding-left-15 error-opening-hour col-12"
                *ngIf="responseError['opening_hours'] && responseError['opening_hours'].length !== 0">{{responseError['opening_hours']}}</span>
        </div>
      </div>

      

    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 padding-bottom-20">
      <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Create</button>
    </div>
  </form>
</div>
