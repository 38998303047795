import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ProviderDetail} from '@app/shared/models/providerDetail';
import {BillingInfo} from '@app/shared/models/billingInfo';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {RestService} from '@app/core/rest.service';
import {ProvidersService} from '@app/providers/providers.service';

@Component({
  selector: 'app-provider-profile',
  templateUrl: './provider-profile.component.html',
  styleUrls: ['./provider-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProviderProfileComponent implements OnInit, OnDestroy {

  _providerDetail: any = new ProviderDetail();
  _billingInfo: any = new BillingInfo();
  device: boolean = false;
  onGetProfileListener: Subscription;
  loadPracticeListSubscriber: Subscription;
  acceptedInsurance: any = [];
  showComma = false;

  constructor(
    private _router: Router,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _systemData: SystemData,
    private _restService: ProvidersService,
    private _utils: Utils
  ) {
  }

  ngOnInit() {
    if (this._utils.accessByProvider()) {
      this._systemService.getSpecialties();
      this.onGetProfileListener = this._systemEvent.profile.subscribe((done: boolean) => {
        if (done) {
          let tempProviderDetail = this._systemData.profile;
          tempProviderDetail.fullName = this._utils.generateFullName(tempProviderDetail.first_name,
            tempProviderDetail.middle_name, tempProviderDetail.last_name);
          let credentials = this._systemData.profile.credentials;
          if (credentials !== '' && credentials !== null) {
            tempProviderDetail.fullName += ', ' + credentials;
          }
          tempProviderDetail.specialties_display = new Array();
          if (this._systemData.profile.specialties !== null) {
            for (let i = 0; i < this._systemData.profile.specialties.length; i++) {
              tempProviderDetail.specialties_display.push(this._systemData.profile.specialties[i].name);
            }
            tempProviderDetail.specialties_display = tempProviderDetail.specialties_display.join(', ');
          } else {
            tempProviderDetail.specialties_display = '';
          }
          tempProviderDetail.opening_hours_display
            = this._utils.generateOpeningHours(tempProviderDetail.opening_hours, 3);
          if (tempProviderDetail.website_url !== null) {
            if (tempProviderDetail.website_url.indexOf('http://') === -1 && tempProviderDetail.website_url.indexOf('https://') === -1) {
              tempProviderDetail.website_display = 'http://' + tempProviderDetail.website_url;
            } else {
              tempProviderDetail.website_display = tempProviderDetail.website_url;
            }
          }
          this._providerDetail = tempProviderDetail;
          
          console.log(this._providerDetail);

          if (this._providerDetail.phone_mobile.indexOf('+1 ') !== -1) {
            this._providerDetail.phone_mobile = this._providerDetail.phone_mobile.substr(this._providerDetail.phone_mobile.indexOf('+1 ') + 3);
          }
          this.loadPracticeListSubscriber = this._restService.getPracticeList().subscribe(
            (success: any) => {
              if (success.practices.length > 0) {
                success.practices.map((item: any) => {
                  if (item.id === parseInt(this._providerDetail.office_name)) {
                    this._providerDetail.office_name = item.name;
                    this._providerDetail.insurances = item.insurances;
                  }
                });
              }
            }
          );
        }
      });
      delete (this._systemData.profile);
      this._systemService.getProfile(null);
      setTimeout(() => {
        if (jQuery(window).width() <= 768) {
          this.device = true;
        }
      }, 100);
    }
  }

  ngOnDestroy() {
    if (this.onGetProfileListener) {
      this.onGetProfileListener.unsubscribe();
    }
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
  }

}
