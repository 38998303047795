import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivatephoneRoutingModule } from './activatephone-routing.module';
import { ActivatephoneComponent } from './activatephone.component';

import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [
    CommonModule,
    ActivatephoneRoutingModule,    
    FormsModule,
    SharedModule
  ],
  declarations: [
    ActivatephoneComponent,    
  ],
  exports: [    
  ]
})
export class ActivatephoneModule { }
