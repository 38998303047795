import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ActivatephoneComponent } from './activatephone.component'

const routes: Routes = [
  {
    path: '',
    component: ActivatephoneComponent,
    children: [
      {
        path: 'activatephone/:id', ///:schedule_id/:code
        component: ActivatephoneComponent          
      }
    ],
}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivatephoneRoutingModule { }
