<div class="speedometer-component" *ngIf="appointmentDetail.enableWaitTimeMeter">
<!--<div class="speedometer-component" *ngIf="true">-->
  <div style="margin: 0px 0px 10px 10px;">
    Wait time meter was last updated at {{ appointmentDetail.currentTime }}
    <div style="display: inline; margin-left: 5px;">
      <button type="submit" class="btn btn-rtn text-uppercase btn-margin updateWaittime" (click)="showWaitTime()" >UPDATE</button>
    </div>
  </div>
  <div class="speedometer-header">Waiting time</div>
  <div class="speedometer-watch">
    <div class="speedometer-picture" [class.style-for-firefox]="isFirefox" *ngIf="!enabledDetail">
      <div class="speedometer-img" [class.speedometer-img-2]="!appointmentDetail.isActive || appointmentDetail.hasLink">
        <div class="speedometer-needle" [ngStyle]="{'-ms-transform': waitTimeMeterStyle['-ms-transform'], '-webkit-transform': waitTimeMeterStyle['-webkit-transform'], 'transform': waitTimeMeterStyle['transform']}"></div>
      </div>
      <div class="wait-time" [class.hide-wait-time]="!appointmentDetail.is_active || appointmentDetail.hasLink || appointmentDetail.waitingTime == null">
        <label>{{ _utils.generateValueUnitDisplay(appointmentDetail.waitingTime, 'min') }}</label>
      </div>
    </div>
    <div class="speedometer-picture-modal remove-margin-left-right" [class.style-for-firefox-with-detail]="isFirefox" *ngIf="enabledDetail">
      <div class="speedometer-img" [class.speedometer-img-2]="!appointmentDetail.isActive || appointmentDetail.hasLink">
        <div class="speedometer-needle" [ngStyle]="{'-ms-transform': waitTimeMeterStyle['-ms-transform'], '-webkit-transform': waitTimeMeterStyle['-webkit-transform'], 'transform': waitTimeMeterStyle['transform']}"></div>
      </div>
      <div class="wait-time" [class.hide-wait-time]="!appointmentDetail.is_active || appointmentDetail.hasLink || appointmentDetail.waitingTime == null">
        <label>{{ _utils.generateValueUnitDisplay(appointmentDetail.waitingTime, 'min') }}</label>
      </div>
    </div>
  </div>
  <div class="speedometer-notice" *ngIf="appointmentDetail.notice">
    <label [class.cursorPointer]="appointmentDetail.hasLink" (click)="showDetailAppointmentPopupClicked()">{{ appointmentDetail.notice }}</label>
  </div>
</div>
<div class="speedometer-detail" *ngIf="enabledDetail">
  <div class="speedometer-item">
    <div class="detail-name">Provider</div>
    <div class="detail-value">
      <span>{{ _utils.generateFullName(appointmentDetail.provider_first_name, appointmentDetail.provider_middle_name, appointmentDetail.provider_last_name) }}</span><span *ngIf="appointmentDetail.credentials">,</span>
      <span>{{ appointmentDetail.credentials }}</span>
    </div>
  </div>
  <div class="speedometer-item">
    <div class="detail-name">Address</div>
    <div class="detail-value">
      <div>{{ appointmentDetail.address }}</div>
      <div>{{ appointmentDetail.address2 }}</div>
      <div>
        <span>{{ appointmentDetail.city }}</span><span *ngIf="appointmentDetail.state_code !== null">,</span>
        <span>{{ appointmentDetail.state_code }}</span>
        <span>{{ appointmentDetail.zip }}</span>
      </div>
    </div>
  </div>
  <div class="speedometer-item">
    <div class="detail-name">Phone number</div>
    <div class="detail-value">
      <div>{{ appointmentDetail.provider_phone }}</div>

    </div>
  </div>
  <div class="speedometer-item">
    <div class="detail-name">Date</div>
    <div class="detail-value">{{ _utils.getDate(appointmentDetail.plan_check_in) }}</div>
  </div>
  <div class="speedometer-item">
    <div class="detail-name">Time</div>
    <div class="detail-value">
      <span>{{ _utils.getTime(appointmentDetail.plan_check_in) }}</span>
      <span>-</span>
      <span>{{ _utils.getTime(appointmentDetail.plan_check_out) }}</span>
    </div>
  </div>
  <div class="speedometer-item">
    <div class="detail-name">Duration</div>
    <div class="detail-value">{{ _utils.generateValueUnitDisplay(appointmentDetail.duration, 'min') }}</div>
  </div>
  <div class="speedometer-item" *ngIf="appointmentDetail.appointment_reason">
    <div class="detail-name">Reason</div>
    <div class="detail-value">      
      <span>{{ appointmentDetail.appointment_reason }}</span>
    </div>
  </div>
</div>
