<footer class="marketing-footer">
  <div class="container">
    <div class="row align-items-center">
      
      <div class="col-12 col-lg-3 py-2  text-center">
        <div *ngIf="user_role_id == '4'">
          <a class="pr-4  text-white" [routerLink]="['/qdownload', 'PatientGuideFinal.pdf']" target="_blank">Patient
            Guides</a>
          <a class=" text-white" [routerLink]="['/qdownload', 'PatientBrochure.pdf']" target="_blank">Patient
            Brochure</a>
        </div>
        <div *ngIf="user_role_id == '3'">
          <a class="pr-4  text-white" [routerLink]="['/qdownload', 'ProviderBrochure.pdf']" target="_blank">Provider
            Guides</a>
          <a class=" text-white" [routerLink]="['/qdownload', 'OFFICE-PROVIDERUSERGUIDE.docx']" target="_blank">Provider
            Brochure</a>
        </div>

      </div>
      
      <div class="col-12 col-lg-4 text-center py-2"> 
        <span
          *ngIf="tenantname.includes('northwell') || tenantname.includes('odstc') || tenantname.includes('rtnuat') || tenantname.includes('rtntest')"><img
            src="assets/img/northwell_health.jpg" class="footer_northwell_health_logo" alt="Logo"></span>
      </div>
      

      <div class="col-12 col-lg-5 d-flex justify-content-lg-start justify-content-center text-center py-2">
        <span class="text-white">Copyright &copy; 2023 <a href="#" class="text-success"> Real Time Neighborhood </a> All
          rights reserved.</span>
      </div>
    </div>

  </div>
</footer>