import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { PatientDetail } from '@app/shared/models/patient-detail';
import { SystemEvent, SystemService, SystemData } from '@app/shared/system';
import { Utils } from '../../shared/utils';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MobileNumberComponent} from '@app/shared/mobile-number/mobile-number.component';
import { HomepageService } from '../home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/shared/alert';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { InputMaskDirective } from '@app/shared/form/input-mask.directive';
import { SimpleMaskDirective } from '@app/shared/form/simple-mask.directive';
import { DOBComponent } from '@app/shared/dob/dob';

@Component({
  moduleId: module.id,
  selector: 'rtn-home-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    signUpForm: PatientDetail = new PatientDetail();
    error: PatientDetail = new PatientDetail();
    /*ngDatePicker: any;
    _weekStart: number = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)*/
    dob: any;
    //showLoginPage: boolean = true;
    successMessage: string = 'Good luck to you as you move onto the next stage in your life. Without a doubt, you will continue to have success in all areas!';
    //idPatientRegistered: number = 0;

    signUpNewPatientSubscriber: Subscription;
    resendEmailActivePatientSubscriber: Subscription;

    @ViewChild(DOBComponent) dobDirective: DOBComponent;
    @ViewChild(MobileNumberComponent) mobileNumberDirective: MobileNumberComponent;

    constructor(
        private _homePageService: HomepageService,
        private _alertService: AlertService,
        private _systemService: SystemService,
        public _systemData: SystemData,
        private _signupMessageModal: AlertService,
        private _router: Router,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        /*this.ngDatePicker = new Object();
        this.ngDatePicker['todayHighlight'] = true;
        this.ngDatePicker['weekStart'] = this._weekStart;
        this.ngDatePicker['format'] = 'MM dd, yyyy';*/
    }

    ngOnInit() {
        /*this._systemService.getStates();*/
        this._systemService.getInsuranceList();
    }

    ngAfterViewInit() {
        let _self = this;
        /* jQuery('#insurance_number').bind('keypress', function (e: any) {
            return !(e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57));
        }); */
        jQuery('#insurance_number').bind({paste : function(){_self.onKeyUp('insurance_number');}});
    }

    ngOnDestroy() {
        if (this.signUpNewPatientSubscriber) {
            this.signUpNewPatientSubscriber.unsubscribe();
        }
        if (this.resendEmailActivePatientSubscriber) {
            this.resendEmailActivePatientSubscriber.unsubscribe();
        }
    }

    submitSignUpForm() {
        this._loadingIndicatorService.start();
        this.signUpForm.user_role = 'PATIENT';
        let ignoreArray = [NaN, null, undefined, ''];
        if (ignoreArray.indexOf(this.signUpForm.month) > -1 && ignoreArray.indexOf(this.signUpForm.day) > -1 && ignoreArray.indexOf(this.signUpForm.year) > -1) {
            this.signUpForm.dob = '';
        } else {
            this.signUpForm.dob = this.signUpForm.year + '-' + this.signUpForm.month + '-' + this.signUpForm.day;
        }
        if (ignoreArray.indexOf(this.signUpForm.first) === -1 || ignoreArray.indexOf(this.signUpForm.middle) === -1 || ignoreArray.indexOf(this.signUpForm.last) === -1) {
            this.signUpForm.phone_mobile = this.signUpForm.first + ' ' + this.signUpForm.middle + '-' + this.signUpForm.last;
        } else {
            this.signUpForm.phone_mobile = null;
        }

        if (this.signUpNewPatientSubscriber) {
            this.signUpNewPatientSubscriber.unsubscribe();
        }

        if(!this.signUpForm.hasOwnProperty("password")){
            this.signUpForm.password = "";
        }

        if(!this.signUpForm.hasOwnProperty("password_confirmation")){
            this.signUpForm.password_confirmation = "";
        }

        this.signUpNewPatientSubscriber = this._homePageService.signUpNewPatient(this.signUpForm).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                if (success.error_code === 'RC000') {
                    //this.showLoginPage = false;
                    //this.idPatientRegistered = success.id;
                    this._signupMessageModal.onClose = () => {
                        this.signUpForm = new PatientDetail();
                        this.error = new PatientDetail();
                        this.dobDirective.setInit();
                        jQuery('#insurance-number').removeClass('hidden');
                    };
                    this._signupMessageModal.showDismissButton = false;
                    this._signupMessageModal.showCloseButton = true;
                    this._signupMessageModal.closeButtonLabel = 'OK';
                    this._signupMessageModal.dismissButtonLabel = '';
                    this._signupMessageModal.backdrop = 'false';
                    this._signupMessageModal.title = success.error_messages;
                    this._signupMessageModal.emitter('open');
                }
            }, (error: any) => {
                this._loadingIndicatorService.stop();
                let e = error.error;
                if (e.error_code === 'RC020') {
                    this.error = e.error_fields;
                } else if (e.error_code === 'RC107' || e.error_code === 'RC106' || e.error_code === 'RC110' || e.error_code === 'RC111') {
                    this.openAlertMessage(e.error_messages);
                }
            }, () => {
                this._loadingIndicatorService.stop();
            }
        );
    }

    openAlertMessage(msg: string) {
        this._alertService.onClose = () => {
            // TODO
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    onKeyUp(key: string) {
        switch (key) {
            case 'email':
                this.error.email = null;
                break;

            case 'email_confirmation':
                this.error.email_confirmation = null;
                break;

            case 'password':
                this.error.password = null;
                break;

            case 'password_confirmation':
                this.error.password_confirmation = null;
                break;

            case 'first_name':
                this.error.first_name = null;
                break;

            case 'last_name':
                this.error.last_name = null;
                break;

            case 'dob':
                this.error.dob = null;
                break;

            case 'phone_mobile':
                this.error.phone_mobile = null;
                break;

            case 'zip':
                this.error.zip = null;
                break;
            case 'insurance_id':
                this.error.insurance_id = null;
                break;
            case 'insurance_number':
                this.error.insurance_number = null;
                break;
        }
    }

    changeInsurance(plan: any) {
        this.error.insurance_id = null;
        if (plan === '-1') {
            this.signUpForm.insurance_number = '';
            jQuery('#insurance-number').addClass('hidden');
        } else {
            jQuery('#insurance-number').removeClass('hidden');
        }
    }

    resendEmail(id: number) {
        if (this.resendEmailActivePatientSubscriber) {
            this.resendEmailActivePatientSubscriber.unsubscribe();
        }
        let data = {'id' : id};
        this.resendEmailActivePatientSubscriber = this._homePageService.resendEmailActivePatient(data).subscribe(
            (success: any) => {
                if (success.error_code === 'RC000') {
                    this.openAlertMessage('Activation email is resent successfully');
                }
            }
        );
    }
}
