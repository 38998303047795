import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ehr',
  templateUrl: './ehr.component.html',
  styleUrls: ['../admin.component.scss','./ehr.component.scss'],
  moduleId: module.id,
})
export class EhrComponent implements AfterViewInit, OnDestroy, OnInit {

    selectedConnectionMode: boolean;
    messageResponseError: any;
    messageResponseSuccess: any;
    messageResponseSyncSuccess: any;
    getModeSubscriber: Subscription;
    switchModeSubscriber: Subscription;
    status: string = '';
    isFlatiron: boolean = false;

    constructor(
        public _router: Router,
        private _adminService: AdminRestService,
        private _alertService: AlertService,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        //TODO
        this.selectedConnectionMode = true;
    }

    ngOnInit() {
        if (this.getModeSubscriber) {
            this.getModeSubscriber.unsubscribe();
        }
        this.getModeSubscriber = this._adminService.getMode().subscribe(
            (success: any) => {
                if (success.error_code === 'RC000') {
                    this.selectedConnectionMode = success.is_online;
                    if (success.is_online) {
                        this.status = 'Connected';
                    } else {
                        this.status = 'Unconnected';
                    }
                }
            },
            (error: any) => {
                //TODO
            }
        );
    }

    ngAfterViewInit() {
        const domain = window.location.hostname;
        if (domain.indexOf('.') > 0 && domain.split('.')[0] == 'flatiron'){
            this.isFlatiron = true;
        }
    }

    ngOnDestroy() {
        if (this.getModeSubscriber) {
            this.getModeSubscriber.unsubscribe();
        }
        if (this.switchModeSubscriber) {
            this.switchModeSubscriber.unsubscribe();
        }
    }

    selectedMode(mode: boolean) {
        this.selectedConnectionMode = mode;
        this.messageResponseError = '';
        this.messageResponseSuccess = '';
    }

    saveConnectionInfo() {
        let is_online = { 'is_online': this.selectedConnectionMode ? 1 : 0  };
        
        if (this.switchModeSubscriber) {
            this.switchModeSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();
        this.switchModeSubscriber = this._adminService.switchMode(is_online).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.messageResponseError = '';
                this.messageResponseSuccess = success.error_messages;
                // if (success.error_code === 'RC000') {
                //     this.messageResponseError = '';
                //     this._alertService.onClose = () => {
                //         location.reload();
                //     };
                //     this._alertService.showDismissButton = false;
                //     this._alertService.dismissButtonLabel = 'Close';
                //     this._alertService.showCloseButton = true;
                //     this._alertService.closeButtonLabel = 'OK';
                //     this._alertService.title = success.message;
                //     this._alertService.emitter('open');
                // }
                if (is_online.is_online) {
                    this.status = 'Connected';
                } else {
                    this.status = 'Unconnected';
                }
            },
            (error: any) => {
                let errorJson = error.error;
                this.messageResponseError = errorJson.error_fields.is_online;
                this.messageResponseSuccess = '';
            }
        );
    }

    synchronizePokitdok() {
        this._loadingIndicatorService.start();
        this.messageResponseSyncSuccess = '';
        this._adminService.synchronizePokitdok().subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.messageResponseSyncSuccess = success.error_messages;
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                this.messageResponseSyncSuccess = '';
            },
            () => {
                this._loadingIndicatorService.stop();
            }
        );
    }

    synchronizeHL7() {
        this._loadingIndicatorService.start();
        this.messageResponseSyncSuccess = '';
        this._adminService.synchronizeHL7().subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.messageResponseSyncSuccess = success.error_messages;
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                this.messageResponseSyncSuccess = '';
            },
            () => {
                this._loadingIndicatorService.stop();
            }
        );
    }
}
