import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { Utils } from '../../shared/utils';
import { DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent } from '../../shared/datatable';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['../admin.component.scss','./practice.component.scss'],
  moduleId: module.id,
})
export class PracticeComponent implements OnInit, OnDestroy {

  practiceList: any = new Object();
    page: number = 1;
    errorMessage: string;
    searchText: string = '';
    runRequest: boolean = true;
    userInfoSubscriber: Subscription;

    @ViewChild(RtnDataTableCoreComponent) rtnDataTableCore: RtnDataTableCoreComponent;
    @ViewChild(RtnDataTableFilterComponent) rtnDataTableFilter: RtnDataTableFilterComponent;

    constructor(
      private _adminService: AdminRestService,
      private _loadingIndicatorService: LoadingIndicatorService,
      private _utils: Utils
    ) {
        this.init();
    }

    init() {
        this.practiceList.data = new Array();
        this.practiceList.numberOfPages = 0;
        this.practiceList.rowsOnPage = 10;
        this.errorMessage = null;
    }

    ngOnInit() {
        this.getInfo(this.page);
    }

	ngOnDestroy() {
        if (this.userInfoSubscriber) {
            this.userInfoSubscriber.unsubscribe();
        }
    }

    getInfo(page: number, search_text: string = null) {
        this.runRequest = false;
        this.errorMessage = null;
        this._loadingIndicatorService.start();
        if (this.userInfoSubscriber) {
            this.userInfoSubscriber.unsubscribe();
        }
        this.userInfoSubscriber = this._adminService.getList(page, 10, search_text).subscribe(
        (success: any) => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
            if (success.error_code === 'RC000') {
                this.practiceList.numberOfPages = success.last_page || 0;
                let practices = success.practices || [];
                practices.map((practice: any, i: any) => {
                    let id = '0000000000' + practice.id.toString();
                    practice.id_show = id.substr(id.length - 10);
                    practice.fullAddress = this._utils.generateFullAddress(practice.address, practice.address2, practice.city,
                        practice.state_code, practice.zip);
                });
                this.practiceList.data = practices;
            } else if (success.error_code === 'RC077') {
                this.init();
                this.errorMessage = success.error_messages;
            }
        },
        (error: any) => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
        },
        () => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
        });
    }

    onDataChange(e: any) {
        this.page = e['currentPage'];
        this.getInfo(this.page, this.searchText);
    }

    onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
        if (this.runRequest) {
            if (this.page === 1) {
                this.getInfo(this.page, this.searchText.trim());
            } else {
                dataTable.setPage(1, 10);
            }
        }
    }

}
