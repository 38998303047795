import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { SystemData, SystemService, SystemEvent, SystemInterval } from '@app/shared/system';

declare let jQuery: any;

@Component({
  selector: 'app-provider-data-logs',
  templateUrl: './provider-data-logs.component.html',
  styleUrls: ['./provider-data-logs.component.scss']
})
export class ProviderDataLogsComponent implements OnInit {
  public menuSelect = '';
  private getMenuDevice: String = '#menu-device';
  private defaultRoute: String = '/admin/';
  public isDownload:boolean = false;
  minHeight: number;

  constructor(
    public _router: Router,
    public _systemData: SystemData,
    public _systemService: SystemService,
    private _route: ActivatedRoute
  ) {
    this._route.queryParams.subscribe((params:any) => {
      if (params['isDownload'] != undefined && params['isDownload'] != null) {
        this.isDownload = params['isDownload'];
      }      
    });
  }

  ngOnInit() {
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }
  onChangeMenu(value: any) {
    const _seft = this;
    switch (value) {
      case 'Appointment Statistical by types':
          this._router.navigate(['/provider', 'statistical-appointment-by-types']).then(() => { //offices-waittime
            window.location.reload();
          });
          break;      
      default:
        break;
    }
    jQuery(_seft.getMenuDevice).blur();
  }
}
