<!--<rtn-provider-header></rtn-provider-header>-->
<div class="provider-edit px-0 pt-4">
  <h3 class="text-header-form-edit">
    Edit Profile
  </h3>
  <form method="post" #form="ngForm" (ngSubmit)="updateSuperUserForm(form.value)">
    <div class="row">
      <div class="px-0 col-sm-12 col-lg-8">
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Contact Info</h3>
          </div>
          <div class="panel-body py-3">
            <div class="col-12 inline-edit-provider">
              <!--                <div class="input-group select width-full">-->
              <span class="text-label-style">Practice</span>
              <div class="input-group">
                <select class="form-control textbox-edit-form" name="practice_id"
                        [(ngModel)]="superUserDetail.practice_id" ngControl="practice_id" disabled>
                  <option [value]="''" selected>Select Practice</option>
                  <option *ngFor="let practice of practiceList" [value]="practice.id">{{practice.name}}</option>
                </select>
                <div class="input-group-append cursorPointer select-state pt-1">
                  <i class="fa fa-caret-down"></i>
                </div>
              </div>
              <span class="message-error" *ngIf="responseError['office_name']">{{responseError['office_name']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="pl-0 col-6 email-address">
                  <span class="text-label-style input-label-required">Email Address</span>
                  <input class="form-control textbox-edit-form" type="text" ngControl="email"
                         [class.input-error]="responseError['email']" name="email" [(ngModel)]="superUserDetail.email"
                         maxlength="50" (keyup)="onKeyUp('email')">
                  <span class="message-error" *ngIf="responseError['email']">{{responseError['email']}}</span>
                </div>
                <div class="px-0 col-6">
                  <div class="text-label-style input-label-required">Phone Number</div>
                  <div>
                    <div class="col-xs-12 col-md-12 no-padding-left-right">
                      <input class="form-control textbox-edit-form" ngControl="phone_mobile" type="text"
                             [class.input-error]="responseError['phone_mobile']" name="phone_mobile"
                             [(ngModel)]="superUserDetail.phone_mobile" (keyup)="onKeyUp('phone_mobile')"
                             mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                      <span class="message-error">{{ responseError.phone_mobile }}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="pl-0 col-5">
                  <span class="text-label-style input-label-required">First Name</span>
                  <input class="form-control textbox-edit-form" type="text" name="first_name"
                         [(ngModel)]="superUserDetail.first_name" ngControl="first_name"
                         [class.input-error]="responseError['first_name']" maxlength="50"
                         (keyup)="onKeyUp('first_name')">
                  <span class="message-error" *ngIf="responseError['first_name']">{{responseError['first_name']}}</span>
                </div>
                <div class="px-0 col-2">
                                  <span for="middle_name">Middle
                                      <span class="hidden-xs">Initial</span>
                                  </span>
                  <input id="middle-name" class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['middle_name']" name="middle_name"
                         [(ngModel)]="superUserDetail.middle_name" ngControl="middle_name" maxlength="1">
                  <span class="message-error"
                        *ngIf="responseError['middle_name']">{{responseError['middle_name']}}</span>
                </div>
                <div class="pr-0 col-5">
                  <span class="text-label-style input-label-required">Last Name</span>
                  <input class="form-control textbox-edit-form" type="text" name="last_name"
                         [(ngModel)]="superUserDetail.last_name" ngControl="last_name"
                         [class.input-error]="responseError['last_name']" maxlength="50" (keyup)="onKeyUp('last_name')">
                  <span class="message-error" *ngIf="responseError['last_name']">{{responseError['last_name']}}</span>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address']"
                     name="address" [(ngModel)]="superUserDetail.address" ngControl="address" maxlength="50"
                     (keyup)="onKeyUp('address')">
              <span class="message-error" *ngIf="responseError['address']">{{responseError['address']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address2']"
                     name="address2" [(ngModel)]="superUserDetail.address2" ngControl="address2" maxlength="50">
              <span class="message-error" *ngIf="responseError['address2']">{{responseError['address2']}}</span>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="px-0 col-5">
                  <span class="text-label-style input-label-required">City</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['city']"
                         name="city" [(ngModel)]="superUserDetail.city" ngControl="city" maxlength="50"
                         (keyup)="onKeyUp('city')">
                  <span class="message-error" *ngIf="responseError['city']">{{responseError['city']}}</span>
                </div>
                <div class="col-4">
                  <!--                <div class="input-group select width-full">-->
                  <span class="text-label-style input-label-required">State</span>
                  <div class="input-group">
                    <select class="form-control textbox-edit-form" name="state_code"
                            [(ngModel)]="superUserDetail.state_code" ngControl="state_code"
                            [class.input-error]="responseError['state_code']" (change)="onKeyUp('state_code')">
                      <option [value]="''" selected>Select State</option>
                      <option *ngFor="let stateItem of _systemData.states"
                              [value]="stateItem.code">{{stateItem.name}}</option>
                    </select>
                    <div class="input-group-append cursorPointer select-state pt-1">
                      <i class="fa fa-caret-down"></i>
                    </div>
                  </div>
                  <span class="message-error" *ngIf="responseError['state_code']">{{responseError['state_code']}}</span>
                </div>
                <div class="px-0 col-3">
                  <span class="text-label-style input-label-required">ZIP Code</span>
                  <input class="form-control textbox-edit-form" type="text" ngControl="zip"
                         [class.input-error]="responseError['zip']" name="zip" [(ngModel)]="superUserDetail.zip"
                         (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'" [target]="superUserDetail"
                         [field]="'zip'" maxlength="10">
                  <span class="message-error" *ngIf="responseError['zip']">{{responseError['zip']}}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 padding-bottom-20 pl-0">
      <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">SAVE</button>
      <span class="padding-left-15 message-success">{{ successMessage }}</span>
    </div>
  </form>
</div>
