<div class="master-add">
    <h3 class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-header-form-add">
      <a class="no-focus" [routerLink]="['/admin', 'account']">
        <span class="fa fa-angle-left style-top-arrow"></span>
      </a>
      Add Master
  </h3>
    <form novalidate class="row padding-left-right-15">
        <div class="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 no-padding-left">
            <div class="card-add-master card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-add">Business Info</h3>
                </div>
                <div class="card-body row">
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master">
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left">
                            <span class="text-label-style input-label-required">Office/Practice/Hospital Name</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError.office_name" [(ngModel)]="superProviderDetail.office_name" name="office_name" maxlength="255" (keyup)="onKeyUp('office_name')">
                            <span class="message-error">{{ responseError.office_name }}</span>
                        </div>
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left-right">
                            <span class="text-label-style input-label-required">Master Account Name</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError.account_name" [(ngModel)]="superProviderDetail.account_name" name="account_name" maxlength="50" (keyup)="onKeyUp('account_name')" (input)="preventWhiteSpaceInput($event)">
                            <span class="message-error">{{ responseError.account_name }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master">
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left">
                            <span class="text-label-style input-label-required">Email Address</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError.email" [(ngModel)]="superProviderDetail.email" name="email" (keyup)="onKeyUp('email')" maxlength="254">
                            <span class="message-error">{{ responseError.email }}</span>
                        </div>
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left-right">
                            <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left">
                                <span class="text-label-style input-label-required">Phone Number</span>
                            </div>
                            <div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-right no-padding-left">
                                    <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError.phone_mobile" [(ngModel)]="superProviderDetail.phone_mobile" name="phone_mobile" (keyup)="onKeyUp('phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                                    <span class="message-error">{{ responseError.phone_mobile }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-add-master card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-add">Send Registration Link</h3>
                </div>
                <div class="card-body row">
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master">
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left">
                            <span class="text-label-style">Names</span>
                        </div>
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left-right" [class.scroll-appear]="scrollAppear">
                            <span class="text-label-style">Email Addresses</span>
                        </div>
                    </div>
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right registration-link" id="scroll">
                        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master" *ngFor="let r of registrationLink">
                            <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left">
                                <input class="form-control textbox-add-form" placeholder="John Smith, MD" type="text" [(ngModel)]="r.names" name="r_names" maxlength="100">
                            </div>
                            <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left-right">
                                <input class="form-control textbox-add-form" placeholder="example@example.com" type="text" [(ngModel)]="r.email" name="r_email" maxlength="254">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right add-more">
                        <button class="btn btn-rtn float-right" type="button" (click)="addMore()">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            Add More
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 no-padding-right">
            <div class="card-add-master card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-add">Billing Info</h3>
                </div>
                <div class="card-body row">
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master">
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left">
                            <span class="text-label-style input-label-required">First Name</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.first_name']" [(ngModel)]="superProviderDetail.billing.first_name" name="billing_first_name" maxlength="50" (keyup)="onKeyUp('billing.first_name')" (input)="preventWhiteSpaceInput($event)">
                            <span class="message-error">{{ responseError['billing.first_name'] }}</span>
                        </div>
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left-right">
                            <span class="text-label-style input-label-required">Last Name</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.last_name']" [(ngModel)]="superProviderDetail.billing.last_name" name="billing_last_name" maxlength="50" (keyup)="onKeyUp('billing.last_name')">
                            <span class="message-error">{{ responseError['billing.last_name'] }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master">
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left">
                            <span class="text-label-style input-label-required">Email Address</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.email']" [(ngModel)]="superProviderDetail.billing.email" name="billing_email" (keyup)="onKeyUp('billing.email')" maxlength="254">
                            <span class="message-error">{{ responseError['billing.email'] }}</span>
                        </div>
                        <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-left-right">
                            <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left">
                                <span class="text-label-style input-label-required">Phone Number</span>
                            </div>
                            <div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-right no-padding-left">
                                    <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.phone_mobile']" [(ngModel)]="superProviderDetail.billing.phone_mobile" name="billing_phone_mobile" (keyup)="onKeyUp('billing.phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                                    <span class="message-error">{{ responseError['billing.phone_mobile'] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master">
                        <span class="text-label-style input-label-required">Address Line 1</span>
                        <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.address']" [(ngModel)]="superProviderDetail.billing.address" name="billing_address" maxlength="50" (keyup)="onKeyUp('billing.address')">
                        <span class="message-error">{{ responseError['billing.address'] }}</span>
                    </div>
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right inline-add-master">
                        <span class="text-label-style">Address Line 2</span>
                        <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.address2']" [(ngModel)]="superProviderDetail.billing.address2" name="billing_address2" maxlength="50" (keyup)="onKeyUp('billing.address2')">
                        <span class="message-error">{{ responseError['billing.address2'] }}</span>
                    </div>
                    <div class="row padding-left-15">
                        <div class="col-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 no-padding-left-right">
                            <span class="text-label-style input-label-required">City</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.city']" [(ngModel)]="superProviderDetail.billing.city" name="billing_city" maxlength="50" (keyup)="onKeyUp('billing.city')">
                            <span class="message-error">{{ responseError['billing.city'] }}</span>
                        </div>
                        <div class="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 no-padding-left-right padding-left-15">
                            <div class="input-group select width-full">
                                <span class="text-label-style input-label-required">State</span>
                                <select class="form-control textbox-add-form" [(ngModel)]="superProviderDetail.billing.state_code" name="billing_state_code" [class.input-error]="responseError['billing.state_code']" (change)="onKeyUp('billing.state_code')">
                                    <option [value]="''" selected>Select State</option>
                                    <option *ngFor="let stateItem of _systemData.states" [value]="stateItem.code">{{ stateItem.name }}</option>
                                </select>
                                <div class="input-group-select-icon cursorPointer select-state">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <span class="message-error">{{ responseError['billing.state_code'] }}</span>
                        </div>
                        <div class="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 padding-right-15 padding-left-15">
                            <span class="text-label-style input-label-required">ZIP Code</span>
                            <input class="form-control textbox-add-form" type="text" [class.input-error]="responseError['billing.zip']" [(ngModel)]="superProviderDetail.billing.zip" name="billing_zip" (keyup)="onKeyUp('billing.zip')" simplemask [format]="'#####-####'" [target]="superProviderDetail.billing" [field]="'zip'" maxlength="10">
                            <span class="message-error">{{ responseError['billing.zip'] }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-bottom-20 no-padding-left">
            <button class="container btn btn-rtn btn-90" type="submit" (click)="addSuperProviderClicked()">Create</button>
        </div>
    </form>
  </div>
