<div class="provider-edit">
  <h3 class="col-xs-12 text-header-form-edit"><a class="no-focus" [routerLink]="['/provider', 'profile']"><span class="fa fa-angle-left style-top-arrow"></span></a>Edit Profile</h3>
  <form method="post" #form="ngForm" (ngSubmit)="updateProfileForm(form.value)">
    <div class="row">
      <div class="col-sm-12 col-lg-6 col-left">
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <div class="row">
              <div class="col-4">
                <h3 class="panel-title text-title-form-edit">Account Info</h3>
              </div>
            </div>
          </div>
          <div class="panel-body py-3 px-0">
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-6">
                  <span class="text-label-style input-label-required">Email Address</span>
                  <input class="form-control textbox-edit-form" type="text" [ngModel]="providerDetail.email" name="email" maxlength="254" disabled>
                </div>
                <div class="pr-0 col-6">
                  <div class="col-xs-12 no-padding-left">
                    <span class="text-label-style input-label-required">Phone Number</span>
                  </div>
                  <div class="col-xs-12 no-padding-left">
                    <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['phone_mobile']" name="phone_mobile" [ngModel]="providerDetail.phone_mobile" (keyup)="onKeyUp($event, 'phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                    <input type="hidden" [ngModel]="providerDetail.phone_mobile_tmp" name="phone_mobile">
                    <span class="message-error" *ngIf="responseError['phone_mobile']">{{responseError['phone_mobile']}}</span>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <!-- <div class="row">
                <div class="col-6">
                  <i class="fa fa-exclamation-circle icon-warning" (click)="passwordFormatToolipDirective.toggle($event)"></i>
                  <span class="text-label-style">Password</span>
                  <input class="form-control textbox-edit-form" type="password" [class.input-error]="responseError['password']" name="password" maxlength="50" (keyup)="onKeyUp($event, 'password')">
                  <span class="message-error" *ngIf="responseError['password']">{{responseError['password']}}</span>
                </div>
                <div class="col-6">
                  <span class="text-label-style">Confirm Password</span>
                  <input class="form-control textbox-edit-form" type="password" [class.input-error]="responseError['password_confirmation']" name="password_confirmation" maxlength="50" (keyup)="onKeyUp($event, 'password_confirmation')">
                  <span class="message-error" *ngIf="responseError['password_confirmation']">{{responseError['password_confirmation']}}</span>
                </div>
              </div> -->

            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address']" [ngModel]="providerDetail.address" name="address" maxlength="50" (keyup)="onKeyUp($event, 'address')">
              <span class="message-error" *ngIf="responseError['address']">{{responseError['address']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address2']" [ngModel]="providerDetail.address2" name="address2" maxlength="50" (keyup)="onKeyUp($event, 'address2')">
              <span class="message-error" *ngIf="responseError['address2']">{{responseError['address2']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-5">
                  <span class="text-label-style input-label-required">City</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['city']" [ngModel]="providerDetail.city" name="city" maxlength="50" (keyup)="onKeyUp($event, 'city')">
                  <span class="message-error" *ngIf="responseError['city']">{{responseError['city']}}</span>
                </div>
                <div class="col-4">
                  <span class="text-label-style input-label-required">State</span>
                  <select class="form-control textbox-edit-form" [ngModel]="providerDetail.state_code" name="state_code" [class.input-error]="responseError['state_code']" (change)="onKeyUp($event, 'state_code')">
                    <option [value]="''" selected>Select State</option>
                    <option *ngFor="let stateItem of _systemData.states" [value]="stateItem.code">{{stateItem.name}}</option>
                  </select>
                  <!--                  <div class="input-group-select-icon cursorPointer select-state">-->
                  <!--                    <i class="fa fa-caret-down"></i>-->
                  <!--                  </div>-->
                  <span class="message-error" *ngIf="responseError['state_code']">{{responseError['state_code']}}</span>
                </div>
                <div class="px-0 col-3">
                  <span class="text-label-style input-label-required">ZIP Code</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['zip']" [ngModel]="providerDetail.zip" name="zip" (keyup)="onKeyUp($event, 'zip')" simplemask [format]="'#####-####'" [target]="providerDetail" [field]="'zip'" maxlength="10">
                  <span class="message-error" *ngIf="responseError['zip']">{{responseError['zip']}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Physician Info</h3>
          </div>
          <div class="panel-body py-3 px-0">
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-5">
                  <span class="text-label-style input-label-required">First Name</span>
                  <input class="form-control textbox-edit-form" type="text" [ngModel]="providerDetail.first_name" name="first_name" [class.input-error]="responseError['first_name']" maxlength="50" (keyup)="onKeyUp($event, 'first_name')" [disabled]="providerDetail.is_mapped">
                  <span class="message-error" *ngIf="responseError['first_name']">{{responseError['first_name']}}</span>
                </div>
                <div class="col-2">
                  <span class="text-label-style">Middle Initial</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['middle_name']" [ngModel]="providerDetail.middle_name" name="middle_name" maxlength="1" (keyup)="onKeyUp($event, 'middle_name')">
                  <span class="message-error" *ngIf="responseError['middle_name']">{{responseError['middle_name']}}</span>
                </div>
                <div class="px-0 col-5">
                  <span class="text-label-style input-label-required">Last Name</span>
                  <input class="form-control textbox-edit-form" type="text" [ngModel]="providerDetail.last_name" name="last_name" [class.input-error]="responseError['last_name']" maxlength="50" (keyup)="onKeyUp($event, 'last_name')" [disabled]="providerDetail.is_mapped">
                  <span class="message-error" *ngIf="responseError['last_name']">{{responseError['last_name']}}</span>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-6">
                  <span class="text-label-style">Credentials</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['credentials']" [ngModel]="providerDetail.credentials" name="credentials" maxlength="50" placeholder="i.e. MD, DO, RN, RPA..." (keyup)="onKeyUp($event, 'credentials')">
                  <span class="message-error" *ngIf="responseError['credentials']">{{responseError['credentials']}}</span>
                </div>
                <div class="pr-0 col-6">
                  <span class="text-label-style input-label-required">NPI</span>
                  <input class="form-control textbox-edit-form" type="text" [ngModel]="providerDetail.npi" name="npi" [disabled]="providerDetail.is_mapped" [class.input-error]="responseError['npi']" maxlength="10" (keyup)="onKeyUp($event, 'npi')" inputmask [format]="'##########'" [validator]="'[0-9]'" [target]="providerDetail" [field]="'npi'">
                  <span class="message-error" *ngIf="responseError['npi']">{{responseError['npi']}}</span>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Office/Practice/Hospital Name</span>
              <select class="form-control textbox-edit-form" [(ngModel)]="providerDetail.office_name" name="office_name" [disabled]="!showPractice"
                      [class.input-error]="responseError['office_name']" (change)="onKeyUp($event, 'office_name')">
                <option [value]="''" selected>Select Practice</option>
                <option *ngFor="let practice of practiceList" [value]="practice.id">{{practice.name}}</option>
              </select>
              <!--              <div class="input-group-select-icon cursorPointer select-state">-->
              <!--                <i class="fa fa-caret-down"></i>-->
              <!--              </div>-->
              <span class="message-error" *ngIf="responseError['office_name']">{{responseError['office_name']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Specialties</span>
              <select select2 class="form-control multichoice-edit-form" (OnKeyUp)="onKeyUp($event, 'specialties')">
                <option *ngFor="let specialtyItem of _systemData.specialties" data-id="specialtyItem.id" [value]="specialtyItem.id">{{specialtyItem.name}}</option>
              </select>
              <span class="message-error" *ngIf="responseError['specialties']">{{responseError['specialties']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Website</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['website_url']" [ngModel]="providerDetail.website_url" name="website_url" maxlength="255" (keyup)="onKeyUp($event, 'website_url')" (input)="preventWhiteSpaceInput($event)">
              <span class="message-error" *ngIf="responseError['website_url']">{{responseError['website_url']}}</span>
            </div>
          </div>
        </div>

        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Office Address</h3>
          </div>
          <div class="panel-body py-3 px-0">

            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['office_address']" [ngModel]="providerDetail.office_address" name="office_address" maxlength="50" (keyup)="onKeyUp($event, 'office_address')">
              <span class="message-error" *ngIf="responseError['office_address']">{{responseError['office_address']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['office_address2']" [ngModel]="providerDetail.office_address2" name="office_address2" maxlength="50" (keyup)="onKeyUp($event, 'office_address2')">
              <span class="message-error" *ngIf="responseError['office_address2']">{{responseError['office_address2']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-5">
                  <span class="text-label-style">City</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['office_city']" [ngModel]="providerDetail.office_city" name="office_city" maxlength="50" (keyup)="onKeyUp($event, 'office_city')">
                  <span class="message-error" *ngIf="responseError['office_city']">{{responseError['office_city']}}</span>
                </div>
                <div class="col-4">
                  <span class="text-label-style">State</span>
                  <select class="form-control textbox-edit-form" [ngModel]="providerDetail.office_state_code" name="office_state_code" [class.input-error]="responseError['office_state_code']" (change)="onKeyUp($event, 'office_state_code')">
                    <option [value]="''" selected>Select State</option>
                    <option *ngFor="let stateItem of _systemData.states" [value]="stateItem.code">{{stateItem.name}}</option>
                  </select>
                  
                  <span class="message-error" *ngIf="responseError['office_state_code']">{{responseError['office_state_code']}}</span>
                </div>
                <div class="px-0 col-3">
                  <span class="text-label-style">ZIP Code</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['office_zip']" [ngModel]="providerDetail.office_zip" name="office_zip" (keyup)="onKeyUp($event, 'office_zip')" simplemask [format]="'#####-####'" [target]="providerDetail" [field]="'office_zip'" maxlength="10">
                  <span class="message-error" *ngIf="responseError['office_zip']">{{responseError['office_zip']}}</span>
                </div>
              </div>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Location</span>
              <select select2location class="form-control multichoice-edit-form" (OnKeyUp)="onKeyUp($event, 'location_id')" id="select2location">
                <option *ngFor="let locitem of _systemData.providerLocationLists; let i = index" data-id="locitem.id" [value]="locitem.id">{{locitem.location_name}} - {{locitem.city}}</option>
              </select>
              <span class="message-error" *ngIf="responseError['location_id']">{{responseError['location_id']}}</span>
            </div>

          </div>
        </div>

        <!-- <div class="panel-edit-profile panel-default">
            <div class="panel-heading">
                <h3 class="panel-title text-title-form-edit">Accepted Insurance</h3>
            </div>
            <div class="panel-body">
                <span class="text-label-style input-label-required">Plan</span>
                <select select2-insurance class="form-control multichoice-signup-form" name="insurances" [(ngModel)]="providerDetail.insurances" (OnKeyUp)="onKeyUp($event, 'insurances')" (change)="onKeyUp($event, 'insurances')" [inputError]="responseError['insurances']">
                    <option *ngFor="let item of _systemData.insurances" [value]="item.id">{{item.name}}</option>
                </select>
                <span class="message-error" *ngIf="responseError['insurances']">{{responseError['insurances']}}</span>
            </div>
        </div> -->
      </div>
      <div class="col-sm-12 col-lg-6 col-right">
        <!-- <div class="panel-edit-profile panel-default">
            <div class="panel-heading">
                <h3 class="panel-title text-title-form-edit">Billing Info</h3>
            </div>
            <div class="panel-body">
                <div class="row padding-left-15">
                    <div class="col-xs-6 no-padding-left inline-edit-provider">
                        <span class="text-label-style input-label-required">First Name</span>
                        <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.first_name']" [ngModel]="billing.first_name" name="billing_first_name" maxlength="50" (keyup)="onKeyUp($event, 'billing_first_name')">
                        <span class="message-error" *ngIf="responseError['billing.first_name']">{{responseError['billing.first_name']}}</span>
                    </div>
                    <div class="col-xs-6 no-padding-left inline-edit-provider">
                        <span class="text-label-style input-label-required">Last Name</span>
                        <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.last_name']" [ngModel]="billing.last_name" name="billing_last_name" maxlength="50" (keyup)="onKeyUp($event, 'billing_last_name')">
                        <span class="message-error" *ngIf="responseError['billing.last_name']">{{responseError['billing.last_name']}}</span>
                    </div>
                </div>
                <div class="row padding-left-15">
                    <div class="col-sm-6 no-padding-left inline-edit-provider">
                        <span class="text-label-style input-label-required">Email Address</span>
                        <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.email']" [ngModel]="billing.email" name="billing_email" maxlength="254" (keyup)="onKeyUp($event, 'billing_email')">
                        <span class="message-error" *ngIf="responseError['billing.email']">{{responseError['billing.email']}}</span>
                    </div>
                    <div class="col-sm-6 no-padding-left-right inline-edit-provider">
                        <div class="col-xs-12 no-padding-left">
                            <span class="text-label-style input-label-required">Phone Number</span>
                        </div>
                        <div class="col-xs-12 no-padding-left">
                            <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.phone_mobile']" [ngModel]="billing.phone_mobile" maxlength="20" (keyup)="onKeyUp($event, 'billing_phone_mobile')" inputmask [format]="'(###) ###-####'" [validator]="'[0-9]'" [target]="billing" [field]="'phone_mobile_tmp'">
                            <input type="hidden" [ngModel]="billing.phone_mobile_tmp" name="billing_phone_mobile">
                            <span class="message-error" *ngIf="responseError['billing.phone_mobile']">{{responseError['billing.phone_mobile']}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 no-padding-left-right inline-edit-provider">
                    <span class="text-label-style">Address Line 1</span>
                    <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.address']" [ngModel]="billing.address" name="billing_address" maxlength="50" (keyup)="onKeyUp($event, 'billing_address')">
                    <span class="message-error" *ngIf="responseError['billing.address']">{{responseError['billing.address']}}</span>
                </div>
                <div class="col-xs-12 no-padding-left-right inline-edit-provider">
                    <span class="text-label-style">Address Line 2</span>
                    <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.address2']" [ngModel]="billing.address2" name="billing_address2" maxlength="50" (keyup)="onKeyUp($event, 'billing_address2')">
                    <span class="message-error" *ngIf="responseError['billing.address2']">{{responseError['billing.address2']}}</span>
                </div>
                <div class="row padding-left-15">
                    <div class="col-xs-5 no-padding-left-right">
                        <span class="text-label-style">City</span>
                        <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.city']" [ngModel]="billing.city" name="billing_city" maxlength="50" (keyup)="onKeyUp($event, 'billing_city')">
                        <span class="message-error" *ngIf="responseError['billing.city']">{{responseError['billing.city']}}</span>
                    </div>
                    <div class="col-xs-4 no-padding-left-right padding-left-15">
                        <div class="input-group select width-full">
                            <span class="text-label-style">State</span>
                            <select class="form-control textbox-edit-form" [ngModel]="billing.state_code" name="billing_state_code" [class.input-error]="responseError['billing.state_code']" (change)="onKeyUp($event, 'billing_state_code')">
                                <option [value]="''" selected>Select State</option>
                                <option *ngFor="let stateItem of _systemData.states" [value]="stateItem.code">{{stateItem.name}}</option>
                            </select>
                            <div class="input-group-select-icon cursorPointer select-state">
                                <i class="fa fa-caret-down"></i>
                            </div>
                        </div>
                        <span class="message-error" *ngIf="responseError['billing.state_code']">{{responseError['billing.state_code']}}</span>
                    </div>
                    <div class="col-xs-3 padding-right-15 padding-left-15">
                        <span class="text-label-style">ZIP Code</span>
                        <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['billing.zip']" [ngModel]="billing.zip" name="billing_zip" (keyup)="onKeyUp($event, 'billing_zip')" simplemask [format]="'#####-####'" [target]="billing" [field]="'zip'" maxlength="10">
                        <span class="message-error" *ngIf="responseError['billing.zip']">{{responseError['billing.zip']}}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit"><span class="input-label-required">Hours of Operation</span></h3>
          </div>
          <div class="panel-body px-0">
            <rtn-opening-hour [enableValidation]="true" [resetOnDisabled]="false" (removeMessage)="removeMessage($event)"></rtn-opening-hour>
            <span class="message-error padding-left-15 error-opening-hour" *ngIf="responseError['opening_hours'] && responseError['opening_hours'].length !== 0">{{responseError['opening_hours']}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 padding-bottom-20 pl-0">
      <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Save</button>
      <span class="padding-left-15 message-success">{{ successMessage }}</span>
    </div>
  </form>
</div>
<rtn-password-format-tooltip></rtn-password-format-tooltip>
