import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';

// import {Body} from '@angular/http/src/body';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  getCapacityUrl = environment.serverUrl + '/documents/capacity';
  getDocumentListUrl = environment.serverUrl + '/documents';
  deleteDocumentsUrl = environment.serverUrl + '/documents/delete';
  xhr: XMLHttpRequest = new XMLHttpRequest();

  constructor(private _http: HttpClient) {
  }
  debugMode() {
    return !environment.production;
  }
  deleteDocuments(Body: any) {
    return this._http.post(this.deleteDocumentsUrl, Body);
  }

  cancelUpload() {
    this.xhr.abort();
  }

  getDocumentList(limit: any, page: any) {
    const params = new HttpParams().set('limit', limit).set('page', page);
    return this._http.get(this.getDocumentListUrl,{params});
  }

  uploadDocument(documents: File, is_duplicate: number) {
    return Observable.create((observer: any) => {
      const url: string = environment.serverUrl + '/documents';
      const formData: FormData = new FormData();
      this.xhr = new XMLHttpRequest();

      this.xhr.onreadystatechange = () => {
        if (this.xhr.readyState === 4) {
          if (this.xhr.status === 200) {
            observer.next(JSON.parse(this.xhr.response));
            observer.complete();
          } else {
            observer.error(this.xhr.response);
          }
        }
      };

      const token = localStorage.getItem('user_token');
      this.xhr.open('POST', url, true);
      this.xhr.setRequestHeader('Authorization', 'Bearer ' + token);

      formData.append('document', documents, documents.name);
      formData.append('is_duplicate', is_duplicate.toString());
      this.xhr.send(formData);
    });
  }

  getCapacity() {
    return this._http.get(this.getCapacityUrl);
  }
}
