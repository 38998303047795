<div class="wrapper-patient">
    <rtn-providerdatalog-header></rtn-providerdatalog-header>
    <div class="container user-portal patient-wraper clear-both pt-3" id="datalogcontainer" role="main" [style.height.px]="minHeight">
        <div class="col-12 col-lg-3 no-padding-left-right sidebar_fa pt-3">
            <nav
                class="navbar navbar-static-top col-md-12 col-lg-12 no-padding-left d-none d-md-block no-print">
                <div class="container no-padding-left-right">
                    <ul class="col-12 navbar-nav main-menu pr-0">
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2"
                                (click)="onChangeMenu('Appointment Statistical by types')"
                                [class.active]="_systemService.navActivated(['/provider', 'statistical-appointment-by-types'], _router)">
                                <i class="fas fa-chart-pie style-icon" aria-hidden="true"></i>Appointment Statistics by type
                            </a>
                        </li>                      
                    </ul>
                </div>
            </nav>
            <nav
                class="navbar col-12 no-padding-left menu-mobile d-block d-md-none navbar-expand-md pl-3 no-print">
                <div class="form-group">
                    <select class="col-12 fontawesome-select menu-device" id="menu-device"
                        (change)="onChangeMenu($event.target.value)" [value]="menuSelect" [(ngModel)]="menuSelect">
                        <option class="menu-device-item" value="Appointment Statistical by types">Appointment Statistics by type</option>
                    </select>
                </div>
            </nav>
        </div>
        
        <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 data_log">
            <router-outlet></router-outlet>
        </div>        
    </div>
    <rtn-common-footer></rtn-common-footer>
</div>