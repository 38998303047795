<modal #AppointmentDetail_Modals class="appointment-detail">

    <i class="fa fa-times fa-lg close-btn " (click)="AppointmentDetail_Modals.close(); closeModals();"
    aria-hidden="true"></i>
    <div class="row container" [style.background-color]=" ((!arrayModule[1] && !arrayModule[2]) || (arrayModule[1] && !arrayModule[2])) ? 'transparent' : null">
        <!-- <div class="col col-sm-4" *ngIf="!arrayModule[1] && !arrayModule[2]"></div> -->
        <div class="col-12 col-lg-5" *ngIf="arrayModule[2]">
            <!-- <i class="fa fa-times fa-lg close-btn" (click)="AppointmentDetail_Modals.close(); closeModals();" aria-hidden="true"></i> -->
            <!-- <i *ngIf="!arrayModule[1]" class="fa fa-times fa-lg close-btn-detail" (click)="AppointmentDetail_Modals.close(); closeModals();" aria-hidden="true"></i> -->
            <div class="detail">
                <h4 class="title pt-2" *ngIf="arrayModule[2]">DETAILS</h4>
                <app-speedometer-waittime *ngIf="arrayModule[2]" [enabledDetail]="true" [attr.isModal]="true">
                </app-speedometer-waittime>                
                <div class="padding-left-right-15" *ngIf="errorCancelMessage">
                    <p  class="message-error">{{errorCancelMessage}}</p>
                </div>
                <p [class.hidden]="!isCancel" class="p-2">
                    <!--<button type="button" class="btn btn-rtn text-uppercase btn-detail"
                        (click)="rescheduleDirective.open(appointmentDetail); close()">RESCHEDULE</button>-->
                    <button type="button" class="btn btn-rtn text-uppercase btn-detail"
                        (click)="reschedule(appointmentDetail)">RESCHEDULE</button>

                    <button type="button" class="btn btn-rtn text-uppercase right-align btn-detail btn-cancel"
                        (click)="cancel()">CANCEL</button>
                </p>
            </div>
        </div>
        <div class="col col-sm-3" *ngIf="!arrayModule[2]"></div>
        <div class="col-12 col-lg-7 notif-div" [class.display-color]="!arrayModule[2]">
            
            <form method="POST" (ngSubmit)="saveNotificationSetting()" *ngIf="arrayModule[1]">
                <div class="notification">
                    <h4 class="title">NOTIFICATIONS</h4>
                    <hr>
                    <h5><strong>ALERTS</strong>
                        <a role="button" (click)="toggleEvent('collapseAlert')" data-toggle="collapse" aria-hidden="true">
                            <i class="more-less fas fa-minus collapseAlert"></i>
                        </a>
                    </h5>
                    <div id="collapseAlert" class="collapse show">
                        <div class="row">
                            <div class="form-check pl-0 d-flex">
                                <div class="input-rtn">
                                    <input class="checkbox check-single" type="checkbox" value="1" id="alertNotif"  name="alertNotif" (change)="alertNotifChange()">
                                    <label class="selectorExtreme" for="alertNotif"></label>
                                </div>
                                <label class="form-check-label ml-2 pt-1" for="alertNotif">Yes, I'd like to receive wait-time and scheduling alerts</label>
                            </div>
                            
                        </div>
                    </div>
                    <hr>
                    <h5><strong>REMINDER</strong>
                        <a role="button" (click)="toggleEvent('collapseReminder')" data-toggle="collapse" aria-hidden="true">
                            <i class="more-less fas fa-minus collapseReminder"></i>
                        </a>
                    </h5>
                    <div id="collapseReminder" class="collapse show">
                        <div class="row">
                            <div class="form-check pl-0 d-flex">
                                <div class="input-rtn">
                                    <input class="checkbox check-single" type="checkbox" value="1" id="reminderNotif" name="reminderNotif" (change)="reminderChange()">
                                    <label class="selectorExtreme" for="alertNotif"></label>
                                </div>
                                <label class="form-check-label ml-2 pt-1" for="alertNotif">Yes, send me a reminder before my appointment</label>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="input-group select form-group reminder-day">
                                <select class="form-control" id="remind" (change)="removeMessage()"
                                    [disabled]="!isReminder">
                                    <option value="1">One day</option>
                                    <option value="2">Two days</option>
                                    <option value="3">Three days</option>
                                    <option value="5">Five days</option>
                                    <option value="7">One Week</option>
                                </select>
                                <div class="input-group-select-icon">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row-notif">
                        
                            <div class="row margin-left-0 email-sms">
                                <div class="col-1 col-sm-1 col-md-1 input-rtn pull-left checkbox-style">
                                    <input class="checkbox check-single" type="checkbox" value="1" id="emailNotif"
                                        name="emailNotif" (change)="emailNotifChange()">
                                    <label class="selectorExtreme margin-top-5" for="emailNotif"></label>
                                </div>
                                <div class="px-0 col-10 col-sm-11 col-md-11 no-padding-left-right block-input-style">
                                    <div class="col-10 col-sm-2 col-md-2 no-padding-left-right pt-1">Email:</div>
                                    <div class="col-11 col-sm-10 col-md-10 no-padding-left-right email-div-large">
                                        <input type="text" class="form-control email-notif" id="email-notif-lg"
                                            maxlength="254" [class.input-error]="errorEmailMessage"
                                            (change)="removeMessage()" [readonly]="true">
                                        <div class="col-xs-12 col-sm-12 col-md-12 no-padding-left-right message-error">
                                            {{ errorEmailMessage }}</div>
                                    </div>
                                </div>
                                <div class="email-div-small">
                                    <input type="text" class="form-control email-notif" id="email-notif-sm" maxlength="254"
                                        [class.input-error]="errorEmailMessage" (change)="removeMessage()"
                                        [readonly]="true">
                                    <div class="col-xs-12 col-sm-12 col-md-12 no-padding-left-right message-error">
                                        {{ errorEmailMessage }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row-notif">
                            <div class="row margin-left-0 email-sms">
                            
                                <div class="col-1 col-sm-1 col-md-1 input-rtn pull-left checkbox-style">
                                    <input class="checkbox check-single" type="checkbox" value="1" id="SMSNotif"
                                        name="SMSNotif" (change)="SMSNotifChange()">
                                    <label class="selectorExtreme margin-top-5" for="SMSNotif"></label>
                                </div>
                                <div class="col-10 col-md-11 px-0 block-input-style">
                                    <div class="col-12 col-sm-2 col-md-2 px-0 pt-1">SMS:</div>
                                    <div class="col-12 col-sm-10 col-md-10 row px-0 sms-div-large">
                                        <div class="col-sm-10 col-md-10 px-0">
                                            <rtn-mobile-number class="font-text" id="phone_mobile" [formInput]="mobile"
                                                [error]="errorMobileMessage" (removeError)="removeMessage()"
                                                [isDisabled]="true"></rtn-mobile-number>
                                        </div>
                                        <div class="col-sm-2 col-md-2 px-0" *ngIf="showVerified">
                                            <i *ngIf="isVerified" class="fa fa-check phone-verified" aria-hidden="true"
                                                title="Confirmed"></i>
                                            <i *ngIf="!isVerified" class="fa fa-times phone-unverified" aria-hidden="true"
                                                title="Not confirmed yet"></i>
                                        </div>
                                        <div class="col-sm-12 col-md-12 px-0 message-error">{{ errorMobileMessage }}</div>
                                    </div>
                                </div>
                                <div class="sms-div-small">
                                    <div class="col-10 px-0">
                                        <rtn-mobile-number class="font-text" id="phone_mobile" [formInput]="mobile"
                                            [error]="errorMobileMessage" (removeError)="removeMessage()"
                                            [isDisabled]="true"></rtn-mobile-number>
                                    </div>
                                    <div class="col-2" *ngIf="showVerified">
                                        <i *ngIf="isVerified" class="fa fa-check phone-verified" aria-hidden="true"
                                            title="Confirmed"></i>
                                        <i *ngIf="!isVerified" class="fa fa-times phone-unverified" aria-hidden="true"
                                            title="Not confirmed yet"></i>
                                    </div>
                                    <div class="col-12 px-0 message-error">{{ errorMobileMessage }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- alt mobile number -->
                        <div class="row-notif">
                            <div class="row margin-left-0 email-sms">
                                
                                <div class="col-xs-2 col-sm-1 col-md-1 input-rtn pull-left checkbox-style spaceDIv">
                                    &nbsp;
                                </div>
                                <div class="col-sm-11 col-md-11 px-0 block-input-style">
                                    <div class="col-sm-2 col-md-2 px-0 padding-top-5 spaceDIv">&nbsp;</div>
                                    <div class="col-sm-10 col-md-10 row px-0 sms-div-large">
                                        <div class="col-sm-10 col-md-10 px-0">
                                            <rtn-mobile-number class="font-text" id="alt_phone_mobile" [formInput]="altmobile"
                                                [error]="errorAltMobileMessage" (removeError)="removeMessage()"
                                                [isDisabled]="!isSMS" (keyup)="onKeyUp($event, 'alt_phonemobile')"></rtn-mobile-number>
                                        </div>
                                        <div class="col-sm-2 col-md-2 px-0" *ngIf="showAltVerified">
                                            <i *ngIf="isAltVerified" class="fa fa-check phone-verified" aria-hidden="true"
                                                title="Confirmed"></i>
                                            <i *ngIf="!isAltVerified" class="fa fa-times phone-unverified" aria-hidden="true"
                                                title="Not confirmed yet"></i>
                                        </div>
                                        <div class="col-sm-12 col-md-12 px-0 message-error">{{ errorAltMobileMessage }}</div>
                                    </div>
                                </div>
                                <div class="sms-div-small">
                                    <div class="col-10 px-0">
                                        <rtn-mobile-number class="font-text" id="alt_phone_mobile" [formInput]="altmobile"
                                            [error]="errorAltMobileMessage" (removeError)="removeMessage()"
                                            [isDisabled]="!isSMS" (keyup)="onKeyUp($event, 'alt_phonemobile')"></rtn-mobile-number>
                                    </div>
                                    <div class="col-2" *ngIf="showAltVerified">
                                        <i *ngIf="isAltVerified" class="fa fa-check phone-verified" aria-hidden="true"
                                            title="Confirmed"></i>
                                        <i *ngIf="!isAltVerified" class="fa fa-times phone-unverified" aria-hidden="true"
                                            title="Not confirmed yet"></i>
                                    </div>
                                    <div class="col-12 px-0 message-error">{{ errorAltMobileMessage }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-sm-4 px-0">
                            <button type="submit" [attr.disabled]=" arrayModule[1] ? null : true"
                                class="btn btn-rtn text-uppercase btn-margin btn-detail-appointment">SAVE</button>
                        </div>
                        <div class="col-sm-8 px-0 message">
                            <span *ngIf="messageResponseSuccess"
                                class="message-success">{{messageResponseSuccess}}</span>
                            <span class="message-error">{{ errorMessage }}</span>
                        </div>
                    </div>
                </div>
            </form>
            <hr>
            <div class="waitlistsection" *ngIf="allow_to_addwaitlist">
                <h5>Waitlist</h5>
                <p> Try our waitlist feature-set your preferred dates and times and we'll notify you when a matching slot opens up. Book it instantly for your convenience</p>
                <button class="btn btn-rtn text-uppercase btn-detail" type="button" (click)="addwaitlist(waitingTimeDirective.appointmentId)">Add / Edit Waitlist</button>          
            </div>
            <div class="col-12 copay">
                <span *ngIf="tenantname.includes('northwell') || tenantname.includes('odstc') || tenantname.includes('rtnuat') || tenantname.includes('rtntest')" >
                    <img src="assets/img/northwell_health.jpg" class="w-100" alt="Logo">
                </span>
                <!--<h4 class="title">COPAYS</h4>
                <hr>
                <h5>
                    <strong>IN PLAN NETWORK</strong>
                    <a role="button" (click)="toggleEvent('collapseInPlan')" data-toggle="collapse" aria-hidden="true">
                        <i class="more-less fas fa-minus collapseInPlan"></i>
                    </a>
                </h5>
                <div id="collapseInPlan" class="collapse show">
                    <div class="row margin-left-0 ml-4">
                        Copayment:
                    </div>
                </div>
                <hr>
                <h5>
                    <strong>OUT PLAN NETWORK</strong>
                    <a role="button" (click)="toggleEvent('collapseOutPlan')" data-toggle="collapse" aria-hidden="true">
                        <i class="more-less fas fa-minus collapseOutPlan"></i>
                    </a>
                </h5>
                <div id="collapseOutPlan" class="collapse show">
                    <div class="row margin-left-0 ml-4">
                        Copayment:
                    </div>
                </div>-->
            </div>
            
        </div>
        <!-- <div class="col col-sm-2" *ngIf="!arrayModule[2]"></div> -->
    </div>



</modal>

<rtn-patient-reschedule></rtn-patient-reschedule>