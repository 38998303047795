import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {RestService} from '@app/core/rest.service';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {AlertService} from '@app/shared/alert';
import {ProviderDetail} from '@app/shared/models/providerDetail';
import {Router} from '@angular/router';
import {Select2Directive} from '@app/shared/form/select2.directive';
import {Subscription, from} from 'rxjs';
import {OpeningHourComponent} from '@app/shared/opening-hour/opening-hour.component';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Utils} from '@app/shared/utils';
import {ProvidersService} from '@app/providers/providers.service';
import {Select2LocationDirective} from '@app/shared/form/select2-location.directive';
import {AdminRestService} from '@app/admin/admin.service';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss'],
  providers: [RestService],
})
export class AddProviderComponent implements OnInit, OnDestroy {
  @ViewChild(Select2Directive) specialtiesSelector: Select2Directive;
  @ViewChild(OpeningHourComponent) openingHourDirective: OpeningHourComponent;
  @ViewChild(Select2LocationDirective) locationSelector: Select2LocationDirective;

  providerDetail: ProviderDetail = new ProviderDetail();
  responseError: ProviderDetail = new ProviderDetail();
  listTime: any[] = new Array();
  settingFields: any = {};
  selectedSpecialties: any;
  managingId: any;
  practiceList: any = [];

  addNewProviderSubscriber: Subscription;
  onGetSpecialtiesListener: Subscription;
  loadPracticeListSubscriber: Subscription;
  butDisabled = true;
  arrlocations:any=[];
  fetchAllLocationSubscriber: Subscription;

  constructor(
    public _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _alertService: AlertService,
    private _restservice: ProvidersService,
    private _router: Router,
    // private _routeParams: RouteParams,
    private _utils: Utils,
    private loadingIndicatorService: LoadingIndicatorService,
    private adminRestService : AdminRestService, 
  ) {
    this.settingFields = {
      state_default: '',
    };
  }

  ngOnInit() {
    if (this._utils.accessAddProvider()) {
      this._systemService.getStates();
      this._systemService.getSpecialties();
      this.fetchAllLocation();

      this.locationSelector.clearData();
      this.locationSelector.resetSelect2(jQuery('#select2location'));

      this.onGetSpecialtiesListener = this._systemEvent.specialty.subscribe((done: boolean) => {
        if (done) {
          if (this.selectedSpecialties) {
            this.specialtiesSelector.updateData(this.selectedSpecialties);
          }
        }
      });

      /* this._systemService.getInsuranceList();

      this.getInsurancesSubscriber = this._systemEvent.insurances.subscribe((done: boolean) => {
          if (done) {
              if (this.insuranceList) {
                  this.insurancesSelector.updateData(this.insuranceList);
              }
          }
      }); */
      // this.managingId = parseInt(this._routeParams.get('id'));
      if (this.loadPracticeListSubscriber) {
        this.loadPracticeListSubscriber.unsubscribe();
      }
      this.loadPracticeListSubscriber = this._restservice.getPracticeList().subscribe(
        (success: any) => {
          this.practiceList = success.practices;
          // Set default value for office
          this.providerDetail.office_name = localStorage.getItem('user_practice');
        }
      );

      /*this.providerDetail.office_address.push({
        'address' : '',
        'address2' : '',
        'city' : '',
        'state_code' : '',
        'zip' : ''
      });*/
    }
  }

  ngOnDestroy() {
    if (this.addNewProviderSubscriber) {
      this.addNewProviderSubscriber.unsubscribe();
    }
    if (this.onGetSpecialtiesListener) {
      this.onGetSpecialtiesListener.unsubscribe();
    }
    /* if (this.getInsurancesSubscriber) {
        this.getInsurancesSubscriber.unsubscribe();
    } */
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
  }

  addProviderForm(formVals: any) {
    this.loadingIndicatorService.start();
    const value = Object.assign({}, formVals);
    const list_opening_hours = this.openingHourDirective.getData();
    // let billing_info = this.formatDataBillingInfo(value);
    value.opening_hours = list_opening_hours;
    // value.billing = billing_info;
    value.specialties = this.specialtiesSelector.getData();
    value.office_name = $('select[name="office_name"]').val();
    // value.insurances = this.insurancesSelector.getData();
    // Get the checked box index before sending out save request
    const currentCheckedBoxIndex = this.openingHourDirective.getCurrentCheckedBoxIndex();
    if (this.addNewProviderSubscriber) {
      this.addNewProviderSubscriber.unsubscribe();
    }
    value.managing_id = this.managingId;
    value.location_id = this.locationSelector.getData();

    this.addNewProviderSubscriber = this._restservice.addNewProvider(value).subscribe(
      (success: any) => {
        this.loadingIndicatorService.stop();
        this._alertService.onClose = () => {
          this._router.navigate(['/provider', 'list']);
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = success.error_messages;
        this._alertService.message = '';
        this._alertService.emitter('open');
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
        const res = error.error;
        this.responseError = res.error_fields;
        let messagePos1 = '';
        let messagePos2 = '';
        let messagePos3 = '';
        this.responseError = res.error_fields;
        if ((<any>this.responseError)['opening_hours.0.days']
          && (<any>this.responseError)['opening_hours.0.days'].length !== 0) {
          messagePos1 = (<any>this.responseError)['opening_hours.0.days'];
        } else if ((<any>this.responseError)['opening_hours.0.end_time']
          && (<any>this.responseError)['opening_hours.0.end_time'].length !== 0) {
          messagePos1 = (<any>this.responseError)['opening_hours.0.end_time'];
        }
        if ((<any>this.responseError)['opening_hours.1.days']
          && (<any>this.responseError)['opening_hours.1.days'].length !== 0) {
          messagePos2 = (<any>this.responseError)['opening_hours.1.days'];
        } else if ((<any>this.responseError)['opening_hours.1.end_time']
          && (<any>this.responseError)['opening_hours.1.end_time'].length !== 0) {
          messagePos2 = (<any>this.responseError)['opening_hours.1.end_time'];
        }
        if ((<any>this.responseError)['opening_hours.2.days']
          && (<any>this.responseError)['opening_hours.2.days'].length !== 0) {
          messagePos3 = (<any>this.responseError)['opening_hours.2.days'];
        } else if ((<any>this.responseError)['opening_hours.2.end_time']
          && (<any>this.responseError)['opening_hours.2.end_time'].length !== 0) {
          messagePos3 = (<any>this.responseError)['opening_hours.2.end_time'];
        }

        const showError = [{index: 0, message: messagePos1}, {index: 1, message: messagePos2}, {
          index: 2,
          message: messagePos3
        }];
        this.openingHourDirective.injectError(currentCheckedBoxIndex, showError);

        const data: any = [];
      }
    );
  }

  /* formatDataBillingInfo(data: any) {
    return {
        'first_name': data.billing_first_name,
        'last_name': data.billing_last_name,
        'email': data.billing_email,
        'phone_mobile': data.billing_phone_mobile,
        'address': data.billing_address,
        'address2': data.billing_address2,
        'city': data.billing_city,
        'state_code': data.billing_state_code,
        'zip': data.billing_zip
    };
  } */

  onKeyUp(error: string) {
    switch (error) {
      case 'email':
        this.responseError['email'] = null;
        break;
      case 'phone_mobile':
        this.responseError['phone_mobile'] = null;
        break;
      case 'password':
        this.responseError['password'] = null;
        break;
      case 'password_confirmation':
        this.responseError['password_confirmation'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'first_name':
        this.responseError['first_name'] = null;
        break;
      case 'last_name':
        this.responseError['last_name'] = null;
        break;
      case 'credentials':
        this.responseError['credentials'] = null;
        break;
      case 'npi':
        this.responseError['npi'] = null;
        break;
      case 'office_name':
        this.responseError['office_name'] = null;
        break;
      case 'specialties':
        this.responseError['specialties'] = null;
        break;
      case 'website_url':
        this.responseError['website_url'] = null;
        break;
      case 'office_address':
        this.responseError['office_address'] = null;
        break;
      case 'office_address2':
        this.responseError['office_address2'] = null;
        break;
      case 'office_city':
        this.responseError['office_city'] = null;
        break;
      case 'office_state_code':
        this.responseError['office_state_code'] = null;
        break;
      case 'office_zip':
        this.responseError['office_zip'] = null;
        break;
      case 'location_id':
        this.responseError['location_id'] = null;
        break;    
      /* case 'insurances':
          this.responseError['insurances'] = null;
          break;
      case 'billing_first_name':
          (<any>this.responseError)['billing.first_name'] = null;
          break;
      case 'billing_last_name':
          (<any>this.responseError)['billing.last_name'] = null;
          break;
      case 'billing_email':
          (<any>this.responseError)['billing.email'] = null;
          break;
      case 'billing_phone_mobile':
          (<any>this.responseError)['billing.phone_mobile'] = null;
          break;
      case 'billing_address':
          (<any>this.responseError)['billing.address'] = null;
          break;
      case 'billing_address2':
          (<any>this.responseError)['billing.address2'] = null;
          break;
      case 'billing_city':
          (<any>this.responseError)['billing.city'] = null;
          break;
      case 'billing_state_code':
          (<any>this.responseError)['billing.state_code'] = null;
          break;
      case 'billing_zip':
          (<any>this.responseError)['billing.zip'] = null;
          break; */
    }
  }

  preventWhiteSpaceInput(event: any) {
    if (event.target.value.match(/\s/g)) {
      event.target.value = event.target.value.replace(/\s/g, '');
    }
  }
  fetchAllLocation(){
    const self = this;
        
    this.loadingIndicatorService.start();
    if (this.fetchAllLocationSubscriber) {
        this.fetchAllLocationSubscriber.unsubscribe();
    }
    this.fetchAllLocationSubscriber = this.adminRestService.fetchAllLocation().subscribe((success: any) => {
          this.loadingIndicatorService.stop();
          this.arrlocations = success.aptlocation;   
          //this.locationSelector.updateData(this.arrlocations);   
              
      }, (error: any) => {
          this.loadingIndicatorService.stop();         
    });
  }

}
