import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataLogsRoutingModule } from './data-logs-routing.module';
import { ChartWaitTimeComponent } from '@app/data-logs/providers-waittime/chart-wait-time.component';
import { ChartsModule, WavesModule } from 'angular-bootstrap-md';
import { ChartTestComponent } from '@app/data-logs/slot-duration/slot-duration.component';
import { AppointmentByDayComponent } from '@app/data-logs/appointment-waittime/appointment-by-day.component';
import { DataLogsComponent } from './data-logs.component';
import {SharedModule} from '@app/shared';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { AppointmentLogByDayComponent } from '@app/data-logs/appointment-info/appointment-log-by-day.component';
import { NotificationComponent } from './notification/notification.component';
import { WaittimeLogBySlotComponent } from './waittime-log-by-slot/waittime-log-by-slot.component';
import { PlanActualComparingComponent } from './plan-actual-comparing/plan-actual-comparing.component';
import { StatisticalAppointmentComponent } from './statistical-appointment/statistical-appointment.component';
import { OfficesWaittimeComponent } from './offices-waittime/offices-waittime.component';
import { WaittimelogByProvidersComponent } from './waittimelog-by-providers/waittimelog-by-providers.component';
import { AppointmentBySlotComponent } from './appointment-by-slot/appointment-by-slot.component';
import { DurationLogBySlotComponent } from './duration-log-by-slot/duration-log-by-slot.component';
import { AppointmentByDateComponent } from './appointment-by-date/appointment-by-date.component';
import { PatientWaitingRoomtimeComponent } from './patient-waiting-roomtime/patient-waiting-roomtime.component';
import { WaittimeOfficeComponent } from './waittime-office/waittime-office.component';
import { AppointmentStatisticsComponent } from './appointment-statistics/appointment-statistics.component';
import { ScheduleActivityComponent } from './schedule-activity/schedule-activity.component';
import { StatisticalTypeofappointmentComponent } from './statistical-typeofappointment/statistical-typeofappointment.component';
import { ScheduleArrivalCompareComponent } from './schedule-arrival-compare/schedule-arrival-compare.component';
import { CancelAptstatisticsComponent } from './cancel-aptstatistics/cancel-aptstatistics.component';
import { AppointmentByLabslotComponent } from './appointment-by-labslot/appointment-by-labslot.component';


@NgModule({
  imports: [
    CommonModule,
    DataLogsRoutingModule,
    ChartsModule,
    WavesModule.forRoot(),
    SharedModule,
    FormsModule,
  ],
  declarations: [ChartWaitTimeComponent, ChartTestComponent, AppointmentByDayComponent, DataLogsComponent, HeaderComponent, AppointmentLogByDayComponent, NotificationComponent, WaittimeLogBySlotComponent, PlanActualComparingComponent, StatisticalAppointmentComponent, OfficesWaittimeComponent, WaittimelogByProvidersComponent, AppointmentBySlotComponent, DurationLogBySlotComponent, AppointmentByDateComponent, PatientWaitingRoomtimeComponent, WaittimeOfficeComponent, AppointmentStatisticsComponent, ScheduleActivityComponent, StatisticalTypeofappointmentComponent, ScheduleArrivalCompareComponent, CancelAptstatisticsComponent, AppointmentByLabslotComponent]
})
export class DataLogsModule { }
