<modal #AppointmentBook_Modals class="appointment-new">
  <form #modalForm="ngForm" class="form">
    <div class="form-content">
      <div class="form-header">Book Appointment</div>
      <div class="form-info">
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3 form-input-label">Patient</div>
          <div  class="col-lg-9 col-md-9 col-xs-9 search px-0">
            {{patient_name}}
          </div>

        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3 form-input-label">Time</div>
          <div  class="col-lg-9 col-md-9 col-xs-9 search px-0">
           {{time}}
          </div>

        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3"></div>
          <div class="message-error">{{ requireMessage.patient_id }}</div>
        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3 form-input-label">Date</div>
          <div class="col-xs-9 col-sm-9 col-lg-9 col-md-9 no-padding-left date-select">
            <div class="input-group">
              <input type="text" #inputDateNewAppointment class="form-control calender-input bc-white"
                     [class.input-error]="requireMessage.date != null" [(ngModel)]="newAvailableSlotModel.date" disabled
                     [ngModelOptions]="{standalone: true}">
              <div class="input-group-append" #clickDateNewAppointment>
                <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3"></div>
          <div class="col-xs-9 col-sm-9 col-lg-9 col-md-9 message-error">{{ requireMessage.date }}</div>
        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3 form-input-label input-label-required">Slot</div>
          <div class="row col-xs-9 col-sm-9 col-lg-9 col-md-9 px-0">
            <div class="col-3 wrapper-slot" *ngFor="let slot of availableSlots">
              <div class="btn btn-default btn-slot" [class.selected-bc]="slot.isSelected"
                   (click)="selectSlot(slot.id)" title="{{slot.location_name}}">{{ getTime(slot.start_time) }}&nbsp;-&nbsp;{{ getTime(slot.end_time) }}</div>
            </div>
            <div *ngIf="availableSlots.length == 0">Please add Available Slot before booking an appointment.</div>
          </div>
        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3 form-input-label input-label-required">Apt. Type</div>
          <div class="col-xs-9 col-sm-9 col-lg-9 col-md-9 no-padding-left width-full">
            <div class="input-group select">              
              <select class="form-control textbox-edit-form width-full" [(ngModel)]="newAvailableSlotModel.event_reason_id" name="event_reason_id" ngControl="event_reason_id" [class.input-error]="requireMessage.event_reason_id">
                <option [value]="''" selected>Select Type</option>
                <option *ngFor="let typeItem of appointmentTypeList" [value]="typeItem.id">{{typeItem.label}}</option>
              </select>
              <div class="input-group-select-icon cursorPointer select-state">
                  <i class="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12">
          <div class="col-lg-3 col-md-3 col-xs-3 form-input-label">Apt. Reason</div>
          <div class="col-xs-9 col-sm-9 col-lg-9 col-md-9 no-padding-left date-select">
            <textarea id="appointment_reason" name="appointment_reason" [(ngModel)]="newAvailableSlotModel.appointment_reason" class="form-control"></textarea>
          </div>
        </div>
        <div class="row col-lg-12 col-md-12 col-xs-12 no-padding-left mb-2" *ngIf="scheduleId == 0">
          <rtn-patient-future-appointment [loadedpage]="loadedpage" [aptlists]="futureaptlists" [providerId]="0" [patientId]="0" (selecteRescheduleId)="selecteRescheduleId($event)" *ngIf="futureaptlists.length > 0"></rtn-patient-future-appointment>
        </div>
        
      </div>
      <div class="row" *ngIf="errorMessageBook">
        <div class="col-xs-3"></div>
        <div class="col-xs-9 no-padding-left-right">
          <div class="message-error">{{ errorMessageBook }}</div>
        </div>
      </div>
    </div>
    <div class="button">
      <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="dismiss()">Cancel
      </button>
      <button type="button" class="btn btn-rtn margin-left-10" (click)="processDeleteSlot()"
              *ngIf="selectedSlot !== 0">Delete Slot
      </button>
      <button type="submit" class="btn btn-rtn btn-90 float-right" (click)="checkLockedProvider(1)" autofocus
              [disabled]="selectedSlot === 0 || !patient_id || newAvailableSlotModel.event_reason_id == ''">Book
      </button>
    </div>
  </form>
</modal>
