import { AfterViewInit, Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy} from '@angular/core';

//import * as d3 from 'd3';

import { Subscription } from 'rxjs';
import { AlertService } from '@app/shared/alert';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { chartDate } from '@app/shared/models/chartDate';
import { PatientsService } from '@app/patients/patients.service';
import { AdminRestService } from '@app/admin/admin.service';
import { dataSearchChart } from '@app/shared/models/dataSearchChart';
import * as moment from 'moment';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Router } from '@angular/router';
import { Utils } from '@app/shared/utils';
import { SystemService, SystemData } from '@app/shared/system';

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";

declare let jQuery: any;

//declare let d3: any;

@Component({
  selector: 'app-waittimelog-by-providers',
  templateUrl: './waittimelog-by-providers.component.html',
  styleUrls: ['./waittimelog-by-providers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WaittimelogByProvidersComponent implements OnInit, AfterViewInit, OnDestroy {
  
  private chartdata:any = [];

  currentDate: any = moment();
  maxDate: any;

  data_chart: any;
  error: chartDate = new chartDate();
  public isDataAvailable = true;
  getWaittimeByProviderSubscriber: Subscription;
  
  practiceId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();
  arrayModule = new Array();
  practiceTypeSubscriber: Subscription;
  
  public practicesList: any = [];
  labelAwt: any = 'Average Wait Time';
  public messagePractice = '';
  public messageDate = '';
  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;

  private chartWidth: number;
  private chartHeight: number;
  private chartMargin = { top: 20, right: 40, bottom: 200, left: 60 };
  private chartX: any;
  private chartY: any;
  private chartSvg: any;
  private chartG: any;

  public curpage : number = 1;
  public noofdata : number = 0;
  public noofpage : number = 0;
  public noofpageArr:any = [];
  public resultMode:string = 'fullview';
  public isSubmitted : boolean = false;

  constructor(
    private _patientService: PatientsService,
    private _datalogsService: DataLogsService,
    private _adminService: AdminRestService,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
    public _router: Router,
    private _utils: Utils,
    public _systemService: SystemService,
    public _systemData: SystemData,
  ) { 
    this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');  

    let width = 1024;
    width = (window.innerWidth > width) ? (window.innerWidth - 600) : width;
    this.chartWidth = width - this.chartMargin.left - this.chartMargin.right;
    this.chartHeight= 500 - this.chartMargin.top - this.chartMargin.bottom;
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));    
    this.getPractices();
  }
  getPractices() {
    this._loadingIndicatorService.start();
    if (this.practiceTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.practiceTypeSubscriber = this._adminService.getPracticeList().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.practicesList = success.practices;
        }
        this._loadingIndicatorService.stop();
      });
  }
  
  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
      this.maxDate = moment().subtract(2, 'days').format('LL');
      this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    } else {
      this.maxDate = moment().subtract(1, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(_self.inputDateFrom.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
      }
    });
    jQuery(_self.inputDateFrom.nativeElement).datepicker('setDate', this.dataSearchChart.dateFrom);
    jQuery(_self.clickDateFrom.nativeElement).click(() => {
      jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
    });

    jQuery(_self.inputDateTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        _self.dataSearchChart.dateTo = jQuery(_self.inputDateTo.nativeElement).val();
      }
    });
    jQuery(_self.inputDateTo.nativeElement).datepicker('setDate', this.dataSearchChart.dateTo);
    jQuery(this.clickDateTo.nativeElement).click(() => {
      jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });

    jQuery('#showDataChart').on('click', function () {
      //_self.resetData();
      _self.updateData();
    });
    /*jQuery('#exportDataChart').on('click', function () {
      _self.exportdata();
    });*/
  }
  exportdata() {
    this.verifyDataSearch();
    const _self = this;
    const startTime = this.dataSearchChart.dateFrom;
    const endTime = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;

    if (this.validate()) {

      

      const token = localStorage.getItem('user_token');
      jQuery.fileDownload(_self._datalogsService.scheduleExportUrl, {
        httpMethod: 'POST',
        data: {
          'token': token,
          'practice': practiceID,
          'startTime': startTime,
          'endTime': endTime,
          'chartType': 1
        }
      }).done(function () {
        // TODO

        console.log('Done ....');
      })
        .fail(function () {
          _self._alertService.onClose = () => {
            location.reload();
          };         
        });
    }
  }
  validateDateFromLessDateTo(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    if (date1 > date2) {
      this.errorResponse.dateFrom = message;
      return false;
    } else {
      this.errorResponse.dateFrom = null;
      return true;
    }
  }

  validateDateMoreThan31(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 31) {
      this.errorResponse.dateTo = message;
      return false;
    } else {
      this.errorResponse.dateTo = null;
      return true;
    }

  }

  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.PracticeSelector.inputError = true;
      this.PracticeSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.PracticeSelector.inputError = false;
      this.PracticeSelector.changeBorderColorPractice();
      return true;
    }
  }

  validate() {
    const validatePractice = this.validatePractice('This field is required');
    const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
    const validateDateMoreThan31
      = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
    return validatePractice && validateDateFromLessDateTo && validateDateMoreThan31;

  }
  updateData() {

    this.verifyDataSearch();
    const dateFrom = this.dataSearchChart.dateFrom;
    const dateTo = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;
    const type = this.dataSearchChart.type;
    if (this.validate()) {
      this.getWTByProvider(dateFrom, dateTo, practiceID, type);
    } else {
      //this.resetData();      
      return;
    }
  }
  verifyDataSearch() {
    this.dataSearchChart.practice = this.PracticeSelector.getData();
    this.dataSearchChart.type = jQuery('#typeChart option:selected').val();
  }

  getWTByProvider(dateFrom: any, dateEnd: any, practiceID: any, type: any) {
    this.isSubmitted = true;

    this._loadingIndicatorService.start();
    
    this._datalogsService.getWaittimeByProvider(dateFrom, dateEnd, practiceID, type).subscribe(
      (success: any) => {
        this.chartdata = [];
        this.data_chart = success.data_chart;
        this._loadingIndicatorService.stop();
        let length = 0;
        if (this.data_chart.length !== 0) {
          length = this.data_chart.length;
          for (let index = 0; index < length; index++) {
            let firstname = this.data_chart[index].provider.first_name;
            let middleName  = this.data_chart[index].provider.middle_name;
            middleName      = (middleName !== null) ? middleName : '';
            let lastName  = this.data_chart[index].provider.last_name;
            // push to array
            let datum:any = {};
            datum.label = firstname + ' ' + middleName + ' ' + lastName;
            datum.value = this.data_chart[index].aptInfo.avgWt;
            datum.noofApt = this.data_chart[index].aptInfo.noOfApt;    
            datum.noOfSkippedApt = this.data_chart[index].aptInfo.noOfSkippedApt;        
            this.chartdata.push(datum);
          }
        }
        this.noofdata = this.chartdata.length;
        this.curpage = 1;
        //this.initBarchart();       
        this.drawMultiBarChart();
      },
      (error: any) => {
        // todo
        this._loadingIndicatorService.stop();
      }
    );
  }  

ngOnDestroy(): void {

}
printChart(){
  window.print();
}
drawMultiBarChart(){

  let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.label.length}));
  let barWidth:any = maxLength * 1.5;

  let numBars:any = Math.round(this.chartWidth / barWidth);

  this.noofpage = Math.ceil(this.noofdata / numBars);
  this.noofpageArr = Array(this.noofpage).fill(0).map((x,i)=>i);
    
  // Remove tooltip box  
  const elements = document.getElementsByClassName("myTooltip");
  while (elements.length > 0) elements[0].remove();

  let that = this;
  setTimeout(function(){
    for (var i=0; i < that.noofpage; i++) {
      var curpos = i + 1;
      that.printBarChart('barchart_' + i, curpos, numBars);
    }
  }, 1000);

}
printBarChart(eleId:any, curpage:number, numBars:number){
  
  if (document.getElementById(eleId) != undefined && document.getElementById(eleId)!= null) {
    document.getElementById(eleId).innerHTML = "";
  }

  if (this.noofdata == 0){
    return false;
  }

  let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.label.length}));
  
  var tooltip = d3.select("body")
  .append("div")
  .attr('class', 'myTooltip')
  .style("position", "absolute")
  .style("z-index", "10")
  .style("visibility", "hidden")
  .text("");
  
  let chartSvgP = d3.select("#" + eleId).append('svg');
  chartSvgP.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
  chartSvgP.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);   

  let chartGp = chartSvgP.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
  let startpos  = (curpage - 1) *  numBars;
  let endpos    = (curpage * numBars);

  endpos = (endpos > this.noofdata) ? this.noofdata : endpos;

  let tempchartdata:any = this.chartdata.slice(startpos, endpos);  

  let chartXp:any = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).padding(0.1);  

  let chartYp:any = d3Scale.scaleLinear().rangeRound([this.chartHeight, 0]);

  chartXp.domain(tempchartdata.map((d:any) => d.label));

  chartYp.domain([0, d3Array.max(tempchartdata, (d:any) => d.value)]);

  chartGp.append("g").attr("class", "axis axis - x").attr("transform", "translate(0," + this.chartHeight + ")")
    .call(d3Axis.axisBottom(chartXp))
    .selectAll("text")
    .attr("transform", "translate(-10,10)rotate(-65)")
    .style("text-anchor", "end")
    .style("font-size", 12)
    .style("fill", "#474847");

  chartGp.append("g").attr("class", "axis axis - y")
    .call(d3Axis.axisLeft(chartYp).tickFormat(function(d:any) { return d + " min"; }))
    .append("text")
    .attr("class", "axis-title")
    .attr("transform", "rotate(-90)")
    .attr("y", -40)
    .attr("x", -200)
    .attr("dy", "0.71em")
    .text("Wait Time");

  let cWidth = this.chartWidth;
  let cHeight = this.chartHeight;

  chartGp.selectAll(".barchart").data(tempchartdata).enter().append("rect").attr("class", "barchart")
  .attr("x", (d:any) => chartXp(d.label))
  .attr("y", (d:any) => chartYp(d.value))
  .attr("width", chartXp.bandwidth()).attr("height", (d:any) => this.chartHeight - chartYp(d.value)).on("mouseover", function(d:any){
    var newHtml = '';
    newHtml += '<p><span class="lbltext">'+d['label']+'</span></p>';
    newHtml += '<p><span class="lbltext1">Appointments </span> <span>: ' + d['noofApt'] + '</span></p>';
    newHtml += '<p><span class="lbltext1">Skipped Apts. </span> <span>: ' + d['noOfSkippedApt'] + '</span></p>';
    newHtml += '<p><span class="lbltext1">Average Waittime </span><span>: ' + d['value'] + '</span></p>';
    tooltip.html(newHtml);
    return tooltip.style("visibility", "visible");
  }).on("mousemove", function(){
    var pageX = d3['event']['pageX'];
    var pageY = d3['event']['pageY'];    

    if (cWidth - (pageX + 200) < 20){ 
      pageX = pageX - 200 - 20;
    }
    
    if (cHeight - (pageY + 200) < 20){ 
      pageY = pageY - 200 - 20;
    }

    return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
  }).on("mouseout", function(){   
    return tooltip.style("visibility", "hidden");
  });
} 
}
