<modal #NotificationMass_Modals_Patient>
  <form class="notification-mass">
      <div class="row noMargin">
          <div class="col-sm-4 bg">
              <div class="header">Send to
                  <div class="arrow-left"></div>
              </div>
              <img src="/assets/img/mass-noti.png">
          </div>
          <div class="col-sm-8 inputForm">
            <div class="form-group">
                <label >Select Patient(s):</label>
                <select #Select2AjaxpatientDirective="select2-ajaxpatient" id="select2Ajaxpatient"  select2-ajaxpatient class="form-control"  [maximumSelectionLength]="0" [tags]="false">
                    <option *ngFor="let patient of patients" [id]="'patientList' + patient.id" [value]="patient.id">{{ patient.full_name }}</option>
                </select>
                <div class="message-error">{{ errorResponse }}</div>
            </div>
              <div class="content">Content
                  <textarea name="something2" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" [class.input-error]="messageRequire != null" class="input form-control paddingInput" rows="8" [ngModel]="massNotiModel.content" (ngModelChange)="massNotiModel.content = $event" maxlength="255"></textarea>
              </div>
              <div>
                  <div class="col-sm-9 message-error no-padding-left">{{ messageRequire }}</div>
                  <div class="col-sm-3 text-left">{{ massNotiModel.content.length }}/255</div>
              </div>
              <div class="textRight">
                  <button type="button" class="btn btn-rtn-inv btn-90 text-uppercase" (click)="dismiss()" data-dismiss="modal">CANCEL</button>
                  <button type="button" class="btn btn-rtn btn-90 text-uppercase" (click)="massNotiClicked()">SEND</button>
              </div>
          </div>
      </div>
  </form>
</modal>