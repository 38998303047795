import { BillingInfo } from './billingInfo';

export class SuperProviderDetail {
    account_name: string;
    office_name: string;
    email: string;
    phone_mobile: string;
    password: string;
    password_confirmation: string;
    providers: any = [];
    billing: BillingInfo = new BillingInfo();
}
