import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProvidersComponent} from '@app/providers/providers.component';
import {ListProvidersComponent} from '@app/providers/list-providers/list-providers.component';
import {extract} from '@app/core';
import {AddProviderComponent} from '@app/providers/add-provider/add-provider.component';
import {PracticeComponent} from '@app/providers/practice/practice.component';
import {Role} from '@app/core/role';
import {AuthGuard} from '@app/core/auth.guard';
import {ListPatientsComponent} from '@app/providers/list-patients/list-patients.component';
import {PracticeEditComponent} from '@app/providers/practice-edit/practice-edit.component';
import {ListManagingUserComponent} from '@app/providers/list-managing-user/list-managing-user.component';
import {AddManagingUserComponent} from '@app/providers/add-managing-user/add-managing-user.component';
import {EditUserComponent} from '@app/providers/edit-user/edit-user.component';
import {ProviderProfileComponent} from '@app/providers/provider-profile/provider-profile.component';
import {ManageProviderComponent} from '@app/providers/manage-provider/manage-provider.component';
import {MappingProviderComponent} from '@app/providers/mapping-provider/mapping-provider.component';
import {AddPracticeProviderComponent} from '@app/providers/add-practice-provider/add-practice-provider.component';
import {EditComponent} from '@app/providers/edit/edit.component';
import {EditManagingUserComponent} from '@app/providers/edit-managing-user/edit-managing-user.component';
import {ProviderChangePasswordComponent} from '@app/providers/change-password/change-password.component';
import { ViewAppointmentsComponent } from '@app/providers/view-appointments/view-appointments.component';
import { ListApttypesComponent } from '@app/providers/list-apttypes/list-apttypes.component';

const routes: Routes = [{
  path: '',
  component: ProvidersComponent,
  children: [    
    {
      path: 'provider/list',
      component: ListProvidersComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
    },
    {
      path: 'provider/add',
      component: AddProviderComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
    },
    {
      path: 'provider/practice',
      component: PracticeComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/patient',
      component: ListPatientsComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER, Role.SUPER_USER, Role.MANAGING_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/practice/edit',
      component: PracticeEditComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/user',
      component: ListManagingUserComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/user/managing/add',
      component: AddManagingUserComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
    },
    {
      path: 'provider/user/edit',
      component: EditUserComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER, Role.MANAGING_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/profile',
      component: ProviderProfileComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/manage/:id',
      component: ManageProviderComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/manage',
      component: ManageProviderComponent,
      data: {title: extract('RTN'), roles: [Role.MANAGING_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/mapping/:id',
      component: MappingProviderComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/practice/add',
      component: AddPracticeProviderComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/edit',
      component: EditComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/user/managing/edit/:id',
      component: EditManagingUserComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/change-password',
      component: ProviderChangePasswordComponent,
      data: {title: extract('Change Password'), roles: [Role.PROVIDER, Role.SUPER_USER, Role.MANAGING_USER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/:id/view-appointments',
      component: ViewAppointmentsComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'apttypes/list',
      component: ListApttypesComponent,
      data: {title: extract('RTN'), roles: [Role.SUPER_USER]},
    },    
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProvidersRoutingModule {
}
