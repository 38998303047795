import {Injectable} from '@angular/core';
import {
  Http,
  Headers as AngularHeaders,
  Request,
  RequestOptions,
  RequestMethod as RequestMethods,
  Response,
  URLSearchParams
} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';



@Injectable()
export class RESTClient {
  public constructor(protected http: HttpClient) {}

  protected getBaseUrl(): string {
    return null;
  }

  protected getDefaultHeaders(): Object {
    return null;
  }

  /**
   * Request Interceptor
   *
   * @method requestInterceptor
   * @param {Request} req - request object
   */
  protected requestInterceptor(req: Request) {
    //
  }

  /**
   * Response Interceptor
   *
   * @method responseInterceptor
   * @param {Response} res - response object
   * @returns {Response} res - transformed response object
   */
  protected responseInterceptor(res: Observable<any>): Observable<any> {
    return res;
  }
}
/**
 * Set the base URL of REST resource
 * @param {String} url - base URL
 */
export function RESTBaseUrl(url: string) {
  return function < TFunction extends Function > (Target: TFunction): TFunction {
    Target.prototype.getBaseUrl = function() {
      return String(url).replace(/\/+$/g, '') + '/';
    };

    return Target;
  };
}
/**
 * Set default headers for every method of the RESTClient
 * @param {Object} headers - deafult headers in a key-value pair
 */
export function RESTDefaultHeaders(headers: any) {
  return function < TFunction extends Function > (Target: TFunction): TFunction {
    Target.prototype.getDefaultHeaders = function() {
      return headers;
    };

    return Target;
  };
}

function paramBuilder(paramName: string) {
  return function(key: string) {
    return function(target: any, propertyKey: any, parameterIndex: number) {
      const metadataKey = `${propertyKey}_${paramName}_parameters`;
      const paramObj: any = {
        parameterIndex: parameterIndex,
        key: key
      };
      if (Array.isArray(target[metadataKey])) {
        target[metadataKey].push(paramObj);
      } else {
        target[metadataKey] = [paramObj];
      }
    };
  };
}

/**
 * Path variable of a method's url, type: string
 * @param {string} key - path key to bind value
 */
export let RESTPath = paramBuilder('Path');
/**
 * Query value of a method's url, type: string
 * @param {string} key - query key to bind value
 */
export let RESTQuery = paramBuilder('Query');
/**
 * Body of a REST method, type: key-value pair object
 * Only one body per method!
 */
export let RESTBody = paramBuilder('Body');
/**
 * Custom header of a REST method, type: string
 * @param {string} key - header key to bind value
 */
export let RESTHeader = paramBuilder('Header');

/**
 * Defines the media type(s) that the methods can produce
 * @param RESTMediaType producesDef - mediaType to be parsed
 */
export function RESTProduces(producesDef: RESTMediaType) {
  return function(target: RESTClient, propertyKey: string, descriptor: any) {
    descriptor.isJSON = (producesDef === RESTMediaType.JSON);
    return descriptor;
  };
}

/**
 * Supported @Produces media types
 */
export enum RESTMediaType {
  JSON
}

/**
 * Set custom headers for a REST method
 * @param {Object} headersDef - custom headers in a key-value pair
 */
export function RESTCustomHeaders(headersDef: any) {
  return function(target: any, propertyKey: string, descriptor: any) {
    descriptor.headers = headersDef;
    return descriptor;
  };
}

function methodBuilder(method: number) {
  return function(url: string) {
    return function(target: any, propertyKey: string, descriptor: any) {

      const pPath = target[`${propertyKey}_Path_parameters`];
      const pQuery = target[`${propertyKey}_Query_parameters`];
      const pBody = target[`${propertyKey}_Body_parameters`];
      const pHeader = target[`${propertyKey}_Header_parameters`];
      const fnCallback = (typeof target[propertyKey] === 'function') ? target[propertyKey] : Function;

      descriptor.value = function(...args: any[]) {
        // Body
        let body: any;
        if (pBody) {
          body = JSON.stringify(args[pBody[0].parameterIndex]);
        }

        // Path
        let replaceUrl = url;
        if (pPath) {
          for (const k of pPath) {
            replaceUrl = replaceUrl.replace('{' + pPath[k].key + '}', args[pPath[k].parameterIndex]);
          }
        }

        // Query
        const search = new URLSearchParams();
        if (pQuery) {
          pQuery
            .filter((p: any) => args[p.parameterIndex]) // filter out optional parameters
            .forEach((p: any) => {
              const key = p.key;
              let value = args[p.parameterIndex];
              // if the value is a instance of Object, we stringify it
              if (value instanceof Object) {
                value = JSON.stringify(value);
              }
              search.set(encodeURIComponent(key), encodeURIComponent(value));
            });
        }
        search.set('___rtnRand', String(Math.random() * (new Date()).getTime()));

        // Headers
        const headers = new AngularHeaders(this.getDefaultHeaders());
        for (const k of descriptor.headers) {
          headers.append(k, descriptor.headers[k]);
        }
        if (pHeader) {
          for (const k of pHeader) {
            headers.append(pHeader[k].key, args[pHeader[k].parameterIndex]);
          }
        }
        // Custom header
        fnCallback.call(this, headers);

        // Request options
        const req = new Request(new RequestOptions({
          method,
          url: this.getBaseUrl() + String(replaceUrl).replace(/^[\/]+/g, ''),
          headers,
          body,
          search
        }));

        // intercept the request
        this.requestInterceptor(req);

        // make the request and store the observable for later transformation
        // let observable: Observable < Response > = this.http.request(req);
        let observable: Observable < Response > = this.httpClient.request(req);

        // transform the obserable in accordance to the @Produces decorator
        if (descriptor.isJSON) {
          observable = observable.pipe(map((res: Response) => res.json()));
        }

        // intercept the response
        return observable.pipe(map(this.responseInterceptor));
      };

      return descriptor;
    };
  };
}

/**
 * GET method
 * @param {string} url - resource url of the method
 */
export let RESTGET = methodBuilder(RequestMethods.Get);
/**
 * POST method
 * @param {string} url - resource url of the method
 */
export let RESTPOST = methodBuilder(RequestMethods.Post);
/**
 * PUT method
 * @param {string} url - resource url of the method
 */
export let RESTPUT = methodBuilder(RequestMethods.Put);
/**
 * DELETE method
 * @param {string} url - resource url of the method
 */
export let RESTDELETE = methodBuilder(RequestMethods.Delete);
/**
 * HEAD method
 * @param {string} url - resource url of the method
 */
export let RESTHEAD = methodBuilder(RequestMethods.Head);
/**
 * OPTIONS method
 * @param {string} url - resource url of the method
 */
export let RESTOPTIONS = methodBuilder(RequestMethods.Options);
/**
 * PATCH method
 * @param {string} url - resource url of the method
 */
export let RESTPATCH = methodBuilder(RequestMethods.Patch);
