import {Component, OnInit, ViewChild} from '@angular/core';
import {SystemData, SystemService, SystemInterval} from '@app/shared/system';
import {Router} from '@angular/router';
import {LogoutModalsComponent} from '@app/shared/general/logout/modals.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
// declare arrayActiveModule
  arrayModule = new Array();
  @ViewChild(LogoutModalsComponent) logoutDirective: LogoutModalsComponent;

  constructor(
    public _systemData: SystemData,
    public _systemService: SystemService,
    private _systemInterval: SystemInterval,
    public _router: Router
  ) {
  }

  ngOnInit() {
    this._systemService.checkOnlineStatus();
    this._systemInterval.header_fetchOnlineMode.interval = setInterval(() => {
      this._systemService.checkOnlineStatus();
    }, this._systemInterval.header_fetchOnlineMode.duration);
    // get active module array
    this.arrayModule
      = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'),
      localStorage.getItem('user_token').substr(0, 32));
  }

  ngOnDestroy() {
    this._systemInterval.clearSystemInterval(this._systemInterval.header_fetchOnlineMode);
  }

  changePassword() {
    this._router.navigate(['/admin', 'change-password']);
  }

  onChangeDatalog() {
    window.location.reload();
  }

}
