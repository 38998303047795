import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';

// import {Body} from '@angular/http/src/body';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  fetchWaitTimeByProviderUrl = environment.serverUrl + '/provider/wait-time';
  getOtherSettingsUrl = environment.serverUrl + '/provider/get-other-settings';
  saveOtherSettingsUrl = environment.serverUrl + '/provider/save-other-settings';
  getNotificationGlobalSettingUrl = environment.serverUrl + '/patients/notification-settings';
  getBookAppointmentUrl = environment.serverUrl + '/appointment/book-appointment';
  bookAppointmentByPatientUrl = environment.serverUrl + '/appointment/book-appointment';
  checkInUrl = environment.serverUrl + '/appointment/check-in/';
  checkOutUrl = environment.serverUrl + '/appointment/check-out/';
  editApmtUrl = environment.serverUrl + '/appointment/update-slot/';
  cancelAppointmentUrl = environment.serverUrl + '/appointment/cancel-appointment/';
  noShowAppointmentUrl = environment.serverUrl + '/appointment/noshow-appointment';
  cancelCheckInUrl = environment.serverUrl + '/appointment/cancel-check-in/';
  searchPatientUrl = environment.serverUrl + '/patients';
  bookAppointmentUrl = environment.serverUrl + '/appointment/book';
  bookAppointmentForWaitListUrl = environment.serverUrl + '/appointment/book-for-wait-list';
  deleteSlotUrl = environment.serverUrl + '/available-slot/delete';
  fetchAvailableSlotByPeriodUrl = environment.serverUrl + '/appointment/available-slot';
  fetchAvailableSlotByTimedUrl = environment.serverUrl + '/appointment/available-slot-by-time';
  getAppointmentNotificationUrl = environment.serverUrl + '/appointments/detail/';
  setAppointmentNotificationUrl = environment.serverUrl + '/appointments/detail/';
  patientCancelAnAppointmentUrl = environment.serverUrl + '/appointment/patient-cancel-appointment/';
  patientCheckIsCancelUrl = environment.serverUrl + '/appointment/check-cancel/';
  newAvailableSlotUrl = environment.serverUrl + '/available-slot/new';
  fetchProviderByRoleUrl = environment.serverUrl + '/provider/list-by-role';
  getNoteFromPracticeUrl = environment.serverUrl + '/practice/get-note';
  patientGetAvailableSlotsUrl = environment.serverUrl + '/providers/available-slots';
  patientUpdateScheduleUrl = environment.serverUrl + '/schedules/update';
  fetchAppointmentByPeriodUrl = environment.serverUrl + '/appointment/view';
  fetchAppointmentByPeriodBySuperProviderUrl = environment.serverUrl + '/super-providers/provider/appointments';
  getIsLockBookApptURL = environment.serverUrl + '/provider/getIsLockProvider';
  getIsDeleteSaveSearchURL = environment.serverUrl + '/provider/getIsDelete-status';
  getNotifySavedOpenAppointmentSettingUrl = environment.serverUrl + '/provider/notify-open-settings';
  updateArrivedTimeUrl =  environment.serverUrl + '/appointment/update-arrived-time';
  getDataPatientUrl = environment.serverUrl + '/appointment/get-data-patient';
  savePatientToWaitlistUrl = environment.serverUrl + '/appointment/save-open-settings';

  waitlistAlertSettingsUrl = environment.serverUrl + '/provider/waitlist-alert-settings';
  saveWaitlistAlertSettingsUrl = environment.serverUrl + '/provider/save-waitlist-alert-settings';
  saveWaitlistPriorityUrl = environment.serverUrl + '/provider/save-waitlist-priority';

  sendAvailableSlotFromWaitListUrl = environment.serverUrl + '/appointment/send-availableslot-from-waitlist';

  fetchWaitlistinfoUrl = environment.serverUrl + '/appointment/fetch-waitlist-info';
  notifyWaitlistToAdminUrl = environment.serverUrl + '/appointment/notify-waitlist-to-admin';

  fetchProviderLocationUrl = environment.serverUrl + '/provider/fetchProviderLocation';
  fetchProviderLocationCityUrl = environment.serverUrl + '/provider/fetchProviderLocationCity';

  getAppointmentTypeListurl = environment.serverUrl+'/apttypes/fetchtypes';
  getSavedOpeningSearchUrl = environment.serverUrl+'/appointment/fetch-waitlist-details';
  updatesavePatientToWaitlistUrl = environment.serverUrl + '/appointment/update-save-open-settings';

  getPracticeAppointmentTypeListurl = environment.serverUrl+'/practiceapttypes';
  getProviderOfficeLocationUrl = environment.serverUrl+'/providerofficelocation';
  saveSettingsUrl = environment.serverUrl + '/patients/notify-open-settings';
  fetchPatientUpcommingAptUrl = environment.serverUrl + '/patient/fetch-patient-upcommingapt';
  fetchAppointmentNotificationUrl = environment.serverUrl + '/appointment/notifications';

  fetchWaitlistInBookingUrl = environment.serverUrl + '/appointment/waitlist-inbooking';
  saveWaitlistFromBookingUrl = environment.serverUrl + '/appointment/save-waitlist-frombooking';

  fetchWaitlistrangeUrl = environment.serverUrl+'/fetchwaitlistrange';
  //getIsPatientHaveFutureApptByProviderUrl = environment.serverUrl+'/getIsPatientHaveFutureApptByProvider';

  constructor(private _http: HttpClient) {

  }

  savePatientToWaitlist(data: any) {
    return this._http.post(this.savePatientToWaitlistUrl, JSON.stringify(data));
  }
  
  getDataPatient(id:any, is_family:any){
    const params = new HttpParams().set('id', id).set('is_family', is_family);
    return this._http.get(this.getDataPatientUrl, {params: params});
  }
  
  updateArrivedTime(data:any){
    return this._http.post(this.updateArrivedTimeUrl, data);
  }
  getNotifySavedOpenAppointmentSetting(page:any, searchText: any, limit:any, tabOrder:any) {
    const params = new HttpParams().set('page', page).set('searchText', searchText).set('limit', limit).set('tabOrder', tabOrder);
    return this._http.get(this.getNotifySavedOpenAppointmentSettingUrl, {params: params});
  }
  debugMode() {
    return !environment.production;
  }
  getIsLockBookApptByProviderID(){
    return this._http.get(this.getIsLockBookApptURL);
  }
  getIsDeleteAndStatusSaveSearch(savedId:any, patientId:any){
    const params = new HttpParams().set('savedId', savedId).set('patientId', patientId);
    return this._http.get(this.getIsDeleteSaveSearchURL, {params: params});
  }

  fetchAppointmentByPeriodBySuperProvider(providerUserId: any, startTime: any, endTime: any) {
    const params = new HttpParams().set('start_time', startTime).set('end_time', endTime);
    return this._http.get(this.fetchAppointmentByPeriodBySuperProviderUrl + providerUserId, {params: params});
  }
  fetchAppointmentByPeriodBySuperProvider1(providerUserId: any, startTime: any, endTime: any, selectedLocationId:any) {
    const params = new HttpParams().set('start_time', startTime).set('end_time', endTime).set('location_id', selectedLocationId);
    return this._http.get(this.fetchAppointmentByPeriodBySuperProviderUrl + providerUserId, {params: params});
  }

  fetchAppointmentByPeriod1(startTime: any, endTime: any, patientId:any, selectedLocationId:any) {
    const params = new HttpParams().set('start_time', startTime).set('end_time', endTime).set('patient_id', patientId).set('location_id', selectedLocationId);
    return this._http.get(this.fetchAppointmentByPeriodUrl, {params: params});
  }
  fetchAppointmentByPeriod(startTime: any, endTime: any, patientId:any) {
    const params = new HttpParams().set('start_time', startTime).set('end_time', endTime).set('patient_id', patientId);
    return this._http.get(this.fetchAppointmentByPeriodUrl, {params: params});
  }

  patientUpdateSchedule(data: any) {
    return this._http.post(this.patientUpdateScheduleUrl, JSON.stringify(data));
  }

  patientGetAvailableSlots(id: any, date: any, location_id:any) {
    const params = new HttpParams().set('schedule_id', id).set('date', date).set('location_id', location_id);
    return this._http.get(this.patientGetAvailableSlotsUrl, {params: params});
  }

  getNoteFromPractice() {
    return this._http.get(this.getNoteFromPracticeUrl);
  }

  fetchProviderByRole(data: any) {
    return this._http.post(this.fetchProviderByRoleUrl, JSON.stringify(data));
  }

  newAvailableSlot(data: any) {
    return this._http.post(this.newAvailableSlotUrl, data);
  }

  patientCheckIsCancel(id: any) {
    return this._http.get(this.patientCheckIsCancelUrl + id);
  }

  patientCancelAnAppointment(id: any) {
    return this._http.put(this.patientCancelAnAppointmentUrl + id, null);
  }

  setAppointmentNotification(id: any, data: any) {
    return this._http.post(this.setAppointmentNotificationUrl + id, JSON.stringify(data));
  }

  getAppointmentNotification(id: any) {
    return this._http.get(this.getAppointmentNotificationUrl + id);
  }

  fetchAvailableSlotByPeriod(startTime: any, endTime: any, location_id:any=0, schedule_id:any=0) {
    const params = new HttpParams().set('start_time', startTime).set('end_time', endTime).set('location_id', location_id).set('schedule_id', schedule_id);
    return this._http.get(this.fetchAvailableSlotByPeriodUrl, {params});
  }

  fetchAvailableSlotByTime(savedID: any, date: any) {
    const params = new HttpParams().set('savedOpeningSearchsId', savedID).set('date', date);
    return this._http.get(this.fetchAvailableSlotByTimedUrl, {params});
  }

  deleteSlot(data: any) {
    return this._http.post(this.deleteSlotUrl, data);
  }

  bookAppointment(data: any) {
    return this._http.post(this.bookAppointmentUrl, data);
  }

  // book appointment for wait list
  bookAppointmentForWaitList(data: any) {
    return this._http.post(this.bookAppointmentForWaitListUrl, data);
  }

  searchPatient(key: any) {
    const params = new HttpParams().set('key', key);
    return this._http.get(this.searchPatientUrl, {params: params});
  }

  checkIn(apptId: any, data: any) {
    return this._http.put(this.checkInUrl + apptId, JSON.stringify(data));
  }

  checkOut(apptId: any, data: any) {
    return this._http.put(this.checkOutUrl + apptId, JSON.stringify(data));
  }

  editApmt(apptId: any, data: any) {
    return this._http.put(this.editApmtUrl + apptId, JSON.stringify(data));
  }

  cancelAppointment(apptId: any) {
    return this._http.put(this.cancelAppointmentUrl + apptId, null);
  }
  noShowAppointment(data: any) {
    return this._http.post(this.noShowAppointmentUrl, data);
  }

  cancelCheckIn(apptId: any) {
    return this._http.put(this.cancelCheckInUrl + apptId, null);
  }

  fetchWaitTimeByProvider() {
    return this._http.get(this.fetchWaitTimeByProviderUrl);
  }

  getOtherSettings() {
    return this._http.get(this.getOtherSettingsUrl);
  }

  saveOtherSettings(data: any) {
    return this._http.post(this.saveOtherSettingsUrl, JSON.stringify(data));
  }

  getNotificationGlobalSetting() {
    return this._http.get(this.getNotificationGlobalSettingUrl);
  }

  getBookAppointment(providerId: any, patientId: any, familyId: any) {
    var params = new HttpParams();
    if(providerId || providerId != undefined)
    {
      params = params.set('provider_id', providerId);
    }
    if(patientId || patientId != undefined)
    {
      params = params.set('patient_user_id', patientId);
    }
    if(familyId || familyId != undefined)
    {
      params = params.set('family_id', familyId);
    }
    return this._http.get(this.getBookAppointmentUrl, {params: params});
  }
  bookAppointmentByPatient(data: any) {
    return this._http.post(this.bookAppointmentByPatientUrl, JSON.stringify(data));
  }
  getWaitlistAlertSettings(){    
    return this._http.get(this.waitlistAlertSettingsUrl);
  }
  saveWaitlistAlertSettings(data: any) {
    return this._http.post(this.saveWaitlistAlertSettingsUrl, JSON.stringify(data));
  } 
  saveWaitlistPriority(data: any) {
    return this._http.post(this.saveWaitlistPriorityUrl, JSON.stringify(data));
  }
  // book appointment for wait list
  sendAvailableSlotFromWaitList(data: any) {
    return this._http.post(this.sendAvailableSlotFromWaitListUrl, data);
  }
  fetchWaitlistinfo(save_id: any) {
    var params = new HttpParams();
    
    if (save_id != undefined)
    {
      params = params.set('save_id', save_id);
    }
    return this._http.get(this.fetchWaitlistinfoUrl, {params: params});
  }
  notifyWaitlistToAdmin() {
    return this._http.post(this.notifyWaitlistToAdminUrl, {});
  }
  fetchProviderLocation(provider_id:any) {
    var params = new HttpParams();
    
    if (provider_id != undefined)
    {
      params = params.set('provider_id', provider_id);
    }
    return this._http.get(this.fetchProviderLocationUrl, {params:params});
  }  
  fetchProviderLocationCity(provider_id:any) {
    var params = new HttpParams();
    
    if (provider_id != undefined)
    {
      params = params.set('provider_id', provider_id);
    }
    return this._http.get(this.fetchProviderLocationCityUrl, {params:params});
  }
  //Get Appointment type list
  getAppointmentTypeList(includeid=0) {
    const data = {'is_limit':1, id : includeid}
    const params =  UtilsService.buildQueryParams(data);//.set('type', type);
    return this._http.get(this.getAppointmentTypeListurl,{params: params});
  }
  getSavedOpeningSearch(id:any){
    return this._http.get(this.getSavedOpeningSearchUrl+'/'+id);
  }
  updatesavePatientToWaitlist(data: any, id:any) {
    return this._http.post(this.updatesavePatientToWaitlistUrl+'/'+id, JSON.stringify(data));
  }
  getPracticeAppointmentTypeList(slot_id:any,is_limit=1, includeid=0, practice_group_id:any=0) {
    const data = {'is_limit' : is_limit, 'slot_id' : slot_id, id : includeid, 'practice_group_id' : practice_group_id};
    
    console.log(data);

    const params =  UtilsService.buildQueryParams(data);//.set('type', type);
    return this._http.get(this.getPracticeAppointmentTypeListurl,{params: params});
  }
  getProviderOfficeLocation(provider_id:any) {
    const data = {'provider_id' : provider_id};
    const params =  UtilsService.buildQueryParams(data);//.set('type', type);
    return this._http.get(this.getProviderOfficeLocationUrl,{params: params});
  }
  saveSettings(data: any) {
    return this._http.post(this.saveSettingsUrl, JSON.stringify(data));
  }
  fetchPatientUpcommingApt(patientUserID: any=0, providerID: any=0,) {
    const params = new HttpParams().set('patient_user_id', patientUserID).set('provider_id', providerID);
    return this._http.get(this.fetchPatientUpcommingAptUrl, {params});
  }
  fetchAppointmentNotification(id: any=0) {
    const params = new HttpParams().set('id', id);
    return this._http.get(this.fetchAppointmentNotificationUrl, {params});
  }
  fetchWaitlistinfosInBooking(id: any=0) {
    const params = new HttpParams().set('id', id);
    return this._http.get(this.fetchWaitlistInBookingUrl, {params});
  }  
  saveWaitlistFromBooking(data: any, id:any) {
    return this._http.post(this.saveWaitlistFromBookingUrl+'/'+id, JSON.stringify(data));
  } 
  fetchWaitlistrange() {
    
    return this._http.get(this.fetchWaitlistrangeUrl);
  }
}
