<div class="provider-search">
  <div class="col-12 no-padding-left text-header-title margin-bottom-15 text-uppercase">Find Providers</div>
  <rtn-table #table [autoloading]="false" (onDataChange)="handleFetchSearchProvider($event)" [data]="listProviders.data"
             [currentPage]="listProviders.currentPage" [rowsOnPage]="listProviders.rowsOnPage"
             [numberOfPages]="listProviders.numberOfPages">
    <div class="row margin-bottom-20">
      <rtn-table-filter #filter class="col-12 row px-0">
        <form class="col-12 no-padding-left-right searchbox row bg-white" novalidate [formGroup]="searchForm"
              (ngSubmit)="handleSubmitSearchProvider(filter)">
          <div class="col-12 col-lg-6 search-box-block">
            <label class="normal-label col-lg-3 no-padding-left" for="searchbox-input-firstname">First Name</label>
            <div class="col-lg-9 no-padding-left-right">
              <input type="text" class="form-control" id="searchbox-input-firstname" ngControl="first_name"
                     formControlName="first_name" name="selectFirstName" [ngModel]="selectFirstName" autoFocusCustom
                     tabindex="1"/>

            </div>
          </div>
          <div class="col-12 col-lg-6 search-box-block">
            <label class="normal-label col-lg-3 no-padding-left" for="searchbox-input-lastname">Last Name</label>
            <div class="col-lg-9 no-padding-left-right">
              <input type="text" class="form-control" id="searchbox-input-lastname" ngControl="last_name"
                     formControlName="last_name" name="selectLastName" [ngModel]="selectLastName" tabindex="2"/>
            </div>
          </div>
          <div class="col-12 col-lg-6 search-box-block">
            <label class="normal-label col-lg-3 no-padding-left" for="searchbox-input-specialty">Disease</label>
            <div class="input-group select col-12 col-lg-9 disease-style center-block no-padding">
              <select select2-disease class="form-control" name="selectedDiseases" id="selectedDiseases"
                      [value]="selectedDiseases" formControlName="disease" [ngModel]="selectedDiseases"
                      (OnKeyUp)="onChangeDisease()" tabindex="3">
                <option *ngFor="let option of diseases" [value]="option.id">{{ option.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-6 search-box-block">
            <label class="normal-label col-lg-3 no-padding-left" for="searchbox-input-specialty">Specialty</label>
            <div class="input-group select col-12 col-lg-9 no-padding">
              <select id="searchbox-input-specialty" ngControl="specialty" [ngModel]="selectSpecialty"
                      name="selectSpecialty" formControlName="specialty" class="form-control" tabindex="4">
                <option [value]="''" selected>Select Specialty</option>
                <option *ngFor="let specialtyItem of specialtyList"
                        [value]="specialtyItem.id">{{specialtyItem.name}}</option>
              </select>
              <div class="input-group-select-icon">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 search-box-block">
            <label class="normal-label col-lg-3 no-padding-left" for="searchbox-input-city">City</label>
            <div class="col-lg-9 no-padding-left-right">
              <input type="text" class="form-control" id="searchbox-input-city" ngControl="city" [ngModel]="selectCity"
                     name="selectCity" formControlName="city" tabindex="5"/>
            </div>
          </div>
          <div class="col-12 col-lg-6 search-box-block pull-right">
            <label class="normal-label col-lg-3 no-padding-left" for="searchbox-input-state">State</label>
            <div class="input-group select col-12 col-lg-9 no-padding">
              <select id="searchbox-input-state" ngControl="state" [ngModel]="selectState" name="selectState"
                      formControlName="state" class="form-control" tabindex="6">
                <option [value]="''" selected>Select State</option>
                <option *ngFor="let stateItem of _systemData.states"
                        [value]="stateItem.code">{{stateItem.name}}</option>
              </select>
              <div class="input-group-select-icon">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-12 margin-top-15 margin-bottom-15 no-padding-left-right row">
            <div class="col-12 col-lg-6 margin-top-15">
              <div class="offset-3 message-error error-find-provider-large">{{ searchNoCriteriaMessage }}</div>
            </div>
            <div class="col-12 col-lg-6 search-box-block">
              <div>
                <button type="button" class="btn btn-clear btn-90 show-focus margin-right-10 text-uppercase"
                        (click)="handleResetSearchProvider(filter);">Clear
                </button>
                <button type="submit" class="btn btn-search btn-rtn btn-90 show-focus text-uppercase">Search</button>
                <span class="message-error error-find-provider-mobile">{{ searchNoCriteriaMessage }}</span>
              </div>
            </div>
          </div>
        </form>
      </rtn-table-filter>
      <div class="col-12 px-0">
        <div class="float-right followed-provider">
          <a [routerLink]="['/patient', 'follow-providers']">
            Followed Providers
            <i class="fa fa-share" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-12 listview px-0">
        <div class="table-responsive">
          <table class="table table-bordered table-ellipsis" [class.hidden]="isMobile">
            <thead>
            <tr>
              <th class="provider-name" id="search-provider-name">Full Name</th>
              <th class="provider-specialties" id="search-provider-specialties">Specialties</th>
              <th class="provider-address" id="search-provider-address">Address</th>
              <th class="provider-number" id="search-provider-address">Phone Number</th>
              <th class="provider-action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of table.data">
              <td class="provider-name wrapword">{{ item.fullName }}</td>
              <td class="provider-specialties wrapword">{{ item.specialties }}</td>
              <td class="provider-address wrapword">
                <div>{{ item.address }}</div>
                <div>{{ item.address2 }}</div>
                <div>
                  <span>{{ item.city }}</span><span *ngIf="item.state_code !== null">,</span>
                  <span>{{ item.state_code }}</span>
                  <span>{{ item.zip }}</span>
                </div>
              </td>
              <td class="provider-number wrapword">{{ item.phone_number }}</td>
              <td class="provider-action wrapword" *ngIf="!item.is_followed"
                  (click)="followProvider(item, $event, table)">
                <a class="btn btn-rtn btn-follow" [class.action]="!item.is_followed">Follow</a>
              </td>
              <td class="followed" *ngIf="item.is_followed">
                <span class="label">Followed</span>
              </td>
            </tr>
            <tr *ngIf="table.numberOfPages < 1">
              <td colspan="5" class="no-provider">{{searchNoDataMessage}}</td>
            </tr>
            </tbody>
            <tfoot *ngIf="table.numberOfPages > 1">
            <tr>
              <td colspan="5" class="text-center">
                <div class="page-footer">
                  <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                </div>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
        <table class="table table-bordered table-ellipsis" [class.hidden]="!isMobile">
          <thead>
          <tr>
            <th class="search-provider-name align-middle">Full Name - Address</th>
            <th class="search-provider-specialties align-middle">Specialties - Phone Number</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of table.data">
            <td class="align-top">
              <span class="wrapword">{{ item.fullName }}</span><br/><br/>
              <div>{{ item.address }}</div>
              <div>{{ item.address2 }}</div>
              <div>
                <span>{{ item.city }}</span><span *ngIf="item.state_code !== null">,</span>
                <span>{{ item.state_code }}</span>
                <span>{{ item.zip }}</span>
              </div>
            </td>
            <td *ngIf="!item.is_followed" (click)="followProvider(item, $event, table)" class="text-left align-top">
              {{ item.specialties }}<br/>
              {{ item.phone_number }}<br/><br/>
              <a class="btn btn-rtn align-right text-white" [class.action]="!item.is_followed">Follow</a>
            </td>
            <td *ngIf="item.is_followed" class="text-left align-top">
              {{ item.specialties }}<br/>
              {{ item.phone_number }}<br/><br/>
              <div class="text-right">Followed</div>
            </td>
          </tr>
          <tr *ngIf="table.numberOfPages < 1">
            <td colspan="5" class="no-provider">{{searchNoDataMessage}}</td>
          </tr>
          </tbody>
          <tfoot *ngIf="table.numberOfPages > 1">
          <tr>
            <td colspan="5" class="text-center">
              <div class="page-footer">
                <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
              </div>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </rtn-table>
</div>
