<div class="mt-3">
  <h3 class="title">Wait Time Log by Providers</h3>
  <div class="col-12 px-0">
      <div class="row col-12 border border-#ddd pt-3 no-print">
          
          <div class="px-0 row col-lg-6 mb-2">
              <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Practice:</div>
              <div
                  class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
                  Practice:</div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getPractice">
                  <select #select2PracticeDirective2="select2-practice2" select2-practice2 class="form-control"
                      [value]="dataSearchChart.practice" [maximumSelectionLength]="0" [closeOnSelect]="true"
                      [multiple]="false" [tags]="false" id="select2Practice">
                      <option *ngFor="let practice of practicesList; let i = index" [id]="'practice' + practice.id"
                          [value]="practice.id">{{ practice.name }}</option>
                  </select>
                  <div class="message-error">{{ errorResponse.practice }}</div>
                  <div class="red-alert d-none d-lg-block error_message"></div>
                  <div
                      class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
                  </div>
              </div>
          </div>
          <!-- Select type-->
          <div class="px-0 row col-lg-6 mb-2">
              <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Type <i
                      class="fas fa-info-circle type_info" data-toggle="tooltip" data-placement="top"
                      title="RTN specific wait time calculation is wait time calculated in accordance with RTN's proprietary rules"></i>
              </div>
              <div
                  class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
                  Type <i class="fas fa-info-circle type_info" data-toggle="tooltip" data-placement="top"
                      title="RTN specific wait time calculation is wait time calculated in accordance with RTN's proprietary rules"></i>
              </div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                  <select class="form-control" id="typeChart" name="typeChart">
                      <option [value]="1" selected>Standard wait time calculation</option>
                      <option [value]="2">RTN specific wait time calculation</option>
                  </select>
              </div>
          </div>
          <div class="px-0 row col-lg-6 mb-2">
              <div class="pl-0 d-none d-lg-block find-appointment-line-title width_70 pt-1">From:</div>
              <div
                  class="text-left col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
                  From:</div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                  <div class="input-group">
                      <input type="text" #inputDateFrom readonly [value]="dataSearchChart['dateFrom']"
                          class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateFrom']"
                          aria-describedby="basic-addon2">
                      <div class="input-group-append" #clickDateFrom>
                          <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                      </div>
                  </div>
                  <div class="message-error">{{ errorResponse.dateFrom }}</div>
              </div>
          </div>

          <!-- <div class="message-error">{{ errorResponse.start_date }}</div> -->
          <div class="px-0 row col-lg-6 mb-2">
              <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">To:</div>
              <div
                  class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
                  To:</div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                  <div class="input-group">
                      <input type="text" #inputDateTo readonly [value]="dataSearchChart['dateTo']"
                          class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateTo']"
                          aria-describedby="basic-addon2">
                      <div class="input-group-append" #clickDateTo>
                          <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                      </div>
                  </div>
                  <div class="message-error d-xl-none d-lg-none">{{ errorResponse.dateTo }}</div>
              </div>
          </div>
          <div class="row col-lg-6 mb-2-title mb-2 px-0">
          </div>
          <div class="row col-lg-6 mb-2-title mb-2 px-0 pl_100">
              <div class="d-none d-lg-block  width_70 pt-1"></div>
              <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none-title px-0">
              </div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                  <div class="message-error d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      {{ errorResponse.dateTo }}</div>
              </div>

          </div>
          <div class="ml-3 px-0 row col-lg-10 mb-2">
                <div class="btn-90" *ngIf="noofdata > 0">
                <button id='printDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1"
                    type="button" (click)="printChart()">PRINT</button>
                </div>
                <div class="btn-90 ml-2" *ngIf="noofdata > 0">
                  <button id='exportDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1"
                      type="button" (click)="exportdata()">EXPORT</button>
                </div>
                <div class="btn-90 ml-2">
                <button id='showDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1"
                    type="submit">SHOW</button>
                </div>
          </div>
          <div class="no-padding-left row col-lg-12 mb-2 inputDateFrom">
              <div class="red-alert d-lg-block d-md-none d-sm-none d-none error_message"></div>
          </div>
      </div>
           
      <div class="providers_waittime">          
        <!--<div *ngIf="noofpage > 1" class="text-center">
            <a href="javascript:void(0);" class="btn btn-rtn btn-sm text-uppercase" (click)="preslide()">Prev</a>
            <span>{{ curpage }}/ {{ noofpage }}</span>
            <a href="javascript:void(0);" class="btn btn-rtn btn-sm text-uppercase" (click)="nextslide()">Next</a>
        </div>
        <div id="barchart"></div>

        <div *ngIf="noofdata > 0">
            <span class="text-danger">* </span>{{ _systemData.footNote }}
        </div>-->
        <ng-container *ngIf="noofdata > 0">
            <ng-container *ngFor="let pages of noofpageArr">
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="title"> Page : {{ pages + 1}}</h3>  
                    <div id="barchart_{{pages}}"></div>              
                  </div>
                </div>          
            </ng-container> 
            <div>
                <span class="text-danger">* </span>{{ _systemData.footNote }}
            </div>
        </ng-container>     
        <ng-container *ngIf="noofdata == 0 && isSubmitted">
            <div class="row">
                <div class="col-md-12">
                    <p>No data found.</p>
                </div>
            </div>
        </ng-container>   
      </div>        
  </div>
</div>