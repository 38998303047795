import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { WaitingTimeService } from '../number-waittime/services/waiting-time.service';
import { AppointmentDetail } from '../model/appointment-detail';
import { SystemInterval, SystemData, SystemEvent, SystemService } from '../../shared/system';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-number-waittime',
  templateUrl: './number-waittime.component.html',
  styleUrls: ['./number-waittime.component.scss']
})
export class NumberWaittimeComponent implements OnInit {
  waitTime: string = '0 min';
  fetchWaitTimeSubscriber: Subscription;
  private _systemInterval: SystemInterval;
  private _utils: Utils;
  private _waitingTimeService: WaitingTimeService;

  constructor() {
  }
  
  ngOnInit() {
  }

  ngOnDestroy() {
    this._systemInterval.clearSystemInterval(this._systemInterval.appointment_fetchWaitTimeProvider);
    if (this.fetchWaitTimeSubscriber) {
        this.fetchWaitTimeSubscriber.unsubscribe();
    }
  }

  fetchWaitTime() {
    //this.waitTime = this._utils.generateValueUnitDisplay(0, 'min');
    if (this.fetchWaitTimeSubscriber) {
        this.fetchWaitTimeSubscriber.unsubscribe();
    }
    // this.fetchWaitTimeSubscriber = this._waitingTimeService.fetchWaitTime().subscribe(
    //     (success: any) => {
    //         let time = 0;
    //         if (success > 999) {
    //             time = 999;
    //         } else {
    //             time = success;
    //         }
    //         this.waitTime = this._utils.generateValueUnitDisplay(time, 'min');
    //     }
    // );
  }
}
