<main>
<div class="container-fluid pl-0 pr-0">
 
    <div id="slider" class="row carousel slide" data-ride="carousel" [style.margin-top]="positionOfSlide + 'px'">
      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#slider" data-slide-to="0" class="active"></li>
      </ul>

      <!-- Wrapper for slides -->
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item slide-01 {{ item.class }}" *ngFor="let item of sliders">
            <div class="row d-flex flex-md-row flex-column-reverse">
                <div class="col-md-6 d-flex align-items-center">
                    <div class="pattern"><img src="{{ src }}bannerPattren.png" /></div>
                    <div class="curve"><img src="{{ src }}bannerCurve.png" /></div>
                    <div class="slider-content">
                      <div class="star"><img src="{{ src }}bannerStar.png" /></div>
                      <h6>Welcome To The</h6>
                      <h1>
                        Real Time <br>
                        Neighborhood
                      </h1>
                      <h2>Where Every Second Counts hshhshdh</h2>
                      <h5>Transforming The Healthcare Experience One Slot At A Time</h5>
                      <div class="mt-5 demoButtonwrap">
                        <a href="mailto:info@realtimeneighborhood.com?subject=Request Demo" title="Real Time Neighborhood - Request a Demo" alt="Real Time Neighborhood - Request a Demo" class="d-inline-flex align-items-center requestDemo mt-3">
                            <span>Request a Demo ff</span>
                            <span><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
                        </a>                       
                       </div>
                    </div>
                
            </div>
            <div class="col-md-6 pr-0 d-flex justify-content-end">
               <img src="{{ src }}slide01.jpg"  alt="{{ item.title }}">
            </div>

            </div>
          
        </div>
      </div>

      <!-- Left and right controls -->
      <!-- <a class="carousel-control-prev" href="#slider" role="button" data-slide="prev">
        <span class="carousel-chevron fa fa-chevron-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#slider" role="button" data-slide="next">
        <span class="carousel-chevron fa fa-chevron-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a> -->
    </div>

    <!-- <div class="row desc">
      <div class="col-6 col-sm-12 col-md-6">
        <div class="desc-text">{{ desc }}</div>
      </div>
      <div class="col-6 col-sm-12 col-md-6">
        <img class="desc-img" src="{{ src }}phone_app.png">
      </div>
    </div> -->
    <!-- <div class="row capability">
      <div class="capability-header">Features & Capabilities Highlights Test</div>
      <div class="col-6 col-sm-12 col-md-6 capability-item" *ngFor="let capability of capabilities">
        <div class="capability-title">{{ capability.title }}</div>
        <div class="capability-desc">{{ capability.desc }}</div>
      </div>
    </div> -->
    <!-- <div class="row feature-area">
      <div class="col-4 col-sm-12 col-md-4 feature-padding" *ngFor="let feature of features">
        <div class="feature-item" (click)="navigate(feature.id)">
          <img class="feature-img" src="{{ feature.src }}">
          <div class="feature-cover">
            <div class="feature-title">{{ feature.title }}</div>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <section class="services">
    <div class="container">
       <div class="row pt-5 mt-5">
           <div class="col-12 col-md-6 col-lg-4 mb-5">
               <article class="shadow p-4 d-flex">
                   <div class="icon"><img src="{{ src }}DynamicSchedulingIcon.png" alt="Dynamic Scheduling"></div>
                   <div class="col-md-10 pr-0">
                       <h5 class="text-truncate"><a href="https://realtimeneighborhood.com/features/" target="_blank">Dynamic Scheduling</a></h5>
                       <p>We maximize operational efficiency and patient satisfaction by enhancing existing scheduling systems.</p>
                   </div>
               </article>
           </div>
           <div class="col-12 col-md-6 col-lg-4 mb-5">
               <article class="shadow p-4 d-flex">
                   <div class="icon"><img src="{{ src }}TimeManagementIcon.png" alt="Time Management"></div>
                   <div class="col-md-10 pr-0">
                    <h5 class="text-truncate"><a href="https://realtimeneighborhood.com/features/" target="_blank">Time Management</a></h5>
                       <p>Our technology calculates and manages patient wait times accurately.</p>
                   </div>
               </article>
           </div>
           <div class="col-12 col-md-6 col-lg-4 mb-5">
               <article class="shadow p-4 d-flex">
                   <div class="icon"><img src="{{ src }}RealTimeDataIcon.png" alt="Real Time Data Transparency"></div>
                   <div class="col-md-10 pr-0">
                    <h5 class="text-truncate"><a href="https://realtimeneighborhood.com/features/" target="_blank">Real Time Data Transparency</a></h5>
                       <p>Our solution offers real-time visibility into operational performance.</p>
                   </div>
               </article>
           </div>
       
           <div class="col-12 col-md-6 col-lg-4 mb-5">
               <article class="shadow p-4 d-flex">
                   <div class="icon"><img src="{{ src }}DynamicSchedulingIcon.png" alt="Big Data Analytics"></div>
                   <div class="col-md-10 pr-0">
                    <h5 class="text-truncate"><a href="https://realtimeneighborhood.com/features/" target="_blank">Big Data Analytics</a></h5>
                       <p>We provide provider-specific insights to drive productivity and targeted strategies.</p>
                   </div>
               </article>
           </div>
           <div class="col-12 col-md-6 col-lg-4 mb-5">
               <article class="shadow p-4 d-flex">
                   <div class="icon"><img src="{{ src }}TimeManagementIcon.png" alt="System Integrated"></div>
                   <div class="col-md-10 pr-0">
                    <h5 class="text-truncate"><a href="https://realtimeneighborhood.com/features/" target="_blank">System Integrated</a></h5>
                       <p>Our technology integrates effortlessly with existing systems.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                   </div>
               </article>
           </div>
           <div class="col-12 col-md-6 col-lg-4 mb-5">
            <article class="shadow p-4 d-flex">
                <div class="icon"><img src="{{ src }}RealTimeDataIcon.png" alt="Real Time Data Transparency Icon"></div>
                <div class="col-md-10 pr-0">
                    <h5 class="text-truncate"><a href="https://realtimeneighborhood.com/features/" target="_blank">Family and Care Coordination</a></h5>
                    <p>Our platform enables users to manage appointments for their loved ones.</p>
                </div>
            </article>
        </div>
       </div>
    </div>
</section>


<section class="aboutCompany">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-12">
              <figure>
                  <img src="{{ src }}about.jpg" alt="Trulli">
                </figure>
          </div>
          <div class="col-lg-6 col-12">
              <aside>
                  <h6 class="pl-4">Know Our Company</h6>
                  <h2>Real Time Neighborhood</h2>
                  <p>At Real Time Neighborhood, we are reimagining healthcare by turning the industry’s most persistent problem – wait times – into a powerful tool for improving patient experiences and healthcare operational efficiency. We harness the power of real-time technology to transform wait times from a point of frustration into an opportunity for patients and providers alike.
                      Traditional approaches to managing wait times have often created more challenges than they have solved. 
                      By contrast, our unique, innovative solution is designed to blend seamlessly with existing scheduling 
                      operations and provide accurate, real-time updates on wait times. This empowers patients and providers, 
                      enhances satisfaction, and improves schedule retention for optimal revenue opportunities.
                  </p>
                  <p>We are at the forefront of a paradigm shift in healthcare, 
                      converting wait times from a negative into a positive. 
                      With Real Time Neighborhood, time becomes not just a measurable quantity but a 
                      valuable asset that can be managed effectively for the benefit of all. Embrace the future with us
                       – because in healthcare, every moment counts.
                  </p>
              </aside>
          </div>
      </div>
  </div>
</section>


<section class="realTime">
  <div class="container">
      <div class="row flex-column-reverse flex-lg-row">
          <div class="col-lg-7 col-12">
              <article>
                   <h2>The Power of Real-Time</h2>
                   <p>In an industry where every second matters, the ability to manage and adapt to changes in real-time is 
                      a game-changer. At Real Time Neighborhood, our unique selling proposition lies in our cutting-edge real-time 
                      technology, which forms the foundation of all our offerings. We believe that the future of healthcare is 
                      real-time, and our platform is designed to offer a seamless, dynamic, and comprehensive real-time solution.</p>

                      <p>By transforming wait times from a source of frustration into an opportunity for patients and providers alike, 
                      our real-time approach is setting us apart in the healthcare technology market. With the power of real-time, 
                      we are not only providing accurate updates on wait times but also enabling dynamic scheduling, real-time 
                      data transparency, instant gap-filling, and more. It is this innovative application of real-time technology 
                      that allows us to deliver a total solution that enhances patient experiences, improves operational efficiency, 
                      and maximizes revenue opportunities.</p>
                      <div class="d-flex justify-content-end readMore">
                           <a href="https://realtimeneighborhood.com/" target="_blank" title="Read More" alt="Read More Button">
                              <span>Read More</span>
                              <img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/>
                           </a>
                      </div>
              </article>
          </div>
          <div class="col-lg-5 col-12">
              <figure>
                  <img src="{{ src }}timerImage.jpg" alt=" Real Time Image">
              </figure>
          </div>
      </div>
  </div>
</section>

<section class="features">
  <div class="container">
      <div class="row">
          <div class="col-md-6">
              <h2>Features</h2>
          </div>
          <div class="col-md-6">
              <p>
                  Real Time Neighborhood is a physician-designed program designed to put you — the  patient — back in control of your day…
              </p>
          </div>
      </div>
      <div class="row pt-5">
          <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-3">
              <article class="d-flex">
                  <div class="icon"><img src="{{ src }}statusIcon.png" alt="Icon"/></div>
                  <div class="col-md-10 pr-0">
                      <h4>WAIT TIME STATUS</h4>
                      <p>Real Time Neighborhood is the first and only application to accurately calculate office wait-times.</p>
                      <a href="https://realtimeneighborhood.com/" target="_blank" title="Read More" alt="Read More" class="d-inline-flex readMore mt-3">
                          <span>Read More</span>
                          <img src="{{ src }}greenRightArrow.svg" alt="Arrow Icon"/>
                       </a>
                  </div>
              </article>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-3">
              <article class="d-flex">
                  <div class="icon"><img src="{{ src }}calanderIcon.png" alt="Icon"/></div>
                  <div class="col-md-10 pr-0">
                      <h4>SELF-SCHEDULING</h4>
                      <p>Real Time Neighborhood provides patients with all the tools they need to manage their healthcare appointments.</p>
                      <a href="https://realtimeneighborhood.com/" target="_blank" title="Read More" alt="Read More Button" class="d-inline-flex readMore mt-3">
                          <span>Read More</span>
                          <img src="{{ src }}greenRightArrow.svg" alt="Arrow Icon"/>
                       </a>
                  </div>
              </article>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-3">
              <article class="d-flex">
                  <div class="icon"><img src="{{ src }}alertIcon.png" alt="Icon"/></div>
                  <div class="col-md-10 pr-0">
                      <h4>NOTIFICATIONS & ALERTS</h4>
                      <p>Real Time Neighborhood sends personalized reminders and alerts keeping Patients informed about their appointments.</p>
                      <a href="https://realtimeneighborhood.com/" target="_blank" title="Read More" alt="Read More Button" class="d-inline-flex readMore mt-3">
                          <span>Read More</span>
                          <img src="{{ src }}greenRightArrow.svg" alt="Arrow Icon"/>
                       </a>
                  </div>
              </article>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-3 mb-3">
              <article class="d-flex">
                  <div class="icon"><img src="{{ src }}homeIcon.png" alt="Icon"/></div>
                  <div class="col-md-10 pr-0">
                      <h4>FAMILY MEMBERS</h4>
                      <p>Real Time Neighborhood offers users the convenience of securely adding family members or friends to their account.</p>
                      <a href="https://realtimeneighborhood.com/" target="_blank" title="Read More" alt="Read More Button" class="d-inline-flex readMore mt-3">
                          <span>Read More</span>
                          <img src="{{ src }}greenRightArrow.svg" alt="Arrow Icon"/>
                       </a>
                  </div>
              </article>
          </div>
      </div>
  </div>
</section>


<section class="scheduleDemo d-flex align-items-center">
  <div class="container d-xl-flex justify-content-center align-items-center">
       <h2 class="text-center">SCHEDULE A DEMO</h2>
       <form class="d-flex justify-content-center align-items-center" novalidate #scheduledemoForm="ngForm" (ngSubmit)="scheduleDemo()">
            <div class="d-flex justify-content-center align-items-center">
              <label for="full-name" class="d-none">Name</label>
              <input type="text" id="supportdemo_name" name="supportdemo_name" class="form-control" placeholder="Your Name" [(ngModel)]="requestdemoModel.supportdemo_name" (keyup)="onKeyUp()" autocomplete="off"/>
              <span class="text-danger" *ngIf="requireMessageDemo.supportdemo_name">{{requireMessageDemo.supportdemo_name}}</span>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <label for="email" class="d-none">Email</label>
              <input type="email" id="supportdemo_email" name="supportdemo_email" class="form-control" placeholder="Your e-mail" [(ngModel)]="requestdemoModel.supportdemo_email" (keyup)="onKeyUp()" autocomplete="off" />
              <span class="text-danger" *ngIf="requireMessageDemo.supportdemo_email">{{requireMessageDemo.supportdemo_email}}</span>
            </div>
            <button type="submit" [disabled]="formDemoSubmitted">
               <span>Request Demo</span>
               <img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/>
            </button>
       </form>
  </div>
</section>


<section class="neighborhoodWorks pt-5">
  <div class="container pt-3">
      <div class="row d-flex justify-content-center flex-column align-items-center pt-5">
          <h2>How Real Time Neighborhood Works, and Works for YOU</h2>
          <p class="mt-4">It’s our belief that when you make your patients’ lives easier, it creates a better relationship built on respect and appreciation. That means:</p>
           <ul>
              <li>Better patient retention</li>
              <li>Increased new patient acquisition</li>
              <li>Fewer no-shows and cancellations</li>
           </ul>
      </div>
      <div class="row d-flex justify-content-center flex-column align-items-center forPatients">
          <h2>For Patients</h2>
          <p class="mt-4">Real Time Neighborhood was created to work for YOU.</p>
          <p>From a simple sign-up process and easily searchable appointment calendar to an innovative self-scheduling and rescheduling interface, 
              it’s our goal to make your life easier and put control where it belongs: into the hands of the patient.</p>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-5">
          <a href="https://realtimeneighborhood.com/patients/" target="_blank" title="Learn More" alt="Learn More Button" class="d-inline-flex align-items-center learnMore mt-3">
              <span>Learn More</span>
              <span><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
           </a>
         
      </div>
  </div>
</section>

</main>

