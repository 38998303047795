export class PracticeDetail {
    [key:string]: any;
    name: string = '';
    billing: any;
    full_address: string = '';
    email: string = '';
    phone_mobile: string = '';
    phone_mobile_tmp: string = '';
    address: string = '';
    address2: string = '';
    city: string = '';
    state_code: string = '';
    state: string = '';
    zip: string = '';
    opening_hours: any  = [];
    opening_hours_display: any;
    address_display: string = '';
    insurances: any;
    insurances_name: any;
    open_notify_type:number=1;
}
