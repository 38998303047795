<div class="row notification-management">
  <h3 class="width-full font-size-24">Notification Management</h3>
  <div class="notification-content width-full">
      <form method="post" #form="ngForm" (ngSubmit)="submitNotification(form.value)">
          <div class="card card-default">
              <!-- //Body -->
              <div class="card-body">
                  <div class="notification-item">
                      <div *ngFor="let item of notificationList; let i = index" class="display-flex row" id="{{item.id}}">
                          <div class="col-12 col-xl-9 col-xl-9 padding-top-20">
                              <h3 class="card-title">{{ item.name }}</h3>
                              <textarea (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event, item.id)" class="input form-control textarea-style"
                                  rows="5" [(ngModel)]="item.message" name="{{item.id}}" [class.input-error]="messageResponseError['notifications.' + item.id]"
                                  (ngModelChange)="item.message = $event" maxlength="500"></textarea>
                              <div class="col-12 col-xl-12 col-xl-12 no-padding-left-right">
                                  <div class="col-10 col-xl-11 col-xl-11 no-padding-left-right message-error">{{ messageResponseError['notifications.' + item.id] }}</div>
                                  <div class="col-2 col-xl-1 col-xl-1 no-padding-left-right float-right text-right">{{ item.message.length }}/500</div>
                              </div>
                          </div>
                          <div class="col-12 col-xl-3 col-xl-3 variables-style">
                              <div class="card-restyle">
                                  <div class="card-heading-restyle">
                                      <h3 class="card-title text-title-form-edit">Variables</h3>
                                  </div>
                                  <div class="card-body variables-body-style">
                                      <div *ngFor="let variable of item.variables">{{ variable }}</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- //Footer -->
              <div class="card-footer">
                  <button type="submit" class="btn btn-rtn btn-90 text-uppercase">SAVE</button>
                  <span *ngIf="messageResponseSuccess" class="message-success">{{messageResponseSuccess}}</span>
              </div>
          </div>
      </form>
  </div>
</div>