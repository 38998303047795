import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoaderComponent} from './loader/loader.component';
import { FormsModule } from '@angular/forms';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {ModalComponent} from './modal/modal.component';
import {AutofocusDirective} from './modal/auto-focus.directive';
import {AutofocusCustomDirective} from './modal/auto-focus.custom';
import {ModalHeaderComponent} from '@app/shared/modal/modal-header';
import {ModalBodyComponent} from '@app/shared/modal/modal-body';
import {ModalFooterComponent} from '@app/shared/modal/modal-footer';
import {TimelineComponent} from '../appointments/timeline/timeline.component';
import {AlertComponent} from './alert/alert.component';
import {RtnDataTableCoreComponent} from '@app/shared/datatable/core';
import {RtnDataTablePagerComponent} from '@app/shared/datatable/pager';
import {RtnDataTableFilterComponent} from '@app/shared/datatable/filter';
import {RtnDataTablePagerBootstrapComponent} from '@app/shared/datatable/pager-bootstrap';
import {RtnDataTableSorterComponent} from '@app/shared/datatable/sorter';
import {SystemData} from '@app/shared/system';
import {AlertDownloadComponent} from './alert-download/alert-download.component';
import {RtnCalendarComponent} from '@app/shared/calendar/calendar.component';
import {Select2Directive} from './form/select2.directive';
import {ModalsAlertComponent} from './modals/modal-alert/modal-alert.component';
import {ModalsConfirmationComponent} from './modals/modal-confirmation/modal-confirmation.component';
import {MODAL_DIRECTIVES} from '@app/shared/ng2-bs3-modal';
import {SimpleModalsComponent} from './modals/simple-modals/simple-modals.component';
import {HttpModule} from '@angular/http';
import {Select2DiseaseDirective} from './form/select2-disease.directive';
import {AutoCompleteDirective} from './form/auto-complete.directive';
import {MobileNumberComponent} from './mobile-number/mobile-number.component';
import { SimpleMaskDirective } from './form/simple-mask.directive';
import { InputMaskDirective } from './form/input-mask.directive';
import { Select2InsuranceDirective } from './form/select2-insurance.directive';
import { DOBComponent } from './dob/dob';
import { OpeningHourComponent } from './opening-hour/opening-hour.component';
import { OpeningHourSlotComponent } from './opening-hour/slot/slot.component';
import { NotFoundComponent } from '@app/shared/not-found/not-found.component';
import { Select2Directive as Select2DirectivePlan } from '@app/shared/form/select2-plan.directive';
import { AcceptedInsuranceComponent } from '@app/shared/accepted-insurance/accepted-insurance';
import { RtnScheduleComponent } from '@app/shared/schedule';
import { PasswordFormatTooltipComponent } from './tooltip/password-format/password-format.component';
import { ResendEmailComponent } from './modals/marketing/resend-email/resend-email.component';
import { Logout_ModalsComponent } from './general/logout';
import {Select2ProviderDirective} from '@app/shared/form/select2-provider.directive';
import { FeedbackComponent } from './feedback/feedback.component';
import { PhysicianDirectoryGlobalComponent } from './physician-directory-global/physician-directory-global.component';
import { HomepageGlobalComponent } from './homepage-global/homepage-global.component';
import { AlertTimeOutComponent } from './alertTimeOut/alertTimeOut.component';
import { Select2Provider2Directive } from '@app/shared/form/select2-provider2.directive';
import { Select2PatientDirective } from '@app/shared/form/select2-patient.directive';
import { Select2PracticeDirective } from '@app/shared/form/select2-practice.directive';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Select2EventtypeDirective } from '@app/shared/form/select2-eventtype.directive';
import { Select2AptlocationDirective } from '@app/shared/form/select2-aptlocation.directive';
import { Select2Provider3Directive } from '@app/shared/form/select2-provider3.directive';

import {NgxMaskModule} from 'ngx-mask';
import { CommonFooterComponent } from './common-footer/common-footer.component';

import {Select2LocationDirective} from './form/select2-location.directive';
import {RouterModule} from '@angular/router';
import { PatientFutureAppointmentComponent } from '@app/shared/patient-future-appointment/patient-future-appointment.component';

import { WaitlistFromAppointmentComponent } from '@app/appointments/modals/waitlist-from-appointment/waitlist-from-appointment.component';

import { Select2AjaxpatientDirective } from '@app/shared/form/select2-ajaxpatient.directive';

// import { SelectProviderComponent } from '@app/providers/select-provider/select-provider.component';
// import {
//   RtnDataTableCoreComponent,
//   RtnDataTableFilterComponent,
//   RtnDataTablePagerComponent,
//   RtnDataTableSorterComponent
// } from '@app/shared/datatable';
// import {RtnDataTablePagerBootstrapComponent} from '@app/shared/datatable/pager/bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    HttpModule,
    RouterModule
  ],
  declarations: [
    Select2Provider2Directive,
    Select2PatientDirective,
    Select2PracticeDirective,
    LoaderComponent,
    LoadingIndicatorComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    AutofocusDirective,
    AlertComponent,
    RtnDataTableCoreComponent,
    RtnDataTablePagerComponent,
    RtnDataTableFilterComponent,
    RtnDataTablePagerBootstrapComponent,
    RtnDataTableSorterComponent,
    AlertDownloadComponent,
    RtnCalendarComponent,
    Select2Directive,
    ModalsAlertComponent,
    ModalsConfirmationComponent,
    MODAL_DIRECTIVES,
    SimpleModalsComponent,
    Select2DiseaseDirective,
    AutoCompleteDirective,
    MobileNumberComponent,
    SimpleMaskDirective,
    InputMaskDirective,
    Select2InsuranceDirective,
    Select2DirectivePlan,
    Select2ProviderDirective,
    DOBComponent,
    OpeningHourComponent,
    OpeningHourSlotComponent,
    NotFoundComponent,
    RtnScheduleComponent,
    AcceptedInsuranceComponent,
    PasswordFormatTooltipComponent,
    ResendEmailComponent,
    AutofocusCustomDirective,
    Logout_ModalsComponent,
    FeedbackComponent,
    PhysicianDirectoryGlobalComponent,
    HomepageGlobalComponent,
    AlertTimeOutComponent,
    Select2PracticeDirective2,
    Select2EventtypeDirective,
    Select2AptlocationDirective,
    CommonFooterComponent,
    Select2Provider3Directive,
    Select2LocationDirective,
    PatientFutureAppointmentComponent,
    WaitlistFromAppointmentComponent,
    Select2AjaxpatientDirective
  ],
  exports: [
    LoaderComponent,
    LoadingIndicatorComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    AlertComponent,
    RtnDataTableCoreComponent,
    RtnDataTablePagerComponent,
    RtnDataTableFilterComponent,
    RtnDataTablePagerBootstrapComponent,
    RtnDataTableSorterComponent,
    RtnCalendarComponent,
    ModalsAlertComponent,
    ModalsConfirmationComponent,
    MODAL_DIRECTIVES,
    SimpleModalsComponent,
    MobileNumberComponent,
    SimpleMaskDirective,
    InputMaskDirective,
    Select2DiseaseDirective,
    Select2InsuranceDirective,
    Select2DirectivePlan,
    Select2ProviderDirective,
    DOBComponent,
    OpeningHourComponent,
    OpeningHourSlotComponent,
    NotFoundComponent,
    RtnScheduleComponent,
    Select2Directive,
    AcceptedInsuranceComponent,
    PasswordFormatTooltipComponent,
    ResendEmailComponent,
    AutofocusDirective,
    AutofocusCustomDirective,
    Logout_ModalsComponent,
    AlertDownloadComponent,
    PasswordFormatTooltipComponent,
    PhysicianDirectoryGlobalComponent,
    HomepageGlobalComponent,
    AutoCompleteDirective,
    AlertTimeOutComponent,
    Select2Provider2Directive,
    Select2PatientDirective,
    Select2PracticeDirective,
    Select2PracticeDirective2,
    Select2EventtypeDirective,
    Select2AptlocationDirective,
    CommonFooterComponent,
    Select2Provider3Directive,
    Select2LocationDirective,
    PatientFutureAppointmentComponent,    
    WaitlistFromAppointmentComponent,
    Select2AjaxpatientDirective
  ]
})
export class SharedModule {
}
