<modal #Add_Appttype_Modals class="appointment-new">
  <form #modalForm="ngForm" class="form">
      <div class="form-content">
          <div class="form-header" *ngIf="aptTypeObject.id == null">Add Apt. Type</div>
          <div class="form-header" *ngIf="aptTypeObject.id != null">Edit Apt. Type</div>
          <div class="form-info">
              <div class="row col-12 col-xs-12 no-padding-left-right">
                  <div
                      class="col-3 col-md-3 col-md-offset-1 no-padding-right input-label-required find-appointment-line-title display-flex px-0">
                      Code
                  </div>
                  <div class="col-9 pr-0">
                      <input class="form-control mt-1" id="input-code" name="code" type="text" placeholder="" [(ngModel)]="aptTypeObject.code"  (keyup)="onChange('code')" 
                      [class.input-error]="errorResponse.code != ''" maxlength="255" [readonly]="aptTypeObject.id != null">
                      <div class="message-error">{{ errorResponse.code }}</div>
                  </div>
              </div>
              <div class="row col-12 col-xs-12 no-padding-left-right">
                <div
                    class="col-3 col-md-3 col-md-offset-1 no-padding-right input-label-required find-appointment-line-title display-flex px-0">
                    Label
                </div>
                <div class="col-9 pr-0">
                  <input class="form-control mt-1" id="input-label" name="label" type="text" placeholder="" [(ngModel)]="aptTypeObject.label"  (keyup)="onChange('label')" 
                  [class.input-error]="errorResponse.label != ''" maxlength="255">
                    <div class="message-error">{{ errorResponse.label }}</div>
                </div>
              </div>
              <div class="row col-12 col-xs-12 no-padding-left-right">
                <div
                    class="col-3 col-md-3 col-md-offset-1 no-padding-right find-appointment-line-title display-flex px-0">
                    Is Skipped Apt?
                </div>
                <div class="col-9 pr-0 mt-2">
                  <div class="input-rtn display-flex">
                      <input class="checkbox check-single" type="checkbox" id="is_allowed"
                          [(ngModel)]="aptTypeObject.is_allowed" [ngModelOptions]="{standalone: true}">
                      <label for="is_allowed" class="selectorExtreme rtn-text-grey-85"></label>
                      <span class="checkbox-text hidden">Is Skipped Apt?</span>
                  </div>
                  <div><i class="font-text notice-text">Skipped Apt : opening notification not send</i></div>
                  <div class="message-error">{{ errorResponse.is_allowed }}</div>
                </div>
              </div>
          </div>
      </div>
      <div class="button height_76">
          <button type="button" class="btn btn-rtn btn-90 float-right  ml-2" (click)="processSave()" autofocus>Save
                  </button>
          <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel float-right" data-dismiss="modal"
              (click)="close()">Cancel
          </button>
          <span class="message-success">{{ successMessage }}</span>
          <span class="message-error">{{ errorMessage }}</span>
      </div>
  </form>
</modal>