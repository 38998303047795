import { AfterViewInit, Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { chartDate } from '@app/shared/models/chartDate';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { AdminRestService } from '@app/admin/admin.service';
import { PatientsService } from '@app/patients/patients.service';
import { AlertService } from '@app/shared/alert';
import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { dataSearchChart } from '@app/shared/models/dataSearchChart';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Utils } from '@app/shared/utils';
import { Router } from '@angular/router';
import { SystemService } from '@app/shared/system';

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";

declare let jQuery: any;

@Component({
  selector: 'app-waittime-office',
  templateUrl: './waittime-office.component.html',
  styleUrls: ['./waittime-office.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WaittimeOfficeComponent  implements OnInit, AfterViewInit, OnInit {
  currentDate: any = moment();
  maxDate:any;
  data_chart: any;
  error: chartDate = new chartDate();
  geByProviderSubscriber: Subscription;
    
  practiceId = '0';
  providerId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();

  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;

  public providersList: any = [];
  public practicesList: any = [];
  public isDataAvailable = true;

  public message = '';
  
  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
  @ViewChild('inputDateFrom') inputPractice: ElementRef;
  @ViewChild('inputDateFrom') inputProvider: ElementRef;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;

  private getIdDatePicker: String = '#inputDateFrom';
  private getIdPractice: String = '#getPractice option:selected';
  private getErrorMes: String = ' .error_message';

  private chartdata:any = [];
  private chartWidth: number;
  private chartHeight: number;
  private chartMargin = { top: 20, right: 40, bottom: 60, left: 80 };
  private chartX: any;
  private chartY: any;
  private chartSvg: any;
  private chartG: any;

  public curpage : number = 1;
  public noofdata : number = 0;
  public noofpage : number = 0;
  public noofpageArr:any = [];

  constructor(
    private _alertService: AlertService,
    private _patientService: PatientsService,
    private _adminService: AdminRestService,
    private _datalogsService: DataLogsService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    
  ) {
    this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');

    let width = 1400; //1366
    width = (window.innerWidth > width) ? (window.innerWidth - 600) : (window.innerWidth - 400);
    this.chartWidth = width - this.chartMargin.left - this.chartMargin.right;
    this.chartHeight= 400 - this.chartMargin.top - this.chartMargin.bottom;

  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.ProviderSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
    const _self = this;
    _self._loadingIndicatorService.start();
    _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
      (success: any) => {
        _self.practicesList = success.practices;
        const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
        _self.getProviderByPractice(firstPractice);
        this._loadingIndicatorService.stop();
      });
  }

  getProviderByPractice(practiceId: any) {
    this._loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.providersList = [];
          success.providers.forEach((item: any) => {
            item.full_name
              = item.first_name + ((item.middle_name == null)
              ? (' ')
              : (' ' + item.middle_name + ' ')) + item.last_name;
            this.providersList.push(item);
          });
        }
        this._loadingIndicatorService.stop();
      },
      (error: any) => {

        this._loadingIndicatorService.stop();
      }
    );
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
        this.maxDate = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    }else{
        this.maxDate = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(this.inputDateTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        this.dataSearchChart.dateTo = jQuery(this.inputDateTo.nativeElement).val();
      }
    });
    jQuery(this.clickDateTo.nativeElement).click(() => {
      jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });
    jQuery(this.inputDateTo.nativeElement).datepicker('setDate', this.dataSearchChart.dateTo);

    /*jQuery('#exportDataChart').on('click', function () {
      _self.exportdata();
    });*/
    jQuery('#showDataChart').on('click', function () {      
      _self.updateData();
    });
  }

  onChangePractice() {
    this.removeMsgErr();
    const practice = this.PracticeSelector.getData();
    if (practice.length !== 0) {
      this.getProviderByPractice(practice);
      this.ProviderSelector.clearData();
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      if (this.providersList.length > 0) {
        this.ProviderSelector.updateData(this.providersList);
      }
    } else {
      this.ProviderSelector.clearData();
    }
  }

  exportdata() {
    this.verifyDataSearch();
    const _self = this;
    const startTime = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;
    const providerID = this.dataSearchChart.provider;
    if (this.validate()) {
      const token = localStorage.getItem('user_token');
      jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
        httpMethod: 'POST',
        data: {
          'token': token,
          'practice': practiceID,
          'providerId': providerID,
          'choosenDate': startTime,
          'chartType': 8
        }
      }).done(function () {
        // TODO
      })
        .fail(function () {
          _self._alertService.onClose = () => {
            location.reload();
          };
        });
    }
    

  }

  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.PracticeSelector.inputError = true;
      this.PracticeSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.PracticeSelector.inputError = false;
      this.PracticeSelector.changeBorderColorPractice();
      return true;
    }
  }

  validateProvider(message: any) {
    if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
      this.errorResponse.provider = message;
      this.ProviderSelector.inputError = true;
      this.ProviderSelector.changeBorderColorProvider();
      return false;
    } else {
      this.removeMsgErr();
      return true;
    }
  }

  removeMsgErr() {
    this.errorResponse.provider = null;
    this.ProviderSelector.inputError = false;
    this.ProviderSelector.changeBorderColorProvider();
  }

  validate() {
    const validatePractice = this.validatePractice('This field is required');
    const validateProvider = this.validateProvider('This field is required');
    if (validatePractice && validateProvider) {
      return true;
    }
    return false;
  }

  updateData() {
    this.verifyDataSearch();
    const date = this.dataSearchChart.dateTo;
    const providerId = this.dataSearchChart.provider;
    if (this.validate()) {
      this.getWaittimeOffice(date, providerId);
    }
  }

  verifyDataSearch() {
    this.dataSearchChart.practice = this.PracticeSelector.getData();
    this.dataSearchChart.provider = this.ProviderSelector.getData();
  }

  getWaittimeOffice(date: any, providerId: any) {
    const self = this;
    this._loadingIndicatorService.start();
    this.chartdata = [];
    this._datalogsService.getWaittimeOffice(date, providerId).subscribe(
      (success: any) => {
        
        this._loadingIndicatorService.stop();
        this.data_chart = success.data_chart;

        if (this.data_chart !== undefined && this.data_chart.time !== undefined  && this.data_chart.waittime !== undefined ) {
          const length_time = this.data_chart.time.length;
          for (let index = 0; index < length_time; index++) {
            let datum:any = {};
            datum.label = this.data_chart.time[index];
            datum.value = this.data_chart.waittime[index];
            this.chartdata.push(datum);
          }
        }
        
        this.noofdata = this.chartdata.length;
        this.curpage = 1;
        this.drawMultiBarChart();  

      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }
  printDataChart(){
    window.print();
  }
  drawMultiBarChart(){
    let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.label.length}));
    
    let barWidth:any = maxLength * 10;    
    let numBars:any = Math.round(this.chartWidth / barWidth);

    this.noofpage = Math.ceil(this.noofdata / numBars);

    this.noofpageArr = Array(this.noofpage).fill(0).map((x,i)=>i);

    let that = this;
    setTimeout(function(){
      for (var i=0; i < that.noofpage; i++) {
        var curpos = i + 1;
        that.printBarChart('groupchartbody_' + i, curpos, numBars);
      }
    }, 500);

  }
  printBarChart(eleId:any, curpage:number, numBars:number){
    
    if (document.getElementById(eleId) != undefined && document.getElementById(eleId)!= null) {
      document.getElementById(eleId).innerHTML = "";
    }

    if (this.noofdata == 0){
      return false;
    }
    
  
    var tooltip = d3.select("body")
    .append("div")
    .attr('id', 'myTooltip')
    .style("position", "absolute")
    .style("z-index", "10")
    .style("visibility", "hidden")
    .text("");
  
    let chartSvgp = d3.select("#" + eleId).append('svg');
    chartSvgp.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
    chartSvgp.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);   
    
    let chartGp = chartSvgp.append("g")
    .attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    let chartXp:any = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).padding(0.1);
    let chartYp:any = d3Scale.scaleLinear().rangeRound([this.chartHeight, 0]);
  
    let startpos = (curpage - 1) *  numBars;
    
    let endpos = (curpage * numBars);
    endpos = (endpos > this.noofdata) ? this.noofdata : endpos;

    let tempchartdata:any = this.chartdata.slice(startpos, endpos);

    chartXp.domain(tempchartdata.map((d:any) => d.label));
  
    chartYp.domain([0, d3Array.max(tempchartdata, (d:any) => d.value)]);
        
    chartGp.append("g")
      .attr("class", "axis axis - x")    
      .attr("transform", "translate(0," + this.chartHeight + ")")
      .call(d3Axis.axisBottom(chartXp))    
      .selectAll("text")
      .attr("transform", "translate(-10,10)rotate(-65)")
      .style("text-anchor", "end")
      .style("font-size", 12)
      .style("fill", "#474847")
  
    chartGp
    .append("g")
    .attr("class", "axis axis - y")
    .call(d3Axis.axisLeft(chartYp).ticks(6).tickFormat(function(d:any) { return d + " min"; }))   
    .append("text")
    .attr("class", "axis-title")
    .attr("transform", "rotate(-90)")
    .attr("y", -40)
    .attr("x", -200)
    .attr("dy", "0.71em")
    .text("Wait Time");
    
    chartGp
    .selectAll(".barchart")   
    .data(tempchartdata)
    .enter()
    .append("rect")
    .attr("class", "barchart")
    .attr("x", (d:any) => chartXp(d.label))
    .attr("y", (d:any) => chartYp(d.value))
    .attr("width", chartXp.bandwidth())
    .attr("height", (d:any) => this.chartHeight - chartYp(d.value))
    .on("mouseover", function(d:any){
      var newHtml = '';
      newHtml += '<p><strong>' + d['label'] + '</strong></p>';    
      newHtml += '<p>Wait Time : ' + d['value'] + '</p>';
    
      tooltip.html(newHtml);
      return tooltip.style("visibility", "visible");
    })
    .on("mousemove", function(){
        var pageX = d3['event']['pageX'];
        var pageY = d3['event']['pageY'];
        return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");    
    })
    .on("mouseout", function(){     
      return tooltip.style("visibility", "hidden");
    });
  }  
  initBarchart(){

    d3.selectAll("svg > *").remove();
    
    d3.selectAll('#myTooltip').remove();

    //document.getElementById("barchart").innerHTML = "";

    if (document.getElementById("barchart") != undefined && document.getElementById("barchart")!= null) {
      document.getElementById("barchart").innerHTML = "";
    }

    if (this.noofdata == 0){
      return false;
    }
    //this.curpage = 1;
    let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.label.length}));
    
    let barWidth:any = maxLength * 10;    
    let numBars:any = Math.round(this.chartWidth / barWidth);
  
    var tooltip = d3.select("body")
    .append("div")
    .attr('id', 'myTooltip')
    .style("position", "absolute")
    .style("z-index", "10")
    .style("visibility", "hidden")
    .text("");
  
    this.chartSvg = d3.select("#barchart").append('svg');
    this.chartSvg.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
    this.chartSvg.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);   
    
    this.chartG = this.chartSvg.append("g")
    .attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    this.chartX = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).padding(0.1);
    this.chartY = d3Scale.scaleLinear().rangeRound([this.chartHeight, 0]);
  
    let startpos = (this.curpage - 1) *  numBars;
    this.noofpage = Math.ceil(this.noofdata / numBars);
    let endpos = (this.curpage * numBars);
    endpos = (endpos > this.noofdata) ? this.noofdata : endpos;

    let tempchartdata:any = this.chartdata.slice(startpos, endpos);

    this.chartX.domain(tempchartdata.map((d:any) => d.label));
  
    this.chartY.domain([0, d3Array.max(this.chartdata, (d:any) => d.value)]);
        
    this.chartG.append("g")
    .attr("class", "axis axis - x")    
    .attr("transform", "translate(0," + this.chartHeight + ")")
    .call(d3Axis.axisBottom(this.chartX))    
    .selectAll("text")
      .attr("transform", "translate(-10,10)rotate(-65)")
      .style("text-anchor", "end")
      .style("font-size", 12)
      .style("fill", "#474847")
  
   this.chartG
   .append("g")
   .attr("class", "axis axis - y")
   .call(d3Axis.axisLeft(this.chartY).ticks(6).tickFormat(function(d:any) { return d + " min"; }))   
   .append("text")
   .attr("class", "axis-title")
   .attr("transform", "rotate(-90)")
   .attr("y", -40)
   .attr("x", -200)
   .attr("dy", "0.71em")
   .text("Wait Time");
  
   this.chartG
   .selectAll(".barchart")   
   .data(tempchartdata)
   .enter()
   .append("rect")
   .attr("class", "barchart")
   .attr("x", (d:any) => this.chartX(d.label))
   .attr("y", (d:any) => this.chartY(d.value))
   .attr("width", this.chartX.bandwidth())
   .attr("height", (d:any) => this.chartHeight - this.chartY(d.value))
   .on("mouseover", function(d:any){
    var newHtml = '';
    newHtml += '<p><strong>' + d['label'] + '</strong></p>';    
    newHtml += '<p>Wait Time : ' + d['value'] + '</p>';
  
    tooltip.html(newHtml);
    return tooltip.style("visibility", "visible");
   })
   .on("mousemove", function(){
      var pageX = d3['event']['pageX'];
      var pageY = d3['event']['pageY'];
      return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");    
  })
  .on("mouseout", function(){     
    return tooltip.style("visibility", "hidden");
  });
  
  }
  preslide(){
    
    if (this.curpage > 1){
      this.curpage--;
      this.initBarchart();
    }
    
  }
  nextslide(){
    if (this.noofpage > this.curpage) {
      this.curpage++;
      this.initBarchart();
    }
  }
}