import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';

@Component({
  selector: 'app-loading-indicator',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./loading-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
  private _pleaseWait = false;
  private _subscription: any;

  // we probably want a reference to ElementRef here to do some DOM manipulations
  constructor(
    private _loadingIndicatorService: LoadingIndicatorService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {
    // TODO
  }

  get pleaseWait(): boolean {
    return this._pleaseWait;
  }

  set pleaseWait(state: boolean) {
    this._pleaseWait = state;
  }

  ngOnInit() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._subscription = this._loadingIndicatorService.indicator$.subscribe((state: boolean) => {
      this.pleaseWait = state;
      const zIndex: number = this.findHighestZIndex();
      this._renderer.setStyle(this._elementRef.nativeElement.children[0], 'z-index', String(zIndex * 10));
    });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private findHighestZIndex(): number {
    const elems: any = document.body.children;
    let highest = 0;
    let zIndex = 0;
    let zIndexElement: any;
    for (let i = 0; i < elems.length; i++) {
      zIndexElement = document.defaultView.getComputedStyle(elems[i], null).getPropertyValue('z-index');
      zIndex = isFinite(zIndexElement) ? parseFloat(zIndexElement) : 0;
      if ((zIndex > highest)) {
        highest = zIndex;
      }
    }
    return (Number(highest) + 1).valueOf();
  }
}
