import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {SystemEvent} from '@app/shared/system/system-event';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';

declare let jQuery: any;

@Component({
  selector: 'rtn-mobile-number',
  moduleId: module.id,
  templateUrl: './mobile-number.component.html',
  styleUrls: ['./mobile-number.component.scss'],
  providers: [SimpleMaskDirective]
})
export class MobileNumberComponent implements OnInit {
  @Input() isSMS: boolean;
  @Input() formInput: any;
  @Input() statusDisabled = false;    
  @Input() error: string;
  @Input() isDisabled = false;
  @Output() removeError: EventEmitter<any> = new EventEmitter();

  @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
  @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;
  constructor(
    private _systemEvent: SystemEvent
  ) {
    // TODO
  }

  ngOnInit() {
    jQuery('.input-phone').keyup(function (event: any) {
      const valueInput = jQuery(this).val();
      if (typeof (valueInput.match(/\d+/)) !== 'undefined' && valueInput.match(/\d+/) !== null) {
        if (valueInput.match(/\d+/)[0].length === parseInt(jQuery(this).attr('datamax'))) {
          jQuery(this).parent().next('div').children('.input-phone').focus();
        }
      } else if (event.keyCode === 8 && valueInput === '') {
        jQuery(this).parent().prev('div').children('.input-phone').focus();
      }
    });
  }

  onKeyUp() {
    this.error = null;
    this.removeError.emit('phone_mobile');
    this._systemEvent.event.emit('event_callSelectedMobile');
  }
}
