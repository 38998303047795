import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation,
    OnInit
} from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
// import {AppointmentBookService} from '@app/appointments/modals/appointment-book/appointment-book.service';
import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
import { Event, TimelineComponent } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { from, Subscription, Subscriber } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { SearchOpeningsComponent } from '@app/appointments/wait-list/search-openings.component';
declare let jQuery: any;
import * as moment from 'moment';
import { SearchOpenings } from '@app/shared/models/search-openings';
import { SystemData } from '@app/shared/system';

@Component({
    selector: 'app-add-waitlist',
    templateUrl: './add-waitlist.component.html',
    styleUrls: ['./add-waitlist.component.scss'],
})
export class AddWaitlistComponent implements OnInit, AfterViewInit, OnDestroy {

    fetchAvailableSlotSubscriber: Subscription;
    fetchAvailableSlotByTimeSubscriber: Subscription;
    bookAppointmentSubscriber: Subscription;
    searchPatientSubscriber: Subscription;
    deleteSlotSubscriber: Subscription;
    getIsLockSubscriber: Subscription;
    getIsDeleteSaveSearchSubscriber: Subscription;
    getEmailSubcriber: Subscription;
    isLock: any;
    currentDate: any = moment();
    msg: string = 'Your account has been locked.';
    // get current date by default
    @ViewChild(TimelineComponent) timelineDirective: TimelineComponent;
    @ViewChild('Add_waitlist') modal: ModalComponent;
    @ViewChild('inputDateNewAppointment') inputDateNewAppointment: ElementRef;
    @ViewChild('clickDateNewAppointment') clickDateNewAppointment: ElementRef;
    @ViewChild('inputStartDateSaveSettingSearch') inputStartDateSaveSettingSearch: ElementRef;
    @ViewChild('inputEndDateSaveSettingSearch') inputEndDateSaveSettingSearch: ElementRef;
    @ViewChild('clickShowStartDateSaveSettingSearch') clickShowStartDateSaveSettingSearch: ElementRef;
    @ViewChild('clickShowEndDateSaveSettingSearch') clickShowEndDateSaveSettingSearch: ElementRef;
    @Output()
    reload: EventEmitter<any> = new EventEmitter();
    eventEntry: Event;
    dataDateBook: DataDateBook = new DataDateBook;
    errorResponse: SearchOpenings = new SearchOpenings();
    errorMessage: string;
    errorMessageBook: string;
    searchDisabled = true;
    patients: any = [];
    patient_id: number;
    uuid: any;
    patient_name: any;
    isFamily = false;
    availableSlots: any = [];
    selectedSlot = 0;
    public savedId: number;
    time: any;
    input_group: any = '.input-group';
    search_field: any = '#search-field-new-appointment';
    searchOpenings: SearchOpenings = new SearchOpenings();
    searchOpeningsDistance: any;
    loadIconVerifiedPhone = false;
    verifiedPhone = false;
    msgPartialAccess
        = 'Your account has not full access yet. Please update insurance or contact RTN admin to get full access account';
    msgPedingAccess = 'Your account has not been activated.';
    successSaveOpeningSearch = '';
    errorSaveOpeningSearch = '';
    saveSettingsSubscriber: Subscription;
    messageResponseErrorSearchOpen: string = null;
    isSaved: boolean = false;
    error_invalid_date: any = '';
    error_invalid_time: any = '';
    msgRequire: any = 'This field is required';
    formatDate: any = 'YYYY-MM-DD';
    appointmentTypeList: any;
    // ========== Specific Modal Method ========== //
    newAvailableSlotModel: NewAvailableSlotClass = new NewAvailableSlotClass();
    requireMessage: RequireMessage = new RequireMessage();
    providerOfficeLists:any=[];
    fetchProviderOfficeSubscriber:Subscription;
    fetchAppointmentTypeListSubscriber:Subscription;
    advancenoticedurations:any=[];
    fetchWaitlistrangeSubscriber:Subscription;
    //addwlmobile: any = {};
    // show future apt
    /*futureaptlists: any = [];
    selectedupcommingId: number = 0;
    fetchPatientUpcommingAptSubscriber: Subscription;
    loadedpage : string = 'provider_wl';*/

    constructor(
        private Service: AppointmentsService,
        private _alertService: AlertService,
        private _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService,
        public _systemData: SystemData,
        // public _searchOpeningsComponent: SearchOpeningsComponent,
    ) {
        // TODO
        this.searchOpeningsDistance = this._systemData.searchOpeningsDistance;
    }
    ngOnInit(): void {
        this.searchOpenings.enable_email = 0;
        this.searchOpenings.enable_sms = 0;
        //this.searchOpenings.apt_type = '';
        //Get Appointment List
        /*this.Service.getAppointmentTypeList().subscribe(
            (success: any) => { //console.log(success);
                this.appointmentTypeList = success.apttypes;
            }
        )*/

    }
    showMessage(msg: any) {
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    getInfoByNameClicked(event: any, id: any, first_name: string, last_name: string, dob: string, is_family: boolean, uuid: any, family_id: any) {
        this.clearData();
        this.patient_id = id;
        this.isFamily = is_family;
        this.searchOpenings.patient = first_name + ' ' + last_name + ' - ' + dob;
        // this.newAvailableSlotModel.patient = first_name + ' ' + last_name + ' - ' + dob;
        this.searchOpenings.patient_name = first_name + ' ' + last_name;
        this.uuid = uuid;
        this.searchOpenings.patient_user_id = id;
        this.searchOpenings.family_id = family_id;
        jQuery(event.srcElement).closest(this.input_group).removeClass('open');
        this.getZipCodeOfPatient();
        this.getDataPatient();  

        //console.log('patient_ID' + this.patient_id);
        //this.fetchPatientUpcommingApt(this.patient_id, 0);
    }
    getInfoByNumberClicked(event: any, id: any, phone_mobile: number, first_name: string, last_name: string, uuid: any) {
        this.clearData();
        this.patient_id = id;
        this.searchOpenings.patient = first_name + ' ' + last_name + ' - ' + phone_mobile;
        this.searchOpenings.patient_name = first_name + ' ' + last_name;
        this.uuid = uuid;
        this.searchOpenings.patient_user_id = id;
        jQuery(event.srcElement).closest(this.input_group).removeClass('open');
        this.getZipCodeOfPatient();
        this.getDataPatient();

        //console.log('patient_ID' + this.patient_id);
        //this.fetchPatientUpcommingApt(this.patient_id, 0);
    }

    getZipCodeOfPatient() {
        const splitArr = this.uuid.split("|");
        const zipCode = splitArr[splitArr.length - 1];
        this.searchOpenings.zip = zipCode;
        this.errorResponse.zip = '';
    }
    getDataPatient() {
        //this.addwlmobile = {};
        if (this.getEmailSubcriber) {
            this.getEmailSubcriber.unsubscribe();
        }
        this._loadingIndicatorService.start();
        this.getEmailSubcriber = this.Service.getDataPatient(this.patient_id, this.isFamily ? 1 : 0)
            .subscribe(
                (success: any) => {
                    this._loadingIndicatorService.stop();
                    this.searchOpenings.email = success.email_patient;
                    this.searchOpenings.mobile = success.phone;
                    //this.addwlmobile = this._utils.explodePhoneMobile(this.searchOpenings);

                    if (this.searchOpenings.mobile !== null) {

                        const num = this.searchOpenings.mobile.replace(/[^0-9]/g, '');
                        const firstMobileNumber = num.substr(0, 3);
                        const secondMobileNumber = num.substr(3, 3);
                        const thirdMobileNumber = num.substr(6, 4);
                        this.searchOpenings.first = '(' + firstMobileNumber + ')';
                        this.searchOpenings.middle = secondMobileNumber;
                        this.searchOpenings.last = thirdMobileNumber;
                    }
                },
                (error: any) => {

                }
            );

    }
    openAlertMessage(msg: string = '') {
        this._alertService.onClose = () => {
            // Nothing
        };
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }
    validatePatient(message: any) {
        if (this.searchOpenings.patient == '') {
            this.errorResponse.patient = message;
            return false;
        } else {
            return true;
        }
    }
    validateZipCode(message: any) {
        if (this.searchOpenings.zip == '') {
            this.errorResponse.zip = message;
            return false;
        } else {
            return true;
        }
    }
    validateDateFrom(message: any) {
        if (this.searchOpenings.start_date == '') {
            this.errorResponse.start_date = message;
            return false;
        } else {
            return true;
        }
    }
    validateDateEnd(message: any) {
        if (this.searchOpenings.end_date == '') {
            this.errorResponse.end_date = message;
            return false;
        } else {
            return true;
        }
    }
    validateEmail(message: any) {
        if (this.searchOpenings.enable_email == 1 && this.searchOpenings.email == null) {
            this.errorResponse.email = message;
            return false;
        } else {
            return true;
        }
    }
    validatePhone(message: any) {
        this.searchOpenings.mobile = this._utils.implodePhoneMobile(this.searchOpenings.first, this.searchOpenings.middle, this.searchOpenings.last);
        if (this.searchOpenings.enable_sms == 1 && this.searchOpenings.mobile == '') {
            this.errorResponse.mobile = message;
            return false;
        } else {
            return true;
        }
    }

    validateDateFromLessDateTo(message: any) {
        const date1 = new Date(this.searchOpenings.start_date);
        const date2 = new Date(this.searchOpenings.end_date);
        if (date1 > date2) {
            this.error_invalid_date = message;
            return false;
        } else {
            return true;
        }
    }
    validateProviderOffice(message:any) {
        if (this.searchOpenings.provider_office_id == '') {
            this.errorResponse.provider_office_id = message;
            return false;
        } else {
            return true;
        }
    }
    validateAppointmentType(message:any) {
        if (this.searchOpenings.apt_type == '') {
            this.errorResponse.apt_type = message;
            return false;
        } else {
            return true;
        }
    }
    /*validateAdvanceNoticeDuration(message:any){
        if (this.searchOpenings.advance_notice_duration == '') {
            this.errorResponse.advance_notice_duration = message;
            return false;
        } else {
            return true;
        }
    }*/
    removeErrEmail() {
        this.errorResponse.email = '';
        // jQuery('#emailInput').removeClass('input-error');
    }
    removeErrPhone() {
        this.errorResponse.mobile = '';
        // jQuery('#emailInput').removeClass('input-error');
    }
    clearMsgError() {
        this.errorResponse.patient = '';
        this.errorResponse.zip = '';
        this.errorResponse.start_date = '';
        this.errorResponse.end_date = '';
        this.errorResponse.email = '';
        this.errorResponse.mobile = '';
        this.error_invalid_date = '';
        this.error_invalid_time = '';
        this.errorResponse.provider_office_id ='';
        this.errorResponse.apt_type ='';
    }
    validate() {
        const validatePatient = this.validatePatient(this.msgRequire);
        const validateZipCode = this.validateZipCode(this.msgRequire);
        const validateDateFrom = this.validateDateFrom(this.msgRequire);
        const validateDateEnd = this.validateDateEnd(this.msgRequire);
        const validateEmail = this.validateEmail(this.msgRequire);
        const validatePhone = this.validatePhone(this.msgRequire);
        const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');

        const validatePOffice = this.validateProviderOffice(this.msgRequire);
        const validateAType = this.validateAppointmentType(this.msgRequire);

        //const validateDuration = this.validateAdvanceNoticeDuration(this.msgRequire);
        //validateDuration

        if (validatePatient && validateZipCode && validateDateFrom && validateDateEnd && validateEmail && validatePhone && validateDateFromLessDateTo && validatePOffice && validateAType) {
            return true;
        }
        return false;
    }

    getDataToSave() {
        this.searchOpenings.start_time = jQuery('#startTime').val();
        this.searchOpenings.end_time = jQuery('#endTime').val();
    }
    processSaveSearch() {
        this.clearMsgError();
        if (this.validate()) {
            this.setDataToSaveSearch();
            this.handleSaveSearch();
        }
    }
    setDataToSaveSearch() {
        if (this._utils.isPartialActiveStatus()) {
            this.openAlertMessage(this.msgPartialAccess);
            return;
        }
        this.successSaveOpeningSearch = '';
        this.errorSaveOpeningSearch = '';
        if (this.saveSettingsSubscriber) {
            this.saveSettingsSubscriber.unsubscribe();
        }
        this.getDataToSave();
        if (this.searchOpenings.enable_email) {
            this.searchOpenings.enable_email = 1;
        } else {
            this.searchOpenings.enable_email = 0;
        }

        if (this.searchOpenings.enable_sms) {
            this.searchOpenings.enable_sms = 1;
        } else {
            this.searchOpenings.enable_sms = 0;
        }
        this.searchOpenings.mobile = this._utils.implodePhoneMobile(this.searchOpenings.first, this.searchOpenings.middle, this.searchOpenings.last);

        this.searchOpenings.start_time = (this.searchOpenings.start_time != undefined && this.searchOpenings.start_time != null && this.searchOpenings.start_time != '') ? this.searchOpenings.start_time : '00:00:00';
        this.searchOpenings.end_time = (this.searchOpenings.end_time != undefined && this.searchOpenings.end_time != null && this.searchOpenings.end_time != '') ? this.searchOpenings.end_time : '23:59:59';

        const startTime:any = this.searchOpenings.start_time.split(':');
        const endTime:any = this.searchOpenings.end_time.split(':');


        const startDatetime:any = moment(this.searchOpenings.start_date).hour(startTime[0])
            .minute(startTime[1]).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
        const endDatetime:any = moment(this.searchOpenings.end_date).hour(endTime[0]).minute(endTime[1])
            .second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');

        this.searchOpenings.start_datetime = startDatetime;
        this.searchOpenings.end_datetime = endDatetime;
        //this.searchOpenings.schedule_id = this.selectedupcommingId;
    }
    handleSaveSearch() { //console.log(this.searchOpenings);return false;
        this._loadingIndicatorService.start();
        this.saveSettingsSubscriber = this.Service.savePatientToWaitlist(this.searchOpenings).subscribe(
            (success: any) => {
                this.isSaved = true;
                this._loadingIndicatorService.stop();
                const res = success;
                if (res.error_code === 'RC000') {
                    /*this.successSaveOpeningSearch = res.error_messages;
                    this.loadIconVerifiedPhone = !!this.searchOpenings.enable_sms;
                    this.verifiedPhone = res.verifiedPhone;
                    this.clearDataOncesaved();*/
                    this.loadIconVerifiedPhone = !!this.searchOpenings.enable_sms;
                    this.verifiedPhone = res.verifiedPhone;
                    this.clearDataOncesaved();

                    let that = this;
                    this._alertService.onClose = () => {
                        that.dismiss();
                        //that.close();
                    };
                    this._alertService.showDismissButton = false;
                    this._alertService.dismissButtonLabel = 'No';
                    this._alertService.showCloseButton = true;
                    this._alertService.closeButtonLabel = 'OK';
                    this._alertService.title = res.error_messages;
                    this._alertService.message = '';
                    this._alertService.emitter('open');

                }
            }, (error: any) => {
                this._loadingIndicatorService.stop();
                const response = error.error;
                this.loadIconVerifiedPhone = false;
                if (response.error_code === 'RC091') {
                    this.messageResponseErrorSearchOpen = response.error_messages;
                } else if (response.error_code === 'RC020') {
                    this.showMsgError(response.error_fields);
                } else if (response.error_code === 'RC101' || response.error_code === 'RC089' || response.error_code === 'RC135' || response.error_code === 'RC142') {
                    this.errorSaveOpeningSearch = response.error_messages;
                }
                // this.callDataSearhOpeningBack();
            }, () => {
                // this.callDataSearhOpeningBack();
            });
    }
    showMsgError(response:any){
        if (response.patient_user_id != null) {
            this.errorResponse.patient = 'This patient is invalid';
        }
        if (response.zip != null) {
            this.errorResponse.zip = response.zip;
        }
        if (response.email != null) {
            this.errorResponse.email = response.email;
        }
        if (response.end_time != null) {
            this.error_invalid_time = response.end_time;
        }
        if (response.mobile != null) {
            this.errorResponse.mobile = response.mobile;
        }
        
    }

    formatDob(dob: any) {
        if (dob !== null) {
            return moment(dob, this.formatDate).format('MMMM DD, YYYY');
        } else {
            return null;
        }
    }
    searchPatientClicked(event: any) {
        const self = this;
        this.patients = [];
        this.errorMessage = null;
        this.errorMessageBook = null;
        if (this.searchOpenings.patient !== undefined) {
            if (this.searchPatientSubscriber) {
                this.searchPatientSubscriber.unsubscribe();
            }
            this._loadingIndicatorService.start();
            this.searchPatientSubscriber = this.Service.searchPatient(this.searchOpenings.patient)
                .subscribe(
                    (success: any) => {
                        this._loadingIndicatorService.stop();
                        if (success.error_code === 'RC000') {
                            success.patients.forEach((item: any) => {
                                const patient = new PatientInfoClass();
                                patient.id = item.id;
                                patient.first_name = item.first_name;
                                patient.last_name = item.last_name;
                                patient.dob = self.formatDob(item.dob);
                                patient.phone_mobile = item.phone_mobile;
                                patient.is_family = item.is_family;
                                patient.uuid = item.key;
                                patient.family_member_id = item.family_member_id;
                                jQuery(event.srcElement).closest(self.input_group).addClass('open');
                                jQuery('.result.dropdown-menu').css({
                                    'width': jQuery(event.srcElement).closest(self.input_group).width(),
                                });
                                this.patients.push(patient);
                            });
                        }
                        if (success.error_code === 'RC061') {
                            jQuery(event.srcElement).closest(self.input_group).addClass('open');
                            this.errorMessage = success.error_messages;
                        }
                    },
                    (error: any) => {
                        this._loadingIndicatorService.stop();
                        const e = error.error;
                        if (e.error_code === 'RC061') {
                            this.errorMessage = e.error_messages;
                        }
                    }
                );
        }
    }

    onChange(event: any) {
        switch (event) {
            case 'patient':
                this.errorResponse.patient = '';
                break;
            case 'zip':
                this.errorResponse.zip = '';
                break;
            case 'startTime':
                this.errorResponse.start_time = '';
                this.error_invalid_time = '';
                //this.onChangeDateTime(event);
                break;
            case 'endTime':
                this.errorResponse.end_time = '';
                this.error_invalid_time = '';
                //this.onChangeDateTime(event);
                break;
            case 'email':
                this.errorResponse.email = '';
                break;
            case 'mobile':
                this.errorResponse.mobile = '';
                break;            
            case 'provider_office_id':
                this.errorResponse.provider_office_id = '';
                break; 
            case 'apt_type':
                this.errorResponse.apt_type = '';
                break;
            case 'advance_notice_duration':
                this.errorResponse.advance_notice_duration = '';
                //this.onChangeDateTime(event);
                break;    
        }
    }
    onChangeDateTime(fieldTime:any){
        let start_time  = jQuery('#startTime').val();
        let end_time    = jQuery('#endTime').val();

        if ((fieldTime == 'startTime' && start_time != '') || (fieldTime == 'endTime' && end_time != '')) {
            this.searchOpenings.advance_notice_duration = '';
        } else if (fieldTime == 'advance_notice_duration') {
            jQuery('#startTime').val('');
            jQuery('#endTime').val('');
        }
    }
    // ========== Class Method ========== //

    ngAfterViewInit() {
        const _self = this;
        jQuery(document).ready(function () {
            jQuery(_self.inputStartDateSaveSettingSearch.nativeElement).datepicker({
                dateFormat: 'MM dd, yy',
                numberOfMonths: jQuery(window).width() < 768 ? 1 : 2,
                minDate: 0,
                showButtonPanel: true,
                onSelect: () => {
                    _self.errorResponse.start_date = '';
                    _self.error_invalid_date = '';
                    _self.onChange('startDate');
                    _self.searchOpenings.start_date = moment(jQuery(_self.inputStartDateSaveSettingSearch.nativeElement)
                        .datepicker('getDate')).format(_self.formatDate);
                }
            });
            jQuery(_self.inputEndDateSaveSettingSearch.nativeElement).datepicker({
                dateFormat: 'MM dd, yy',
                numberOfMonths: jQuery(window).width() < 768 ? 1 : 2,
                minDate: 0,
                showButtonPanel: true,
                onSelect: () => {
                    _self.errorResponse.end_date = '';
                    _self.error_invalid_date = '';
                    _self.onChange('endDate');
                    _self.searchOpenings.end_date = moment(jQuery(_self.inputEndDateSaveSettingSearch.nativeElement)
                        .datepicker('getDate')).format(_self.formatDate);
                }
            });

            jQuery(_self.clickShowStartDateSaveSettingSearch.nativeElement).click(() => {
                jQuery(_self.inputStartDateSaveSettingSearch.nativeElement).datepicker('show');
            });

            jQuery(_self.clickShowEndDateSaveSettingSearch.nativeElement).click(() => {
                jQuery(_self.inputEndDateSaveSettingSearch.nativeElement).datepicker('show');
            });
        });
        jQuery(document).on('click', function (e: any) {
            if (jQuery(e.target).hasClass('result dropdown-menu')
                || jQuery(e.target).hasClass('error-message')) {
                // TODO: Close dropdown when click outside
            } else {
                jQuery(_self.search_field).closest(_self.input_group).removeClass('open');
            }
        });
    }
    ngOnDestroy() {
        this.selectedSlot = 0;
        if (this.searchPatientSubscriber) {
            this.searchPatientSubscriber.unsubscribe();
        }
        if (this.fetchAvailableSlotSubscriber) {
            this.fetchAvailableSlotSubscriber.unsubscribe();
        }
        if (this.bookAppointmentSubscriber) {
            this.bookAppointmentSubscriber.unsubscribe();
        }
        if (this.deleteSlotSubscriber) {
            this.deleteSlotSubscriber.unsubscribe();
        }

        if (this.fetchProviderOfficeSubscriber) {
            this.fetchProviderOfficeSubscriber.unsubscribe();
        }
        if (this.fetchAppointmentTypeListSubscriber) {
            this.fetchAppointmentTypeListSubscriber.unsubscribe();
        }
        if (this.fetchWaitlistrangeSubscriber) {
            this.fetchWaitlistrangeSubscriber.unsubscribe();
        }
        
    }

    // ========== General Modal Method ========== //

    dismiss() {
        this.searchOpenings.enable_email = 0;
        this.searchOpenings.enable_sms = 0;
        this.searchOpenings.patient = this.patient_id = null;
        this.requireMessage = new RequireMessage();
        jQuery(this.search_field).parent().removeClass('open');
        this.clearData();
        this.clearMsgError();
        if (this.isSaved) {
            window.location.reload();
        }

        // this._searchOpeningsComponent.getNotifySavedOpenAppointmentSetting(1);
        // this.saveSettingClicked();

    }
    clearData() {
        this.searchOpenings = new SearchOpenings();
        this.errorResponse = new SearchOpenings();
        jQuery(this.inputStartDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery(this.inputEndDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery('#startTime').val('');
        jQuery('#endTime').val('');
        this.successSaveOpeningSearch = '';
        this.errorSaveOpeningSearch = '';
        this.loadIconVerifiedPhone = false;
        this.searchDisabled = true;
    }
    clearDataOncesaved() {
        this.searchOpenings = new SearchOpenings();
        this.errorResponse = new SearchOpenings();
        jQuery(this.inputStartDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery(this.inputEndDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery('#startTime').val('');
        jQuery('#endTime').val('');
        //this.successSaveOpeningSearch = '';
        //this.errorSaveOpeningSearch = '';
        this.loadIconVerifiedPhone = false;
        this.searchDisabled = true;
    }
    onKeyUp() {
        this.searchOpenings.patient_user_id = '';
        this.errorResponse.patient = '';
        if (jQuery(this.search_field).val() === '') {
            this.patient_id = null;
            this.searchDisabled = true;
            jQuery('.search').removeClass('open');
        } else {
            this.searchDisabled = false;
        }
    }

    open() {
        this.fetchWaitlistrange();
        this.fetchProviderOffice();
        this.modal.open();
    }
    close() {
        this.selectedSlot = 0;
        this.modal.close();
    }
    fetchProviderOffice() {
        if (this.fetchProviderOfficeSubscriber) {
            this.fetchProviderOfficeSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();

        this.fetchProviderOfficeSubscriber = this.Service.getProviderOfficeLocation(null).subscribe(
            (success: any) => {
                const res = success;
                this._loadingIndicatorService.stop();
                if (res.error_code === 'RC000') {
                    this.providerOfficeLists = res.providerOffices;
                    //this.advancenoticedurations = res.advancenoticedurations;
                }
            }, (error: any) => {
                this._loadingIndicatorService.stop();
            });
    }
    fetchWaitlistrange() {
        if (this.fetchWaitlistrangeSubscriber) {
            this.fetchWaitlistrangeSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();

        this.fetchWaitlistrangeSubscriber = this.Service.fetchWaitlistrange().subscribe(
            (success: any) => {
                const res = success;
                this._loadingIndicatorService.stop();
                if (res.error_code === 'RC000') {
                    this.advancenoticedurations = res.advancenoticedurations;
                }
            }, (error: any) => {
                this._loadingIndicatorService.stop();
            });
    }
    
    onChangeProviderOffice(){
        let office_id = this.searchOpenings.provider_office_id;
        let gid = null;
        let lentt = this.providerOfficeLists.length;
        if (lentt > 0) {
            for (let i=0; i < lentt; i++){
                if (this.providerOfficeLists[i].id == office_id) {
                    gid = this.providerOfficeLists[i].practice_group_id;
                }
            }
        }
        this.fetchAppointmentTypeList(gid);
    }
    fetchAppointmentTypeList(group_id:any) {

        if (this.fetchAppointmentTypeListSubscriber) {
            this.fetchAppointmentTypeListSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();
        //getPracticeAppointmentTypeList(slot_id:any,is_limit=1, includeid=0, practice_group_id:any=0)
        this.fetchAppointmentTypeListSubscriber = this.Service.getPracticeAppointmentTypeList(null, 1, 0, group_id).subscribe(
            (success: any) => { //console.log(success);
                this.appointmentTypeList = success.apttypes;
                this._loadingIndicatorService.stop();
            }, (error: any) => {
                this._loadingIndicatorService.stop();
            });
    }
    /*fetchPatientUpcommingApt(patient_id: any, provider_id: any) {
    
        this.futureaptlists = [];
        this.selectedupcommingId = 0;
        this._loadingIndicatorService.start();

        console.log('patient_id1:' + patient_id);
    
        if (this.fetchPatientUpcommingAptSubscriber) {
          this.fetchPatientUpcommingAptSubscriber.unsubscribe();
        }
        this.fetchPatientUpcommingAptSubscriber = this.Service.fetchPatientUpcommingApt(patient_id, provider_id).subscribe((success: any) => {
          this._loadingIndicatorService.stop();
          if (success.aptlist !== undefined) {
            this.futureaptlists = success.aptlist;  
            console.log(this.futureaptlists);          
          }
        },(error: any) => {
          this._loadingIndicatorService.stop();
        });    
      }
      selecteRescheduleId(id:any){
        console.log('ID  =' +id);
        this.selectedupcommingId = (this.selectedupcommingId == id) ? 0 : id;
        console.log('selectedupcommingId = ' + this.selectedupcommingId);
      }*/

}
export class NewAvailableSlotClass {
    patient = '';
    date: string;
}

export class DataDateBook {
    selectedDate: any = moment();
    dateFrom: any = '';
    dateTo: any = '';
}

export class PatientInfoClass {
    id: number;
    first_name: string;
    last_name: string;
    dob: string;
    phone_mobile: number;
    is_family: boolean;
    uuid: any;
    family_member_id: any;
}

export class RequireMessage {
    patient_id: string;
    date: string;
    start_time: string;
    end_time: string;
}