import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  Router
} from '@angular/router';
import { AlertService } from '@app/shared/alert';
import { SystemEvent } from '@app/shared/system';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private _alertService: AlertService,
    private _systemEvent: SystemEvent,
    private _router: Router,) {
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let messageResponseError: string = '';
    let action: number = 0;
    let flagError: boolean = false;
    var self = this;
    const token = localStorage.getItem('user_token');
    let newParams = new HttpParams({fromString: request.params.toString()});
    newParams = newParams.append('___rtnRand', String(Math.random() * (new Date()).getTime()));
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        },
        params: newParams
      });
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
        },
        params: newParams
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('EVENT===>', event);
        }
        return event;
      }),
      catchError((error: any) => {
        if (error.status === 401) {
          self.clearLocalStorage();
          self.router.navigate(['/home', 'login']);
        }
        else if (typeof error.error.error_code !== 'string') { // No Internet Connection
          messageResponseError = 'Error: Please try again';
          action = 3;
          //Unable to connect to the Internet. Please check your connection and try to reload the page
          flagError = true;
        }
        else if(error.status === 500) // Out of Service
        {
          messageResponseError = 'Sorry, our websites are temporarily out of service';
          flagError = true;
        }
        else
        {
          switch (error.error.error_code)
          {
          case 'US002':// Inactive account
          case 'l':// Account role has been changed
          case 'TK001':// Token expire
          case 'TK002':// Token expire
          case 'TK003':// Token expire
            messageResponseError = error.error.error_messages;
            action = 1;
            flagError = true;
            break;
          case 'CP001':// EHR Online Status changed
            messageResponseError = error.error.error_messages;
            action = 2;
            flagError = true;
            break;
          case 'TL001' ://Page not found
            this._router.navigate(['/home', 'page-not-found']);
            this.clearLocalStorage();
          }
        }
        // return throwError(error);
        if (flagError) {
          let _action = action;
          action = 0; // reset action to default
          this._systemEvent.system.emit('system_ClearTimeInterval');
          if (this._alertService.title !== messageResponseError) {
              this._alertService.emitter('dismiss');
              setTimeout(() => {
                  this._alertService.title = messageResponseError;
                  this._alertService.message = '';
                  this._alertService.showDismissButton = false;
                  this._alertService.dismissButtonLabel = 'Close';
                  this._alertService.showCloseButton = true;
                  this._alertService.closeButtonLabel = 'OK';
                  this._alertService.emitter('open');
              }, 500);
          } else {
              this._alertService.showDismissButton = false;
              this._alertService.dismissButtonLabel = 'Close';
              this._alertService.showCloseButton = true;
              this._alertService.closeButtonLabel = 'OK';
              this._alertService.emitter('open');
          }
          this._alertService.onClose = () => {
              switch (_action) {
                  case 1:
                      // this._systemEvent.system.emit('system_LogOut');
                      this.clearLocalStorage();
                      this._router.navigate(['/home', 'login']);
                      break;
                  case 2:
                      this._systemEvent.system.emit('system_UpdateMode');
                      break;
                  case 3:
                    //this._systemEvent.system.emit('system_UpdateMode');
                    break;    
                  default:
                      this._systemEvent.system.emit('system_ReloadPage');
                      break;
              }
          };
          // return Observable.empty();
      }
      flagError = false;
      return throwError(error);
      }));
  }

  clearLocalStorage() {
    // Remove localStorage role id when logout
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role_id');
    localStorage.removeItem('user_status');
    localStorage.removeItem('user_email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('user_practice');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('managing_user_id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('user_token');
    localStorage.removeItem('last_active_token');
    localStorage.removeItem('storeData');
  }
}
