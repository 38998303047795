import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
// import { CORE_DIRECTIVES, FORM_DIRECTIVES } from '@angular/common';
import { SystemData, SystemEvent, SystemInterval, SystemService } from '@app/shared/system';
import { Utils } from '@app/shared/utils';
// import { AppointmentService } from '../services/appointment.service';
// import { WaitingTimeService } from '../../../../shared/waiting-time/services/waiting-time.service';
import { DataTableDirectives, RtnDataTableCoreComponent } from '@app/shared/datatable';
import { Select2Directive } from '@app/shared/form/select2.directive';
import { Select2DiseaseDirective } from '@app/shared/form/select2-disease.directive';
import { AutoCompleteDirective } from '@app/shared/form/auto-complete.directive';
import { MODAL_DIRECTIVES, ModalComponent } from '@app/shared/ng2-bs3-modal';
import { AppointmentDetailComponent } from '@app/appointments/modals/appointment-detail/appointment-detail.component';
import { SpeedometerWaittimeComponent } from '@app/wait-time/speedometer-waittime/speedometer-waittime.component';
import { FindAppointment } from '@app/shared/models/find-appointment';
import { SearchOpenings } from '@app/shared/models/search-openings';
import { PatientDetail } from '@app/shared/models/patient-detail';
import { MobileNumberComponent } from '@app/shared/mobile-number/mobile-number.component';
import { InputMaskDirective } from '@app/shared/form/input-mask.directive';
import { SimpleMaskDirective } from '@app/shared/form/simple-mask.directive';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { BookAppointmentComponent } from '@app/appointments/book-component/book-component.component';
import { AlertService } from '@app/shared/alert/alert.service';
import { clone } from '@app/shared/common.helper';
import { SelectProviderComponent } from '@app/providers/select-provider/select-provider.component';
import { RestService } from '@app/core/rest.service';

import * as moment from 'moment';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { PatientsService } from '@app/patients/patients.service';

import { AddPatientWaitlistComponent } from '@app/patients/find-appointments/add-patient-waitlist/add-patient-waitlist.component';

import { WaitlistFromAppointmentComponent } from '@app/appointments/modals/waitlist-from-appointment/waitlist-from-appointment.component';

declare let jQuery: any;

@Component({
    moduleId: module.id,
    selector: 'app-find-appointments',
    templateUrl: './find-appointments.component.html',
    styleUrls: ['./find-appointments.component.scss']
})
export class FindAppointmentsComponent implements OnInit, OnDestroy, AfterViewInit {
    // declare arrayActiveModule
    arrayModule = new Array();
    activeMessageModule = '';
    activeSchuduleModule = '';

    appointmentScreen = true;
    bookScreen = false;
    limitHeight = true;
    /*ngDatePicker: any;*/
    _weekStart = 0;
    _startDate: any = moment().format('MM-DD-YYYY');

    insuranceCompanies: any;
    insurancePlans: any;
    showAppointmentTable = false;
    showAppointmentByTimeTable = false;
    showOpeningsTable = false;
    showSpeedometer = false;
    isExpand = true;
    firstTabs = false;
    findMsg: string = null;
    findInFutureMsg: string = null;
    findInPastMsg: string = null;
    searchMsg: string = null;
    errorResponse: SearchOpenings = new SearchOpenings();
    messageResponseErrorSearchOpen: string = null;
    findAppointment: FindAppointment = new FindAppointment();
    searchOpenings: SearchOpenings = new SearchOpenings();
    appointmentData: any;
    appointmentInFutureData: any;
    appointmentInPastData: any;
    searchOpeningsResult: any;
    currentAppointment: any;
    selectedSpecialties: any;
    selectedDiseases: any;
    diseases: any;
    appointmentDateDate: any;
    searchOpeningsDistance: any;
    waitTimeInterval: any;
    eventListener: any;
    familyMembers: any = [];
    userFullName = '';
    ownerInsurance: any;
    providers: Array<any> = [];
    providersList: any = [];
    findApptSpecialty: any;
    searchOpenSpecialty: any;
    searchOpenDisease: any;
    selectedProviderName = '';
    noResult = '';
    showSettingOpeningsTable = true;
    userInfo: PatientDetail = new PatientDetail();
    successSaveOpeningSearch = '';
    errorSaveOpeningSearch = '';
    currentPageOpening: any;
    currentLimitOpening: any;
    settingRowOnPage = 10;
    loadIconVerifiedPhone = false;
    verifiedPhone = false;
    showNoticeSelectProviderSave = false;
    tempError: any;
    specialtyList: any;
    specialtyListByDisease: any;
    providerSelected: any = [];
    providerBackup: any = {
        providerSelected: [],
    };
    msgPartialAccess
        = 'Your account has not full access yet. Please update insurance or contact RTN admin to get full access account';
    checkedItems: any = [];
    openFromNotifi = false;

    appointmentTypeList : any;

    getSpecialtySubscriber: Subscription;
    /*getCompanyInsuranceSubscriber: Subscription;
    getCompanyPlanSubscriber: Subscription;*/
    findAppointmentSubscriber: Subscription;
    searchOpeningSubscriber: Subscription;
    saveSettingsSubscriber: Subscription;
    getListFamilyMemberSubscriber: Subscription;
    getInsuranceSettingsSubscriber: Subscription;
    getListProviderSubscriber: Subscription;
    getListDiseaseSubscriber: Subscription;
    onGetProfileListener: Subscription;
    getSavedSearchOpeningSubscriber: Subscription;
    getSpecialtiesSubscriber: Subscription;
    getListSpecialtyByDiseaseSubscriber: Subscription;
    onGetSpecialtyListener: Subscription;
    getApptByIdSubscriber: Subscription;
    updateHiddenStateSubscriber: Subscription;

    @ViewChild('BookAppointmentComponent') bookDirective: BookAppointmentComponent;
    @ViewChild('select2Directive1') specialtiesSelector: Select2Directive;
    @ViewChild('select2Directive2') specialtiesSelector2: Select2Directive;
    @ViewChild('select2DiseaseDirective') diseasesSelector: Select2DiseaseDirective;
    @ViewChild(AppointmentDetailComponent) appointmentDetailDirective: AppointmentDetailComponent;
    @ViewChild(SpeedometerWaittimeComponent) waitingTimeDirective: SpeedometerWaittimeComponent;
    /*@ViewChild(RtnCalendarComponent) RtnCalendarDirective: RtnCalendarComponent;*/
    @ViewChild('autoCompleteDirective1') autoCompleteDirective1: AutoCompleteDirective;
    @ViewChild('autoCompleteDirective2') autoCompleteDirective2: AutoCompleteDirective;
    /*@ViewChild('inputStartDateSaveSettingSearch') inputStartDateSaveSettingSearch: ElementRef;
    @ViewChild('clickShowStartDateSaveSettingSearch') clickShowStartDateSaveSettingSearch: ElementRef;
    @ViewChild('inputEndDateSaveSettingSearch') inputEndDateSaveSettingSearch: ElementRef;
    @ViewChild('clickShowEndDateSaveSettingSearch') clickShowEndDateSaveSettingSearch: ElementRef;*/
    @ViewChild('table') dataTableSearchOpening: RtnDataTableCoreComponent;
    @ViewChild('selectProvider') selectProvider: ModalComponent;
    
    @ViewChild(AddPatientWaitlistComponent) addPatientWaitListDirective: AddPatientWaitlistComponent;    
    @ViewChild(WaitlistFromAppointmentComponent) waitlistFromAppointmentComponent: WaitlistFromAppointmentComponent;
    

    constructor(// private _routeParams: RouteParams,
        private _router: Router,
        private activeRoute: ActivatedRoute,
        private _waitingTimeService: SpeedometerWaittimeComponent,
        private Service: PatientsService,
        private _systemInterval: SystemInterval,
        public _systemData: SystemData,
        private _systemEvent: SystemEvent,
        private _systemService: SystemService,
        public _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _alertService: AlertService) {

        /*this.ngDatePicker = new Object();
        this.ngDatePicker['todayHighlight'] = true;
        this.ngDatePicker['weekStart'] = this._weekStart;
        this.ngDatePicker['startDate'] = this._startDate;
        this.ngDatePicker['format'] = 'MM dd, yyyy';*/

        this.userFullName = localStorage.getItem('userFullName');

        this.appointmentDateDate = this._systemData.appointmentDateDate;

        this.searchOpeningsDistance = this._systemData.searchOpeningsDistance;
        this.searchOpeningsResult = {
            data: [],
            rowsOnPage: this.settingRowOnPage,
            numberOfPages: 0
        };

        this.getSpecialtySubscriber = this._systemEvent.specialty.subscribe((done: boolean) => {
            if (done) {
                // if (this.selectedSpecialties) {
                //   this.specialtiesSelector.updateData(this.selectedSpecialties);
                // }
                if (this.selectedSpecialties) {
                    this.specialtiesSelector2.updateData(this.selectedSpecialties);
                }
                // Set data default for specialty
                this.specialtyList = this._systemData.specialties;
                this.specialtyListByDisease = this._systemData.specialties;
            }
        });
    }

    ngOnInit() {
        // get active module array
        this.arrayModule = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
        if (!this.arrayModule[3]) {
            this.activeMessageModule = 'Module Provider is not active';
        }
        if (!this.arrayModule[5]) {
            this._router.navigate(['/home', 'page-not-found']);
            this.activeSchuduleModule = 'Module Schedule is not active';
        }


        // alert(this.activeRoute.snapshot.params['find']);
        delete (this._systemData.profile);
        this._systemService.getProfile(null);
        if (this._utils.isPartialActiveStatus()) {
            jQuery('#find-appt-tab').removeAttr('data-toggle').removeAttr('href');
        }

        /*if (this.getCompanyInsuranceSubscriber) {
            this.getCompanyInsuranceSubscriber.unsubscribe();
        }
        this.getCompanyInsuranceSubscriber = this._appointmentService.getInsuranceCompany().subscribe(
            (success: any) => {
                this.searchOpenings.insurance_company_id = '';
                this.searchOpenings.insurance_plan_id = '';
                this.insuranceCompanies = success.insurance_companies;
            }
        );*/
        // get family member list
        this._loadingIndicatorService.start();
        this.getListFamilyMemberSubscriber = this.Service.getListPatientFamilyMember().subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this._loadingIndicatorService.stop();
                if (success.patient_family.length > 0) {
                    this.familyMembers = [];
                    success.patient_family.forEach((item: any) => {
                        item.fullName = item.first_name + ' ' + item.last_name;
                        this.familyMembers.push(item);
                    });
                }
                // Get url params search
                const apptId = parseInt(this._utils.getUrlParameter('appt_id'), 10);
                if (this._router.url.split('?')[0] === '/patient/appointment/openings' && this.activeRoute.snapshot.queryParamMap.get('saved_id')) {
                    this.getSavedSearchOpeningSubscriber = this.Service
                        .getSavedSearchOpening(this.activeRoute.snapshot.queryParamMap.get('saved_id')).subscribe(
                            (res: any) => {
                                this.setValueSearchOpening(res.saved_search_opening);
                            });
                } else if (this._router.url.split('?')[0] === '/patient/appointment/openings' && this._router.url.split('=')[1] === 'provider') {
                    this.setValueSearchOpeningFromProviderDetail();
                } else if (this._router.url.split('?')[0] === '/patient/appointment/find' && apptId > 0) {
                    this.getApptByIdSubscriber = this.Service.getAppointmentDetailById('/' + apptId).subscribe(
                        (res: any) => {
                            if (res.appointment.patient_id === this._systemData.profile.patient_id) {
                                this.findAppointment.show = '0';
                            } else {
                                this.findAppointment.show = '1';
                            }
                            this.findAppointment.date = 0;
                            this.searchAppointment();
                        });
                }
            }
        );

        // get provider list
        this._loadingIndicatorService.start();
        this.getListProviderSubscriber = this.Service.getListProvider().subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.providersList = success.providers;
                if (success.providers.length > 0) {
                    success.providers.forEach((item: any) => {
                        this.providers.push(item.full_name);
                    });

                    this.autoCompleteDirective1.setSource(this.providers);
                    this.autoCompleteDirective2.setSource(this.providers);
                }
            }
        );

        this.onGetProfileListener = this._systemEvent.profile.subscribe((done: boolean) => {
            if (done) {
                this.userInfo = this._systemData.profile;
                
                this.searchOpenings.email = (this.userInfo.contact_email != undefined && this.userInfo.contact_email != '') ? this.userInfo.contact_email : this.userInfo.email;
                //this.searchOpenings.email = this.userInfo.email;
                this.searchOpenings.mobile = this.userInfo.phone_mobile;
                this.searchOpenings = this._utils.explodePhoneMobile(this.searchOpenings);

                //console.log('searchOpenings');
               // console.log(this.searchOpenings);
            }
        });

        // get Diseases list
        this._loadingIndicatorService.start();
        this.getListDiseaseSubscriber = this.Service.getDiseases().subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.diseases = success.diseases;
                /* if (this.selectedDiseases) {
                    this.diseasesSelector.updateData(this.selectedDiseases);
                } */
            }
        );

        //Get Appointment List
        this.Service.getAppointmentTypeList().subscribe(
            (success: any) => { //console.log(success);
                this.appointmentTypeList = success.apttypes;
            }
        )

        this._systemService.getSpecialties();
        this.switchTabs();
        this._loadingIndicatorService.stop();
    }

    ngAfterViewInit() {
        const _self = this;
        jQuery(document).ready(function () {
            /*jQuery(_self.inputStartDateSaveSettingSearch.nativeElement).datepicker({
                dateFormat: 'MM dd, yy',
                numberOfMonths: jQuery(window).width() < 768 ? 1 : 2,
                minDate: 0,
                showButtonPanel: true,
                onSelect: () => {
                    _self.onChange('startDate');
                    _self.searchOpenings.start_date = moment(jQuery(_self.inputStartDateSaveSettingSearch.nativeElement)
                        .datepicker('getDate')).format('YYYY-MM-DD');
                }
            });
            jQuery(_self.inputEndDateSaveSettingSearch.nativeElement).datepicker({
                dateFormat: 'MM dd, yy',
                numberOfMonths: jQuery(window).width() < 768 ? 1 : 2,
                minDate: 0,
                showButtonPanel: true,
                onSelect: () => {
                    _self.onChange('endDate');
                    _self.searchOpenings.end_date = moment(jQuery(_self.inputEndDateSaveSettingSearch.nativeElement)
                        .datepicker('getDate')).format('YYYY-MM-DD');
                }
            });
            // jQuery(document).on('click', this.clickShowStartDateSaveSettingSearch.nativeElement, function () {
            //   jQuery(_self.inputStartDateSaveSettingSearch.nativeElement).datepicker('show');
            // });
            jQuery(_self.clickShowStartDateSaveSettingSearch.nativeElement).click(() => {
                jQuery(_self.inputStartDateSaveSettingSearch.nativeElement).datepicker('show');
            });
            // jQuery(document).on('click', this.clickShowEndDateSaveSettingSearch.nativeElement, function () {
            //   jQuery(_self.inputEndDateSaveSettingSearch.nativeElement).datepicker('show');
            // });
            jQuery(_self.clickShowEndDateSaveSettingSearch.nativeElement).click(() => {
                jQuery(_self.inputEndDateSaveSettingSearch.nativeElement).datepicker('show');
            });*/

            _self._systemEvent.menu.emit('appointments');
            _self.eventListener = _self._systemEvent.event.subscribe((action: string) => {
                switch (action) {
                    case 'event_callSearchAppointment':
                        _self.openFromNotifi = true;
                        _self.searchAppointment();
                        break;
                    case 'event_callSelectedSpecialty':
                        _self.onChange('specialty');
                        break;
                    case 'event_callSelectedDisease':
                        _self.onChange('disease');
                        break;
                    case 'event_callSelectedMobile':
                        _self.onChange('mobile');
                        break;
                    default:
                        break;
                }
            });

            // Set padding placeholder select specialty
            jQuery('.select2-search__field').attr('style', 'padding-left: 7px');
            jQuery('.select2-selection .select2-selection__caret').css({ 'font-size': '12px', 'right': '9px' });

            jQuery('.select-time-save-setting, .select-insurance-info').css('color', '#999');
            jQuery(document).on('change', '.select-time-save-setting, .select-insurance-info', function () {
                jQuery(this).css('color', jQuery(this).val() === '' ? '#999' : '#555');
            });
            // jQuery('.select-time-save-setting, .select-insurance-info').change(function () {
            //   jQuery(this).css('color', jQuery(this).val() === '' ? '#999' : '#555');
            // });

            jQuery('#input-zip').bind({
                paste: function () {
                    _self.onChange('zip');
                }
            });

            jQuery('.disease-style > div > span > span > span').attr('style', 'padding-right: 25px');
            _self.specialtyList = _self._systemData.specialties;
            _self.specialtyListByDisease = _self._systemData.specialties;
        });

    }

    onChangeDisease() {
        const diseases = this.diseasesSelector.getData();
        if (this.getListSpecialtyByDiseaseSubscriber) {
            this.getListSpecialtyByDiseaseSubscriber.unsubscribe();
        }
        if (diseases.length !== 0) {
            this.getListSpecialtyByDiseaseSubscriber = this.Service.getSpecialtyByDisease(diseases.toString()).subscribe(
                (success: any) => {
                    this.specialtyListByDisease = success.specialties;
                }, () => {
                    // TODO
                }, () => {
                    this.specialtiesSelector2.clearData();
                    jQuery('#selectSpecialty2').select2('destroy').css('width', '100%');
                    this.specialtiesSelector2.resetSelect2(jQuery('#selectSpecialty2'));
                }
            );
        } else {
            this.specialtyListByDisease = this._systemData.specialties;
            this.specialtiesSelector2.clearData();
            jQuery('#selectSpecialty2').select2('destroy').css('width', '100%');
            this.specialtiesSelector2.resetSelect2(jQuery('#selectSpecialty2'));
        }
    }

    setValueSearchOpeningFromProviderDetail() {
        // Set specialties
        this.selectedSpecialties = [];
        const specialtiesList = localStorage.getItem('providerSpecialty');
        if (specialtiesList !== null) {
            if (this._systemData.specialties.length !== 0) {
                jQuery('#selectSpecialty2').select2('destroy').css('width', '100%');
                this.specialtiesSelector2.resetSelect2(jQuery('#selectSpecialty2'));
                this.specialtiesSelector2.updateData(specialtiesList.split(','));
            }
        }
        // Set provider name
        this.searchOpenings.providerName = localStorage.getItem('providerName');
    }

    setValueSearchOpening(data: any) {
        const _self = this;
        if (this._systemData.profile) {
            if (this._systemData.profile.id === data.patient_user_id) {
                this.searchOpenings = data;
                // Set patient name
                const intervalSetPatientName = setInterval(function () {
                    jQuery('#searchPatientName option').filter(function () {
                        return this.text === data.patient_name;
                    }).attr('selected', true);
                    if (jQuery('#searchPatientName').val() !== '') {
                        clearInterval(intervalSetPatientName);
                        const args = { page: 1, limit: this.settingRowOnPage };
                        _self.searchOpeningsSlot(args);
                    }
                }, 1000);
                // Set specialties
                this.selectedSpecialties = [];
                const specialtiesList = data.specialties;
                if (specialtiesList !== null) {
                    for (let i = 0; i < specialtiesList.length; i++) {
                        this.selectedSpecialties.push(specialtiesList[i].id);
                    }
                    if (this._systemData.specialties.length !== 0) {
                        this.specialtiesSelector2.updateData(this.selectedSpecialties);
                    }
                }
                // Set disease
                this.selectedDiseases = [];
                const diseasesList = data.diseases;
                if (diseasesList !== null) {
                    for (let i = 0; i < diseasesList.length; i++) {
                        this.selectedDiseases.push(diseasesList[i].id);
                    }
                    if (this.diseases.length !== 0) {
                        this.diseasesSelector.updateData(this.selectedDiseases);
                    }
                }
                // Set provider name
                this.searchOpenings.providerName = data.provider_name;
                this.searchOpenings.insurance_id = data.insurance_id;
                this.searchOpenings.insurance_number = data.insurance_number;
                // Set insurance company and plan
                /*if (data.insurance_company_id) {
                    this.changeCompanyPlan(data.insurance_company_id);
                }*/
                // Set mobile
                this.searchOpenings.mobile = data.mobile;
                this.searchOpenings = this._utils.explodePhoneMobile(this.searchOpenings);
                // Set start time end time
                if (data.start_time === '00:00:00' && data.end_time === '23:59:59') {
                    this.searchOpenings.start_time = '';
                    this.searchOpenings.end_time = '';
                }
                //this.searchOpenings.apt_type = 

            }
        }
    }

    /*changeCompanyPlan(companyId: any, change: boolean = false) {
        if (companyId !== '' && companyId !== '-1') {
            if (this.getCompanyPlanSubscriber) {
                this.getCompanyPlanSubscriber.unsubscribe();
            }
            this.getCompanyPlanSubscriber = this._appointmentService.getInsurancePlan(companyId).subscribe(
                (success: any) => {
                    this.insurancePlans = success.insurance_plans;
                    if (change) {
                        this.searchOpenings.insurance_plan_id = '';
                    }
                }
            );
        } else {
            this.insurancePlans = [];
            this.searchOpenings.insurance_plan_id = '';
        }
        this.onChange('insurance_plan_id');
    }*/

    ngOnDestroy() {
        if (this.onGetProfileListener) {
            this.onGetProfileListener.unsubscribe();
        }
        if (this.getSpecialtySubscriber) {
            this.getSpecialtySubscriber.unsubscribe();
        }
        if (this.findAppointmentSubscriber) {
            this.findAppointmentSubscriber.unsubscribe();
        }
        if (this.searchOpeningSubscriber) {
            this.searchOpeningSubscriber.unsubscribe();
        }
        if (this.saveSettingsSubscriber) {
            this.saveSettingsSubscriber.unsubscribe();
        }
        /*if (this.getCompanyInsuranceSubscriber) {
            this.getCompanyInsuranceSubscriber.unsubscribe();
        }
        if (this.getCompanyPlanSubscriber) {
            this.getCompanyPlanSubscriber.unsubscribe();
        }*/
        if (this.getListFamilyMemberSubscriber) {
            this.getListFamilyMemberSubscriber.unsubscribe();
        }
        if (this.getInsuranceSettingsSubscriber) {
            this.getInsuranceSettingsSubscriber.unsubscribe();
        }
        if (this.getListProviderSubscriber) {
            this.getListProviderSubscriber.unsubscribe();
        }
        if (this.getListDiseaseSubscriber) {
            this.getListDiseaseSubscriber.unsubscribe();
        }
        if (this.getSavedSearchOpeningSubscriber) {
            this.getSavedSearchOpeningSubscriber.unsubscribe();
        }
        if (this.getSpecialtiesSubscriber) {
            this.getSpecialtiesSubscriber.unsubscribe();
        }
        if (this.getListSpecialtyByDiseaseSubscriber) {
            this.getListSpecialtyByDiseaseSubscriber.unsubscribe();
        }
        if (this.onGetSpecialtyListener) {
            this.onGetSpecialtyListener.unsubscribe();
        }
        if (this.getApptByIdSubscriber) {
            this.getApptByIdSubscriber.unsubscribe();
        }
        clearInterval(this.waitTimeInterval);
        this._waitingTimeService.stopWaitTimeInterval();
    }

    /* ================================================================================ */
    /* ================================= Handle Event ================================= */

    /* ================================================================================ */

    switchTabs() {
        // this.firstTabs = this._routeParams.get('params') === 'find';
        this.firstTabs = this.activeRoute.snapshot.params.params !== 'find';
    }

    findMyAppTab() {
        if (this._utils.isPartialActiveStatus()) {
            this.openAlertMessage(this.msgPartialAccess);
            return;
        }
        jQuery('#selectSpecialty1').select2('destroy').css('width', '100%');
        this.specialtiesSelector.resetSelect2(jQuery('#selectSpecialty1'));
        jQuery('.nav-tabs li:nth-child(1)').addClass('active');
        jQuery('.nav-tabs li:nth-child(2)').removeClass('active');
        this.tempError = this.errorResponse['specialty'];
        this.errorResponse['specialty'] = '';
    }

    searchOpeningTab() {
        jQuery('#selectSpecialty2').select2('destroy').css('width', '100%');
        this.specialtiesSelector2.resetSelect2(jQuery('#selectSpecialty2'));
        this.errorResponse['specialty'] = this.tempError;
        jQuery('.nav-tabs li:nth-child(2)').addClass('active');
        jQuery('.nav-tabs li:nth-child(1)').removeClass('active');
    }

    openAlertMessage(msg: string = '') {
        this._alertService.onClose = () => {
            // Nothing
        };
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    changePatient(e: any) {
        this.errorResponse.patient_name = '';
        if (e === 'link_to_insurance') {
            this._router.navigate(['/patient', 'insurance']);
        } else if (e === 'null') {
            // get insurance setting of user login
            if (this.getInsuranceSettingsSubscriber) {
                this.getInsuranceSettingsSubscriber.unsubscribe();
            }
            this._loadingIndicatorService.start();
            this.getInsuranceSettingsSubscriber = this.Service.getInsuranceSettings().subscribe(
                (success: any) => {
                    this._loadingIndicatorService.stop();
                    this.ownerInsurance = success;
                    this.searchOpenings.insurance_id = this.ownerInsurance.patientInsurance.insurance_id;
                    this.searchOpenings.insurance_number = this.ownerInsurance.patientInsurance.insurance_number;
                    /*this.searchOpenings.insurance_company_id = this.ownerInsurance.patientInsurance.insurance_company_id;
                    if (this.searchOpenings.insurance_company_id) {
                        this.changeCompanyPlan(this.searchOpenings.insurance_company_id);
                        if (this.ownerInsurance.patientInsurance.insurance_plan_id) {
                            this.searchOpenings.insurance_plan_id = this.ownerInsurance.patientInsurance.insurance_plan_id;
                        }
                    } else {
                        if(typeof this.searchOpenings.insurance_company_id === 'undefined') {
                            this.searchOpenings.insurance_company_id = '';
                        } else {
                            this.searchOpenings.insurance_company_id = '-1';
                        }
                        this.searchOpenings.insurance_plan_id = '';
                    }*/
                    this.searchOpenings.zip = this.ownerInsurance.patientZip;
                    if (this.searchOpenings.zip) {
                        this.errorResponse.zip = null;
                    }
                    this.searchOpenings.patient_name = jQuery('#searchPatientName').find(':selected').text();
                    this.searchOpenings.family_id = null;
                }
            );
        } else {
            this.searchOpenings.patient_name = jQuery('#searchPatientName').find(':selected').text();
            this.searchOpenings.family_id = e;
            // get family member list
            if (this.getListFamilyMemberSubscriber) {
                this.getListFamilyMemberSubscriber.unsubscribe();
            }
            this.getListFamilyMemberSubscriber = this.Service.getListPatientFamilyMember().subscribe(
                (success: any) => {
                    const familyMembers = success.patient_family;
                    for (let i = 0; i < familyMembers.length; i++) {
                        if (familyMembers[i].family_id === parseInt(e, 10)) {
                            this.searchOpenings.insurance_id = familyMembers[i].insurance_id;
                            this.searchOpenings.insurance_number = familyMembers[i].insurance_number;
                            this.searchOpenings.zip = familyMembers[i].zip;
                            if (this.searchOpenings.zip) {
                                this.errorResponse.zip = null;
                            }
                            break;
                        }
                    }
                    /*if (success.patient_family.length > 0) {
                        let familyMembers = new Array();
                        success.patient_family.forEach((item: any) => {
                            item.fullName = item.first_name + ' ' + item.last_name;
                            familyMembers.push(item);
                        });
                        for (let i = 0; i < familyMembers.length; i++) {
                            if (familyMembers[i].family_id === parseInt(e)) {
                                if (familyMembers[i].insurance_company_id) {
                                    this.searchOpenings.insurance_company_id = familyMembers[i].insurance_company_id;
                                    this.changeCompanyPlan(familyMembers[i].insurance_company_id);
                                    if (familyMembers[i].insurance_plan_id) {
                                        this.searchOpenings.insurance_plan_id = familyMembers[i].insurance_plan_id;
                                    }
                                }
                                if (familyMembers[i].insurance_company_id === null) {
                                    if (!familyMembers[i].is_same_insurance) {
                                        this.searchOpenings.insurance_company_id = '-1';
                                    } else {
                                        this.searchOpenings.insurance_company_id = '';
                                    }
                                    this.searchOpenings.insurance_plan_id = '';
                                }
                                this.searchOpenings.zip = familyMembers[i].zip;
                                if (this.searchOpenings.zip) {
                                    this.errorResponse.zip = null;
                                }
                                break;
                            }
                        }
                    }*/
                }
            );
        }
    }

    callSearchAppointment() {
        if (location.search) {
            location.replace(location.hash);
        }
        this.searchAppointment();
    }

    searchAppointment() {
        this._loadingIndicatorService.start();
        this.findMsg = null;
        this.findInFutureMsg = null;
        this.findInPastMsg = null;
        this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = false;
        this.findAppointment.specialty = this.specialtiesSelector.getData();
        this.findApptSpecialty = this.findAppointment.specialty;
        if (this.findAppointmentSubscriber) {
            this.findAppointmentSubscriber.unsubscribe();
        }
        this.specialtiesSelector.updateData(this.findAppointment.specialty);
        const findAppointmentProviderName = this.verifyMiddleName(this.findAppointment.provider);
        this.findAppointmentSubscriber = this.Service.findAppointment(
            this.findAppointment.specialty,
            findAppointmentProviderName,
            this.findAppointment.date,
            this.findAppointment.show
        ).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                const res = success;
                if (res.error_code === 'RC000') {
                    this.appointmentData = res.appointments;
                    if (this.appointmentData.length > 0) {
                        if (this.findAppointment.date < 1) {
                            this.showAppointmentByTimeTable = true;
                            this.showAppointmentTable = false;
                            this.appointmentInFutureData = res.appointmentInFuture;
                            

                            //console.log(this.appointmentInFutureData.length);

                            if (this.appointmentInFutureData.length === 0) {
                                this.findInFutureMsg = null;

                                this.showSpeedometer = true;
                                /*setTimeout(() => {
                                    //console.log('selectTheFirstResult');
                                    this.selectTheFirstResult();
                                }, 1000);*/
                                //is_current_scheduleid
                            } else {
                                this.showSpeedometer = true;
                                /*setTimeout(() => {
                                    this.selectTheFirstFutureResult();
                                }, 1000);*/
                            }
                            this.appointmentInPastData = res.appointmentInPast;
                            if (this.appointmentInPastData.length === 0) {
                                this.findInPastMsg = null;
                            }
                        } else {
                            this.showAppointmentByTimeTable = false;
                            this.showAppointmentTable = true;
                            this.showSpeedometer = true;
                            /*setTimeout(() => {
                                this.selectTheFirstResult();
                            }, 1000);*/
                        }
                        
                        setTimeout(() => {
                            this.selectFirstSchdule();
                        }, 1000);
                    } else {
                        this.showAppointmentByTimeTable = false;
                        this.showAppointmentTable = false;
                        this.findMsg = 'There is no appointment';
                    }
                }
                this.specialtiesSelector.updateData(this.findApptSpecialty);
            }, (error: any) => {
                this._loadingIndicatorService.stop();
                this.specialtiesSelector.updateData(this.findApptSpecialty);
            }, () => {
                this._loadingIndicatorService.stop();
                this.specialtiesSelector.updateData(this.findApptSpecialty);
                // If click link from notification
                if (!this.openFromNotifi) {
                    const apptId = parseInt(this._utils.getUrlParameter('appt_id'), 10);
                    const popup = parseInt(this._utils.getUrlParameter('popup'), 10);
                    if (this._router.url.split('?')[0] === '/patient/appointment/find' && apptId > 0 && popup === 1) {
                        for (let i = 0; i < this.appointmentData.length; i++) {
                            if (this.appointmentData[i].id === apptId) {
                                this.appointmentDetails(this.appointmentData[i]);
                                this.openFromNotifi = true;
                                break;
                            }
                        }
                    }
                }
            });
        jQuery('.bulk.enable').css({ display: 'none' });
        jQuery('.bulk.disable').css({ display: 'block' });
    }

    /*selectTheFirstResult() {
        this._waitingTimeService.startWaitTimeInterval();
        this.appointmentData[0].isSelected = true;
        this.currentAppointment = this.appointmentData[0];
        
        //console.log(this.currentAppointment);

        this.waitingTimeDirective.appointmentId = this.currentAppointment.id;
        this.waitingTimeDirective.appointmentDetail.is_active = this.appointmentData[0].is_active;
        this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = true;
    }

    selectTheFirstFutureResult() {
        this._waitingTimeService.startWaitTimeInterval();
        this.appointmentInFutureData[0].isSelected = true;
        this.currentAppointment = this.appointmentInFutureData[0];
        this.waitingTimeDirective.appointmentId = this.currentAppointment.id;
        this.waitingTimeDirective.appointmentDetail.is_active = this.currentAppointment.is_active;
        this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = true;
    }*/
    selectFirstSchdule(){
        
        var index = this.appointmentInFutureData.findIndex((x: any) => x.is_current_scheduleid === true);
        var index1 = this.appointmentInPastData.findIndex((x: any) => x.is_current_scheduleid === true);

        var index2 = this.appointmentData.findIndex((x: any) => x.is_current_scheduleid === true);

        /*console.log('index : ' + index);
        console.log('index1 : ' + index1);
        console.log('appointmentInFutureData : ' , this.appointmentInFutureData);
        console.log('appointmentData : ' , this.appointmentData);*/


        if (index > -1) {
            this._waitingTimeService.startWaitTimeInterval();
            this.appointmentInFutureData[index].isSelected = true;
            this.appointmentData[index2].isSelected = true;
            this.currentAppointment = this.appointmentData[index2];
            this.waitingTimeDirective.appointmentId = this.currentAppointment.id;
            this.waitingTimeDirective.appointmentDetail.is_active = this.currentAppointment.is_active;
            this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = true;
            
        } else if (index === -1 && index1 > -1) {
            this._waitingTimeService.startWaitTimeInterval();
            this.appointmentData[index2].isSelected = true;
            this.currentAppointment = this.appointmentData[index2];           
            this.appointmentInPastData[index1].isSelected = true;
            this.waitingTimeDirective.appointmentId = this.currentAppointment.id;
            this.waitingTimeDirective.appointmentDetail.is_active = this.currentAppointment.is_active;
            this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = true;
        }
    }

    showWaitingTimeClicked(appointment: any) {
        this.showSpeedometer = true;
        this._waitingTimeService.startWaitTimeInterval();
        for (let i = 0; i < this.appointmentData.length; i++) {
            this.appointmentData[i].isSelected = appointment.id === this.appointmentData[i].id ? true : false;
        }
        this.currentAppointment = appointment;
        this.waitingTimeDirective.appointmentId = this.currentAppointment.id;
        this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = true;
        this.waitingTimeDirective.appointmentDetail.is_active = appointment.is_active;
    }

    onSelectedItem(id: any, event: any) {
        this.checkedItems[id] = event.target.checked;
        let active = false;
        this.checkedItems.forEach(function (val: any) {
            if (val === true) {
                active = true;
            }
        });
        if (active) {
            jQuery('.bulk.enable').css({ display: 'block' });
            jQuery('.bulk.disable').css({ display: 'none' });
        } else {
            jQuery('.bulk.enable').css({ display: 'none' });
            jQuery('.bulk.disable').css({ display: 'block' });
        }
    }

    appointmentHidden() {
        const data: number[] = [];
        this.appointmentInPastData.forEach(function (val: any) {
            if (val.is_hidden === true) {
                data.push(val.id);
            }
        });
        if (data.length <= 0) {
            return;
        }
        if (this.updateHiddenStateSubscriber) {
            this.updateHiddenStateSubscriber.unsubscribe();
        }
        this.updateHiddenStateSubscriber = this.Service.updateHiddenState({ data: data }).subscribe(
            (success: any) => {
                if (success.data !== null) {
                    this.searchAppointment();
                }
            }
        );
    }

    appointmentDetails(appointment: any) {
        this.appointmentDetailDirective.open(appointment);
    }

    showDetailAppointmentPopup() {
        console.log('currentAppointment : ', this.currentAppointment);
        this.appointmentDetailDirective.open(this.currentAppointment);
    }

    verifyDataSearchAndSaveOpening() {
        this.searchOpenings.provider = this.verifyMiddleName(this.searchOpenings.providerName);
        this.searchOpenings.specialty = this.specialtiesSelector2.getData();
        this.searchOpenSpecialty = this.searchOpenings.specialty;
        this.searchOpenDisease = this.searchOpenings.disease = this.diseasesSelector.getData();
    }

    clickBtnSearchOpening(arg: any) {
        const args = { page: arg['currentPage'] || 1, limit: arg['rowsOnPage'] };
        if (this.dataTableSearchOpening) {
            this.dataTableSearchOpening.setPage(1, this.searchOpeningsResult.rowsOnPage);
        }
        this.searchOpeningsSlot(args);
    }

    searchOpeningsSlot(args: any) {
        args.page = args['currentPage'] || 1;
        args.limit = args['rowsOnPage'] || this.searchOpeningsResult.rowsOnPage;
        this.currentPageOpening = args.page;
        this.currentLimitOpening = args.limit;
        // this.searchOpenings.distance = jQuery("#distance option:selected").val();
        this.formattedContructSearchOpenings(args);
        this.clearDataSaveSettingWhenClickSearch();
    }

    formattedContructSearchOpenings(args: any, action: string = '', dateCompare: string = '') {
        // this.searchOpeningsResult = [];
        this._loadingIndicatorService.start();
        this.messageResponseErrorSearchOpen = '';
        this.noResult = '';
        if (this.searchOpeningSubscriber) {
            this.searchOpeningSubscriber.unsubscribe();
        }
        this.verifyDataSearchAndSaveOpening();
        this.searchOpeningSubscriber = this.Service.searchOpenings(
            args.limit,
            args.page,
            this.searchOpenings.patient_name,
            this.searchOpenings.family_id,
            this.searchOpenings.disease,
            this.searchOpenings.specialty,
            this.searchOpenings.provider,
            /*this.searchOpenings.insurance_company_id,
            this.searchOpenings.insurance_plan_id,*/
            this.searchOpenings.insurance_id,
            this.searchOpenings.insurance_number,
            this.searchOpenings.zip,
            this.searchOpenings.distance,
            this.searchOpenings.show
        ).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.searchOpeningsResult.data = [];
                this.showOpeningsTable = false;
                const res = success;
                if (res.error_code === 'RC000') {
                    this.searchOpeningsResult.data = res.openings || [];
                    if (action === 'next') {
                        this.searchOpeningsResult.data
                            = this.formattedResultOpeningData(this.searchOpeningsResult.data, 'next', dateCompare);
                    } else if (action === 'previous') {
                        this.searchOpeningsResult.data
                            = this.formattedResultOpeningData(this.searchOpeningsResult.data, 'previous', dateCompare);
                    } else {
                        this.searchOpeningsResult.data
                            = this.formattedResultOpeningData(this.searchOpeningsResult.data);
                    }
                    this.searchOpeningsResult.numberOfPages = res.last_page || 0;
                    if (this.searchOpeningsResult.data.length > 0) {
                        this.showOpeningsTable = true;
                        this.insertEmptyTimeSearchOpenings();
                    }
                    this.showSettingOpeningsTable = false;
                } else if (res.error_code === 'RC103') {
                    this.showOpeningsTable = false;
                    this.showSettingOpeningsTable = false;
                    this.noResult = res.error_messages;
                } else if (res.error_code === 'RC020') {
                    this.errorResponse = res.error_fields;
                }
                this.callDataSearhOpeningBack();
            }, (error: any) => {
                this._loadingIndicatorService.stop();
                this.showOpeningsTable = false;
                const response = error.error;
                if (response.error_code === 'RC091') {
                    this.messageResponseErrorSearchOpen = response.error_messages;
                    this.showSettingOpeningsTable = false;
                }
                if (response.error_code === 'RC020') {
                    this.errorResponse = response.error_fields;
                }
                this.callDataSearhOpeningBack();
            }, () => {
                this._loadingIndicatorService.stop();
                this.callDataSearhOpeningBack();
            });
    }

    convertTimeShortToLong(str: string) {
        return (true === (new RegExp('pm$', 'i').test(str))) ? ((parseInt(str.substring(0, 2), 10) === 12
            ? parseInt(str.substring(0, 2), 10)
            : parseInt(str.substring(0, 2), 10) + 12)) + str.substring(2, 6) : str.substring(0, 6);
    }

    formattedResultOpeningData(searchOpeningsResult: any, action: string = '', dateCompare: string = '') {
        searchOpeningsResult.map((item: any, i: any) => {
            if (item.distance) {
                if (item.distance > 1) {
                    item.distance = 'Within ' + item.distance + ' miles';
                } else {
                    item.distance = 'Within ' + item.distance + ' mile';
                }
            }
            item.fullProviderName = this._utils
                .generateFullName(item.provider_first_name, item.provider_middle_name, item.provider_last_name);
            item.showMore = false;
            if (item.open_appointment) {
                const array = new Array;
                let index = 0;
                let currentDate: string;
                if (action === 'next') {
                    currentDate = moment(dateCompare).add(1, 'days').format('YYYY-MM-DD');
                } else if (action === 'previous') {
                    const numberDayBack = jQuery(window).width() < 768 ? 2 : 4;
                    currentDate = moment(dateCompare).subtract(numberDayBack, 'days').format('YYYY-MM-DD');
                } else {
                    currentDate = moment().format('YYYY-MM-DD');
                }
                const dateAfter1Days: string = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
                const dateAfter2Days: string = moment(currentDate).add(2, 'days').format('YYYY-MM-DD');
                const dateAfter3Days: string = moment(currentDate).add(3, 'days').format('YYYY-MM-DD');
                let haveCurrentDay = false;
                let haveAfter1Day = false;
                let haveAfter2Day = false;
                let haveAfter3Day = false;
                for (const element in item.open_appointment) {
                    // Show max 4 record
                    if (index < 4) {
                        const slot = [];
                        for (const el in item.open_appointment[element]) {
                            slot.push({
                                'slot_id': item.open_appointment[element][el].slot_id,
                                'end_time': this.convertTimeShortToLong(item.open_appointment[element][el].end_time),
                                'start_time': this.convertTimeShortToLong(item.open_appointment[element][el].start_time),
                                'location_info': item.open_appointment[element][el].location_info,
                                'location_name': item.open_appointment[element][el].location_name
                            });
                        }

                        if (moment(element).isSame(currentDate)) {
                            array.push({ 'date': element, 'slot': slot, 'temp': false });
                            index++;
                            haveCurrentDay = true;
                        } else if (moment(element).isSame(dateAfter1Days)) {
                            array.push({ 'date': element, 'slot': slot, 'temp': false });
                            index++;
                            haveAfter1Day = true;
                        } else if (moment(element).isSame(dateAfter2Days)) {
                            array.push({ 'date': element, 'slot': slot, 'temp': false });
                            index++;
                            haveAfter2Day = true;
                        } else if (moment(element).isSame(dateAfter3Days)) {
                            array.push({ 'date': element, 'slot': slot, 'temp': false });
                            index++;
                            haveAfter3Day = true;
                        }

                        // if (moment(element).isSame(currentDate)) {
                        //     array.push({'date': element, 'slot': item.open_appointment[element], 'temp': false});
                        //     index++;
                        //     haveCurrentDay = true;
                        // } else if (moment(element).isSame(dateAfter1Days)) {
                        //     array.push({'date': element, 'slot': item.open_appointment[element], 'temp': false});
                        //     index++;
                        //     haveAfter1Day = true;
                        // } else if (moment(element).isSame(dateAfter2Days)) {
                        //     array.push({'date': element, 'slot': item.open_appointment[element], 'temp': false});
                        //     index++;
                        //     haveAfter2Day = true;
                        // } else if (moment(element).isSame(dateAfter3Days)) {
                        //     array.push({'date': element, 'slot': item.open_appointment[element], 'temp': false});
                        //     index++;
                        //     haveAfter3Day = true;
                        // }
                    }
                }
                // Add empty slot
                const slotEmpty = [
                    { start_time: '', end_time: '' }, { start_time: '', end_time: '' }, { start_time: '', end_time: '' },
                    { start_time: '', end_time: '' }, { start_time: '', end_time: '' }, { start_time: '', end_time: '' }
                ];
                while (index < 4) {
                    if (!haveCurrentDay) {
                        array.push({ 'date': currentDate, 'slot': slotEmpty, 'temp': true });
                        haveCurrentDay = true;
                    } else if (!haveAfter1Day) {
                        array.push({ 'date': dateAfter1Days, 'slot': slotEmpty, 'temp': true });
                        haveAfter1Day = true;
                    } else if (!haveAfter2Day) {
                        array.push({ 'date': dateAfter2Days, 'slot': slotEmpty, 'temp': true });
                        haveAfter2Day = true;
                    } else if (!haveAfter3Day) {
                        array.push({ 'date': dateAfter3Days, 'slot': slotEmpty, 'temp': true });
                        haveAfter3Day = true;
                    }
                    index++;
                }
                array.sort(function (a: any, b: any) {
                    const keyA = a.date,
                        keyB = b.date;
                    // Compare the 2 dates
                    if (moment(keyA).isBefore(keyB)) {
                        return -1;
                    }
                    if (moment(keyA).isAfter(keyB)) {
                        return 1;
                    }
                    return 0;
                });
                item.open_appointment = array;
            }
        });
        return searchOpeningsResult;
    }

    callDataSearhOpeningBack() {
        this.specialtiesSelector2.updateData(this.searchOpenSpecialty);
        this.diseasesSelector.updateData(this.searchOpenDisease);
    }

    insertEmptyTimeSearchOpenings() {
        for (let i = 0; i < this.searchOpeningsResult.data.length; i++) {
            // const longestLength = 0;
            const data = this.searchOpeningsResult.data[i];
            const appointment = data.open_appointment;
            data.showMore = false;
            // get longest appointment length
            let showTextMore = false;
            if (appointment) {
                for (let j = 0; j < appointment.length; j++) {
                    const item = appointment[j];
                    const slotLength = item.slot.length;
                    if (item.temp) {
                        showTextMore = false;
                    } else {
                        showTextMore = slotLength <= 7 ? false : true;
                    }
                    // if (slotLength > longestLength) {
                    //   longestLength = slotLength;
                    // }
                    // if (longestLength % 2 !== 0) {
                    //   longestLength += 1;
                    // }
                    // if (longestLength < 7) {
                    //   longestLength = 7;
                    // }
                    appointment[j].showTextMore = showTextMore;
                }
                // insert '-'
                for (let j = 0; j < appointment.length; j++) {
                    // appointment[j].showTextMore = showTextMore;
                    const slot = appointment[j].slot;
                    const slotLength = slot.length;
                    const dumpData = { 'end_time': '', 'start_time': '' };
                    if (slot.length < 8) {
                        for (let k = 0; k < 8 - slotLength; k++) {
                            appointment[j].slot.push(dumpData);
                        }
                    }
                }
            }
        }
    }

    getPreviousSearchOpeningsData(dateCompare: string) {
        const numberDayBack = jQuery(window).width() < 768 ? 2 : 4;
        const previousDays = moment(dateCompare).subtract(numberDayBack, 'days').format('YYYY-MM-DD');
        const currentDate = moment().format('YYYY-MM-DD');
        if (moment(previousDays).isSameOrAfter(currentDate)) {
            const args = { limit: this.currentLimitOpening, page: this.currentPageOpening };
            this.formattedContructSearchOpenings(args, 'previous', dateCompare);
        }
    }

    getNextSearchOpeningsData(dateCompare: string) {
        const args = { limit: this.currentLimitOpening, page: this.currentPageOpening };
        this.formattedContructSearchOpenings(args, 'next', dateCompare);
        console.log()
    }

    showMoreSearchData(id: string, compareDate: string) {
        for (let i = 0; i < this.searchOpeningsResult.data.length; i++) {
            const data = this.searchOpeningsResult.data[i];
            const appointment = data.open_appointment;
            if (data.id === id) {
                data.showMore = true;
                for (let j = 0; j < appointment.length; j++) {
                    const item = appointment[j];
                    if (item.date === compareDate) {
                        item.showTextMore = false;
                        item.showTextLess = true;
                    }
                }
            }

        }
    }

    showLessSearchData(id: string, compareDate: string) {
        for (let i = 0; i < this.searchOpeningsResult.data.length; i++) {
            const data = this.searchOpeningsResult.data[i];
            const appointment = data.open_appointment;
            if (data.id === id) {
                data.showMore = false;
                for (let j = 0; j < appointment.length; j++) {
                    const item = appointment[j];
                    if (item.date === compareDate) {
                        item.showTextMore = true;
                        item.showTextLess = false;
                    }
                }
            }
        }
    }

    goToBookAppointment(provider_id: number = 0,
        patient_user_id: number = 0,
        date: string = '',
        start_time: string = '',
        end_time: string = '',
        slot_id: number = 0,
        location_info:any

    ) {
        // Set data for book appt
        this._systemData.selectOpenSlotData.provider_id = provider_id;
        this._systemData.selectOpenSlotData.patient_user_id = patient_user_id;
        this._systemData.selectOpenSlotData.family_id = this.searchOpenings.family_id;
        // this._systemData.selectOpenSlotData.start_time = date + ' ' + moment(start_time, ['h:mm A']).format('HH:mm');
        this._systemData.selectOpenSlotData.start_time = date + ' ' + moment(start_time, ['HH:mm']).format('HH:mm');
        this._systemData.selectOpenSlotData.start_time = moment(this._systemData.selectOpenSlotData.start_time).utc();
        // this._systemData.selectOpenSlotData.end_time = date + ' ' + moment(end_time, ['h:mm A']).format('HH:mm');
        this._systemData.selectOpenSlotData.end_time = date + ' ' + moment(end_time, ['HH:mm']).format('HH:mm');
        this._systemData.selectOpenSlotData.end_time = moment(this._systemData.selectOpenSlotData.end_time).utc();
        //slot id
        this._systemData.selectOpenSlotData.slot_Id = slot_id;
        this._systemData.selectOpenSlotData.location_info = location_info;

        //console.log(location_info);

        if (moment(this._systemData.selectOpenSlotData.start_time).isAfter(this._systemData.selectOpenSlotData.end_time)) {
            this._systemData.selectOpenSlotData.end_time
                = moment(this._systemData.selectOpenSlotData.end_time, 'YYYY-MM-DD HH:mm').add(1, 'day');
        }
        this._systemEvent.book.emit('book_callGetBookAppointment');
        this.appointmentScreen = false;
        this.bookScreen = true;
        // Goto top page
        console.log(this._systemData.selectOpenSlotData);
        jQuery(window).scrollTop(0);
    }

    backToSearchOpening() {
        this.appointmentScreen = true;
        this.bookScreen = false;
        this._systemData.appointmentId = 0;
        
        const args = { page: 1, limit: this.settingRowOnPage };
        this.clickBtnSearchOpening(args);
    }

    handlecallEventBookEmitter() {
        this.appointmentScreen = true;
        this.bookScreen = false;
        const args = { page: 1, limit: this.settingRowOnPage };
        this.clickBtnSearchOpening(args);

        /*if (this._systemData.appointmentId == 0) {
            this.appointmentScreen = true;
            this.bookScreen = false;
            const args = { page: 1, limit: this.settingRowOnPage };
            this.clickBtnSearchOpening(args);
        } else {        
            this.waitlistFromAppointmentComponent.open(this._systemData.appointmentId);
        }*/
    }
    /*handlecallEventAddWaitlistEmitter() {
        this._systemData.appointmentId = 0;        
        this.appointmentScreen = true;
        this.bookScreen = false;
        const args = { page: 1, limit: this.settingRowOnPage };
        this.clickBtnSearchOpening(args);
    }*/

    saveSettingClicked() {
        if (this._utils.isPartialActiveStatus()) {
            this.openAlertMessage(this.msgPartialAccess);
            return;
        }
        this.errorResponse.email = '';
        this.successSaveOpeningSearch = '';
        this.errorSaveOpeningSearch = '';
        if (this.saveSettingsSubscriber) {
            this.saveSettingsSubscriber.unsubscribe();
        }
        if (this.searchOpenings.enable_email) {
            this.searchOpenings.enable_email = 1;
        } else {
            this.searchOpenings.enable_email = 0;
        }
        if (this.searchOpenings.enable_sms) {
            this.searchOpenings.enable_sms = 1;
        } else {
            this.searchOpenings.enable_sms = 0;
        }
        this.verifyDataSearchAndSaveOpening();
        if (this.searchOpenings.disease) {
            this.searchOpenings.disease = this.searchOpenings.disease.toString();
        }
        if (this.searchOpenings.specialty) {
            this.searchOpenings.specialty = this.searchOpenings.specialty.toString();
        }
        if (this.searchOpenings.providerName) {
            this.searchOpenings.provider_name = this.searchOpenings.providerName;
        }
        this.searchOpenings.provider_selected = this.providerSelected ? this.providerSelected
            .map((provider: any) => provider.id).join() : '';
        this.searchOpenings.mobile
            = this._utils.implodePhoneMobile(this.searchOpenings.first, this.searchOpenings.middle, this.searchOpenings.last);
        
        /* start and end time update */
        this.searchOpenings.start_time = (this.searchOpenings.start_time != undefined && this.searchOpenings.start_time != null && this.searchOpenings.start_time != '') ? this.searchOpenings.start_time : '00:00:00';
        this.searchOpenings.end_time = (this.searchOpenings.end_time != undefined && this.searchOpenings.end_time != null && this.searchOpenings.end_time != '') ? this.searchOpenings.end_time : '23:59:59';

        const startTime:any = this.searchOpenings.start_time.split(':');
        const endTime:any = this.searchOpenings.end_time.split(':');

        const startDatetime:any = moment(this.searchOpenings.start_date).hour(startTime[0])
            .minute(startTime[1]).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
        const endDatetime:any = moment(this.searchOpenings.end_date).hour(endTime[0]).minute(endTime[1])
            .second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');

        this.searchOpenings.start_datetime = startDatetime;
        this.searchOpenings.end_datetime = endDatetime;
        /* end time */

//console.log(this.searchOpenings);return false;
        this.saveSettingsSubscriber = this.Service.saveSettings(this.searchOpenings).subscribe(
            (success: any) => {
                const res = success;
                if (res.error_code === 'RC000') {
                    this.successSaveOpeningSearch = res.error_messages;
                    this.loadIconVerifiedPhone = !!this.searchOpenings.enable_sms;
                    this.verifiedPhone = res.verifiedPhone;
                    this.clearDataSaveSettingWhenSaveSearch();
                }
            }, (error: any) => {
                const response = error.error;
                this.loadIconVerifiedPhone = false;
                if (response.error_code === 'RC091') {
                    this.messageResponseErrorSearchOpen = response.error_messages;
                } else if (response.error_code === 'RC020') {
                    this.errorResponse = response.error_fields;
                } else if (response.error_code === 'RC101' || response.error_code === 'RC089' || response.error_code === 'RC135' || response.error_code === 'RC142') {
                    this.errorSaveOpeningSearch = response.error_messages;
                }
                
                this.callDataSearhOpeningBack();
            }, () => {
                this.callDataSearhOpeningBack();
            });
    }

    clearDataSaveSettingWhenClickSearch() {
        
        this.searchOpenings.email = (this.userInfo.contact_email != undefined && this.userInfo.contact_email != '') ? this.userInfo.contact_email : this.userInfo.email;
        this.searchOpenings.mobile = this.userInfo.phone_mobile;
        this.searchOpenings = this._utils.explodePhoneMobile(this.searchOpenings);
        this.loadIconVerifiedPhone = false;
        this.searchOpenings.enable_email = 0;
        this.searchOpenings.enable_sms = 0;
        //jQuery(this.inputStartDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        //jQuery(this.inputEndDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        this.searchOpenings.start_date = '';
        this.searchOpenings.end_date = '';
        this.searchOpenings.start_time = '';
        this.searchOpenings.end_time = '';
        this.searchOpenings.start_datetime = '';
        this.searchOpenings.end_datetime = '';
        this.successSaveOpeningSearch = '';
        this.errorSaveOpeningSearch = '';
        this.errorResponse.start_date = null;
        this.errorResponse.end_date = null;
        this.errorResponse.start_time = null;
        this.errorResponse.end_time = null;
        this.errorResponse.email = null;
        this.errorResponse.mobile = null;
    }

    verifyMiddleName(name: string = '') {
        if (name) {
            const check = name.search('. ');
            if (check > -1) {
                return name.replace('. ', ' ');
            } else {
                return name;
            }
        } else {
            return name;
        }
    }

    onChange(event: any) {
        switch (event) {
            case 'patient_name':
                this.errorResponse.patient_name = null;
                break;
            case 'disease':
                this.errorResponse.disease = null;
                break;
            case 'specialty':
                this.errorResponse.specialty = null;
                break;
            case 'zip':
                this.errorResponse.zip = null;
                break;
            case 'startDate':
                this.errorResponse.start_date = null;
                this.removeMessage();
                break;
            case 'endDate':
                this.errorResponse.end_date = null;
                this.removeMessage();
                break;
            case 'startTime':
                this.errorResponse.start_time = null;
                this.removeMessage();
                break;
            case 'endTime':
                this.errorResponse.end_time = null;
                this.removeMessage();
                break;
            case 'email':
                this.errorResponse.email = null;
                this.removeMessage();
                break;
            case 'mobile':
                this.errorResponse.mobile = null;
                this.removeMessage();
                break;
            case 'apt_type':
                this.errorResponse.apt_type = null;
                this.removeMessage();
                break;    
            /*case 'insurance_plan_id':
                this.errorResponse.insurance_plan_id = null;
                break;*/
        }
    }

    removeMessage() {
        this.successSaveOpeningSearch = '';
        this.errorSaveOpeningSearch = '';
    }

    /* ================================================================================ */

    toggleSelectProviderDialog() {
        this.providerBackup = {
            providerSelected: clone(this.providerSelected),
        };
        
        this.selectProvider.open();
    }

    cancelSelectProviderDialog() {
        this.providerSelected = clone(this.providerBackup.providerSelected);
        this.selectProvider.dismiss();
    }

    getSelectedProviderName(): string {
        return this.providerSelected.map((provider: any) => provider.name).join(', ');
    }
    clearDataSaveSettingWhenSaveSearch() {
        
        this.searchOpenings.email = (this.userInfo.contact_email != undefined && this.userInfo.contact_email != '') ? this.userInfo.contact_email : this.userInfo.email;
        this.searchOpenings.mobile = this.userInfo.phone_mobile;
        this.searchOpenings = this._utils.explodePhoneMobile(this.searchOpenings);
        this.loadIconVerifiedPhone = false;
        this.searchOpenings.enable_email = 0;
        this.searchOpenings.enable_sms = 0;
        //jQuery(this.inputStartDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
       //jQuery(this.inputEndDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        this.searchOpenings.start_date = '';
        this.searchOpenings.end_date = '';
        this.searchOpenings.start_time = '';
        this.searchOpenings.end_time = '';
        
        this.errorResponse.start_date = null;
        this.errorResponse.end_date = null;
        this.errorResponse.start_time = null;
        this.errorResponse.end_time = null;
        this.errorResponse.email = null;
        this.errorResponse.mobile = null;
    }
    // open waitlist from detail window
    handlecallEventWaitlistFrombookEmitter(){      
        console.log('ID=' + this._systemData.appointmentId);
        this.waitlistFromAppointmentComponent.open(this._systemData.appointmentId);
    }
    // close from apt confirmation / apt details page wait list
    handleCloseWaitlistEmitter($event:any) {
        console.log($event);
        //this.backToSearchOpening();
    }
    // open wait list form after booking confirmation
    handlecallEventAddwaitlistBookEmitter(){
        
        this.appointmentScreen = true;
        this.bookScreen = false;
        const args = { page: 1, limit: this.settingRowOnPage };
        this.clickBtnSearchOpening(args);

        this.waitlistFromAppointmentComponent.open(this._systemData.appointmentId);
    }
}
