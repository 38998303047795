export class ProviderDetail {
    [key:string]: any;
    first_name: string = '';
    middle_name: string = '';
    billing: any;
    last_name: string = '';
    fullName: string = '';
    dob: string = '';
    is_mapped: boolean;
    credentials: string = '';
    npi: string = '';
    office_name: string = '';
    specialties: any;
    website_url: string = '';
    email: string = '';
    phone_mobile: string = '';
    phone_mobile_tmp: string = '';
    address: string = '';
    address2: string = '';
    city: string = '';
    state_code: string = '';
    state: string = '';
    zip: string = '';
    website: string = '';
    password: string = '';
    password_confirmation: string = '';
    website_display: string = '';
    opening_hours: any  = [];
    opening_hours_display: any;
    credentials_display: string = '';
    specialties_display: string = '';
    address_display: string = '';
    /*insurance_plan_id: string = '';
    insurance_company_id: string = '';*/
    insurances: any;
    /*office_address: string = '';
    office_address2: string = '';
    office_city: string = '';
    office_state_code: string = '';
    office_state: string = '';
    office_zip: string = '';*/
}
