import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PatientsComponent} from '@app/patients/patients.component';
import {InsuranceComponent} from '@app/patients/insurance/insurance.component';
import {PatientAccountComponent} from '@app/patients/account/account.component'
import { DoctorSearchlComponent } from '@app/patients/doctor-search/doctor-search.component';
import { DoctorFollowlComponent } from '@app/patients/doctor-follow/doctor-follow.component';
import { DoctorDetailComponent } from '@app/patients/doctor-detail/doctor-detail.component';
import {extract} from '@app/core';
import {Role} from '@app/core/role';
import {AuthGuard} from '@app/core/auth.guard';
import {NotificationDashboardComponent} from '@app/notifications/notification-dashboard/notification-dashboard.component';
import {NotificationListComponent} from '@app/notifications/notification-list/notification-list.component';
import {FindAppointmentsComponent} from '@app/patients/find-appointments/find-appointments.component';
import {PatientsActivateComponent } from '@app/patients/active/activate.component';
import {DashboardComponent} from '@app/patients/dashboard/dashboard.component';
import {PatientChangePasswordComponent} from '@app/patients/change-password/change-password.component';

const routes: Routes = [{
  path: '',
  component: PatientsComponent,
  children: [
    {
      path: 'patient/insurance',
      component: InsuranceComponent,
      data: {title: extract('RTN'), roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/find-providers',
      component: DoctorSearchlComponent,
      data: {roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/follow-providers',
      component: DoctorFollowlComponent,
      data: {roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/provider-detail/:id',
      component: DoctorDetailComponent,
      data: {roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/dashboard',
      component: DashboardComponent,
      data: {title: extract('RTN'), roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/notification',
      component: NotificationListComponent,
      data: {title: extract('RTN'), roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/edit',
      component: PatientAccountComponent,
      data: {title: extract('RTN'), roles: [Role.PATIENT]},
      canActivate:[AuthGuard]
    },
    {
      path: 'patient/appointment/:params',
      component: FindAppointmentsComponent,
      data: {title: extract('RTN'), roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/activated',
      component: PatientsActivateComponent,
      data: {title: extract('RTN'), roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
    {
      path: 'patient/change-password',
      component: PatientChangePasswordComponent,
      data: {title: extract('RTN'), roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientsRoutingModule {
}
