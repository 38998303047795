import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { SuperManagingUserDetail } from '../../shared/models/superManagingUserDetail';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-super-user',
  templateUrl: './edit-super-user.component.html',
  styleUrls: ['../admin.component.scss','./edit-super-user.component.scss'],
  moduleId: module.id,
})
export class EditSuperUserComponent implements OnInit {

  superUserDetail: SuperManagingUserDetail = new SuperManagingUserDetail();
  responseError: SuperManagingUserDetail = new SuperManagingUserDetail();
  updateNewSuperUserSubscriber: Subscription;
  onGetUserSubscriber: Subscription;
  loadPracticeListSubscriber: Subscription;
  practiceList: any = [];
  idSelected: number;
  successMessage: string;
  emailInDb: string = '';
  currentStatus: number;
  responseError1: SuperuserPasswordItem = new SuperuserPasswordItem();

  @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
  @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;
  
  password: string;
  password_confirmation: string;
  notify_to : boolean = false;
  successMessage1 : string;
  updateNewSuperUserPasswordSubscriber : Subscription;

  constructor(
      public _systemData: SystemData,
      private _systemService: SystemService,
      private _adminService: AdminRestService,
      private _routeParams: ActivatedRoute,
      private _loadingIndicatorService: LoadingIndicatorService,
      private _alertService: AlertService
  ) {
      var self = this;
      this._loadingIndicatorService.start();
      this.idSelected = parseInt(this._routeParams.snapshot.paramMap.get('id'));
      this.onGetUserSubscriber = this._adminService.getUser(this.idSelected).subscribe(
      (success: any) => {
        self._loadingIndicatorService.stop();
        self.superUserDetail = success.user;
          // Set format for phone
        self.superUserDetail.phone_mobile = this.superUserDetail.phone_mobile.replace('+1 ', '');
        self.superUserDetail.state_code = this.superUserDetail.state_code === null ? '' : this.superUserDetail.state_code.toUpperCase();
        self.emailInDb = this.superUserDetail.email;
        self.currentStatus = this.superUserDetail.status;
      }, (error: any) => {
        self._loadingIndicatorService.stop();
      });
      this.loadPracticeListSubscriber = this._adminService.getPracticeList().subscribe(
          (success: any) => {
            self.practiceList = success.practices;
          }
      );
  }

  ngOnInit() {
      this._systemService.getStates();
  }

  ngOnDestroy() {
      if (this.loadPracticeListSubscriber) {
          this.loadPracticeListSubscriber.unsubscribe();
      }
      if (this.updateNewSuperUserSubscriber) {
          this.updateNewSuperUserSubscriber.unsubscribe();
      }
      if (this.onGetUserSubscriber) {
          this.onGetUserSubscriber.unsubscribe();
      }
        if (this.updateNewSuperUserPasswordSubscriber) {
            this.updateNewSuperUserPasswordSubscriber.unsubscribe();
        }
  }

  updateSuperUserForm(formVals: any) {
      const self = this;
      this.successMessage = null;
      let value = Object.assign({}, formVals);
      if (this.currentStatus === 0 && this.emailInDb !== value.email) {
          this._alertService.onClose = () => {
              this.superUserDetail.email = this.emailInDb;
          };
          this._alertService.showDismissButton = false;
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'OK';
          this._alertService.title = 'The user account has not been activated.';
          this._alertService.message = '';
          this._alertService.emitter('open');
      } else {
          this._loadingIndicatorService.start();
          if (this.updateNewSuperUserSubscriber) {
              this.updateNewSuperUserSubscriber.unsubscribe();
          }
          this.updateNewSuperUserSubscriber = this._adminService.updateSuperUser(this.idSelected, value).subscribe(
              (success: any) => {
                  this._loadingIndicatorService.stop();
                  this.successMessage = success.error_messages;
                  this.responseError = new SuperManagingUserDetail();
              },
              (error: any) => {
                  this._loadingIndicatorService.stop();
                  let res = error.error;
                  this.responseError = res.error_fields;
              }
          );
      }
  }

  onKeyUp(error: string) {
      switch (error) {
          case 'email':
              this.responseError['email'] = null;
              break;
          case 'phone_mobile':
              this.responseError['phone_mobile'] = null;
              break;
          case 'address':
              this.responseError['address'] = null;
              break;
          case 'address2':
              this.responseError['address2'] = null;
              break;
          case 'city':
              this.responseError['city'] = null;
              break;
          case 'state_code':
              this.responseError['state_code'] = null;
              break;
          case 'zip':
              this.responseError['zip'] = null;
              break;
          case 'first_name':
              this.responseError['first_name'] = null;
              break;
          case 'middle_name':
              this.responseError['middle_name'] = null;
              break;
          case 'last_name':
              this.responseError['last_name'] = null;
              break;
          case 'office_name':
              this.responseError['office_name'] = null;
              break;
            case 'password':
                this.responseError['password'] = null;
                break;
            case 'confirm_password':
                this.responseError['confirm_password'] = null;
                break;       
      }
  }
  updatePasswordFromAdmin(formVals: any) {
    const self = this;
    this.successMessage = null;
    let value = Object.assign({}, formVals);
    //console.log(value);

    this._loadingIndicatorService.start();
        if (this.updateNewSuperUserPasswordSubscriber) {
            this.updateNewSuperUserPasswordSubscriber.unsubscribe();
        }
        this.updateNewSuperUserPasswordSubscriber = this._adminService.updatePasswordFromAdmin(this.idSelected, value).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.successMessage1 = success.error_messages;
                this.responseError1 = new SuperuserPasswordItem();
                this.password = null;
                this.password_confirmation = null;
                this.notify_to = false;
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let res = error.error;
                this.responseError1 = res.error_fields;
                this.password = null;
                this.password_confirmation = null;
                this.notify_to = false;
            }
        );
    }
}

export class SuperuserPasswordItem {
    password: string = null;
    password_confirmation: string = null;
    notify_to : boolean = false;
  }
