import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ModalComponent} from '@app/shared/ng2-bs3-modal';

@Component({
    moduleId: module.id,
    selector: 'rtn-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})

export class RtnFeedbackComponent{
    @ViewChild('feedbackModal') modal: ModalComponent;
    model: Feedback = new Feedback();

    messageErrorSubject: string; //Show message error subject
    constructor() {
        //TODO
    }

    dismiss() {
        this.modal.dismiss();
        this.model.subject = null;
        this.model.content = null;
        this.messageErrorSubject = null;
    }

    open() {
        this.modal.open();
    }

    close() {
        this.modal.close();
    }

    onKey() {
        this.messageErrorSubject = null;
    }

    private _fnFeedback: Function;
    public set sendFeedback($_fnFeedback: Function) {
        this._fnFeedback = $_fnFeedback;
    }

    public get sendFeedback() {
        let _self = this;
        return function () {
            this._fnFeedback.apply(_self);
        };
    }
}

export class Feedback {
    subject: string;
    content: string;
}