<div class="marketing-portal clear-both" role="main">
    <div class="marketing-page container change-password">
        <div class="content-header row" [style.min-height.px]="minHeight">
            <div class="login-form col-xs-12 col-sm-12 col-md-10 offset-md-1 offset-sm-0 offset-xs-0">
                <div class="login-form-body col-xl-6 col-md-8">
                    <h1 class="text-uppercase margin-bottom-0 pb-2">CHANGE PASSWORD</h1>
                    <h3 class="row d-block d-sm-none d-none d-sm-block d-md-none text-center pb-2">CHANGE PASSWORD</h3>
                    <form novalidate #form="ngForm" (ngSubmit)="reset(form.value)">
                        <div *ngIf="error" class="alert alert-danger" role="alert">{{errorMessage}}</div>
                        <div class="form-body">
                            <div class="form-group">
                                <label for="password">New Password</label>
                                <i class="fa fa-exclamation-circle icon-warning" (click)="passwordFormatToolipDirective.toggle($event)"></i>
                                <input tabindex="1" type="password" autoFocusCustom [(ngModel)]="password" name="password" id="password" class="form-control" [class.input-error]="responseError['password']" ngControl="password" maxlength="50" required>
                                <span class="message-error" *ngIf="responseError['password']">{{responseError['password']}}</span>
                            </div>
                            <div class="form-group">
                                <label for="password_confirm">Confirm New Password</label>
                                <input tabindex="2" type="password" [(ngModel)]="password_confirm" name="password_confirm" id="password_confirm" class="form-control" [class.input-error]="responseError['password_confirm']" ngControl="password_confirm" maxlength="50" required>
                                <span class="message-error" *ngIf="responseError['password_confirm']">{{responseError['password_confirm']}}</span>
                            </div>
                            <div class="row form-submit">
                                <div class="col-xs-12 col-sm-12 col-md-4 no-padding">
                                    <button type="submit" [disabled]="!resetForm.valid" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <rtn-password-format-tooltip></rtn-password-format-tooltip>
</div>
<rtn-home-footer-appointment></rtn-home-footer-appointment>