import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {Event} from '@app/appointments/timeline/timeline.component';
import {Utils} from '@app/shared/utils';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'rtn-timeline-tooltip',
  templateUrl: './timeline-tooltip.component.html',
  styleUrls:['./timeline-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TimelineTooltipComponent {
  event: Event;
  name = '';

  constructor(
    private elementRef: ElementRef,
    private _utils: Utils
  ) {
    // TODO
  }

  updateEvent(event: Event) {
    this.event = event;
  }

  getHtml() {
    return jQuery(this.elementRef.nativeElement).html();
  }
}
