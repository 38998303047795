<modal #Timeline_MassNotification_Patient>
  <form class="notification-mass">
      <div class="row noMargin">
          <div class="col-sm-4 bg">
              <div class="header">Send to 
                  <div class="arrow-left"></div>
              </div>
              <img src="/assets/img/mass-noti.png">
          </div>
          <div class="col-sm-8 inputForm">
                <div class="form-group mt-4">
                  <label >Send to <b>{{patientName}}</b></label>
                  <label class="pull-right" *ngIf="eventEntry!= undefined && eventEntry != null">
                    <a title="Open Waittime View" [routerLink]="['/appointment/waittime', eventEntry.data.appointmentid_encode]" target="_blank" *ngIf="eventEntry.data.appointment_is_allowed == 1 && eventEntry.data.cancel_status_text == ''"><i class="far fa-clock"></i></a>
                  </label>
                </div>
                <div class="form-group mt-2">
                    <label >Email :</label>
                    <input type="email" name="email" id="email" class="form-control" [(ngModel)]="patientSettings.email" [class.input-error]="errorEmailMessage" [readonly]="true"/>
                </div>

                <div class="form-group mt-2">
                    <label >Phone :</label>
                    <div class="row">
                        <div class="col-sm-10 col-md-10 px-0">
                            <rtn-mobile-number class="font-text" id="phone_mobile" [formInput]="mobile" [error]="errorEmailMessage" (removeError)="removeMessage()" [isDisabled]="true"></rtn-mobile-number>
                        </div>
                        <div class="col-sm-1 col-md-1 px-0 pt-1 pl-1">
                            <!--<a href="javascript:void(0);" (click)="clearMobileField('mobile')"><i class="fa fa-times" aria-hidden="true">&nbsp;</i></a>-->
                        </div>
                    </div>                                              
                </div>

                <div class="form-group mt-2">
                    <label>Alt. Phone :</label>
                    <div class="row">
                        <div class="col-sm-10 col-md-10 px-0">
                            <rtn-mobile-number class="font-text" id="alt_mobile" [formInput]="alt_mobile" [error]="errorEmailMessage" (removeError)="removeMessage()"></rtn-mobile-number>
                        </div>
                        <div class="col-sm-1 col-md-1 px-0 pt-1 pl-1">
                            <a href="javascript:void(0);" (click)="clearMobileField('alt_mobile')"><i class="fa fa-times" aria-hidden="true">&nbsp;</i></a>
                        </div>
                    </div>                                                                        
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 no-padding-left-right message-error">{{ errorEmailMessage }}</div>
                <ng-container *ngIf="notifytype == 'patient_activation'">
                    <div class="form-group mt-2">
                        <label>Content:</label>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 px-0">
                                <img src="/assets/rtnmeter-images/images/waitmeter.png" class="waitmeter-img">
                            </div>                            
                        </div>                                              
                    </div>
                    <div>
                        <div class="col-sm-12 text-left help-block mt-2 mb-2">
                            <strong>Notes : </strong> 
                            <i>Sending the Wait Time Meter Link will Activate Messages to the Patient for this Appointment Only.</i> 
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="notifytype == 'patient_sendto'">
                    <div class="form-group mt-2">
                        <label >Notification Template:</label>
                        <div class="input-group select width-full">                    
                            <select class="form-control textbox-edit-form" id="notification_text" [(ngModel)]="notification_text" name="notification_text" (change)="changeTemplate($event.target.value)">
                                <option value="">Select Template</option>
                                <option *ngFor="let customtext of sendto_templates; let idx= index" id="idx" [value]="customtext">{{ customtext }}</option>
                            </select> 
                            <div class="input-group-append cursorPointer select-state pr-0">
                                <i class="fa fa-caret-down"></i>
                            </div>
                        </div>                
                    </div>
                    <div class="content">Content 
                        <textarea name="something2" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" [class.input-error]="messageRequire != null" class="input form-control paddingInput" rows="8" [ngModel]="massNotiModel.content" (ngModelChange)="massNotiModel.content = $event" maxlength="255"></textarea>
                    </div>
                    <div>
                        <div class="col-sm-9 message-error no-padding-left">{{ messageRequire }}</div>
                        <div class="col-sm-3 text-left">{{ massNotiModel.content.length }}/255</div>
                    </div>
                    <div>
                        <div class="col-sm-12 text-left help-block mt-2 mb-2">
                            <strong>Notes : </strong> 
                            <i>Sending the Wait Time Meter Link will Activate Messages to the Patient for this Appointment Only.</i>                            
                        </div>
                    </div>
                    <div>
                        <div class="col-sm-9 text-left">
                            <p><input type="checkbox" name="input-incl-Sat" id="isaddwaittimemeterlink" [ngModel]="patientSettings.isaddwaittimemeterlink" [checked]="patientSettings.isaddwaittimemeterlink" (change)="patientSettings.isaddwaittimemeterlink = !patientSettings.isaddwaittimemeterlink; addwaittimelink(patientSettings.isaddwaittimemeterlink)" /> Add Wait Time Meter Link </p>
                        </div>
                        <!--<div class="col-sm-12 text-left" *ngIf="notifytype=='patient'">
                            <p><input type="checkbox" name="input-incl-Sat-activeuser" id="isactivateuser" [ngModel]="patientSettings.isactivateuser" [checked]="patientSettings.isactivateuser" (change)="patientSettings.isactivateuser = !patientSettings.isactivateuser; activateuseraccount(patientSettings.isactivateuser)" [disabled]="notifytype=='patient' ? true : null"/> Activate user and notification settings automatically </p>                        
                        </div>-->
                    </div>
                </ng-container>
                
                <div class="textRight text-right">
                    <button type="button" class="btn btn-rtn-inv btn-90 text-uppercase" (click)="dismiss()" data-dismiss="modal">CANCEL </button>
                    <button type="button" class="btn btn-rtn btn-90 text-uppercase" (click)="massNotiClicked()">SEND</button>
                </div>
          </div>
      </div>
  </form>
</modal>
