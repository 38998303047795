    <div class="patient-notification col-12 pl-0 pr-0">
        <div class="text-header row col-12">
            <span class="col-6 pl-0">Message Center<div class="notification-number" *ngIf="NumOfUnreadNotification">{{NumOfUnreadNotification}}</div></span>
            <span class="col-6 text-right pr-0">
                <i class="fa fa-refresh icon-refresh-style" aria-hidden="true" (click)="refreshData()"></i>
            </span>
        </div>
        <div class="row col-12 notification-style">
            
            <rtn-table class="w-100" #table (onDataChange)="handleFetchGetNotificationList($event)" [data]="getNotificationList.data"
                [rowsOnPage]="getNotificationList.rowsOnPage" [numberOfPages]="getNotificationList.numberOfPages">
                <div class="col-md-12 col-sm-12 col-xs-12 no-padding-left-right messageCenter">
                    <table class="table table-notification-style">
                        <div *ngFor="let item of getNotificationList.data; let i = index" class="notification-item bg-white"
                            (click)="readNotification(item.read_at, item.id)" [class.read]="item.read_at!=null"
                            id="notification-{{item.id}}">
                            <div class="row position-relative">
                                <div
                                    class="no-padding-right content-notification-item">
                                    <div class="notification-item-unread" id="notification-unread-{{item.id}}" *ngIf="item.read_at === null"></div>
                                    <div class="notification-text">
                                        <span class="notification-name">{{ item.fullName }}: </span>
                                        <span class="notification-message" *ngIf="item.type != 'OPENINGS'" [innerHTML]="item.message"></span>
                                        <span class="notification-message" *ngIf="item.type == 'OPENINGS'" [innerHTML]="item.message"></span>                                        
                                        <div class="notification-time" *ngIf="item.read_at === null">{{ item.timeAgo }} - Unread</div>
                                        <div class="notification-time" *ngIf="item.read_at != null">{{ item.timeAgo }} - Read at: {{ item.read_at }}</div>
                                    </div>
                                </div>
                               
                                    <a class="icon-delete-style position-absolute top-0 end-0" (click)="callDeleteNotificationDirective(item.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                               
                            </div>

                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 px-0" *ngIf="table.numberOfPages < 1">
                            <table class="table mb-0">
                                <tr class="no-notification-style read">
                                    <td class="no-provider">There is no notification</td>
                                </tr>
                            </table>
                        </div>
                        <tfoot *ngIf="table.numberOfPages > 1">
                            <tr>
                                <td colspan="2" class="text-center no-padding-left-right">
                                    <div class="page-footer">
                                        <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </rtn-table>
        </div>
    </div>
    <rtn-modals-delete-notification (onClick)="deleteNotification()"></rtn-modals-delete-notification>
