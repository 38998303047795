import { Component, EventEmitter, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
import { ModalsConfirmationComponent } from '@app/shared/modals/modal-confirmation/modal-confirmation.component';
import { AlertService } from '@app/shared/alert';
import { Event } from '@app/master-schedules/master-schedules.component';
import { Subscription } from 'rxjs';

import * as moment from 'moment';
import { RestService } from '@app/core/rest.service';
import { MasterSchedulesService } from '@app/master-schedules/master-schedules.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

const _momentOptions = {
    week: {
        dow: 0,
        doy: 6// Sunday is the first day of the week.
    }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
    selector: 'app-schedule-check-in-component',
    templateUrl: './schedule-check-in.component.html',
    styleUrls: ['./schedule-check-in.component.scss']
})
export class ScheduleCheckInComponent implements OnDestroy {

    checkInSubscriber: Subscription;

    @ViewChild('ScheduleCheckIn_Modals') modal: ModalComponent;
    @ViewChild(ModalsConfirmationComponent) confirmation: ModalsConfirmationComponent;

    @Output()
    reload: EventEmitter<any> = new EventEmitter();

    eventEntry: Event;
    listSchedule: any = [];
    hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
        '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
    errorMessage: string;
    requireMessage: string;
    previousTime: string;
    currentTime: string;

    // ========== Specific Modal Method ========== //

    checkInModel: CheckInClass = new CheckInClass();

    constructor(
        private Service: MasterSchedulesService,
        private _alertService: AlertService,
        private _loadingIndicatorService: LoadingIndicatorService,
    ) {
        // TODO
    }

    ngOnDestroy() {
        if (this.checkInSubscriber) {
            this.checkInSubscriber.unsubscribe();
        }
    }

    checkInClicked() {
        this.checkTime();
        this.callToWebService(0);
    }

    confirm(msg: any) {
        this._alertService.showDismissButton = true;
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Yes';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    getIndexOfCurrentId(currentId: any) {
        for (let index = 0; index < this.listSchedule.length; index++) {
            if (this.listSchedule[index].id === currentId) {
                return index;
            }
        }
    }

    callToWebService(is_multiple: number) {
        if (this.checkInSubscriber) {
            this.checkInSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();
        this.checkInSubscriber = this.Service.checkIn(this.eventEntry.data.id,
            {
                time: moment().hour(parseInt(this.checkInModel.hour, 10)).minute(this.checkInModel.minute)
                    .second(0).utc().format('YYYY-MM-DD HH:mm:ss'),
                is_multiple: is_multiple,
                provider_id: this.eventEntry.data.providerId
            }).subscribe(
                (success: any) => {
                    switch (success.error_code) {
                        case 'RC000':
                            this.reload.emit(this.eventEntry);
                            this._loadingIndicatorService.stop();
                            this.close();
                            const currentIndexId = this.getIndexOfCurrentId(this.eventEntry.data.id);
                            // if (currentIndexId != 0 && this.listSchedule[currentIndexId - 1].actual.actual_check_in
                            // == null
                            // && this.listSchedule[currentIndexId + 1].actual.actual_check_in != null) {
                            //     this._alertService.onClose = () => {
                            //         //no show
                            //     };
                            //     const first_name = this.listSchedule[currentIndexId - 1].patient.first_name;
                            //     const middle_name = this.listSchedule[currentIndexId - 1].patient.middle_name;
                            //     const last_name = this.listSchedule[currentIndexId - 1].patient.last_name;
                            //     const fullname = first_name + ((middle_name == null)
                            //     ? (' ')
                            //     : (' ' + middle_name + ' ')) + last_name;
                            //     this.confirm('Are you still wating for ' + fullname + ' or is this no show?');
                            // } else if (currentIndexId != 0
                            // && this.listSchedule[currentIndexId - 2].actual.actual_check_in
                            // == null && this.listSchedule[currentIndexId - 1].actual.actual_check_in != null) {
                            //     this._alertService.onClose = () => {
                            //         //no show
                            //     };
                            //     const first_name = this.listSchedule[currentIndexId - 2].patient.first_name;
                            //     const middle_name = this.listSchedule[currentIndexId - 2].patient.middle_name;
                            //     const last_name = this.listSchedule[currentIndexId - 2].patient.last_name;
                            //     const fullname = first_name + ((middle_name == null) ? (' ') : (' ' + middle_name + ' '))
                            //     + last_name;
                            //     this.confirm('Are you still wating for ' + fullname + ' or is this no show?');
                            // }
                            break;
                        case 'RC039':
                            this._loadingIndicatorService.stop();
                            this.confirmation.message = success.error_messages;
                            this.confirmation.open();
                            break;
                        case 'RC020':
                            this._loadingIndicatorService.stop();
                            this.requireMessage = success.error_fields.time
                                + ' [' + this.previousTime + ', ' + this.currentTime + ']';
                            break;
                        default:
                            this.errorMessage = success.error_messages;
                            this.close();
                            this.openErrorMessage();
                            break;
                    }
                    // if (success.error_code === 'RC000') {
                    //   this.reload.emit(this.eventEntry);
                    //   this.close();
                    // } else if (success.error_code === 'RC039') {
                    //   this.confirmation.message = success.error_messages;
                    //   this.confirmation.open();
                    // }
                },
                (error: any) => {
                    const e = error.error;
                    // switch (e.error_code) {
                    //   case 'RC020':
                    //     this.requireMessage = e.error_fields.time
                    //     + ' [' + this.previousTime + ', ' + this.currentTime + ']';
                    //     break;
                    //   default:
                    //     this.errorMessage = e.error_messages;
                    //     this.close();
                    //     this.openErrorMessage();
                    //     break;
                    // }
                });
    }

    openErrorMessage() {
        this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = this.errorMessage;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    validCheckInPopup() {
        this.checkInModel.hour = moment().hour().toString();
        this.checkInModel.hour = moment(this.checkInModel.hour, 'H').format('HH');
        // this.checkInModel.minute = (moment().minutes() - moment().minutes() % 5).toString();
        // this.checkInModel.minute = moment(this.checkInModel.minute, 'm').format('mm');
        this.checkInModel.minute = moment((moment().minutes()).toString(), 'm').format('mm');
        this.requireMessage = null;
    }

    checkTime() {
        let previousHour = moment().hour() - 2;
        previousHour = previousHour === -1 ? 23 : previousHour;
        previousHour = previousHour === -2 ? 22 : previousHour;
        const currentMinute = moment().minutes() - moment().minutes() % 5;
        this.previousTime = moment(previousHour, 'H').format('HH') + ':' + moment(currentMinute, 'm').format('mm');
        this.currentTime = moment().format('HH') + ':' + moment(currentMinute, 'm').format('mm');
    }

    confirmationNoClicked() {
        this.confirmation.close();
        this.close();
    }

    confirmationYesClicked() {
        this.confirmation.close();
        this.checkTime();
        this.callToWebService(1);
    }

    onSelectClicked() {
        this.requireMessage = null;
    }

    // ========== General Modal Method ========== //

    open() {
        this.modal.open();
        this.validCheckInPopup();
    }

    close() {
        this.modal.close();
    }
}

export class CheckInClass {
    hour: string;
    minute: any;
}
