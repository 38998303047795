import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DataLogsComponent} from '@app/data-logs/data-logs.component';
//import {ChartWaitTimeComponent} from '@app/data-logs/providers-waittime/chart-wait-time.component';
//import {ChartTestComponent} from '@app/data-logs/slot-duration/slot-duration.component';
//import {AppointmentByDayComponent} from '@app/data-logs/appointment-waittime/appointment-by-day.component';
//import {AppointmentLogByDayComponent} from '@app/data-logs/appointment-info/appointment-log-by-day.component';

import {Role} from '@app/core/role';
import {extract} from '@app/core';
import {AuthGuard} from '@app/core/auth.guard';
//import {ListProvidersComponent} from '@app/providers/list-providers/list-providers.component';
//import {WaittimeLogBySlotComponent} from '@app/data-logs/waittime-log-by-slot/waittime-log-by-slot.component';

//import{ OfficesWaittimeComponent} from './offices-waittime/offices-waittime.component'
import { AppointmentStatisticsComponent } from './appointment-statistics/appointment-statistics.component';

import { WaittimelogByProvidersComponent } from './waittimelog-by-providers/waittimelog-by-providers.component';
import { AppointmentBySlotComponent } from './appointment-by-slot/appointment-by-slot.component';
import { AppointmentByDateComponent } from './appointment-by-date/appointment-by-date.component';
import { DurationLogBySlotComponent } from './duration-log-by-slot/duration-log-by-slot.component';
import { StatisticalAppointmentComponent } from './statistical-appointment/statistical-appointment.component';
import { PatientWaitingRoomtimeComponent } from './patient-waiting-roomtime/patient-waiting-roomtime.component';
import { PlanActualComparingComponent } from '@app/data-logs/plan-actual-comparing/plan-actual-comparing.component';
import { WaittimeOfficeComponent } from './waittime-office/waittime-office.component';
import { NotificationComponent } from '@app/data-logs/notification/notification.component';

import { ScheduleActivityComponent } from './schedule-activity/schedule-activity.component';
import { StatisticalTypeofappointmentComponent } from './statistical-typeofappointment/statistical-typeofappointment.component';
import { ScheduleArrivalCompareComponent } from './schedule-arrival-compare/schedule-arrival-compare.component';
import { CancelAptstatisticsComponent } from './cancel-aptstatistics/cancel-aptstatistics.component';
import { AppointmentByLabslotComponent } from './appointment-by-labslot/appointment-by-labslot.component';

const routes: Routes = [{
    path: '',
    component: DataLogsComponent,
    children: [
        {
            path: 'admin/waittimelog-by-provider',
            component: WaittimelogByProvidersComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/appointment-by-slot',
            component: AppointmentBySlotComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/appointment-by-day',
            component: AppointmentByDateComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/duration-log-by-slot',
            component: DurationLogBySlotComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/chart/statistical-appointment',
            component: StatisticalAppointmentComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/statistical-appointment-by-types',
            component: StatisticalTypeofappointmentComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/patient-waittime',
            component: PatientWaitingRoomtimeComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/plan-actual-comparing',
            component: PlanActualComparingComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/waittime-office',
            component: WaittimeOfficeComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/notification-log',
            component: NotificationComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/schedule-activity',
            component: ScheduleActivityComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/schedule-arrival-comparing',
            component: ScheduleArrivalCompareComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/cancel-reschedule-statistics',
            component: CancelAptstatisticsComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },      
        {
            path: 'admin/appointment-by-slot-withlab',
            component: AppointmentByLabslotComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },  
        /*{
            path: 'admin/chart/waittime',
            component: ChartWaitTimeComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/chart/duration-log',
            component: ChartTestComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/chart/appointment-by-day',
            component: AppointmentByDayComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/chart/appointment-by-slot',
            component: AppointmentLogByDayComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        
        {
            path: 'admin/chart/patient-waittime',
            component: WaittimeLogBySlotComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        
        
        {
            path: 'admin/offices-waittime',
            component: OfficesWaittimeComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },*/
        {
            path: 'admin/appointment-statistics',
            component: AppointmentStatisticsComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DataLogsRoutingModule {
}
