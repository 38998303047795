import {Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {RestService} from '@app/core/rest.service';
import {AlertService} from '@app/shared/alert';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {NotificationsService} from '@app/notifications/notifications.service';

// declare let moment: any;

let _momentOptions = {
  week: {
    dow: 0,// Sunday is the first day of the week.
    doy: 6
  }
};

moment.updateLocale('en-US', _momentOptions);

@Component({
  moduleId: module.id,
  selector: 'app-notification-mass',
  templateUrl: './notification-mass.component.html',
  styleUrls: ['./notification-mass.component.scss'],
  providers: [RestService],
  encapsulation: ViewEncapsulation.None
})
export class NotificationMassComponent implements OnInit, OnDestroy {
  messageRequire: string; //Show message require
  receivers: any;

  massNotificationSubscriber: Subscription;
  getReceiversSubscriber: Subscription;

  @ViewChild('NotificationMass_Modals') modal: ModalComponent;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private _restService: NotificationsService,
    private _alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService) {
  }

  getReceivers() {
    this._restService.getReceivers().subscribe(
      (success: any) => {
        this.receivers = success.receivers;
      },
      (error: any) => {
        //TODO
        console.log(error);
      }
    );
  }


  // ========== Specific Modal Method ========== //

  massNotiModel: MassNotiClass = new MassNotiClass();

  massNotiClicked() {
    var receivers_id: number;
    if (this.massNotiModel.receivers === undefined) {
      receivers_id = this.receivers[0].id;
    } else {
      for (var i = 0; i < this.receivers.length; i++) {
        if (this.massNotiModel.receivers === this.receivers[i].name) {
          receivers_id = this.receivers[i].id;
        }
      }
    }
    this.loadingIndicatorService.start();
    if (this.massNotificationSubscriber) {
      this.massNotificationSubscriber.unsubscribe();
    }
    this.massNotificationSubscriber = this._restService.massNotification({
      receiver_id: receivers_id,
      body: this.massNotiModel.content,
      time_offset: moment().utcOffset(),
      date: moment().format('YYYY-MM-DD')
    }).subscribe(
      (success: any) => {
        this.loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          this._alertService.onClose = () => {
            this.dismiss();
          };
          this._alertService.showDismissButton = false;
          this._alertService.dismissButtonLabel = 'Close';
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'Close';
          this._alertService.title = success.error_messages;
          this._alertService.message = '';
          this._alertService.emitter('open');
        }
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
        let e = error.error;
        if (e.error_code === 'RC050') {
          this.messageRequire = e.error_fields.body;
        } else if (e.error_code === 'RC049' || e.error_code === 'RC139') {
          this._alertService.onClose = () => {
            this.dismiss();
          };
          this._alertService.showDismissButton = false;
          this._alertService.dismissButtonLabel = 'Close';
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'Close';
          this._alertService.title = e.error_messages;
          this._alertService.message = '';
          this._alertService.emitter('open');
        }
      },
      () => {
        this.loadingIndicatorService.stop();
      }
    );
  }

  // ========== Class Method ========== //

  ngOnInit() {
    jQuery('.notification-mass').parent().css('width', '600px');
    this.getReceivers();
    if (this.getReceiversSubscriber) {
      this.getReceiversSubscriber.unsubscribe();
    }
    this.getReceiversSubscriber = this._restService.getReceivers().subscribe((success: any) => {
      if (success.error_code === 'RC000') {
        if (success.receivers[0] !== null) {
          this.receivers = success.receivers;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.massNotificationSubscriber) {
      this.massNotificationSubscriber.unsubscribe();
    }
    if (this.getReceiversSubscriber) {
      this.getReceiversSubscriber.unsubscribe();
    }
  }

  // ========== General Modal Method ========== //

  dismiss() {
    this.modal.dismiss();
    this.messageRequire = null;
    this.massNotiModel.receivers = this.receivers[0].name;
    this.massNotiModel.content = '';
  }

  onKeyDown(event: any) {
    /*if (event.keyCode === 13 || (event.keyCode === 13 && event.shiftKey)) {    
      event.preventDefault();
    }*/
  }

  onKeyUp(e: any) {
    this.messageRequire = null;
  }

  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }
}

export class MassNotiClass {
  receivers: string;
  content: string = '';
}

