<div class="sidebarAndContent">
  <div class="mt-3">
    <h3 class="title">Appointment Statistics by type</h3>
    <div class="col-12 px-0">
      <div class="row col-12 px-0">
        <div class="row col-12 border border-#ddd pt-4 no-print">
        
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="pl-0 d-none d-lg-block find-appointment-line-title width_70 pt-1">From:</div>
            <div
              class="width_70 text-left col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              From:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
              <div class="input-group">
                <input type="text" #inputDateFrom readonly [value]="dataSearchChart['dateFrom']"
                  class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateFrom']"
                  aria-describedby="basic-addon2">
                <div class="input-group-append" #clickDateFrom>
                  <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                </div>
              </div>
              <div class="message-error">{{ errorResponse.dateFrom }}</div>
            </div>
          </div>
          
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">To:</div>
            <div
              class="width_70 col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              To:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
              <div class="input-group">
                <input type="text" #inputDateTo readonly [value]="dataSearchChart['dateTo']"
                  class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateTo']"
                  aria-describedby="basic-addon2">
                <div class="input-group-append" #clickDateTo>
                  <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                </div>
              </div>
              <div class="message-error d-xl-none d-lg-none">{{ errorResponse.dateTo }}</div>
            </div>
          </div>
          <div class="row col-lg-6 mb-2-title mb-2 px-0">
          </div>
          <div class="row col-lg-6 mb-2-title mb-2 px-0 pl_100">
            <div class="d-none d-lg-block  width_70 pt-1"></div>
            <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none-title px-0">
            </div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
              <div class="message-error d-none d-sm-none d-md-none d-lg-block d-xl-block">{{ errorResponse.dateTo }}</div>
            </div>
  
          </div>
          <div class="ml-3 px-0 row col-lg-10 mb-2 float-custom">
            <div class="btn-90" *ngIf="chartLabels != undefined && chartLabels.length > 0">
              <button id='printDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1" type="button" (click)="printDataChart()">PRINT</button>
            </div>
            
            <div class="btn-90 ml-2" *ngIf="chartLabels != undefined && chartLabels.length > 0">
              <button id='exportDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1" type="button" (click)="export()">EXPORT</button>
            </div>
            <div class="btn-90 ml-2">
              <button id='showDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1"
                type="submit">SHOW</button>
            </div>
          </div>
  
  
          <div class="no-padding-left row col-lg-12 mb-2 inputDateFrom">
            <div class="red-alert d-lg-block d-md-none d-sm-none d-none error_message"></div>
          </div>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="d-none d-sm-block d-md-block d-lg-block d-xl-block col-sm-2 col-md-3 col-lg-2"></div>
        <div class="col-12 col-sm-8 col-md-6 col-lg-7 col-xl-7 max_height" style="display: block;" *ngIf="chartLabels.length > 0">
          <canvas mdbChart [chartType]="chartType" [datasets]="chartDatasets" [labels]="chartLabels"
            [colors]="chartColors" [options]="chartOptions" [legend]="true" >
          </canvas>
        </div>
      </div>

    </div>
  </div>
  </div>