import { Component, Output, EventEmitter, ElementRef, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MODAL_DIRECTIVES, ModalComponent } from '@app/shared/ng2-bs3-modal';
// import {AvailableSlotService} from '@app/appointments/modals/available-slot/available-slot.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { AlertService } from '@app/shared/alert';
import { Event } from '@app/appointments/timeline/timeline.component';
import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { ModalsAlertComponent } from '@app/shared/modals/modal-alert/modal-alert.component';
import { Utils } from '@app/shared/utils';
import { Subscription } from 'rxjs';
import { RestService } from '@app/core/rest.service';

declare let jQuery: any;
import * as moment from 'moment';
import { SimpleModalsService } from '@app/shared/modals/simple-modals/simple-modals.service';
import { AppointmentsService } from '@app/appointments/appointments.service';
// import {stringify} from 'querystring';
// import 'select2';


@Component({
  moduleId: module.id,
  selector: 'rtn-modals-available-slot',
  templateUrl: './available-slot.component.html',
  styleUrls: ['./available-slot.component.scss'],
  // directives: [ModalComponent, ModalsAlertComponent, MODAL_DIRECTIVES, Select2Directive],
  providers: [AlertService]
})
export class AvailableSlotComponent implements OnInit, AfterViewInit, OnDestroy {
  fetchProviderByRoleSubscriber: Subscription;
  newAvailSlotSubscriber: Subscription;

  @ViewChild('AppointmentNew_Modals') modal: ModalComponent;
  @ViewChild(ModalsAlertComponent) confirmation: ModalsAlertComponent;
  @ViewChild('inputDateNewAppointment') inputDateNewAppointment: ElementRef;
  @ViewChild('clickDateNewAppointment') clickDateNewAppointment: ElementRef;
  @ViewChild('inputEndDateNewAppointment') inputEndDateNewAppointment: ElementRef;
  @ViewChild('clickEndDateNewAppointment') clickEndDateNewAppointment: ElementRef;
  @ViewChild(Select2ProviderDirective) providerSelector: Select2ProviderDirective;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  eventEntry: Event;

  hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  errorMessage: string;
  errorProvider: string;

  slotListObject: SlotList = new SlotList();
  errorSlot: SlotList = new SlotList();
  slotList: any = [];
  selectedProviders: any = [];
  providerList: any;
  isProvider = false;
  isManagingMode = false;
  runRequest = true;
  getIsLockSubscriber: Subscription;
  isLock: any;
  // ========== Specific Modal Method ========== //
  newAvailSlot: NewAvailSlotClass = new NewAvailSlotClass();
  requireMessage: RequireMessage = new RequireMessage();
  note: String = '';
  errorMessageBook: string;

  public addresses: any[] = [{
    id : 0,    
    city: '',
    country: ''
  }];

  fetchProviderLocationSubscriber: Subscription;
  selectedProviderId : number = 0;
  selectedLocationId : any = '';
  providerLocations:any=[];
  practiceLocationGroups:any=[];

  constructor(
    private Service: AppointmentsService,
    public _alertService: SimpleModalsService,
    public _loadingIndicatorService: LoadingIndicatorService,
    public _utils: Utils
    //private service: AppointmentsService
  ) {
  }

  addAddress() {
    this.addresses.push({
      //id: this.addresses.length + 1,      
      city: '',
      country: ''
    });
  }
  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }

  ngOnInit() {
    // TODO
    this.selectedProviderId = parseInt(localStorage.getItem('user_providerId'));
  }

  setInitial() {
    this.isProvider = this._utils.isProvider();
    const storeData = localStorage.getItem('storeData');
    if (this.isProvider && storeData) {
      this.isManagingMode = true;
      const extractData = JSON.parse(localStorage.getItem('storeData'));
      this.fetchProviderByUserId(extractData.user_id);
    } else {
      this.isManagingMode = false;
    }

    this.slotList = [];
    this.initDateobject();
    /*this.slotListObject = new SlotList();
    this.newAvailSlot.date = moment().format('MMMM DD, YYYY');
    this.newAvailSlot.enddate = moment().format('MMMM DD, YYYY');
    this.newAvailSlot.includeSaturday = false;
    this.newAvailSlot.includeSunday = false;
    this.slotListObject.hourStart = this.slotListObject.hourEnd = moment().format('HH');
    let minute = moment().minute() % 5 === 0 ? moment().minute() : moment().minute() - moment().minute() % 5 + 5;
    if (minute === 60) {
      const hour = moment().hour() + 1;
      this.slotListObject.hourStart = this.slotListObject.hourEnd = moment(hour, 'H').format('HH');
      minute = 0;
    }
    this.slotListObject.minuteStart = this.slotListObject.minuteEnd = moment(minute, 'm').format('mm');*/
    // set default 1 slot
    this.slotList.push(this.slotListObject);
    this.fetchNoteFromPractice();
    this.fetchProviderLocation();
  }
  initDateobject() {
    this.slotListObject = new SlotList();
    this.newAvailSlot.date = moment().format('MMMM DD, YYYY');
    this.newAvailSlot.enddate = moment().format('MMMM DD, YYYY');
    this.newAvailSlot.includeSaturday = false;
    this.newAvailSlot.includeSunday = false;
    this.slotListObject.hourStart = this.slotListObject.hourEnd = moment().format('HH');
    let minute = moment().minute() % 5 === 0 ? moment().minute() : moment().minute() - moment().minute() % 5 + 5;
    if (minute === 60) {
      const hour = moment().hour() + 1;
      this.slotListObject.hourStart = this.slotListObject.hourEnd = moment(hour, 'H').format('HH');
      minute = 0;
    }
    this.slotListObject.minuteStart = this.slotListObject.minuteEnd = moment(minute, 'm').format('mm');
  }
  initDateobjectnew() {
    let slotObject:any = new SlotList();    
    slotObject.hourStart = slotObject.hourEnd = moment().format('HH');
    let minute = moment().minute() % 5 === 0 ? moment().minute() : moment().minute() - moment().minute() % 5 + 5;
    if (minute === 60) {
      const hour = moment().hour() + 1;
      slotObject.hourStart = slotObject.hourEnd = moment(hour, 'H').format('HH');
      minute = 0;
    }
    slotObject.minuteStart = slotObject.minuteEnd = moment(minute, 'm').format('mm');

    return slotObject;
  }

  addMoreSlot() {
    if (this.slotList.length < 20) {      
      let _slotObject = this.initDateobjectnew();      
      this.slotList.push(_slotObject);      
    }
  }
  removeSlot(i : number) {
   
    this.slotList.splice(i,1);
    console.log(this.slotList);
  }
  showMessage(msg: any) {
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  addNewAvailableSlot(type: number, formVals: any) {
    this._loadingIndicatorService.start();
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this.isLock = success.providers.is_lock;
        if (this.isLock == true) {
          if (type == 2) {
            this._loadingIndicatorService.stop();
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              window.location.reload();
            };
            this.showMessage('Your account has been locked.');
          } else if (type == 1) {
            this._loadingIndicatorService.stop();
            this.dismiss();
            this.close();
            this.showMessage('Your account has been locked.');
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              window.location.reload();
            };
          }
        } else {
          if (type == 1) {
            this.newAvailableSlotClicked(formVals);
            this._loadingIndicatorService.stop();
          } else if (type == 2) {
            this.open();
            this._loadingIndicatorService.stop();
          }
        }
      }
    );
  }
  newAvailableSlotClicked(formVals: any) {
    if (this.runRequest) {      
      this.submitFormAddNewAvailableSlot(formVals);
    }
  }

  submitFormAddNewAvailableSlot(formVals: any) {
    this.runRequest = false;
    const self = this;
    self._loadingIndicatorService.start();
    const value = Object.assign({}, formVals);
    if (this.newAvailSlotSubscriber) {
      this.newAvailSlotSubscriber.unsubscribe();
    }
    
    //console.log(value);

    // ADD VALUE FOR CHECKBOX HERE
    const availSlot = self.formatDataSlot(value);

    //console.log(availSlot);

    //let user_provider_loc_id = localStorage.getItem('user_provider_loc_id');

    let dataSlot: any;
    if (this.isManagingMode) {
      const providers = this.providerSelector.getData();
      dataSlot = { slot: availSlot, provider: providers, location_id : value.location_id };
    } else {
      dataSlot = { slot: availSlot, location_id : value.location_id };
    }

    this.newAvailSlotSubscriber = this.Service.newAvailableSlot(dataSlot).subscribe(
      (res: any) => {
        switch (res.error_code) {
          case 'RC000':
            this.runRequest = true;
            self._loadingIndicatorService.stop();
            if (this.isManagingMode) {
              const newMsg: any = [];
              if (res.listAvailId.length > 0) {
                newMsg.push('Some available slots have created successfully!');
              }
              if (res.providerNotMap.length > 0) {
                res.providerNotMap.map((item: any) => {
                  newMsg.push(item);
                });
                this.confirmation.message = newMsg;
                this.confirmation.open();
              } else {
                self.showPopUp(res.error_messages);
              }
            } else {
              self.showPopUp(res.error_messages);
            }
            this.close();
            break;
          case 'RC056':
            this.runRequest = true;
            self._loadingIndicatorService.stop();
            self.showPopUp(res.error_messages);
            break;
          case 'RC063':
            this.runRequest = true;
            self._loadingIndicatorService.stop();
            this.requireMessage = res.error_fields;
            break;
          case 'RC134':
          case 'RC155':
            this.runRequest = true;
            self._loadingIndicatorService.stop();
            this.errorMessageBook = res.error_messages;
            //this.requireMessage.enddate = res.error_messages;
            break;  
        }
      },
      (error: any) => {
        this.runRequest = true;
        self._loadingIndicatorService.stop();
        //console.log(error.error_code);
        // const e = error.error;
        // switch (e.error_code) {
        //   case 'RC056':
        //     self.showPopUp(e.error_messages);
        //     break;
        //
        //   case 'RC063':
        //     this.requireMessage = e.error_fields;
        //     break;
        // }
      },
      () => {
        this.runRequest = true;
        self._loadingIndicatorService.stop();
      }
    );
  }

  confirmationYesClicked() {
    this.confirmation.close();
    this.dismiss();
    this.close();
    this.setInitial();
    this.reload.emit(this.eventEntry);
  }

  showPopUp(msg: any) {
    const self = this;
    self._alertService.onClose = () => {
      self.dismiss();
      self.close();
      self.setInitial();
      self.reload.emit(this.eventEntry);
    };
    self._alertService.showDismissButton = false;
    self._alertService.dismissButtonLabel = 'Close';
    self._alertService.showCloseButton = true;
    self._alertService.closeButtonLabel = 'OK';
    self._alertService.title = msg;
    this._alertService.message = '';
    self._alertService.emitter('open');
  }

  getDateArray(start: any, end: any) {
    const listDate = [];
    let startDate = moment(start);
    const endDate = moment(end);

    while (startDate <= endDate) {
      listDate.push(startDate);
      startDate = startDate.clone().add(1, 'day');
    }

    return listDate;

  }

  formatDataSlot(data: any) {
    const date = this.newAvailSlot.date;
    const enddate = this.newAvailSlot.enddate;
    const listDate = this.getDateArray(date, enddate);

    const length = this.slotList.length;
    const numOfDate = listDate.length;

    //console.log(this.slotList);
    //console.log(listDate);

    const newData = [];

    for (let i = 0; i < this.slotList.length; i++) {
      let tempdata:any = this.slotList[i];
      for (let j = 0; j < numOfDate; j++) {
        const date1 = listDate[j];
        if (tempdata.hourStart && tempdata.minuteStart && tempdata.hourEnd || tempdata.minuteEnd) {
          const startHour = moment(date1, 'MMMM DD, YYYY').hour(tempdata.hourStart)
              .minute(tempdata.minuteStart).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
          
          const endHour = moment(date1, 'MMMM DD, YYYY').hour(tempdata.hourEnd).minute(tempdata.minuteEnd)
              .second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
            
          if ((!this.newAvailSlot.includeSaturday && moment(date1).day() !== 6 || this.newAvailSlot.includeSaturday) &&
              ((!this.newAvailSlot.includeSunday && moment(date1).day() !== 0) || this.newAvailSlot.includeSunday)
            ) {
              const itemEnd = { start_time: startHour, end_time: endHour };
              newData.push(itemEnd);
            }
        }
      } 
    }
    return newData;
    /*const newData = [];
    for (let i = 0; i < length; i++) {
      for (let j = 0; j < numOfDate; j++) {
        const hourStart = 'slot_' + i + '_hourStart';
        const hourEnd = 'slot_' + i + '_hourEnd';
        const minuteStart = 'slot_' + i + '_minuteStart';
        const minuteEnd = 'slot_' + i + '_minuteEnd';
        const date1 = listDate[j];
        if (data[hourStart] && data[minuteStart] && data[hourEnd] || data[minuteEnd]) {
          const startHour = moment(date1, 'MMMM DD, YYYY').hour(data[hourStart])
            .minute(data[minuteStart]).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
          const endHour = moment(date1, 'MMMM DD, YYYY').hour(data[hourEnd]).minute(data[minuteEnd])
            .second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');

          if ((!this.newAvailSlot.includeSaturday && moment(date1).day() !== 6 || this.newAvailSlot.includeSaturday) &&
            ((!this.newAvailSlot.includeSunday && moment(date1).day() !== 0) || this.newAvailSlot.includeSunday)
          ) {
            const itemEnd = { start_time: startHour, end_time: endHour };
            newData.push(itemEnd);
          }
        }
      }
    }
    return newData;*/
  }

  // ========== Class Method ========== //

  ngAfterViewInit() {
    jQuery('.search-button').on('click', function (e: any) {
      if (jQuery('.search').hasClass('open')) {
        e.stopPropagation();
      }
    });
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      minDate: 0,
      showButtonPanel: false,
      onSelect: () => {
        this.newAvailSlot.date = jQuery(this.inputDateNewAppointment.nativeElement).val();
        jQuery(this.inputEndDateNewAppointment.nativeElement).datepicker('option', 'minDate', this.newAvailSlot.date);
        if (moment(this.newAvailSlot.date) > moment(this.newAvailSlot.enddate)) {
          this.newAvailSlot.enddate = this.newAvailSlot.date;
        }
      }
    });

    jQuery(this.inputEndDateNewAppointment.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      minDate: 0,
      showButtonPanel: false,
      onSelect: () => {
        this.newAvailSlot.enddate = jQuery(this.inputEndDateNewAppointment.nativeElement).val();
        if (moment(this.newAvailSlot.date) > moment(this.newAvailSlot.enddate)) {
          this.newAvailSlot.enddate = this.newAvailSlot.date;
        }
      }
    });

    jQuery(this.clickDateNewAppointment.nativeElement).click((e: any) => {
      jQuery(this.inputDateNewAppointment.nativeElement).datepicker('show');
      e.preventDefault();
      this.requireMessage = new RequireMessage();
    });

    jQuery(this.clickEndDateNewAppointment.nativeElement).click((e: any) => {
      jQuery(this.inputEndDateNewAppointment.nativeElement).datepicker('show');
      e.preventDefault();
      this.requireMessage = new RequireMessage();
    });
  }

  fetchProviderByUserId(userId: any) {
    if (this.fetchProviderByRoleSubscriber) {
      this.fetchProviderByRoleSubscriber.unsubscribe();
    }

    this.fetchProviderByRoleSubscriber = this.Service.fetchProviderByRole({ user_id: userId }).subscribe(
      (success: any) => {
        if (success.users !== null) {
          this.providerList = success.users;
        }
      }
    );
  }

  fetchNoteFromPractice() {
    this.Service.getNoteFromPractice().subscribe(
      (success: any) => {
        if (success.note !== null && success.note !== undefined) {
          this.note = success.note;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.newAvailSlotSubscriber) {
      this.newAvailSlotSubscriber.unsubscribe();
    }
    if (this.fetchProviderByRoleSubscriber) {
      this.fetchProviderByRoleSubscriber.unsubscribe();
    }
  }

  // ========== General Modal Method ========== //

  dismiss() {
    this.requireMessage = new RequireMessage();
  }

  onClicked(error: string) {
    switch (error) {
      case 'date':
        this.requireMessage.date = null;
        break;
      case 'start_time':
        this.requireMessage.start_time = null;
        break;
      case 'end_time':
        this.requireMessage.end_time = null;
        break;
    }
    this.requireMessage = new RequireMessage();
    this.errorMessageBook = '';
  }

  open() {
    this.modal.open();
    this.setInitial();
  }

  close() {
    this.modal.close();
  }

  onKeyUp(error: string) {
    switch (error) {
      case 'provider':
        this.errorProvider = null;
        break;
      case 'location_id':
        this.requireMessage.location_id = null;
        break;  
    }
  }
  fetchProviderLocation(){
    if (this.fetchProviderLocationSubscriber) {
      this.fetchProviderLocationSubscriber.unsubscribe();
    }
    this.fetchProviderLocationSubscriber = this.Service.fetchProviderLocation(this.selectedProviderId).subscribe((success: any) => {
      this.providerLocations = success.aptlocations;
      this.practiceLocationGroups = success.arrpractice_groups;
      //console.log(this.practiceLocationGroups);
    },(error: any) => {
      this.providerLocations = [];
      this.practiceLocationGroups = [];
    });
  }
}

export class NewAvailSlotClass {
  patient = '';
  date: string;
  enddate: string;
  includeSaturday: boolean;
  includeSunday: boolean;
  hourStart: string;
  minuteStart: any;
  hourEnd: string;
  minuteEnd: any;
  location_id:string='';
}

export class RequireMessage {
  date: string;
  enddate: string;
  start_time: string;
  end_time: string;
  location_id: string;
}

export class SlotList {
  hourStart: string;
  minuteStart: string;
  hourEnd: string;
  minuteEnd: string;
}
