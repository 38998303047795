import {Component, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
// import {CORE_DIRECTIVES} from 'angular2/common';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';

@Component({
  selector: 'rtn-modals-confirmation',
  // directives: [CORE_DIRECTIVES, ModalComponent, MODAL_DIRECTIVES],
  template: `
    <form class="confirmation" [class.hidden]="!showConfirmation">
      <i [class.hidden]="!showIcon" class="fa fa-info fa-4x" aria-hidden="true"></i>
      <div class="text">{{ message }}</div>
      <div>
        <button type="submit" class="btn btn-rtn-inv btn-90 btn-no" (click)="handleOnNoClicked($event)" autofocus>No
        </button>
        <button type="button" class="btn btn-rtn btn-90" (click)="handleOnYesClicked($event)">Yes</button>
      </div>
    </form>
  `,
  encapsulation: ViewEncapsulation.None
})

export class ModalsConfirmationComponent {
  message = '';
  showConfirmation = false;
  @Input()
  showIcon = true;
  @Output()
  OnYesClicked: any = new EventEmitter();
  @Output()
  OnNoClicked: any = new EventEmitter();

  constructor() {
    // TODO
  }

  open() {
    this.showConfirmation = true;
    setTimeout(function () {
      jQuery('.btn-no').focus();
    }, 400);
  }

  close() {
    this.showConfirmation = false;
  }

  handleOnYesClicked(e: any) {
    this.OnYesClicked.emit(e);
  }

  handleOnNoClicked(e: any) {
    this.OnNoClicked.emit(e);
  }
}
