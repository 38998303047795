<modal #ActiveModule_Modals class="active-module">
  <form>
    <div class="form-content">
      <div class="col-xs-offset-2 col-xs-8 content">
        <div class="form-header">{{ saveMessage }}</div>
      </div>
    </div>
    <div class="button form-footer">
      <button type="button" class="btn btn-default btn-rtn-inv btn-90" (click)="close()">No</button>
      <button type="button" class="btn btn-primary btn-rtn btn-90" (click)="saveActiveModule()">Yes</button>
    </div>
  </form>
</modal>
