import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';
// import {AppointmentEditService} from '@app/appointments/modals/appointment-edit/appointment-edit.service';
/* import { ModalsConfirmationComponent } from '../../../shared/modals-confirmation'; */
import {AlertService} from '@app/shared/alert';
import {Event} from '@app/appointments/timeline/timeline.component';
import {Subscription} from 'rxjs';
import {Utils} from '@app/shared/utils';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

import * as moment from 'moment';
import {RestService} from '@app/core/rest.service';
import {AppointmentsService} from '@app/appointments/appointments.service';
import { ProvidersService } from '@app/providers/providers.service';

declare let jQuery: any;

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);


@Component({
  moduleId: module.id,
  selector: 'rtn-modals-appointment-edit',
  templateUrl: './appointment-edit.component.html',
  styleUrls: ['./appointment-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentEditComponent implements AfterViewInit, OnDestroy {
  fetchAvailableSlotSubscriber: Subscription;
  editApmtSubscriber: Subscription;
  searchPatientSubscriber: Subscription;
  editAppointmentSubscriber: Subscription;

  @ViewChild('AppointmentEdit_Modals') modal: ModalComponent;
  // @ViewChild(ModalsConfirmationComponent) confirmation: ModalsConfirmationComponent;
  @ViewChild('inputDateEditAppointment') inputDateEditAppointment: ElementRef;
  @ViewChild('clickDateEditAppointment') clickDateEditAppointment: ElementRef;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  eventEntry: Event;

  ngDatePicker: any;
  _weekStart = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)
  hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  errorMessage: string;
  errorMessageBook: string;
  searchDisabled = true;
  patients: any = [];
  patient_id: number;
  availableSlots: any = [];
  selectedSlot = 0;
  selectedDate: any;

  // ========== Specific Modal Method ========== //
  editApmtModel: EditApmtClass = new EditApmtClass();
  requireMessage: RequireMessage = new RequireMessage();

  fetchAppointmentTypeSubscriber: Subscription;
  appointmentTypeList: any = [];

  constructor(
    private Service: AppointmentsService,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    private providersService: ProvidersService
  ) {
    this.ngDatePicker = new Object();
    this.ngDatePicker['todayHighlight'] = true;
    this.ngDatePicker['weekStart'] = this._weekStart;
    this.ngDatePicker['format'] = 'MM dd, yyyy';
  }

  // /*editApmtSaveCloseClicked() {
  //     this.callToWebService(0);
  // }
  //
  // callToWebService(multiple: number) {
  //      if (this.editApmtSubscriber) {
  //         this.editApmtSubscriber.unsubscribe();
  //     }
  //     this.editApmtSubscriber = this._modalsService.editApmt(this.eventEntry.data.id, {
  //         patient_id: this.patient_id || this.editApmtModel.patient,
  //         start_time: moment(this.editApmtModel.date, 'MMMM DD, YYYY')
  //         .hour(this.editApmtModel.hour1).minute(this.editApmtModel.minute1).second(0).utc()
  //         .format('YYYY-MM-DDTHH:mm:ssZZ'),
  //         end_time: moment(this.editApmtModel.date, 'MMMM DD, YYYY').hour(this.editApmtModel.hour2)
  //         .minute(this.editApmtModel.minute2).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ'),
  //         is_multiple: multiple
  //     }).subscribe(
  //         (success: any) => {
  //             this.close();
  //             this.reload.emit(this.eventEntry);
  //         },
  //         (error: any) => {
  //             let e = error.error;
  //             switch (e.error_code) {
  //                 case 'RC033':
  //                 case 'RC042':
  //                 case 'RC053':
  //                 case 'RC058':
  //                 case 'RC059':
  //                     this.errorMessage = e.error_messages;
  //                     this.close();
  //                     this.openErrorMessage();
  //                     break;
  //                 case 'RC063':
  //                     this.requireMessage = e.error_fields;
  //                     break;
  //                 case 'RC064':
  //                     this.confirmation.message = e.error_messages;
  //                     this.confirmation.open();
  //                     break;
  //             }
  //         });
  // } */
  editApmtSaveCloseClicked() {
    this.errorMessageBook = null;
    this._loadingIndicatorService.start();
    if (this.editAppointmentSubscriber) {
      this.editAppointmentSubscriber.unsubscribe();
    }

    this.editAppointmentSubscriber = this.Service.editApmt(this.eventEntry.data.id, {
      patient_id: this.patient_id || this.editApmtModel.patient,
      slot_id: this.selectedSlot,
      appointment_reason : this.editApmtModel.appointment_reason,
      event_reason_id : this.editApmtModel.event_reason_id,
    })
      .subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          if (success.error_code === 'RC000') {
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
            };
            this._alertService.showDismissButton = false;
            this._alertService.dismissButtonLabel = 'No';
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'OK';
            this._alertService.title = success.error_messages;
            this._alertService.message = '';
            this._alertService.emitter('open');
          } else if (success.error_code === 'RC002'
            || success.error_code === 'RC061'
            || success.error_code === 'RC119'
            || success.error_code === 'RC033'
            || success.error_code === 'RC042'
            || success.error_code === 'RC053'
            || success.error_code === 'RC058'
            || success.error_code === 'RC059') {
            this.errorMessageBook = success.error_messages;
          }
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
          const e = error.error;
          if (e.error_code === 'RC002'
            || e.error_code === 'RC061'
            || e.error_code === 'RC119'
            || e.error_code === 'RC033'
            || e.error_code === 'RC042'
            || e.error_code === 'RC053'
            || e.error_code === 'RC058'
            || e.error_code === 'RC059') {
            this.errorMessageBook = e.error_messages;
          }
        },
        () => {
          this._loadingIndicatorService.stop();
        }
      );
  }

  openErrorMessage() {
    this._alertService.onClose = () => {
      this.reload.emit(this.eventEntry);
    };
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = this.errorMessage;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  selectSlot(slotId: any) {
    this.selectedSlot = slotId;
    for (let i = 0; i < this.availableSlots.length; i++) {
      const s = this.availableSlots[i];
      s.isSelected = s.id === slotId;
    }
  }

  getTime(time: any) {
    // return moment(time).format('hh:mm A');
    return moment(time).format('HH:mm');
  }

  fetchAppointmentByDate(date: any) {
    const startTimeRequest = this._utils.formatTimeForWebServiceCall(date.clone().startOf('day'));
    const endTimeRequest = this._utils.formatTimeForWebServiceCall(date.clone().add(1, 'day').startOf('day'));

    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    let location_id = this.eventEntry.location_id;
    let apt_id = this.eventEntry.data.id;

    console.log(this.eventEntry);

    this.fetchAvailableSlotSubscriber = this.Service
      .fetchAvailableSlotByPeriod(startTimeRequest, endTimeRequest, location_id, apt_id).subscribe(
        (success: any) => {
          if (success.available_slots !== null) {
            this.availableSlots = success.available_slots;
            this.selectedSlot = 0;
          }
        }
      );

  }

  searchPatientClicked(event: any) {
    this.patients = [];
    this.errorMessage = null;
    if (this.editApmtModel.patient !== undefined) {
      if (this.searchPatientSubscriber) {
        this.searchPatientSubscriber.unsubscribe();
      }

      this.searchPatientSubscriber = this.Service.searchPatient(this.editApmtModel.patient).subscribe(
        (success: any) => {
          if (success.error_code === 'RC000') {
            success.patients.forEach((item: any) => {
              const patient = new PatientInfoClass();
              patient.id = item.id;
              patient.first_name = item.first_name;
              patient.last_name = item.last_name;
              patient.dob = item.dob !== null ? moment(item.dob, 'YYYY-MM-DD').format('MMMM DD, YYYY') : null;
              patient.phone_mobile = item.phone_mobile;
              jQuery(event.srcElement).closest('.input-group').addClass('open');
              this.patients.push(patient);
            });
          } else if (success.error_code === 'RC061' || success.error_code === 'RC071') {
            this.errorMessage = success.error_messages;
            jQuery(event.srcElement).closest('.input-group').addClass('open');
          }
        },
        (error: any) => {
          const e = error.error;
          if (e.error_code === 'RC061' || e.error_code === 'RC071') {
            this.errorMessage = e.error_messages;
          }
        });
    }
  }

  setInitial() {
    const e = this.eventEntry;
    //this.patient_id = e.data.patientId;
    this.patient_id = e.data.patient_user_id;
    this.editApmtModel.patient = e.data.patientName + ' - ' + e.data.patientDob;
    this.editApmtModel.date = e.date;
    // /* let hour1 = e.start.get('hour');
    // let minute1 = e.start.get('minute') % 5 === 0 ? e.start.get('minute')
    // : e.start.get('minute') - e.start.get('minute') % 5 + 5;
    // if (minute1 === 60) {
    //     minute1 = 0;
    //     hour1 += 1;
    // }
    // this.editApmtModel.hour1 = moment(hour1, 'H').format('HH');
    // this.editApmtModel.minute1 = moment(minute1, 'm').format('mm');
    // let hour2 = e.end.get('hour');
    // let minute2 = e.end.get('minute') % 5 === 0 ? e.end.get('minute')
    // : e.end.get('minute') - e.end.get('minute') % 5 + 5;
    // if (minute2 === 60) {
    //     minute2 = 0;
    //     hour2 += 1;
    // }
    // this.editApmtModel.hour2 = moment(hour2, 'H').format('HH');
    // this.editApmtModel.minute2 = moment(minute2, 'm').format('mm'); */
    this.selectedSlot = 0;
    this.selectedDate = e.date;
    this.fetchAppointmentByDate(moment(this.selectedDate));
    console.log(e);
    this.fetchAppointmentType(e.event_reason_id);
    this.editApmtModel.event_reason_id = e.event_reason_id;
    this.editApmtModel.appointment_reason = e.appointment_reason;
  }

  getInfoByNameClicked(event: any, id: number, first_name: string, last_name: string, dob: string) {
    this.patient_id = id;
    this.editApmtModel.patient = first_name + ' ' + last_name + ' - ' + dob;
    this.requireMessage.patient_id = null;
    jQuery(event.srcElement).closest('.input-group').removeClass('open');
  }

  getInfoByNumberClicked(event: any, id: number, phone_mobile: number, first_name: string, last_name: string) {
    this.patient_id = id;
    this.editApmtModel.patient = phone_mobile + ' ' + first_name + ' ' + last_name;
    this.requireMessage.patient_id = null;
    jQuery(event.srcElement).closest('.input-group').removeClass('open');
  }

  /* confirmationNoClicked() {
      this.confirmation.close();
  }

  confirmationYesClicked() {
      this.confirmation.close();
      this.callToWebService(1);
  } */

  // ========== Class Method ========== //

  ngAfterViewInit() {
    jQuery('.search-button').on('click', function (e: any) {
      if (jQuery('.search').hasClass('open')) {
        e.stopPropagation();
      }
    });
    jQuery(this.inputDateEditAppointment.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      minDate: 0,
      showButtonPanel: true,
      onSelect: (selected: any) => {
        this.editApmtModel.date = jQuery(this.inputDateEditAppointment.nativeElement).val();
        this.selectedDate = moment(selected).local();
        this.fetchAppointmentByDate(this.selectedDate);
        this.errorMessageBook = null;
      }
    });
    jQuery(this.clickDateEditAppointment.nativeElement).click(() => {
      jQuery(this.inputDateEditAppointment.nativeElement).datepicker('show');
    });
    jQuery(document).on('click', function (e: any) {
      if (jQuery(e.target).hasClass('result dropdown-menu')
        || jQuery(e.target).hasClass('error-message')) {
        // TODO: Close dropdown when click outside
      } else {
        jQuery('#search-field-new-appointment').closest('.input-group').removeClass('open');
      }
    });
  }

  ngOnDestroy() {
    if (this.editApmtSubscriber) {
      this.editApmtSubscriber.unsubscribe();
    }
    if (this.searchPatientSubscriber) {
      this.searchPatientSubscriber.unsubscribe();
    }
    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    if (this.editAppointmentSubscriber) {
      this.editAppointmentSubscriber.unsubscribe();
    }
    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
  }

  // ========== General Modal Method ========== //

  dismiss() {
    this.editApmtModel.patient = this.patient_id = null;
    this.requireMessage = new RequireMessage();
    jQuery('#search-field-edit-appointment').parent().removeClass('open');
  }

  onKeyUp() {
    this.requireMessage.patient_id = null;
    if (this.editApmtModel.patient === '') {
      this.patient_id = null;
      this.searchDisabled = true;
      jQuery('.search').removeClass('open');
    } else {
      this.searchDisabled = false;
    }
  }

  /* onClicked(error: string) {
      switch (error) {
          case 'date':
              this.requireMessage.date = null;
              break;
          case 'start_time':
              this.requireMessage.start_time = null;
              break;
          case 'end_time':
              this.requireMessage.end_time = null;
              break;
      }
  } */

  open() {
    this.modal.open();
    this.setInitial();
  }

  close() {
    this.modal.close();
  }
  fetchAppointmentType(selectedId:any) {
    let practiceId = localStorage.getItem('user_practice');

    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.fetchAppointmentTypeSubscriber = this.providersService.fetchAppointmentType(practiceId)
      .subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          this.appointmentTypeList = success.apttypes;
          this.editApmtModel.event_reason_id = selectedId;
        }
      );
  }
}

export class EditApmtClass {
  patient = '';
  date: string;
  appointment_reason:string='';
  event_reason_id:string='';
}

export class PatientInfoClass {
  id: number;
  first_name: string;
  last_name: string;
  dob: string;
  phone_mobile: number;
}

export class RequireMessage {
  patient_id: string;
  date: string;
  start_time: string;
  end_time: string;
  appointment_reason:string='';
  event_reason_id:string='';
}
