<div class="wrapper-patient patent-protal">
  <rtn-patient-header></rtn-patient-header>
  <div class="container user-portal patient-wraper clear-both padding-top-15"
       [class.container.user-portal.patient-wraper.clear-both]="isActivatedScreen"
       [style.min-height.px]="minHeight"
       [class.has-download]="_systemData.localStorage.getItem('app_download_ignore') === '0'"
       role="main">
    <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 no-padding-left-right" *ngIf="showMenu">
      <nav class="navbar navbar-static-top col-md-12 col-lg-12 hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none leftAccountMainMenu">
        <div class="container no-padding-left-right">
          <ul class="col-12 navbar-nav main-menu pr-0 bg-white">
            <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('main')"
                 [class.active]="_systemService.navActivated(['/patient', 'dashboard'], _router)">
                <i class="fa fa fa-th-large style-icon" aria-hidden="true"></i>Main
              </a>
            </li>
            <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('account')"
                 [class.active]="_systemService.navActivated(['/patient', 'edit'], _router)">
                <i class="fa fa-user-circle style-icon" aria-hidden="true"></i>Account
              </a>
            </li>
            <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('insurance')"
                 [class.active]="_systemService.navActivated(['/patient', 'insurance'], _router)">
                <i class="fa fa-plus-square style-icon" aria-hidden="true"></i>Insurance
              </a>
            </li>
            <!--<li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu" *ngIf="arrayModule[5]">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('appointments')"
                 [class.active]="_systemService.navActivated(['/patient', 'appointment', 'find' ], _router) || _systemService.navActivated(['/patient', 'appointment',  'openings'], _router) ||
                            _systemService.navActivated(['/patient', 'appointment', 'openings?provider'], _router)">                
                <i class="fa fa-calendar-alt style-icon" aria-hidden="true"></i>Find Appointments
              </a>
            </li>-->
            <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu" *ngIf="arrayModule[5]">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('appointments')"
                 [class.active]="_systemService.navActivated(['/patient', 'appointment', 'openings' ], _router) ||
                            _systemService.navActivated(['/patient', 'appointment', 'openings?provider'], _router)">                
                <i class="fa fa-user-md style-icon" aria-hidden="true"></i>Find Appointments
              </a>
            </li>
            <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu" *ngIf="arrayModule[5]">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('myappointments')"
                 [class.active]="_systemService.navActivated(['/patient', 'appointment', 'find' ], _router) || _systemService.navActivated(['/patient', 'appointment', 'openings?provider'], _router)">                
                <i class="fa fa-calendar-alt style-icon" aria-hidden="true"></i>Neighborhood Appointments
              </a>
            </li>
            <!--<li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu" *ngIf="arrayModule[1]">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('notifications')"
                 [class.active]="_systemService.navActivated(['/patient', 'notification'], _router)">
                <i class="fa fa-bell-o style-icon" aria-hidden="true"></i>Notifications
                <div class="notification-number" *ngIf="NumOfUnreadNotification">{{NumOfUnreadNotification}}</div>
              </a>
            </li>-->
            <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu" *ngIf="arrayModule[3]">
              <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('find-providers')"
                 [class.active]="_systemService.navActivated(['/patient', 'find-providers'], _router)
                 ||_systemService.navActivated(['/patient', 'follow-providers'], _router)
                 || this._router.url.split('/')[2] =='provider-detail'">
                <i class="fa fa-search-plus style-icon" aria-hidden="true"></i>Find Providers
              </a>
            </li>
          </ul>
        </div>
      </nav>
    <div class="mobile-side-nav">
    <div class="row justify-content-end account-nav-btn-wrap">
      <button class="btn account-nav-btn" (click)="openPatientMenufunction()">
        <i class="fas fa-bars text-dark"></i>
      </button>
    </div>
 
    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="account-nav" [class.open-account-nav]="patientMobileMenu" id="bs-example-navbar-collapse-1">
     
      <div class="close account-nav-btn" (click)="closePatientMenufunction()">
        <span class="first"></span>
        <span class="last"></span>
      </div>
				<ul class="nav navbar-nav">
					<li role="nav-item presentation" class="style-side-menu">
            <a class=" px-3 py-2" (click)="onChangeMenu('main')"
               [class.active]="_systemService.navActivated(['/patient', 'dashboard'], _router)">
              <i class="fa fa fa-th-large style-icon" aria-hidden="true"></i>Main
            </a>
          </li>
          <li role="nav-item presentation" class="style-side-menu">
            <a class=" px-3 py-2" (click)="onChangeMenu('account')"
               [class.active]="_systemService.navActivated(['/patient', 'edit'], _router)">
              <i class="fa fa-user-circle style-icon" aria-hidden="true"></i>Account
            </a>
          </li>
          <li role="nav-item presentation" class="style-side-menu">
            <a class=" px-3 py-2" (click)="onChangeMenu('insurance')"
               [class.active]="_systemService.navActivated(['/patient', 'insurance'], _router)">
              <i class="fa fa-plus-square style-icon" aria-hidden="true"></i>Insurance
            </a>
          </li>
        
          <li role="nav-item presentation" class="style-side-menu" *ngIf="arrayModule[5]">
            <a class="px-3 py-2" (click)="onChangeMenu('appointments')"
               [class.active]="_systemService.navActivated(['/patient', 'appointment', 'openings' ], _router) ||
                          _systemService.navActivated(['/patient', 'appointment', 'openings?provider'], _router)">                
              <i class="fa fa-user-md style-icon" aria-hidden="true"></i>Find Appointments
            </a>
          </li>
          <li role="nav-item presentation" class="style-side-menu" *ngIf="arrayModule[5]">
            <a class=" px-3 py-2" (click)="onChangeMenu('myappointments')"
               [class.active]="_systemService.navActivated(['/patient', 'appointment', 'find' ], _router) || _systemService.navActivated(['/patient', 'appointment', 'openings?provider'], _router)">                
              <i class="fa fa-calendar-alt style-icon" aria-hidden="true"></i>Neighborhood Appointments
            </a>
          </li>
         
          <li role="nav-item presentation" class="style-side-menu" *ngIf="arrayModule[3]">
            <a class=" px-3 py-2" (click)="onChangeMenu('find-providers')"
               [class.active]="_systemService.navActivated(['/patient', 'find-providers'], _router)
               ||_systemService.navActivated(['/patient', 'follow-providers'], _router)
               || this._router.url.split('/')[2] =='provider-detail'">
              <i class="fa fa-search-plus style-icon" aria-hidden="true"></i>Find Providers
            </a>
          </li>
				</ul>
      </div>

    </div>

      
   
	
   
    <!-- continues the page content ... -->
 
    
    <!-- Sidenav -->
 
      <!-- <nav class="navbar col-12 no-padding-left hidden-md hidden-lg d-xl-none d-lg-none d-md-none d-sm-block d-block navbar-expand-md pl-3">
        <div class="form-group">
          <select class="col-12 fontawesome-select menu-device" id="menu-device"
              (change)="onChangeMenu($event.target.value)" [value]="menuSelect" [(ngModel)]="menuSelect">
              <option class="menu-device-item" value="main">Main</option>
              <option class="menu-device-item" value="account">Account</option>
              <option class="menu-device-item" value="insurance">Insurance</option>
              <option class="menu-device-item" value="appointments">Appointments</option>
              <option class="menu-device-item" value="notifications">Notifications</option>
              <option class="menu-device-item" value="find-providers">Find Providers</option>
          </select>
        </div>
      </nav> -->
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="!showMenu">
      <router-outlet></router-outlet>
    </div>
    <div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9" *ngIf="showMenu">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<rtn-modals-verify-phoneno></rtn-modals-verify-phoneno>
<rtn-common-footer></rtn-common-footer>
<!-- <app-dashboard></app-dashboard> -->
<!-- <app-insurance></app-insurance> -->
<!-- <app-account></app-account> -->
