<div class="provider-follow">
    <div class="col-12 no-padding-left margin-bottom-15 back-to-find-provider">
        <a [routerLink]="['/patient', 'find-providers']">
            <i class="fa fa-share fa-flip-horizontal" aria-hidden="true"></i>
            Back to Find Providers
        </a>
    </div>
    <div class="col-12 no-padding-left-right text-header-title margin-bottom-15 text-uppercase">
        Followed Providers
        <a class="btn btn-unfollow-providers btn-rtn btn-90 float-right" (click)="showProviderUnfollowDirective()">Unfollow</a>
    </div>
    <div class="row clear-both margin-bottom-20 table-responsive">
        <rtn-table #table (onDataChange)="handleFetchFollowedProvider($event)" [data]="followedProvider.data" [rowsOnPage]="followedProvider.rowsOnPage" [numberOfPages]="followedProvider.numberOfPages">
            <div class="col-md-12 listview px-0">
                <div >
                    <table class="table table-bordered table-ellipsis" [class.hidden]="isMobile">
                        <thead>
                            <tr>
                                <th class="provider-unfollow">
                                    <div class="input-rtn">
                                        <input class="checkbox check-all" type="checkbox" name="selectAll" id="selectorAll" (click)="selectAll()">
                                        <label class="selectorExtreme" for="selectorAll"></label>
                                    </div>
                                </th>
                                <th class="provider-table-follow provider-action text-center">View</th>
                                <th class="provider-table-follow provider-name" id="follow-provider-name">Full Name</th>
                                <th class="provider-table-follow provider-specialties" id="follow-provider-specialties">Specialties</th>
                                <th class="provider-table-follow provider-address" id="follow-provider-address">Address</th>
                                <th class="provider-table-follow provider-phone" id="follow-provider-phone">Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of table.data; let i = 'index'">
                                <td class="provider-unfollow">
                                    <div class="input-rtn">
                                        <input class="checkbox check-single" type="checkbox" name="select-list[]" value="{{ item.id }}" (click)="checkSelectStatus()" [attr.id]="'selector'+i">
                                        <label class="selectorExtreme" [attr.for]="'selector'+i"></label>
                                    </div>
                                </td>
                                <td class="provider-action text-center" [routerLink]="['/patient', 'provider-detail', item.id]">
                                    <div>Details</div>
                                </td>
                                <td class="provider-name wrapword">{{ item.fullName }}<div></div></td>
                                <td class="provider-specialties wrapword">{{ item.specialties }}</td>
                                <td class="provider-address wrapword">
                                    <div>{{ item.address }}</div>
                                    <div>{{ item.address2 }}</div>
                                    <div>
                                        <span>{{ item.city }}</span><span *ngIf="item.state_code !== null">,</span>
                                        <span>{{ item.state_code }}</span>
                                        <span>{{ item.zip }}</span>
                                    </div>
                                </td>
                                <td class="provider-phone wrapword">{{ item.phone_mobile }}</td>
                            </tr>
                            <tr *ngIf="followedProvider.numberOfRow == 0">
                                <td colspan="7" class="no-provider">There is no provider in personal list</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="table.numberOfPages > 1">
                            <tr>
                                <td colspan="7" class="text-center">
                                    <div class="page-footer">
                                        <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                    <table class="table table-bordered table-ellipsis" [class.hidden]="!isMobile">
                        <thead>
                            <tr>
                                <th>
                                    <div class="input-rtn">
                                        <input class="checkbox check-all" type="checkbox" name="selectAll" id="selectorAll" (click)="selectAll()">
                                        <label class="selectorExtreme" for="selectorAll"></label>
                                    </div>
                                </th>
                                <th class="provider-table-follow provider-fullname-address align-middle">Full Name - Address</th>
                                <th class="provider-table-follow provider-specialties-phonenumber align-middle">Specialties - Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of table.data; let i = 'index'">
                                <td>
                                    <div class="input-rtn">
                                        <input class="checkbox check-single" type="checkbox" name="select-list[]" value="{{ item.id }}" (click)="checkSelectStatus()" [attr.id]="'selector'+i">
                                        <label class="selectorExtreme" [attr.for]="'selector'+i"></label>
                                    </div>
                                </td>
                                <td class="provider-fullname-address align-top">
                                    <span class="wrapword">{{ item.fullName }}</span><br/><br/>
                                    <div>{{ item.address }}</div>
                                    <div>{{ item.address2 }}</div>
                                    <div>
                                        <span>{{ item.city }}</span><span *ngIf="item.state_code !== null">,</span>
                                        <span>{{ item.state_code }}</span>
                                        <span>{{ item.zip }}</span>
                                    </div>
                                </td>
                                <td class="provider-specialties-phonenumber align-top text-left">
                                    {{ item.specialties }}<br/>
                                    <div>{{ item.phone_mobile }}</div><br/>
                                    <div class="text-right" [routerLink]="['/patient', 'provider-detail', {id: item.id}]">Details</div>
                                </td>
                            </tr>
                            <tr *ngIf="table.numberOfPages == 0 ">
                                <td colspan="7" class="no-provider">There is no provider in personal list</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="table.numberOfPages > 1">
                            <tr>
                                <td colspan="7" class="text-center">
                                    <div class="page-footer">
                                        <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
            </div>
        </rtn-table>
    </div>
</div>
<rtn-modals-provider-unfollow (onClick)="unfollowProvider(table)"></rtn-modals-provider-unfollow>
