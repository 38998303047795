import { RtnDataTableCoreComponent } from './datatable/core';
import { RtnDataTableSorterComponent } from './datatable/sorter';
import { RtnDataTableFilterComponent } from './datatable/filter';
import { RtnDataTablePagerComponent } from './datatable/pager';
import * as RtnDataTablePagerBootstrapComponent from '@app/shared/datatable/pager-bootstrap';

export { RtnDataTableCoreComponent } from './datatable/core';
export { RtnDataTableSorterComponent } from './datatable/sorter';
export { RtnDataTableFilterComponent } from './datatable/filter';
export { RtnDataTablePagerComponent } from './datatable/pager';

export const DataTableDirectives: any[] = [
    RtnDataTableCoreComponent,
    RtnDataTableSorterComponent,
    RtnDataTableFilterComponent,
    RtnDataTablePagerComponent,
    RtnDataTablePagerBootstrapComponent
];
