<h3 class="edit-header text-uppercase">My Account</h3>
<div class="first-letter">{{_systemData.localStorage.getItem('userFullName').substring(0,1)}}</div>
<div class="full-name bg-white">{{_systemData.localStorage.getItem('userFullName') | titlecase }}</div>

  <div class="row accountWrapper">
    <div class="col-xl-7 col-sm-12 col-12 pl-md-0 accountBlock">
      <div class="col-12 panel-default account-panel">
        <div class="panel-heading">
          <div class="panel-title">Account</div>
        </div>
        <div class="panel-body py-3 bg-white">
          <form method="POST" #accountForm="ngForm" (ngSubmit)="updateAccount(accountForm.value)" novalidate>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="first_name" class="input-label-required">First Name</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-11 col-12">
                <input type="text" class="form-control" id="first_name"
                       [(ngModel)]="_patientDetail.first_name" ngControl="first_name"
                       name="first_name" (keyup)="onKeyUp($event, 'first_name')"
                       maxlength="50" [class.input-error]="error['first_name']">
                <div class="message-error">{{ error.first_name }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="last_name" class="input-label-required">Last Name</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-11 col-12">
                <input type="text" class="form-control" id="last_name"
                       [(ngModel)]="_patientDetail.last_name" ngControl="last_name"
                       (keyup)="onKeyUp($event, 'last_name')" maxlength="50" name="last_name"
                       [class.input-error]="error['last_name']">
                <div class="message-error">{{ error.last_name }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="email" class="input-label-required">Email Address</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-11 col-12">
                <input type="text" class="form-control" id="email" [(ngModel)]="_patientDetail.email"
                       ngControl="email" (input)="onChangeEmail()" name="email"
                       maxlength="254" [class.input-error]="error['email']" (keyup)="onKeyUp($event, 'email')">
                <div class="message-error">{{ error.email }}</div>
              </div>
            </div>

            <div class="row hidden" id="confirm_email">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="email_confirmation" class="input-label-required">Confirm Email Address</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-11 col-12">
                <input type="text" class="form-control" id="email_confirmation" ngControl="email_confirmation" [(ngModel)]="_patientDetail.email_confirmation"
                       (keyup)="onKeyUp($event, 'email_confirmation')" maxlength="254" name="email_confirmation"
                       [class.input-error]="error['email_confirmation']">
                <div class="message-error">{{ error.email_confirmation }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="dob" class="input-label-required">Date of Birth</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-11 col-12 px-0">
                <rtn-dob [formInput]="_patientDetail" [error]="error.dob" (change)="clearErrorDob()" id="dob" (removeError)="onKeyUp($event,'test')"></rtn-dob>
                <div class="message-error">{{ error.dob }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="phone_mobile" class="no-padding-left-right">Mobile Number</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7  col-12 mobile-div">
                <rtn-mobile-number class="font-text" id="phone_mobile" [formInput]="_patientDetail" [error]="error.phone_mobile" (keyup)="onKeyUp($event, 'phone_mobile')"></rtn-mobile-number>
                <div *ngIf="showStatusPhoneVerify"><label *ngIf="!statusPhoneVerify" style="color:darkred; margin-top:6px;">Mobile number not verified</label></div>
                <div class="message-error" style="min-height: auto;">{{ error.phone_mobile }} </div>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-12 col-12 text-right" *ngIf="showStatusPhoneVerify">
                <label *ngIf="statusPhoneVerify" style="color:green;margin-top:5px;margin-right:16px; font-weight: bold;">Verified</label>
                <!--<label *ngIf="!statusPhoneVerify"  >Unverified</label>-->
                <button type="button" class="btn btn-rtn text-uppercase" *ngIf="!statusPhoneVerify" (click)="Openverifyphonenomodal('mobileno')">Verify</button>
                <!--<i *ngIf="statusPhoneVerify" class="fa fa-check phone-verified" aria-hidden="true" title="Confirmed"></i>
                <i *ngIf="!statusPhoneVerify" class="fa fa-times phone-unverified" aria-hidden="true" title="Not confirmed yet"></i>-->
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="alt_phonemobile" class="no-padding-left-right">Alt. Mobile Number</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-12 mobile-div">
                <rtn-mobile-number class="font-text" id="alt_phonemobile" [formInput]="alt_phonemobile" [error]="error.alt_phonemobile" (keyup)="onKeyUp($event, 'alt_phonemobile')"></rtn-mobile-number>
                <div *ngIf="showStatusAltPhoneVerify"><label *ngIf="!statusAltPhoneVerify" style="color:darkred; margin-top:6px;">Alt. mobile number not verified</label></div>
                <div class="message-error" style="min-height: auto;">{{ error.alt_phonemobile }}</div>
              </div>
              <div class="col-xl-1 text-right" *ngIf="showStatusAltPhoneVerify">
                <label *ngIf="statusAltPhoneVerify" style="color:green;margin-top:5px;margin-right:16px; font-weight: bold;" >Verified</label>
                <!--<label *ngIf="!statusAltPhoneVerify" >Unverified</label>-->
                <button type="button" class="btn btn-rtn text-uppercase" *ngIf="!statusAltPhoneVerify" (click)="Openverifyphonenomodal('alt_mobileno')">Verify</button>
                <!--<i *ngIf="statusAltPhoneVerify" class="fa fa-check phone-verified" aria-hidden="true" title="Confirmed"></i>
                <i *ngIf="!statusAltPhoneVerify" class="fa fa-times phone-unverified" aria-hidden="true" title="Not confirmed yet"></i>-->
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12 text-label">
                <label for="contact_email" class="input-label-required">Contact Email Address</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-11 col-12">
                <input type="text" class="form-control" id="contact_email" [(ngModel)]="_patientDetail.contact_email"
                       ngControl="contact_email" (input)="onChangeContactEmail()" name="contact_email"
                       maxlength="254" [class.input-error]="error['contact_email']" (keyup)="onKeyUp($event, 'contact_email')">
                <div class="message-error">{{ error.contact_email }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-11 text-label">
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-11 col-12">
                <button type="submit" class="btn btn-rtn text-uppercase">Update</button>
                <span class="message-success">{{ successMessageAccount }}</span>
              </div>
            </div>

          </form>
        </div>

      </div>
    </div>
    <div class="col-xl-5 col-12 pr-md-0 passwordBlock">
      <div class="col-12 panel-default password-panel">
        <div class="panel-heading">
          <div class="panel-title">Password</div>
        </div>
        <div class="panel-body py-3 bg-white">
          <form method="POST" #passwordForm="ngForm" (ngSubmit)="updatePassword(passwordForm.value)" novalidate>
            <div class="row">
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-label responsive-label">
                <label for="password_current" class="input-label-required">Current Password</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                <input id="password_current" type="password" class="form-control" ngControl="password_current" (keyup)="onKeyUp($event, 'password_current')"
                       [class.input-error]="error['password_current']" name="password_current" [(ngModel)]="_patientDetail.password_current">
                <div class="message-error">{{ error.password_current }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-label responsive-label">
                <label for="password" class="input-label-required">New Password</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                <input id="password" type="password" class="form-control" ngControl="password" minlength="8" maxlength="50" name="password"
                       (keyup)="onKeyUp($event, 'password')" [class.input-error]="error['password']" [(ngModel)]="_patientDetail.password">
                <div class="message-error">{{ error.password }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-label responsive-label">
                <label for="password_confirmation" class="input-label-required">Confirm Password</label>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                <input id="password_confirmation" type="password" class="form-control" ngControl="password_confirmation" name="password_confirmation" [(ngModel)]="_patientDetail.password_confirmation"
                       minlength="8" maxlength="50" (keyup)="onKeyUp($event, 'password_confirmation')" [class.input-error]="error['password_confirmation']">
                <div class="message-error">{{ error.password_confirmation }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-label">
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                <button type="submit" class="btn btn-rtn text-uppercase">Update</button>
                <div class="padding-top-10 message-success">{{ successMessagePassword }}</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 panel-default address-panel">
    <div class="panel-heading">
        <div class="panel-title">Address</div>
    </div>
    <div class="panel-body">
        <form method="POST" #addressForm="ngForm" (ngSubmit)="updateAddress(addressForm.value)" novalidate>
            <div class="row">
                <div class="col-xl-7 col-12 bg-white py-3">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 text-label">
                            <label for="address" class="input-label-required">Address Line 1</label>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                            <input id="address" type="text" class="form-control" [(ngModel)]="_patientDetail.address"
                                ngControl="address" name="address" maxlength="50" (keyup)="onKeyUp($event, 'address')"> <!-- disabled s-->
                            <div class="message-error">{{ error.address }}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 text-label">
                            <label for="address2" class="">Address Line 2</label>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                            <input id="address2" type="text" class="form-control" [(ngModel)]="_patientDetail.address2"
                                ngControl="address2" name="address2" maxlength="50" (keyup)="onKeyUp($event, 'address2')"> <!--disabled-->
                            <div class="message-error">{{ error.address2 }}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 text-label">
                            <label for="city" class="input-label-required">City</label>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                            <input id="city" type="text" class="form-control" [(ngModel)]="_patientDetail.city"
                                ngControl="city" name="city" maxlength="50"
                                (keyup)="onKeyUp($event, 'city')"> <!-- disabled -->
                            <div class="message-error">{{ error.city }}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 text-label">
                            <label for="first_address_line" class="input-label-required">State</label>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                            <div class="input-group select state"> <!-- disabled -->
                                <select class="form-control" id="state" [(ngModel)]="_patientDetail.state_code"
                                    (change)="onKeyUp($event, 'state_code')" name="state_code"
                                    (change)="onKeyUp($event, 'state')">
                                    <option [value]="''" selected>Select State</option>
                                    <option *ngFor="let stateItem of _systemData.states" [value]="stateItem.code">{{stateItem.name}}</option>
                                </select>
                                <div class="input-group-select-icon">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ error.state_code }}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 text-label">
                            <label for="zip" class="input-label-required">Zip Code</label>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                            <input id="zip" type="text" class="form-control" [(ngModel)]="_patientDetail.zip"
                                ngControl="zip" (keyup)="onKeyUp($event, 'zip')" simplemask [format]="'#####-####'" name="zip"
                                [target]="_patientDetail" [field]="'zip'" [class.input-error]="error['zip']" maxlength="10" > <!--disabled-->
                            <div class="message-error">{{ error.zip }}</div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-label">
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                        <button type="submit" class="btn btn-rtn text-uppercase">Update</button>
                        <div class="padding-top-10 message-success">{{ successMessageAddress }}</div>
                      </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<rtn-modals-verify-phoneno></rtn-modals-verify-phoneno>