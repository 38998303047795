import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SystemData, SystemService, SystemEvent, SystemInterval } from '@app/shared/system';

declare let jQuery: any;

@Component({
  selector: 'app-data-logs',
  templateUrl: './data-logs.component.html',
  styleUrls: ['./data-logs.component.scss']
})
export class DataLogsComponent implements OnInit {
  public menuSelect = '';
  private getMenuDevice: String = '#menu-device';
  private defaultRoute: String = '/admin/';
  public isDownload:boolean = false;
  public tenantname:string = '';
  minHeight: number;

  constructor(
    public _router: Router,
    public _systemData: SystemData,
    public _systemService: SystemService,
    private _route: ActivatedRoute
  ) {
    this._route.queryParams.subscribe((params:any) => {
      if (params['isDownload'] != undefined && params['isDownload'] != null) {
        this.isDownload = params['isDownload'];
      }      
    });
  }

  ngOnInit() {

    //console.log("is Download : " + this.isDownload);

    const _seft = this;
    const pathname = window.location.hash;
    const splitted = pathname.split('/');
    const action = splitted[splitted.length - 1];
    switch (action) {
      case 'waittimelog-by-provider':
        this.menuSelect = 'Providers Waittime';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'appointment-by-slot':
        this.menuSelect = 'Appoiment Slot';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'appointment-by-day':
        this.menuSelect = 'Appointment by Day';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'duration-log-by-slot':
        this.menuSelect = 'Slot Duration';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'notification-log':
        this.menuSelect = 'Notification';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'statistical-appointment':
        this.menuSelect = 'Statistical Appointment';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'patient-waittime':
        this.menuSelect = 'Patient Waittime';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'plan-actual-comparing':
        this.menuSelect = 'Compare Appointment';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'waittime-office':
        this.menuSelect = 'Offices Wait Time';
        jQuery(_seft.getMenuDevice).blur();
        break;
      case 'appointment-by-slot-withlab':
        this.menuSelect = 'appointment-by-slot-withlab';
        jQuery(_seft.getMenuDevice).blur();
        break;  
      default:
        break;
    }

    // setTimeout(() => {
    //   const pathname = window.location.hash;
    //  if (pathname === '/admin/chart/appointment-provider'
    //  || pathname === '/admin/chart/appointment-by-day'
    //   || pathname === '/admin/chart/waittime' || pathname === '/admin/chart/duration-log') {
    //     this.showMenu = false;
    //   } else {
    //     this.showMenu = true;
    //   }
    // }, 100);
    
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }

  onChangeMenu(value: any) {
    const _seft = this;
    switch (value) {
      case 'Providers Waittime':
        this._router.navigate([_seft.defaultRoute, 'waittimelog-by-provider']).then(() => {
          window.location.reload();
        });
      break;
      case 'Appoiment Slot':
        this._router.navigate([_seft.defaultRoute, 'appointment-by-slot']).then(() => {
          window.location.reload();
        });
        break;
      case 'Appointment by Day':
        this._router.navigate([_seft.defaultRoute, 'appointment-by-day']).then(() => {
          window.location.reload();
        });
        break;
      
      case 'Slot Duration':
        this._router.navigate([_seft.defaultRoute, 'duration-log-by-slot']).then(() => {
          window.location.reload();
        });
        break;
      case 'Statistical Appointment':
          this._router.navigate(['/admin/chart', 'statistical-appointment']).then(() => {
            window.location.reload();
          });
          break;
      case 'Patient Waittime':
          this._router.navigate(['/admin', 'patient-waittime']).then(() => {
            window.location.reload();
          });
          break;  
      case 'Notification':
        this._router.navigate(['/admin/', 'notification-log']).then(() => {
          window.location.reload();
        });
        break;
      
      case 'Compare Appointment':
        this._router.navigate(['/admin', 'plan-actual-comparing']).then(() => {
          window.location.reload();
        });
        break;
      case 'Offices Wait Time':
        this._router.navigate(['/admin', 'waittime-office']).then(() => { //offices-waittime
          window.location.reload();
        });
        break;
      case 'schedule-activity':
          this._router.navigate(['/admin', 'schedule-activity']).then(() => { //offices-waittime
            window.location.reload();
          });
          break;
      case 'Appointment Statistical by types':
          this._router.navigate(['/admin', 'statistical-appointment-by-types']).then(() => { //offices-waittime
            window.location.reload();
          });
          break;
      case 'schedule-arrival-comparing':
        this._router.navigate(['/admin', 'schedule-arrival-comparing']).then(() => { //offices-waittime
          window.location.reload();
        });
        break;
      case 'cancel-reschedule-statistics':
        this._router.navigate(['/admin', 'cancel-reschedule-statistics']).then(() => { //offices-waittime
          window.location.reload();
        });
        break;
      case 'appointment-by-slot-withlab':
          this._router.navigate([_seft.defaultRoute, 'appointment-by-slot-withlab']).then(() => {
            window.location.reload();
          });
          break;              
      default:
        break;
    }
    jQuery(_seft.getMenuDevice).blur();
  }

  getSubDomain() {
    const fullDomain = window.location.host;
    const path = fullDomain.split('.');
    if (path[1] == 'dev') {
        this.tenantname = path[0] + '.' + path[1];
    } else {
      this.tenantname = path[0];
    }
  }
}
