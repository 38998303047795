<modal #ResendEmail_Modals>
  <div class="resend-modal">
      <div class="content">
          <p>You are attempting to log in to an inactive account. To activate your account please follow these instructions:</p>
          <ul>
              <li>Click resend email account verification</li>
              <li>Open to your email and click the activation link</li>
              <li>Log in to your account</li>
          </ul>
      </div>
      <button class="btn btn-lg text-uppercase btn-rtn-radius resend-button" (click)="resend()">Resend Email</button>
      <button class="btn btn-lg btn-rtn text-uppercase btn-rtn-radius ok-button pull-right" (click)="close()">OK</button>
  </div>
</modal>

<modal #Contact_Modals>
  <div class="resend-modal">
      <div class="content">
          <p>You are attempting to log in to an inactive account. If you believe this in error please contact our Customer Care team at:</p>
          <p>(866) 555-1212</p>
          <p>Monday to Friday</p>
          <p>8am to 6pm ET</p>
          <a href="mailto:Support@RTN.com">Support@RTN.com</a>
      </div>
      <button class="btn btn-lg btn-rtn text-uppercase btn-rtn-radius ok-button pull-right" (click)="close()">OK</button>
  </div>
</modal>

<modal #SuccessResend_Modals>
  <div class="resend-modal success-resend">
      <div class="content">
          <p>{{ successResend }}</p>
      </div>
      <button class="btn btn-lg btn-rtn text-uppercase btn-rtn-radius ok-button pull-right" (click)="close()">OK</button>
  </div>
</modal>