<!--<rtn-provider-header></rtn-provider-header>-->
<div class="provider-edit">
  <div class="padding-left-15">
    <h3 class="text-header-form-edit">
      <a class="no-focus" [routerLink]="['/provider', 'user']">
        <span class="fa fa-angle-left style-top-arrow"></span>
      </a>
      Edit Managing User
    </h3>
  </div>
  <div class="row">

  
    <div class="col-xs-12 col-md-8">
        <form method="post" #form="ngForm" (ngSubmit)="updateManagingUserForm(form.value)">
      <div class="panel-edit-profile panel-default">
        <div class="panel-heading">
          <h3 class="panel-title text-title-form-edit">Contact Info</h3>
        </div>
        <div class="panel-body py-3">
          <div class="col-12 inline-edit-provider mb-3">
              <span class="text-label-style">Practice</span>
            <div class="input-group select width-full">
              <select class="form-control textbox-edit-form" [(ngModel)]="managingUserDetail.office_name" name="office_name" [class.input-error]="responseError['office_name']" (change)="onKeyUp('office_name')" disabled>
                <option [value]="''" selected>Select Practice</option>
                <option *ngFor="let practice of practiceList" [value]="practice.id">{{practice.name}}</option>
              </select>
              <div class="input-group-append cursorPointer select-state">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>

            <span class="message-error" *ngIf="responseError['office_name']">{{responseError['office_name']}}</span>
          </div>
          <div class="col-12 inline-edit-provider mb-3">
            <div class="row">
              <div class="px-0 col-6 email-address">
                <span class="text-label-style input-label-required">Email Address</span>
                <input class="form-control textbox-edit-form" type="text" name="email" [class.input-error]="responseError['email']" [(ngModel)]="managingUserDetail.email" maxlength="50" (keyup)="onKeyUp('email')">
                <span class="message-error" *ngIf="responseError['email']">{{responseError['email']}}</span>
              </div>
              <div class="pr-0 col-6">
                <div class="text-label-style input-label-required">Phone Number</div>
                <div>
                    <input class="form-control textbox-edit-form" name="phone_mobile" type="text" [class.input-error]="responseError['phone_mobile']" [(ngModel)]="managingUserDetail.phone_mobile" (keyup)="onKeyUp('phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                    <span class="message-error">{{ responseError.phone_mobile }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 inline-edit-provider mb-3">
            <div class="row">
              <div class="col-5 pl-0">
                <span class="text-label-style input-label-required">First Name</span>
                <input class="form-control textbox-edit-form" type="text" [(ngModel)]="managingUserDetail.first_name" name="first_name" [class.input-error]="responseError['first_name']" maxlength="50" (keyup)="onKeyUp('first_name')">
                <span class="message-error" *ngIf="responseError['first_name']">{{responseError['first_name']}}</span>
              </div>
              <div class="col-2 px-0">
                            <span for="middle_name">Middle
                                <span class="hidden-xs">Initial</span>
                            </span>
                <input id="middle-name" class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['middle_name']" [(ngModel)]="managingUserDetail.middle_name" name="middle_name" maxlength="1">
                <span class="message-error" *ngIf="responseError['middle_name']">{{responseError['middle_name']}}</span>
              </div>
              <div class="col-5 pr-0">
                <span class="text-label-style input-label-required">Last Name</span>
                <input class="form-control textbox-edit-form" type="text" [(ngModel)]="managingUserDetail.last_name" name="last_name" [class.input-error]="responseError['last_name']" maxlength="50" (keyup)="onKeyUp('last_name')">
                <span class="message-error" *ngIf="responseError['last_name']">{{responseError['last_name']}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 inline-edit-provider mb-3">
            <span class="text-label-style input-label-required">Address Line 1</span>
            <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address']" [(ngModel)]="managingUserDetail.address" name="address" maxlength="50" (keyup)="onKeyUp('address')">
            <span class="message-error" *ngIf="responseError['address']">{{responseError['address']}}</span>
          </div>
          <div class="col-12 inline-edit-provider mb-3">
            <span class="text-label-style">Address Line 2</span>
            <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address2']" [(ngModel)]="managingUserDetail.address2" name="address2" maxlength="50">
            <span class="message-error" *ngIf="responseError['address2']">{{responseError['address2']}}</span>
          </div>
          <div class="col-12 inline-edit-provider mb-3">
            <div class="row">
              <div class="col-5 px-0">
                <span class="text-label-style input-label-required">City</span>
                <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['city']" [(ngModel)]="managingUserDetail.city" name="city" maxlength="50" (keyup)="onKeyUp('city')">
                <span class="message-error" *ngIf="responseError['city']">{{responseError['city']}}</span>
              </div>
              <div class="col-4">

                  <span class="text-label-style input-label-required">State</span>
                <div class="input-group select width-full">
                  <select class="form-control textbox-edit-form" [(ngModel)]="managingUserDetail.state_code" name="state_code" [class.input-error]="responseError['state_code']" (change)="onKeyUp('state_code')">
                    <option [value]="''" selected>Select State</option>
                    <option *ngFor="let stateItem of _systemData.states" [value]="stateItem.code">{{stateItem.name}}</option>
                  </select>
                  <div class="input-group-append cursorPointer select-state">
                    <i class="fa fa-caret-down"></i>
                  </div>
                </div>
                <span class="message-error" *ngIf="responseError['state_code']">{{responseError['state_code']}}</span>
              </div>
              <div class="col-3 px-0">
                <span class="text-label-style input-label-required">ZIP Code</span>
                <input class="form-control textbox-edit-form" type="text" name="zip" [class.input-error]="responseError['zip']" [(ngModel)]="managingUserDetail.zip" (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'" [target]="managingUserDetail" [field]="'zip'" maxlength="10">
                <span class="message-error" *ngIf="responseError['zip']">{{responseError['zip']}}</span>
              </div>
            </div>

          </div>
          <div class="col-12">
              <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">SAVE</button>
              <span class="padding-left-15 message-success">{{ successMessage }}</span>
            </div>
        </div>
      </div>
    </form>
    </div>
    <div class="col-12 col-lg-4 no-padding">
      <form method="post" #pwdform="ngForm" (ngSubmit)="updatePasswordFromSuperuser(pwdform.value)" class="row padding-left-right-15">
          <div class="panel-edit-profile panel-default">
              <div class="panel-heading">
                <h3 class="panel-title text-title-form-edit">Change Password</h3>
              </div>
              <div class="card-body row">
                  <div class="col-md-12 row no-padding mb-3">
                      <span class="text-label-style input-label-required">Password</span>
                      <input class="form-control textbox-edit-form" type="password" [(ngModel)]="password" name="password" ngControl="password" [class.input-error]="responseError1['password']" maxlength="50" (keyup)="onKeyUp('password')">
                      <span class="message-error" *ngIf="responseError1['password']">{{responseError1['password']}}</span>
                  </div>
                  <div class="col-md-12 row no-padding mb-3">
                      <span class="text-label-style input-label-required">Confirm Password</span>
                      <input class="form-control textbox-edit-form" type="password" [(ngModel)]="password_confirmation" name="password_confirmation" ngControl="password_confirmation" [class.input-error]="responseError1['password_confirmation']" maxlength="50" (keyup)="onKeyUp('password_confirmation')">
                      <span class="message-error" *ngIf="responseError1['password_confirmation']">{{responseError1['password_confirmation']}}</span>
                  </div>
                  <div class="col-md-12 row no-padding mb-3">
                      <span class="text-label-style">
                      <input type="checkbox" [(ngModel)]="notify_to" name="notify_to" ngControl="ngControl" value="true"  /> Send Password to mail</span>
                  </div>
                  <div class="col-12 padding-bottom-20 no-padding-left">
                      <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Update</button>
                      <span class="padding-left-15 message-success">{{ successMessage1 }}</span>
                  </div>
              </div>
          </div>
      </form>
    </div>
  </div>
</div>
