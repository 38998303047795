<!--<div class="patient-notification col-12 pl-0 pr-0">
        <div class="text-header row col-12">
            <span class="col-6 pl-0">Message Center<div class="notification-number" *ngIf="numberOfNewNotifications">{{numberOfNewNotifications}}</div></span>
            <span class="col-6 link-to-detail"><a class="link link-style" [routerLink]="['/patient', 'notification']">View all notifications</a></span>
        </div>
        <div class="row col-12 notification-style">
            <div class="notification-item notification-label" *ngIf="numberOfNewNotifications">
                <div class="col-12 pl-0 pr-0">
                    <span>You have {{numberOfNewNotifications}} new notifications <i class="fa fa-refresh icon-refresh-style" aria-hidden="true" (click)="refreshData()"></i></span>
                </div>
            </div>
            <div *ngFor="let item of listNotification; let i = index" class="notification-item">
                <div class="col-12 px-0">
                    <span class="notification-name">{{ item.fullName }}: </span>
                    <span class="notification-message" *ngIf="item.type == 'OPENINGS_MULTI'" [innerHTML]="item.message"></span>
                    <span class="notification-message" *ngIf="item.type != 'OPENINGS_MULTI'" [innerHTML]="item.message"></span>
                </div>
                <div class="col-12 notification-time">{{item.timeAgo}}</div>
            </div>
            <div class="notification-item notification-no-message" *ngIf="!numberOfNewNotifications">
                <div class="col-12 pl-0 pr-0">
                    <span>You have no new notifications <i class="fa fa-refresh icon-refresh-style" aria-hidden="true" (click)="refreshData()"></i></span>
                </div>
            </div>
        </div>
    </div>-->

    <div class="patient-setting col-12 pl-0 pr-0 dashboardNotification">
        <form novalidate #form="ngForm" (ngSubmit)="updateNotificationSettingForm(form, notificationSetting)">
            <div class="text-header col-12">
                <span class="col-12 pl-0">Notifications</span>
            </div>
            <div class="col-12 block-setting-style p-4 bg-white">
                <div class="row">
                    <div class="form-check d-flex">
                        <div class="input-rtn">
                            <input class="checkbox check-single" type="checkbox" name="settingEmail" id="settingEmail"
                                [value]="notificationSetting.is_email" [(ngModel)]="notificationSetting.is_email"
                                (change)="removeMessage()">
                            <label class="selectorExtreme" for="settingEmail"></label>
                        </div>
                        <label class="form-check-label ml-2 pt-1">
                            Email Message ({{ notificationSetting.email }})
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-check d-flex">
                        <div class="input-rtn">
                            <input class="checkbox check-single" type="checkbox" name="settingSms" id="settingSms"
                                [value]="notificationSetting.is_sms" [(ngModel)]="notificationSetting.is_sms"
                                [disabled]="disabledSms" (change)="removeMessage()">
                            <label class="selectorExtreme" for="settingSms"></label>
                        </div>
                        <label class="form-check-label ml-2 pt-1">
                            SMS / Text Message ( {{notificationSetting.mobile_number}} )
                        </label>
                    </div>
                </div>
                <div class="col-12 border-line setting-style"></div>
                <div class="row">
                    <div class="form-check d-flex">
                        <div class="input-rtn">
                            <input class="checkbox check-single" type="checkbox" name="settingWaitTime" id="settingWaitTime"
                                [value]="notificationSetting.alert_waittime"
                                [(ngModel)]="notificationSetting.alert_waittime" (change)="removeMessage()">
                            <label class="selectorExtreme" for="settingWaitTime"></label>
                        </div>
                        <label class="form-check-label ml-2 pt-1">
                            Yes, I'd like to receive wait-time and scheduling alerts (when enabled by Provider)
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-check d-flex">
                        <div class="input-rtn">
                            <input class="checkbox check-single" type="checkbox" name="settingReminder" id="settingReminder"
                                [value]="notificationSetting.is_reminder" [(ngModel)]="notificationSetting.is_reminder"
                                (change)="removeMessage()">
                            <label class="selectorExtreme" for="settingReminder"></label>
                        </div>
                        <label class="form-check-label ml-2 pt-1">
                            Yes, I'd like to receive a reminder message before my scheduled appointments (when enabled by
                            Provider)
                        </label>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-xs-7 col-sm-6 col-xl-2 input-select-width">
                        <select class="custom-select select-day-style" [value]="notificationSetting.reminder_day"
                            [(ngModel)]="notificationSetting.reminder_day" [disabled]="notificationSetting.is_reminder == 0"
                            (change)="removeMessage()" name="something">
                            <option *ngFor="let time of timeSetting" [value]="time.value">{{time.name}}</option>
                        </select>
                        <!--<div class="input-group-select-icon">-->
                        <!--<i class="fa fa-caret-down"></i>-->
                        <!--</div>-->
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="col-12">
    
                        <button type="submit" class="btn btn-rtn btn-90 text-uppercase">SAVE</button>
                        <span *ngIf="messageResponseSuccess"
                            class="message-success my-auto pl-2">{{messageResponseSuccess}}</span>
                        <span *ngIf="messageResponseError"
                            class="message-error my-auto pl-2">{{messageResponseError}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>