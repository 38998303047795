import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { SuperProviderDetail } from '../../shared/models/superProviderDetail';
import { RegistrationLink } from '../../shared/models/registrationLink';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';

@Component({
  selector: 'app-add-master',
  templateUrl: './add-master.component.html',
  styleUrls: ['../admin.component.scss','./add-master.component.scss'],
  moduleId: module.id,
})
export class AddMasterComponent implements OnInit {

  superProviderDetail: SuperProviderDetail = new SuperProviderDetail();
    responseError: SuperProviderDetail = new SuperProviderDetail();
    registrationLink: any = [];
    scrollAppear: boolean = false;

    addSuperProviderSubscriber: Subscription;

    @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
    @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;

    constructor(
        public _systemData: SystemData,
        private _alertService: AlertService,
        private _systemService: SystemService,
        private _adminService: AdminRestService,
        private loadingIndicatorService: LoadingIndicatorService
    ) {
        this.initial();
    }

    initial() {
        this.registrationLink = [];
        this.addLink();
    }

    ngOnInit() {
        this._systemService.getStates();
    }

    ngOnDestroy() {
        if (this.addSuperProviderSubscriber) {
            this.addSuperProviderSubscriber.unsubscribe();
        }
    }

    addLink() {
        for (let i = 0; i < 5; i++) {
            let r = new RegistrationLink();
            this.registrationLink.push(r);
        }
    }

    addMore() {
        this.scrollAppear = true;
        this.addLink();
        setTimeout(function() {
            var objDiv = document.getElementById('scroll');
            objDiv.scrollTop = objDiv.scrollHeight;
        });
    }

    addSuperProviderClicked() {
        this.loadingIndicatorService.start();
        this.registrationLink.forEach((r: any) => {
            if (r.names !== undefined && r.email !== undefined) {
                this.superProviderDetail.providers.push(r);
            }
        });

        if (this.addSuperProviderSubscriber) {
            this.addSuperProviderSubscriber.unsubscribe();
        }

        this.addSuperProviderSubscriber = this._adminService.addSuperProvider(this.superProviderDetail).subscribe(
        (success: any) => {
            this.loadingIndicatorService.stop();
            if (success.error_code === 'RC000') {
                this._alertService.onClose = () => {
                    this.superProviderDetail = new SuperProviderDetail();
                    this.responseError = new SuperProviderDetail();
                };
                this._alertService.showDismissButton = false;
                this._alertService.showCloseButton = true;
                this._alertService.closeButtonLabel = 'OK';
                this._alertService.title = success.error_messages;
                this._alertService.message = '';
                this._alertService.emitter('open');
            }
        },
        (error: any) => {
            this.loadingIndicatorService.stop();
            let e = error.error;
            if (e.error_code === 'RC020') {
                this.responseError = e.error_fields;
            }
        });
    }

    onKeyUp(error: string) {
        switch (error) {
            case 'email':
                this.responseError.email = null;
                break;

            case 'account_name':
                this.responseError.account_name = null;
                break;

            case 'office_name':
                this.responseError.office_name = null;
                break;

            case 'phone_mobile':
                this.responseError.phone_mobile = null;
                break;

            case 'billing.address':
                (<any>this.responseError)['billing.address'] = null;
                break;

            case 'billing.address2':
                (<any>this.responseError)['billing.address2'] = null;
                break;

            case 'billing.city':
                (<any>this.responseError)['billing.city'] = null;
                break;

            case 'billing.state_code':
                (<any>this.responseError)['billing.state_code'] = null;
                break;

            case 'billing.zip':
                (<any>this.responseError)['billing.zip'] = null;
                break;

            case 'billing.first_name':
                (<any>this.responseError)['billing.first_name'] = null;
                break;

            case 'billing.last_name':
                (<any>this.responseError)['billing.last_name'] = null;
                break;

            case 'billing.email':
                (<any>this.responseError)['billing.email'] = null;
                break;

            case 'billing.phone_mobile':
                (<any>this.responseError)['billing.phone_mobile'] = null;
                break;
        }
    }

    preventWhiteSpaceInput(event: any) {
        if (event.target.value.match(/\s/g)) {
            event.target.value = event.target.value.replace(/\s/g, '');
        }
    }

}
