import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
// import { PasswordFormatTooltipComponent } from '@app/shared/tooltip/password-format';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { HomepageService } from '../home.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Utils } from '@app/shared/utils';
import { AlertService } from '@app/shared/alert';

declare let jQuery: any;

@Component({
  selector: 'rtn-home-patientnotification-setting',
  templateUrl: './patient-notification-setting.component.html',
  styleUrls: ['../home.component.scss','./patient-notification-setting.component.scss'],
  moduleId: module.id,
})
export class PatientNotificationSettingComponent implements OnInit {

    error: boolean = false;
    errorMessage: any;
    linkIsValid: boolean = true;
    id: string;
    token: string;
    showBtn: boolean = true;

    activateUserSubscriber: Subscription;
    patientSettings : any = {
        id : 0,
        full_name : '',
        phone_mobile_verified : 0,
        alert_waittime : 0,
        email : '',
        is_email : 0,
        is_reminder : 0,
        is_sms : 0,
        mobile_number : '',
        patient_user_id : 0,
        reminder_day : 0,
        alert_waitlist:0
    };
    
    notificationSetting: any = [];
    disabledSms = false;
    timeSetting: any = [];
    updateNotificationGlobalSettingSubscriber: Subscription;
    messageResponseSuccess = '';
    messageResponseError = '';
    minHeight: number;
    constructor(
        private _homePageService: HomepageService,
        private _utils: Utils,
        private _params: ActivatedRoute,
        private _router: Router,
        private _alertService: AlertService
    ) {
        // TODO
        this.timeSetting = [
            {'name': 'One day', 'value': '1'},
            {'name': 'Two days', 'value': '2'},
            {'name': 'Three days', 'value': '3'},
            {'name': 'Five days', 'value': '5'},
            {'name': 'One week', 'value': '7'}
        ];
    }

    ngOnInit() {
        // TODO: Get token/ id from URL
        this.id = this._params.snapshot.paramMap.get('id');
        //this.token = this._params.snapshot.paramMap.get('token');
        // TODO: Validate id & token
        this.getPatientuserInfo();
        this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
    }

    ngOnDestroy() {
        if (this.activateUserSubscriber) {
            this.activateUserSubscriber.unsubscribe();
        }
    }

    private getPatientuserInfo() {
        var self = this;
        
        if (this.activateUserSubscriber) {
            this.activateUserSubscriber.unsubscribe();
        }
        this.activateUserSubscriber = this._homePageService.getPatientuserinfo(this.id).subscribe((success: any) => {
            self.linkIsValid = true;
            //console.log(success.user);

            this.notificationSetting = success.user;
            this.notificationSetting.reminder_day = (this.notificationSetting.reminder_day !== 0) ? this.notificationSetting.reminder_day : 1;
            if (this.notificationSetting.mobile_number === '' || this.notificationSetting.mobile_number === null) {
                this.disabledSms = true;
                this.notificationSetting.is_sms = 0;
            }

        }, (error: any) => {
            this.linkIsValid = false;
            this.errorMessage = error.error.error_messages;
        });
    }
    updateNotificationSettingForm(form: any, data: any) {
           
        const _self = this;
        this.messageResponseSuccess = null;
        this.messageResponseError = null;
        if (this.updateNotificationGlobalSettingSubscriber) {
          this.updateNotificationGlobalSettingSubscriber.unsubscribe();
        }
        this.updateNotificationGlobalSettingSubscriber = this._homePageService.updatePatientNotificationSettingByCode(this.id, data).subscribe(
          (success: any) => {
            this.linkIsValid = true;
            if (success.error_code === 'RC000') {                
              this.messageResponseSuccess = success.error_messages;
            }
          },
          (error: any) => {
            if (error.error.error_code === 'RC087' || error.error.error_code === 'RC088') {
                this.messageResponseError = error.error.error_messages;
            }

            if (error.error.error_code === 'RC062') {
                this.linkIsValid = false;
                this.messageResponseError = error.error.error_messages;
            }
            
          }
        );
    }
    removeMessage() {
        this.messageResponseSuccess = null;
        this.messageResponseError = null;
    }
}
