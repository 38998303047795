import {AfterViewInit, Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {DataLogsService} from '@app/data-logs/data-logs.service';
import {chartDate} from '@app/shared/models/chartDate';
import {Subscription} from 'rxjs';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {AdminRestService} from '@app/admin/admin.service';
import {PatientsService} from '@app/patients/patients.service';
import {AlertService} from '@app/shared/alert';
import {dataSearchChart} from '@app/shared/models/dataSearchChart';
import {Select2PracticeDirective2} from '@app/shared/form/select2-practice.directive2';
import {Select2ProviderDirective} from '@app/shared/form/select2-provider.directive';
import {SystemEvent, SystemData} from '@app/shared/system';
import * as moment from 'moment';
import { Utils } from '@app/shared/utils';
import { ChartData } from '@app/shared/models/chartData';

import * as d3 from "d3-selection";
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";

declare let jQuery: any;

@Component({
  selector: 'app-appointment-by-date',
  templateUrl: './appointment-by-date.component.html',
  styleUrls: ['./appointment-by-date.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentByDateComponent implements OnInit, AfterViewInit, OnDestroy {
  currentDate: any = moment();
  minHeight: number;
  heightCanvas: number;
  widthChart: number;
  data_chart: any;
  error: chartDate = new chartDate();
  getWaittimeByProviderSubscriber: Subscription;  
  practiceId = '0';
  providerId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;

  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();

  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;
  eventListener: any;

  public providersList: any = [];
  public practicesList: any;
  public selectedPractice: any;
  public isDataAvailable = false;
  
  maxDate:any;
  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
  @ViewChild('inputDateFrom') inputPractice: ElementRef;
  @ViewChild('inputDateFrom') inputProvider: ElementRef;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;

  private chartdata:any = [];

  private chartWidth: number;
  private chartHeight: number;
  private chartMargin = { top: 20, right: 40, bottom: 20, left: 60 };
  private chartX: any;
  private chartY: any;
  private chartSvg: any;
  private chartG: any;

  public curpage : number = 1;
  public noofdata : number = 0;
  public noofpage : number = 0;
  public noofpageArr:any = [];

  constructor(
    private _alertService: AlertService,
    private _patientService: PatientsService,
    private _adminService: AdminRestService,
    private _datalogsService: DataLogsService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _systemEvent: SystemEvent,
    private _utils: Utils,
    public _systemData: SystemData,
  ) { 
    this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');

    let width = 1400;
    width = (window.innerWidth > width) ? (window.innerWidth - 600) : (window.innerWidth - 400);
    this.chartWidth = width - this.chartMargin.left - this.chartMargin.right;
    this.chartHeight= 400 - this.chartMargin.top - this.chartMargin.bottom;
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.ProviderSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
    const _self = this;
    _self._loadingIndicatorService.start();
    _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
      (success: any) => {
        _self.practicesList = success.practices;
        const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
        _self.getProviderByPractice(firstPractice);
        this._loadingIndicatorService.stop();
      });
  }
  ngOnDestroy(): void {


  }
  exportdata() {
    this.verifyDataSearch();
    const _self = this;
    const startTime = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;
    const providerID = this.dataSearchChart.provider;
    console.log(this.validate());

    if (this.validate()) {
      const token = localStorage.getItem('user_token');
      jQuery.fileDownload(this._datalogsService.scheduleExportUrl, {
        httpMethod: 'POST',
        data: {
          'token': token,
          'practice': practiceID,
          'providerId': providerID,
          'startTime': startTime,
          'chartType': 3
        }
      }).done(function () {
        // TODO
      }).fail(function () {
          _self._alertService.onClose = () => {
            location.reload();
          };
      });
    }
  }
  resetData(){

  }
  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
        this.maxDate = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    }else{
        this.maxDate = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(_self.inputDateFrom.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
      }
    });
    jQuery(_self.inputDateFrom.nativeElement).datepicker('setDate', this.dataSearchChart.dateFrom);
    jQuery(_self.clickDateFrom.nativeElement).click(() => {
      jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
    });

    jQuery(_self.inputDateTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        _self.dataSearchChart.dateTo = jQuery(_self.inputDateTo.nativeElement).val();
      }
    });
    jQuery(_self.inputDateTo.nativeElement).datepicker('setDate', this.dataSearchChart.dateTo);
    jQuery(this.clickDateTo.nativeElement).click(() => {
      jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });

    jQuery('#showDataChart').on('click', function () {
      _self.resetData();
      _self.updateData();
    });
    /*jQuery('#exportDataChart').on('click', function () {
      _self.exportdata();
    });*/
  }

  onChangePractice() {
    this.removeMsgErr();
    const practice = this.PracticeSelector.getData();
    if (practice.length !== 0) {
      this.getProviderByPractice(practice);
      this.ProviderSelector.clearData();
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      if (this.providersList.length > 0) {
        this.ProviderSelector.updateData(this.providersList);
      }
    } else {
      this.ProviderSelector.clearData();
    }
  }

  removeMsgErr() {
    this.errorResponse.provider = null;
    this.ProviderSelector.inputError = false;
    this.ProviderSelector.changeBorderColorProvider();
  }

  getProviderByPractice(practiceId: any) {
    this._loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.providersList = [];
          success.providers.forEach((item: any) => {
            item.full_name
              = item.first_name + ((item.middle_name == null)
              ? (' ')
              : (' ' + item.middle_name + ' ')) + item.last_name;
            this.providersList.push(item);
          });

        }
        this._loadingIndicatorService.stop();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }

  validateDateFromLessDateTo(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    if (date1 > date2) {
      this.errorResponse.dateFrom = message;
      return false;
    } else {
      this.errorResponse.dateFrom = null;
      return true;
    }
  }

  validateDateMoreThan31(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 31) {
      this.errorResponse.dateTo = message;
      return false;
    } else {
      this.errorResponse.dateTo = null;
      return true;
    }
  }

  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.PracticeSelector.inputError = true;
      this.PracticeSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.PracticeSelector.inputError = false;
      this.PracticeSelector.changeBorderColorPractice();
      return true;
    }
  }

  validateProvider(message: any) {
    if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
      this.errorResponse.provider = message;
      this.ProviderSelector.inputError = true;
      this.ProviderSelector.changeBorderColorProvider();
      return false;
    } else {
      this.errorResponse.provider = null;
      this.ProviderSelector.inputError = false;
      this.ProviderSelector.changeBorderColorProvider();
      return true;
    }
  }

  validate() {
    const validatePractice = this.validatePractice('This field is required');
    const validateProvider = this.validateProvider('This field is required');
    const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
    const validateDateMoreThan31
      = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
    return validatePractice && validateProvider && validateDateFromLessDateTo && validateDateMoreThan31;

  }

  verifyDataSearch() {
    this.dataSearchChart.practice = this.PracticeSelector.getData();
    this.dataSearchChart.provider = this.ProviderSelector.getData();
    this.dataSearchChart.type = jQuery('#typeChart option:selected').val();
  }

  updateData() {
    this.verifyDataSearch();
    const dateFrom = this.dataSearchChart.dateFrom;
    const dateEnd = this.dataSearchChart.dateTo;
    const providerId = this.dataSearchChart.provider;
    const practiceId = this.dataSearchChart.practice;
    const type = this.dataSearchChart.type;
    if (this.validate()) {
      this.getAppoinmentByDay(dateFrom, dateEnd, practiceId, providerId, type);
    }
  }

  getAppoinmentByDay(dateFrom: any, dateEnd: any, practiceId: any, providerId: any, type: any) {
    this._loadingIndicatorService.start();
    this.chartdata = [];
    this._datalogsService.getappoinmentByDay(dateFrom, dateEnd, providerId, type, practiceId).subscribe(
      (success: any) => {
        
        this._loadingIndicatorService.stop();
        this.data_chart = success.data_chart;
        if (this.data_chart.data !== undefined && this.data_chart.data.length > 0) {
          for (let index = 0; index < this.data_chart.data.length; index++) {
            
            let datainfo:any = {};
            datainfo.State = index +'#' + this.data_chart.data[index].date;
            
            let tempvalues:any = [];

            tempvalues.push({
              "type" : "Avg. Wait Time", 
              "value" : this.data_chart.data[index].aptInfo.avgWt, 
              "significant" : false,
              "avgWt" : this.data_chart.data[index].aptInfo.avgWt,
              "avgPdu" : this.data_chart.data[index].aptInfo.avgPdu,
              "avgAdu" : this.data_chart.data[index].aptInfo.avgAdu,
              "sumAdu" : this.data_chart.data[index].aptInfo.sumAdu,
              "sumPdu" : this.data_chart.data[index].aptInfo.sumPdu,
              "noOfApt" : this.data_chart.data[index].aptInfo.noOfApt,
              "State" : this.data_chart.data[index].date,
              "sumPduSkipped" : this.data_chart.data[index].aptInfo.sumPduSkipped,
              "noOfAptSkipped" : this.data_chart.data[index].aptInfo.noOfAptSkipped
            });

            tempvalues.push({
              "type" : "Avg. Plan Duration", 
              "value" : this.data_chart.data[index].aptInfo.avgPdu, 
              "significant" : false,
              "avgWt" : this.data_chart.data[index].aptInfo.avgWt,
              "avgPdu" : this.data_chart.data[index].aptInfo.avgPdu,
              "avgAdu" : this.data_chart.data[index].aptInfo.avgAdu,
              "sumAdu" : this.data_chart.data[index].aptInfo.sumAdu,
              "sumPdu" : this.data_chart.data[index].aptInfo.sumPdu,
              "noOfApt" : this.data_chart.data[index].aptInfo.noOfApt,
              "State" : this.data_chart.data[index].date,
              "sumPduSkipped" : this.data_chart.data[index].aptInfo.sumPduSkipped,
              "noOfAptSkipped" : this.data_chart.data[index].aptInfo.noOfAptSkipped
            });

            tempvalues.push({
              "type"  : "Avg. Actual Duration", 
              "value"   : this.data_chart.data[index].aptInfo.avgAdu, 
              "significant" : false,
              "avgWt"   : this.data_chart.data[index].aptInfo.avgWt,
              "avgPdu"  : this.data_chart.data[index].aptInfo.avgPdu,
              "avgAdu"  : this.data_chart.data[index].aptInfo.avgAdu,
              "sumAdu"  : this.data_chart.data[index].aptInfo.sumAdu,
              "sumPdu"  : this.data_chart.data[index].aptInfo.sumPdu,
              "noOfApt" : this.data_chart.data[index].aptInfo.noOfApt,
              "State" : this.data_chart.data[index].date,
              "sumPduSkipped" : this.data_chart.data[index].aptInfo.sumPduSkipped,
              "noOfAptSkipped" : this.data_chart.data[index].aptInfo.noOfAptSkipped
            });

            tempvalues.push({
              "type" : "Actual Duration", 
              "value" : this.data_chart.data[index].aptInfo.sumAdu, 
              "significant" : false,
              "avgWt" : this.data_chart.data[index].aptInfo.avgWt,
              "avgPdu" : this.data_chart.data[index].aptInfo.avgPdu,
              "avgAdu" : this.data_chart.data[index].aptInfo.avgAdu,
              "sumAdu" : this.data_chart.data[index].aptInfo.sumAdu,
              "sumPdu" : this.data_chart.data[index].aptInfo.sumPdu,
              "noOfApt" : this.data_chart.data[index].aptInfo.noOfApt,
              "State" : this.data_chart.data[index].date,
              "sumPduSkipped" : this.data_chart.data[index].aptInfo.sumPduSkipped,
              "noOfAptSkipped" : this.data_chart.data[index].aptInfo.noOfAptSkipped
            });

            tempvalues.push({
              "type" : "Plan Duration", 
              "value" : this.data_chart.data[index].aptInfo.sumPdu, 
              "significant" : false,
              "avgWt" : this.data_chart.data[index].aptInfo.avgWt,
              "avgPdu" : this.data_chart.data[index].aptInfo.avgPdu,
              "avgAdu" : this.data_chart.data[index].aptInfo.avgAdu,
              "sumAdu" : this.data_chart.data[index].aptInfo.sumAdu,
              "sumPdu" : this.data_chart.data[index].aptInfo.sumPdu,
              "noOfApt" : this.data_chart.data[index].aptInfo.noOfApt,
              "State" : this.data_chart.data[index].date,
              "sumPduSkipped" : this.data_chart.data[index].aptInfo.sumPduSkipped,
              "noOfAptSkipped" : this.data_chart.data[index].aptInfo.noOfAptSkipped
            });
            datainfo.values = tempvalues;

            datainfo.avgWt = this.data_chart.data[index].aptInfo.avgWt;
            datainfo.sumAdu = this.data_chart.data[index].aptInfo.sumAdu;
            datainfo.sumPdu = this.data_chart.data[index].aptInfo.sumPdu;

            this.chartdata.push(datainfo);
          }
        }
        this.noofdata = this.chartdata.length; 
        this.curpage = 1; 
        //this.drawChart(); 
        this.drawMultiBarChart();      
        
      },
      (error: any) => {
        this._loadingIndicatorService.stop();        
    });
  }
  printDataChart(){
    window.print();
  }
  drawMultiBarChart(){
    
    //let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.State.length}));

    const elements = document.getElementsByClassName("myTooltip");
    while (elements.length > 0) elements[0].remove();

    let barWidth:any = 50 * 10;

    let numBars:any = Math.round(this.chartWidth / barWidth);

    this.noofpage = Math.ceil(this.noofdata / numBars);
    this.noofpageArr = Array(this.noofpage).fill(0).map((x,i)=>i);
    
    let that = this;
    setTimeout(function(){
      for (var i=0; i < that.noofpage; i++) {
        var curpos = i + 1;
        that.printBarChart('groupchartbody_' + i, curpos, numBars);
      }
    }, 500);

  }
  printBarChart(eleId:any, curpage:number, numBars:number){   

    if (document.getElementById(eleId) != undefined && document.getElementById(eleId) != null) {
      document.getElementById(eleId).innerHTML = "";
    }

    if (this.noofdata == 0) {
      return false;
    }

    
    let chartSvgp = d3.select("#" + eleId).append('svg');

		chartSvgp.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
		chartSvgp.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
		
		let chartGp = chartSvgp.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    let startpos  = (curpage - 1) *  numBars;    
    let endpos    = (curpage * numBars);
    endpos        = (endpos > this.noofdata) ? this.noofdata : endpos;

    let tempdata:any = this.chartdata.slice(startpos, endpos);

    var x0 = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).paddingInner(0.2);
    var x1 = d3Scale.scaleBand().padding(0.1);

    var y = d3Scale.scaleLinear().rangeRound([(this.chartHeight / 1.75), 0]);

    var z = d3Scale.scaleOrdinal<any>().range(["#ff0000", "#37A3D6", "#1ca268", "#ffce1a", "#e26b01"]);

    var categoriesNames = tempdata.map(function(d:any) { return d.State; });
    var rateNames = tempdata[0].values.map(function(d:any) { return d.type; }); 

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);

    let sumAdu  = d3Array.max(tempdata, (d:any) => d.sumAdu);
    let sumPdu  = d3Array.max(tempdata, (d:any) => d.sumPdu);
    let avgWt   = d3Array.max(tempdata, (d:any) => d.avgWt);

    let maxlimit:number = Math.max(parseInt(sumAdu), parseInt(sumPdu), parseInt(avgWt));

    y.domain([0, maxlimit]);

    var tooltip = d3.select("body")
      .append("div")
      .attr('class', 'myTooltip')
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("");

      var legend = chartGp.append("g").attr("class", "legend");
      var cWidth = this.chartWidth;
      var cHeight = this.chartHeight;
  
      var legenG = legend.selectAll("g")
        .data(tempdata[0].values.map(function(d:any) { return d.type; }))
        .enter()
        .append("g")
        .attr("transform", function(d:any, i:any) { 
          return "translate(" + i * (cWidth / 5) + ", 0)"; //(rateNames.length)
      });
  
      legenG.append("rect")
        .attr("x", 0)
        .attr("y", cHeight - 40)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", z);
  
      legenG.append("text")
        .attr("x", 25)        
        .attr("y", cHeight - 30)
        .attr("dy", "0.32em")
        .text(function(d:any) { return d; });
  
      chartGp.append("g")			
        .attr("class", "grid")        
        .attr("transform", "translate(0," + (cHeight / 7) + ")")
        .call(d3Axis.axisLeft(y)
        .tickSize(-cWidth)        
        .tickFormat(null));  

      var barG = chartGp.append("g")
        .selectAll("g")
        .data(tempdata)
        .enter()
        .append("g")
        .attr("transform", function(d:any) { return "translate(" + x0(d.State) + ",0)"; });
    
      barG.selectAll(".bars-container-middle")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", 'bars-container-middle')	
        .attr("transform", "translate(0," + (cHeight / 7) + ")")      
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("y", function(d:any) { return 0; })
        .attr("width", x1.bandwidth())        
        .attr('height', function(d:any) { 
          return (d.value > 0) ? (cHeight / 1.75) : 0;        
      })
    
      barG.selectAll(".bars")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")        
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("width", x1.bandwidth())
        .attr("fill", function(d:any) { return z(d.type); })
        .attr("y", (cHeight / 2))
        .attr("y", function(d:any) { return y(d.value); })
        .attr('height', function(d:any) { 
          return (d.value > 0) ? ((cHeight / 1.75))  - y( d.value ) : 0;
        })
        .on("mouseover", function(d:any){
          var newHtml = '';

          newHtml += '<p><span class="lbltext">Date </span><span>: ' + d['State'] + '</span></p>';
          newHtml += '<p><span class="lbltext">No. of Appointments </span><span>: ' + d['noOfApt'] + '</span></p>';
          
          newHtml += '<p><span class="lbltext">Avg. Wait time </span><span>: ' + d['avgWt'] + '</span></p>';
          newHtml += '<p><span class="lbltext pdu">Avg. Plan Duration </span><span>: ' + d['avgPdu'] + '</span></p>';
          newHtml += '<p><span class="lbltext adu">Avg. Actual Duration </span><span>: ' + d['avgAdu'] + '</span></p>';

          newHtml += '<p><span class="lbltext">Sum of Plan Duration </span><span>: ' + d['sumPdu'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Sum of Actual Duration </span><span>: ' + d['sumAdu'] + '</span></p>';

          newHtml += '<p><span class="lbltext">No of Skipped</span><span>: ' + d['noOfAptSkipped'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Skipped Duration</span><span>: ' + d['sumPduSkipped'] + '</span></p>';
        
          tooltip.html(newHtml);
          return tooltip.style("visibility", "visible");
         })
         .on("mousemove", function(){
            var pageX = d3['event']['pageX'];
            var pageY = d3['event']['pageY'];

            if (cWidth - (pageX + 200) < 20){ 
              pageX = pageX - 200 - 20;
            }
            
            if (cHeight - (pageY + 200) < 20){ 
              pageY = pageY - 200 - 20;
            }

            return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
        })
        .on("mouseout", function(){           
          return tooltip.style("visibility", "hidden");
        });
    
      chartGp.append("g")
          .attr("class", "x-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 1.4) + ")")
          .call(d3Axis.axisBottom(x0))
          .selectAll("text")	
          .style("text-anchor", "middle")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr('y', '15')
          .text(function (d:any) {
            let xtext = d.split('#');
            return xtext[1];
          });
    
      chartGp.append("g")
          .attr("class", "y-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 7) + ")")
          .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return d + " min"; }));  

  } 
  drawChart(){

    d3.selectAll("svg > *").remove();
    d3.selectAll('#myTooltip').remove();

    if (document.getElementById("groupchartbody") != undefined && document.getElementById("groupchartbody")!= null) {
      document.getElementById("groupchartbody").innerHTML = "";
    }

    if (this.noofdata == 0) {
      return false;
    }
    
    let maxLength:any = d3Array.max(this.chartdata.map(function(d:any){ return d.State.length}));

    //console.log(maxLength);

    maxLength = 25;

    let barWidth:any = maxLength * 10;
    
    //console.log(barWidth);
    

    let numBars:any = Math.round(this.chartWidth / barWidth);

    this.chartSvg = d3.select("#groupchartbody").append('svg');
		this.chartSvg.attr("width", this.chartWidth + this.chartMargin.left + this.chartMargin.right);
		this.chartSvg.attr("height", this.chartHeight + this.chartMargin.top + this.chartMargin.bottom);
		
		this.chartG = this.chartSvg.append("g").attr("transform", "translate(" + this.chartMargin.left + "," + this.chartMargin.top + ")");
  
    let startpos = (this.curpage - 1) *  numBars;
    this.noofpage = Math.ceil(this.noofdata / numBars);
    let endpos = (this.curpage * numBars);
    endpos = (endpos > this.noofdata) ? this.noofdata : endpos;

    let tempdata:any = this.chartdata.slice(startpos, endpos);

    var x0 = d3Scale.scaleBand().rangeRound([0, this.chartWidth]).paddingInner(0.2);
    var x1 = d3Scale.scaleBand().padding(0.1);

    var y = d3Scale.scaleLinear().rangeRound([(this.chartHeight / 1.75), 0]);

    var z = d3Scale.scaleOrdinal<any>()
      .range(["#ff0000", "#37A3D6", "#1ca268", "#ffce1a", "#e26b01"]);

    var categoriesNames = tempdata.map(function(d:any) { return d.State; });
    var rateNames = tempdata[0].values.map(function(d:any) { return d.type; }); 

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);

    let sumAdu  = d3Array.max(tempdata, (d:any) => d.sumAdu);
    let sumPdu  = d3Array.max(tempdata, (d:any) => d.sumPdu);
    
    let maxlimit:number = Math.max(parseInt(sumAdu), parseInt(sumPdu));

    y.domain([0, maxlimit]);

    var tooltip = d3.select("body")
      .append("div")
      .attr('id', 'myTooltip')
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .text("");

      var legend = this.chartG.append("g").attr("class", "legend");
      var cWidth = this.chartWidth;
      var cHeight = this.chartHeight;
  
      var legenG = legend.selectAll("g")
        .data(tempdata[0].values.map(function(d:any) { return d.type; }))
        .enter()
        .append("g")
        .attr("transform", function(d:any, i:any) { 
          return "translate(" + i * (cWidth / (rateNames.length + 1)) + ", 0)"; 
      });
  
      legenG.append("rect")
        .attr("x", 0)
        .attr("y", cHeight - 40)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", z);
  
      legenG.append("text")
        .attr("x", 25)        
        .attr("y", cHeight - 30)
        .attr("dy", "0.32em")
        .text(function(d:any) { return d; });
  
      this.chartG.append("g")			
        .attr("class", "grid")
        //.attr("transform", "translate(0," + (cHeight) + ")")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")
        .call(d3Axis.axisLeft(y)
        .tickSize(-cWidth)          
        //.ticks(6)
        .tickFormat(null));  

        var barG = this.chartG.append("g")
        .selectAll("g")
        .data(tempdata)
        .enter()
        .append("g")
        .attr("transform", function(d:any) { return "translate(" + x0(d.State) + ",0)"; });
    
      barG.selectAll(".bars-container-middle")
        .data(function(d:any) { return d.values; })
        .enter()
      .append("rect")
      .attr("class", 'bars-container-middle')	
      .attr("transform", "translate(0," + (cHeight / 7) + ")")
      //.attr("transform", "translate(0," + (cHeight) + ")")
      .attr("x", function(d:any) { return x1(d.type); })
      .attr("y", function(d:any) { return 0; })
      .attr("width", x1.bandwidth())        
      .attr('height', function(d:any) { 
        return (d.value > 0) ? (cHeight / 1.75) : 0;        
      })
    
      barG.selectAll(".bars")
        .data(function(d:any) { return d.values; })
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("transform", "translate(0," + (cHeight / 7) + ")")        
        .attr("x", function(d:any) { return x1(d.type); })
        .attr("width", x1.bandwidth())
        .attr("fill", function(d:any) { return z(d.type); })
        .attr("y", (cHeight / 2))
        .attr("y", function(d:any) { return y(d.value); })
        .attr('height', function(d:any) { 
          return (d.value > 0) ? ((cHeight / 1.75))  - y( d.value ) : 0;
        })
        .on("mouseover", function(d:any){
          var newHtml = '';

          newHtml += '<p><span class="lbltext">Date </span><span>: ' + d['State'] + '</span></p>';
          newHtml += '<p><span class="lbltext">No. of Appointments </span><span>: ' + d['noOfApt'] + '</span></p>';

          newHtml += '<p><span class="lbltext pdu">Average Plan Duration </span><span>: ' + d['avgPdu'] + '</span></p>';
          newHtml += '<p><span class="lbltext adu">Average Actual Duration </span><span>: ' + d['avgAdu'] + '</span></p>';

          newHtml += '<p><span class="lbltext">Average Wait time </span><span>: ' + d['avgWt'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Sum of Plan Duration </span><span>: ' + d['sumPdu'] + '</span></p>';
          newHtml += '<p><span class="lbltext">Sum of Actual Duration </span><span>: ' + d['sumAdu'] + '</span></p>';
        
          tooltip.html(newHtml);
          return tooltip.style("visibility", "visible");
         })
         .on("mousemove", function(){
            var pageX = d3['event']['pageX'];
            var pageY = d3['event']['pageY'];
            //return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
            
            if (cWidth - (pageX + 200) < 20){ 
              pageX = pageX - 200 - 20;
            }
            
            if (cHeight - (pageY + 200) < 20){ 
              pageY = pageY - 200 - 20;
            }

            return tooltip.style("top", (pageY-10)+"px").style("left",(pageX+10)+"px");
        })
        .on("mouseout", function(){           
          return tooltip.style("visibility", "hidden");
        });
    
      this.chartG.append("g")
          .attr("class", "x-axis axis")          
          .attr("transform", "translate(0," + (cHeight / 1.4) + ")")
          .call(d3Axis.axisBottom(x0))
          .selectAll("text")	
          .style("text-anchor", "middle")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr('y', '15')
          .text(function (d:any) {
            let xtext = d.split('#');
            return xtext[1];
          });
    
      this.chartG.append("g")
          .attr("class", "y-axis axis")          
         .attr("transform", "translate(0," + (cHeight / 7) + ")")
          .call(d3Axis.axisLeft(y).tickFormat(function(d:any) { return d + " min"; }));    


  }
  preslide(){    
    if (this.curpage > 1){
      this.curpage--;
      this.drawChart();
    }    
  }
  nextslide(){
    if (this.noofpage > this.curpage) {
      this.curpage++;
      this.drawChart();
    }
  }
}
