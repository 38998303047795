import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class QuickDownloadService {

  getDocumentURL = environment.serverUrl + '/documents/downloadfromlink';
  
  constructor(private _http: HttpClient) { }  
}