import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class WaitTimeService {
  fetchWaitTimeByAppointmentUrl = environment.serverUrl + '/appointment/wait-time';

  constructor(private _http: HttpClient) {
  }

  debugMode() {
    return !environment.production;
  }

  fetchWaitTimeByAppointment() {
    return this._http.get(this.fetchWaitTimeByAppointmentUrl);
  }
}
