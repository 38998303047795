import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AlertService } from '@app/shared/alert';
import { SimpleModalsService } from '@app/shared/modals/simple-modals/simple-modals.service';
import { SimpleModalsComponent } from '@app/shared/modals/simple-modals/simple-modals.component';
// import {Event} from '@app/appointments/timeline/timeline.component';
import { Event } from '@app/master-schedules/master-schedules.component';
import { Subscription } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { MasterSchedulesService } from '@app/master-schedules/master-schedules.service';

// import {AppointmentsService} from '@app/appointments/appointments.service';

@Component({
    moduleId: module.id,
    template: `
        <rtn-simple-modals></rtn-simple-modals>`,
    selector: 'app-schedule-cancel-check-in-component',
    // templateUrl: './schedule-cancel-check-in.component.html',
    // styleUrls: ['./schedule-cancel-check-in.component.scss']
    providers: [SimpleModalsService]
})
export class ScheduleCancelCheckInComponent implements OnDestroy {

    cancelCheckInSubscriber: Subscription;

    @Output()
    reload: EventEmitter<any> = new EventEmitter();

    eventEntry: Event;
    errorMessage: string;

    constructor(
        private Service: MasterSchedulesService,
        private _simpleModals: SimpleModalsService,
        private _alertService: AlertService,
        private service: RestService
    ) {
        // TODO
    }

    ngOnDestroy() {
        if (this.cancelCheckInSubscriber) {
            this.cancelCheckInSubscriber.unsubscribe();
        }
    }

    // ========== Specific Modal Method ========== //

    open() {
        this._simpleModals.onClose = () => {
            if (this.cancelCheckInSubscriber) {
                this.cancelCheckInSubscriber.unsubscribe();
            }

            this.cancelCheckInSubscriber = this.Service.cancelCheckIn(this.eventEntry.data.id, { provider_id: this.eventEntry.data.providerId }).subscribe(
                (success: any) => {
                    if (success.error_code === 'RC000') {
                        this.reload.emit(this.eventEntry);
                    } else {
                        this.errorMessage = success.error_messages;
                        this.openErrorMessage();
                    }
                },
                (error: any) => {
                    // this.errorMessage = error.error.error_messages;
                    // this.openErrorMessage();
                });

        };
        this._simpleModals.showCloseButton = true;
        this._simpleModals.showDismissButton = true;
        this._simpleModals.closeButtonLabel = 'Yes';
        this._simpleModals.dismissButtonLabel = 'No';
        this._simpleModals.title = 'Are you sure you want to cancel check-in?';
        this._alertService.message = '';
        this._simpleModals.backdrop = 'false';
        this._simpleModals.emitter('open');
    }

    openErrorMessage() {
        this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = this.errorMessage;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }
}
