<!--<rtn-provider-header></rtn-provider-header>-->
<div class="provider-edit px-3">
  <h3 class="text-header-form-edit">
    <a classk="no-focus" [routerLink]="['/provider', 'practice']">
      <span class="fa fa-angle-left style-top-arrow"></span>
    </a>
    Edit Practice
  </h3>
  <form method="post" #form="ngForm" (ngSubmit)="updatePracticeForm(form.value)">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Practice Info</h3>
          </div>
          <div class="panel-body">
            <div class="col-md-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Name</span>
              <input class="form-control textbox-edit-form" type="text" ngControl="name" name="name"
                     [(ngModel)]="practiceDetail.name" maxlength="200" [class.input-error]="responseError['name']"
                     (keyup)="onKeyUp($event, 'name')">
              <span class="message-error" *ngIf="responseError['name']">{{responseError['name']}}</span>
            </div>
            <div class="col-md-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address']"
                     name="address" [(ngModel)]="practiceDetail.address" ngControl="address" maxlength="50"
                     (keyup)="onKeyUp($event, 'address')">
              <span class="message-error" *ngIf="responseError['address']">{{responseError['address']}}</span>
            </div>
            <div class="col-md-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address2']"
                     name="address2" [(ngModel)]="practiceDetail.address2" ngControl="address2" maxlength="50"
                     (keyup)="onKeyUp($event, 'address2')">
              <span class="message-error" *ngIf="responseError['address2']">{{responseError['address2']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-5">
                  <span class="text-label-style input-label-required">City</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['city']"
                         name="city" [(ngModel)]="practiceDetail.city" ngControl="city" maxlength="50"
                         (keyup)="onKeyUp($event, 'city')">
                  <span class="message-error" *ngIf="responseError['city']">{{responseError['city']}}</span>
                </div>
                <div class="col-4">
                  <div class="input-group select width-full">
                    <span class="text-label-style input-label-required">State</span>
                    <select class="col-12 form-control-sm textbox-edit-form" name="state_code"
                            [(ngModel)]="practiceDetail.state_code" ngControl="state_code"
                            [class.input-error]="responseError['state_code']" (change)="onKeyUp($event, 'state_code')">
                      <option [value]="''" selected>Select State</option>
                      <option *ngFor="let stateItem of _systemData.states"
                              [value]="stateItem.code">{{stateItem.name}}</option>
                    </select>
                    <!--                  <div class="input-group-select-icon cursorPointer select-state">-->
                    <!--                    <i class="fa fa-caret-down"></i>-->
                    <!--                  </div>-->
                  </div>
                  <span class="message-error" *ngIf="responseError['state_code']">{{responseError['state_code']}}</span>
                </div>
                <div class="px-0 col-3">
                  <span class="text-label-style input-label-required">ZIP Code</span>
                  <input class="form-control textbox-edit-form" type="text" ngControl="zip"
                         [class.input-error]="responseError['zip']" name="zip" [(ngModel)]="practiceDetail.zip"
                         (keyup)="onKeyUp($event, 'zip')" simplemask [format]="'#####-####'" [target]="practiceDetail"
                         [field]="'zip'" maxlength="10">
                  <span class="message-error" *ngIf="responseError['zip']">{{responseError['zip']}}</span>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Accepted Insurance</h3>
          </div>
          <div class="panel-body select-insurance">
            <div class="col-md-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Plan</span>
              <select select2-insurance class="form-control multichoice-signup-form" ngControl="insurances"
                      name="insurances" [(ngModel)]="practiceDetail.insurances"
                      (OnKeyUp)="onKeyUp($event, 'insurances')"
                      (change)="onKeyUp($event, 'insurances')" [inputError]="responseError['insurances']">
                <option *ngFor="let item of _systemData.insurances" [value]="item.id">{{item.name}}</option>
              </select>
              <span class="message-error" *ngIf="responseError['insurances']">{{responseError['insurances']}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Billing Info</h3>
          </div>
          <div class="panel-body">
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-6">
                  <span class="text-label-style input-label-required">First Name</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.first_name']" name="billing_first_name"
                         [(ngModel)]="billing.first_name" ngControl="billing_first_name" maxlength="50"
                         (keyup)="onKeyUp($event, 'billing_first_name')">
                  <span class="message-error"
                        *ngIf="responseError['billing.first_name']">{{responseError['billing.first_name']}}</span>
                </div>
                <div class="pr-0 col-6">
                  <span class="text-label-style input-label-required">Last Name</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.last_name']" name="billing_last_name"
                         [(ngModel)]="billing.last_name" ngControl="billing_last_name" maxlength="50"
                         (keyup)="onKeyUp($event, 'billing_last_name')">
                  <span class="message-error"
                        *ngIf="responseError['billing.last_name']">{{responseError['billing.last_name']}}</span>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-6">
                  <span class="text-label-style input-label-required">Email Address</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.email']" name="billing_email"
                         [(ngModel)]="billing.email" ngControl="billing_email" maxlength="254"
                         (keyup)="onKeyUp($event, 'billing_email')">
                  <span class="message-error"
                        *ngIf="responseError['billing.email']">{{responseError['billing.email']}}</span>
                </div>
                <div class="col-6 pr-0">
                  <div class="text-label-style input-label-required">Phone Number</div>
                  <div>
                    <div class="col-sm-12 col-md-12 px-0">
                      <!--                          <input class="form-control textbox-edit-form" ngControl="billing_phone_mobile" type="text"-->
                      <!--                                 [class.input-error]="responseError['billing.phone_mobile']" name="billing_phone_mobile"-->
                      <!--                                 [(ngModel)]="billing.phone_mobile" (keyup)="onKeyUp($event, 'billing_phone_mobile')"-->
                      <!--                                 inputmask [format]="'(###) ###-####'" [validator]="'[0-9]'" [target]="billing"-->
                      <!--                                 [field]="'phone_mobile'">-->
                      <input class="form-control textbox-edit-form" ngControl="billing_phone_mobile" type="text"
                             [class.input-error]="responseError['billing.phone_mobile']" name="billing_phone_mobile"
                             [(ngModel)]="billing.phone_mobile" (keyup)="onKeyUp($event, 'billing_phone_mobile')"
                             mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                      <span class="message-error"
                            *ngIf="responseError['billing.phone_mobile']">{{responseError['billing.phone_mobile']}}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text"
                     [class.input-error]="responseError['billing.address']" name="billing_address"
                     [(ngModel)]="billing.address" ngControl="billing_address" maxlength="50"
                     (keyup)="onKeyUp($event, 'billing.address')">
              <span class="message-error"
                    *ngIf="responseError['billing.address']">{{responseError['billing.address']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text"
                     [class.input-error]="responseError['billing.address2']" name="billing_address2"
                     [(ngModel)]="billing.address2" ngControl="billing_address2" maxlength="50"
                     (keyup)="onKeyUp($event, 'billing.address2')">
              <span class="message-error"
                    *ngIf="responseError['billing.address2']">{{responseError['billing.address2']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="px-0 col-5">
                  <span class="text-label-style">City</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.city']" name="billing_city"
                         [(ngModel)]="billing.city" ngControl="billing_city" maxlength="50"
                         (keyup)="onKeyUp($event, 'billing.city')">
                  <span class="message-error"
                        *ngIf="responseError['billing.city']">{{responseError['billing.city']}}</span>
                </div>
                <div class="col-4">
                  <div class="input-group select width-full">
                    <span class="text-label-style">State</span>
                    <select class="col-12 form-control-sm textbox-edit-form" name="billing_state_code"
                            [(ngModel)]="billing.state_code" ngControl="billing_state_code"
                            [class.input-error]="responseError['billing.state_code']"
                            (change)="onKeyUp($event, 'state_code')">
                      <option selected value=''>Select State</option>
                      <option *ngFor="let stateItem of _systemData.states"
                              [value]="stateItem.code">{{stateItem.name}}</option>
                    </select>
                    <!--                  <div class="input-group-select-icon cursorPointer select-state">-->
                    <!--                    <i class="fa fa-caret-down"></i>-->
                    <!--                  </div>-->
                  </div>
                  <span class="message-error"
                        *ngIf="responseError['billing.state_code']">{{responseError['billing.state_code']}}</span>
                </div>
                <div class="px-0 col-3">
                  <span class="text-label-style">ZIP Code</span>
                  <input class="form-control textbox-edit-form" type="text" ngControl="billing_zip"
                         [class.input-error]="responseError['billing.zip']" name="billing_zip" [(ngModel)]="billing.zip"
                         simplemask [format]="'#####-####'" [target]="billing" [field]="'zip'"
                         (keyup)="onKeyUp($event, 'billing.zip')" maxlength="10">
                  <span class="message-error"
                        *ngIf="responseError['billing.zip']">{{responseError['billing.zip']}}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Note</h3>
          </div>
          <div class="panel-body">
            <div class="col-12 inline-edit-provider">
              <div class="col-12 px-0">
                <span class="text-label-style">Message</span>
                <textarea class="form-control" [class.input-error]="responseError['note']" name="note"
                          [(ngModel)]="practiceDetail.note" ngControl="note" maxlength="500" rows="5"
                          (keyup)="onKeyUp($event, 'note')"></textarea>
                <span class="message-error" *ngIf="responseError['note']">{{responseError['note']}}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pl-0 padding-bottom-20">
      <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Save</button>
      <span class="padding-left-15 message-success">{{ successMessage }}</span>
    </div>

  </form>
</div>
