'use strict';

let object = window; // #8: web workers
let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

// encoder
// [https://gist.github.com/999166] by [https://github.com/nignag]
object.btoa || (
  object.btoa = function (input: any) {
    var str = String(input);
    for (
      // initialize result and counter
      var block: any, charCode: any, idx = 0, map = chars, output = '';
      // if the next str index does not exist:
      //   change the mapping table to '='
      //   check if d has no fractional digits
      str.charAt(idx | 0) || (map = '=', idx % 1);
      // '8 - idx % 1 * 8' generates the sequence 2, 4, 6, 8
      output += map.charAt(63 & block >> 8 - idx % 1 * 8)
    ) {
      charCode = str.charCodeAt(idx += 3/4);
      if (charCode > 0xFF) {
        throw new Error('{btoa} failed: The string to be encoded contains characters outside of the Latin1 range.');
      }
      block = block << 8 | charCode;
    }
    return output;
  });

// decoder
// [https://gist.github.com/1020396] by [https://github.com/atk]
object.atob || (
  object.atob = function (input: any) {
    var str = String(input).replace(/=+$/, '');
    if (str.length % 4 === 1) {
      throw new Error('{atob} failed: The string to be decoded is not correctly encoded.');
    }
    for (
      // initialize result and counters
      var bc = 0, bs: any, buffer: any, idx = 0, output = '';
      // get next character
      buffer = str.charAt(idx++);
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
      bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  });
