import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
// import {AppointmentBookService} from '@app/appointments/modals/appointment-book/appointment-book.service';
import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
import { Event, TimelineComponent } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { from, Subscription, Subscriber } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { ProvidersService } from '@app/providers/providers.service';

//import { PatientFutureAppointmentComponent } from '@app/shared/patient-future-appointment/patient-future-appointment.component';

declare let jQuery: any;
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'rtn-appointment-book-waitlist',
  templateUrl: './appointment-book-waitlist.component.html',
  styleUrls: ['./appointment-book-waitlist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentBookWaitlistComponent implements AfterViewInit, OnDestroy {
  fetchAvailableSlotSubscriber: Subscription;
  fetchAvailableSlotByTimeSubscriber: Subscription;
  bookAppointmentSubscriber: Subscription;
  searchPatientSubscriber: Subscription;
  deleteSlotSubscriber: Subscription;
  getIsLockSubscriber: Subscription;
  getIsDeleteSaveSearchSubscriber: Subscription;
  isLock: any;
  currentDate: any = moment();
  msg:string = 'Your account has been locked.';
  // get current date by default
  @ViewChild(TimelineComponent) timelineDirective: TimelineComponent;
  @ViewChild('AppointmentBook_Modals') modal: ModalComponent;
  @ViewChild('inputDateNewAppointment') inputDateNewAppointment: ElementRef;
  @ViewChild('clickDateNewAppointment') clickDateNewAppointment: ElementRef;
  //@ViewChild(PatientFutureAppointmentComponent) patientFutureApptDirective: PatientFutureAppointmentComponent;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  eventEntry: Event;
  dataDateBook: DataDateBook = new DataDateBook;
  errorMessage: string;
  errorMessageBook: string;
  searchDisabled = true;
  patients: any = [];
  patient_id: number;
  patient_name: any;
  isFamily = false;
  availableSlots: any = [];
  selectedSlot = 0;
  public savedId: number;
  time: any;
  input_group: any = '.input-group';
  search_field: any = '#search-field-new-appointment';

  // ========== Specific Modal Method ========== //
  newAvailableSlotModel: NewAvailableSlotClass = new NewAvailableSlotClass();
  requireMessage: RequireMessage = new RequireMessage();

  fetchAppointmentTypeSubscriber: Subscription;
  appointmentTypeList: any = [];

  // show future apt
   futureaptlists: any = [];
   selectedupcommingId: number = 0;
   fetchPatientUpcommingAptSubscriber: Subscription;
   loadedpage : string = 'provider_book_wl';
   scheduleId: number = 0;

  constructor(
    private Service: AppointmentsService,
    private _alertService: AlertService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
    private providersService: ProvidersService,
  ) {
    // TODO
    this.newAvailableSlotModel.appointment_reason = '';
    this.newAvailableSlotModel.event_reason_id = '';
    //this.newAvailableSlotModel.description_1  = '';
    //this.newAvailableSlotModel.description_2  = '';
  }

  selectSlot(slotId: any) {
    this.selectedSlot = slotId;
    for (let i = 0; i < this.availableSlots.length; i++) {
      const s = this.availableSlots[i];
      s.isSelected = s.id === slotId ? true : false;
    }
    // Fecth appointment types by provider pratice
    this.fetchAppointmentType(slotId);
  }
  fetchAvailableSlotByTime(date: any) {
    let dateRequest = null;
    if (date != null && date != '') {
      dateRequest = this._utils.formatTimeForWebServiceCall(date.clone());
     //var tdate = date.clone();
     //console.log(tdate);
     //dateRequest = moment(date).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
     //console.log(dateRequest);
    }
    this._loadingIndicatorService.start();
    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    this.fetchAvailableSlotSubscriber = this.Service.fetchAvailableSlotByTime(this.savedId, dateRequest)
      .subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          if (success.available_slots !== null) {
            this.availableSlots = success.available_slots;
            this.selectedSlot = 0;
          }


        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        }
      );
  }
  fomartTimeShowInList(time: string) {
    let value = '';
    if (time) {
      if (time === '00:00:00') {
        value = '00:00 AM';
      } else if (time === '00:30:00') {
        value = '00:30 AM';
      } else {
        value = this._utils.formatTimeString(time, 'LT');
      }
    }
    if (value.substr(1, 1) === ':') {
      value = '0' + value;
    }
    return value;
  }
  getDataAvailableSlot(savedId: any, patient_id: any, patient_name: any, dateFrom: any, dateTo: any, startTime: any, endTime: any) {
    this.savedId = savedId;
    this.appointmentTypeList = [];
    
    //this.dataDateBook.dateFrom = moment(moment(dateFrom).add(1, 'days').format('LL'));
    //this.dataDateBook.dateTo = moment(moment(dateTo).add(1, 'days').format('LL'));

    this.dataDateBook.dateFrom = moment(dateFrom).format('LL');
    this.dataDateBook.dateTo = moment(dateTo).format('LL');

    const today = moment(this.currentDate.format('LL'));

    //console.log("dataDateBook : " + this.dataDateBook);

    if (startTime === '00:00:00' && endTime === '23:59:59') {
      this.time = '';
    } else {
      //this.time = this.fomartTimeShowInList(startTime) + ' - ' + this.fomartTimeShowInList(endTime);
      this.time =  moment(dateFrom).format('hh:mm A') + ' - '
      + moment(dateTo).format('hh:mm A')
    }

    if (!this.isLock && !this.modal.visible) {
      this.patient_id = patient_id;
      this.patient_name = patient_name;
      if (this.dataDateBook.dateFrom !== '') {
        if (this.dataDateBook.dateFrom < today && this.dataDateBook.dateTo >= today) {
          this.dataDateBook.dateFrom = this.currentDate;
        }
        this.dataDateBook.selectedDate = this.dataDateBook.dateFrom;
        this.newAvailableSlotModel.date = this.dataDateBook.selectedDate.format('MMMM DD, YYYY');
      }

      //console.log(this.newAvailableSlotModel);

     // var sdate = moment(dateFrom);

      //console.log(sdate);

      this.setDatePicker(this.dataDateBook.dateFrom, this.dataDateBook.dateTo);
      this.errorMessageBook = null;
      this.fetchAvailableSlotByTime(moment(this.dataDateBook.selectedDate));
      this.modal.open();
    }
    //  else if (!this.isLock && this.modal.visible) {
    //   this.dataDateBook.selectedDate = this.dataDateBook.dateFrom;
    //   this.newAvailableSlotModel.date = this.dataDateBook.selectedDate.format('MMMM DD, YYYY');
    //   this.fetchAvailableSlotByTime(this.dataDateBook.selectedDate);
    // }
  }
  showMessage(msg: any) {
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  checkLockedProvider(type: any, savedId: any = null, patient_id: any = null, patient_name: any = null, dateFrom: any = null, dateTo: any = null, startTime: any = null, endTime: any = null, is_family:any=null,schedule_id=0) {
    this._loadingIndicatorService.start();
    let that = this;
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this.isLock = success.providers.is_lock;
        if (this.isLock) {
          if (type == 2) {
            this._loadingIndicatorService.stop();
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              window.location.reload();
            };
            this.showMessage(this.msg);
          } else if (type == 1) {
            this._loadingIndicatorService.stop();
            this.dismiss();
            this.close();
            this.showMessage(this.msg);
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              window.location.reload();
            };
          }
        } else {
          if (type == 1) {
            this.checkIsDeleteAndStatusSaveSearch();
            this._loadingIndicatorService.stop();
          } else if (type == 2) {
            this.savedId = savedId;
            this.patient_id = patient_id;
            this.isFamily = is_family;
            this.scheduleId = schedule_id;
            
            //console.log('scheduleId' + this.scheduleId);

            this.availableSlots = [];
            this.getDataAvailableSlot(savedId, patient_id, patient_name, dateFrom, dateTo, startTime, endTime);
            this.fetchPatientUpcommingApt(patient_id, 0);
            //that.patientFutureApptDirective.initData();
            
            //this.fetchAppointmentType();
            this._loadingIndicatorService.stop();
          }
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );
  }

  checkIsDeleteAndStatusSaveSearch(){
    this._loadingIndicatorService.start();
    this.getIsDeleteSaveSearchSubscriber = this.Service.getIsDeleteAndStatusSaveSearch(this.savedId, this.patient_id).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        if(success.isDeletedAt.deleted_at!=null){
          this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
            this.dismiss();
            this.close();
            window.location.reload();

          };
          this.showMessage('This patient is no longer in the waitlist.');
        }else if(success.status==2){
          this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
            this.dismiss();
            this.close();
            window.location.reload();

          };
          this.showMessage('This patient is inactive.');
        }else if(success.is_family==false){
          this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
            this.dismiss();
            this.close();
            window.location.reload();

          };
          this.showMessage('This patient is no longer in the waitlist.');
        }
        else{
          this.bookAppointment();
        }
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      },
      () => {
        
      }
    );
  }

  setDatePicker(startDate: any, endDate: any) {
    startDate = moment(startDate).toDate();
    endDate = moment(endDate).toDate();
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker('destroy');
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker({
      format: 'MM dd, yy',
      altFormat: 'MM dd, yy',
      numberOfMonths: 2,
      minDate: startDate,
      maxDate: endDate,
      showButtonPanel: true,
      onSelect: (selected: any) => {
        this.availableSlots = [];
        this.dataDateBook.selectedDate = moment(selected);
        this.newAvailableSlotModel.date = this.dataDateBook.selectedDate.format('MMMM DD, YYYY');
        this.fetchAvailableSlotByTime(this.dataDateBook.selectedDate);
        this.errorMessageBook = null;
      }
    });
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker('setDate', startDate);
    jQuery(this.clickDateNewAppointment.nativeElement).click(() => {
      jQuery(this.inputDateNewAppointment.nativeElement).datepicker('show');
    });
  }
 
  bookAppointment() {
    this.errorMessageBook = null;
    this._loadingIndicatorService.start();
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }

    this.bookAppointmentSubscriber = this.Service.bookAppointmentForWaitList({
      patient_id: this.patient_id,
      slot_id: this.selectedSlot,
      is_family: this.isFamily,
      save_id: this.savedId,
      appointment_reason : this.newAvailableSlotModel.appointment_reason,
      event_reason_id : this.newAvailableSlotModel.event_reason_id,
      reschedule_id : this.selectedupcommingId
    })
      .subscribe(
        (res: any) => {
          this._loadingIndicatorService.stop();
          if (res.error_code === 'RC000') {
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              window.location.reload();

            };
            this._alertService.showDismissButton = false;
            this._alertService.dismissButtonLabel = 'No';
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'OK';
            this._alertService.title = res.error_messages;
            this._alertService.message = '';
            this._alertService.emitter('open');
          }
          if (res.error_code === 'RC002' || res.error_code === 'RC061' || res.error_code === 'RC119' || res.error_code === 'RC086') {
            this.errorMessageBook = res.error_messages;
          }
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
          const e = error.error;
          if (e.error_code === 'RC002' || e.error_code === 'RC061' || e.error_code === 'RC119') {
            this.errorMessageBook = e.error_messages;
          }
        },
        () => {
          this._loadingIndicatorService.stop();
        }
      );


  }

  getInfoByNameClicked(event: any, id: number, first_name: string, last_name: string, dob: string, is_family: boolean) {
    this.patient_id = id;
    this.isFamily = is_family;
    this.newAvailableSlotModel.patient = first_name + ' ' + last_name + ' - ' + dob;
    this.requireMessage.patient_id = null;
    jQuery(event.srcElement).closest(this.input_group).removeClass('open');
  }

  getInfoByNumberClicked(event: any, id: number, phone_mobile: number, first_name: string, last_name: string) {
    this.patient_id = id;
    this.newAvailableSlotModel.patient = phone_mobile + ' ' + first_name + ' ' + last_name;
    this.requireMessage.patient_id = null;
    jQuery(event.srcElement).closest(this.input_group).removeClass('open');
  }

  getTime(time: any) {
    // return moment(time).format('hh:mm A');
    return moment(time).format('HH:mm');
  }
  processDeleteSlot(){
    this._loadingIndicatorService.start();
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.isLock = success.providers.is_lock;
        if (this.isLock) {
            this._loadingIndicatorService.stop();
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              window.location.reload();
            };
            this.showMessage(this.msg);
          } else{
            this.deleteSlotClicked();
          }
      }
    );
  }
  deleteSlotClicked() {
    this._alertService.onClose = () => {
      this._loadingIndicatorService.start();
      if (this.deleteSlotSubscriber) {
        this.deleteSlotSubscriber.unsubscribe();
      }

      this.deleteSlotSubscriber = this.Service.deleteSlot({ slot_id: this.selectedSlot })
        .subscribe(
          (success: any) => {
            this._loadingIndicatorService.stop();
            switch (success.error_code) {
              case 'RC000':
                this.showAlert(success.error_messages);
                this.fetchAvailableSlotByTime(this.dataDateBook.selectedDate);
                break;
              default:
                this.showAlert(success.error_messages);
                this.fetchAvailableSlotByTime(this.dataDateBook.selectedDate);
                break;
            }
          },
          (error: any) => {
            this._loadingIndicatorService.stop();
            const e = error.error;
          },
          () => {
            this._loadingIndicatorService.stop();
          }
        );
    };
    this._alertService.showDismissButton = true;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'Yes';
    this._alertService.title = 'Are you sure want delete this slot ?';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }



  showAlert(message: any) {
    this._alertService.onClose = () => {
      // TODO
    };
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = message;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  // ========== Class Method ========== //

  ngAfterViewInit() {
    jQuery('.search-button').on('click', function (e: any) {
      if (jQuery('.search').hasClass('open')) {
        e.stopPropagation();
      }
    });

    const self = this;
    jQuery(document).on('click', function (e: any) {
      if (jQuery(e.target).hasClass('result dropdown-menu')
        || jQuery(e.target).hasClass('error-message')) {
        // TODO: Close dropdown when click outside
      } else {
        jQuery(self.search_field).closest(self.input_group).removeClass('open');
      }
    });

  }

  ngOnDestroy() {
    this.selectedSlot = 0;
    if (this.searchPatientSubscriber) {
      this.searchPatientSubscriber.unsubscribe();
    }
    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }
    if (this.deleteSlotSubscriber) {
      this.deleteSlotSubscriber.unsubscribe();
    }
    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
  }

  // ========== General Modal Method ========== //

  dismiss() {
    this.newAvailableSlotModel.patient = this.patient_id = null;
    this.requireMessage = new RequireMessage();
    jQuery(this.search_field).parent().removeClass('open');
  }

  onKeyUp() {
    this.requireMessage.patient_id = null;
    if (jQuery(this.search_field).val() === '') {
      this.patient_id = null;
      this.searchDisabled = true;
      jQuery('.search').removeClass('open');
    } else {
      this.searchDisabled = false;
    }
  }

  open() {

  }

  close() {
    this.selectedSlot = 0;
    this.modal.close();
  }

  fetchAppointmentType(slot_Id:any) {
    
    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    // Reset appointment types
    this.appointmentTypeList = [];
    this.newAvailableSlotModel.event_reason_id='';

    this.fetchAppointmentTypeSubscriber = this.Service.getPracticeAppointmentTypeList(slot_Id, 1, 0, null).subscribe(
      (success: any) => {
          this._loadingIndicatorService.stop();
          this.appointmentTypeList = success.apttypes;
      },
      (error: any) => {
        this._loadingIndicatorService.stop();        
      });
  }
  
  /*selectAppointment(id:any){
    this.selectedupcommingId = (this.selectedupcommingId == id) ? 0 : id;
  }*/
  
  fetchPatientUpcommingApt(patient_id: any, provider_id: any) {
    
    this.futureaptlists = [];
    this.selectedupcommingId = 0;
    this._loadingIndicatorService.start();

    if (this.fetchPatientUpcommingAptSubscriber) {
      this.fetchPatientUpcommingAptSubscriber.unsubscribe();
    }
    this.fetchPatientUpcommingAptSubscriber = this.Service.fetchPatientUpcommingApt(patient_id, provider_id).subscribe((success: any) => {
      this._loadingIndicatorService.stop();
      if (success.aptlist !== undefined) {
        this.futureaptlists = success.aptlist;  
        console.log(this.futureaptlists);          
      }
    },(error: any) => {
      this._loadingIndicatorService.stop();
    });    
  }
  selecteRescheduleId(id:any){
    console.log('ID  =' +id);
    this.selectedupcommingId = (this.selectedupcommingId == id) ? 0 : id;
    console.log('selectedupcommingId = ' + this.selectedupcommingId);
  }
}

export class NewAvailableSlotClass {
  patient = '';
  date: string;
  appointment_reason: string = '';
  event_reason_id:string='';
  //description_1:string='';
  //description_2:string='';
}

export class DataDateBook {
  selectedDate: any = moment();
  dateFrom: any = '';
  dateTo: any = '';
}

export class PatientInfoClass {
  id: number;
  first_name: string;
  last_name: string;
  dob: string;
  phone_mobile: number;
  is_family: boolean;
}

export class RequireMessage {
  patient_id: string;
  date: string;
  start_time: string;
  end_time: string;
  appointment_reason: string = '';
  event_reason_id:string='';
}