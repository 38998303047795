import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '@app/shared/utils';
import { PracticeDetail } from '@app/shared/models/practiceDetail';
import { BillingInfo } from '@app/shared/models/billingInfo';
import { Subscription } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import * as moment from 'moment';
import { ProvidersService } from '@app/providers/providers.service';

declare let jQuery: any;
var _momentOptions = {
    week: {
        dow: 0, // Sunday is the first day of the week.
        doy: 6
    }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
    selector: 'app-practice',
    moduleId: module.id,
    templateUrl: './practice.component.html',
    styleUrls: ['./practice.component.scss'],
    providers: [RestService],
})
export class PracticeComponent implements OnDestroy {

    practiceDetail: any = new PracticeDetail();
    billingInfo: any = new BillingInfo();
    onGetProfileListener: Subscription;
    acceptedInsurance: any = [];
    showComma = false;
    fullAddress: string = '';
    note: string = '';

    constructor(
        private _router: Router,
        private _restService: ProvidersService,
        private _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        if (this._utils.accessPractice()) {
            this._loadingIndicatorService.start();
            this.onGetProfileListener = this._restService.getProfile().subscribe(
                (success: any) => {
                    this._loadingIndicatorService.stop();
                    this.practiceDetail = success.practice;
                    this.fullAddress = this._utils.generateFullAddress(this.practiceDetail.address, this.practiceDetail.address2, this.practiceDetail.city, this.practiceDetail.state_code, this.practiceDetail.zip);
                    //this.practiceDetail.opening_hours_display = this._utils.generateOpeningHours(this.practiceDetail.opening_hours, 3);
                    if (this.practiceDetail.billing) {
                        this.billingInfo = this.practiceDetail.billing;
                        this.billingInfo.name_display = this._utils.generateFullName(this.billingInfo.first_name, null, this.billingInfo.last_name);
                        if (this.billingInfo.phone_mobile.indexOf('+1 ') !== -1) {
                            this.billingInfo.phone_mobile = this.billingInfo.phone_mobile.substr(this.billingInfo.phone_mobile.indexOf('+1 ') + 3);
                        }
                        if (this.billingInfo.state_code !== null && this.billingInfo.state_code !== '' &&
                            this.billingInfo.city !== null && this.billingInfo.city !== '') {
                            this.showComma = true;
                        }
                    }
                    var index = this.practiceDetail.insurances_name.indexOf(null);
                    if (index !== -1) {
                        this.practiceDetail.insurances_name[index] = 'No Insurance/Self Pay';
                    }
                }, (error: any) => {
                    this._loadingIndicatorService.stop();
                }, () => {
                    this._loadingIndicatorService.stop();
                }
            );
        }

        this._restService.getNoteFromPractice().subscribe(
            (success: any) => {
                if (success.note !== null && success.note !== undefined) {
                    this.note = success.note;
                }
            }
        );
    }
    edit() {
        const _seft = this;
        this._router.navigate(['/provider/practice', 'edit']);
    }
    ngOnDestroy() {
        if (this.onGetProfileListener) {
            this.onGetProfileListener.unsubscribe();
        }
    }

}
