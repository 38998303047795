import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {dataSearchChart} from '@app/shared/models/dataSearchChart';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';

@Injectable({
    providedIn: 'root'
})
export class DataLogsService {
    // API export
    public scheduleExportUrl = environment.serverUrl + '/datalog/export';
    // API patients
    public patientsUrl = environment.serverUrl + '/admin/patients';
    // API providers
    public providersUrl = environment.serverUrl + '/admin/providers';
    // API providers
    public practicesUrl = environment.serverUrl + '/admin/practices';
    // API searchDataLog
    public searchDataLogUrl = environment.serverUrl + '/datalog/notification-log?';

    dataSearchChartVar: dataSearchChart = new dataSearchChart();
    // API get provider by practice
    private getListProviderByPracticeUrl = environment.serverUrl + '/datalog/get-list-provide-by-practice?';

    private waittimeByProvidersUrl = environment.serverUrl + '/datalog/waittime-providers?';
    private appoinmentByDayUrl = environment.serverUrl + '/datalog/apointment-log-provider?';
    private durationLogByDay = environment.serverUrl + '/datalog/duration-log?';
    private appoinmentLogByDay = environment.serverUrl + '/datalog/apointment-log-day?';
    private waittimeOffice = environment.serverUrl + '/datalog/offices-waittime?';
    //API get statistical appointment by day
    private statisticalApptUrl = environment.serverUrl + '/datalog/get-statistical-appointment?';
    
    //API get comparing appointment in a choosen day
    //private getDataComparingDayURL = environment.serverUrl + '/datalog/get-comparing-appointment?';
    private getDataComparingDayURL = environment.serverUrl + '/datalog/getScheduleCompareList?';

    //API get time from arrived to check in
    private getTimeFronArrivedToCheckInURL = environment.serverUrl + '/datalog/get-time-from-arriving-to-checkin?';
    private getScheduleActivityUrl = environment.serverUrl + '/datalog/getscheduleactivity?';

    private planActualComparingUrl = environment.serverUrl + '';
    
    private statisticalApptTypeUrl = environment.serverUrl + '/datalog/getstatisticalappointmentbytypes?';
    
    private geteventtypesUrl = environment.serverUrl + '/datalog/geteventtypes';
    private getschedulearrivaltimecompareUrl = environment.serverUrl + '/datalog/get-schedule-arrivaltimecompare?';
    private getcancelappointmentUrl = environment.serverUrl + '/datalog/getcancelappointment?';
    private getOfficeLocationUrl = environment.serverUrl + '/datalog/getofficelocations';
    private labappoinmentLogByDay = environment.serverUrl + '/datalog/getschedulewithlab?';   
    
    public exportcancelappointmentUrl = environment.serverUrl + '/datalog/exportcancelappointment';

    public patientsearchUrl = environment.serverUrl + '/admin/search-patient';

    constructor(private _http: HttpClient) {

    }

    getStatiscalAppt(startTime: any, endTime: any, practice: any, providerId:any) {
        const params = new HttpParams().set('startTime', startTime).set('endTime', endTime).set('practice', practice).set('providerId', providerId);
        return this._http.get(this.statisticalApptUrl + params);
    }
    getCompareAppt(startTime: any, practice: any, providerId:any, page:any, limit:any, status:any, event_reason_id : any, location_id : any) {
        const params = new HttpParams().set('choosenDate', startTime).set('practice', practice).set('providerId', providerId).set('page', page).set('limit', limit).set('status', status).set('event_reason_id', event_reason_id).set('location_id', location_id);
        return this._http.get(this.getDataComparingDayURL + params);
    }

    getWaittimeByProvider(startTime: any, endTime: any, practice: any, type: any) {
        const params = new HttpParams()
            .set('startTime', startTime)
            .set('endTime', endTime)
            .set('practice', practice)
            .set('type', type);
        return this._http.get(this.waittimeByProvidersUrl + params);
        // return this._http.get(this.waittimeByProvidersUrl);
    }

    getapointmentLog(date: any, providerId: any, type: any, practice: any, event_reason_id : any) {
        const params = new HttpParams()
            .set('date', date)
            .set('providerId', providerId)
            .set('type', type)
            .set('practice', practice)
            .set('event_reason_id', event_reason_id);
        return this._http.get(this.appoinmentLogByDay + params);
    }

    getappoinmentByDay(startTime: any, endTime: any, providerId: any, type: any, practice: any) {
        const params = new HttpParams()
            .set('startTime', startTime)
            .set('endTime', endTime)
            .set('providerId', providerId)
            .set('type', type)
            .set('practice', practice);
        return this._http.get(this.appoinmentByDayUrl + params);
    }

    getDurationLog(startTime: any, endTime: any, providerId: any, type: any, practice: any) {
        const params = new HttpParams()
            .set('startTime', startTime)
            .set('endTime', endTime)
            .set('providerId', providerId)
            .set('type', type)
            .set('practice', practice);
        return this._http.get(this.durationLogByDay + params);
    }

    getTimeFromArrivaltoCheckIn(startTime: any, providerId: any, practice: any) {
        const params = new HttpParams()
            .set('startTime', startTime)
            .set('providerId', providerId)
            .set('practice', practice);
            return this._http.get(this.getTimeFronArrivedToCheckInURL + params);
    }

    // get list provider with each practice
    getListProviderByPractice(practiceId: any) {
        const params = new HttpParams().set('practiceId', practiceId);
        return this._http.get(this.getListProviderByPracticeUrl + params);
    }

    // Get all Patients of the system
    getPatients() {
        return this._http.get(this.patientsUrl);
    }

    // Get all providers of the system
    getProviders() {
        return this._http.get(this.providersUrl);
    }

    // Get all practices of the system
    getPractices() {
        return this._http.get(this.practicesUrl);
    }

    // search data log
    searchDataLog(
        limit: any,
        page: any,
        practice: string,
        provider: string,
        patient: string,
        plan_check_in: string,
        plan_check_in_to: string,
        timezone: string
    ) {
        const params = new HttpParams()
            .set('limit', limit)
            .set('practice', practice)
            .set('page', page)
            .set('provider', provider)
            .set('patient', patient)
            .set('plan_check_in', plan_check_in)
            .set('plan_check_in_to', plan_check_in_to)
            .set('timezone', timezone);
        return this._http.get(this.searchDataLogUrl + params);
    }

    handleErrorInput(error: any) {

        switch (error.error_fields) {
            case 'practice':
                this.dataSearchChartVar.practice = error.error_messages;
                break;
            case 'provider':
                this.dataSearchChartVar.provider = error.error_messages;
                break;
            case 'type':
                this.dataSearchChartVar.type = error.error_messages;
                break;
            case 'dateFrom':
                this.dataSearchChartVar.dateFrom = error.error_messages;
                break;
            case 'dateTo':
                this.dataSearchChartVar.dateTo = error.error_messages;
                break;
            default:
                break;
        }
        return this.dataSearchChartVar;
    }

    comparingData(page: any, limit: any) {
        const params = new HttpParams().set('page', page).set('limit', limit);
        return this._http.get(this.planActualComparingUrl, {params});
    }
    getWaittimeOffice(date: any, providerId: any) {
        const params = new HttpParams()
            .set('date', date)
            .set('providerId', providerId);   
        return this._http.get(this.waittimeOffice + params);
    }
    getScheduleActivity(startTime: any, endTime: any, practice: any, providerId: any, locationId: any,) {
        const params = new HttpParams()
            .set('startTime', startTime)
            .set('endTime', endTime)
            .set('practice', practice)
            .set('providerId', providerId)            
            .set('locationId', locationId);            
        return this._http.get(this.getScheduleActivityUrl + params);
    }
    getStatiscalApptTypes(startTime: any, endTime: any, practice: any, providerId:any) {
        const params = new HttpParams().set('startTime', startTime).set('endTime', endTime).set('practice', practice).set('providerId', providerId);
        return this._http.get(this.statisticalApptTypeUrl + params);
    }
    getEventtypes(practice:any) {
        const params = new HttpParams().set('practice_id', practice);
        return this._http.get(this.geteventtypesUrl);
    }
    getScheduleArrivalCompare(startTime: any, practice: any, providerId:any) {
        const params = new HttpParams().set('startTime', startTime).set('practice', practice).set('providerId', providerId);
        return this._http.get(this.getschedulearrivaltimecompareUrl + params);
    }
    getcancelappointment(startTime: any, endTime: any, practice: any, providerId: any, locationId:any, apt_type:any) {
        const params = new HttpParams()
            .set('startTime', startTime)
            .set('endTime', endTime)
            .set('practice', practice)
            .set('providerId', providerId)
            .set('locationId', locationId)
            .set('apt_type',apt_type);
            
        return this._http.get(this.getcancelappointmentUrl + params);
    } 
    getOfficeLocation(provider_id:any) {
        const params = new HttpParams()
            .set('provider_id', provider_id)
        return this._http.get(this.getOfficeLocationUrl + '?' + params);
    }
    getlabappointmentLog(date: any, providerId: any, practice: any) {
        const params = new HttpParams()
            .set('date', date)
            .set('providerId', providerId)            
            .set('practice', practice)            
        return this._http.get(this.labappoinmentLogByDay + params);
    }
    getSearchPatients(searchTerm:any) {
        const params = new HttpParams().set('searchTerm', searchTerm);
        return this._http.get(this.patientsearchUrl, {params});
    }   
}
