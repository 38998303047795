import { Component, ViewChild, Input, Output, OnInit, AfterViewInit, OnDestroy, ElementRef, EventEmitter } from '@angular/core';
import { OpeningHourSlot } from '../../models/openingHourSlot';
import { Utils } from '../../utils';

@Component({
  selector: 'rtn-opening-hour-slot',
  moduleId: module.id,
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class OpeningHourSlotComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isDisabled: boolean = true;
    @Input() slot: any;
    @Input() index: number;
    @Input() resetOnDisabled: boolean = false;
    @Input() checked: string;
    @Input() checkStatus: any = Array(7).fill(-1);

    @Output() onDayStatusChange: EventEmitter<any> = new EventEmitter();
    @Output() onSlotStatusChange: EventEmitter<any> = new EventEmitter();
    @Output() clearError: EventEmitter<any> = new EventEmitter();

    listTime: any;
    listDay: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    itemLabel: 'day';
    responseError: string = '';

    constructor(
        private _utils: Utils,
        private el: ElementRef
    ) {
        // do nothing
    }

    ngOnInit() {
        this.listTime = this._utils.generateTimeArray(0, 30, 24 * 60);
    }

    ngAfterViewInit() {
        (<any>jQuery(this.el.nativeElement.children[0]).find(':checkbox[data-labelauty]')).labelauty();
    }

    ngOnDestroy() {
        // do nothing
    }

    onDayCheckboxChange(event: any, index: number) {
        this.onDayStatusChange.emit([this.index, index]);
    }

    onCheckboxChange(event: any) {
        if (event.target.checked) {
            this.onSlotStatusChange.emit([this.index, true]);
            this.isDisabled = false;
        } else {
            this.onSlotStatusChange.emit([this.index, false]);
            if (this.resetOnDisabled) {
                this.slot = new OpeningHourSlot();
            }
            this.isDisabled = true;
        }
    }

    clearErr() {
        this.clearError.emit('');
    }

}
