import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {NotificationsRoutingModule} from './notifications-routing.module';
import {NotificationListComponent} from '@app/notifications/notification-list/notification-list.component';
import { NotificationDashboardComponent } from './notification-dashboard/notification-dashboard.component';
import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { ModalsComponent as DeleteNotificationsComponent} from './modals/delete-notification/delete-notification.component';
import { NotificationMassComponent } from './notification-mass/notification-mass.component';
import { EmbedAppointmentBookComponent } from './modals/embed-appointment-book/embed-appointment-book.component';
import { EmbedBookModalComponent } from './modals/embed-book-modal/embed-book-modal.component';
import { NotificationMassPatientComponent } from './notification-mass-patient/notification-mass-patient.component';
import { LatestNotificationDashboardComponent } from './latest-notification-dashboard/latest-notification-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    SharedModule,
    BrowserModule,
    FormsModule,
  ],
  declarations: [
    NotificationListComponent,
    NotificationDashboardComponent,
    DeleteNotificationsComponent,
    NotificationMassComponent,
    EmbedAppointmentBookComponent,
    EmbedBookModalComponent,
    NotificationMassPatientComponent,
    LatestNotificationDashboardComponent
  ],
  exports: [ DeleteNotificationsComponent, NotificationMassComponent, NotificationDashboardComponent, NotificationMassPatientComponent, LatestNotificationDashboardComponent]
})
export class NotificationsModule {
}
