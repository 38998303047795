import {Injectable} from '@angular/core';
import {Observable, interval} from 'rxjs';
import {Http} from '@angular/http';
import './auth/atob.service';
import {flatMap} from 'rxjs/operators';
import {RestService} from '@app/core/rest.service';
import {RtnConfig} from '@app/shared/rtnconfig';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule} from '@angular/http';

// Avoid TS error 'cannot find name escape'
declare var escape: any;
declare var localStorage: any;

@Injectable({
  providedIn: 'root'
})
export class RtnAuth {
  public tokenStream: Observable<string>;
  private _tokenValue: any;
  private readonly _jwtHelper: JwtHelper;
  // private _service: AuthService;
  private _refreshSubscription: any;

  // Constructor
  constructor(
    private service: RestService
  ) {
    this._jwtHelper = new JwtHelper();
    // this._service = new AuthService(_http);
    this.tokenStream = new Observable<string>((obs: any) => {
      obs.next((() => this.tokenValue));
    });
  }

  // Gets service
  // public get service(): AuthService {
  //   return this._service;
  // }

  // Gets jwt helper
  public get jwtHelper(): JwtHelper {
    return this._jwtHelper;
  }

// Gets token value
  public get tokenValue(): string {
    return (this._tokenValue = localStorage.getItem(RtnConfig['token.key']));
  }

  // Sets token value
  public set tokenValue(_tokenValue: string) {
    this._tokenValue = _tokenValue;
    if (_tokenValue === null || _tokenValue === undefined) {
      localStorage.removeItem(RtnConfig['token.key']);
    } else {
      localStorage.setItem(RtnConfig['token.key'], _tokenValue);
    }
  }

  /**
   * Checks for presence of token and that token hasn't expired.
   * For use with the @CanActivate router decorator and NgIf
   */
  public authenticated() {
    const isAuthenticated = this.tokenValue && !this.isTokenExpired();
    if (!isAuthenticated) {
      this.tokenValue = null;
    }
    return isAuthenticated;
  }

  public isTokenExpired() {
    return this._jwtHelper.isTokenExpired(this.tokenValue, null);
  }

  /**
   * Checks if current user's role is in allowed role list to access a route
   */
  public hasRole(roles: string[]): boolean {
    const currentRole = localStorage.getItem('user_role_id');
    for (const item of roles) {
      if (currentRole === item) {
        return true;
      }
    }
    return false;
  }

  public scheduleRefresh() {
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    const source = this.tokenStream.pipe(flatMap((token: any) => {
      // The delay to generate in this case is the difference
      // between the expiry time and the issued at time
      const jwtJson = this.jwtHelper.decodeToken(token()) || {};
      const jwtIat = jwtJson.iat;
      const jwtExp = jwtJson.exp;
      const iat = new Date(0);
      const exp = new Date(0);
      const delay = (exp.setUTCSeconds(jwtExp) - iat.setUTCSeconds(jwtIat));

      return interval(delay);
    }));

    this._refreshSubscription = source.subscribe(() => {
      this.getNewJwt();
    });
  }

  public startupTokenRefresh() {
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    /*
    if (this.authenticated()) {
        let source = this.service.tokenStream.flatMap((token: any) => {
            // Get the expiry time to generate
            // a delay in milliseconds
            let jwtJson = this.jwtHelper.decodeToken(token()) || {};
            let now: number = new Date().valueOf();
            let jwtExp: number = jwtJson.exp;
            let exp: Date = new Date(0);
            exp.setUTCSeconds(jwtExp);
            let delay: number = exp.valueOf() - now;

            // Use the delay in a timer to
            // run the refresh at the proper time
            return Observable.timer(delay);
        });

        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        source.subscribe(() => {
            this.getNewJwt();
            this.scheduleRefresh();
        });
    }
    */
  }

  // Unsubscribe fromt the refresh
  public unscheduleRefresh() {
    if (this._refreshSubscription) {
      this._refreshSubscription.unsubscribe();
    }
  }

  // Get a new JWT from Auth0 using the refresh token saved
  // in local storage
  public getNewJwt() {
    /*
    this.service.refreshToken().subscribe(
        (success: any) => {
            this.tokenValue = success.token;
        },
        (error: any) => {
            this.tokenValue = null;
        }
    );
    */
  }

  public login(username: string, password: string) {
    // const _source = this.service.userLogin(username, password).subscribe((res: any) => {
    //     this.tokenValue = res.token;
    //   },
    //   (error: any) => {
    //     this.tokenValue = null;
    //   });
    // return _source;

    // let _source = this.service.userLogin(username, password).toPromise();

    // _source.then((success: any) => {
    //     this.tokenValue = success.token;
    // }).catch((error: any) => {
    //     this.tokenValue = null;
    // });

    // return _source;
    return "";
  }

  public logout() {
    // const _source = this.service.userLogout().subscribe((res: any) => {
    //     this.clearLocalStorage();
    //   },
    //   (error: any) => {
    //     this.clearLocalStorage();
    //   });
     // return _source;
  }

  public clearLocalStorage() {
    // Remove localStorage role id when logout
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role_id');
    localStorage.removeItem('user_status');
    localStorage.removeItem('user_email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('user_practice');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('managing_user_id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('last_active_token');
    localStorage.removeItem('storeData');
    this.tokenValue = null;
  }
}

/**
 * Helper class to decode and find JWT expiration.
 */
export class JwtHelper {

  public urlBase64Decode(str: string) {
    let output = (str + '').replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }

    // polyfill https://github.com/davidchambers/Base64.js
    return decodeURIComponent(escape(window.atob(output)));
  }

  public decodeToken(token: string) {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded);
  }

  public getTokenExpirationDate(token: string) {
    const decoded: any = this.decodeToken(token);
    if (typeof decoded.exp === 'undefined') {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  public isTokenExpired(token: string, offsetSeconds?: number) {
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;
    if (date === null) {
      return false;
    }

    // Token expired?
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
  }
}
