<div class="provider-edit">
    <div class="padding-left-15">
        <h3 class="text-header-form-edit">
            <a class="no-focus" [routerLink]="['/admin', 'account']">
                <span class="fa fa-angle-left style-top-arrow"></span>
            </a>
            Add New Super User
        </h3>
    </div>
    <form method="post" #form="ngForm" (ngSubmit)="addSuperUserForm(form.value)" class="row padding-left-right-15">
        <div class="col-12 col-lg-8 no-padding">
            <div class="card-edit-profile card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-edit">Contact Info</h3>
                </div>
                <div class="card-body row">
                    <div class="col-md-12 no-padding-left-right inline-edit-provider">
                        <div class="select width-full">
                            <span class="text-label-style input-label-required">Practice</span>
                            <select class="form-control textbox-edit-form width-full" [(ngModel)]="superUserDetail.office_name" name="office_name" [class.input-error]="responseError['office_name']" (change)="onKeyUp('office_name')">
                                <option [value]="''" selected>Select Practice</option>
                                <option *ngFor="let practice of practiceList" [value]="practice.id">{{practice.name}}</option>
                            </select>
                            <div class="input-group-select-icon cursorPointer select-state">
                                <i class="fa fa-caret-down"></i>
                            </div>
                        </div>
                        <span class="message-error" *ngIf="responseError['office_name']">{{responseError['office_name']}}</span>
                    </div>
                    <div class="col-md-6 no-padding-left inline-edit-provider email-address">
                        <span class="text-label-style input-label-required">Email Address</span>
                        <input class="form-control textbox-edit-form" type="text" ngControl="email" [class.input-error]="responseError['email']" [(ngModel)]="superUserDetail.email" name="email" maxlength="50" (keyup)="onKeyUp('email')">
                        <span class="message-error" *ngIf="responseError['email']">{{responseError['email']}}</span>
                    </div>
                    <div class="col-md-6 row no-padding">
                        <div class="no-padding-left-right inline-edit-provider width-full">
                            <div class="text-label-style input-label-required">Phone Number</div>
                            <div>
                                <div class="col-12 col-md-12 no-padding-left-right">
                                    <input class="form-control textbox-edit-form" ngControl="phone_mobile" type="text" [class.input-error]="responseError['phone_mobile']" [(ngModel)]="superUserDetail.phone_mobile" name="phone_mobile" (keyup)="onKeyUp('phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                                    <span class="message-error">{{ responseError.phone_mobile }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row no-padding">
                        <div class="col-md-5 no-padding-left inline-edit-provider">
                            <span class="text-label-style input-label-required">First Name</span>
                            <input class="form-control textbox-edit-form" type="text" [(ngModel)]="superUserDetail.first_name" name="first_name" ngControl="first_name" [class.input-error]="responseError['first_name']" maxlength="50" (keyup)="onKeyUp('first_name')">
                            <span class="message-error" *ngIf="responseError['first_name']">{{responseError['first_name']}}</span>
                        </div>
                        <div class="col-md-2 no-padding-left inline-edit-provider">
                            <span for="middle_name">Middle
                                <span class="hidden-xs">Initial</span>
                            </span>
                            <input id="middle-name" class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['middle_name']" [(ngModel)]="superUserDetail.middle_name" name="middle_name" ngControl="middle_name" maxlength="1">
                            <span class="message-error" *ngIf="responseError['middle_name']">{{responseError['middle_name']}}</span>
                        </div>
                        <div class="col-md-5 no-padding inline-edit-provider">
                            <span class="text-label-style input-label-required">Last Name</span>
                            <input class="form-control textbox-edit-form" type="text" [(ngModel)]="superUserDetail.last_name" name="last_name" ngControl="last_name" [class.input-error]="responseError['last_name']" maxlength="50" (keyup)="onKeyUp('last_name')">
                            <span class="message-error" *ngIf="responseError['last_name']">{{responseError['last_name']}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 no-padding-left-right inline-edit-provider">
                        <span class="text-label-style input-label-required">Address Line 1</span>
                        <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address']" [(ngModel)]="superUserDetail.address" name="address" ngControl="address" maxlength="50" (keyup)="onKeyUp('address')">
                        <span class="message-error" *ngIf="responseError['address']">{{responseError['address']}}</span>
                    </div>
                    <div class="col-md-12 no-padding-left-right inline-edit-provider">
                        <span class="text-label-style">Address Line 2</span>
                        <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address2']" [(ngModel)]="superUserDetail.address2" name="address2" ngControl="address2" maxlength="50">
                        <span class="message-error" *ngIf="responseError['address2']">{{responseError['address2']}}</span>
                    </div>
                    <div class="col-md-12 row no-padding">
                        <div class="col-md-5 no-padding-left">
                            <span class="text-label-style input-label-required">City</span>
                            <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['city']" [(ngModel)]="superUserDetail.city" name="city" ngControl="city" maxlength="50" (keyup)="onKeyUp('city')">
                            <span class="message-error" *ngIf="responseError['city']">{{responseError['city']}}</span>
                        </div>
                        <div class="col-md-4 no-padding-left">
                            <div class="select width-full postion-relative">
                                <span class="text-label-style input-label-required">State</span>
                                <select class="form-control textbox-edit-form" [(ngModel)]="superUserDetail.state_code" name="state_code" ngControl="state_code" [class.input-error]="responseError['state_code']" (change)="onKeyUp('state_code')">
                                    <option [value]="''" selected>Select State</option>
                                    <option *ngFor="let stateItem of _systemData.states" [value]="stateItem.code">{{stateItem.name}}</option>
                                </select>
                                <div class="input-group-select-icon cursorPointer select-state">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <span class="message-error" *ngIf="responseError['state_code']">{{responseError['state_code']}}</span>
                        </div>
                        <div class="col-md-3 no-padding">
                            <span class="text-label-style input-label-required">ZIP Code</span>
                            <input class="form-control textbox-edit-form" type="text" ngControl="zip" [class.input-error]="responseError['zip']" [(ngModel)]="superUserDetail.zip" name="zip" (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'" [target]="superUserDetail" [field]="'zip'" maxlength="10">
                            <span class="message-error" *ngIf="responseError['zip']">{{responseError['zip']}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 padding-bottom-20 no-padding-left">
            <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Create</button>
        </div>
    </form>
  </div>
