import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {UtilsService} from '../core/utils.service';

@Injectable({
  providedIn: 'root'
})

export class HomepageService {

  sendForgotMailURL = environment.serverUrl + '/users/forgot';
  // {0}: id
  // {1}: token
  validLinkURL: any = environment.serverUrl + '/users/forgot/change-password/{0}/{1}';
  changePasswordURL = environment.serverUrl + '/users/forgot/change-password';
  changePasswordExpiredURL = environment.serverUrl + '/users/change-password-expired';
  signUpNewPatientURL = environment.serverUrl + '/users/sign-up';
  resendEmailActivePatientURL = environment.serverUrl + '/users/re-send';
  resendEmailURL = environment.serverUrl + '/users/re-send';
  activateUserURL = environment.serverUrl + '/users/activate';
  acceptURL = environment.serverUrl + '/familymember/accept';
  declineURL = environment.serverUrl + '/familymember/decline';

  patientuserinfoURL: any = environment.serverUrl + '/users/patientuserinfo/{0}';
  patientsettingbycodeURL: any = environment.serverUrl + '/users/patientsettingbycode/{0}';

  getpatientOptoutURL: any = environment.serverUrl + '/fetch-notification-schedule-setting/{0}/{1}';
  updateScheduleOptoutURL: any = environment.serverUrl + '/notification-schedule-optout/{0}';
  updateScheduleAltOptoutURL: any = environment.serverUrl + '/notification-schedule-altoptout/{0}';

  updatePatientOptoutURL: any = environment.serverUrl + '/notification-patient-optout/{0}';
  getintouchURL: any = environment.serverUrl + '/getintouch';
  requestdemoURL: any = environment.serverUrl + '/requestdemo';

  constructor(private _http: HttpClient) {
  }
  debugMode() {
    return !environment.production;
  }
  accept(data: any) {
    return this._http.put(this.acceptURL, data).pipe(
      map(results => results)
    );
  }

  decline(data: any) {
    return this._http.put(this.declineURL, data).pipe(
      map(results => results)
    );
  }

  //add-manager
  sendForgotMail(params: any) {
    return this._http.post(this.sendForgotMailURL, params).pipe(
      map(results => results)
    );
  }

  validLink(id: number | string, token: any) {
    return this._http.get(this.validLinkURL.format(id, token)).pipe(
      map(results => results)
    );
  }

  changePassword(params: any) {
    return this._http.put(this.changePasswordURL, params).pipe(
      map(results => results)
    );
  }

  changePasswordExpired(params: any) {
    return this._http.put(this.changePasswordExpiredURL, params).pipe(
      map(results => results)
    );
  }

  signUpNewPatient(params: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('api-version', '3.0');
    return this._http.post(this.signUpNewPatientURL, params, {
      headers: headers
    }).pipe(
      map(results => results)
    );
  }

  resendEmailActivePatient(params: any) {
    return this._http.post(this.resendEmailActivePatientURL, params).pipe(
      map(results => results)
    );
  }

  resendEmail(params: any) {
    return this._http.post(this.resendEmailURL, params).pipe(
      map(results => results)
    );
  }

  activateUser(params: any) {
    return this._http.put(this.activateUserURL, params).pipe(
      map(results => results)
    );
  }

  getPatientuserinfo(id:any) {
    return this._http.get(this.patientuserinfoURL.format(id)).pipe(map(results => results));
  }
  updatePatientNotificationSettingByCode(id: any, params: any) {
    return this._http.post(this.patientsettingbycodeURL.format(id), params).pipe(map(results => results));
  }
  getPatientOptoutURL(id:any, opttype:any) {
    return this._http.get(this.getpatientOptoutURL.format(id, opttype)).pipe(map(results => results));
  }
  
  updateScheduleOptout(id: any, params: any) {
    return this._http.post(this.updateScheduleOptoutURL.format(id), params).pipe(map(results => results));
  }
  updateScheduleAltOptout(id: any) {
    return this._http.post(this.updateScheduleAltOptoutURL.format(id), {}).pipe(map(results => results));
  }
  
  updatePatientOptout(id: any, params: any) {
    return this._http.post(this.updatePatientOptoutURL.format(id), params).pipe(map(results => results));
  }
  getInTouch(params: any) {
    return this._http.post(this.getintouchURL, params).pipe(map(results => results));
  }
  requestDemo(params: any) {
    return this._http.post(this.requestdemoURL, params).pipe(map(results => results));
  }  
}
