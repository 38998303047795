<nav class="navbar navbar-expand-lg pl-0 pr-0 pt-0">
  <div class="container provider-header pl-0 pt-2 menu-flex-no-wrap">
    <a class="navbar-header pt-icon-2">
      <a class="navbar-brand" href="https://www.realtimeneighborhood.com/">
        <img src="/assets/img/logo.png"/>
      </a>
    </a>
    <div class="provider-header-navigator navbar-collapse collapse">
      <ul class="navbar-nav title-menu provider-header-navigator-title menu-flex-row">
        <li style="display: block;" [class.active]="_systemService.navActivated(['/admin', 'account'], _router)">
          <a class="show-focus nav-link" [routerLink]="['/admin', 'account']">
            <span
              class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">ACCOUNT MANAGEMENT</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                    <span class="fa fa-users header-icon"></span>
                </span>

          </a>
        </li>
        <li style="display: block;" [class.active]="_systemService.navActivated(['/admin', 'ehr'], _router)">
          <a class="show-focus nav-link" [routerLink]="['/admin', 'ehr']">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">EHR INTEGRATION</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                    <span class="fr-font fr-connectivity header-icon"></span>
                  </span>
          </a>
        </li>
        <li style="display: block;" [class.active]="_systemService.navActivated(['/admin', 'notification'], _router)"
            *ngIf="arrayModule[1]">
          <a class="show-focus nav-link" [routerLink]="['/admin', 'notification']">
            <span
              class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">NOTIFICATION MANAGEMENT</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                  <span class="fa fa-bell-o header-icon"></span>
                  </span>
          </a>
        </li>
        <li style="display: block;" [class.active]="_systemService.navActivated(['/admin', 'practice'], _router)">
          <a class="show-focus nav-link" [routerLink]="['/admin', 'practice']">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">PRACTICE</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                    <span class="fa fa-hospital-o header-icon"></span>
                  </span>
          </a>
        </li>
        <li style="display: block;" [class.active]="_systemService.navActivated(['/admin', 'dataLog'], _router)"
            *ngIf="arrayModule[6]">
          <a class="show-focus nav-link" [routerLink]="['/admin', 'waittimelog-by-provider']">
            <span class="hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none">DATA LOG</span>
            <span class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs">
                    <span class="fa fa-hospital-o header-icon"></span>
                  </span>
          </a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right profile-menu provider-header-navigator-profile">
        <!-- User menu dropdown -->
        <li class="dropdown">
          <a href="#" class="btn hidden-xs hidden-sm d-xl-block d-lg-block d-md-block d-sm-none d-none  dropdown-toggle hover-bottom-border no-focus" data-toggle="dropdown"
             role="button" aria-haspopup="true" aria-expanded="false">
            <i class="pull-right fa fa-caret-down"></i>
            <i class="fa faalign-left fa-user menu-user"></i>
            <span class="pull-left text-overflow-ellipsis">{{_systemData.localStorage.getItem('userFullName')}}</span>
          </a>
          <a href="#" class="d-xl-none d-lg-none d-md-none d-sm-block d-block visible-sm visible-xs dropdown-toggle hover-bottom-border no-focus" data-toggle="dropdown"
             role="button" aria-haspopup="true" aria-expanded="false">
            <span class="fa fa-bars header-icon"></span>
          </a>
          <ul class="dropdown-menu provider-header-navigator-profile-menu">
            <li class="height_36">
                <a [routerLink]="['/admin', 'profile']" class="cursorPointer dropdown-item height_36">
                    <i class="fa faalign-left fa-user"></i>My Profile
                </a>
            </li>
            <li>
              <a (click)="changePassword()" class="cursorPointer logout dropdown-item">
                <i class="fa faalign-left fa-key"></i>Change password
              </a>
            </li>
            <li>
              <a (click)="logoutDirective.open()" class="cursorPointer logout dropdown-item">
                <i class="fa faalign-left fa-sign-out"></i>Log Out
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
<rtn-modals-general-logout></rtn-modals-general-logout>
