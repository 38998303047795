import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AdminDetail } from '@app/shared/models/adminDetail';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '@app/shared/form/input-mask.directive';
import { SimpleMaskDirective } from '@app/shared/form/simple-mask.directive';


@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['../admin.component.scss','./add-admin.component.scss'],
  moduleId: module.id,
  // providers:[SimpleMaskDirective]
})
export class AddAdminComponent implements OnDestroy {

  newAdmin: AdminDetail;
  error: AdminDetail;

  addNewAdminSubscriber: Subscription;

  @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
  @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;

  constructor(
    private _alertService: AlertService,
    private _adminService: AdminRestService,
    private loadingIndicatorService: LoadingIndicatorService) {
      this.init();
    }
  init() {
    this.newAdmin = new AdminDetail();
    this.error = new AdminDetail();
  }

  ngOnDestroy() {
    if (this.addNewAdminSubscriber) {
        this.addNewAdminSubscriber.unsubscribe();
    }
  }
  addAdminForm() {
    this.loadingIndicatorService.start();
    if (this.addNewAdminSubscriber) {
        this.addNewAdminSubscriber.unsubscribe();
    }
    this.addNewAdminSubscriber = this._adminService.addNewAdmin(this.newAdmin).subscribe(
        (success: any) => {
            this.loadingIndicatorService.stop();
            this._alertService.onClose = () => {
                this.newAdmin = new AdminDetail();
                this.error = new AdminDetail();
            };
            this._alertService.showDismissButton = false;
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'OK';
            this._alertService.title = success.error_messages;
            this._alertService.message = '';
            this._alertService.emitter('open');
        },
        (error: any) => {
            this.loadingIndicatorService.stop();
            if (error.error.error_code === 'RC040') {
                this.error = error.error.error_fields;
            }
        });
  }

  onKeyUp(key: string) {
      switch (key) {
          case 'email':
              this.error.email = null;
              break;

          case 'first_name':
              this.error.first_name = null;
              break;

          case 'last_name':
              this.error.last_name = null;
              break;

          case 'phone_mobile':
              this.error.phone_mobile = null;
              break;
      }
  }

}
