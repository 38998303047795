import {Component, Directive, OnDestroy, ViewChild} from '@angular/core';
import {COMMON_DIRECTIVES} from '@angular/common/src/directives';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {AlertService} from './alert.service';

@Directive({
  providers: [MODAL_DIRECTIVES, ModalComponent]
})

@Component({
  selector: 'rtn-alert',
  // directives: [CORE_DIRECTIVES, ModalComponent, MODAL_DIRECTIVES],
  /* It is extremely important to set INLINE MODAL Z_INDEX here to be higher than that of bootstrap z-index (1050)
  * If we set in main.css, when the production version is built, boostrap.css modal's z-index will be overriden by
  * our z-index setting in main.css, which causes some bug, limit the usage of alert service to only system message
  * for highest priority
  */

  template: `
    <modal style="z-index: 2017" #modal
           [tabindex]="alert$.tabindex"
           [size]="alert$.size"
           [animation]="alert$.animation"
           [keyboard]="alert$.keyboard"
           [backdrop]="alert$.backdrop"
           (onOpen)="alert$.onOpen($event)"
           (onDismiss)="alert$.onDismiss($event); _lockOpen = false;"
           (onClose)="alert$.onClose($event); _lockOpen = false;">
      <div *ngIf="alert$.title" class="modal-header">
        <h4 class="modal-title" [innerHTML]="alert$.title"></h4>
      </div>
      <div *ngIf="alert$.message" class="modal-body" [innerHTML]="alert$.message"></div>
      <div *ngIf="alert$.showCustomInputHtml" class="col-12 col-md-6 form-group">
        <label style="font-weight: 700;" for="input_value" class="input-label">{{alert$.labelCustomInputHtml}}</label>
        <input class="form-control ng-pristine ng-invalid ng-touched" type="alert$.inputTypeHtml" name="input_value" [(ngModel)]="alert$.inputValueInputHtml">
      </div>
      <div *ngIf="alert$.showDefaultButtons" class="modal-footer">
        <button *ngIf="alert$.showDismissButton" type="button" class="btn btn-rtn-inv" data-dismiss="modal"
                (click)="modal.dismiss()" [innerHTML]="alert$.dismissButtonLabel" autofocus></button>
        <button *ngIf="alert$.showCloseButton" type="button" class="btn btn-rtn" (click)="modal.close()"
                [innerHTML]="alert$.closeButtonLabel"></button>
      </div>
    </modal>
  `
})

export class AlertComponent implements OnDestroy {
  _lockOpen = false;
  @ViewChild('modal') private modal: ModalComponent;
  private readonly _modalSubscription: any;

  constructor(public alert$: AlertService) {
    this.alert$.tabindex = '-1';
    this._modalSubscription = this.alert$.indicator$.subscribe((mode: string) => {
      switch (mode) {
        case 'open':
          if (!this._lockOpen) {
            this.modal.open();
            this._lockOpen = true;
          }
          break;
        case 'close':
          this.modal.close();
          break;
        case 'dismiss':
          this.modal.dismiss();
          break;
        default:
          this.modal.dismiss();
          break;
      }
    });
  }

  ngOnDestroy() {
    if (this._modalSubscription) {
      this._modalSubscription.unsubscribe();
    }
  }
}
