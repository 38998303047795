import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { HomepageService } from '../home.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Utils } from '@app/shared/utils';
import { AlertService } from '@app/shared/alert';

declare let jQuery: any;

@Component({
  selector: 'rtn-home-patientnotification-optout',
  templateUrl: './patient-notification-optout.component.html',
  styleUrls: ['../home.component.scss','./patient-notification-optout.component.scss'],
  moduleId: module.id,
})
export class PatientNotificationOptoutComponent implements OnInit {

    error: boolean = false;
    errorMessage: any;
    linkIsValid: boolean = true;
    id: string;
    token: string;
    showBtn: boolean = true;

    activateUserSubscriber: Subscription;
    patientSettings : any = {
        id : 0,
        full_name : '',
        phone_mobile_verified : 0,
        alert_waittime : 0,
        email : '',
        is_email : 0,
        is_reminder : 0,
        is_sms : 0,
        mobile_number : '',
        patient_user_id : 0,
        reminder_day : 0,
        alert_waitlist:0
    };
    
    notificationSetting: any = [];
    disabledSms = false;
    timeSetting: any = [];
    updateNotificationGlobalSettingSubscriber: Subscription;
    messageResponseSuccess = '';
    messageResponseError = '';
    minHeight: number;
    optiontype: string = 'schedule_optout';
    fieldDisabled:boolean=false;

    constructor(
        private _homePageService: HomepageService,
        private _utils: Utils,
        private _params: ActivatedRoute,
        private _router: Router,
        private _alertService: AlertService
    ) {
        // TODO
        this.timeSetting = [
            {'name': 'One day', 'value': '1'},
            {'name': 'Two days', 'value': '2'},
            {'name': 'Three days', 'value': '3'},
            {'name': 'Five days', 'value': '5'},
            {'name': 'One week', 'value': '7'}
        ];
    }

    ngOnInit() {
        // TODO: Get token/ id from URL
        this.id = this._params.snapshot.paramMap.get('id');
        this.optiontype = this._params.snapshot.data['optiontype'];

        //this.token = this._params.snapshot.paramMap.get('token');
        // TODO: Validate id & token
        if (this.optiontype == 'schedule_altoptout') {
            this.linkIsValid = false;
            this.errorMessage = '';
            this.updateNotificationAltoptoutForm();
        } else if (this.optiontype == 'patient_altoptout') {
            this.linkIsValid = false;
            this.errorMessage = '';
            this.updateNotificationAltoptoutForm();
        } else {
            this.getPatientuserInfo();
        }

        this.fieldDisabled = (this.optiontype == 'schedule_optout' || this.optiontype == 'patient_optout' || this.optiontype == 'schedule_altoptout' || this.optiontype == 'patient_altoptout') ? true:false;
        
        
        
        this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
    }

    ngOnDestroy() {
        if (this.activateUserSubscriber) {
            this.activateUserSubscriber.unsubscribe();
        }
    }

    private getPatientuserInfo() {
        var self = this;
        
        if (this.activateUserSubscriber) {
            this.activateUserSubscriber.unsubscribe();
        }

        this.notificationSetting = {};
        if (this.optiontype == 'schedule_optout' || this.optiontype == 'schedule_optin') {
            this.notificationSetting.opttype = 'appointment';
        } else {
            this.notificationSetting.opttype = 'everything';
        }
       
        let ttype = (this.optiontype == 'schedule_optout' || this.optiontype == 'schedule_optin') ? 'Schedule' : 'Patient';
        
        this.activateUserSubscriber = this._homePageService.getPatientOptoutURL(this.id, ttype).subscribe((success: any) => {

            self.linkIsValid = true;            
            this.notificationSetting = success.data;
            if (this.optiontype == 'schedule_optout' || this.optiontype == 'schedule_optin') {
                this.notificationSetting.opttype = 'appointment';
            } else {
                this.notificationSetting.opttype = 'everything';
            }

            this.notificationSetting.reminder_day = (this.notificationSetting.reminder_day !== 0) ? this.notificationSetting.reminder_day : 1;
            if (this.notificationSetting.mobile_number === '' || this.notificationSetting.mobile_number === null) {
                this.disabledSms = true;
                this.notificationSetting.is_sms = 0;
            }

            if (this.optiontype == 'schedule_optout' || this.optiontype == 'patient_optout' || this.optiontype == 'schedule_altoptout' || this.optiontype == 'patient_altoptout') {
                this.notificationSetting.is_email = false;
                this.notificationSetting.is_sms = false;
                this.notificationSetting.alert_waittime = false;
                this.notificationSetting.is_reminder = false;
            }

        }, (error: any) => {
            this.linkIsValid = false;
            this.errorMessage = error.error.error_messages;
        });
    }
    changeOptoption(opt:any){
        this.notificationSetting.opttype = opt;
    }
    updateNotificationSettingForm(form: any, data: any) {
        if (this.optiontype == 'schedule_optout' || this.optiontype == 'schedule_optin'){
            const _self = this;
            this.messageResponseSuccess = null;
            this.messageResponseError = null;
            if (this.updateNotificationGlobalSettingSubscriber) {
            this.updateNotificationGlobalSettingSubscriber.unsubscribe();
            }
            this.updateNotificationGlobalSettingSubscriber = this._homePageService.updateScheduleOptout(this.id, data).subscribe(
            (success: any) => {
                this.linkIsValid = true;
                if (success.error_code === 'RC000') {                
                this.messageResponseSuccess = success.error_messages;
                }
            },
            (error: any) => {
                if (error.error.error_code === 'RC087' || error.error.error_code === 'RC088') {
                    this.messageResponseError = error.error.error_messages;
                }

                if (error.error.error_code === 'RC062') {
                    this.linkIsValid = false;
                    this.messageResponseError = error.error.error_messages;
                }
                
            }
            );
        } else {
            const _self = this;
            this.messageResponseSuccess = null;
            this.messageResponseError = null;
            if (this.updateNotificationGlobalSettingSubscriber) {
            this.updateNotificationGlobalSettingSubscriber.unsubscribe();
            }
            this.updateNotificationGlobalSettingSubscriber = this._homePageService.updatePatientOptout(this.id, data).subscribe(
            (success: any) => {
                this.linkIsValid = true;
                if (success.error_code === 'RC000') {                
                this.messageResponseSuccess = success.error_messages;
                }
            },
            (error: any) => {
                if (error.error.error_code === 'RC087' || error.error.error_code === 'RC088') {
                    this.messageResponseError = error.error.error_messages;
                }

                if (error.error.error_code === 'RC062') {
                    this.linkIsValid = false;
                    this.messageResponseError = error.error.error_messages;
                }
                
            }
            );
        }
        
    }
    updateNotificationAltoptoutForm() {
        const _self = this;
        this.errorMessage = null;
        this.messageResponseError = null;
        if (this.updateNotificationGlobalSettingSubscriber) {
            this.updateNotificationGlobalSettingSubscriber.unsubscribe();
        }
        this.updateNotificationGlobalSettingSubscriber = this._homePageService.updateScheduleAltOptout(this.id).subscribe(
        (success: any) => {
            //this.linkIsValid = true;
            this.errorMessage = success.error_messages;
        },
        (error: any) => {
            if (error.error.error_code === 'RC087' || error.error.error_code === 'RC088') {
                this.errorMessage = error.error.error_messages;
            }

            if (error.error.error_code === 'RC062') {
                //this.linkIsValid = false;
                this.errorMessage = error.error.error_messages;
            }
            
        });        
    }
    removeMessage() {
        this.messageResponseSuccess = null;
        this.messageResponseError = null;
    }
}
