<h3 class="provider-appointment">Saved searched for openings</h3>

<div class="row">       
    <div class="col-md-12">
        <button type="button" (click)="addWaitListDirective.open()" class="btn btn-rtn btn-180 float-right ml-2">
            <span class="label">Add Patient</span>
        </button>
        <div class="searchsetting">
            <ng-container *ngIf="saved_opening_alert_settings.alert_waitlist_to_office == 1">
                <label *ngIf="saved_opening_alert_settings.waitlist_alert_office_email_id != undefined && saved_opening_alert_settings.waitlist_alert_office_email_id.length > 0">
                    Office Email: 
                    <ng-container *ngFor="let emailitem of saved_opening_alert_settings.waitlist_alert_office_email_id">
                        <span class="badge badge-info">{{ emailitem }}</span>
                    </ng-container>                
                </label> |
                <label>Send At : 
                    <span class="badge badge-success" *ngIf="saved_opening_alert_settings.send_waitlist_to_office_time1 == 1">6 AM</span>
                    <span class="badge badge-success" *ngIf="saved_opening_alert_settings.send_waitlist_to_office_time2 == 1">3 PM</span>
                    <span class="badge badge-success" *ngIf="saved_opening_alert_settings.send_waitlist_to_office_time3 == 1">7 PM</span>
                </label> |
            </ng-container>
            <ng-container *ngIf="saved_opening_alert_settings.alert_waitlist_to_office == 0">
                <label>
                    Office Email : <span class="badge badge-danger">Disabled</span>               
                </label> | 
            </ng-container>
            <label>Threshold : <span class="badge badge-warning">{{ saved_opening_alert_settings.waitlist_threshold_limit }}</span> days</label>
            | <a [routerLink]="['/provider', 'preferences']"><i class="fa fa-cog"></i> Change Setting</a>
            <ng-container *ngIf="saved_opening_alert_settings.alert_waitlist_to_office == 1">
            | <a href="javascript:void(0)" (click)="manuallySend()"><i class="fa fa-send"></i> Send</a>
            </ng-container>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ul class="nav nav-tabs">
            <li class="nav-item col-2 col-lg-2 col-md-2 col-xs-6 no-padding-left-right find-appointment-link">
                <div class="active-tabs"></div>
                <div class="active-tabs"></div>
                <a class="text-ellipsis nav-link active" data-toggle="tab" href="#prioritywaitlist"
                    (click)="openTab(1)" [ngClass]="{ 'active' : (selectedTab == 1) ? true : false }">Priority List</a>
            </li>
            <li class="nav-item col-3 col-lg-3 col-md-3 col-xs-6 no-padding-left-right find-appointment-link">
                <div class="active-tabs"></div>
                <div class="active-tabs"></div>
                <a class="text-ellipsis nav-link" data-toggle="tab" href="#existingpatientlist" 
                (click)="openTab(2)" [ngClass]="{ 'active' : (selectedTab == 2) ? true : false }">Existing Patient Wait List</a>
            </li>            
        </ul>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <div class="tab-content">
            <div id="prioritywaitlist" class="tab-pane container fade in px-0" [ngClass]="{ 'active' : (selectedTab == 1) ? true : false, 'show' : (selectedTab == 1) ? true : false }">
                <rtn-table #tableSave
                    (onDataChange)="onDataChange($event)"
                    [autoloading]="false"
                    [data]="listSaveSearch.data"
                    [rowsOnPage]="listSaveSearch.rowsOnPage"
                    [numberOfPages]="listSaveSearch.numberOfPages"
                    >
                    <rtn-table-filter class="w-100" #filter class="col-lg-12 px-0">
                        <form #form="ngForm" (ngSubmit)="onSearchSubmited(tableSave)">
                            <div class="row col-12 pr-0 mb-2 justify-content-end">
                                <div class="row col-12 col-lg-8 col-xl-7 pr-0 justify-content-end">
                                    <div class="col-6 sol-sm-7 mr-1">
                                        <input class="form-control margin-left-15 height_35" type="text" placeholder="Enter Patient name" [(ngModel)]="searchText" name="searchText">
                                    </div>
                                    <div class="pr-0">
                                        <button type="submit" class="btn btn-rtn btn-180 width_90 ml-2 mr-1">
                                            <i class="fas fa-search"></i>
                                            <span class="label">Search</span>
                                        </button>
                                        <a  class="btn btn-rtn mr-1" href="javascript:void(0);" title="Block to sent open slot notification" (click)="blocktosendnotification(1)">
                                            <span class="label">Block</span>
                                        </a>
                                        <a  class="btn btn-rtn btn-red mr-0" href="javascript:void(0);" title="Unblock to sent open slot notification" (click)="blocktosendnotification(2)">
                                            <span class="label">Unblock</span>
                                        </a>
                                    </div>
                                </div>
        
                            </div>
                        </form>
                    </rtn-table-filter>
                    <div class="saved-searchs-for-openings col-xs-12 col-sm-12 col-md-12 no-padding-left-right table-responsive">
                        <div class="col-xs-12 col-sm-12 col-md-12 listview no-padding-left-right">
                            <table class="table table-bordered table-ellipsis remove-style">
                                <thead>
                                    <tr>
                                        <th class="col-checkbox">&nbsp;</th>
                                        <th class="col-date">Date</th>
                                        <th class="col-zip">Time</th>
                                        <th class="col-provider">Patient</th>
                                        <th class="col-zip">ZIP Code</th>                                        
                                        <th class="col-zip">Schedule Date</th>  
                                        <th class="col-distance">Reason</th>                                 
                                        <th class="col-delete" width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let item of tableSave.data; let isOdd=odd" id="saved-data-{{item.id}}" [ngClass]="{ 
                                    'high-priority-background' : (item.priority == 1 && item.sort_order == 1) ? true : false,
                                    'medium-priority-background' : (item.priority == 1 && item.sort_order == 2) ? true : false,
                                    'low-priority-background' : (item.priority == 2 && item.sort_order == 1) ? true : false,
                                    'normal-priority-background' : (item.priority == 2 && item.sort_order == 2) ? true : false
                                }">                                    
                                    <tr>
                                        <td class="pt_15">
                                            <input type="checkbox" [value]="item.patient_user_id" (click)="selectePatientFromWl(item.patient_user_id)" [attr.id]="'selector'+ item.id" />
                                        </td>
                                        <td class="pt_15">{{item.date}}</td>
                                        <td class="pt_15">                                            
                                            <span class="d-block">{{item.time}}</span>
                                            <span class="d-block" *ngIf="item.advance_notice_duration">Availability : {{item.advance_notice_duration}}</span>
                                        </td>
                                        <td class="pt_15" [ngClass]="{'iswlblocked' : (is_wl_blocked == true) ? true : false }">
                                            <span class="d-block">{{item.patient_name}}</span>                                            
                                            <span class="d-block" *ngIf="item.mobile">{{item.mobile}}</span>
                                            <span class="d-block" *ngIf="item.email">{{item.email}}</span>
                                        </td>
                                        <td class="pt_15">
                                            <span class="d-block">{{item.zip}}</span>                                            
                                            <!--<span class="d-block">Distance : {{item.distance}}</span>-->
                                        </td>                                        
                                        <td class="pt_15">{{item.schedule_date}}<br/>{{item.schedule_time}}</td>   
                                        <td class="pt_15">                                           
                                            <span class="d-block" *ngIf="item.appointment_reason">{{item.appointment_reason}}</span>
                                            <span class="d-block" *ngIf="item.apt_type"><strong>Apt.Type:</strong>{{item.apt_type}}</span>                                            
                                        </td>                                     
                                        <td class="text-left">
                                            <a  class="btn btn-rtn mr-1 mt-1" (click)="editWaitListDirective.open(item.id)" *ngIf="item.allow_moveto_wl == 1"><span class="label">Move</span></a>
                                            <a  class="btn btn-rtn mr-1 disabled mt-1" href="javascript:void(0);" *ngIf="item.allow_moveto_wl == 0"><span class="label">Move</span></a>
                                            <a  class="btn btn-rtn mr-1 mt-1"                                           
                                                (click)="notifyAvailableSlotWaitlistDirective.checkLockedProvider(2,item.id, item.patient_user_id, item.patient_name, item.start_datetime, item.end_datetime, item.start_time, item.end_time, item.is_family)">
                                                <span class="label">Send</span>
                                            </a>                                        
                                            <a  class="btn btn-rtn mr-1 mt-1"                                           
                                                (click)="appointmentBookWaitListDirective.checkLockedProvider(2,item.id, item.patient_user_id, item.patient_name, item.start_datetime, item.end_datetime, item.start_time, item.end_time, item.is_family, item.schedule_id)">
                                                <span class="label">Book</span>
                                            </a>                                            
                                            <a class="btn btn-rtn btn-red mr-1 mt-1" href="javascript:void(0);" title="Delete" (click)="showConfirmDeleteAlertService(item.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                                        </td>                                        
                                    </tr>                                    
                                </tbody>
                                <tbody>
                                    <tr *ngIf="tableSave.data.length==0" class="no-saved-search">
                                        <td colspan="8">There is no saved searches for openings</td>
                                    </tr>
                                </tbody>
                                
                                <tfoot *ngIf="tableSave.numberOfPages > 1">
                                    <tr>
                                        <td colspan="12" class="text-center table-responsive no-overflow-y">
                                            <div class="page-footer">
                                                <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </rtn-table>
            </div>
            
            <div id="existingpatientlist" class="tab-pane container fade in px-0" [ngClass]="{ 'active' : (selectedTab == 2) ? true : false, 'show' : (selectedTab == 2) ? true : false }">
                <rtn-table #tableSave1
                    (onDataChange)="onDataChange($event)"
                    [autoloading]="false"
                    [data]="listSaveSearch.data"
                    [rowsOnPage]="listSaveSearch.rowsOnPage"
                    [numberOfPages]="listSaveSearch.numberOfPages"
                    >
                    <rtn-table-filter class="w-100" #filter class="col-lg-12 px-0">
                        <form #form="ngForm" (ngSubmit)="onSearchSubmited(tableSave1)">
                            <div class="row col-12 pr-0 mb-2 justify-content-end">
                                <div class="row col-12 col-lg-8 col-xl-7 pr-0 justify-content-end">
                                    <div class="col-6 sol-sm-7 mr-1">
                                        <input class="form-control margin-left-15 height_35" type="text" placeholder="Enter Patient name" [(ngModel)]="searchText" name="searchText">
                                    </div>
                                    <div class="pr-0">
                                        <button type="submit" class="btn btn-rtn btn-180 width_90 ml-2">
                                            <i class="fas fa-search"></i>
                                            <span class="label">Search</span>
                                        </button>
                                        <a  class="btn btn-rtn mr-1" href="javascript:void(0);" title="Block to sent open slot notification" (click)="blocktosendnotification(1)">
                                            <span class="label">Block</span>
                                        </a>
                                        <a  class="btn btn-rtn btn-red mr-0" href="javascript:void(0);" title="Unblock to sent open slot notification" (click)="blocktosendnotification(2)">
                                            <span class="label">Unblock</span>
                                        </a>
                                    </div>
                                </div>
        
                            </div>
                        </form>
                    </rtn-table-filter>
                    
                    <div class="saved-searchs-for-openings col-xs-12 col-sm-12 col-md-12 no-padding-left-right table-responsive">
                        <div class="col-xs-12 col-sm-12 col-md-12 listview no-padding-left-right">
                            <table class="table table-bordered table-ellipsis remove-style">
                                <thead>
                                    <tr>
                                        <th class="col-checkbox">&nbsp;</th>
                                        <th class="col-date">Date</th>
                                        <th class="col-zip">Time</th>
                                        <th class="col-provider">Patient</th>
                                        <th class="col-zip">ZIP Code</th>
                                        <th class="col-zip">Schedule Date</th>  
                                        <th class="col-distance">Reason</th>                                                                      
                                        <th class="col-delete" width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let item of tableSave.data; let isOdd=odd" id="saved-data-{{item.id}}" [ngClass]="{ 
                                    'high-priority-background' : (item.priority == 1 && item.sort_order == 1) ? true : false,
                                    'medium-priority-background' : (item.priority == 1 && item.sort_order == 2) ? true : false,
                                    'low-priority-background' : (item.priority == 2 && item.sort_order == 1) ? true : false,
                                    'normal-priority-background' : (item.priority == 2 && item.sort_order == 2) ? true : false
                                }">                                    
                                    <tr>
                                        <td class="pt_15">
                                            <input type="checkbox" [value]="item.patient_user_id" (click)="selectePatientFromWl(item.patient_user_id)" [attr.id]="'selector'+ item.id" />
                                        </td>
                                        
                                        <td class="pt_15">{{item.date}}</td>
                                        <td class="pt_15">                                            
                                            <span class="d-block">{{item.time}}</span>
                                            <span class="d-block" *ngIf="item.advance_notice_duration">Availability : {{item.advance_notice_duration}}</span>
                                        </td>
                                        <td class="pt_15">
                                            <span class="d-block">{{item.patient_name}}</span>                                            
                                            <span class="d-block" *ngIf="item.mobile">{{item.mobile}}</span>
                                            <span class="d-block" *ngIf="item.email">{{item.email}}</span>                                           
                                        </td>
                                        <td class="pt_15">                                            
                                            <span class="d-block">{{item.zip}}</span>                                            
                                            <!--<span class="d-block">Distance : {{item.distance}}</span>-->
                                        </td>
                                        <td class="pt_15">{{item.schedule_date}}<br/>{{item.schedule_time}}</td> 
                                        <td class="pt_15">
                                            <span class="d-block" *ngIf="item.appointment_reason">{{item.appointment_reason}}</span>
                                            <span class="d-block" *ngIf="item.apt_type"><strong>Apt.Type:</strong>{{item.apt_type}}</span>
                                        </td>                                           
                                        <td class="text-center">                                            
                                            <a  class="btn btn-rtn mr-1"                                           
                                                (click)="notifyAvailableSlotWaitlistDirective.checkLockedProvider(2,item.id, item.patient_user_id, item.patient_name, item.start_datetime, item.end_datetime, item.start_time, item.end_time, item.is_family)">
                                                <span class="label">Send</span>
                                            </a>                                        
                                            <a  class="btn btn-rtn mr-1"                                           
                                                (click)="appointmentBookWaitListDirective.checkLockedProvider(2,item.id, item.patient_user_id, item.patient_name, item.start_datetime, item.end_datetime, item.start_time, item.end_time, item.is_family, item.schedule_id)">
                                                <span class="label">Book</span>
                                            </a>
                                            
                                            <a class="btn btn-rtn btn-red" href="javascript:void(0);" title="Delete" (click)="showConfirmDeleteAlertService(item.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                                        </td>                                        
                                    </tr>                                    
                                </tbody>
                                <tbody>
                                    <tr *ngIf="tableSave.data.length==0" class="no-saved-search">
                                        <td colspan="8">There is no saved searches for openings</td>
                                    </tr>
                                </tbody>
                                
                                <tfoot *ngIf="tableSave.numberOfPages > 1">
                                    <tr>
                                        <td colspan="12" class="text-center table-responsive no-overflow-y">
                                            <div class="page-footer">
                                                <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </rtn-table>
            </div>            
        </div>
    </div>
</div>

<rtn-appointment-book-waitlist (reload)="timelineDirective.refetchAppointment()"></rtn-appointment-book-waitlist>
<app-add-waitlist (reload)="getNotifySavedOpenAppointmentSetting(2, '', selectedTab)"></app-add-waitlist>
<app-notify-available-slot-waitlist (reload)="getNotifySavedOpenAppointmentSetting(1, '', selectedTab)"></app-notify-available-slot-waitlist>
<app-edit-waitlist (reload)="getNotifySavedOpenAppointmentSetting(2, '', selectedTab)"></app-edit-waitlist>