import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class QuickAptbookingService {

  getOpenslotDetailsURL = environment.serverUrl + '/getopenslotnotification';
  getopenslotbookappointmentUrl = environment.serverUrl + '/getopenslotbookappointment';
  openslotQuickbookAppointmentUrl = environment.serverUrl + '/openslot-quickbook-appointment';
  fetchPatientUpcommingAptUrl = environment.serverUrl + '/patient/fetch-patient-upcommingapt';

  constructor(private _http: HttpClient) { }

  getOpenslotDetails(id: any) {
    return this._http.post(this.getOpenslotDetailsURL, {id: id});
  }
  getBookAppointment(openSlotId:any, providerId: any, slotId: any) {
    var params = new HttpParams();
    if (openSlotId || openSlotId != undefined) {
      params = params.set('open_slot_id', openSlotId);
    }
    if (providerId || providerId != undefined) {
      params = params.set('provider_id', providerId);
    }    
    if (slotId || slotId != undefined){
      params = params.set('slot_id', slotId);
    }
    return this._http.get(this.getopenslotbookappointmentUrl, {params: params});
  }  
  openslotQuickbookAppointment(data: any) {
    return this._http.post(this.openslotQuickbookAppointmentUrl, JSON.stringify(data));
  }
  fetchPatientUpcommingApt(patientUserID: any=0, providerID: any=0,) {
    const params = new HttpParams().set('patient_user_id', patientUserID).set('provider_id', providerID);
    return this._http.get(this.fetchPatientUpcommingAptUrl, {params});
  }
}