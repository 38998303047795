import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent } from '@app/shared/datatable';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Utils } from '@app/shared/utils';
import { Item } from '@app/shared/models/multipleSelect';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import * as moment from 'moment';
import { SystemEvent,SystemInterval, SystemService, SystemData } from '../../shared/system';


declare let jQuery: any;

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};

moment.updateLocale('en-US', _momentOptions);

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['../admin.component.scss', './account.component.scss'],
  moduleId: module.id,
  // providers: [ Utils, LoadingIndicatorService]
})
export class AccountComponent implements OnInit {
  // declare arrayActiveModule
  arrayModule = new Array();

  defaultType: string = 'Account Type: All';
  defaultStatus: string = 'Account Status: All';
  accountList: any = new Object();
  roles: any = [];
  statuses: any = [];
  role: any = [];
  status: any = [];
  rolesSelected: string;
  statusesSelected: string;
  rolesId: any = [];
  statusesId: any = [];
  page: number = 1;
  errorMessage: string;
  searchText: string = '';

  userTypeSubscriber: Subscription;
  userStatusSubscriber: Subscription;
  userInfoSubscriber: Subscription;
  activateAccountSubscriber: Subscription;
  deactivateAccountSubscriber: Subscription;
  runRequest: boolean = true;

  loginEmail: string;

  @ViewChild(RtnDataTableCoreComponent) rtnDataTableCore: RtnDataTableCoreComponent;
  @ViewChild(RtnDataTableFilterComponent) rtnDataTableFilter: RtnDataTableFilterComponent;
  // @ViewChild(RtnDataTableFilterComponent) rtnDataTableDirect: DataTableDirectives;

  constructor(
      private _admintService: AdminRestService,
      private _loadingIndicatorService: LoadingIndicatorService,
      private _utils: Utils,
      public _systemService: SystemService,
  ) {
      this.init();
  }

  init() {
      this.accountList.data = new Array();
      this.accountList.numberOfPages = 0;
      this.accountList.rowsOnPage = 10;
      this.errorMessage = null;
  }

  ngOnInit() {
      // get active module array
      this.arrayModule = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
      this.getType();
      this.rolesSelected = this.defaultType;
      this.statusesSelected = this.defaultStatus;
      this.loginEmail = localStorage.getItem('user_id');
  }

	ngOnDestroy() {
        if (this.userTypeSubscriber) {
            this.userTypeSubscriber.unsubscribe();
        }
        if (this.userStatusSubscriber) {
            this.userStatusSubscriber.unsubscribe();
        }
        if (this.userInfoSubscriber) {
            this.userInfoSubscriber.unsubscribe();
        }
        if (this.activateAccountSubscriber) {
            this.activateAccountSubscriber.unsubscribe();
        }
        if (this.deactivateAccountSubscriber) {
            this.deactivateAccountSubscriber.unsubscribe();
        }
    }

    getType() {
        if (this.userTypeSubscriber) {
            this.userTypeSubscriber.unsubscribe();
        }
        this.userTypeSubscriber = this._admintService.userType().subscribe(
        (success: any) => {
            if (success.error_code === 'RC000') {
                success.roles.forEach((role: any) => {
                    let i = new Item();
                    i.oj = role;
                    this.roles.push(i);
                    // remove deactiveModule
                    if ( ( !this.arrayModule[3] && [3,5,6].includes(role.id)) ||( !this.arrayModule[4] && role.id === 4) ){
                      this.roles.pop();
                    }
                });
                this.getStatus();
            }
        });
    }

    getStatus() {
        if (this.userStatusSubscriber) {
            this.userStatusSubscriber.unsubscribe();
        }
        this.userStatusSubscriber = this._admintService.userStatus().subscribe(
        (success: any) => {
            if (success.error_code === 'RC000') {
                success.statuses.forEach((status: any) => {
                    let i = new Item();
                    i.oj = status;
                    this.statuses.push(i);
                });
                this.getInfo(this.page);
            }
        });
    }

    getInfo(page: number, roles: any = [], statuses: any = [], search_text: string = null) {
        this.runRequest = false;
        this.errorMessage = null;
        this._loadingIndicatorService.start();
        if (this.userInfoSubscriber) {
            this.userInfoSubscriber.unsubscribe();
        }
        this.userInfoSubscriber = this._admintService.userInfo(page, 10, roles, statuses, search_text).subscribe(
        (success: any) => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
            if (success.error_code === 'RC000') {
                this.accountList.numberOfPages = success.last_page || 0;
                let accounts = success.users || [];
                accounts.map((user: any, i: any) => {
                    let full_name = this._utils.generateFullName(user.first_name, user.middle_name, user.last_name);
                    user.name_show = user.role_id === 2 ? user.account_name : full_name;

                    let id = '0000000000' + user.id.toString();
                    user.id_show = id.substr(id.length - 10);

                    for (let i = 0; i < this.roles.length; i++) {
                        if (user.role_id === this.roles[i].oj.id) {
                            user.role_id_show = this.roles[i].oj.name;
                        }
                    }

                    for (let i = 0; i < this.statuses.length; i++) {
                        if (user.status === this.statuses[i].oj.id) {
                            user.status_show = this.statuses[i].oj.name;
                        }
                    }

                    switch(user.role_id) {
                        case 3:
                            user.is_mapped = user.provider_id === null ? 'Unmap' : 'Mapped';
                            break;

                        case 4:
                            user.is_mapped = user.patient_id === null ? 'Unmap' : 'Mapped';
                            break;

                        default:
                            user.is_mapped = 'None';
                            break;
                    }
                });
                this.accountList.data = accounts;
            } else if (success.error_code === 'RC077') {
                this.init();
                this.errorMessage = success.error_messages;
            }
        },
        (error: any) => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
        },
        () => {
            this.runRequest = true;
            this._loadingIndicatorService.stop();
        });
    }

    onDataChange(e: any) {
        this.page = e['currentPage'];
        this.getInfo(this.page, this.rolesId, this.statusesId, this.searchText);
    }

    activeAccount(id: number, dataTable: RtnDataTableCoreComponent) {
        if (this.activateAccountSubscriber) {
            this.activateAccountSubscriber.unsubscribe();
        }
        this.activateAccountSubscriber = this._admintService.activeAccount({ id: id }).subscribe(
        (success: any) => {
            this.getInfo(this.page, this.rolesId, this.statusesId, this.searchText);
            dataTable.setPage(this.page, 10);
        });
    }

    deactiveAccount(id: number, dataTable: RtnDataTableCoreComponent) {
        if (this.deactivateAccountSubscriber) {
            this.deactivateAccountSubscriber.unsubscribe();
        }
        this.deactivateAccountSubscriber = this._admintService.deactiveAccount({ id: id }).subscribe(
        (success: any) => {
            this.getInfo(this.page, this.rolesId, this.statusesId, this.searchText);
            dataTable.setPage(this.page, 10);
        });
    }

    selectRoleClicked(e: any, item: any, dataTable: RtnDataTableCoreComponent) {
        if (jQuery('.select-box').hasClass('show')) {
            e.stopPropagation();
        }
        let count: number = 0;
        let rolesSelected: any = [];
        this.rolesId = [];
        for (let i = 0; i < this.roles.length; i++) {
            if (item.oj.id === this.roles[i].oj.id) {
                this.roles[i].isCheck = !this.roles[i].isCheck;
            }
            if (this.roles[i].isCheck) {
                rolesSelected.push(this.roles[i].oj.name);
                this.rolesId.push(this.roles[i].oj.id);
                count++;
            }
        }
        if (count === 0 || count === this.roles.length) {
            this.rolesSelected = this.defaultType;
        } else {
            this.rolesSelected = rolesSelected.join(', ');
        }
        if (this.page === 1) {
            this.getInfo(this.page, this.rolesId, this.statusesId, this.searchText);
        } else {
            dataTable.setPage(1, 10);
        }
    }

    selectStatusClicked(e: any, item: any, dataTable: RtnDataTableCoreComponent) {
        if (jQuery('.select-box').hasClass('show')) {
            e.stopPropagation();
        }
        let count: number = 0;
        let statusesSelected: any = [];
        this.statusesId = [];
        for (let i = 0; i < this.statuses.length; i++) {
            if (item.oj.id === this.statuses[i].oj.id) {
                this.statuses[i].isCheck = !this.statuses[i].isCheck;
            }
            if (this.statuses[i].isCheck) {
                statusesSelected.push(this.statuses[i].oj.name);
                this.statusesId.push(this.statuses[i].oj.id);
                count++;
            }
        }
        if (count === 0 || count === this.statuses.length) {
            this.statusesSelected = this.defaultStatus;
        } else {
            this.statusesSelected = statusesSelected.join(', ');
        }
        if (this.page === 1) {
            this.getInfo(this.page, this.rolesId, this.statusesId, this.searchText);
        } else {
            dataTable.setPage(1, 10);
        }
    }

    onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
        if (this.runRequest) {
            if (this.page === 1) {
                this.getInfo(this.page, this.rolesId, this.statusesId, this.searchText.trim());
            } else {
                dataTable.setPage(1, 10);
            }
        }
    }

}
