import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {extract} from '@app/core';
import {ShellComponent} from './shell.component';
// import {Shell} from '@app/shell/shell.service';
// import {NotificationListComponent} from '@app/notifications/notification-list/notification-list.component';
// import {NotificationDashboardComponent} from '@app/notifications/notification-dashboard/notification-dashboard.component';
// import {NumberWaittimeComponent} from '@app/wait-time/number-waittime/number-waittime.component';
// import {NotificationMassComponent} from '@app/notifications/notification-mass/notification-mass.component';

import {NotFoundComponent} from '@app/shared/not-found/not-found.component';


const routes: Routes = [{
  path: '',
  component: ShellComponent,
  children: [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {
      path: 'page-not-found',
      component: NotFoundComponent,
      data: {}
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ShellRoutingModule {
}
