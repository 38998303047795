import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
// import { DashboardService } from '../services/dashboard.service';
import {RestService as DashboardService} from '@app/core/rest.service';
// import { ROUTER_DIRECTIVES, Router } from 'angular2/router';
import {Router} from '@angular/router';
// import { Utils } from '../../../../shared/utils';
import {Utils} from '@app/shared/utils';
import {SystemInterval, SystemEvent, SystemData, SystemService} from '@app/shared/system';
// import { MODAL_DIRECTIVES, ModalComponent } from '../../../../shared/ng2-bs3-modal';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {DataTableDirectives, RtnDataTableCoreComponent} from '@app/shared/datatable';
import {Subscription} from 'rxjs';
import {AlertService} from '@app/shared/alert';
import {PatientsService} from '@app/patients/patients.service';
import * as moment from 'moment';

declare let jQuery: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  // declare arrayActiveModule
  arrayModule = new Array();
  listNotification: any;
  numberOfTop = 10;
  numberRecords = 0;
  timeSetting: any = [];
  disabledSms = false;
  savedSearchForOpeningData: any;
  numberSavedSearchForOpening = 0;
  notificationSetting: any = [];
  messageResponseSuccess = '';
  messageResponseError = '';
  

  getNotificationGlobalSettingSubscriber: Subscription;
  updateNotificationGlobalSettingSubscriber: Subscription;
  getNotificationListSubscriber: Subscription;
  getNotifySavedOpenAppointmentSettingSubscriber: Subscription;
  deleteSavedSearchForOpeningSubscriber: Subscription;

  constructor(
    private _dashboardService: PatientsService,
    private _systemService: SystemService,
    private _router: Router,
    private _utils: Utils,
    private _systemEvent: SystemEvent,
    public _systemData: SystemData,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService
  ) {
    this.timeSetting = [
      {'name': 'One day', 'value': '1'},
      {'name': 'Two days', 'value': '2'},
      {'name': 'Three days', 'value': '3'},
      {'name': 'Five days', 'value': '5'},
      {'name': 'One week', 'value': '7'}
    ];
  }

  ngOnInit() {
    if (this._utils.isPartialActiveStatus()) {
      this._router.navigate(['/patient', 'appointment', 'openings']);
    }
    // get active module array
    this.arrayModule = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
    //this.getNotificationList(this.numberOfTop);
    this.getNotificationSetting();
    this.getNotifySavedOpenAppointmentSetting();
  }

  ngAfterViewInit() {
    this._systemEvent.menu.emit('dashboard');
  }

  ngOnDestroy() {
    if (this.getNotificationListSubscriber) {
      this.getNotificationListSubscriber.unsubscribe();
    }
    if (this.getNotificationGlobalSettingSubscriber) {
      this.getNotificationGlobalSettingSubscriber.unsubscribe();
    }
    if (this.updateNotificationGlobalSettingSubscriber) {
      this.updateNotificationGlobalSettingSubscriber.unsubscribe();
    }
    if (this.getNotifySavedOpenAppointmentSettingSubscriber) {
      this.getNotifySavedOpenAppointmentSettingSubscriber.unsubscribe();
    }
    if (this.deleteSavedSearchForOpeningSubscriber) {
      this.deleteSavedSearchForOpeningSubscriber.unsubscribe();
    }
  }

  getNotificationList(limit: any) {
    if (this.getNotificationListSubscriber) {
      this.getNotificationListSubscriber.unsubscribe();
    }
    this.getNotificationListSubscriber = this._dashboardService.getTopNotificationList(limit).subscribe(
      (success: any) => {
        this.listNotification = [];
        this.numberRecords = success.total_records || 0;
        this.listNotification = this.listNotification.concat(
          this._utils.generateTimeAgoAndCredentials(success.notifications));
      },
      (error: any) => {
        // TODO
      }
    );
  }

  getNotificationSetting() {
    if (this.getNotificationGlobalSettingSubscriber) {
      this.getNotificationGlobalSettingSubscriber.unsubscribe();
    }
    this.getNotificationGlobalSettingSubscriber = this._dashboardService.getNotificationGlobalSetting().subscribe(
      (success: any) => {
        this.notificationSetting = success.notification_settings;
        this.notificationSetting.reminder_day = (this.notificationSetting.reminder_day !== 0)
          ? this.notificationSetting.reminder_day : 1;
        if (this.notificationSetting.mobile_number === '' || this.notificationSetting.mobile_number === null) {
          this.disabledSms = true;
          this.notificationSetting.is_sms = 0;
        }
      }
    );
  }

  getNotifySavedOpenAppointmentSetting() {
    if (this.getNotifySavedOpenAppointmentSettingSubscriber) {
      this.getNotifySavedOpenAppointmentSettingSubscriber.unsubscribe();
    }
    this.getNotifySavedOpenAppointmentSettingSubscriber
      = this._dashboardService.getNotifySavedOpenAppointmentSetting().subscribe(
      (success: any) => {
        this.savedSearchForOpeningData = success.saved_opening_settings || [];
        this.numberSavedSearchForOpening = success.total_records || 0;
        this.savedSearchForOpeningData.map((item: any, i: any) => {
          item.date = moment(item.start_date).format('MMMM D, YYYY') + ' - '
            + moment(item.end_date).format('MMMM D, YYYY');
          if (item.start_time === '00:00:00' && item.end_time === '23:59:59') {
            item.time = '';
          } else {
            item.time = this.fomartTimeShowInList(item.start_time) + ' - ' + this.fomartTimeShowInList(item.end_time);
          }
          if (item.distance) {
            item.distance = 'Within ' + item.distance + ' miles';
          }
          const specialties: any = [];
          item.specialties.map((s: any) => {
            specialties.push(s.name);
          });
          item.specialty = specialties.join(', ');
          /*item.insurance = '';
          if (item.self_pay) {
              item.insurance = 'No Insurance/Self Pay';
          } else {
              if (item.insurance_company_name && item.insurance_plan_name) {
                  item.insurance = item.insurance_company_name + ' - ' + item.insurance_plan_name;
              }
          }*/
        });
      }
    );
  }

  refreshData() {
    this._systemEvent.notification.emit('notification_getNumberOfUnreadNotifications');
    this.getNotificationList(this.numberOfTop);
  }

  updateNotificationSettingForm(form: any, data: any) {
    const _self = this;
    this.messageResponseSuccess = null;
    this.messageResponseError = null;
    if (this.updateNotificationGlobalSettingSubscriber) {
      this.updateNotificationGlobalSettingSubscriber.unsubscribe();
    }
    this.updateNotificationGlobalSettingSubscriber
      = this._dashboardService.updateNotificationGlobalSetting(data).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.messageResponseSuccess = success.error_messages;
        }
      },
      (error: any) => {
        if (error.json().error_code === 'RC087' || error.json().error_code === 'RC088') {
          this.messageResponseError = error.json().error_messages;
        }
      }
    );
  }

  showConfirmDeleteAlertService(id: any) {
    this._alertService.onClose = () => {
      const savedId = {'saved_id': id};
      this.deleteSavedSearchForOpeningSubscriber
        = this._dashboardService.deleteNotifySavedOpenAppointmentSetting(savedId).subscribe(
        (success: any) => {
          if (success.error_code === 'RC000') {
            const rowId = '#saved-data-' + id;
            jQuery(rowId).remove();
            this.numberSavedSearchForOpening--;
            this.deleteSavedSearchForOpening(id);
          }
        }
      );
    };
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.closeButtonLabel = 'Yes';
    this._alertService.showDismissButton = true;
    this._alertService.showCloseButton = true;
    this._alertService.title = 'Are you sure you want to delete this setting?';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  deleteSavedSearchForOpening(id: any) {
    this._alertService.onClose = () => {
      // TODO
    };
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.showDismissButton = false;
    this._alertService.showCloseButton = true;
    this._alertService.title = 'The setting is deleted successfully!';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  fomartTimeShowInList(time: string) {
    let value = '';
    if (time) {
      if (time === '00:00:00') {
        value = '00:00 AM';
      } else if (time === '00:30:00') {
        value = '00:30 AM';
      } else {
        value = this._utils.formatTimeString(time, 'LT');
      }
    }
    if (value.substr(1, 1) === ':') {
      value = '0' + value;
    }
    return value;
  }

  removeMessage() {
    this.messageResponseSuccess = null;
    this.messageResponseError = null;
  }

}
