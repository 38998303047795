<div class="book-appointment">
  <div class="book-appointment-page">
    <div class="content-page">
      <div class="text-header-title">APPOINTMENT</div>
      <div class="text-name">
        <span>{{ providerInfo.fullName }}</span><span *ngIf="providerInfo.credentials">,</span>
        <span>{{ providerInfo.credentials }}</span>
      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-md-3 col-sm-4 pl-0">Specialty</div>
          <div class="col-12 text-value col-md-9 col-sm-8 pl-0">{{ providerInfo.specialties }}</div>
        </div>

      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-md-3 col-sm-4 pl-0">Date</div>
          <div class="col-12 text-value col-md-9 col-sm-8 pl-0">{{ _utils.getDateTimeFormat(bookInfo.start_time) }}
          </div>
        </div>

      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-md-3 col-sm-4 pl-0">Address</div>
          <div class="col-12 text-value col-md-9 col-sm-8 pl-0">
            <div class="col-md-12 col-sm-12 px-0">
              {{ providerInfo.address }}
            </div>
            <div class="col-md-12 col-sm-12 px-0">
              {{ providerInfo.address2 }}
            </div>
            <div class="col-md-12 col-sm-12 px-0">
              <span>{{ providerInfo.city }}</span><span *ngIf="providerInfo.state_code !== null">,</span>
              <span>{{ providerInfo.state_code }}</span>
              <span>{{ providerInfo.zip }}</span>
            </div>
          </div>
        </div>

      </div>
      <div class="text_info" *ngIf="providerInfo.phone_mobile != '' && providerInfo.phone_mobile != null">
        <div class="row w-100">
          <div class="col-12 col-md-3 col-sm-4 pl-0">Provider Phone</div>
          <div class="col-12 text-value col-md-9 col-sm-8 pl-0">+{{ providerInfo.phone_mobile_country_code }}
            {{ providerInfo.phone_mobile }}</div>
        </div>

      </div>
      <div class="text_info">
        <div class="row w-100">
          <div class="col-12 col-md-3 col-sm-4 pl-0">Patient</div>
          <div class="col-12 text-value col-md-9 col-sm-8 pl-0">
            {{ patientInfo.first_name + ' ' + patientInfo.last_name }}</div>
        </div>

      </div>

      <form method="post" #form="ngForm" (ngSubmit)="submitBookAppointment(bookAppointment)"
        *ngIf="practice_open_notify_type == 1">
        <div class="text_info">
          <div class="row w-100">
            <div class="col-12 col-md-3 col-sm-4 pl-0 input-label-required">Apt. Type</div>
            <div class="col-12 text-value col-md-9 col-sm-8 pl-0">
              <div class="input-group select">
                <select class="form-control textbox-edit-form width-full" [(ngModel)]="bookAppointment.event_reason_id"
                  name="event_reason_id" ngControl="event_reason_id">
                  <option [value]="''" selected>Select Type</option>
                  <option *ngFor="let typeItem of aptTypes" [value]="typeItem.id">{{typeItem.label}}</option>
                </select>
                <div class="input-group-select-icon cursorPointer select-state">
                  <i class="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text_info">
          <div class="row w-100">
            <div class="col-12 col-md-3 col-sm-4 pl-0">Apt. Reason</div>
            <div class="col-12 text-value col-md-9 col-sm-8 pl-0">
              <textarea id="appointment_reason" name="appointment_reason"
                [(ngModel)]="bookAppointment.appointment_reason" class="form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="text_info">
          <div class="row w-100">
            <div class="col-12 col-md-3 col-sm-4 pl-0">&nbsp;</div>
            <div class="col-12 col-md-9 col-sm-8 pl-0">
              <div class="form-check d-flex pl-0">
                <div class="input-rtn">
                  <input class="checkbox check-single" type="checkbox" name="settingReminder" id="settingReminder"
                    [value]="bookAppointment.is_reminder" [(ngModel)]="bookAppointment.is_reminder" #settingReminder>
                  <label class="selectorExtreme margin-top-8" for="settingReminder"></label>
                </div>
                <div class="form-check-label ml-2 pt-1">
                  Yes, send me a reminder before my appointment (when enabled by Provider)
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text_info">
          <div class="row w-100">
            <div class="col-12 col-md-3 col-sm-4 pl-0">&nbsp;</div>
            <div class="col-12 col-md-9 col-sm-8 pl-0">
              <div class="input-group select select-time-style">
                <select class="form-control" [value]="bookAppointment.reminder_day"
                  [(ngModel)]="bookAppointment.reminder_day" [disabled]="!bookAppointment.is_reminder" #selectTime
                  name="select-reminder">
                  <option *ngFor="let time of timeSetting" [value]="time.value">{{time.name}}</option>
                </select>
                <div class="input-group-select-icon">
                  <i class="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text_info">
          <div class="row w-100">
            <div class="col-12 col-md-3 col-sm-4 pl-0">&nbsp;</div>
            <div class="col-12 col-md-9 col-sm-8 pl-0">
              <div class="form-check d-flex pl-0">
                <div class="input-rtn">
                  <input class="checkbox check-single" type="checkbox" name="settingWaitTime" id="settingWaitTime"
                    [value]="bookAppointment.add_followed" [(ngModel)]="bookAppointment.add_followed">
                  <label class="selectorExtreme margin-top-8" for="settingWaitTime"></label>
                </div>
                <div class="form-check-label ml-2 pt-1">Add this Provider to
                  my Followed Provider list
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="text_info1" *ngIf="waitlistinfo.schedule_id == 0">
            <rtn-patient-future-appointment [loadedpage]="loadedpage" [aptlists]="futureaptlists" [providerId]="0" [patientId]="0" (selecteRescheduleId)="selecteRescheduleId($event)" *ngIf="patientInfo.id"></rtn-patient-future-appointment>
          </div>
          <div class="row ">
            <button class="btn btn-rtn btn-180 text-uppercase margin-top-20" type="submit">BOOk APPOINTMENT</button>
          </div>
        <div class="apt-referal-notes">
          <p><strong>Appointment Note:</strong> <span>If a referral is needed for your visit, please obtain it before
              arriving. Assistance? Contact our office.</span></p>
        </div>
      </form>

      <div class="text_info"
        *ngIf="practice_open_notify_type == 2 && providerInfo.phone_mobile != '' && providerInfo.phone_mobile != null">
        <div class="row w-100">

          <div class="col-12 text-value col-md-12 col-sm-12 pl-0">For Booking appointment, Please call to office
            +{{ providerInfo.phone_mobile_country_code }} {{ providerInfo.phone_mobile }}</div>
        </div>

      </div>

    </div>
  </div>
</div>