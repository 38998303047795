import { Component, OnInit } from '@angular/core';
import { HomepageService } from '@app/home/home.service';
import { from, Subscription } from 'rxjs';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

@Component({
  selector: 'rtn-home-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  src = '../../../assets/img/homepage/newDesign/';
  public tenantname:string = '';

  getintouchSubscriber: Subscription;
  customerSupportModel: CustomerSupportClass = new CustomerSupportClass();
  requireMessageSupport: RequireMessageSupportClass = new RequireMessageSupportClass();
  errorMessageBook:string='';
  formSubmitted:boolean = false;

  constructor(
    private _homeService: HomepageService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) { }

  ngOnInit() {
    this.getSubDomain();
  }
  getSubDomain() {
    const fullDomain = window.location.host;
    const path = fullDomain.split('.');
    if (path[1] == 'dev') {
        this.tenantname = path[0] + '.' + path[1];
    } else {
      this.tenantname = path[0];
    }
  }  
  getInTouch() {
    this.errorMessageBook = null;
    this._loadingIndicatorService.start();
    if (this.getintouchSubscriber) {
      this.getintouchSubscriber.unsubscribe();
    }
    this.formSubmitted = true;
    this.getintouchSubscriber = this._homeService.getInTouch({
      support_name: this.customerSupportModel.support_name,
      support_email: this.customerSupportModel.support_email,
      support_subject: this.customerSupportModel.support_subject,
      support_message: this.customerSupportModel.support_message
    }).subscribe((res: any) => {
      this.formSubmitted = false;
        this._loadingIndicatorService.stop();
      if (res.error_code === 'RC000') {
        this.customerSupportModel = new CustomerSupportClass();
        this.requireMessageSupport = new RequireMessageSupportClass();

        this.errorMessageBook = res.error_messages;
      } else if (res.error_code === 'RC020') {
        this.requireMessageSupport = res.error_fields;
      }      
    }, (error: any) => {
      this.formSubmitted = false;
      this._loadingIndicatorService.stop();
      const e = error.error;
      if (e.error_code === 'RC002' || e.error_code === 'RC061' || e.error_code === 'RC119') {
        this.errorMessageBook = e.error_messages;
      }
    }, () => {
      this._loadingIndicatorService.stop();
      this.formSubmitted = false;
    });
  }
  ngOnDestroy() {
    if (this.getintouchSubscriber) {
      this.getintouchSubscriber.unsubscribe();
    }
  }
  onKeyUp(){
    if (this.customerSupportModel.support_name != ''){
      this.requireMessageSupport.support_name = '';

    }
    if (this.customerSupportModel.support_email != ''){
      this.requireMessageSupport.support_email = '';

    }
    if (this.customerSupportModel.support_subject != ''){
      this.requireMessageSupport.support_subject = '';

    }
    if (this.customerSupportModel.support_message != ''){
      this.requireMessageSupport.support_message = '';

    }
  }
}
export class CustomerSupportClass {
  support_name:string = '';
  support_email:string = '';
  support_subject:string = '';
  support_message:string = '';
}

export class RequireMessageSupportClass {
  support_name:string = '';
  support_email:string = '';
  support_subject:string = '';
  support_message:string = '';
}