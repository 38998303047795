<modal #AppointmentNotification_Modals class="appointment-notification">
  <div class="form-content">
    <div class="form-header">Notification's of {{ eventEntry?.data?.patientName }}</div>
    <div class="form-info">
      
      <div class="row">              

        <div class="col-md-12 no-padding-left-right search-box listview panel-table">
          <table class="table table-bordered">
              <thead class="account-thead">
                <tr>
                  <th class="account-thead-text col-email">S.No</th>
                  <th class="account-thead-text col-full-name">Type</th>
                  <th class="account-thead-text col-status">Mode</th>
                  <th class="account-thead-text col-status">Status</th>
                  <th class="account-thead-text col-status">Notes</th>
                  <th class="account-thead-text col-action">Time</th>
                </tr>
              </thead>
              <tbody class="account-tbody">
                  <tr *ngFor="let notification of notificationLists; let i = index">
                        <td class="account-email">{{ notification.sno}}</td>
                        <td class="account-full-name">{{ notification.type }}</td>                            
                        <td class="account-full-name">{{ notification.methods }}</td>
                        <td class="account-full-name">{{ notification.status }}</td>
                        <td class="account-full-name">
                          <span>{{ notification.reason }}</span> | 
                          <span>{{ notification.notes }}</span>
                        </td>
                        <td class="account-full-name">{{ notification.created_at }}</td>
                  </tr>                     
              </tbody>
          </table>
        </div>
      </div>
  </div>
  <div class="button">
    <button type="button" class="btn btn-rtn btn-90 btn-cancel1" data-dismiss="modal" (click)="dismiss()">Cancel</button>      
  </div>
  </div> 
</modal>
