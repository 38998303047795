
    <modal style="z-index: 2017" #modal
           [tabindex]="alert$.tabindex"
           [size]="alert$.size"
           [animation]="alert$.animation"
           [keyboard]="alert$.keyboard"
           [backdrop]="alert$.backdrop"
           (onOpen)="alert$.onOpen($event)"
           (onDismiss)="alert$.onDismiss($event); _lockOpen = false;"
           (onClose)="alert$.onClose($event); _lockOpen = false;">
      <div *ngIf="alert$.title" class="modal-header">
        <h4 class="modal-title" [innerHTML]="alert$.title"></h4>
      </div>
      <div *ngIf="alert$.message" class="modal-body" [innerHTML]="alert$.message"></div>
      <div *ngIf="alert$.showCustomInputHtml" class="col-12 col-md-6 form-group">
        <label style="font-weight: 700;" for="input_value" class="input-label">{{alert$.labelCustomInputHtml}}</label>
        <input class="form-control ng-pristine ng-invalid ng-touched" type="alert$.inputTypeHtml" name="input_value" [(ngModel)]="alert$.inputValueInputHtml">
      </div>
      <div *ngIf="alert$.showDefaultButtons" class="modal-footer">
        <button *ngIf="alert$.showDismissButton" type="button" class="btn btn-rtn-inv" data-dismiss="modal"
                (click)="modal.dismiss()" [innerHTML]="alert$.dismissButtonLabel" autofocus></button>
        <button *ngIf="alert$.showCloseButton" type="button" class="btn btn-rtn" (click)="modal.close()"
                [innerHTML]="alert$.closeButtonLabel"></button>
      </div>
    </modal>
  