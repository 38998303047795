<div class="sidebarAndContent">
  <div class="mt-3">
    <h3 class="title">Cancel / Reschedule Statistics</h3>
    <div class="col-12 px-0">
      <div class="row col-12 px-0">
        <div class="row col-12 border border-#ddd pt-3 no-print">
          <!-- <div class="col-2 col-xs-2 no-padding-right display-flex line-height-30"></div> -->
          <!-- Select pratice-->
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Practice:</div>
            <div
              class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0 width_70">
              Practice:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getPractice">
              <select #select2PracticeDirective2="select2-practice2" select2-practice2 class="form-control"
                [value]="dataSearchChart.practice" [maximumSelectionLength]="0" [closeOnSelect]="true" [multiple]="false"
                [tags]="false" id="select2Practice" (OnSelected)="onChangePractice()">
                <option *ngFor="let practice of practicesList; let i = index" [id]="'practice' + practice.id"
                  [value]="practice.id">{{ practice.name }}</option>
              </select>
              <div class="message-error">{{ errorResponse.practice }}</div>
              <div class="red-alert d-none d-lg-block error_message"></div>
              <div
                class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
              </div>
            </div>
          </div>
        <!-- Select provider-->
        <div class="px-0 row col-lg-6 mb-2 pl_100">
          <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Provider:</div>
          <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">Provider:</div>
          <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
            <select
              #Select2Provider3Directive="select2-provider3"
              select2-provider3 
              class="form-control"
              [value]="dataSearchChart.provider"
              [closeOnSelect]="true"
              [maximumSelectionLength]="0"
              [multiple]="false"
              id="select2Provider"
              placeholder="Select provider"
              [tags]="false"
              (OnSelected)="onChangeProvider()"
              >
              <option value=""></option>
              <option *ngFor="let provider of providersList; let i = index" [id]="'provider' + provider.id" [value]="provider.id" >
                {{ provider.full_name }}
              </option>
            </select>
            <div class="message-error">{{ errorResponse.provider }}</div>
            <div class="red-alert d-none d-lg-block error_message"></div>
            <div class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message"></div>
          </div>
        </div>
        <!--date from-->
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="pl-0 d-none d-lg-block find-appointment-line-title width_70 pt-1">From:</div>
            <div
              class="width_70 text-left col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              From:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
              <div class="input-group">
                <input type="text" #inputDateFrom readonly [value]="dataSearchChart['dateFrom']"
                  class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateFrom']"
                  aria-describedby="basic-addon2">
                <div class="input-group-append" #clickDateFrom>
                  <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                </div>
              </div>
              <div class="message-error">{{ errorResponse.dateFrom }}</div>
            </div>
          </div>
  
          <!-- <div class="message-error">{{ errorResponse.start_date }}</div> -->
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">To:</div>
            <div
              class="width_70 col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              To:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
              <div class="input-group">
                <input type="text" #inputDateTo readonly [value]="dataSearchChart['dateTo']"
                  class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateTo']"
                  aria-describedby="basic-addon2">
                <div class="input-group-append" #clickDateTo>
                  <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                </div>
              </div>
              <div class="message-error d-xl-none d-lg-none">{{ errorResponse.dateTo }}</div>
            </div>
          </div>
          
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Location:</div>
            <div
              class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              Location:</div>
            <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
              <select
                  #Select2AptlocationDirective="select2-aptlocation"
                  select2-aptlocation 
                  class="form-control"
                  [value]="dataSearchChart.location_id"
                  [closeOnSelect]="true"
                  [multiple]="false"
                  id="select2aptlocation"
                  placeholder="Select Location"
                  [tags]="false"
                  [allowClear]="true"
                  >
                  <option value=""></option>
                  <option *ngFor="let locitem of locationList; let i = index" [id]="'location' + locitem.id" [value]="locitem.id" >
                    {{ locitem.location_name }}
                  </option>
              </select>
              <div class="message-error"></div>
              <div class="red-alert d-none d-lg-block error_message"></div>
              <div
                class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
              </div>
            </div>
          </div>
          <div class="ml-3 px-0 row col-lg-10 mb-2 float-custom">
            <div class="btn-90" *ngIf="data_chart != undefined && data_chart.length > 0">
              <button id='printDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1" type="button" (click)="printDataChart()">PRINT</button>
            </div>
            
            <div class="btn-90 ml-2" *ngIf="data_chart != undefined && data_chart.length > 0">
              <button id='exportDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1" type="button" (click)="export(1)" >EXPORT</button>
            </div>
            <div class="ml-2" *ngIf="skippeddata_chart != undefined && skippeddata_chart.length > 0" >
              <button id='exportDataChart' class="container btn btn-rtn text-uppercase px-1" type="button" (click)="export(0)" >EXPORT Skipped Apt.</button>
            </div>
            <div class="btn-90 ml-2">
              <button id='showDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1"
                type="submit">SHOW</button>
            </div>
          </div>
  
  
          <div class="no-padding-left row col-lg-12 mb-2 inputDateFrom">
            <div class="red-alert d-lg-block d-md-none d-sm-none d-none error_message"></div>
          </div>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="d-none d-sm-block d-md-block d-lg-block d-xl-block col-sm-2 col-md-3 col-lg-2"></div>
        <div class="col-12 col-sm-8 col-md-6 col-lg-7 col-xl-5 max_height" style="display: block">
          <canvas mdbChart [chartType]="chartType" [datasets]="chartDatasets" [labels]="chartLabels"
            [colors]="chartColors" [options]="chartOptions" [legend]="true" >
          </canvas>
        </div>
      </div>
      <div class="row col-12 mt-5">        
          <div _ngcontent-c4="" class="col-12 col-sm-8 col-md-6 col-lg-7 col-xl-5">
              <label style="font-weight: 700; font-size: medium;">Skipped Appointment - Cancel / Reschedule Statistics</label>
          </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="d-none d-sm-block d-md-block d-lg-block d-xl-block col-sm-2 col-md-3 col-lg-2"></div>
        <div class="col-12 col-sm-8 col-md-6 col-lg-7 col-xl-5 max_height" style="display: block">
          <canvas mdbChart [chartType]="chartType" [datasets]="chartSkippedDatasets" [labels]="chartLabels"
            [colors]="chartColors" [options]="chartOptions" [legend]="true" >
          </canvas>
        </div>
      </div>
    </div>
  </div>
  </div>