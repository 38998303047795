import {Injectable} from '@angular/core';
import {Interval} from '@app/shared/models/interval';

@Injectable({
  providedIn: 'root'
})
export class SystemInterval {
  header_fetchNotification: any = new Interval();
  header_fetchOnlineMode: any = new Interval();
  notification_fetchNotification: any = new Interval();
  appointment_fetchWaitTimePatient: any = new Interval();
  appointment_fetchWaitTimeProvider: any = new Interval();
  timeline_fetchAppointment: any = new Interval();
  appointment_setWaitTimePatient: any = new Interval();

  system_fetchCurrentTime: any = new Interval();

  constructor() {
    // Set duration (ms) for each interval
    this.header_fetchNotification.duration = 3 * 10 * 1000;
    this.header_fetchOnlineMode.duration = 3 * 10 * 1000;
    this.notification_fetchNotification.duration = 1 * 60 * 1000;
    this.appointment_fetchWaitTimePatient.duration = 1 * 60 * 1000;
    this.appointment_fetchWaitTimeProvider.duration = 3 * 20 * 1000;
    this.timeline_fetchAppointment.duration = 3 * 60 * 1000;
    this.appointment_setWaitTimePatient.duration = 1 * 30 * 1000;

    // System Clock
    this.system_fetchCurrentTime.duration = 1 * 1000;
  }

  clearSystemInterval(intervalObj: Interval) {
    clearInterval(intervalObj.interval);
  }

  clearAllSystemInterval() {
    this.clearSystemInterval(this.header_fetchNotification);
    this.clearSystemInterval(this.header_fetchOnlineMode);
    this.clearSystemInterval(this.notification_fetchNotification);
    this.clearSystemInterval(this.appointment_fetchWaitTimePatient);
    this.clearSystemInterval(this.appointment_fetchWaitTimeProvider);
    this.clearSystemInterval(this.timeline_fetchAppointment);
    this.clearSystemInterval(this.appointment_setWaitTimePatient);
  }
}
