import { Component, Input } from '@angular/core';
import { RtnDataTableCoreComponent, AsyncParams } from './core';

@Component({
    selector: 'rtn-table-sorter',
    template: `
        <a (click)="sort()" class="text-nowrap">
            <ng-content></ng-content>
            <span *ngIf="isSortedByMeAsc" class="glyphicon glyphicon-triangle-top" aria-hidden="true"></span>
            <span *ngIf="isSortedByMeDesc" class="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span>
        </a>`
})

export class RtnDataTableSorterComponent {
    @Input() private by: string;

    public isSortedByMeAsc: boolean = false;
    public isSortedByMeDesc: boolean = false;

    public constructor(public dataTable: RtnDataTableCoreComponent) {
        this.dataTable.onSortChange.subscribe((event: AsyncParams) => {
            this.isSortedByMeAsc = (event.sortField === this.by && event.sortOrder === 'asc');
            this.isSortedByMeDesc = !this.isSortedByMeAsc;
        });
    }

    public sort() {
        this.dataTable.setSort(this.by, this.isSortedByMeAsc ? 'desc' : 'asc');
    }
}
