<nav class="navbar navbar-expand-lg pl-0 pr-0 pt-0">
    <a class="d-none d-lg-block navbar-header pt-4">
        <a class="navbar-brand" href="https://www.realtimeneighborhood.com/">
            <img src="/assets/img/logo.png" />
        </a>
    </a>
    <a class="d-none d-sm-block d-lg-none navbar-header">
        <a class="navbar-brand" href="https://www.realtimeneighborhood.com/">
            <img src="/assets/img/logo.png" />
        </a>
    </a>
    <a class="d-block d-sm-none navbar-header mx-auto">
        <a class="navbar-brand" style="border-right: none" href="https://www.realtimeneighborhood.com/">
            <img src="/assets/img/logo.png" />
        </a>
    </a>


    <div class="provider-header-navigator navbar_collapse collapse d-none d-sm-block pt-2">
        <ul class="navbar-nav title-menu provider-header-navigator-title menu-flex-row">
            <li class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'Appointment'], _router)"
                *ngIf="isProvider && module[5]">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'appointment']"
                    (click)="onReload('Appointment')">
                    <span class="d-none d-lg-block">APPOINTMENTS</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-calendar-check-o header-icon"></span>
                    </span>
                </a>
            </li>
            <li class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'Document'], _router)"
                *ngIf="isProvider && module[7]">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'Document']" (click)="onReload('Document')">
                    <span class="d-none d-lg-block">DOCUMENTS</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-files-o header-icon"></span>
                    </span>
                </a>
            </li>
            <li class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'Practice'], _router)"
                *ngIf="isSuperUser">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'practice']">
                    <span class="d-none d-lg-block">PRACTICE MANAGEMENT</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-files-o header-icon"></span>
                    </span>
                </a>
            </li>
            <li class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'ManageUser'], _router)"
                *ngIf="!isProvider">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'user']">
                    <span class="d-none d-lg-block">MANAGING USERS</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-files-o header-icon"></span>
                    </span>
                </a>
            </li>
            <li class="nav-item" [class.active]="_systemService.navActivated(['/Provider', 'ListProvider'], _router)"
                *ngIf="isSuperUser && module[3]">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'list']">
                    <span class="d-none d-lg-block">PROVIDERS</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-files-o header-icon"></span>
                    </span>
                </a>
            </li>
            <li *ngIf="module[4]" class="nav-item"
                [class.active]="_systemService.navActivated(['/Provider', 'Patient'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'patient']" (click)="onReload('Patient')">
                    <span class="d-none d-lg-block">PATIENTS</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-user-circle header-icon"></span>
                    </span>
                </a>
            </li>
           <li *ngIf="isSuperUser && module[9]" class="nav-item"
                [class.active]="_systemService.navActivated(['/master-schedules'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/master-schedules']">
                    <span class="d-none d-lg-block">MASTER SCHEDULES</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-files-o header-icon"></span>
                    </span>
                </a>
            </li>
            <li *ngIf="isSuperUser" class="nav-item"
                [class.active]="_systemService.navActivated(['/apttypes/list'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/apttypes/list']">
                    <span class="d-none d-lg-block">APT. TYPES</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-files-o header-icon"></span>
                    </span>
                </a>
            </li>
            <li *ngIf="!isLock && isProvider" class="nav-item"
                [class.active]="_systemService.navActivated(['/Provider', 'wait-list'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'wait-list']"
                    (click)="onReload('wait-list')">
                    <span class="d-none d-lg-block">WAIT LIST</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-list header-icon"></span>
                    </span>
                </a>
            </li>
            <li *ngIf="!isLock && isProvider" class="nav-item"
                [class.active]="_systemService.navActivated(['/Provider', 'dataLog'], _router)">
                <a class="show-focus nav-link" [routerLink]="['/provider', 'statistical-appointment-by-types']"
                    (click)="onReload('statistical-appointment-by-types')">
                    <span class="d-none d-lg-block">DATA LOG</span>
                    <span class="d-block d-lg-none">
                        <span class="fa fa-list header-icon"></span>
                    </span>
                </a>
            </li>
        </ul>
        
    </div>
    <ul class="nav navbar-nav navbar-right profile-menu provider-header-navigator-profile">

        <!-- Online/Offline status -->
        <!-- <li class="status">
                <a class="no-focus" aria-haspopup="true" aria-expanded="false">
                    <div class="online cursorDefault" *ngIf="_systemData.isOnline">
                        <span class="fr-font fr-connectivity header-icon" aria-hidden="true"></span>
                        <span class="hidden-xs hidden-sm">Online</span>
                    </div>
                    <div class="offline cursorDefault" *ngIf="!_systemData.isOnline">
                        <span class="fr-font fr-connectivity header-icon" aria-hidden="true"></span>
                        <span class="hidden-xs hidden-sm">Offline</span>
                    </div>
                </a>
            </li> -->

        <!-- User menu dropdown -->
        <li class="dropdown show">
            <a href="#" class="btn d-none d-lg-block dropdown-toggle hover-bottom-border no-focus"
                data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <i class="pull-right fa fa-caret-down"></i>
                <i class="fa faalign-left fa-user menu-user"></i>
                <span class="pull-left text-overflow-ellipsis"
                    *ngIf="isManagingMode">{{extractData.userFullName + ' : ' + _systemData.localStorage.getItem('userFullName')}}</span>
                <span class="pull-left text-overflow-ellipsis"
                    *ngIf="!isManagingMode">{{_systemData.localStorage.getItem('userFullName')}}</span>
            </a>
            <a href="#" class="d-block d-lg-none dropdown-toggle hover-bottom-border no-focus padding_18"
                data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <span class="fa fa-bars header-icon font_size_120 pl_6"></span>
            </a>
            <ul class="dropdown-menu provider-header-navigator-profile-menu custom_menu">
                <li class="height_36 d-block d-sm-none" *ngIf="isProvider && module[5]">
                    <a class="cursorPointer dropdown-item height_36" [routerLink]="['/provider', 'preferences']">
                        <i class="fa fa-calendar-check-o"></i>APPOINTMENTS
                    </a>
                </li>
                <li class="height_36 d-block d-sm-none" *ngIf="isProvider && module[7]">
                    <a class="cursorPointer dropdown-item height_36" [routerLink]="['/provider', 'preferences']">
                        <i class="fa fa-files-o"></i>DOCUMENTS
                    </a>
                </li>
                <li class="height_36 d-block d-sm-none" *ngIf="isProvider && module[4]">
                    <a class="cursorPointer dropdown-item height_36" [routerLink]="['/provider', 'preferences']">
                        <i class="fa fa-user-circle"></i>PATIENTS
                    </a>
                </li>
                <li class="height_36 d-block d-sm-none" *ngIf="isProvider">
                    <a class="cursorPointer dropdown-item height_36" [routerLink]="['/provider', 'preferences']">
                        <i class="fa fa-list"></i>WAIT LIST
                    </a>
                </li>

                <li class="height_36" *ngIf="isProvider">
                    <a [routerLink]="['/provider', 'profile']" class="cursorPointer dropdown-item height_36">
                        <i class="fa faalign-left fa-user"></i>My Profile
                    </a>
                </li>
                <li class="height_36" *ngIf="!isProvider">
                    <a [routerLink]="['/provider', 'user', 'edit']" class="cursorPointer dropdown-item height_36">
                        <i class="fa faalign-left fa-user"></i>My Profile
                    </a>
                </li>
                <li class="height_36" *ngIf="isProvider && module[4]">
                    <a class="cursorPointer dropdown-item height_36" (click)="patientNewSignUpDirective.open()">
                        <i class="fa faalign-left fa-user-plus"></i>Sign Up New Patient
                    </a>
                </li>
                <li class="height_36" *ngIf="isProvider">
                    <a class="cursorPointer dropdown-item height_36" [routerLink]="['/provider', 'preferences']">
                        <i class="fa faalign-left fa-gear"></i>Patient Preferences
                    </a>
                </li>
                <li class="height_36" *ngIf="isProvider && module[1]">
                    <a (click)="notificationMass()" class="cursorPointer dropdown-item height_36">
                        <i class="fa faalign-left fa-send"></i>Mass Notifications
                    </a>
                </li>
                <li class="height_36" *ngIf="isProvider">
                    <a (click)="notificationMassPatient()" class="cursorPointer dropdown-item height_36">
                        <i class="fa faalign-left fa-send"></i>Send to
                    </a>
                </li>
                <li>
                    <a (click)="changePassword()" class="cursorPointer dropdown-item height_36">
                        <i class="fa faalign-left fa-key"></i>Change password
                    </a>
                </li>
                <li>
                    <a (click)="logoutDirective.open()" class="cursorPointer dropdown-item height_36"
                        *ngIf="!isManagingMode">
                        <i class="fa faalign-left fa-sign-out"></i>Log Out
                    </a>
                    <a (click)="logoutManaging()" class="cursorPointer dropdown-item height_36"
                        *ngIf="isManagingMode">
                        <i class="fa faalign-left fa-sign-out"></i>Log Out
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</nav>
<rtn-modals-general-logout></rtn-modals-general-logout>
<app-notification-mass></app-notification-mass>
<app-notification-mass-patient></app-notification-mass-patient>
<app-rtn-modals-patient-new-sign-up></app-rtn-modals-patient-new-sign-up>