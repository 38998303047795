import {Directive, ElementRef} from '@angular/core';
import {ModalComponent} from '@app/shared/modal/modal.component';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective {
  constructor(private el: ElementRef, private modal: ModalComponent) {
    if (modal !== null) {
      this.modal.onOpen.subscribe(() => {
        this.el.nativeElement.focus();
      });
    }
  }
}
