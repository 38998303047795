import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';

import {AlertService} from '@app/shared/alert';
import {Event} from '@app/appointments/timeline/timeline.component';
import {Subscription} from 'rxjs';
import {Utils} from '@app/shared/utils';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

import * as moment from 'moment';
import {RestService} from '@app/core/rest.service';
import {AppointmentsService} from '@app/appointments/appointments.service';
//import { ProvidersService } from '@app/providers/providers.service';
import { DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent } from '@app/shared/datatable';

declare let jQuery: any;

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);


@Component({
  moduleId: module.id,
  selector: 'rtn-modals-appointment-notification',
  templateUrl: './appointment-notification.component.html',
  styleUrls: ['./appointment-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentNotificationComponent implements OnDestroy {
  
  notificationAppointmentSubscriber: Subscription;

  @ViewChild('AppointmentNotification_Modals') modal: ModalComponent;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();

  eventEntry: Event;
  notificationLists:any = [];
  constructor(
    private Service: AppointmentsService,
    //private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    
  ) {
    
  }

  open() {
    this.modal.open();
    this.setInitial();
  }

  close() {
    this.modal.close();
  }
  dismiss(){
    this.modal.close();
  }
  setInitial() {
    const e = this.eventEntry;
    this.notificationLists = [];
    if (this.notificationAppointmentSubscriber) {
      this.notificationAppointmentSubscriber.unsubscribe();
    }
    //this._loadingIndicatorService.start();
    console.log(this.eventEntry);

    this.notificationAppointmentSubscriber = this.Service.fetchAppointmentNotification(e.data.id).subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          if (success.notificationLists !== null) {
            this.notificationLists = success.notificationLists;
            console.log(this.notificationLists);
          }
        }, (error: any) => {
          this._loadingIndicatorService.stop();
        }

      );
  }

  ngOnDestroy() {
    if (this.notificationAppointmentSubscriber) {
      this.notificationAppointmentSubscriber.unsubscribe();
    }
  }
}
