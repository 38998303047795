<div class="row_30 col-11 col-md-7 col-lg-6">
  <div *ngIf="!comfirmInfo.result" class="wrapper ">
    <div class="child">
      <h4 *ngIf="clicked">Thank you for confirmation</h4>
      <h4 *ngIf="!clicked"> Click this button when you arrive</h4>
      <a *ngIf="showbackbutton" class="btn btn-rtn-inv text-uppercase btn-90 px-1 mt-2" [routerLink]="['/patient', 'dashboard']">BACK</a>
      <button *ngIf="!clicked" (click)="updateArrive()" class="container btn btn-rtn text-uppercase btn-90 px-1 mt-2" type="submit">CONFIRM</button>
    </div>
  </div>
  <div *ngIf="comfirmInfo.result" class="wrapper">
    <div class="child" *ngFor="let item of comfirmInfo.result">
      <h4>{{item}}</h4>
    </div>
    <div class="child" *ngIf="showbackbutton">
      <a class="container btn btn-rtn-inv text-uppercase btn-90 px-1 mt-2" [routerLink]="['/patient', 'dashboard']">BACK</a>
    </div>
  </div>
</div>
