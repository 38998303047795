import {Injectable, Inject, OnDestroy} from '@angular/core';
import {Observable, Subscription, Subscriber} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
// import { isPresent } from 'angular2/src/facade/lang';
import {Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import * as $ from 'jquery';
// import {SystemRest} from '@app/shared/system/system-rest';
import {SystemData} from '@app/shared/system/system-data';
import {SystemEvent} from '@app/shared/system/system-event';
import {SystemInterval} from '@app/shared/system/system-interval';
import * as moment from 'moment';
import {RestService} from '@app/core/rest.service';
import {AuthService} from '@app/core/auth.service';
import * as CryptoJS from 'crypto-js';
import {environment} from '@env/environment';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Injectable({
  providedIn: 'root'
})
export class SystemService implements OnDestroy {

  getStatesSubscriber: Subscription;
  getSpecialtiesSubscriber: Subscription;
  getInsurancesSubscriber: Subscription;
  getProfileSubscriber: Subscription;
  getOnlineStatusSubscriber: Subscription;
  systemEventListener: Subscription;
  activeModuleSubcriber: Subscription;
  runRequest: any = true;
  param: any;
  getLocationsSubscriber: Subscription;

  constructor(
    private service: RestService,
    // private _systemRest: SystemRest,
    private _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemInterval: SystemInterval,
    private _router: Router,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    private _authService: AuthService,
  ) {

    // System Clock
    this._systemData.currentTime = moment();
    this._systemData.currentTimeDisplay24hFormat = this._utils.formatTime24h(this._systemData.currentTime
      .get('hour') * 60 + this._systemData.currentTime.get('minute'));
    this._systemInterval.system_fetchCurrentTime.interval = setInterval(() => {
      this._systemData.currentTime.add(this._systemInterval.system_fetchCurrentTime.duration, 'ms');
      this._systemData.currentTimeDisplay24hFormat = this._utils.formatTime24h(this._systemData.currentTime
        .get('hour') * 60 + this._systemData.currentTime.get('minute'));
    }, this._systemInterval.system_fetchCurrentTime.duration);

    // System Event Listener
    this.systemEventListener = this._systemEvent.system.subscribe((action: string) => {
      switch (action) {
        case 'system_LogOut':
          this.logOut();
          break;
        case 'system_RedirectToLogin':
          this._router.navigate(['/home', 'login']);
          break;
        case 'system_ReloadPage':
          this.reloadPage();
          break;
        case 'system_ClearTimeInterval':
          this._systemInterval.clearAllSystemInterval();
          break;
        case 'system_UpdateMode':
          this.checkOnlineStatus();
          this.reloadPage();
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    if (this.getStatesSubscriber) {
      this.getStatesSubscriber.unsubscribe();
    }
    if (this.getOnlineStatusSubscriber) {
      this.getOnlineStatusSubscriber.unsubscribe();
    }
    if (this.getSpecialtiesSubscriber) {
      this.getSpecialtiesSubscriber.unsubscribe();
    }
    if (this.getInsurancesSubscriber) {
      this.getInsurancesSubscriber.unsubscribe();
    }
    if (this.getProfileSubscriber) {
      this.getProfileSubscriber.unsubscribe();
    }
    if (this.systemEventListener) {
      this.systemEventListener.unsubscribe();
    }
    if (this.getLocationsSubscriber) {
      this.getLocationsSubscriber.unsubscribe();
    }    
  }

  checkOnlineStatus() {
    if (this._router.url === 'home/page-not-found') {
      return;
    }
    if (this.getOnlineStatusSubscriber) {
      this.getOnlineStatusSubscriber.unsubscribe();
    }
    this.getOnlineStatusSubscriber = this.service.getMode().subscribe(
      (success: any) => {
        this._systemData.isOnline = <boolean>success.is_online;
        // return this._systemData.isOnline;
      }
    );
    // return true;
  }

  moduleDecrypt(encrypted: any, key: any) {
    let decrypt = CryptoJS.AES.decrypt(
      encrypted, CryptoJS.enc.Utf8.parse(key), {
        keySize: 256,
        iv: CryptoJS.enc.Utf8.parse(environment.iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    let obDecrypt = JSON.parse(decrypt);
    let arrayModule = [];
    for (let index = 0; index < obDecrypt.length; ++index) {
      arrayModule[obDecrypt[index].id] = Boolean(parseInt(obDecrypt[index].active_status, 10));
    }
    return arrayModule;
  }

  getStates() {
    if (this._systemData.states.length === 0) {
      this._loadingIndicatorService.start();
      if (this.getStatesSubscriber) {
        this.getStatesSubscriber.unsubscribe();
        this._loadingIndicatorService.stop();
      }
      this.getStatesSubscriber = this.service.getStates().subscribe(
        (success: any) => {
          this._systemData.states = success.states;
          this._loadingIndicatorService.stop();
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        },
        () => {
          this._loadingIndicatorService.stop();
        }
      );
    }
  }

  getSpecialties() {
    if (this._systemData.specialties.length === 0) {
      let done = false;
      this._loadingIndicatorService.start();
      if (this.getSpecialtiesSubscriber) {
        this.getSpecialtiesSubscriber.unsubscribe();
        this._loadingIndicatorService.stop();
      }
      this.getSpecialtiesSubscriber = this.service.getSpecialties().subscribe(
        (success: any) => {
          this._systemData.specialties = success.specialties;
          done = true;
          this._loadingIndicatorService.stop();
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        },
        () => {
          this._systemEvent.specialty.emit(done);
          this._loadingIndicatorService.stop();
        }
      );
    } else {
      this._systemEvent.specialty.emit(true);
    }
  }

  getInsuranceList() {
    if (this._systemData.insurances.length === 0) {
      let done = false;
      this._loadingIndicatorService.start();
      if (this.getInsurancesSubscriber) {
        this.getInsurancesSubscriber.unsubscribe();
        this._loadingIndicatorService.stop();
      }
      return this.getInsurancesSubscriber = this.service.getInsuranceList().subscribe(
        (success: any) => {
          const noInsuranceOption = {
            'id': '-1',
            'name': 'No Insurance/Self Pay'
          };
          this._systemData.insurances = success.insurances;
          this._systemData.insurances.unshift(noInsuranceOption);
          done = true;
          this._loadingIndicatorService.stop();
        },
        (error: any) => {
          //console.log('eerorrrr');
          this._loadingIndicatorService.stop();
          // do nothing
        },
        () => {
          this._systemEvent.insurances.emit(done);
          this._loadingIndicatorService.stop();
        }
      );
    } else {
      this._systemEvent.insurances.emit(true);
      return 'done';
    }
  }

  // getProfile(id: number = undefined) {
  getProfile(id: number | undefined) {
    if (!this._systemData.profile) {
      let done = false;
      this._loadingIndicatorService.start();
      if (this.getProfileSubscriber) {
        this.getProfileSubscriber.unsubscribe();
        this._loadingIndicatorService.stop();
      }
      if (localStorage.getItem('user_role_id') === '3') {
        // Provider
        this.getProfileSubscriber = this.service.getProviderProfile().subscribe(
          (success: any) => {
            this._loadingIndicatorService.stop();
            this._systemData.profile = success.user;
            done = true;
          },
          (error: any) => {
            this._loadingIndicatorService.stop();
          },
          () => {
            this._systemEvent.profile.emit(done);
            this._loadingIndicatorService.stop();
          }
        );
      } else if (localStorage.getItem('user_role_id') === '4') {
        // Patient
        this.getProfileSubscriber = this.service.getPatientProfile().subscribe(
          (success: any) => {
            this._systemData.profile = success.user;
            done = true;
          },
          (error: any) => {
            // do nothing
          },
          () => {
            this._systemEvent.profile.emit(done);
            this._loadingIndicatorService.stop();
          }
        );
      }
    } else {
      this._systemEvent.profile.emit(true);
    }
  }

  removePopoverFromDOM() {
    // ________________________________________________________
    // According to this https://github.com/twbs/bootstrap/issues/10740, when destroyed,
    // the popover still lingers in the DOM. This still happens on boostrap as of 3.10.3, if you move on to
    // newer version, please check if this line is necessary anymore.
    // In our program, when we hover the mouse at User Portal Appointment and press Back/Forward in browser,
    // the popover, tooltip still remain.
    jQuery('.popover').remove();
    jQuery('.tooltip').remove();
    // ________________________________________________________
  }

  checkTouchEnabled() {
    return ('ontouchstart' in document.documentElement);
  }

  navActivated(instruction: any[], routerInstance: any): boolean {
    // let onActivate: any[];
    // if ((onActivate = routerInstance.currentInstruction.component.routeData.get('onActivate'))) {
    //   const onActivateGenerate: any = routerInstance.generate(onActivate);
    //   const onActivateSegments: string[] = new Array();
    //   onActivateSegments.push(onActivateGenerate.component.routeName);
    //   let onActivateChild: any = onActivateGenerate.child;
    //   // while (isPresent(onActivateChild)) {
    //   while ($(onActivateChild) !== null) {
    //     onActivateSegments.push(onActivateChild.component.routeName);
    //     onActivateChild = onActivateChild.child;
    //   }
    //   const currentSegments: string[] = new Array();
    //   const currentGenerate: any = routerInstance.generate(instruction);
    //   currentSegments.push(currentGenerate.component.routeName);
    //   let currentChild: any = currentGenerate.child;
    //   while ($(currentChild) !== null) {
    //     currentSegments.push(currentChild.component.routeName);
    //     currentChild = currentChild.child;
    //   }
    //   return currentSegments.join('/') === onActivateSegments.join('/');
    // }
    // return routerInstance.isRouteActive(currentGenerate);
    return routerInstance.isActive(instruction.join('/'));
  }

  logOut() {
    this._loadingIndicatorService.start();
    this._authService.userLogout().subscribe((res: any) => {
        localStorage.removeItem('iscancel');
        this._authService.clearLocalStorage();
        this._systemInterval.clearAllSystemInterval();
        this._systemData.profile = null; // Reset the Profile
        this._loadingIndicatorService.stop();
        this.setLoginedState(false);
        this._router.navigate(['/home', 'login']);
      },
      (error: any) => {
        localStorage.removeItem('iscancel');
        this._authService.clearLocalStorage();
        this._systemInterval.clearAllSystemInterval();
        this._loadingIndicatorService.stop();
        this.setLoginedState(false);
        this._router.navigate(['/home', 'login']);
      });
  }

  logOutNotRouter() {
    this._loadingIndicatorService.start();
    return this._authService.userLogout().subscribe((res: any) => {
        this._authService.clearLocalStorage();
        this._systemInterval.clearAllSystemInterval();
        this._systemData.profile = null; // Reset the Profile
        this._loadingIndicatorService.stop();
        this.setLoginedState(false);
      },
      (error: any) => {
        this._authService.clearLocalStorage();
        this._systemInterval.clearAllSystemInterval();
        this._loadingIndicatorService.stop();
        this.setLoginedState(false);
      });
  }

  reloadPage() {
    location.reload();
  }

  setLoginedState(state: boolean) {
    this._systemData.isLogined = state;
  }

  getLoginedState() {
    return this._systemData;
  }
  getLocationList() {
    if (this._systemData.providerLocationLists.length === 0) {
      let done = false;
      this._loadingIndicatorService.start();
      if (this.getLocationsSubscriber) {
        this.getLocationsSubscriber.unsubscribe();
        this._loadingIndicatorService.stop();
      }
      this.getLocationsSubscriber = this.service.getProviderLocations().subscribe(
        (success: any) => {
          this._systemData.providerLocationLists = success.aptlocation;
          done = true;
          this._loadingIndicatorService.stop();
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        },
        () => {
          this._systemEvent.providerlocations.emit(done);
          this._loadingIndicatorService.stop();
        }
      );
    } else {
      this._systemEvent.providerlocations.emit(true);
    }
  }
}
