import { Component, ViewChild, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { RestService } from '@app/core/rest.service';
import { SystemEvent, SystemService, SystemData } from '@app/shared/system';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
//import { Utils } from '@app/shared/utils';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
//import { Item } from '@app/shared/models/multipleSelect';
import { AlertService } from '@app/shared/alert';
import { DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent } from '@app/shared/datatable';
import { ProvidersService } from '@app/providers/providers.service';
import { Module } from '@app/core/module';

import { AddApttypesComponent } from '@app/providers/list-apttypes/modals/add-apttypes/add-apttypes.component';

@Component({
  moduleId: module.id,
  selector: 'app-list-apttypes',
  templateUrl: './list-apttypes.component.html',
  styleUrls: ['./list-apttypes.component.scss'],
  providers: [RestService],
})
export class ListApttypesComponent implements OnInit, OnDestroy {

  //updateIsLockProviderSubscriber: Subscription;
  //userStatusSubscriber: Subscription;
  aptTypeSubscriber: Subscription;
  //startManagingSubscriber: Subscription;
  successMessage: string;
  accountList: any = new Object();
  //statuses: any = [];
  //status: any = [];
  page: number = 1;
  rowOfPage: number = 20;
  errorMessage: string;
  searchText: string = '';
  countData: number = 0;
  runRequest: boolean = true;
  module: any = true;
  reload: EventEmitter<any> = new EventEmitter();
  //eventEntry: Event;
  //isProvider: boolean = true;
  @ViewChild(AddApttypesComponent) addApttypesComponent: AddApttypesComponent;

  constructor(
    private _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _restservice: ProvidersService,
    private _alertService: AlertService,
    private _router: Router,
    private _loadingIndicatorService: LoadingIndicatorService,
    //private _utils: Utils
    ) {
      
  }
  init() {
    this.accountList.data = new Array();
    this.accountList.numberOfPages = 0;
    this.accountList.rowsOnPage = 10;
    this.errorMessage = null;
    this.getInfo(this.page);
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!this.module[Module.SCHEDULE]) {
      this._loadingIndicatorService.stop();
      this._router.navigate(['/home', 'page-not-found']);
    }
    
    this.init();
  }

  ngOnDestroy() {
    
    if (this.aptTypeSubscriber) {
      this.aptTypeSubscriber.unsubscribe();
    }    
  }

  onDataChange(e: any) {
    this.page = e['currentPage'];
    this.getInfo(this.page, this.searchText);
  }

  getInfo(page: number, search_text: string = null) {
    this.runRequest = false;
    this.errorMessage = null;
    this._loadingIndicatorService.start();
    if (this.aptTypeSubscriber) {
      this.aptTypeSubscriber.unsubscribe();
    }
    let practiceId = localStorage.getItem('user_practice');
    this.aptTypeSubscriber = this._restservice.getAptTypeList(page, this.rowOfPage, search_text, practiceId).subscribe((success: any) => {
      this.runRequest = true;
      this._loadingIndicatorService.stop();
      if (success.error_code === 'RC000') {
        this.accountList.numberOfPages = success.last_page || 0;
        let accounts = success.apttypes || [];            
        this.accountList.data = accounts;
        this.countData = success.total;
      } else if (success.error_code === 'RC077') {
        this.init();
        this.errorMessage = success.error_messages;
      }
    },
    (error: any) => {
      this.runRequest = true;
      this._loadingIndicatorService.stop();
    },
    () => {
      this.runRequest = true;
      this._loadingIndicatorService.stop();
    });
  }

  onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
    if (this.runRequest) {
      if (this.page === 1) {
        this.getInfo(this.page, this.searchText.trim());
      } else {
        dataTable.setPage(1, 10);
      }
    }
  }  
}
