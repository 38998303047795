import { Component, ViewChild, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { RestService } from '@app/core/rest.service';
import { SystemEvent, SystemService, SystemData } from '@app/shared/system';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Utils } from '@app/shared/utils';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Item } from '@app/shared/models/multipleSelect';
import { AlertService } from '@app/shared/alert';
import { DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent } from '@app/shared/datatable';
import { ProvidersService } from '@app/providers/providers.service';
import { Module } from '@app/core/module';

@Component({
  moduleId: module.id,
  selector: 'app-list-providers',
  templateUrl: './list-providers.component.html',
  styleUrls: ['./list-providers.component.scss'],
  providers: [RestService],
})
export class ListProvidersComponent implements OnInit, OnDestroy {
  updateIsLockProviderSubscriber: Subscription;
  userStatusSubscriber: Subscription;
  userInfoSubscriber: Subscription;
  startManagingSubscriber: Subscription;
  successMessage: string;
  accountList: any = new Object();
  statuses: any = [];
  status: any = [];
  page: number = 1;
  rowOfPage: number = 10;
  errorMessage: string;
  searchText: string = '';
  countData: number = 0;
  runRequest: boolean = true;
  module: any = true;
  reload: EventEmitter<any> = new EventEmitter();
  eventEntry: Event;
  isProvider: boolean = true;
  constructor(private _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _restservice: ProvidersService,
    private _alertService: AlertService,
    private _router: Router,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils) {
  }

  init() {
    this.accountList.data = new Array();
    this.accountList.numberOfPages = 0;
    this.accountList.rowsOnPage = 10;
    this.errorMessage = null;
  }


  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!this.module[Module.PROVIDER]) {
      this._loadingIndicatorService.stop();
      this._router.navigate(['/home', 'page-not-found']);
    }
    if (this._utils.accessListProvider()) {
      this.init();
      this.getStatus();
      this._systemService.getStates();
    }
  }

  ngOnDestroy() {
    if (this.userStatusSubscriber) {
      this.userStatusSubscriber.unsubscribe();
    }
    if (this.userInfoSubscriber) {
      this.userInfoSubscriber.unsubscribe();
    }
    if (this.startManagingSubscriber) {
      this.startManagingSubscriber.unsubscribe();
    }
  }

  getStatus() {
    if (this.userStatusSubscriber) {
      this.userStatusSubscriber.unsubscribe();
    }
    this.userStatusSubscriber = this._restservice.userStatus().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          success.statuses.forEach((status: any) => {
            let i = new Item();
            i.oj = status;
            this.statuses.push(i);
          });
          this.getInfo(this.page);
        }
      });
  }

  onDataChange(e: any) {
    this.page = e['currentPage'];
    this.getInfo(this.page, this.searchText);
  }

  getInfo(page: number, search_text: string = null) {
    this.runRequest = false;
    this.errorMessage = null;
    this._loadingIndicatorService.start();
    if (this.userInfoSubscriber) {
      this.userInfoSubscriber.unsubscribe();
    }
    let practiceId = localStorage.getItem('user_practice');
    this.userInfoSubscriber = this._restservice.getProviderUserInfo(page, this.rowOfPage, search_text, 'PROVIDER',
      practiceId, null, 0).subscribe(
        (success: any) => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
          if (success.error_code === 'RC000') {
            this.accountList.numberOfPages = success.last_page || 0;
            let accounts = success.users || [];
            accounts.map((user: any, i: any) => {
              let listSpecialtiesName = new Array();
              var specialties = user.specialties;
              for (let i = 0; i < specialties.length; i++) {
                listSpecialtiesName.push(specialties[i].name);
              }
              user.specialties = listSpecialtiesName.join(', ');
              if (user.phone_mobile) {
                if (user.phone_mobile.indexOf('+1 ') !== -1) {
                  user.phone_mobile = user.phone_mobile.substr(user.phone_mobile.indexOf('+1 ') + 3);
                }
              }

              for (let i = 0; i < this.statuses.length; i++) {
                if (user.status === this.statuses[i].oj.id) {
                  user.status_show = this.statuses[i].oj.name;
                }
              }

              let time = 0;
              if (user.waittime > 999) {
                  time = 999;
              } else {
                  time = user.waittime;
              }
              user.waittime = this._utils.generateValueUnitDisplay(time, 'min');
            });
            this.accountList.data = accounts;
            this.countData = success.total;
          } else if (success.error_code === 'RC077') {
            this.init();
            this.errorMessage = success.error_messages;
          }
        },
        (error: any) => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
        },
        () => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
        });
  }

  onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
    if (this.runRequest) {
      if (this.page === 1) {
        this.getInfo(this.page, this.searchText.trim());
      } else {
        dataTable.setPage(1, 10);
      }
    }
  }
  updateIsLock(is_lock: any, provider_id: any) {
    this._alertService.onClose = () => {
      this._loadingIndicatorService.start();
      if (this.updateIsLockProviderSubscriber) {
        this.updateIsLockProviderSubscriber.unsubscribe();
      }
      this.updateIsLockProviderSubscriber = this._restservice.updateIsLockProvider({
        is_lock: is_lock,
        provider_id: provider_id,
      }).subscribe(
        (res: any) => {
          this._loadingIndicatorService.stop();
          if (res.error_code === 'RC000') {
            this.accountList.data.forEach((user: any, index: number) => {
              if (user.provider_id == res.provider_id) {
                this.accountList.data[index].is_lock = res.is_lock;
              }
            });
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              // this.dismiss();
              // this.close();
            };
            // this._alertService.showDismissButton = false;
            // this._alertService.dismissButtonLabel = 'No';
            // this._alertService.showCloseButton = true;
            // this._alertService.closeButtonLabel = 'OK';
            // this._alertService.title = res.error_messages;
            // this._alertService.message = '';
            // this._alertService.emitter('open');
          }
          // if (res.error_code === 'RC002' || res.error_code === 'RC061' || res.error_code === 'RC119') {
          //   this.errorMessageBook = res.error_messages;
          // }
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
          // const e = error.error;
          // if (e.error_code === 'RC002' || e.error_code === 'RC061' || e.error_code === 'RC119') {
          //   this.errorMessageBook = e.error_messages;
          // }
        },
        () => {
          this._loadingIndicatorService.stop();
        }
      );


    };
    if (is_lock) {
      this.confirmLock('Are you sure to unlock this provider ?');
    } else {
      this.confirmLock('Are you sure to lock this provider ?');
    }

  }
  confirmLock(msg: any) {
    this._alertService.showDismissButton = true;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'Yes';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  add() {
    const _seft = this;
    this._router.navigate(['/provider', 'add']);
  }
  startManaging(providerId: any) {
    this._loadingIndicatorService.start();
    if (this.startManagingSubscriber) {
      this.startManagingSubscriber.unsubscribe();
    }
    this.startManagingSubscriber = this._restservice.startManaging({ 'providerId': providerId }).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        // Save old data
        let storeData = {
          //   id_token: localStorage.getItem('id_token'),
          last_active_token: localStorage.getItem('last_active_token'),
          user_token: localStorage.getItem('user_token'),
          user_role_id: localStorage.getItem('user_role_id'),
          account_name: localStorage.getItem('account_name'),
          user_status: localStorage.getItem('user_status'),
          user_practice: localStorage.getItem('user_practice'),
          loginEmail: localStorage.getItem('loginEmail'),
          userFullName: localStorage.getItem('userFullName'),
          user_id: localStorage.getItem('user_id'),
          user_providerId: localStorage.getItem('user_providerId'),
        };
        localStorage.setItem('storeData', JSON.stringify(storeData));
        // Save data to local storage
        localStorage.setItem('id_token', success.token);
        localStorage.setItem('last_active_token', success.token);
        localStorage.setItem('user_id', success.user.id);
        localStorage.setItem('user_role_id', success.user.role_id);
        localStorage.setItem('account_name', success.user.account_name);
        localStorage.setItem('user_status', success.user.status);
        localStorage.setItem('user_practice', success.user.practice);
        localStorage.setItem('loginEmail', success.user.email);
        localStorage.setItem('user_token', success.token);
        localStorage.setItem('user_providerId', success.user.provider_id);

        localStorage.setItem('userFullName', this._utils.generateFullName(success.user_name.first_name,
          success.user_name.middle_name, success.user_name.last_name));
        this._alertService.onClose = () => {
          // Redirect to Appoinment page
          this._router.navigate(['/provider', 'profile']);
        };
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = success.message;
        this._alertService.message = '';
        this._alertService.emitter('open');
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
        var response = error.error;
        if (response.error_code === 'RC004' || response.error_code === 'RC118') {
          this.openAlertMessage(response.error_messages);
        }
      },
      () => {
        this._loadingIndicatorService.stop();
      });
  }

  openAlertMessage(msg: string) {
    this._alertService.onClose = () => {
      // location.reload();
    };
    this._alertService.showDismissButton = false;
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  reloadProviderList(){
    this.getInfo(this.page, this.searchText);
  }
}
