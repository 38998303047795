<modal #AppointmentEdit_Modals class="appointment-edit">
  <form #modalForm="ngForm" class="form">
    <div class="form-content">
      <div class="form-header">Edit Appointment</div>
      <div class="form-info">
        <div class="row">
          <div class="col-2 form-input-label">Patient</div>
          <div class="col-10 search">
            <div class="input-group">
              <input class="col-12 form-control form-input search-input" [class.input-error]="requireMessage.patient_id != null"
                     type="text" placeholder="Enter Name or Phone Number" [(ngModel)]="editApmtModel.patient"
                     (keyup)="onKeyUp()" name="input-name-or-phone" id="search-field-edit-appointment">
              <div class="input-group-append">
                <button class="search-button dropdown-toggle" [class.disabled]="searchDisabled"
                        [disabled]="searchDisabled" (click)="searchPatientClicked($event)" data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true" aria-expanded="false">
                  <span aria-hidden="true" class="fas fa-search"></span>
                </button>
              </div>
              <div class="result dropdown-menu">
                <div class="error-message">{{ errorMessage }}</div>
                <div *ngFor="let p of patients" class="patient-info">
                  <div *ngIf="p.phone_mobile == null"
                       (click)="getInfoByNameClicked($event,p.id, p.first_name, p.last_name, p.dob)">
                    <span>{{ p.first_name }}</span>
                    <span>{{ p.last_name }}</span>
                    <span>- {{ p.dob }}</span>
                  </div>
                  <div *ngIf="p.phone_mobile != null"
                       (click)="getInfoByNumberClicked($event,p.id, p.phone_mobile, p.first_name, p.last_name)">
                    <span>{{ p.phone_mobile }}</span>
                    <span>{{ p.first_name }}</span>
                    <span>{{ p.last_name }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-xs-2"></div>
          <div class="message-error">{{ requireMessage.patient_id }}</div>
        </div>
        <div class="row">
          <div class="col-2 form-input-label">Date</div>
          <!-- <rtn-calendar class="col-xs-6 calender" [inline]="false" [autoclose]="true" [(value)]="editApmtModel.date" [todayHighlight]="ngDatePicker.todayHighlight" [weekStart]="ngDatePicker.weekStart" [defaultViewDate]="ngDatePicker.defaultViewDate" [format]="ngDatePicker.format" (click)="onClicked('date')" #calendar>
              <input type="text" class="form-control calender-input" [(ngModel)]="editApmtModel.date" (input)="calendar.valueChange.next($event.target.value)" disabled>
              <div class="input-group-addon">
                  <span aria-hidden="true" class="fa fa-calendar-o"></span>
              </div>
          </rtn-calendar> -->
          <div class="col-10 date-select pl-0">
            <div class="input-group">
              <input type="text" #inputDateEditAppointment class="form-control calender-input bc-white"
                     [class.input-error]="requireMessage.date != null" [(ngModel)]="editApmtModel.date" disabled
                     name="input-date">
              <div class="input-group-append" #clickDateEditAppointment>
                <span aria-hidden="true" class="input-group-text far fa-calendar"></span>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-xs-2"></div>
          <div class="message-error">{{ requireMessage.date }}</div>
        </div>
        <!-- <div class="row">
            <div class="col-xs-2 form-input-label">Time</div>
            <div class="col-xs-6 content">
                <div class="input-group select time-picker">
                    <select class="form-control" [class.input-error]="requireMessage.start_time != null" [ngModel]="editApmtModel.hour1" (ngModelChange)="editApmtModel.hour1 = $event" (click)="onClicked('start_time')">
                        <option *ngFor="let h of hours" [value]="h">{{ h }}</option>
                    </select>
                    <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                    </div>
                </div>
                <div>:</div>
                <div class="input-group select time-picker">
                    <select class="form-control" [class.input-error]="requireMessage.start_time != null" [ngModel]="editApmtModel.minute1" (ngModelChange)="editApmtModel.minute1 = $event" (click)="onClicked('start_time')">
                        <option *ngFor="let m of minutes" [value]="m">{{ m }}</option>
                    </select>
                    <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                    </div>
                </div>
                <div>-</div>
                <div class="input-group select time-picker">
                    <select class="form-control" [class.input-error]="requireMessage.end_time != null" [ngModel]="editApmtModel.hour2" (ngModelChange)="editApmtModel.hour2 = $event" (click)="onClicked('end_time')">
                        <option *ngFor="let h of hours" [value]="h">{{ h }}</option>
                    </select>
                    <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                    </div>
                </div>
                <div>:</div>
                <div class="input-group select time-picker">
                    <select class="form-control" [class.input-error]="requireMessage.end_time != null" [ngModel]="editApmtModel.minute2" (ngModelChange)="editApmtModel.minute2 = $event" (click)="onClicked('end_time')">
                        <option *ngFor="let m of minutes" [value]="m">{{ m }}</option>
                    </select>
                    <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
          <div class="col-2 form-input-label input-label-required">Slot</div>
          <div class="row col-10 no-padding-left-right">
            <div class="col-xs-4 col-sm-3 wrapper-slot" *ngFor="let slot of availableSlots">
              <div class="btn btn-default btn-slot" [class.selected-bc]="slot.isSelected"
                   (click)="selectSlot(slot.id)" title="{{slot.location_name}}">{{ getTime(slot.start_time) }}&nbsp;-&nbsp;{{ getTime(slot.end_time) }}</div>
            </div>
            <div *ngIf="availableSlots.length == 0">Please add Available Slot before booking an appointment.</div>
          </div>
        </div>

        <!--<div class="row">
          <div class="col-2 form-input-label">Apt. Type</div>
          <div class="row col-10 no-padding-left-right">
            <div class="input-group select">              
              <select class="form-control textbox-edit-form width-full" [(ngModel)]="editApmtModel.event_reason_id" name="event_reason_id" ngControl="event_reason_id" [class.input-error]="requireMessage.event_reason_id">
                <option [value]="''" selected>Select Type</option>
                <option *ngFor="let typeItem of appointmentTypeList" [value]="typeItem.id">{{typeItem.code}}^{{typeItem.label}}</option>
              </select>
              <div class="input-group-select-icon cursorPointer select-state">
                  <i class="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2 form-input-label">Reason</div>
          <div class="row col-10 no-padding-left-right">
            <textarea id="appointment_reason" name="appointment_reason" [(ngModel)]="editApmtModel.appointment_reason" class="form-control"></textarea>
          </div>
        </div>-->

      </div>
      <div class="row" *ngIf="errorMessageBook">
        <div class="col-xs-2"></div>
        <div class="col-xs-10 no-padding-left-right">
          <div class="message-error">{{ errorMessageBook }}</div>
        </div>
      </div>
    </div>
    <div class="button">
      <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="dismiss()">Cancel
      </button>
      <button type="submit" class="btn btn-rtn btn-90 float-right" (click)="editApmtSaveCloseClicked()" autofocus
              [disabled]="selectedSlot === 0">Save
      </button>
    </div>
  </form>
  <!--<rtn-modals-confirmation [showIcon]="true" (OnYesClicked)="confirmationYesClicked()" (OnNoClicked)="confirmationNoClicked()"></rtn-modals-confirmation>-->
</modal>
