import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {RestService} from '@app/core/rest.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Utils} from '@app/shared/utils';
import {Item} from '@app/shared/models/multipleSelect';
import {RtnDataTableCoreComponent} from '@app/shared/datatable/core';
import * as moment from 'moment';
import {ProvidersService} from '@app/providers/providers.service';
import {Module} from '@app/core/module';

declare let jQuery: any;

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};

moment.updateLocale('en-US', _momentOptions);

@Component({
  selector: 'app-list-managing-user',
  templateUrl: './list-managing-user.component.html',
  styleUrls: ['./list-managing-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListManagingUserComponent implements OnInit, OnDestroy {

  accountList: any = new Object();
  statuses: any = [];
  status: any = [];
  page = 1;
  rowOfPage = 10;
  errorMessage: string;
  searchText = '';
  userStatusSubscriber: Subscription;
  managingInfoSubscriber: Subscription;
  runRequest = true;
  module: any;
  activateAccountSubscriber: Subscription;
  deactivateAccountSubscriber: Subscription;

  constructor(
      private _restService: ProvidersService,
      private _loadingIndicatorService: LoadingIndicatorService,
      private _router: Router,
      private _utils: Utils,
      private _systemService: SystemService
  ) {
    this.init();
  }

  init() {
    this.accountList.data = new Array();
    this.accountList.numberOfPages = 0;
    this.accountList.rowsOnPage = 10;
    this.errorMessage = null;
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!Module.PROVIDER) {
      this._loadingIndicatorService.stop();
      this._router.navigate(['/home', 'page-not-found']);
    }
    if (this._utils.accessManageUser()) {
      this.getStatus();
    }
  }

  getStatus() {
    if (this.userStatusSubscriber) {
      this.userStatusSubscriber.unsubscribe();
    }
    this.userStatusSubscriber = this._restService.userStatus().subscribe(
        (success: any) => {
          if (success.error_code === 'RC000') {
            success.statuses.forEach((status: any) => {
              let i = new Item();
              i.oj = status;
              this.statuses.push(i);
            });
            this.getInfo(this.page);
          }
        });
  }

  ngOnDestroy() {
    if (this.managingInfoSubscriber) {
      this.managingInfoSubscriber.unsubscribe();
    }
    if (this.activateAccountSubscriber) {
      this.activateAccountSubscriber.unsubscribe();
    }
    if (this.deactivateAccountSubscriber) {
        this.deactivateAccountSubscriber.unsubscribe();
    }
  }

  onDataChange(e: any) {
    this.page = e['currentPage'];
    this.getInfo(this.page, this.searchText);
  }

  getInfo(page: number, search_text: string = null) {
    this.runRequest = false;
    this.errorMessage = null;
    this._loadingIndicatorService.start();
    if (this.managingInfoSubscriber) {
      this.managingInfoSubscriber.unsubscribe();
    }
    this.managingInfoSubscriber = this._restService.getListManagingUserInfo(page, this.rowOfPage, search_text)
        .subscribe(
        (success: any) => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
          if (success.error_code === 'RC000') {
            this.accountList.numberOfPages = success.last_page || 0;
            const accounts = success.users || [];
            accounts.map((user: any, i: any) => {
              const full_name = this._utils.generateFullName(user.first_name, user.middle_name, user.last_name);
              user.name_show = user.role_id === 2 ? user.account_name : full_name;
              user.full_address = this._utils.generateFullAddress(user.address, user.address2,
                  user.city, user.state_code, user.zip);
              user.dob = this._utils.formatDate(user.dob);
              for (let i = 0; i < this.statuses.length; i++) {
                if (user.status === this.statuses[i].oj.id) {
                  user.status_show = this.statuses[i].oj.name;
                }
              }
            });
            this.accountList.data = accounts;
          } else if (success.error_code === 'RC115') {
            this.init();
            this.errorMessage = success.error_messages;
          }
        },
        (error: any) => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
        },
        () => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
        });
  }

  onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
    if (this.runRequest) {
      if (this.page === 1) {
        this.getInfo(this.page, this.searchText.trim());
      } else {
        dataTable.setPage(1, 10);
      }
    }
  }
  activeAccount(id: number, dataTable: RtnDataTableCoreComponent) {
    if (this.activateAccountSubscriber) {
        this.activateAccountSubscriber.unsubscribe();
    }
    this.activateAccountSubscriber = this._restService.activeAccount({ id: id }).subscribe(
    (success: any) => {
        this.getInfo(this.page, this.searchText.trim());
        dataTable.setPage(this.page, 10);
    });
}

deactiveAccount(id: number, dataTable: RtnDataTableCoreComponent) {
    if (this.deactivateAccountSubscriber) {
        this.deactivateAccountSubscriber.unsubscribe();
    }
    this.deactivateAccountSubscriber = this._restService.deactiveAccount({ id: id }).subscribe(
    (success: any) => {
        this.getInfo(this.page, this.searchText.trim());
        dataTable.setPage(this.page, 10);
    });
}
}
