import { Directive, Input, Output, OnInit, AfterViewInit, OnDestroy, OnChanges, ElementRef, EventEmitter } from '@angular/core';
// import { CORE_DIRECTIVES, ControlGroup, Control } from '@angular/common';

declare let jQuery: any;
@Directive({
    selector: '[select2]'
})
export class Select2Directive implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() multiple: boolean = true;
    @Input() tags: boolean = true;
    @Input() index: number = 0;
    @Input() tokenSeparators: any = [',', ' '];
    @Input() dropdownAutoWidth: boolean = true;
    @Input() placeholder: string = 'Select Plan';
    @Input() allowClear: boolean = false;
    @Input() closeOnSelect: boolean = false;
    @Input() maximumSelectionLength: number = 99;
    @Input() dropdownParent: any;
    @Input() noResults: any = () => { return 'No plan matches'; };
    @Input() maximumSelected: any = () => { return 'You cannot choose more than ' + this.maximumSelectionLength + ' plans'; };
    @Input() templateResult: any;
    @Input() inputError: boolean = false;

    @Output() OnKeyUp: EventEmitter<any> = new EventEmitter();
    @Output() clearErr: EventEmitter<any> = new EventEmitter();

    withCheckboxTemplate: any = (result: any) => {
        return jQuery(`<span class="input-rtn">
        <input type="checkbox" class="checkbox check-single">
        <label class="selectorExtreme"></label></span><span class="select-text">` + result.text + `</span>`);
    };
    public element: any;

    constructor(
        public el: ElementRef
    ) {
        this.element = jQuery(this.el.nativeElement);
    }

    ngOnInit() {
        this.element.select2({
            multiple: this.multiple,
            tags: this.tags,
            dropdownAutoWidth: this.dropdownAutoWidth,
            placeholder: this.placeholder,
            allowClear: this.allowClear,
            closeOnSelect: this.closeOnSelect,
            maximumSelectionLength: this.maximumSelectionLength,
            dropdownParent: jQuery(this.element).parent(),
            language: {
                maximumSelected: this.maximumSelected,
                noResults: this.noResults
            },
            templateResult: this.withCheckboxTemplate
        })
            .on('select2:open', (e: any) => {
                this.OnKeyUp.emit(e);
                // Fix dropdown display problem: https://github.com/select2/select2/issues/3303
                let dropdown = jQuery('.select2-container.select2-container--default.select2-container--open:not(".select2")');
                let trigger = jQuery('.select2-container.select2-container--default.select2-container--open.select2');
                if (!dropdown.children().hasClass('select2-dropdown--below')) {
                    dropdown.children().removeClass('select2-dropdown--above');
                    dropdown.children().addClass('select2-dropdown--below');
                }
                dropdown.css({ 'top': dropdown.parent().height() + 'px' });
                // Always make sure the dropdown goes to the bottom
                this.clearErr.emit('');
            })
            .on('select2:close', (e: any) => {
                this.OnKeyUp.emit(e);
                this.clearErr.emit('');
            })
            .on('select2:select', (e: any) => {
                if (jQuery(this.element).val().length === this.maximumSelectionLength) {
                    this.close();
                }
                this.clearErr.emit('');
            })
            ;
    }

    ngOnChanges() {
        if (this.inputError) {
            jQuery('#input-multiple-plan-'+ this.index + ' .select2-container--default .select2-selection--multiple').addClass('input-error');
        } else {
            jQuery('#input-multiple-plan-'+ this.index + ' .select2-container--default .select2-selection--multiple').removeClass('input-error');
        }
    }

    ngAfterViewInit() {
        this.element.next('.select2-container').css({ 'width': '100%' });
        jQuery('.select2-container--default .select2-selection--multiple').prepend('<span class="select2-selection__caret cursorPointer"><i class="fa fa-caret-down"></i></span>');
    }

    ngOnDestroy() {
        // do nothing
    }

    invoke(...args: any[]) {
        this.element.select2.apply(this.element, args);
    }

    getData() {
        return jQuery(this.element).val();
    }

    clearData() {
        jQuery(this.element).val(null).trigger('change');
    }

    updateData(data: any) {
        jQuery(this.element).val(data).trigger('change');
    }

    open() {
        this.invoke('open');
    }

    close() {
        this.invoke('close');
    }
}
