import { Component, ViewChild } from '@angular/core';
import {PhysicianDirectoryGlobalComponent} from '@app/shared/physician-directory-global/physician-directory-global.component';

@Component({
  moduleId: module.id,
  templateUrl: './physician-directory.component.html',
  styleUrls: ['./physician-directory.component.scss']
})
export class PhysicianDirectoryComponent {
  @ViewChild(PhysicianDirectoryGlobalComponent) physicianDirectoryComponent: PhysicianDirectoryGlobalComponent;
}
