import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {RtnConfig} from '@app/shared/rtnconfig';
import {DataTableDirectives, RtnDataTableCoreComponent} from '@app/shared/datatable';
// import { DocumentService } from '../services/document.service';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {AlertService} from '@app/shared/alert';
import {Utils} from '@app/shared/utils';
import {DocumentDeleteComponent} from './modals/document-delete/document-delete.component';
import {DocumentUploadComponent} from './modals/document-upload/document-upload.component';
import {Subscription} from 'rxjs';
import {RestService as DocumentService} from '@app/core/rest.service';

declare let jQuery: any;
// declare var moment: any;
import * as moment from 'moment';
import {DocumentsService} from '@app/documents/documents.service';
import {Module} from '@app/core/module';
import {SystemService} from '@app/shared/system/system.service';
import {Router} from '@angular/router';

var _momentOptions = {
  week: {
    dow: 0,
    doy: 6 // Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})

export class DocumentsComponent implements OnInit, OnDestroy {

  documentList: any = new Object();
  isOverCapacity: boolean = false;
  maxCapacity: number = 100 * Math.pow(1024, 2);
  maxCapacityDisplay: string = '100 MB';
  capacity: number;
  capacityDisplay: string;
  currentPage: number = 1;
  currentRowOnPage: number = 10;

  getCapacitySubscriber: Subscription;
  getDocumentListSubscriber: Subscription;
  deleteDocumentsSubscriber: Subscription;
  module: any;
  @ViewChild(DocumentUploadComponent) documentUploadDirective: DocumentUploadComponent;
  @ViewChild(DocumentDeleteComponent) documentDeleteDirective: DocumentDeleteComponent;
  
  minHeight: number;

  constructor(
    private _documentService: DocumentsService,
    private _alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService,
    private _utils: Utils,
    public _systemService: SystemService,
    public _router: Router
  ) {
    this.documentList.rowsOnPage = 10;
    this.documentList.numberOfPages = 0;
    this.documentList.data = new Array();
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!this.module[Module.DOCUMENT]) {
      this.loadingIndicatorService.stop();
      this._router.navigate(['/home', 'page-not-found']);
    } else {
      if (this._utils.accessByProvider()) {
        this.getCapacity();
      }
    }
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }

  ngOnDestroy() {
    if (this.getCapacitySubscriber) {
      this.getCapacitySubscriber.unsubscribe();
    }
    if (this.getDocumentListSubscriber) {
      this.getDocumentListSubscriber.unsubscribe();
    }
    if (this.deleteDocumentsSubscriber) {
      this.deleteDocumentsSubscriber.unsubscribe();
    }
  }

  getCapacity() {
    if (this.getCapacitySubscriber) {
      this.getCapacitySubscriber.unsubscribe();
    }
    this.getCapacitySubscriber = this._documentService.getCapacity().subscribe(
      (success: any) => {
        this.capacity = parseInt(success.capacity);
        this.isOverCapacity = this.capacity < this.maxCapacity ? false : true;
        this.capacityDisplay = this._utils.bytesToSize(this.capacity);
      });
  }

  downloadFile(_downloadUrl: string) {
    let _self = this;
    const token = localStorage.getItem('user_token');
    jQuery.fileDownload(_downloadUrl, {
      httpMethod: 'POST',
      data: 'token=' + token
    })
      .done(function () {
        //TODO
      })
      .fail(function () {
        _self._alertService.onClose = () => {
          location.reload();
        };
        _self._alertService.showDismissButton = false;
        _self._alertService.dismissButtonLabel = 'Close';
        _self._alertService.showCloseButton = true;
        _self._alertService.closeButtonLabel = 'Close';
        _self._alertService.title = 'This document is not available to download anymore.';
        _self._alertService.message = '';
        _self._alertService.emitter('open');
      });
  }

  handleFetchDocumentList(args: any) {
    if (typeof args['currentPage'] !== 'undefined') {
      this.currentPage = args['currentPage'];
    }
    if (typeof args['rowsOnPage'] !== 'undefined') {
      this.currentRowOnPage = args['rowsOnPage'];
    }
    args.page = this.currentPage;
    args.limit = this.currentRowOnPage;

    if (this.getDocumentListSubscriber) {
      this.getDocumentListSubscriber.unsubscribe();
    }
    this.getDocumentListSubscriber = this._documentService.getDocumentList(args.limit, args.page).subscribe(
      (success: any) => {
        this.documentList.rowsOnPage = success.documents.limit || 10;
        this.documentList.numberOfPages = success.documents.total_pages || 0;
        var item = success.documents.list || [];
        item.map((item: any, i: any) => {
          item.file_size = this._utils.bytesToSize(item.file_size);
          item.upload_date = moment(item.upload_date, 'YYYY-MM-DD').format('MMMM DD, YYYY');
        });
        this.documentList.data = item;
        setTimeout(this.checkSelectStatus, 10);
        this.getCapacity();
      },
      (error: any) => {
        // TODO
      }
    );
  }

  deleteDocuments(dataTable: RtnDataTableCoreComponent) {
    var _self = this;
    var deletedDocuments = jQuery('input[name="select-list[]"]:checked').map(function () {
      return jQuery(this).val();
    }).get();
    this.loadingIndicatorService.start();
    if (this.deleteDocumentsSubscriber) {
      this.deleteDocumentsSubscriber.unsubscribe();
      this.loadingIndicatorService.stop();
    }
    this.deleteDocumentsSubscriber = _self._documentService.deleteDocuments({
      document_ids: deletedDocuments
    }).subscribe(
      (success: any) => {
        this.loadingIndicatorService.stop();
        if (deletedDocuments.length === dataTable.data.length && dataTable.currentPage > 1) {
          dataTable.setPage(dataTable.currentPage - 1, dataTable.rowsOnPage);
        } else {
          dataTable.reload();
        }
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
      },
      () => {
        this.loadingIndicatorService.stop();
      });
  }

  showDocumentDeleteDirective() {
    this.documentDeleteDirective.minimumSelectedStatus = this.checkMinimumSelect();
    this.documentDeleteDirective.open();
  }

  checkMinimumSelect() {
    return (jQuery('input[name="select-list[]"]:checked').length > 0);
  }

  checkSelectStatus() {
    jQuery('label[for="selectorAll"]').removeClass('selectorIntermediate').addClass('selectorExtreme');
    // jQuery('input[name="selectAll"]').prop("indeterminate", false);
    if (jQuery('input[name="select-list[]"]:checked').length === 0) {
      jQuery('input[name="selectAll"]').prop('checked', false);
    } else {
      if (jQuery('input[name="select-list[]"]:checked').length === jQuery('input[name="select-list[]"]').length) {
        jQuery('input[name="selectAll"]').prop('checked', true);
      } else {
        // jQuery('input[name="selectAll"]').prop("indeterminate", true);
        jQuery('label[for="selectorAll"]').addClass('selectorIntermediate').removeClass('selectorExtreme');
        jQuery('input[name="selectAll"]').prop('checked', true);
      }
    }
    return;
  }

  selectAll() {
    jQuery('label[for="selectorAll"]').removeClass('selectorIntermediate').addClass('selectorExtreme');
    if (jQuery('input[name="selectAll"]:checked').length > 0) {
      jQuery('input[name="select-list[]"]').prop('checked', true);
    } else {
      jQuery('input[name="select-list[]"]').prop('checked', false);
    }
    return;
  }
}
