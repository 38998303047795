<div class="container family-member" [style.min-height.px]="minHeight">
  <div class="center">
    <ng-container *ngIf="!errorMessage">
      <h2>Thanks for completing family setup.</h2>
      <h2 class="label-rtn">Are you RTN member? If not,<br/>create your own account</h2>
      <button class="text-uppercase btn-rtn" (click)="createAccount()">Create Account</button>
    </ng-container>
    <ng-container *ngIf="errorMessage">
        <h4 class="text-danger">{{errorMessage}}</h4>
    </ng-container>
  </div>
</div>
<!--<div *ngIf="errorMessage" class="alert alert-danger col-xs-6 col-xs-offset-3">
  {{errorMessage}}
</div>-->
