import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {PasswordFormatTooltipComponent} from '@app/shared/tooltip/password-format';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {RestService} from '@app/core/rest.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {PatientsService} from '@app/patients/patients.service';

@Component({
  selector: 'rtn-home-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../patients.component.scss', './change-password.component.scss']
})
export class PatientChangePasswordComponent implements OnInit, OnDestroy {

  resetForm: FormGroup;
  error: boolean = false;
  errorMessage: any;
  responseError: PasswordItem = new PasswordItem();
  password: string;
  password_confirm: string;
  minHeight: number;

  validLinkSubscriber: Subscription;
  changePasswordSubscriber: Subscription;

  @ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _restService: PatientsService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.resetForm = this._formBuilder.group({
      password: ['', Validators.compose([Validators.required])],
      password_confirm: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight() - 81;
  }

  reset(formVals: any) {
    var self = this;
    var resetData = {
      'password': formVals.password,
      'password_confirmation': formVals.password_confirm,
    };
    // Call to service change password
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.changePasswordSubscriber = this._restService.changePassword(resetData).subscribe(
      (success: any) => {
        // Todo
        self._loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          this.error = false;
          this.password = null;
          this.password_confirm = null;
        } else {
          this.responseError = success.error_fields;
          this.errorMessage = success.error_messages;
        }
      }, (error: any) => {
        this.responseError = error.error.error_fields;
        this.errorMessage = error.error_messages;
      });
  }

  ngOnDestroy() {
    if (this.validLinkSubscriber) {
      this.validLinkSubscriber.unsubscribe();
    }
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
  }
}

export class PasswordItem {
  password: string = '';
  password_confirm: string = '';
}
