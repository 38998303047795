<div class="add-patient">
    <div class="padding-left-15">
        <h3 class="text-header-form-edit">
            <a class="no-focus" [routerLink]="['/admin', 'account']">
                <span class="fa fa-angle-left style-top-arrow"></span>
            </a>
            Add New Patient
        </h3>
    </div>
    <form method="post" #form="ngForm" (ngSubmit)="addPatientForm()" novalidate class="row padding-left-right-15">
        <div class="col-12 col-lg-7 no-padding">
            <div class="card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-edit">Name</h3>
                </div>
                <div class="card-body row">
                    <div class="col-6 no-padding-left">
                        <label for="first_name" class="input-label-required">First Name</label>
                        <input type="text" class="form-control" id="first_name" [(ngModel)]="newPatient.first_name" name="first_name" [class.input-error]="error.first_name" (keyup)="onKeyUp('first_name')" maxlength="50">
                        <div class="message-error">{{ error.first_name }}</div>
                    </div>
                    <div class="col-6 no-padding-right">
                        <label for="last_name" class="input-label-required">Last Name</label>
                        <input type="text" class="form-control" id="last_name" [(ngModel)]="newPatient.last_name" name="last_name" [class.input-error]="error.last_name" (keyup)="onKeyUp('last_name')" maxlength="50">
                        <div class="message-error">{{ error.last_name }}</div>
                    </div>
                </div>
            </div>
            <div class="card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-edit">Email</h3>
                </div>
                <div class="card-body">
                    <label for="email" class="input-label-required">Email Address</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="newPatient.email" name="email" [class.input-error]="error.email" (keyup)="onKeyUp('email')" minlength="5" maxlength="254">
                    <div class="message-error">{{ error.email }}</div>
                </div>
            </div>
            <div class="card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-edit">Insurance</h3>
                </div>
                <div class="card-body row">
                    <div class="col-12 col-md-12 no-padding-left-right">
                        <label for="insurance_id" class="input-label-required">Insurance Plan</label>
                        <select class="form-control" id="insurance_id" [(ngModel)]="newPatient.insurance_id" name="insurance_id" (change)="changeInsurance($event.target.value)" [class.input-error]="error.insurance_id">
                            <option value="" disabled>Select Insurance</option>
                            <option *ngFor="let plan of _systemData.insurances" id="plan.id" [value]="plan.id">{{ plan.name }}</option>
                        </select>
                        <div class="cursorPointer selecter">
                            <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="message-error" *ngIf="error.insurance_id">{{ error.insurance_id }}</div>
                    </div>
                    <div class="col-12 col-md-12 no-padding-left-right padding-bottom-15">
                        <i class="font-text notice-text">* If you cannot find your plan in the list, please contact RTN admin</i>
                    </div>
                    <div class="col-12 col-md-12 no-padding-left-right" id="insurance-number">
                        <label for="insurance_number" class="input-label-required">Insurance ID</label>
                        <input class="form-control" id="insurance_number" type="text" [(ngModel)]="newPatient.insurance_number" name="insurance_number" (keyup)="onKeyUp('insurance_number')" maxlength="50" [class.input-error]="error.insurance_number">
                        <div class="message-error">{{ error.insurance_number }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-5 no-padding-right">
            <div class="card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-edit">Date of Birth</h3>
                </div>
                <div class="card-body px-0">
                    <label for="dob" class="input-label-required pl-3">Date of Birth</label>
                    <div class="col-12 col-md-12 no-padding-left-right">
                        <rtn-dob [formInput]="newPatient" [error]="error.dob" (removeError)="onKeyUp($event)" id="dob"></rtn-dob>
                        <div class="message-error pl-3">{{ error.dob }}</div>
                    </div>
                    <!-- <rtn-calendar class="calender" [inline]="false" [autoclose]="true" [(value)]="dob" [todayHighlight]="ngDatePicker.todayHighlight" [weekStart]="ngDatePicker.weekStart" [defaultViewDate]="ngDatePicker.defaultViewDate" [format]="ngDatePicker.format" #calendar>
                        <input type="text" class="form-control calender-input" [(ngModel)]="dob" (input)="calendar.valueChange.next($event.target.value)" [class.input-error]="error.dob" disabled>
                        <div class="input-group-addon" (click)="onKeyUp('dob')">
                            <span aria-hidden="true" class="fa fa-calendar-o"></span>
                        </div>
                    </rtn-calendar>
                    <div class="message-error">{{ error.dob }}</div> -->
                </div>
            </div>
            <div class="card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-edit">ZIP Code</h3>
                </div>
                <div class="card-body">
                    <label for="zip" class="input-label-required">ZIP Code</label>
                    <input type="text" class="form-control" id="input-zip" [(ngModel)]="newPatient.zip" name="zip" [class.input-error]="error.zip" (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'" [target]="newPatient" [field]="'zip'" maxlength="10">
                    <div class="message-error">{{ error.zip }}</div>
                </div>
            </div>
            <div class="card card-default">
                <div class="card-header">
                    <h3 class="card-title text-title-form-edit">Mobile Phone Number</h3>
                </div>
                <div class="card-body">
                    <label for="phone_mobile">Mobile Phone Number</label>
                    <div>
                        <div class="col-12 col-md-10 no-padding-left-right">
                            <input type="text" class="form-control" [(ngModel)]="newPatient.phone_mobile" name="phone_mobile" [class.input-error]="error.phone_mobile" (keyup)="onKeyUp('phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="message-error">{{ error.phone_mobile }}</div>
                </div>
            </div>
        </div>
        <div class="col-12 padding-bottom-20 no-padding-left">
            <button type="submit" class="container btn btn-rtn btn-90 text-uppercase">Create</button>
        </div>
    </form>
</div>
