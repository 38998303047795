<header>
<rtn-alert-download [class.alert-download]="openAlertDownload" (checkDevice)="handleCheckDevice($event)"
                    (click)="onClose($event)"></rtn-alert-download>
   <section class="navbar-aside-outer d-flex align-items-center flex-md-row flex-column">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-6 col-sm-5 col-12 socialMedia d-flex align-items-center flex-row">
                  <!--<h6>Follow Us:</h6>
                  <ul class="list-group d-flex align-items-center flex-row">
                    <li><a href="#" target="_blank" title="Facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="#" target="_blank" title="Twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                    <li><a href="#" target="_blank" title="Instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                    <li><a href="#" target="_blank" title="Linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                  </ul>-->
                
              </div>
              <div class="contact-info col-lg-6 col-sm-7 col-12 d-flex justify-content-end align-items-center">
                    <ul class="d-flex align-items-center flex-row">
                        <!--<li class="d-flex align-items-center">
                          <span><img src="{{ src }}phone_icon.png"></span>
                          <span><a href="tel:2122263126">(+212)-226-3126</a></span>
                        </li>-->
                        <li class="d-flex align-items-center">
                            <span><img src="{{ src }}envelope_icon.png"></span>
                            <span><a href="mailto:info@realtimeneighborhood.com">info@realtimeneighborhood.com</a></span>
                          </li>
                    </ul>
               
              </div>
          </div>
      </div>
   </section>
<section class="container-fluid position-relative">
<nav class="d-flex justify-content-between navbar navbar-expand-lg col-sm-12 col-md-12 px-3 py-2"
     [class.has-download]="_systemData.localStorage.getItem('app_download_ignore') === '0' || _systemData.localStorage.getItem('app_download_ignore') === null">
    <div class="d-flex justify-content-between navWrapper">
     <div class="logoSection">
     <a class="navbar-brand logo-position d-none d-sm-block no-focus m-auto" href="https://www.realtimeneighborhood.com" target="_blank">
        <img class="rtn-logo m-auto" src="{{ src }}logo.jpg" alt="Realtimeneighborhood Logo"/>
    </a>
    <a class="navbar-brand d-block d-sm-none no-focus m-auto" [routerLink]="['/home']">
        <img class="rtn-logo m-auto" src="{{ src }}logo.jpg" alt="Realtimeneighborhood Logo"/>
    </a>
  
   </div>
   <div class="mobileLogin">
    <div class="login-info" *ngIf="!_systemData.isLogined">
        <button type="button" class="navbar-toggler" (click)="openMainMenufunction()">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul class="d-flex">
        <!--<li role="presentation">
            <a class="d-flex justify-content-center align-items-center" [routerLink]="['/home', 'front-sign-up']">
                <span>SignUp</span>
                <span class="arrowIcon"><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
            </a>
        </li>-->
        <li role="presentation">
            <a  class="d-flex justify-content-center align-items-center"  [routerLink]="['/home', 'login']">
                <span>Log In</span>
                <span class="arrowIcon"><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
            </a>
        </li>
    </ul>
    </div>
    <div class="login-info" *ngIf="_systemData.isLogined">
        <ul class="d-flex">
        <!--<li role="presentation" class="hidden-xs"><a class="no-focus text-overflow-ellipsis">{{_systemData.localStorage.getItem('userFullName')}}</a></li>-->
        <li role="presentation" class="d-none d-sm-block nav-item">
            <a class="no-focus text-overflow-ellipsis nav-link">
                {{_systemData.localStorage.getItem('userFullName')}}
            </a>
        </li>
        <!--<li role="presentation"><a (click)="logoutDirective.open()" class="cursorPointer padding-left-15 right-panel no-focus">Log Out</a></li>-->
        <li role="presentation" *ngIf="!isManagingMode" class="nav-item">
            <a class="cursorPointer padding-left-15 right-panel no-focus nav-link"
            (click)="logoutDirective.open()">Log Out</a>
        </li>
        <li role="presentation" *ngIf="isManagingMode" class="nav-item">
            <a class="cursorPointer padding-left-15 right-panel no-focus nav-link" (click)="logoutManaging()">
                Log Out
            </a>
        </li>
    </ul>
    </div>
</div>
</div>
    <div class="desktopMenu collapse navbar-collapse d-lg-flex justify-content-lg-end justify-content-sm-start align-items-center mainM" [class.show]="isShowCollapse" id="btn_menu_collspse">
        <ul class="navbar-nav">
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/about-us" target="_blank">About Us </a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/features" target="_blank">Features</a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/providers" target="_blank">Providers</a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/patients" target="_blank">Patients</a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/contact-us" target="_blank">Contact Us</a>
            </li>
            <li role="presentation" class="nav-item"
                *ngIf="_systemData.isLogined && _systemData.localStorage.getItem('user_role_id') == '4'">
                <a class="no-focus nav-link" [routerLink]="['/patient', 'dashboard']"
                    [class.active]="_systemData.isLogined && showHighlight">User Portal</a>
            </li>
           
        </ul>
        <div class="desktopLogin">
            <div class="login-info" *ngIf="!_systemData.isLogined">
                <ul class="d-flex">
                <!--<li role="presentation">
                    <a class="d-flex justify-content-center align-items-center" [routerLink]="['/home', 'front-sign-up']">
                        <span>SignUp</span>
                        <span class="arrowIcon"><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
                    </a>
                </li>-->
                <li role="presentation">
                    <a  class="d-flex justify-content-center align-items-center"  [routerLink]="['/home', 'login']">
                        <span>Log In</span>
                        <span class="arrowIcon"><img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/></span>
                    </a>
                </li>
            </ul>
            </div>
            <div class="login-info" *ngIf="_systemData.isLogined">
                <ul class="d-flex">
                <!--<li role="presentation" class="hidden-xs"><a class="no-focus text-overflow-ellipsis">{{_systemData.localStorage.getItem('userFullName')}}</a></li>-->
                <li role="presentation" class="d-none d-sm-block nav-item">
                    <a class="no-focus text-overflow-ellipsis nav-link">
                        {{_systemData.localStorage.getItem('userFullName')}}
                    </a>
                </li>
                <!--<li role="presentation"><a (click)="logoutDirective.open()" class="cursorPointer padding-left-15 right-panel no-focus">Log Out</a></li>-->
                <li role="presentation" *ngIf="!isManagingMode" class="nav-item">
                    <a class="cursorPointer padding-left-15 right-panel no-focus nav-link"
                    (click)="logoutDirective.open()">Log Out</a>
                </li>
                <li role="presentation" *ngIf="isManagingMode" class="nav-item">
                    <a class="cursorPointer padding-left-15 right-panel no-focus nav-link" (click)="logoutManaging()">
                        Log Out
                    </a>
                </li>
            </ul>
            </div>
        </div>
    </div>
    
</nav>

<div class="mainMobileNav"  [class.open-main-nav]="MainMobileMenu">
    <div class="account-nav" >
        <div class="close account-nav-btn" (click)="closeMainMenufunction()">
            <span class="first"></span>
            <span class="last"></span>
        </div>
        <ul class="navbar-nav">
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/about-us" target="_blank">About Us </a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/features" target="_blank">Features</a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/providers" target="_blank">Providers</a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/patients" target="_blank">Patients</a>
            </li>
            <li role="presentation" class="nav-item">
                <a class="no-focus nav-link" href="https://www.realtimeneighborhood.com/contact-us" target="_blank">Contact Us</a>
            </li>
            <li role="presentation" class="nav-item"
                *ngIf="_systemData.isLogined && _systemData.localStorage.getItem('user_role_id') == '4'">
                <a class="no-focus nav-link" [routerLink]="['/patient', 'dashboard']"
                    [class.active]="_systemData.isLogined && showHighlight">User Portal</a>
            </li>
        
        </ul>
    </div>
  
 </div>
 

</section>
<rtn-modals-general-logout></rtn-modals-general-logout>
</header>
