import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { SystemData, SystemEvent, SystemService } from '@app/shared/system';
import { DataTableDirectives, RtnDataTableCoreComponent } from '@app/shared/datatable';
import { Utils } from '@app/shared/utils';
import { AlertService } from '@app/shared/alert';
import { Subscription } from 'rxjs';
import { ProvidersService } from '../providers.service';
import { FindAppointment } from '@app/data-logs/providers-waittime/chart-wait-time.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
@Component({
    selector: 'app-view-appointments',
    templateUrl: './view-appointments.component.html',
    styleUrls: ['./view-appointments.component.scss']
})
export class ViewAppointmentsComponent implements OnInit {
    appointmentInFutureData: any = [];
    appointmentInPastData: any = [];

    findMsg: string = null;
    findInFutureMsg: string = null;
    findInPastMsg: string = null;

    findAppointmentSubscriber: Subscription;
    appointmentData: any;
    findAppointment: FindAppointment = new FindAppointment();
    showAppointmentByTimeTable = false;
    showAppointmentTable = false;
    user_id: any;
    
    @ViewChild('table') dataTableApointment: RtnDataTableCoreComponent;
    @ViewChild('tableFuture') dataTableApointment1: RtnDataTableCoreComponent;

    constructor(
        private _routeParams: ActivatedRoute,
        public _systemData: SystemData,
        private _systemEvent: SystemEvent,
        private _systemService: SystemService,
        public _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _alertService: AlertService,
        private Service: ProvidersService,
        public _router: Router,
    ) { }

    ngOnInit() {
        this.searchAppointment();
    }
    viewDetailAppt(plan_check_in:any){
        var patientid = parseInt(this._routeParams.snapshot.paramMap.get('id'));
        this._router.navigate(['/provider','appointment', patientid,this.formatDate(plan_check_in)]).then(() => {
            window.location.reload();
          });
    }
    // selectTheFirstFutureResult() {
    //     this._waitingTimeService.startWaitTimeInterval();
    //     this.appointmentInFutureData[0].isSelected = true;
    //     this.currentAppointment = this.appointmentInFutureData[0];
    //     this.waitingTimeDirective.appointmentId = this.currentAppointment.id;
    //     this.waitingTimeDirective.appointmentDetail.is_active = this.currentAppointment.is_active;
    //     this.waitingTimeDirective.appointmentDetail.enableWaitTimeMeter = true;
    // }

    onDataChange(e: any) {
        // this.page = e['currentPage'];
        this.searchAppointment();
    }
    formatDate(date: string) {
        if (date) {
          return moment.utc(date).local().format('MM-DD-YYYY');
        }
    }
    returnFindInFutureMsg(){
        if (this.appointmentInFutureData.length === 0) {
            this.findInFutureMsg = null;
        }
    }
    returnFindInPastMsg(){
        if (this.appointmentInPastData.length === 0) {
            this.findInPastMsg = null;
        }
    }

    searchAppointment() {
        this._loadingIndicatorService.start();
        this.findMsg = null;
        this.findInFutureMsg = null;
        this.findInPastMsg = null;
        if (this.findAppointmentSubscriber) {
            this.findAppointmentSubscriber.unsubscribe();
        }
        this.findAppointmentSubscriber = this.Service.getApointmentList(parseInt(this._routeParams.snapshot.paramMap.get('id'))).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                const res = success;
                if (res.error_code === 'RC000') {
                    this.appointmentData = res.appointments;
                    if (this.appointmentData.length > 0) {
                        if (this.findAppointment.date < 1) {
                            this.showAppointmentByTimeTable = true;
                            this.showAppointmentTable = false;
                            this.appointmentInFutureData = res.appointmentInFuture;
                            // if (this.appointmentInFutureData.length === 0) {
                            //     this.findInFutureMsg = null;
                            // }
                            this.returnFindInFutureMsg();
                            this.appointmentInPastData = res.appointmentInPast;
                            // if (this.appointmentInPastData.length === 0) {
                            //     this.findInPastMsg = null;
                            // }
                            this.returnFindInPastMsg();
                        } else {
                            this.showAppointmentByTimeTable = false;
                            this.showAppointmentTable = true;
                            // this.showSpeedometer = true;
                            setTimeout(() => {
                                // this.selectTheFirstResult();
                            }, 1000);
                        }
                    } else {
                        this.showAppointmentByTimeTable = false;
                        this.showAppointmentTable = false;
                        this.findMsg = 'There is no appointment';
                        this.appointmentInPastData = [];
                        this.appointmentInFutureData = [];
                    }
                }
                // this.specialtiesSelector.updateData(this.findApptSpecialty);
            }, (error: any) => {
                this._loadingIndicatorService.stop();
                // this.specialtiesSelector.updateData(this.findApptSpecialty);
            }, () => {
                this._loadingIndicatorService.stop();
                // this.specialtiesSelector.updateData(this.findApptSpecialty);
                // If click link from notification

                // if (!this.openFromNotifi) {
                //     const apptId = parseInt(this._utils.getUrlParameter('appt_id'), 10);
                //     const popup = parseInt(this._utils.getUrlParameter('popup'), 10);
                //     if (this._router.url.split('?')[0] === '/patient/appointment/find' && apptId > 0 && popup === 1) {
                //         for (let i = 0; i < this.appointmentData.length; i++) {
                //             if (this.appointmentData[i].id === apptId) {
                //                 this.appointmentDetails(this.appointmentData[i]);
                //                 this.openFromNotifi = true;
                //                 break;
                //             }
                //         }
                //     }
                // }
            });
        jQuery('.bulk.enable').css({ display: 'none' });
        jQuery('.bulk.disable').css({ display: 'block' });
    }

}
