<div *ngIf="event!=null" class="tl-tooltip-content">
  <div class="row">
    <div class="col-12 py-0 patient"><span class="pull-left">Patient</span><span
      class="pull-right">{{event.data.patientName}}</span></div>
    <div class="col-12 py-0 separator"></div>
  </div>
  <div class="row">
    <div class="col-12 py-0"><span class="pull-left">DOB</span><span class="pull-right">{{event.data.patientDob}}</span>
    </div>
    <div class="col-12 py-0 separator"></div>
  </div>
  <div class="row">
    <div class="col-12 py-0"><span class="pull-left">Date</span><span class="pull-right">{{event.date}}</span></div>
    <div class="col-12 py-0 separator"></div>
  </div>
  <div class="row">
    <div class="col-12 py-0"><span class="pull-left"><span *ngIf="event.type==0">Planned</span><span *ngIf="event.type!=0">Actual</span> Time</span><span
      class="pull-right">{{event.startTimeFormatted24h}} - {{ event.endTimeFormatted24h }}</span></div>
    <div class="col-12 py-0 separator"></div>
  </div>
  <div class="row">
    <div class="col-12 py-0"><span class="pull-left"><span *ngIf="event.type==0">Planned</span><span *ngIf="event.type!=0">Actual</span> Duration</span><span
      class="pull-right">{{_utils.generateValueUnitDisplay(event.duration, "min")}}</span></div>
    <div class="col-12 py-0 separator"></div>
  </div>
  <div class="row" *ngIf="event.data.appointment_event_name">
    <div class="col-12 py-0"><span class="pull-left">Type</span><span class="pull-right">{{event.data.appointment_event_name}}</span></div>
    <div class="col-12 py-0 separator"></div>
  </div>
  <div class="row" *ngIf="event.data.location_name">
    <div class="col-12 py-0"><span class="pull-left">Location</span><span class="pull-right">{{event.data.location_name}}</span></div>
    <div class="col-12 py-0 separator"></div>
  </div>   

  
  <div class="row" *ngIf="event.data.cancel_status_text == ''">
    <div *ngIf="!event.hasError" class="col-12 py-0">        
        <span *ngIf="event.data.status !== event.data.status_table[4]">
          <span class="pull-left">Status</span>
          <span class="pull-right">{{event.data.progress_status}}</span>
        </span>
        <span *ngIf="event.data.status === event.data.status_table[4]">
          <span class="pull-left">Status</span>
          <span class="pull-right">{{event.data.status}}</span>
        </span>                
    </div>
    <div class="col-12 py-0 separator"></div>
  </div>
  
  <ng-container *ngIf="event.data.cancel_status_text != ''">
    <div class="row" >
      <div *ngIf="!event.hasError" class="col-12 py-0">
        <span>
          <span class="pull-left">Status</span>
          <span class="pull-right">{{event.data.cancel_status_text}}</span>
        </span>       
      </div>
      <div class="col-12 py-0 separator"></div>
    </div>
    <div class="row" >
      <div *ngIf="!event.hasError" class="col-12 py-0">
        <span>
          <span class="pull-left" *ngIf="event.data.cancel_status_text == 'Cancel'">Cancelled At</span>
          <span class="pull-left" *ngIf="event.data.cancel_status_text == 'No Show'">No-Show At</span>
          <span class="pull-right">{{event.data.cancelled_at}}</span>
        </span>       
      </div>
      <div class="col-12 py-0 separator"></div>
    </div>
  </ng-container>

  <div class="row" *ngIf="event.data.appointment_reason">
    <div class="col-12 py-0">
      <span class="pull-left"><strong>Reason:</strong></span><br/>
      <p class="pull-left">{{event.data.appointment_reason}}</p>
    </div>
    <div *ngIf="event.hasError" class="col-12 py-0 separator"></div>
  </div>
  <div class="row" *ngIf="event.hasError" >
    <div class="col-12 py-0 color-error"><span class="pull-left">Error</span><span
      class="pull-right">{{event.error_message}}</span></div>
  </div>
</div>
