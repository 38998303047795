<modal #PatientNewSignUp_Modals class="patient-new-sign-up">
    <form class="form" novalidate>
        <div class=" form-content">
            <div class=" form-header">Sign-Up New Patient</div>
            <div class=" form-info">
                <div class="row">
                    <div class="col-6 form-input-label input-label-required">Email Address</div>
                    <input (keyup)="onKeyUp('email')" [class.input-error]="error.email != null"
                           class="col-6 form-input" type="text" [(ngModel)]="signUpPatient.email" name="email" maxlength="254">
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.email }}</div>
                </div>

                <div class="row">
                    <div class="col-6 form-input-label input-label-required">First Name</div>
                    <input (keyup)="onKeyUp('first_name')" [class.input-error]="error.first_name != null"
                           class="col-6 form-input" type="text" [(ngModel)]="signUpPatient.first_name"
                           name="first_name" maxlength="50">
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.first_name }}</div>
                </div>
                <div class="row">
                    <div class="col-6 form-input-label input-label-required">Last Name</div>
                    <input (keyup)="onKeyUp('last_name')" [class.input-error]="error.last_name != null"
                           class="col-6 form-input" type="text" [(ngModel)]="signUpPatient.last_name" name="last_name" maxlength="50">
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.last_name }}</div>
                </div>

                <div class="row">
                    <div class="col-6 form-input-label input-label-required">Date of Birth</div>
                    <div class="col-6 dob-select">
                        <rtn-dob [formInput]="signUpPatient" [error]="error.dob" (removeError)="onKeyUp($event)"
                                 id="dob"></rtn-dob>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.dob }}</div>
                </div>
                <div class="row">
                    <div class="col-6 form-input-label-required">Mobile Phone Number</div>
                    <div class="col-6 number-area">
                        <div class="col-12 number-enter">
                            <input class="form-input numberic" type="text" (keyup)="onKeyUp('phone_mobile')"
                                   [class.input-error]="error.phone_mobile" [(ngModel)]="signUpPatient.phone_mobile"
                                   mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false"
                                   name="phone_mobile">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.phone_mobile }}</div>
                </div>
                <div class="row">
                    <div class="col-6 form-input-label input-label-required">ZIP Code</div>
                    <input class="col-6 form-input" type="text" (keyup)="onKeyUp('zip')"
                           [class.input-error]="error.zip" [(ngModel)]="signUpPatient.zip" simplemask
                           [format]="'#####-####'" [target]="signUpPatient" [field]="'zip'" name="zip" maxlength="10">
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.zip }}</div>
                </div>

                <div class="row">
                    <div class="col-6 form-input-label input-label-required">Insurance Plan</div>
                    <div class="col-6 form-input no-padding-left-right no-border">
                        <select class="form-control padding-right-20" [(ngModel)]="signUpPatient.insurance_id"
                                (change)="changeInsurance($event.target.value)"
                                name="insurance_id" [class.input-error]="error.insurance_id">
                            <option value="" disabled>Select Insurance</option>
                            <option *ngFor="let plan of insurances" id="plan.id"
                                    [value]="plan.id">{{ plan.name }}</option>
                        </select>
                        <div class="cursorPointer selecter">
                            <i class="fa fa-caret-down"></i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.insurance_id }}</div>
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <i class="px-0 col-6 notice-text">* If you cannot find your plan in the list, please
                        contact RTN admin</i>
                </div>

                <div class="row showInsuranceNumber">
                    <div class="col-6 form-input-label input-label-required">Insurance ID</div>
                    <input class="col-6 form-input" type="text" id="insurance_number"
                           [(ngModel)]="signUpPatient.insurance_number" (keyup)="onKeyUp('insurance_number')"
                           name="insurance_number" maxlength="50" [class.input-error]="error.insurance_number">
                </div>
                <div class="row showInsuranceNumber">
                    <div class="col-6"></div>
                    <div class="col-6 message-error px-0">{{ error.insurance_number }}</div>
                </div>
            </div>
        </div>
        <div class=" button">
            <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel text-uppercase" data-dismiss="modal"
                    (click)="dismiss()">Cancel
            </button>
            <button type="submit" class="btn btn-rtn btn-90 float-right text-uppercase" [disabled]="buttonclicked" (click)="submitSignUpClicked()"
                    autofocus>Submit
            </button>
        </div>
    </form>
</modal>
