import {Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Subscription} from 'rxjs';
import {AppointmentsService} from '@app/appointments/appointments.service';
import * as moment from 'moment';
import {Utils} from '@app/shared/utils';
import {AppointmentBookWaitlistComponent} from '@app/appointments/modals/appointment-book-waitlist/appointment-book-waitlist.component';
import {SystemData, SystemInterval} from '@app/shared/system';
import {TimelineComponent} from '../timeline/timeline.component';
import {Router} from '@angular/router';
import { RtnDataTableCoreComponent } from '@app/shared/datatable';
import { AddWaitlistComponent } from '../modals/add-waitlist/add-waitlist.component';
import {AlertService} from '@app/shared/alert';
import {PatientsService} from '@app/patients/patients.service';
import { NotifyAvailableSlotWaitlistComponent } from '../modals/notify-available-slot-waitlist/notify-available-slot-waitlist.component';
import { EditWaitlistComponent } from '../modals/edit-waitlist/edit-waitlist.component';

@Component({
  selector: 'app-search-openings',
  templateUrl: './search-openings.component.html',
  styleUrls: ['./search-openings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SystemData, SystemInterval, Utils]
})
export class SearchOpeningsComponent implements OnInit, AfterViewInit {
  listSaveSearch: any = new Object();
  numberSavedSearchForOpening = 0;
  getNotifySavedOpenAppointmentSettingSubscriber: Subscription;
  savedSearchForOpeningData: any;
  getIsLockSubscriber: Subscription;
  isLock: any;
  runRequest: boolean = true;
  page: number = 1;
  rowOfPage: number = 10;
  searchText: string = '';
  @ViewChild(TimelineComponent) timelineDirective: TimelineComponent;
  @ViewChild(AddWaitlistComponent) addWaitListDirective: AddWaitlistComponent;
  @ViewChild(AppointmentBookWaitlistComponent) appointmentBookWaitListDirective: AppointmentBookWaitlistComponent;
  @ViewChild(NotifyAvailableSlotWaitlistComponent) notifyAvailableSlotWaitlistDirective: NotifyAvailableSlotWaitlistComponent;
  @ViewChild(EditWaitlistComponent) editWaitListDirective: EditWaitlistComponent;
    
  deleteSavedSearchForOpeningSubscriber: Subscription;
  moveSavedSearchForOpeningSubscriber: Subscription;

  getWaitlistAlertSettingSubscriber: Subscription;
  saveWaitlistAlertSettingSubscriber: Subscription;
  saveWaitlistPrioritySubscriber: Subscription;

  saved_opening_alert_settings : any = {};
  //error_alert_email_ids : string = '';
  selectedTab:number = 1;
  blockedSavedSearchForOpeningSubscriber: Subscription;
  blockPatientLists : any = [];

  constructor(
    private Service: AppointmentsService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
    public _systemInterval: SystemInterval,
    public _systemData: SystemData,
    public _router: Router,
    private _alertService: AlertService,
    private _dashboardService: PatientsService
  ) {
  }
  
  ngOnInit() {
    this.listSaveSearch.data = new Array();
    this.listSaveSearch.numberOfPages = 0;
    this.listSaveSearch.rowsOnPage = 10;
    this.getLockProvider();
    this.getWaitlistAlertSetting();
    this.getNotifySavedOpenAppointmentSetting(this.page, null, this.selectedTab);
  }
  ngOnDestroy() {
    if (this.deleteSavedSearchForOpeningSubscriber) {
      this.deleteSavedSearchForOpeningSubscriber.unsubscribe();
    }
    
    if (this.getWaitlistAlertSettingSubscriber) {
      this.getWaitlistAlertSettingSubscriber.unsubscribe();
    }

    if (this.saveWaitlistAlertSettingSubscriber) {
      this.saveWaitlistAlertSettingSubscriber.unsubscribe();
    }

    if (this.saveWaitlistPrioritySubscriber) {
      this.saveWaitlistPrioritySubscriber.unsubscribe();
    }  
    if (this.blockedSavedSearchForOpeningSubscriber) {
      this.blockedSavedSearchForOpeningSubscriber.unsubscribe();
    }   
    if (this.moveSavedSearchForOpeningSubscriber) {
      this.moveSavedSearchForOpeningSubscriber.unsubscribe();
    }     
  }
  ngAfterViewInit(): void {

  }

  getLockProvider() {
    this._loadingIndicatorService.start();
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.isLock = success.providers.is_lock;
        if (this.isLock) {
          this._router.navigate(['/provider', 'appointment']);
        }

      }
    );

  }

  fomartTimeShowInList(time: string) {
    let value = '';
    if (time) {
      if (time === '00:00:00') {
        value = '00:00 AM';
      } else if (time === '00:30:00') {
        value = '00:30 AM';
      } else {
        value = this._utils.formatTimeString(time, 'LT');
      }
    }
    if (value.substr(1, 1) === ':') {
      value = '0' + value;
    }
    return value;
  }

  getNotifySavedOpenAppointmentSetting(page: number, search_text: string = null, tab_order: number = 1) {
    this.runRequest = false;
    this._loadingIndicatorService.start();
    if (this.getNotifySavedOpenAppointmentSettingSubscriber) {
      this.getNotifySavedOpenAppointmentSettingSubscriber.unsubscribe();
    }
    this.getNotifySavedOpenAppointmentSettingSubscriber
      = this.Service.getNotifySavedOpenAppointmentSetting(page, search_text, this.rowOfPage, tab_order).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.listSaveSearch.numberOfPages = success.last_page || 0;
        this.savedSearchForOpeningData = success.saved_opening_settings || [];
        this.numberSavedSearchForOpening = success.total_records || 0;
        this.savedSearchForOpeningData.map((item: any, i: any) => {
          
          if (item.start_datetime != undefined && item.start_datetime != null && item.start_datetime != '') {
            item.date = moment(item.start_datetime).format('MMMM D, YYYY') + ' - '
            + moment(item.end_datetime).format('MMMM D, YYYY');

            if (item.start_time === '00:00:00' && item.end_time === '23:59:59') {
              item.time = '';
            } else {
              item.time = moment(item.start_datetime).format('hh:mm A') + ' - '
              + moment(item.end_datetime).format('hh:mm A');
            }
          } else {
            item.date = moment(item.start_date).format('MMMM D, YYYY') + ' - '
            + moment(item.end_date).format('MMMM D, YYYY');

            if (item.start_time === '00:00:00' && item.end_time === '23:59:59') {
              item.time = '';
            } else {
              item.time = this.fomartTimeShowInList(item.start_time) + ' - ' + this.fomartTimeShowInList(item.end_time);
            }
          }
          
          if (item.distance) {
            item.distance = 'Within ' + item.distance + ' miles';
          }

        });
        this.listSaveSearch.data = this.savedSearchForOpeningData;
        this.runRequest = true;
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      }
    );

  }
  onDataChange(e: any) {
    this.page = e['currentPage'];
    this.getNotifySavedOpenAppointmentSetting(this.page, this.searchText.trim(), this.selectedTab);
  }

  onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
    if (this.runRequest) {
      if (this.page === 1) {
        this.getNotifySavedOpenAppointmentSetting(this.page, this.searchText.trim(), this.selectedTab);
      } else {
        dataTable.setPage(1, 10);
      }
    }
  }
  showConfirmDeleteAlertService(id: any) {
    this._alertService.onClose = () => {
      const savedId = {'saved_id': id};
      this.deleteSavedSearchForOpeningSubscriber
        = this._dashboardService.deleteNotifySavedOpenAppointmentSetting(savedId).subscribe(
        (success: any) => {
          if (success.error_code === 'RC000') {
            this.deleteSavedSearchForOpening(id);
          }
        }
      );
    };
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.closeButtonLabel = 'Yes';
    this._alertService.showDismissButton = true;
    this._alertService.showCloseButton = true;
    this._alertService.title = 'Are you sure you want to delete this waitlist?';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  deleteSavedSearchForOpening(id: any) {
    this._alertService.onClose = () => {
      // TODO
      this.getNotifySavedOpenAppointmentSetting(1, null, this.selectedTab);
    };
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.showDismissButton = false;
    this._alertService.showCloseButton = true;
    this._alertService.title = 'The waitlist is deleted successfully!';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  getWaitlistAlertSetting() {
    this._loadingIndicatorService.start();
    this.getWaitlistAlertSettingSubscriber = this.Service.getWaitlistAlertSettings().subscribe((data: any) => {
        this._loadingIndicatorService.stop();
        this.saved_opening_alert_settings = data.saved_opening_alert_settings;
      }
    );
  }
  manuallySend() {
    this._loadingIndicatorService.start();
    this.getWaitlistAlertSettingSubscriber = this.Service.notifyWaitlistToAdmin().subscribe((data: any) => {
        //console.log(data);
        this._loadingIndicatorService.stop();
       // alert('Send');
      this._alertService.onClose = () => {

      };
      this._alertService.dismissButtonLabel = 'Close';
      this._alertService.closeButtonLabel = 'OK';
      this._alertService.showDismissButton = false;
      this._alertService.showCloseButton = true;
      this._alertService.title = data.error_messages;
      this._alertService.message = '';
      this._alertService.emitter('open');

      },(error: any) => {
        this._loadingIndicatorService.stop();        
      }
    );
  }
  
  /*saveWaitlistAlertSetting() {
    if (this.alert_email_ids == '') {
      this.error_alert_email_ids = 'Please enter email id';
    } else {
      this.error_alert_email_ids = '';
      let inputdata = { 'email_ids' : this.alert_email_ids };

      this._loadingIndicatorService.start();
      this.saveWaitlistAlertSettingSubscriber = this.Service.saveWaitlistAlertSettings(inputdata).subscribe((data: any) => {
          this._loadingIndicatorService.stop();
          this.alert_email_ids = data.saved_opening_alert_settings;
        }
      );
    }
  }
  changePriority(priority:any, id:any){
    if (priority != '') {
      let inputdata = {
        'id' : id,
        'sort_order' : priority
      };
      this._loadingIndicatorService.start();
      this.saveWaitlistPrioritySubscriber = this.Service.saveWaitlistPriority(inputdata).subscribe((data: any) => {
        this._loadingIndicatorService.stop();
        this.page = 1;
        this.getNotifySavedOpenAppointmentSetting(this.page, null, this.selectedTab);          
      });
    }
  }*/
  openTab(idx : number) {
    this.selectedTab = idx;

    this.getNotifySavedOpenAppointmentSetting(1, null, this.selectedTab);

  }
  selectePatientFromWl(id:any) {
    if (this.blockPatientLists.indexOf(id) === -1) {  
      this.blockPatientLists.push(id);
    }
  }
  blocktosendnotification(mode: any) {
      const blockedForm = {'patient_user_id' : this.blockPatientLists, 'action' : mode};
      this._loadingIndicatorService.start();
      this.blockedSavedSearchForOpeningSubscriber = this._dashboardService.blockUserFromWaitList(blockedForm).subscribe((success: any) => {
        this._loadingIndicatorService.stop();
        // alert('Send');
        this._alertService.onClose = () => {
          window.location.reload();
        };
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.title = success.error_messages;
        this._alertService.message = '';
        this._alertService.emitter('open');
        //this.getNotifySavedOpenAppointmentSetting(1, null, this.selectedTab);
      }, (error:any) => {
        this._loadingIndicatorService.stop(); 
      });
  } 
  
  showConfirmMoveAlertService(id: any) {
    this._alertService.onClose = () => {      
      this.moveSavedSearchForOpeningSubscriber = this._dashboardService.movedNotifySavedOpenAppointmentSetting(id).subscribe(
        (success: any) => {
          if (success.error_code === 'RC000') {
            this.MoveSavedSearchForOpening();
          }
        }
      );
    };
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.closeButtonLabel = 'Yes';
    this._alertService.showDismissButton = true;
    this._alertService.showCloseButton = true;
    this._alertService.title = 'Are you sure you want to move this to Existing Patient List?';
    this._alertService.message = '';
    this._alertService.emitter('open');
  } 
  MoveSavedSearchForOpening() {
    this._alertService.onClose = () => {
      // TODO
      this.getNotifySavedOpenAppointmentSetting(1, null, this.selectedTab);
    };
    this._alertService.dismissButtonLabel = 'Close';
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.showDismissButton = false;
    this._alertService.showCloseButton = true;
    this._alertService.title = 'The waitlist is moved successfully!';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
}
