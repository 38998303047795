import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {AlertService} from '@app/shared/alert';
import {AlertDownloadComponent} from '@app/shared/alert-download/alert-download.component';
import {RestService} from '@app/core/rest.service';
import { Utils } from '@app/shared/utils';

// declare let jQuery: any;
import * as $ from 'jquery';
import * as jQuery from 'jquery';
import 'popper.js';
import 'bootstrap';

import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import { HomepageService } from '@app/home/home.service';

@Component({
  selector: 'rtn-home-home',
  moduleId: module.id,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: []
})
export class HomePageComponent implements OnInit, AfterViewInit {

  positionOfSlide = 0;
  src = '../../../assets/img/homepage/newDesign/';
  sliders: { id: number; src: string; title: string; class: string }[];
  features: { id: number; src: string, title: string, url: string }[];
  capabilities: { title: string; desc: string }[];
  desc = 'We’ve all experienced it. Waiting for hours on end in your doctor’s office, checking the clock ' +
    'wondering if your name will ever be called. Or worse yet, leaving in frustration without ever being seen. ' +
    'Although the circumstances may differ, it’s an all too common theme but one heard everyday around the world: ' +
    '“What about my time?” Solving the number - one complaint by patients - and medical providers alike - ' +
    'is the reason Real Time Neighborhood - RTN - was created.';

  @ViewChild(AlertDownloadComponent) _alertDownload: AlertDownloadComponent;

  requestDemoSubscriber: Subscription;
  requestdemoModel: RequestDemoClass = new RequestDemoClass();
  requireMessageDemo: RequireMessageDemoClass = new RequireMessageDemoClass();
  errorMessageBook:string='';
  formDemoSubmitted:boolean = false;

  constructor(
    private _router: Router,
    private _utils: Utils,
    private service: RestService,
    private _homeService: HomepageService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.sliders = [
      {
        id: 0,
        src: this.src + 'banner_01.png',
        title: 'Isn’t it time you stopped waiting?',
        class: 'active'
      },
      {
        id: 1,
        src: this.src + 'banner_02.png',
        title: 'Time is money. Make the most of yours.',
        class: ''
      },
      {
        id: 2,
        src: this.src + 'banner_03.png',
        title: 'We calculate the wait time so you can make the most out of your day.',
        class: ''
      },
      {
        id: 3,
        src: this.src + 'banner_04.png',
        title: 'Your time. Spend it wisely.',
        class: ''
      }
    ];

    this.features = [
      {
        id: 0,
        src: this.src + 'forpatient.png',
        title: 'for patients',
        url: '/Login'
      },
      {
        id: 1,
        src: this.src + 'fordoctor.png',
        title: 'for doctor',
        url: '/Login'
      },
      {
        id: 2,
        src: this.src + 'requestdemo.png',
        title: 'request demo',
        url: '/Login'
      },
      /*{
        id: 3,
        src: this.src + 'getApp.png',
        title: 'get app',
        url: '/Login'
      }*/
    ];

    this.capabilities = [
      {
        title: 'Time Management',
        desc: 'Avoid waiting in your doctor’s office and spend your time well.'
      },
      {
        title: 'Notifications',
        desc: 'Receive custom notifications sent directly to your phone or inbox with real time wait info.'
      },
      {
        title: 'Appointments',
        desc: 'Integrates directly with most EHR\'s and seamlessly with your current workflow.'
      },
      {
        title: 'Customer Service',
        desc: 'Improve relationships between doctors and patients'
      }
    ];

    if (jQuery(window).width() < 768) {
      setTimeout(() => {
        this.positionOfSlide = $('.navbar-static-top').height();
      }, 1000);
    }
  }

  ngOnInit(): void {
    let user_role = localStorage.getItem('user_role_id');
    let user_status = localStorage.getItem('user_status');
    let user_practice = localStorage.getItem('user_practice');
    let user_id = localStorage.getItem('user_id');
    if (user_role === "1") {
      this._router.navigate(['/admin', 'account']);
    } else if (user_role === "2") {
        // this._router.navigate(['/superProvider', 'account']);
        this._router.navigate(['/provider/practice', 'add']);
    } else if (user_role === "3") {
        this._router.navigate(['/provider', 'appointment']);
    } else if (user_role === "4") {
        if (user_status === "4") { //4: Partial Active status
            this._router.navigate(['/patient', 'appointment', 'openings']);
        } else {
            this._router.navigate(['/patient', 'dashboard']);
        }
    } else if (user_role === "5") {
        if (user_practice !== null) { //If have 1 practice
            this._router.navigate(['/provider', 'practice']);
        } else { //If have no practice
            this._router.navigate(['/provider', 'practice', 'add']);
        }
    } else if (user_role === "6") {
        localStorage.setItem('managing_user_id', user_id);
        this._router.navigate(['/provider', 'manage']);
    }
  }
  ngAfterViewInit() {
    
  }
  ngOnDestroy() {
    if (this.requestDemoSubscriber) {
      this.requestDemoSubscriber.unsubscribe();
    }
  }
  scheduleDemo() {
    this.errorMessageBook = null;
    this._loadingIndicatorService.start();
    if (this.requestDemoSubscriber) {
      this.requestDemoSubscriber.unsubscribe();
    }
    this.formDemoSubmitted = true;
    this.requestDemoSubscriber = this._homeService.requestDemo({
      support_name: this.requestdemoModel.supportdemo_name,
      support_email: this.requestdemoModel.supportdemo_email      
    }).subscribe((res: any) => {
      this.formDemoSubmitted = false;
        this._loadingIndicatorService.stop();
      if (res.error_code === 'RC000') {
        this.requestdemoModel = new RequestDemoClass();
        this.requireMessageDemo = new RequireMessageDemoClass();

        this.errorMessageBook = res.error_messages;
      } else if (res.error_code === 'RC020') {
        this.requireMessageDemo = res.error_fields;
      }      
    }, (error: any) => {
      this.formDemoSubmitted = false;
      this._loadingIndicatorService.stop();
      const e = error.error;
      if (e.error_code === 'RC002' || e.error_code === 'RC061' || e.error_code === 'RC119') {
        this.errorMessageBook = e.error_messages;
      }
    }, () => {
      this._loadingIndicatorService.stop();
      this.formDemoSubmitted = false;
    });
  }
  onKeyUp(){
    if (this.requestdemoModel.supportdemo_name != ''){
      this.requireMessageDemo.supportdemo_name = '';

    }
    if (this.requestdemoModel.supportdemo_email != ''){
      this.requireMessageDemo.supportdemo_email = '';
    }
  }
}

export class RequestDemoClass {
  supportdemo_name:string = '';
  supportdemo_email:string = '';
}

export class RequireMessageDemoClass {
  supportdemo_name:string = '';
  supportdemo_email:string = '';
}
