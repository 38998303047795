<div class="provider-edit">
  <div class="padding-left-15">
    <h3 class="text-header-form-edit">
      Add Practice
    </h3>
  </div>
  <form method="post" #form="ngForm" (ngSubmit)="addPracticeForm(form.value)">
    <div class="row">
      <div class="col-6">
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Practice Info</h3>
          </div>
          <div class="panel-body py-2">
            <div class="col-12 inline-edit-provider email-address">
              <span class="text-label-style input-label-required">Name</span>
              <input class="form-control textbox-edit-form" type="text" name="name"
                     [class.input-error]="responseError['name']" [(ngModel)]="practiceDetail.name" maxlength="200"
                     (keyup)="onKeyUp('name')">
              <span class="message-error" *ngIf="responseError['name']">{{responseError['name']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address']"
                     [(ngModel)]="practiceDetail.address" name="address" maxlength="50" (keyup)="onKeyUp('address')">
              <span class="message-error" *ngIf="responseError['address']">{{responseError['address']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['address2']"
                     [(ngModel)]="practiceDetail.address2" name="address2" maxlength="50">
              <span class="message-error" *ngIf="responseError['address2']">{{responseError['address2']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="col-5 pl-0">
                  <span class="text-label-style input-label-required">City</span>
                  <input class="form-control textbox-edit-form" type="text" [class.input-error]="responseError['city']"
                         [(ngModel)]="practiceDetail.city" name="city" maxlength="50" (keyup)="onKeyUp('city')">
                  <span class="message-error" *ngIf="responseError['city']">{{responseError['city']}}</span>
                </div>
                <div class="col-4 pl-0 pr-0">
                  <!--              <div class="input-group select width-full">-->
                  <span class="text-label-style input-label-required">State</span>
                  <div class="input-group select width-full">
                    <select class="form-control textbox-edit-form" [(ngModel)]="practiceDetail.state_code"
                            name="state_code"
                            [class.input-error]="responseError['state_code']" (change)="onKeyUp('state_code')">
                      <option [value]="''" selected>Select State</option>
                      <option *ngFor="let stateItem of _systemData.states"
                              [value]="stateItem.code">{{stateItem.name}}</option>
                    </select>
                    <div class="input-group-append cursorPointer select-state">
                      <i class="fa fa-caret-down"></i>
                    </div>
                  </div>
                  <span class="message-error" *ngIf="responseError['state_code']">{{responseError['state_code']}}</span>
                </div>
                <div class="col-3 pr-0">
                  <span class="text-label-style input-label-required">ZIP Code</span>
                  <input class="form-control textbox-edit-form" type="text" name="zip"
                         [class.input-error]="responseError['zip']" [(ngModel)]="practiceDetail.zip"
                         (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'" [target]="practiceDetail"
                         [field]="'zip'" maxlength="10">
                  <span class="message-error" *ngIf="responseError['zip']">{{responseError['zip']}}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Accepted Insurance</h3>
          </div>
          <div class="panel-body py-2 select-insurance">
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style input-label-required">Plan</span>
              <select select2-insurance class="form-control multichoice-signup-form" name="insurances"
                      (OnKeyUp)="onKeyUp('insurances')" (change)="onKeyUp('insurances')"
                      [inputError]="responseError['insurances']">
                <option *ngFor="let item of _systemData.insurances" [value]="item.id">{{item.name}}</option>
              </select>
              <span class="message-error" *ngIf="responseError['insurances']">{{responseError['insurances']}}</span>
            </div>

          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="panel-edit-profile panel-default">
          <div class="panel-heading">
            <h3 class="panel-title text-title-form-edit">Billing Info</h3>
          </div>
          <div class="panel-body py-2">
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="col-6 px-0">
                  <span class="text-label-style input-label-required">First Name</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.first_name']" [(ngModel)]="billing.first_name"
                         name="billing_first_name" maxlength="50" (keyup)="onKeyUp('billing_first_name')">
                  <span class="message-error"
                        *ngIf="responseError['billing.first_name']">{{responseError['billing.first_name']}}</span>
                </div>
                <div class="col-6 pr-0">
                  <span class="text-label-style input-label-required">Last Name</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.last_name']" [(ngModel)]="billing.last_name"
                         name="billing_last_name" maxlength="50" (keyup)="onKeyUp('billing_last_name')">
                  <span class="message-error"
                        *ngIf="responseError['billing.last_name']">{{responseError['billing.last_name']}}</span>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="col-6 px-0">
                  <span class="text-label-style input-label-required">Email Address</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.email']" [(ngModel)]="billing.email"
                         name="billing_email" maxlength="254" (keyup)="onKeyUp('billing_email')">
                  <span class="message-error"
                        *ngIf="responseError['billing.email']">{{responseError['billing.email']}}</span>
                </div>
                <div class="col-6 pr-0">
                  <div class="text-label-style input-label-required">Phone Number</div>
                  <div>
                    <div class="col-xs-12 col-sm-12 no-padding-left">
                      <input class="form-control textbox-edit-form" name="billing_phone_mobile" type="text"
                             [class.input-error]="responseError['billing.phone_mobile']"
                             [(ngModel)]="billing.phone_mobile" (keyup)="onKeyUp('billing_phone_mobile')"
                             mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                      <span class="message-error"
                            *ngIf="responseError['billing.phone_mobile']">{{responseError['billing.phone_mobile']}}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 1</span>
              <input class="form-control textbox-edit-form" type="text"
                     [class.input-error]="responseError['billing.address']" [(ngModel)]="billing.address"
                     name="billing_address" maxlength="50">
              <span class="message-error"
                    *ngIf="responseError['billing.address']">{{responseError['billing.address']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <span class="text-label-style">Address Line 2</span>
              <input class="form-control textbox-edit-form" type="text"
                     [class.input-error]="responseError['billing.address2']" [(ngModel)]="billing.address2"
                     name="billing_address2" maxlength="50">
              <span class="message-error"
                    *ngIf="responseError['billing.address2']">{{responseError['billing.address2']}}</span>
            </div>
            <div class="col-12 inline-edit-provider">
              <div class="row">
                <div class="col-5 pl-0">
                  <span class="text-label-style">City</span>
                  <input class="form-control textbox-edit-form" type="text"
                         [class.input-error]="responseError['billing.city']" [(ngModel)]="billing.city"
                         name="billing_city" maxlength="50">
                  <span class="message-error"
                        *ngIf="responseError['billing.city']">{{responseError['billing.city']}}</span>
                </div>
                <div class="col-4 pl-0 pr-0">
                  <!--                <div class="input-group select width-full">-->
                  <span class="text-label-style">State</span>
                  <div class="input-group select width-full">
                    <select class="form-control textbox-edit-form" [(ngModel)]="billing.state_code"
                            name="billing_state_code" [class.input-error]="responseError['billing.state_code']"
                            (change)="onKeyUp('state_code')">
                      <option [value]="''" selected>Select State</option>
                      <option *ngFor="let stateItem of _systemData.states"
                              [value]="stateItem.code">{{stateItem.name}}</option>
                    </select>
                    <div class="input-group-append cursorPointer select-state">
                      <i class="fa fa-caret-down"></i>
                    </div>
                  </div>
                  <span class="message-error"
                        *ngIf="responseError['billing.state_code']">{{responseError['billing.state_code']}}</span>
                </div>
                <div class="col-3 pr-0">
                  <span class="text-label-style">ZIP Code</span>
                  <input class="form-control textbox-edit-form" type="text" name="billing_zip"
                         [class.input-error]="responseError['billing.zip']" [(ngModel)]="billing.zip" simplemask
                         [format]="'#####-####'" [target]="billing" [field]="'zip'" maxlength="10">
                  <span class="message-error"
                        *ngIf="responseError['billing.zip']">{{responseError['billing.zip']}}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- <div class="panel-edit-profile panel-default">
            <div class="panel-heading">
                <h3 class="panel-title text-title-form-edit"><span class="input-label-required">Hours of Operation</span></h3>
            </div>
            <div class="panel-body no-padding">
                <rtn-opening-hour [enableValidation]="true" [resetOnDisabled]="false" [addNew]="true"></rtn-opening-hour>
                <span class="message-error padding-left-15 error-opening-hour" *ngIf="responseError['opening_hours'] && responseError['opening_hours'].length !== 0">{{responseError['opening_hours']}}</span>
            </div>
        </div> -->
      </div>
    </div>

    <div class="col-12 padding-bottom-20 pl-0">
      <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Create</button>
    </div>
  </form>
</div>
