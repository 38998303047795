<div class="tl">
  <div class="tl-date-picker">
    <div class="tl-date-picker-container dropdown dropdown-toggle">
      <span class="tl-date-prev pull-left"  (click)="prevDateOnClick()"><span class="fa fa-angle-left"></span></span>
      <span class="tl-date button-dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
        #clickShowDateTimlineAppointment aria-expanded="false">{{formatDate(selectedDate)}}</span>
      <span class="tl-date-next pull-right"  (click)="nextDateOnClick()"><span class="fa fa-angle-right"></span></span>
      <ul class="dropdown-menu dropdown-menu-date-timeline no-padding">
        <li>
          <div #inputDateTimlineAppointment id="date-select"></div>
          <!--<div class="calendar">
              <rtn-calendar #tlDatePicker [todayHighlight]="ngDatePicker.todayHighlight" [weekStart]="ngDatePicker.weekStart" [daysOfWeekHighlighted]="ngDatePicker.daysOfWeekHighlighted" [beforeShowDay]="ngDatePicker.beforeShowDay" [todayBtn]="true" [defaultViewDate]="ngDatePicker.defaultViewDate"
                  (onChangeDate)="handleDatePickerDayClick($event)" (onShow)="handleDatePickerOnShow($event)"></rtn-calendar>

          </div>-->
        </li>
      </ul>
    </div>
    <div class="row padding-left-15">
      <div class="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 no-padding-left-right padding-left-15"></div>
      <div class="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 no-padding-left-right padding-left-15 margin-bottom-5">
        <div class="input-group select width-full">        
            <select class="form-control textbox-add-form" [(ngModel)]="selectedLocationId" name="selectedLocationId" (change)="onSelectLocation()">
                <option [value]="''" selected>View All</option>
                <option *ngFor="let providerLoc of providerLocations" [value]="providerLoc.location_name">{{ providerLoc.location_name }}</option>
            </select>
            <div class="input-group-select-icon cursorPointer select-state">
                <i class="fa fa-caret-down"></i>
            </div>
        </div>
      </div>
    </div>
        
  </div>
  <!-- ======= Header Area ======= -->
  <table class="tl-timeline-table">
    <thead class="tl-table-header">
      <tr>
        <!--Left Box on Header-->
        <td class="tl-left wid_360">
          <div class="tl-scroller-off">
            <div>
              <div class="tl-area-scroller">
                <div class="tl-area-content">
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <div>
                            <div class="tl-table-cell">
                              <span class="tl-table-cell-label">
                                <div class="time-slider" (click)="timeSliderClick()">
                                  <span id="time-option-15" class="time-option">15 mins</span>
                                  <div class="slider" [ngStyle]="{'width': sliderWidth + 'px'}">
                                    <div class="slider-base"></div>
                                    <div id="time-slider-body" class="slider-body"
                                      [ngStyle]="{'width': sliderBodyRadius * 2 + 'px', 'height': sliderBodyRadius * 2 + 'px'}">
                                    </div>
                                  </div>
                                  <span id="time-option-30" class="time-option active">30 mins</span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </th>
                        <th class="wid_40 table_cell_arrive">
                          <div class="height_full">
                            <div class="mt_9">Arrived</div>
                          </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="tl-right">
          <!--Right Box on Header-->
          <div class="tl-scroller-off">
            <div class="tl-scroller-x">
              <div class="tl-area-scroller tl-reposition-right">
                <div class="tl-area-content">
                  <table>
                    <tbody>
                      <tr>
                        <th *ngFor="let item of colArray; let $j = index">
                          <div class="tl-table-cell"><span class="tl-table-cell-label">{{item}}</span></div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </thead>
    <tbody class="tl-table-body">
      <!-- ======= Body Area ======= -->
      <tr>
        <td class="tl-left">
          <!--Left Box on Body-->
          <div class="tl-scroller-off">
            <div class="tl-scroller-y">
              <div class="tl-area-scroller tl-reposition-bottom">
                <div class="tl-area-content">
                  <table>
                    <tbody>
                      <tr *ngFor="let item of rowArray; let $j = index"
                        [class.checkin_backgroud_on]="item.events[1] != null && item.events[1].type === 1"
                        [class.checkin_backgroud_late]="item.events[1] != null && item.events[1].type === 2">
                        <td>
                          <div class="tl-grid-x" [ngStyle]="{'height': tableCellHeight+'px'}">
                            <div class="tl-table-cell">
                              <!--<span title="{{item.patientName}}" class="table_cell"
                                [ngStyle]="{'line-height': tableCellHeight+'px'}" [ngClass]="{'notAllowedapt' : (item.appointment_is_allowed == 0 || item.cancel_status_text != '')}">{{item.patientName}}
                                &nbsp;<a title="Open Waittime View" [routerLink]="['/appointment/waittime', item.appointmentid_encode]" target="_blank" *ngIf="item.appointment_is_allowed == 1 && item.cancel_status_text == ''"><i class="far fa-clock"></i></a>  
                              </span>
                            -->
                            <span title="{{item.patientName}}" class="table_cell"
                                [ngStyle]="{'line-height': tableCellHeight+'px'}" [ngClass]="{'notAllowedapt' : (item.appointment_is_allowed == 0 || item.patient_user_id == undefined), 'cancelledApt' : (item.cancel_status_text != '')}">{{item.patientName}}
                                &nbsp;<a title="Share Wait Time Meter to Patient" (click)=" notificationMassPatient($event, item.events[0], 'patient_activation') " *ngIf="item.appointment_is_allowed == 1 && item.cancel_status_text == '' && item.patient_user_id != undefined && item.patient_user_id != null"><i class="far fa-clock"></i></a>
                                &nbsp;<a *ngIf="item.optin_status == 1 || item.optin_status == 2" title="{{ item.optout_reason}}" href="javascript:void(0);">
                                  <i class="fa fa-bell" *ngIf="item.optin_status == 1"></i>
                                  <i class="fa fa-bell-slash" *ngIf="item.optin_status == 2"></i>
                                </a>  
                                &nbsp;<a href="javascript:void(0);" (click)="scheduleNotification($event, item.events[0])"><i class="fa fa-commenting-o" aria-hidden="true"></i></a>
                              </span>
                              
                            </div>
                          </div>
                        </td>
                        <td class="wid_40">
                          <div class="tl-grid-x height_46 width_full">
                            <div class="input-rtn mt_0 ml_27">
                              <input class="checkbox check-single" [(ngModel)]="item.arrived_time"
                                [attr.disabled]="item.isDisabled" [value]="item.arrived_time" type="checkbox"
                                id="{{item.id}}" (change)="updateArrivedTime(item.id, item.arrived_time)">
                              <label for="{{item.id}}" class="selectorExtreme rtn-text-grey-85 mt-3 ml_7"></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="numberOfCompensationToShow !== 0">
                        <!--Compensation Area For No Entry-->
                        <!-- <td>
                          <div class="tl-grid-x tl-compensation" [ngStyle]="{'height': heightCompensationBox +'px'}">
                            <div class="tl-table-cell"></div>
                          </div>
                        </td>
                        <td class="wid_40">
                          <div class="tl-grid-x tl-compensation" [ngStyle]="{'height': heightCompensationBox +'px'}">
                            <div class="tl-table-cell"></div>
                          </div>

                        </td> -->
                      </tr>
                      <tr *ngIf="rowArray.length === 0">
                        <td>
                          <div>
                            <div class="tl-table-cell-no-entry"
                              [ngStyle]="{'height': (tableCellHeight*numberOfEntryToShow + hintNextEntryHeight) + 'px', 'line-height': (tableCellHeight*numberOfEntryToShow + hintNextEntryHeight) + 'px'}">
                              No patient </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="tl-right">
          <!--Right Box on Body-->
          <div class="tl-scroller-off">
            <div class="tl-scroller-all">
              <div class="tl-area-scroller">
                <div class="tl-area-content">
                  <div>
                    <table>
                      <tbody>
                        <tr *ngFor="let item of rowArray; let $j = index">
                          <td>
                            <div class="tl-grid-x" [ngStyle]="{'height':tableCellHeight+'px'}">
                              <div class="tl-entry">
                                <div *ngFor="let event of item.events; let $k = index"
                                  class="tl-entry-body tl-entry-top" [class.tl-entry-plan]="event.type === 0"
                                  [class.tl-entry-on]="event.type === 1" [class.tl-entry-late]="event.type === 2"
                                  [class.tl-entry-done]="[3, 4].indexOf(event.type) !== -1"
                                  [class.disabled]="event.isDisabled"
                                  [class.tl-entry-cancel]="event.type === 5"
                                  [ngStyle]="{'left': event.left+'px', 'top': event.top+'px', 'height': eventHeight+'px', 'width': event.width+'px', 'min-width': calculateMinWidth(event) + 'px', 'max-width' : calculateMaxWidth(event) + 'px'}">
                                  <div class="tl-entry-content dropdown dropdown-toggle"
                                    [ngStyle]="{'line-height': eventHeight+'px'}">
                                    <span class="tl-entry-time"
                                      [class.tl-entry-time-error]="event.hasError">{{event.labelStartTimeForDisplay}}</span>
                                    <span class="tl-entry-tooltip-trigger"
                                      (touchstart)="touchHandler($event, event, $j, $k)"
                                      (mouseover)="eventEntryMouseOver($event, event, $j, $k)"
                                      (mouseout)="eventEntryMouseOut(event)"
                                      [ngStyle]="{'height': eventHeight+'px', 'width': (event.width-widthForDropDownMenuToggle) + 'px', 'min-width': (calculateMinWidth(event)-widthForDropDownMenuToggle) + 'px', 'max-width' : (calculateMaxWidth(event) - widthForDropDownMenuToggle) + 'px'}"></span>
                                    <span [class.hidden]="isHidden" class="fa fa-sort button-dropdown-toggle"
                                      [ngStyle]="{'line-height': eventHeight+'px'}"></span>
                                    <span [class.hidden]="isHidden" *ngIf="(!event.isDisabled)"
                                      class="tl-entry-dropdown-trigger cursorPointer"
                                      (click)="toggleDropdown($event, event, $j, $k)"
                                      [ngStyle]="{'height': eventHeight+'px', 'width': (widthForDropDownMenuToggle) + 'px'}"></span>
                                    <span [class.hidden]="isHidden" *ngIf="event.isDisabled"
                                      class="tl-entry-dropdown-trigger"
                                      [ngStyle]="{'height': eventHeight+'px', 'width': (widthForDropDownMenuToggle) + 'px'}"></span>
                                    <!-- ON, LATE -->
                                    
                                    <!-- PLAN -->
                                    <ul
                                      class="dropdown-menu"
                                      [ngStyle]="{'width': widthForDropDownMenu + 'px', 'min-width': widthForDropDownMenu + 'px'}">
                                      <ng-container *ngIf="event.type != 5 && event.type != 3 && (!event.isDisabled) && !(event.type === 0)"> <!--&& !_systemData.isOnline-->
                                        <li><a class="cursorPointer dropdown-option"
                                          (click)="cancelCheckInAppointment($event, event)"><i
                                            class="fa fa-close pull-right"></i>Cancel Check-In</a></li>
                                        <li>
                                          <div class="dropdown-separator"></div>
                                        </li>
                                        <li *ngIf="!event.isEndBeforeStart"><a class="cursorPointer dropdown-option"
                                            (click)="checkOutAppointment($event, event)"><i
                                              class="fa fa-sign-out pull-right"></i>Check Out</a></li>
                                      </ng-container>

                                      <ng-container *ngIf="!event.isDisabled && ([0, 4].indexOf(event.type) !== -1)">

                                        <!--<ng-container *ngIf="!_systemData.isOnline">-->
                                          <li *ngIf="event.isCurrentDate && (event.data.status !== event.data.status_table[4])">
                                            <a class="cursorPointer dropdown-option"
                                              (click)="checkInAppointment($event, event)"><i
                                                class="fa fa-sign-in pull-right"></i>Check-In</a></li>
                                          <li>
                                            <div class="dropdown-separator"></div>
                                          </li>
                                        <!--</ng-container>-->
                                      
                                        <li><a class="cursorPointer dropdown-option"
                                            (click)="editAppointment($event, event)"><i
                                              class="fas fa-edit pull-right"></i>Edit Appointment</a></li>
                                        <li>
                                          <div class="dropdown-separator"></div>
                                        </li>

                                        <li><a class="cursorPointer dropdown-option"
                                            (click)="cancelAppointment($event, event)"><i
                                              class="fas fa-close pull-right"></i>Cancel Appointment</a></li>
                                        <li>
                                          <div class="dropdown-separator"></div>
                                        </li>
                                        <li><a class="cursorPointer dropdown-option"
                                            (click)="noShowAppointment($event, event, item.id)"><i
                                              class="fas fa-close pull-right"></i>No Show</a></li>
                                        <li>
                                          <div class="dropdown-separator"></div>
                                        </li>
                                      
                                      </ng-container>
                                      <li *ngIf="item.patient_user_id != undefined && item.patient_user_id != null"><a class="cursorPointer dropdown-option"
                                        (click)=" notificationMassPatient($event, event,'patient_sendto') "><i
                                          class="far fa-paper-plane pull-right"></i>Send
                                        To</a>
                                      </li>
                                    </ul>
                                    
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <!--Compensation Area For No Entry-->
                        <tr *ngIf="numberOfCompensationToShow !== 0">
                          <td>
                            <div class="tl-grid-x tl-compensation"
                              [ngStyle]="{'height': (heightCompensationBox+112) +'px'}">
                              <div class="tl-entry">
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="rowArray.length === 0">
                          <td>
                            <div>
                              <div class="tl-table-cell-no-entry"
                                [ngStyle]="{'height': (tableCellHeight*numberOfEntryToShow + hintNextEntryHeight+100) + 'px', 'line-height': (tableCellHeight*numberOfEntryToShow + hintNextEntryHeight) + 'px'}">
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!--Draw Vertical Grid-->
                <div class="tl-reposition">
                  <div class="tl-grid-y">
                    <table>
                      <tbody>
                        <tr>
                          <td *ngFor="let item of colArray; let $j = index">
                            <div></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<rtn-modals-appointment-check-in (reload)="refetchAppointment()"></rtn-modals-appointment-check-in>
<rtn-modals-appointment-check-out (reload)="refetchAppointment()"></rtn-modals-appointment-check-out>
<!--<rtn-modals-appointment-edit (reload)="refetchAppointment()"></rtn-modals-appointment-edit>-->
<rtn-modals-appointment-cancel (reload)="refetchAppointment()"></rtn-modals-appointment-cancel>
<rtn-modals-appointment-edit (reload)="refetchAppointment()"></rtn-modals-appointment-edit>
<rtn-modals-appointment-cancel-check-in (reload)="refetchAppointment()"></rtn-modals-appointment-cancel-check-in>
<app-timline-mass-notification></app-timline-mass-notification>
<div style="display:none;">
  <rtn-timeline-tooltip></rtn-timeline-tooltip>
</div>
<rtn-modals-appointment-notification (reload)="refetchAppointment1()"></rtn-modals-appointment-notification>