import {ElementRef} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {fromEvent} from 'rxjs';

declare let jQuery: any;

export class ModalInstance {

  public shown: Observable<void>;
  public hidden: Observable<ModalResult>;
  public result: ModalResult;
  public visible = false;

  private suffix = '.ng2-bs3-modal';
  private shownEventName: string = 'shown.bs.modal' + this.suffix;
  private hiddenEventName: string = 'hidden.bs.modal' + this.suffix;
  private $modal: any;


  constructor(private element: ElementRef) {
    this.init();
  }

  public open(): Promise<any> {
    return this.show();
  }

  public close(): Promise<any> {
    this.result = ModalResult.Close;
    return this.hide();
  }

  public dismiss(): Promise<any> {
    this.result = ModalResult.Dismiss;
    return this.hide();
  }

  public function(): Promise<any> {
    this.result = ModalResult.Function;
    return this.hide();
  }

  public destroy(): Promise<any> {
    return this.hide().then(() => {
      if (this.$modal) {
        this.$modal.data('bs.modal', null);
        this.$modal.remove();
      }
    });
  }

  private show() {
    const promise = this.toPromise(this.shown);
    this.resetData();
    this.$modal.modal();
    return promise;
  }

  private hide(): Promise<ModalResult> {
    if (this.$modal && this.visible) {
      const promise = this.toPromise(this.hidden);
      this.$modal.modal('hide');
      return promise;
    }
    return Promise.resolve(this.result);
  }

  private init() {
    this.$modal = jQuery(this.element.nativeElement);
    this.$modal.appendTo('body');

    this.shown = fromEvent(this.$modal, this.shownEventName).pipe(map(() => {
      this.visible = true;
    }));

    this.hidden = fromEvent(this.$modal, this.hiddenEventName).pipe(map(() => {
      // const result = (!this.result || this.result === ModalResult.None) ?
      const result = (!this.result) ?
        ModalResult.Dismiss : this.result;

      this.result = ModalResult.None;
      this.visible = false;

      return result;
    }));
  }

  private resetData() {
    this.$modal.removeData();
    this.$modal.data('backdrop', this.booleanOrValue(this.$modal.attr('data-backdrop')));
    this.$modal.data('keyboard', this.booleanOrValue(this.$modal.attr('data-keyboard')));
  }

  private booleanOrValue(value: any) {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  }

  private toPromise<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      observable.subscribe(next => {
        resolve(next);
      });
    });
  }
}

export enum ModalResult {
  None,
  Close,
  Dismiss,
  Function
}
