<!--<rtn-provider-header></rtn-provider-header>-->
<div class="provider-edit">
  <div class="col-xs-12 col-md-12 col-sm-12 list-provider-of-managing-user px-3">
    <h3 class="text-header-form-edit">
      <a class="no-focus" [routerLink]="['/provider', 'manage', idSelected]" *ngIf="isSuperUser">
        <span class="fa fa-angle-left style-top-arrow"></span>
      </a>
      <a class="no-focus" [routerLink]="['/provider', 'manage']" *ngIf="!isSuperUser">
        <span class="fa fa-angle-left style-top-arrow"></span>
      </a>
      Select Mapping/UnMapping Provider
    </h3>
    <rtn-table #table
               (onDataChange)="onDataChange($event)"
               [autoloading]="false"
               [data]="accountList.data"
               [rowsOnPage]="accountList.rowsOnPage"
               [numberOfPages]="accountList.numberOfPages">
      <div class="row">
        <rtn-table-filter #filter class="col-lg-12 pl-0 pr-0">
          <form (ngSubmit)="onSearchSubmited(table)">
            <div class="row col-5 float-right pr-0">
              <div class="col-9 pr-0">
                <input class="form-control col-12" type="text" placeholder="Enter Email or Name" name="searchText" [(ngModel)]="searchText">
              </div>
              <div class="col-3 pr-0">
                <button type="submit" class="btn btn-rtn btn-180 pull-right">
                  <span class="fas fa-search" aria-hidden="true"></span>
                  <span class="label">Search</span>
                </button>
              </div>
            </div>
          </form>
        </rtn-table-filter>

        <div class="col-sm-12 no-padding-left-right search-box listview panel-table">
          <table class="table table-bordered">
            <thead class="account-thead">
            <tr>
              <th class="account-thead-text col-email">Email</th>
              <th class="account-thead-text col-full-name">Full Name</th>
              <th class="account-thead-text col-phone">Phone Mobile</th>
              <th class="account-thead-text col-address">Address</th>
              <th class="account-thead-text col-specialties">Specialties</th>
              <th class="account-thead-text col-status">Status</th>
              <th class="account-thead-text col-action"></th>
              <th class="account-thead-text col-musers">Current Managing Users</th>
            </tr>
            </thead>

            <tbody class="account-tbody">
            <tr *ngFor="let item of table.data; let i = index">
              <td class="account-email">{{ item.email }}</td>
              <td class="account-full-name">{{ item.full_name }}</td>
              <td class="account-phone">{{ item.phone_mobile }}</td>
              <td class="account-address">{{ item.full_address }}</td>
              <td class="account-specialties">{{ item.specialties }}</td>
              <td class="account-status">{{ item.status_show }}</td>
              <td class="account-action text-center" *ngIf="!item.is_mapped">
                <button type="submit" class="btn btn-rtn btn-120" (click)="mappingProvider(item.id, $event, table)" [class.btn-disabled]="item.status !== 3" [disabled]="item.status !== 3">
                  <span class="label">Mapping</span>
                </button>
              </td>
              <td class="account-action text-center" *ngIf="item.is_mapped">
                <button type="submit" class="btn btn-rtn btn-120" (click)="unMappingProvider(item.id, $event, table)" [class.btn-disabled]="item.status !== 3" [disabled]="item.status !== 3">
                  <span class="label">UnMapping</span>
                </button>
              </td>
              <td class="account-musers">{{ item.managing_users }}</td>
            </tr>
            <tr *ngIf="countData === 0" class="text-center">
              <td colspan="8" class="no-provider">There is no provider</td>
            </tr>
            </tbody>

            <tfoot *ngIf="table.numberOfPages > 1">
            <tr>
              <td colspan="12" class="text-center table-responsive no-overflow-y">
                <div class="page-footer">
                  <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                </div>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </rtn-table>
  </div>
</div>
