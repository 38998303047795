import { Component, OnInit } from '@angular/core';
// import { PhysicianDirectoryService } from '../service/physician-directory.service';
// import { ROUTER_DIRECTIVES } from 'angular2/router';
import { Utils } from '@app/shared/utils';
import {RestService} from '@app/core/rest.service';

@Component({
  selector: 'rtn-physician-directory',
  moduleId: module.id,
  templateUrl: './physician-directory-global.component.html',
  styleUrls: ['./physician-directory-global.component.scss']
})
export class PhysicianDirectoryGlobalComponent implements OnInit {
  physicianDirectory: any = [];
  physicianDirectoryLetter: any = [];

  constructor(
    private _utils: Utils,
    private _service: RestService
  ) {
    // TODO
  }

  ngOnInit() {
    this._service.getPhysicianDirectory().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          let physicianDirectory = success.physician_directory;

          for (let i in physicianDirectory) {
            let directory = new Directory;
            if (physicianDirectory.hasOwnProperty(i)) {
              directory.letter = i;
              this.physicianDirectoryLetter.push(i);
              directory.physician = physicianDirectory[i];
              for (let j in directory.physician) {
                let user = directory.physician[j];
                user.credentials = user.credentials === null ? null : user.credentials;
                user.full_name = this._utils.generateFullName(user.first_name, user.middle_name, user.last_name);
              }
              this.physicianDirectory.push(directory);
            }
          }
        }
      });
  }

  scrollArchor(letter: string) {
    jQuery('body').animate({
      scrollTop: jQuery('#' + letter).offset().top
    }, 'slow');
  }

  backToTop() {
    jQuery('body').animate({
      scrollTop: 0
    }, 'slow');
  }
}

export class Directory {
  letter: string;
  physician: any = [];
}
