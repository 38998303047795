<div class="marketing-page container">
  <div class="content-header row" [style.min-height.px]="minHeight">
      <div class="login-form col-xs-12 col-sm-8 col-md-10 offset-md-1 offset-sm-0 offset-xs-0">
          <div class="col-xs-12 col-sm-12 col-md-7 no-padding-left">
              <h1 class="style-text">CREATE AN ACCOUNT</h1>
<!--              <div class="form-group">-->
<!--                  Lorem ipsum dolor sit amet, consectetur adispiscing elit. Sed accumsan magna ac ligula euismod, tempus lacinia sapien pretium.-->
<!--              </div>-->
              <div class="row padding-bottom-30">
                  <div class="col-xs-12 col-sm-4 col-md-5 col-lg-4 no-padding">
                      <button tabindex="1" type="button" [routerLink]="['/home', 'sign-up']" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius">Patient</button>
                  </div>
              </div>
              <!--<div class="form-group">
                  Lorem ipsum dolor sit amet, consectetur adispiscing elit. Sed accumsan magna ac ligula euismod, tempus lacinia sapien pretium.
              </div>
              <div class="row padding-bottom-30">
                  <div class="col-xs-12 col-sm-4 col-md-5 col-lg-4">
                      <button tabindex="1" type="button" [routerLink]="['/Home', 'SignUpProvider']" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius">Provider</button>
                  </div>
              </div>-->
          </div>
      </div>
  </div>
</div>
