import {
    NgModule,
    Directive,
    Input,
    Output,
    OnInit,
    AfterViewInit,
    OnDestroy,
    OnChanges,
    ElementRef,
    EventEmitter
} from '@angular/core';
// import { CORE_DIRECTIVES, ControlGroup, Control } from 'angular2/common';
import {SystemEvent} from '@app/shared/system';

import {NotificationsService} from '@app/notifications/notifications.service';

import {environment} from '@env/environment';

declare let jQuery: any;

@Directive({
    selector: '[select2-ajaxpatient]',
    exportAs: 'select2-ajaxpatient'
})

export class Select2AjaxpatientDirective implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() multiple = true;
    @Input() tags = true;
    @Input() tokenSeparators: any = [',', ' '];
    @Input() dropdownAutoWidth = true;
    @Input() placeholder = 'Add Patient(s)';
    @Input() allowClear = true;
    @Input() closeOnSelect = false;
    @Input() maximumSelectionLength = 3;
    @Input() dropdownParent: any;
    @Input() templateResult: any;
    @Input() inputError = false;

    @Output() OnKeyUp: EventEmitter<any> = new EventEmitter();
    @Output() OnSelected: EventEmitter<any> = new EventEmitter();
    //@Output() OnSearchDone: EventEmitter<any> = new EventEmitter();

    public element: any;
    public getSelect2Element: String = '.select2-container--default .select2-selection--multiple';
    private inputErrorClass: any = 'input-error';
    private getSelect2DropDownBelow = 'select2-dropdown--below';
    patientsUrl = environment.serverUrl + '/notifications/patients';

    constructor(
        public el: ElementRef,
        private _systemEvent: SystemEvent,
        private _restService: NotificationsService,
    ) {
        this.element = jQuery(this.el.nativeElement);
    }

    @Input() noResults: any = () => 'No patient matches';
    @Input() maximumSelected: any = () => 'You cannot choose more than ' + this.maximumSelectionLength + ' patients';

    withCheckboxTemplate: any = (result: any) => {
        return jQuery(`<span class="input-rtn">
        <input type="checkbox" class="checkbox check-single">
        <label class="selectorExtreme"></label></span><span class="select-text">` + result.text + `</span>`);
    };


    ngOnInit() {
        const token = localStorage.getItem('user_token');

        this.element.select2({
            multiple: this.multiple,
            tags: this.tags,
            dropdownAutoWidth: this.dropdownAutoWidth,
            placeholder: this.placeholder,
            allowClear: this.allowClear,
            closeOnSelect: this.closeOnSelect,
            maximumSelectionLength: this.maximumSelectionLength,
            dropdownParent: jQuery(this.element).parent(),
            minimumInputLength: 2,
            language: {
                maximumSelected: this.maximumSelected,
                noResults: this.noResults
            },
            templateResult: this.withCheckboxTemplate,
            cacheDataSource: [],
            async : false,
            delay: 250,
            ajax: {
                url: this.patientsUrl,
                dataType: 'json',
                headers: {
                    "Authorization" : "Bearer "+ token,                    
                },
                processResults: function (data:any) {
                    // Transforms the top-level key of the response object from 'items' to 'results'
                   // console.log(data);
                    //this.OnSearchDone.emit(data.patients);
                    
                    return {
                      results: data.patients
                    };
                }                          
            }
        })
            .on('select2:open', (e: any) => {
                this.OnKeyUp.emit(e);
                // Fix dropdown display problem: https://github.com/select2/select2/issues/3303
                const dropdown = jQuery('.select2-container.select2-container--default.select2-container--open:not(".select2")');
                const trigger = jQuery('.select2-container.select2-container--default.select2-container--open.select2');
                if (!dropdown.children().hasClass(this.getSelect2DropDownBelow)) {
                    dropdown.children().removeClass('select2-dropdown--above');
                    dropdown.children().addClass(this.getSelect2DropDownBelow);
                }
                dropdown.css({'top': dropdown.parent().height() + 'px'});
                // Always make sure the dropdown goes to the bottom
            })
            .on('select2:close', (e: any) => {
                this.OnKeyUp.emit(e);
            })
            .on('select2:select', (e: any) => {
                if (jQuery(this.element).val().length === this.maximumSelectionLength) {
                    this.close();
                }
                this._systemEvent.event.emit('event_callSelectedSpecialty');
            });
    }

    ngOnChanges() {
        const _self = this;
        if (this.inputError) {
            jQuery(_self.getSelect2Element).addClass(this.inputErrorClass);
        } else {
            jQuery(_self.getSelect2Element).removeClass(this.inputErrorClass);
        }
    }

    changeBorderColorPatient() {
        const _self = this;
        if (this.inputError) {
            jQuery(_self.getSelect2Element).addClass(this.inputErrorClass);
        } else {
            jQuery(_self.getSelect2Element).removeClass(this.inputErrorClass);
        }
    }

    ngAfterViewInit() {
        const _self = this;
        this.element.next('.select2-container').css({'width': '100%'});
        jQuery(_self.getSelect2Element)
            .prepend('<span class="select2-selection__caret cursorPointer"><i class="fa fa-caret-down"></i></span>');
        $(document).ready(function () {
            _self.element.on('change', function (e: any) {
                _self.inputError = false;
                _self.changeBorderColorPatient();
            });
        });
    }

    ngOnDestroy() {
        // do nothing
    }

    invoke(...args: any[]) {
        this.element.select2.apply(this.element, args);
    }

    getData() {
        return jQuery(this.element).val();
    }

    clearData() {
        jQuery(this.element).val(null).trigger('change');
    }

    updateData(data: any) {
        jQuery(this.element).val(data).trigger('change');
    }

    open() {
        this.invoke('open');
    }

    close() {
        this.invoke('close');
    }

    resetSelect2(element: any) {
        element.select2({
            multiple: this.multiple,
            tags: this.tags,
            dropdownAutoWidth: this.dropdownAutoWidth,
            placeholder: this.placeholder,
            allowClear: this.allowClear,
            closeOnSelect: this.closeOnSelect,
            /*maximumSelectionLength: this.maximumSelectionLength,*/
            dropdownParent: jQuery(this.element).parent(),
            language: {
                maximumSelected: this.maximumSelected,
                noResults: this.noResults
            },
            templateResult: this.withCheckboxTemplate
        })
            .on('select2:open', (e: any) => {
                // this.OnKeyUp.emit(e);
                // Fix dropdown display problem: https://github.com/select2/select2/issues/3303
                const dropdown = jQuery('.select2-container.select2-container--default.select2-container--open:not(".select2")');
                const trigger = jQuery('.select2-container.select2-container--default.select2-container--open.select2');
                if (!dropdown.children().hasClass(this.getSelect2DropDownBelow)) {
                    dropdown.children().removeClass('select2-dropdown--above');
                    dropdown.children().addClass(this.getSelect2DropDownBelow);
                }
                dropdown.css({'top': dropdown.parent().height() + 'px'});
                // Always make sure the dropdown goes to the bottom
            })
            .on('select2:close', (e: any) => {
                // this.OnKeyUp.emit(e);
            })
            .on('select2:unselect', (e: any) => {
                this.OnSelected.emit(e);
            })
            .on('select2:select', (e: any) => {
                this.OnSelected.emit(e);
            });
    }
}
