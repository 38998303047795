import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import {RestService} from '@app/core/rest.service';
import {Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {SystemInterval, SystemEvent, SystemData} from '@app/shared/system';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {DataTableDirectives, RtnDataTableCoreComponent} from '@app/shared/datatable';
import {Subscription} from 'rxjs';
import {AlertService} from '@app/shared/alert';
import {NotificationsService} from '@app/notifications/notifications.service';
import {ModalsComponent} from '../modals/delete-notification/delete-notification.component';

declare let jQuery: any;
declare let moment: any;

@Component({
  moduleId: module.id,
  selector: 'app-latest-notification-dashboard',
  templateUrl: './latest-notification-dashboard.component.html',
  styleUrls: ['./latest-notification-dashboard.component.scss'],
  providers: [RestService, Utils, AlertService, SystemEvent],
  // directives: [ROUTER_DIRECTIVES, DataTableDirectives]
})

export class LatestNotificationDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  isRead = false;
  getNotificationList: any = new Object();
  numberOfRowDefault = 10;
  itemSelectedDelete = 0;
  getNotificationListSubscriber: Subscription;
  readNotificationSubscriber: Subscription;
  deleteNotificationsSubscriber: Subscription;
  heightOfUnread: number = 0;
  NumOfUnreadNotification: any = 0;
  currentPage: number = 1;
  module: any;

  @ViewChild(ModalsComponent) deleteNotificationDirective: ModalsComponent;  
  @ViewChild(RtnDataTableCoreComponent) rtnDataTableCore: RtnDataTableCoreComponent;

  constructor(
    private _restService: NotificationsService,    
    private _utils: Utils,
    private _systemEvent: SystemEvent,    
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService
  ) {
    
  }

  ngOnInit() {    
    this.getUnreadNotifications();
  }

  ngOnDestroy() {
    if (this.getNotificationListSubscriber) {
      this.getNotificationListSubscriber.unsubscribe();
    }
    if (this.readNotificationSubscriber) {
      this.readNotificationSubscriber.unsubscribe();
    }
    if (this.deleteNotificationsSubscriber) {
      this.deleteNotificationsSubscriber.unsubscribe();
    }
  }

  ngAfterViewInit() {
    
  }
  handleFetchGetNotificationList(args: any) {
    args.page = args['currentPage'] || this.currentPage;
    this.currentPage = args.page;
    args.limit = args['rowsOnPage'] || this.numberOfRowDefault;
    if (this.getNotificationListSubscriber) {
      this.getNotificationListSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.getNotificationListSubscriber = this._restService.getNotificationList(null, args.page, args.limit).subscribe(
      (success: any) => {
        this.getNotificationList = new Object();
        this.getNotificationList.rowsOnPage = success.limit || this.numberOfRowDefault;
        this.getNotificationList.numberOfPages = success.total_pages || 0;
        this.getNotificationList.data = [];
        this.getNotificationList.data = this.getNotificationList.data.concat(
          this._utils.generateTimeAgoAndCredentials(success.notifications));
        this._loadingIndicatorService.stop();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      },
      () => {
        this._loadingIndicatorService.stop();
      }
    );
  }
  readNotification(readStatus: any, notificationId: number) {
    if (readStatus != null || jQuery('#notification-' + (notificationId)).hasClass('read')) {
      return;
    }
    const dataRead = {'is_read': true};
    this.isRead = true;
    if (this.readNotificationSubscriber) {
      this.readNotificationSubscriber.unsubscribe();
    }
    this.readNotificationSubscriber = this._restService.readNotification(notificationId, dataRead).subscribe(
      (success: any) => {
        jQuery('#notification-' + (notificationId)).addClass('read');
        jQuery('#notification-unread-' + (notificationId)).attr('style', 'display: none');
        this._systemEvent.notification.emit('notification_changeNumberOfUnreadNotifications');
        this.getUnreadNotifications();
      },
      (error: any) => {
        const errorJson = error.error;
        if (errorJson.error_code === 'RC081') {
          this._alertService.onClose = () => {
            location.reload();
          };
        }
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Close';
        this._alertService.title = errorJson.error_messages;
        this._alertService.message = '';
        this._alertService.emitter('open');
      }
    );
  }
  refreshData() {
    if (this.currentPage != 1 && this.getNotificationList.data.length == 1) {
      this.currentPage = this.currentPage - 1;
      this.rtnDataTableCore.setPage(this.currentPage, this.numberOfRowDefault);
    }
    this._systemEvent.notification.emit('notification_getNumberOfUnreadNotifications');
    let args: any;
    args = [{'currentPage': 1, 'rowsOnPage': this.numberOfRowDefault}];
    this.handleFetchGetNotificationList(args);
    // Back to page 1
    // this.rtnDataTableCore.setPage(1, this.numberOfRowDefault);
  }
  callDeleteNotificationDirective(id: any) {
    this.itemSelectedDelete = id;
    this.deleteNotificationDirective.open();
  }
  deleteNotification() {
    if (this.deleteNotificationsSubscriber) {
      this.deleteNotificationsSubscriber.unsubscribe();
    }
    const args = {'notification_ids': [this.itemSelectedDelete]};
    this.deleteNotificationsSubscriber = this._restService.deleteNotifications(args).subscribe(
      (success: any) => {
        const rowId = '#notification-' + this.itemSelectedDelete;
        jQuery(rowId).remove();
        this.refreshData();
      }
    );
  }
  getUnreadNotifications() {
    this.deleteNotificationsSubscriber = this._restService.getUnreadNotifications().subscribe(
      (success: any) => {
        this.NumOfUnreadNotification = success.total;
        if(this.NumOfUnreadNotification > 99){
           this.NumOfUnreadNotification = "99+";
        }
        if(this.isRead){
          jQuery('.navbar-nav.main-menu').find('.notification-number').html(String(this.NumOfUnreadNotification));
        }

      }
    );
  }
}
