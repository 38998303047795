<modal #Waitlist_from_appointment class="appointment-new">
    <form #modalForm="ngForm" class="form">
        <div class="form-content">
            <div class="form-header" *ngIf="searchOpenings.id == null || searchOpenings.id == 0">Add Waitlist</div>
            <div class="form-header" *ngIf="searchOpenings.id != null">Edit Waitlist</div>
            <div class="form-info">
                
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-xs-2 input-label-required no-padding-right display-flex line-height-30 mt-1 px-0">
                        Date
                    </div>
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <div class="input-group">
                                    <input type="text" #waitlistFromAptinputStartDateSaveSettingSearch
                                        [class.input-error]="errorResponse.start_date != '' || error_invalid_date != ''"
                                        class="form-control calender-input bc-white" id="startDate1" disabled
                                        placeholder="From" [(ngModel)]="searchOpenings.start_date_display"
                                        [ngModelOptions]="{standalone: true}">
                                    <div class="input-group-append" #waitlistFromAptclickShowStartDateSaveSettingSearch>
                                        <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.start_date }}</div>
                        </div>

                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <div class="input-group">
                                    <input type="text" #waitlistFromAptinputEndDateSaveSettingSearch
                                        [class.input-error]="errorResponse.end_date != '' || error_invalid_date != ''"
                                        class="form-control calender-input bc-white" id="endDate1" disabled
                                        placeholder="To" [(ngModel)]="searchOpenings.end_date_display"
                                        [ngModelOptions]="{standalone: true}">
                                    <div class="input-group-append" #waitlistFromAptclickShowEndDateSaveSettingSearch>
                                        <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="message-error">{{ errorResponse.end_date }}</div>
                        </div>
                        <div class="col-12 pr-0 message-error">
                            {{error_invalid_date}}
                        </div>

                    </div>
                </div>

                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div class="col-2 col-xs-2 no-padding-right display-flex line-height-30 px-0">Time</div>
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <select class="form-control select-time-save-setting" (change)="onChange('startTime1')"
                                    id="startTime1"
                                    [class.input-error]="errorResponse.start_time != '' || error_invalid_time != ''" [(ngModel)]="searchOpenings.start_time" [ngModelOptions]="{standalone: true}">
                                    <option value='' selected>From</option>
                                    <option class="select-option" *ngFor="let time of _systemData.arrayTimes"
                                        [value]="time.value">
                                        {{ time.text }}</option>
                                </select>
                                <div class="cursorPointer selecter-save-block">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.start_time }}</div>
                        </div>
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <select class="form-control select-time-save-setting" id="endTime1"
                                    (change)="onChange('endTime1')"
                                    [class.input-error]="errorResponse.end_time != '' || error_invalid_time != ''" [(ngModel)]="searchOpenings.end_time" [ngModelOptions]="{standalone: true}">
                                    <option value='' selected>To</option>
                                    <option class="select-option" *ngFor="let time of _systemData.arrayTimes"
                                        [value]="time.value">
                                        {{ time.text }}</option>
                                </select>
                                <div class="cursorPointer selecter-save-block">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.end_time }}</div>
                        </div>
                        <div class="col-12 pr-0 message-error">
                            {{error_invalid_time}}
                        </div>
                    </div>
                </div>  
                <div class="row col-12 col-xs-12 no-padding-left-right mt-2">
                    <div class="col-2 col-xs-2 no-padding-right display-flex line-height-30 mt-1 px-0">Availability</div>
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-12 pr-0 fieldWrap">
                            <div class="col-xs-12 col-sm-12 col-lg-12 px-0 fieldWrap">
                                <select class="form-control select-time-save-setting" [(ngModel)]="searchOpenings.advance_notice_duration"
                                    [ngModelOptions]="{standalone: true}" id="advance_notice_duration"  (change)="onChange('advance_notice_duration')">
                                    <option *ngFor="let option of advancenoticedurations" [value]="option.code">
                                        {{ option.label }}</option>
                                </select>
                                <div class="cursorPointer selecter secondary-select mt-1">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>   
                            <label class="message-error" *ngIf="errorResponse.advance_notice_duration">{{ errorResponse.advance_notice_duration }}</label>
                        </div>                      
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-md-2 col-md-offset-1 no-padding-right find-appointment-line-title display-flex px-0">
                        Appt. Reason</div>
                    
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-12 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right">
                                <textarea id="appointment_reason1" name="appointment_reason1" [(ngModel)]="searchOpenings.appointment_reason" class="form-control"></textarea>
                            </div>                            
                        </div>
                                                
                    </div>
                </div>
                
                <div class="row col-12 col-xs-12 no-padding-left-right pt_5">
                    <div class="col-2 col-xs-2 line-height-30 no-padding-left-right checkbox-info px-0">
                        <div class="input-rtn display-flex">
                            <input class="checkbox check-single" type="checkbox" id="searchOpenings-email1"
                                [(ngModel)]="searchOpenings.enable_email" [ngModelOptions]="{standalone: true}"
                                (click)="removeErrEmail()">
                            <label for="searchOpenings-email1" class="selectorExtreme rtn-text-grey-85"></label>
                            <span class="checkbox-text">Email:</span>
                        </div>
                    </div>
                    <div class="col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-xs-12 col-sm-5 col-lg-12 pr-0">
                            <input class="form-control" type="text" [(ngModel)]="searchOpenings.email" id='emailInput1'
                                [disabled]="!searchOpenings.enable_email" (change)="onChange('email')"
                                [ngModelOptions]="{standalone: true}"
                                [class.input-error]="errorResponse.email != '' && searchOpenings.enable_email == 1"
                                (keyup)="onChange('email')">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-12 message-error">{{ errorResponse.email }}</div>
                    </div>
                </div>


                <div class="row col-12 col-xs-12 no-padding-left-right pt_10">
                    <div class="col-2 col-xs-2 line-height-30 no-padding-left-right checkbox-info px-0">
                        <div class="input-rtn display-flex">
                            <input class="checkbox check-single" type="checkbox" id="searchOpenings-sms1"
                                [(ngModel)]="searchOpenings.enable_sms" [ngModelOptions]="{standalone: true}" (click)="removeErrPhone()">
                            <label for="searchOpenings-sms1" class="selectorExtreme rtn-text-grey-85"></label>
                            <span class="checkbox-text">
                                <span class="hidden-xs">Text/</span>
                                SMS:
                            </span>
                        </div>
                    </div>
                    <div class="col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="row col-12 pr-0">
                            <div class="row col-11 pl-0" *ngIf="loadIconVerifiedPhone">
                                <rtn-mobile-number [formInput]="searchOpenings"
                                    [isDisabled]="!searchOpenings.enable_sms" [error]="errorResponse.mobile != '' && searchOpenings.enable_sms"
                                    (keyup)="onChange('mobile')">
                                </rtn-mobile-number>
                            </div>
                            <div class="row col-12 px-0" *ngIf="!loadIconVerifiedPhone">
                                <rtn-mobile-number [formInput]="searchOpenings"
                                    [isDisabled]="!searchOpenings.enable_sms" [error]="errorResponse.mobile != '' && searchOpenings.enable_sms"
                                    (keyup)="onChange('mobile')">
                                </rtn-mobile-number>
                            </div>
                            <div class="message-error">{{ errorResponse.mobile }}</div>
                            <div class="row col-1 col-md-1 col-xs-1 no-padding-right" *ngIf="loadIconVerifiedPhone">
                                <i *ngIf="verifiedPhone" class="fa fa-check phone-verified height_18" aria-hidden="true"
                                    title="Confirmed"></i>
                                <i *ngIf="!verifiedPhone" class="fa fa-times phone-unverified height_18" aria-hidden="true"
                                    title="Not confirmed yet"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="button height_76">
            <button type="button" class="btn btn-rtn btn-90 float-right  ml-2" (click)="processSaveSearch()" autofocus>Save
                    </button>
            <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel float-right" data-dismiss="modal"
                (click)="dismiss()">Cancel
            </button>
            <span class="message-success">{{ successSaveOpeningSearch }}</span>
            <span class="message-error">{{ errorSaveOpeningSearch }}</span>
        </div>
    </form>
</modal>