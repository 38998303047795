export class FamilyMember {
  id: number;
  is_insert = true;
  status: number;
  first_name = '';
  last_name = '';
  month = 'null';
  day = 'null';
  year = 'null';
  dob = '';
  over18 = false;
  show = false;
  email = '';
  zip = '';
  // insurance_company_id: string = '';
  is_same_insurance: any = 1;
  // insurance_plan_id: string = '';
  insurance_id = '';
  id_number = '';
  insurance_number = '';
}
