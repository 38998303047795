import {Component, Output, EventEmitter, ViewChild, OnDestroy} from '@angular/core';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
// import { ModalsService } from '../service/modals.service';
import {RestService as ModalsService} from '@app/core/rest.service';
import {ModalsConfirmationComponent} from '@app/shared/modals/modal-confirmation/modal-confirmation.component';
import {AlertService} from '@app/shared/alert';
import {Utils} from '@app/shared/utils';
import {Subscription} from 'rxjs';
import {DocumentsService} from '@app/documents/documents.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnDestroy {

  getCapacitySubscriber: Subscription;
  uploadDocumentSubscriber: Subscription;

  @ViewChild('DocumentUpload_Modals') modal: ModalComponent;
  @ViewChild(ModalsConfirmationComponent) confirmation: ModalsConfirmationComponent;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();

  files: any = [];
  uploadedFile = 0;
  totalFile = 0;
  cancelButtonText = 'Cancel';
  startButtonText = 'Start';
  uploading = false;
  uploaded = false;
  percentProgress: string;
  isCancel = false;
  capacity: number;
  maxCapacity: number = 100 * Math.pow(1024, 2);
  startDisabled = true;
  addFileDisabled = false;

  constructor(
    private _modalsService: DocumentsService,
    private _utils: Utils,
    private _alertService: AlertService
  ) {
    // TODO
  }

  ngOnDestroy() {
    if (this.getCapacitySubscriber) {
      this.getCapacitySubscriber.unsubscribe();
    }
    if (this.uploadDocumentSubscriber) {
      this.uploadDocumentSubscriber.unsubscribe();
    }
  }

  // ========== Specific Modal Method ========== //

  getCapacity() {
    if (this.getCapacitySubscriber) {
      this.getCapacitySubscriber.unsubscribe();
    }
    this.getCapacitySubscriber = this._modalsService.getCapacity().subscribe(
      (success: any) => {
        this.capacity = parseInt(success.capacity, 10);
        if (this.capacity < this.maxCapacity) {
          this.modal.open();
        }
      });
  }

  onChange(event: any) {
    const files = event.target.files || event.srcElement.files;
    for (let i = 0; i < files.length; i++) {
      this.capacity += files[i].size;
      if (files[i].type === 'application/pdf') { // && this.capacity < this.maxCapacity) {
        const item = new UploadFile();
        item.file = files[i];
        this.files.push(item);
      }
    }
    this.totalFile = this.files.length;
    this.startDisabled = false;
    jQuery('#input-file').val('');
    setTimeout(function () {
      jQuery('.focus-button').focus();
    }, 400);
  }

  triggerFileChooser(e: any) {
    e.stopPropagation();
    if (!this.addFileDisabled) {
      jQuery('#input-file').trigger('click');
    }
  }

  uploadClicked(e: any) {
    if (this.uploaded) {
      this.callEventReloadPage(false);
      this.close();
      this.reset();
      this.reload.emit(e);
    } else {
      this.callToWebService(this.files[0].file, 0);
      this.files[0].uploadQueue = false;
      this.files[0].uploading = true;
      this.uploading = true;
      this.addFileDisabled = true;
      this.callEventReloadPage(true);
    }
  }

  removeFile(file: any) {
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i].file === file) {
        this.files.splice(i, 1);
        break;
      }
    }
    this.totalFile -= 1;
    this.startDisabled = this.totalFile === 0 ? true : false;
  }

  callToWebService(file: any, is_duplicate: number) {
    if (file.size === 0) { // Check if file have remove from upload bar
      this._alertService.onClose = () => {
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].uploading) {
            this.files[i].uploading = false;
            this.files[i].uploadError = true;
            if (this.files[this.totalFile - 1].uploaded || this.files[this.totalFile - 1].uploadError) {
              this.uploading = false;
              this.uploaded = true;
              this.startButtonText = 'Done';
            } else {
              this.callToWebService(this.files[i + 1].file, 0);
              this.files[i + 1].uploadQueue = false;
              this.files[i + 1].uploading = true;
              break;
            }
          }
        }
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].file === file) {
            this.files.splice(i, 1);
            break;
          }
        }
        this.totalFile -= 1;
        const percentProgress: number = this.uploadedFile / this.totalFile * 100;
        this.percentProgress = percentProgress + '%';
        this.callEventReloadPage(false);
      };
      this._alertService.showDismissButton = false;
      this._alertService.dismissButtonLabel = 'Close';
      this._alertService.showCloseButton = true;
      this._alertService.closeButtonLabel = 'OK';
      this._alertService.title = 'This document is not available.';
      this._alertService.message = '';
      this._alertService.emitter('open');
    } else {
      if (this.uploadDocumentSubscriber) {
        this.uploadDocumentSubscriber.unsubscribe();
      }
      this.uploadDocumentSubscriber = this._modalsService.uploadDocument(file, is_duplicate).subscribe(
        (success: any) => {
          if (success.error_code === 'RC000') {
            for (let i = 0; i < this.files.length; i++) {
              if (this.files[i].uploading) {
                this.files[i].uploading = false;
                this.files[i].uploaded = true;
                this.uploadedFile += 1;
                if (this.files[this.totalFile - 1].uploaded || this.files[this.totalFile - 1].uploadError) {
                  this.uploading = false;
                  this.uploaded = true;
                  this.startButtonText = 'Done';
                } else {
                  if (!this.isCancel) {
                    this.callToWebService(this.files[i + 1].file, 0);
                  }
                  this.files[i + 1].uploadQueue = false;
                  this.files[i + 1].uploading = true;
                  break;
                }
              }
            }
            const percentProgress: number = this.uploadedFile / this.totalFile * 100;
            this.percentProgress = percentProgress + '%';
            this.callEventReloadPage(false);
          }
        },
        (error: any) => {
          console.log(error);
          const e = JSON.parse(error);
          switch (e.error_code) {
            case 'RC035':
            case 'RC073':
              this.overSizeFile(e.error_messages);
              break;
            case 'RC036':
            case 'RC037':
              this.confirmation.message = e.error_messages;
              this.isCancel = false;
              this.confirmation.open();
              break;
            case 'RC038':
              break;
          }
          this.callEventReloadPage(false);
        });
    }
  }

  overSizeFile(msg: string) {
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i].uploading) {
        this.files[i].uploading = false;
        this.files[i].uploadError = true;
        this.files[i].message = msg;
        if (this.files[this.totalFile - 1].uploaded || this.files[this.totalFile - 1].uploadError) {
          this.uploading = false;
          this.uploaded = true;
          this.startButtonText = 'Done';
        } else {
          this.callToWebService(this.files[i + 1].file, 0);
          this.files[i + 1].uploadQueue = false;
          this.files[i + 1].uploading = true;
          break;
        }
      }
    }
  }

  closePopup(e: any) {
    if (this.uploading) {
      this.isCancel = true;
      this.confirmation.message = 'Are you sure you want to cancel?';
      this.confirmation.open();
    } else if (this.uploaded) {
      return;
    } else {
      this.close();
      this.reset();
      this.reload.emit(e);
    }
  }

  confirmationNoClicked() {
    this.callEventReloadPage(false);
    this.confirmation.close();
    if (this.isCancel) {
      return;
    } else {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].uploading) {
          this.files[i].uploading = false;
          this.files[i].uploadError = true;
          if (this.files[this.totalFile - 1].uploaded || this.files[this.totalFile - 1].uploadError) {
            this.uploading = false;
            this.uploaded = true;
            this.startButtonText = 'Done';
          } else {
            this.callToWebService(this.files[i + 1].file, 0);
            this.files[i + 1].uploadQueue = false;
            this.files[i + 1].uploading = true;
            break;
          }
        }
      }
    }
    this.isCancel = false;
  }

  confirmationYesClicked() {
    this.callEventReloadPage(true);
    this.confirmation.close();
    if (this.isCancel) {
      this.close();
      this.reset();
      this._modalsService.cancelUpload();
      this.reload.emit(new Object());
    } else {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].uploading) {
          this.callToWebService(this.files[i].file, 1);
          break;
        }
      }
    }
    this.isCancel = false;
  }

  callEventReloadPage(status: any) {
    if (status) {
      window.onbeforeunload = function () {
        return 'Do you want to refresh page while uploading ?';
      };
    } else {
      window.onbeforeunload = function () {
        // TODO
      };
    }
  }

  // ========== General Modal Method ========== //

  reset() {
    this.files = [];
    this.uploadedFile = this.totalFile = 0;
    this.percentProgress = '0%';
    this.uploading = this.uploaded = this.addFileDisabled = false;
    this.startButtonText = 'Start';
    this.startDisabled = true;
    jQuery('#input-file').val('');
  }

  open() {
    this.getCapacity();
  }

  close() {
    this.modal.close();
  }
}

export class UploadFile {
  file: any;
  message: string;
  uploaded = false;
  uploading = false;
  uploadQueue = true;
  uploadError = false;
}

