<div class="tl">
    <div class="tl-date-picker d-flex justify-content-between">
        <div class="btn-group">
            <button type="button" class="btn btn-outline text-primary dropdown-toggle p-1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i class="fas fa-bars ml-1"></i></button>
            <div class="dropdown-menu master-menu">
                <li>
                    <a class="show-focus dropdown-item " [routerLink]="['/provider', 'practice']">
                        <span class="hidden-xs hidden-sm">PRACTICE MANAGEMENT</span>
                    </a>
                </li>
                <li>
                    <a class="show-focus dropdown-item" [routerLink]="['/provider', 'user']">
                        <span class="hidden-xs hidden-sm">MANAGING USERS</span>
                    </a>
                </li>
                <li>
                    <a class="show-focus dropdown-item" [routerLink]="['/provider', 'list']">
                        <span class="hidden-xs hidden-sm">PROVIDERS</span>
                    </a>
                </li>
                <li>
                    <a class="show-focus dropdown-item" [routerLink]="['/provider', 'patient']">
                        <span class="hidden-xs hidden-sm">PATIENTS</span>
                    </a>
                </li>
                <li class="cursor_pointer">
                    <a class="show-focus dropdown-item" (click)="logoutDirective.open()">
                        <span class="hidden-xs hidden-sm">LOG OUT</span>
                    </a>
                    <!-- <a (click)="logoutManaging()" class="show-focus dropdown-item" *ngIf="isManagingMode">
                        <span class="hidden-xs hidden-sm">LOG OUT</span>
                    </a> -->
                </li>
            </div>
        </div>
        <div class="tl-date-picker-container dropdown dropdown-toggle">
            <span class="tl-date-prev pull-left" (click)="prevDateOnClick()"><span
                    class="fa fa-angle-left"></span></span>
            <span class="tl-date button-dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                #clickShowDateTimlineAppointment
                aria-expanded="false">{{selectedDate?.format("dddd, MMMM DD, YYYY")}}</span>
            <span class="tl-date-next pull-right" (click)="nextDateOnClick()"><span
                    class="fa fa-angle-right"></span></span>
            <ul class="dropdown-menu dropdown-menu-date-timeline no-padding">
                <li>
                    <div #inputDateTimlineAppointment id="date-select"></div>
                    <!--<div class="calendar">
                        <rtn-calendar #tlDatePicker [todayHighlight]="ngDatePicker.todayHighlight" [weekStart]="ngDatePicker.weekStart" [daysOfWeekHighlighted]="ngDatePicker.daysOfWeekHighlighted" [beforeShowDay]="ngDatePicker.beforeShowDay" [todayBtn]="true" [defaultViewDate]="ngDatePicker.defaultViewDate"
                            (onChangeDate)="handleDatePickerDayClick($event)" (onShow)="handleDatePickerOnShow($event)"></rtn-calendar>

                    </div>-->
                </li>
            </ul>
        </div>
        <span class="mr-3 pull-right current-time pt-2 mb-2">            
            <span class="time-value">
                <select
                    #Select2Provider3Directive="select2-provider3"
                    select2-provider3 
                    class="form-control"
                    [value]="selectedproviderID"
                    [closeOnSelect]="true"
                    [maximumSelectionLength]="0"
                    [multiple]="false"
                    id="select2Provider"
                    placeholder="Filter By Provider"
                    [tags]="false"
                    (OnSelected)="onChangeProvider()"
                    >
                    <option value=""></option>
                    <option *ngFor="let provider of providersList; let i = index" [id]="'provider' + provider.id" [value]="provider.id" >
                        {{ provider.full_name }}
                    </option>
                </select>
               
            </span>
        </span>
        <span class="mr-3 pull-right current-time pt-2"><span class="time-label">Current time:</span><span
                class="time-value">{{ _systemData.currentTimeDisplay24hFormat }}</span></span>        
    </div>
    <!-- ======= Header Area ======= -->
    <table class="tl-timeline-table">
        <thead class="tl-table-header">
            <tr>
                <!--Left Box on Header-->
                <td class="tl-left wid_450">
                    <div class="tl-scroller-off">
                        <div>
                            <div class="tl-area-scroller">
                                <div class="tl-area-content">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <div>
                                                        <div class="tl-table-cell text-center">
                                                            <span class="tl-table-cell-label">
                                                                Patient
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="wid_p_40 table_cell_arrive">
                                                    <div class="height_full">
                                                        <div class="mt_9">Provider</div>
                                                    </div>
                                                </th>
                                                <th class="wid_75 table_cell_arrive">
                                                    <div class="height_full">
                                                        <div class="mt_9">Arrived</div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="tl-right">
                    <!--Right Box on Header-->
                    <div class="tl-scroller-off">
                        <div class="tl-scroller-x">
                            <div class="tl-area-scroller tl-reposition-right">
                                <div class="tl-area-content">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th *ngFor="let item of colArray; let $j = index">
                                                    <div class="tl-table-cell"><span
                                                            class="tl-table-cell-label">{{item}}</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </thead>
        <tbody class="tl-table-body">
            <!-- ======= Body Area ======= -->
            <tr>
                <td class="tl-left">
                    <!--Left Box on Body-->
                    <div class="tl-scroller-off">
                        <div class="tl-scroller-y">
                            <div class="tl-area-scroller tl-reposition-bottom table-box">
                                <div class="tl-area-content">
                                    <table>
                                        <tbody>
                                            <tr *ngFor="let item of rowArray; let $j = index"
                                                [class.checkin_backgroud_on]="item.events[1] != null && item.events[1].type === 1"
                                                [class.checkin_backgroud_late]="item.events[1] != null && item.events[1].type === 2">
                                                <td>
                                                    <div class="tl-grid-x" [ngStyle]="{'height': tableCellHeight+'px'}">
                                                        <div class="tl-table-cell">
                                                            <span title="{{item.patientName}}" class="table_cell"
                                                                [ngStyle]="{'line-height': tableCellHeight+'px'}"
                                                                [ngClass]="{'notAllowedapt' : (item.appointment_is_allowed == 0 || item.cancel_status_text != '')}">{{item.patientName}}
                                                                &nbsp;<a title="Open Waittime View" [routerLink]="['/appointment/waittime', item.appointmentid_encode]" target="_blank" *ngIf="item.appointment_is_allowed == 1  && item.cancel_status_text == ''"><i class="far fa-clock"></i></a> 
                                                            </span>

                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="wid_p_40">
                                                    <div class="tl-grid-x height_46 width_full">
                                                        <div class="tl-table-cell">
                                                            <span title="{{item.providerName}}" class="table_cell"
                                                                [ngStyle]="{'line-height': tableCellHeight+'px'}">{{item.providerName}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="wid_75" style="vertical-align: inherit;">
                                                    <div class="tl-grid-x height_46 width_full">
                                                        <div class="input-rtn mx-auto my-auto">
                                                            <input class="checkbox check-single"
                                                                [(ngModel)]="item.arrived_time"
                                                                [attr.disabled]="item.isDisabled"
                                                                [value]="item.arrived_time" type="checkbox"
                                                                id="{{item.id}}"
                                                                (change)="updateArrivedTime(item.id, item.arrived_time)">
                                                            <label for="{{item.id}}"
                                                                class="mt-3 selectorExtreme rtn-text-grey-85"></label>
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr *ngIf="numberOfCompensationToShow !== 0">
                                                <!--Compensation Area For No Entry-->
                                                <!-- <td>
                                          <div class="tl-grid-x tl-compensation" [ngStyle]="{'height': heightCompensationBox +'px'}">
                                            <div class="tl-table-cell"></div>
                                          </div>
                                        </td>
                                        <td class="wid_75">
                                          <div class="tl-grid-x tl-compensation" [ngStyle]="{'height': heightCompensationBox +'px'}">
                                            <div class="tl-table-cell"></div>
                                          </div>

                                        </td> -->
                                            </tr>
                                            <tr *ngIf="rowArray.length === 0">
                                                <td>
                                                    <div>
                                                        <div class="tl-table-cell-no-entry"
                                                            [ngStyle]="{'height': 0.8 * innerHeight + 'px', 'line-height': (tableCellHeight*numberOfEntryToShow + hintNextEntryHeight) + 'px'}">
                                                            No patient
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="tl-right">
                    <!--Right Box on Body-->
                    <div class="tl-scroller-off">
                        <div class="tl-scroller-all">
                            <div class="tl-area-scroller">
                                <div class="tl-area-content">
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr *ngFor="let item of rowArray; let $j = index">
                                                    <td>
                                                        <div class="tl-grid-x"
                                                            [ngStyle]="{'height':tableCellHeight+'px'}">
                                                            <div class="tl-entry">
                                                                <div *ngFor="let event of item.events; let $k = index"
                                                                    class="tl-entry-body tl-entry-top"
                                                                    [class.tl-entry-plan]="event.type === 0"
                                                                    [class.tl-entry-on]="event.type === 1"
                                                                    [class.tl-entry-late]="event.type === 2"
                                                                    [class.tl-entry-done]="[3, 4].indexOf(event.type) !== -1"
                                                                    [class.disabled]="event.isDisabled"
                                                                    [class.tl-entry-cancel]="event.type === 5"
                                                                    [ngStyle]="{'left': event.left+'px', 'top': event.top+'px', 'height': eventHeight+'px', 'width': event.width+'px', 'min-width': calculateMinWidth(event) + 'px', 'max-width' : calculateMaxWidth(event) + 'px'}">
                                                                    <div class="tl-entry-content dropdown dropdown-toggle"
                                                                        [ngStyle]="{'line-height': eventHeight+'px'}">
                                                                        <span class="tl-entry-time"
                                                                            [class.tl-entry-time-error]="event.hasError">{{event.labelStartTimeForDisplay}}</span>
                                                                        <span class="tl-entry-tooltip-trigger"
                                                                            (touchstart)="touchHandler($event, event, $j, $k)"
                                                                            (mouseover)="eventEntryMouseOver($event, event, $j, $k)"
                                                                            (mouseout)="eventEntryMouseOut(event)"
                                                                            [ngStyle]="{'height': eventHeight+'px', 'width': (event.width-widthForDropDownMenuToggle) + 'px', 'min-width': (calculateMinWidth(event)-widthForDropDownMenuToggle) + 'px', 'max-width' : (calculateMaxWidth(event) - widthForDropDownMenuToggle) + 'px'}"></span>
                                                                        <span [class.hidden]="isHidden"
                                                                            class="fa fa-sort button-dropdown-toggle"
                                                                            [ngStyle]="{'line-height': eventHeight+'px'}"></span>
                                                                        <span [class.hidden]="isHidden"
                                                                            *ngIf="(!event.isDisabled)"
                                                                            class="tl-entry-dropdown-trigger cursorPointer"
                                                                            (click)="toggleDropdown($event, event, $j, $k)"
                                                                            [ngStyle]="{'height': eventHeight+'px', 'width': (widthForDropDownMenuToggle) + 'px'}"></span>
                                                                        <span [class.hidden]="isHidden"
                                                                            *ngIf="event.isDisabled"
                                                                            class="tl-entry-dropdown-trigger"
                                                                            [ngStyle]="{'height': eventHeight+'px', 'width': (widthForDropDownMenuToggle) + 'px'}"></span>
                                                                        <!-- ON, LATE -->
                                                                        <!--<ng-container *ngIf="event.type != 5">
                                                                            <ul *ngIf="(!event.isDisabled && !_systemData.isOnline) && !(event.type === 0)"
                                                                            class="dropdown-menu"
                                                                            [ngStyle]="{'width': widthForDropDownMenu + 'px', 'min-width': widthForDropDownMenu + 'px'}">
                                                                            <li><a class="cursorPointer dropdown-option"
                                                                                    (click)="cancelCheckInAppointment($event, event)"><i
                                                                                        class="fa fa-close pull-right"></i>Cancel
                                                                                    Check-In</a></li>
                                                                            <li>
                                                                                <div class="dropdown-separator"></div>
                                                                            </li>
                                                                            <li *ngIf="!event.isEndBeforeStart"><a
                                                                                    class="cursorPointer dropdown-option"
                                                                                    (click)="checkOutAppointment($event, event)"><i
                                                                                        class="fa fa-sign-out pull-right"></i>Check
                                                                                    Out</a>
                                                                            </li>
                                                                            <li><a class="cursorPointer dropdown-option"
                                                                                (click)=" notificationMassPatient($event, event) "><i
                                                                                    class="far fa-paper-plane pull-right"></i>Send
                                                                                To</a>
                                                                            </li>
                                                                        </ul>-->
                                                                        <!-- PLAN -->
                                                                        <ul 
                                                                            class="dropdown-menu"
                                                                            [ngStyle]="{'width': widthForDropDownMenu + 'px', 'min-width': widthForDropDownMenu + 'px'}">
                                                                            <!--&& !_systemData.isOnline-->
                                                                            <ng-container *ngIf="event.type != 5 && event.type != 3 && (!event.isDisabled) && !(event.type === 0)">
                                                                                <li><a class="cursorPointer dropdown-option" (click)="cancelCheckInAppointment($event, event)"><i class="fa fa-close pull-right"></i>Cancel Check-In</a></li>
                                                                                <li>
                                                                                    <div class="dropdown-separator"></div>
                                                                                </li>
                                                                                <li *ngIf="!event.isEndBeforeStart"><a class="cursorPointer dropdown-option" (click)="checkOutAppointment($event, event)"><i class="fa fa-sign-out pull-right"></i>Check Out</a></li>
                                                                                <li><a class="cursorPointer dropdown-option" (click)="notificationMassPatient($event, event)"><i class="far fa-paper-plane pull-right"></i>Send To</a></li>
                                                                            </ng-container>

                                                                            <ng-container *ngIf="!event.isDisabled && ([0, 4].indexOf(event.type) !== -1)">

                                                                                <!--<ng-container *ngIf="!_systemData.isOnline">-->
                                                                                  <li *ngIf="event.isCurrentDate && (event.data.status !== event.data.status_table[4])">
                                                                                    <a class="cursorPointer dropdown-option" (click)="checkInAppointment($event, event)"><i class="fa fa-sign-in pull-right"></i>Check-In</a></li>
                                                                                  <li>
                                                                                    <div class="dropdown-separator"></div>
                                                                                  </li>
                                                                                <!--</ng-container>-->
                                                                                <li><a class="cursorPointer dropdown-option" (click)="cancelAppointment($event, event)"><i class="fas fa-close pull-right"></i>Cancel Appointment</a></li>
                                                                                <li>
                                                                                  <div class="dropdown-separator"></div>
                                                                                </li>
                                                                                <li><a class="cursorPointer dropdown-option" (click)="noShowAppointment($event, event)"><i class="fas fa-close pull-right"></i>No Show</a></li>
                                                                                <li>
                                                                                  <div class="dropdown-separator"></div>
                                                                                </li>
                                                                              
                                                                              </ng-container>

                                                                            <!--<ng-container *ngIf="(!event.isDisabled) && ([0, 4].indexOf(event.type) !== -1)">
                                                                            <li *ngIf="event.isCurrentDate && (event.data.status !== event.data.status_table[4])">
                                                                                <a class="cursorPointer dropdown-option" (click)="checkInAppointment($event, event)"><i class="fa fa-sign-in pull-right"></i>Check-In</a>
                                                                            </li>
                                                                            <li>
                                                                                <div class="dropdown-separator"></div>
                                                                            </li>
                                                                            

                                                                            <li><a class="cursorPointer dropdown-option" (click)="cancelAppointment($event, event)"><i class="fas fa-close pull-right"></i>Cancel Appointment</a></li>
                                                                            <li>
                                                                                <div class="dropdown-separator"></div>
                                                                            </li>
                                                                            <li><a class="cursorPointer dropdown-option" (click)="noShowAppointment($event, event)"><i class="fas fa-close pull-right"></i>No Show</a></li>
                                                                            <li>
                                                                                <div class="dropdown-separator"></div>
                                                                            </li>
                                                                            </ng-container>-->
                                                                            <li><a class="cursorPointer dropdown-option" (click)="notificationMassPatient($event, event)"><i class="far fa-paper-plane pull-right"></i>Send To</a></li>
                                                                        </ul>
                                                                        <!--</ng-container>-->
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <!--Compensation Area For No Entry-->
                                                <tr *ngIf="numberOfCompensationToShow !== 0">
                                                    <td>
                                                        <div class="tl-grid-x tl-compensation"
                                                            [ngStyle]="{'height': heightCompensationBox +'px'}">
                                                            <div class="tl-entry">
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="rowArray.length === 0">
                                                    <td>
                                                        <div>
                                                            <div class="tl-table-cell-no-entry"
                                                                [ngStyle]="{'height': 0.8*innerHeight + 'px', 'line-height': (tableCellHeight*numberOfEntryToShow + hintNextEntryHeight) + 'px'}">
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!--Draw Vertical Grid-->

                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<app-schedule-check-in-component (reload)="refetchAppointment()"></app-schedule-check-in-component>
<app-schedule-check-out-component (reload)="refetchAppointment()"></app-schedule-check-out-component>
<app-schedules-mass-patient-notification></app-schedules-mass-patient-notification>
<app-schedule-cancel-component (reload)="refetchAppointment()"></app-schedule-cancel-component>
<app-schedule-edit-component (reload)="refetchAppointment()"></app-schedule-edit-component>
<app-schedule-cancel-check-in-component (reload)="refetchAppointment()"></app-schedule-cancel-check-in-component>
<rtn-modals-general-logout></rtn-modals-general-logout>

<div style="display:none;">
    <app-schedule-tooltip></app-schedule-tooltip>
</div>