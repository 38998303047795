<div *ngIf="upcommingappointments.length > 0" class="patientFutureList">
  <div>
    <strong>Future Appointment:</strong> 
    <span *ngIf="loadedpage == 'provider_book_wl' || loadedpage == 'patient_book_wl' || loadedpage == 'provider_wl'">Please select the follow-up appointment for this wait list.</span>
    <span *ngIf="loadedpage == 'provider_book' || loadedpage == 'patient_schedule'">Please click the BOOK button to make a new booking, or select the follow-up appointment if you want to reschedule any of the booked appointments below.</span>
  </div>
  <div class="row col-lg-12 col-md-12 col-xs-12 px-3">
    <div class="py-2"  [ngClass]="{ 'col-3': (loadedpage == 'patient_schedule'), 'col-6': (loadedpage != 'patient_schedule'), 'col-4': (loadedpage == 'patient_book_wl') }"  *ngFor="let appt of upcommingappointments">
      <div [ngClass]="{'selected-bc': (selectedupcommingId == appt.id)}" class="btn btn-default btn-apt-slot" 
           (click)="selectAppointment(appt.id)" title="{{appt.location_name}} - {{appt.apt_label}}"><strong>{{ appt.slot_date }} : </strong>{{ appt.slot_check_in }}&nbsp;-&nbsp;{{ appt.slot_check_out }}</div>
    </div>            
  </div>
</div>