export class AppointmentDetail {
  id: number;
  enableWaitTimeMeter = true;
  waitingTime = 0;
  notice: string;
  launchDate: string;
  startingTime: string;
  endingTime: string;
  duration: number;
  providerName: string;
  providerAddress: string;

  provider_first_name: string;
  provider_middle_name: string;
  provider_last_name: string;
  provider_phone: string;
  patient_first_name: string;
  patient_middle_name: string;
  patient_last_name: string;
  address: string;
  address2: string;
  city: string;
  state_code: string;
  zip: string;
  specialty: string;
  date: string;
  time: string;
  isSelected: boolean;
  is_active: boolean;
  hasLink: boolean;
  in: any = {
    date: null,
    time: null
  };
  out: any = {
    time: null
  };
  plan_check_in: string;
  plan_check_out: string;
  currentTime: string;
  isActive = false;
  credentials: any;
  appointment_reason: string = '';
}
