<rtn-documents-header></rtn-documents-header>
<div class="col-sm-12 customer-portal" [style.min-height.px]="minHeight">
  <div class="provider-document">
    <h3 class="d-none d-sm-none d-xl-block d-lg-block d-md-block">Documents
      <span class="capacity">({{ capacityDisplay }} of {{ maxCapacityDisplay }} used)</span>
      <span class="float-right">
                <button class="btn btn-rtn btn-document" (click)="documentUploadDirective.open()"
                        [disabled]="isOverCapacity">
                    <span class="label">Add Files</span>
                </button>
                <button class="btn btn-rtn btn-document btn-red" (click)="showDocumentDeleteDirective()">
                    <span class="label">Delete Files</span>
                </button>
            </span>
    </h3>
    <div class="row">
      <rtn-table #table (onDataChange)="handleFetchDocumentList($event)" [data]="documentList.data"
                 [rowsOnPage]="documentList.rowsOnPage" [numberOfPages]="documentList.numberOfPages">
        <div class="col-xs-12 listview">
          <div class="table-responsive no-overflow">
            <table class="table table-bordered table-ellipsis">
              <thead>
              <tr>
                <th class="document-list">
                  <div class="input-rtn"><input class="checkbox check-all" type="checkbox" name="selectAll"
                                                id="selectorAll" (click)="selectAll()"><label class="selectorExtreme"
                                                                                              for="selectorAll"></label>
                  </div>
                </th>
                <th class="document-table-text document-order">No.</th>
                <th class="cell-ellipsis document-table-text document-name" id="document-name">Name</th>
                <th class="cell-ellipsis document-table-text document-size" id="document-size">Size</th>
                <th class="cell-ellipsis document-table-text document-date" id="document-date">Upload Date</th>
                <th class="document-table-text document-action"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of table.data; let i = index">
                <td>
                  <div class="input-rtn"><input class="checkbox check-single" type="checkbox" name="select-list[]"
                                                value="{{item.id}}" (click)="checkSelectStatus()"
                                                [attr.id]="'selector'+i"><label class="selectorExtreme"
                                                                                [attr.for]="'selector'+i"></label></div>
                </td>
                <td class="cell-ellipsis text-center">{{(table.currentPage - 1) * table.rowsOnPage + (i + 1)}}</td>
                <td class="cell-ellipsis">{{item.name}}</td>
                <td class="cell-ellipsis">{{item.file_size}}</td>
                <td class="cell-ellipsis">{{item.upload_date}}</td>
                <td class="text-center action"><a (click)="downloadFile(item.download_url)"><span
                  class="label">View</span></a>
                </td>
              </tr>
              <tr *ngIf="table.numberOfPages < 1">
                <td colspan="7" class="no-document">There is no document in the list</td>
              </tr>
              </tbody>
              <tfoot *ngIf="table.numberOfPages > 1">
              <tr>
                <td colspan="6" class="text-center">
                  <div class="page-footer">
                    <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </rtn-table>
    </div>
  </div>
  <app-document-delete (onClick)="deleteDocuments(table)"></app-document-delete>
  <app-document-upload (reload)="handleFetchDocumentList($event)"></app-document-upload>
</div>
<rtn-common-footer></rtn-common-footer>