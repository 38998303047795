import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {AlertService} from '@app/shared/alert/alert.service';
import {SystemData} from '@app/shared/system/system-data';
import {SystemService} from '@app/shared/system/system.service';
import {ModalComponent} from '@app/shared/modal/modal.component';
import {DOBComponent} from '@app/shared/dob/dob/dob.component';
import {Subscription} from 'rxjs';
import {PatientDetail} from '@app/shared/models/patientDetail';
import {RestService} from '@app/core/rest.service';
import {ProvidersService} from '@app/providers/providers.service';

@Component({
    moduleId: module.id,
    selector: 'app-rtn-modals-patient-new-sign-up',
    templateUrl: './patient-new-sign-up.component.html',
    styleUrls: ['./patient-new-sign-up.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PatientNewSignUpComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output()
    reload: EventEmitter<any> = new EventEmitter();
    insurances: any;
    signUpPatient: PatientDetail = new PatientDetail();
    error: PatientDetail = new PatientDetail();

    signUpNewPatientSubscriber: Subscription;

    @ViewChild('PatientNewSignUp_Modals') modal: ModalComponent;
    @ViewChild(DOBComponent) dobDirective: DOBComponent;

    /*ngDatePicker: any;
    _weekStart: number = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)*/
    dob: string;
    buttonclicked : boolean = false;
    constructor(
        private _systemService: SystemService,
        public _systemData: SystemData,
        private _restService: ProvidersService,
        private _alertService: AlertService,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        /*this.ngDatePicker = new Object();
        this.ngDatePicker['todayHighlight'] = true;
        this.ngDatePicker['weekStart'] = this._weekStart;
        this.ngDatePicker['format'] = 'MM dd, yyyy';*/
    }

    // ========== Specific Modal Method ========== //

    submitSignUpClicked() {
        this.buttonclicked = true;
        this._loadingIndicatorService.start();
        this.signUpPatient.user_role = 'PROVIDER';
        const ignoreArray = [NaN, null, undefined, ''];
        if (ignoreArray.indexOf(this.signUpPatient.month) > -1 && ignoreArray.indexOf(this.signUpPatient.day) > -1
            && ignoreArray.indexOf(this.signUpPatient.year) > -1) {
            this.signUpPatient.dob = '';
        } else {
            this.signUpPatient.dob = this.signUpPatient.year + '-'
                + this.signUpPatient.month + '-' + this.signUpPatient.day;
        }
        if (this.signUpNewPatientSubscriber) {
            this.signUpNewPatientSubscriber.unsubscribe();
        }
        this.signUpNewPatientSubscriber = this._restService.signUpNewPatient(this.signUpPatient).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.buttonclicked = false;
                if (success.error_code === 'RC000') {
                    jQuery('.showInsuranceNumber').removeClass('hidden');
                    this.close();
                    this.dismiss();
                    this.openAlertMessage(success.error_messages);
                }
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                this.buttonclicked = false;
                const e = error.error;
                if (e.error_code === 'RC020') {
                    this.error = e.error_fields;
                } else if (e.error_code === 'RC107' || e.error_code === 'RC106'
                    || e.error_code === 'RC110' || e.error_code === 'RC111') {
                    this.openAlertMessage(e.error_messages);
                }
            }, () => {
                this._loadingIndicatorService.stop();
                this.buttonclicked = false;
            }
        );
    }

    openAlertMessage(msg: string) {
        this._alertService.onClose = () => {
            // TODO
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    changeInsurance(plan: any) {
        this.error.insurance_id = null;
        if (plan === '-1') {
            this.signUpPatient.insurance_number = '';
            jQuery('.showInsuranceNumber').addClass('hidden');
        } else {
            jQuery('.showInsuranceNumber').removeClass('hidden');
        }
    }

    // ========== Class Method ========== //

    ngOnInit() {
        /*this._systemService.getStates();*/
    }

    ngAfterViewInit() {
        const _self = this;
        /* jQuery('#insurance_number').bind('keypress', function (e: any) {
            return !(e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57));
        }); */
        jQuery('#insurance_number').bind({
            paste: function () {
                _self.onKeyUp('insurance_number');
            }
        });
        jQuery('.showInsuranceNumber').addClass('hidden');
    }

    ngOnDestroy() {
        if (this.signUpNewPatientSubscriber) {
            this.signUpNewPatientSubscriber.unsubscribe();
        }
    }

    // ========== General Modal Method ========== //

    dismiss() {
        this.signUpPatient = new PatientDetail();
        this.error = new PatientDetail();
        this.dob = undefined;
    }

    onKeyUp(error: string) {
        switch (error) {
            case 'email':
                this.error.email = null;
                break;

            case 'first_name':
                this.error.first_name = null;
                break;

            case 'last_name':
                this.error.last_name = null;
                break;

            case 'dob':
                this.error.dob = null;
                break;

            /*case 'ssn':
                this.error.ssn = null;
                break;*/

            case 'phone_mobile':
                this.error.phone_mobile = null;
                break;

            case 'zip':
                this.error.zip = null;
                break;
            case 'insurance_id':
                this.error.insurance_id = null;
                break;
            case 'insurance_number':
                this.error.insurance_number = null;
                break;
        }
    }

    open() {
       this._restService.getInsuranceList().subscribe(
            (success: any) => {
              const noInsuranceOption = {
                'id': '-1',
                'name': 'No Insurance/Self Pay'
              };
              this.insurances = success.insurances;
              this.insurances.unshift(noInsuranceOption);
            },
            (error: any) => {
            },
            () => {
                this.modal.open();
            }
          );
    }

    close() {
        this.modal.close();
    }
}
