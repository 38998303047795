<div class="container physician-directory">
  <div class="header text-align-center">
    <h1 class="text-uppercase text-header">RTN - Provider Directory</h1>
  </div>
  <div class="col-xs-12 body">
    <div class="text-align-center">
      <div class="row directory-letter">
        <div class="text-center alphabet-letter letter-bold padding-top-20" *ngFor="let l of physicianDirectoryLetter" (click)="scrollArchor(l)">{{ l }}</div>
      </div>
      <div class="text-desc padding-bottom-20">[each letter anchors to section below]</div>
    </div>
    <div>
      <div class="directory-physician" *ngFor="let d of physicianDirectory">
        <div>
          <span class="letter-archor" id="{{ d.letter }}">{{ d.letter }}</span>
          <span class="float-right link back-to-top" (click)="backToTop()">back to top</span>
        </div>
        <div *ngFor="let p of d.physician">
          {{ p.full_name }}<span [class.hidden]="p.credentials === null || p.credentials === ''">, {{ p.credentials }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
