import {Component, Output, EventEmitter, OnDestroy} from '@angular/core';
// import {AppointmentCancelService} from '@app/appointments/modals/appointment-cancel/appointment-cancel.service';
import {AlertService} from '@app/shared/alert';
import {SimpleModalsService} from '@app/shared/modals/simple-modals/simple-modals.service';
import {SimpleModalsComponent} from '@app/shared/modals/simple-modals/simple-modals.component';
import {Event} from '@app/appointments/timeline/timeline.component';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {AppointmentsService} from '@app/appointments/appointments.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

@Component({
    moduleId: module.id,
    template: `
        <rtn-simple-modals></rtn-simple-modals>`,
    selector: 'rtn-modals-appointment-cancel',
    // directives: [SimpleModalsComponent],
    providers: [SimpleModalsService, SimpleModalsService]
})
export class AppointmentCancelComponent implements OnDestroy {
    noShowSubcriber: Subscription;
    cancelAppointmentSubscriber: Subscription;

    @Output()
    reload: EventEmitter<any> = new EventEmitter();

    eventEntry: Event;
    errorMessage: string;

    constructor(
        private Service: AppointmentsService,
        private _simpleModals: SimpleModalsService,
        private _alertService: AlertService,
        private service: RestService,
        private _loadingIndicatorService: LoadingIndicatorService,
    ) {
        // TODO
    }

    ngOnDestroy() {
        if (this.cancelAppointmentSubscriber) {
            this.cancelAppointmentSubscriber.unsubscribe();
        }
    }

    // ========== Specific Modal Method ========== //
    // updateNoShow(schedule_id:any){
    //   if (this.noShowSubcriber) {
    //     this.noShowSubcriber.unsubscribe();
    //   }
    //   this._loadingIndicatorService.start();
    //   this.noShowSubcriber =  this.Service.updateNoShowTime({
    //     schedule_id: schedule_id,
    //   }).subscribe(
    //     (res: any) => {
    //       this._loadingIndicatorService.stop();
    //       if (res.error_code === 'RC000') {
    //       }
    //     },
    //     (error: any) => {
    //       this._loadingIndicatorService.stop();

    //     },
    //     () => {
    //       this._loadingIndicatorService.stop();
    //     }
    //   );
    // }
    open() {
        this._simpleModals.onClose = () => {
            if (this.cancelAppointmentSubscriber) {
                this.cancelAppointmentSubscriber.unsubscribe();
            }
            this.cancelAppointmentSubscriber = this.Service.cancelAppointment(this.eventEntry.data.id).subscribe(
                (success: any) => {
                    if (success.error_code === 'RC000') {
                        this.reload.emit(this.eventEntry);
                        window.location.reload();
                    } else {
                        this.errorMessage = success.error_messages;
                        this.openErrorMessage();
                    }
                },
                (error: any) => {
                    // this.errorMessage = error.error.error_messages;
                    // this.openErrorMessage();
                });

        };
        this._simpleModals.showCloseButton = true;
        this._simpleModals.showDismissButton = true;
        this._simpleModals.closeButtonLabel = 'Yes';
        this._simpleModals.dismissButtonLabel = 'No';
        this._simpleModals.title = 'Are you sure you want to cancel appointment of '
            + this.eventEntry.data.patientName + '?';
        this._alertService.message = '';
        this._simpleModals.emitter('open');
    }

    openNoShow(schedule_id: any) {
        this._simpleModals.onClose = () => {
            this._loadingIndicatorService.start();
            if (this.cancelAppointmentSubscriber) {
                this.cancelAppointmentSubscriber.unsubscribe();
            }

            this.cancelAppointmentSubscriber = this.Service.noShowAppointment({
                apptId: this.eventEntry.data.id,
                schedule_id: schedule_id
            }).subscribe(
                (success: any) => {
                    this._loadingIndicatorService.stop();
                    if (success.error_code === 'RC000') {
                        this.reload.emit(this.eventEntry);
                        window.location.reload();
                    } else {
                        this.errorMessage = success.error_messages;
                        this.openErrorMessage();
                    }
                },
                (error: any) => {
                    // this.errorMessage = error.error.error_messages;
                    // this.openErrorMessage();
                });


        };
        this._simpleModals.showCloseButton = true;
        this._simpleModals.showDismissButton = true;
        this._simpleModals.closeButtonLabel = 'Yes';
        this._simpleModals.dismissButtonLabel = 'No';
        this._simpleModals.title = 'Are you sure you want to no show appointment of '
            + this.eventEntry.data.patientName + '?';
        this._alertService.message = '';
        this._simpleModals.emitter('open');
    }

    openErrorMessage() {
        this._alertService.onClose = () => {
            this.reload.emit(this.eventEntry);
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = this.errorMessage;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }
}
