import { Component, Optional } from '@angular/core';
import { RtnDataTableCoreComponent } from './core';

@Component({
    selector: 'rtn-table-filter',
    template: `<ng-content></ng-content>`
})

export class RtnDataTableFilterComponent {
    public constructor(@Optional() public dataTable: RtnDataTableCoreComponent) {
        // TODO 
    }

    public query(_params: any): void {
        if (_params['currentPage'] == null) {
            _params['currentPage'] = 1;
        }
        this.dataTable.setFilter(_params);
    }
}
