import {Injectable, EventEmitter} from '@angular/core';

/**
 * Singleton service, injected at app level
 */
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private _title: string;
  private _message: string;
  private _backdrop: string | boolean = 'static';
  private _keyboard = false;
  private _animation = true;
  private _labelCustomInputHtml: string;
  private _inputValueInputHtml: string;
  private _showCustomInputHtml = false;
  private _typeCustomInputHtml = false;
  private _showDefaultButtons = false;
  private _showDismissButton = false;
  private _showCloseButton = true;
  private _dismissButtonLabel = 'Dismiss';
  private _closeButtonLabel = 'Close';
  private _inputTypeHtml = 'text';
  private _tabindex: string;
  private _size: string;
  private _onOpen: Function;
  private _onDismiss: Function;
  private _onClose: Function;
  private _indicator$: EventEmitter<any> = new EventEmitter();

  constructor() {
    this._showDefaultButtons = (this._showCloseButton || this._showDismissButton);        
  }

  /**
   * The popup tabindex
   */
  public set tabindex(_tabindex: string) {
    this._tabindex = _tabindex;
  }

  public get tabindex(): string {
    return this._tabindex || null;
  }

  /**
   * The popup data backdrop
   */
  public set backdrop(_backdrop: string | boolean) {
    this._backdrop = _backdrop;
  }

  public get backdrop(): string | boolean {
    return this._backdrop || null;
  }

  /**
   * The popup default buttons in footer
   */
  public get showDefaultButtons(): boolean {
    return this._showDefaultButtons || null;
  }

  /**
   * The popup dismiss button in footer
   */
  public set showDismissButton(_showDismissButton: boolean) {
    if (!this._showDefaultButtons) {
      this._showDefaultButtons = _showDismissButton;
    }
    this._showDismissButton = _showDismissButton;
  }

  public get showDismissButton(): boolean {
    return this._showDismissButton || null;
  }

  /**
   * The showCustomInputHtml
   */
  public set showCustomInputHtml(_showCustomInputHtml: boolean) {
    if (!this._showCustomInputHtml) {
      this._showCustomInputHtml = _showCustomInputHtml;
    }
    this._showCustomInputHtml = _showCustomInputHtml;
  }

  public get showCustomInputHtml(): boolean {
    return this._showCustomInputHtml || null;
  }

  public set labelCustomInputHtml(_labelCustomInputHtml: string) {
    this._labelCustomInputHtml = _labelCustomInputHtml;
  }

  public get labelCustomInputHtml(): string {
    return this._labelCustomInputHtml || null;
  }


  /**
   * The popup dismiss button label in footer
   */
  public set dismissButtonLabel(_dismissButtonLabel: string) {
    this._dismissButtonLabel = _dismissButtonLabel;
  }

  public get dismissButtonLabel(): string {
    return this._dismissButtonLabel || null;
  }
  
  /**
   * The popup close button in footer
   */
  public set showCloseButton(_showCloseButton: boolean) {
    if (!this._showDefaultButtons) {
      this._showDefaultButtons = _showCloseButton;
    }
    this._showCloseButton = _showCloseButton;
  }

  public get showCloseButton(): boolean {
    return this._showCloseButton || null;
  }

  public set inputValueInputHtml(_inputValueInputHtml: string) {
    this._inputValueInputHtml = _inputValueInputHtml;
  }

  public get inputValueInputHtml(): string {
    return this._inputValueInputHtml || null;
  }

  /**
   * The popup close button label in footer
   */
  public set inputTypeHtml(_inputTypeHtml: string) {
    this._inputTypeHtml = _inputTypeHtml;
  }

  public get inputTypeHtml(): string {
    return this._inputTypeHtml || null;
  }
  /**
   * The popup close button label in footer
   */
  public set closeButtonLabel(_closeButtonLabel: string) {
    this._closeButtonLabel = _closeButtonLabel;
  }

  public get closeButtonLabel(): string {
    return this._closeButtonLabel || null;
  }
  
  /**
   * The popup data keyboard
   */
  public set keyboard(_keyboard: boolean) {
    this._keyboard = _keyboard;
  }

  public get keyboard(): boolean {
    return this._keyboard || null;
  }

  /**
   * The popup animation
   */
  public set animation(_animation: boolean) {
    this._animation = _animation;
  }

  public get animation(): boolean {
    return this._animation || null;
  }

  /**
   * The popup size
   */
  public set size(_size: string) {
    this._size = _size;
  }

  public get size(): string {
    return this._size || null;
  }

  /**
   * The popup title
   */
  public set title(_title: string) {
    this._title = _title;
  }

  public get title(): string {
    return this._title || null;
  }

  /**
   * The popup message
   */
  public set message(_message: string) {
    this._message = _message;
  }

  public get message(): string {
    return this._message || null;
  }

  /**
   * Whether to perform the open button
   */
  public set onOpen(_onOpen: Function) {
    this._onOpen = _onOpen;
  }

  public get onOpen(): Function {
    return this._onOpen || Function;
  }

  /**
   * Whether to perform the dismiss button
   */
  public set onDismiss(_onDismiss: Function) {
    this._onDismiss = _onDismiss;
  }

  public get onDismiss(): Function {
    return this._onDismiss || Function;
  }

  /**
   * Whether to perform the close button
   */
  public set onClose(_onClose: Function) {
    this._onClose = _onClose;
  }

  public get onClose(): Function {
    return this._onClose || Function;
  }

  get indicator$() {
    return this._indicator$ || null;
  }

  public emitter(mode: string) {
    if (this.indicator$) {
      this.indicator$.next(mode);
    }
  }
}
