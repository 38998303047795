import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef, HostListener, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MODAL_DIRECTIVES, ModalComponent } from '@app/shared/ng2-bs3-modal';
import { RtnCalendarComponent } from '@app/shared/calendar/calendar.component';
import { Utils } from '@app/shared/utils';
import { SystemData, SystemInterval, SystemService } from '@app/shared/system';
import { ScheduleCheckInComponent } from '@app/master-schedules/modals/schedule-check-in-component/schedule-check-in.component';
import { ScheduleCheckOutComponent } from '@app/master-schedules/modals/schedule-check-out-component/schedule-check-out.component';
import { ScheduleEditComponent } from '@app/master-schedules/modals/schedule-edit-component/schedule-edit.component';
import { ScheduleCancelComponent } from '@app/master-schedules/modals/schedule-cancel-component/schedule-cancel.component';
import { ScheduleCancelCheckInComponent } from '@app/master-schedules/modals/schedule-cancel-check-in-component/schedule-cancel-check-in.component';
import { ScheduleTooltipComponent } from '@app/master-schedules/schedule-tooltip/schedule-tooltip.component';
import { Router } from '@angular/router';
import { RestService } from '@app/core/rest.service';
// import 'bootstrap-datepicker';
declare let jQuery: any;
import * as moment from 'moment';
import { MasterSchedulesService } from '@app/master-schedules/master-schedules.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { SchedulesMassPatientNotificationComponent } from '@app/master-schedules/schedules-mass-patient-notification/schedules-mass-patient-notification.component';

import Echo from 'laravel-echo';
import { environment } from '@env/environment';
import { AlertService } from '@app/shared/alert';
import { LogoutModalsComponent } from '@app/shared/general/logout/modals.component';
import { Module } from '@app/core/module';

import { Select2Provider3Directive } from '@app/shared/form/select2-provider3.directive';

// At the top of the file
(window as any).global = window;
declare global {
    interface Window { io: any; }
    interface Window { Echo: any; }
}
declare var require: any
// declare var Echo: any
window.io = window.io || require('socket.io-client');
window.Echo = window.Echo || {};

const _momentOptions = {
    week: {
        dow: 0,
        doy: 6// Sunday is the first day of the week.
    }
};
moment.updateLocale('en-US', _momentOptions);

@Component({
    moduleId: module.id,
    selector: 'app-schedules',
    templateUrl: './master-schedules.component.html',
    styleUrls: ['./master-schedules.component.scss']
})
export class MasterSchedulesComponent implements OnInit, OnDestroy, AfterViewInit {

    // Parameter
    maxNumberOfEntryToShow = 18;
    minNumberOfEntryToShow = 4; // This should be respected in order for dropdown menu to display properly
    tableCellWidth = 95; // px
    hintNextEntryHeight = 26; // px
    headerHeight = 40; // px
    defaultAreaWidth = 205; // px
    eventHeight = 20; // px
    seperationHeightBetweenEntry = 2; // px
    widthForDropDownMenuToggle = 12;
    widthForDropDownMenu = 155;

    durationMinimumToShow = 15; // min
    timeBeforeCurrentToShow = 60; // min
    timeDefaultFocus = 9; // default time of the day to focus when a date which is not today is selected
    // NOTICE: (timeDefaultFocus - timeBeforeCurrentToShow) = Left most time to see in timeline
    timeStartHour = 0; // hour
    timeEndHour = 24; // hour

    // Computed
    tableCellHeight: number; // decided by entry's height
    numberOfCol = 48;
    numberOfRow = 26;
    colArray: any = new Array();
    rowArray: any = new Array();
    appointmentList: any = new Array();
    innerHeight = 0;

    scrollWidth: number; // px
    numberOfEntryToShow = 10;
    numberOfCompensationToShow = 0;
    heightCompensationBox = 0;
    timeTotalHour: number;
    lastScrollLeft = 0;
    lastScrollTop = 0;
    maxScrollLeft: number;
    maxScrollTop: number;
    noShowSubcriber: Subscription;
    updateArrivedTimeSubcriber: Subscription;
    fetchAppointmentSubscriber: Subscription;
    fetchAppointmentBySuperProviderSubscriber: Subscription;
    overflowObj:any = { 'overflow-x': 'scroll', 'overflow-y': 'scroll' };

    // Time Slider
    sliderWidth = 40;
    sliderBodyRadius = 8;
    timeIntervalTable: any = [15, 30];
    timeIntervalIndex = 1; // index of the time interval value in the array
    timeInterval = 30; // min

    // Datepicker
    currentDate: any = moment(); // get current date by default
    selectedDate: any;
    selectedDateDisplay: any;
    ngDatePicker: any;
    _weekStart = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)
    @ViewChild('tlDatePicker') calendarDirective: RtnCalendarComponent;

    // Tooltip
    tooltipTrigger: any = null;
    tooltipTriggerObject: any = null;
    _timeOutMouseOverInfo: any;
    intervalToTrigger = 400; // ms
    @ViewChild(ScheduleTooltipComponent) timeLineTooltipDirective: ScheduleTooltipComponent;

    // Touch Support
    tooltipTriggerId = '';
    timeoutDurationBetweenTouch = 500;
    preventTouch = false;
    timeoutTouch: any;

    // Modal
    @ViewChild('inputDateTimlineAppointment') inputDateTimlineAppointment: ElementRef;
    @ViewChild('clickShowDateTimlineAppointment') clickShowDateTimlineAppointment: ElementRef;
    @ViewChild(ScheduleCheckInComponent) appointmentCheckInDirective: ScheduleCheckInComponent;
    @ViewChild(ScheduleCheckOutComponent) appointmentCheckOutDirective: ScheduleCheckOutComponent;
    @ViewChild(ScheduleEditComponent) appointmentEditDirective: ScheduleEditComponent;
    @ViewChild(ScheduleCancelComponent) appointmentCancelDirective: ScheduleCancelComponent;
    @ViewChild(ScheduleCancelCheckInComponent) appointmentCancelCheckInDirective: ScheduleCancelCheckInComponent;
    @ViewChild(SchedulesMassPatientNotificationComponent) notificationMassPatientDirective: SchedulesMassPatientNotificationComponent;
    @ViewChild(LogoutModalsComponent) logoutDirective: LogoutModalsComponent;
    @Input() providerId: string;
    @Input() isHidden = false;
    dropdown: any = '.dropdown-menu-date-timeline';
    listSchedule: any = [];
    isManagingMode: boolean = false;
    module: any;
    numberOfAppt: any;
    tlScrollAll: any = '.tl-scroller-all';
    tlScrollX: any = '.tl-scroller-x';
    tlScrollY: any = '.tl-scroller-y';
    tlTableBody: any = '.tl-table-body';
    tlEntryContent: any = '.tl-entry-content';
    formatOfDate: any = 'MMMM DD, YYYY';
    htmlTooltipArrow: any = '<div class="tooltip-arrow tl-tooltip-arrow">​<svg height="20" width="24">';
    polylinePoint: any = '<polyline points="24,0 12,12 0,0"></polyline>';
    polygonPoint: any = '<polygon points="24,-1 24,-5 0,-5 0,-1" stroke="white" stroke-width="2"/></svg></div>';
    tooptipInner: any = '<div class="tooltip-inner tl-tooltip-inner"></div></div>';
    tlTimelineTable: any = '.tl-timeline-table';
    providersList:any=[];
    fetchProviderListSubscriber: Subscription;
    @ViewChild('Select2Provider3Directive') ProviderSelector: Select2Provider3Directive;
    selectedproviderID:any='';

    constructor(
        private Service: MasterSchedulesService,
        public _systemData: SystemData,
        private _systemService: SystemService,
        private _systemInterval: SystemInterval,
        private _utils: Utils,
        private _elementRef: ElementRef,
        private _loadingIndicatorService: LoadingIndicatorService,
        private _alertService: AlertService,
        public _router: Router,

    ) {
        this.ngDatePicker = new Object();
        this.ngDatePicker['todayHighlight'] = true;
        this.ngDatePicker['weekStart'] = this._weekStart;
        this.ngDatePicker['format'] = 'MM dd, yyyy';
        this.selectedDate = this.currentDate;
    }
    processFetchAppt(){
        if (this._systemData.isOnline) {
            this.fetchAppointmentByDate(this.selectedDate, false);
        }
    }
    configElement() {
        for (let i = 0; i < this.timeIntervalTable.length; i++) {
            if (this.timeInterval === this.timeIntervalTable[i]) {
                this.timeIntervalIndex = i;
            }
        }
        this.tableCellHeight = this.eventHeight * 2 + this.seperationHeightBetweenEntry * 3;
        this.timeTotalHour = this.timeEndHour - this.timeStartHour;
        this.setupView(true);
        const _self = this;

        jQuery(this.clickShowDateTimlineAppointment.nativeElement).click(() => {
            jQuery(_self.dropdown).toggle();
        });
        jQuery(window).click(() => {
            jQuery(_self.dropdown).hide();
        });
        jQuery(this.inputDateTimlineAppointment.nativeElement).click((e: any) => {
            e.stopPropagation(); // prevent clicks on datepicker from collapsing 'parent'
        });
        jQuery(this.inputDateTimlineAppointment.nativeElement).datepicker({
            dateFormat: 'MM dd, yy',
            numberOfMonths: 2,
            showButtonPanel: true,
            onSelect: (selected: any) => {
                jQuery(_self.dropdown).toggle();
                this.setNewDate(selected);
            }
        });
        this.handleDatePickerOnShow(null);
        // View Intialize
        jQuery('#time-slider-body')
            .css({ 'left': this.sliderWidth * this.timeIntervalIndex - this.sliderBodyRadius + 'px' });
        this.fetchAppointmentByDate(this.selectedDate, false);
        // Refetch appointment in Online mode
        this._systemInterval.timeline_fetchAppointment.interval = setInterval(() => {
          this.processFetchAppt();
        }, this._systemInterval.timeline_fetchAppointment.duration);

        // Synchronize Scrollers
        const mainScroller = jQuery(this.tlScrollAll);
        const panelScroller = jQuery(this.tlScrollY);
        const scheduleScroller = jQuery(this.tlScrollX);

        mainScroller.scroll(function () {
            _self.eventTooltipHide(_self.tooltipTriggerObject);
            const mainScrollLeft = mainScroller.scrollLeft();
            if (_self.lastScrollLeft !== mainScrollLeft) {
                _self.lastScrollLeft = mainScrollLeft;
                scheduleScroller.scrollLeft(_self.lastScrollLeft);
            }
            const mainScrollTop = mainScroller.scrollTop();
            if (_self.lastScrollTop !== mainScrollTop) {
                _self.lastScrollTop = mainScrollTop;
                panelScroller.scrollTop(_self.lastScrollTop);
            }
        });

        panelScroller.scroll(function () {
            const panelScrollTop = panelScroller.scrollTop();
            if (_self.lastScrollTop !== panelScrollTop) {
                _self.lastScrollTop = panelScrollTop;
                mainScroller.scrollTop(_self.lastScrollTop);
            }
        });

        scheduleScroller.scroll(function () {
            const scheduleScrollLeft = scheduleScroller.scrollLeft();
            if (_self.lastScrollLeft !== scheduleScrollLeft) {
                _self.lastScrollLeft = scheduleScrollLeft;
                mainScroller.scrollLeft(_self.lastScrollLeft);
            }
        });
        // enable / disable socket
        if (environment.socketEnabled) {
            window.Echo = new Echo({
                broadcaster: 'socket.io',
                host: environment.socketUrl,
            });
            window.Echo.channel(this.getSubDomain() + 'schedule_channel').listen('.schedule.arrived', (e: any) => {
                const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
                this.rowArray.forEach((el: any, index: any) => {
                    if (el.id == e.data.schedule_id) {
                        this.rowArray[index].arrived_time = now;
                        return;
                    }
                });
            });
        }
    }
    ngOnInit() {
        const module_enc = localStorage.getItem('module_enc');
        const token = localStorage.getItem('user_token').substr(0, 32);
        this.module = this._systemService.moduleDecrypt(module_enc, token);
        if (!this.module[Module.MASTER_SCHEDULE]) {
            this._loadingIndicatorService.stop();
            this._router.navigate(['/home', 'page-not-found']);
        } else {
            this.configElement();
        }
        this.ProviderSelector.clearData();
        this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
        this.fetchProviderLists();
    }

    getSubDomain() {
        const fullDomain = window.location.host;
        const path = fullDomain.split('.');
        if (path[1] == 'dev') {
            return path[0] + '.' + path[1];
        } else {
            return path[0];
        }
    }

    setNewDate(date: any) {
        this.selectedDate = moment(date).local();
        this.triggerDateChange(this.selectedDate, true, this.checkDiffFromSelectedDate(this.selectedDate));
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        if (this.fetchAppointmentSubscriber) {
            this.fetchAppointmentSubscriber.unsubscribe();
        }
        if (this.fetchAppointmentBySuperProviderSubscriber) {
            this.fetchAppointmentBySuperProviderSubscriber.unsubscribe();
        }
        this._systemInterval.clearSystemInterval(this._systemInterval.timeline_fetchAppointment);
        this.eventTooltipHide(this.tooltipTriggerObject);
        clearTimeout(this.timeoutTouch);

        if (this.fetchProviderListSubscriber) {
            this.fetchProviderListSubscriber.unsubscribe();
        }
    }

    // Touch is used for Devices: on device window is considered ALL for touch event, not click event
    @HostListener('window:touchstart', ['$event'])
    onTouch(event: any) {
        if (!this._systemService.checkTouchEnabled()) {
            return;
        }
        if (!jQuery(event.target).hasClass('tl-entry-tooltip-trigger')) {
            this.eventTooltipHide(this.tooltipTriggerObject);
        }
        // Avoid touch interaction for the dropdown trigger and the dropdown menu
        if (!jQuery(event.target).closest('.tl-entry-content.toggled .dropdown-menu')
            .length && !jQuery(event.target).hasClass('tl-entry-dropdown-trigger')) {
            jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
        }
    }

    // Click Uses for Desktop, touch event won't matter here
    @HostListener('window:click', ['$event'])
    onClick(event: any) {
        if (this._systemService.checkTouchEnabled()) {
            return;
        }
        // Since we STOP PROPAGTION of the .tl-entry-dropdown-toggle element,
        // it is safe to just select the window element
        // Close all other opened dropdown to prevent overlap of dropdown
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
    }

    @HostListener('window:resize')
    onResize() {
        this.eventTooltipHide(this.tooltipTriggerObject);
        this.setupView(false);
    }

    @HostListener('window:scroll')
    onScroll() {
        this.eventTooltipHide(this.tooltipTriggerObject);
    }

    /* =============== Event Render Method =============== */
    updateArrivedTime(schedule_id: any, arrived_time: any, provider_id: any) {
        if (this.updateArrivedTimeSubcriber) {
            this.updateArrivedTimeSubcriber.unsubscribe();
        }
        this._loadingIndicatorService.start();
        this.updateArrivedTimeSubcriber = this.Service.updateArrivedTime({
            schedule_id: schedule_id,
            arrived_time: arrived_time,
            provider_id: provider_id
        }).subscribe(
            (res: any) => {
                this._loadingIndicatorService.stop();
                if (res.error_code === 'RC000') {
                }
            },
            (error: any) => {
                this._loadingIndicatorService.stop();

            },
            () => {
                this._loadingIndicatorService.stop();
            }
        );
    }
    handleEntry(eventActual :any, eventPlan :any, entry:Entry){
        if (eventActual !== null || eventPlan !== null) {
            this.appointmentList.push(entry);
        }
    }
    getStartCurrentTime(now:any, zero_oclock:any,two_oclock:any){
        if (now >= zero_oclock && now < two_oclock) {
            return this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').subtract(1, 'days').add(2, 'hours').subtract(1, 'seconds'));
        }else{
            return this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours').subtract(1, 'seconds'));
        }
    }
    getEndCurrentTime(now:any, zero_oclock:any,two_oclock:any){
        if (now >= zero_oclock && now < two_oclock) {
            return this._utils.formatTimeForWebServiceCall(this.currentDate.clone().endOf('day').subtract(1, 'days').add(2, 'hours'));
        }else{
            return this._utils.formatTimeForWebServiceCall(this.currentDate.clone().endOf('day').add(2, 'hours'));
        }
    }
    fetchAppointmentByDate(date: any, changeSelectedDate: boolean) {
        // Date format yyyy-MM-dd
        const startTimeRequest = this._utils.formatTimeForWebServiceCall(date.clone().startOf('day'));
        const endTimeRequest = this._utils.formatTimeForWebServiceCall(date.clone().add(1, 'day').startOf('day'));

        // if (this.providerId === undefined) {
        if (this.fetchAppointmentSubscriber) {
            this.fetchAppointmentSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();
        this.fetchAppointmentSubscriber = this.Service.fetchAppointmentByPeriod(startTimeRequest, endTimeRequest, this.selectedproviderID)
            .subscribe(
                (success: any) => {
                    this._loadingIndicatorService.stop();
                    this.listSchedule = success.appointments;
                    this.selectedDate = date;
                    this.appointmentList = [];
                    if (success.appointments !== null) {
                        this.numberOfAppt = success.appointments.length;
                        success.appointments.forEach((item: any) => {
                            // == Entry ==//
                            // == Entry ==//
                            const entry = new Entry();
                            const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
                            const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
                            const two_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
                            let endCurrentTime = null;
                            let startCurrentTime = null;
                            // if (now >= zero_oclock && now < two_oclock) {
                            //     startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').subtract(1, 'days').add(2, 'hours').subtract(1, 'seconds'));
                            //     endCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().endOf('day').subtract(1, 'days').add(2, 'hours'));
                            // } else {
                            //     startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours').subtract(1, 'seconds'));
                            //     endCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().endOf('day').add(2, 'hours'));
                            // }
                            startCurrentTime = this.getStartCurrentTime(now, zero_oclock, two_oclock);
                            endCurrentTime= this.getEndCurrentTime(now, zero_oclock, two_oclock);

                            // if (item.patient.arrived_time != null && item.actual.actual_check_in != null) {
                            //     entry.isDisabled = true;
                            // } else if (item.planned.plan_check_in < startCurrentTime
                            //     || item.planned.plan_check_in > endCurrentTime) {
                            //     entry.isDisabled = true;
                            // } else {
                            //     entry.isDisabled = null;
                            // }
                            entry.isDisabled = this.checkIsDisable(item.patient.arrived_time, item.actual.actual_check_in,
                                item.planned.plan_check_in, startCurrentTime, endCurrentTime);
                            entry.id = parseInt(item.id, 10);
                            entry.patientName = this._utils
                                .generateFullName(item.patient.first_name,
                                    item.patient.middle_name,
                                    item.patient.last_name);
                            entry.patientDob = moment(item.patient.dob, 'YYYY-MM-DD').format(this.formatOfDate);
                            entry.patientId = item.patient.id;
                            entry.status = entry.status_table[item.status];
                            entry.current_time = success.current_time;
                            entry.arrived_time = item.patient.arrived_time;
                            entry.providerName = item.provider.provider_name;
                            entry.providerId = item.provider.provider_id;

                            entry.appointment_event_name = item.appointment_event_name;
                            entry.appointment_is_allowed = item.appointment_is_allowed;
                            entry.appointmentid_encode   = item.appointmentid_encode;
                            entry.appointment_reason = item.appointment_reason;  
                            entry.cancel_status_text = item.cancel_status_text;
                            if (item.cancel_status_text != ''){
                                entry.isDisabled = true;
                            }
                            // == Event == //
                            // Notice this step,
                            // add progress_status to entry then pass reference of entry objet to event.data
                            entry.progress_status = entry.progress_status_table[0]; // STATUS: Not Started Yet
                            // Render Event Plan
                            const eventPlan = this.renderEventObject(item, entry, 1);
                            if (eventPlan !== null) {
                                entry.hasPlanEvent = true;
                                entry.events.push(eventPlan);
                            }
                            // Render Entry Actual
                            const eventActual = this.renderEventObject(item, entry, 2);
                            if (eventActual !== null) {
                                entry.events.push(eventActual);
                            }
                            // Determine if Entry SHOULD BE displayed or NOT
                            // if (eventActual !== null || eventPlan !== null) {
                            //     this.appointmentList.push(entry);
                            // }
                            this.handleEntry(eventActual, eventPlan, entry);
                        });
                    }

                    this.rowArray = this.appointmentList;
                    // this.calendarDirective.updateDate(new Date(this.selectedDate.get('year'),
                    // this.selectedDate.get('month'), this.selectedDate.get('date')));
                    jQuery('.tl-date-picker-container').removeClass('open');
                },
                (error: any) => {
                    this.rowArray = [];
                },
                () => {
                    this.eventTooltipHide(this.tooltipTriggerObject);
                    this.setupView(changeSelectedDate);
                }
            );
        // } else {
        //     if (this.fetchAppointmentBySuperProviderSubscriber) {
        //         this.fetchAppointmentBySuperProviderSubscriber.unsubscribe();
        //     }
        //     this.fetchAppointmentBySuperProviderSubscriber = this.Service
        //         .fetchAppointmentByPeriodBySuperProvider(
        //             startTimeRequest,
        //             endTimeRequest)
        //         .subscribe(
        //             (success: any) => {
        //                 this.selectedDate = date;
        //                 this.appointmentList = [];
        //                 if (success.appointments !== null) {
        //                     success.appointments.forEach((item: any) => {
        //                         // == Entry ==//
        //                         const entry = new Entry();
        //                         entry.id = parseInt(item.id, 10);
        //                         entry.patientName = this._utils
        //                             .generateFullName(
        //                                 item.patient.first_name,
        //                                 item.patient.middle_name,
        //                                 item.patient.last_name
        //                             );
        //                         entry.patientDob = moment(item.patient.dob, 'YYYY-MM-DD').format(this.formatOfDate);
        //                         entry.patientId = item.patient.id;
        //                         entry.status = entry.status_table[item.status];
        //                         entry.current_time = success.current_time;
        //                         entry.providerName = item.provider.provider_name;
        //                         entry.providerId = item.provider.provider_id;
        //                         // == Event == //
        //                         // Notice this step,
        //                         // add progress_status to entry then pass reference of entry objet to event.data
        //                         entry.progress_status = entry.progress_status_table[0]; // STATUS: Not Started Yet
        //                         // Render Event Plan
        //                         const eventPlan = this.renderEventObject(item, entry, 1);
        //                         if (eventPlan !== null) {
        //                             entry.hasPlanEvent = true;
        //                             entry.events.push(eventPlan);
        //                         }
        //                         // Render Entry Actual
        //                         const eventActual = this.renderEventObject(item, entry, 2);
        //                         if (eventActual !== null) {
        //                             entry.events.push(eventActual);
        //                         }
        //                         // Determine if Entry SHOULD BE displayed or NOT
        //                         if (eventActual !== null || eventPlan !== null) {
        //                             this.appointmentList.push(entry);
        //                         }
        //                     });
        //                 }
        //                 this.rowArray = this.appointmentList;
        //                 // this.calendarDirective.updateDate(new Date(this.selectedDate.get('year'),
        //                 // this.selectedDate.get('month'), this.selectedDate.get('date')));
        //                 jQuery('.tl-date-picker-container').removeClass('open');
        //             },
        //             (error: any) => {
        //                 this.rowArray = [];
        //             },
        //             () => {
        //                 this.eventTooltipHide(this.tooltipTriggerObject);
        //                 this.setupView(changeSelectedDate);
        //             }
        //         );
        // }
    }
    checkIsDisable(arrived_time: any, actual_check_in: any, plan_check_in: any, startCurrentTime: any, endCurrentTime: any) {
        if (arrived_time != null && actual_check_in != null) {
            return true;
        } else if (plan_check_in < startCurrentTime || plan_check_in > endCurrentTime) {
            return true;
        } else {
            return null;
        }
    }
    findIndexToUpdate(newItem: any) {
        return newItem.id === this;
    }

    // Render Event type based on start and end time
    // mode = 1 for PLAN
    // mode = 2 for LATE, DONE, ON
    // DO NOT CHANGE THESE VALUES, they are used for calculating "top" of object
    renderEventObject(entryObj: any, data: any, mode: number) {
        const event = new Event();
        event.mode = mode;
        if (mode === 1) {
            // PLAN
            event.start = this._utils.parseTimeFromWebServiceCall(entryObj.planned.plan_check_in);
            event.end = this._utils.parseTimeFromWebServiceCall(entryObj.planned.plan_check_out);
            event.type = data.status === data.status_table[4] ? 4 : 0;
            // As long as appointment started, event option is disabled
            if (entryObj.actual.actual_check_in !== null) {
                event.isDisabled = true;
            }
        } else {
            if (entryObj.actual.actual_check_in === null) {
                // NO EVENT
                return null;
            } else {
                event.start = this._utils.parseTimeFromWebServiceCall(entryObj.actual.actual_check_in);
                event.end = this._utils.parseTimeFromWebServiceCall(data.current_time);
                if (entryObj.actual.actual_check_out === null) {
                    data.progress_status = data.progress_status_table[5]; // STATUS: IN PROGRESS
                    if (this._utils
                        .calculateDuration(this._utils.parseTimeFromWebServiceCall(entryObj.planned.plan_check_in),
                            event.start) > 0) {
                        // LATE
                        event.type = 2;
                    } else {
                        // ON
                        event.type = 1;
                    }
                } else {
                    data.progress_status = data.progress_status_table[6]; // STATUS: FINISHED
                    // DONE
                    event.type = 3;
                    event.end = this._utils.parseTimeFromWebServiceCall(entryObj.actual.actual_check_out);
                    //event.isDisabled = true;
                }
            }
        }
        event.type = (entryObj.cancel_status_text == '') ? event.type : 5;
        /* === Process Ilegal Cases Before Rendering === */
        // 1. Check if (END < START)
        if (event.end.isBefore(event.start)) {
            if (event.type !== null) {
                event.hasError = true;
                if (event.type === 0 || event.type === 3) {
                    // PLAN & DONE
                    event.error_code = '001';
                    // ===> SWAP end <-> start
                    event.error_start = event.start;
                    event.error_end = event.end;
                    event.start = event.error_end;
                    event.end = event.error_start;
                } else if (event.type === 1 || event.type === 2) {
                    // ON & LATE
                    event.error_code = '002';
                    // ===> end = start
                    event.end = event.start;
                    event.error_start = event.start;
                    event.error_end = event.end;
                }
            }
        }
        // ===> After this code, we make sure 100% that start < end
        // ===> We can proceed to do the following check to see if an appointment is OUT OF SCOPE
        // 2. Check if (START > END of SELECTED DATE) for ANY appointment
        if (event.start.isAfter(this.selectedDate.clone().endOf('day'))) {
            return null;
        }
        // 3. Check if (END > START of SELECTED DATE) for ANY appointment
        if (event.end.isBefore(this.selectedDate.clone().startOf('day'))) {
            return null;
        }
        /* === Render Event === */
        if (event.hasError) {
            if (event.error_code === '001' || event.error_code === '002') {
                event.error_message = event.error_table[event.error_code];
                event.duration = Math.floor(this._utils.calculateDuration(event.error_start, event.error_end));
                event.startTimeFormatted24h = this._utils
                    .formatTime24h(event.error_start.get('hour') * 60 + event.error_start.get('minute'));
                event.labelStartTimeForDisplay = 'ERROR';
                event.endTimeFormatted24h = this._utils
                    .formatTime24h(event.error_end.get('hour') * 60 + event.error_end.get('minute'));
                event.labelEndTimeForDisplay = 'ERROR';
                event.date = event.error_start.format(this.formatOfDate);
                event.isEndBeforeStart = true;
            }
        } else {
            event.duration = Math.floor(this._utils.calculateDuration(event.start, event.end));
            event.startTimeFormatted24h
                = this._utils.formatTime24h(event.start.get('hour') * 60 + event.start.get('minute'));
            event.labelStartTimeForDisplay = event.startTimeFormatted24h;
            event.endTimeFormatted24h = this._utils.formatTime24h(event.end.get('hour') * 60 + event.end.get('minute'));
            event.labelStartTimeForDisplay = event.startTimeFormatted24h;
            event.date = event.start.format(this.formatOfDate);
        }

        /* === Check Current Date === */
        if (!this.selectedDate.isSame(this.currentDate, 'day')) {
            event.isCurrentDate = false;
        }
        event.data = data;
        this.renderEventStyle(event, event.data);
        return event;
    }

    // Render Event style based on start and end time
    renderEventStyle(eventObj: any, data: any) {
        // Crop Left Criterion: START of SELECTED DATE
        const startOfSelectedDate = this.selectedDate.clone().startOf('day');
        // Crop Right Criterion: END of SELECTED DATE - 15 min
        // (the min duration of an appoint -> used for rendering min-width)
        const endOfSelectedDate = this.selectedDate.clone().endOf('day').subtract(this.durationMinimumToShow, 'minute');
        let startCrop = eventObj.start.clone();
        // Check START appointment BEFORE SELECTED date START
        if (eventObj.start.isBefore(startOfSelectedDate)) {
            startCrop = startOfSelectedDate;
        }
        // Check START appointment BEFORE SELECTED date END - MIN DURATION
        if (eventObj.start.isAfter(endOfSelectedDate)) {
            startCrop = endOfSelectedDate;
        }
        eventObj.left = this.momentDurationToPixel(this.momentTimeToPixel(startCrop));
        eventObj.width = this.momentDurationToPixel(this._utils.calculateDurationIgnoringDST(startCrop, eventObj.end));
        eventObj.top = this.seperationHeightBetweenEntry * eventObj.mode;
        // Check if the plan event exist, otherwise, the actual event need to move down more
        if ([0, 4].indexOf(eventObj.type) === -1 && !data.hasPlanEvent) {
            eventObj.top = this.eventHeight + this.seperationHeightBetweenEntry * eventObj.mode;
        }
    }

    momentTimeToPixel(momentObj: any) {
        return momentObj.get('hour') * 60 + momentObj.get('minute') - this.timeStartHour * 60;
    }

    momentDurationToPixel(time: number) {
        return (time / this.timeInterval) * this.tableCellWidth;
    }

    calculateMinWidth(event: any) {
        return (this.durationMinimumToShow / this.timeInterval) * this.tableCellWidth;
    }

    calculateMaxWidth(event: any) {
        return this.tableCellWidth * this.numberOfCol - event.left;
    }

    /* =============== Tooltip Render Method =============== */

    eventTooltipShow(event: any, obj: Event, indexEntry: number, indexEvent: number) {
        const _self = this;
        this.tooltipTrigger = jQuery(event.target);
        this.tooltipTriggerObject = obj;
        this.timeLineTooltipDirective.updateEvent(obj);
        let tooltipContent: string;
        setTimeout(() => {
            tooltipContent = this.timeLineTooltipDirective.getHtml();
        }, 0);
        let tooltipTemplate: string;
        switch (obj.type) {
            case 0:
                tooltipTemplate = '<div class="tooltip tl-tooltip plan" style="z-index: 2; opacity: 100">' +
                    this.htmlTooltipArrow + this.polylinePoint + this.polygonPoint + this.tooptipInner;
                break;
            case 1:
                tooltipTemplate = '<div class="tooltip tl-tooltip on" style="z-index: 2; opacity: 100">' +
                    this.htmlTooltipArrow + this.polylinePoint + this.polygonPoint + this.tooptipInner;
                break;
            case 2:
                tooltipTemplate = '<div class="tooltip tl-tooltip late" style="z-index: 2; opacity: 100">' +
                    this.htmlTooltipArrow + this.polylinePoint + this.polygonPoint + this.tooptipInner;
                break;
            case 3:
                tooltipTemplate = '<div class="tooltip tl-tooltip done" style="z-index: 2; opacity: 100">' +
                    this.htmlTooltipArrow + this.polylinePoint + this.polygonPoint + this.tooptipInner;
                break;
            default:
                tooltipTemplate = '<div class="tooltip tl-tooltip done" style="z-index: 2; opacity: 100">' +
                    this.htmlTooltipArrow + this.polylinePoint + this.polygonPoint + this.tooptipInner;
                break;
        }
        this._timeOutMouseOverInfo = setTimeout(() => {
            let options: any = {
                animation: false,
                html: true,
                container: 'body', // append the tooltip to this
                trigger: 'manual',
                title: tooltipContent,
                template: tooltipTemplate,
                viewport: 'body' // keeps the tooltip within this box --> need to keep the table high enough
            };
            // Show and set display:none for the tooltip in order to measure its dimensions
            _self.tooltipTrigger.tooltip(options);
            const tooltipDataBody = _self.tooltipTrigger.data('bs.tooltip').getTipElement();
            _self.tooltipTrigger.tooltip('show');
            const actualWidthTooltip = tooltipDataBody.offsetWidth;
            const actualHeightTooltip = tooltipDataBody.offsetHeight;
            jQuery(tooltipDataBody).css({ 'display': 'none' });
            _self.tooltipTrigger.tooltip('dispose');
            jQuery('.tooltip').remove();
            // Reset the tooltip
            options = {
                animation: true,
                html: true,
                container: 'body', // append the tooltip to this
                trigger: 'manual',
                title: tooltipContent,
                template: tooltipTemplate,
                viewport: 'body', // keeps the tooltip within this box --> need to keep the table high enough
                placement: function () {
                    let placement = 'bottom';
                    const viewPortWidth = window.innerWidth;
                    const viewPortHeight = window.innerHeight;
                    const offSetMainTableLeft = jQuery(_self.tlTimelineTable).offset().left;
                    const offSetMainTableTop = jQuery(_self.tlTimelineTable).offset().top;
                    let objRealWidth: number;
                    objRealWidth
                        = Math.max(
                            obj.width,
                            (_self.durationMinimumToShow / _self.timeInterval * _self.tableCellWidth)
                        );
                    objRealWidth = Math.min(objRealWidth, (_self.tableCellWidth * _self.numberOfCol - obj.left));
                    const widthContentArea = jQuery('tbody.tl-table-body td.tl-right').width();
                    const heightTableRow = _self.eventHeight * 2 + _self.seperationHeightBetweenEntry * 3;

                    /* === Determine Left/Right ===*/
                    // Determine if events are fully shown OR cropped
                    let cropLeft = false;
                    let cropRight = false;
                    // if (_self.lastScrollLeft > obj.left) {
                    //     cropLeft = true;
                    // }
                    cropLeft = _self.checkIfLastScrollLeftMoreThanLeft(_self.lastScrollLeft, obj.left);
                   
                    // if ((obj.left + objRealWidth
                    //     - _self.lastScrollLeft
                    //     - _self.widthForDropDownMenuToggle) > widthContentArea) {
                    //     cropRight = true;
                    // }
                    const tempWidth = obj.left + objRealWidth - _self.lastScrollLeft - _self.widthForDropDownMenuToggle;
                    cropRight = _self.checkCropRight(tempWidth,widthContentArea);
                    // Determine if tooltip can be shown on sides
                    if (cropLeft && cropRight) {
                        _self.tooltipTrigger.css({
                            'left': _self.lastScrollLeft - obj.left + 'px',
                            'width': widthContentArea + 'px'
                        });
                    } else if (cropLeft && !cropRight) {
                        // Check if there is enough room to display tooltip on the right
                        const rightEdge = obj.left + objRealWidth - _self.lastScrollLeft
                            + offSetMainTableLeft + _self.defaultAreaWidth;
                        if (_self.checkIfEnoughToDisplayTooltipRight(rightEdge , actualWidthTooltip, viewPortWidth)) {
                            placement = 'right';

                        } else {
                            // Still place at the bottom/top
                            _self.tooltipTrigger.css({
                                'left': _self.lastScrollLeft - obj.left + 'px',
                                'width': ((objRealWidth + obj.left
                                    - _self.widthForDropDownMenuToggle)
                                    - _self.lastScrollLeft) + 'px'
                            });
                        }
                    } else if (!cropLeft && cropRight) {
                        // Check if there is enough room to display tooltip on the left
                        const leftEdge = obj.left - _self.lastScrollLeft + offSetMainTableLeft + _self.defaultAreaWidth;
                        if (_self.checkIfLeftEdgeMoreThanActualWidthTooltip(leftEdge , actualWidthTooltip)) {
                            placement = 'left';
                        } else {
                            _self.tooltipTrigger.css({
                                'left': 0 + 'px',
                                'width': widthContentArea - (obj.left - _self.lastScrollLeft) + 'px'
                            });
                        }
                    }

                    /* === Determine Top/Bottom ===*/
                    // Notice that we must subtract the scrollTop of the window
                    // Also notice the 1px added while multiplying with eventHeight,
                    // this accounts for the border between each row!
                    const bottomEdge = indexEntry * (heightTableRow + 1) + _self.headerHeight + (offSetMainTableTop
                        - jQuery(window).scrollTop()) + (indexEvent + 1) * _self.eventHeight
                        + (indexEvent + 2) * _self.seperationHeightBetweenEntry - _self.lastScrollTop;
                    if (_self.checkPlacement(placement,bottomEdge,actualHeightTooltip,viewPortHeight)) {
                        placement = 'top';
                    }
                    return placement;
                }
            };
            _self.tooltipTrigger.tooltip(options);
            _self.tooltipTrigger.tooltip('show');
        }, this.intervalToTrigger);
    }
    checkPlacement(placement:any,bottomEdge:any, actualHeightTooltip:any,viewPortHeight:any){
        if (placement === 'bottom' && ((bottomEdge + actualHeightTooltip) > viewPortHeight)) {
            return true;
        }else{
            return false;
        }
    }
    checkIfLastScrollLeftMoreThanLeft(lastScrollLeft:any, left:any){
        if (lastScrollLeft > left) {
            return true;
        }
        return false;
    }
    checkCropRight(tempWidth:any, widthContentArea:any){
        if (tempWidth > widthContentArea) {
            return true;
        }
        return false;
    }
    checkIfLeftEdgeMoreThanActualWidthTooltip(leftEdge :any,actualWidthTooltip:any){
        if((leftEdge - actualWidthTooltip) >= 0){
            return true;
        }
        return false;
    }
    checkIfEnoughToDisplayTooltipRight(rightEdge :any,actualWidthTooltip:any, viewPortWidth:any ){
        if ((rightEdge + actualWidthTooltip) <= viewPortWidth) {
            return true;
        }
        return false;
    }
    eventTooltipHide(obj: Event) {
        if (this.tooltipTrigger !== null) {
            this.tooltipTrigger.tooltip('dispose');
            this._systemService.removePopoverFromDOM(); // Please check this method in future updates
            this.tooltipTrigger.css({ 'left': 0 + 'px', 'width': obj.width - this.widthForDropDownMenuToggle + 'px' });
            this.tooltipTrigger = null;
            this.tooltipTriggerObject = null;
            clearTimeout(this._timeOutMouseOverInfo);
        }
    }

    eventEntryMouseOver(event: any, obj: Event, indexEntry: number, indexEvent: number) {
        if (this._systemService.checkTouchEnabled()) {
            return;
        }
        this.eventTooltipShow(event, obj, indexEntry, indexEvent);
    }

    eventEntryMouseOut(obj: Event) {
        // Mouseout can also be used as an indicator for touch, as the click/touch event is considered to be mouseover
        if (this._systemService.checkTouchEnabled()) {
            return;
        }
        this.eventTooltipHide(obj);
    }

    touchHandler(event: any, obj: Event, indexEntry: number, indexEvent: number) {
        if (this.preventTouch || !this._systemService.checkTouchEnabled()) {
            return;
        }
        this.preventTouch = true;
        if (this.tooltipTrigger === null) {
            this.tooltipTriggerId = indexEntry + '_' + indexEvent;
            this.eventTooltipShow(event, obj, indexEntry, indexEvent);
        } else {
            const tempTooltipTriggerId = this.tooltipTriggerId;
            this.eventTooltipHide(this.tooltipTriggerObject);
            if (tempTooltipTriggerId !== indexEntry + '_' + indexEvent) {
                this.tooltipTriggerId = indexEntry + '_' + indexEvent;
                this.eventTooltipShow(event, obj, indexEntry, indexEvent);
            } else {
                this.tooltipTriggerId = '';
            }
        }
        this.timeoutTouch = setTimeout(() => {
            this.preventTouch = false;
        }, this.timeoutDurationBetweenTouch);
    }

    /* =============== Main Table Render Method =============== */

    setupView(enableMoveScrollFocus: boolean) {
        this.numberOfCol = this.timeTotalHour * 60 / this.timeInterval;
        this.createTimeColArray();
        this.setupTable();
        if (enableMoveScrollFocus) {
            // Usually not at init
            // This is when the scroll is applied so we get scroll range
            // = full scroll range of the Scheduler bar - scrollbarWidth (approx. 17px)
            // this.maxScrollLeft
            // = jQuery(".tl-scroller-all .tl-area-scroller").width() - jQuery(".tl-scroller-all").width();
            this.moveFocusToDefaultTime();
        } else {
            // Usually at init
            // Because we enable the y-scrollbar in the main table, we do not need to use MaxScrollLeft
            // This is when the scroll is not applied yet so we get full scroll range of the Scheduler bar
            // (the header area storing the time)
            // this.maxScrollLeft
            // = jQuery(".tl-scroller-all .tl-area-scroller").width() - jQuery(".tl-scroller-all").width();
        }
    }

    setupTable() {
        this.scrollWidth = this.getScrollBarWidth();
        jQuery('.temp-div-for-width-calc').remove();
        this.calculateNumberOfEntryToShow();
        this.setUpDisplayOnInit();
        setTimeout(() => {
            this.calculateCompensationBoxHeight();
        }, 0);
    }

    createTimeColArray() {
        this.colArray = new Array();
        for (let i = 0; i < this.numberOfCol; i++) {
            this.colArray.push(this._utils.formatTime24h(i * this.timeInterval + this.timeStartHour * 60));
        }
    }

    moveFocusToDefaultTime() {
        // Check if the selected date is the current date
        let amountScrollLeft: any;
        if (this.selectedDate.isSame(this.currentDate, 'day')) {
            const now = moment();
            amountScrollLeft = ((now.get('hour') * 60 + (now.get('minute') - (now.get('minute') % this.timeInterval))
                - this.timeStartHour * 60 - this.timeBeforeCurrentToShow) / this.timeInterval) * this.tableCellWidth;
        } else {
            amountScrollLeft = ((this.timeDefaultFocus * 60 - this.timeStartHour * 60
                - this.timeBeforeCurrentToShow) / this.timeInterval) * this.tableCellWidth;
        }
        amountScrollLeft = Math.max(amountScrollLeft, 0);
        // Because we enable the y-scrollbar in the main table, we do not need to use MaxScrollLeft
        // amountScrollLeft = Math.min(amountScrollLeft, this.maxScrollLeft);
        this.lastScrollLeft = amountScrollLeft;
        jQuery(this.tlScrollAll).scrollLeft(amountScrollLeft);
        jQuery(this.tlScrollX).scrollLeft(amountScrollLeft);
    }

    setUpDisplayOnInit() {
        this.innerHeight = window.innerHeight;
        // ================= Height ================= //
        jQuery(this.tlScrollY).css({
            // 'height': (this.tableCellHeight * this.numberOfEntryToShow
            //     + this.hintNextEntryHeight + 114) + 'px'
            'height': 0.9 * window.innerHeight + 'px'
        });
        jQuery(this.tlScrollAll).css({
            // 'height': (this.tableCellHeight * this.numberOfEntryToShow
            //     + this.hintNextEntryHeight + 114) + 'px'
            'height': 0.9 * window.innerHeight + 'px'
        });
        jQuery('.tl-table-header .tl-area-content table').css({ 'height': (this.headerHeight) + 'px' });

        // ================= Width ================= //
        jQuery('.tl-area-scroller.tl-reposition-right').css({
            'width': (this.tableCellWidth * this.numberOfCol
                + this.scrollWidth) + 'px'
        });
        jQuery('.tl-left').css({ 'width': (this.defaultAreaWidth) + 'px' });
        jQuery('.tl-scroller-all .tl-area-scroller').css({ 'width': (this.tableCellWidth * this.numberOfCol) + 'px' });
        // ================= Overflow ================= //
        // jQuery(this.tlScrollX).css({ 'overflow-x': 'scroll', 'overflow-y': 'hidden' });
        this.setOverflow(this.tlScrollX, 'scroll', 'hidden');
        // jQuery(this.tlScrollY).css({ 'overflow-x': 'hidden', 'overflow-y': 'scroll' });
        this.setOverflow(this.tlScrollY, 'hidden', 'scroll');
        // jQuery(this.tlScrollAll).css({ 'overflow-x': 'auto', 'overflow-y': 'scroll' });
        this.setOverflow(this.tlScrollAll, 'auto', 'scroll');

        // ================= Margin ================= //
        jQuery(this.tlScrollX).css({ 'margin': '0 0 ' + '-' + this.scrollWidth + 'px' + '' });
        jQuery(this.tlScrollY).css({ 'margin': '0 ' + '-' + this.scrollWidth + 'px' + ' 0 0' });
        jQuery(this.tlScrollAll).css({ 'margin': '0' });

        // ================= Padding ================= //
        jQuery('.tl-area-scroller.tl-reposition-right').css({ 'padding-right': this.scrollWidth + 'px' });
        jQuery('.tl-area-scroller.tl-reposition-bottom').css({ 'padding-bottom': this.scrollWidth + 'px' });

        // ================= Reposition ================= //
        jQuery('.tl-area-scroller.tl-reposition-right .tl-reposition').css({ 'right': this.scrollWidth + 'px' });
        jQuery('.tl-area-scroller.tl-reposition-bottom .tl-reposition').css({ 'bottom': this.scrollWidth + 'px' });
    }

    setOverflow(id:any, overflowX: any, overflowy: any){
        jQuery(id).css({ 'overflow-x': overflowX, 'overflow-y': overflowy });
    }

    // Creditted to Alexander Gomes: http://www.alexandre-gomes.com/?p=115
    getScrollBarWidth() {
        const inner = document.createElement('p');
        inner.style.width = '100%';
        inner.style.height = '31px';

        const outer = document.createElement('div');
        outer.style.position = 'absolute';
        outer.style.top = '0px';
        outer.style.left = '0px';
        outer.style.visibility = 'hidden';
        outer.style.width = '31px';
        outer.style.height = '150px';
        outer.style.overflow = 'hidden';
        outer.appendChild(inner);
        jQuery(outer).addClass('temp-div-for-width-calc');

        document.body.appendChild(outer);
        const w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        let w2 = inner.offsetWidth;
        if (w1 === w2) {
            w2 = outer.clientWidth;
        }
        return (w1 - w2);
    }

    calculateNumberOfEntryToShow() {
        const heightViewport = window.innerHeight;
        const offSetTopMainTable = jQuery(this._elementRef.nativeElement).offset().top;
        let calculatedNumberToShow = Math.floor((heightViewport - offSetTopMainTable
            - this.headerHeight - this.hintNextEntryHeight) / this.tableCellHeight) - 1;
        // Minus 1 entry to ensure all cases
        calculatedNumberToShow = Math.max(calculatedNumberToShow, this.minNumberOfEntryToShow);
        this.numberOfEntryToShow = Math.min(calculatedNumberToShow, this.maxNumberOfEntryToShow);
    }

    calculateCompensationBoxHeight() {
        if (this.rowArray.length !== 0) {
            this.numberOfCompensationToShow = Math.max(this.numberOfEntryToShow - this.rowArray.length, 0);
        } else {
            this.numberOfCompensationToShow = 0;
        }
        if (this.numberOfCompensationToShow === 0) {
            this.heightCompensationBox = 0;
        } else {
            const temp = jQuery('.table-box').height() - 5;
            this.heightCompensationBox = temp - (this.tableCellHeight * this.numberOfAppt);
            // NOTICE: There is a 1px border for EACH row displayed
            // this.heightCompensationBox = (this.tableCellHeight * this.numberOfCompensationToShow)
            //     - (1 * this.rowArray.length) + this.hintNextEntryHeight - this.scrollWidth;
        }
    }

    /* =============== Time Scale Changer =============== */

    timeSliderClick() {
        this.timeIntervalIndex = (this.timeIntervalIndex + 1) % this.timeIntervalTable.length;
        this.timeInterval = this.timeIntervalTable[this.timeIntervalIndex];
        this.changeTimeScale(this.timeInterval);
        jQuery('#time-slider-body').animate({
            left: this.sliderWidth * this.timeIntervalIndex - this.sliderBodyRadius + 'px'
        }, 200, 'linear');
    }

    changeTimeScale(changeInterval: number) {
        if (!isNaN(changeInterval)) {
            this.timeInterval = changeInterval;
            if (this.timeInterval === 15) {
                jQuery('#time-option-15').addClass('active');
                jQuery('#time-option-30').removeClass('active');
            } else {
                jQuery('#time-option-15').removeClass('active');
                jQuery('#time-option-30').addClass('active');
            }
            this.rowArray.forEach((item: any) => {
                item.events.forEach((event: any) => {
                    this.renderEventStyle(event, event.data);
                });
            });
            this.setupView(false);
            this.moveFocusToFitChangedTimeScale();
        }
    }

    moveFocusToFitChangedTimeScale() {
        let prevTimeScale: number;
        const timeDisplayOffset = this.lastScrollLeft % this.tableCellWidth;
        const prevTimeScaleIndex = (this.timeIntervalTable.length
            + this.timeIntervalIndex - 1) % this.timeIntervalTable.length;
        prevTimeScale = this.timeIntervalTable[prevTimeScaleIndex];
        // This is to make sure the left most edge is fixed while changing time scale
        const time = this.lastScrollLeft / this.tableCellWidth * prevTimeScale;
        const amountScrollLeft = (time - time % this.timeInterval) / this.timeInterval * this.tableCellWidth
            + timeDisplayOffset;
        jQuery(this.tlScrollAll).scrollLeft(amountScrollLeft);
        jQuery(this.tlScrollX).scrollLeft(amountScrollLeft);
    }

    /* =============== Date Picker Render Method =============== */

    refetchCurrentDate() {
        const updatedCurrentDate = moment();
        if (!updatedCurrentDate.isSame(this.currentDate, 'day')) {
            this.currentDate = updatedCurrentDate;
        }
    }

    checkDiffFromSelectedDate(date: any) {
        return !date.isSame(this.selectedDate, 'day');

    }

    handleDatePickerDayClick(e: any) {
        const date = moment(e.event.date).local();
        this.triggerDateChange(date, true, this.checkDiffFromSelectedDate(date));
    }

    handleDatePickerOnShow(e: any) {
        // Set today to active
        // this.calendarDirective.updateDate(new Date(this.currentDate.get('year'),
        // this.currentDate.get('month'), this.currentDate.get('date')));

        // Today button listener
        jQuery('tfoot th.today').on('click', () => {
            // Check current date to account for time change while the tab is still being opened
            this.refetchCurrentDate();
            this.triggerDateChange(this.currentDate, false, this.checkDiffFromSelectedDate(this.currentDate));
            // Current date is already checked here so this is set tp false to avoid
            // an odd occasion when the function is executed in midst of day change
        });
    }

    nextDateOnClick() {
        // this.triggerDateChange(this.selectedDate.clone().add(1, 'day'), true);
        this.triggerDateChange(this.selectedDate.add(1, 'day'), true, true);
    }

    prevDateOnClick() {
        // this.triggerDateChange(this.selectedDate.clone().subtract(1, 'day'), true);
        this.triggerDateChange(this.selectedDate.subtract(1, 'day'), true, true);
    }

    triggerDateChange(_selectedDate: any, isCheckCurrentDateEnabled: boolean, changeDate: boolean) {
        // Check current date to account for time change while the tab is still being opened
        if (isCheckCurrentDateEnabled) {
            this.refetchCurrentDate();
        }
        this.fetchAppointmentByDate(_selectedDate, changeDate);
    }

    /* =============== Dropdown Render Method =============== */

    // Change the position of the dropdown box based on the position of the current entry
    toggleDropdown(e: any, event: Event, indexEntry: number, indexEvent: number) {
        e.stopPropagation();
        const toggle = e.target.parentElement; // get the toggle box
        const dropdownMenu = e.target.nextElementSibling;
        const offSetMainTableLeft = jQuery(this.tlTimelineTable).offset().left;
        let eventWidth = event.width;
        const heightTableRow = this.eventHeight * 2 + this.seperationHeightBetweenEntry * 3;
        const tableHeight = jQuery(this.tlScrollAll).height();
        const maxDropdownMenuHeight = 100; // Over-estimation of dropdown menu
        // Notice the 1px added while multiplying with eventHeight, this accounts for the border between each row!
        const bottomEdge = indexEntry * (heightTableRow + 1) + (indexEvent + 1) * this.eventHeight
            + (indexEvent + 2) * this.seperationHeightBetweenEntry - this.lastScrollTop;

        // Set bottom/top position
        if (bottomEdge + maxDropdownMenuHeight > tableHeight) {
            // ALIGN_TOP
            jQuery(dropdownMenu).css({ 'top': -(1 + jQuery(dropdownMenu).height()) + 'px' });
        }

        // Set true width for appointments which are too short
        if (eventWidth < (this.durationMinimumToShow / this.timeInterval * this.tableCellWidth)) {
            eventWidth = (this.durationMinimumToShow / this.timeInterval * this.tableCellWidth);
        }
        if (jQuery(toggle).hasClass('toggled')) {
            jQuery(toggle).removeClass('toggled');
            // RESET TO DEFAULT
            jQuery(dropdownMenu).css({ 'right': '0', 'left': 'initial' });
            jQuery(dropdownMenu).css({ 'top': 'initial' });
        } else {
            // Set right/left position
            if (event.left + eventWidth < this.widthForDropDownMenu) {
                // ALIGN_LEFT
                jQuery(dropdownMenu).css({ 'left': '0', 'right': 'initial' });
                if (event.left < this.lastScrollLeft) {
                    this.lastScrollLeft = event.left;
                    jQuery(this.tlScrollAll).scrollLeft(this.lastScrollLeft);
                    jQuery(this.tlScrollX).scrollLeft(this.lastScrollLeft);
                }
            } else {
                // ALIGN_RIGHT
                jQuery(dropdownMenu).css({ 'left': 'initial', 'right': '0' });
                const offsetDropdownMenu = event.left + eventWidth - this.lastScrollLeft - this.widthForDropDownMenu;
                if (offsetDropdownMenu < 0) {
                    this.lastScrollLeft = this.lastScrollLeft + offsetDropdownMenu;
                    jQuery(this.tlScrollAll).scrollLeft(this.lastScrollLeft);
                    jQuery(this.tlScrollX).scrollLeft(this.lastScrollLeft);
                }
            }
            // Close all other opened dropdown to prevent overlap of dropdown
            jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
            jQuery(toggle).addClass('toggled');
        }
    }

    refetchAppointment() {
        this.fetchAppointmentByDate(this.selectedDate, false);
    }

    checkInAppointment(e: any, event: Event, id: any) {
        this.appointmentCheckInDirective.eventEntry = event;
        this.appointmentCheckInDirective.listSchedule = this.listSchedule;
        this.appointmentCheckInDirective.open();
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
    }


    checkOutAppointment(e: any, event: Event, id: any) {
        this.appointmentCheckOutDirective.eventEntry = event;
        this.appointmentCheckOutDirective.open();
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
    }

    cancelAppointment(e: any, event: Event, id: any) {
        this.appointmentCancelDirective.eventEntry = event;
        this.appointmentCancelDirective.open();
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
    }

    noShowAppointment(e: any, event: Event) {
        this.appointmentCancelDirective.eventEntry = event;
        this.appointmentCancelDirective.openNoShow();
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');

    }

    editAppointment(e: any, event: Event, id: any) {
        this.appointmentEditDirective.eventEntry = event;
        this.appointmentEditDirective.open();
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
    }

    cancelCheckInAppointment(e: any, event: Event, providerId: any) {
        this.appointmentCancelCheckInDirective.eventEntry = event;
        this.appointmentCancelCheckInDirective.open();
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
    }
    notificationMassPatient(e: any, event: Event) {
        this.notificationMassPatientDirective.eventEntry = event;
        this.notificationMassPatientDirective.open();
        jQuery(this.tlTableBody).find(this.tlEntryContent).removeClass('toggled');
    }
    logoutManaging() {
        this._alertService.onClose = () => {
            const storeData = localStorage.getItem('storeData');
            if (this._utils.isProvider() && storeData) {
                const extractData = JSON.parse(localStorage.getItem('storeData'));
                localStorage.setItem('user_token', extractData.user_token);
                localStorage.setItem('last_active_token', extractData.last_active_token);
                localStorage.setItem('user_id', extractData.user_id);
                localStorage.setItem('user_role_id', extractData.user_role_id);
                localStorage.setItem('account_name', extractData.account_name);
                localStorage.setItem('user_status', extractData.user_status);
                localStorage.setItem('user_practice', extractData.user_practice);
                localStorage.setItem('loginEmail', extractData.loginEmail);
                localStorage.setItem('userFullName', extractData.userFullName);
                localStorage.removeItem('storeData');
                if (parseInt(extractData.user_role_id) === 5) {
                    this._router.navigate(['/provider', 'practice']).
                        then(() => { window.location.reload(); }
                        );
                } else if (parseInt(extractData.user_role_id) === 6) {
                    this._router.navigate(['/provider', 'manage']).
                        then(() => { window.location.reload(); }
                        );
                }
            };
        }
        this._alertService.showDismissButton = true;
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Yes';
        this._alertService.backdrop = 'false';
        this._alertService.title = 'Are you sure you want to log out?';
        this._alertService.message = '';
        this._alertService.emitter('open');
    }
    fetchProviderLists(){
        // get provider list
        this._loadingIndicatorService.start();
        this.fetchProviderListSubscriber = this.Service.getListProvider().subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.providersList = success.providers;                
            }, (error:any) => {
                this._loadingIndicatorService.stop();
            }
        );
    }
    onChangeProvider(){        
        this.selectedproviderID = this.ProviderSelector.getData();
        this.fetchAppointmentByDate(this.selectedDate, false);

        console.log(this.selectedproviderID);
    }
}

export class Entry {
    id: number;
    progress_status: string;
    progress_status_table: any = {
        0: 'Not Started Yet',
        5: 'In Progress',
        6: 'Finished'
    };
    status: string;
    status_table: any = {
        0: 'New',
        1: 'Rescheduled',
        2: 'Modified',
        3: 'Cancelled',
        4: 'No-show',
    };
    patientName: string;
    patientDob: any;
    patientId: number;
    events: any = new Array();
    current_time: any;
    arrived_time: any;
    providerName: any;
    providerId: any;
    // conditional
    hasPlanEvent = false;
    hasActualEvent = false;
    isDisabled = false;
    appointment_event_name:string = '';
    appointment_is_allowed:number = 1;
    appointmentid_encode:string = '';
    appointment_reason : string = '';
    cancel_status_text : string = '';
}

export class Event {
    mode: number;
    type: number; // 0: plan, 1: on, 2: late, 3: done
    start: any;
    end: any;
    duration: any;
    

    // info
    data: any = new Object();
    startTimeFormatted24h: string;
    endTimeFormatted24h: string;
    labelStartTimeForDisplay: string;
    labelEndTimeForDisplay: string;
    date: any = new Object();

    // style
    top: number;
    left: number;
    width: number;
    isDisabled: any = null;

    // conditional render
    isCurrentDate = true;
    isEndBeforeStart = false;

    // error
    hasError = false;
    error_code: string;
    error_table: any = {
        '001': 'End time before start time',
        '002': 'Check-in time after current time'
    };
    error_start: any;
    error_end: any;
    error_message: string;
    ntype: string;
    appointmentid_encode:string = '';
}
