import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QuickDownloadComponent } from './quick-download.component'

const routes: Routes = [
  {
    path: '',
    component: QuickDownloadComponent,
    children: [
      {
        path: 'qdownload/:id', ///:schedule_id/:code
        component: QuickDownloadComponent          
      }
    ],
}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuickDownloadRoutingModule { }
