import {Component, Input, SimpleChange, OnChanges} from '@angular/core';
import {RtnDataTablePagerComponent} from './pager';
import {Router} from '@angular/router';

@Component({
  selector: 'rtn-table-pager-bootstrap',
  template: `
    <rtn-table-pager-default [class.col-12]="this._router.url.split('/')[2] == 'provider-detail'" #p>
      <ul class="pagination pagination-bootstrap4" [class.col-4]="this._router.url.split('/')[2] == 'provider-detail'"
          [class.offset-5]="this._router.url.split('/')[2] == 'provider-detail'"
          *ngIf="p.dataTable.numberOfPages * p.dataTable.rowsOnPage > p.dataTable.rowsOnPage"
          [ngClass]="paginationClass">
        <li *ngIf="enableExtreme && !(hiddenDisabledButton && p.dataTable.currentPage <= 1)"
            class="pagination-left-most"
            [class.disabled]="p.dataTable.currentPage <= 1"
            (click)="(p.dataTable.currentPage > 1) && p.setPage(1)">
          <a class="cursorPointer item-angle-left-style"><i class="fa fa-angle-double-left"></i></a>
        </li>
        <li class="pagination-left"
            *ngIf="!(hiddenDisabledButton && p.dataTable.currentPage <= 1)"
            [class.disabled]="p.dataTable.currentPage <= 1"
            (click)="(p.dataTable.currentPage > 1) && p.setPage(p.dataTable.currentPage - 1)">
          <a class="cursorPointer item-angle-left-style"><i class="fa fa-angle-left"></i></a>
        </li>
        <li *ngIf="p.dataTable.currentPage > 2" (click)="p.setPage(1)">
          <a class="cursorPointer item-pager-style">1</a>
        </li>
        <li *ngIf="p.dataTable.currentPage > 3" class="pagination-ellipsis">
          <a class="cursorPointer item-ellipsis-style">...</a>
        </li>
        <li *ngIf="p.dataTable.currentPage > 1" (click)="p.setPage(p.dataTable.currentPage - 1)">
          <a class="cursorPointer item-pager-style">{{p.dataTable.currentPage - 1}}</a>
        </li>
        <li class="active">
          <a class="cursorPointer item-pager-style">{{p.dataTable.currentPage}}</a>
        </li>
        <li *ngIf="p.dataTable.currentPage + 1 <= p.dataTable.numberOfPages"
            (click)="p.setPage(p.dataTable.currentPage + 1)">
          <a class="cursorPointer item-pager-style">{{p.dataTable.currentPage + 1}}</a>
        </li>
        <li *ngIf="p.dataTable.currentPage + 3 <= p.dataTable.numberOfPages" class="pagination-ellipsis">
          <a class="cursorPointer item-ellipsis-style">...</a>
        </li>
        <li *ngIf="p.dataTable.currentPage + 2 <= p.dataTable.numberOfPages"
            (click)="p.setPage(p.dataTable.numberOfPages)">
          <a class="cursorPointer item-pager-style">{{p.dataTable.numberOfPages}}</a>
        </li>
        <li class="pagination-right"
            *ngIf="!(hiddenDisabledButton && p.dataTable.currentPage >= p.dataTable.numberOfPages)"
            [class.disabled]="p.dataTable.currentPage >= p.dataTable.numberOfPages"
            (click)="(p.dataTable.currentPage < p.dataTable.numberOfPages) && p.setPage(p.dataTable.currentPage + 1)">
          <a class="cursorPointer item-angle-right-style"><i class="fa fa-angle-right"></i></a>
        </li>
        <li *ngIf="enableExtreme && !(hiddenDisabledButton && p.dataTable.currentPage >= p.dataTable.numberOfPages)"
            class="pagination-right-most"
            [class.disabled]="p.dataTable.currentPage >= p.dataTable.numberOfPages"
            (click)="(p.dataTable.currentPage < p.dataTable.numberOfPages) && p.setPage(p.dataTable.numberOfPages)">
          <a class="cursorPointer item-angle-right-style"><i class="fa fa-angle-double-right"></i></a>
        </li>
      </ul>
      <ul class="pagination pagination-bootstrap4"
          *ngIf="p.dataTable.numberOfPages * p.dataTable.rowsOnPage > minRowsOnPage"
          [ngClass]="limitClass">
        <li *ngFor="let rows of rowsOnPage" [class.active]="(p.dataTable.rowsOnPage == rows)"
            (click)="p.setRowsOnPage(rows)">
          <a class="cursorPointer">{{rows}}</a>
        </li>
      </ul>
    </rtn-table-pager-default>
  `,
  // directives: [RtnDataTablePagerComponent],
  providers: [RtnDataTablePagerComponent, RtnDataTablePagerBootstrapComponent]
})

export class RtnDataTablePagerBootstrapComponent implements OnChanges {
  @Input() rowsOnPage: number[] = [];
  @Input() paginationClass: string;
  @Input() limitClass: string;
  @Input() enableExtreme = true;
  @Input() hiddenDisabledButton = false;

  public minRowsOnPage = 0;

  constructor(
    public _router: Router,
  ) {
    // TODO
  }

  ngOnChanges(changes: {
    [key: string]: SimpleChange
  }): any {
    if (changes['rowsOnPage']) {
      this.minRowsOnPage = Math.min.apply(Math, this.rowsOnPage);
    }
  }
}
