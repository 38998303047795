import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  ɵConsole,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '@app/shared/alert';
import { DataLogsService } from '@app/data-logs/data-logs.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { chartDate } from '@app/shared/models/chartDate';
import { PatientsService } from '@app/patients/patients.service';
import { AdminRestService } from '@app/admin/admin.service';
import { Select2PracticeDirective } from '@app/shared/form/select2-practice.directive';
import { dataSearchChart } from '@app/shared/models/dataSearchChart';
import * as moment from 'moment';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { Router } from '@angular/router';
import { Utils } from '@app/shared/utils';
import { SystemService, SystemData } from '@app/shared/system';
import { ChartData } from '@app/shared/models/chartData';

declare let jQuery: any;

@Component({
  selector: 'app-chart-wait-time',
  templateUrl: './chart-wait-time.component.html',
  styleUrls: ['./chart-wait-time.component.scss'],
  // encapsulation: ViewEncapsulation.None
})

export class ChartWaitTimeComponent implements OnInit, AfterViewInit, OnDestroy {
  currentDate: any = moment();
  maxDate: any;
  minHeight: number;
  heightCanvas: number;
  data_chart: any;
  error: chartDate = new chartDate();
  public isDataAvailable = true;
  getWaittimeByProviderSubscriber: Subscription;
  dateBound: DateBound = new DateBound();
  requireMessage: RequireMessage = new RequireMessage();
  // chart: any;
  widthChart: number;

  // search provider
  findAppointment: FindAppointment = new FindAppointment();
  practiceId = '0';
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataSearchChart = new dataSearchChart();
  errorResponse: dataSearchChart = new dataSearchChart();
  arrayModule = new Array();
  practiceTypeSubscriber: Subscription;
  // practices: any = [];
  // public providersList: any ;
  public practicesList: any = [];
  labelAwt: any = 'Average Wait Time';
  public messagePractice = '';
  public messageDate = '';
  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;
  
  public chartDataModel = [0];

  public chartLabelModel = [''];

  public chartType = 'bar';

  public chartDatasets: Array<any> = [{ data: this.chartDataModel, label: this.labelAwt }];

  public chartLabels: Array<any> = this.chartLabelModel;

  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(192, 0, 0, 1)',
      borderColor: 'rgba(220,220,220,1)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(220,220,221,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(220,220,222,1)'
    }
  ];

  public chartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, values: any) {
            if (value !== '') {
              const tempValue = JSON.parse(value);
              return tempValue.label;
            } else {
              return value;
            }
          },
          title: function (tooltipItem: any, data: any) {
            const dataTooltip = JSON.parse(data.labels[tooltipItem[0].index]);
            return dataTooltip.label;
          }
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          userCallback: function (label: any, index: any, labels: any) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label + ' min';
            }
          },
        }
      }]
    },
    legend: {
      display: true,
      labels: {
        fontColor: 'rgb(14, 163, 237)'
      },
      position: 'bottom'
    },
    tooltips: {
      callbacks: {
        beforeBody: function (tooltipItem: any, data: any) {
          const dataTooltip = JSON.parse(data.labels[tooltipItem[0].index]);
          const schedule = [];
          schedule.push('Number of appointments: '+dataTooltip.num_of_apt);
          schedule.push(' ');
          return schedule;
        },
        title: function (tooltipItem: any, data: any) {
          const dataTooltip = JSON.parse(data.labels[tooltipItem[0].index]);
          return dataTooltip.label;
        }
      },
      mode: 'index',
      axis: 'y',
    },
  };
  // private getIdDatePickerFrom: String = '#inputDateFrom';
  // private getIdDatePickerTo: String = '#inputDateFrom1';
  // private getErrorMes: String = ' .error_message';
  // private setBorderColor: String = 'border-color';
  // private removeMessage: String = 'none !important';
  // private getIdPractice: String = '#getPractice option:selected';

  constructor(
    private _patientService: PatientsService,
    private _datalogsService: DataLogsService,
    private _adminService: AdminRestService,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
    public _router: Router,
    private _utils: Utils,
    public _systemService: SystemService,
    public _systemData: SystemData,
  ) {
    this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
    this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    // this.arrayModule = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
    this.getPractices();
  }
  getPractices() {
    this._loadingIndicatorService.start();
    if (this.practiceTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.practiceTypeSubscriber = this._adminService.getPracticeList().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.practicesList = success.practices;
        }
        this._loadingIndicatorService.stop();
      });
  }

  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
      this.maxDate = moment().subtract(2, 'days').format('LL');
      this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    } else {
      this.maxDate = moment().subtract(1, 'days').format('LL');
      this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(_self.inputDateFrom.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
      }
    });
    jQuery(_self.inputDateFrom.nativeElement).datepicker('setDate', this.dataSearchChart.dateFrom);
    jQuery(_self.clickDateFrom.nativeElement).click(() => {
      jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
    });

    jQuery(_self.inputDateTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      maxDate: moment(this.maxDate).toDate(),
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        _self.dataSearchChart.dateTo = jQuery(_self.inputDateTo.nativeElement).val();
      }
    });
    jQuery(_self.inputDateTo.nativeElement).datepicker('setDate', this.dataSearchChart.dateTo);
    jQuery(this.clickDateTo.nativeElement).click(() => {
      jQuery(this.inputDateTo.nativeElement).datepicker('show');
    });

    jQuery('#showDataChart').on('click', function () {
      _self.resetData();
      _self.updateData();
    });
    jQuery('#exportDataChart').on('click', function () {
      _self.export();
    });
  }

  validateDateFromLessDateTo(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    if (date1 > date2) {
      this.errorResponse.dateFrom = message;
      return false;
    } else {
      this.errorResponse.dateFrom = null;
      return true;
    }
  }

  validateDateMoreThan31(message: any) {
    const date1 = new Date(this.dataSearchChart.dateFrom);
    const date2 = new Date(this.dataSearchChart.dateTo);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 31) {
      this.errorResponse.dateTo = message;
      return false;
    } else {
      this.errorResponse.dateTo = null;
      return true;
    }

  }

  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.PracticeSelector.inputError = true;
      this.PracticeSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.PracticeSelector.inputError = false;
      this.PracticeSelector.changeBorderColorPractice();
      return true;
    }
  }

  validate() {
    const validatePractice = this.validatePractice('This field is required');
    const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
    const validateDateMoreThan31
      = this.validateDateMoreThan31('The gap between date from and date end must not exceed 31 days');
    return validatePractice && validateDateFromLessDateTo && validateDateMoreThan31;

  }


  updateData() {

    this.verifyDataSearch();
    const dateFrom = this.dataSearchChart.dateFrom;
    const dateTo = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;
    const type = this.dataSearchChart.type;
    if (this.validate()) {
      this.getWTByProvider(dateFrom, dateTo, practiceID, type);
    } else {
      this.resetData();
      this.chartDatasets = [{ data: this.chartDataModel, label: this.labelAwt }];
      this.chartLabels = this.chartLabelModel;
      return;
    }
  }

  export() {
    this.verifyDataSearch();
    const _self = this;
    const startTime = this.dataSearchChart.dateFrom;
    const endTime = this.dataSearchChart.dateTo;
    const practiceID = this.dataSearchChart.practice;
    if (this.validate()) {
      const token = localStorage.getItem('user_token');
      jQuery.fileDownload(_self._datalogsService.scheduleExportUrl, {
        httpMethod: 'POST',
        data: {
          'token': token,
          'practice': practiceID,
          'startTime': startTime,
          'endTime': endTime,
          'chartType': 1
        }
      }).done(function () {
        // TODO
      })
        .fail(function () {
          _self._alertService.onClose = () => {
            location.reload();
          };
          // _self._alertService.showDismissButton = false;
          // _self._alertService.dismissButtonLabel = 'Close';
          // _self._alertService.showCloseButton = true;
          // _self._alertService.closeButtonLabel = 'Close';
          // _self._alertService.title = 'This document is not available to download anymore.';
          // _self._alertService.message = '';
          // _self._alertService.emitter('open');
        });
    } else {
      this.resetData();
      this.chartDatasets = [{ data: this.chartDataModel, label: this.labelAwt }];
      this.chartLabels = this.chartLabelModel;
    }
  }

  verifyDataSearch() {
    this.dataSearchChart.practice = this.PracticeSelector.getData();
    this.dataSearchChart.type = jQuery('#typeChart option:selected').val();
  }

  getWTByProvider(dateFrom: any, dateEnd: any, practiceID: any, type: any) {
    this._loadingIndicatorService.start();
    this.chartDatasets = [new ChartData()];
    this._datalogsService.getWaittimeByProvider(dateFrom, dateEnd, practiceID, type).subscribe(
      (success: any) => {
        this.data_chart = success.data_chart;
        this._loadingIndicatorService.stop();
        let length = 0;
        if (this.data_chart.length !== 0) {
          this.resetData();
          length = this.data_chart.length;
          for (let index = 0; index < length; index++) {
            // create full name
            const firstname = this.data_chart[index].provider.first_name;
            let middleName = this.data_chart[index].provider.middle_name;
            middleName = (middleName !== null) ? middleName : '';
            const lastName = this.data_chart[index].provider.last_name;
            // push to array
            this.chartDataModel[index] = this.data_chart[index].aptInfo.avgWt;
            // this.chartLabelModel[index] = firstname + ' ' + middleName + ' ' + lastName;

            const dataLabel = {
              'label': firstname + ' ' + middleName + ' ' + lastName,
              'num_of_apt': this.data_chart[index].aptInfo.noOfApt,
            };
            this.chartLabelModel[index] = JSON.stringify(dataLabel);

            
          }
        } else {
          this.resetData();
        }

        this.chartDatasets = [{ data: this.chartDataModel, label: this.labelAwt }];
        this.chartLabels = this.chartLabelModel;
      },
      (error: any) => {
        // todo
        this._loadingIndicatorService.stop();
      }
    );
  }

  ngOnDestroy(): void {

  }

  resetData() {
    this.chartLabelModel.splice(0);
    this.chartDataModel.splice(0);
  }

  public chartHovered(e: any): void {
  }
}

// servew date picker

export class DateBound {
  date: string;
}

export class RequireMessage {
  patient_id: string;
  date: string;
  start_time: string;
  end_time: string;
}

export class FindAppointment {
  specialty: string;
  provider: string;
  date = 0;
  show = '0';
}
