<div class="patient-notification col-12">
    <div class="text-header col-12 pr-0">
        <div class="row">
            <div class="col-6 pl-0">
                <div class="row">
                    <span class="text-uppercase">
                        Messages Center
                    </span>
                    <div class="notification-number" *ngIf="NumOfUnreadNotification">{{NumOfUnreadNotification}}</div>
                </div>
            </div>

            <span class="col-6 text-right pr-0"><i class="fa fa-refresh icon-refresh-style" aria-hidden="true"
                    (click)="refreshData()"></i></span>
        </div>
    </div>
    <rtn-table #table (onDataChange)="handleFetchGetNotificationList($event)" [data]="getNotificationList.data"
        [rowsOnPage]="getNotificationList.rowsOnPage" [numberOfPages]="getNotificationList.numberOfPages">
        <div class="col-md-12 col-sm-12 col-xs-12 no-padding-left-right">
            <table class="table table-notification-style">
                <div *ngFor="let item of getNotificationList.data; let i = index" class="notification-item"
                    (click)="readNotification(item.read_at, item.id)" [class.read]="item.read_at!=null"
                    id="notification-{{item.id}}">
                    <div class="row">
                        <div
                            class="col-11 col-lg-11 col-md-11 col-sm-11 col-xs-10 no-padding-right content-notification-item">
                            <div class="notification-item-unread" id="notification-unread-{{item.id}}"
                                *ngIf="item.read_at === null"></div>
                            <div>
                                <span class="notification-name">{{ item.fullName }}: </span>
                                <span class="notification-message" *ngIf="item.type != 'OPENINGS'"
                                    [innerHTML]="item.message"></span>
                                <span class="notification-message" *ngIf="item.type == 'OPENINGS'"
                                    [innerHTML]="item.message"></span>
                                <!--<span *ngIf="item.type == 'OPENINGS'"
                                    (click)="openAppointmentBook(item.data)" class="temp">. Click here for more
                                    details</span>
                                <div class="container no-padding-left-right"
                                    *ngIf="item.type == 'OPENINGS' && item.data !== null">
                                    <button class="btn btn-rtn btn-180 text-uppercase btn-book"
                                        (click)="openAppointmentBook(item.data)"
                                        [attr.disabled]="!module[5] ? true : null">
                                        BOOK APPOINTMENT
                                    </button>
                                </div>-->
                                <div class="notification-time" *ngIf="item.read_at === null">
                                    {{ item.timeAgo }} - Unread
                                </div>
                                <div class="notification-time" *ngIf="item.read_at != null">
                                    {{ item.timeAgo }} - Read at: {{ item.read_at }}
                                </div>
                            </div>
                        </div>
                        <div class="col-1 col-lg-1 col-md-1 col-sm-1 col-xs-2 text-right">
                            <a class="icon-delete-style" (click)="callDeleteNotificationDirective(item.id)">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 px-0" *ngIf="table.numberOfPages < 1">
                    <table class="table mb-0">
                        <tr class="no-notification-style read">
                            <td class="no-provider">There is no notification</td>
                        </tr>
                    </table>
                </div>
                <tfoot *ngIf="table.numberOfPages > 1">
                    <tr>
                        <td colspan="2" class="text-center no-padding-left-right">
                            <div class="page-footer">
                                <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </rtn-table>
</div>
<rtn-modals-delete-notification (onClick)="deleteNotification()"></rtn-modals-delete-notification>
<rtn-embed-book-modal></rtn-embed-book-modal>