import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RtnConfig} from '@app/shared/rtnconfig';
import {map} from 'rxjs/operators';
import {UtilsService} from '@app/core/utils.service';


@Injectable({
    providedIn: 'root'
})
export class MasterSchedulesService {
    checkInUrl = environment.serverUrl + '/schedule/check-in/';
    checkOutUrl = environment.serverUrl + '/schedule/check-out/';
    cancelAppointmentUrl = environment.serverUrl + '/schedule/cancel-appointment/';
    cancelCheckInUrl = environment.serverUrl + '/schedule/cancel-check-in/';
    // editApmtUrl = environment.serverUrl + '/schedule/update-slot/';
    // fetchAvailableSlotByPeriodUrl = environment.serverUrl + '/schedule/available-slot';
    searchPatientUrl = environment.serverUrl + '/patients';
    noShowAppointmentUrl = environment.serverUrl + '/schedule/noshow-appointment';
    updateArrivedTimeUrl = environment.serverUrl + '/schedule/update-arrived-time';
    fetchAppointmentByPeriodUrl = environment.serverUrl + '/schedule/view';
    // fetchAppointmentByPeriodBySuperProviderUrl = environment.serverUrl + '/super-providers/provider/appointments';
    massNotificationUrlToPatient = environment.serverUrl + '/schedule/send-to-patient';
    patientsUrl = environment.serverUrl + '/notifications/patients';
    //validate code
    validateCodeURL = environment.serverUrl + '/schedule/confirmation/';
    //save arrived time
    saveArrivedTime = environment.serverUrl +'/schedule/save-arrived/';

    massNotificationPatientInfoUrl = environment.serverUrl + '/schedule/patientschedulesettings';
    sendtotemplateUrl = environment.serverUrl + '/schedule/send-to-template';
    getListProviderUrl = environment.serverUrl + '/providers/list-providers';

    constructor(private _http: HttpClient) {
    }

    validateCode(code:any, schedule_id:any){
        const params =  schedule_id+'/'+code;
        return this._http.get(this.validateCodeURL+params);
    }
    saveArrTime(code:any, schedule_id:any){
        const params =  schedule_id+'/'+code;
        return this._http.put(this.saveArrivedTime+params, null);
    }
    getPatients() {
        return this._http.get(this.patientsUrl);
    }

    massNotificationToPatient(data: any) {
        //const params = new HttpParams().set('wmlgn',true);
        return this._http.post(this.massNotificationUrlToPatient,  JSON.stringify(data));
    }

    // fetchAppointmentByPeriodBySuperProvider(startTime: any, endTime: any) {
    //     const params = new HttpParams()
    //         .set('start_time', startTime)
    //         .set('end_time', endTime);
    //     return this._http.get(this.fetchAppointmentByPeriodBySuperProviderUrl, { params: params });
    // }

    fetchAppointmentByPeriod(startTime: any, endTime: any, providerId: any) {
        const params = new HttpParams()
            .set('start_time', startTime)
            .set('end_time', endTime)
            .set('provider_id', providerId);
        return this._http.get(this.fetchAppointmentByPeriodUrl, {params: params});
    }

    updateArrivedTime(data: any) {

        return this._http.post(this.updateArrivedTimeUrl, data);
    }

    checkIn(apptId: any, data: any) {
        return this._http.put(this.checkInUrl + apptId, JSON.stringify(data));
    }

    checkOut(apptId: any, data: any) {
        return this._http.put(this.checkOutUrl + apptId, JSON.stringify(data));
    }

    cancelAppointment(apptId: any, data: any) {
        return this._http.put(this.cancelAppointmentUrl + apptId, JSON.stringify(data));
    }

    cancelCheckIn(apptId: any, data: any) {
        return this._http.put(this.cancelCheckInUrl + apptId, JSON.stringify(data));
    }

    // editApmt(apptId: any, data: any) {
    //     return this._http.put(this.editApmtUrl + apptId, JSON.stringify(data));
    // }

    // fetchAvailableSlotByPeriod(startTime: any, endTime: any) {
    //     const params = new HttpParams().set('start_time', startTime).set('end_time', endTime);
    //     return this._http.get(this.fetchAvailableSlotByPeriodUrl, { params });
    // }

    searchPatient(key: any) {
        const params = new HttpParams().set('key', key);
        return this._http.get(this.searchPatientUrl, {params: params});
    }

    noShowAppointment(data: any) {
        return this._http.post(this.noShowAppointmentUrl, data);
    }
    getPatientScheduleSettings(patientId: any, scheduleId: any) {
        const params = new HttpParams().set('patientId', patientId).set('scheduleId', scheduleId);
        return this._http.get(this.massNotificationPatientInfoUrl, {params: params});
    }
    getSendtoTemplates() {        
        return this._http.get(this.sendtotemplateUrl);
    }
    getListProvider() {
        return this._http.get(this.getListProviderUrl);
    }
}
