import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
// import { PatientService } from '../services/patient.service';
import {FormControl, FormGroup, FormArray} from '@angular/forms';
// import {
//   Control,
//   ControlGroup,
//   ControlArray,
//   CORE_DIRECTIVES,
//   FORM_DIRECTIVES
// } from 'angular2/common';
import {Utils} from '@app/shared/utils';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {AppointmentsService} from '@app/appointments/appointments.service';
import {SystemService} from '@app/shared/system';
import { Module } from '@app/core/module';
import {Router} from '@angular/router';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'app-appointment-preferences',
  templateUrl: './appointment-preferences.component.html',
  styleUrls: ['./appointment-preferences.component.scss']
})
export class AppointmentPreferencesComponent implements OnInit, OnDestroy, AfterViewInit {

  waitTimeTrigger: any = {};
  // waitTimeReminder: any = {};
  appointmentNotifications: any = {};
  messageResponseTriggerSuccess: any;
  // messageResponseReminderSuccess: any;
  messageResponseAppointmentSuccess: any;
  waitTimeTriggerErrors: any = {};
  // waitTimeReminderErrors: any = {};
  listHoursLonger: any[] = new Array();
  listHoursEqualOrShorter: any[] = new Array();
  listMinutesThan: any[] = new Array();

  isNotification = true;
  isAlertWaittime = true;
  isReminder = true;
  alertAppointmentConfirmation = true;
  isWaittimeDisplay = true;
  isShowAvailbleAppointment = true;
  messageSuccess: String = '';

  // Form wait time reminder
  allControlGroups: FormGroup[] = [];
  controlArray: FormArray = new FormArray(this.allControlGroups);

  getWaitTimeTriggerSettingSubscriber: Subscription;
  getWaitTimeReminderSettingSubscriber: Subscription;
  /*getAppointmentNotificationsSettingSubscriber: Subscription;*/
  saveWaitTimeTriggerSubscriber: Subscription;
  saveWaitTimeReminderSubscriber: Subscription;
  saveAppointmentNotificationsSubscriber: Subscription;

  getOtherSettingsSubscriber: Subscription;
  saveOtherSettingsSubscriber: Subscription;
  module: any;

  alert_waitlist_to_patient:any = false;
  alert_waitlist_to_office:any  = false;
  waitlist_alert_office_email_id:any  = '';
  send_waitlist_to_office_time1:any   = null;
  send_waitlist_to_office_time2:any   = null;
  send_waitlist_to_office_time3:any   = null;

  waitlist_threshold_limit : number = 20;
  expire_open_slot:any=false;
  expire_open_slot_threshold_limit : number = 24;
  fill_slots_equal_or_smaller:any = false;
  slotThresholdLimitOptions:any = [];
  disable_45_rule_to_office:any = true;

  constructor(private Service: AppointmentsService,
     private _utils: Utils,
     private _systemService:  SystemService,
     private _router: Router) {
    // TODO
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if(!this.module[Module.NOTIFICATION] && !this.module[Module.WAIT_TIME] && !this.module[Module.NOTIFICATION])
    {
      this._router.navigate(['/home', 'page-not-found']);
    }
    if (this._utils.accessByProvider()) {
      const _self = this;
      if (this.getOtherSettingsSubscriber) {
        this.getOtherSettingsSubscriber.unsubscribe();
      }
      this.getOtherSettingsSubscriber = this.Service.getOtherSettings().subscribe(
        (success: any) => {
          this.isAlertWaittime = (parseInt(success.dataOtherSettings.alert_waittime, 10) === 1);
          this.isReminder = (parseInt(success.dataOtherSettings.is_reminder, 10) === 1);
          this.alertAppointmentConfirmation = (parseInt(success.dataOtherSettings.alert_appointment_confirmation, 10) === 1);
          this.isWaittimeDisplay = (parseInt(success.dataOtherSettings.is_waittime_display, 10) === 1);
          this.isShowAvailbleAppointment = (parseInt(success.dataOtherSettings.show_availble_appointment, 10) === 1);
          //this.isNotification = (parseInt(success.dataOtherSettings.is_setting_notification, 10) === 1);
          this.isNotification = true;
          
          this.alert_waitlist_to_patient = (parseInt(success.dataOtherSettings.alert_waitlist_to_patient, 10) === 1);
          this.alert_waitlist_to_office = (parseInt(success.dataOtherSettings.alert_waitlist_to_office, 10) === 1);
          this.waitlist_alert_office_email_id = success.dataOtherSettings.waitlist_alert_office_email_id;

          this.send_waitlist_to_office_time1 = (parseInt(success.dataOtherSettings.send_waitlist_to_office_time1, 10) === 1); // 6 AM
          this.send_waitlist_to_office_time2 = (parseInt(success.dataOtherSettings.send_waitlist_to_office_time2, 10) === 1); // 3 PM
          this.send_waitlist_to_office_time3 = (parseInt(success.dataOtherSettings.send_waitlist_to_office_time3, 10) === 1); // 7 PM
          this.waitlist_threshold_limit = success.dataOtherSettings.waitlist_threshold_limit;

          this.expire_open_slot = (parseInt(success.dataOtherSettings.expire_open_slot, 10) === 1);
          this.expire_open_slot_threshold_limit = success.dataOtherSettings.expire_open_slot_threshold_limit;
          this.fill_slots_equal_or_smaller = (parseInt(success.dataOtherSettings.fill_slots_equal_or_smaller, 10) === 1);
          this.disable_45_rule_to_office = (parseInt(success.dataOtherSettings.disable_45_rule_to_office, 10) === 1);

          //jQuery('#notification-main').prop('checked', this.isNotification);
          jQuery('#notification-first').prop('checked', this.isAlertWaittime);
          jQuery('#notification-second').prop('checked', this.isReminder);
          jQuery('#alert_appointment_confirmation').prop('checked', this.alertAppointmentConfirmation);
          jQuery('#wait-time-display').prop('checked', this.isWaittimeDisplay);
          jQuery('#available-appointment').prop('checked', this.isShowAvailbleAppointment);

          jQuery('#alert_waitlist_to_patient').prop('checked', this.alert_waitlist_to_patient);
          jQuery('#alert_waitlist_to_office').prop('checked', this.alert_waitlist_to_office);
          jQuery('#send_waitlist_to_office_time1').prop('checked', this.send_waitlist_to_office_time1);
          jQuery('#send_waitlist_to_office_time2').prop('checked', this.send_waitlist_to_office_time2);
          jQuery('#send_waitlist_to_office_time3').prop('checked', this.send_waitlist_to_office_time3);

          jQuery('#expire_open_slot').prop('checked', this.expire_open_slot);
          jQuery('#fill_slots_equal_or_smaller').prop('checked', this.fill_slots_equal_or_smaller);
          jQuery('#disable_45_rule_to_office').prop('checked', this.disable_45_rule_to_office);

          /*if (jQuery('#notification-main').is(':checked')) {
            jQuery('#notification-first').prop('disabled', false);
            jQuery('#notification-second').prop('disabled', false);
          } else {
            jQuery('#notification-first').prop('disabled', true);
            jQuery('#notification-second').prop('disabled', true);
          }*/
          jQuery('#notification-first').prop('disabled', false);
          jQuery('#notification-second').prop('disabled', false);

          if (jQuery('#notification-first').is(':checked')) {
            jQuery('#wait-time-display').prop('disabled', false);            
          } else {
            jQuery('#wait-time-display').prop('disabled', true);            
          }

          if (jQuery('#alert_waitlist_to_office').is(':checked')) {
            
          } else {
            this.waitlist_alert_office_email_id = '';            
            this.send_waitlist_to_office_time1 = 0;
            this.send_waitlist_to_office_time2 = 0;
            this.send_waitlist_to_office_time3 = 0;
            jQuery('#send_waitlist_to_office_time1').prop('checked', false);
            jQuery('#send_waitlist_to_office_time2').prop('checked', false);
            jQuery('#send_waitlist_to_office_time3').prop('checked', false);
          }
          if (jQuery('#expire_open_slot').is(':checked')) {
            this.expire_open_slot = true;
          } else {
            this.expire_open_slot_threshold_limit = 0;
            this.expire_open_slot = false;
          }
          this.slotThresholdLimitOptions = success.slotThresholdLimitOptions;
        },
        (error: any) => {
          const err = error.error;
        }
      );
    }

    // if (this.getWaitTimeTriggerSettingSubscriber) {
    //     this.getWaitTimeTriggerSettingSubscriber.unsubscribe();
    // }
    // this.getWaitTimeTriggerSettingSubscriber = this._patientService.getWaitTimeTriggerSetting().subscribe(
    //     (success: any) => {
    //         _self.waitTimeTrigger = success.wait_time_trigger;
    //     },
    //     (error: any) => {
    //         //TODO
    //     }
    // );

    // if (this.getWaitTimeReminderSettingSubscriber) {
    //     this.getWaitTimeReminderSettingSubscriber.unsubscribe();
    // }
    // this.getWaitTimeReminderSettingSubscriber = this._patientService.getWaitTimeReminderSetting().subscribe(
    //     (success: any) => {
    //         _self.waitTimeReminder = success.wait_time_reminder;
    //         while(_self.controlArray.length) {
    //             _self.controlArray.removeAt(0);
    //           }
    //         jQuery.each(_self.waitTimeReminder, function(i, waittimeMessage) {
    //             _self.controlArray.push(
    //                 new ControlGroup({
    //                     id: new Control(waittimeMessage.id),
    //                     user_id: new Control(waittimeMessage.user_id),
    //                     from_waittime: new Control(waittimeMessage.from_waittime),
    //                     to_waittime: new Control(waittimeMessage.to_waittime),
    //                     message: new Control(waittimeMessage.message),
    //                 })
    //             );
    //         });
    //     },
    //     (error: any) => {
    //         //TODO
    //     }
    // );

    // if (this.getAppointmentNotificationsSettingSubscriber) {
    //     this.getAppointmentNotificationsSettingSubscriber.unsubscribe();
    // }
    // this.getAppointmentNotificationsSettingSubscriber
    // = this._patientService.getAppointmentNotificationsSetting().subscribe(
    //     (success: any) => {
    //         _self.appointmentNotifications = success.appointment_notifications;
    //     },
    //     (error: any) => {
    //         //TODO
    //     }
    // );

    // for (let i = 1; i <= 4; i += 0.5) {
    //     this.listHoursLonger.push(i);
    //     this.listHoursEqualOrShorter.push(i);
    // }

    // for (let i = 10; i <= 80; i += 5) {
    //     this.listMinutesThan.push(i);
    // }
  }

  ngOnDestroy() {
    if (this.getOtherSettingsSubscriber) {
      this.getOtherSettingsSubscriber.unsubscribe();
    }
    if (this.saveOtherSettingsSubscriber) {
      this.saveOtherSettingsSubscriber.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // TODO
    const _self = this;
    jQuery('#notification-main').change(function () {
      if (this.checked) {
        jQuery('#notification-first').prop('disabled', false);
        jQuery('#notification-second').prop('disabled', false);
        jQuery('#wait-time-display').prop('disabled', false);
        jQuery('#alert_appointment_confirmation').prop('disabled', false);
      } else {
        jQuery('#notification-first').prop('checked', false);
        jQuery('#notification-second').prop('checked', false);
        jQuery('#wait-time-display').prop('checked', false);
        jQuery('#alert_appointment_confirmation').prop('checked', false);
        _self.isAlertWaittime = false;
        _self.isReminder = false;
        _self.alertAppointmentConfirmation = false;
        jQuery('#notification-first').prop('disabled', true);
        jQuery('#notification-second').prop('disabled', true);
        jQuery('#wait-time-display').prop('disabled', true);
        jQuery('#alert_appointment_confirmation').prop('disabled', true);

      }
    });

    jQuery('#alert_waitlist_to_office').change(function () {
      if (this.checked) {
        _self.alert_waitlist_to_office = true;
        jQuery('#send_waitlist_to_office_time1').prop('checked', false);
        jQuery('#send_waitlist_to_office_time2').prop('checked', false);
        jQuery('#send_waitlist_to_office_time3').prop('checked', false);

        _self.waitlist_alert_office_email_id = '';
        _self.send_waitlist_to_office_time1 = 0;
        _self.send_waitlist_to_office_time2 = 0;
        _self.send_waitlist_to_office_time3 = 0;
      } else {
        _self.alert_waitlist_to_office = false;
        jQuery('#send_waitlist_to_office_time1').prop('checked', false);
        jQuery('#send_waitlist_to_office_time2').prop('checked', false);
        jQuery('#send_waitlist_to_office_time3').prop('checked', false);

        _self.waitlist_alert_office_email_id = '';
        _self.send_waitlist_to_office_time1 = 0;
        _self.send_waitlist_to_office_time2 = 0;
        _self.send_waitlist_to_office_time3 = 0;
      }
    });

    jQuery('#notification-first').change(function () {
      if (this.checked) {
        _self.isWaittimeDisplay = true;
        jQuery('#wait-time-display').prop('checked', true);
        jQuery('#wait-time-display').prop('disabled', false);
      } else {
        _self.isWaittimeDisplay = false;
        jQuery('#wait-time-display').prop('checked', false);
        jQuery('#wait-time-display').prop('disabled', true);
      }
    });

    jQuery('#expire_open_slot').change(function () {
      if (this.checked) {
        _self.expire_open_slot_threshold_limit = 24;
        _self.expire_open_slot = true;
      } else {
        _self.expire_open_slot_threshold_limit = 0;
        _self.expire_open_slot = false;
      }
      console.log('expire_open_slot:' + _self.expire_open_slot);
    });    
  }

  submitSetting(formVals: any) {
    const value = Object.assign({}, formVals);

    //value.is_setting_notification = jQuery('#notification-main').is(':checked') ? 1 : 0;
    value.is_setting_notification = 1;
    value.alert_waittime = jQuery('#notification-first').is(':checked') ? 1 : 0;
    value.is_reminder = jQuery('#notification-second').is(':checked') ? 1 : 0;
    value.is_waittime_display = jQuery('#wait-time-display').is(':checked') ? 1 : 0;
    value.show_availble_appointment = jQuery('#available-appointment').is(':checked') ? 1 : 0;
    value.alert_appointment_confirmation = jQuery('#alert_appointment_confirmation').is(':checked') ? 1 : 0;

    value.alert_waitlist_to_patient = jQuery('#alert_waitlist_to_patient').is(':checked') ? 1 : 0;
    value.alert_waitlist_to_office = jQuery('#alert_waitlist_to_office').is(':checked') ? 1 : 0;
    value.waitlist_alert_office_email_id = this.waitlist_alert_office_email_id;

    value.send_waitlist_to_office_time1 = jQuery('#send_waitlist_to_office_time1').is(':checked') ? 1 : 0;
    value.send_waitlist_to_office_time2 = jQuery('#send_waitlist_to_office_time2').is(':checked') ? 1 : 0;
    value.send_waitlist_to_office_time3 = jQuery('#send_waitlist_to_office_time3').is(':checked') ? 1 : 0;

    value.expire_open_slot = jQuery('#expire_open_slot').is(':checked') ? 1 : 0;
    value.fill_slots_equal_or_smaller = jQuery('#fill_slots_equal_or_smaller').is(':checked') ? 1 : 0;
    value.disable_45_rule_to_office = jQuery('#disable_45_rule_to_office').is(':checked') ? 1 : 0;
    //value.expire_open_slot_threshold_limit = this.expire_open_slot_threshold_limit;

    console.log(value);

    if (value.alert_waitlist_to_office == 0) {
      delete value.waitlist_alert_office_email_id;

      value.send_waitlist_to_office_time1 = 0;
      value.send_waitlist_to_office_time2 = 0;
      value.send_waitlist_to_office_time3 = 0;
    }

    if (value.alert_waitlist_to_office == 1 && value.waitlist_alert_office_email_id == '') {
      this.messageSuccess = 'Please Enter Office email id';
      return false;
    }

    if (value.alert_waitlist_to_office == 1 && value.send_waitlist_to_office_time1 == 0 && value.send_waitlist_to_office_time2 == 0 && value.send_waitlist_to_office_time3 == 0) {
      this.messageSuccess = 'Please select atlease one reminder time';
      return false;
    }

    if (this.saveOtherSettingsSubscriber) {
      this.saveOtherSettingsSubscriber.unsubscribe();
    }
    this.saveOtherSettingsSubscriber = this.Service.saveOtherSettings(value).subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          this.messageSuccess = success.error_messages;
        }
      },
      (error: any) => {
        const err = error.error;
        if (err.error_code != undefined && err.error_code != null && err.error_code == 'RC156'){
          this.messageSuccess = err.error_messages; 
        }
      }
    );
  }

  removeMessage() {
    this.messageSuccess = null;
  }
}
