import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  EventEmitter,
  Output,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import { MODAL_DIRECTIVES, ModalComponent } from '@app/shared/ng2-bs3-modal';
import { RestService } from '@app/core/rest.service';
import { AlertService } from '@app/shared/alert';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MasterSchedulesService } from '@app/master-schedules/master-schedules.service';
import { Event } from '@app/master-schedules/master-schedules.component';
import { MobileNumberComponent } from '@app/shared/mobile-number/mobile-number.component';

const _momentOptions = {
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 6
  }
};

moment.updateLocale('en-US', _momentOptions);

@Component({
  moduleId: module.id,
  selector: 'app-timline-mass-notification',
  templateUrl: './timline-mass-notification.component.html',
  styleUrls: ['./timline-mass-notification.component.scss'],
  providers: [RestService],
})
export class TimlineMassNotificationComponent implements OnInit {
  eventEntry: Event;
  messageRequire: string; // Show message require
  patients: any = [];
  patientsId: any = [];
  patientsSelected: any = [];
  errorResponse: any;
  massNotificationSubscriber: Subscription;
  getReceiversSubscriber: Subscription;
  patientDefaultType = 'Add Patient(s)';
  patientName: string = '';
  @ViewChild('Timeline_MassNotification_Patient') modal: ModalComponent;
  @Output()
  onClick: EventEmitter<any> = new EventEmitter();
  patientTypeSubscriber: Subscription;
  // ========== Specific Modal Method ========== //

  massNotiModel: MassNotiClass = new MassNotiClass();
  patientSettings:any = {};
  massNotificationSettingsSubscriber: Subscription;
  //errorMessage: string;
  errorEmailMessage: string;
  //errorMobileMessage: string;
  //errorAltMobileMessage:string;
  //messageResponseSuccess: string;
  mobile: any = {};
  alt_mobile: any = {};
  //waittimelink : any = 'Waiting Time Meter';
  waittimelink : any = '[WAITTIMEMETERLINK]';
  notifytype : string;
  

  sendto_templates:any = [];

  sendtoTemplateSubscriber: Subscription;
  notification_text:any = '';

  @ViewChild(MobileNumberComponent) mobileNumberDirective: MobileNumberComponent;

  constructor(
    private _restService: MasterSchedulesService,
    private _alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService
  ) { }

  validatePatient() {

    let mobileValid = true;
    let altmobileValid = true;
    let emailValid = true;

    if (this.mobile.first === '' && this.mobile.middle === '' && this.mobile.last === '') {
        mobileValid = false;
    }
    if (this.alt_mobile.first === '' && this.alt_mobile.middle === '' && this.alt_mobile.last === '') {
        altmobileValid = false;
    }
    if (this.patientSettings.email === '') {
        
        emailValid = false;
    } 

    //console.log(this.mobile.first + ' - ' + this.mobile.middle + ' - ' + this.mobile.last);
    //console.log(this.alt_mobile.first + ' - ' + this.alt_mobile.middle + ' - ' + this.alt_mobile.last);
    //console.log(this.patientSettings.email);

    if (mobileValid === false && altmobileValid === false && emailValid === false) {
        this.errorEmailMessage = 'Please enter email or phone number';
        return false;
    }

    if (this.notifytype == 'patient_sendto' && this.massNotiModel.content == ""){
        this.messageRequire = 'This field is required';
        return false;
    }

    return true;
}

removeMessage() {
    //this.messageResponseSuccess = '';
    //this.errorMessage = '';
    this.errorEmailMessage = '';
    //this.errorMobileMessage = '';    
    //this.errorAltMobileMessage = '';
    this.messageRequire = null;
}

massNotiClicked() {
    if(this.validatePatient()){
        this.patientsSelected = this.eventEntry.data.patientId;
        let scheduleId = this.eventEntry.data.id;

        this.loadingIndicatorService.start();
        if (this.massNotificationSubscriber) {
            this.massNotificationSubscriber.unsubscribe();
        }
        
        if (this.mobile.first === '' && this.mobile.middle === '' && this.mobile.last === '') {
            this.patientSettings.mobile_number = '';
        } else {
            this.patientSettings.mobile_number = this.mobile.first + ' ' + this.mobile.middle + '-' + this.mobile.last;
            const numberOnly = this.patientSettings.mobile_number.replace(/[^0-9]/g, '');
            this.patientSettings.mobile_number = numberOnly ? this.patientSettings.mobile_number : null;
        }

        if (this.alt_mobile.first === '' && this.alt_mobile.middle === '' && this.alt_mobile.last === '') {
            this.patientSettings.alt_phone_mobile = '';
        } else {
            this.patientSettings.alt_phone_mobile = this.alt_mobile.first + ' ' + this.alt_mobile.middle + '-' + this.alt_mobile.last;
            const numberOnly1 = this.patientSettings.alt_phone_mobile.replace(/[^0-9]/g, '');
            this.patientSettings.alt_phone_mobile = numberOnly1 ? this.patientSettings.alt_phone_mobile : null;
        }

        this.patientSettings.isaddwaittimemeterlink = this.patientSettings.isaddwaittimemeterlink;

        /*if(this.notifytype == 'waittime'){
            this.patientSettings.isactivateuser = false;
        }else{
            this.patientSettings.isactivateuser = this.patientSettings.isactivateuser;
        }*/

        this.massNotificationSubscriber = this._restService.massNotificationToPatient({
            patientID: this.patientsSelected,
            msg: this.massNotiModel.content,
            patientSettings : this.patientSettings,
            scheduleId : scheduleId,
            notifyType: this.notifytype
        }).subscribe(
            (success: any) => {
                this.loadingIndicatorService.stop();
                // if (success.error_code === 'RC000') {
                    this._alertService.onClose = () => {
                        this.dismiss();
                    };
                    this._alertService.showDismissButton = false;
                    this._alertService.dismissButtonLabel = 'Close';
                    this._alertService.showCloseButton = true;
                    this._alertService.closeButtonLabel = 'Close';
                    this._alertService.title = 'Sent successfully!';
                    this._alertService.message = '';
                    this._alertService.emitter('open');
                // }
            },
            (error: any) => {
                this.loadingIndicatorService.stop();
                const e = error.error;
                if (e.error_code === 'RC050') {
                    this.messageRequire = e.error_fields.body;
                } else if (e.error_code === 'RC049') {
                    this._alertService.onClose = () => {
                        this.dismiss();
                    };
                    this._alertService.showDismissButton = false;
                    this._alertService.dismissButtonLabel = 'Close';
                    this._alertService.showCloseButton = true;
                    this._alertService.closeButtonLabel = 'Close';
                    this._alertService.title = 'Sent failed.';
                    this._alertService.message = '';
                    this._alertService.emitter('open');
                }
            },
            () => {
                this.loadingIndicatorService.stop();
            }
        );
    }
}

ngOnInit() {

    const self = this;
    //this.patientSettings.isaddwaittimemeterlink = true;
    // jQuery('.notification-mass').parent().css('width', '600px');

    this.getSendtoTemplates();

}

ngAfterViewInit(): void {
    const _self = this;

}

ngOnDestroy() {
    if (this.massNotificationSubscriber) {
        this.massNotificationSubscriber.unsubscribe();
    }

    if (this.sendtoTemplateSubscriber) {
        this.sendtoTemplateSubscriber.unsubscribe();
    }
}

// ========== General Modal Method ========== //

dismiss() {
    this.modal.dismiss();
    this.messageRequire = null;
    this.massNotiModel.content = '';
    this.notification_text = '';
    this.patientsSelected = [];
    this.errorResponse = null;
    this.patientSettings.isaddwaittimemeterlink = true;
    this.patientSettings.isactivateuser = false;
    this.waittimelink = '[WAITTIMEMETERLINK]';
}

onKeyDown(event: any) {
    /*if (event.keyCode === 13 || (event.keyCode === 13 && event.shiftKey)) {    
        event.preventDefault();
    }*/
}

onKeyUp(e: any) {
    this.messageRequire = null;
}

open() {

    this.mobile = {};
    this.alt_mobile = {};   
    this.removeMessage();

    this.notifytype = this.eventEntry.ntype;
    this.loadingIndicatorService.start();
    if (this.massNotificationSettingsSubscriber) {
        this.massNotificationSettingsSubscriber.unsubscribe();
    }
    this.massNotificationSettingsSubscriber = this._restService.getPatientScheduleSettings(this.eventEntry.data.patientId, this.eventEntry.data.id).subscribe((success: any) => {
                
        this.loadingIndicatorService.stop();
        
        this.patientName = this.eventEntry.data.patientName;
        this.patientSettings = success.notification_settings;
        this.patientSettings.isaddwaittimemeterlink = true;
        //this.patientSettings.isactivateuser = true;

        /*if(this.patientSettings.isaddwaittimemeterlink == true) {                   
            this.massNotiModel.content = this.waittimelink;
        }*/

        this.mobile.first = '';
        this.mobile.middle = '';
        this.mobile.last = '';

        this.alt_mobile.first = '';
        this.alt_mobile.middle = '';
        this.alt_mobile.last = '';
        if (this.patientSettings.email === null) {
            this.patientSettings.email = '';
        }

        if (this.patientSettings.mobile_number !== null) {

            const num = this.patientSettings.mobile_number.replace(/[^0-9]/g, '');
            const firstMobileNumber = num.substr(0, 3);
            const secondMobileNumber = num.substr(3, 3);
            const thirdMobileNumber = num.substr(6, 4);
            this.mobile.first = '(' + firstMobileNumber + ')';
            this.mobile.middle = secondMobileNumber;
            this.mobile.last = thirdMobileNumber;
        }

        if (this.patientSettings.mobile_number === '' || this.patientSettings.mobile_number === null) {
            this.patientSettings.is_sms = 0;                    
        }

        if (this.patientSettings.alt_phone_mobile !== null) {

            const altnum = this.patientSettings.alt_phone_mobile.replace(/[^0-9]/g, '');
            const alt_firstMobileNumber = altnum.substr(0, 3);
            const alt_secondMobileNumber = altnum.substr(3, 3);
            const alt_thirdMobileNumber = altnum.substr(6, 4);
            this.alt_mobile.first = '(' + alt_firstMobileNumber + ')';
            this.alt_mobile.middle = alt_secondMobileNumber;
            this.alt_mobile.last = alt_thirdMobileNumber;
        }

        if (this.patientSettings.alt_phone_mobile === '' || this.patientSettings.alt_phone_mobile === null) {
            this.patientSettings.alt_is_sms = 0;                    
        }

        this.modal.open();
    }, (error: any) => {
        this.loadingIndicatorService.stop();
        const e = error.error;
        //console.log(e);

        if (e.error_code === 'RC050') {
            this.messageRequire = e.error_fields.body;
        } else if (e.error_code === 'RC049') {
            this._alertService.onClose = () => {
                this.dismiss();
            };
            this._alertService.showDismissButton = false;
            this._alertService.dismissButtonLabel = 'Close';
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'Close';
            this._alertService.title = 'Sent failed.';
            this._alertService.message = '';
            this._alertService.emitter('open');
        }
    },
    () => {
        this.loadingIndicatorService.stop();
    });
}

    close() {
        this.modal.close();
    }
    clearMobileField(type:any){
        if (type == 'mobile') {
            this.mobile.first = '';
            this.mobile.middle = '';
            this.mobile.last = '';
        } else {
            this.alt_mobile.first = '';
            this.alt_mobile.middle = '';
            this.alt_mobile.last = '';
        }
    }

    getSendtoTemplates() {

        this.loadingIndicatorService.start();
        if (this.sendtoTemplateSubscriber) {
            this.sendtoTemplateSubscriber.unsubscribe();
        }
        this.sendtoTemplateSubscriber = this._restService.getSendtoTemplates().subscribe((success: any) => {                
                this.loadingIndicatorService.stop();
                this.sendto_templates = success.sendto_text;
            },
            (error: any) => {
                this.loadingIndicatorService.stop();
                const e = error.error;
                
                if (e.error_code === 'RC050') {
                    this.messageRequire = e.error_fields.body;
                } else if (e.error_code === 'RC049') {
                    this._alertService.onClose = () => {
                        this.dismiss();
                    };
                    this._alertService.showDismissButton = false;
                    this._alertService.dismissButtonLabel = 'Close';
                    this._alertService.showCloseButton = true;
                    this._alertService.closeButtonLabel = 'Close';
                    this._alertService.title = 'Sent failed.';
                    this._alertService.message = '';
                    this._alertService.emitter('open');
                }
            },
            () => {
                this.loadingIndicatorService.stop();
            }
        );
    }
    changeTemplate(template:any) {
        //this.massNotiModel.content = template+'\n\n'+this.waittimelink;
        this.massNotiModel.content = template;
    }
 
    addwaittimelink(value: any) {
        if(value == true){
            //this.waittimelink = "Waiting Time Meter ";
            //this.waittimelink = "[WAITTIMEMETERLINK] ";
            //this.massNotiModel.content += this.waittimelink;
        }else if(value == false){
            this.waittimelink = '';
            //this.massNotiModel.content = this.massNotiModel.content.replace('[WAITTIMEMETERLINK]','');
        }
    }
    /*activateuseraccount(value:any) {
        if(value == true){

        }else if(value == false) {

        }
    }*/
}

export class MassNotiClass {
  content = '';
}