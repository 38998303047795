import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';

import { ChartsModule, WavesModule } from 'angular-bootstrap-md';

import { ProviderDataLogsRoutingModule } from './provider-data-logs-routing.module';
import { ProviderDataLogsComponent } from './provider-data-logs.component';
import { ProviderStatisticalTypeofappointmentComponent } from './provider-statistical-typeofappointment/provider-statistical-typeofappointment.component';
import {ProvidersModule} from '@app/providers/providers.module';
import {NotificationsModule} from '@app/notifications/notifications.module';

@NgModule({
  imports: [
    CommonModule,
    ProviderDataLogsRoutingModule,
    ChartsModule,
    WavesModule.forRoot(),
    SharedModule,
    FormsModule,
    ProvidersModule,
    NotificationsModule
  ],
  declarations: [ProviderDataLogsComponent, HeaderComponent, ProviderStatisticalTypeofappointmentComponent]
})
export class ProviderDataLogsModule { }
