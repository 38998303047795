import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DocumentsRoutingModule} from './documents-routing.module';
import {DocumentsComponent} from '@app/documents/documents.component';
import {DocumentDeleteComponent} from '@app/documents/modals/document-delete/document-delete.component';
import {DocumentUploadComponent} from '@app/documents/modals/document-upload/document-upload.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@app/shared';
import { HeaderComponent } from './header/header.component';
import {ProvidersModule} from '@app/providers/providers.module';
import {NotificationsModule} from '@app/notifications/notifications.module';


@NgModule({
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ProvidersModule,
    NotificationsModule
  ],
  declarations: [DocumentsComponent, DocumentDeleteComponent, DocumentUploadComponent, HeaderComponent]
})
export class DocumentsModule {
}
