export class PatientDetail {
    email: string;
    email_confirmation: string;
    password_current: string;
    password: string;
    password_confirmation: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    dob: string;
    dob_display: string;
    ssn: string;
    phone_mobile: string;
    phone_mobile_tmp: string;
    phone_mobile_verified: any;
    is_mapped: boolean;
    address: string;
    address2: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string = '';
    state_code: string = '';
    zip: string;
    user_role: string;
    is_confirm_add_same: boolean;
    first: string;
    middle: string;
    last: string;
    month: string = null;
    day: string = null;
    year: string = null;
    insurance_id: string = '';
    insurance_number: string = '';
    status: number;
    alt_phone_mobile: string;
    alt_phonemobile:any; 
    contact_email: string;
}

export class PatientAltphone {
    first: string;
    middle: string;
    last: string;
}    
