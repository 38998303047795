<div class="activate-content col-12 col-lg-10">
    <div class="active-body">
        <div class="col-lg-9 offset-md-3 col-md-10 offset-sm-1 col-12">
            <h2>THANKS FOR SIGNING UP</h2>
<!--            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam leo augue, bibendum id euismod a congue at metus. In vitae metus quam.</p>-->
            <div class="row">
                <div class="col-md-4 col-6 item-router">
                    <div class="row activated-item cursorPointer">
                        <div class="col-12 col-lg-6 icon-image"><a (click)="onClickIcon('appointments')"><img src="/assets/img/icon-calendar-02.png" alt="appointments" class="img-responsive"></a></div>
                        <div class="col-12 col-lg-6 text-align-left"><div (click)="onClickIcon('appointments')"><strong class="icon-text">Appointments</strong></div></div>
                    </div>
                </div>
                <div class="col-md-4 col-6 item-router">
                    <div class="row activated-item cursorPointer">
                        <div class="col-12 col-lg-6 icon-image"><a (click)="onClickIcon('notifications')"><img src="/assets/img/icon-notification-03.png" alt="appointments" class="img-responsive"></a></div>
                        <div class="col-12 col-lg-6 text-align-left"><div (click)="onClickIcon('notifications')"><strong class="icon-text">Notifications</strong></div></div>
                    </div>
                </div>
                <div class="col-md-4 col-6 item-router">
                    <div class="row activated-item cursorPointer">
                        <div class="col-12 col-lg-6 icon-image"><a (click)="onClickIcon('main')"><img src="/assets/img/icon-time-04.png" alt="appointments" class="img-responsive"></a></div>
                        <div class="col-12 col-lg-6 text-align-left"><div (click)="onClickIcon('main')"><strong class="icon-text">Wait times</strong></div></div>
                    </div>
                </div>
                <div class="col-md-4 col-6 item-router">
                    <div class="row activated-item cursorPointer">
                        <div class="col-12 col-lg-6 icon-image"><a (click)="onClickIcon('find-providers')"><img src="/assets/img/icon-doctor-05.png" alt="appointments" class="img-responsive"></a></div>
                        <div class="col-12 col-lg-6 text-align-left"><div (click)="onClickIcon('find-providers')"><strong class="icon-text">Doctors</strong></div></div>
                    </div>
                </div>
                <div class="col-md-4 col-6 item-router">
                    <div class="row activated-item cursorPointer">
                        <div class="col-12 col-lg-6 icon-image"><a (click)="onClickIcon('insurance')"><img src="/assets/img/icon-family-05.png" alt="appointments" class="img-responsive"></a></div>
                        <div class="col-12 col-lg-6 text-align-left"><div (click)="onClickIcon('insurance')"><strong class="icon-text">Family</strong></div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
