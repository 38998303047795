import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { BillingInfo } from '../../shared/models/billingInfo';
import { PracticeDetail } from '../../shared/models/practiceDetail';
import { Select2InsuranceDirective } from '../../shared/form/select2-insurance.directive';
import { Utils } from '../../shared/utils';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-practice',
  templateUrl: './add-practice.component.html',
  styleUrls: ['../admin.component.scss','./add-practice.component.scss'],
  moduleId: module.id,
})
export class AddPracticeComponent implements OnInit {

  @ViewChild(Select2InsuranceDirective) insurancesSelector: Select2InsuranceDirective;
  @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
  @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;
    //@ViewChild(OpeningHourComponent) openingHourDirective: OpeningHourComponent;

    billing: BillingInfo = new BillingInfo();
    practiceDetail: PracticeDetail = new PracticeDetail();
    responseError: PracticeDetail = new PracticeDetail();
    listTime: any[] = new Array();
    settingFields: any = {};
    insuranceList: any;

    addPracticeSubscriber: Subscription;
    getInsurancesSubscriber: Subscription;

    constructor(
        private _router: Router,
        public _systemData: SystemData,
        private _systemEvent: SystemEvent,
        private _systemService: SystemService,
        private _alertService: AlertService,
        private _adminService: AdminRestService,
        private _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        this.settingFields = {
            state_default: '',
            state_billing_default: ''
        };
    }

    ngOnInit() {
        this._systemService.getStates();
        this._systemService.getInsuranceList();
        this.getInsurancesSubscriber = this._systemEvent.insurances.subscribe((done: boolean) => {
            if (done) {
                
                //console.log(this.insuranceList);

                if (this.insuranceList) {
                    this.insurancesSelector.updateData(this.insuranceList);
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.addPracticeSubscriber) {
            this.addPracticeSubscriber.unsubscribe();
        }
        if (this.getInsurancesSubscriber) {
            this.getInsurancesSubscriber.unsubscribe();
        }
    }

    addPracticeForm(formVals: any) {
        this._loadingIndicatorService.start();
        let value = Object.assign({}, formVals);
        //let list_opening_hours = this.openingHourDirective.getData();
        let billing_info = this.formatDataBillingInfo(value);
        //value.opening_hours = list_opening_hours;
        value.billing = billing_info;
        value.insurances = this.insurancesSelector.getData();
        // Get the checked box index before sending out save request
        //let currentCheckedBoxIndex = this.openingHourDirective.getCurrentCheckedBoxIndex();
        if (this.addPracticeSubscriber) {
            this.addPracticeSubscriber.unsubscribe();
        }
        this.addPracticeSubscriber = this._adminService.addPractice(value).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this._alertService.onClose = () => {
                    this._router.navigate(['/admin', 'practice']);
                };
                this._alertService.showDismissButton = false;
                this._alertService.dismissButtonLabel = 'Close';
                this._alertService.showCloseButton = true;
                this._alertService.closeButtonLabel = 'OK';
                this._alertService.title = success.error_messages;
                this._alertService.message = '';
                this._alertService.emitter('open');
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let res = error.error;
                this.responseError = res.error_fields;
                /* let messagePos1 = '';
                let messagePos2 = '';
                let messagePos3 = '';
                if ((<any>this.responseError)['opening_hours.0.days'] && (<any>this.responseError)['opening_hours.0.days'].length !== 0) {
                    messagePos1 = (<any>this.responseError)['opening_hours.0.days'];
                } else if ((<any>this.responseError)['opening_hours.0.end_time'] && (<any>this.responseError)['opening_hours.0.end_time'].length !== 0) {
                    messagePos1 = (<any>this.responseError)['opening_hours.0.end_time'];
                }
                if ((<any>this.responseError)['opening_hours.1.days'] && (<any>this.responseError)['opening_hours.1.days'].length !== 0) {
                    messagePos2 = (<any>this.responseError)['opening_hours.1.days'];
                } else if ((<any>this.responseError)['opening_hours.1.end_time'] && (<any>this.responseError)['opening_hours.1.end_time'].length !== 0) {
                    messagePos2 = (<any>this.responseError)['opening_hours.1.end_time'];
                }
                if ((<any>this.responseError)['opening_hours.2.days'] && (<any>this.responseError)['opening_hours.2.days'].length !== 0) {
                    messagePos3 = (<any>this.responseError)['opening_hours.2.days'];
                } else if ((<any>this.responseError)['opening_hours.2.end_time'] && (<any>this.responseError)['opening_hours.2.end_time'].length !== 0) {
                    messagePos3 = (<any>this.responseError)['opening_hours.2.end_time'];
                }

                let showError = [{ index: 0, message: messagePos1 }, { index: 1, message: messagePos2 }, { index: 2, message: messagePos3}];
                this.openingHourDirective.injectError(currentCheckedBoxIndex, showError); */

                let data: any = [];
            }
        );
    }

    formatDataBillingInfo(data: any) {
        return {
            'first_name': data.billing_first_name,
            'last_name': data.billing_last_name,
            'email': data.billing_email,
            'phone_mobile': data.billing_phone_mobile,
            'address': data.billing_address,
            'address2': data.billing_address2,
            'city': data.billing_city,
            'state_code': data.billing_state_code,
            'zip': data.billing_zip
        };
    }

    onKeyUp(error: string) {
        switch (error) {
            case 'name':
                this.responseError['name'] = null;
                break;
            case 'address':
                this.responseError['address'] = null;
                break;
            case 'address2':
                this.responseError['address2'] = null;
                break;
            case 'city':
                this.responseError['city'] = null;
                break;
            case 'state_code':
                this.responseError['state_code'] = null;
                break;
            case 'zip':
                this.responseError['zip'] = null;
                break;
            case 'insurances':
                this.responseError['insurances'] = null;
                break;
            case 'billing_first_name':
                (<any>this.responseError)['billing.first_name'] = null;
                break;
            case 'billing_last_name':
                (<any>this.responseError)['billing.last_name'] = null;
                break;
            case 'billing_email':
                (<any>this.responseError)['billing.email'] = null;
                break;
            case 'billing_phone_mobile':
                (<any>this.responseError)['billing.phone_mobile'] = null;
                break;
            case 'billing_address':
                (<any>this.responseError)['billing.address'] = null;
                break;
            case 'billing_address2':
                (<any>this.responseError)['billing.address2'] = null;
                break;
            case 'billing_city':
                (<any>this.responseError)['billing.city'] = null;
                break;
            case 'billing_state_code':
                (<any>this.responseError)['billing.state_code'] = null;
                break;
            case 'billing_zip':
                (<any>this.responseError)['billing.zip'] = null;
                break;
        }
    }
}
