import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SystemEvent,SystemInterval, SystemService, SystemData } from '../../shared/system';
import {Router} from '@angular/router';
import { LogoutModalsComponent } from '@app/shared/general/logout/modals.component';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['../admin.component.scss','./header-admin.component.scss'],
})
export class HeaderAdminComponent implements OnInit, OnDestroy {
  // declare arrayActiveModule
  arrayModule = new Array();
  @ViewChild(LogoutModalsComponent) logoutDirective: LogoutModalsComponent;
  constructor(
    public _systemData: SystemData,
    public _systemService: SystemService,
    private _systemInterval: SystemInterval,
    public _router: Router
  ) { }

  ngOnInit() {
    this._systemService.checkOnlineStatus();
    this._systemInterval.header_fetchOnlineMode.interval = setInterval(() => {
        this._systemService.checkOnlineStatus();
    }, this._systemInterval.header_fetchOnlineMode.duration);
    // get active module array
    this.arrayModule = this._systemService.moduleDecrypt(localStorage.getItem('module_enc'), localStorage.getItem('user_token').substr(0, 32));
  }

  ngOnDestroy() {
    this._systemInterval.clearSystemInterval(this._systemInterval.header_fetchOnlineMode);
  }

  changePassword(){
    this._router.navigate(['/admin', 'change-password']);
  }


}
