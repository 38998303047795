import { Directive, Input, Output, OnInit, AfterViewInit, OnDestroy, OnChanges, ElementRef, EventEmitter } from '@angular/core';
// import { CORE_DIRECTIVES, ControlGroup, Control } from 'angular2/common';
import { SystemEvent } from '@app/shared/system';

declare let jQuery: any;
@Directive({
  selector: '[select2-provider3]',
  exportAs: 'select2-provider3'
})
export class Select2Provider3Directive implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() multiple = true;
  @Input() tags = true;
  @Input() tokenSeparators: any = [',', ' '];
  @Input() dropdownAutoWidth = true;
  @Input() placeholder = 'Select Provider(s)';
  @Input() allowClear = false;
  @Input() closeOnSelect = false;
  @Input() maximumSelectionLength = 3;
  @Input() dropdownParent: any;
  @Input() templateResult: any;
  @Input() inputError = false;

  @Output() OnKeyUp: EventEmitter<any> = new EventEmitter();
  @Output() OnSelected: EventEmitter<any> = new EventEmitter();
  public element: any;
  inputErrorClass: any = 'input-error';
  public getSelect2ElementSingle: String = '.select2-container--default .select2-selection--single';
  private getSelect2DropDownBelow = 'select2-dropdown--below';
  constructor(
    public el: ElementRef,
    private _systemEvent: SystemEvent
  ) {
    this.element = jQuery(this.el.nativeElement);
  }
  @Input() noResults: any = () => 'No provider matches';
  @Input() maximumSelected: any = () => 'You cannot choose more than ' + this.maximumSelectionLength + ' providers';

  withCheckboxTemplate: any = (result: any) => {
    return jQuery(`<span class="input-rtn">
        <input type="checkbox" class="checkbox check-single">
        <label class="selectorExtreme"></label></span><span class="select-text">` + result.text + `</span>`);
  }
  // withCheckboxTemplate: any = (result: any) => {
  //   return jQuery(`<span class="select-text">` + result.text + `</span>`);
  // }
  ngOnInit() {
    this.element.select2({
      multiple: this.multiple,
      tags: this.tags,
      dropdownAutoWidth: this.dropdownAutoWidth,
      placeholder: this.placeholder,
      allowClear: this.allowClear,
      closeOnSelect: this.closeOnSelect,
      maximumSelectionLength: this.maximumSelectionLength,
      dropdownParent: jQuery(this.element).parent(),
      language: {
        maximumSelected: this.maximumSelected,
        noResults: this.noResults
      },
      templateResult: this.withCheckboxTemplate
    })
      .on('select2:open', (e: any) => {
        this.OnKeyUp.emit(e);
        // Fix dropdown display problem: https://github.com/select2/select2/issues/3303
        const dropdown = jQuery('.select2-container.select2-container--default.select2-container--open:not(".select2")');
        const trigger = jQuery('.select2-container.select2-container--default.select2-container--open.select2');
        if (!dropdown.children().hasClass(this.getSelect2DropDownBelow)) {
          dropdown.children().removeClass('select2-dropdown--above');
          dropdown.children().addClass(this.getSelect2DropDownBelow);
        }
        dropdown.css({ 'top': dropdown.parent().height() + 'px' });
        // Always make sure the dropdown goes to the bottom
      })
      .on('select2:close', (e: any) => {
        this.OnKeyUp.emit(e);
      })
      .on('select2:select', (e: any) => {
        if (jQuery(this.element).val().length === this.maximumSelectionLength) {
          this.close();
        }
        this.OnKeyUp.emit(e);
        // this._systemEvent.event.emit('event_callSelectedSpecialty');
      });
  }

  ngOnChanges() {
    if (this.inputError) {
      jQuery('.select2-container--default .select2-selection--multiple').addClass(this.inputErrorClass);
    } else {
      jQuery('.select2-container--default .select2-selection--multiple').removeClass(this.inputErrorClass);
    }
    const _self = this;
    _self.element.next('.select2-container').css({ 'width': '100%' });
    jQuery('.select2-container--default .select2-selection--multiple').prepend('<span class="select2-selection__caret cursorPointer"><i class="fa fa-caret-down"></i></span>');
    jQuery('select2-results__option.select2-results__option--highlighted').each(function() {
      if (jQuery(this).attr('id') === '') {
        jQuery(this).remove();
      }
    });
  }
  changeBorderColorProvider() {
    const _self = this;
    if (this.inputError) {
        jQuery(jQuery(_self.getSelect2ElementSingle)[1]).addClass(this.inputErrorClass);
    } else {
      jQuery(jQuery(_self.getSelect2ElementSingle)[1]).removeClass(this.inputErrorClass);
    }
}
  ngAfterViewInit() {
    const _self = this;
    _self.element.next('.select2-container').css({ 'width': '100%' });
    jQuery('.select2-container--default .select2-selection--multiple').prepend('<span class="select2-selection__caret cursorPointer"><i class="fa fa-caret-down"></i></span>');
    jQuery('select2-results__option.select2-results__option--highlighted').each(function() {
      if (jQuery(this).attr('id') === '') {
        jQuery(this).remove();
      }
    });
  }

  ngOnDestroy() {
    // do nothing
  }

  invoke(...args: any[]) {
    this.element.select2.apply(this.element, args);
  }

  getData() {
    return jQuery(this.element).val();
  }

  clearData() {
    jQuery(this.element).val(null).trigger('change');
  }

  updateData(data: any) {
    jQuery(this.element).val(data).trigger('change');
  }

  open() {
    this.invoke('open');
  }

  close() {
    this.invoke('close');
  }

  resetSelect2(element: any) {
    element.select2({
      multiple: this.multiple,
      tags: this.tags,
      dropdownAutoWidth: this.dropdownAutoWidth,
      placeholder: this.placeholder,
      allowClear: this.allowClear,
      closeOnSelect: this.closeOnSelect,
      /*maximumSelectionLength: this.maximumSelectionLength,*/
      dropdownParent: jQuery(this.element).parent(),
      language: {
        maximumSelected: this.maximumSelected,
        noResults: this.noResults
      },
      templateResult: this.withCheckboxTemplate
    })
      .on('select2:open', (e: any) => {
        // this.OnKeyUp.emit(e);
        // Fix dropdown display problem: https://github.com/select2/select2/issues/3303
        const dropdown = jQuery('.select2-container.select2-container--default.select2-container--open:not(".select2")');
        const trigger = jQuery('.select2-container.select2-container--default.select2-container--open.select2');
        if (!dropdown.children().hasClass(this.getSelect2DropDownBelow)) {
          dropdown.children().removeClass('select2-dropdown--above');
          dropdown.children().addClass(this.getSelect2DropDownBelow);
        }
        dropdown.css({'top': dropdown.parent().height() + 'px'});
        // Always make sure the dropdown goes to the bottom
      })
      .on('select2:close', (e: any) => {
        // this.OnKeyUp.emit(e);
      })
      .on('select2:unselect', (e: any) => {
        this.OnSelected.emit(e);
      })
      .on('select2:select', (e: any) => {
        this.OnSelected.emit(e);
      });
}
}
