import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
// import { PracticeService } from '../services/practice.service';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {AlertService} from '@app/shared/alert';
import {BillingInfo} from '@app/shared/models/billingInfo';
import {PracticeDetail} from '@app/shared/models/practiceDetail';
import {Router} from '@angular/router';
import {Select2InsuranceDirective} from '@app/shared/form/select2-insurance.directive';
import {Subscription} from 'rxjs';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
// import { OpeningHourComponent } from '../../../../shared/opening-hour';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {Utils} from '@app/shared/utils';
import {RestService} from '@app/core/rest.service';
import {ProvidersService} from '@app/providers/providers.service';

@Component({
  moduleId: module.id,
  templateUrl: './add-practice-provider.component.html',
  styleUrls: ['./add-practice-provider.component.scss']
})
export class AddPracticeProviderComponent implements OnInit, OnDestroy {
  @ViewChild(Select2InsuranceDirective) insurancesSelector: Select2InsuranceDirective;
  // @ViewChild(OpeningHourComponent) openingHourDirective: OpeningHourComponent;

  billing: BillingInfo = new BillingInfo();
  practiceDetail: PracticeDetail = new PracticeDetail();
  responseError: PracticeDetail = new PracticeDetail();
  listTime: any[] = new Array();
  settingFields: any = {};
  insuranceList: any;

  addPracticeSubscriber: Subscription;
  getInsurancesSubscriber: Subscription;

  constructor(
    private _router: Router,
    public _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _alertService: AlertService,
    private _service: ProvidersService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService
  ) {
    this.settingFields = {
      state_default: '',
      state_billing_default: ''
    };
  }

  ngOnInit() {
    if (this._utils.accessAddPractice()) {
      this._systemService.getStates();
      this._systemService.getInsuranceList();
      this.getInsurancesSubscriber = this._systemEvent.insurances.subscribe((done: boolean) => {
        if (done) {
          if (this.insuranceList) {
            this.insurancesSelector.updateData(this.insuranceList);
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.addPracticeSubscriber) {
      this.addPracticeSubscriber.unsubscribe();
    }
    if (this.getInsurancesSubscriber) {
      this.getInsurancesSubscriber.unsubscribe();
    }
  }

  addPracticeForm(formVals: any) {
    this._loadingIndicatorService.start();
    const value = Object.assign({}, formVals);
    // let list_opening_hours = this.openingHourDirective.getData();
    const billing_info = this.formatDataBillingInfo(value);
    // value.opening_hours = list_opening_hours;
    value.billing = billing_info;
    value.insurances = this.insurancesSelector.getData();
    // Get the checked box index before sending out save request
    // let currentCheckedBoxIndex = this.openingHourDirective.getCurrentCheckedBoxIndex();
    if (this.addPracticeSubscriber) {
      this.addPracticeSubscriber.unsubscribe();
    }
    this.addPracticeSubscriber = this._service.addPractice(value).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        localStorage.setItem('user_practice', success.practice_id);
        this._alertService.onClose = () => {
          this._router.navigate(['/provider', 'practice']);
        };
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = success.error_messages;
        this._alertService.message = '';
        this._alertService.emitter('open');
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
        const res = error.error;
        if (res.error_code === 'RC114') {
          this._alertService.onClose = () => {
            this._router.navigate(['/provider', 'practice']);
          };
          this._alertService.showDismissButton = false;
          this._alertService.dismissButtonLabel = 'Close';
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'OK';
          this._alertService.title = res.error_messages;
          this._alertService.message = '';
          this._alertService.emitter('open');
        } else if (res.error_code === 'RC020') {
          this.responseError = res.error_fields;
        }
        let data: any = [];
      },
      () => {
        this._loadingIndicatorService.stop();
      }
    );
  }

  formatDataBillingInfo(data: any) {
    return {
      'first_name': data.billing_first_name,
      'last_name': data.billing_last_name,
      'email': data.billing_email,
      'phone_mobile': data.billing_phone_mobile,
      'address': data.billing_address,
      'address2': data.billing_address2,
      'city': data.billing_city,
      'state_code': data.billing_state_code,
      'zip': data.billing_zip
    };
  }

  onKeyUp(error: string) {
    switch (error) {
      case 'name':
        this.responseError['name'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'insurances':
        this.responseError['insurances'] = null;
        break;
      case 'billing_first_name':
        (<any>this.responseError)['billing.first_name'] = null;
        break;
      case 'billing_last_name':
        (<any>this.responseError)['billing.last_name'] = null;
        break;
      case 'billing_email':
        (<any>this.responseError)['billing.email'] = null;
        break;
      case 'billing_phone_mobile':
        (<any>this.responseError)['billing.phone_mobile'] = null;
        break;
      case 'billing_address':
        (<any>this.responseError)['billing.address'] = null;
        break;
      case 'billing_address2':
        (<any>this.responseError)['billing.address2'] = null;
        break;
      case 'billing_city':
        (<any>this.responseError)['billing.city'] = null;
        break;
      case 'billing_state_code':
        (<any>this.responseError)['billing.state_code'] = null;
        break;
      case 'billing_zip':
        (<any>this.responseError)['billing.zip'] = null;
        break;
    }
  }
}
