import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentsComponent } from '@app/appointments/appointments.component';
import { AppointmentListComponent } from '@app/appointments/appointment-list/appointment-list.component';
import { extract } from '@app/core';
import { Role } from '@app/core/role';
import { AuthGuard } from '@app/core/auth.guard';
import { AppointmentPreferencesComponent } from '@app/appointments/appointment-preferences/appointment-preferences.component';
import { SearchOpeningsComponent } from '@app/appointments/wait-list/search-openings.component';
import { ProviderChangePasswordComponent } from '@app/providers/change-password/change-password.component';
import { MasterSchedulesComponent } from '@app/master-schedules/master-schedules.component';
import { DocumentsComponent } from '@app/documents/documents.component';
import { ArrivalConfirmationComponent } from '@app/master-schedules/arrival-confirmation/arrival-confirmation.component';
const routes: Routes = [{
    path: '',
    component: ArrivalConfirmationComponent,
    children: [
        {
            path: 'schedule/confirmation/:schedule_id/:code',
            component: ArrivalConfirmationComponent,
            data: { title: extract('RTN')},
        },

    ],
},
{
    path: '',
    component: MasterSchedulesComponent,
    children: [{
        path: 'master-schedules',
        component: MasterSchedulesComponent,
        data: {}
    }],
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MasterSchedulesRoutingModule {
}
