import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { PatientDetail } from '../../shared/models/patient-detail';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';
import { DOBComponent } from '../../shared/dob/dob';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['../admin.component.scss','./edit-patient.component.scss'],
  moduleId: module.id,
})
export class EditPatientComponent implements OnInit {

     /*ngDatePicker: any;
    _weekStart: number = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)isMapped: boolean = true;*/
    editPatient: PatientDetail = new PatientDetail();
    error: PatientDetail = new PatientDetail();
    dob: string;
    successMessage: string;
    //isMapped: boolean = true;
    emailInDb: string = '';
    currentStatus: number;
    getUserSubscriber: Subscription;
    updateProfileSubscriber: Subscription;

    @ViewChild(DOBComponent) dobDirective: DOBComponent;
    @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
    @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;

    password: string;
    password_confirmation: string;
    notify_to : boolean = false;
    successMessage1 : string;
    updateNewSuperUserPasswordSubscriber : Subscription;
    responseError1: PatientuserPasswordItem = new PatientuserPasswordItem();

    constructor(
        private _routeParams: ActivatedRoute,
        private _alertService: AlertService,
        private _systemService: SystemService,
        public _systemData: SystemData,
        private _adminService: AdminRestService,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        /*this.ngDatePicker = new Object();
        this.ngDatePicker['todayHighlight'] = true;
        this.ngDatePicker['weekStart'] = this._weekStart;
        this.ngDatePicker['format'] = 'MM dd, yyyy';*/
    }

    ngOnInit() {
        this._loadingIndicatorService.start();
        this._systemService.getInsuranceList();
        this.getPatientInfo();
    }

    ngAfterViewInit() {
        let _self = this;
        /* jQuery('#insurance_number').bind('keypress', function (e: any) {
            return !(e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57));
        }); */
        jQuery('#insurance_number').bind({paste : function(){_self.onKeyUp('insurance_number');}});
    }

    ngOnDestroy() {
        if (this.getUserSubscriber) {
            this.getUserSubscriber.unsubscribe();
        }
        if (this.updateProfileSubscriber) {
            this.updateProfileSubscriber.unsubscribe();
        }

        if (this.updateNewSuperUserPasswordSubscriber) {
            this.updateNewSuperUserPasswordSubscriber.unsubscribe();
        }
    }

    changeInsurance(plan: any) {
        this.error.insurance_id = null;
        if (plan === '-1') {
            this.editPatient.insurance_number = '';
            jQuery('#insurance-number').addClass('hidden');
        } else {
            jQuery('#insurance-number').removeClass('hidden');
        }
    }
    getPatientInfo(){   
        if (this.getUserSubscriber) {
            this.getUserSubscriber.unsubscribe();
        }
        this.getUserSubscriber = this._adminService.getUserPatiend(parseInt(this._routeParams.snapshot.paramMap.get('id'))).subscribe((success: any) => {
            this._loadingIndicatorService.stop();
            if (success.error_code === 'RC000') {
                this.editPatient = success.user;
                this.emailInDb = this.editPatient.email;
                this.currentStatus = this.editPatient.status;
                let dobMoment = moment(this.editPatient.dob);
                this.editPatient.day = dobMoment.get('date').toString();
                if (dobMoment.get('date') < 10) {
                    this.editPatient.day = '0'+dobMoment.get('date').toString();
                } else {
                    this.editPatient.day = dobMoment.get('date').toString();
                }
                if (dobMoment.get('month') < 9) {
                    this.editPatient.month = '0'+(dobMoment.get('month')+1).toString();
                } else {
                    this.editPatient.month = (dobMoment.get('month')+1).toString();
                }
                this.editPatient.year = dobMoment.get('year').toString();
                let ignoreArray = [NaN, null, undefined, ''];
                if (ignoreArray.indexOf(this.editPatient.month) > -1 && ignoreArray.indexOf(this.editPatient.day) > -1 && ignoreArray.indexOf(this.editPatient.year) > -1) {
                    this.editPatient.dob = '';
                } else {
                    this.editPatient.dob = this.editPatient.year + '-' + this.editPatient.month + '-' + this.editPatient.day;
                }
                //this.isMapped = success.user.is_mapped;
                this.editPatient.phone_mobile = this.editPatient.phone_mobile.replace('+1 ', '');
                if (this.editPatient.insurance_id === '' || this.editPatient.insurance_id === null) {
                    this.editPatient.insurance_id = '-1';
                    jQuery('#insurance-number').addClass('hidden');
                }
                //console.log(this.editPatient);
                // this.dob = this.editPatient.dob !== undefined ? moment(this.editPatient.dob, 'YYYY-MM-DD').format('MMMM DD, YYYY') : null;
                //this.editPatient.state_code = success.user.state_code === null ? '' : success.user.state_code.toUpperCase();
            }
        }, (error: any) => {
            this._loadingIndicatorService.stop();
        });
    }
    editPatientForm() {
        this.successMessage = null;
        if (this.currentStatus === 0 && this.emailInDb !== this.editPatient.email) {
            this._alertService.onClose = () => {
                this.editPatient.email = this.emailInDb;
            };
            this._alertService.showDismissButton = false;
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'OK';
            this._alertService.title = 'The user account has not been activated.';
            this._alertService.message = '';
            this._alertService.emitter('open');
        } else {
            this._loadingIndicatorService.start();
            let ignoreArray = [NaN, null, undefined, ''];
            if (ignoreArray.indexOf(this.editPatient.month) > -1 && ignoreArray.indexOf(this.editPatient.day) > -1 && ignoreArray.indexOf(this.editPatient.year) > -1) {
                this.editPatient.dob = '';
            } else {
                this.editPatient.dob = this.editPatient.year + '-' + this.editPatient.month + '-' + this.editPatient.day;
            }
            // this.editPatient.dob = this.dob !== undefined ? moment(this.dob, 'MMMM DD, YYYY').format('YYYY-MM-DD') : null;
            if (this.updateProfileSubscriber) {
                this.updateProfileSubscriber.unsubscribe();
            }
            this.updateProfileSubscriber = this._adminService.updateProfilePatiend(parseInt(this._routeParams.snapshot.paramMap.get('id')), this.editPatient).subscribe(
                (success: any) => {
                    this._loadingIndicatorService.stop();
                    if (success.error_code === 'RC000') {
                        this.successMessage = success.error_messages;
                        this.error = new PatientDetail();
                        //this.editPatient.is_mapped = this.isMapped = success.is_mapped;
                        this.getPatientInfo();
                    }
                },
                (error: any) => {
                    this._loadingIndicatorService.stop();
                    let e = error.error;
                    if (e.error_code === 'RC020') {
                        this.error = e.error_fields;
                    } else if (e.error_code === 'RC107') {
                        this.openErrorMessage(e.error_messages);
                    }else if(e.error_code === 'RC132'){
                        this.openErrorMessage(e.error_messages);
                    }
                    /*switch(e.error_code) {
                        case 'RC020':
                            this.error = e.error_fields;
                            break;

                        case 'RC023':
                            this.openErrorMessage(e.error_messages);
                            break;

                        case 'RC060':
                            this.editPatient.is_mapped = this.isMapped = false;
                            this.error = e.error_fields;
                            break;
                    }*/
                }, () => {
                    this._loadingIndicatorService.stop();
                }
            );
        }
    }

    openErrorMessage(msg: string) {
        this._alertService.onClose = () => {
            //location.reload();
        };
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    onKeyUp(key: string) {
        this.successMessage = null;

        switch (key) {
            case 'email':
                this.error.email = null;
                break;

            case 'first_name':
                this.error.first_name = null;
                break;

            case 'middle_name':
                this.error.middle_name = null;
                break;

            case 'last_name':
                this.error.last_name = null;
                break;

            case 'dob':
                this.error.dob = null;
                break;

            case 'phone_mobile':
                this.error.phone_mobile = null;
                break;

            case 'insurance_id':
                this.error.insurance_id = null;
                break;

            case 'insurance_number':
                this.error.insurance_number = null;
                break;

            case 'zip':
                this.error.zip = null;
                break;
            case 'password':
                this.responseError1['password'] = null;
                break;
            case 'confirm_password':
                this.responseError1['confirm_password'] = null;
                break;  
            case 'response_status':
                this.error.response_status = null;
                break;      
        }
    }
    updatePasswordFromAdmin(formVals: any) {
        const self = this;
        this.successMessage = null;
        let value = Object.assign({}, formVals);
        //console.log(value);

        var idSelected = parseInt(this._routeParams.snapshot.paramMap.get('id'));
    
        this._loadingIndicatorService.start();
            if (this.updateNewSuperUserPasswordSubscriber) {
                this.updateNewSuperUserPasswordSubscriber.unsubscribe();
            }
            this.updateNewSuperUserPasswordSubscriber = this._adminService.updatePasswordFromAdmin(idSelected, value).subscribe(
                (success: any) => {
                    this._loadingIndicatorService.stop();
                    this.successMessage1 = success.error_messages;
                    this.responseError1 = new PatientuserPasswordItem();
                    this.password = null;
                    this.password_confirmation = null;
                    this.notify_to = false;
                },
                (error: any) => {
                    this._loadingIndicatorService.stop();
                    let res = error.error;
                    this.responseError1 = res.error_fields;
                    this.password = null;
                    this.password_confirmation = null;
                    this.notify_to = false;
                }
            );
    }
}

export class PatientuserPasswordItem {
    password: string = null;
    password_confirmation: string = null;
    notify_to : boolean = false;
}
