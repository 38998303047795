import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ProvidersRoutingModule } from './providers-routing.module';
import { ListProvidersComponent } from './list-providers/list-providers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { AddProviderComponent } from './add-provider/add-provider.component';
import { ListManagingUserComponent } from './list-managing-user/list-managing-user.component';
import { AddManagingUserComponent } from './add-managing-user/add-managing-user.component';
import { EditManagingUserComponent } from './edit-managing-user/edit-managing-user.component';
import { SelectProviderComponent } from './select-provider/select-provider.component';
import { PracticeComponent } from './practice/practice.component';
import { PracticeEditComponent } from './practice-edit/practice-edit.component';
import { ListPatientsComponent } from './list-patients/list-patients.component';
import { HeaderComponent } from './header/header.component';
import { ProviderProfileComponent } from './provider-profile/provider-profile.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { PatientNewSignUpComponent } from './patient-new-sign-up/patient-new-sign-up.component';
import { ManageProviderComponent } from './manage-provider/manage-provider.component';
import { MappingProviderComponent } from './mapping-provider/mapping-provider.component';
import { ProvidersComponent } from './providers.component';
import { NotificationsModule } from '@app/notifications/notifications.module';
import { AddPracticeProviderComponent } from './add-practice-provider/add-practice-provider.component';
import { EditComponent } from './edit/edit.component';
import { ProviderChangePasswordComponent } from './change-password/change-password.component';
import { HomeModule } from '@app/home/home.module';
import {NgxMaskModule} from 'ngx-mask';
import { ViewAppointmentsComponent } from './view-appointments/view-appointments.component';
import { ListApttypesComponent } from './list-apttypes/list-apttypes.component';
import { AddApttypesComponent } from './list-apttypes/modals/add-apttypes/add-apttypes.component';
//import { FooterComponent } from '@app/home/footer/footer.component';

@NgModule({
    imports: [
        CommonModule,
        ProvidersRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NotificationsModule,
        HomeModule,
        NgxMaskModule,
    ],
  declarations: [
    ListProvidersComponent,
    AddProviderComponent,
    ListManagingUserComponent,
    AddManagingUserComponent,
    EditManagingUserComponent,
    SelectProviderComponent,
    PracticeComponent,
    PracticeEditComponent,
    ListPatientsComponent,
    HeaderComponent,
    ProviderProfileComponent,
    EditUserComponent,
    PatientNewSignUpComponent,
    ManageProviderComponent,
    MappingProviderComponent,
    ProvidersComponent,
    AddPracticeProviderComponent,
    EditComponent,
    ProviderChangePasswordComponent,
    ViewAppointmentsComponent,    
    ListApttypesComponent, AddApttypesComponent
    ],
  exports: [SelectProviderComponent, HeaderComponent, PatientNewSignUpComponent]
})
export class ProvidersModule {
}
