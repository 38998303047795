<div class="sidebarAndContent">
    <h3 class="title">Patient waiting room time</h3>
    <div class="col-12 px-0">
        <div class="row col-12 border border-#ddd pt-3 mb-3 no-print">
            <!-- Select pratice-->
            <div class="row col-lg-6 mb-2 px-0">
                <div class="d-none d-lg-block width_70 pt-1">Practice:</div>
                <div
                    class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                    Practice:</div>
                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getPractice">
                    <select
                        #select2PracticeDirective2="select2-practice2"
                        select2-practice2
                        class="form-control"
                        [value]="dataSearchChart.practice"
                        [maximumSelectionLength]="0"
                        [closeOnSelect]="true"
                        [multiple]="false"
                        (OnSelected)="onChangePractice()"
                        [tags]="false"
                        id="select2Practice">
                        <option *ngFor="let practice of practicesList; let i = index" [id]="'practice' + practice.id" [value]="practice.id" >{{ practice.name }}</option>
                    </select>
                    <div class="message-error">{{ errorResponse.practice }}</div>
                    <div class="red-alert d-none d-lg-block error_message"></div>
                    <div class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message"></div>
                </div>

            </div>
            <!-- Select provider-->
            <div class="row col-lg-6  mb-2 px-0">
                <div class="d-none d-lg-block  width_70 pt-1">Provider:</div>
                <div
                    class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                    Provider:</div>
                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
                    <select
                        #select2ProviderDirective="select2-provider"
                        select2-provider
                        class="form-control"
                        [value]="dataSearchChart.provider"
                        [closeOnSelect]="true"
                        [maximumSelectionLength]="0"
                        [multiple]="false"
                        id="select2Provider"
                        placeholder="Select provider"
                        [tags]="false">
                        <option value=""></option>
                        <option *ngFor="let provider of providersList; let i = index" [id]="'provider' + provider.id" [value]="provider.id" >
                            {{ provider.full_name }}
                        </option>
                    </select>
                    <div class="message-error">{{ errorResponse.provider }}</div>
                    <div class="red-alert d-none d-lg-block error_message"></div>
                    <div
                        class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
                    </div>

                </div>
            </div>


            <!-- select data from -->
            <div class="row col-lg-6 col-md-12 col-sm-12 mb-2 px-0">
                <div class="d-none d-lg-block  width_70 pt-1">Date:</div>
                <div
                    class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none -title px-0">
                    Date:</div>
                <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                    <div class="input-group">
                        <input
                            type="text"
                            #inputDateFrom
                            readonly
                            [value]="dataSearchChart['dateFrom']"
                            class="form-control calender-input bc-white"
                            [class.input-error]="errorResponse['dateFrom']"
                            aria-describedby="basic-addon2">
                        <div class="input-group-append"  #clickDateFrom>
                            <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                        </div>
                    </div>
                    <div class="message-error">{{ errorResponse.dateFrom }}</div>
                </div>
                <!-- <div class="message-error">{{ errorResponse.start_date }}</div> -->
            </div>
            <!-- select date to -->
            <div class="row col-lg-6 col-md-12 col-sm-12 mb-2 px-0">
            </div>
            <div class="row col-lg-6 mb-2-title mb-2 px-0">
            </div>
            <div class="row col-lg-6 mb-2-title mb-2 px-0 pl_100">
              <div class="d-none d-lg-block  width_70 pt-1"></div>
              <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none-title px-0">
              </div>
              <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
                <div class="message-error d-none d-sm-none d-md-none d-lg-block d-xl-block">{{ errorResponse.dateTo }}</div>
              </div>
    
            </div>
            <div class="px-0 ml-3 row col-lg-10 mb-2">
                <div class="btn-90" *ngIf="noofpage > 0">
                    <button id='printDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1" type="button" (click)="printDataChart()">PRINT</button>
                </div>
                <div class="btn-90  ml-2" *ngIf="noofpage > 0">
                    <button id='exportDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1" type="button" (click)="exportdata()">EXPORT</button>
                </div>
                <div class="btn-90 ml-2">
                  <button id='showDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1"
                    type="submit">SHOW</button>
                </div>
              </div>



            <div class="no-padding-left row col-lg-12 mb-2 inputDateFrom">
                <div class="red-alert d-lg-block d-md-none d-sm-none d-none error_message"></div>
            </div>

        </div>
        <div class="appointment-waittime">
            <!--<ng-container *ngIf="noofpage > 1" >
              <div class="text-center">
                  <a href="javascript:void(0);" class="btn btn-rtn btn-sm text-uppercase" (click)="preslide()">Prev</a>
                  <span>{{ curpage }}/ {{ noofpage }}</span>
                  <a href="javascript:void(0);" class="btn btn-rtn btn-sm text-uppercase" (click)="nextslide()">Next</a>
              </div>
            </ng-container>  
            <div id="groupchartbody"></div>

            <ng-container *ngIf="noofpage == 0 && isSubmitted">
              <p>No data found</p>
            </ng-container>--> 
            
            <ng-container *ngFor="let pages of noofpageArr">
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="title"> Page : {{ pages + 1}}</h3>
                    <p class="wttimetooltip">(*) - Patient Waiting Room Time > 20</p>  
                    <div id="groupchartbody_{{pages}}"></div>              
                  </div>
                </div>          
            </ng-container> 

            <div class="row" *ngIf="charttotaldata.length > 0">
                <div class="col-lg-12">
                    <h3 class="title"> Total Patient Waiting Room Time / Planned Duration / Total Actual Duration</h3>                  
                    <div id="totalChartbar"></div>              
                </div>
            </div>
            
            <ng-container *ngIf="noofpage == 0 && isSubmitted">
                <p>No data found</p>
            </ng-container>
            
        </div>
    </div>
</div>