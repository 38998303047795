<!--<rtn-provider-header></rtn-provider-header>-->
<div class="provider-profile">
  <div class="col-md-10 col-md-offset-1 col-lg-6 col-lg-offset-3 no-padding-left-right">
      <h1 class="head-title">{{practiceDetail.name}}</h1>
      <div class="head-content">{{fullAddress}}</div>
      <div class="horizontal-divider"></div>
      <div class="contact-info">
          <div class="col-12 px-0">
              <div class="row col-12 pl-0 contact-block-title">BILLING INFO</div>
              <div class="row col-12 pl-0 padding-side-v-5">
                  <div class="title pull-left">Full Name</div>
                  <div class="content pull-right">{{billingInfo.name_display}}</div>
              </div>
              <div class="row col-12 pl-0 padding-side-v-5">
                  <div class="title pull-left">Phone Number</div>
                  <div class="content pull-right">{{billingInfo.phone_mobile}}</div>
              </div>
              <div class="row col-12 pl-0 padding-side-v-5">
                  <div class="title pull-left">Email Address</div>
                  <div class="content pull-right">{{billingInfo.email}}</div>
              </div>
              <div class="row col-12 pl-0 padding-side-v-5">
                  <div class="title pull-left">Address</div>
                  <div class="content pull-right" *ngIf="billingInfo.address">{{billingInfo.address}}</div>
                  <div class="col-12 content pull-right" *ngIf="billingInfo.address2">{{billingInfo.address2}}</div>
                  <div class="col-12 content pull-right">{{billingInfo.city}}<span *ngIf="showComma">,</span> {{ billingInfo.state_code }} {{ billingInfo.zip }}</div>
              </div>
          </div>
      </div>
      <div class="note-info">
          <div class="col-12 px-0">
              <div class="row col-12 pl-0 note-block-title">NOTE</div>
              <pre class="row col-12 pl-0 note-block-content" [innerHtml]="note"></pre>
          </div>
      </div>
      <div class="clear-side-v-5 visible-xs-block visible-sm-block"></div>
      <div class="horizontal-divider"></div>
      <div class="card-default">
          <div class="card-header accepted-insurance-header">
              <h3 class="card-title text-title-form-signup">Accepted Insurance</h3>
          </div>
          <div class="card-body accepted-insurance">
              <div *ngFor="let plan of practiceDetail.insurances_name; let i = index" class="col-12 pl-0 pr-0">
                  {{plan}}
              </div>
          </div>
      </div>
      <div class="col-12 pl-0">
          <!-- <a type="button" class="btn btn-rtn btn-90 btn-bottom-page margin-top-40 text-uppercase" [routerLink]="['/provider/practice', 'edit']">EDIT</a> -->
          <div class="btn-90 mt-3">
            <button class="container btn btn-rtn text-uppercase btn-90 px-1"
              type="submit" (click)="edit()">EDIT</button>
          </div>
      </div>
  </div>
</div>
