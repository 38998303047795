<div class="provider-edit">
  <div class="px-0 col-xs-12 col-lg-12 col-md-12 list-provider-of-managing-user">
      <h3 class="pl-3 text-header-form-edit">
          List Apt. Types
      </h3>
      <rtn-table #table
          (onDataChange)="onDataChange($event)"
          [autoloading]="false"
          [data]="accountList.data"
          [rowsOnPage]="accountList.rowsOnPage"
          [numberOfPages]="accountList.numberOfPages">
          <div class="row">
              <rtn-table-filter #filter class="col-lg-12 px-0">
                  <form (ngSubmit)="onSearchSubmited(table)" class="row">
                      <div class="col-7 pl-0 pr-0">                          
                          <div class="btn-90">
                            <button class="btn btn-rtn"
                              type="button" (click)="addApttypesComponent.open(0)">Add</button>
                          </div>
                      </div>
                      <div class="row col-5 pl-0 pr-0">
                          <div class="col-9 pl-0 pr-0">
                              <input class="col-12 form-control margin-left-15" type="text" placeholder="Search" [(ngModel)]="searchText" name="something">
                          </div>
                          <div class="col-3 pl-0 pr-0">
                              <button type="submit" class="btn btn-rtn btn-180 pull-right">
                                  <span class="fa fa-search" aria-hidden="true"></span>
                                  <span class="label">&nbsp;&nbsp;Search</span>
                              </button>
                          </div>
                      </div>
                  </form>
              </rtn-table-filter>

              <div class="col-md-12 no-padding-left-right search-box listview panel-table">
                  <table class="table table-bordered">
                      <thead class="account-thead">
                          <tr>
                              <th class="account-thead-text col-email">Code</th>
                              <th class="account-thead-text col-full-name">Label</th>
                              <th class="account-thead-text col-status">Skipped Apt.</th>
                              <th class="account-thead-text col-action"></th>
                          </tr>
                      </thead>

                      <tbody class="account-tbody">
                          <tr *ngFor="let item of table.data; let i = index">
                                <td class="account-email">{{ item.code }}</td>
                                <td class="account-full-name">{{ item.label }}</td>                              
                                <td class="account-status">
                                  <span *ngIf="item.is_allowed == 1">No</span>
                                  <span *ngIf="item.is_allowed == 0">Yes</span>
                                </td>
                                <td class="account-action text-center">
                                    <button type="button" class="btn btn-rtn" (click)="addApttypesComponent.open(item)">
                                        <span class="label">Edit</span>
                                    </button>
                                </td>
                          </tr>
                          <tr *ngIf="countData === 0" class="text-center">
                              <td [attr.colspan]="4" class="no-provider">There is no apt types</td>
                          </tr>
                      </tbody>

                      <tfoot *ngIf="table.numberOfPages > 1">
                          <tr>
                              <td [attr.colspan]="12" class="text-center table-responsive no-overflow-y">
                                  <div class="page-footer">
                                      <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                  </div>
                              </td>
                          </tr>
                      </tfoot>
                  </table>
              </div>
          </div>
      </rtn-table>
  </div>
</div>

<rtn-modals-app-add-apttypes></rtn-modals-app-add-apttypes>