import {Component, NgModule, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
// import {ModalComponent} from '@app/shared/modal/modal.component';
import {Subscription} from 'rxjs';
import { AdminRestService } from './../../admin.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {Utils} from '@app/shared/utils';
import {ActivatedRoute} from '@angular/router';
import {forEach} from '@angular/router/src/utils/collection';
import {SystemData} from '@app/shared/system/system-data';
import {ActiveModuleModalComponent} from '@app/admin/practice/active-module/modals/active-module-modal.component';
import { AlertService } from '@app/shared/alert';

@Component({
  templateUrl: './active-module.component.html',
  styleUrls: ['../../admin.component.scss','./active-module.component.scss'],
})
export class ActiveModuleComponent implements OnInit {

  @ViewChild(ActiveModuleModalComponent) activeModuleDirective: ActiveModuleModalComponent;

  isChanged: any = false;
  page: any = 1;
  runRequest: any = true;
  param: any;
  modulesActivatedState: any;
  moduleName: string;
  moduleId: number;
  practiceId: string;
  status: boolean;
  activeModuleSubcriber: Subscription;

  constructor(
      // private activeModule: ActiveModuleComponent,
      private restService: AdminRestService,
      private _loadingIndicatorService: LoadingIndicatorService,
      public _utils: Utils,
      private route: ActivatedRoute,
      public _systemData: SystemData,
      private _alertService: AlertService
  ) {
    var self = this;
    self._alertService.onClose = () => {
      self.saveActivatedModule();
    };
    self._alertService.onDismiss = () => {
      self._systemData.moduleList.data[self.getModuleIndex(self.moduleName)].isActivated = !self._systemData.moduleList.data[self.getModuleIndex(self.moduleName)].isActivated;
    }
    self._alertService.showDismissButton = true;
    self._alertService.showCloseButton = true;
    self._alertService.closeButtonLabel = 'OK';
    // self._alertService.title = "Warning";
  }

  init() {
    this._systemData.moduleList.data = new Array();
    this._systemData.moduleList.numberOfPages = 0;
    this._systemData.moduleList.rowsOnPage = 10;
  }

  ngOnInit() {
    this.init();
    this.param = this.route.snapshot.paramMap.get('id');
    this.getAllModule(this.page);
  }

  onCheckbox(moduleName: string,moduleId: number,status: boolean) {
    this._systemData.moduleList.data[this.getModuleIndex(moduleName)].isActivated = !this._systemData.moduleList.data[this.getModuleIndex(moduleName)].isActivated;
    this.moduleName = moduleName;
    this.moduleId = moduleId;
    this.practiceId = this.route.snapshot.paramMap.get('id');
    this.status = status;
    if(status){
      this._alertService.title='';
      this._alertService.message = 'Do you want to ACTIVE module ' + moduleName + ' for this practice?';
    }else{
      this._alertService.title='';
      this._alertService.message = 'Do you want to DEACTIVE module ' + moduleName + ' for this practice?';
    }
    this._alertService.emitter('open');
  }

  onDataChange(e: any) {
    this.page = e['currentPage'];
    this.getAllModule(this.page);
  }

  getModuleIndex(moduleName: string) {
    return this._systemData.moduleList.data.findIndex(function (item: any) {
      return item.name === moduleName;
    });
  }

  getAllModule(page: number) {
    this._loadingIndicatorService.start();
    if (this.activeModuleSubcriber) {
      this.activeModuleSubcriber.unsubscribe();
    }
    this.activeModuleSubcriber = this.restService.loadAllModule(this.param).subscribe(
        (success: any) => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
          if (success.error_code === 'RC000') {
            this._systemData.moduleList.numberOfPage = success.last_page || 0;
            const modules = success.modules || [];
            this._systemData.moduleList.data = modules;
          }
        },
        (error: any) => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
        },
        () => {
          this.runRequest = true;
          this._loadingIndicatorService.stop();
        }
    );
  }

  saveActivatedModule() {
    this._loadingIndicatorService.start();
    if (this.activeModuleSubcriber) {
      this.activeModuleSubcriber.unsubscribe();
    }
    this.activeModuleSubcriber = this.restService.saveActiveModule({module_id: this.moduleId,practice_id: this.practiceId, status: this.status})
    .subscribe((success: any) => {
        console.log(success);
        // this.runRequest = true;
        // this._loadingIndicatorService.stop();
        // for (const module of this._systemData.checkList) {
        //   this._systemData.modulesActivatedState[module.id - 1].state = true;
        // }
        // console.log(this._systemData.modulesActivatedState);
      },
      (error: any) => {
        this._systemData.moduleList.data[this.getModuleIndex(this.moduleName)].isActivated = !this._systemData.moduleList.data[this.getModuleIndex(this.moduleName)].isActivated;
        this.runRequest = true;
        this._loadingIndicatorService.stop();
      },
      () => {
        this.runRequest = true;
        this._loadingIndicatorService.stop();
      }
    );
  }
}
