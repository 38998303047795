<modal #Add_waitlist class="appointment-new">
    <form #modalForm="ngForm" class="form">
        <div class="form-content">
            <div class="form-header">Add patient to Waitlist</div>
            <div class="form-info">
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div class="col-lg-2 col-md-2 col-2 form-input-label input-label-required px-0">Patient</div>
                    <div class="row col-10 search pr-0">
                        <div class="input-group row col-12 px-0">
                            <input class="form-control mt-1" id="search-field-new-appointment"
                                [class.input-error]="errorResponse.patient != ''" type="text"
                                placeholder="Enter Name or Phone Number" [(ngModel)]="searchOpenings.patient"
                                (keyup)="onKeyUp()" autocomplete="off" [ngModelOptions]="{standalone: true}">
                            <div class="input-group-append">
                                <button class="search-button dropdown-toggle mt_4" [class.disabled]="searchDisabled"
                                    [disabled]="searchDisabled" (click)="searchPatientClicked($event)" role="button"
                                    aria-haspopup="true" aria-expanded="false">
                                    <span aria-hidden="true" class="fas fa-search"></span>
                                </button>
                            </div>
                            <div class="result dropdown-menu">
                                <div class="error-message">{{ errorMessage }}</div>
                                <div *ngFor="let p of patients" class="patient-info">
                                    <div *ngIf="p.phone_mobile == null"
                                        (click)="getInfoByNameClicked($event,p.id, p.first_name, p.last_name, p.dob, p.is_family, p.uuid, p.family_member_id)">
                                        <span>{{ p.first_name }}</span>
                                        <span>{{ p.last_name }}</span>
                                        <span>- {{ p.dob }}</span>
                                    </div>
                                    <div *ngIf="p.phone_mobile != null"
                                        (click)="getInfoByNumberClicked($event,p.id, p.phone_mobile, p.first_name, p.last_name, p.uuid)">
                                        <span>{{ p.phone_mobile }}</span>
                                        <span>{{ p.first_name }}</span>
                                        <span>{{ p.last_name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-12 px-0">
                            <div class="message-error">{{ errorResponse.patient }}</div>
                        </div>
                    </div>
                </div>

                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-md-2 col-md-offset-1 no-padding-right input-label-required find-appointment-line-title display-flex zip-code px-0">
                        ZIP Code
                    </div>
                    <div class="col-10 pr-0">
                        <input class="form-control mt-1" id="input-zip" type="text" placeholder="Enter ZIP"
                            [(ngModel)]="searchOpenings.zip" [ngModelOptions]="{standalone: true}"
                            (keyup)="onChange('zip')" simplemask [format]="'#####-####'" [target]="searchOpenings"
                            [field]="'zip'" [class.input-error]="errorResponse.zip != ''" maxlength="10">
                        <div class="message-error">{{ errorResponse.zip }}</div>
                    </div>

                </div>

                <!--<div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-md-2 col-md-offset-1 no-padding-right find-appointment-line-title display-flex px-0">
                        Distance</div>
                    
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right">
                                <select class="form-control mt-1" [(ngModel)]="searchOpenings.distance"
                                    [ngModelOptions]="{standalone: true}" id="distance">
                                    <option *ngFor="let option of searchOpeningsDistance" [value]="option.value">
                                        {{ option.text }}</option>
                                </select>
                                <div class="cursorPointer selecter secondary-select mt-1">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>                            
                        </div>
                        <div class="col-6 pr-0">
                                                   
                        </div>                        
                    </div>
                </div>-->
                

                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-xs-2 input-label-required no-padding-right display-flex line-height-30 mt-1 px-0">
                        Date
                    </div>
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <div class="input-group">
                                    <input type="text" #inputStartDateSaveSettingSearch
                                        [class.input-error]="errorResponse.start_date != '' || error_invalid_date != ''"
                                        class="form-control calender-input bc-white" id="startDate" disabled
                                        placeholder="From" [(ngModel)]="searchOpenings.start_date_display"
                                        [ngModelOptions]="{standalone: true}">
                                    <div class="input-group-append" #clickShowStartDateSaveSettingSearch>
                                        <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.start_date }}</div>
                        </div>

                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <div class="input-group">
                                    <input type="text" #inputEndDateSaveSettingSearch
                                        [class.input-error]="errorResponse.end_date != '' || error_invalid_date != ''"
                                        class="form-control calender-input bc-white" id="endDate" disabled
                                        placeholder="To" [(ngModel)]="searchOpenings.end_date_display"
                                        [ngModelOptions]="{standalone: true}">
                                    <div class="input-group-append" #clickShowEndDateSaveSettingSearch>
                                        <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="message-error">{{ errorResponse.end_date }}</div>
                        </div>
                        <div class="col-12 pr-0 message-error">
                            {{error_invalid_date}}
                        </div>

                    </div>
                </div>

                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div class="col-2 col-xs-2 no-padding-right display-flex line-height-30 px-0">Time</div>
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <select class="form-control select-time-save-setting" (change)="onChange('startTime')"
                                    id="startTime"
                                    [class.input-error]="errorResponse.start_time != '' || error_invalid_time != ''">
                                    <option value='' selected>From</option>
                                    <option class="select-option" *ngFor="let time of _systemData.arrayTimes"
                                        [value]="time.value">
                                        {{ time.text }}</option>
                                </select>
                                <div class="cursorPointer selecter-save-block">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.start_time }}</div>
                        </div>
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <select class="form-control select-time-save-setting" id="endTime"
                                    (change)="onChange('endTime')"
                                    [class.input-error]="errorResponse.end_time != '' || error_invalid_time != ''">
                                    <option value='' selected>To</option>
                                    <option class="select-option" *ngFor="let time of _systemData.arrayTimes"
                                        [value]="time.value">
                                        {{ time.text }}</option>
                                </select>
                                <div class="cursorPointer selecter-save-block">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.end_time }}</div>
                        </div>
                        <div class="col-12 pr-0 message-error">
                            {{error_invalid_time}}
                        </div>
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-md-2 col-md-offset-1 no-padding-right find-appointment-line-title display-flex px-0">
                        Availability</div>
                    
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-12 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right">
                                <select class="form-control mt-1" [(ngModel)]="searchOpenings.advance_notice_duration"
                                    [ngModelOptions]="{standalone: true}" id="advance_notice_duration" (change)="onChange('advance_notice_duration')">
                                    <option *ngFor="let option of advancenoticedurations" [value]="option.code">
                                        {{ option.label }}</option>
                                </select>
                                <div class="cursorPointer selecter secondary-select mt-1">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>    
                                                  
                        </div>
                           
                        <div class="row col-12 pr-0">
                            <label class="rangetooltip">How much advance notice do you need to book the appointment and show up on time after receiving a notification</label>
                            <label class="message-error">{{ errorResponse.advance_notice_duration }}</label>
                        </div>                    
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-md-2 col-md-offset-1 no-padding-right find-appointment-line-title display-flex input-label-required px-0">
                        Office</div>
                   
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-12 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right">
                                
                                
                                <select class="form-control select-time-save-setting"
                                 id="provider_office_id"  [(ngModel)]="searchOpenings.provider_office_id" [ngModelOptions]="{standalone: true}" (change)="onChangeProviderOffice(); onChange('provider_office_id')">
                                    <option value='' selected>Select</option>
                                    <option class="select-option" *ngFor="let list of providerOfficeLists"
                                            [value]="list.id">{{ list.location_name }}</option>
                                </select>
                                <div class="cursorPointer selecter secondary-select mt-1">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>   
                            <div class="message-error">{{ errorResponse.provider_office_id }}</div>                         
                        </div>
                                               
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-md-2 col-md-offset-1 no-padding-right find-appointment-line-title display-flex px-0 input-label-required px-0">
                        Apt. Type</div>
                   
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-6 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right">
                                
                                
                                <select class="form-control select-time-save-setting"
                                 id="apt_type"  [(ngModel)]="searchOpenings.apt_type" [ngModelOptions]="{standalone: true}" (change)="onChange('apt_type')">
                                    <option value='' selected>Select</option>
                                    <option class="select-option" *ngFor="let list of appointmentTypeList"
                                            [value]="list.id">{{ list.label }}</option>
                                </select>
                                <div class="cursorPointer selecter secondary-select mt-1">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>   
                            <label class="message-error">{{ errorResponse.apt_type }}</label>                         
                        </div>
                                               
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div
                        class="col-2 col-md-2 col-md-offset-1 no-padding-right find-appointment-line-title display-flex px-0">
                        Appt. Reason</div>
                    
                    <div class="row col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-12 pr-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right">
                                <textarea id="appointment_reason" name="appointment_reason" [(ngModel)]="searchOpenings.appointment_reason" class="form-control"></textarea>
                            </div>                            
                        </div>
                        <div class="col-6 pr-0">
                                                   
                        </div>                        
                    </div>
                </div>
                

                <div class="row col-12 col-xs-12 no-padding-left-right pt_5">
                    <div class="col-2 col-xs-2 line-height-30 no-padding-left-right checkbox-info px-0">
                        <div class="input-rtn display-flex">
                            <input class="checkbox check-single" type="checkbox" id="searchOpenings-email"
                                [(ngModel)]="searchOpenings.enable_email" [ngModelOptions]="{standalone: true}"
                                (click)="removeErrEmail()">
                            <label for="searchOpenings-email" class="selectorExtreme rtn-text-grey-85"></label>
                            <span class="checkbox-text">Email:</span>
                        </div>
                    </div>
                    <div class="col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="col-xs-12 col-sm-5 col-lg-12 pr-0">
                            <input class="form-control" type="text" [(ngModel)]="searchOpenings.email" id='emailInput'
                                [disabled]="!searchOpenings.enable_email" (change)="onChange('email')"
                                [ngModelOptions]="{standalone: true}"
                                [class.input-error]="errorResponse.email != '' && searchOpenings.enable_email == 1"
                                (keyup)="onChange('email')">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-12 message-error">{{ errorResponse.email }}</div>
                    </div>
                </div>


                <div class="row col-12 col-xs-12 no-padding-left-right pt_10">
                    <div class="col-2 col-xs-2 line-height-30 no-padding-left-right checkbox-info px-0">
                        <div class="input-rtn display-flex">
                            <input class="checkbox check-single" type="checkbox" id="searchOpenings-sms"
                                [(ngModel)]="searchOpenings.enable_sms" [ngModelOptions]="{standalone: true}" (click)="removeErrPhone()">
                            <label for="searchOpenings-sms" class="selectorExtreme rtn-text-grey-85"></label>
                            <span class="checkbox-text">
                                <span class="hidden-xs">Text/</span>
                                SMS:
                            </span>
                        </div>
                    </div>
                    <div class="col-10 pr-0 col-xs-10 no-padding-left-right">
                        <div class="row col-12 pr-0">
                            <div class="row col-11 pl-0" *ngIf="loadIconVerifiedPhone">
                                <rtn-mobile-number [formInput]="searchOpenings"
                                    [isDisabled]="!searchOpenings.enable_sms" [error]="errorResponse.mobile != '' && searchOpenings.enable_sms"
                                    (keyup)="onChange('mobile')">
                                </rtn-mobile-number>
                            </div>
                            <div class="row col-12 px-0" *ngIf="!loadIconVerifiedPhone">
                                <rtn-mobile-number [formInput]="searchOpenings"
                                    [isDisabled]="!searchOpenings.enable_sms" [error]="errorResponse.mobile != '' && searchOpenings.enable_sms"
                                    (keyup)="onChange('mobile')">
                                </rtn-mobile-number>
                            </div>
                            <div class="message-error">{{ errorResponse.mobile }}</div>
                            <div class="row col-1 col-md-1 col-xs-1 no-padding-right" *ngIf="loadIconVerifiedPhone">
                                <i *ngIf="verifiedPhone" class="fa fa-check phone-verified height_18" aria-hidden="true"
                                    title="Confirmed"></i>
                                <i *ngIf="!verifiedPhone" class="fa fa-times phone-unverified height_18" aria-hidden="true"
                                    title="Not confirmed yet"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button height_76">
            <button type="button" class="btn btn-rtn btn-90 float-right  ml-2" (click)="processSaveSearch()" autofocus>Save
                    </button>
            <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel float-right" data-dismiss="modal"
                (click)="dismiss()">Cancel
            </button>
            <span class="message-success">{{ successSaveOpeningSearch }}</span>
            <span class="message-error">{{ errorSaveOpeningSearch }}</span>
        </div>
    </form>
</modal>