import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from '@app/core';

import {AppointmentsRoutingModule} from './appointments-routing.module';
import {AppointmentListComponent} from './appointment-list/appointment-list.component';
import {AppointmentPreferencesComponent} from './appointment-preferences/appointment-preferences.component';
import {SearchOpeningsComponent} from './wait-list/search-openings.component';
import {TimelineTooltipComponent} from '@app/appointments/timeline-tooltip/timeline-tooltip.component';
import {SharedModule} from '@app/shared';
import {AppointmentBookComponent} from './modals/appointment-book/appointment-book.component';
import {AvailableSlotComponent} from './modals/available-slot/available-slot.component';
import {ApointmentCheckInComponent} from './modals/apointment-check-in/apointment-check-in.component';
import {TimelineComponent} from '@app/appointments/timeline/timeline.component';
import {AppointmentCheckOutComponent} from '@app/appointments/modals/appointment-check-out/appointment-check-out.component';
import {AppointmentEditComponent} from '@app/appointments/modals/appointment-edit/appointment-edit.component';
import {AppointmentCancelComponent} from '@app/appointments/modals/appointment-cancel/appointment-cancel.component';
import {AppointmentCancelCheckInComponent} from '@app/appointments/modals/appointment-cancel-check-in/appointment-cancel-check-in.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppointmentDetailComponent} from './modals/appointment-detail/appointment-detail.component';
import {RescheduleComponent} from './modals/reschedule/reschedule.component';
import {WaitTimeModule} from '@app/wait-time/wait-time.module';
import {BookAppointmentComponent} from './book-component/book-component.component';
import {ProvidersModule} from '@app/providers/providers.module';
import { HeaderComponent } from './header/header.component';
import { NotificationsModule } from '@app/notifications/notifications.module';
import { AppointmentsComponent } from './appointments.component';
import { AppointmentBookWaitlistComponent } from './modals/appointment-book-waitlist/appointment-book-waitlist.component';
import { TimlineMassNotificationComponent } from './modals/timline-mass-notification/timline-mass-notification.component';
import { AddWaitlistComponent } from './modals/add-waitlist/add-waitlist.component';
import { NotifyAvailableSlotWaitlistComponent } from './modals/notify-available-slot-waitlist/notify-available-slot-waitlist.component';
import { EditWaitlistComponent } from './modals/edit-waitlist/edit-waitlist.component';
//import { PatientFutureAppointmentComponent } from '@app/appointments/modals/patient-future-appointment/patient-future-appointment.component';
import {AppointmentNotificationComponent} from '@app/appointments/modals/appointment-notification/appointment-notification.component';


@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    AppointmentsRoutingModule,
    SharedModule,
    WaitTimeModule,
    ProvidersModule,
    NotificationsModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    AppointmentListComponent,
    AppointmentPreferencesComponent,
    SearchOpeningsComponent,
    TimelineComponent,
    TimelineTooltipComponent,
    AppointmentBookComponent,
    AvailableSlotComponent,
    ApointmentCheckInComponent,
    AppointmentCheckOutComponent,
    AppointmentEditComponent,
    AppointmentCancelComponent,
    AppointmentCancelCheckInComponent,
    AppointmentDetailComponent,
    RescheduleComponent,
    BookAppointmentComponent,
    HeaderComponent,
    AppointmentsComponent,
    AppointmentBookWaitlistComponent,
    TimlineMassNotificationComponent,
    AddWaitlistComponent,
    NotifyAvailableSlotWaitlistComponent,
    EditWaitlistComponent,
    AppointmentNotificationComponent,
    
    ],
  exports: [
    TimelineComponent,
    TimelineTooltipComponent,
    ApointmentCheckInComponent,
    AppointmentCheckOutComponent,
    AppointmentEditComponent,
    AppointmentCancelComponent,
    AppointmentCancelCheckInComponent,
    AvailableSlotComponent,
    AppointmentDetailComponent,
    BookAppointmentComponent,
    HeaderComponent,
    AppointmentNotificationComponent  
  ]
})
export class AppointmentsModule {
}
