<div class="marketing-page container activate-user">
    <div class="content-header row" [style.min-height.px]="minHeight">
        <div class="activate-content col-12">
            <div class="row mt-5">
                <div class="col-3"></div>
                <div *ngIf="!linkIsValid" class="alert alert-danger col-6 offset-xs-3">
                    {{errorMessage}}
                </div>
                <div class="col-3"></div>
            </div>            
            <div *ngIf="linkIsValid" class="active-body">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="patient-setting col-8 pl-0 pr-0">
                        <h1 class="active-headline text-center">Thanks for joining Real Time Neighborhood.</h1>
                        <div class="success-body text-center">
                            If you no longer wish to receive notification from Real Time Neighborhood. Please update your notification preferences.         
                        </div>
                        <form novalidate #form="ngForm" (ngSubmit)="updateNotificationSettingForm(form, notificationSetting)">
                            <div class="row">
                                <div class="col-1">&nbsp;</div>
                                <div class="text-header col-10">
                                    <span class="col-12 pl-0">Notifications</span>
                                </div>
                                <div class="col-1">&nbsp;</div>
                            </div>
                            <div class="row">
                                <div class="col-1">&nbsp;</div>
                                <div class="col-10 block-setting-style">
                                    <div class="row col-12 ml-0">
                                        <div class="col-1 setting-style input-rtn pull-left">
                                            <input class="checkbox check-single" type="checkbox" name="settingEmail" id="settingEmail" [value]="notificationSetting.is_email"
                                                [(ngModel)]="notificationSetting.is_email" (change)="removeMessage()">
                                            <label class="selectorExtreme" for="settingEmail"></label>
                                        </div>
                                        <div class="col-11 setting-style no-padding-left-right">Email Message ({{ notificationSetting.email }})</div>
                                    </div>
                                    <div class="row col-12 ml-0">
                                        <div class="col-xs-2 col-sm-1 col-md-1 setting-style input-rtn pull-left">
                                            <input class="checkbox check-single" type="checkbox" name="settingSms" id="settingSms" [value]="notificationSetting.is_sms"
                                                [(ngModel)]="notificationSetting.is_sms" [disabled]="disabledSms" (change)="removeMessage()">
                                            <label class="selectorExtreme" for="settingSms"></label>
                                        </div>
                                        <div class="col-xs-10 col-sm-11 col-md-11 setting-style no-padding-left-right">SMS / Text Message ( {{notificationSetting.mobile_number}} )</div>
                                    </div>
                                    <div class="col-12 border-line setting-style"></div>

                                    <div class="row col-12 ml-0">
                                        <div class="col-1 setting-style input-rtn pull-left">
                                            <input class="checkbox check-single" type="checkbox" name="settingWaitTime" id="settingWaitTime" [value]="notificationSetting.alert_waittime"
                                                [(ngModel)]="notificationSetting.alert_waittime" (change)="removeMessage()">
                                            <label class="selectorExtreme" for="settingWaitTime"></label>
                                        </div>
                                        <div class="col-11 setting-style no-padding-left-right">Yes, I'd like to receive wait-time and scheduling alerts (when enabled by Provider)</div>
                                    </div>
                                    <div class="row col-12 ml-0">
                                        <div class="col-1 setting-style input-rtn pull-left">
                                            <input class="checkbox check-single" type="checkbox" name="settingReminder" id="settingReminder" [value]="notificationSetting.is_reminder"
                                                [(ngModel)]="notificationSetting.is_reminder" (change)="removeMessage()">
                                            <label class="selectorExtreme" for="settingReminder"></label>
                                        </div>
                                        <div class="col-11 setting-style no-padding-left-right">Yes, I'd like to receive a reminder message before my scheduled appointments (when enabled by Provider)</div>
                                    </div>
                                    <div class="col-xs-7 col-sm-6 col-md-3 input-select-width">
                                        <select class="custom-select select-day-style" [value]="notificationSetting.reminder_day" [(ngModel)]="notificationSetting.reminder_day"
                                            [disabled]="notificationSetting.is_reminder == 0" (change)="removeMessage()" name="something">
                                            <option *ngFor="let time of timeSetting" [value]="time.value">{{time.name}}</option>
                                        </select>
                                        <!--<div class="input-group-select-icon">-->
                                            <!--<i class="fa fa-caret-down"></i>-->
                                        <!--</div>-->
                                    </div>
                                    <!--<div class="row col-12 ml-0">
                                        <div class="col-1 setting-style input-rtn pull-left">
                                            <input class="checkbox check-single" type="checkbox" name="settingWaitlist" id="settingWaitlist" [value]="notificationSetting.alert_waitlist"
                                                [(ngModel)]="notificationSetting.alert_waitlist" (change)="removeMessage()">
                                            <label class="selectorExtreme" for="settingWaitlist"></label>
                                        </div>
                                        <div class="col-11 setting-style no-padding-left-right">Yes, I'd like to receive waitlist notification(when enabled by Provider)</div>
                                    </div>-->
                                    <div class="row col-lg-12 col-xs-12 col-sm-12 col-md-12 setting-style padding-top-15">
                                        <button type="submit" class="btn btn-rtn btn-90 text-uppercase">SAVE</button>
                                        <span *ngIf="messageResponseSuccess" class="message-success my-auto pl-2">{{messageResponseSuccess}}</span>
                                        <span *ngIf="messageResponseError" class="message-error my-auto pl-2">{{messageResponseError}}</span>
                                    </div>
                                </div>
                                <div class="col-1">&nbsp;</div>
                            </div>
                            
                        </form>
                    </div>
                    <div class="col-2"></div>
                </div>
                
                
            </div>
        </div>
    </div>
</div>