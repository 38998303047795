<div class="provider-edit">
  <div class="px-0 col-xs-12 col-md-12 col-sm-12 list-provider-of-managing-user">
      <h3 class="pl-3 text-header-form-edit">
          <a class="no-focus" [routerLink]="['/provider', 'user']" *ngIf="isSuperUser">
              <span class="fa fa-angle-left style-top-arrow"></span>
          </a>
          Providers under {{managingName}}'s Management
      </h3>
      <rtn-table #table
          (onDataChange)="onDataChange($event)"
          [autoloading]="false"
          [data]="accountList.data"
          [rowsOnPage]="accountList.rowsOnPage"
          [numberOfPages]="accountList.numberOfPages">
          <div class="row">
              <rtn-table-filter #filter class="col-lg-12 px-0">
                  <form (ngSubmit)="onSearchSubmited(table)" class="row">
                      <div class="col-7 pl-0 pr-0">
                          <button type="button" [routerLink]="['/provider','mapping', idSelected]" class="btn btn-rtn" *ngIf="isSuperUser">
                              <span class="label mx-2">Mapping Provider</span>
                          </button>
                      </div>
                      <div class="row col-5 pl-0 pr-0">
                          <div class="col-8 pl-0 pr-0">
                              <input class="col-12 form-control margin-left-15" type="text" placeholder="Enter Email or Name" name="searchText" [(ngModel)]="searchText">
                          </div>
                          <div class="col-4 pl-0 pr-0">
                            <button type="button" class="btn btn-rtn btn-180 pull-right ml-1" (click)="reloadProviderList()">
                                <span class="fa fa-refresh" aria-hidden="true"></span>
                                <span class="label">&nbsp;&nbsp;Reload</span>
                            </button>
                              <button type="submit" class="btn btn-rtn btn-180 pull-right">
                                  <span class="fas fa-search mr-2" aria-hidden="true"></span>
                                  <span class="label mr-2">Search</span>
                              </button>
                          </div>
                      </div>
                  </form>
              </rtn-table-filter>

              <div class="col-sm-12 no-padding-left-right search-box listview panel-table">
                  <table class="table table-bordered">
                      <thead class="account-thead">
                          <tr>
                              <th class="account-thead-text col-email">Email</th>
                              <th class="account-thead-text col-full-name">Full Name</th>
                              <th class="account-thead-text col-phone">Phone Mobile</th>
                              <th class="account-thead-text col-address">Address</th>
                              <th class="account-thead-text col-specialties">Specialties</th>
                              <th class="account-thead-text col-status">Status</th>
                              <th class="account-thead-text col-status">Wait Time</th>
                              <th class="account-thead-text col-action"></th>
                          </tr>
                      </thead>

                      <tbody class="account-tbody">
                          <tr *ngFor="let item of table.data; let i = index">
                              <td class="account-email">{{ item.email }}</td>
                              <td class="account-full-name">{{ item.full_name }}</td>
                              <td class="account-phone">{{ item.phone_mobile }}</td>
                              <td class="account-address">{{ item.full_address }}</td>
                              <td class="account-specialties">{{ item.specialties }}</td>
                              <td class="account-status">{{ item.status_show }}</td>
                              <td class="account-status">{{ item.waittime }}</td>
                              <td class="account-action text-center">
                                  <button type="submit" class="btn btn-rtn" (click)="startManaging(item.id)" [class.btn-disabled]="item.status !== 3" [disabled]="item.status !== 3">
                                      <span class="label">Start Managing</span>
                                  </button>
                              </td>
                          </tr>
                          <tr *ngIf="countData === 0" class="text-center">
                              <td colspan="7" class="no-provider">There is no provider</td>
                          </tr>
                      </tbody>

                      <tfoot *ngIf="table.numberOfPages > 1">
                          <tr>
                              <td colspan="12" class="text-center table-responsive no-overflow-y">
                                  <div class="page-footer">
                                      <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                  </div>
                              </td>
                          </tr>
                      </tfoot>
                  </table>
              </div>
          </div>
      </rtn-table>
  </div>
</div>
