import {Component, Output, EventEmitter, OnInit, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {RestService} from '@app/core/rest.service';
import {NotificationsService} from '@app/notifications/notifications.service';

// import { ModalsService } ;

@Component({
  moduleId: module.id,
  selector: 'rtn-modals-delete-notification',
  templateUrl: './delete-notification.component.html',
  // providers: [ModalsService]
})

export class ModalsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('DeleteNotification_Modals') modal: ModalComponent;
  messageConfirmDelete = 'Are you sure you want to delete this notification?';

  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private Service: NotificationsService,
    private _loadingIndicatorService: LoadingIndicatorService
  ) {
    // TODO
  }

  // ========== Specific Modal Method ========== //

  deleteNotification(e: any) {
    this.onClick.emit(e);
  }

  // ========== Class Method ========== //

  ngOnInit() {
    // TODO
  }

  ngOnDestroy() {
    // TODO
  }

  ngAfterViewInit() {
    // TODO
  }


  // ========== General Modal Method ========== //

  open() {
    this.modal.open();
  }
}
