import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {RestService} from '@app/core/rest.service';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {SuperManagingUserDetail} from '@app/shared/models/superManagingUserDetail';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Utils} from '@app/shared/utils';
import {ProvidersService} from '@app/providers/providers.service';


@Component({
  moduleId: module.id,
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [RestService],
})
export class EditUserComponent implements OnInit, OnDestroy {
  superUserDetail: SuperManagingUserDetail = new SuperManagingUserDetail();
  responseError: SuperManagingUserDetail = new SuperManagingUserDetail();
  updateNewSuperUserSubscriber: Subscription;
  onGetUserSubscriber: Subscription;
  loadPracticeListSubscriber: Subscription;
  practiceList: any = [];
  successMessage: string;
  practice_id: number;

  constructor(
    public _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    private _resetService: ProvidersService,
    // private _routeParams: RouteParams,
    private _router: Router,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    if (this._utils.accessEditUser()) {
      this._loadingIndicatorService.start();
      this.onGetUserSubscriber = this._resetService.getUser().subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          this.superUserDetail = success.user;
          this.practice_id = success.user.practice_id;
          // this.superUserDetail.practice_id = success.practice_id;
          // Set format for phone
          this.superUserDetail.phone_mobile = this.superUserDetail.phone_mobile.replace('+1 ', '');
          this.superUserDetail.state_code = this.superUserDetail.state_code === null
            ? '' : this.superUserDetail.state_code.toUpperCase();
        }, (error: any) => {
          this._loadingIndicatorService.stop();
        });
      this.loadPracticeListSubscriber = this._resetService.getPracticeList().subscribe(
        (success1: any) => {
          this.practiceList = success1.practices;
        }
      );
    }
  }

  ngOnInit() {
    this._systemService.getStates();
  }

  ngOnDestroy() {
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
    if (this.updateNewSuperUserSubscriber) {
      this.updateNewSuperUserSubscriber.unsubscribe();
    }
    if (this.onGetUserSubscriber) {
      this.onGetUserSubscriber.unsubscribe();
    }
  }

  updateSuperUserForm(formVals: any) {
    const self = this;
    this.successMessage = null;
    this._loadingIndicatorService.start();
    let value = Object.assign({}, formVals);
    if (this.updateNewSuperUserSubscriber) {
      this.updateNewSuperUserSubscriber.unsubscribe();
    }
    value.practice_id = this.practice_id;
    this.updateNewSuperUserSubscriber = this._resetService.updateUser(value).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.successMessage = success.error_messages;
        this.responseError = new SuperManagingUserDetail();
        localStorage.setItem('userFullName',
          this._utils.generateFullName(value.first_name, value.middle_name, value.last_name));
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
        let res = error.error;
        this.responseError = res.error_fields;
      }
    );
  }

  onKeyUp(error: string) {
    switch (error) {
      case 'email':
        this.responseError['email'] = null;
        break;
      case 'phone_mobile':
        this.responseError['phone_mobile'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'first_name':
        this.responseError['first_name'] = null;
        break;
      case 'middle_name':
        this.responseError['middle_name'] = null;
        break;
      case 'last_name':
        this.responseError['last_name'] = null;
        break;
      case 'office_name':
        this.responseError['office_name'] = null;
        break;
    }
  }

}
