import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation,
    OnInit
} from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';

import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
import { Event, TimelineComponent } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { from, Subscription, Subscriber } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { AppointmentsService } from '@app/appointments/appointments.service';
//import { SearchOpeningsComponent } from '@app/appointments/wait-list/search-openings.component';
declare let jQuery: any;
import * as moment from 'moment';
import { SearchOpenings } from '@app/shared/models/search-openings';
import { SystemData } from '@app/shared/system';

@Component({
    selector: 'app-edit-waitlist',
    templateUrl: './edit-waitlist.component.html',
    styleUrls: ['./edit-waitlist.component.scss'],
})
export class EditWaitlistComponent implements OnInit, AfterViewInit, OnDestroy {

    
    searchPatientSubscriber: Subscription;
    getSavedOpeningSearchSubscriber: Subscription;
        
    isLock: any;
    currentDate: any = moment();
    msg: string = 'Your account has been locked.';    
    @ViewChild('Edit_waitlist') modal: ModalComponent;    
    @ViewChild('editinputStartDateSaveSettingSearch') editinputStartDateSaveSettingSearch: ElementRef;
    @ViewChild('editinputEndDateSaveSettingSearch') editinputEndDateSaveSettingSearch: ElementRef;
    @ViewChild('editclickShowStartDateSaveSettingSearch') editclickShowStartDateSaveSettingSearch: ElementRef;
    @ViewChild('editclickShowEndDateSaveSettingSearch') editclickShowEndDateSaveSettingSearch: ElementRef;
    @Output()
    reload: EventEmitter<any> = new EventEmitter();
    //eventEntry: Event;
    //dataDateBook: DataDateBook = new DataDateBook;
    errorResponse: SearchOpenings = new SearchOpenings();
    errorMessage: string;
    errorMessageBook: string;
    searchDisabled = true;
    patients: any = [];
    patient_id: number;
    uuid: any;
    patient_name: any;
    isFamily = false;
    //availableSlots: any = [];
    //selectedSlot = 0;
    public savedId: number;
    time: any;
    input_group: any = '.input-group';
    search_field: any = '#search-field-new-appointment';
    searchOpenings: SearchOpenings = new SearchOpenings();
    searchOpeningsDistance: any;
    loadIconVerifiedPhone = false;
    verifiedPhone = false;
    msgPartialAccess
        = 'Your account has not full access yet. Please update insurance or contact RTN admin to get full access account';
    msgPedingAccess = 'Your account has not been activated.';
    successSaveOpeningSearch = '';
    errorSaveOpeningSearch = '';
    saveSettingsSubscriber: Subscription;
    //messageResponseErrorSearchOpen: string = null;
    isSaved: boolean = false;
    error_invalid_date: any = '';
    error_invalid_time: any = '';
    msgRequire: any = 'This field is required';
    formatDate: any = 'YYYY-MM-DD';
    appointmentTypeList: any;
    
    //requireMessage: RequireMessage = new RequireMessage();

    providerOfficeLists:any=[];
    fetchProviderOfficeSubscriber:Subscription;
    fetchAppointmentTypeListSubscriber:Subscription;
    advancenoticedurations:any=[];
    fetchWaitlistrangeSubscriber:Subscription;
    //editwlmobile: any = {};

    constructor(
        private Service: AppointmentsService,
        private _alertService: AlertService,
        private _utils: Utils,
        private _loadingIndicatorService: LoadingIndicatorService,
        public _systemData: SystemData,        
    ) {
        // TODO
        this.searchOpeningsDistance = this._systemData.searchOpeningsDistance;
    }
    ngOnInit(): void {
        this.searchOpenings.enable_email = 0;
        this.searchOpenings.enable_sms = 0;
    }
    showMessage(msg: any) {
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }    
    openAlertMessage(msg: string = '', reload: boolean = false) {
        this._alertService.onClose = () => {
            // Nothing
            if (reload == true) {
                this.dismiss();
            }
        };
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }
    validatePatient(message: any) {
        if (this.searchOpenings.patient == '') {
            this.errorResponse.patient = message;
            return false;
        } else {
            return true;
        }
    }
    validateZipCode(message: any) {
        if (this.searchOpenings.zip == '') {
            this.errorResponse.zip = message;
            return false;
        } else {
            return true;
        }
    }
    validateDateFrom(message: any) {
        if (this.searchOpenings.start_date == '') {
            this.errorResponse.start_date = message;
            return false;
        } else {
            return true;
        }
    }
    validateDateEnd(message: any) {
        if (this.searchOpenings.end_date == '') {
            this.errorResponse.end_date = message;
            return false;
        } else {
            return true;
        }
    }
    validateEmail(message: any) {
        if (this.searchOpenings.enable_email == 1 && this.searchOpenings.email == null) {
            this.errorResponse.email = message;
            return false;
        } else {
            return true;
        }
    }
    validatePhone(message: any) {
        this.searchOpenings.mobile = this._utils.implodePhoneMobile(this.searchOpenings.first, this.searchOpenings.middle, this.searchOpenings.last);
        if (this.searchOpenings.enable_sms == 1 && this.searchOpenings.mobile == '') {
            this.errorResponse.mobile = message;
            return false;
        } else {
            return true;
        }
    }

    validateDateFromLessDateTo(message: any) {
        const date1 = new Date(this.searchOpenings.start_date);
        const date2 = new Date(this.searchOpenings.end_date);
        if (date1 > date2) {
            this.error_invalid_date = message;
            return false;
        } else {
            return true;
        }
    }
    removeErrEmail() {
        this.errorResponse.email = '';
        // jQuery('#emailInput').removeClass('input-error');
    }
    removeErrPhone() {
        this.errorResponse.mobile = '';
        // jQuery('#emailInput').removeClass('input-error');
    }
    clearMsgError() {
        this.errorResponse.patient = '';
        this.errorResponse.zip = '';
        this.errorResponse.start_date = '';
        this.errorResponse.end_date = '';
        this.errorResponse.email = '';
        this.errorResponse.mobile = '';
        this.error_invalid_date = '';
        this.error_invalid_time = '';
    }
    validate() {
        //const validatePatient = this.validatePatient(this.msgRequire);
        const validateZipCode = this.validateZipCode(this.msgRequire);
        const validateDateFrom = this.validateDateFrom(this.msgRequire);
        const validateDateEnd = this.validateDateEnd(this.msgRequire);
        const validateEmail = this.validateEmail(this.msgRequire);
        const validatePhone = this.validatePhone(this.msgRequire);
        const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');

        if (validateZipCode && validateDateFrom && validateDateEnd && validateEmail && validatePhone && validateDateFromLessDateTo) {
            return true;
        }
        return false;
    }

    getDataToSave() {
        this.searchOpenings.start_time = jQuery('#startTime1').val();
        this.searchOpenings.end_time = jQuery('#endTime1').val();
    }
    processSaveSearch() {
        this.clearMsgError();
        if (this.validate()) {
            this.setDataToSaveSearch();
            this.handleSaveSearch();
        }
    }
    setDataToSaveSearch() {
        if (this._utils.isPartialActiveStatus()) {
            this.openAlertMessage(this.msgPartialAccess);
            return;
        }
        this.successSaveOpeningSearch = '';
        this.errorSaveOpeningSearch = '';
        if (this.saveSettingsSubscriber) {
            this.saveSettingsSubscriber.unsubscribe();
        }
        this.getDataToSave();
        if (this.searchOpenings.enable_email) {
            this.searchOpenings.enable_email = 1;
        } else {
            this.searchOpenings.enable_email = 0;
        }

        if (this.searchOpenings.enable_sms) {
            this.searchOpenings.enable_sms = 1;
        } else {
            this.searchOpenings.enable_sms = 0;
        }
        this.searchOpenings.mobile = this._utils.implodePhoneMobile(this.searchOpenings.first, this.searchOpenings.middle, this.searchOpenings.last);

        this.searchOpenings.start_time = (this.searchOpenings.start_time != undefined && this.searchOpenings.start_time != null && this.searchOpenings.start_time != '') ? this.searchOpenings.start_time : '00:00:00';
        this.searchOpenings.end_time = (this.searchOpenings.end_time != undefined && this.searchOpenings.end_time != null && this.searchOpenings.end_time != '') ? this.searchOpenings.end_time : '23:59:59';

        const startTime:any = this.searchOpenings.start_time.split(':');
        const endTime:any = this.searchOpenings.end_time.split(':');

        const startDatetime:any = moment(this.searchOpenings.start_date).hour(startTime[0])
            .minute(startTime[1]).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
        const endDatetime:any = moment(this.searchOpenings.end_date).hour(endTime[0]).minute(endTime[1])
            .second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');

        this.searchOpenings.start_datetime = startDatetime;
        this.searchOpenings.end_datetime = endDatetime;
    }
    handleSaveSearch() { 
        console.log(this.searchOpenings);
       // return false;

        this._loadingIndicatorService.start();
        this.saveSettingsSubscriber = this.Service.updatesavePatientToWaitlist(this.searchOpenings, this.savedId).subscribe(
            (success: any) => {
                this.isSaved = true;
                this._loadingIndicatorService.stop();
                const res = success;
                if (res.error_code === 'RC000') {
                    this.successSaveOpeningSearch = res.error_messages;
                    this.loadIconVerifiedPhone = !!this.searchOpenings.enable_sms;
                    this.verifiedPhone = res.verifiedPhone;
                    this.openAlertMessage(this.successSaveOpeningSearch, true);
                    //this.clearDataOncesaved();
                }
            }, (error: any) => {
                this._loadingIndicatorService.stop();
                const response = error.error;
                this.loadIconVerifiedPhone = false;
                if (response.error_code === 'RC091') {
                    this.errorSaveOpeningSearch = response.error_messages;
                } else if (response.error_code === 'RC020') {
                    this.showMsgError(response.error_fields);
                } else if (response.error_code === 'RC101' || response.error_code === 'RC089' || response.error_code === 'RC135' || response.error_code === 'RC142') {
                    this.errorSaveOpeningSearch = response.error_messages;
                }
                // this.callDataSearhOpeningBack();
            }, () => {
                // this.callDataSearhOpeningBack();
            });
    }
    
    showMsgError(response:any){
        if (response.patient_user_id != null) {
            this.errorResponse.patient = 'This patient is invalid';
        }
        if (response.zip != null) {
            this.errorResponse.zip = response.zip;
        }
        if (response.email != null) {
            this.errorResponse.email = response.email;
        }
        if (response.end_time != null) {
            this.error_invalid_time = response.end_time;
        }
        if (response.mobile != null) {
            this.errorResponse.mobile = response.mobile;
        }
    }
    
    onChange(event: any) {
        switch (event) {
            case 'patient':
                this.errorResponse.patient = '';
                break;
            case 'zip':
                this.errorResponse.zip = '';
                break;
            case 'startTime1':
                this.errorResponse.start_time = '';
                this.error_invalid_time = '';
                //this.onChangeDateTime(event);
                break;
            case 'endTime1':
                this.errorResponse.end_time = '';
                this.error_invalid_time = '';
                //this.onChangeDateTime(event);
                break;
            case 'email':
                this.errorResponse.email = '';
                break;
            case 'mobile':
                this.errorResponse.mobile = '';
                break;
            case 'provider_office_id':
                this.errorResponse.provider_office_id = '';
                break; 
            case 'apt_type':
                this.errorResponse.apt_type = '';
                break;
            case 'advance_notice_duration':
                this.errorResponse.advance_notice_duration = '';
                //this.onChangeDateTime(event);
                break;     

        }
    }
    onChangeDateTime(fieldTime:any){
        let start_time  = jQuery('#startTime1').val();
        let end_time    = jQuery('#endTime1').val();

        if ((fieldTime == 'startTime1' && start_time != '') || (fieldTime == 'endTime1' && end_time != '')) {
            this.searchOpenings.advance_notice_duration = '';
        } else if (fieldTime == 'advance_notice_duration') {
            jQuery('#startTime1').val('');
            jQuery('#endTime1').val('');
        }
    }
    // ========== Class Method ========== //

    ngAfterViewInit() {
        const _self = this;
        jQuery(document).ready(function () {
            jQuery(_self.editinputStartDateSaveSettingSearch.nativeElement).datepicker({
                dateFormat: 'MM dd, yy',
                numberOfMonths: jQuery(window).width() < 768 ? 1 : 2,
                minDate: 0,
                showButtonPanel: true,
                onSelect: () => {
                    _self.errorResponse.start_date = '';
                    _self.error_invalid_date = '';
                    _self.onChange('startDate1');
                    _self.searchOpenings.start_date = moment(jQuery(_self.editinputStartDateSaveSettingSearch.nativeElement)
                        .datepicker('getDate')).format(_self.formatDate);
                }
            });
            jQuery(_self.editinputEndDateSaveSettingSearch.nativeElement).datepicker({
                dateFormat: 'MM dd, yy',
                numberOfMonths: jQuery(window).width() < 768 ? 1 : 2,
                minDate: 0,
                showButtonPanel: true,
                onSelect: () => {
                    _self.errorResponse.end_date = '';
                    _self.error_invalid_date = '';
                    _self.onChange('endDate1');
                    _self.searchOpenings.end_date = moment(jQuery(_self.editinputEndDateSaveSettingSearch.nativeElement)
                        .datepicker('getDate')).format(_self.formatDate);
                }
            });

            jQuery(_self.editclickShowStartDateSaveSettingSearch.nativeElement).click(() => {
                jQuery(_self.editinputStartDateSaveSettingSearch.nativeElement).datepicker('show');
            });

            jQuery(_self.editclickShowEndDateSaveSettingSearch.nativeElement).click(() => {
                jQuery(_self.editinputEndDateSaveSettingSearch.nativeElement).datepicker('show');
            });
        });
        jQuery(document).on('click', function (e: any) {
            if (jQuery(e.target).hasClass('result dropdown-menu')
                || jQuery(e.target).hasClass('error-message')) {
                // TODO: Close dropdown when click outside
            } else {
                jQuery(_self.search_field).closest(_self.input_group).removeClass('open');
            }
        });

    }

    ngOnDestroy() {
        //this.selectedSlot = 0;
        if (this.searchPatientSubscriber) {
            this.searchPatientSubscriber.unsubscribe();
        }
        if (this.getSavedOpeningSearchSubscriber) {
            this.getSavedOpeningSearchSubscriber.unsubscribe();
        }
        /*if (this.fetchAvailableSlotSubscriber) {
            this.fetchAvailableSlotSubscriber.unsubscribe();
        }
        if (this.bookAppointmentSubscriber) {
            this.bookAppointmentSubscriber.unsubscribe();
        }
        if (this.deleteSlotSubscriber) {
            this.deleteSlotSubscriber.unsubscribe();
        }*/

        if (this.fetchProviderOfficeSubscriber) {
            this.fetchProviderOfficeSubscriber.unsubscribe();
        }
        if (this.fetchAppointmentTypeListSubscriber) {
            this.fetchAppointmentTypeListSubscriber.unsubscribe();
        }
        if (this.fetchWaitlistrangeSubscriber) {
            this.fetchWaitlistrangeSubscriber.unsubscribe();
        }
        
    }

    // ========== General Modal Method ========== //

    dismiss() {
        this.searchOpenings.enable_email = 0;
        this.searchOpenings.enable_sms = 0;
        this.searchOpenings.patient = this.patient_id = null;
        //this.requireMessage = new RequireMessage();
        jQuery(this.search_field).parent().removeClass('open');
        this.clearData();
        this.clearMsgError();
        if (this.isSaved) {
            window.location.reload();
        }

        // this._searchOpeningsComponent.getNotifySavedOpenAppointmentSetting(1);
        // this.saveSettingClicked();

    }
    clearData() {
        this.searchOpenings = new SearchOpenings();
        this.errorResponse = new SearchOpenings();
        jQuery(this.editinputStartDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery(this.editinputEndDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery('#startTime1').val('');
        jQuery('#endTime1').val('');
        this.successSaveOpeningSearch = '';
        this.errorSaveOpeningSearch = '';
        this.loadIconVerifiedPhone = false;
        this.searchDisabled = true;
    }
    clearDataOncesaved() {
        this.searchOpenings = new SearchOpenings();
        this.errorResponse = new SearchOpenings();
        jQuery(this.editinputStartDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery(this.editinputEndDateSaveSettingSearch.nativeElement).datepicker('setDate', null);
        jQuery('#startTime1').val('');
        jQuery('#endTime1').val('');
        //this.successSaveOpeningSearch = '';
        //this.errorSaveOpeningSearch = '';
        this.loadIconVerifiedPhone = false;
        this.searchDisabled = true;
    }    
    open(id:any) {
        this.savedId = id;
        const self = this;        
        this.errorMessage = null;
        this.errorMessageBook = null;
        if (this.getSavedOpeningSearchSubscriber) {
            this.getSavedOpeningSearchSubscriber.unsubscribe();
        }
        //
        //this.fetchProviderOffice();

        this.clearMsgError();

        if (this.fetchProviderOfficeSubscriber) {
            this.fetchProviderOfficeSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();

        this.fetchProviderOfficeSubscriber = this.Service.getProviderOfficeLocation(null).subscribe(
            (success: any) => {
                const res = success;
                this._loadingIndicatorService.stop();
                if (res.error_code === 'RC000') {
                    this.providerOfficeLists = res.providerOffices;
                    //this.advancenoticedurations = res.advancenoticedurations;
                }
                this.fetchSavedInfo();
            }, (error: any) => {
                this._loadingIndicatorService.stop();
            });

        this.fetchWaitlistrange();
    }
    /*getAppointmentTypes(id:any){
        this.Service.getAppointmentTypeList(id).subscribe((success: any) => { 
            this.appointmentTypeList = success.apttypes;
        });
    }*/
    close() {
        //this.selectedSlot = 0;
        this.modal.close();
    }
    /*fetchProviderOffice() {
        
    }*/
    fetchWaitlistrange() {
        if (this.fetchWaitlistrangeSubscriber) {
            this.fetchWaitlistrangeSubscriber.unsubscribe();
        }
        this._loadingIndicatorService.start();

        this.fetchWaitlistrangeSubscriber = this.Service.fetchWaitlistrange().subscribe(
            (success: any) => {
                const res = success;
                this._loadingIndicatorService.stop();
                if (res.error_code === 'RC000') {
                    this.advancenoticedurations = res.advancenoticedurations;
                }
            }, (error: any) => {
                this._loadingIndicatorService.stop();
            });
    }
    fetchSavedInfo(){
        //this.editwlmobile = {};
        this._loadingIndicatorService.start();
        this.getSavedOpeningSearchSubscriber = this.Service.getSavedOpeningSearch(this.savedId).subscribe((success: any) => {
            this._loadingIndicatorService.stop();
            
            //console.log(success);

            if (success.error_code === 'RC000') {
                this.searchOpenings = success.data;
                this.searchOpenings.start_date_display = moment(success.data.start_datetime).format('MMMM D, YYYY');
                this.searchOpenings.end_date_display = moment(success.data.end_datetime).format('MMMM D, YYYY');

                this.searchOpenings.start_date = moment(success.data.start_datetime).format('YYYY-MM-DD');
                this.searchOpenings.end_date = moment(success.data.end_datetime).format('YYYY-MM-DD');
                //self.getAppointmentTypes(this.searchOpenings.provider_office_id);

                if (this.searchOpenings.mobile !== null) {

                    const num = this.searchOpenings.mobile.replace(/[^0-9]/g, '');
                    const firstMobileNumber = num.substr(0, 3);
                    const secondMobileNumber = num.substr(3, 3);
                    const thirdMobileNumber = num.substr(6, 4);
                    this.searchOpenings.first = '(' + firstMobileNumber + ')';
                    this.searchOpenings.middle = secondMobileNumber;
                    this.searchOpenings.last = thirdMobileNumber;
                }

                this.onChangeProviderOffice();
                this.modal.open();                
            }
            if (success.error_code === 'RC061') {
               // jQuery(event.srcElement).closest(self.input_group).addClass('open');
                this.errorMessage = success.error_messages;
            }
        },(error: any) => {
                this._loadingIndicatorService.stop();
                const e = error.error;
                if (e.error_code === 'RC061') {
                    this.errorMessage = e.error_messages;
                }
            }
        );
    }
    onChangeProviderOffice(){
        let office_id = this.searchOpenings.provider_office_id;
        let gid = null;
        let lentt = this.providerOfficeLists.length;
        if (lentt > 0) {
            for (let i=0; i < lentt; i++){
                if (this.providerOfficeLists[i].id == office_id) {
                    gid = this.providerOfficeLists[i].practice_group_id;
                }
            }
        }
        this.fetchAppointmentTypeList(gid);
    }
    fetchAppointmentTypeList(group_id:any) {

        if (this.fetchAppointmentTypeListSubscriber) {
            this.fetchAppointmentTypeListSubscriber.unsubscribe();
        }

        let cid = (this.searchOpenings.apt_type != undefined)? this.searchOpenings.apt_type : 0;

        this._loadingIndicatorService.start();
        //getPracticeAppointmentTypeList(slot_id:any,is_limit=1, includeid=0, practice_group_id:any=0)
        this.fetchAppointmentTypeListSubscriber = this.Service.getPracticeAppointmentTypeList(null, 1, cid, group_id).subscribe(
            (success: any) => { //console.log(success);
                this.appointmentTypeList = success.apttypes;
                this._loadingIndicatorService.stop();
            }, (error: any) => {
                this._loadingIndicatorService.stop();
            });
    }
}


/*export class DataDateBook {
    selectedDate: any = moment();
    dateFrom: any = '';
    dateTo: any = '';
}

export class PatientInfoClass {
    id: number;
    first_name: string;
    last_name: string;
    dob: string;
    phone_mobile: number;
    is_family: boolean;
    uuid: any;
    family_member_id: any;
}

export class RequireMessage {
    patient_id: string;
    date: string;
    start_time: string;
    end_time: string;
}*/