import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {Shell} from '@app/shell/shell.service';
import {extract} from '@app/core';
import {AuthGuard} from '../core/auth.guard';
import {Role} from '../core/role';
import {NotFoundComponent} from '../shared/not-found/not-found.component';
import {AccountComponent} from './account/account.component';

import {AdminComponent} from './admin.component';
import {EditAdminComponent} from './edit-admin/edit-admin.component';
import {AdminProfileComponent} from './admin-profile/admin-profile.component';

//add
import {AddAdminComponent} from './add-admin/add-admin.component';
import {AddPatientComponent} from './add-patient/add-patient.component';
import {AddSuperUserComponent} from './add-super-user/add-super-user.component';
import {AddMasterComponent} from './add-master/add-master.component';

//edit
import {EditPatientComponent} from './edit-patient/edit-patient.component';
import {EditSuperUserComponent} from './edit-super-user/edit-super-user.component';
import {EditMasterComponent} from './edit-master/edit-master.component';

import {EditManagingUserComponent} from './edit-managing-user/edit-managing-user.component';
import {EditProviderComponent} from './edit-provider/edit-provider.component';

import {NotificationComponent} from './notification/notification.component';
import {PracticeComponent} from './practice/practice.component';
import {AddPracticeComponent} from './add-practice/add-practice.component';
import {EditPracticeComponent} from './edit-practice/edit-practice.component';

import {EhrComponent} from './ehr/ehr.component';
import {ActiveModuleComponent} from '@app/admin/practice/active-module/active-module.component';
import {AdminChangePasswordComponent} from '@app/admin/change-password/change-password.component';
import {DataLogComponent} from '@app/admin/data-log/data-log.component';
import {StatisticalAppointmentComponent} from '@app/admin/statistical-appointment/statistical-appointment.component';
import { SimulatortoolComponent } from '@app/admin/simulatortool/simulatortool.component';

const routes: Routes = [{ 
    path: '', 
    component: AdminComponent,
    children: [
        {path: '', redirectTo: '/home', pathMatch: 'full'},
        // { path: '/notfound', component: NotFoundComponent },
        // { path: '/**', redirectTo: "/notfound" },
        {path: 'admin/account', component: AccountComponent, data: {roles: [Role.ADMIN]}, canActivate: [AuthGuard]},

        { 
            path: 'admin/add-admin', 
            component: AddAdminComponent, 
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/edit-admin/:id',
            component: EditAdminComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },

        {
            path: 'admin/add-patient',
            component: AddPatientComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/add-super-user',
            component: AddSuperUserComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/add-super-provider',
            component: AddMasterComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/edit-patient/:id',
            component: EditPatientComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/edit-provider/:id',
            component: EditProviderComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },        
        {
            path: 'admin/edit-super-user/:id',
            component: EditSuperUserComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/edit-super-provider/:id',
            component: EditMasterComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/notification',
            component: NotificationComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/practice',
            component: PracticeComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]},
        {
            path: 'admin/add-practice',
            component: AddPracticeComponent,
            data: {onActivate: ['/admin', 'practice'], roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/edit-practice/:id',
            component: EditPracticeComponent,
            data: {onActivate: ['/admin', 'practice'], roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/ehr',
            component: EhrComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]},
        {
            path: 'admin/active-module/:id',
            component: ActiveModuleComponent,
            data: {title: extract('RTN'), roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/change-password',
            component: AdminChangePasswordComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/data-log',
            component: DataLogComponent,
            data: {roles: [Role.ADMIN]}, 
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/edit-managing-user/:id',
            component: EditManagingUserComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/profile',
            component: AdminProfileComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
        },
        {
            path: 'admin/simulator',
            component: SimulatortoolComponent,
            data: {roles: [Role.ADMIN]},
            canActivate: [AuthGuard]
            
        }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule {
}
