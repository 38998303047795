import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
// import { PasswordFormatTooltipComponent } from '@app/shared/tooltip/password-format';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { HomepageService } from '../home.service';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Utils } from '@app/shared/utils';
import { AlertService } from '@app/shared/alert';
declare let jQuery: any;

@Component({
  selector: 'rtn-home-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['../home.component.scss','./activate.component.scss'],
  moduleId: module.id,
})
export class ActivateComponent implements OnInit {

    error: boolean = false;
    errorMessage: any;
    linkIsValid: boolean = true;
    id: string;
    token: string;
    showBtn: boolean = true;

    activateUserSubscriber: Subscription;
    minHeight: number;

    constructor(
        private _homePageService: HomepageService,
        private _utils: Utils,
        private _params: ActivatedRoute,
        private _router: Router,
        private _alertService: AlertService
    ) {
        // TODO
    }

    ngOnInit() {
        // TODO: Get token/ id from URL
        this.id = this._params.snapshot.paramMap.get('id');
        this.token = this._params.snapshot.paramMap.get('token');
        // TODO: Validate id & token
        this.active();
        this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
    }

    ngOnDestroy() {
        if (this.activateUserSubscriber) {
            this.activateUserSubscriber.unsubscribe();
        }
    }

    private active() {
        var self = this;
        var activeData = {
            'id': this.id,
            'token': this.token
        };
        if (this.activateUserSubscriber) {
            this.activateUserSubscriber.unsubscribe();
        }
        this.activateUserSubscriber = this._homePageService.activateUser(activeData).subscribe(
            (success: any) => {
                // TODO
                self.error = false;
                self.linkIsValid = true;
                let continued = true;
                self.showBtn = true;

                setTimeout(function(){
                    self._router.navigate(['/home', 'login']);
                }, 5000);
                // hide the redirection logic - OD defect_02
                /*if (success.user.role_id === 6) {
                    if (success.user.practice) {
                        self.showBtn = false;
                        self._alertService.onClose = () => {
                            localStorage.setItem('managing_user_id', success.user.id);
                            self._router.navigate(['/provider', 'manage-provider']);
                        };
                        self._alertService.showDismissButton = false;
                        self._alertService.dismissButtonLabel = 'Close';
                        self._alertService.showCloseButton = true;
                        self._alertService.closeButtonLabel = 'OK';
                        self._alertService.title = 'Your account has been activated successfully.';
                        self._alertService.message = '';
                        self._alertService.emitter('open');
                    } else {
                        continued = false;
                        self._alertService.onClose = () => {
                            self._router.navigate(['/home', 'login']);
                        };
                        self._alertService.showDismissButton = false;
                        self._alertService.dismissButtonLabel = 'Close';
                        self._alertService.showCloseButton = true;
                        self._alertService.closeButtonLabel = 'OK';
                        self._alertService.title = 'You cannot login if you have no practice';
                        self._alertService.message = '';
                        self._alertService.emitter('open');
                    }
                }
                if (continued && success.user.role_id !== 3) {
                    localStorage.setItem('module_enc', success.modules);
                    localStorage.setItem('id_token', success.token);
                    localStorage.setItem('user_token', success.token);
                    localStorage.setItem('last_active_token', success.token);
                    localStorage.setItem('user_id', success.user.id);
                    localStorage.setItem('user_role_id', success.user.role_id);
                    localStorage.setItem('account_name', success.user.account_name);
                    localStorage.setItem('user_status', success.user.status);
                    localStorage.setItem('loginEmail', success.user.email);
                    localStorage.setItem('user_practice', success.user.practice);
                    localStorage.setItem('userFullName', this._utils.generateFullName(success.user_name.first_name, success.user_name.middle_name, success.user_name.last_name));
                }
                // Redirect to Appoinment page
                if (success.user.role_id === 1) {
                    this._router.navigate(['/admin', 'account']);
                } else if (success.user.role_id === 2) {
                    this._router.navigate(['/superProvider', 'account']);
                } else if (success.user.role_id === 4) {
                    this._router.navigate(['/patient', 'activated']);
                } else if (success.user.role_id === 5) {
                    this.showBtn = false;
                    this._alertService.onClose = () => {
                        if (success.user.practice !== null) { //If have 1 practice
                            this._router.navigate(['/provider', 'practice']);
                        } else { //If have no practice
                            this._router.navigate(['/provider', 'addPractice']);
                        }
                    };
                    this._alertService.showDismissButton = false;
                    this._alertService.dismissButtonLabel = 'Close';
                    this._alertService.showCloseButton = true;
                    this._alertService.closeButtonLabel = 'OK';
                    this._alertService.title = 'Your account has been activated successfully.';
                    this._alertService.message = '';
                    this._alertService.emitter('open');
                }*/
            }, (error: any) => {
                // TODO
               this.linkIsValid = false;
               this.error = true;
               this.errorMessage = error.error.error_messages;
            });
    }

}
