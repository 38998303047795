<div class="mt-3">
    <h3 class="title">Appointment by Slot</h3>
    <div class="col-12 px-0">
      <div class="row col-12 border border-#ddd pt-3 no-print">
        <!-- Select pratice-->
        <div class="px-0 row col-lg-6 mb-2">
          <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Practice:</div>
          <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
            Practice:</div>
          <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getPractice">
            <select
            #select2PracticeDirective2="select2-practice2"
            select2-practice2
            class="form-control"
            [value]="dataSearchChart.practice"
            [maximumSelectionLength]="0"
            [closeOnSelect]="true"
            [multiple]="false"
            (OnSelected)="onChangePractice()"
            [tags]="false"
            id="select2Practice">
            <option *ngFor="let practice of practicesList; let i = index" [id]="'practice' + practice.id" [value]="practice.id" >{{ practice.name }}</option>
          </select>
          <div class="message-error">{{ errorResponse.practice }}</div>
          <div class="red-alert d-none d-lg-block error_message"></div>
          <div class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message"></div>
          </div>
        </div>
        <!-- Select provider-->
        <div class="px-0 row col-lg-6 mb-2">
          <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Provider:</div>
          <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
            Provider:</div>
          <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
            <select
            #select2ProviderDirective="select2-provider"
            select2-provider
            class="form-control"
            [value]="dataSearchChart.provider"
            [closeOnSelect]="true"
            [maximumSelectionLength]="0"
            [multiple]="false"
            id="select2Provider"
            placeholder="Select Provider"
            [tags]="false"            
            >
            <option value=""></option>
            <option *ngFor="let provider of providersList; let i = index" [id]="'provider' + provider.id" [value]="provider.id" >
              {{ provider.full_name }}
            </option>
        </select>
        <div class="message-error">{{ errorResponse.provider }}</div>
        <div class="red-alert d-none d-lg-block error_message"></div>
        <div
          class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
        </div>
          </div>
  
        </div>
        <!-- Select type-->
        <div class="px-0 row col-lg-6 mb-2">
          <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Type <i class="fas fa-info-circle type_info" data-toggle="tooltip" data-placement="top" title="RTN specific wait time calculation is wait time calculated in accordance with RTN's proprietary rules"></i></div>
          <div
            class="text-left col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
            Type <i class="fas fa-info-circle type_info" data-toggle="tooltip" data-placement="top" title="RTN specific wait time calculation is wait time calculated in accordance with RTN's proprietary rules"></i></div>
          <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
            <select class="form-control" id="typeChart" name="typeChart" (change)="changeType()">
              <option [value]="1" selected>Standard wait time calculation</option>
              <option [value]="2">RTN specific wait time calculation</option>
            </select>
          </div>
  
        </div>
        <!-- select date  -->
        <div class="px-0 row col-lg-6 mb-2">
          <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Date:</div>
          <div
            class="text-left col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
            Date:</div>
          <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10">
            <div class="input-group">
              <input type="text" #inputDateTo readonly class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateTo']" aria-describedby="basic-addon2">
              <div class="input-group-append"  #clickDateTo>
                <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
              </div>
            </div>
          </div>
  
          <!-- <div class="message-error">{{ errorResponse.start_date }}</div> -->
        </div>
        <!-- event type -->
        <div class="px-0 row col-lg-12 mb-2">
          <div class="d-none d-lg-block find-appointment-line-title width_70 pt-2">Event Type:</div>          
          <div class="col-lg-10 col-md-6 col-sm-6 col-6 px-0" style="max-width: 79%;">
            <select
              #Select2EventtypeDirective="select2-eventtype"
              select2-eventtype 
              class="form-control"
              [value]="dataSearchChart.event_reason_id"
              [closeOnSelect]="true"
              [multiple]="true"
              id="select2Eventtype"
              placeholder="Select event type"
              [tags]="false"
              [disabled]="(dataSearchChart.type == 1) ? false : true"
              >
              <option value=""></option>
              <option *ngFor="let eventtype of eventtypeList; let i = index" [id]="'eventtype' + eventtype.id" [value]="eventtype.id" >
                {{ eventtype.label }} <ng-container *ngIf="eventtype.is_allowed != '1'">(***)</ng-container>
              </option>
            </select>
          </div>
  
        </div>
        <!-- event type -->
        <div class="px-0 ml-3 row col-lg-10 mb-2">
          <div class="btn-90" *ngIf="noofdata > 0">
            <button id='printDataChart' class="container btn btn-rtn btn-90 px-1 text-uppercase" type="button" (click)="printChart()">Print</button>
          </div>
          <div class="btn-90 ml-2" *ngIf="noofdata > 0">
            <button id='exportDataChart' class="container btn btn-rtn btn-90 px-1 text-uppercase" type="button" (click)="exportdata()">EXPORT</button>
          </div>          
          <div class="btn-90 ml-2">
            <button id='showDataChart' class="container btn btn-rtn btn-90 px-1 text-uppercase"
              type="submit">SHOW</button>
          </div>
        </div>
      </div>      
      <ng-container *ngIf="noofdata > 0">
        <div class="providers_waittime mt-3">         
          <ng-container *ngFor="let pages of noofpageArr">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="title"> Page : {{ pages + 1}}</h3>  
                <div id="groupchartbody_{{pages}}"></div>              
              </div>
            </div>          
           </ng-container> 
           <div *ngIf="noofdata > 0">
              <span class="text-danger">* </span>{{ _systemData.footNote }}
          </div>
        </div> 
      </ng-container>

      <ng-container *ngIf="noofdata == 0 && isSubmitted">
        <div class="row">
          <div class="col-lg-12 pt-3"><p>No data found</p></div>        
        </div>
      </ng-container>
       
    </div>
  </div>
  