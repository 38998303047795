import {Component, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
// import { BookService } from '../services/book.service';
import {AlertService} from '@app/shared/alert/alert.service';
import {SystemData, SystemEvent} from '@app/shared/system';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {Utils} from '@app/shared/utils';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {AppointmentsService} from '@app/appointments/appointments.service';
import * as moment from 'moment';

import { ProvidersService } from '@app/providers/providers.service';
import { PatientsService } from '@app/patients/patients.service';

//import { PatientFutureAppointmentComponent } from '@app/shared/patient-future-appointment/patient-future-appointment.component';

declare let jQuery: any;
const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};

@Component({
  selector: 'rtn-book-appointment',
  moduleId: module.id,
  templateUrl: './book-component.component.html',
  styleUrls: ['./book-component.component.scss']
})
export class BookAppointmentComponent implements OnInit, OnDestroy, AfterViewInit {
  timeSetting: any = [];
  bookAppointment: any = {};
  bookInfo: any = [];
  notificationSetting: any = [];

  providerInfo: any = [];
  patientInfo: any = [];

  bookListener: any;
  bookAppointmentSubscriber: Subscription;
  getBookAppointmentSubscriber: Subscription;
  getNotificationSettingsSubscriber: Subscription;

  @Output() callEventBookEmitter = new EventEmitter();
  @Output() callEventAddwaitlistBookEmitter = new EventEmitter();

  @ViewChild('settingReminder') settingReminder: ElementRef;
  @ViewChild('selectTime') selectTime: ElementRef;

  fetchAppointmentTypeSubscriber: Subscription;
  appointmentTypeList: any = [];
  practiceId: any= '';
  //@ViewChild(PatientFutureAppointmentComponent) patientFutureApptDirective: PatientFutureAppointmentComponent;
  //loadedpage:string = 'patient_schedule';
  aptlists: any = [];
  //selectedupcommingId: number = 0;
  currentId:any='';
  currentstep:string='booking'; // booking / addwaitlist
  appointmentId:any=0;

  // show future apt
  futureaptlists: any = [];
  selectedupcommingId: number = 0;
  fetchPatientUpcommingAptSubscriber: Subscription;
  loadedpage : string = 'patient_schedule';
  
  constructor(
    private _router: Router,
    private Service: AppointmentsService,
    private _alertService: AlertService,
    public _utils: Utils,
    public _systemData: SystemData,
    private _systemEvent: SystemEvent,
    private _loadingIndicatorService: LoadingIndicatorService,
    private providersService: ProvidersService,
    private patientsService: PatientsService,
  ) {
    this.bookAppointment.appointment_reason = '';
    this.bookAppointment.event_reason_id = '';
    //this.bookAppointment.description_1 = '';
    //this.bookAppointment.description_2 = '';
  }

  ngOnInit() {
    // Default value
    this.timeSetting = [
      {'name': 'One day', 'value': '1'},
      {'name': 'Two days', 'value': '2'},
      {'name': 'Three days', 'value': '3'},
      {'name': 'Five days', 'value': '5'},
      {'name': 'One week', 'value': '7'}
    ];
    this.initData();

    if (!this._systemData.selectOpenSlotData) {
      // If user do not have click slot
      this._router.navigate(['/patient', 'appointment', 'openings']);
    }
    this.getBookAppointment();
    this.bookListener = this._systemEvent.book.subscribe((action: string) => {
      switch (action) {
        case 'book_callGetBookAppointment':
          this.getBookAppointment();
          this.initData();
          break;
        default:
          break;
      }
    });
  }

  ngAfterViewInit() {
    const _self = this;
    jQuery(_self.settingReminder.nativeElement).change(function () {
      if (this.checked) {
        jQuery(_self.selectTime.nativeElement).prop('disabled', false);
      } else {
        jQuery(_self.selectTime.nativeElement).prop('disabled', true);
      }
    });
    if (jQuery(this.settingReminder.nativeElement).is(':checked')) {
      jQuery(this.selectTime.nativeElement).prop('disabled', false);
    } else {
      jQuery(this.selectTime.nativeElement).prop('disabled', true);
    }
  }

  ngOnDestroy() {
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }
    if (this.getBookAppointmentSubscriber) {
      this.getBookAppointmentSubscriber.unsubscribe();
    }
    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
  }

  initData() {
    this.getNotificationSettings();
    
    this.bookAppointment.is_reminder = this.notificationSetting.is_reminder;
    this.bookAppointment.reminder_day = this.notificationSetting.reminder_day;
    this.bookAppointment.add_followed = 0;
    this.bookAppointment.appointment_reason = '';
    this.bookAppointment.event_reason_id = '';
    //this.bookAppointment.description_1 = '';
    //this.bookAppointment.description_2 = '';

    //console.log(this._systemData.selectOpenSlotData);
  }

  getNotificationSettings() {
    if (this.getNotificationSettingsSubscriber) {
      this.getNotificationSettingsSubscriber.unsubscribe();
    }
    this.getNotificationSettingsSubscriber = this.Service.getNotificationGlobalSetting().subscribe(
      (success: any) => {
        this.notificationSetting = success.notification_settings;
        this.notificationSetting.reminder_day = (this.notificationSetting.reminder_day !== 0)
          ? this.notificationSetting.reminder_day : 1;
      }
    );
  }

  getBookAppointment() {
    if (this.getBookAppointmentSubscriber) {
      this.getBookAppointmentSubscriber.unsubscribe();
    }
    this.getBookAppointmentSubscriber = this.Service.getBookAppointment(
      this._systemData.selectOpenSlotData.provider_id,
      this._systemData.selectOpenSlotData.patient_user_id,
      this._systemData.selectOpenSlotData.family_id
     
    ).subscribe(
      (success: any) => {
        this.providerInfo = success.provider;
        this.providerInfo.fullName = this._utils.generateFullName(this.providerInfo.first_name,
        this.providerInfo.middle_name, this.providerInfo.last_name);
        this.providerInfo.specialties = this._utils.getSpecialties(this.providerInfo.specialties);
        this.patientInfo = success.patient;
        if(this.patientInfo == null){
          this.patientInfo.first_name = ' ';
          this.patientInfo.last_name = ' ';
        }
        this.practiceId = success.practiceId;
        this.fetchAppointmentType();
        
        this.fetchPatientUpcommingApt(0, this.providerInfo.provider_id);

        console.log('Provider ID:' + this.providerInfo.id);

        //TEST
        //this.aptlists = success.aptlists;
        //this.aptlists = [...this.aptlists];
        //this.currentId = new Date().valueOf();
        //this._systemEvent.book.emit('book_callFutureApt');
        //console.log("emit - book_callFutureApt");
        //console.log(this.aptlists);
      }
    );
  }
  fetchAppointmentType() {
    
    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
    this.appointmentTypeList = [];
    this._loadingIndicatorService.start();
    this.fetchAppointmentTypeSubscriber = this.Service.getPracticeAppointmentTypeList(this._systemData.selectOpenSlotData.slot_Id, 1, 0, null)
      .subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          this.appointmentTypeList = success.apttypes;
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        }
      );
  }
  submitBookAppointment(formVals: any) {
    const pathname = window.location.hash;
    let bookFromNotification = false;
    if (pathname === '/patient/notification') {
      bookFromNotification = true;
    }
    const _self = this;
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }
    const value = Object.assign({}, formVals);
    value.provider_id = this._systemData.selectOpenSlotData.provider_id;
    value.patient_user_id = this._systemData.selectOpenSlotData.patient_user_id;
    value.family_id = this._systemData.selectOpenSlotData.family_id;
    value.start_time = moment.utc(this._systemData.selectOpenSlotData.start_time).format();
    value.end_time = moment.utc(this._systemData.selectOpenSlotData.end_time).format();
    value.book_from_notification = bookFromNotification;
    value.slot_id =  this._systemData.selectOpenSlotData.slot_Id;
    value.reschedule_id =  this.selectedupcommingId;

    this._loadingIndicatorService.start();
    this.bookAppointmentSubscriber = this.Service.bookAppointmentByPatient(value).subscribe(
      (success: any) => {
        _self._alertService.onDismiss = () => {
          console.log(success);
          this._systemData.appointmentId = 0;
          this.callEventBookEmitter.emit('');
        };
        _self._alertService.onClose = () => {
          console.log(success);
          this._systemData.appointmentId = success.id;
          this.callEventAddwaitlistBookEmitter.emit('');
        };
        if (success.allow_to_addwaitlist) {
          _self._alertService.showCloseButton = true;
          _self._alertService.message = "Try our waitlist feature-set your preferred dates and times and we'll notify you when a matching slot opens up. Book it instantly for your convenience";
        } else {
          _self._alertService.showCloseButton = false;
          _self._alertService.message = "";
        }

        _self._alertService.dismissButtonLabel = 'Close';
        _self._alertService.closeButtonLabel = 'Add Waitlist';
        _self._alertService.showDismissButton = true;
       // _self._alertService.showCloseButton = true;
        _self._alertService.title = success.error_messages;
        //_self._alertService.message = "Try our waitlist feature-set your preferred dates and times and we'll notify you when a matching slot opens up. Book it instantly for your convenience";
        _self._alertService.emitter('open');
        this._loadingIndicatorService.stop();
      },
      (error: any) => {
        const res = error.error;
        if (res.error_code === 'RC056' || res.error_code === 'RC063' || res.error_code === 'RC086'
          || res.error_code === 'RC104' || res.error_code === 'RC108') {
          _self._alertService.onClose = () => {
            this.callEventBookEmitter.emit('');
          };
          _self._alertService.dismissButtonLabel = 'No';
          _self._alertService.closeButtonLabel = 'OK';
          _self._alertService.showDismissButton = false;
          _self._alertService.showCloseButton = true;
          _self._alertService.title = res.error_messages;
          _self._alertService.message = '';
          _self._alertService.emitter('open');
          this._loadingIndicatorService.stop();
        } else {
          this._loadingIndicatorService.stop();
        }
      },
      () => {
        this._loadingIndicatorService.stop();
      }
    );
  }  
  fetchPatientUpcommingApt(patient_id: any, provider_id: any) {
    
    this.futureaptlists = [];

    this._loadingIndicatorService.start();

    if (this.fetchPatientUpcommingAptSubscriber) {
      this.fetchPatientUpcommingAptSubscriber.unsubscribe();
    }
    this.fetchPatientUpcommingAptSubscriber = this.Service.fetchPatientUpcommingApt(patient_id, provider_id).subscribe((success: any) => {
      this._loadingIndicatorService.stop();
      if (success.aptlist !== undefined) {
        this.futureaptlists = success.aptlist;  
        console.log(this.futureaptlists);          
      }
    },(error: any) => {
          this._loadingIndicatorService.stop();
    });    
  }
  selecteRescheduleId(id:any){
    console.log('ID  =' +id);
    this.selectedupcommingId = (this.selectedupcommingId == id) ? 0 : id;
    console.log('selectedupcommingId = ' + this.selectedupcommingId);
  }
}
