<div class="container-fluid  w-100">
  <div class="row" *ngIf="!errorMessage">
      <div class="col-md-12 Rtn-Meter" >
          <div class="waitmeter-top-part px-3 pb-0 pt-1 d-flex justify-content-center">
              <img class="logo" src="assets/rtnmeter-images/images/rtn-logo.png">
          </div>
          <div class="rtnmeter-main d-lg-flex justify-content-center align-items-center">
              <div class="wait-meter w-sm-100">
                  <div class="head-part d-flex justify-content-between align-items-center">
                      <button class="btn undo mr-lg-5" type="button" (click)="showWaitTime()"><img class="rotate" src="assets/rtnmeter-images/images/reload.png"></button>
                      <div class="wait-time  pl-lg-5 ml-lg-5">Wait Time Meter</div>
                      <a class="btn call" href="tel:{{appointmentDetail.provider_phone_number}}" *ngIf="appointmentDetail.provider_phone != ''">Call to Cancel</a>
                  </div>
                  <div class="col-md-12 form meter-main px-0 py-0 d-xl-flex">
                      <div class="col-xl-6 left-part px-0" [class.style-for-firefox-with-detail]="isFirefox">
                          
                        <!--<div class="meter-image speedometer-img" [class.speedometer-img-2]="!appointmentDetail.isActive || appointmentDetail.hasLink">
                            <div class="speedometer-needle" [ngStyle]="{'-ms-transform': waitTimeMeterStyle['-ms-transform'], '-webkit-transform': waitTimeMeterStyle['-webkit-transform'], 'transform': waitTimeMeterStyle['transform']}"></div>                              
                        </div>-->

                        <div class="speedometer-img" [class.speedometer-img-2]="!appointmentDetail.isActive || appointmentDetail.hasLink">
                            <div class="speedometer-needle" [ngStyle]="{'-ms-transform': waitTimeMeterStyle['-ms-transform'], '-webkit-transform': waitTimeMeterStyle['-webkit-transform'], 'transform': waitTimeMeterStyle['transform']}"></div>
                        </div>

                          <div class="duration position-absolute"  *ngIf="appointmentDetail.waitingTime != null && appointmentDetail.waitingTime != -1">
                              <div class="d-flex justify-content-center align-items-center">
                                  <h2>{{ appointmentDetail.waitingTime }}</h2>
                              </div>
                              <div class="d-flex justify-content-center align-items-center">
                                  <p class="mb-0">{{ _utils.generateUnitDisplay(appointmentDetail.waitingTime, 'MINUTE') }}</p>
                              </div>
                          </div>
                            <div class="realtime d-flex justify-content-center align-items-center px-2" *ngIf="appointmentDetail.notice && (appointmentDetail.waitingTime == null || appointmentDetail.waitingTime == -1)">
                                <h4 class="mb-0">{{ appointmentDetail.notice }}</h4>
                            </div>
                      </div>
                      <div class="col-xl-6 px-0 right-part">
                          <div class="display" [ngClass]="{'h-100' : appointmentDetail.notice && (appointmentDetail.waitingTime == null || appointmentDetail.waitingTime == -1)}">
                              <div class="d-flex flex-wrap notification justify-content-between">
                                  <div class="col-6 timrWrapper">
                                        <div class="time list">
                                            <div>
                                                <img src="assets/rtnmeter-images/images/timer.png">
                                                 <h3 class="mb-1">{{ _utils.getTime(appointmentDetail.plan_check_in) }} - {{ _utils.getTime(appointmentDetail.plan_check_out) }}</h3>
                                                 <p class="pl-1">Time</p>
                                            </div>
                                            
                                        </div>
                                  </div>
                                  <div class="col-6 dateWrapper">
                                    <div class="date list">
                                        <div>
                                            <img src="assets/rtnmeter-images/images/date.png">
                                            <h3 class="mb-1">{{ _utils.getDate(appointmentDetail.plan_check_in) }} <!--September 14, 2021--></h3>
                                            <p class="pl-1">Date</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-6 minutesWrapper">
                                    <div class="minutes list">
                                        <div>
                                            <img src="assets/rtnmeter-images/images/duration.png">
                                            <h3 class="mb-1">{{ _utils.generateUnitWithValueDisplay(appointmentDetail.duration, 'MINUTE') }}</h3>
                                            <p class="pl-1">Duration</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 position-relative reviewWrapper">
                                    <div class="review_block list">
                                        <div>
                                        <h3 class="text-danger">Please rate us</h3>   
                                        <ng-container *ngIf="reviewDetails.rating == 0">
                                            <ng-container *ngFor="let star of stars; let i= index">
                                                <ul class="list-inline rating-list" style="display: inline-block;">
                                                    <li (click)="countStar(star, popover)" id="{{'starId'+i}}" [ngClass]="{'selected': (star <= selectedValue)}" (mouseover)="addClass(star)" (mouseout)="removeClass(star)" #popover="ngbPopover" placement="bottom" [ngbPopover]="popContent" data-placement="left"  popoverTitle="Review and Rating" triggers="manual" [autoClose]="'outside'">
                                                        <i class="fa fa-star "></i>
                                                    </li>
                                                </ul> 
                                            </ng-container>

                                            <ng-template #popContent>
                                                <textarea placeholder="Enter your comment" [(ngModel)]="review_text" class="form-control" (keyup)="onKeyUp('review_text')"></textarea>                                            
                                                <button type="button" class="container btn btn-rtn btn-90 text-uppercase" (click)="savescheduleratings()">Submit</button>
                                                <label class="text-danger" *ngIf="responseError['review']">{{responseError['review']}}</label>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container *ngIf="reviewDetails.rating > 0">
                                            <ng-container *ngFor="let star of stars; let i= index">
                                                <ul class="list-inline rating-list" style="display: inline-block;">
                                                    <li class="readonly" id="{{'starId'+i}}" [ngClass]="{'selected': (star <= reviewDetails.rating)}" #popover="ngbPopover" placement="bottom" [ngbPopover]="popContent"  popoverTitle="Review and Rating" [autoClose]="'outside'">
                                                        <i class="fa fa-star "></i>
                                                    </li>
                                                </ul> 
                                            </ng-container>
                                            <ng-template #popContent>
                                                <p>{{ reviewDetails.review }}</p>
                                            </ng-template>
                                        </ng-container>    
                                    </div>                                     
                                </div>
                                </div>
                              </div>
                                 
                          </div>
                          <div class="realtime bottomNote d-flex justify-content-center align-items-center px-2" *ngIf="appointmentDetail.notice && appointmentDetail.waitingTime != null && appointmentDetail.waitingTime != -1">
                              <h4 class="mb-0">{{ appointmentDetail.notice }}</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      
  </div>
  <div class="row mt-5" *ngIf="errorMessage">
    <div class="col-md-12" >
        <div class="errorsection">
            <p class="label-danger">{{ errorMessage }}</p>
        </div>        
    </div>
  </div>
</div>