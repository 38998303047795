import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {extract} from '@app/core';
import {Shell} from '@app/shell/shell.service';
import {DocumentsComponent} from './documents.component';
import {Role} from '@app/core/role';
import {AuthGuard} from '@app/core/auth.guard';
import {ProviderChangePasswordComponent} from '@app/providers/change-password/change-password.component';

const routes: Routes = [{
  path: '',
  component: DocumentsComponent,
  children: [{
    path: 'provider/document',
    component: DocumentsComponent,
    data: {title: extract('Documents'), roles: [Role.PROVIDER]},
    canActivate: [AuthGuard]
  },
  {
    path: 'provider/apt/change-password',
    component: ProviderChangePasswordComponent,
    data: {title: extract('RTN'), roles: [Role.PROVIDER]},
    canActivate: [AuthGuard]
  }],
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule {
}
