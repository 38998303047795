import {Component, ViewChild, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
// import { Http } from 'angular2/http';
import {Router, ActivatedRoute} from '@angular/router';
import {HomepageService} from '@app/home/home.service';
import {Subscription} from 'rxjs';
import {Utils} from '@app/shared/utils';
import {SystemData, SystemService, SystemInterval, SystemEvent} from '@app/shared/system';
declare let jQuery: any;

@Component({
  moduleId: module.id,
  templateUrl: './family-member.component.html',
  styleUrls: ['family-member.component.css'],
})
export class DeclinedFamilyComponent implements OnInit, OnDestroy, AfterViewInit {
  family_member_id: string;
  active_token: string;
  errorMessage: any;

  declinedSubscriber: Subscription;
  minHeight: number;

  constructor(
    private _declinedService: HomepageService,
    private _utils: Utils,
    public _systemData: SystemData,
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) {
    this.family_member_id = this._activeRoute.snapshot.paramMap.get('id');
    this.active_token = this._activeRoute.snapshot.paramMap.get('active_token');
    console.log(this._activeRoute.snapshot.paramMap);
    if (this._systemData.localStorage.id_token) {
      this._router.navigate(['/patient', 'declined', {
        family_member_id: this.family_member_id,
        active_token: this.active_token
      }]);
    }
  }

  ngOnInit() {
    // Todo
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }

  ngAfterViewInit() {
    // TODO: Validate id & token
    if (!this._systemData.localStorage.id_token) {
      this.decline();
    }
  }

  ngOnDestroy() {
    if (this.declinedSubscriber) {
      this.declinedSubscriber.unsubscribe();
    }
  }

  createAccount() {
    this._router.navigate(['/Home', 'SignUp']);
  }

  private decline() {
    let declineData = {
      'id': this.family_member_id,
      'token': this.active_token
    };
    if (this.declinedSubscriber) {
      this.declinedSubscriber.unsubscribe();
    }
    this.declinedSubscriber = this._declinedService.decline(declineData).subscribe(
      (success: any) => {
        // TODO
      },
      (error: any) => {
        //console.log(error);
        this.errorMessage = error.error.error_messages;
      });
  }
}
