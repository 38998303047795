import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WaittimeMeterComponent } from './waittime-meter.component';

const routes: Routes = [{
  path: '',
  component: WaittimeMeterComponent,
  children: [
    {
      path: 'appointment/waittime/:id/:keyword', ///:schedule_id/:code
      component: WaittimeMeterComponent          
    },
    {
      path: 'appointment/waittime/:id', ///:schedule_id/:code
      component: WaittimeMeterComponent          
    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaittimeMeterRoutingModule { }
