import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { AdminDetail } from '../../shared/models/adminDetail';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';
import { ActivatedRoute } from '@angular/router';
import {Utils} from '@app/shared/utils';

@Component({
  selector: 'app-admin-profile-user',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['../admin.component.scss','./admin-profile.component.scss'],
  moduleId: module.id,
})
export class AdminProfileComponent implements OnInit {

  adminUserDetail: AdminDetail = new AdminDetail();
  responseError: AdminDetail = new AdminDetail();
  updateNewSuperUserSubscriber: Subscription;
  onGetUserSubscriber: Subscription;
  //loadPracticeListSubscriber: Subscription;
  //practiceList: any = [];
  idSelected: number;
  successMessage: string;
  emailInDb: string = '';
  currentStatus: number;
  responseError1: AdminuserPasswordItem = new AdminuserPasswordItem();

  @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
  @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;
  
  password: string;
  password_confirmation: string;
  notify_to : boolean = false;
  successMessage1 : string;
  updateNewSuperUserPasswordSubscriber : Subscription;
  fullNameTemp: string;
  constructor(
      public _systemData: SystemData,
      //private _systemService: SystemService,
      private _adminService: AdminRestService,
      private _routeParams: ActivatedRoute,
      private _loadingIndicatorService: LoadingIndicatorService,
      private _utils: Utils,
      //private _alertService: AlertService,
      //private _systemEvent: SystemEvent,
  ) {
    
    this._loadingIndicatorService.start();
    this.idSelected = this._systemData.localStorage.getItem('user_id');

      this.onGetUserSubscriber = this._adminService.getUser(this.idSelected).subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          this.adminUserDetail = success.user;
         
          // Set format for phone
          this.adminUserDetail.phone_mobile = this.adminUserDetail.phone_mobile.replace('+1 ', '');
         
        }, (error: any) => {
          this._loadingIndicatorService.stop();
        });
      
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
     
      if (this.updateNewSuperUserSubscriber) {
          this.updateNewSuperUserSubscriber.unsubscribe();
      }
      if (this.onGetUserSubscriber) {
          this.onGetUserSubscriber.unsubscribe();
      }
        if (this.updateNewSuperUserPasswordSubscriber) {
            this.updateNewSuperUserPasswordSubscriber.unsubscribe();
        }
  }

  updateAdminUser(formVals: any) {
        const self = this;
        this.successMessage = null;
        let value = Object.assign({}, formVals);
        this._loadingIndicatorService.start();
        if (this.updateNewSuperUserSubscriber) {
            this.updateNewSuperUserSubscriber.unsubscribe();
        }
        this.fullNameTemp = this._utils.generateFullName(value.first_name, '', value.last_name);

        this.updateNewSuperUserSubscriber = this._adminService.updateAdminUser(this.idSelected, value).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.successMessage = success.error_messages;
                this.responseError = new AdminDetail();
                localStorage.setItem('userFullName', this.fullNameTemp);
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let res = error.error;
                this.responseError = res.error_fields;
            }
        );
  }

  onKeyUp(error: string) {
      switch (error) {
          case 'email':
              this.responseError['email'] = null;
              break;
          case 'phone_mobile':
              this.responseError['phone_mobile'] = null;
              break;          
          case 'first_name':
              this.responseError['first_name'] = null;
              break;
          case 'middle_name':
              this.responseError['middle_name'] = null;
              break;
          case 'last_name':
              this.responseError['last_name'] = null;
              break;          
        case 'password':
                this.responseError['password'] = null;
                break;
            case 'confirm_password':
                this.responseError['confirm_password'] = null;
                break;       
      }
  }
  updatePasswordFromAdmin(formVals: any) {
    const self = this;
    this.successMessage = null;
    let value = Object.assign({}, formVals);
    //console.log(value);

    this._loadingIndicatorService.start();
        if (this.updateNewSuperUserPasswordSubscriber) {
            this.updateNewSuperUserPasswordSubscriber.unsubscribe();
        }
        this.updateNewSuperUserPasswordSubscriber = this._adminService.updatePasswordFromAdmin(this.idSelected, value).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.successMessage1 = success.error_messages;
                this.responseError1 = new AdminuserPasswordItem();
                this.password = null;
                this.password_confirmation = null;
                this.notify_to = false;
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let res = error.error;
                this.responseError1 = res.error_fields;
                this.password = null;
                this.password_confirmation = null;
                this.notify_to = false;
            }
        );
    }
}

export class AdminuserPasswordItem {
    password: string = null;
    password_confirmation: string = null;
    notify_to : boolean = false;
}
