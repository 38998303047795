import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import {RestService} from '@app/core/rest.service';
import {Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {SystemInterval, SystemEvent, SystemData, SystemService} from '@app/shared/system';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
// import { DeleteNotification_ModalsComponent } from '@app/shared/modals/patient/delete-notification';
import {ModalsComponent} from '../modals/delete-notification/delete-notification.component';
// import { AppointmentBook_ModalComponent } from '@app/shared/modals/patient/appointment-book/appointmentBook';
import {DataTableDirectives, RtnDataTableCoreComponent} from '@app/shared/datatable';
import {AlertService} from '@app/shared/alert';
import {Subscription} from 'rxjs';
import {NotificationsService} from '@app/notifications/notifications.service';
import {Module} from '@app/core/module';
import {EmbedBookModalComponent} from '@app/notifications/modals/embed-book-modal/embed-book-modal.component';


@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  moduleId: module.id,
  providers: [RestService, AlertService, SystemEvent, Utils, SystemData, LoadingIndicatorService]
})
export class NotificationListComponent implements OnInit, OnDestroy, AfterViewInit {
  isRead = false;
  getNotificationList: any = new Object();
  numberOfRowDefault = 10;
  itemSelectedDelete = 0;
  getNotificationListSubscriber: Subscription;
  readNotificationSubscriber: Subscription;
  deleteNotificationsSubscriber: Subscription;
  heightOfUnread: number = 0;
  NumOfUnreadNotification: any = 0;
  currentPage: number = 1;
  module: any;

  @ViewChild(ModalsComponent) deleteNotificationDirective: ModalsComponent;
  @ViewChild(EmbedBookModalComponent) appointmentBookDirective: EmbedBookModalComponent;
  @ViewChild(RtnDataTableCoreComponent) rtnDataTableCore: RtnDataTableCoreComponent;

  constructor(
    private _restService: NotificationsService,
    private _router: Router,
    private _utils: Utils,
    private _systemEvent: SystemEvent,
    private _systemData: SystemData,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
    public _systemService: SystemService,
  ) {
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!Module.NOTIFICATION) {
      this._loadingIndicatorService.stop();
      this._router.navigate(['/home', 'page-not-found']);
    } else {
      this.getUnreadNotifications();
      if (this._utils.isPartialActiveStatus()) {
        this._router.navigate(['/patient', 'appointment', 'openings']);
      }
    }


  }

  ngAfterViewInit() {
    this._systemEvent.menu.emit('notifications');
    // If click link from notification
    if (location.pathname === '/patient/notification' && this._utils.getUrlParameter('data')) {
      const data = this._utils.getUrlParameter('data');
      const dataDecode = this._utils.decodeBase64UrlParameter(data);
      // this.openAppointmentBook(dataDecode);
    }
  }

  ngOnDestroy() {
    if (this.getNotificationListSubscriber) {
      this.getNotificationListSubscriber.unsubscribe();
    }
    if (this.readNotificationSubscriber) {
      this.readNotificationSubscriber.unsubscribe();
    }
    if (this.deleteNotificationsSubscriber) {
      this.deleteNotificationsSubscriber.unsubscribe();
    }
  }

  handleFetchGetNotificationList(args: any) {
    args.page = args['currentPage'] || this.currentPage;
    this.currentPage = args.page;
    args.limit = args['rowsOnPage'] || this.numberOfRowDefault;
    if (this.getNotificationListSubscriber) {
      this.getNotificationListSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.getNotificationListSubscriber = this._restService.getNotificationList(null, args.page, args.limit).subscribe(
      (success: any) => {
        this.getNotificationList = new Object();
        this.getNotificationList.rowsOnPage = success.limit || this.numberOfRowDefault;
        this.getNotificationList.numberOfPages = success.total_pages || 0;
        this.getNotificationList.data = [];
        this.getNotificationList.data = this.getNotificationList.data.concat(
          this._utils.generateTimeAgoAndCredentials(success.notifications));
        this._loadingIndicatorService.stop();
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
      },
      () => {
        this._loadingIndicatorService.stop();
      }
    );
  }

  readNotification(readStatus: any, notificationId: number) {
    if (readStatus != null || jQuery('#notification-' + (notificationId)).hasClass('read')) {
      return;
    }
    const dataRead = {'is_read': true};
    this.isRead = true;
    if (this.readNotificationSubscriber) {
      this.readNotificationSubscriber.unsubscribe();
    }
    this.readNotificationSubscriber = this._restService.readNotification(notificationId, dataRead).subscribe(
      (success: any) => {
        jQuery('#notification-' + (notificationId)).addClass('read');
        jQuery('#notification-unread-' + (notificationId)).attr('style', 'display: none');
        this._systemEvent.notification.emit('notification_changeNumberOfUnreadNotifications');
        this.getUnreadNotifications();
      },
      (error: any) => {
        const errorJson = error.error;
        if (errorJson.error_code === 'RC081') {
          this._alertService.onClose = () => {
            location.reload();
          };
        }
        this._alertService.showDismissButton = false;
        this._alertService.dismissButtonLabel = 'Close';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Close';
        this._alertService.title = errorJson.error_messages;
        this._alertService.message = '';
        this._alertService.emitter('open');
      }
    );
  }

  refreshData() {
    if (this.currentPage != 1 && this.getNotificationList.data.length == 1) {
      this.currentPage = this.currentPage - 1;
      this.rtnDataTableCore.setPage(this.currentPage, this.numberOfRowDefault);
    }
    this._systemEvent.notification.emit('notification_getNumberOfUnreadNotifications');
    let args: any;
    args = [{'currentPage': 1, 'rowsOnPage': this.numberOfRowDefault}];
    this.handleFetchGetNotificationList(args);
    // Back to page 1
    // this.rtnDataTableCore.setPage(1, this.numberOfRowDefault);
  }

  callDeleteNotificationDirective(id: any) {
    this.itemSelectedDelete = id;
    this.deleteNotificationDirective.open();
  }

  deleteNotification() {
    if (this.deleteNotificationsSubscriber) {
      this.deleteNotificationsSubscriber.unsubscribe();
    }
    const args = {'notification_ids': [this.itemSelectedDelete]};
    this.deleteNotificationsSubscriber = this._restService.deleteNotifications(args).subscribe(
      (success: any) => {
        const rowId = '#notification-' + this.itemSelectedDelete;
        jQuery(rowId).remove();
        this.refreshData();
      }
    );
  }

  openAppointmentBook(data: string) {
    if (data) {
      let dataJson = JSON.parse(data);
      this._systemData.selectOpenSlotData = dataJson;
      console.log(dataJson);
      
      this._systemEvent.book.emit('book_callGetBookAppointment');
      this.appointmentBookDirective.open();
    }
  }

  getUnreadNotifications() {
    this.deleteNotificationsSubscriber = this._restService.getUnreadNotifications().subscribe(
      (success: any) => {
        this.NumOfUnreadNotification = success.total;
        if(this.NumOfUnreadNotification > 99){
           this.NumOfUnreadNotification = "99+";
        }
        if(this.isRead){
          jQuery('.navbar-nav.main-menu').find('.notification-number').html(String(this.NumOfUnreadNotification));
        }

      }
    );
  }
}
