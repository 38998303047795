import {Component, ViewChild, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
// import { Http } from 'angular2/http';
import {Router, ActivatedRoute} from '@angular/router';
import {HomepageService} from '@app/home/home.service';
import {Subscription} from 'rxjs';
import {Utils} from '@app/shared/utils';
import {SystemData, SystemService, SystemInterval, SystemEvent} from '@app/shared/system';

declare let jQuery: any;

@Component({
    moduleId: module.id,
    templateUrl: './family-member.component.html',
    styleUrls: ['family-member.component.css'],
})
export class AcceptedFamilyComponent implements OnInit, OnDestroy, AfterViewInit {
    family_member_id: string;
    active_token: string;
    errorMessage: any;
    acceptedSubscriber: Subscription;
    minHeight: number;

    constructor(
        private _acceptedService: HomepageService,
        private _utils: Utils,
        public _systemData: SystemData,
        private _router: Router,
        private _activeRoute: ActivatedRoute
    ) {
        // TODO: Get token/ id from URL
        this.family_member_id = this._activeRoute.snapshot.paramMap.get('id');
        this.active_token = this._activeRoute.snapshot.paramMap.get('active_token');
        this.clearLocalStorage();
        if (this._systemData.localStorage.id_token) {
            this._router.navigate(['/patient', 'accepted', {
                family_member_id: this.family_member_id,
                active_token: this.active_token
            }]);
        }
    }

    ngOnInit() {
        // TODO
        this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
    }

    ngAfterViewInit() {
        // TODO: Validate id & token
        if (!this._systemData.localStorage.id_token) {
            this.accept();
        }
    }

    ngOnDestroy() {
        if (this.acceptedSubscriber) {
            this.acceptedSubscriber.unsubscribe();
        }
    }

    createAccount() {
        this._router.navigate(['/Home', 'SignUp']);
    }

    private accept() {
        let acceptData = {
            'id': this.family_member_id,
            'token': this.active_token
        };
        if (this.acceptedSubscriber) {
            this.acceptedSubscriber.unsubscribe();
        }
        this.acceptedSubscriber = this._acceptedService.accept(acceptData).subscribe(
            (success: any) => {
                // TODO
            },
            (error: any) => {
                this.errorMessage = error.error.error_messages;
            });
    }

    private clearLocalStorage() {
        // Remove localStorage role id when logout
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_role_id');
        localStorage.removeItem('user_status');
        localStorage.removeItem('user_email');
        localStorage.removeItem('account_name');
        localStorage.removeItem('user_practice');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('userFullName');
        localStorage.removeItem('managing_user_id');
        // localStorage.removeItem('id_token');
        localStorage.removeItem('user_token');
        localStorage.removeItem('last_active_token');
        localStorage.removeItem('storeData');
        localStorage.removeItem('module_enc');
    }
}
