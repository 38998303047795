<modal #DocumentUpload_Modals class="document-upload">
  <form id="fileupload">
  <!-- action="https://jquery-file-upload.appspot.com/" method="POST" enctype="multipart/form-data" novalidate> -->
      <!-- The fileupload-buttonbar contains buttons to add/delete files and start/cancel the upload -->
      <div class="form-content">
          <div class="header">
              <div class="form-header float-left">Add Files</div>
              <!-- This is rather problematic, there will be an area in the button not showing the not-allowed cursor when the button is disabled
              due to the fact that the input is above the button. Consider HIDING this completely when disabled -->
              <button class="btn btn-rtn btn-upload float-right" (click)="triggerFileChooser($event)" [class.disabled]="addFileDisabled">Choose Files
                  <input id="input-file" type="file" accept="application/pdf" multiple="" (change)="onChange($event)" class="hidden">
              </button>
          </div>
          <div class="content">
              <div class="upload-box">
                  <div class="upload-item" *ngFor="let item of files">
                      <div class="upload-file">
                          <div class="file-name">{{ item.file.name }}</div>
                          <div class="file-size" [innerHTML]="_utils.bytesToSize(item.file.size)"></div>
                          <div class="file-status">
                              <i class="fa fa-times" aria-hidden="true" *ngIf="item.uploadQueue" (click)="removeFile(item.file)"></i>
                              <i class="fa fa-check" aria-hidden="true" *ngIf="item.uploaded"></i>
                              <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" *ngIf="item.uploading"></i>
                              <i class="fa fa-exclamation-circle color-error" aria-hidden="true" *ngIf="item.uploadError"></i>
                          </div>
                      </div>
                      <div class="message-error" [class.hidden]="item.message == null">{{ item.message }}</div>
                  </div>
              </div>
              <div class="progress">
                  <div class="progress-bar-border">
                      <div class="progress-bar" [style.width]=percentProgress></div>
                  </div>
                  <div class="progress-status">{{ uploadedFile }}/{{ totalFile }}</div>
              </div>
          </div>
      </div>
      <div class="button">
          <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" (click)="closePopup($event)" [disabled]="uploaded">{{ cancelButtonText }}</button>
          <button type="submit" class="btn btn-rtn btn-90 float-right focus-button" (click)="uploadClicked($event)" [disabled]="uploading || startDisabled">{{ startButtonText }}</button>
      </div>
  </form>
  <rtn-modals-confirmation [showIcon]="true" (OnYesClicked)="confirmationYesClicked()" (OnNoClicked)="confirmationNoClicked()"></rtn-modals-confirmation>
</modal>
