import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {PasswordFormatTooltipComponent} from '@app/shared/tooltip/password-format';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../shared/alert';
import {HomepageService} from '../home.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

@Component({
  selector: 'rtn-home-change-password-expired',
  templateUrl: './change-password-expired.component.html',
  styleUrls: ['../home.component.scss', './change-password-expired.component.scss']
})
export class ChangePasswordExpiredComponent implements OnInit {

  resetForm: FormGroup;
  linkIsValid: boolean = true;
  error: boolean = false;
  errorMessage: any;
  responseError: PasswordItem = new PasswordItem();
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
  minHeight: number;

  changePasswordSubscriber: Subscription;

  @ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _homePageService: HomepageService,
    private _params: ActivatedRoute,
    private _router: Router,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.resetForm = this._formBuilder.group({
      email: ['', Validators.compose([Validators.required])],
      old_password: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      password_confirmation: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    //TODO: Get token/ id from URL
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
  }

  reset() {
    var self = this;
    var resetData = {
      'password': this.resetForm.value.password,
      'password_confirmation': this.resetForm.value.password_confirmation,
      'email': this.resetForm.value.email,
      'old_password': this.resetForm.value.old_password,
    };
    // Call to service change password
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.changePasswordSubscriber = this._homePageService.changePasswordExpired(resetData).subscribe(
      (success: any) => {
        self._loadingIndicatorService.stop();
        // Todo
        self.error = false;
        self.email = null;
        self.password = null;
        self.password_confirmation = null;
        self.old_password = null;
        self.responseError = new PasswordItem();
        self.error = false;
        self._alertService.onClose = () => {
          self._router.navigate(['/home', 'login']);
        };
        self._alertService.showDismissButton = false;
        self._alertService.dismissButtonLabel = 'Close';
        self._alertService.showCloseButton = true;
        self._alertService.closeButtonLabel = 'OK';
        self._alertService.title = 'Your password is reset successfully!';
        self._alertService.message = '';
        self._alertService.emitter('open');
      }, (error: any) => {
        if (error.error.error_fields) {
          self.responseError = error.error.error_fields;
        } else {
          self.responseError = new PasswordItem();
        }
        if (error.error.error_messages && error.error.error_messages != '') {
          self.error = true;
        } else {
          self.error = false;
        }
        self.errorMessage = error.error.error_messages;
        self._loadingIndicatorService.stop();
      });
  }

  ngOnDestroy() {
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
  }
}

export class PasswordItem {
  email: string = '';
  old_password: string = '';
  password: string = '';
  password_confirmation: string = '';
}
