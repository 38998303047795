<div class="provider-edit">
        <div class="padding-left-15">
            <h3 class="text-header-form-edit">
                My Profile
            </h3>
        </div>
            <div class="row">
                <div class="col-12 col-lg-8 no-padding">
                    
                    <div class="card-edit-profile card-default">
                        <div class="card-header">
                            <h3 class="card-title text-title-form-edit">Contact Info</h3>
                        </div>
                        <div class="card-body">
                            <form method="post" #form="ngForm" (ngSubmit)="updateAdminUser(form.value)" class="padding-left-right-15">
                            <div class="row mb-3">
                                <div class="col-md-6 no-padding-left inline-edit-provider">
                                    <span class="text-label-style input-label-required">First Name</span>
                                    <input class="form-control textbox-edit-form" type="text" [(ngModel)]="adminUserDetail.first_name" name="first_name" ngControl="first_name" [class.input-error]="responseError['first_name']" maxlength="50" (keyup)="onKeyUp('first_name')">
                                    <span class="message-error" *ngIf="responseError['first_name']">{{responseError['first_name']}}</span>
                                </div>                                
                                <div class="col-md-6 no-padding inline-edit-provider">
                                    <span class="text-label-style input-label-required">Last Name</span>
                                    <input class="form-control textbox-edit-form" type="text" [(ngModel)]="adminUserDetail.last_name" name="last_name" ngControl="last_name" [class.input-error]="responseError['last_name']" maxlength="50" (keyup)="onKeyUp('last_name')">
                                    <span class="message-error" *ngIf="responseError['last_name']">{{responseError['last_name']}}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6 no-padding-left inline-edit-provider email-address">
                                    <span class="text-label-style input-label-required">Email Address</span>
                                    <input class="form-control textbox-edit-form" type="text" ngControl="email" [class.input-error]="responseError['email']" [(ngModel)]="adminUserDetail.email" name="email" maxlength="50" (keyup)="onKeyUp('email')" readonly>
                                    <span class="message-error" *ngIf="responseError['email']">{{responseError['email']}}</span>
                                </div>
                                <div class="col-md-6 no-padding">
                                    <div class="no-padding-left-right inline-edit-provider">
                                        <div class="text-label-style input-label-required">Phone Number</div>
                                        <div>
                                            <div class="col-12 col-md-12 no-padding-left-right">
                                                <input class="form-control textbox-edit-form" ngControl="phone_mobile" type="text" [class.input-error]="responseError['phone_mobile']" [(ngModel)]="adminUserDetail.phone_mobile" name="phone_mobile" (keyup)="onKeyUp('phone_mobile')" mask="(000) 000-0000" [showMaskTyped] = "true" [dropSpecialCharacters]="false">
                                                <span class="message-error">{{ responseError.phone_mobile }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 padding-bottom-20 no-padding-left">
                                    <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">SAVE</button>
                                    <span class="padding-left-15 message-success">{{ successMessage }}</span>
                                </div>
                            </div>
                            </form>  
                        </div>
                    </div>
                     
                </div>
                <!--<div class="col-12 col-lg-4 no-padding">
                    <form method="post" #pwdform="ngForm" (ngSubmit)="updatePasswordFromAdmin(pwdform.value)" class="row padding-left-right-15">
                        <div class="card-edit-profile card-default">
                            <div class="card-header">
                                <h3 class="card-title text-title-form-edit">Change Password</h3>
                            </div>
                            <div class="card-body row">
                                <div class="col-md-12 row no-padding mb-3">
                                    <span class="text-label-style input-label-required">Password</span>
                                    <input class="form-control textbox-edit-form" type="password" [(ngModel)]="password" name="password" ngControl="password" [class.input-error]="responseError['password']" maxlength="50" (keyup)="onKeyUp('password')">
                                    <span class="message-error" *ngIf="responseError['password']">{{responseError['password']}}</span>
                                </div>
                                <div class="col-md-12 row no-padding mb-3">
                                    <span class="text-label-style input-label-required">Confirm Password</span>
                                    <input class="form-control textbox-edit-form" type="password" [(ngModel)]="password_confirmation" name="password_confirmation" ngControl="password_confirmation" [class.input-error]="responseError['password_confirmation']" maxlength="50" (keyup)="onKeyUp('password_confirmation')">
                                    <span class="message-error" *ngIf="responseError['password_confirmation']">{{responseError['password_confirmation']}}</span>
                                </div>
                                <div class="col-md-12 row no-padding mb-3">
                                    <span class="text-label-style">
                                    <input type="checkbox" [(ngModel)]="notify_to" name="notify_to" ngControl="ngControl" value="true"  /> Send Password to mail</span>
                                </div>
                                <div class="col-12 padding-bottom-20 no-padding-left">
                                    <button class="container btn btn-rtn btn-90 text-uppercase" type="submit">Update</button>
                                    <span class="padding-left-15 message-success">{{ successMessage1 }}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>-->
            </div>
      </div>
    