<modal #ScheduleCheckIn_Modals class="appointment-check-in">
    <form>
        <div class="form-content">
            <div class="form-header">Check In {{ eventEntry?.data?.patientName }}</div>
            <div class="offset-lg-2">
                <div class="row">
                    <div class="form-input-label pt-1">Time</div>
                    <div class="input-group select mx-3">
                        <select class="form-control" [class.input-error]="requireMessage != null" [ngModel]="checkInModel.hour"
                                (ngModelChange)="checkInModel.hour = $event" (click)="onSelectClicked()" name="select-check-in">
                            <option *ngFor="let h of hours" [value]="h" [attr.name]="h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                            <i class="fa fa-caret-down"></i>
                        </div>
                    </div>
                    <div class="pt-1">:</div>
                    <div class="input-group mx-3">
                        <input type="text" class="form-control" [ngModel]="checkInModel.minute"
                               (ngModelChange)="checkInModel.minute = $event" name="check-in-minute"/>
                    </div>
                </div>

            </div>
            <div class="col-xs-12 message-error">{{ requireMessage }}</div>
        </div>
        <div class="button">
            <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-rtn btn-90 float-right" (click)="checkInClicked()" autofocus>Check In
            </button>
        </div>
    </form>
    <rtn-modals-confirmation [showIcon]="false" (OnYesClicked)="confirmationYesClicked()"
                             (OnNoClicked)="confirmationNoClicked()"></rtn-modals-confirmation>
</modal>
