import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {SuperManagingUserDetail} from '@app/shared/models/superManagingUserDetail';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {SystemData} from '@app/shared/system/system-data';
import {SystemEvent} from '@app/shared/system/system-event';
import {SystemService} from '@app/shared/system/system.service';
import {AlertService} from '@app/shared/alert/alert.service';
import {Utils} from '@app/shared/utils';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {RestService} from '@app/core/rest.service';
import {ProvidersService} from '@app/providers/providers.service';

@Component({
  selector: 'app-add-managing-user',
  templateUrl: './add-managing-user.component.html',
  styleUrls: ['./add-managing-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddManagingUserComponent implements OnInit, OnDestroy {
  managingUserDetail: SuperManagingUserDetail = new SuperManagingUserDetail();
  responseError: SuperManagingUserDetail = new SuperManagingUserDetail();
  addNewManagingUserSubscriber: Subscription;
  loadPracticeListSubscriber: Subscription;
  practiceList: any = [];
  practiceDefault: any;

  constructor(
      private _router: Router,
      public _systemData: SystemData,
      private _systemEvent: SystemEvent,
      private _systemService: SystemService,
      private _alertService: AlertService,
      private _restService: ProvidersService,
      private _utils: Utils,
      private loadingIndicatorService: LoadingIndicatorService
  ) {}

  ngOnInit() {
    if (this._utils.accessManageUser()) {
      this._systemService.getStates();
      if (this.loadPracticeListSubscriber) {
        this.loadPracticeListSubscriber.unsubscribe();
      }
      this.loadPracticeListSubscriber = this._restService.getPracticeList().subscribe(
          (success: any) => {
            this.practiceList = success.practices;
            // Set default value for office
            this.managingUserDetail.office_name = this.practiceDefault = localStorage.getItem('user_practice');
          }
      );
    }
  }

  ngOnDestroy() {
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
    if (this.addNewManagingUserSubscriber) {
      this.addNewManagingUserSubscriber.unsubscribe();
    }
  }

  addManagingUserForm(formVals: any) {
    const self = this;
    this.loadingIndicatorService.start();
    let value = Object.assign({}, formVals);
    if (this.addNewManagingUserSubscriber) {
      this.addNewManagingUserSubscriber.unsubscribe();
    }
    value.type = 'MANAGING_USER';
    value.practice_id = this.practiceDefault;
    this.addNewManagingUserSubscriber = this._restService.addNewManagingUser(value).subscribe(
        (success: any) => {
          this.loadingIndicatorService.stop();
          this._alertService.onClose = () => {
            this._router.navigate(['/provider', 'user']);
          };
          this._alertService.showDismissButton = false;
          this._alertService.dismissButtonLabel = 'Close';
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'OK';
          this._alertService.title = success.error_messages;
          this._alertService.message = '';
          this._alertService.emitter('open');
        },
        (error: any) => {
          this.loadingIndicatorService.stop();
          const res = error.error;
          this.responseError = res.error_fields;
        }
    );
  }

  onKeyUp(error: string) {
    switch (error) {
      case 'email':
        this.responseError['email'] = null;
        break;
      case 'phone_mobile':
        this.responseError['phone_mobile'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'first_name':
        this.responseError['first_name'] = null;
        break;
      case 'middle_name':
        this.responseError['middle_name'] = null;
        break;
      case 'last_name':
        this.responseError['last_name'] = null;
        break;
      case 'office_name':
        this.responseError['office_name'] = null;
        break;
    }
  }
}
