<div *ngFor="let item of data; let $j = index" class="row">
  <rtn-opening-hour-slot class="px-0 col-12"
  [slot]="item.item"
  [checkStatus]="dayCheckStatus"
  [resetOnDisabled]="resetOnDisabled"
  [isDisabled]="item.isDisabled"
  [checked]="item.checkedStatus"
  [index]="$j"
  (onDayStatusChange)="onDayStatusChange($event)"
  (onSlotStatusChange)="onSlotStatusChange($event)"
  (clearError)="clearError($event)"
  ></rtn-opening-hour-slot>
</div>
