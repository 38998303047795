import {Injectable, EventEmitter} from '@angular/core';

declare let jQuery: any;

/**
 * Singleton service, injected at app level
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  private _indicator$: EventEmitter<any>;
  private _numberOfCalls = 0;

  constructor() {
    this._indicator$ = new EventEmitter();
  }

  get indicator$() {
    return this._indicator$;
  }

  public start() {
    this._numberOfCalls++;
    this.toggle();
  }

  public stop() {
    this._numberOfCalls = this._numberOfCalls < 1 ? 0 : this._numberOfCalls - 1;
    this.toggle();
  }

  private toggle() {
    let state = true;
    if (this.indicator$) {
      jQuery('body').addClass('screen-loading-open');
      if (this._numberOfCalls < 1) {
        jQuery('body').removeClass('screen-loading-open');
        state = false;
      }
      this.indicator$.next(state);
    }
  }


}
