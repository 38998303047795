import {Type} from '@angular/core';
import {ModalComponent} from '@app/shared/modal/modal.component';
import {ModalHeaderComponent} from '@app/shared/modal/modal-header';
import {ModalBodyComponent} from '@app/shared/modal/modal-body';
import {ModalFooterComponent} from '@app/shared/modal/modal-footer';
import {AutofocusDirective} from '@app/shared/modal/auto-focus.directive';

export * from '@app/shared/modal/modal.component';
export * from '@app/shared/modal/modal-header';
export * from '@app/shared/modal/modal-body';
export * from '@app/shared/modal/modal-footer';
export * from '@app/shared/modal/modal-instance';

export const MODAL_DIRECTIVES: Type<any>[] = [
  ModalComponent,
  ModalHeaderComponent,
  ModalBodyComponent,
  ModalFooterComponent,
  AutofocusDirective
];
