import {Component, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
// import { CORE_DIRECTIVES } from 'angular2/common';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';
import {SimpleModalsService} from '@app/shared/modals/simple-modals/simple-modals.service';
import {Router} from '@angular/router';

@Component({
  selector: 'rtn-simple-modals',
  // directives: [CORE_DIRECTIVES, ModalComponent, MODAL_DIRECTIVES],
  template: `
    <modal #modal class="simple-modal"
           [tabindex]="simpleModals.tabindex"
           [size]="simpleModals.size"
           [animation]="simpleModals.animation"
           [keyboard]="simpleModals.keyboard"
           [backdrop]="simpleModals.backdrop"
           (onOpen)="simpleModals.onOpen($event)"
           (onDismiss)="simpleModals.onDismiss($event); _lockOpen = false;"
           (onFunction)="simpleModals.onFunction($event); _lockOpen = false;"
           (onClose)="simpleModals.onClose($event); _lockOpen = false;">
      <div *ngIf="simpleModals.title" class="modal-header">
        <h4 class="modal-title" [innerHTML]="simpleModals.title"></h4>
      </div>
      <div *ngIf="simpleModals.message" class="modal-body" [innerHTML]="simpleModals.message"></div>
      <div *ngIf="simpleModals.showDefaultButtons" class="modal-footer">
        <button *ngIf="simpleModals.showFnButton" type="button" class="btn btn-rtn-inv btn-120 ml-3"
                (click)="modal.function()" [innerHTML]="simpleModals.fnButtonLabel"
                [class.btn-rtn-reschedule]="this._router.url === '/patient/appointment/openings' || this._router.url.split('?')[0] === '/patient/appointment/find'"></button>
        <button *ngIf="simpleModals.showDismissButton" type="button" class="btn btn-rtn-inv btn-90" data-dismiss="modal"
                (click)="modal.dismiss()" [innerHTML]="simpleModals.dismissButtonLabel" autofocus></button>
        <button *ngIf="simpleModals.showCloseButton" type="button" class="btn btn-rtn btn-90" (click)="modal.close()"
                [innerHTML]="simpleModals.closeButtonLabel"></button>
      </div>
    </modal>
  `,
  encapsulation: ViewEncapsulation.None
})
export class SimpleModalsComponent implements OnDestroy {
  _lockOpen = false;
  @ViewChild('modal') private modal: ModalComponent;

  private _modalSubscription: any;

  constructor(public simpleModals: SimpleModalsService,
              private _router: Router,) {
    this.simpleModals.tabindex = '-1';
    this._modalSubscription = this.simpleModals.indicator$.subscribe((mode: string) => {
      switch (mode) {
        case 'open':
          if (!this._lockOpen) {
            this.modal.open();
            this._lockOpen = true;
          }
          break;
        case 'close':
          this.modal.close();
          break;
        default:
          this.modal.dismiss();
          break;
      }
    });
  }

  ngOnDestroy() {
    if (this._modalSubscription) {
      this._modalSubscription.unsubscribe();
    }
  }
}
