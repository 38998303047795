import {Component, ViewChild, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewEncapsulation} from '@angular/core';
import {DataLogsService} from '@app/data-logs/data-logs.service';
import {DataTableDirectives, RtnDataTableFilterComponent, RtnDataTableCoreComponent} from '@app/shared/datatable';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Utils} from '@app/shared/utils';
import {Item} from '@app/shared/models/multipleSelect';
import {Subscription} from 'rxjs';
// import { AdminRestService } from './../admin.service';
import {RtnCalendarComponent} from '@app/shared/calendar/calendar.component';
import {Select2Provider2Directive} from '@app/shared/form/select2-provider2.directive';
import {Select2PatientDirective} from '@app/shared/form/select2-patient.directive';
import {Select2PracticeDirective} from '@app/shared/form/select2-practice.directive';
import {SearchDataLog} from '@app/shared/models/searchDataLog';
import * as moment from 'moment';
import {Select2PracticeDirective2} from '@app/shared/form/select2-practice.directive2';
import {Select2AjaxpatientDirective} from '@app/shared/form/select2-ajaxpatient.directive';

declare let jQuery: any;

// @NgModule({
//     declarations: [Select2Provider2Directive, Select2PatientDirective,Select2PracticeDirective],
//     imports:[Select2Provider2Directive, Select2PatientDirective,Select2PracticeDirective]
// })
@Component({
  moduleId: module.id,
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, OnDestroy {
  practiceDefaultType = 'Select Practice/Hospital';
  patientDefaultType = 'Patients: All';
  dataLogList: any = new Object();
  practiceSelected: string;
  patientsSelected: string;
  errorMessage: string;

  public practice = 1;

  IdPracticeDefault: number;
  practices: any = [];
  providers: any = [];
  patients: any = [];

  patientsId: any = [];
  errorDateTo = '';
  searchDataLog: SearchDataLog = new SearchDataLog();
  errorResponse: SearchDataLog = new SearchDataLog();
  searchDataLogResult: any;
  currentPageDataLog: any;
  currentLimitDataLog: any;
  settingRowOnPage = 10;
  showDataLogTable = true;

  messageResponseErrorSearchOpen: string = null;
  noResult = '';

  ngDatePicker: any;
  _weekStart = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)
  hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
    '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

  @ViewChild('inputDatePlanCheckIn') inputDatePlanCheckIn: ElementRef;
  @ViewChild('clickDatePlanCheckIn') clickDatePlanCheckIn: ElementRef;
  @ViewChild('inputDatePlanCheckInTo') inputDatePlanCheckInTo: ElementRef;
  @ViewChild('clickDatePlanCheckInTo') clickDatePlanCheckInTo: ElementRef;
  @ViewChild('select2PracticeDirective') practicesSelector: Select2PracticeDirective;
  @ViewChild('select2Provider2Directive') providersSelector: Select2Provider2Directive;
  @ViewChild('select2PatientDirective') patientsSelector: Select2PatientDirective;
  @ViewChild('table') dataTableSearchDataLog: RtnDataTableCoreComponent;
  @ViewChild('Select2AjaxpatientDirective') ajaxpatientsSelector: Select2AjaxpatientDirective;

  patientTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;
  practiceTypeSubscriber: Subscription;
  searchDataLogSubscriber: Subscription;

  public getSelect2Element: String = '.select2-container--default .select2-selection--multiple';
  patientLoadedStatus=false;

  constructor(
    private _dataLogService: DataLogsService,
    private loadingIndicatorService: LoadingIndicatorService,
    // private _router: Router,
    private _utils: Utils
  ) {
    this.init();

    this.ngDatePicker = new Object();
    this.ngDatePicker['todayHighlight'] = true;
    this.ngDatePicker['weekStart'] = this._weekStart;
    this.ngDatePicker['format'] = 'MM dd, yyyy';
    this.searchDataLog.plan_check_in = moment().format('LL');
    this.searchDataLog.plan_check_in_to = moment().format('LL');
    this.searchDataLogResult = {
      data: [],
      rowsOnPage: this.settingRowOnPage,
      numberOfPages: 0
    };
  }

  init() {
    this.dataLogList.data = new Array();
    this.dataLogList.numberOfPages = 0;
    this.dataLogList.rowsOnPage = 10;
    this.errorMessage = null;
  }

  ngOnInit() {
    this.practicesSelector.clearData();
    this.providersSelector.clearData();
    this.practicesSelector.resetSelect2(jQuery('#select2Practice'));
    this.providersSelector.resetSelect2(jQuery('#select2Provider'));
    this.getPractices();
    this.getProviders();
    //this.getPatients();
    this.practiceSelected = this.practiceDefaultType;
    this.patientsSelected = this.patientDefaultType;
  }

  ngAfterViewInit() {
    jQuery(this.getSelect2Element).addClass('mt-0');

    jQuery(this.inputDatePlanCheckIn.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        this.searchDataLog.plan_check_in = jQuery(this.inputDatePlanCheckIn.nativeElement).val();
      }
    });
    jQuery(this.inputDatePlanCheckIn.nativeElement).datepicker('setDate', this.searchDataLog.plan_check_in);
    jQuery(this.clickDatePlanCheckIn.nativeElement).click(() => {
      jQuery(this.inputDatePlanCheckIn.nativeElement).datepicker('show');
    });

    jQuery(this.inputDatePlanCheckInTo.nativeElement).datepicker({
      dateFormat: 'MM dd, yy',
      numberOfMonths: 2,
      showButtonPanel: true,
      changeMonth: true,
      changeYear: true,
      onSelect: (selected: any) => {
        this.searchDataLog.plan_check_in_to = jQuery(this.inputDatePlanCheckInTo.nativeElement).val();
      }
    });
    jQuery(this.inputDatePlanCheckInTo.nativeElement).datepicker('setDate', this.searchDataLog.plan_check_in_to);
    jQuery(this.clickDatePlanCheckInTo.nativeElement).click(() => {
      jQuery(this.inputDatePlanCheckInTo.nativeElement).datepicker('show');
    });
    
    /*const _self = this;
    jQuery(document).ready(function () {
      jQuery('#select2Ajaxpatientblock input.select2-search__field').on('keyup', function(e:any) {
        e.preventDefault();
        let searchTerm = jQuery(this).val();
        //alert(jQuery(this).val());
    
        console.log(typeof searchTerm);
        console.log(searchTerm);
        console.log(_self.patientLoadedStatus);
    
        if (searchTerm !='' && searchTerm != undefined && _self.patientLoadedStatus == false){
            _self.getSearchPatients(searchTerm);
        }
      });
    });*/
  }

  ngOnDestroy() {

  }

  selectPatientClicked(e: any, item: any, dataTable: RtnDataTableCoreComponent) {
    if (jQuery('.select-box').hasClass('open')) {
      e.stopPropagation();
    }
    let count = 0;
    const patientsSelected: any = [];
    this.patientsId = [];
    for (let i = 0; i < this.patients.length; i++) {
      if (item.oj.id === this.patients[i].oj.id) {
        this.patients[i].isCheck = !this.patients[i].isCheck;
      }
      if (this.patients[i].isCheck) {
        patientsSelected.push(this.patients[i].oj.name);
        this.patientsId.push(this.patients[i].oj.id);
        count++;
      }
    }
    if (count === 0 || count === this.patients.length) {
      this.patientsSelected = this.patientDefaultType;
    } else {
      this.patientsSelected = patientsSelected.join(', ');
    }
    // if (this.page === 1) {
    //     this.getInfo(this.page, this.rolesId, this.statusesId, this.searchText);
    // } else {
    //     dataTable.setPage(1, 10);
    // }
  }
  getSearchPatients(searchTerm:any) {
    this.loadingIndicatorService.start();
    if (this.patientTypeSubscriber) {
        this.patientTypeSubscriber.unsubscribe();
    }
    this.patientLoadedStatus = true;
    this.patients = [];
    this.patientTypeSubscriber = this._dataLogService.getSearchPatients(searchTerm).subscribe(
        (success: any) => {
          
            if (success.error_code === 'RC000') {
               // const now = moment(new Date()).seconds(0).milliseconds(0);
                success.patients.forEach((item: any) => {
                    //const dob = moment(item.dob).seconds(0).milliseconds(0);
                    //const duration = moment.duration(now.diff(dob));
                    //const years = duration.asYears();
                    //if (years >= 18) {
                        item.full_name = item.first_name + ' ' + item.last_name + ' - ' + moment(item.dob).format('ll');
                        this.patients.push(item);
                    //}

                });
            }
            this.patientLoadedStatus = false;
            this.loadingIndicatorService.stop();
        },
        (error: any) => {
          this.patientLoadedStatus = false;
            this.loadingIndicatorService.stop();
        });
}
  getPatients() {
    this.loadingIndicatorService.start();
    if (this.patientTypeSubscriber) {
      this.patientTypeSubscriber.unsubscribe();
    }
    this.patientTypeSubscriber = this._dataLogService.getPatients().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          success.patients.forEach((item: any) => {
            item.full_name = item.first_name + ' ' + item.last_name + ' - ' + moment(item.dob).format('ll');
            this.patients.push(item);
          });
        }
        this.loadingIndicatorService.stop();
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
      });
  }

  getProviders() {
    this.loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
      this.providerTypeSubscriber.unsubscribe();
    }
    this.providerTypeSubscriber = this._dataLogService.getProviders().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          success.providers.forEach((item: any) => {
            item.full_name = item.first_name + ' ' + item.last_name;
            this.providers.push(item);
          });
        }
        this.loadingIndicatorService.stop();
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
      });
  }

  getPractices() {
    this.loadingIndicatorService.start();
    if (this.practiceTypeSubscriber) {
      this.practiceTypeSubscriber.unsubscribe();
    }
    this.practiceTypeSubscriber = this._dataLogService.getPractices().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          success.practices.forEach((item: any) => {
            this.practices.push(item);
          });
        }
        this.loadingIndicatorService.stop();
      },
      (error: any) => {
        this.loadingIndicatorService.stop();
      });
  }

  validateDateFromLessDateTo(message: any) {
    const date1 = new Date(this.searchDataLog.plan_check_in);
    const date2 = new Date(this.searchDataLog.plan_check_in_to);
    if (date1 > date2) {
      this.errorDateTo = message;
      return false;
    } else {
      this.errorDateTo = '';
      return true;
    }
  }

  validatePractice(message: any) {
    if (this.practicesSelector.getData() == null || this.practicesSelector.getData().length === 0) {
      this.errorResponse.practice = message;
      this.practicesSelector.inputError = true;
      this.practicesSelector.changeBorderColorPractice();
      return false;
    } else {
      this.errorResponse.practice = null;
      this.practicesSelector.inputError = false;
      this.practicesSelector.changeBorderColorPractice();
      return true;
    }
  }

  clickBtnSearchDataLog(arg: any) {
    const args = {page: arg['currentPage'] || 1, limit: arg['rowsOnPage']};
    if (this.dataTableSearchDataLog) {
      this.dataTableSearchDataLog.setPage(1, this.searchDataLogResult.rowsOnPage);
    }
    const validateDateFromLessDateTo = this.validateDateFromLessDateTo('Date from must be less or equal than date to');
    const validatePractice = this.validatePractice('This field is required');
    if (validatePractice && validateDateFromLessDateTo) {
      this.searchDataLogSlot(args);
    } else {
      this.resetData();
    }
  }

  searchDataLogSlot(args: any) {
    args.page = args['currentPage'] || 1;
    args.limit = args['rowsOnPage'] || this.searchDataLogResult.rowsOnPage;
    this.currentPageDataLog = args.page;
    this.currentLimitDataLog = args.limit;
    this.formattedContructSearchDataLog(args);
    // this.clearDataSaveSettingWhenClickSearch();
  }

  verifyDataSearchAndSaveDataLog() {
    this.searchDataLog.practice = this.practicesSelector.getData();
    this.searchDataLog.provider = this.providersSelector.getData();
    this.searchDataLog.patient = this.ajaxpatientsSelector.getData();
  }

  resetData() {
    this.searchDataLogResult.data = [];
  }

  formattedContructSearchDataLog(args: any, action: string = '', dateCompare: string = '') {
    this.loadingIndicatorService.start();
    this.messageResponseErrorSearchOpen = '';
    this.noResult = '';
    if (this.searchDataLogSubscriber) {
      this.searchDataLogSubscriber.unsubscribe();
    }
    this.verifyDataSearchAndSaveDataLog();
    this.searchDataLogSubscriber = this._dataLogService.searchDataLog(
      args.limit,
      args.page,
      this.searchDataLog.practice,
      this.searchDataLog.provider,
      this.searchDataLog.patient,
      moment(this.searchDataLog.plan_check_in, 'LL').format('YYYY-MM-DD'),
      moment(this.searchDataLog.plan_check_in_to, 'LL').format('YYYY-MM-DD'),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ).subscribe(
      (success: any) => {
        this.loadingIndicatorService.stop();
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res = success;
        if (res.error_code === 'RC000') {
          this.searchDataLogResult.data = res.data || [];
          this.searchDataLogResult.numberOfPages = res.last_page || 0;
          if (this.searchDataLogResult.data.length > 0) {
            this.showDataLogTable = true;
          }
        } else if (res.error_code === 'RC103') {
          this.showDataLogTable = false;
          this.noResult = res.error_messages;
        }
      }, (error: any) => {
        this.loadingIndicatorService.stop();
        this.showDataLogTable = false;
        const response = error.json();
        if (response.error_code === 'RC091') {
          this.messageResponseErrorSearchOpen = response.error_messages;
        }
        if (response.error_code === 'RC020') {
          // this.errorResponse = response.error_fields;
        }
        // this.callDataSearhOpeningBack();
      }, () => {
        this.loadingIndicatorService.stop();
      });
  }
}
