import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppointmentListComponent} from '@app/appointments/appointment-list/appointment-list.component';
import {extract} from '@app/core';
import {Role} from '@app/core/role';
import {AuthGuard} from '@app/core/auth.guard';
import {FindAppointmentsComponent} from '@app/patients/find-appointments/find-appointments.component';
import {AppointmentPreferencesComponent} from '@app/appointments/appointment-preferences/appointment-preferences.component';
import {SearchOpeningsComponent} from '@app/appointments/wait-list/search-openings.component';
import {AppointmentsComponent} from '@app/appointments/appointments.component';
import {ProviderChangePasswordComponent} from '@app/providers/change-password/change-password.component';
//import { ProviderStatisticalTypeofappointmentComponent } from '@app/provider-data-logs/provider-statistical-typeofappointment/provider-statistical-typeofappointment.component';

const routes: Routes = [{
  path: '',
  component: AppointmentsComponent,
  children: [
    {
      path: 'provider/appointment',
      component: AppointmentListComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/appointment/:patientId/:date',
      component: AppointmentListComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/preferences',
      component: AppointmentPreferencesComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/wait-list',
      component: SearchOpeningsComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    },
    {
      path: 'provider/apt/change-password',
      component: ProviderChangePasswordComponent,
      data: {title: extract('RTN'), roles: [Role.PROVIDER]},
      canActivate: [AuthGuard]
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentsRoutingModule {
}
