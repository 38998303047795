import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { PatientDetail } from '../../shared/models/patient-detail';
import { SystemEvent, SystemService, SystemData } from '../../shared/system';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { AlertService } from '../../shared/alert';
import { LoadingIndicatorService } from '../../shared/loading-indicator';
import { InputMaskDirective } from '../../shared/form/input-mask.directive';
import { SimpleMaskDirective } from '../../shared/form/simple-mask.directive';
import { DOBComponent } from '../../shared/dob/dob';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['../admin.component.scss','./add-patient.component.scss'],
  moduleId: module.id,
})
export class AddPatientComponent implements OnInit {

  /*ngDatePicker: any;
    _weekStart: number = 0; // Day of the week start. 0 (Sunday) to 6 (Saturday)*/
    newPatient: PatientDetail = new PatientDetail();
    error: PatientDetail = new PatientDetail();
    dob: string;

    addNewPatientSubscriber: Subscription;

    @ViewChild(DOBComponent) dobDirective: DOBComponent;
    @ViewChild(InputMaskDirective) rtnInputMaskDirective: InputMaskDirective;
    @ViewChild(SimpleMaskDirective) rtnSimpleMaskDirective: SimpleMaskDirective;

    constructor(
        public _systemData: SystemData,
        private _systemService: SystemService,
        private _alertService: AlertService,
        private _adminService: AdminRestService,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        /*this.ngDatePicker = new Object();
        this.ngDatePicker['todayHighlight'] = true;
        this.ngDatePicker['weekStart'] = this._weekStart;
        this.ngDatePicker['format'] = 'MM dd, yyyy';*/
    }

    ngOnInit() {
        this._systemService.getInsuranceList();
    }

    ngAfterViewInit() {
        let _self = this;
        /* jQuery('#insurance_number').bind('keypress', function (e: any) {
            return !(e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57));
        }); */
        jQuery('#insurance_number').bind({paste : function(){_self.onKeyUp('insurance_number');}});
    }

    ngOnDestroy() {
        if (this.addNewPatientSubscriber) {
            this.addNewPatientSubscriber.unsubscribe();
        }
    }

    addPatientForm() {
        this._loadingIndicatorService.start();
        let ignoreArray = [NaN, null, undefined, ''];
        if (ignoreArray.indexOf(this.newPatient.month) > -1 && ignoreArray.indexOf(this.newPatient.day) > -1 && ignoreArray.indexOf(this.newPatient.year) > -1) {
            this.newPatient.dob = '';
        } else {
            this.newPatient.dob = this.newPatient.year + '-' + this.newPatient.month + '-' + this.newPatient.day;
        }
        this.newPatient.state_code = this.newPatient.state_code !== '' ? this.newPatient.state_code : null;
        this.newPatient.user_role = 'ADMIN';
        if (this.addNewPatientSubscriber) {
            this.addNewPatientSubscriber.unsubscribe();
        }
        this.addNewPatientSubscriber = this._adminService.addNewPatient(this.newPatient).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this._alertService.onClose = () => {
                    this.dob = undefined;
                    this.error = new PatientDetail();
                    this.newPatient = new PatientDetail();
                    jQuery('#insurance-number').removeClass('hidden');
                };
                this._alertService.showDismissButton = false;
                this._alertService.showCloseButton = true;
                this._alertService.closeButtonLabel = 'OK';
                this._alertService.title = success.error_messages;
                this._alertService.message = '';

                this._alertService.emitter('open');
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let e = error.error;
                if (e.error_code === 'RC045' || e.error_code === 'RC020') {
                    this.error = e.error_fields;
                } else if (e.error_code === 'RC107' || e.error_code === 'RC106' || e.error_code === 'RC110' || e.error_code === 'RC111') {
                    this.openErrorMessage(e.error_messages);
                }
            }, () => {
                this._loadingIndicatorService.stop();
            }
        );
    }

    openErrorMessage(msg: string) {
        this._alertService.onClose = () => {
            //location.reload();
        };
        this._alertService.showDismissButton = false;
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'OK';
        this._alertService.title = msg;
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    changeInsurance(plan: any) {
        this.error.insurance_id = null;
        if (plan === '-1') {
            this.newPatient.insurance_number = '';
            jQuery('#insurance-number').addClass('hidden');
        } else {
            jQuery('#insurance-number').removeClass('hidden');
        }
    }

    onKeyUp(key: string) {
        switch (key) {
            case 'email':
                this.error.email = null;
                break;

            case 'first_name':
                this.error.first_name = null;
                break;

            case 'last_name':
                this.error.last_name = null;
                break;

            case 'dob':
                this.error.dob = null;
                break;

            case 'phone_mobile':
                this.error.phone_mobile = null;
                break;

            case 'insurance_id':
                this.error.insurance_id = null;
                break;

            case 'insurance_number':
                this.error.insurance_number = null;
                break;

            case 'zip':
                this.error.zip = null;
                break;
        }
    }

}
