import {Injectable, Inject} from '@angular/core';
import {Http, Response, Request, Headers} from '@angular/http/http';
import {Observable} from 'rxjs';
import {RtnConfig} from '../../shared/rtnconfig';
import {
  RESTBaseUrl, RESTDefaultHeaders, RESTClient
  , RESTGET, RESTPOST
  , RESTQuery, RESTBody
  , RESTProduces, RESTMediaType
} from '../../shared/restclient';

@RESTBaseUrl(RtnConfig['service.URL'])
@RESTDefaultHeaders({
  'Accept': 'application/json'
})

@Injectable()
export class HeaderService extends RESTClient {
  // Todo Service
}
