import {Directive, Input, Output, OnInit, AfterViewInit, OnDestroy, ElementRef, EventEmitter} from '@angular/core';
import jQEvent = JQuery.Event;

// import { CORE_DIRECTIVES, ControlGroup, Control } from 'angular2/common';

@Directive({
  selector: '[inputmask]'
})
export class InputMaskDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input() format: string;
  @Input() greedy = false;
  @Input() validator: string;
  @Input() target: any;
  @Input() field: string;

  @Output() OnComplete: EventEmitter<any> = new EventEmitter();
  @Output() OnIncomplete: EventEmitter<any> = new EventEmitter();
  @Output() OnKeyDown: EventEmitter<any> = new EventEmitter();
  @Output() onBeforePaste: EventEmitter<any> = new EventEmitter();

  public element: any;

  constructor(
    public el: ElementRef
  ) {
    this.element = jQuery(this.el.nativeElement);
  }

  ngOnInit() {
    this.element.inputmask({
      mask: [{'mask': this.format}],
      greedy: this.greedy,
      definitions: {'#': {validator: this.validator, cardinality: 1}},
      'oncomplete': (e: Event) => {
        this.target[this.field] = jQuery(e.target).val();
        this.OnComplete.emit(e);
      },
      'onincomplete': (e: Event) => {
        this.target[this.field] = jQuery(e.target).val();
        this.OnIncomplete.emit(e);
      },
      'onKeyDown': (e: Event, ke: jQEvent) => {
        this.target[this.field] = jQuery(e.target).val();
        this.OnKeyDown.emit(e);
      },
      'onBeforePaste': (e: Event) => {
        this.onBeforePaste.emit(e);
      }
    });
  }

  ngAfterViewInit() {
    this.element.bind('paste', (e: Event) => {
      this.target[this.field] = jQuery(e.target).val();
    });
  }

  ngOnDestroy() {
    // do nothing
  }

  invoke(...args: any[]) {
    this.element.select2.apply(this.element, args);
  }

  open() {
    this.invoke('open');
  }

  close() {
    this.invoke('close');
  }
}
