import { Component, OnInit, AfterViewInit, HostListener }  from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  moduleId: module.id,
})
export class NotFoundComponent implements OnInit, AfterViewInit  {

  constructor(
    private _router: Router
  ) {
    //TODO
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
      this.setPositionCenter();
  }

  ngOnInit() {
      //TODO
  }

  ngAfterViewInit() {
      this.setPositionCenter();
  }

  setPositionCenter() {
      let contentHeight = jQuery('.not-found .container').height();
      // jQuery('.not-found .container').css({'margin-top':(window.innerHeight-contentHeight)/2 - 40 + 'px'}); //40px for the blank space above the truck
  }

  changeToHome(event:any){
    this._router.navigate(['/home', 'homepage']);
  }

}
