import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminRestService } from './../admin.service'
import { LoadingIndicatorService } from '../../shared/loading-indicator';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['../admin.component.scss','./notification.component.scss'],
  moduleId: module.id,
})
export class NotificationComponent implements OnInit, OnDestroy {

  notificationList: any = [];
    messageResponseError: any = [];
    messageResponseSuccess: string = '';
    getNotificationListSubscriber: Subscription;
    submitNotificationListSubscriber: Subscription;

    constructor(
        private _adminService: AdminRestService,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {}

    ngOnInit() {
        if (this.getNotificationListSubscriber) {
            this.getNotificationListSubscriber.unsubscribe();
        }
        this.getNotificationListSubscriber = this._adminService.getNotificationList().subscribe(
            (success: any) => {
                if (success.error_code === 'RC000') {
                    this.notificationList = success.notifications;
                }
            },
            (error: any) => {
                console.log(error);
            }
        );
    }

    ngOnDestroy() {
        if (this.getNotificationListSubscriber) {
            this.getNotificationListSubscriber.unsubscribe();
        }
        if (this.submitNotificationListSubscriber) {
            this.submitNotificationListSubscriber.unsubscribe();
        }
    }

    onKeyDown(event: any) {
        this.messageResponseSuccess = '';
        if (event.keyCode === 13 || (event.keyCode === 13 && event.shiftKey)) {
            event.preventDefault();
        }
    }

    onKeyUp(event: any, id: number) {
        delete this.messageResponseError['notifications.' + id];
    }

    submitNotification(formVals: any) {
        this.messageResponseSuccess = '';
        this._loadingIndicatorService.start();
        let value = Object.assign({}, formVals);
        this.submitNotificationListSubscriber = this._adminService.putNotificationList({notifications: value}).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.messageResponseError = '';
                this.messageResponseSuccess = success.error_messages;
            },
            (error: any) => {
                this._loadingIndicatorService.stop();
                let errorJson = error.error;
                this.messageResponseError = errorJson.error_fields;
                let firstIdError = 0;
                for (let item in errorJson.error_fields) {
                    firstIdError = parseInt(item.match(/[0-9]+/)[0], 10);
                    break;
                }
                if (firstIdError > 0) {
                    jQuery('body, html').animate({
                        scrollTop: jQuery('#' + firstIdError).offset().top - 55
                    }, 'slow');
                }
                this.messageResponseSuccess = '';
            },
            () => {
                this._loadingIndicatorService.stop();
            }
        );
    }

}
