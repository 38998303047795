import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {PasswordFormatTooltipComponent} from '@app/shared/tooltip/password-format';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {AlertService} from '../../shared/alert';
import {ActivatedRoute, Router} from '@angular/router';
import {ProvidersService} from '@app/providers/providers.service';
import { executeInitAndContentHooks } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'rtn-provider-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../providers.component.scss', './change-password.component.scss']
})
export class ProviderChangePasswordComponent implements OnInit, OnDestroy {

  resetForm: FormGroup;
  linkIsValid = true;
  error = false;
  errorMessage: any;
  responseError: PasswordItem = new PasswordItem();
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
  minHeight: number;

  changePasswordSubscriber: Subscription;

  @ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _providerRestService: ProvidersService,
    private _router: Router,
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.resetForm = this._formBuilder.group({
      old_password: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      password_confirmation: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    // TODO: Get token/ id from URL
    // this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight() - 81;
    this.minHeight = window.innerHeight - 81;
  }

  reset() {
    let self = this;
    let resetData = {
      'old_password': this.resetForm.value.old_password,
      'password': this.resetForm.value.password,
      'password_confirmation': this.resetForm.value.password_confirmation,
    };
    // Call to service change password
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    this.changePasswordSubscriber = this._providerRestService.changePasswordProvider(resetData).subscribe(
      (success: any) => {
        self._loadingIndicatorService.stop();   
        if (success.error_code === 'RC000') {
          self.error = false;
          self.email = null;
          self.password = null;
          self.password_confirmation = null;
          self.old_password = null;
          self._alertService.onClose = () => {
            self._router.navigate(['/admin', 'account']);
          };
          self._alertService.showDismissButton = false;
          self._alertService.dismissButtonLabel = 'Close';
          self._alertService.showCloseButton = true;
          self._alertService.closeButtonLabel = 'OK';
          self._alertService.title = 'Your password is reset successfully!';
          this._alertService.message = '';
          self._alertService.emitter('open');
        }
        // Todo
      }, (error: any) => {
        console.log(error.error.error_fields.password);
        if (error.error.error_code === 'RC020') {
          self.responseError = error.error.error_fields;
        } else if (error.error.error_code === 'RC066' || error.error.error_code === 'RC010') {
          if(error.error.error_fields)
          {
            self.responseError = error.error.error_fields;
          }
          else if(error.error.error_messages){
            self.errorMessage = error.error.error_messages;
            self.error = !!(self.errorMessage || self.errorMessage === '');
          }
        } else {
          self.responseError = new PasswordItem();
        }
        self._loadingIndicatorService.stop();
      });
  }

  ngOnDestroy() {
    if (this.changePasswordSubscriber) {
      this.changePasswordSubscriber.unsubscribe();
    }
  }

  onKeyUp(error: string) {
    switch (error) {
      case 'old_password':
        this.responseError['old_password'] = null;
        break;
      case 'password':
        this.responseError['password'] = null;
        break;
      case 'password_confirmation':
        this.responseError['password_confirmation'] = null;
        break;
    }
  }
}

export class PasswordItem {
  old_password = '';
  password = '';
  password_confirmation = '';
}


