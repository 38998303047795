import { Component, OnInit } from '@angular/core';
import {PatientsService} from '@app/patients/patients.service';
import {environment} from '@env/environment';
//import {Router} from '@angular/router';

@Component({
  selector: 'rtn-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss']
})
export class CommonFooterComponent implements OnInit {

  public tenantname:string = '';
  public user_role_id:string;
  public downloadpath: any = environment.serverUrl;

  constructor(
    private _patientService: PatientsService,
  ) { }

  ngOnInit() { //console.log(localStorage.getItem('user_role_id'));
    this.user_role_id = localStorage.getItem('user_role_id')
    this.getSubDomain();
  }
  getSubDomain() {
    const fullDomain = window.location.host;
    const path = fullDomain.split('.');
    if (path[1] == 'dev') {
        this.tenantname = path[0] + '.' + path[1];
    } else {
      this.tenantname = path[0];
    }
  }

  /*downloadFile(filename: string) {
    this._patientService.downloadfile({'filename':filename}).subscribe((success: any) =>  {  

    });
      
  }*/

}
