import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
// import { ModalsService } from '../services/modals.service';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';
// import { AlertService } from '../../../../../shared/alert';
import {EmbedAppointmentBookComponent} from '@app/notifications/modals/embed-appointment-book/embed-appointment-book.component';

@Component({
  moduleId: module.id,
  selector: 'rtn-embed-book-modal',
  templateUrl: './embed-book-modal.component.html',
  styleUrls: ['./embed-book-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
  // directives: [ROUTER_DIRECTIVES, MODAL_DIRECTIVES, BookComponent],
  // providers: [ModalsService]
})

export class EmbedBookModalComponent {

  @ViewChild('BookAppointmentComponent') bookDirective: EmbedAppointmentBookComponent;
  @ViewChild('EmbedBook_Modals') EmbedBook_Modals: ModalComponent;

  constructor(private _router: Router) {}

  open() {
    this.EmbedBook_Modals.open();
  }

  close() {
    this.EmbedBook_Modals.close();
  }

  handlecallEventBookEmitter() {
    this.close();
  }

}
