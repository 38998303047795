export class SuperManagingUserDetail {
   practice_id: number;
    first_name: string = '';
    middle_name: string = '';
    last_name: string = '';
    fullName: string = '';
    dob: string = '';
    office_name: string = '';
    email: string = '';
    phone_mobile: string = '';
    phone_mobile_tmp: string = '';
    address: string = '';
    address2: string = '';
    city: string = '';
    state_code: string = '';
    state: string = '';
    zip: string = '';
    status: number;
}
