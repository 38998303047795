import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
// import {AppointmentBookService} from '@app/appointments/modals/appointment-book/appointment-book.service';
import { AlertService } from '@app/shared/alert';
import { Utils } from '@app/shared/utils';
import { Event } from '@app/appointments/timeline/timeline.component';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { from, Subscription } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { ProvidersService } from '@app/providers/providers.service';

declare let jQuery: any;
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'rtn-modals-appointment-book',
  templateUrl: './appointment-book.component.html',
  styleUrls: ['./appointment-book.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppointmentBookComponent implements AfterViewInit, OnDestroy {
  fetchAvailableSlotSubscriber: Subscription;
  bookAppointmentSubscriber: Subscription;
  searchPatientSubscriber: Subscription;
  deleteSlotSubscriber: Subscription;
  getIsLockSubscriber: Subscription;
  isLock: any;
  msg:string = 'Your account has been locked.';
  currentDate: any = moment();
  // get current date by default

  @ViewChild('AppointmentBook_Modals') modal: ModalComponent;
  @ViewChild('inputDateNewAppointment') inputDateNewAppointment: ElementRef;
  @ViewChild('clickDateNewAppointment') clickDateNewAppointment: ElementRef;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  eventEntry: Event;

  errorMessage: string;
  errorMessageBook: string;
  searchDisabled = true;
  patients: any = [];
  patient_id: number;
  isFamily = false;
  availableSlots: any = [];
  selectedSlot = 0;
  selectedDate: any;

  // ========== Specific Modal Method ========== //
  newAvailableSlotModel: NewAvailableSlotClass = new NewAvailableSlotClass();
  requireMessage: RequireMessage = new RequireMessage();

  fetchAppointmentTypeSubscriber: Subscription;
 
  appointmentTypeList: any = [];
  // show future apt
  futureaptlists: any = [];
  selectedupcommingId: number = 0;
  fetchPatientUpcommingAptSubscriber: Subscription;
  loadedpage : string = 'provider_book';

  constructor(
    private Service: AppointmentsService,
    private _alertService: AlertService,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
    private providersService: ProvidersService
  ) {
    // TODO
    this.newAvailableSlotModel.appointment_reason = '';
    this.newAvailableSlotModel.event_reason_id = '';
    //this.newAvailableSlotModel.description_1  = '';
    //this.newAvailableSlotModel.description_2  = '';
  }

  setInitial() {
    this.selectedSlot = 0;
    this.newAvailableSlotModel.date = moment().format('MMMM DD, YYYY');
    this.selectedDate = this.currentDate;
    this.fetchAppointmentByDate(this.selectedDate);
    //this.fetchAppointmentType();
    this.futureaptlists = [];
    this.selectedupcommingId = 0;
  }

  searchPatientClicked(event: any) {
    this.patients = [];
    this.errorMessage = null;
    this.errorMessageBook = null;
    if (this.newAvailableSlotModel.patient !== undefined) {
      if (this.searchPatientSubscriber) {
        this.searchPatientSubscriber.unsubscribe();
      }

      this.searchPatientSubscriber = this.Service.searchPatient(this.newAvailableSlotModel.patient)
        .subscribe(
          (success: any) => {
            if (success.error_code === 'RC000') {
              success.patients.forEach((item: any) => {
                const patient = new PatientInfoClass();
                patient.id = item.id;
                patient.first_name = item.first_name;
                patient.last_name = item.last_name;
                patient.dob = item.dob !== null ? moment(item.dob, 'YYYY-MM-DD').format('MMMM DD, YYYY') : null;
                patient.phone_mobile = item.phone_mobile;
                patient.is_family = item.is_family;
                jQuery(event.srcElement).closest('.input-group').addClass('open');
                jQuery('.result.dropdown-menu').css({
                  'width': jQuery(event.srcElement).closest('.input-group').width() + 15,
                  'margin-left': '-15px'
                });
                this.patients.push(patient);
              });
            }
            if (success.error_code === 'RC061') {
              jQuery(event.srcElement).closest('.input-group').addClass('open');
              this.errorMessage = success.error_messages;
            }
          },
          (error: any) => {
            const e = error.error;
            if (e.error_code === 'RC061') {
              this.errorMessage = e.error_messages;
            }
          }
        );
    }
  }

  selectSlot(slotId: any) {
    this.selectedSlot = slotId;
    for (let i = 0; i < this.availableSlots.length; i++) {
      const s = this.availableSlots[i];
      s.isSelected = s.id === slotId ? true : false;
    }
    // Fecth appointment types by provider pratice
    this.fetchAppointmentType(slotId);
  }
  showMessage(msg: any) {
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = msg;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }
  bookAppointmentClicked(type: number) {
    this._loadingIndicatorService.start();
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this.isLock = success.providers.is_lock;
        if (this.isLock == true) {
          if (type == 2) {
            this._loadingIndicatorService.stop();
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              window.location.reload();
            };
            this.showMessage(this.msg);
          } else if (type == 1) {
            this._loadingIndicatorService.stop();
            this.dismiss();
            this.close();
            this.showMessage(this.msg);
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              window.location.reload();
            };

          }
        } else {
          if (type == 1) {
            this.bookAppointment();
            this._loadingIndicatorService.stop();
          } else if (type == 2) {
            this.open();
            this._loadingIndicatorService.stop();
          }
        }

      }
    );
  }
  processDeleteSlot(){
    this._loadingIndicatorService.start();
    this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        this.isLock = success.providers.is_lock;
        if (this.isLock) {
            this._loadingIndicatorService.stop();
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
              window.location.reload();
            };
            this.showMessage(this.msg);
          } else{
            this.deleteSlotClicked();
          }
      }
    );
  }
  bookAppointment() {
    this.errorMessageBook = null;
    this._loadingIndicatorService.start();
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }

    let user_provider_loc_id = localStorage.getItem('user_provider_loc_id');

    this.bookAppointmentSubscriber = this.Service.bookAppointment({
      patient_id: this.patient_id,
      slot_id: this.selectedSlot,
      is_family: this.isFamily ? true : false,
      appointment_reason : this.newAvailableSlotModel.appointment_reason,
      event_reason_id : this.newAvailableSlotModel.event_reason_id,
      location_id : user_provider_loc_id,
      reschedule_id : this.selectedupcommingId
      //description_1 : this.newAvailableSlotModel.description_1,
      //description_2 : this.newAvailableSlotModel.description_2,
    })
      .subscribe(
        (res: any) => {
          this._loadingIndicatorService.stop();
          if (res.error_code === 'RC000') {
            this._alertService.onClose = () => {
              this.reload.emit(this.eventEntry);
              this.dismiss();
              this.close();
            };
            this._alertService.showDismissButton = false;
            this._alertService.dismissButtonLabel = 'No';
            this._alertService.showCloseButton = true;
            this._alertService.closeButtonLabel = 'OK';
            this._alertService.title = res.error_messages;
            this._alertService.message = '';
            this._alertService.emitter('open');
          }
          if (res.error_code === 'RC002' || res.error_code === 'RC061' || res.error_code === 'RC119' || res.error_code === 'RC086') {
            this.errorMessageBook = res.error_messages;
          }
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
          const e = error.error;
          if (e.error_code === 'RC002' || e.error_code === 'RC061' || e.error_code === 'RC119') {
            this.errorMessageBook = e.error_messages;
          }
        },
        () => {
          this._loadingIndicatorService.stop();
        }
      );
  }

  getInfoByNameClicked(event: any, id: number, first_name: string, last_name: string, dob: string, is_family: boolean) {
    this.patient_id = id;
    this.isFamily = is_family;
    this.newAvailableSlotModel.patient = first_name + ' ' + last_name + ' - ' + dob;
    this.requireMessage.patient_id = null;
    jQuery(event.srcElement).closest('.input-group').removeClass('open');
    this.fetchPatientUpcommingApt(this.patient_id, 0);

    //console.log('getInfoByNameClicked:' + this.patient_id);
  }

  getInfoByNumberClicked(event: any, id: number, phone_mobile: number, first_name: string, last_name: string) {
    this.patient_id = id;
    this.newAvailableSlotModel.patient = phone_mobile + ' ' + first_name + ' ' + last_name;
    this.requireMessage.patient_id = null;
    jQuery(event.srcElement).closest('.input-group').removeClass('open');

    this.fetchPatientUpcommingApt(this.patient_id, 0);
   // console.log('getInfoByNumberClicked:' + this.patient_id);
  }

  getTime(time: any) {
    // return moment(time).format('hh:mm A');
    return moment(time).format('HH:mm');
  }

  deleteSlotClicked() {
    this._alertService.onClose = () => {
      this._loadingIndicatorService.start();
      if (this.deleteSlotSubscriber) {
        this.deleteSlotSubscriber.unsubscribe();
      }

      this.deleteSlotSubscriber = this.Service.deleteSlot({ slot_id: this.selectedSlot })
        .subscribe(
          (success: any) => {
            this._loadingIndicatorService.stop();
            switch (success.error_code) {
              case 'RC000':
                this.showAlert(success.error_messages);
                this.fetchAppointmentByDate(this.selectedDate);
                break;
              default:
                this.showAlert(success.error_messages);
                this.fetchAppointmentByDate(this.selectedDate);
                break;
            }
            // if (success.error_code === 'RC000') {
            //   this.showAlert(success.error_messages);
            //   this.fetchAppointmentByDate(this.selectedDate);
            // }
          },
          (error: any) => {
            this._loadingIndicatorService.stop();
            const e = error.error;
            // if (e.error_code === 'RC002' || e.error_code === 'RC119') {
            //   this.showAlert(e.error_messages);
            //   this.fetchAppointmentByDate(this.selectedDate);
            // }
          },
          () => {
            this._loadingIndicatorService.stop();
          }
        );
    };
    this._alertService.showDismissButton = true;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'Yes';
    this._alertService.title = 'Are you sure want delete this slot ?';
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  fetchAppointmentByDate(date: any) {
    const startTimeRequest = this._utils.formatTimeForWebServiceCall(date.clone().startOf('day'));
    const endTimeRequest = this._utils.formatTimeForWebServiceCall(date.clone().add(1, 'day').startOf('day'));
    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    
    let user_provider_loc_id = localStorage.getItem('user_provider_loc_id');

    this.fetchAvailableSlotSubscriber = this.Service.fetchAvailableSlotByPeriod(startTimeRequest, endTimeRequest, user_provider_loc_id, 0)
      .subscribe(
        (success: any) => {
          this._loadingIndicatorService.stop();
          this.appointmentTypeList=[]; // Reset the appointment types
          this.newAvailableSlotModel.event_reason_id='';

          if (success.available_slots !== null) {
            this.availableSlots = success.available_slots;
            this.selectedSlot = 0;
          }
        },
        (error: any) => {
          this._loadingIndicatorService.stop();
        }
      );
  }
  fetchAppointmentType(slot_Id:any) {
    
    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
    this._loadingIndicatorService.start();
    // Reset appointment types
    this.appointmentTypeList = [];
    this.newAvailableSlotModel.event_reason_id='';

    this.fetchAppointmentTypeSubscriber = this.Service.getPracticeAppointmentTypeList(slot_Id, 1, 0, null).subscribe(
      (success: any) => {
          this._loadingIndicatorService.stop();
          this.appointmentTypeList = success.apttypes;
      },
      (error: any) => {
        this._loadingIndicatorService.stop();        
      });
  }

  showAlert(message: any) {
    this._alertService.onClose = () => {
      // TODO
    };
    this._alertService.showDismissButton = false;
    this._alertService.dismissButtonLabel = 'No';
    this._alertService.showCloseButton = true;
    this._alertService.closeButtonLabel = 'OK';
    this._alertService.title = message;
    this._alertService.message = '';
    this._alertService.emitter('open');
  }

  // ========== Class Method ========== //

  ngAfterViewInit() {
    jQuery('.search-button').on('click', function (e: any) {
      if (jQuery('.search').hasClass('open')) {
        e.stopPropagation();
      }
    });
    jQuery(this.inputDateNewAppointment.nativeElement).datepicker({
      format: 'MM dd, yy',
      altFormat: 'MM dd, yy',
      numberOfMonths: 2,
      minDate: 0,
      showButtonPanel: true,
      onSelect: (selected: any) => {
        this.selectedDate = moment(selected).local();
        this.newAvailableSlotModel.date = this.selectedDate.format('MMMM DD, YYYY');
        this.fetchAppointmentByDate(this.selectedDate);
        this.errorMessageBook = null;
      }
    });
    jQuery(this.clickDateNewAppointment.nativeElement).click(() => {
      jQuery(this.inputDateNewAppointment.nativeElement).datepicker('show');
    });
    const self = this;
    jQuery(document).on('click', function (e: any) {
      if (jQuery(e.target).hasClass('result dropdown-menu')
        || jQuery(e.target).hasClass('error-message')) {
        // TODO: Close dropdown when click outside
      } else {
        jQuery('#search-field-new-appointment').closest('.input-group').removeClass('open');
      }
    });
  }

  ngOnDestroy() {
    this.selectedSlot = 0;
    if (this.searchPatientSubscriber) {
      this.searchPatientSubscriber.unsubscribe();
    }
    if (this.fetchAvailableSlotSubscriber) {
      this.fetchAvailableSlotSubscriber.unsubscribe();
    }
    if (this.bookAppointmentSubscriber) {
      this.bookAppointmentSubscriber.unsubscribe();
    }
    if (this.deleteSlotSubscriber) {
      this.deleteSlotSubscriber.unsubscribe();
    }

    if (this.fetchAppointmentTypeSubscriber) {
      this.fetchAppointmentTypeSubscriber.unsubscribe();
    }
  }

  // ========== General Modal Method ========== //

  dismiss() {
    this.newAvailableSlotModel.patient = this.patient_id = null;
    this.requireMessage = new RequireMessage();
    jQuery('#search-field-new-appointment').parent().removeClass('open');
  }

  onKeyUp() {
    this.requireMessage.patient_id = null;
    if (jQuery('#search-field-new-appointment').val() === '') {
      this.patient_id = null;
      this.searchDisabled = true;
      jQuery('.search').removeClass('open');
    } else {
      this.searchDisabled = false;
    }
  }

  open() {
    this.modal.open();
    this.setInitial();
  }
  close() {
    this.selectedSlot = 0;
    this.modal.close();
  }
  fetchPatientUpcommingApt(patient_id: any, provider_id: any) {
    
    this.futureaptlists = [];

    this._loadingIndicatorService.start();

    if (this.fetchPatientUpcommingAptSubscriber) {
      this.fetchPatientUpcommingAptSubscriber.unsubscribe();
    }
    this.fetchPatientUpcommingAptSubscriber = this.Service.fetchPatientUpcommingApt(patient_id, provider_id).subscribe((success: any) => {
      this._loadingIndicatorService.stop();
      if (success.aptlist !== undefined) {
        this.futureaptlists = success.aptlist;  
        //console.log(this.futureaptlists);          
      }
    },(error: any) => {
          this._loadingIndicatorService.stop();
    });    
  }
  selecteRescheduleId(id:any){
    console.log('ID  =' +id);
    this.selectedupcommingId = (this.selectedupcommingId == id) ? 0 : id;
    console.log('selectedupcommingId = ' + this.selectedupcommingId);
  }
}

export class NewAvailableSlotClass {
  patient = '';
  date: string;
  appointment_reason:string='';
  event_reason_id:string='';
  //description_1:string='';
  //description_2:string='';
}

export class PatientInfoClass {
  id: number;
  first_name: string;
  last_name: string;
  dob: string;
  phone_mobile: number;
  is_family: boolean;
}

export class RequireMessage {
  patient_id: string;
  date: string;
  start_time: string;
  end_time: string;
  appointment_reason:string='';
  event_reason_id:string='';
  //description_1 :string='';
  //description_2 :string='';
}
