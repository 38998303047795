import { Component, Optional } from '@angular/core';
import { RtnDataTableCoreComponent } from './core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'rtn-table-pager-default',
    template: `<ng-content></ng-content>`
})

export class RtnDataTablePagerComponent {
    public constructor(@Optional() public dataTable: RtnDataTableCoreComponent) {
        // TODO 
    }

    public setPage(pageNumber: number): void {
        this.dataTable.setPage(pageNumber, this.dataTable.rowsOnPage);
    }

    public setRowsOnPage(rowsOnPage: number): void {
        this.dataTable.setPage(this.dataTable.currentPage, rowsOnPage);
    }
}
