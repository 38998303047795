import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WaitTimeComponent} from '@app/wait-time/wait-time.component';
import {NumberWaittimeComponent} from '@app/wait-time/number-waittime/number-waittime.component';
import {extract} from '@app/core';
import {SpeedometerWaittimeComponent} from '@app/wait-time/speedometer-waittime/speedometer-waittime.component';
import {Role} from '@app/core/role';
import {AuthGuard} from '@app/core/auth.guard';

const routes: Routes = [{
  path: '',
  component: WaitTimeComponent,
  children: [
    {
      path: 'wait-time/number-waittime',
      component: NumberWaittimeComponent,
      data: {title: extract('Notification List')}
    },
    {
      path: 'patient/waittime-speedometer',
      component: SpeedometerWaittimeComponent,
      data: {title: extract('Speedometer Waittime'), roles: [Role.PATIENT]},
      canActivate: [AuthGuard]
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaitTimeRoutingModule {
}
