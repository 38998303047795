import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare let jQuery: any;
@Component({
  selector: 'rtn-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss']
})
export class DOBComponent implements OnInit {
  days: any = [];
  months: any = [];
  years: any = [];

  @Input() formInput: any;
  @Input() disabledForm: any;
  @Input() error: any;
  @Output() removeError: EventEmitter<any> = new EventEmitter();
  @Output() callEventOnChange = new EventEmitter();

  constructor(
      //TODO
  ) {
      // do nothing
  }

  ngOnInit() {
      this.months = [{
          name: 'January',
          value: '01'
      }, {
          name: 'February',
          value: '02'
      }, {
          name: 'March',
          value: '03'
      }, {
          name: 'April',
          value: '04'
      }, {
          name: 'May',
          value: '05'
      }, {
          name: 'June',
          value: '06'
      }, {
          name: 'July',
          value: '07'
      }, {
          name: 'August',
          value: '08'
      }, {
          name: 'September',
          value: '09'
      }, {
          name: 'October',
          value: '10'
      }, {
          name: 'November',
          value: '11'
      }, {
          name: 'December',
          value: '12'
      }];

      for (let day = 1; day <= 31; day++) {
          if (day < 10) {
              this.days.push('0' + day);
          } else {
              this.days.push(day);
          }
      }
      let currentTime = new Date();
      let currentYear = currentTime.getFullYear();
      for (let year = currentYear; year >= 1900; year--) {
          this.years.push(year);
      }

      this.setInit();
  }

  setInit() {
      jQuery('.input-dob select > select').css('color', '#999');
      jQuery('.input-dob select > select').change(function() {
          var color = jQuery(this).val() === null ? '#999' : '#555';
          jQuery(this).css('color', color);
      });
  }

  onSelect() {
      this.error = null;
      this.removeError.emit('dob');
  }

  callEventOnChangeEmit() {
      this.callEventOnChange.emit('');
  }
}

