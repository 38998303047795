import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ModalComponent} from '@app/shared/ng2-bs3-modal';
import {ModalsConfirmationComponent} from '@app/shared/modals/modal-confirmation/modal-confirmation.component';
import {Subscription} from 'rxjs';
import {RestService} from '@app/core/rest.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';
import {SystemData} from '@app/shared/system';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'app-active-module-modal',
  templateUrl: './active-module-modal.component.html',
  styleUrls: ['./active-module-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActiveModuleModalComponent implements OnInit, OnDestroy {


  @ViewChild('ActiveModule_Modals') modal: ModalComponent;
  @ViewChild(ModalsConfirmationComponent) confirmation: ModalsConfirmationComponent;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  // ========== Specific Modal Method ========== //
  private _onClose: Function;
  private _onOk: Function;
  saveMessage: string;

  constructor(
      private service: RestService,
      private _loadingIndicatorService: LoadingIndicatorService,
      public _systemData: SystemData
  ) {
    // TODO

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  // ========== General Modal Method ========== //

  modalMessage(moduleName: string): void {
    this.saveMessage = 'Do you want to active module ' + moduleName + ' for this practice?';
  }

  openDialog(moduleName: string) {
    this.modal.open();
    this.modalMessage(moduleName);
  }

  close() {        
    this._onClose();
    this.modal.close();
  }

  public set onClose(_onClose: Function) {
    this._onClose = _onClose;
  }

  public set onOk(_onOk: Function){
    this._onOk = _onOk;
  }

  saveActiveModule() {
    this._onOk();
    this.modal.close();
  }
}
