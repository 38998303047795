import { Component, OnInit, ViewChild} from '@angular/core';
import { QuickAptbookingService } from '@app/quick-aptbooking/quick-aptbooking.service';
import { Utils } from '@app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Subscription } from 'rxjs';
import {EmbedBookModalComponent} from '@app/quick-aptbooking/modals/embed-book-modal/embed-book-modal.component';
import {SystemInterval, SystemEvent, SystemData, SystemService} from '@app/shared/system';
import * as moment from 'moment';

@Component({
  selector: 'app-quick-aptbooking',
  templateUrl: './quick-aptbooking.component.html',
  styleUrls: ['./quick-aptbooking.component.scss']
})
export class QuickAptbookingComponent implements OnInit {
  isFirefox = false;
  errorMessage: string = '';
  openslotId: any=''
  searchOpeningSubscriber: Subscription;
  searchOpeningsResult: any = [];

  @ViewChild(EmbedBookModalComponent) appointmentBookDirective: EmbedBookModalComponent;
  
  constructor(private QuickBookingService: QuickAptbookingService, 
    private _router: Router,
    public _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _systemEvent: SystemEvent,
    private _systemData: SystemData,
    ) {
  }

  ngOnInit() {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (is_firefox) {
      this.isFirefox = true;
    }

    const currentUrl = this._router.url;
    const splitArr = currentUrl.split("/");

    const code = splitArr[splitArr.length - 1];

    if (code == null || code == undefined) {
      this.errorMessage = "Invalid request";
    } else {
      this.openslotId = code;
    }

    if (this.openslotId != '') {
      this.getOpenslotDetails();
    }
  }
  ngOnDestroy() {
    if (this.searchOpeningSubscriber) {
      this.searchOpeningSubscriber.unsubscribe();
    }    
  }
  reloadSlots(){
    this.getOpenslotDetails();
  }

  getOpenslotDetails() {
    var self = this;
    if (this.searchOpeningSubscriber) {
      this.searchOpeningSubscriber.unsubscribe();
    }
    
    this.errorMessage = '';

    this._loadingIndicatorService.start();
    this.QuickBookingService.getOpenslotDetails(self.openslotId).subscribe((success: any) => {
      this._loadingIndicatorService.stop();

      if (success.error_code === 'RC000') {
        this.searchOpeningsResult = success.openings;
        if (this.searchOpeningsResult.length == 0) {
          this.errorMessage = 'No opening slot available';
        }
      } else if (success.error_code === 'RC152' || success.error_code === 'RC153' || success.error_code === 'RC154') {
        this.errorMessage = success.error_messages;
      }
      
      console.log(this.errorMessage);

    }, (error:any) => {
      this._loadingIndicatorService.stop();
      this.errorMessage = 'Try after some time';
    });
  } 
  goToBookAppointment(open_slot_id:any='', provider_id: any = '', slot_id: any = '', practice_open_notify_type:any=1) {
    // Set data for book appt
    this._systemData.selectOpenSlotData.open_slot_id = open_slot_id;
    this._systemData.selectOpenSlotData.provider_id = provider_id;
    this._systemData.selectOpenSlotData.slot_id = slot_id;
    this._systemData.selectOpenSlotData.practice_open_notify_type = practice_open_notify_type;

    /*this._systemData.selectOpenSlotData.start_time = date + ' ' + moment(start_time, ['HH:mm']).format('HH:mm');
    this._systemData.selectOpenSlotData.start_time = moment(this._systemData.selectOpenSlotData.start_time).utc();
    
    this._systemData.selectOpenSlotData.end_time = date + ' ' + moment(end_time, ['HH:mm']).format('HH:mm');
    this._systemData.selectOpenSlotData.end_time = moment(this._systemData.selectOpenSlotData.end_time).utc();
    //slot id
    this._systemData.selectOpenSlotData.slot_Id = slot_id;
    if (moment(this._systemData.selectOpenSlotData.start_time).isAfter(this._systemData.selectOpenSlotData.end_time)) {
        this._systemData.selectOpenSlotData.end_time
            = moment(this._systemData.selectOpenSlotData.end_time, 'YYYY-MM-DD HH:mm').add(1, 'day');
    }*/
    this._systemEvent.book.emit('book_callGetBookAppointment');    
    this.appointmentBookDirective.open();
  }
  handlecallEventBookEmitter1(){
    console.log('here');
    this.reloadSlots();
  }
}
