import {Component, OnInit} from '@angular/core';
import {SystemService} from '@app/shared/system/system.service';
import {Module} from '@app/core/module';
import {Router} from '@angular/router';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';

declare let jQuery: any;

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
  module: any;
  minHeight: number;

  constructor(
    public _systemService: SystemService,
    public _router: Router,
    private loading: LoadingIndicatorService
  ) {
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!Module.SCHEDULE) {
      this.loading.stop();
      this._router.navigate(['/home', 'page-not-found']);
    }

    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();
    
  }
}
