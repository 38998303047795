import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
// import { PasswordFormatTooltipComponent } from '@app/shared/tooltip/password-format';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {ActivatedRoute, Router} from '@angular/router';
import {HomepageService} from '../home.service';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';

@Component({
  selector: 'rtn-home-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../home.component.scss', './forgot-password.component.scss'],
  moduleId: module.id,
})
export class ForgotPasswordComponent implements OnInit {

  error: boolean = false;
  errorMessage: any;
  success: boolean = false;
  minHeight: number;
  email: string;
  isDisabled: boolean;

  sendForgotPasswordEmailSubscriber: Subscription;
  genPass: string = '';
  pageTitleText: string = 'Forgot your password';
  constructor(
    private _formBuilder: FormBuilder,
    private _homePageService: HomepageService,
    private loadingIndicatorService: LoadingIndicatorService,
    private _params: ActivatedRoute,
  ) {
    if (this.email && this.email.length > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  ngOnInit() {
    this.minHeight = window.innerHeight - jQuery('footer.marketing-footer').outerHeight();

    this.genPass = this._params.snapshot.paramMap.get('genpass');
    if (this.genPass != null && this.genPass == 'genpass'){
      this.pageTitleText = 'Create your Password';
    }    
  }

  ngOnDestroy() {
    if (this.sendForgotPasswordEmailSubscriber) {
      this.sendForgotPasswordEmailSubscriber.unsubscribe();
    }
  }

  public reset() {
    var self = this;
    this.loadingIndicatorService.start();
    this.success = false;
    this.error = false;
    var email = {'email': this.email};
    if (this.sendForgotPasswordEmailSubscriber) {
      this.sendForgotPasswordEmailSubscriber.unsubscribe();
    }
    this.sendForgotPasswordEmailSubscriber = this._homePageService.sendForgotMail(email).subscribe(
      (success: any) => {
        self.loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          self.success = true;
          self.error = false;
          self.email = '';
        } else {
          self.error = true;
          self.errorMessage = success.error_messages;
        }
        // Todo
      }, (error: any) => {
        self.loadingIndicatorService.stop();
        // Todo
        self.error = true;
        self.errorMessage = error.error.error_messages;
      });
  }

  onChange() {
    this.isDisabled = !(this.email && this.email.length > 0);
    this.error = this.success = false;
  }

}
