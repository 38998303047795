<div [class.hidden]="!appointmentScreen" class="findAppointment">
    <!--<ul class="nav nav-tabs">
        <li class="nav-item col-6 col-lg-6 col-md-6 col-xs-6 no-padding-left-right find-appointment-link"
            [class.active]="!firstTabs">
            <div class="active-tabs"></div>
            <div class="active-tabs"></div>
            <a class="text-ellipsis nav-link active" id="find-appt-tab" data-toggle="tab" href="#find-appointment"
                (click)="findMyAppTab()" [class.active]="!firstTabs">Find My Appointments</a>
        </li>
        <li class="nav-item col-6 col-lg-6 col-md-6 col-xs-6 no-padding-left-right find-appointment-link"
            [class.active]="firstTabs">
            <div class="active-tabs"></div>
            <div class="active-tabs"></div>
            <a class="text-ellipsis nav-link" data-toggle="tab" href="#search-openings" (click)="searchOpeningTab()"
                [class.active]="firstTabs">Search Openings</a>
        </li>
    </ul>-->

    <div class="tab-content">

        <div id="find-appointment" class="tab-pane container fade in px-0" [class.active]="!firstTabs">
            <div class="text-header-title padding-bottom-15 text-uppercase">Neighborhood Appointments</div>
            <div class="row col-12 col-xs-12 margin-bottom-15 find-appointment-container bg-white">
                <div class="col-12 col-lg-6">
                    <div class="row find-appointment-line">
                        <div class="col-xs-3 col-lg-2 col-lg-offset-1 find-appointment-line-title select2-specialties">
                            Specialty
                        </div>
                        <div class="col-xs-9 col-lg-8">
                            <select #select2Directive1="select2" select2 class="form-control"
                                [(ngModel)]="findAppointment.specialty" id="selectSpecialty1"
                                [maximumSelectionLength]="0">
                                <option *ngFor="let option of specialtyList" [value]="option.id">{{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row find-appointment-line">
                        <div class="col-xs-3 col-lg-2 col-lg-offset-1 find-appointment-line-title">Provider</div>
                        <div class="col-xs-9 col-lg-8">
                            <input #autoCompleteDirective1="autocompletedata" autocompletedata class="form-control"
                                type="text" [(ngModel)]="findAppointment.provider" [source]="providers"
                                [target]="findAppointment" [field]="'provider'">
                        </div>
                    </div>
                    <div class="row find-appointment-line">
                        <div
                            class="col-xs-3 col-lg-2 col-lg-offset-1 find-appointment-line-title input-label-required no-padding-right">
                            Date
                        </div>
                        <div class="col-xs-9 col-lg-8">
                            <select class="form-control" [(ngModel)]="findAppointment.date">
                                <option *ngFor="let option of appointmentDateDate" [value]="option.value">
                                    {{ option.text }}</option>
                            </select>
                            <div class="cursorPointer selecter">
                                <i class="fa fa-caret-down"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-xl-9 offset-lg-2 d-flex flex-column flex-xl-row">
                            <div class="padding-top-5">
                                <label>
                                    <div class="radio-div">
                                        <input [attr.disabled]="!arrayModule[3] ? true : null" type="radio"
                                            name="findAppointment.show" (click)="findAppointment.show = '0'"
                                            [checked]="findAppointment.show === '0' ? true : false">
                                        Show my appointments
                                    </div>
                                </label>
                            </div>
                            <div class="padding-bottom-15 pl-xl-3 padding-top-5">
                                <label>
                                    <div class="radio-div">
                                        <input [attr.disabled]="!arrayModule[3] ? true : null" type="radio"
                                            name="findAppointment.show" (click)="findAppointment.show = '1'"
                                            [checked]="findAppointment.show === '1' ? true : false">
                                        Show family member appointments
                                    </div>
                                </label>
                            </div>
                           
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-9 offset-lg-2">
                            <button [attr.disabled]="!arrayModule[3] ? true : null"
                            class="btn btn-rtn btn-90 text-uppercase" (click)="callSearchAppointment()">Search
                           </button>
                            <span *ngIf="activeSchuduleModule" class="message-error">{{activeSchuduleModule}}</span>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 col-lg-6">
                    <app-speedometer-waittime *ngIf="appointmentData && appointmentData.length > 0 && showSpeedometer"
                        (showDetailAppointmentPopup)="showDetailAppointmentPopup()"></app-speedometer-waittime>
                </div>
            </div>
            <div class="col-xs-12 appointment-table margin-bottom-20" *ngIf="showAppointmentTable">
                <rtn-table #table (onDataChange)="onDataChange($event)" [autoloading]="false" [data]="appointmentData">
                    <div class="row">
                        <div class="col-sm-12 no-padding-left-right listview">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="thead-text col-view">View</th>
                                        <th class="thead-text col-date">Date Time</th>
                                        <th class="thead-text col-patient">Patient</th>
                                        <th class="thead-text col-provider">Provider</th>
                                        <th class="thead-text col-specialty">Specialty</th>
                                        <th class="thead-text col-address">Address</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of table.data; let i = index"
                                        (click)="showWaitingTimeClicked(item)" [class.has-wait-time]="item.change_color"
                                        [class.selected-tr]="item.isSelected">
                                        <td class="col-view bg-white" (click)="appointmentDetails(item)">Details</td>
                                        <td class="col-date bg-white">
                                            <div>{{ _utils.getDate(item.initial_check_in) }}</div>
                                            <div>
                                                <span>{{ _utils.getTime(item.initial_check_in) }}</span>
                                                <span>-</span>
                                                <span>{{ _utils.getTime(item.initial_check_out) }}</span>
                                            </div>
                                        </td>
                                        <td class="col-patient bg-white">
                                            {{ _utils.generateFullName(item.patient_first_name, item.patient_middle_name, item.patient_last_name) }}
                                        </td>
                                        <td class="col-provider bg-white">
                                            <span>{{ _utils.generateFullName(item.provider_first_name, item.provider_middle_name, item.provider_last_name) }}</span><span
                                                *ngIf="item.credentials">,</span>
                                            <span>{{ item.credentials }}</span>
                                        </td>
                                        <td class="col-specialty bg-white">{{ _utils.getSpecialties(item.specialties) }}</td>
                                        <td class="col-address bg-white">
                                            <div *ngIf="item.location_name">{{ item.location_name }},</div>
                                            <div>{{ item.address }},</div>
                                            <div *ngIf="item.address2">{{ item.address2 }},</div>
                                            <div>
                                                <span>{{ item.city }},</span><span
                                                    *ngIf="item.state_code !== null">,</span>
                                                <span>{{ item.state_code }}</span>
                                                <span>{{ item.zip }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </rtn-table>
            </div>
            <div class="col-xs-12" [class.no-result]="findMsg !== null">{{ findMsg }}</div>
            <div class="col-xs-12 no-padding-left-right text-header-title margin-bottom-15 text-uppercase"
                *ngIf="showAppointmentByTimeTable">
                Future
            </div>
            <div class="col-xs-12 appointment-table margin-bottom-20" *ngIf="showAppointmentByTimeTable">
                <rtn-table #table (onDataChange)="onDataChange($event)" [autoloading]="false"
                    [data]="appointmentInFutureData">
                    <div class="row">
                        <div class="col-sm-12 no-padding-left-right listview">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="thead-text col-view">View</th>
                                        <th class="thead-text col-date">Date Time</th>
                                        <th class="thead-text col-patient">Patient</th>
                                        <th class="thead-text col-provider">Provider</th>
                                        <th class="thead-text col-specialty">Specialty</th>
                                        <th class="thead-text col-address">Address</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of table.data; let i = index"
                                        (click)="showWaitingTimeClicked(item)" [class.has-wait-time]="item.change_color"
                                        [class.selected-tr]="item.isSelected">
                                        <td class="col-view bg-white" (click)="appointmentDetails(item)">Details</td>
                                        <td class="col-date bg-white">
                                            <div>{{ _utils.getDate(item.plan_check_in) }}</div>
                                            <div>
                                                <span>{{ _utils.getTime(item.plan_check_in) }}</span>
                                                <span>-</span>
                                                <span>{{ _utils.getTime(item.plan_check_out) }}</span>
                                            </div>
                                        </td>
                                        <td class="col-patient bg-white">
                                            {{ _utils.generateFullName(item.patient_first_name, item.patient_middle_name, item.patient_last_name) }}
                                        </td>
                                        <td class="col-provider bg-white">
                                            <span>{{ _utils.generateFullName(item.provider_first_name, item.provider_middle_name, item.provider_last_name) }}</span><span
                                                *ngIf="item.credentials">,</span>
                                            <span>{{ item.credentials }}</span>
                                        </td>
                                        <td class="col-specialty bg-white">{{ _utils.getSpecialties(item.specialties) }}</td>
                                        <td class="col-address bg-white">
                                            <div>{{ item.address }}</div>
                                            <div>{{ item.address2 }}</div>
                                            <div>
                                                <span>{{ item.city }}</span><span
                                                    *ngIf="item.state_code !== null">,</span>
                                                <span>{{ item.state_code }}</span>
                                                <span>{{ item.zip }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </rtn-table>
            </div>
            <div class="col-xs-12" [class.no-result]="findInFutureMsg !== null">{{ findInFutureMsg }}</div>
            <div class="col-xs-12 no-padding-left-right text-header-title margin-bottom-15 text-uppercase"
                *ngIf="showAppointmentByTimeTable">
                Past
            </div>
            <div class="col-xs-12 appointment-table margin-bottom-20" *ngIf="showAppointmentByTimeTable">
                <rtn-table #table (onDataChange)="onDataChange($event)" [autoloading]="false"
                    [data]="appointmentInPastData">
                    <div class="row">
                        <div class="col-sm-12 no-padding-left-right listview">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="thead-text col-view">View</th>
                                        <th class="thead-text col-date">Date Time</th>
                                        <th class="thead-text col-patient">Patient</th>
                                        <th class="thead-text col-provider">Provider</th>
                                        <th class="thead-text col-specialty">Specialty</th>
                                        <th class="thead-text col-address">Address</th>
                                        <th class="thead-text col-action">

                                            <div class="bulk btn-group navbar-right disable">
                                                <div class="dropdown">
                                                    <a href="#" data-toggle="dropdown" class="btn">
                                                        <i class="fa fa-th" aria-hidden="true">&nbsp;</i>
                                                        Actions&nbsp;<span class="caret">&nbsp;</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="bulk btn-group navbar-right enable">
                                                <div class="dropdown">
                                                    <a href="#" data-toggle="dropdown" class="btn">
                                                        <i class="fa fa-th" aria-hidden="true">&nbsp;</i>
                                                        Actions&nbsp;<span class="caret">&nbsp;</span>
                                                    </a>
                                                    <ul class="dropdown-menu">
                                                        <li class="pl-4"><a href="javascript:void(0);"
                                                                (click)="appointmentHidden()"><i class="fa fa-trash"
                                                                    aria-hidden="true">&nbsp;</i>Delete
                                                                item(s) </a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of table.data; let i = index"
                                        (click)="showWaitingTimeClicked(item)" [class.has-wait-time]="item.change_color"
                                        [class.selected-tr]="item.isSelected">
                                        <td class="col-view bg-white" (click)="appointmentDetails(item)">Details</td>
                                        <td class="col-date bg-white">
                                            <div>{{ _utils.getDate(item.plan_check_in) }}</div>
                                            <div>
                                                <span>{{ _utils.getTime(item.plan_check_in) }}</span>
                                                <span>-</span>
                                                <span>{{ _utils.getTime(item.plan_check_out) }}</span>
                                            </div>
                                        </td>
                                        <td class="col-patient bg-white">
                                            {{ _utils.generateFullName(item.patient_first_name, item.patient_middle_name, item.patient_last_name) }}
                                        </td>
                                        <td class="col-provider bg-white">
                                            <span>{{ _utils.generateFullName(item.provider_first_name, item.provider_middle_name, item.provider_last_name) }}</span><span
                                                *ngIf="item.credentials">,</span>
                                            <span>{{ item.credentials }}</span>
                                        </td>
                                        <td class="col-specialty bg-white">{{ _utils.getSpecialties(item.specialties) }}</td>
                                        <td class="col-address bg-white">
                                            <div>{{ item.address }}</div>
                                            <div>{{ item.address2 }}</div>
                                            <div>
                                                <span>{{ item.city }}</span><span
                                                    *ngIf="item.state_code !== null">,</span>
                                                <span>{{ item.state_code }}</span>
                                                <span>{{ item.zip }}</span>
                                            </div>
                                        </td>
                                        <td class="col-action bg-white">
                                            <label class="chkbox">
                                                <input type="checkbox" [(ngModel)]="item.is_hidden"
                                                    (change)="onSelectedItem(item.id, $event)" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </rtn-table>
            </div>
            <div class="col-xs-12" [class.no-result]="findInPastMsg !== null">{{ findInPastMsg }}</div>
        </div>
        <div id="search-openings" class="tab-pane container fade in px-0" [class.active]="firstTabs">
            <div class="text-header-title padding-bottom-15 text-uppercase">Find Appointments</div>
            <div class="col-xs-12 margin-bottom-15 find-appointment-container bg-white">
                <div class="row find-appointment-line">
                    <div
                        class="col-xs-3 col-lg-2 no-padding-right input-label-required find-appointment-line-title">
                        Patient
                        <span class="hidden-xs"> Name</span>
                    </div>
                    <div class="col-xs-9 col-lg-4">
                        <select required class="form-control select-patient-style" id="searchPatientName"
                            [(ngModel)]="searchOpenings.patient" (change)="changePatient($event.target.value)"
                            (input)="onChange('patient_name')" [class.input-error]="errorResponse['patient_name']">
                            <option value="" disabled selected hidden>Select Patient</option>
                            <option *ngFor="let member of familyMembers" [value]="member.family_id">{{member.fullName}}
                            </option>
                            <option value="link_to_insurance">Add new family member</option>
                        </select>
                        <div class="cursorPointer selecter">
                            <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="message-error">{{ errorResponse.patient_name }}</div>
                    </div>
                </div>
                <div class="row find-appointment-line specialty-search-openings select-disease disease-style">
                    <div
                        class="col-xs-3 col-lg-2 no-padding-right find-appointment-line-title select2-specialties display-flex">
                        Disease
                    </div>
                    <div class="col-xs-9 col-lg-4">
                        <select #select2DiseaseDirective="select2-disease" select2-disease class="form-control"
                            [(ngModel)]="searchOpenings.disease" [value]="searchOpenings.disease"
                            [maximumSelectionLength]="0" (change)="onChange('disease')"
                            [inputError]="errorResponse['disease']" (OnKeyUp)="onChangeDisease()">
                            <option *ngFor="let option of diseases" [id]="'diseases' + option.id" [value]="option.id">{{
                option.name }}
                            </option>
                        </select>
                        <div class="message-error">{{ errorResponse.disease }}</div>
                    </div>
                </div>
                <div class="row find-appointment-line specialty-search-openings">
                    <div
                        class="col-xs-3 col-lg-2 no-padding-right find-appointment-line-title select2-specialties display-flex">
                        Specialty
                    </div>
                    <div class="col-xs-9 col-lg-4">
                        <select #select2Directive2="select2" select2 class="form-control"
                            [(ngModel)]="searchOpenings.specialty" id="selectSpecialty2"
                            [value]="searchOpenings.specialty" [maximumSelectionLength]="0"
                            (change)="onChange('specialty')" [inputError]="errorResponse['specialty']">
                            <option *ngFor="let option of specialtyListByDisease"
                                [id]="'specialtyListByDisease' + option.id" [value]="option.id">{{ option.name }}
                            </option>
                        </select>
                        <div class="message-error">{{ errorResponse.specialty }}</div>
                    </div>
                </div>
                <div class="row find-appointment-line">
                    <div class="col-xs-3 col-lg-2  find-appointment-line-title">Provider</div>
                    <div class="col-xs-9 col-lg-4">
                        <input #autoCompleteDirective2="autocompletedata" autocompletedata class="form-control"
                            type="text" [(ngModel)]="searchOpenings.providerName" [source]="providers"
                            [target]="searchOpenings" [field]="'providerName'">
                    </div>
                </div>
                <div class="row find-appointment-line">
                    <div
                        class="col-xs-3 col-lg-2  no-padding-right input-label-required find-appointment-line-title display-flex zip-code">
                        ZIP Code
                    </div>
                    <div class="col-xs-9 col-lg-4">
                        <input class="form-control" id="input-zip" type="text" placeholder="Enter ZIP"
                            [(ngModel)]="searchOpenings.zip" (keyup)="onChange('zip')" simplemask
                            [format]="'#####-####'" [target]="searchOpenings" [field]="'zip'"
                            [class.input-error]="errorResponse['zip']" maxlength="10">
                        <div class="message-error">{{ errorResponse.zip }}</div>
                    </div>
                </div>
                <div class="row find-appointment-line">
                    <div class="col-xs-3 col-lg-2 find-appointment-line-title">Distance</div>
                    <div class="col-xs-9 col-lg-4">
                        <select class="form-control" [(ngModel)]="searchOpenings.distance" id="distance">
                            <option *ngFor="let option of searchOpeningsDistance" [value]="option.value">
                                {{ option.text }}</option>
                        </select>
                        <div class="cursorPointer selecter secondary-select">
                            <i class="fa fa-caret-down"></i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9 offset-lg-2">

                        <div class="d-flex flex-column flex-md-row py-3">
                        <div>
                            <label>
                                <div class="radio-div">
                                    <input [attr.disabled]="!arrayModule[3] ? true : null" type="radio"
                                        name="searchOpenings.show" [value]="'1'" [checked]="searchOpenings.show=='1'"
                                        (click)="searchOpenings.show = '1'">
                                    Show only My Providers
                                </div>
                            </label>
                        </div>
                        <div class="pl-0 pl-md-2">
                            <label>
                                <div class="radio-div">
                                    <input [attr.disabled]="!arrayModule[3] ? true : null" type="radio"
                                        name="searchOpenings.show" [value]="'0'" [checked]="searchOpenings.show=='0'"
                                        (click)="searchOpenings.show = '0'">
                                    Show all Providers
                                </div>
                            </label>
                        </div>
                       </div>
                        <button [attr.disabled]="!arrayModule[3] ? true : null"
                            class="btn btn-rtn btn-90 text-uppercase" (click)="clickBtnSearchOpening($event)">Search
                        </button>
                        <span *ngIf="messageResponseErrorSearchOpen"
                            class="message-error">{{messageResponseErrorSearchOpen}}</span>
                        <span *ngIf="activeMessageModule" class="message-error">{{activeMessageModule}}</span>
                    </div>
                </div>
            </div>
            <div class="col-xs-12" class="no-result" *ngIf="noResult">{{ noResult }}</div>
            <div class="clear-both search-openings-table margin-bottom-20 bg-white p-2" *ngIf="showOpeningsTable">
                <rtn-table #table (onDataChange)="searchOpeningsSlot($event)" [autoloading]="false"
                    [data]="searchOpeningsResult.data" [rowsOnPage]="searchOpeningsResult.rowsOnPage"
                    [numberOfPages]="searchOpeningsResult.numberOfPages">
                    <table class="table">
                        <thead *ngIf="table.numberOfPages > 1">
                            <tr>
                                <td colspan="12" class="text-center no-padding">
                                    <div class="page-footer">
                                        <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <div class="d-flex flex-wrap notice-color justify-content-between">
                            <div>
                                <span class="box-item"></span>
                                Physicians that accept users insurance
                            </div>
                            <label class="disclimertext pull-right hovernotes">Please hover over an appointment slot to see the provider's location for this appointment</label>
                        </div>
                        <div class="col-12 no-padding-left-right">
                            <div class="row col-12 col-lg-12 col-md-12 col-sm-12 no-padding-left-right search-table"
                                *ngFor="let openings of table.data; let i = 'index'">
                                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 search-table-block-info">
                                    <div class="col-12 col-sm-12 no-padding-left-right address">
                                        <div class="open_sanssemibold">
                                            <span>{{ openings.fullProviderName }}</span>
                                            <span *ngIf="openings.credentials">,</span>
                                            <span *ngIf="openings.credentials">{{ openings.credentials }}</span>
                                        </div>
                                        <div class="open_sanslight">{{ _utils.getSpecialties(openings.specialties) }}
                                        </div>
                                        <div class="open_sanslight padding-top-15">{{ openings.address }}</div>
                                        <div class="open_sanslight">{{ openings.address2 }}</div>
                                        <div class="open_sanslight">
                                            <span>{{ openings.city }}</span>
                                            <span *ngIf="openings.state_code !== null">,</span>
                                            <span>{{ openings.state_code }}</span>
                                            <span>{{ openings.zip }}</span>
                                        </div>
                                        <!--<div *ngIf="openings.location_name">Office:{{ openings.location_name }}</div>-->
                                        <div class="open_sanslight">{{ openings.phone_mobile }}</div>
                                        <div class="open_sanssemibold padding-top-15 info-distance">
                                            {{ openings.distance }}</div>                                            
                                        <pre class="note-block-content" [innerHtml]="openings.note_for_provider"></pre>
                                        <a href="javascript:void(0);" class="btn btn-rtn" (click)="addPatientWaitListDirective.open(openings, _systemData.profile, searchOpenings)">Click to Join Wait-List for Preferred Date/Times</a>
                                    </div>
                                </div>
                                <div class="row col-12 col-sm-12 col-md-12 col-xl-9 col-lg-9 px-0">
                                    <div class="col-xl-5 col-lg-5 col-md-5 col-6 col-sm-6 margin-top-15 no-padding-left-right text-center search-table-item opening-table"
                                        *ngFor="let appointment of openings.open_appointment; let i = index;">
                                        <div class="open_sanssemibold text-uppercase search-table-item-date">
                                            <span class="previous-data"
                                                (click)="getPreviousSearchOpeningsData(appointment.date)">
                                                <i class="fa fa-2x fa-angle-left"></i>
                                            </span>
                                            {{ _utils.formatDate(appointment.date) }}
                                            <span class="next-data" (click)="getNextSearchOpeningsData(appointment.date)">
                                                <i class="fa fa-2x fa-angle-right"></i>
                                            </span>
                                        </div>
                                        <div [ngClass]="openings.is_accept_insurance?'is-accept-insurance':'is-not-accept-insurance'"
                                            class="row calendar_box">
                                            <!-- <div class="open_sanslight search-table-item-time col-6 col-xs-6 no-padding-left-right"
                                                *ngFor="let slot of appointment.slot"
                                                [class.show-search-data]="openings.showMore"> -->
                                            <div class="open_sanslight search-table-item-time col-6 col-xs-6 no-padding-left-right"
                                                *ngFor="let slot of appointment.slot"
                                                [class.show-search-data]="!appointment.showTextMore && appointment.showTextLess">
                                                <div class="search-slot" *ngIf="slot.start_time !== ''"
                                                    (click)="goToBookAppointment(openings.provider_id, openings.patient_user_id, appointment.date, slot.start_time, slot.end_time, slot.slot_id, slot.location_info)" title="{{slot.location_name}}">
                                                    {{ slot.start_time }} - {{slot.end_time}}</div>
                                                <div class="search-slot-empty" *ngIf="slot.start_time === ''">00:00<span
                                                        style="color: #000;">-</span>00:00
                                                </div>
                                            </div>
                                            <div class="show-more-search-data clear-both col-12 text-center">
                                                <span (click)="showMoreSearchData(openings.id, appointment.date)"
                                                    *ngIf="appointment.showTextMore">
                                                    more
                                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                                </span>
                                                <span (click)="showLessSearchData(openings.id, appointment.date)"
                                                    *ngIf="appointment.showTextLess">
                                                    less
                                                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-12 col-lg-12 col-md-12 col-xs-12 search-table-hr">
                                    <hr>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 notice-color">
                            <div class="box-item"></div>
                            Physicians that accept users insurance
                        </div>

                        <tfoot *ngIf="table.numberOfPages > 1">
                            <tr>
                                <td colspan="12" class="text-center no-padding">
                                    <div class="page-footer">
                                        <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </rtn-table>
            </div>
            
            <!--<div class="col-xs-12 search-openings-date-time"
                [class.hidden]="showSettingOpeningsTable || messageResponseErrorSearchOpen">
                <div class="col-xs-12 open_sanssemibold"><p class="disclimertext">Please contact the office directly if you are trying to move up an apt associated with an a specialized purpose such as procedure or earlier yearly physical</p></div>
                <div class="col-xs-12 open_sanssemibold">Can't get the appointment time you wanted?</div>
                <div class="col-xs-12 padding-bottom-15 open_sanslight">Simply select the date + time range and we'll
                    let you
                    know when appointments opens up.
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right">
                    <div class="col-2 col-xs-2 input-label-required no-padding-right display-flex line-height-30">Date
                    </div>
                    <div class="row col-10 col-xs-10 no-padding-left-right">
                        <div class="col-4 col-xs-12 col-sm-5 col-lg-4">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <div class="input-group">
                                    <input type="text" #inputStartDateSaveSettingSearch
                                        class="form-control calender-input bc-white" disabled placeholder="From"
                                        [(ngModel)]="searchOpenings.start_date_display" (change)="removeMessage()">
                                    <div class="input-group-append" #clickShowStartDateSaveSettingSearch>
                                        <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.start_date }}</div>
                        </div>
                        <span class="float-left hidden-xs"> - </span>
                        <div class="col-4 col-xs-12 col-sm-5 col-lg-4 secondary-selecter">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <div class="input-group">
                                    <input type="text" #inputEndDateSaveSettingSearch
                                        class="form-control calender-input bc-white" disabled placeholder="To"
                                        [(ngModel)]="searchOpenings.end_date_display" (change)="removeMessage()">
                                    <div class="input-group-append" #clickShowEndDateSaveSettingSearch>
                                        <span aria-hidden="true" class="input-group-text far fa-calendar-alt"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="message-error">{{ errorResponse.end_date }}</div>
                        </div>
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right padding-top-10">
                    <div class="col-2 col-xs-2 line-height-30">Time</div>
                    <div class="row col-10 col-xs-10 no-padding-left-right">
                        <div class="col-4 col-xs-12 col-sm-5 col-lg-4">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <select class="form-control select-time-save-setting"
                                    [(ngModel)]="searchOpenings.start_time" (change)="onChange('startTime')">
                                    <option value='' selected>From</option>
                                    <option class="select-option" *ngFor="let time of _systemData.arrayTimes"
                                        [value]="time.value">{{ time.text }}</option>
                                </select>
                                <div class="cursorPointer selecter-save-block">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.start_time }}</div>
                        </div>
                        <span class="float-left hidden-xs"> - </span>
                        <div class="col-xs-12 col-sm-5 col-lg-4 secondary-selecter">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <select class="form-control select-time-save-setting"
                                    [(ngModel)]="searchOpenings.end_time" (change)="onChange('endTime')">
                                    <option value='' selected>To</option>
                                    <option class="select-option" *ngFor="let time of _systemData.arrayTimes"
                                        [value]="time.value">{{ time.text }}</option>
                                </select>
                                <div class="cursorPointer selecter-save-block">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.end_time }}</div>
                        </div>
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right padding-top-10">
                    <div class="col-2 col-xs-2 line-height-30 input-label-required">Appointment Type</div>
                    <div class="row col-10 col-xs-10 no-padding-left-right">
                        <div class="col-4 col-xs-12 col-sm-5 col-lg-4">
                            <div class="col-xs-12 col-sm-12 col-lg-12 no-padding-left-right date-select">
                                <select class="form-control select-time-save-setting"
                                    [(ngModel)]="searchOpenings.apt_type" (change)="onChange('apt_type')">
                                    <option value='' selected>Select</option>
                                    <option class="select-option" *ngFor="let list of appointmentTypeList"
                                            [value]="list.id">{{ list.label }}</option>
                                </select>
                                <div class="cursorPointer selecter-save-block">
                                    <i class="fa fa-caret-down"></i>
                                </div>
                            </div>
                            <div class="message-error">{{ errorResponse.apt_type }}</div>
                        </div>                       
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right padding-top-10">
                    <div class="col-2 col-xs-2 line-height-30">Reason</div>
                    <div class="row col-10 col-xs-10 no-padding-left-right">
                        <div class="col-xs-12 col-sm-5 col-lg-5">
                            <textarea  class="input form-control" rows="4" [(ngModel)]="searchOpenings.apt_reason"  maxlength="255">                        
                            </textarea>
                        </div>
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right padding-top-10">
                    <div class="col-2 col-xs-2 line-height-30 no-padding-left-right checkbox-info">
                        <div class="input-rtn display-flex">
                            <input class="checkbox check-single" type="checkbox" id="searchOpenings-email"
                                [(ngModel)]="searchOpenings.enable_email" (change)="removeMessage()">
                            <label for="searchOpenings-email" class="selectorExtreme rtn-text-grey-85"></label>
                            <span class="checkbox-text">Email:</span>
                        </div>
                    </div>
                    <div class="col-10 col-xs-10 no-padding-left-right">
                        <div class="col-xs-12 col-sm-5 col-lg-5">
                            <input class="form-control" type="text" [(ngModel)]="searchOpenings.email"
                                [disabled]="!searchOpenings.enable_email" (change)="onChange('email')">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-12 message-error">{{ errorResponse.email }}</div>
                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right padding-top-10">
                    <div class="col-2 col-xs-2 line-height-30 no-padding-left-right checkbox-info">
                        <div class="input-rtn display-flex">
                            <input class="checkbox check-single" type="checkbox" id="searchOpenings-sms"
                                [(ngModel)]="searchOpenings.enable_sms" (change)="removeMessage()">
                            <label for="searchOpenings-sms" class="selectorExtreme rtn-text-grey-85"></label>
                            <span class="checkbox-text">
                                <span class="hidden-xs">Text/</span>
                                SMS:
                            </span>
                        </div>
                    </div>
                    <div class="col-10 col-xs-10 no-padding-left-right">
                        <div class="row">
                            <div class="col-11 col-xs-11 col-sm-5 col-lg-5">
                                <rtn-mobile-number [formInput]="searchOpenings"
                                    [isDisabled]="!searchOpenings.enable_sms" (removeError)="removeMessage()">
                                </rtn-mobile-number>
                                <div class="message-error">{{ errorResponse.mobile }}</div>
                            </div>
                            <div class="col-1 col-md-1 col-xs-1 no-padding-right" *ngIf="loadIconVerifiedPhone">
                                <i *ngIf="verifiedPhone" class="fa fa-check phone-verified" aria-hidden="true"
                                    title="Confirmed"></i>
                                <i *ngIf="!verifiedPhone" class="fa fa-times phone-unverified" aria-hidden="true"
                                    title="Not confirmed yet"></i>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row col-12 col-xs-12 no-padding-left-right padding-top-10">
                    <div class="col-2 col-xs-2 line-height-30 no-padding-left-right checkbox-info">
                        <div class="input-rtn display-flex">
                            <input class="checkbox check-single" type="checkbox" id="searchOpenings-providers"
                                disabled="disabled" [checked]="!!providerSelected.length">
                            <label for="searchOpenings-providers" class="selectorExtreme rtn-text-grey-85"></label>
                            <span class="checkbox-text">Provider(s) selected:</span>
                        </div>
                    </div>
                    <div class="col-10 col-xs-10 no-padding-left-right">
                        <div class="col-xs-12 col-sm-5 col-lg-5">
                            <ng-container *ngIf="searchOpeningsResult?.data.length > 0">
                                <button class="btn btn-rtn btn-select-provider" (click)="toggleSelectProviderDialog()">
                                    Select Providers
                                </button>
                            </ng-container>
                            <ng-container *ngIf="searchOpeningsResult?.data.length == 0">
                                <button class="btn btn-rtn btn-select-provider">
                                    No Provider founds 
                                </button>
                            </ng-container>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 open_sanssemibold margin-top-15" *ngIf="showNoticeSelectProviderSave">
                    <p>The notifications will include the following providers: {{getSelectedProviderName()}}</p>
                    <p>(If none is selected, we will include all providers from the above search.)</p>
                </div>
                <div class="col-12 col-xs-12 margin-top-15">
                    <button class="btn btn-rtn save-settings text-uppercase"
                        (click)="saveSettingClicked()">SAVE</button>
                    <span class="message-success">{{ successSaveOpeningSearch }}</span>
                    <span class="message-error">{{ errorSaveOpeningSearch }}</span>
                </div>
            </div>-->
            <div class="clear-both" [class.no-result]="searchMsg !== null">{{ searchMsg }}</div>
        </div>
    </div>
</div>
<div [class.hidden]="!bookScreen">
    <div class="btn-back">
        <button type="button" class="mb-2 btn btn-secondary btn-sm d-flex align-items-center" (click)="backToSearchOpening()">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span class="ml-1">Back to Openings</span>
        </button>
    </div>    
    <rtn-book-appointment (callEventBookEmitter)="handlecallEventBookEmitter()" (callEventAddwaitlistBookEmitter)="handlecallEventAddwaitlistBookEmitter()"></rtn-book-appointment>
</div>

<rtn-modals-appointment-detail (callEventWaitlistFrombookEmitter)="handlecallEventWaitlistFrombookEmitter()"></rtn-modals-appointment-detail>

<!--<modal #selectProvider>
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="cancelSelectProviderDialog()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Select Providers</h4>
    </div>
    <app-select-provider *ngIf="showOpeningsTable" [providerSelected]="providerSelected"
        [searchOpenings]="searchOpenings" [settingRowOnPage]="settingRowOnPage"
        (onSelectProvider)="showNoticeSelectProviderSave = true">
    </app-select-provider>
    <div class="modal-footer">
        <button type="button" class="btn btn-rtn btn-90" data-dismiss="modal" (click)="cancelSelectProviderDialog()">
            Cancel
        </button>
        <button type="button" class="btn btn-rtn btn-90" (click)="selectProvider.close()">Select</button>
    </div>
</modal>-->

<app-add-patient-waitlist></app-add-patient-waitlist>
<rtn-waitlist-from-appointment (CloseWaitlistEmitter)="handleCloseWaitlistEmitter($event)"></rtn-waitlist-from-appointment>