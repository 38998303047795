<div class="container marketing-page sign-up-bc">
  <div class="row">
      <div class="col-12 col-md-12 col-lg-5 offset-md-4 col-xl-4 offset-lg-3">
          <h1>CREATE AN ACCOUNT</h1>
          <div class="link-sign-up">
              <a tabindex="1" [routerLink]="['/home', 'login']" class="link cursorPointer" target="_self">Already have an account? Log in</a>
          </div>
      </div>
  </div>
  <form method="post" (ngSubmit)="submitSignUpForm()" novalidate>
      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="first_name" class="input-label-required">First Name</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="Enter First Name" type="text" class="form-control" id="first_name" [(ngModel)]="signUpForm.first_name" name="first_name" [class.input-error]="error.first_name" (keyup)="onKeyUp('first_name')" maxlength="50">
              <div class="message-error">{{ error.first_name }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="last_name" class="input-label-required">Last Name</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="Enter Last Name" type="text" class="form-control" id="last_name" [(ngModel)]="signUpForm.last_name" name="last_name" [class.input-error]="error.last_name" (keyup)="onKeyUp('last_name')" maxlength="50">
              <div class="message-error">{{ error.last_name }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="email" class="input-label-required">Email Address</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="Enter Email" type="text" class="form-control" id="email" [(ngModel)]="signUpForm.email" name="email" [class.input-error]="error.email" (keyup)="onKeyUp('email')" minlength="5" maxlength="254">
              <div class="message-error">{{ error.email }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1 no-padding-right">
              <label for="confirm_email" class="input-label-required">Confirm Email Address</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="Enter Confirm Email" type="text" class="form-control" id="confirm_email" name="email_confirmation" [(ngModel)]="signUpForm.email_confirmation" [class.input-error]="error.email_confirmation" (keyup)="onKeyUp('email_confirmation')" minlength="5" maxlength="254">
              <div class="message-error">{{ error.email_confirmation }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="pwd" class="input-label-required">Password</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="At least 8 characters long" type="password" class="form-control" id="password" [(ngModel)]="signUpForm.password" name="password" [class.input-error]="error.password" (keyup)="onKeyUp('password')" minlength="8" maxlength="50">
              <div class="message-error white-space-nowrap">{{ error.password }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="password_confirmation" class="input-label-required">Confirm Password</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="At least 8 characters long" type="password" class="form-control" id="password_confirmation" [(ngModel)]="signUpForm.password_confirmation" name="password_confirmation" [class.input-error]="error.password_confirmation" (keyup)="onKeyUp('password_confirmation')" minlength="8" maxlength="50">
              <div class="message-error white-space-nowrap">{{ error.password_confirmation }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="dob" class="input-label-required">Date of Birth</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4 px-0">
              <rtn-dob [formInput]="signUpForm" [error]="error.dob" (removeError)="onKeyUp($event)" id="dob"></rtn-dob>
              <div class="message-error pl-3">{{ error.dob }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="phone_mobile" class="col-12 col-lg-12 no-padding-left-right">Mobile Number</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <rtn-mobile-number class="font-text" id="phone_mobile" [formInput]="signUpForm" [error]="error.phone_mobile" (removeError)="onKeyUp($event)"></rtn-mobile-number>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-8 offset-md-4 col-xl-9 offset-lg-3">
              <i class="font-text notice-text">Receive appointment alerts and notifications directly to your phone.</i>
              <div class="message-error">{{ error.phone_mobile }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="zip" class="input-label-required">ZIP Code</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="Enter ZIP Code" type="text" class="form-control" id="zip" [class.input-error]="error.zip" (keyup)="onKeyUp('zip')" simplemask [format]="'#####-####'" [target]="signUpForm" [field]="'zip'" [(ngModel)]="signUpForm.zip" name="zip" ngControl="zip" maxlength="10">
              <div class="message-error">{{ error.zip }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-2 offset-md-2 col-xl-2 offset-lg-1">
              <label for="zip" class="input-label-required">Insurance Plan</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4 padding-bottom-15">
              <select class="form-control" [(ngModel)]="signUpForm.insurance_id" name="insurance_id" (change)="changeInsurance($event.target.value)" [class.input-error]="error.insurance_id">
                  <option value="" disabled>Select Insurance</option>
                  <option *ngFor="let plan of _systemData.insurances" id="plan.id" [value]="plan.id">{{ plan.name }}</option>
              </select>
              <div class="cursorPointer selecter">
                  <i class="fa fa-caret-down"></i>
              </div>
              <div class="message-error" *ngIf="error.insurance_id">{{ error.insurance_id }}</div>
              <i class="font-text notice-text">* If you cannot find your plan in the list, please contact RTN admin</i>
          </div>
      </div>

      <div class="row" id="insurance-number">
          <div class="col-12 col-md-12 col-xl-2 col-lg-5 offset-md-2 col-xl-2 offset-lg-1">
              <label for="zip" class="input-label-required">Insurance ID</label>
          </div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <input placeholder="Enter Insurance ID" class="form-control" id="insurance_number" type="text" [(ngModel)]="signUpForm.insurance_number" name="insurance_number" (keyup)="onKeyUp('insurance_number')" maxlength="50" [class.input-error]="error.insurance_number">
              <div class="message-error">{{ error.insurance_number }}</div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-12 col-lg-8 offset-md-4 col-xl-9 offset-lg-3 padding-bottom-20">
              <button type="submit" class="btn btn-rtn text-uppercase login-button">Submit</button>
          </div>
      </div>
  </form>
</div>
