import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { CORE_DIRECTIVES, FORM_DIRECTIVES } from 'angular2/common';
import { TimelineComponent } from '@app/appointments/timeline/timeline.component';
// import {AppointmentListService} from '@app/appointments/appointment-list/appointment-list.service';
import { Utils } from '@app/shared/utils';
import { SystemData, SystemInterval, SystemService, SystemEvent } from '@app/shared/system';
// import { PatientNewComponent } from '../../../../shared/modals/provider/patient-new';
import { AppointmentBookComponent } from '@app/appointments/modals/appointment-book/appointment-book.component';
import { AvailableSlotComponent } from '@app/appointments/modals/available-slot/available-slot.component';
import { Subscription } from 'rxjs';
import { RestService } from '@app/core/rest.service';
import * as moment from 'moment';
import { AppointmentsService } from '@app/appointments/appointments.service';
import { Module } from '@app/core/module';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

@Component({
    moduleId: module.id,
    templateUrl: './appointment-list.component.html',
    styleUrls: ['./appointment-list.component.scss'],
    providers: [SystemData, SystemInterval, Utils]
})
export class AppointmentListComponent implements AfterViewInit, OnDestroy, OnInit {
    // declare arrayActiveModule
    arrayModule = new Array();
    waitTime = '0 min';
    fetchWaitTimeSubscriber: Subscription;
    getOnlineStatusSubscriber: Subscription;
    module: any;
    getIsLockSubscriber: Subscription;
    isLock: any;
    @ViewChild(TimelineComponent) timelineDirective: TimelineComponent;
    @ViewChild(AppointmentBookComponent) appointmentBookDirective: AppointmentBookComponent;
    @ViewChild(AvailableSlotComponent) availableSlotDirective: AvailableSlotComponent;

    // @ViewChild(PatientNewComponent) patientNewDirective: PatientNewComponent;

    constructor(
        private Service: AppointmentsService,
        public _systemService: SystemService,
        public _systemInterval: SystemInterval,
        public _systemData: SystemData,
        private _systemEvent: SystemEvent,
        public _utils: Utils,
        private _restService: RestService,
        private _router: Router,
        private _loadingIndicatorService: LoadingIndicatorService,
        
    ) {
        // TODO
    }

    ngOnInit() {
        // get active module array
        if (this.getOnlineStatusSubscriber) {
            this.getOnlineStatusSubscriber.unsubscribe();
        }
        if (this.getIsLockSubscriber) {
            this.getIsLockSubscriber.unsubscribe();
        }

        let module_enc = localStorage.getItem('module_enc');
        let token = localStorage.getItem('user_token').substr(0, 32);
        this.module = this._systemService.moduleDecrypt(module_enc, token);
        if (!this.module[Module.SCHEDULE]) {
            this._loadingIndicatorService.stop();
            this._router.navigate(['/home', 'page-not-found']);
        }
        this.getOnlineStatusSubscriber = this._restService.getMode().subscribe(
            (success: any) => {
                this._systemData.isOnline = <boolean>success.is_online;
            }
        );

        this.getLockProvider();
    }

    ngAfterViewInit() {
        if (this._utils.accessByProvider()) {
            this.fetchWaitTime();
            this._systemInterval.appointment_fetchWaitTimeProvider.interval = setInterval(() => {
                this.fetchWaitTime();
            }, this._systemInterval.appointment_fetchWaitTimeProvider.duration);
        }
    }

    ngOnDestroy() {
        this._systemInterval.clearSystemInterval(this._systemInterval.appointment_fetchWaitTimeProvider);
        if (this.fetchWaitTimeSubscriber) {
            this.fetchWaitTimeSubscriber.unsubscribe();
        }
    }

    fetchWaitTime() {
        const today = new Date();
        if (this._restService.debugMode()) {
            console.log(today.getHours() + ' : ' +
                today.getMinutes() + ' : ' +
                today.getSeconds() + ' : ' +
                today.getMilliseconds());
        }

        // this.waitTime = this._utils.generateValueUnitDisplay(0, 'min');
        if (this.fetchWaitTimeSubscriber) {
            this.fetchWaitTimeSubscriber.unsubscribe();
        }

        this.fetchWaitTimeSubscriber = this.Service.fetchWaitTimeByProvider().subscribe(
            (success: any) => {
                let time = 0;
                if (success.wait_time > 999) {
                    time = 999;
                } else {
                    time = success.wait_time;
                }
                this.waitTime = this._utils.generateValueUnitDisplay(time, 'min');
            }
        );
    }

    getCurrentTime() {
        return this._utils.formatTime24h(moment()
            .get('hour') * 60 + moment().get('minute'));
    }

    getLockProvider() {
        this._loadingIndicatorService.start();
        this.getIsLockSubscriber = this.Service.getIsLockBookApptByProviderID().subscribe(
            (success: any) => {
                this.isLock = success.providers.is_lock;
                this._loadingIndicatorService.stop();

            }
        );
    }
}

