<!--<rtn-provider-header></rtn-provider-header>-->
<div class="container admin-account px-0">
    <h3 class="row d-none d-sm-none d-xl-block d-lg-block d-md-block">List of Appointments</h3>
    <h3 class="row d-block d-sm-none d-none d-sm-block d-md-none text-center">List of Appointments</h3>
    <div class="row col-12 px-0">
        <div class="row col-12 border border-#ddd pt-3">
          <!-- <div class="col-2 col-xs-2 no-padding-right display-flex line-height-30"></div> -->
          <!-- Select pratice-->
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Practice:</div>
            <div
              class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0 width_70">
              Practice:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0 getPractice">
              <select #select2PracticeDirective2="select2-practice2" select2-practice2 class="form-control"
                [value]="dataSearchChart.practice"  [closeOnSelect]="true" [multiple]="false"
                [tags]="false" id="select2Practice" (OnSelected)="onChangePractice()">
                <option *ngFor="let practice of practicesList; let i = index" [id]="'practice' + practice.id"
                  [value]="practice.id">{{ practice.name }}</option>
              </select>
              <div class="message-error">{{ errorResponse.practice }}</div>
              <div class="red-alert d-none d-lg-block error_message"></div>
              <div
                class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
              </div>
            </div>
          </div>
        <!-- Select provider-->
        <div class="px-0 row col-lg-6 mb-2 pl_100">
          <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Provider:</div>
          <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
            Provider:</div>
          <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
            <select
            #Select2Provider3Directive="select2-provider3"
            select2-provider3 
            class="form-control"
            [value]="dataSearchChart.provider"
            [closeOnSelect]="true"
            [multiple]="false"
            id="select2Provider"
            placeholder="Select provider"
            [tags]="false"
            (OnSelected)="onChangeProvider()"
            >
            <option value=""></option>
            <option *ngFor="let provider of providersList; let i = index" [id]="'provider' + provider.id" [value]="provider.id" >
              {{ provider.full_name }}
            </option>
        </select>
        <div class="message-error">{{ errorResponse.provider }}</div>
        <div class="red-alert d-none d-lg-block error_message"></div>
        <div
          class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
        </div>
          </div>         
        </div>
        <!--date from welcome -->
          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="pl-0 d-none d-lg-block find-appointment-line-title width_70 pt-1">Date:</div>
            <div
              class="width_70 text-left col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              Date:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
              <div class="input-group">
                <input type="text" #inputDateFrom readonly [value]="dataSearchChart['dateFrom']"
                  class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateFrom']"
                  aria-describedby="basic-addon2">
                <div class="input-group-append" #clickDateFrom>
                  <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                </div>
              </div>
              <div class="message-error">{{ errorResponse.dateFrom }}</div>
            </div>
          </div>
  
          <div class="px-0 row col-lg-6 mb-2">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-1">Status:</div>
            <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              Status:</div>
            <div class="col-lg-7 col-md-10 col-sm-10 col-10 px-0">
              <select class="form-control" id="typeChart" name="typeChart">
                <option [value]="0" selected>All</option>
                <option [value]="1">No-show</option>
                <option [value]="2">Cancel</option>
                <option [value]="3">Finished</option>
                <option [value]="4">Not check-in</option>
              </select>
            </div>
          </div>

          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-2">Event Type:</div>
            <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              Event Type:</div>
            <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
              <select
              #Select2EventtypeDirective="select2-eventtype"
              select2-eventtype 
              class="form-control"
              [value]="dataSearchChart.event_reason_id"
              [closeOnSelect]="true"
              [multiple]="true"
              id="select2Eventtype"
              placeholder="Select event type"
              [tags]="false"
              >
              <option value=""></option>
              <option *ngFor="let eventtype of eventtypeList; let i = index" [id]="'eventtype' + eventtype.id" [value]="eventtype.id" >
                {{ eventtype.label }} <ng-container *ngIf="eventtype.is_allowed != '1'">(***)</ng-container>
              </option>
          </select>
          <div class="message-error">{{ errorResponse.event_reason_id }}</div>
          <div class="red-alert d-none d-lg-block error_message"></div>
          <div
            class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
          </div>
            </div>
           
          </div>

          <div class="px-0 row col-lg-6 mb-2 pl_100">
            <div class="d-none d-lg-block find-appointment-line-title width_70 pt-2">Location:</div>
            <div class="col-md-2 col-sm-2 col-2 d-sm-block d-md-block d-lg-none d-xl-none find-appointment-line-title px-0">
              Location:</div>
            <div class="px-0 col-lg-7 col-md-10 col-sm-10 col-10 px-0 getProvider">
              <select
              #Select2AptlocationDirective="select2-aptlocation"
              select2-aptlocation 
              class="form-control"
              [value]="dataSearchChart.location_id"
              [closeOnSelect]="true"
              [multiple]="false"
              id="select2aptlocation"
              placeholder="Select Location"
              [tags]="false"
              [allowClear]="true"
              >
              <option value=""></option>
              <option *ngFor="let locitem of locationList; let i = index" [id]="'location' + locitem.id" [value]="locitem.id" >
                {{ locitem.location_name }}
              </option>
          </select>
          <div class="message-error">{{ errorResponse.location_id }}</div>
          <div class="red-alert d-none d-lg-block error_message"></div>
          <div
            class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
          </div>
            </div>
           
          </div>
          
          <div class="ml-3 px-0 row col-lg-10 mb-2">
            <div class="btn-90">
              <button id='showDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1"
                type="submit">SHOW</button>
            </div>
            <div class="btn-90 ml-2">
              <button id='exportDataChart' class="container btn btn-rtn text-uppercase btn-90 px-1" type="button" (click)="export()">EXPORT</button>
            </div>
    
          </div>
  
  
          <div class="no-padding-left row col-lg-12 mb-2 inputDateFrom">
            <div class="red-alert d-lg-block d-md-none d-sm-none d-none error_message"></div>
          </div>
        </div>
      </div>
      <div class="row col-12 px-0 mt-3">
        <div class="px-0 row col-sm-6 mb-2">
          Number of plan appointments: {{dataNumber.planAppts}}
        </div>
        <div class="px-0 row col-sm-6 mb-2">
          Number of actual appointments:  {{dataNumber.actualAppts}}
        </div>
        <div class="px-0 row col-sm-6 mb-2">
          Number of plan minutes:  {{dataNumber.planMinutes}} minutes
        </div>
        <div class="px-0 row col-sm-6 mb-2">
          Number of actual minutes:  {{dataNumber.actualMinutes}} minutes
        </div>

      </div>
    <rtn-table #table
               (onDataChange)="onDataChange($event)"
               [autoloading]="false"
               [data]="comparingList.data"
               [rowsOnPage]="comparingList.rowsOnPage"
               [numberOfPages]="comparingList.numberOfPages">
        <div class="row">
            <div class="col-sm-12 no-padding-left-right search-box listview">
                <table class="table table-bordered">
                    <thead class="account-thead">
                    <tr>
                        <th class="account-thead-text col-email">Plan</th>
                        <th class="account-thead-text col-dob">Duration</th>
                        <th class="account-thead-text col-full-name">Actual</th>
                        <th class="account-thead-text col-dob">Duration</th>
                        <th class="account-thead-text col-full-name">Actual Status</th>
                        <th class="account-thead-text col-full-name">Type</th>
                    </tr>
                    </thead>

                    <tbody  class="account-tbody">
                    <tr *ngFor="let item of table.data; let i = index">
                        <td class="account-email">{{ item.plan }}</td>
                        <td class="account-dob">{{ item.plan_duration }}</td>
                        <td class="account-full-name">{{ item.actual }}</td>
                        <td class="account-dob">{{ item.actual_duration }}</td>
                        <td class="account-full-name">{{ item.status }}</td>
                        <td class="account-full-name">{{ (item.event_name) ? item.event_name : '-' }}</td>
                    </tr>
                    <!-- <tr *ngIf="!isEmpty">
                      <td>There is no result</td>
                    </tr> -->
                    </tbody>
                    <tbody>
                      <tr *ngIf="!isEmpty" class="no-saved-search">
                          <td colspan="8">There is no result</td>
                      </tr>
                  </tbody>
                    <tfoot *ngIf="table.numberOfPages > 1">
                    <tr>
                        <td colspan="12" class="text-center table-responsive no-overflow-y">
                            <div class="page-footer">
                                <rtn-table-pager-bootstrap [enableExtreme]="false"></rtn-table-pager-bootstrap>
                            </div>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        
    </rtn-table>
    <div class="text-center">{{ errorMessage }}</div>
</div>
