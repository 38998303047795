import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '@app/shared/utils';
import { AlertService } from '@app/shared/alert';

@Component({
  selector: 'rtn-patient-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['../patients.component.scss','./activate.component.scss'],
  moduleId: module.id,
})

export class PatientsActivateComponent {

    constructor( private _router: Router, private _utils: Utils, private _alertService: AlertService ) {
        // TODO
    }

    onClickIcon(value: any) {
        if (value !== 'insurance' && value !== 'appointments') {
            if (this._utils.isPartialActiveStatus()) {
                this._alertService.onClose = () => {
                    // NOTHING
                };
                this._alertService.dismissButtonLabel = 'No';
                this._alertService.closeButtonLabel = 'OK';
                this._alertService.showDismissButton = false;
                this._alertService.showCloseButton = true;
                this._alertService.title = 'Your account has not full access yet. Please update insurance or contact RTN admin to get full access account';
                this._alertService.message = '';
                this._alertService.emitter('open');
                return;
            }
        }
        switch(value) {
            case 'main':
                this._router.navigate(['/patient', 'dashboard']);
                break;
            case 'insurance':
                this._router.navigate(['/patient', 'insurance']);
                break;
            case 'appointments':
                this._router.navigate(['/patient', 'appointment', "openings"]);
                break;
            case 'notifications':
                this._router.navigate(['/patient', 'notification']);
                break;
            case 'find-providers':
                this._router.navigate(['/patient', 'find-providers']);
                break;
            default:
                break;
        }
    }

}
