import { Component, QueryList, ViewChildren, Input, OnInit, AfterViewInit, OnDestroy, ElementRef, EventEmitter, Output } from '@angular/core';
import { OpeningHourSlotComponent } from './slot/slot.component';
import { OpeningHourSlot } from '../models/openingHourSlot';
import { Utils } from '../utils';

@Component({
  selector: 'rtn-opening-hour',
  moduleId: module.id,
  templateUrl: './opening-hour.component.html',
  styleUrls: ['./opening-hour.component.scss'],
})
export class OpeningHourComponent implements OnInit, AfterViewInit, OnDestroy{

    @Input() addNew: boolean = false;
    @Input() resetOnDisabled: boolean = false;
    @Input() enableValidation: boolean = false;
    @ViewChildren(OpeningHourSlotComponent) hourSlots: QueryList<OpeningHourSlotComponent>;
    @Output() removeMessage: EventEmitter<any> = new EventEmitter<any>();
    data: any = [];
    dataTemp: any = [];
    dayCheckStatus: any = Array(7).fill(-1);
    //numberOfSlots: number = 3;
    numberOfSlots: number = 6;

    constructor(
        private _utils: Utils,
        private el: ElementRef
    ) {
        // do nothing
    }

    getData() {
        let temp: any = [];
        this.hourSlots.toArray().forEach((item: any) => {
            if (!item.isDisabled) {
                temp.push(item.slot);
            }
        });
        return temp;
    }

    getCurrentCheckedBoxIndex() {
        let temp: any = [];
        this.hourSlots.toArray().forEach((item: any) => {
            if (!item.isDisabled) {
                temp.push(item.index);
            }
        });
        return temp;
    }

    injectData(data: any) {
        let dataTemp: any = [];
        let index = 0;
        // Add checked slots for data returned by WS
        for (let item of data) {
            let itemTemp = new OpeningHourDataWrapper();
            for (let i = 0; i < this.dayCheckStatus.length; i++) {
                if (item.days['day' + i]) {
                    if (this.enableValidation) {
                        this.dayCheckStatus[i] = index;
                    }
                }
            }
            itemTemp.checkedStatus = 'checked';
            itemTemp.isDisabled = false;
            itemTemp.item = item;
            index++;
            dataTemp.push(itemTemp);
        }
        // Add empty slots if number of active slots < numberOfSlots
        let numberOfRemainingSlots = this.numberOfSlots - dataTemp.length;
        if (numberOfRemainingSlots > 0) {
            for (let i = 0; i < numberOfRemainingSlots; i++) {
                let itemTemp = new OpeningHourDataWrapper();
                dataTemp.push(itemTemp);
            }
        }
        this.data = dataTemp;
    }

    injectError(currentCheckedBoxIndex: number[], data: any) {
        if (data.length === 0) {
            return;
        }
        let counter = 0;
        this.hourSlots.toArray().forEach((item: any) => {
            item.responseError = '';
            if (item.index === currentCheckedBoxIndex[counter] && counter < data.length) {
                item.responseError = data[counter].message;
                counter++;
            }
        });
    }

    onDayStatusChange(event: any) {
        if (this.enableValidation) {
            if (this.dayCheckStatus[event[1]] === event[0]) {
                // Release the day slot
                this.dayCheckStatus[event[1]] = -1;
            } else {
                // Occupy the day slot
                this.dayCheckStatus[event[1]] = event[0];
            }
        }
        this.clearError();
    }

    onSlotStatusChange(event: any) {
        if (this.enableValidation) {
            if (event[1]) {
                // Check the checkbox
                // Consider conflict with existing selected days in the other checked slots by changing the value of selected days for the checked slots
                let temp = new OpeningHourSlot();
                for (let i = 0; i < this.dayCheckStatus.length; i++) {
                    temp.days['day' + i] = this.data[event[0]].item.days['day' + i];
                    // If the dayslot is true and another openinghour slot is occuping it, release it
                    if (temp.days['day' + i] && this.dayCheckStatus[i] !== event[0] && this.dayCheckStatus[i] !== -1) {
                        temp.days['day' + i] = false;
                    }
                    // If the dayslot is true and no other openinghour slot is occuping it, ocupy it
                    if (temp.days['day' + i] && this.dayCheckStatus[i] === -1) {
                        this.dayCheckStatus[i] = event[0];
                    }
                }
                this.data[event[0]].item = temp;
            } else {
                // Uncheck the checkbox
                for (let i = 0; i < this.dayCheckStatus.length; i++) {
                    // Release all the day slot this openinghour slot is occupying
                    if (this.dayCheckStatus[i] === event[0]) {
                        this.dayCheckStatus[i] = -1;
                    }
                }
            }
        }
        this.clearError();
    }

    ngOnInit() {
        if (this.addNew) {
            this.injectData([]);
        }
    }

    ngAfterViewInit() {
        // do nothing
    }

    ngOnDestroy() {
        // do nothing
    }

    clearError() {
        this.removeMessage.emit('');
    }
}

export class OpeningHourDataWrapper {
    item: any = new OpeningHourSlot();
    checkedStatus: string = null;
    isDisabled: boolean = true;
}
