import {Component, OnInit, ViewChild} from '@angular/core';
import {HeaderService} from 'app/shell/header/header.service';
import {Router} from '@angular/router';
import {AlertDownloadComponent} from '../../shared/alert-download/alert-download.component';
import {SystemData, SystemService, SystemInterval, SystemEvent} from '../../shared/system';
import {AuthService} from '@app/core/auth.service';
import {AlertService} from '@app/shared/alert/alert.service';
import {Utils} from '@app/shared/utils';
import {LogoutModalsComponent} from '@app/shared/general/logout/modals.component';

@Component({
    selector: 'rtn-home-header',
    moduleId: module.id,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [HeaderService]
})
export class HeaderComponent implements OnInit {
    @ViewChild(LogoutModalsComponent) logoutDirective: LogoutModalsComponent;
    // @ViewChild(AlertDownloadComponent) alertDownloadDirective: AlertDownloadComponent;
    // logined = false;
    MainMobileMenu:boolean=false;
    showHighlight = false;
    routerParam = '/Home';
    openAlertDownload = false;
    isManagingMode: boolean = false;
    isShowCollapse: boolean = false;
    src = '../../../assets/img/homepage/newDesign/';

    constructor(public _systemData: SystemData,
                public _headerService: HeaderService,
                public _router: Router,
                public _systemService: SystemService,
                public _systemInterval: SystemInterval,
                public _systemEvent: SystemEvent,
                private _alertService: AlertService,
                private _utils: Utils,
                private _authService: AuthService) {
    }

    ngOnInit() {
        if (typeof (this._systemData.localStorage.user_token) !== 'undefined' && this._systemData.localStorage.user_token !== null) {
            this._systemService.setLoginedState(true);
            this.routerParam = '/patient';
        }
        setTimeout(() => {
            const pathname = window.location.hash;
            this.showHighlight = !(pathname === '/patient/homepage' || pathname === '/patient/physician-directory');
        }, 100);
    }

    handleCheckDevice(e: any) {
        this.openAlertDownload = e;
    }

    onClose(e: any) {
        this.openAlertDownload = false;
    }

    showCollapse() {
        this.isShowCollapse = !this.isShowCollapse;
    }

    logoutManaging() {
        
        //alert('back button');

        this._alertService.onClose = () => {
            let storeData = localStorage.getItem('storeData');
            if (this._utils.isProvider() && storeData) {
                let extractData = JSON.parse(localStorage.getItem('storeData'));
                localStorage.setItem('id_token', extractData.id_token);
                localStorage.setItem('last_active_token', extractData.last_active_token);
                localStorage.setItem('user_id', extractData.user_id);
                localStorage.setItem('user_role_id', extractData.user_role_id);
                localStorage.setItem('account_name', extractData.account_name);
                localStorage.setItem('user_status', extractData.user_status);
                localStorage.setItem('user_practice', extractData.user_practice);
                localStorage.setItem('loginEmail', extractData.loginEmail);
                localStorage.setItem('userFullName', extractData.userFullName);
                localStorage.removeItem('storeData');
                if (parseInt(extractData.user_role_id) === 5) {
                    this._router.navigate(['/Provider', 'Practice']);
                } else if (parseInt(extractData.user_role_id) === 6) {
                    this._router.navigate(['/Provider', 'ManageProvider']);
                }
            }
        };
        this._alertService.showDismissButton = true;
        this._alertService.dismissButtonLabel = 'No';
        this._alertService.showCloseButton = true;
        this._alertService.closeButtonLabel = 'Yes';
        this._alertService.backdrop = 'false';
        this._alertService.title = 'Are you sure you want to log out?';
        this._alertService.message = '';
        this._alertService.emitter('open');
    }

    openMainMenufunction(){
        this.MainMobileMenu=true;
     }
   
    closeMainMenufunction(){
       this.MainMobileMenu=false;
    }
   

}
