<div class="marketing-page container change-password">
  <div class="content-header row" [style.min-height.px]="minHeight">
      <div class="login-form col-12 col-md-12 col-lg-10 offset-md-1 offset-sm-0 offset-xs-0">
          <div class="login-form-body col-md-6">
              <h1 class="text-uppercase margin-bottom-0">Reset password</h1>
              <label>To complete the password reset process, please enter the new password</label>
              <form novalidate [formGroup]="resetForm" (ngSubmit)="reset()">
                  <div *ngIf="error" class="alert alert-danger" role="alert">{{errorMessage}}</div>
                  <div *ngIf="linkIsValid" class="form-body">
                      <div class="form-group">
                          <label for="password">New Password</label>
                          <i class="fa fa-exclamation-circle icon-warning" (click)="passwordFormatToolipDirective.toggle($event)"></i>
                          <input tabindex="1" type="password" autoFocusCustom [(ngModel)]="password" formControlName="password" name="password" id="password" class="form-control" [class.input-error]="responseError['password']" ngControl="password" maxlength="50" required>
                          <span class="message-error" *ngIf="responseError['password']">{{responseError['password']}}</span>
                      </div>
                      <div class="form-group">
                          <label for="password_confirm">Confirm New Password</label>
                          <input tabindex="2" type="password" [(ngModel)]="password_confirm" name="password_confirm" formControlName="password_confirm" id="password_confirm" class="form-control" [class.input-error]="responseError['password_confirm']" ngControl="password_confirm" maxlength="50" required>
                          <span class="message-error" *ngIf="responseError['password_confirmation']">{{responseError['password_confirmation']}}</span>
                      </div>
                      <div class="row form-submit">
                          <div class="col-12 col-md-12 col-lg-4 no-padding">
                              <button type="submit" [disabled]="!resetForm.valid" class="btn btn-rtn btn-lg login-btn text-uppercase btn-rtn-radius">Reset</button>
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>
<rtn-password-format-tooltip></rtn-password-format-tooltip>
