import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {WaitTimeRoutingModule} from './wait-time-routing.module';
import {NumberWaittimeComponent} from './number-waittime/number-waittime.component';
import {SpeedometerWaittimeComponent} from './speedometer-waittime/speedometer-waittime.component';
import { WaitTimeComponent } from './wait-time.component';

@NgModule({
  imports: [
    CommonModule,
    WaitTimeRoutingModule
  ],
  declarations: [
    NumberWaittimeComponent,
    SpeedometerWaittimeComponent,
    WaitTimeComponent
  ],
  exports: [NumberWaittimeComponent,
    SpeedometerWaittimeComponent]
})
export class WaitTimeModule {
}
