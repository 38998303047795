<!-- <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous"> -->
<div class="wrapper-patient">
    <app-header></app-header>
    <div class="container user-portal patient-wraper clear-both pt-3" role="main">
        <div class="col-12 col-lg-3 no-padding-left-right sidebar_fa pt-3">
            <nav
                class="navbar navbar-static-top col-md-12 col-lg-12 no-padding-left d-none d-md-block no-print">
                <div class="container no-padding-left-right">
                    <ul class="col-12 navbar-nav main-menu pr-0">
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2"
                                (click)="onChangeMenu('Providers Waittime')"
                                [class.active]="_systemService.navActivated(['/admin','waittimelog-by-provider'], _router)">
                                <i class="fa fa-user-circle style-icon" aria-hidden="true"></i>Wait Time Log by
                                Providers
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('Appoiment Slot')"
                                [class.active]="_systemService.navActivated(['/admin', 'appointment-by-slot'], _router)">
                                <i class="fa fa fa-th-large style-icon" aria-hidden="true"></i>Appointment by Slot
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2"
                                (click)="onChangeMenu('Appointment by Day')"
                                [class.active]="_systemService.navActivated(['/admin', 'appointment-by-day'], _router)">
                                <i class="fa fa-calendar-alt style-icon" aria-hidden="true"></i>Appointment by Day
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('Slot Duration')"
                                [class.active]="_systemService.navActivated(['/admin', 'duration-log-by-slot'], _router)">
                                <i class="fa fa-plus-square style-icon" aria-hidden="true"></i>Duration log by Slot
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2"
                                (click)="onChangeMenu('Statistical Appointment')"
                                [class.active]="_systemService.navActivated(['/admin/chart', 'statistical-appointment'], _router)">
                                <i class="fas fa-chart-pie style-icon" aria-hidden="true"></i>Same Day Appointment Statistics
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2"
                                (click)="onChangeMenu('Appointment Statistical by types')"
                                [class.active]="_systemService.navActivated(['/admin', 'statistical-appointment-by-types'], _router)">
                                <i class="fas fa-chart-pie style-icon" aria-hidden="true"></i>Appointment Statistics by type
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('Patient Waittime')"
                                [class.active]="_systemService.navActivated(['/admin', 'patient-waittime'], _router)">
                                <i class="fas fa-hourglass-start style-icon" aria-hidden="true"></i>Patient waiting room time
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2"
                                (click)="onChangeMenu('Compare Appointment')"
                                [class.active]="_systemService.navActivated(['/admin', 'plan-actual-comparing'], _router)">
                                <i class="far fa-calendar-check style-icon" aria-hidden="true"></i>Scheduled Appointment
                                Comparing
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu"> <!--offices-waittime-->
                            <a class="nav-link no-focus style-menu px-3 py-2"
                                (click)="onChangeMenu('Offices Wait Time')"
                                [class.active]="_systemService.navActivated(['/admin', 'waittime-office'], _router)">
                                <i class="fas fa-stethoscope style-icon" aria-hidden="true"></i>Wait Time Office
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('Notification')"
                                [class.active]="_systemService.navActivated(['/admin', 'notification-log'], _router)">
                                <i class="fa fa-bell-o style-icon" aria-hidden="true"></i>Notification
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('schedule-activity')"
                                [class.active]="_systemService.navActivated(['/admin', 'schedule-activity'], _router)">
                                <i class="fa fa-calendar-check-o style-icon" aria-hidden="true"></i>Schedule Activity
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('schedule-arrival-comparing')"
                                [class.active]="_systemService.navActivated(['/admin', 'schedule-arrival-comparing'], _router)">
                                <i class="fa fa-calendar-check-o style-icon" aria-hidden="true"></i>Schedule Arrival Comparing
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('cancel-reschedule-statistics')"
                                [class.active]="_systemService.navActivated(['/admin', 'cancel-reschedule-statistics'], _router)">
                                <i class="fa fa-calendar-check-o style-icon" aria-hidden="true"></i>Cancel / Reschedule Statistics
                            </a>
                        </li>
                        <li role="nav-item presentation" class="col-md-12 col-lg-12 style-side-menu" *ngIf="tenantname.includes('flatiron')">
                            <a class="nav-link no-focus style-menu px-3 py-2" (click)="onChangeMenu('appointment-by-slot-withlab')"
                                [class.active]="_systemService.navActivated(['/admin', 'appointment-by-slot-withlab'], _router)">
                                <i class="fa fa fa-th-large style-icon" aria-hidden="true"></i>Appointment by Slot(Lab)
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav
                class="navbar col-12 no-padding-left menu-mobile d-block d-md-none navbar-expand-md pl-3 no-print">
                <div class="form-group">
                    <select class="col-12 fontawesome-select menu-device" id="menu-device"
                        (change)="onChangeMenu($event.target.value)" [value]="menuSelect" [(ngModel)]="menuSelect">
                        <option class="menu-device-item" value="Providers Waittime">Wait Time Log by Providers</option>
                        <option class="menu-device-item" value="Appoiment Slot">Appointment by Slot</option>
                        <option class="menu-device-item" value="Appointment by Day">Appointment by Day</option>
                        <option class="menu-device-item" value="Slot Duration">Duration log by Slot</option>
                        <option class="menu-device-item" value="Statistical Appointment">Same Day Appointment Statistics</option>
                        <option class="menu-device-item" value="Appointment Statistical by types">Appointment Statistical by type</option>
                        <option class="menu-device-item" value="Patient Waittime">Patient waiting room time</option>
                        <option class="menu-device-item" value="Compare Appointment">Scheduled Appointment Comparing</option>
                        <option class="menu-device-item" value="Offices Wait Time">Wait Time Office</option>
                        <option class="menu-device-item" value="Notification">Notification</option>                        
                        <option class="menu-device-item" value="schedule-activity">Schedule Activity</option>
                        <option class="menu-device-item" value="schedule-arrival-comparing">Schedule Arrival Comparing</option>
                        <option class="menu-device-item" value="cancel-reschedule-statistics">Cancel / Reschedule Statistics</option>
                        <option class="menu-device-item" value="appointment-by-slot-withlab" *ngIf="tenantname.includes('flatiron')">Appointment by Slot(Lab)</option>
                    </select>
                </div>
            </nav>
        </div>
        <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="!showMenu">
      <router-outlet></router-outlet>
    </div> -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 data_log">
            <router-outlet></router-outlet>
        </div>
        <!-- <div class="col-xl-12">
      
    </div> -->
    </div>
    <!--<rtn-common-footer></rtn-common-footer>-->
</div>