import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {RestService} from '@app/core/rest.service';
import {Item} from '@app/shared/models/multipleSelect';
import {RtnDataTableCoreComponent} from '@app/shared/datatable';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Utils} from '@app/shared/utils';
import {ProvidersService} from '@app/providers/providers.service';
import { SystemService } from '@app/shared/system';
import {Module} from '@app/core/module';
@Component({
  moduleId: module.id,
  selector: 'app-list-patients',
  templateUrl: './list-patients.component.html',
  styleUrls: ['./list-patients.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListPatientsComponent implements OnInit, OnDestroy {

  accountList: any = new Object();
  statuses: any = [];
  status: any = [];
  page: number = 1;
  rowOfPage: number = 10;
  errorMessage: string;
  searchText: string = '';
  userStatusSubscriber: Subscription;
  userInfoSubscriber: Subscription;
  runRequest: boolean = true;
  module:any;
  isProvider: boolean = true;
  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    private _router: Router,
    private _utils: Utils,
    private service: ProvidersService,
    private _systemService: SystemService,
  ) {
    this.init();
  }

  init() {
    this.accountList.data = new Array();
    this.accountList.numberOfPages = 0;
    this.accountList.rowsOnPage = 10;
    this.errorMessage = null;
  }

  ngOnInit() {
    const module_enc = localStorage.getItem('module_enc');
    const token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    this.isProvider = this._utils.isProvider() ? true : false;
    if (!this.module[Module.PATIENT]) {
      this.loadingIndicatorService.stop();
      this._router.navigate(['/home', 'page-not-found']);
    }else{
      this.getStatus();
    }
    
  }

  getStatus() {
    if (this.userStatusSubscriber) {
      this.userStatusSubscriber.unsubscribe();
    }
    this.userStatusSubscriber = this.service.userStatus().subscribe(
      (success: any) => {
        if (success.error_code === 'RC000') {
          success.statuses.forEach((status: any) => {
            let i = new Item();
            i.oj = status;
            this.statuses.push(i);
          });
          this.getInfo(this.page);
        }
      });
  }

  ngOnDestroy() {
    if (this.userStatusSubscriber) {
      this.userStatusSubscriber.unsubscribe();
    }
    if (this.userInfoSubscriber) {
      this.userInfoSubscriber.unsubscribe();
    }
  }

  onDataChange(e: any) {
    this.page = e['currentPage'];
    this.getInfo(this.page, this.searchText);
  }

  getInfo(page: number, search_text: string = null) {
    this.runRequest = false;
    this.errorMessage = null;
    this.loadingIndicatorService.start();
    if (this.userInfoSubscriber) {
      this.userInfoSubscriber.unsubscribe();
    }
    this.userInfoSubscriber = this.service.userInfo(page, this.rowOfPage, search_text).subscribe(
      (success: any) => {
        this.runRequest = true;
        this.loadingIndicatorService.stop();
        if (success.error_code === 'RC000') {
          this.accountList.numberOfPages = success.last_page || 0;
          let accounts = success.users || [];
          accounts.map((user: any, i: any) => {
            let full_name = this._utils.generateFullName(user.first_name, user.middle_name, user.last_name);
            user.name_show = user.role_id === 2 ? user.account_name : full_name;
            user.full_address = this._utils.generateFullAddress(user.address, user.address2,
              user.city, user.state_code, user.zip);
            user.dob = this._utils.formatDate(user.dob);
            for (let i = 0; i < this.statuses.length; i++) {
              if (user.status === this.statuses[i].oj.id) {
                user.status_show = this.statuses[i].oj.name;
              }
            }
          });
          this.accountList.data = accounts;
        } else if (success.error_code === 'RC077') {
          this.init();
          this.errorMessage = success.error_messages;
        }
      },
      (error: any) => {
        this.runRequest = true;
        this.loadingIndicatorService.stop();
      },
      () => {
        this.runRequest = true;
        this.loadingIndicatorService.stop();
      });
  }

  onSearchSubmited(dataTable: RtnDataTableCoreComponent) {
    if (this.runRequest) {
      if (this.page === 1) {
        this.getInfo(this.page, this.searchText.trim());
      } else {
        dataTable.setPage(1, 10);
      }
    }
  }
}
