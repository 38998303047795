import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
   // API for Authentication
  loginUrl = environment.serverUrl + '/users/login';
  logoutUrl = environment.serverUrl + '/users/logout';
  refreshTokenUrl = environment.serverUrl + '/users/refreshToken';
  verifiCodeURL = environment.serverUrl + '/users/login/2fa';

  private _userInfor: any
  private _tokenValue: string;

  constructor(private _http: HttpClient) {
  }

  userLogin(username: string, password: string) {
    return this._http.post(this.loginUrl,
      {
        email : username,
        password: password,
        //client: ''
      }
    ).pipe(
      tap(async (token:any) => {
        this._tokenValue = token.token;
        this._userInfor = token;
        localStorage.setItem('user_token', token.token);
        return token;
      }),
    );
  }

  tokenValue(): string {
    return this._tokenValue;
  }

  userRole(): any {
      return localStorage.getItem('user_role_id')
  }

  userLogout() {
    return this._http.get(this.logoutUrl);
  }

  verifiOtpCode(userId: string, otp_code: string) {
    return this._http.post(this.verifiCodeURL, {
      user_id: userId,
      otp_code: otp_code,
      token: localStorage.getItem('user_token')
    }).pipe(
      tap(async (results:any) => {
        return results;
      }),
    );
  }

  clearLocalStorage() {
    // Remove localStorage role id when logout
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role_id');
    localStorage.removeItem('user_status');
    localStorage.removeItem('user_email');
    localStorage.removeItem('account_name');
    localStorage.removeItem('user_practice');
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('managing_user_id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('user_token');
    localStorage.removeItem('last_active_token');
    localStorage.removeItem('storeData');
    localStorage.removeItem('user_providerId');
    this.tokenValue = null;
  }

  refreshToken() {
    return this._http.get(this.refreshTokenUrl);
  }
}
