
    <form class="confirmation" [class.hidden]="!showConfirmation">
      <i [class.hidden]="!showIcon" class="fa fa-info fa-4x" aria-hidden="true"></i>
      <div class="text">{{ message }}</div>
      <div>
        <button type="submit" class="btn btn-rtn-inv btn-90 btn-no" (click)="handleOnNoClicked($event)" autofocus>No
        </button>
        <button type="button" class="btn btn-rtn btn-90" (click)="handleOnYesClicked($event)">Yes</button>
      </div>
    </form>
  