import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    EventEmitter,
    Output,
    ViewEncapsulation,
    AfterViewInit
} from '@angular/core';
import {MODAL_DIRECTIVES, ModalComponent} from '@app/shared/ng2-bs3-modal';
import {RestService} from '@app/core/rest.service';
import {AlertService} from '@app/shared/alert';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {MasterSchedulesService} from '@app/master-schedules/master-schedules.service';
import { Event } from '@app/master-schedules/master-schedules.component';

const _momentOptions = {
    week: {
        dow: 0, // Sunday is the first day of the week.
        doy: 6
    }
};

moment.updateLocale('en-US', _momentOptions);

@Component({
    moduleId: module.id,
    selector: 'app-schedules-mass-patient-notification',
    templateUrl: './schedules-mass-patient-notification.component.html',
    styleUrls: ['./schedules-mass-patient-notification.component.scss'],
    providers: [RestService],
    // encapsulation: ViewEncapsulation.None
})
export class SchedulesMassPatientNotificationComponent implements OnInit, OnDestroy, AfterViewInit {
    eventEntry: Event;
    messageRequire: string; // Show message require
    patients: any = [];
    patientsId: any = [];
    patientsSelected: any = [];
    providerID: any;
    errorResponse: any;
    massNotificationSubscriber: Subscription;
    getReceiversSubscriber: Subscription;
    patientDefaultType = 'Add Patient(s)';
    patientName:string= '';
    @ViewChild('Schedules_MassNotification_Patient') modal: ModalComponent;
    @Output()
    onClick: EventEmitter<any> = new EventEmitter();
    patientTypeSubscriber: Subscription;


    // ========== Specific Modal Method ========== //

    massNotiModel: MassNotiClass = new MassNotiClass();

    constructor(
        private _restService: MasterSchedulesService,
        private _alertService: AlertService,
        private loadingIndicatorService: LoadingIndicatorService) {
    }

    validatePatient() {
        if(this.massNotiModel.content == ""){
            this.messageRequire = 'This field is required';
            return false;
        }
        return true;
    }

    massNotiClicked() {
            if(this.validatePatient()){
            this.patientsSelected = this.eventEntry.data.patientId;
            this.providerID = this.eventEntry.data.providerId
            this.loadingIndicatorService.start();
            if (this.massNotificationSubscriber) {
                this.massNotificationSubscriber.unsubscribe();
            }
            this.massNotificationSubscriber = this._restService.massNotificationToPatient({
                patientID: this.patientsSelected,
                msg: this.massNotiModel.content,
                providerID: this.providerID
            }).subscribe(
                (success: any) => {
                    this.loadingIndicatorService.stop();
                    // if (success.error_code === 'RC000') {
                        this._alertService.onClose = () => {
                            this.dismiss();
                        };
                        this._alertService.showDismissButton = false;
                        this._alertService.dismissButtonLabel = 'Close';
                        this._alertService.showCloseButton = true;
                        this._alertService.closeButtonLabel = 'Close';
                        this._alertService.title = 'Sent successfully!';
                        this._alertService.message = '';
                        this._alertService.emitter('open');
                    // }
                },
                (error: any) => {
                    this.loadingIndicatorService.stop();
                    const e = error.error;
                    if (e.error_code === 'RC050') {
                        this.messageRequire = e.error_fields.body;
                    } else if (e.error_code === 'RC049') {
                        this._alertService.onClose = () => {
                            this.dismiss();
                        };
                        this._alertService.showDismissButton = false;
                        this._alertService.dismissButtonLabel = 'Close';
                        this._alertService.showCloseButton = true;
                        this._alertService.closeButtonLabel = 'Close';
                        this._alertService.title = 'Sent failed.';
                        this._alertService.message = '';
                        this._alertService.emitter('open');
                    }
                },
                () => {
                    this.loadingIndicatorService.stop();
                }
            );
            }
    }

    ngOnInit() {

        const self = this;
        // jQuery('.notification-mass').parent().css('width', '600px');

    }

    ngAfterViewInit(): void {
        const _self = this;

    }



    ngOnDestroy() {
        if (this.massNotificationSubscriber) {
            this.massNotificationSubscriber.unsubscribe();
        }
    }

    // ========== General Modal Method ========== //

    dismiss() {
        this.modal.dismiss();
        this.messageRequire = null;
        this.massNotiModel.content = '';
        this.patientsSelected = [];
        this.errorResponse = null;
    }

    onKeyDown(event: any) {
        if (event.keyCode === 13 || (event.keyCode === 13 && event.shiftKey)) {
            event.preventDefault();
        }
    }

    onKeyUp(e: any) {
        this.messageRequire = null;
    }

    open() {
        this.patientName = this.eventEntry.data.patientName;
        this.modal.open();
    }

    close() {
        this.modal.close();
    }
}

export class MassNotiClass {
    content = '';
}
