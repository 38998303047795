import { Component, OnInit, ViewChild} from '@angular/core';
import { ActivatephoneService } from '@app/activatephone/activatephone.service';
import { Utils } from '@app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import { Subscription } from 'rxjs';
import {SystemInterval, SystemEvent, SystemData, SystemService} from '@app/shared/system';
import * as moment from 'moment';
declare let jQuery: any;

@Component({
  selector: 'app-activate-phone',
  templateUrl: './activatephone.component.html',
  styleUrls: ['./activatephone.component.scss']
})
export class ActivatephoneComponent implements OnInit {
  isFirefox = false;
  successMessage: string = '';
  errorMessage: string = '';
  openslotId: any=''
  verifyPhoneSubscriber: Subscription;
  searchOpeningsResult: any = [];

  constructor(private activatephoneService: ActivatephoneService, 
    private _router: Router,
    public _utils: Utils,    
    private _loadingIndicatorService: LoadingIndicatorService,
    ) {
  }

  ngOnInit() {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (is_firefox) {
      this.isFirefox = true;
    }

    const currentUrl = this._router.url;
    const splitArr = currentUrl.split("/");

    const code = splitArr[splitArr.length - 1];

    if (code == null || code == undefined) {
      this.errorMessage = "Invalid request";
    } else {
      this.openslotId = code;
    }

    if (this.openslotId != '') {
      this.getPhoneDetails();
    } 
  }
  ngOnDestroy() {
    if (this.verifyPhoneSubscriber) {
      this.verifyPhoneSubscriber.unsubscribe();
    }    
  }
  
  getPhoneDetails() {
    var self = this;
    if (this.verifyPhoneSubscriber) {
      this.verifyPhoneSubscriber.unsubscribe();
    }
    
    this.successMessage = '';
    this.errorMessage = '';

    this._loadingIndicatorService.start();
    this.activatephoneService.verifyPhoneFromURL({ userId : self.openslotId}).subscribe((success: any) => {
      this._loadingIndicatorService.stop();

      this.successMessage = success.error_messages;
      console.log(success);
    }, (err:any) => {
      console.log(err.error);
      this._loadingIndicatorService.stop();
      this.errorMessage = err.error.error_messages;
    });    
  }   
}
