import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange } from '@angular/core';
// import { CORE_DIRECTIVES } from '@angular/common';

@Component({
    selector: 'rtn-table',
    template: '<ng-content></ng-content>',
    // directives: [CORE_DIRECTIVES]
})

export class RtnDataTableCoreComponent implements OnInit, OnChanges {

    @Output() public onDataChange: EventEmitter<any> = new EventEmitter<AsyncParams>();
    @Output() public onSortChange: EventEmitter<any> = new EventEmitter<AsyncParams>();
    @Output() public onFilterChange: EventEmitter<any> = new EventEmitter<AsyncParams>();
    @Output() public onPageChange: EventEmitter<any> = new EventEmitter<AsyncParams>();

    @Input() public rowsOnPage = 20;
    @Input() public currentPage = 1;
    @Input() public numberOfPages = 0;
    @Input() public data: any[] = [];
    @Input() public autoloading = true;

    private sortField: string;
    private sortOrder = 'asc';
    private _parameters: any;

    constructor() {
        this._parameters = new Object();
    }

    ngOnInit() {
        this.setParams({
            currentPage: this.currentPage,
            rowsOnPage: this.rowsOnPage,
            numberOfPages: this.numberOfPages,
            sortField: this.sortField,
            sortOrder: this.sortOrder
        });

        if (this.autoloading) {
            this.onDataChange.next(this.getParams());
        }
    }

    public getParam(key: string): any {
        return this._parameters.hasOwnProperty(key) ? this._parameters[key] : null;
    }

    public getParams(): AsyncParams {
        return this._parameters;
    }

    public setParams(_input: any): void {
        this._parameters = Object.assign({}, this.getParams(), _input);
    }

    public setSort(sortField: string, sortOrder: string): void {
        if (this.sortField !== sortField || this.sortOrder !== sortOrder) {
            this.sortField = sortField;
            this.sortOrder = sortOrder;
            this.setParams({
                sortField: sortField,
                sortOrder: sortOrder
            });
            this.onDataChange.emit(this.getParams());
            this.onSortChange.emit(this.getParams());
        }
    }

    public setPage(currentPage: number, rowsOnPage: number): void {
        if (this.rowsOnPage !== rowsOnPage || this.currentPage !== currentPage) {
            this.currentPage = this.currentPage !== currentPage ? currentPage : this.calculateNewActivePage(this.rowsOnPage, rowsOnPage);
            this.rowsOnPage = rowsOnPage;
            this.setParams({
                currentPage: this.currentPage,
                rowsOnPage: this.rowsOnPage
            });
            this.onPageChange.emit(this.getParams());
            this.onDataChange.emit(this.getParams());
        }
    }

    public reload(): void {
        this.onDataChange.emit(this.getParams());
    }

    public setFilter(_params: AsyncParams) {
        this.currentPage = 1;
        this.setParams(_params);
        this.onFilterChange.emit(this.getParams());
        this.onDataChange.emit(this.getParams());
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }) {
        if (changes['autoloading'] && (changes['autoloading'].currentValue === true)) {
            this.onDataChange.emit(this.getParams());
        }
    }

    ngAfterViewInit() {
        jQuery('.scrollbar-top').scroll(function() {
            jQuery('.tbl-data-log').scrollLeft(jQuery('.scrollbar-top').scrollLeft());
        });
        jQuery('.tbl-data-log').scroll(function() {
            jQuery('.scrollbar-top').scrollLeft(jQuery('.tbl-data-log').scrollLeft());
        });
    }

    private calculateNewActivePage(previousRowsOnPage: number, currentRowsOnPage: number): number {
        return Math.ceil(((this.currentPage - 1) * previousRowsOnPage + 1) / currentRowsOnPage);
    }
}

export class AsyncParams {
    currentPage = 1;
    rowsOnPage = 10;
    numberOfPages = 0;
    sortField: string;
    sortOrder: string;
}
