import {Component, ViewChild, OnInit, AfterViewInit, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
// import { EditService } from '../services/edit.service';
import {SystemEvent, SystemService, SystemData} from '@app/shared/system';
import {AlertService} from '@app/shared/alert';
import {Select2Directive} from '@app/shared/form/select2.directive';
import {OpeningHourComponent} from '@app/shared/opening-hour/opening-hour.component';
import {InputMaskDirective} from '@app/shared/form/input-mask.directive';
import {SimpleMaskDirective} from '@app/shared/form/simple-mask.directive';
import {PasswordFormatTooltipComponent} from '@app/shared/tooltip/password-format/password-format.component';
import {ProviderDetail} from '@app/shared/models/providerDetail';
import {Utils} from '@app/shared/utils';
import {Subscription} from 'rxjs';
import {LoadingIndicatorService} from '@app/shared/loading-indicator';
import {RestService} from '@app/core/rest.service';
import {ProvidersService} from '@app/providers/providers.service';
import {AdminRestService} from '@app/admin/admin.service';
import {Select2LocationDirective} from '@app/shared/form/select2-location.directive';

declare let jQuery: any;

@Component({
  moduleId: module.id,
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, AfterViewInit, OnDestroy {
  providerDetail: ProviderDetail = new ProviderDetail();
  // billing: BillingInfo = new BillingInfo();
  responseError: ProviderDetail = new ProviderDetail();
  /*insuranceCompanyError: any = {};
  insurancePlanError: any = {};*/
  listTime: any[] = new Array();
  selectedSpecialties: any;
  is_mapped: boolean;
  fullNameTemp: string;
  successMessage: string;
  /*insuranceCompanyObject: InsuranceCompany = new InsuranceCompany();
  insuranceCompany: any = [];
  insuranceCompanies: any;
  insurancePlans: any = [];
  insuranceArr: any = [];
  isAddInsurance: boolean = false;
  insuranceList: any;*/
  practiceList: any;
  showPractice = false;
  practiceDefault: any;

  updateProfileSubscriber: Subscription;
  onGetProfileListener: Subscription;
  onGetSpecialtiesListener: Subscription;
  /*getAcceptedInsurancesSubscriber: Subscription;
  getInsurancesSubscriber: Subscription;*/
  loadPracticeListSubscriber: Subscription;

  @ViewChild(Select2Directive) specialtiesSelector: Select2Directive;
  @ViewChild(PasswordFormatTooltipComponent) passwordFormatToolipDirective: PasswordFormatTooltipComponent;
  @ViewChild(OpeningHourComponent) openingHourDirective: OpeningHourComponent;
  @ViewChild(Select2LocationDirective) locationSelector: Select2LocationDirective;
  fetchAllLocationSubscriber: Subscription;
  arrlocations:any=[];
  selectedLocationId:any=[];
  onGetLocationListener: Subscription;  
  selectedLocations: any;

  constructor(
    private _systemEvent: SystemEvent,
    private _systemService: SystemService,
    public _systemData: SystemData,
    private _service: ProvidersService,
    private _alertService: AlertService,
    private _router: Router,
    private _utils: Utils,
    private _loadingIndicatorService: LoadingIndicatorService,
    private adminRestService : AdminRestService
  ) {
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
    this.loadPracticeListSubscriber = this._service.getPracticeList().subscribe(
      (success: any) => {
        this.practiceList = success.practices;
        this.practiceDefault = '';
        this.practiceList.map((item: any) => {
          if (item.name === 'RTN') {
            // Set default value for office
            this.practiceDefault = item.id;
          }
        });
      }
    );
    delete (this._systemData.profile);
    this._systemService.getProfile(null);
    this.onGetProfileListener = this._systemEvent.profile.subscribe((done: boolean) => {
      if (done) {
        this.providerDetail = this._systemData.profile;
        /* if (this._systemData.profile.billing !== null) {
            this.billing = this._systemData.profile.billing;
        } */
        this.is_mapped = this.providerDetail.is_mapped;
        this.providerDetail.phone_mobile = this.providerDetail.phone_mobile.replace('+1 ', '');
        this.providerDetail.phone_mobile_tmp = this.providerDetail.phone_mobile;
        /* this.billing.phone_mobile = this.billing.phone_mobile.replace('+1 ', '');
        this.billing.phone_mobile_tmp = this.billing.phone_mobile;
        this.billing.state_code = this.billing.state_code === null ? '' : this.billing.state_code; */
        this.selectedSpecialties = [];
        const specialtiesList = this.providerDetail.specialties;
        if (specialtiesList !== null) {
          for (let i = 0; i < specialtiesList.length; i++) {
            this.selectedSpecialties.push(specialtiesList[i].id);
          }
          if (this._systemData.specialties.length !== 0) {
            this.specialtiesSelector.updateData(this.selectedSpecialties);
          }
        }
        this.openingHourDirective.injectData(this.providerDetail.opening_hours);

        this.selectedLocations = [];

        if (this.providerDetail.location_id.length !== 0) {

          for (let i = 0; i < this.providerDetail.location_id.length; i++) {
            this.selectedLocations.push(this.providerDetail.location_id[i]);
          }   
          if (this._systemData.providerLocationLists.length !== 0) {
            this.locationSelector.updateData(this.selectedLocations);
          }
          //this.selectedLocationId = this.providerDetail.location_id;          
         // this.locationSelector.updateData(this.selectedLocationId);
        } 

        // Check if have case no insurance
        /* let index = this.providerDetail.insurances.indexOf(null);
        if (index !== -1) {
            this.providerDetail.insurances[index] = '-1';
        }
        if (this._systemData.insurances.length !== 0) {
            this.insurancesSelector.updateData(this.providerDetail.insurances);
        } */
        // Set default value for office
        if (this.providerDetail.office_name === null
          || this.providerDetail.office_name === 'null'
          || this.providerDetail.office_name === '') {
          if (localStorage.getItem('user_practice') === 'null'
            || localStorage.getItem('user_practice') === null
            || localStorage.getItem('user_practice') === '') {
            this.providerDetail.office_name = this.practiceDefault;
            this.showPractice = true;
          } else {
            this.providerDetail.office_name = localStorage.getItem('user_practice');
          }
        }
        /*// sort
        this.providerDetail.insurances.sort((a: any, b: any) => {
            return a.batch - b.batch;
        });
        // Group company
        let companyArrKeyHolder: any = [];
        let companyArr: any = [];
        this.providerDetail.insurances.forEach(function(item: any){
            companyArrKeyHolder[item.batch] = companyArrKeyHolder[item.batch]||{};
            var obj = companyArrKeyHolder[item.batch];
            if(Object.keys(obj).length === 0) {
                companyArr.push(obj);
            }
            obj.company_name = (item.company_name === null) ? 'No Insurance/Self Pay' : item.company_name;
            obj.insurance_company_id = (item.insurance_company_id === null) ? '-1' : item.insurance_company_id;
            obj.plans  = obj.plans || [];
            obj.plans.push({plan_name: item.plan_name, insurance_plan_id: item.insurance_plan_id});
        });
        this.insuranceArr = companyArr;*/
      }
    });
    this.onGetSpecialtiesListener = this._systemEvent.specialty.subscribe((done: boolean) => {
      if (done) {
        if (this.selectedSpecialties) {
          this.specialtiesSelector.updateData(this.selectedSpecialties);
        }
      }
    });
    this.onGetLocationListener = this._systemEvent.providerlocations.subscribe((done: boolean) => {
      if (done) {
        if (this.selectedLocations) {
          //console.log(this.selectedSpecialties);
          this.locationSelector.updateData(this.selectedLocations);
        }
      }
    });
    /* this.getInsurancesSubscriber = this._systemEvent.insurances.subscribe((done: boolean) => {
        if (done) {
            setTimeout(() => {
                this.insurancesSelector.updateData(this.providerDetail.insurances);
            }, 500);
        }
    }); */
  }

  ngOnInit() {
    this._systemService.getStates();
    this._systemService.getSpecialties();
    this._systemService.getLocationList();
    
    //this.locationSelector.clearData();
    //this.locationSelector.resetSelect2(jQuery('#select2location'));

    

    //this.fetchAllLocation();

    // this._systemService.getInsuranceList();

    /*// get all accepted insurance
    this.getAcceptedInsurancesSubscriber = this._editService.getAcceptedInsurances().subscribe(
        (success: any) => {
            this.insuranceCompanies = success.insurance_companies;
        }
    );*/
  }

  ngAfterViewInit() {
    // todo
  }

  ngOnDestroy() {
    if (this.updateProfileSubscriber) {
      this.updateProfileSubscriber.unsubscribe();
    }
    if (this.onGetProfileListener) {
      this.onGetProfileListener.unsubscribe();
    }
    if (this.onGetSpecialtiesListener) {
      this.onGetSpecialtiesListener.unsubscribe();
    }
    /* if (this.getInsurancesSubscriber) {
        this.getInsurancesSubscriber.unsubscribe();
    } */
    if (this.loadPracticeListSubscriber) {
      this.loadPracticeListSubscriber.unsubscribe();
    }
    if (this.onGetLocationListener) {
      this.onGetLocationListener.unsubscribe();
    }
    /*if (this.getAcceptedInsurancesSubscriber) {
        this.getAcceptedInsurancesSubscriber.unsubscribe();
    }*/
  }

  /*startSetValue() {
      this.acceptedInsuranceDirectives.forEach(directive => {
          directive.setValue();
      });
  }*/

  updateProfileForm(formVals: any) {
    this._loadingIndicatorService.start();
    this.successMessage = null;
    this.clearError();
    const value = Object.assign({}, formVals);
    // let billing_info = this.formatDataBillingInfo(value);
    this.fullNameTemp
      = this._utils.generateFullName(this.providerDetail.first_name, value.middle_name, this.providerDetail.last_name);
    value.opening_hours = this.openingHourDirective.getData();
    // value.billing = billing_info;
    value.is_mapped = this.is_mapped;
    value.specialties = this.specialtiesSelector.getData();
    value.office_name = this._systemData.profile.office_name;
    value.first_name = this._systemData.profile.first_name;
    value.last_name = this._systemData.profile.last_name;
    value.npi = this._systemData.profile.npi;
    // value.insurances = this.insurancesSelector.getData();
    /*value.insurance_plans = this.acceptedInsuranceDirective.getInsuranceEdit();*/

    value.location_id = this.locationSelector.getData();
    // Get the checked box index before sending out save request
    const currentCheckedBoxIndex = this.openingHourDirective.getCurrentCheckedBoxIndex();
    if (this.updateProfileSubscriber) {
      this.updateProfileSubscriber.unsubscribe();
    }
    this.updateProfileSubscriber = this._service.updateProviderProfile(value).subscribe(
      (success: any) => {
        this._loadingIndicatorService.stop();
        localStorage.setItem('userFullName', this.fullNameTemp);
        // Update mapping since we use both global and local instance of Profile
        this.is_mapped = success.is_mapped;
        value.is_mapped = success.is_mapped;
        this.providerDetail.is_mapped = success.is_mapped;
        // Update global is_mapped of Profile instance since we don't use the local instance to render view
        const temp = value.specialties;
        if (temp !== null) {
          // Convert from an array of ID String to an array of Object({id: value})
          value.specialties = [];
          for (let i = 0; i < temp.length; i++) {
            // We need to deduct 1 from the index because in Database, the specialties start from 1,
            // in the object returned to front end, it starts at 0
            value.specialties.push({
              id: parseInt(temp[i], 10),
              name: this._systemData.specialties[parseInt(temp[i], 10) - 1].name
            });
          }
        }
        // Update global Profile instance
        this._systemData.profile = value;

        this.successMessage = success.error_messages;
      },
      (error: any) => {
        this._loadingIndicatorService.stop();
        const res = error.error;
        if (res.error_code === 'RC060') {
          this.providerDetail.is_mapped = false;
          this.is_mapped = false;
          this.responseError = res.error_fields;
        } else if (res.error_code === 'RC070') {
          this._alertService.onClose = () => {
            location.reload();
          };
          this._alertService.showDismissButton = false;
          this._alertService.dismissButtonLabel = 'Close';
          this._alertService.showCloseButton = true;
          this._alertService.closeButtonLabel = 'OK';
          this._alertService.title = res.error_messages;
          this._alertService.message = '';
          this._alertService.emitter('open');
        } else {
          this.responseError = res.error_fields;
          this.openingHourDirective.injectError(currentCheckedBoxIndex, [{
            index: 0,
            message: 'You are there'
          }, {index: 2, message: 'You are here'}]);
          if (typeof this.responseError['password'] !== 'undefined') {
            this.responseError['password_confirmation'] = '';
          } else {
            this.responseError['password_confirmation'] = res.error_fields.password_confirmation;
          }
        }
        let messagePos1 = '';
        let messagePos2 = '';
        let messagePos3 = '';
        let messagePos4 = '';
        let messagePos5 = '';
        let messagePos6 = '';
        let messagePos7 = '';

        if ((<any>this.responseError)['opening_hours.0.days']
          && (<any>this.responseError)['opening_hours.0.days'].length !== 0) {
          messagePos1 = (<any>this.responseError)['opening_hours.0.days'];
        } else if ((<any>this.responseError)['opening_hours.0.end_time']
          && (<any>this.responseError)['opening_hours.0.end_time'].length !== 0) {
          messagePos1 = (<any>this.responseError)['opening_hours.0.end_time'];
        }
        if ((<any>this.responseError)['opening_hours.1.days']
          && (<any>this.responseError)['opening_hours.1.days'].length !== 0) {
          messagePos2 = (<any>this.responseError)['opening_hours.1.days'];
        } else if ((<any>this.responseError)['opening_hours.1.end_time']
          && (<any>this.responseError)['opening_hours.1.end_time'].length !== 0) {
          messagePos2 = (<any>this.responseError)['opening_hours.1.end_time'];
        }
        if ((<any>this.responseError)['opening_hours.2.days']
          && (<any>this.responseError)['opening_hours.2.days'].length !== 0) {
          messagePos3 = (<any>this.responseError)['opening_hours.2.days'];
        } else if ((<any>this.responseError)['opening_hours.2.end_time']
          && (<any>this.responseError)['opening_hours.2.end_time'].length !== 0) {
          messagePos3 = (<any>this.responseError)['opening_hours.2.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.3.days']
          && (<any>this.responseError)['opening_hours.3.days'].length !== 0) {
          messagePos4 = (<any>this.responseError)['opening_hours.3.days'];
        } else if ((<any>this.responseError)['opening_hours.3.end_time']
          && (<any>this.responseError)['opening_hours.3.end_time'].length !== 0) {
          messagePos4 = (<any>this.responseError)['opening_hours.3.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.4.days']
          && (<any>this.responseError)['opening_hours.4.days'].length !== 0) {
          messagePos5 = (<any>this.responseError)['opening_hours.4.days'];
        } else if ((<any>this.responseError)['opening_hours.4.end_time']
          && (<any>this.responseError)['opening_hours.4.end_time'].length !== 0) {
          messagePos5 = (<any>this.responseError)['opening_hours.4.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.5.days']
          && (<any>this.responseError)['opening_hours.5.days'].length !== 0) {
          messagePos6 = (<any>this.responseError)['opening_hours.5.days'];
        } else if ((<any>this.responseError)['opening_hours.5.end_time']
          && (<any>this.responseError)['opening_hours.5.end_time'].length !== 0) {
          messagePos6 = (<any>this.responseError)['opening_hours.5.end_time'];
        }

        if ((<any>this.responseError)['opening_hours.6.days']
          && (<any>this.responseError)['opening_hours.6.days'].length !== 0) {
          messagePos7 = (<any>this.responseError)['opening_hours.6.days'];
        } else if ((<any>this.responseError)['opening_hours.6.end_time']
          && (<any>this.responseError)['opening_hours.6.end_time'].length !== 0) {
          messagePos7 = (<any>this.responseError)['opening_hours.6.end_time'];
        }       

        const showError = [
          {index: 0, message: messagePos1},          
          {index: 1, message: messagePos2},
          {index: 2, message: messagePos3},
          {index: 3, message: messagePos4},
          {index: 4, message: messagePos5},
          {index: 5, message: messagePos6}
        ];
        this.openingHourDirective.injectError(currentCheckedBoxIndex, showError);

        const data: any = [];
        /*this.insurancePlanError = {};
        this.insuranceCompanyError = {};
        for (let field in this.responseError) {
            if (field.substr(0, 15) === 'insurance_plans') {
                let thenum = field.replace(/[^0-9]/g, '');
                if (field.substr(field.lastIndexOf('.')+1) === 'insurance_plan_ids') {
                    this.insurancePlanError[thenum] = this.responseError[field];
                } else {
                    this.insuranceCompanyError[thenum] = this.responseError[field];
                }
            }
        }*/
      }
    );
  }

  clearError() {
    this.responseError = new ProviderDetail();
  }

  /* formatDataBillingInfo(data: any) {
      return {
          'first_name': data.billing_first_name,
          'last_name': data.billing_last_name,
          'email': data.billing_email,
          'phone_mobile': data.billing_phone_mobile,
          'address': data.billing_address,
          'address2': data.billing_address2,
          'city': data.billing_city,
          'state_code': data.billing_state_code,
          'zip': data.billing_zip
      };
  } */

  preventWhiteSpaceInput(event: any) {
    if (event.target.value.match(/\s/g)) {
      event.target.value = event.target.value.replace(/\s/g, '');
    }
  }

  onKeyUp(event: any, error: string) {
    this.successMessage = null;

    switch (error) {
      case 'phone_mobile':
        this.responseError['phone_mobile'] = null;
        break;
      case 'password':
        this.responseError['password'] = null;
        break;
      case 'password_confirmation':
        this.responseError['password_confirmation'] = null;
        break;
      case 'address':
        this.responseError['address'] = null;
        break;
      case 'address2':
        this.responseError['address2'] = null;
        break;
      case 'city':
        this.responseError['city'] = null;
        break;
      case 'state_code':
        this.responseError['state_code'] = null;
        break;
      case 'zip':
        this.responseError['zip'] = null;
        break;
      case 'first_name':
        this.responseError['first_name'] = null;
        break;
      case 'last_name':
        this.responseError['last_name'] = null;
        break;
      case 'credentials':
        this.responseError['credentials'] = null;
        break;
      case 'npi':
        this.responseError['npi'] = null;
        break;
      case 'office_name':
        this.responseError['office_name'] = null;
        break;
      case 'specialties':
        this.responseError['specialties'] = null;
        break;
      case 'website_url':
        this.responseError['website_url'] = null;
        break;
      case 'office_address':
        this.responseError['office_address'] = null;
        break;
      case 'office_address2':
        this.responseError['office_address2'] = null;
        break;
      case 'office_city':
        this.responseError['office_city'] = null;
        break;
      case 'office_state_code':
        this.responseError['office_state_code'] = null;
        break;
      case 'office_zip':
        this.responseError['office_zip'] = null;
        break; 
      case 'location_id':
          this.responseError['location_id'] = null;
          break;    
      /* case 'insurances':
          this.responseError['insurances'] = null;
          break;
      case 'billing_first_name':
          (<any>this.responseError)['billing.first_name'] = null;
          break;
      case 'billing_last_name':
          (<any>this.responseError)['billing.last_name'] = null;
          break;
      case 'billing_email':
          (<any>this.responseError)['billing.email'] = null;
          break;
      case 'billing_phone_mobile':
          (<any>this.responseError)['billing.phone_mobile'] = null;
          break;
      case 'billing_address':
          (<any>this.responseError)['billing.address'] = null;
          break;
      case 'billing_address2':
          (<any>this.responseError)['billing.address2'] = null;
          break;
      case 'billing_city':
          (<any>this.responseError)['billing.city'] = null;
          break;
      case 'billing_state_code':
          (<any>this.responseError)['billing.state_code'] = null;
          break;
      case 'billing_zip':
          (<any>this.responseError)['billing.zip'] = null;
          break; */
    }
  }

  /*addMoreInsuranceCompany() {
      this.successMessage = null;
      this.isAddInsurance = true;
      this.insuranceArr.push(Object.assign({}, this.insuranceCompanyObject));
  }*/

  removeMessage(event: any) {
    this.successMessage = null;
  }
  fetchAllLocation(){
    const self = this;
        
    this._loadingIndicatorService.start();
    if (this.fetchAllLocationSubscriber) {
        this.fetchAllLocationSubscriber.unsubscribe();
    }
    this.fetchAllLocationSubscriber = this.adminRestService.fetchAllLocation().subscribe((success: any) => {
          this._loadingIndicatorService.stop();
          this.arrlocations = success.aptlocation;   
                    
          if (this.selectedLocationId.length !== 0) {            
            this.locationSelector.updateData(this.selectedLocationId);
          } 
              
      }, (error: any) => {
          this._loadingIndicatorService.stop();         
    });
  }
}
