import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuickDownloadRoutingModule } from './quick-download-routing.module';
import { QuickDownloadComponent } from './quick-download.component';

import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [
    CommonModule,
    QuickDownloadRoutingModule,    
    FormsModule,
    SharedModule
  ],
  declarations: [
    QuickDownloadComponent,    
  ],
  exports: [    
  ]
})
export class QuickDownloadModule { }
