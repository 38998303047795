<div class="row ehr-integration">
  <h3 class="width-full font-size-24">EHR Integration</h3>
  <div class="connection-info width-full">
      <form #form="ngForm">
          <h4>
              <span class="connectionInfoTitle font-size-20">CONNECTION INFO</span>
          </h4>
          <div class="card card-default">
              <!-- //Body -->
              <div class="card-body">
                  <div class="row form-inline">
                      <strong class="display-inline">Connection Status:</strong><div class="display-inline padding-left-35">{{status}}</div>
                  </div>
                  <div class="row form-inline">
                      <strong>Operation Mode:</strong>
                      <div class="padding-left-35 padding-top-15 width-full">
                          <input type="radio"
                              name="mode" [value]="true" [ngModel]="selectedConnectionMode" (click)="selectedMode(true)">Online Mode
                      </div>
                      <div class="padding-left-35 padding-top-10 width-full">
                          <input type="radio"
                              name="mode" [value]="false" [ngModel]="selectedConnectionMode" (click)="selectedMode(false)">Offline Mode
                      </div>
                  </div>
              </div>
              <!-- //Footer -->
              <div class="card-footer">
                  <button type="button" class="btn btn-rtn btn-90 text-uppercase" (click)="saveConnectionInfo()">SAVE</button>
                  <span *ngIf="messageResponseError" class="message-error">{{messageResponseError}}</span>
                  <span *ngIf="messageResponseSuccess" class="message-success">{{messageResponseSuccess}}</span>
              </div>
          </div>
      </form>
  </div>
  <!--<h4 class="width-full font-size-24 sync-pokitdok-magin">Sync Pokitdok list</h4>
  <button type="button" class="btn btn-rtn btn-180 text-uppercase" (click)="synchronizePokitdok()">SYNCHRONIZE</button>
  <div class="col-12 px-0" [class.d-none] = "!isFlatiron">
    <h4 class="width-full font-size-24 sync-pokitdok-magin">Sync HL7</h4>
    <button type="button" class="btn btn-rtn btn-180 text-uppercase" (click)="synchronizeHL7()">SYNCHRONIZE HL7</button>
  </div>
  <span *ngIf="messageResponseSyncSuccess" class="message-success">{{messageResponseSyncSuccess}}</span>-->
</div>