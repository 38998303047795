import {Component, OnInit} from '@angular/core';
import {Module} from '@app/core/module';
import {SystemService} from '@app/shared/system/system.service';
import {Router} from '@angular/router';
import {LoadingIndicatorService} from '@app/shared/loading-indicator/loading-indicator.service';

@Component({
  selector: 'app-wait-time',
  templateUrl: './wait-time.component.html',
  styleUrls: ['./wait-time.component.scss']
})
export class WaitTimeComponent implements OnInit {
  module: any;

  constructor(
    public _systemService: SystemService,
    public _router: Router,
    private loading: LoadingIndicatorService
  ) {
  }

  ngOnInit() {
    let module_enc = localStorage.getItem('module_enc');
    let token = localStorage.getItem('user_token').substr(0, 32);
    this.module = this._systemService.moduleDecrypt(module_enc, token);
    if (!Module.WAIT_TIME) {
      this.loading.stop();
      this._router.navigate(['/home', 'page-not-found']);
    }
  }

}
